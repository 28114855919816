"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../../api/api_service");
var button_component_1 = require("../../../../common/buttons/button_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var search_component_1 = require("../../../../common/search/search_component");
var tabs_component_1 = require("../../../../common/tabs/tabs_component");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var tabs_data_1 = require("../../../../constants/tabs_data");
var Pagenotfound_1 = require("../../../../ErrorBoundary/Pagenotfound");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var routes_list_1 = require("../../../../routes/routes_list");
var array_helpers_1 = require("../../../../utils/array_helpers");
var VisitReports = function () {
    var _a = React.useState(''), searchTerm = _a[0], setSearchTerm = _a[1];
    var _b = React.useState(new Date().toString()), filterFromDate = _b[0], setFromDateFilter = _b[1];
    var _c = React.useState(new Date().toString()), filterToDate = _c[0], setToDateFilter = _c[1];
    var _d = React.useState(false), isDateRangeUpdated = _d[0], setIsDateRangeUpdated = _d[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _e = React.useState(false), isListReloaded = _e[0], setIsListReloaded = _e[1];
    var _f = React.useState(null), activeTab = _f[0], setActiveTab = _f[1];
    var _g = React.useState([]), selectedLab = _g[0], setSelectedLab = _g[1];
    var _h = React.useState(null), visitType = _h[0], setSelectedVisitType = _h[1];
    var _j = React.useState(false), open = _j[0], setOpen = _j[1];
    var _k = React.useState([]), isReportList = _k[0], setIsReportList = _k[1];
    var visitStatusList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.visitStatusList; });
    var navigate = (0, react_router_dom_1.useNavigate)();
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.reports);
    var filteredTabs = (0, array_helpers_1.disableTabs)(tabs_data_1.TABS_DATA.VISITS_REPORT_TABS_LIST, features);
    var _l = React.useState(appConstants_1.defaultPageLimit), PageLimit = _l[0], setPageLimit = _l[1];
    var visitsReportRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.reports, routes_list_1.VISITS_REPORT_SUB_ROUES);
    var _m = React.useState(false), isFilter = _m[0], setIsFilter = _m[1];
    var LabHandler = function (Lab) {
        setSelectedLab(Lab);
    };
    var visitTypeHandler = function (visitType) {
        setSelectedVisitType(visitType);
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    var tabChangeHandler = function (val) {
        setSearchTerm('');
        setFromDateFilter(new Date().toString());
        setToDateFilter(new Date().toString());
        setSelectedLab([]);
        setSelectedVisitType(null);
        setPageLimit(appConstants_1.defaultPageLimit);
        setActiveTab(val);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var dumpDownloadHandler = function () {
        var currentPage = 1;
        var payload = {};
        if (filterFromDate && filterToDate) {
            (payload['start_date'] =
                moment(filterFromDate).format('YYYY-MM-DD')),
                (payload['end_date'] =
                    moment(filterToDate).format('YYYY-MM-DD'));
        }
        if (searchTerm) {
            payload['mobile'] = searchTerm;
        }
        if (selectedLab.length > 0) {
            payload['lab_code'] = selectedLab.map(function (lab) {
                return lab.value;
            });
            ;
        }
        if (visitType) {
            payload['visit_status'] = visitType.id;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.DOWNLOAD_VISITS,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message.includes('Sorry! No results found')) {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var navigateToMyReports = function () {
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    return (React.createElement("div", { className: "orderlist-main-wrapper mis-reports-page" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", { className: "title" }, "Visits Report")),
            React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, color: "yellow-outline", endIcon: "icon-import download-icon", isError: !isReportList.length, onClickHandler: dumpDownloadHandler })),
        React.createElement("div", { className: "filter-menu-wrapper border-bottom" },
            React.createElement("div", { className: "search-filter" },
                React.createElement(search_component_1.default, { placeholder: "Search mobile", searchValue: searchTerm, searchHandler: searchingHandler, activetab: activeTab })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true, key: activeTab }),
                React.createElement(multi_selector_1.default, { label: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList), onChangeHnadlre: LabHandler, value: selectedLab }),
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "Visit Status", value: visitType || null, dropDownList: visitStatusList, placeholder: "Visit Status", onChange: visitTypeHandler }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: function () { return setIsFilter(!isFilter); } }))),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement(tabs_component_1.default, { tabList: filteredTabs, tabChangeHandler: tabChangeHandler }),
            React.createElement(react_router_dom_1.Routes, null,
                visitsReportRoutes &&
                    visitsReportRoutes.map(function (features) {
                        return (React.createElement(react_router_dom_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                                    isListReloaded: isListReloaded,
                                    setIsListReloaded: setIsListReloaded,
                                } },
                                React.createElement(features.component, { searchTerm: searchTerm, filterFromDate: filterFromDate, filterToDate: filterToDate, selectedLab: selectedLab, PageLimit: PageLimit, visitType: visitType, setIsReportList: setIsReportList, isFilter: isFilter })) }));
                    }),
                React.createElement(react_router_dom_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) }))),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = VisitReports;
