"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../../api/api_service");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var ModalComponentWithoutButton_1 = require("../../../../common/modal/ModalComponentWithoutButton");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var array_helpers_1 = require("../../../../utils/array_helpers");
var CC_helper_1 = require("../../CC/CC_helper");
var route_planner_api_service_1 = require("../api/route_planner_api_service");
var route_planner_service_1 = require("../service/route_planner_service");
var CREATE_ROUTE_PALNNER_INITIAL_STATE = {
    lab_code: null,
    route_assignment_type: null,
    speed: null,
    radius: null,
    cc_buffer_time: null,
    psc_buffer_time: null,
    fpsc_buffer_time: null,
    pup_buffer_time: null,
    hub_buffer_time: null,
    lab_buffer_time: null,
};
var EDIT_ROUTE_PALNNER_INITIAL_STATE = {
    lab_code: null,
    route_assignment_type: null,
    speed: null,
    radius: null,
    cc_buffer_time: null,
    psc_buffer_time: null,
    fpsc_buffer_time: null,
    pup_buffer_time: null,
    hub_buffer_time: null,
    lab_buffer_time: null,
};
var RoutePlannerForm = function (_a) {
    var isModalOpend = _a.isModalOpend, setOpen = _a.setOpen, profildeDetails = _a.profildeDetails, updatedLocationInfo = _a.updatedLocationInfo, setRealoadPage = _a.setRealoadPage;
    var _b = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }), labList = _b.labList, routing_assignment_type = _b.routing_assignment_type;
    var convertedLabList = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var id = (0, react_router_1.useParams)().id;
    var nav = (0, react_router_1.useNavigate)();
    var _c = React.useState(false), openSubModal = _c[0], setOpenSubModal = _c[1];
    var _d = React.useState(''), created_route_id = _d[0], setCreated_route_id = _d[1];
    var _e = React.useState([]), configList = _e[0], setConfigList = _e[1];
    console.log(configList, 'configList');
    var checkRouteType = function (value) {
        var type;
        if (value == route_planner_service_1.ROUTE_ASSIGNMENT_TYPES.dynamic.type) {
            type = route_planner_service_1.ROUTE_ASSIGNMENT_TYPES.dynamic.value;
        }
        else {
            type = route_planner_service_1.ROUTE_ASSIGNMENT_TYPES.static.value;
        }
        return type;
    };
    console.log(profildeDetails, 'profildeDetails', CREATE_ROUTE_PALNNER_INITIAL_STATE);
    var isEditFlow = pathname.includes("".concat(route_path_1.PATHS.create_route_planner))
        ? true
        : false;
    var fetchRoutePlannerConfigarationList = function (endPoint, payLoad, domain, setFieldValue, setFieldTouched) {
        (0, route_planner_api_service_1.globalRoutePlannerGetAPI)(endPoint, payLoad, domain)
            .then(function (response) {
            console.log(response.result, 'response');
            if (response.result.length > 0) {
                (0, route_planner_service_1.connvertLabCOnfigarationData)(response.result, setFieldValue, setFieldTouched);
                setConfigList(response.result);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    console.log(updatedLocationInfo, 'fromupdatedLocationInfo');
    var genereateRouteForm = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var editRoutePayload, createRoutePayload, editRoute, editPayload, createRoute;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log(values);
                    editRoutePayload = {};
                    createRoutePayload = {};
                    if (!isEditFlow) return [3, 2];
                    return [4, (0, route_planner_service_1.converEditFormDataToPayload)(values, configList, profildeDetails && profildeDetails.changes)];
                case 1:
                    editRoute = _b.sent();
                    console.log(editRoute, 'editRoute');
                    editPayload = (0, route_planner_service_1.convertEditPayloadData)(updatedLocationInfo);
                    console.log(editPayload, 'editPayload');
                    editRoute.route_id = parseInt(id);
                    if (editRoute.changes && editRoute.changes.length > 0) {
                        (_a = editRoute.changes).push.apply(_a, editPayload);
                    }
                    else {
                        editRoute['changes'] = editPayload;
                    }
                    editRoutePayload = editRoute;
                    console.log(editRoutePayload, 'editRoutePayload');
                    return [3, 4];
                case 2: return [4, (0, route_planner_service_1.converCreateFormDataToPayload)(values, configList)];
                case 3:
                    createRoute = _b.sent();
                    createRoutePayload = createRoute;
                    _b.label = 4;
                case 4:
                    api_service_1.api_service
                        .post({
                        endPoint: isEditFlow
                            ? api_urls_1.api_urls.ROUTE_PLANEER_UPDATE
                            : api_urls_1.api_urls.ROUTE_PLANNER_CREATE,
                        payLoad: isEditFlow ? editRoutePayload : createRoutePayload,
                        domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
                    })
                        .then(function (response) {
                        console.log(response, 'response');
                        if (response.status == 200) {
                            react_hot_toast_1.default.success('Success');
                            setOpen(false);
                            setCreated_route_id(response.data.data.route_id);
                            setOpenSubModal(true);
                        }
                        else {
                            react_hot_toast_1.default.error(response.data.message);
                        }
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                    return [2];
            }
        });
    }); };
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (isEditFlow)
            if (profildeDetails && profildeDetails.details && profildeDetails.details) {
                CREATE_ROUTE_PALNNER_INITIAL_STATE.lab_code = profildeDetails.details
                    .lab_code
                    ?
                        (0, CC_helper_1.convertEditDataForLabCode)(profildeDetails === null || profildeDetails === void 0 ? void 0 : profildeDetails.details.lab_code, convertedLabList)
                    : null;
                CREATE_ROUTE_PALNNER_INITIAL_STATE.route_assignment_type = profildeDetails
                    .details.route_assignment_type
                    ? {
                        label: profildeDetails.details.route_assignment_type,
                        value: checkRouteType(profildeDetails.details.route_assignment_type),
                    }
                    : null;
                CREATE_ROUTE_PALNNER_INITIAL_STATE.speed =
                    (_a = profildeDetails.details.configuration.speed) !== null && _a !== void 0 ? _a : null;
                CREATE_ROUTE_PALNNER_INITIAL_STATE.radius =
                    (_b = profildeDetails.details.configuration.radius) !== null && _b !== void 0 ? _b : null;
                CREATE_ROUTE_PALNNER_INITIAL_STATE.cc_buffer_time =
                    (_c = profildeDetails.details.configuration.cc_buffer_time) !== null && _c !== void 0 ? _c : null;
                CREATE_ROUTE_PALNNER_INITIAL_STATE.psc_buffer_time =
                    (_d = profildeDetails.details.configuration.psc_buffer_time) !== null && _d !== void 0 ? _d : null;
                CREATE_ROUTE_PALNNER_INITIAL_STATE.fpsc_buffer_time =
                    (_e = profildeDetails.details.configuration.fpsc_buffer_time) !== null && _e !== void 0 ? _e : null;
                CREATE_ROUTE_PALNNER_INITIAL_STATE.pup_buffer_time =
                    (_f = profildeDetails.details.configuration.pup_buffer_time) !== null && _f !== void 0 ? _f : null;
                CREATE_ROUTE_PALNNER_INITIAL_STATE.hub_buffer_time =
                    (_g = profildeDetails.details.configuration.hub_buffer_time) !== null && _g !== void 0 ? _g : null;
                CREATE_ROUTE_PALNNER_INITIAL_STATE.lab_buffer_time =
                    (_h = profildeDetails.details.configuration.lab_buffer_time) !== null && _h !== void 0 ? _h : null;
                var configData = (0, route_planner_service_1.converProfileCongData)(profildeDetails.details.configuration);
                setConfigList(configData);
            }
    }, [profildeDetails, isEditFlow]);
    return (React.createElement("div", null,
        React.createElement(ModalComponentWithoutButton_1.default, { open: isModalOpend },
            React.createElement(formik_1.Formik, { initialValues: isEditFlow
                    ? CREATE_ROUTE_PALNNER_INITIAL_STATE
                    : EDIT_ROUTE_PALNNER_INITIAL_STATE, validateOnBlur: false, validationSchema: schema_1.ROUTE_PLANNER_SCHEAMA, onSubmit: genereateRouteForm }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "modal-body" },
                        React.createElement("div", { className: "form-container" },
                            ' ',
                            React.createElement("h2", { className: "heading" }, pathname.includes("".concat(route_path_1.PATHS.create_route_planner))
                                ? ' Re Generate Route'
                                : 'Generate Route'),
                            React.createElement(material_1.Grid, { container: true, columns: 10, columnGap: 3 },
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(drop_down_component_1.default, { label: "Lab Code", placeholder: "Lab Code", dropDownList: convertedLabList, required: true, isDisabled: isEditFlow, onChange: function (labValue) {
                                            setFieldValue('lab_code', labValue);
                                            setFieldTouched('lab_code', true);
                                        }, value: values.lab_code }),
                                    errors['lab_code'] && touched['lab_code'] ? (React.createElement("div", { className: "error" }, errors['lab_code'])) : null),
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(drop_down_component_1.default, { label: "Route Type", placeholder: "Route Type", dropDownList: routing_assignment_type && routing_assignment_type.slice(0, 2), isDisabled: !pathname.includes("".concat(route_path_1.PATHS.create_route_planner)) &&
                                            values.lab_code
                                            ? false
                                            : true, onChange: function (routeTypeValue) {
                                            fetchRoutePlannerConfigarationList(api_urls_1.api_urls.ROUTE_PLANNER_CONFIGARATION, {
                                                lab_code: values.lab_code.value,
                                                assignment_type: parseInt(routeTypeValue.value),
                                            }, process.env.ONE_TWO_LEVEL_APPROVAL_URL, setFieldValue, setFieldTouched);
                                            setFieldValue('route_assignment_type', routeTypeValue);
                                            setFieldTouched('route_assignment_type', true);
                                        }, value: values.route_assignment_type }),
                                    errors['route_assignment_type'] &&
                                        touched['route_assignment_type'] ? (React.createElement("div", { className: "error" }, errors['route_assignment_type'])) : null),
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(text_filed_component_1.default, { label: "Speed Km/hr", required: true, onChange: function (speedValue) {
                                            setFieldValue('speed', Number(speedValue));
                                            setFieldTouched('speed', true);
                                        }, onKeyPress: function (e) { return (0, array_helpers_1.restictSpecialCharecters)(e); }, value: values.speed }),
                                    errors['speed'] && touched['speed'] ? (React.createElement("div", { className: "error" }, errors['speed'])) : null),
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(text_filed_component_1.default, { label: "FE Radius Km", required: true, onChange: function (radiusValue) {
                                            setFieldValue('radius', Number(radiusValue));
                                            setFieldTouched('radius', true);
                                        }, onKeyPress: function (e) { return (0, array_helpers_1.restictSpecialCharecters)(e); }, value: values.radius }),
                                    errors['radius'] && touched['radius'] ? (React.createElement("div", { className: "error" }, errors['radius'])) : null))),
                        React.createElement("div", { className: "form-container" },
                            ' ',
                            React.createElement(material_1.Typography, { variant: "h2", py: 2, className: "heading" }, "Buffer Times"),
                            React.createElement(material_1.Grid, { container: true, columns: 10, columnGap: 3 },
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(text_filed_component_1.default, { label: "Time for CC (mins) ", onChange: function (value) {
                                            setFieldValue('cc_buffer_time', Number(value));
                                            setFieldTouched('cc_buffer_time', true);
                                        }, onKeyPress: function (e) { return (0, array_helpers_1.restictSpecialCharecters)(e); }, value: values.cc_buffer_time }),
                                    errors['cc_buffer_time'] && touched['cc_buffer_time'] ? (React.createElement("div", { className: "error" }, errors['cc_buffer_time'])) : null),
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(text_filed_component_1.default, { label: "Time for PSC (mins) ", onChange: function (value) {
                                            setFieldValue('psc_buffer_time', Number(value));
                                            setFieldTouched('psc_buffer_time', true);
                                        }, onKeyPress: function (e) { return (0, array_helpers_1.restictSpecialCharecters)(e); }, value: values.psc_buffer_time }),
                                    errors['psc_buffer_time'] && touched['psc_buffer_time'] ? (React.createElement("div", { className: "error" }, errors['psc_buffer_time'])) : null),
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(text_filed_component_1.default, { label: "Time for FPSC (mins)  ", onChange: function (value) {
                                            setFieldValue('fpsc_buffer_time', Number(value));
                                            setFieldTouched('fpsc_buffer_time', true);
                                        }, onKeyPress: function (e) { return (0, array_helpers_1.restictSpecialCharecters)(e); }, value: values.fpsc_buffer_time }),
                                    errors['fpsc_buffer_time'] && touched['fpsc_buffer_time'] ? (React.createElement("div", { className: "error" }, errors['fpsc_buffer_time'])) : null),
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(text_filed_component_1.default, { label: "Time for PUP (mins)  ", onChange: function (value) {
                                            setFieldValue('pup_buffer_time', Number(value));
                                            setFieldTouched('pup_buffer_time', true);
                                        }, onKeyPress: function (e) { return (0, array_helpers_1.restictSpecialCharecters)(e); }, value: values.pup_buffer_time }),
                                    errors['pup_buffer_time'] && touched['pup_buffer_time'] ? (React.createElement("div", { className: "error" }, errors['pup_buffer_time'])) : null),
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(text_filed_component_1.default, { label: "Time for HUB (mins)  ", onChange: function (value) {
                                            setFieldValue('hub_buffer_time', Number(value));
                                            setFieldTouched('hub_buffer_time', true);
                                        }, onKeyPress: function (e) { return (0, array_helpers_1.restictSpecialCharecters)(e); }, value: values.hub_buffer_time }),
                                    errors['hub_buffer_time'] && touched['hub_buffer_time'] ? (React.createElement("div", { className: "error" }, errors['hub_buffer_time'])) : null),
                                React.createElement(material_1.Grid, { sm: 3 },
                                    ' ',
                                    React.createElement(text_filed_component_1.default, { label: "Time for LAB (mins) ", onChange: function (value) {
                                            setFieldValue('lab_buffer_time', Number(value));
                                            setFieldTouched('lab_buffer_time', true);
                                        }, onKeyPress: function (e) { return (0, array_helpers_1.restictSpecialCharecters)(e); }, value: values.lab_buffer_time }),
                                    errors['lab_buffer_time'] && touched['lab_buffer_time'] ? (React.createElement("div", { className: "error" }, errors['lab_buffer_time'])) : null)),
                            ' '),
                        ' '),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return setOpen(false); } }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "GENERATE", color: "yellow", isError: isEditFlow
                                ? JSON.stringify(CREATE_ROUTE_PALNNER_INITIAL_STATE) ==
                                    JSON.stringify(values) &&
                                    updatedLocationInfo &&
                                    updatedLocationInfo.length == 0
                                : false }))));
            })),
        React.createElement(ModalComponentWithoutButton_1.default, { open: openSubModal },
            React.createElement("div", { className: "modal-header " },
                React.createElement("h2", null, isEditFlow ? 'Route Generation Updated' : 'Route Generation Initiated'),
                React.createElement("h3", { className: "sub-heading text" },
                    "Please view Route",
                    ' ',
                    React.createElement("span", { className: "link" },
                        "ID",
                        ' ',
                        isEditFlow
                            ? profildeDetails &&
                                profildeDetails.details &&
                                profildeDetails.details.route_id
                            : created_route_id),
                    ' ',
                    "when ready")),
            React.createElement(button_component_1.default, { buttonName: "OK", color: "yellow-outline", onClickHandler: function () {
                    console.log(isEditFlow, 'isEditFlow');
                    if (isEditFlow) {
                        nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_list));
                    }
                    setOpenSubModal(false);
                    setRealoadPage(true);
                    console.log(isEditFlow, 'isEditFlow3');
                } }))));
};
exports.default = RoutePlannerForm;
