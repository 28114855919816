"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var React = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var route_path_1 = require("../../../constants/route_path");
var array_helpers_1 = require("../../../utils/array_helpers");
var payroll_apicalls_1 = require("../../field_exicutive/payroll/payroll_list/payroll.apicalls");
var StateCityFilter = function (_a) {
    var misreports = _a.misreports, onChangeHandler = _a.onChangeHandler, isDisabled = _a.isDisabled, name = _a.name, bagginglifecycle = _a.bagginglifecycle, activetab = _a.activetab, setSelectedStates = _a.setSelectedStates, setSelectedCities = _a.setSelectedCities, module = _a.module;
    var pathname = (0, react_router_1.useLocation)().pathname;
    var auto_routing_filtes = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer.auto_routing_filtes; });
    var stateList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).stateList;
    var _b = React.useState([]), citiesData = _b[0], setCitiesData = _b[1];
    var _c = React.useState(pathname.includes(route_path_1.PATHS.controlTower) && (auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.selected_states)
        ? auto_routing_filtes.selected_states
        : []), filterSelectedStates = _c[0], setSelectedFilterStates = _c[1];
    var _d = React.useState(pathname.includes(route_path_1.PATHS.controlTower) && (auto_routing_filtes === null || auto_routing_filtes === void 0 ? void 0 : auto_routing_filtes.selected_cities)
        ? auto_routing_filtes.selected_cities
        : []), filterSelectedCities = _d[0], setSelectedFilterCities = _d[1];
    var callbackUpdateSelectedStates = function (value, event) {
        onChangeHandler([]);
        setCitiesData([]);
        setSelectedFilterCities([]);
        setSelectedFilterStates(value);
        setSelectedStates(value);
    };
    var callbackUpdateSelectedCities = function (value) {
        setSelectedFilterCities(value);
        if (module === 'Controltower') {
            setSelectedCities(value);
        }
        onChangeHandler(value);
    };
    var fetchCities = function () { return __awaiter(void 0, void 0, void 0, function () {
        var stateIdArray, payloadToFetchCities;
        return __generator(this, function (_a) {
            stateIdArray = filterSelectedStates.map(function (state) { return state.id; });
            payloadToFetchCities = {
                state_id: stateIdArray,
            };
            (0, payroll_apicalls_1.fetchCitiesList)(payloadToFetchCities)
                .then(function (citiesListResponse) {
                var formattedCitiesList = (0, array_helpers_1.convertLabelAndValueCity)(citiesListResponse.cities);
                setCitiesData(formattedCitiesList);
            })
                .catch(function (e) {
                setCitiesData([]);
            });
            return [2];
        });
    }); };
    var updateSelectedCities = function () {
        console.log('jhfdslk', filterSelectedCities, stateList);
        var filteredData = lodash_1.default.intersectionBy(filterSelectedCities, stateList, 'state_id');
        setSelectedFilterCities(filteredData);
    };
    React.useEffect(function () {
        if (filterSelectedStates.length >= 1) {
            fetchCities();
        }
        if (false) {
            updateSelectedCities();
        }
    }, [filterSelectedStates]);
    return (React.createElement("div", { className: "filter-options city-state-wrapper" },
        React.createElement(multi_selector_1.default, { isDisabled: isDisabled, label: "Select State", dropDownList: stateList && stateList.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: callbackUpdateSelectedStates, value: filterSelectedStates }),
        React.createElement(multi_selector_1.default, { isDisabled: isDisabled, noOptions: 'No cities found', hasSelectAll: bagginglifecycle || misreports ? false : true, label: "Select City", dropDownList: citiesData && citiesData.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: callbackUpdateSelectedCities, value: filterSelectedCities || [] })));
};
exports.default = StateCityFilter;
