"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_alice_carousel_1 = require("react-alice-carousel");
require("react-alice-carousel/lib/alice-carousel.css");
var ImageCarosal = function (_a) {
    var carousalElements = _a.carousalElements;
    var renderImages = function (images) {
        if (!images || images.length === 0) {
            return [];
        }
        return images.map(function (url) {
            return (React.createElement("div", { className: "carousel-item" },
                React.createElement("a", { target: "_blank", href: url, download: true },
                    React.createElement("img", { src: url, role: "presentation", width: 100, height: 100 }))));
        });
    };
    return (React.createElement("div", { className: "image-upload-section" },
        React.createElement("div", { className: "carousel-conatiner" },
            React.createElement(react_alice_carousel_1.default, { disableDotsControls: true, preservePosition: true, mouseTracking: true, responsive: { 0: { items: 8 } }, controlsStrategy: "responsive", items: renderImages(carousalElements) }))));
};
exports.default = ImageCarosal;
