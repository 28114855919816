"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchFEList = exports.createCCTimeSlot = exports.fetchSelectedFeDeatils = exports.fetchFeOnSelectedFeType = exports.getAllWeekDays = exports.getAllTimeSlots = exports.submitFeTimeSlot = exports.fetchWorkingHoursFEList = void 0;
var api_service_1 = require("../../../api/api_service");
var array_helpers_1 = require("../../../utils/array_helpers");
var fetchWorkingHoursFEList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.FE_SLOTS,
            domain: process.env.VENDOR_BASE_URL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            console.log('14666', response);
            throw response.data.message;
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchWorkingHoursFEList = fetchWorkingHoursFEList;
var submitFeTimeSlot = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CREATE_FE_TIME_SLOT,
            domain: process.env.VENDOR_BASE_URL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log('yhuji', response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw response === null || response === void 0 ? void 0 : response.data.message;
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.submitFeTimeSlot = submitFeTimeSlot;
var getAllTimeSlots = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.ALL_TIME_SLOTS,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.getAllTimeSlots = getAllTimeSlots;
var getAllWeekDays = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.WEEK_DAYS_FOR_SLOTS,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.getAllWeekDays = getAllWeekDays;
var fetchFeOnSelectedFeType = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.FE_LIST,
            domain: process.env.FE_LIST_BASE_URL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data.data.result);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchFeOnSelectedFeType = fetchFeOnSelectedFeType;
var fetchSelectedFeDeatils = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.FE_SLOTS,
            domain: process.env.VENDOR_BASE_URL,
            payLoad: payLoad,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response);
            }
            throw response.data.message;
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchSelectedFeDeatils = fetchSelectedFeDeatils;
var createCCTimeSlot = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CC_CREATE,
            domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response, '78yu');
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.createCCTimeSlot = createCCTimeSlot;
var fetchFEList = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .post({
                    endPoint: api_service_1.api_service.api_urls.FE_LIST,
                    domain: process.env.VENDOR_BASE_URL,
                    payLoad: payload,
                })
                    .then(function (response) {
                    console.log('55555', response);
                    if (response.status === 200) {
                        var convertedActiveFEList = (0, array_helpers_1.convertActiveFEListFromRes)(response.data.data);
                        return resolve(convertedActiveFEList);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchFEList = fetchFEList;
