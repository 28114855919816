"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Box_1 = require("@mui/material/Box");
var Grid_1 = require("@mui/material/Grid");
var Tab_1 = require("@mui/material/Tab");
var Tabs_1 = require("@mui/material/Tabs");
var Typography_1 = require("@mui/material/Typography");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var config_action_1 = require("../../../actions/config_action");
var FE_Creation_action_1 = require("../../../actions/FE_Creation_action");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var route_path_1 = require("../../../constants/route_path");
var field_exicutive_types_1 = require("../../../types/field_exicutive_types");
var array_helpers_1 = require("../../../utils/array_helpers");
var generic_api_calls_1 = require("../../generic_api_calls");
var CC_helper_1 = require("../../super_admin/CC/CC_helper");
var lodash_1 = require("./../../../utils/lodash");
require("./fe-registration.scss");
var FE_BankAccountDetailsForm_1 = require("./FE_BankAccountDetailsForm");
var FE_ContactDetailsForm_1 = require("./FE_ContactDetailsForm");
var FE_DrivingLiecenseDetailsForm_1 = require("./FE_DrivingLiecenseDetailsForm");
var FE_EmploymentDetailsForm_1 = require("./FE_EmploymentDetailsForm");
var FE_InventoryForm_1 = require("./FE_InventoryForm");
var FE_PersonalDetailsForm_1 = require("./FE_PersonalDetailsForm");
var TabPanel = function (props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (React.createElement("div", __assign({ role: "tabpanel", hidden: value !== index, id: "vertical-tabpanel-".concat(index), "aria-labelledby": "vertical-tab-".concat(index) }, other), value === index && (React.createElement(Box_1.default, { sx: { p: 3 } },
        React.createElement(Typography_1.default, null, children)))));
};
var a11yProps = function (index) {
    return {
        id: "vertical-tab-".concat(index),
        'aria-controls': "vertical-tabpanel-".concat(index),
    };
};
var FE_REGISTATION = function () {
    var _a = React.useState(0), value = _a[0], setValue = _a[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), stateList = _b.stateList, countryList = _b.countryList, labList = _b.labList, company = _b.company, tenure = _b.tenure, zoneTypeList = _b.zoneTypeList, fe_types = _b.fe_types, vehicleTypeList = _b.vehicleTypeList, genderTypeList = _b.genderTypeList, addressProofType = _b.addressProofType, HudIdList = _b.HudIdList, routing_assignment_type = _b.routing_assignment_type;
    var dispatch = (0, react_redux_1.useDispatch)();
    var converteLabList = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var user_id = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer; }).user_id;
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.user; }), vendorName = _c.vendorName, vendorCode = _c.vendorCode;
    console.log(vendorName, 'vendorName');
    var editUserId = (0, react_router_dom_1.useParams)().id;
    console.log(editUserId);
    var replaceFE_FECode = (0, react_router_dom_1.useParams)().replaceFeCode;
    var _d = React.useState(), replaceFeIdFromApi = _d[0], setreplaceFeIdFromApi = _d[1];
    console.log('replaceFeIdFromApi', replaceFeIdFromApi);
    var activeTabId = (0, react_router_dom_1.useParams)().activeTabId;
    var FE_ReplaceFePersonalDetailsInitialState = {
        feIdToBeReplaced: replaceFeIdFromApi,
        firstName: '',
        lastName: '',
        dob: '',
        mobileNumber: '',
        email: '',
        emergencyContactNo: '',
        gender: null,
        uploadPhoto: '',
        referenceContactName1: '',
        referenceMobileNumber1: '',
        referenceContactName2: '',
        referenceMobileNumber2: '',
        isActiveFE: false,
        replaceFE_FECode: replaceFE_FECode,
    };
    var _e = React.useState(__assign({}, field_exicutive_types_1.FE_PersonalDetailsInitialState)), fePersonalDetails = _e[0], setFePersonalDetails = _e[1];
    var _f = React.useState(__assign({}, FE_ReplaceFePersonalDetailsInitialState)), feReplacementDetails = _f[0], setFeRepalcementDetails = _f[1];
    console.log(fePersonalDetails, 'uuuuuuu');
    var _g = React.useState(__assign({}, field_exicutive_types_1.FE_ContactDetailsInitialState)), feContactDetails = _g[0], setFeContactDetails = _g[1];
    var _h = React.useState(__assign({}, field_exicutive_types_1.FE_BankAccountDetailsInitialState)), feBankAccountDetails = _h[0], setFeBankAccountDetails = _h[1];
    var _j = React.useState(__assign({}, field_exicutive_types_1.FE_DrivingLicenceDetailsInitialState)), feDrivingLicenceDetails = _j[0], setFeDrivingLicenceDetails = _j[1];
    var _k = React.useState(__assign({}, field_exicutive_types_1.FE_EmploymentDetailsInitialState)), feEmploymentDetails = _k[0], setFeEmploymentDetails = _k[1];
    var _l = React.useState(__assign({}, field_exicutive_types_1.FE_InventoryDetailsInitialState)), feInventoryDetails = _l[0], setInventoryDetails = _l[1];
    var _m = React.useState(false), enableSendItForApproval = _m[0], setEnableSendItForApproval = _m[1];
    var _o = React.useState(null), onBoardingStatus = _o[0], setOnBoardingStatus = _o[1];
    var _p = React.useState(false), enableDeleteDraft = _p[0], setEnableDeleteDraft = _p[1];
    var _q = React.useState(null), FE_ID = _q[0], setFE_ID = _q[1];
    var location = (0, react_router_dom_1.useLocation)();
    var _r = React.useState(false), isActveFEEdit = _r[0], setIsActiveFEEdit = _r[1];
    var _s = React.useState(true), disableSendItForApprovalForActiveFE = _s[0], setdisableSendItForApprovalForActiveFE = _s[1];
    var _t = React.useState(''), vendorcode = _t[0], setVendorcode = _t[1];
    var replaceFEID = (0, react_router_dom_1.useParams)().replaceFeId;
    var updateValue = function (updatedValue) {
        setValue(updatedValue);
    };
    var saveFE_PersonalDetails = function (values, isActiveFEEdit) {
        console.log('values inside saveFE_PersonalDetails: ', values);
        setFePersonalDetails(values);
        if (onBoardingStatus === 2) {
            setEnableDeleteDraft(true);
        }
        updateValue(value + 1);
        setdisableSendItForApprovalForActiveFE(false);
    };
    var saveFE_ContactDetails = function (values, isActiveFEEdit) {
        setFeContactDetails(values);
        updateValue(value + 1);
        setdisableSendItForApprovalForActiveFE(false);
    };
    var saveFE_BankAccountDetails = function (values, isActiveFEEdit) {
        setFeBankAccountDetails(values);
        updateValue(value + 1);
        setdisableSendItForApprovalForActiveFE(false);
    };
    var saveFE_DrivingLicenceDetails = function (values, isActiveFEEdit) {
        setFeDrivingLicenceDetails(values);
        updateValue(value + 1);
        setdisableSendItForApprovalForActiveFE(false);
    };
    var saveFE_EmploymentDetails = function (values, isActiveFEEdit) {
        setFeEmploymentDetails(values);
        if (onBoardingStatus === 1) {
            updateValue(value + 1);
            setdisableSendItForApprovalForActiveFE(false);
        }
    };
    var saveFE_InventoryDetails = function (values) {
        console.log('inside inventory', values);
        setInventoryDetails(values);
    };
    var uploadFile = function (val) {
        console.log('inside fileUpload', val);
        var payload = new FormData();
        payload.append('cc_id', vendorcode);
        payload.append('file', val);
        payload.append('file_type', 'Other');
        return (0, generic_api_calls_1.fileUpload)(payload);
    };
    React.useEffect(function () {
        setVendorcode(vendorCode);
        setreplaceFeIdFromApi(replaceFEID);
        dispatch((0, FE_Creation_action_1.updateUserID)({
            user_id: null,
        }));
        if (activeTabId !== null && activeTabId !== undefined) {
            setValue(parseInt(activeTabId));
        }
        return function () {
            dispatch((0, config_action_1.fetch_permanentCities)([]));
            dispatch((0, config_action_1.fetch_cities)([]));
            dispatch((0, config_action_1.fetch_HubIDList)([]));
        };
    }, []);
    React.useEffect(function () {
        if (onBoardingStatus === 1) {
            return;
        }
        else {
            if (editUserId) {
                dispatch((0, FE_Creation_action_1.updateUserID)({
                    user_id: parseInt(editUserId),
                }));
                api_service_1.api_service
                    .get({
                    endPoint: "".concat(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, "?user_id=").concat(editUserId),
                    domain: process.env.VENDOR_BASE_URL,
                    showLoader: false,
                })
                    .then(function (res) {
                    if (res.data.statusCode === 200) {
                        var responseObj_1 = res.data.data;
                        console.log(responseObj_1, 'responseObj');
                        var selectedLab = null;
                        setOnBoardingStatus(responseObj_1.onboard_status);
                        setIsActiveFEEdit(responseObj_1.onboard_status === 1 ? true : false);
                        setFE_ID(responseObj_1.fe_id);
                        if (responseObj_1.onboard_status === 2) {
                            setEnableDeleteDraft(true);
                        }
                        var personalDetails = {
                            firstName: responseObj_1.first_name !== null &&
                                responseObj_1.first_name !== ''
                                ? responseObj_1.first_name
                                : '',
                            lastName: responseObj_1.last_name !== null && responseObj_1.last_name !== ''
                                ? responseObj_1.last_name
                                : '',
                            dob: responseObj_1.dob !== null ? responseObj_1.dob : '',
                            mobileNumber: responseObj_1.mobile !== null && responseObj_1.mobile !== ''
                                ? responseObj_1.mobile
                                : '',
                            email: responseObj_1.email !== null && responseObj_1.email !== ''
                                ? responseObj_1.email
                                : '',
                            uploadPhoto: responseObj_1.avatar != null && responseObj_1.avatar != ''
                                ? responseObj_1.avatar
                                : '',
                            gender: responseObj_1.gender != null
                                ? (0, array_helpers_1.filterDataByLabel)({
                                    filterArray: genderTypeList,
                                    name: responseObj_1.gender,
                                    fieldname: 'gender',
                                })
                                : null,
                            emergencyContactNo: responseObj_1.emergency_mobile !== null &&
                                responseObj_1.emergency_mobile !== ''
                                ? responseObj_1.emergency_mobile
                                : '',
                            referenceContactName1: responseObj_1.reference_name1 != null &&
                                responseObj_1.reference_name1 != ''
                                ? responseObj_1.reference_name1
                                : '',
                            referenceMobileNumber1: responseObj_1.reference_mobile1 !== null &&
                                responseObj_1.reference_mobile1 !== ''
                                ? responseObj_1.reference_mobile1
                                : '',
                            referenceContactName2: responseObj_1.reference_name2 !== null &&
                                responseObj_1.reference_name2 !== ''
                                ? responseObj_1.reference_name2
                                : '',
                            referenceMobileNumber2: responseObj_1.reference_mobile2 !== null &&
                                responseObj_1.reference_mobile2 !== ''
                                ? responseObj_1.reference_mobile2
                                : '',
                            isActiveFE: responseObj_1.onboard_status === 1 ? true : false,
                        };
                        responseObj_1.replacement_fe_id
                            ? setFeRepalcementDetails(__assign(__assign({}, personalDetails), { feIdToBeReplaced: responseObj_1.replacement_fe_id, replaceFE_FECode: responseObj_1.replacement_fe_code
                                    ? responseObj_1.replacement_fe_code
                                    : '' }))
                            : setFePersonalDetails(personalDetails);
                        setFePersonalDetails(personalDetails);
                        setOnBoardingStatus(responseObj_1.onboard_status);
                        setFePersonalDetails(personalDetails);
                        if (responseObj_1.addresses.length > 0) {
                            var currentDetailsObj_1 = lodash_1.default.find(responseObj_1.addresses, { type: 'present' });
                            var permenentAddressDetails_1 = lodash_1.default.find(responseObj_1.addresses, { type: 'permanent' });
                            var stateIds = [];
                            if (currentDetailsObj_1.state_id ===
                                permenentAddressDetails_1.state_id &&
                                currentDetailsObj_1.state_id !== null) {
                                stateIds.push(currentDetailsObj_1.state_id);
                            }
                            else {
                                if (currentDetailsObj_1.state_id != null) {
                                    stateIds.push(currentDetailsObj_1.state_id);
                                }
                                else {
                                    dispatch((0, config_action_1.fetch_cities)([]));
                                }
                                if (permenentAddressDetails_1.state_id != null) {
                                    stateIds.push(permenentAddressDetails_1.state_id);
                                }
                                else {
                                    dispatch((0, config_action_1.fetch_permanentCities)([]));
                                }
                            }
                            var contactDetails_1 = {
                                addresslane1: currentDetailsObj_1.lane1 !== null
                                    ? currentDetailsObj_1.lane1
                                    : '',
                                addresslane2: currentDetailsObj_1.lane2 !== null
                                    ? currentDetailsObj_1.lane2
                                    : '',
                                landmark: currentDetailsObj_1.landmark !== null
                                    ? currentDetailsObj_1.landmark
                                    : '',
                                state: null,
                                city: null,
                                country: null,
                                pincode: currentDetailsObj_1.pincode !== null
                                    ? currentDetailsObj_1.pincode.toString()
                                    : '',
                                addressProofType: currentDetailsObj_1.proof_of_doc_type != null
                                    ? (0, array_helpers_1.filterDataByLabel)({
                                        filterArray: addressProofType,
                                        name: currentDetailsObj_1.proof_of_doc_type,
                                        fieldname: 'addressProofType',
                                    })
                                    : null,
                                proofOfAddress: currentDetailsObj_1.proof_of_doc != null &&
                                    currentDetailsObj_1.proof_of_doc != undefined &&
                                    currentDetailsObj_1.proof_of_doc != ''
                                    ? currentDetailsObj_1.proof_of_doc
                                    : '',
                                permenentAddresslane1: permenentAddressDetails_1.lane1 !== null
                                    ? permenentAddressDetails_1.lane1
                                    : '',
                                permenentAddresslane2: permenentAddressDetails_1.lane2 !== null
                                    ? permenentAddressDetails_1.lane2
                                    : '',
                                permenentLandmark: permenentAddressDetails_1.landmark !== null
                                    ? permenentAddressDetails_1.landmark
                                    : '',
                                permenentState: null,
                                permenentCity: null,
                                permenentCountry: null,
                                permenentPincode: permenentAddressDetails_1.pincode !== null
                                    ? permenentAddressDetails_1.pincode.toString()
                                    : '',
                                permanentProofOfAddressType: permenentAddressDetails_1.proof_of_doc_type != null
                                    ? (0, array_helpers_1.filterDataByLabel)({
                                        filterArray: addressProofType,
                                        name: permenentAddressDetails_1.proof_of_doc_type,
                                        fieldname: 'permanentProofOfAddressType',
                                    })
                                    : null,
                                permenentProofOfAddress: permenentAddressDetails_1.proof_of_doc != null &&
                                    permenentAddressDetails_1.proof_of_doc != undefined &&
                                    permenentAddressDetails_1.proof_of_doc != ''
                                    ? permenentAddressDetails_1.proof_of_doc
                                    : '',
                                isActiveFE: responseObj_1.onboard_status === 1 ? true : false,
                            };
                            console.log(contactDetails_1, 'contactDetails');
                            if (stateIds.length > 0) {
                                (0, generic_api_calls_1.fetchCitiesUnderStates)(stateIds)
                                    .then(function (cityListFromStateId) {
                                    dispatch((0, config_action_1.fetch_cities)(cityListFromStateId.filter(function (city) {
                                        return city.state_id === currentDetailsObj_1.state_id;
                                    })));
                                    dispatch((0, config_action_1.fetch_permanentCities)(cityListFromStateId.filter(function (city) {
                                        return city.state_id === permenentAddressDetails_1.state_id;
                                    })));
                                    (contactDetails_1['state'] =
                                        currentDetailsObj_1.state_id !== null
                                            ? (0, array_helpers_1.filterData)({
                                                filterArray: stateList,
                                                id: currentDetailsObj_1.state_id,
                                                fieldname: ' state ',
                                            })
                                            : null),
                                        (contactDetails_1['city'] =
                                            currentDetailsObj_1.city_id !== null
                                                ? (0, array_helpers_1.filterData)({
                                                    filterArray: cityListFromStateId,
                                                    id: currentDetailsObj_1.city_id,
                                                })
                                                : null),
                                        (contactDetails_1['country'] =
                                            currentDetailsObj_1.country_id !== null
                                                ? (0, array_helpers_1.filterData)({
                                                    filterArray: countryList,
                                                    id: currentDetailsObj_1.country_id,
                                                })
                                                : null),
                                        (contactDetails_1['permenentState'] =
                                            permenentAddressDetails_1.state_id !== null
                                                ? (0, array_helpers_1.filterData)({
                                                    filterArray: stateList,
                                                    id: permenentAddressDetails_1.state_id,
                                                })
                                                : null),
                                        (contactDetails_1['permenentCity'] =
                                            permenentAddressDetails_1.city_id !== null
                                                ? (0, array_helpers_1.filterData)({
                                                    filterArray: cityListFromStateId,
                                                    id: permenentAddressDetails_1.city_id,
                                                })
                                                : null),
                                        (contactDetails_1['permenentCountry'] =
                                            permenentAddressDetails_1.country_id !== null
                                                ? (0, array_helpers_1.filterData)({
                                                    filterArray: countryList,
                                                    id: permenentAddressDetails_1.country_id,
                                                })
                                                : null);
                                    setFeContactDetails(contactDetails_1);
                                })
                                    .catch(function (err) {
                                    console.log(';Unable to process your request');
                                });
                            }
                            else {
                                setFeContactDetails(contactDetails_1);
                            }
                        }
                        var bankAccountDetails = {
                            AccountNumber: responseObj_1.bank_account !== null
                                ? responseObj_1.bank_account
                                : '',
                            IFSC_Code: responseObj_1.bank_ifsc != null ? responseObj_1.bank_ifsc : '',
                            Bank_Name: responseObj_1.bank_name != null ? responseObj_1.bank_name : '',
                            Bank_Branch: responseObj_1.bank_branch != null
                                ? responseObj_1.bank_branch
                                : '',
                            UPI_ID: responseObj_1.bank_upi_id != null
                                ? responseObj_1.bank_upi_id
                                : '',
                            isActiveFE: responseObj_1.onboard_status === 1 ? true : false,
                            proofOfBankAccountDetails: responseObj_1.bank_cheque_doc != null &&
                                responseObj_1.bank_cheque_doc != undefined &&
                                responseObj_1.bank_cheque_doc != ''
                                ? responseObj_1.bank_cheque_doc
                                : '',
                        };
                        setFeBankAccountDetails(bankAccountDetails);
                        var drivingLicenceDetails = {
                            vehicleType: responseObj_1.vehicle_type_id !== null
                                ? (0, array_helpers_1.filterData)({
                                    filterArray: vehicleTypeList,
                                    id: responseObj_1.vehicle_type_id,
                                })
                                : null,
                            drivingLicence: responseObj_1.driving_licence !== null
                                ? responseObj_1.driving_licence
                                : '',
                            insuranceNumber: responseObj_1.insurance != null ? responseObj_1.insurance : '',
                            uploadInsuranceDoc: responseObj_1.insurance_photo != null &&
                                responseObj_1.insurance_photo != undefined &&
                                responseObj_1.insurance_photo != ''
                                ? responseObj_1.insurance_photo
                                : '',
                            insuranceExpirydate: responseObj_1.insurance_expiry !== null
                                ? responseObj_1.insurance_expiry
                                : '',
                            drivingLicenceExpirydate: responseObj_1.driving_licence_expiry !== null
                                ? responseObj_1.driving_licence_expiry
                                : '',
                            isActiveFE: responseObj_1.onboard_status === 1 ? true : false,
                            proofOfDrivingLicence: responseObj_1.driving_licence_doc != null &&
                                responseObj_1.driving_licence_doc != undefined &&
                                responseObj_1.driving_licence_doc != ''
                                ? responseObj_1.driving_licence_doc
                                : '',
                        };
                        setFeDrivingLicenceDetails(drivingLicenceDetails);
                        if (responseObj_1.lab_id !== null) {
                            selectedLab = (0, array_helpers_1.filterLabCodeData)({
                                filterArray: labList,
                                id: responseObj_1.lab_id,
                            });
                        }
                        console.log('selected lab:', selectedLab);
                        var feWorkingStateIds = [];
                        if (responseObj_1.working_state_id != null) {
                            feWorkingStateIds.push(responseObj_1.working_state_id);
                        }
                        else {
                            dispatch((0, config_action_1.fetch_fe_working_cities)([]));
                        }
                        var employmentDetails_1 = {
                            employee_vendor_id: responseObj_1.employee_id
                                ? responseObj_1.employee_id
                                : '',
                            fe_Type: responseObj_1.role_id !== null
                                ? (0, array_helpers_1.filterData)({
                                    filterArray: fe_types,
                                    id: responseObj_1.role_id,
                                })
                                : null,
                            dateOfJoining: responseObj_1.date_of_joining !== null
                                ? responseObj_1.date_of_joining
                                : '',
                            company: responseObj_1.company_id !== null
                                ? (0, array_helpers_1.filterData)({
                                    filterArray: company,
                                    id: responseObj_1.company_id,
                                })
                                : null,
                            tenure: responseObj_1.tenure_id !== null
                                ? (0, array_helpers_1.filterData)({
                                    filterArray: tenure,
                                    id: responseObj_1.tenure_id,
                                })
                                : null,
                            labCode: selectedLab ? (0, CC_helper_1.convertEditDataForLabCode)(selectedLab.value, converteLabList) : null,
                            labName: selectedLab ? selectedLab.center_name : '',
                            regionalManager: responseObj_1.regional_manager1 !== null
                                ? responseObj_1.regional_manager1
                                : '',
                            fe_working_state: null,
                            fe_working_city: null,
                            zone: responseObj_1.zone_id !== null
                                ? (0, array_helpers_1.filterData)({
                                    filterArray: zoneTypeList,
                                    id: responseObj_1.zone_id,
                                })
                                : null,
                            baseLocation: responseObj_1.base_latitude != null &&
                                responseObj_1.base_longitude != null
                                ? "".concat(responseObj_1.base_latitude, ",").concat(responseObj_1.base_longitude)
                                : '',
                            base_location_address: responseObj_1.base_location_address != null
                                ? responseObj_1.base_location_address
                                : '',
                            FEWorkingState: selectedLab ? selectedLab.center_state : '',
                            FEWorkingCity: selectedLab ? selectedLab.center_city : '',
                            FEVendorCode: responseObj_1.vendor_code
                                ? responseObj_1.vendor_code
                                : vendorCode,
                            vendorName: responseObj_1.vendor_name
                                ? responseObj_1.vendor_name
                                : vendorName,
                            isActiveFE: responseObj_1.onboard_status === 1 ? true : false,
                            assign_route: responseObj_1.fe_route_assignment_id !== null
                                ? (0, array_helpers_1.filterData)({
                                    filterArray: routing_assignment_type,
                                    id: responseObj_1.fe_route_assignment_id,
                                })
                                : null,
                            isSuperVisor: responseObj_1.supervisor
                                ? Boolean(responseObj_1.supervisor)
                                : false,
                            isBackUpFE: responseObj_1.backup
                                ? Boolean(responseObj_1.backup)
                                : false,
                            canEditCCCoordinates: responseObj_1.can_update_cc_coordinates
                                ? Boolean(responseObj_1.can_update_cc_coordinates)
                                : false,
                        };
                        if (feWorkingStateIds.length > 0) {
                            (0, generic_api_calls_1.fetchCitiesUnderStates)(feWorkingStateIds)
                                .then(function (cityListFromStateId) {
                                dispatch((0, config_action_1.fetch_fe_working_cities)(cityListFromStateId.filter(function (city) {
                                    return city.state_id === responseObj_1.working_state_id;
                                })));
                                (employmentDetails_1['fe_working_state'] =
                                    responseObj_1.working_state_id !== null
                                        ? (0, array_helpers_1.filterData)({
                                            filterArray: stateList,
                                            id: responseObj_1.working_state_id,
                                        })
                                        : null),
                                    (employmentDetails_1['fe_working_city'] =
                                        responseObj_1.working_city_id !== null
                                            ? (0, array_helpers_1.filterData)({
                                                filterArray: cityListFromStateId,
                                                id: responseObj_1.working_city_id,
                                            })
                                            : null),
                                    setFeEmploymentDetails(employmentDetails_1);
                            })
                                .catch(function (err) {
                                console.log(';Unable to process your request');
                            });
                        }
                        else {
                            setFeEmploymentDetails(employmentDetails_1);
                        }
                        if (selectedLab) {
                            (0, generic_api_calls_1.fetchHubList)({
                                lab_code: selectedLab.value,
                            })
                                .then(function (res) {
                                if (res.data.statusCode === 200) {
                                    var hubListRes = (0, array_helpers_1.convertHubListFromRes)(res.data.data);
                                    console.log('HUbListRes', hubListRes);
                                    dispatch((0, config_action_1.fetch_HubIDList)(hubListRes));
                                    employmentDetails_1['HudId'] =
                                        responseObj_1.hub_id !== null
                                            ? (0, array_helpers_1.filterData)({
                                                filterArray: hubListRes,
                                                id: responseObj_1.hub_id,
                                            })
                                            : null;
                                    setFeEmploymentDetails(employmentDetails_1);
                                }
                                else {
                                    dispatch((0, config_action_1.fetch_HubIDList)([]));
                                }
                            })
                                .catch(function (e) { return dispatch((0, config_action_1.fetch_HubIDList)([])); });
                        }
                        else {
                            setFeEmploymentDetails(employmentDetails_1);
                        }
                        setVendorcode(responseObj_1.axapta_code ? responseObj_1.axapta_code : vendorCode);
                        if (responseObj_1.inventory.length > 0) {
                            var selectedInventoryArr_1 = [];
                            responseObj_1.inventory.forEach(function (item) {
                                selectedInventoryArr_1.push(item.inventory_id);
                            });
                            var inventoryDetailsObj = {
                                selectedInventoryList: selectedInventoryArr_1,
                            };
                            console.log('inventoryDetailsObj:', inventoryDetailsObj);
                            setInventoryDetails(inventoryDetailsObj);
                        }
                    }
                })
                    .catch(function (err) {
                    react_hot_toast_1.default.error(err);
                });
            }
        }
    }, [editUserId, activeTabId]);
    React.useEffect(function () {
        console.log('replace employee');
        if (replaceFeIdFromApi) {
            dispatch((0, FE_Creation_action_1.updateReplaceId)({
                replace_id: parseInt(replaceFeIdFromApi),
            }));
            api_service_1.api_service
                .get({
                endPoint: "".concat(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, "?user_id=").concat(replaceFeIdFromApi ? replaceFeIdFromApi : ''),
                domain: process.env.VENDOR_BASE_URL,
                showLoader: false,
            })
                .then(function (res) {
                var selectedLab = null;
                if (res.data.statusCode === 200) {
                    console.log('replaceeee', res);
                    var responseObj_2 = res.data.data;
                    setOnBoardingStatus(responseObj_2.onboard_status);
                    console.log(responseObj_2, 'responbvcgcfgccseObj');
                    if (responseObj_2.lab_id !== null) {
                        selectedLab = (0, array_helpers_1.filterLabCodeData)({
                            filterArray: labList,
                            id: responseObj_2.lab_id,
                        });
                    }
                    console.log('selected lab:', selectedLab);
                    if (selectedLab) {
                        (0, generic_api_calls_1.fetchHubList)({
                            lab_code: selectedLab.value,
                        })
                            .then(function (res) {
                            if (res.data.statusCode === 200) {
                                var hubListRes = (0, array_helpers_1.convertHubListFromRes)(res.data.data);
                                console.log('HUbListRes', hubListRes);
                                dispatch((0, config_action_1.fetch_HubIDList)(hubListRes));
                            }
                            else {
                                dispatch((0, config_action_1.fetch_HubIDList)([]));
                            }
                        })
                            .catch(function (e) { return dispatch((0, config_action_1.fetch_HubIDList)([])); });
                    }
                    var feWorkingStateIds = [];
                    if (responseObj_2.working_state_id != null) {
                        feWorkingStateIds.push(responseObj_2.working_state_id);
                    }
                    else {
                        dispatch((0, config_action_1.fetch_fe_working_cities)([]));
                    }
                    var employmentDetails_2 = {
                        employee_vendor_id: responseObj_2.employee_vendor_id,
                        fe_Type: responseObj_2.role_id !== null
                            ? (0, array_helpers_1.filterData)({
                                filterArray: fe_types,
                                id: responseObj_2.role_id,
                            })
                            : null,
                        dateOfJoining: responseObj_2.date_of_joining !== null
                            ? responseObj_2.date_of_joining
                            : '',
                        company: responseObj_2.company_id !== null
                            ? (0, array_helpers_1.filterData)({
                                filterArray: company,
                                id: responseObj_2.company_id,
                            })
                            : null,
                        tenure: responseObj_2.tenure_id !== null
                            ? (0, array_helpers_1.filterData)({
                                filterArray: tenure,
                                id: responseObj_2.tenure_id,
                            })
                            : null,
                        labCode: selectedLab ? selectedLab : null,
                        labName: selectedLab ? selectedLab.center_name : '',
                        HudId: responseObj_2.hub_id != null
                            ? (0, array_helpers_1.filterData)({
                                filterArray: HudIdList,
                                id: responseObj_2.hub_id,
                            })
                            : null,
                        regionalManager: responseObj_2.regional_manager1 !== null
                            ? responseObj_2.regional_manager1
                            : '',
                        fe_working_state: null,
                        fe_working_city: null,
                        zone: responseObj_2.zone_id !== null
                            ? (0, array_helpers_1.filterData)({
                                filterArray: zoneTypeList,
                                id: responseObj_2.zone_id,
                            })
                            : null,
                        baseLocation: responseObj_2.base_latitude != null &&
                            responseObj_2.base_longitude != null
                            ? "".concat(responseObj_2.base_latitude, ",").concat(responseObj_2.base_longitude)
                            : '',
                        base_location_address: responseObj_2.base_location_address != null
                            ? responseObj_2.base_location_address
                            : '',
                        FEWorkingState: selectedLab ? selectedLab.center_state : '',
                        FEWorkingCity: selectedLab ? selectedLab.center_city : '',
                        FEVendorCode: responseObj_2.vendor_code
                            ? responseObj_2.vendor_code
                            : vendorCode,
                        vendorName: responseObj_2.vendor_name
                            ? responseObj_2.vendor_name
                            : vendorName,
                        isActiveFE: responseObj_2.onboard_status === 4 ? true : false,
                        assign_route: responseObj_2.fe_route_assignment_id !== null
                            ? (0, array_helpers_1.filterData)({
                                filterArray: routing_assignment_type,
                                id: responseObj_2.fe_route_assignment_id,
                            })
                            : null,
                        isSuperVisor: responseObj_2.supervisor
                            ? Boolean(responseObj_2.supervisor)
                            : false,
                        isBackUpFE: responseObj_2.backup
                            ? Boolean(responseObj_2.backup)
                            : false,
                        canEditCCCoordinates: responseObj_2.can_update_cc_coordinates
                            ? Boolean(responseObj_2.can_update_cc_coordinates)
                            : false,
                    };
                    if (feWorkingStateIds.length > 0) {
                        (0, generic_api_calls_1.fetchCitiesUnderStates)(feWorkingStateIds)
                            .then(function (cityListFromStateId) {
                            dispatch((0, config_action_1.fetch_fe_working_cities)(cityListFromStateId.filter(function (city) {
                                return city.state_id === responseObj_2.working_state_id;
                            })));
                            (employmentDetails_2['fe_working_state'] =
                                responseObj_2.working_state_id !== null
                                    ? (0, array_helpers_1.filterData)({
                                        filterArray: stateList,
                                        id: responseObj_2.working_state_id,
                                        fieldname: ' state ',
                                    })
                                    : null),
                                (employmentDetails_2['fe_working_city'] =
                                    responseObj_2.working_city_id !== null
                                        ? (0, array_helpers_1.filterData)({
                                            filterArray: cityListFromStateId,
                                            id: responseObj_2.working_city_id,
                                        })
                                        : null),
                                setFeEmploymentDetails(employmentDetails_2);
                        })
                            .catch(function (err) {
                            console.log(';Unable to process your request');
                        });
                    }
                    else {
                        setFeEmploymentDetails(employmentDetails_2);
                    }
                    setFeEmploymentDetails(employmentDetails_2);
                }
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err);
            });
        }
    }, [replaceFeIdFromApi]);
    var handleChange = function (__event, newValue) {
        if (onBoardingStatus === 1) {
            setValue(newValue);
        }
        else {
            if (JSON.stringify(fePersonalDetails) !==
                JSON.stringify(field_exicutive_types_1.FE_PersonalDetailsInitialState)) {
                if (location.pathname.includes('edit')) {
                    navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(editUserId, "/").concat(newValue));
                    setValue(newValue);
                }
                else {
                    navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(editUserId, "/").concat(newValue));
                    setValue(newValue);
                }
            }
        }
    };
    var sendItForApproval = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, personalDetailsObj, noValueIsEmpty, contactDetailsarr, presentAdressObj_1, permanentAddressObj_1, bankAccountDetails_1, drivingLicenceObj_1, employmentDetails;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = {};
                    personalDetailsObj = {};
                    noValueIsEmpty = checkForEmptyValues();
                    if (!noValueIsEmpty) return [3, 19];
                    personalDetailsObj['first_name'] = fePersonalDetails.firstName
                        .trim()
                        .toUpperCase();
                    personalDetailsObj['last_name'] = fePersonalDetails.lastName
                        .trim()
                        .toUpperCase();
                    personalDetailsObj['dob'] = moment(fePersonalDetails.dob).format('YYYY-MM-DD');
                    personalDetailsObj['mobile'] = fePersonalDetails.mobileNumber;
                    personalDetailsObj['email'] = fePersonalDetails.email
                        .trim()
                        .toUpperCase();
                    personalDetailsObj['gender'] = fePersonalDetails.gender.label;
                    if (!(typeof fePersonalDetails.uploadPhoto === 'object')) return [3, 2];
                    return [4, uploadFile(fePersonalDetails.uploadPhoto).then(function (res) {
                            if (res.data.statusCode === 200) {
                                personalDetailsObj['avatar'] = res.data.data.name;
                            }
                            else {
                                personalDetailsObj['avatar'] = fePersonalDetails.uploadPhoto;
                            }
                        })];
                case 1:
                    _a.sent();
                    return [3, 3];
                case 2:
                    personalDetailsObj['avatar'] = fePersonalDetails.uploadPhoto;
                    _a.label = 3;
                case 3:
                    personalDetailsObj['emergency_mobile'] =
                        fePersonalDetails.emergencyContactNo;
                    personalDetailsObj['reference_name1'] =
                        fePersonalDetails.referenceContactName1.trim().toUpperCase();
                    personalDetailsObj['reference_name2'] =
                        fePersonalDetails.referenceContactName2.trim().toUpperCase();
                    personalDetailsObj['reference_mobile1'] =
                        fePersonalDetails.referenceMobileNumber1;
                    personalDetailsObj['reference_mobile2'] =
                        fePersonalDetails.referenceMobileNumber2;
                    payload['personal_details'] = personalDetailsObj;
                    contactDetailsarr = [];
                    presentAdressObj_1 = {
                        address_type: 'present',
                    };
                    presentAdressObj_1['lane1'] = feContactDetails.addresslane1
                        .trim()
                        .toUpperCase();
                    presentAdressObj_1['lane2'] = feContactDetails.addresslane2
                        .trim()
                        .toUpperCase();
                    presentAdressObj_1['landmark'] = feContactDetails.landmark
                        .trim()
                        .toUpperCase();
                    presentAdressObj_1['state_id'] = feContactDetails.state.id;
                    presentAdressObj_1['city_id'] = feContactDetails.city.id;
                    presentAdressObj_1['country_id'] = feContactDetails.country.id;
                    presentAdressObj_1['pincode'] = parseInt(feContactDetails.pincode);
                    if (!(typeof feContactDetails.proofOfAddress === 'object')) return [3, 5];
                    return [4, uploadFile(feContactDetails.proofOfAddress).then(function (res) {
                            if (res.data.statusCode === 200) {
                                presentAdressObj_1['proof_of_doc'] = res.data.data.name;
                                presentAdressObj_1['proof_of_doc_type'] =
                                    feContactDetails.addressProofType.label;
                            }
                            else {
                                presentAdressObj_1['proof_of_doc'] = feContactDetails.proofOfAddress;
                                presentAdressObj_1['proof_of_doc_type'] =
                                    feContactDetails.addressProofType.label;
                            }
                        })];
                case 4:
                    _a.sent();
                    return [3, 6];
                case 5:
                    presentAdressObj_1['proof_of_doc'] = feContactDetails.proofOfAddress;
                    presentAdressObj_1['proof_of_doc_type'] =
                        feContactDetails.addressProofType.label;
                    _a.label = 6;
                case 6:
                    contactDetailsarr.push(presentAdressObj_1);
                    permanentAddressObj_1 = {
                        address_type: 'permanent',
                    };
                    permanentAddressObj_1['lane1'] = feContactDetails.permenentAddresslane1
                        .trim()
                        .toUpperCase();
                    if (!(feContactDetails.permenentAddresslane1 !== '')) return [3, 19];
                    permanentAddressObj_1['lane2'] = feContactDetails.permenentAddresslane1
                        .trim()
                        .toUpperCase();
                    permanentAddressObj_1['landmark'] = feContactDetails.permenentLandmark
                        .trim()
                        .toUpperCase();
                    permanentAddressObj_1['state_id'] = feContactDetails.permenentState.id;
                    permanentAddressObj_1['city_id'] = feContactDetails.permenentCity.id;
                    permanentAddressObj_1['country_id'] =
                        feContactDetails.permenentCountry.id;
                    permanentAddressObj_1['pincode'] = parseInt(feContactDetails.permenentPincode);
                    if (!(typeof feContactDetails.permenentProofOfAddress === 'object')) return [3, 8];
                    return [4, uploadFile(feContactDetails.permenentProofOfAddress).then(function (res) {
                            if (res.data.statusCode === 200) {
                                permanentAddressObj_1['proof_of_doc'] = res.data.data.name;
                                permanentAddressObj_1['proof_of_doc_type'] =
                                    feContactDetails.permanentProofOfAddressType.label;
                            }
                            else {
                                permanentAddressObj_1['proof_of_doc'] =
                                    feContactDetails.permenentProofOfAddress;
                                permanentAddressObj_1['proof_of_doc_type'] =
                                    feContactDetails.permanentProofOfAddressType.label;
                            }
                        })];
                case 7:
                    _a.sent();
                    return [3, 9];
                case 8:
                    permanentAddressObj_1['proof_of_doc'] =
                        feContactDetails.permenentProofOfAddress;
                    permanentAddressObj_1['proof_of_doc_type'] =
                        feContactDetails.permanentProofOfAddressType.label;
                    _a.label = 9;
                case 9:
                    contactDetailsarr.push(permanentAddressObj_1);
                    payload['contact_details'] = contactDetailsarr;
                    bankAccountDetails_1 = {};
                    if (!(typeof feBankAccountDetails.proofOfBankAccountDetails === 'object')) return [3, 11];
                    return [4, uploadFile(feBankAccountDetails.proofOfBankAccountDetails).then(function (res) {
                            if (res.data.statusCode === 200) {
                                bankAccountDetails_1['bank_cheque_doc'] = res.data.data.name;
                            }
                            else {
                                bankAccountDetails_1['bank_cheque_doc'] =
                                    feBankAccountDetails.proofOfBankAccountDetails;
                            }
                        })];
                case 10:
                    _a.sent();
                    return [3, 12];
                case 11:
                    bankAccountDetails_1['bank_cheque_doc'] =
                        feBankAccountDetails.proofOfBankAccountDetails;
                    _a.label = 12;
                case 12:
                    bankAccountDetails_1['bank_account'] =
                        feBankAccountDetails.AccountNumber.trim().toUpperCase();
                    bankAccountDetails_1['bank_ifsc'] =
                        feBankAccountDetails.IFSC_Code.trim().toUpperCase();
                    bankAccountDetails_1['bank_name'] =
                        feBankAccountDetails.Bank_Name.trim().toUpperCase();
                    bankAccountDetails_1['bank_branch'] =
                        feBankAccountDetails.Bank_Branch.trim().toUpperCase();
                    bankAccountDetails_1['bank_upi_id'] =
                        feBankAccountDetails.UPI_ID.trim().toUpperCase();
                    payload['bank_details'] = bankAccountDetails_1;
                    drivingLicenceObj_1 = {};
                    drivingLicenceObj_1['vehicle_type'] =
                        feDrivingLicenceDetails.vehicleType.id;
                    if (!(feDrivingLicenceDetails.vehicleType.id != 4)) return [3, 18];
                    drivingLicenceObj_1['driving_licence'] =
                        feDrivingLicenceDetails.drivingLicence.trim().toUpperCase();
                    drivingLicenceObj_1['insurance'] =
                        feDrivingLicenceDetails.insuranceNumber.trim().toUpperCase();
                    drivingLicenceObj_1['insurance_expiry'] = moment(feDrivingLicenceDetails.insuranceExpirydate).format('YYYY-MM-DD');
                    drivingLicenceObj_1['driving_licence_expiry'] = moment(feDrivingLicenceDetails.drivingLicenceExpirydate).format('YYYY-MM-DD');
                    if (!(typeof feDrivingLicenceDetails.uploadInsuranceDoc === 'object')) return [3, 14];
                    return [4, uploadFile(feDrivingLicenceDetails.uploadInsuranceDoc).then(function (res) {
                            if (res.data.statusCode === 200) {
                                drivingLicenceObj_1['insurance_photo'] = res.data.data.name;
                            }
                            else {
                                drivingLicenceObj_1['insurance_photo'] =
                                    feDrivingLicenceDetails.uploadInsuranceDoc;
                            }
                        })];
                case 13:
                    _a.sent();
                    return [3, 15];
                case 14:
                    drivingLicenceObj_1['insurance_photo'] =
                        feDrivingLicenceDetails.uploadInsuranceDoc;
                    _a.label = 15;
                case 15:
                    if (!(typeof feDrivingLicenceDetails.proofOfDrivingLicence === 'object')) return [3, 17];
                    return [4, uploadFile(feDrivingLicenceDetails.proofOfDrivingLicence).then(function (res) {
                            if (res.data.statusCode === 200) {
                                drivingLicenceObj_1['driving_licence_doc'] = res.data.data.name;
                            }
                            else {
                                drivingLicenceObj_1['driving_licence_doc'] =
                                    feDrivingLicenceDetails.proofOfDrivingLicence;
                            }
                        })];
                case 16:
                    _a.sent();
                    return [3, 18];
                case 17:
                    drivingLicenceObj_1['driving_licence_doc'] =
                        feDrivingLicenceDetails.proofOfDrivingLicence;
                    _a.label = 18;
                case 18:
                    payload['driving_licence_details'] = drivingLicenceObj_1;
                    employmentDetails = {};
                    employmentDetails['role_id'] = feEmploymentDetails.fe_Type.id;
                    employmentDetails['date_of_joining'] = moment(feEmploymentDetails.dateOfJoining).format('YYYY-MM-DD');
                    employmentDetails['company_id'] = feEmploymentDetails.company.id;
                    employmentDetails['lab_id'] = feEmploymentDetails.labCode.value;
                    employmentDetails['tenure_id'] = feEmploymentDetails.tenure.id;
                    employmentDetails['base_latitude'] = feEmploymentDetails.baseLocation
                        .split(',')[0]
                        .toString();
                    employmentDetails['base_longitude'] = feEmploymentDetails.baseLocation
                        .split(',')[1]
                        .toString();
                    employmentDetails['base_location_address'] =
                        feEmploymentDetails.base_location_address;
                    employmentDetails['zone_id'] = feEmploymentDetails.zone.id;
                    employmentDetails['working_state_id'] = feEmploymentDetails.fe_working_state.id;
                    employmentDetails['working_city_id'] = feEmploymentDetails.fe_working_city.id;
                    if (feEmploymentDetails.HudId) {
                        employmentDetails['hub_id'] = feEmploymentDetails.HudId.value;
                    }
                    if (feEmploymentDetails.employee_vendor_id) {
                        employmentDetails['employee_id'] =
                            feEmploymentDetails.employee_vendor_id;
                    }
                    employmentDetails['fe_route_assignment_type'] =
                        feEmploymentDetails.assign_route.id;
                    employmentDetails['supervisor'] = feEmploymentDetails.isSuperVisor;
                    employmentDetails['backup'] = feEmploymentDetails.isBackUpFE;
                    employmentDetails['can_update_cc_coordinates'] =
                        feEmploymentDetails.canEditCCCoordinates;
                    if (replaceFeIdFromApi) {
                        payload['employment_details'] = employmentDetails;
                        payload['is_final_submit'] = true;
                        payload['user_id'] = user_id;
                        payload['is_replacement'] = true;
                        payload['replacement_fe_id'] = parseInt(replaceFeIdFromApi);
                    }
                    else {
                        payload['employment_details'] = employmentDetails;
                        payload['is_final_submit'] = true;
                        payload['user_id'] = user_id;
                    }
                    api_service_1.api_service
                        .post({
                        endPoint: api_service_1.api_service.api_urls.FE_CREATION,
                        payLoad: payload,
                        domain: process.env.VENDOR_BASE_URL,
                    })
                        .then(function (res) {
                        if (res.data.statusCode === 200) {
                            if (onBoardingStatus === 1) {
                                react_hot_toast_1.default.success('Details are updated successfully');
                                return false;
                            }
                            else {
                                react_hot_toast_1.default.success('Successfully sent for approval');
                                navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.feApprovalPending));
                            }
                        }
                        else {
                            react_hot_toast_1.default.error(res.data.message);
                        }
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.data.errors);
                    });
                    _a.label = 19;
                case 19: return [2];
            }
        });
    }); };
    var DeleteDraftedFEHandler = function () {
        console.log('DeleteDraftedFEHandler called');
        var payload = {
            user_id: user_id,
        };
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.DELETE_DRAFTED_FE,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                dispatch((0, FE_Creation_action_1.updateUserID)({
                    user_id: null,
                }));
                setFePersonalDetails(__assign({}, field_exicutive_types_1.FE_PersonalDetailsInitialState));
                setFeContactDetails(__assign({}, field_exicutive_types_1.FE_ContactDetailsInitialState));
                setFeBankAccountDetails(__assign({}, field_exicutive_types_1.FE_BankAccountDetailsInitialState));
                setFeDrivingLicenceDetails(__assign({}, field_exicutive_types_1.FE_DrivingLicenceDetailsInitialState));
                setFeEmploymentDetails(__assign({}, field_exicutive_types_1.FE_EmploymentDetailsInitialState));
                setInventoryDetails(__assign({}, field_exicutive_types_1.FE_InventoryDetailsInitialState));
                react_hot_toast_1.default.success(res.data.message);
                setValue(0);
                setEnableDeleteDraft(false);
                navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.activeList), {
                    replace: true,
                });
            }
            else {
                react_hot_toast_1.default.error(res.data.errors);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.message);
        });
    };
    React.useEffect(function () {
        console.log('onBoardingStatus:', onBoardingStatus, field_exicutive_types_1.FE_EmploymentDetailsInitialState);
        if (onBoardingStatus !== 1 && onBoardingStatus !== null) {
            setEnableSendItForApproval(true);
        }
    }, [onBoardingStatus]);
    var checkPersonalDetailsValidation = function () {
        if (fePersonalDetails.firstName === '') {
            return false;
        }
        if (fePersonalDetails.lastName === '') {
            return false;
        }
        if (fePersonalDetails.dob === '' ||
            fePersonalDetails.dob === null ||
            fePersonalDetails.dob === undefined) {
            return false;
        }
        if (fePersonalDetails.mobileNumber === '') {
            return false;
        }
        if (fePersonalDetails.email === '') {
            return false;
        }
        if (fePersonalDetails.emergencyContactNo === '') {
            return false;
        }
        if (fePersonalDetails.gender == null ||
            fePersonalDetails.gender === undefined) {
            return false;
        }
        if (fePersonalDetails.referenceContactName1 === '') {
            return false;
        }
        if (fePersonalDetails.referenceContactName2 === '') {
            return false;
        }
        if (fePersonalDetails.referenceMobileNumber1 === '') {
            return false;
        }
        if (fePersonalDetails.referenceMobileNumber2 === '') {
            return false;
        }
        if (fePersonalDetails.uploadPhoto === '') {
            return false;
        }
        return true;
    };
    var checkContactDetailsValidation = function () {
        if (feContactDetails.addresslane1 === '') {
            return false;
        }
        if (feContactDetails.addresslane2 === '') {
            return false;
        }
        if (feContactDetails.landmark === '') {
            return false;
        }
        if (feContactDetails.state === null) {
            return false;
        }
        if (feContactDetails.city === null) {
            return false;
        }
        if (feContactDetails.country === null) {
            return false;
        }
        if (feContactDetails.pincode === '') {
            return false;
        }
        if (feContactDetails.proofOfAddress === '') {
            return false;
        }
        if (feContactDetails.addressProofType === null) {
            return false;
        }
        if (feContactDetails.permenentAddresslane1 === '') {
            return false;
        }
        if (feContactDetails.permenentAddresslane2 === '') {
            return false;
        }
        if (feContactDetails.permenentLandmark === '') {
            return false;
        }
        if (feContactDetails.permenentState === null) {
            return false;
        }
        if (feContactDetails.permenentCity === null) {
            return false;
        }
        if (feContactDetails.permenentCountry === null) {
            return false;
        }
        if (feContactDetails.permenentPincode === '') {
            return false;
        }
        if (feContactDetails.permenentProofOfAddress === '') {
            return false;
        }
        if (feContactDetails.permanentProofOfAddressType === null) {
            return false;
        }
        return true;
    };
    var checkBankDetailsValidation = function () {
        if (feBankAccountDetails.AccountNumber === '') {
            return false;
        }
        if (feBankAccountDetails.IFSC_Code === '') {
            return false;
        }
        if (feBankAccountDetails.Bank_Name === '') {
            return false;
        }
        if (feBankAccountDetails.Bank_Branch === '') {
            return false;
        }
        if (feBankAccountDetails.UPI_ID === '') {
            return false;
        }
        if (feBankAccountDetails.proofOfBankAccountDetails === '') {
            return false;
        }
        return true;
    };
    var checkDrivingLicenceDetailsValidation = function () {
        if (feDrivingLicenceDetails.vehicleType === null) {
            return false;
        }
        else if (feDrivingLicenceDetails.vehicleType.id === 4) {
            return true;
        }
        if (feDrivingLicenceDetails.drivingLicence === '') {
            return false;
        }
        if (feDrivingLicenceDetails.drivingLicenceExpirydate === '' ||
            feDrivingLicenceDetails.drivingLicenceExpirydate === null ||
            feDrivingLicenceDetails.drivingLicenceExpirydate === undefined) {
            return false;
        }
        if (feDrivingLicenceDetails.insuranceNumber === '') {
            return false;
        }
        if (feDrivingLicenceDetails.uploadInsuranceDoc === '') {
            return false;
        }
        if (feDrivingLicenceDetails.insuranceExpirydate === '' ||
            feDrivingLicenceDetails.insuranceExpirydate === null ||
            feDrivingLicenceDetails.insuranceExpirydate === undefined) {
            return false;
        }
        if (feDrivingLicenceDetails.uploadInsuranceDoc === '') {
            return false;
        }
        if (feDrivingLicenceDetails.proofOfDrivingLicence === '') {
            return false;
        }
        return true;
    };
    var checkEmploymentDetailsValidation = function () {
        if (feEmploymentDetails.fe_Type === null) {
            return false;
        }
        if (feEmploymentDetails.dateOfJoining === null ||
            feEmploymentDetails.dateOfJoining === '') {
            return false;
        }
        if (feEmploymentDetails.company === null) {
            return false;
        }
        if (feEmploymentDetails.labCode === null) {
            return false;
        }
        if (feEmploymentDetails.fe_working_state === null) {
            return false;
        }
        if (feEmploymentDetails.fe_working_city === null) {
            return false;
        }
        if (feEmploymentDetails.tenure === null) {
            return false;
        }
        if (feEmploymentDetails.zone === null) {
            return false;
        }
        if (feEmploymentDetails.baseLocation === null ||
            feEmploymentDetails.baseLocation === '') {
            return false;
        }
        if (feEmploymentDetails.base_location_address === null ||
            feEmploymentDetails.base_location_address === '') {
            return false;
        }
        if (feEmploymentDetails.assign_route === null) {
            return false;
        }
        return true;
    };
    var compareFeEmploymentDetailsWithInitialState = function () {
        if (feEmploymentDetails.fe_Type ===
            field_exicutive_types_1.FE_EmploymentDetailsInitialState.fe_Type &&
            feEmploymentDetails.dateOfJoining === feEmploymentDetails.dateOfJoining &&
            feEmploymentDetails.company ===
                field_exicutive_types_1.FE_EmploymentDetailsInitialState.company &&
            feEmploymentDetails.tenure === field_exicutive_types_1.FE_EmploymentDetailsInitialState.tenure &&
            feEmploymentDetails.labCode ===
                field_exicutive_types_1.FE_EmploymentDetailsInitialState.labCode &&
            feEmploymentDetails.zone === field_exicutive_types_1.FE_EmploymentDetailsInitialState.zone &&
            feEmploymentDetails.fe_working_state === field_exicutive_types_1.FE_EmploymentDetailsInitialState.fe_working_state &&
            feEmploymentDetails.fe_working_city === field_exicutive_types_1.FE_EmploymentDetailsInitialState.fe_working_city &&
            feEmploymentDetails.baseLocation ===
                field_exicutive_types_1.FE_EmploymentDetailsInitialState.baseLocation &&
            feEmploymentDetails.base_location_address ===
                field_exicutive_types_1.FE_EmploymentDetailsInitialState.base_location_address &&
            feEmploymentDetails.assign_route ===
                field_exicutive_types_1.FE_EmploymentDetailsInitialState.assign_route) {
            return false;
        }
        return true;
    };
    var checkForEmptyValues = function () {
        if (!fePersonalDetails.firstName) {
            react_hot_toast_1.default.error('First Name in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.lastName) {
            react_hot_toast_1.default.error('Last Name in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.dob) {
            react_hot_toast_1.default.error('Date of Birth in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.mobileNumber) {
            react_hot_toast_1.default.error('Mobile Number in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.email) {
            react_hot_toast_1.default.error('Email in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.emergencyContactNo) {
            react_hot_toast_1.default.error('Emergency Contact Number in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.gender) {
            react_hot_toast_1.default.error('Gender in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.referenceContactName1) {
            react_hot_toast_1.default.error('Reference Contact Name1 in  Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.referenceContactName2) {
            react_hot_toast_1.default.error('Reference Contact Name2 in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.referenceMobileNumber1) {
            react_hot_toast_1.default.error('Reference MobileNumber1 in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.referenceMobileNumber2) {
            react_hot_toast_1.default.error('Reference MobileNumber2 in Personal Details section is  Invalid');
            return false;
        }
        if (!fePersonalDetails.uploadPhoto) {
            react_hot_toast_1.default.error('Profile Photo document is Invalid');
            return false;
        }
        if (!feContactDetails.addresslane1) {
            react_hot_toast_1.default.error('Address Lane1 in  Current address of Contact Details  section is  Invalid');
            return false;
        }
        if (!feContactDetails.addresslane2) {
            react_hot_toast_1.default.error('Address Lane2 in  Current address of Contact Details  is  Invalid');
            return false;
        }
        if (!feContactDetails.landmark) {
            react_hot_toast_1.default.error('Landmark in  Current address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.state) {
            react_hot_toast_1.default.error('state in   Current address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.city) {
            react_hot_toast_1.default.error('city  in   Current address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.country) {
            react_hot_toast_1.default.error('Country in   Current address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.addressProofType &&
            !feContactDetails.proofOfAddress) {
            react_hot_toast_1.default.error('Present Address Proof is Invalid');
            return false;
        }
        if (!feContactDetails.pincode) {
            react_hot_toast_1.default.error('pincode  in   Current address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.permenentAddresslane1) {
            react_hot_toast_1.default.error('Address Lane1 in  permanent address of Contact Details  section is  Invalid');
            return false;
        }
        if (!feContactDetails.permenentAddresslane2) {
            react_hot_toast_1.default.error('Address Lane2 in  permanent address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.permenentLandmark) {
            react_hot_toast_1.default.error('Landmark in  permanent address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.permenentState) {
            react_hot_toast_1.default.error('state in  permanent address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.permenentCity) {
            react_hot_toast_1.default.error('city  in  permanent address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.permenentCountry) {
            react_hot_toast_1.default.error('Country  in  permanent address of Contact Details section is  Invalid');
            return false;
        }
        if (!feContactDetails.permanentProofOfAddressType &&
            !feContactDetails.permenentProofOfAddress) {
            react_hot_toast_1.default.error('Permanent Address Proof is Invalid');
            return false;
        }
        if (!feContactDetails.permenentPincode) {
            react_hot_toast_1.default.error('pincode in  permanent address of Contact Details section is  Invalid');
            return false;
        }
        if (!feBankAccountDetails.AccountNumber) {
            react_hot_toast_1.default.error('Account Number in BankAccount   Details section is  Invalid');
            return false;
        }
        if (!feBankAccountDetails.IFSC_Code) {
            react_hot_toast_1.default.error('IFSC code in BankAccount   Details section is  Invalid');
            return false;
        }
        if (!feBankAccountDetails.Bank_Name) {
            react_hot_toast_1.default.error('Bank Name in BankAccount   Details section is  Invalid');
            return false;
        }
        if (!feBankAccountDetails.Bank_Branch) {
            react_hot_toast_1.default.error('Bank Branch in BankAccount   Details section is  Invalid');
            return false;
        }
        if (!feBankAccountDetails.UPI_ID) {
            react_hot_toast_1.default.error('UPI ID in BankAccount   Details section is  Invalid');
            return false;
        }
        if (!feBankAccountDetails.proofOfBankAccountDetails) {
            react_hot_toast_1.default.error('Bank Account Details Document  is Invalid');
            return false;
        }
        if (!feDrivingLicenceDetails.vehicleType) {
            react_hot_toast_1.default.error('Vehicle Type in Driving Liecence details  is Invalid');
            return false;
        }
        else if (feDrivingLicenceDetails.vehicleType.id != 4) {
            if (!feDrivingLicenceDetails.drivingLicence) {
                react_hot_toast_1.default.error('drivingLicence in  Driving Licence  Details section is  Invalid');
                return false;
            }
            if (!feDrivingLicenceDetails.insuranceNumber) {
                react_hot_toast_1.default.error('Insurance Number in  Driving Licence  Details section is  Invalid');
                return false;
            }
            if (!feDrivingLicenceDetails.insuranceExpirydate) {
                react_hot_toast_1.default.error('Insurance Experience date in  Driving Licence  Details section is  Invalid');
                return false;
            }
            if (!feDrivingLicenceDetails.drivingLicenceExpirydate) {
                react_hot_toast_1.default.error('Driving Licence expiry date in  Driving Licence  Details section is  Invalid');
                return false;
            }
            if (!feDrivingLicenceDetails.uploadInsuranceDoc) {
                react_hot_toast_1.default.error('Insurance document is  Invalid');
                return false;
            }
            if (!feDrivingLicenceDetails.proofOfDrivingLicence) {
                react_hot_toast_1.default.error('Proof of Driving Licence is  Invalid');
                return false;
            }
        }
        if (!feEmploymentDetails.fe_Type) {
            react_hot_toast_1.default.error('FE Type in Employment   Details section is  Invalid');
            return false;
        }
        if (!feEmploymentDetails.dateOfJoining) {
            react_hot_toast_1.default.error('Please select date of joining');
            return false;
        }
        if (!feEmploymentDetails.company) {
            react_hot_toast_1.default.error('Company in Employment   Details section is  Invalid');
            return false;
        }
        if (!feEmploymentDetails.tenure) {
            react_hot_toast_1.default.error('Tenure  in Employment   Details section is  Invalid');
            return false;
        }
        if (!feEmploymentDetails.labCode) {
            react_hot_toast_1.default.error('Labcode  in Employment   Details section is  Invalid');
            return false;
        }
        if (!feEmploymentDetails.zone) {
            react_hot_toast_1.default.error('Zone   in Employment   Details section is  Invalid');
            return false;
        }
        if (!feEmploymentDetails.base_location_address) {
            react_hot_toast_1.default.error('Base Location Address in Employment  Details section is  Invalid');
            return false;
        }
        if (!feEmploymentDetails.baseLocation) {
            react_hot_toast_1.default.error('Base Location in Employment  Details section is  Invalid');
            return false;
        }
        if (!feEmploymentDetails.assign_route) {
            react_hot_toast_1.default.error('Assign Route in Employment   Details section is  Invalid');
            return false;
        }
        if (!feEmploymentDetails.fe_working_state) {
            react_hot_toast_1.default.error('FE working state in Employment   Details section is  Invalid');
            return false;
        }
        if (!feEmploymentDetails.fe_working_city) {
            react_hot_toast_1.default.error('FE working city in Employment   Details section is  Invalid');
            return false;
        }
        return true;
    };
    var navigationHandler = function () {
        if (onBoardingStatus === 1) {
            navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.feDetails, "/").concat(editUserId, "/").concat(route_path_1.default.PATHS.feProfileDetails));
        }
        else {
            navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.activeList));
        }
    };
    console.log(field_exicutive_types_1.FE_PersonalDetailsInitialState, 'Vendor_Personal_DetailsInitialState', fePersonalDetails, 'VendorProfileDetails');
    var sendItForApprovalForActiveFE = function () { return __awaiter(void 0, void 0, void 0, function () {
        var personalDetails, contactDetailsarr, bankDetails, drivingLicenceDetails, employmentDetails, payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    personalDetails = {};
                    contactDetailsarr = [];
                    bankDetails = {};
                    drivingLicenceDetails = {};
                    employmentDetails = {};
                    payload = {};
                    return [4, api_service_1.api_service
                            .get({
                            endPoint: "".concat(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, "?user_id=").concat(editUserId),
                            domain: process.env.VENDOR_BASE_URL,
                            showLoader: false,
                        })
                            .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                            var responseObj, noValueIsEmpty, permanentAddressObj_2, presentAddressObj_1, currentDetailsObj, permenentAddressDetails;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(res.data.statusCode === 200)) return [3, 22];
                                        responseObj = res.data.data;
                                        noValueIsEmpty = checkForEmptyValues();
                                        if (!noValueIsEmpty) return [3, 22];
                                        if (responseObj.first_name != fePersonalDetails.firstName) {
                                            personalDetails['first_name'] = fePersonalDetails.firstName;
                                        }
                                        if (responseObj.last_name != fePersonalDetails.lastName) {
                                            personalDetails['last_name'] = fePersonalDetails.lastName;
                                        }
                                        if (moment(fePersonalDetails.dob).format('YYYY-MM-DD') !=
                                            moment(responseObj.dob).format('YYYY-MM-DD')) {
                                            personalDetails['dob'] = moment(fePersonalDetails.dob).format('YYYY-MM-DD');
                                        }
                                        if (responseObj.mobile != fePersonalDetails.mobileNumber) {
                                            personalDetails['mobile'] = fePersonalDetails.mobileNumber;
                                        }
                                        if (responseObj.email != fePersonalDetails.email) {
                                            personalDetails['email'] = fePersonalDetails.email;
                                        }
                                        if (responseObj.emergency_mobile !=
                                            fePersonalDetails.emergencyContactNo) {
                                            personalDetails['emergency_mobile'] =
                                                fePersonalDetails.emergencyContactNo;
                                        }
                                        if (responseObj.gender != fePersonalDetails.gender.label) {
                                            personalDetails['gender'] = fePersonalDetails.gender.label;
                                        }
                                        if (responseObj.reference_name1 !=
                                            fePersonalDetails.referenceContactName1) {
                                            personalDetails['reference_name1'] =
                                                fePersonalDetails.referenceContactName1;
                                        }
                                        if (responseObj.reference_name2 !=
                                            fePersonalDetails.referenceContactName2) {
                                            personalDetails['reference_name2'] =
                                                fePersonalDetails.referenceContactName2;
                                        }
                                        if (responseObj.reference_mobile1 !=
                                            fePersonalDetails.referenceMobileNumber1) {
                                            personalDetails['reference_mobile1'] =
                                                fePersonalDetails.referenceMobileNumber1;
                                        }
                                        if (responseObj.reference_mobile2 !=
                                            fePersonalDetails.referenceMobileNumber2) {
                                            personalDetails['reference_mobile2'] =
                                                fePersonalDetails.referenceMobileNumber2;
                                        }
                                        if (!(typeof fePersonalDetails.uploadPhoto === 'object')) return [3, 2];
                                        return [4, uploadFile(fePersonalDetails.uploadPhoto).then(function (res) {
                                                if (res.data.statusCode === 200) {
                                                    personalDetails['avatar'] = res.data.data.name;
                                                }
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [3, 3];
                                    case 2:
                                        if (fePersonalDetails.uploadPhoto != responseObj.avatar) {
                                            personalDetails['avatar'] = fePersonalDetails.uploadPhoto;
                                        }
                                        _a.label = 3;
                                    case 3:
                                        permanentAddressObj_2 = {
                                            address_type: 'permanent',
                                        };
                                        presentAddressObj_1 = {
                                            address_type: 'present',
                                        };
                                        if (!(responseObj.addresses.length > 0)) return [3, 10];
                                        currentDetailsObj = lodash_1.default.find(responseObj.addresses, { type: 'present' });
                                        permenentAddressDetails = lodash_1.default.find(responseObj.addresses, { type: 'permanent' });
                                        if (currentDetailsObj.lane1 != feContactDetails.addresslane1) {
                                            presentAddressObj_1['lane1'] = feContactDetails.addresslane1;
                                        }
                                        if (currentDetailsObj.lane2 != feContactDetails.addresslane2) {
                                            presentAddressObj_1['lane2'] = feContactDetails.addresslane2;
                                        }
                                        if (currentDetailsObj.landmark != feContactDetails.landmark) {
                                            presentAddressObj_1['landmark'] = feContactDetails.landmark;
                                        }
                                        if (currentDetailsObj.state_id != feContactDetails.state.id) {
                                            presentAddressObj_1['state_id'] = feContactDetails.state.id;
                                        }
                                        if (currentDetailsObj.city_id != feContactDetails.city.id) {
                                            presentAddressObj_1['city_id'] = feContactDetails.city.id;
                                        }
                                        if (currentDetailsObj.country_id != feContactDetails.country.id) {
                                            presentAddressObj_1['country_id'] = feContactDetails.country.id;
                                        }
                                        if (!(typeof feContactDetails.proofOfAddress === 'object')) return [3, 5];
                                        return [4, uploadFile(feContactDetails.proofOfAddress).then(function (res) {
                                                if (res.data.statusCode === 200) {
                                                    presentAddressObj_1['proof_of_doc_type'] =
                                                        feContactDetails.addressProofType.label;
                                                    presentAddressObj_1['proof_of_doc'] = res.data.data.name;
                                                }
                                            })];
                                    case 4:
                                        _a.sent();
                                        return [3, 6];
                                    case 5:
                                        if (feContactDetails.addressProofType.label !=
                                            currentDetailsObj.proof_of_doc_type ||
                                            feContactDetails.proofOfAddress !=
                                                currentDetailsObj.proof_of_doc) {
                                            presentAddressObj_1['proof_of_doc_type'] =
                                                feContactDetails.addressProofType.label;
                                            presentAddressObj_1['proof_of_doc'] =
                                                feContactDetails.proofOfAddress;
                                        }
                                        _a.label = 6;
                                    case 6:
                                        if (currentDetailsObj.pincode != feContactDetails.pincode) {
                                            presentAddressObj_1['pincode'] = parseInt(feContactDetails.pincode);
                                        }
                                        presentAddressObj_1['id'] = currentDetailsObj.id;
                                        if (Object.keys(presentAddressObj_1).length > 2) {
                                            contactDetailsarr.push(presentAddressObj_1);
                                        }
                                        if (permenentAddressDetails.lane1 !=
                                            feContactDetails.permenentAddresslane1) {
                                            permanentAddressObj_2['lane1'] =
                                                feContactDetails.permenentAddresslane1;
                                        }
                                        if (permenentAddressDetails.lane2 !=
                                            feContactDetails.permenentAddresslane2) {
                                            permanentAddressObj_2['lane2'] =
                                                feContactDetails.permenentAddresslane2;
                                        }
                                        if (permenentAddressDetails.landmark !=
                                            feContactDetails.permenentLandmark) {
                                            permanentAddressObj_2['landmark'] =
                                                feContactDetails.permenentLandmark;
                                        }
                                        if (permenentAddressDetails.state_id !=
                                            feContactDetails.permenentState.id) {
                                            permanentAddressObj_2['state_id'] =
                                                feContactDetails.permenentState.id;
                                        }
                                        if (permenentAddressDetails.city_id !=
                                            feContactDetails.permenentCity.id) {
                                            permanentAddressObj_2['city_id'] =
                                                feContactDetails.permenentCity.id;
                                        }
                                        if (permenentAddressDetails.country_id !=
                                            feContactDetails.permenentCountry.id) {
                                            permanentAddressObj_2['country_id'] =
                                                feContactDetails.permenentCountry.id;
                                        }
                                        if (!(typeof feContactDetails.permenentProofOfAddress === 'object')) return [3, 8];
                                        return [4, uploadFile(feContactDetails.permenentProofOfAddress).then(function (res) {
                                                if (res.data.statusCode === 200) {
                                                    permanentAddressObj_2['proof_of_doc_type'] =
                                                        feContactDetails.permanentProofOfAddressType.label;
                                                    permanentAddressObj_2['proof_of_doc'] = res.data.data.name;
                                                }
                                            })];
                                    case 7:
                                        _a.sent();
                                        return [3, 9];
                                    case 8:
                                        if (feContactDetails.permanentProofOfAddressType.label !=
                                            permenentAddressDetails.proof_of_doc_type ||
                                            feContactDetails.permenentProofOfAddress !=
                                                permenentAddressDetails.proof_of_doc) {
                                            permanentAddressObj_2['proof_of_doc_type'] =
                                                feContactDetails.permanentProofOfAddressType.label;
                                            permanentAddressObj_2['proof_of_doc'] =
                                                feContactDetails.permenentProofOfAddress;
                                        }
                                        _a.label = 9;
                                    case 9:
                                        if (permenentAddressDetails.pincode !=
                                            feContactDetails.permenentPincode) {
                                            permanentAddressObj_2['pincode'] = parseInt(feContactDetails.permenentPincode);
                                        }
                                        permanentAddressObj_2['id'] = permenentAddressDetails.id;
                                        if (Object.keys(permanentAddressObj_2).length > 2) {
                                            contactDetailsarr.push(permanentAddressObj_2);
                                        }
                                        _a.label = 10;
                                    case 10:
                                        if (responseObj.bank_account != feBankAccountDetails.AccountNumber) {
                                            bankDetails['bank_account'] = feBankAccountDetails.AccountNumber;
                                        }
                                        if (responseObj.bank_ifsc != feBankAccountDetails.IFSC_Code) {
                                            bankDetails['bank_ifsc'] = feBankAccountDetails.IFSC_Code;
                                        }
                                        if (responseObj.bank_name != feBankAccountDetails.Bank_Name) {
                                            bankDetails['bank_name'] = feBankAccountDetails.Bank_Name;
                                        }
                                        if (responseObj.bank_branch != feBankAccountDetails.Bank_Branch) {
                                            bankDetails['bank_branch'] = feBankAccountDetails.Bank_Branch;
                                        }
                                        if (responseObj.bank_upi_id != feBankAccountDetails.UPI_ID) {
                                            bankDetails['bank_upi_id'] = feBankAccountDetails.UPI_ID;
                                        }
                                        if (!(typeof feBankAccountDetails.proofOfBankAccountDetails === 'object')) return [3, 12];
                                        return [4, uploadFile(feBankAccountDetails.proofOfBankAccountDetails).then(function (res) {
                                                if (res.data.statusCode === 200) {
                                                    bankDetails['bank_cheque_doc'] = res.data.data.name;
                                                }
                                            })];
                                    case 11:
                                        _a.sent();
                                        return [3, 13];
                                    case 12:
                                        if (feBankAccountDetails.proofOfBankAccountDetails !=
                                            responseObj.bank_cheque_doc) {
                                            bankDetails['bank_cheque_doc'] =
                                                feBankAccountDetails.proofOfBankAccountDetails;
                                        }
                                        _a.label = 13;
                                    case 13:
                                        if (!(feDrivingLicenceDetails.vehicleType != null)) return [3, 21];
                                        if (!(feDrivingLicenceDetails.vehicleType.id != 4)) return [3, 20];
                                        if (responseObj.vehicle_type_id !=
                                            feDrivingLicenceDetails.vehicleType.id) {
                                            drivingLicenceDetails['vehicle_type'] =
                                                feDrivingLicenceDetails.vehicleType.id;
                                        }
                                        if (responseObj.driving_licence !=
                                            feDrivingLicenceDetails.drivingLicence) {
                                            drivingLicenceDetails['driving_licence'] =
                                                feDrivingLicenceDetails.drivingLicence;
                                            drivingLicenceDetails['vehicle_type'] =
                                                feDrivingLicenceDetails.vehicleType.id;
                                        }
                                        if (responseObj.insurance !=
                                            feDrivingLicenceDetails.insuranceNumber) {
                                            drivingLicenceDetails['insurance'] =
                                                feDrivingLicenceDetails.insuranceNumber;
                                            drivingLicenceDetails['vehicle_type'] =
                                                feDrivingLicenceDetails.vehicleType.id;
                                        }
                                        if (moment(feDrivingLicenceDetails.insuranceExpirydate).format('YYYY-MM-DD') != moment(responseObj.insurance_expiry).format('YYYY-MM-DD')) {
                                            drivingLicenceDetails['insurance_expiry'] = moment(feDrivingLicenceDetails.insuranceExpirydate).format('YYYY-MM-DD');
                                            drivingLicenceDetails['vehicle_type'] =
                                                feDrivingLicenceDetails.vehicleType.id;
                                        }
                                        if (moment(feDrivingLicenceDetails.drivingLicenceExpirydate).format('YYYY-MM-DD') !=
                                            moment(responseObj.driving_licence_expiry).format('YYYY-MM-DD')) {
                                            drivingLicenceDetails['driving_licence_expiry'] = moment(feDrivingLicenceDetails.drivingLicenceExpirydate).format('YYYY-MM-DD');
                                            drivingLicenceDetails['vehicle_type'] =
                                                feDrivingLicenceDetails.vehicleType.id;
                                        }
                                        if (!(typeof feDrivingLicenceDetails.uploadInsuranceDoc === 'object')) return [3, 15];
                                        return [4, uploadFile(feDrivingLicenceDetails.uploadInsuranceDoc).then(function (res) {
                                                if (res.data.statusCode === 200) {
                                                    drivingLicenceDetails['insurance_photo'] =
                                                        res.data.data.name;
                                                    drivingLicenceDetails['vehicle_type'] =
                                                        feDrivingLicenceDetails.vehicleType.id;
                                                }
                                            })];
                                    case 14:
                                        _a.sent();
                                        return [3, 16];
                                    case 15:
                                        if (feDrivingLicenceDetails.uploadInsuranceDoc !=
                                            responseObj.insurance_photo) {
                                            drivingLicenceDetails['insurance_photo'] =
                                                feDrivingLicenceDetails.uploadInsuranceDoc;
                                            drivingLicenceDetails['vehicle_type'] =
                                                feDrivingLicenceDetails.vehicleType.id;
                                        }
                                        _a.label = 16;
                                    case 16:
                                        if (!(typeof feDrivingLicenceDetails.proofOfDrivingLicence ===
                                            'object')) return [3, 18];
                                        return [4, uploadFile(feDrivingLicenceDetails.proofOfDrivingLicence).then(function (res) {
                                                if (res.data.statusCode === 200) {
                                                    drivingLicenceDetails['driving_licence_doc'] =
                                                        res.data.data.name;
                                                    drivingLicenceDetails['vehicle_type'] =
                                                        feDrivingLicenceDetails.vehicleType.id;
                                                }
                                            })];
                                    case 17:
                                        _a.sent();
                                        return [3, 19];
                                    case 18:
                                        if (feDrivingLicenceDetails.proofOfDrivingLicence !=
                                            responseObj.driving_licence_doc) {
                                            drivingLicenceDetails['driving_licence_doc'] =
                                                feDrivingLicenceDetails.proofOfDrivingLicence;
                                            drivingLicenceDetails['vehicle_type'] =
                                                feDrivingLicenceDetails.vehicleType.id;
                                        }
                                        _a.label = 19;
                                    case 19: return [3, 21];
                                    case 20:
                                        if (feDrivingLicenceDetails.vehicleType.label !=
                                            responseObj.vehicle_type) {
                                            drivingLicenceDetails['insurance_photo'] = '';
                                            drivingLicenceDetails['driving_licence_doc'] = '';
                                            drivingLicenceDetails['driving_licence'] = '';
                                            drivingLicenceDetails['insurance'] = '';
                                            drivingLicenceDetails['insurance_expiry'] = '';
                                            drivingLicenceDetails['driving_licence_expiry'] = '';
                                            drivingLicenceDetails['vehicle_type'] =
                                                feDrivingLicenceDetails.vehicleType.id;
                                        }
                                        _a.label = 21;
                                    case 21:
                                        if (responseObj.employee_id ||
                                            feEmploymentDetails.employee_vendor_id) {
                                            if (responseObj.employee_id !=
                                                feEmploymentDetails.employee_vendor_id) {
                                                employmentDetails['employee_id'] =
                                                    feEmploymentDetails.employee_vendor_id;
                                            }
                                        }
                                        if (responseObj.role_id != feEmploymentDetails.fe_Type.id) {
                                            employmentDetails['role_id'] = feEmploymentDetails.fe_Type.id;
                                        }
                                        if (moment(feEmploymentDetails.dateOfJoining).format('YYYY-MM-DD') !=
                                            moment(responseObj.date_of_joining).format('YYYY-MM-DD')) {
                                            employmentDetails['date_of_joining'] = moment(feEmploymentDetails.dateOfJoining).format('YYYY-MM-DD');
                                        }
                                        if (responseObj.company_id != feEmploymentDetails.company.id) {
                                            employmentDetails['company_id'] = feEmploymentDetails.company.id;
                                        }
                                        if (responseObj.tenure_id != feEmploymentDetails.tenure.id) {
                                            employmentDetails['tenure_id'] = feEmploymentDetails.tenure.id;
                                        }
                                        if (responseObj.lab_id != feEmploymentDetails.labCode.value) {
                                            employmentDetails['lab_id'] = feEmploymentDetails.labCode.value;
                                        }
                                        if (responseObj.zone_id != feEmploymentDetails.zone.id) {
                                            employmentDetails['zone_id'] = feEmploymentDetails.zone.id;
                                        }
                                        if (feEmploymentDetails.HudId) {
                                            if (responseObj.hub_id != feEmploymentDetails.HudId.value) {
                                                employmentDetails['hub_id'] = feEmploymentDetails.HudId.value;
                                            }
                                        }
                                        if (feEmploymentDetails.fe_working_state) {
                                            if (responseObj.working_state_id != feEmploymentDetails.fe_working_state.value) {
                                                employmentDetails['working_state_id'] = feEmploymentDetails.fe_working_state.value;
                                            }
                                        }
                                        if (feEmploymentDetails.fe_working_city) {
                                            if (responseObj.working_city_id != feEmploymentDetails.fe_working_city.value) {
                                                employmentDetails['working_city_id'] = feEmploymentDetails.fe_working_city.value;
                                            }
                                        }
                                        if (responseObj.base_location_address !=
                                            feEmploymentDetails.base_location_address) {
                                            employmentDetails['base_location_address'] =
                                                feEmploymentDetails.base_location_address;
                                        }
                                        if (responseObj.base_latitude !=
                                            feEmploymentDetails.baseLocation.split(',')[0].toString()) {
                                            employmentDetails['base_latitude'] =
                                                feEmploymentDetails.baseLocation.split(',')[0].toString();
                                        }
                                        if (responseObj.base_longitude !=
                                            feEmploymentDetails.baseLocation.split(',')[1].toString()) {
                                            employmentDetails['base_longitude'] =
                                                feEmploymentDetails.baseLocation.split(',')[1].toString();
                                        }
                                        if (feEmploymentDetails.isSuperVisor) {
                                            employmentDetails['supervisor'] =
                                                feEmploymentDetails.isSuperVisor;
                                        }
                                        else {
                                            employmentDetails['supervisor'] =
                                                feEmploymentDetails.isSuperVisor;
                                        }
                                        if (feEmploymentDetails.isBackUpFE) {
                                            employmentDetails['backup'] = feEmploymentDetails.isBackUpFE;
                                        }
                                        else {
                                            employmentDetails['backup'] = feEmploymentDetails.isBackUpFE;
                                        }
                                        if (feEmploymentDetails.canEditCCCoordinates) {
                                            employmentDetails['can_update_cc_coordinates'] =
                                                feEmploymentDetails.canEditCCCoordinates;
                                        }
                                        else {
                                            employmentDetails['can_update_cc_coordinates'] =
                                                feEmploymentDetails.canEditCCCoordinates;
                                        }
                                        if (feEmploymentDetails.assign_route) {
                                            if (responseObj.fe_route_assignment_type) {
                                                if (responseObj.fe_route_assignment_type.toUpperCase() !=
                                                    feEmploymentDetails.assign_route.label.toUpperCase()) {
                                                    employmentDetails['fe_route_assignment_type'] =
                                                        feEmploymentDetails.assign_route.id;
                                                }
                                            }
                                            else {
                                                employmentDetails['fe_route_assignment_type'] =
                                                    feEmploymentDetails.assign_route.id;
                                            }
                                        }
                                        if (Object.keys(personalDetails).length > 0) {
                                            payload['personal_details'] = personalDetails;
                                        }
                                        if (contactDetailsarr.length > 0) {
                                            payload['contact_details'] = contactDetailsarr;
                                        }
                                        if (Object.keys(bankDetails).length > 0) {
                                            payload['bank_details'] = bankDetails;
                                        }
                                        if (Object.keys(drivingLicenceDetails).length > 0) {
                                            payload['driving_licence_details'] = drivingLicenceDetails;
                                        }
                                        if (Object.keys(employmentDetails).length > 0) {
                                            payload['employment_details'] = employmentDetails;
                                        }
                                        api_service_1.api_service
                                            .post({
                                            endPoint: api_service_1.api_service.api_urls.ACTIVEFE_EDIT,
                                            payLoad: user_id != null && user_id != undefined
                                                ? __assign(__assign({}, payload), { user_id: user_id }) : payload,
                                            domain: process.env.VENDOR_BASE_URL,
                                        })
                                            .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                if (res.data.statusCode === 200) {
                                                    react_hot_toast_1.default.success('Successfully sent for approval');
                                                    navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.feApprovalPending));
                                                }
                                                else {
                                                    react_hot_toast_1.default.error(res.data.message);
                                                }
                                                return [2];
                                            });
                                        }); })
                                            .catch(function (err) {
                                            react_hot_toast_1.default.error(err.data.errors);
                                        });
                                        _a.label = 22;
                                    case 22: return [2];
                                }
                            });
                        }); })
                            .catch(function (err) {
                            react_hot_toast_1.default.error(err);
                        })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    return (React.createElement("div", { className: "on-boarding-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper border-bottom" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", onClick: navigationHandler, icon: 'icon-left-arrow' }),
                location.pathname.includes('edit') ? (React.createElement("h2", null, "Edit FE")) : replaceFeIdFromApi ? (React.createElement("h2", null, "Replace FE")) : (React.createElement("h2", null, "Add new FE"))),
            enableDeleteDraft && (React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Delete Draft", color: "yellow-outline", type: "button", onClickHandler: DeleteDraftedFEHandler }))),
            isActveFEEdit && (React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Send it for approval", color: "blue", isError: disableSendItForApprovalForActiveFE, onClickHandler: sendItForApprovalForActiveFE })))),
        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: { xs: 1 } },
            React.createElement(Grid_1.default, { item: true, xs: 4, lg: 3 },
                React.createElement("div", { className: "vertical-tabs-wrapper" },
                    React.createElement(Tabs_1.default, { orientation: "vertical", variant: "scrollable", value: value, onChange: handleChange, "aria-label": "Vertical tabs example" },
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "FE Personal Details" }, a11yProps(0), { className: value != 0 &&
                                JSON.stringify(fePersonalDetails) !=
                                    JSON.stringify(field_exicutive_types_1.FE_PersonalDetailsInitialState)
                                ? 'tab-btn submitted'
                                : 'tab-btn ', "aria-label": "Vertical tabs example", value: 0 })),
                        value != 0 &&
                            JSON.stringify(fePersonalDetails) !=
                                JSON.stringify(field_exicutive_types_1.FE_PersonalDetailsInitialState) ? (checkPersonalDetailsValidation() ? (React.createElement("span", { className: "form-status-icon icon-double-tick  tab-one-status" }, ' ')) : (React.createElement("span", { className: "form-status-icon icon-warning  tab-one-status" }, ' '))) : (''),
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "FE Contact Details" }, a11yProps(1), { className: value != 1 &&
                                JSON.stringify(feContactDetails) !=
                                    JSON.stringify(field_exicutive_types_1.FE_ContactDetailsInitialState)
                                ? 'tab-btn submitted'
                                : 'tab-btn', value: 1, "aria-label": "Vertical tabs example" })),
                        value != 1 &&
                            JSON.stringify(feContactDetails) !=
                                JSON.stringify(field_exicutive_types_1.FE_ContactDetailsInitialState) ? (checkContactDetailsValidation() ? (React.createElement("span", { className: "form-status-icon icon-double-tick  tab-two-status" })) : (React.createElement("span", { className: "form-status-icon icon-warning  tab-two-status" }))) : (''),
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "FE Bank Account Details" }, a11yProps(2), { className: value != 2 &&
                                JSON.stringify(feBankAccountDetails) !=
                                    JSON.stringify(field_exicutive_types_1.FE_BankAccountDetailsInitialState)
                                ? 'tab-btn submitted'
                                : 'tab-btn', value: 2 })),
                        value != 2 &&
                            JSON.stringify(feBankAccountDetails) !=
                                JSON.stringify(field_exicutive_types_1.FE_BankAccountDetailsInitialState) ? (checkBankDetailsValidation() ? (React.createElement("span", { className: "form-status-icon icon-double-tick  tab-three-status" })) : (React.createElement("span", { className: "form-status-icon icon-warning  tab-three-status" }))) : (''),
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "FE Driving Licence Details" }, a11yProps(3), { className: value != 3 &&
                                JSON.stringify(feDrivingLicenceDetails) !=
                                    JSON.stringify(field_exicutive_types_1.FE_DrivingLicenceDetailsInitialState)
                                ? 'tab-btn submitted'
                                : 'tab-btn', value: 3 })),
                        value != 3 &&
                            JSON.stringify(feDrivingLicenceDetails) !=
                                JSON.stringify(field_exicutive_types_1.FE_DrivingLicenceDetailsInitialState) ? (checkDrivingLicenceDetailsValidation() ? (React.createElement("span", { className: "form-status-icon icon-double-tick  tab-four-status" })) : (React.createElement("span", { className: "form-status-icon icon-warning  tab-four-status" }))) : (''),
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "FE Employment Details" }, a11yProps(4), { className: value != 4 && compareFeEmploymentDetailsWithInitialState()
                                ? 'tab-btn submitted'
                                : 'tab-btn', value: 4 })),
                        value != 4 && compareFeEmploymentDetailsWithInitialState() ? (checkEmploymentDetailsValidation() ? (React.createElement("span", { className: "form-status-icon  icon-double-tick tab-five-status" })) : (React.createElement("span", { className: "form-status-icon icon-warning  tab-five-status" }))) : (''),
                        onBoardingStatus === 1 && (React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "Assign Inventory" }, a11yProps(5), { className: "tab-btn submitted", value: 5 })))))),
            React.createElement(Grid_1.default, { item: true, xs: 8, lg: 9 },
                React.createElement("div", { className: "scrollable-content form-wrapper" },
                    React.createElement(TabPanel, { value: value, index: 0 }, React.createElement(FE_PersonalDetailsForm_1.default, { initialValues: replaceFeIdFromApi
                            ? feReplacementDetails
                            : fePersonalDetails, saveFormData: saveFE_PersonalDetails, updateValue: updateValue, feEmploymentDetails: feEmploymentDetails, value: value, FEIdToBeReplaced: replaceFeIdFromApi, FE_PersonalDetailsInitialState: field_exicutive_types_1.FE_PersonalDetailsInitialState, sendItForApproval: sendItForApproval, onBoardingStatus: onBoardingStatus, vendorCode: vendorcode, replaceFeIdFromApi: replaceFeIdFromApi && replaceFeIdFromApi })),
                    React.createElement(TabPanel, { value: value, index: 1 }, React.createElement(FE_ContactDetailsForm_1.default, { initialValues: feContactDetails, saveFormData: saveFE_ContactDetails, fepersonalDetails: fePersonalDetails, updateValue: updateValue, FEIdToBeReplaced: replaceFeIdFromApi, value: value, FE_ContactDetailsInitialState: field_exicutive_types_1.FE_ContactDetailsInitialState, sendItForApproval: sendItForApproval, onBoardingStatus: onBoardingStatus, vendorCode: vendorcode })),
                    React.createElement(TabPanel, { value: value, index: 2 }, React.createElement(FE_BankAccountDetailsForm_1.default, { initialValues: feBankAccountDetails, saveFormData: saveFE_BankAccountDetails, fepersonalDetails: fePersonalDetails, onBoardingStatus: onBoardingStatus, FEIdToBeReplaced: replaceFeIdFromApi, updateValue: updateValue, value: value, FE_BankAccountDetailsInitialState: field_exicutive_types_1.FE_BankAccountDetailsInitialState, sendItForApproval: sendItForApproval, vendorCode: vendorcode })),
                    React.createElement(TabPanel, { value: value, index: 3 },
                        React.createElement(FE_DrivingLiecenseDetailsForm_1.default, { initialValues: feDrivingLicenceDetails, saveFormData: saveFE_DrivingLicenceDetails, fepersonalDetails: fePersonalDetails, FEIdToBeReplaced: replaceFeIdFromApi, updateValue: updateValue, value: value, FE_DrivingLicenceDetailsInitialState: field_exicutive_types_1.FE_DrivingLicenceDetailsInitialState, sendItForApproval: sendItForApproval, onBoardingStatus: onBoardingStatus, vendorCode: vendorcode })),
                    React.createElement(TabPanel, { value: value, index: 4 },
                        React.createElement(FE_EmploymentDetailsForm_1.default, { initialValues: feEmploymentDetails, saveFormData: saveFE_EmploymentDetails, enableSendItForApproval: enableSendItForApproval, fepersonalDetails: fePersonalDetails, FEIdToBeReplaced: replaceFeIdFromApi, sendItForApproval: sendItForApproval, updateValue: updateValue, value: value, onBoardingStatus: onBoardingStatus, FE_EmploymentDetailsInitialState: field_exicutive_types_1.FE_EmploymentDetailsInitialState })),
                    React.createElement(TabPanel, { value: value, index: 5 },
                        React.createElement(FE_InventoryForm_1.default, { initialValues: feInventoryDetails, saveFormData: saveFE_InventoryDetails, updateValue: updateValue, value: value, key: Math.random(), name: fePersonalDetails.firstName, FEID: FE_ID, onBoardingStatus: onBoardingStatus, isActiveFESendItForApprovalDisabled: disableSendItForApprovalForActiveFE })),
                    React.createElement(TabPanel, { value: value, index: 6 }, "Item Seven"))))));
};
exports.default = FE_REGISTATION;
