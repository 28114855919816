"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var route_path_1 = require("../../constants/route_path");
var MasterBoxActionIconButtons = function (_a) {
    var data = _a.data;
    var nav = (0, react_router_dom_1.useNavigate)();
    var viewHandler = function () {
        console.log('viewHandler clicked');
        nav("/".concat(route_path_1.default.PATHS.masterBoxDetails, "/").concat(data.id, "/").concat(data.center_code), { replace: true });
    };
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn", onClick: viewHandler, disabled: false },
            React.createElement("span", { className: "icon-Vector" }))));
};
exports.default = MasterBoxActionIconButtons;
