"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var button_component_1 = require("../../common/buttons/button_component");
var iconButton_component_1 = require("../../common/buttons/iconButton_component");
var multi_selector_1 = require("../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../common/drop_down/drop_down_component");
var text_area_component_1 = require("../../common/text_area/text_area_component");
var text_field_with_datepicker_1 = require("../../common/text_filed/text_field_with_datepicker");
var text_filed_component_1 = require("../../common/text_filed/text_filed_component");
require("./notification.scss");
var select_group_1 = require("./select-group");
var NotificationConstants_1 = require("./NotificationConstants");
var react_redux_1 = require("react-redux");
var formik_1 = require("formik");
var FormHelperText_1 = require("@mui/material/FormHelperText");
var schema_1 = require("../../constants/schema");
var react_hot_toast_1 = require("react-hot-toast");
var moment = require("moment");
var route_path_1 = require("../../constants/route_path");
var react_router_dom_1 = require("react-router-dom");
var NotificationHelpers_1 = require("./NotificationHelpers");
var array_helpers_1 = require("../../utils/array_helpers");
var CC_helper_1 = require("../super_admin/CC/CC_helper");
var NotificationPopup_1 = require("./NotificationPopup");
var material_1 = require("@mui/material");
var appConstants_1 = require("../../constants/appConstants");
var styles_1 = require("@mui/styles");
var useStyles = (0, styles_1.makeStyles)({
    customSelect: {
        backgroundColor: '#f5f7fa !important',
        marginTop: 8,
        "& .MuiList-root": {
            maxHeight: '180px',
            "& .MuiMenuItem-root": {
                fontSize: '14px',
            },
        },
    },
});
var CreateNotification = function () {
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var _b = React.useState(false), singleoccurence = _b[0], setSingleoccurance = _b[1];
    var _c = React.useState(false), yearoccurance = _c[0], setYearoccurance = _c[1];
    var rowId = (0, react_router_dom_1.useParams)().id;
    var handleClose = function () { return setOpen(false); };
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), labList = _d.labList, notification_category = _d.notification_category, notification_occurrence = _d.notification_occurrence;
    var convertedLabList = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var initialValues = {
        notificationCategory: null,
        userGroups: [],
        labCode: [],
        occurrence: null,
        startDate: "",
        endDate: "",
        executionDate: '',
        notificationTitle: "",
        notificationMessage: "",
        execution_hour: "10",
        execution_min: "00",
    };
    var _e = React.useState(initialValues), createNotificationFormValues = _e[0], setCreateNotificationFormValues = _e[1];
    var _f = React.useState(initialValues), viewNotificationFormValues = _f[0], setViewNotificationFormValues = _f[1];
    var formSubmitHnadler = function (values) {
        setOpen(true);
        setViewNotificationFormValues(values);
    };
    var nav = (0, react_router_dom_1.useNavigate)();
    console.log(createNotificationFormValues, 'createNotificationFormValues');
    var createNotificationHandler = function () {
        var payload = {};
        if (viewNotificationFormValues.notificationTitle) {
            payload['title'] = viewNotificationFormValues.notificationTitle;
        }
        else {
            react_hot_toast_1.default.error("Notification title is required");
        }
        if (viewNotificationFormValues.notificationMessage) {
            payload['message'] = viewNotificationFormValues.notificationMessage;
        }
        else {
            react_hot_toast_1.default.error("Notification Message is required");
        }
        if (singleoccurence === false && viewNotificationFormValues.startDate) {
            payload['start_date'] = viewNotificationFormValues.startDate && viewNotificationFormValues.startDate != 'Invalid Date'
                ? moment(viewNotificationFormValues.startDate).format('YYYY-MM-DD')
                : '';
        }
        if (viewNotificationFormValues.executionDate) {
            payload['execution_date'] = viewNotificationFormValues.executionDate && viewNotificationFormValues.executionDate != 'Invalid Date'
                ? moment(viewNotificationFormValues.executionDate).format('YYYY-MM-DD')
                : '';
        }
        if (singleoccurence === false && viewNotificationFormValues.endDate) {
            payload['end_date'] = viewNotificationFormValues.endDate && viewNotificationFormValues.endDate != 'Invalid Date'
                ? moment(viewNotificationFormValues.endDate).format('YYYY-MM-DD')
                : '';
        }
        if (viewNotificationFormValues.occurrence) {
            payload['frequency'] = viewNotificationFormValues.occurrence.id;
        }
        else {
            react_hot_toast_1.default.error("Notification Occurrence is required");
        }
        if (viewNotificationFormValues.notificationCategory) {
            payload['category'] = viewNotificationFormValues.notificationCategory.id;
        }
        else {
            react_hot_toast_1.default.error("Notification Category is required");
        }
        if (viewNotificationFormValues.execution_hour) {
            payload['execution_time'] = (0, array_helpers_1.hourmin)(viewNotificationFormValues.execution_hour, viewNotificationFormValues.execution_min);
        }
        if (viewNotificationFormValues.userGroups.length > 0) {
            var selectedUserGroups_1 = [];
            viewNotificationFormValues.userGroups.forEach(function (userGroup) { return selectedUserGroups_1.push(userGroup.label); });
            payload['user_groups'] = selectedUserGroups_1;
        }
        else {
            react_hot_toast_1.default.error("User Groups is required");
        }
        if (viewNotificationFormValues.labCode.length > 0) {
            var selectedLabCodes_1 = [];
            viewNotificationFormValues.labCode.forEach(function (selectedLab) { return selectedLabCodes_1.push(selectedLab.value); });
            payload['lab_codes'] = selectedLabCodes_1;
        }
        if (rowId != null) {
            payload['notification_id'] = rowId;
            (0, NotificationHelpers_1.EditNotificationList)(payload).then(function (res) {
                if (res.statusCode === 200) {
                    react_hot_toast_1.default.success(res.message);
                    nav("/".concat(route_path_1.default.PATHS.notificationManagement));
                }
            })
                .catch(function (err) {
                console.log(err);
            });
        }
        else {
            (0, NotificationHelpers_1.CreateNotificationList)(payload).then(function (res) {
                if (res.statusCode === 200) {
                    react_hot_toast_1.default.success(res.message);
                    nav("/".concat(route_path_1.default.PATHS.notificationManagement));
                }
            })
                .catch(function (err) {
                console.log(err);
            });
        }
    };
    var cancelHandler = function () {
        nav("/".concat(route_path_1.default.PATHS.notificationManagement));
    };
    var backHandler = function () {
        nav(-1);
    };
    var EditData = function () {
        var payload = {};
        if (rowId) {
            payload['notification_id'] = rowId;
        }
        else {
            payload['notification_id'] = '';
        }
        (0, NotificationHelpers_1.NotificationDetails)(payload)
            .then(function (res) {
            var _a, _b, _c, _d, _e, _f, _g;
            if (res.statusCode === 200) {
                console.log(res.data, "kjhgv");
                if (((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.frequency) === 10) {
                    setSingleoccurance(true);
                }
                if ((((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.frequency) === 8) || (((_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.frequency) === 9)) {
                    setYearoccurance(true);
                }
                if ((((_d = res === null || res === void 0 ? void 0 : res.data) === null || _d === void 0 ? void 0 : _d.frequency) === 8) || (((_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.frequency) === 9)) {
                    setYearoccurance(true);
                }
                setCreateNotificationFormValues({
                    notificationCategory: (0, CC_helper_1.convertEditData)((0, array_helpers_1.NotificationCat)(res.data.category)),
                    userGroups: res.data.user_groups.map(function (e) {
                        return (0, CC_helper_1.convertEditData)(e);
                    }),
                    labCode: res.data.lab_codes.map(function (e) {
                        return (0, CC_helper_1.convertEditData)(e);
                    }),
                    occurrence: (0, CC_helper_1.convertEditData)((0, array_helpers_1.NotificationFreq)(res.data.frequency)),
                    startDate: res.data.start_date,
                    endDate: res.data.end_date,
                    executionDate: res.data.execution_date,
                    notificationTitle: res.data.notification_title,
                    notificationMessage: res.data.notification_message,
                    execution_hour: res.data.execution_time ? (_f = res === null || res === void 0 ? void 0 : res.data) === null || _f === void 0 ? void 0 : _f.execution_time.split(":")[0] : '10',
                    execution_min: res.data.execution_time ? (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.execution_time.split(":")[1] : '00',
                });
            }
            console.log(createNotificationFormValues, 'setCreateNotificationFormValues');
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    React.useEffect(function () {
        if (rowId) {
            EditData();
        }
    }, [rowId]);
    var classes = useStyles();
    return (React.createElement("div", { className: "create-notification-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: backHandler }),
                React.createElement("h2", null, "Create New Notification"))),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement("div", { className: "crate-notification-form" },
                React.createElement(formik_1.Formik, { initialValues: createNotificationFormValues, validateOnBlur: false, enableReinitialize: true, validationSchema: schema_1.schemas.Notification_management_schema, onSubmit: function (values, _a) {
                        var setFieldError = _a.setFieldError, setSubmitting = _a.setSubmitting;
                        formSubmitHnadler(values);
                    } }, function (_a) {
                    var errors = _a.errors, values = _a.values, setFieldValue = _a.setFieldValue, setErrors = _a.setErrors, setFieldTouched = _a.setFieldTouched, setValues = _a.setValues, setTouched = _a.setTouched;
                    return (React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                        React.createElement(Grid_1.default, { item: true, xs: 12 },
                            React.createElement("div", { className: "form-filed-wrapper" },
                                console.log(errors, "k,mnbvjhg"),
                                React.createElement("div", { className: "input-fileds-wrapper" },
                                    React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3, alignItems: 'center' },
                                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                React.createElement(formik_1.Field, { name: "notificationCategory" }, function () {
                                                    return (React.createElement(drop_down_component_1.default, { dropDownList: notification_category || [], required: true, placeholder: "Select Category", onChange: function (data) {
                                                            setFieldTouched('notificationCategory', true);
                                                            setFieldValue('notificationCategory', data);
                                                        }, value: values.notificationCategory }));
                                                }),
                                                React.createElement(formik_1.ErrorMessage, { name: "notificationCategory" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                    React.createElement("div", { className: "error" },
                                                        " ",
                                                        errorMsg))); })),
                                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                React.createElement(formik_1.Field, { name: "userGroups" }, function () {
                                                    return (React.createElement(multi_selector_1.default, { noOptions: 'No User Groups', requiredClass: "validate-filed", size: "dropdown-lg", hasSelectAll: false, label: "Select User Groups", dropDownList: NotificationConstants_1.userGroupsOptions &&
                                                            NotificationConstants_1.userGroupsOptions.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: function (val) {
                                                            setFieldTouched("userGroups", true);
                                                            setFieldValue("userGroups", val);
                                                        }, value: values.userGroups }));
                                                }),
                                                React.createElement(formik_1.ErrorMessage, { name: "userGroups" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                    React.createElement("div", { className: "error" },
                                                        " ",
                                                        errorMsg))); })),
                                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                React.createElement(formik_1.Field, { name: "labCode" }, function () {
                                                    return (React.createElement(multi_selector_1.default, { label: "Lab Code", key: "labCode", size: "dropdown-lg", dropDownList: convertedLabList &&
                                                            convertedLabList.sort(function (a, b) { return a.label.localeCompare(b.label); }), value: values === null || values === void 0 ? void 0 : values.labCode, onChangeHnadlre: function (val) { return __awaiter(void 0, void 0, void 0, function () {
                                                            return __generator(this, function (_a) {
                                                                console.log(val, 'val');
                                                                setFieldTouched('labCode', true);
                                                                setFieldValue("labCode", val);
                                                                return [2];
                                                            });
                                                        }); } }));
                                                }),
                                                React.createElement(formik_1.ErrorMessage, { name: "labCode" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                    React.createElement("div", { className: "error" },
                                                        " ",
                                                        errorMsg))); })),
                                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                React.createElement(formik_1.Field, { name: "occurrence" }, function () {
                                                    return (React.createElement(drop_down_component_1.default, { dropDownList: notification_occurrence || [], placeholder: "Occurrence", required: true, value: values === null || values === void 0 ? void 0 : values.occurrence, onChange: function (val) {
                                                            console.log(val, "oiuytfd");
                                                            setFieldTouched("occurrence", true);
                                                            setFieldValue("occurrence", val);
                                                            if ((val.value === 'specific_date_time')) {
                                                                setSingleoccurance(true);
                                                            }
                                                            else {
                                                                setSingleoccurance(false);
                                                            }
                                                            if ((val.value === 'Monthly') || (val.value === 'yearly')) {
                                                                setYearoccurance(true);
                                                            }
                                                            else {
                                                                setYearoccurance(false);
                                                            }
                                                        } }));
                                                }),
                                                React.createElement(formik_1.ErrorMessage, { name: "occurrence" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                    React.createElement("div", { className: "error" },
                                                        " ",
                                                        errorMsg))); })),
                                            singleoccurence ?
                                                React.createElement(React.Fragment, null,
                                                    React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                        React.createElement(formik_1.Field, { name: "executionDate" }, function () {
                                                            return (React.createElement(text_field_with_datepicker_1.default, { required: true, label: "Date", value: values === null || values === void 0 ? void 0 : values.executionDate, minDate: new Date(), onChange: function (e) {
                                                                    setFieldValue('executionDate', e);
                                                                    setFieldTouched('executionDate', true);
                                                                } }));
                                                        }),
                                                        React.createElement(formik_1.ErrorMessage, { name: "executionDate" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                            React.createElement("div", { className: "error" },
                                                                " ",
                                                                errorMsg))); })),
                                                    React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                        React.createElement(material_1.Stack, { direction: 'row', gap: 2, alignItems: 'center', className: 'cst-timepicker' },
                                                            React.createElement(material_1.FormControl, { fullWidth: true },
                                                                React.createElement(material_1.InputLabel, { id: "hour" }),
                                                                React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, key: values.execution_hour, labelId: "hour", id: 'hour', name: "hour", value: values.execution_hour, defaultValue: values.execution_hour, onChange: function (e) {
                                                                        setFieldValue('execution_hour', e.target.value);
                                                                    } }, appConstants_1.Hours === null || appConstants_1.Hours === void 0 ? void 0 : appConstants_1.Hours.map(function (name, index) { return (React.createElement(material_1.MenuItem, { key: index, value: name }, name)); }))),
                                                            React.createElement("div", null, ":"),
                                                            React.createElement(material_1.FormControl, { fullWidth: true },
                                                                React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, value: values.execution_min, defaultValue: values.execution_min, onChange: function (e) {
                                                                        console.log(e.target.value, "kjhgfc");
                                                                        setFieldValue('execution_min', e.target.value);
                                                                    } }, appConstants_1.Minutes === null || appConstants_1.Minutes === void 0 ? void 0 : appConstants_1.Minutes.map(function (name) { return (React.createElement(material_1.MenuItem, { key: name, value: name }, name)); }))))))
                                                :
                                                    yearoccurance ?
                                                        React.createElement(React.Fragment, null,
                                                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                                React.createElement(formik_1.Field, { name: "startDate" }, function () {
                                                                    return (React.createElement(text_field_with_datepicker_1.default, { label: " Start Date", required: true, value: values === null || values === void 0 ? void 0 : values.startDate, minDate: new Date(), onChange: function (e) {
                                                                            setFieldValue('startDate', e);
                                                                            setFieldTouched('startDate', true);
                                                                        } }));
                                                                }),
                                                                React.createElement(formik_1.ErrorMessage, { name: "startDate" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                                    React.createElement("div", { className: "error" },
                                                                        " ",
                                                                        errorMsg))); })),
                                                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                                React.createElement(formik_1.Field, { name: "endDate" }, function () {
                                                                    return (React.createElement(text_field_with_datepicker_1.default, { label: "End Date", required: true, value: values === null || values === void 0 ? void 0 : values.endDate, minDate: new Date(), onChange: function (e) {
                                                                            setFieldValue('endDate', e);
                                                                            setFieldTouched('endDate', true);
                                                                        } }));
                                                                }),
                                                                React.createElement(formik_1.ErrorMessage, { name: "endDate" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                                    React.createElement("div", { className: "error" },
                                                                        " ",
                                                                        errorMsg))); })),
                                                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                                React.createElement(formik_1.Field, { name: "executionDate" }, function () {
                                                                    return (React.createElement(text_field_with_datepicker_1.default, { required: true, label: "Date", value: values === null || values === void 0 ? void 0 : values.executionDate, minDate: new Date(), onChange: function (e) {
                                                                            setFieldValue('executionDate', e);
                                                                            setFieldTouched('executionDate', true);
                                                                        } }));
                                                                }),
                                                                React.createElement(formik_1.ErrorMessage, { name: "executionDate" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                                    React.createElement("div", { className: "error" },
                                                                        " ",
                                                                        errorMsg))); })),
                                                            React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                                React.createElement(material_1.Stack, { direction: 'row', gap: 2, alignItems: 'center', className: 'cst-timepicker' },
                                                                    React.createElement(material_1.FormControl, { fullWidth: true },
                                                                        React.createElement(material_1.InputLabel, { id: "hour" }),
                                                                        React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, key: values.execution_hour, labelId: "hour", id: 'hour', name: "hour", value: values.execution_hour, defaultValue: values.execution_hour, onChange: function (e) {
                                                                                setFieldValue('execution_hour', e.target.value);
                                                                            } }, appConstants_1.Hours === null || appConstants_1.Hours === void 0 ? void 0 : appConstants_1.Hours.map(function (name, index) { return (React.createElement(material_1.MenuItem, { key: index, value: name }, name)); }))),
                                                                    React.createElement("div", null, ":"),
                                                                    React.createElement(material_1.FormControl, { fullWidth: true },
                                                                        React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, value: values.execution_min, defaultValue: values.execution_min, onChange: function (e) {
                                                                                console.log(e.target.value, "kjhgfc");
                                                                                setFieldValue('execution_min', e.target.value);
                                                                            } }, appConstants_1.Minutes === null || appConstants_1.Minutes === void 0 ? void 0 : appConstants_1.Minutes.map(function (name) { return (React.createElement(material_1.MenuItem, { key: name, value: name }, name)); }))))))
                                                        :
                                                            React.createElement(React.Fragment, null,
                                                                React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                                    React.createElement(formik_1.Field, { name: "startDate" }, function () {
                                                                        return (React.createElement(text_field_with_datepicker_1.default, { label: " Start Date", required: true, value: values === null || values === void 0 ? void 0 : values.startDate, minDate: new Date(), onChange: function (e) {
                                                                                setFieldValue('startDate', e);
                                                                                setFieldTouched('startDate', true);
                                                                            } }));
                                                                    }),
                                                                    React.createElement(formik_1.ErrorMessage, { name: "startDate" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                                        React.createElement("div", { className: "error" },
                                                                            " ",
                                                                            errorMsg))); })),
                                                                React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                                    React.createElement(formik_1.Field, { name: "endDate" }, function () {
                                                                        return (React.createElement(text_field_with_datepicker_1.default, { label: "End Date", required: true, value: values === null || values === void 0 ? void 0 : values.endDate, minDate: new Date(), onChange: function (e) {
                                                                                setFieldValue('endDate', e);
                                                                                setFieldTouched('endDate', true);
                                                                            } }));
                                                                    }),
                                                                    React.createElement(formik_1.ErrorMessage, { name: "endDate" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                                        React.createElement("div", { className: "error" },
                                                                            " ",
                                                                            errorMsg))); })),
                                                                React.createElement(Grid_1.default, { item: true, xs: 3, alignItems: 'center' },
                                                                    React.createElement(material_1.Stack, { direction: 'row', gap: 2, alignItems: 'center', className: 'cst-timepicker' },
                                                                        React.createElement(material_1.FormControl, { fullWidth: true },
                                                                            React.createElement(material_1.InputLabel, { id: "hour" }),
                                                                            React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, key: values.execution_hour, labelId: "hour", id: 'hour', name: "hour", value: values.execution_hour, defaultValue: values.execution_hour, onChange: function (e) {
                                                                                    setFieldValue('execution_hour', e.target.value);
                                                                                } }, appConstants_1.Hours === null || appConstants_1.Hours === void 0 ? void 0 : appConstants_1.Hours.map(function (name, index) { return (React.createElement(material_1.MenuItem, { key: index, value: name }, name)); }))),
                                                                        React.createElement("div", null, ":"),
                                                                        React.createElement(material_1.FormControl, { fullWidth: true },
                                                                            React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, value: values.execution_min, defaultValue: values.execution_min, onChange: function (e) {
                                                                                    console.log(e.target.value, "kjhgfc");
                                                                                    setFieldValue('execution_min', e.target.value);
                                                                                } }, appConstants_1.Minutes === null || appConstants_1.Minutes === void 0 ? void 0 : appConstants_1.Minutes.map(function (name) { return (React.createElement(material_1.MenuItem, { key: name, value: name }, name)); })))))),
                                            React.createElement(Grid_1.default, { item: true, xs: 12 },
                                                React.createElement(Grid_1.default, { item: true, xs: 3 },
                                                    React.createElement(select_group_1.default, { group: values === null || values === void 0 ? void 0 : values.userGroups }))),
                                            React.createElement(Grid_1.default, { item: true, container: true, xs: 12 },
                                                React.createElement(Grid_1.default, { item: true, xs: 6.1 },
                                                    React.createElement(formik_1.Field, { name: "notificationTitle" }, function () {
                                                        return (React.createElement(text_filed_component_1.default, { label: "Notification Title", required: true, value: values === null || values === void 0 ? void 0 : values.notificationTitle, onChange: function (val) { return setFieldValue("notificationTitle", val); } }));
                                                    }),
                                                    React.createElement(formik_1.ErrorMessage, { name: "notificationTitle" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                        React.createElement("div", { className: "error" },
                                                            " ",
                                                            errorMsg))); })),
                                                React.createElement(Grid_1.default, { item: true, xs: 6.1 },
                                                    React.createElement(formik_1.Field, { name: "notificationMessage" }, function () {
                                                        return (React.createElement(text_area_component_1.default, { minRows: 5, placeholder: "Notification Message", value: values === null || values === void 0 ? void 0 : values.notificationMessage, onChange: function (val) { return setFieldValue("notificationMessage", val); } }));
                                                    }),
                                                    React.createElement(formik_1.ErrorMessage, { name: "notificationMessage" }, function (errorMsg) { return (React.createElement(FormHelperText_1.default, { error: true, sx: { marginLeft: '0' } },
                                                        React.createElement("div", { className: "error" },
                                                            " ",
                                                            errorMsg))); })))),
                                        React.createElement("div", { className: "form-btns" },
                                            React.createElement(button_component_1.default, { type: "button", buttonName: 'Cancel', color: "yellow-outline", onClickHandler: cancelHandler }),
                                            React.createElement(button_component_1.default, { type: 'submit', buttonName: 'Review', color: "yellow", isError: false }))))))));
                }),
                React.createElement(NotificationPopup_1.default, { open: open, handleClose: handleClose, notificationData: viewNotificationFormValues, onClickHandler: createNotificationHandler, name: "Edit", singleoccurence: singleoccurence, yearoccurance: yearoccurance })))));
};
exports.default = CreateNotification;
