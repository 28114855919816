"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var forgot_password_1 = require("../components/login/forgot_password");
var LoginComponent_1 = require("../components/login/LoginComponent");
var reset_password_1 = require("../components/login/reset_password");
var UnAuthAuthRoutes = function () {
    return (React.createElement(react_router_1.Routes, null,
        React.createElement(react_router_1.Route, { path: '/sso/*', element: React.createElement(LoginComponent_1.default, null) }),
        React.createElement(react_router_1.Route, { path: '/', element: React.createElement(LoginComponent_1.default, null) }),
        React.createElement(react_router_1.Route, { path: '/forgotPassword', element: React.createElement(forgot_password_1.default, null) }),
        React.createElement(react_router_1.Route, { path: '/reset/:id', element: React.createElement(reset_password_1.default, null) })));
};
exports.default = UnAuthAuthRoutes;
