"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var api_service_1 = require("../../api/api_service");
var button_component_1 = require("../../common/buttons/button_component");
var multi_selector_1 = require("../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../common/react_pagination/react_paginate");
var search_component_1 = require("../../common/search/search_component");
var AGgrid_table_component_1 = require("../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../constants/appConstants");
var route_path_1 = require("../../constants/route_path");
var table_data_1 = require("../../constants/table_data");
var generic_api_calls_1 = require("../generic_api_calls");
var masterBoxService_1 = require("./masterBoxService");
var MasterBoxTracking = function () {
    var stateList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).stateList;
    var nav = (0, react_router_1.useNavigate)();
    var masterbox_status = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.masterbox_status; });
    var masterBoxTrackingFilters = [
        {
            id: 1,
            label: 'Masterbox ID',
        },
        {
            id: 2,
            label: 'Bag ID',
        },
    ];
    var _a = React.useState(''), searchValue = _a[0], setSearchValue = _a[1];
    var _b = React.useState(new Date().toString()), startDate = _b[0], setStartDate = _b[1];
    var _c = React.useState(new Date().toString()), endDate = _c[0], setEndDate = _c[1];
    var _d = React.useState(appConstants_1.defaultPageLimit), PageLimit = _d[0], setPageLimit = _d[1];
    var _e = React.useState([]), selectedStates = _e[0], setSelectedStates = _e[1];
    var _f = React.useState([]), selectedCities = _f[0], setSelectedCities = _f[1];
    var _g = React.useState([]), citiesData = _g[0], setCitiesData = _g[1];
    var _h = React.useState(), selectedStatus = _h[0], setSelectedStatus = _h[1];
    var _j = React.useState(''), errorMessage = _j[0], setErrorMessage = _j[1];
    var _k = React.useState(undefined), masterBoxList = _k[0], setMasterBoxList = _k[1];
    var _l = React.useState(false), isDateRangeUpdated = _l[0], setIsDateRangeUpdated = _l[1];
    var _m = React.useState({
        id: 1,
        label: 'Masterbox ID',
    }), masterBoxFilter = _m[0], setMasterBoxFilter = _m[1];
    var _o = React.useState(1), pageCount = _o[0], setpageCount = _o[1];
    var _p = React.useState(0), totalPageCount = _p[0], setTotalPageCount = _p[1];
    var _q = React.useState(0), totalCount = _q[0], setTotalRecordsCount = _q[1];
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var callBackOnStatesSelectionChange = function (values) {
        setSelectedStates(values);
    };
    var callBackOnCitiesSelectionChange = function (values) {
        setSelectedCities(values);
    };
    var callBackOnStatusChange = function (value) {
        setSelectedStatus(value);
    };
    var callBackOnPageLimitChange = function (val) {
        setPageLimit(val);
    };
    var callbackOnPageNumberChange = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var refetch = (0, react_query_1.useQuery)(['masterbox-list', pageCount], function () {
        var currentPage = pageCount || 1;
        var payload = {};
        payload.page = currentPage;
        payload.size = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        if (searchValue) {
            if (masterBoxFilter.label === 'Bag ID') {
                payload['barcode_id'] = searchValue;
            }
            else {
                payload['master_box_id'] = searchValue;
            }
        }
        if (startDate && endDate) {
            var dateRangeObj = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeObj;
        }
        if (selectedCities.length > 0) {
            var cities = selectedCities.map(function (seelctedCity) { return seelctedCity.label.split(',')[0]; });
            var states = selectedStates.map(function (selectedState) { return selectedState.label; });
            payload['states'] = states;
            payload['cities'] = cities;
        }
        if (selectedStatus) {
            payload['status'] = selectedStatus.label;
        }
        (0, masterBoxService_1.fetchMasterBoxList)(api_service_1.api_service.api_urls.master_box_list, payload, process.env.SAMPLE_TRAKING_URL)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            var convertedActiveFEList = (0, masterBoxService_1.convertMasterBoxListFromRes)(response.result);
            setErrorMessage("");
            setMasterBoxList(convertedActiveFEList);
            setTotalPageCount(count);
            setTotalRecordsCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setMasterBoxList([]);
        });
    }).refetch;
    (0, react_query_1.useQuery)(['cities-list', selectedStates], function () {
        if (selectedStates.length === 0) {
            setCitiesData([]);
            setSelectedCities([]);
            return;
        }
        var selectedStateIDs = selectedStates.map(function (selectedState) { return selectedState.id; });
        (0, generic_api_calls_1.fetchCitiesUnderStates)(selectedStateIDs)
            .then(function (res) {
            setCitiesData(res);
        })
            .catch(function () {
            react_hot_toast_1.toast.error('Unable to fetch cities list!');
            setCitiesData([]);
        });
    });
    var rowClickHandlere = function (tableRowData) {
        var rowInfo = tableRowData.data;
        nav("/".concat(route_path_1.default.PATHS.masterBoxDetails, "/").concat(rowInfo.id, "/").concat(rowInfo.warehouse_code));
    };
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var renderFilterMenu = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "filter-menu-wrapper" },
                React.createElement(drop_down_component_1.default, { name: "Master Box Tracking Filter", placeholder: "Master Box Tracking Filter", dropDownList: masterBoxTrackingFilters, value: masterBoxFilter, onChange: function (e) {
                        setMasterBoxFilter(e);
                    } }),
                React.createElement("div", { className: "search-filter search-lg" },
                    React.createElement(search_component_1.default, { placeholder: masterBoxFilter.label === 'Bag ID' ? 'Search by Bag ID' : 'Search by Masterbox ID', searchHandler: function (e) {
                            return setSearchValue(e.target.value.trim());
                        } })),
                React.createElement("div", { className: "filter-options" },
                    React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true }),
                    React.createElement(multi_selector_1.default, { label: "Select State", dropDownList: stateList &&
                            stateList.sort(function (a, b) {
                                return a.label.localeCompare(b.label);
                            }), onChangeHnadlre: callBackOnStatesSelectionChange, value: selectedStates }),
                    React.createElement(multi_selector_1.default, { label: "Select City", dropDownList: citiesData &&
                            citiesData.sort(function (a, b) {
                                return a.label.localeCompare(b.label);
                            }), onChangeHnadlre: callBackOnCitiesSelectionChange, value: selectedCities, hasSelectAll: true, noOptions: 'No cities found' }),
                    React.createElement(drop_down_component_1.default, { name: "Status", placeholder: "Status", label: "Status", dropDownList: masterbox_status, onChange: callBackOnStatusChange, value: selectedStatus, disableClearable: true }),
                    React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: callBackOnPageLimitChange, value: PageLimit }),
                    React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler })))));
    };
    var renderDataTable = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "scrollable-content clickable-row" },
                React.createElement("div", { className: "bookmark-checkbox" },
                    React.createElement(AGgrid_table_component_1.default, { mutilselector: "single", isSortable: true, columnDefs: table_data_1.default.MASTERBOX_TRACKING_COLUMNS, rows: masterBoxList, errorMessage: errorMessage, pagination: false, onRowClicked: rowClickHandlere }),
                    React.createElement(react_paginate_1.default, { onPageChange: callbackOnPageNumberChange, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: masterBoxList === null || masterBoxList === void 0 ? void 0 : masterBoxList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })))));
    };
    return (React.createElement("div", { className: "page-wrapper audit-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Masterbox Tracking")),
            React.createElement("div", { className: "page-control-wrapper" })),
        renderFilterMenu(),
        renderDataTable()));
};
exports.default = MasterBoxTracking;
