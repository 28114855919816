"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./modal.scss");
var PopUP = function (_a) {
    var modalstyle = _a.modalstyle, popUpSize = _a.popUpSize, value = _a.value, children = _a.children, isBtnDisabled = _a.isBtnDisabled, onClickHandler = _a.onClickHandler, open = _a.open, handleClose = _a.handleClose;
    return (React.createElement("div", { className: "modal-component popup-wrapper" },
        React.createElement(material_1.Modal, { open: open, onClose: handleClose },
            React.createElement(material_1.Box, { className: "modal-child pop-up-content ".concat(popUpSize) }, children))));
};
exports.default = PopUP;
