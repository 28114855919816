"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Card_1 = require("@mui/material/Card");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var drawer_1 = require("../../../common/drawer/drawer");
var table_data_1 = require("../../../constants/table_data");
var array_helpers_1 = require("../../../utils/array_helpers");
var api_service_1 = require("../api/api_service");
var out_station_masterbox_list_1 = require("../list/out_station_masterbox_list");
var out_station_masterbox_graph_view_1 = require("./out_station_masterbox_graph_view");
var OutStationMasterBoxMetrics = function (_a) {
    var endDate = _a.endDate, labCode = _a.labCode, isDateRangeUpdated = _a.isDateRangeUpdated, selectedEvent = _a.selectedEvent, filterSelectedCities = _a.filterSelectedCities, startDate = _a.startDate, labList = _a.labList, setUpdatedTIme = _a.setUpdatedTIme, updatedTime = _a.updatedTime, filterButton = _a.filterButton;
    var _b = React.useState({}), masterboxCounts = _b[0], setMasterboxCounts = _b[1];
    var _c = React.useState([]), tableData = _c[0], setTableData = _c[1];
    var _d = React.useState(false), open = _d[0], setOpen = _d[1];
    var _e = React.useState(table_data_1.Outstation_Master_Box_GRAPH_PENDIGN_RECIEVED_COLUN), tableCoulumns = _e[0], setTableColumns = _e[1];
    var refetch = (0, react_query_1.useQuery)(['masterbox-count'], function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(labCode, labList);
            payload['lab_codes'] = selectedlabCode;
        }
        (0, api_service_1.callToMasterboxCountAPI)(payload)
            .then(function (response) {
            setMasterboxCounts(response);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, [filterButton]);
    var closeHandlere = function () {
        setOpen(false);
    };
    return (React.createElement(material_1.Box, { px: 3, mt: 3 },
        React.createElement("div", { className: "orderlist-main-wrapper" },
            React.createElement("div", { className: "orderlist-main" },
                React.createElement(Card_1.default, { className: "card-main bg-lightblue disabled" },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, masterboxCounts && masterboxCounts.total_created_boxes
                                ? masterboxCounts === null || masterboxCounts === void 0 ? void 0 : masterboxCounts.total_created_boxes
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "Total Boxes Created"))),
                React.createElement(Card_1.default, { className: "card-main bg-lightblue disabled" },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, masterboxCounts && masterboxCounts.boxes_handover_to_lab
                                ? masterboxCounts === null || masterboxCounts === void 0 ? void 0 : masterboxCounts.boxes_handover_to_lab
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "Boxes Received At P Lab"))),
                React.createElement(Card_1.default, { className: "card-main bg-lightblue disabled" },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, masterboxCounts && masterboxCounts.in_transists_boxes
                                ? masterboxCounts === null || masterboxCounts === void 0 ? void 0 : masterboxCounts.in_transists_boxes
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "In Transit Boxes"))))),
        React.createElement(out_station_masterbox_graph_view_1.default, { isDateRangeUpdated: isDateRangeUpdated, startDate: startDate, endDate: endDate, labCode: labCode, labList: labList, setUpdatedTIme: setUpdatedTIme, filterSelectedCities: filterSelectedCities, updatedTime: updatedTime, setTableData: setTableData, setOpen: setOpen, setTableColumns: setTableColumns, filterButton: filterButton }),
        React.createElement(drawer_1.default, { isOpend: open, closeModal: function () { return closeHandlere(); } },
            React.createElement(out_station_masterbox_list_1.default, { list: tableData, tableCoulumns: tableCoulumns }))));
};
exports.default = OutStationMasterBoxMetrics;
