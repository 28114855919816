"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove_duplicates_Slots = exports.getWeekDay = exports.getAllWeekDaysMasterSlots = exports.createLabTimeSlot = exports.fetchLabEditList = exports.getlabList = exports.fetchLabMasterList = void 0;
var react_hot_toast_1 = require("react-hot-toast");
var api_service_1 = require("../../api/api_service");
var array_helpers_1 = require("../../utils/array_helpers");
var fetchLabMasterList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Lab_Master_List,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            var _a;
            if (response.status === 200) {
                return resolve((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchLabMasterList = fetchLabMasterList;
var getlabList = function (endPoint, payLoad, domain) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: endPoint,
            domain: domain,
            payLoad: payLoad,
        })
            .then(function (response) {
            if (response.status === 200 && response.data) {
                var convertedRolesList = (0, array_helpers_1.convertLabDataResponse)(response.data.data.result);
                return resolve(convertedRolesList);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.getlabList = getlabList;
var fetchLabEditList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Lab_edit_data,
            domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchLabEditList = fetchLabEditList;
var createLabTimeSlot = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Lab_CREATE,
            domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response, '78yu');
            if (response.status === 200) {
                return resolve(response.data);
            }
            else {
                react_hot_toast_1.default.error(response.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
            return reject(err);
        });
    });
};
exports.createLabTimeSlot = createLabTimeSlot;
var getAllWeekDaysMasterSlots = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.master_slots,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.getAllWeekDaysMasterSlots = getAllWeekDaysMasterSlots;
var getWeekDay = function (day) {
    switch (day) {
        case 'day1':
            return 1;
        case 'day2':
            return 2;
        case 'day3':
            return 3;
        case 'day4':
            return 4;
        case 'day5':
            return 5;
        case 'day6':
            return 6;
        case 'day7':
            return 7;
    }
    return day;
};
exports.getWeekDay = getWeekDay;
var remove_duplicates_Slots = function (arr) {
    var newData = new Set(arr);
    var fiterData = newData.values();
    return Array.from(fiterData);
};
exports.remove_duplicates_Slots = remove_duplicates_Slots;
