"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVENTS_LIST = void 0;
exports.EVENTS_LIST = [
    {
        id: 1,
        label: 'Registration Timestamp to Pickup',
        value: 'registration_Timestamp_to_Pickup',
    },
    {
        id: 2,
        label: 'Registration Timestamp to Lab Handover',
        value: 'registration_Timestamp_to_lab_handover',
    },
    {
        id: 3,
        label: 'Registration Timestamp to Lab Receiving',
        value: 'registration_Timestamp_to_lab_receiving',
    },
    { id: 4, label: 'Pickup to Lab Handover', value: 'pickupt_to_lab_handover' },
    { id: 5, label: 'Pickup to Lab Receiving', value: 'pickup_to_lab_receiving' },
    {
        id: 6,
        label: 'Lab Handover to Lab Receiving',
        value: 'lab_handover_to_lab_receiving',
    },
];
