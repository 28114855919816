"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var search_component_1 = require("../../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
require("../../../field_exicutive/fe-page.scss");
var api_1 = require("../api");
var RoleManagement = function () {
    var roleManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.roleManagement);
    console.log('roleManagementFeatures:', roleManagementFeatures);
    var nav = (0, react_router_1.useNavigate)();
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _a = React.useState(undefined), roleList = _a[0], setRoleList = _a[1];
    var _b = React.useState(''), searchValue = _b[0], setSearchValue = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var createNewRole = function () {
        nav("".concat(route_path_1.default.PATHS.addNewRole));
    };
    var fetchRoleList = function (endPoint, payload, domain) {
        (0, api_1.getRoleList)(endPoint, payload, domain)
            .then(function (res) {
            setErrorMessage("");
            setRoleList(res);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setRoleList([]);
        });
    };
    React.useEffect(function () {
        if (searchValue.length == 0) {
            fetchRoleList(api_urls_1.api_urls.ROLE_LIST, { page: 1, size: 200, app_id: parseInt(process.env.ROLES_APP_ID, 10) }, process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL);
        }
        UpdateListData.setIsListReloaded(false);
    }, [UpdateListData.isListReloaded, searchValue]);
    React.useEffect(function () {
        if (searchValue.length >= 3) {
            fetchRoleList(api_urls_1.api_urls.ROLE_LIST, {
                page: 1,
                size: 200,
                search: searchValue,
                app_id: parseInt(process.env.ROLES_APP_ID, 10),
            }, process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL);
        }
    }, [searchValue]);
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Role Management")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "CREATE NEW ROLE", color: "yellow", onClickHandler: createNewRole, isError: !roleManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.roleCreate] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: "Search Role", searchValue: searchValue, searchHandler: function (event) { return setSearchValue(event.target.value.trim()); } }))),
        React.createElement("div", { className: "scrollable-content unclickablerow" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ROLE_LIST_COLUMN, rows: roleList && roleList, errorMessage: errorMessage, pagination: false, className: 'role-management-table' }))));
};
exports.default = RoleManagement;
