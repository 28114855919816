"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../../reports/reports_helper");
var intracity_tat_service_1 = require("../Intracity_Tat/intracity_tat_service");
var OnTimePickupGraphList = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate, selectedEvent = _a.selectedEvent, labCode = _a.labCode, labList = _a.labList, filterSelectedCities = _a.filterSelectedCities;
    var _b = React.useState(undefined), IntracityTatList = _b[0], setIntracityTatList = _b[1];
    var _c = React.useState(1), pageCount = _c[0], setpageCount = _c[1];
    var _d = React.useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = React.useState({
        totalDelayPercentage: '--',
        totalEarlyPercentage: '--',
        totalOntimePercentage: '--',
        totalPercentage: '--',
    }), feGrandtotal = _e[0], setFegrandtotal = _e[1];
    var _f = React.useState(false), open = _f[0], setOpen = _f[1];
    var state = (0, react_router_dom_1.useLocation)().state;
    console.log(state, 'state');
    var nav = (0, react_router_dom_1.useNavigate)();
    var refetch = (0, react_query_1.useQuery)(['dynamic-clients-list', pageCount], function () {
        var payload = {};
        if (state.startDate && state.endDate) {
            var dateRangeArr = {
                start_date: moment(state.startDate).format('YYYY-MM-DD'),
                end_date: moment(state.endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (state.labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(state.labCode, state.labList);
            payload['lab_code'] = selectedlabCode;
        }
        if (state.selectedEvent != null) {
            payload['type'] = state.selectedEvent;
        }
        if (state.filterSelectedCities && state.filterSelectedCities.length > 0) {
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(state.filterSelectedCities);
            payload['state'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['city'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        (0, reports_helper_1.OnTimePickupFeList)(payload)
            .then(function (response) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            var responseData = ((_a = response.data.data) === null || _a === void 0 ? void 0 : _a.feCounts)
                ? response.data.data.feCounts
                : response.data.data;
            setErrorMessage('');
            setIntracityTatList(responseData);
            setFegrandtotal({
                totalDelayPercentage: (0, intracity_tat_service_1.convertFloatToPercentage)((_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.totalDelayPercentage),
                totalEarlyPercentage: (0, intracity_tat_service_1.convertFloatToPercentage)((_e = (_d = response.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.totalEarlyPercentage),
                totalOntimePercentage: (0, intracity_tat_service_1.convertFloatToPercentage)((_g = (_f = response.data) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.totalOntimePercentage),
                totalPercentage: (0, intracity_tat_service_1.convertFloatToPercentage)((_j = (_h = response.data) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.totalPercentage),
            });
        })
            .catch(function (err) {
            setErrorMessage(err);
            setIntracityTatList([]);
        });
    }).refetch;
    var navigateToIntracityFilter = function () {
        nav("/".concat(route_path_1.default.PATHS.on_time_pickup));
    };
    var navigateToMyReports = function () {
        nav("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var autoGenerateReportHandler = function () {
        var payload = {};
        if (state.startDate && state.endDate) {
            var dateRangeArr = {
                start_date: moment(state.startDate).format('YYYY-MM-DD'),
                end_date: moment(state.endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (state.labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(state.labCode, state.labList);
            payload['lab_code'] = selectedlabCode;
        }
        if (state.selectedEvent != null) {
            payload['type'] = state.selectedEvent;
        }
        if (state.filterSelectedCities && state.filterSelectedCities.length > 0) {
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(state.filterSelectedCities);
            payload['state'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['city'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        (0, reports_helper_1.OntimePickupGenerate)(payload)
            .then(function (response) {
            if (response.data.data.statusCode === 200) {
                react_hot_toast_1.default.error(response.data.message);
            }
            else {
                setOpen(true);
            }
        })
            .catch(function (err) {
            console.log(err, 'rrr');
            react_hot_toast_1.default.error(err);
        });
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [state.filterButton]);
    return (React.createElement("div", { className: "fe-wrapper pay  -fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper mid-page-heaidng" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateToIntracityFilter }),
                React.createElement("h2", { className: "F-18" }, "FE Intracity On Time Pickup")),
            React.createElement("h2", { className: "F-18 intracity-grand_totol" },
                'Delayed PUP:',
                React.createElement("span", null, feGrandtotal.totalDelayPercentage)),
            React.createElement("h2", { className: "F-18 intracity-grand_totol" },
                'Early PUP:',
                React.createElement("span", null, feGrandtotal.totalEarlyPercentage)),
            React.createElement("h2", { className: "F-18 intracity-grand_totol" },
                'Ontime PUP:',
                React.createElement("span", null, feGrandtotal.totalOntimePercentage)),
            React.createElement("h2", { className: "F-18 intracity-grand_totol" },
                'Grand Total:',
                React.createElement("span", null, feGrandtotal.totalPercentage)),
            React.createElement(button_component_1.default, { type: "button", buttonName: 'Download', color: "yellow-outline", isError: (IntracityTatList === null || IntracityTatList === void 0 ? void 0 : IntracityTatList.length) > 0 ? false : true, endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler })),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ONTIME_PICKUP_FE_LIST_COLUMNS, rows: IntracityTatList, pagination: true, errorMessage: errorMessage, className: 'intarcity-tat-pagination' })),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = OnTimePickupGraphList;
