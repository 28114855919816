"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchReasonsList = exports.submitRevokeLeaveRequestForFE = exports.submitApproveLeaveRequestForFE = exports.fetchCitiesList = exports.submitLeaveRequestForFE = exports.fetchLeavesList = void 0;
var api_service_1 = require("../../../api/api_service");
var fetchLeavesList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.LEAVES_LIST,
            domain: process.env.VENDOR_BASE_URL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                console.log(res.data.data, 'response');
                return resolve(res.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.fetchLeavesList = fetchLeavesList;
var submitLeaveRequestForFE = function (apiPayload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.LEAVES_CREATE,
            payLoad: apiPayload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            return resolve(response);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.submitLeaveRequestForFE = submitLeaveRequestForFE;
var fetchCitiesList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CITIES,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            return resolve(response.data.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCitiesList = fetchCitiesList;
var submitApproveLeaveRequestForFE = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .put({
            endPoint: api_service_1.api_service.api_urls.LEAVES_UPDATE,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            return resolve(response.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.submitApproveLeaveRequestForFE = submitApproveLeaveRequestForFE;
var submitRevokeLeaveRequestForFE = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .put({
            endPoint: api_service_1.api_service.api_urls.LEAVES_UPDATE,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            return resolve(response.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.submitRevokeLeaveRequestForFE = submitRevokeLeaveRequestForFE;
var fetchReasonsList = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.LEAVE_REASONS__LIST,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            return resolve(response.data.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchReasonsList = fetchReasonsList;
