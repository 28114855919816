"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var FE_InventoryForm_1 = require("./FE_InventoryForm");
require("./fe-registration.scss");
var FE_InventoryDetailsInitialState = {
    selectedInventoryList: [],
};
var FE_AssignInventoryComponent = function (_a) {
    var FEID = _a.FEID, handleDisplayModel = _a.handleDisplayModel;
    var _b = React.useState(__assign({}, FE_InventoryDetailsInitialState)), feInventoryDetails = _b[0], setInventoryDetails = _b[1];
    React.useEffect(function () {
        return function () {
            setInventoryDetails(feInventoryDetails);
        };
    }, []);
    return (React.createElement("div", { className: "on-boarding-wrapper" },
        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: { xs: 1 } },
            React.createElement(Grid_1.default, { item: true, xs: 12 },
                React.createElement("div", { className: " form-wrapper" }, React.createElement(FE_InventoryForm_1.default, { handleDisplayModel: handleDisplayModel, initialValues: feInventoryDetails, FEID: FEID, key: Math.random(), isActiveFESendItForApprovalDisabled: true }))))));
};
exports.default = FE_AssignInventoryComponent;
