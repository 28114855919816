"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Constants = {
    saveAuditDetails: 'saveAuditDetails',
};
var saveAuditDetails = function (data) { return ({
    type: Constants.saveAuditDetails,
    payload: data,
}); };
exports.default = {
    Constants: Constants,
    saveAuditDetails: saveAuditDetails,
};
