"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var approvals_helper_1 = require("./approvals_helper");
var LevelOnePendingApproval = function (_a) {
    var searchTerm = _a.searchTerm, isDateRangeUpdated = _a.isDateRangeUpdated, isFilterButton = _a.isFilterButton;
    var nav = (0, react_router_1.useNavigate)();
    var _b = React.useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = React.useState(undefined), pendingList = _c[0], setPendingList = _c[1];
    var onRowClickedHandler = function (event) {
        nav("/".concat(route_path_1.default.PATHS.approvals, "/").concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.feLevelOneDetails, "/").concat(event.data.id, "/").concat(event.data.onboard_status));
    };
    var getPendingList = function (payLoad) {
        (0, approvals_helper_1.levelOnePendingList)(payLoad)
            .then(function (response) {
            var data = (0, array_helpers_1.approvalsResponseConverter)(response);
            setPendingList(data);
            setErrorMessage('');
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setPendingList([]);
        });
    };
    React.useEffect(function () {
        console.log(' searchTerm inside useEffect:', searchTerm);
        var payload = {};
        if (searchTerm) {
            payload['fe_name'] = searchTerm;
        }
        getPendingList(payload);
    }, [isFilterButton]);
    return (React.createElement("div", { className: "fe-wrapper custom-pagination-table clickable-row" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.SUPER_ADMIN_APPROVAL_LIST_COLUMN, rows: pendingList, errorMessage: errorMessage, onRowClicked: onRowClickedHandler, pagination: false, className: 'approvals-table' })));
};
exports.default = LevelOnePendingApproval;
