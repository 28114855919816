"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colseVendorModalHandler = exports.deleteDraftedVednor = void 0;
var React = require("react");
var react_query_1 = require("react-query");
var react_router_dom_1 = require("react-router-dom");
var api_urls_1 = require("../../../../api/api_urls");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var vendor_creation_tab_constant_1 = require("../../../../constants/vendor_creation_tab_constant");
var array_helpers_1 = require("../../../../utils/array_helpers");
var vendor_api_service_1 = require("../api/vendor_api_service");
var userId = null;
var deleteDraftedVednor = function (id) {
    userId = id;
};
exports.deleteDraftedVednor = deleteDraftedVednor;
var colseVendorModalHandler = function () {
    userId = null;
};
exports.colseVendorModalHandler = colseVendorModalHandler;
var DraftVendors = function (_a) {
    var searchValue = _a.searchValue, selectedCities = _a.selectedCities, isFilterButton = _a.isFilterButton;
    var nav = (0, react_router_dom_1.useNavigate)();
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _b = React.useState(undefined), draftVedors = _b[0], setDraftVendors = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var onRowClickedHandler = function (e) {
        var selectedDraftedUserId = e.data.id;
        if (userId == selectedDraftedUserId) {
            e.stopPropagation();
        }
        else {
            nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.editVendor, "/").concat(e.data.axapta_code, "/").concat(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.PERSONALDETAILS), { replace: true });
        }
    };
    var fetchDraftVednors = function (endPoint, payLoad, domain) {
        (0, vendor_api_service_1.fetchVednors)(endPoint, payLoad, domain)
            .then(function (response) {
            setDraftVendors(response);
            setErrorMessage("");
        })
            .catch(function (err) {
            setErrorMessage(err);
            setDraftVendors([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)('draft-vendor', function () {
        var payload = (0, array_helpers_1.createPayloadForVendorList)(appConstants_1.VENDOR_STATUS.drafted, searchValue, selectedCities);
        fetchDraftVednors(api_urls_1.api_urls.VEDNOR_LIST, payload, process.env.ONE_TWO_LEVEL_APPROVAL_URL);
        if (UpdateListData.isListReloaded) {
            UpdateListData.setIsListReloaded(false);
        }
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, [UpdateListData.isListReloaded, isFilterButton]);
    return (React.createElement("div", null,
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.VENDOR_DRAFTED_COLUMNS, rows: draftVedors && draftVedors, onRowClicked: onRowClickedHandler, errorMessage: errorMessage, pagination: false, className: 'vendor-table' })));
};
exports.default = DraftVendors;
