"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_router_1 = require("react-router");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var route_path_1 = require("../../../../constants/route_path");
var generic_api_calls_1 = require("../../../generic_api_calls");
var lodash_1 = require("../../../../utils/lodash");
require("../etrf.scss");
var ETRFUpdatedDetails = function () {
    var _a = React.useState([]), etrfImages = _a[0], setEtrfImages = _a[1];
    var _b = React.useState([]), otherImages = _b[0], setOtherImages = _b[1];
    var nav = (0, react_router_1.useNavigate)();
    var stateData = (0, react_router_1.useLocation)();
    var backClick = function () {
        nav("/".concat(route_path_1.default.PATHS.ETRFList));
    };
    var getEtrfImgPromiseAPICalls = function (etrfPromiseCalls) { return __awaiter(void 0, void 0, void 0, function () {
        var imageData_1, results, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    imageData_1 = [];
                    return [4, (etrfPromiseCalls ? Promise.allSettled(etrfPromiseCalls) : [])];
                case 1:
                    results = _a.sent();
                    results.forEach(function (result) {
                        var _a, _b, _c;
                        if (result.status === 'fulfilled') {
                            var url = (_c = (_b = (_a = result.value) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.url;
                            if (url) {
                                imageData_1.push(url);
                            }
                        }
                        else {
                            return result.reason;
                        }
                    });
                    return [2, lodash_1.default.cloneDeep(imageData_1)];
                case 2:
                    err_1 = _a.sent();
                    return [2, err_1];
                case 3: return [2];
            }
        });
    }); };
    var getOtherDocsImgPromiseAPICalls = function (otherDocsPromiseCalls) { return __awaiter(void 0, void 0, void 0, function () {
        var imageData_2, results, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    imageData_2 = [];
                    return [4, (otherDocsPromiseCalls ? Promise.allSettled(otherDocsPromiseCalls) : [])];
                case 1:
                    results = _a.sent();
                    results.forEach(function (result) {
                        var _a, _b, _c;
                        if (result.status === 'fulfilled') {
                            var url = (_c = (_b = (_a = result.value) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.url;
                            if (url) {
                                imageData_2.push(url);
                            }
                        }
                        else {
                            return result.reason;
                        }
                    });
                    return [2, lodash_1.default.cloneDeep(imageData_2)];
                case 2:
                    err_2 = _a.sent();
                    return [2, err_2];
                case 3: return [2];
            }
        });
    }); };
    var fetchEtrfImages = function () { return __awaiter(void 0, void 0, void 0, function () {
        var etrfPromiseCalls_1, etrfUrls, otherDocsPromiseCalls_1, otherDocsUrls;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!stateData.state.etrf) return [3, 2];
                    etrfPromiseCalls_1 = [];
                    stateData.state.etrf.forEach(function (etrfDocData) {
                        etrfPromiseCalls_1.push((0, generic_api_calls_1.fetchImnageURLFromName)(etrfDocData.path));
                    });
                    return [4, getEtrfImgPromiseAPICalls(etrfPromiseCalls_1)];
                case 1:
                    etrfUrls = _a.sent();
                    setEtrfImages(etrfUrls);
                    _a.label = 2;
                case 2:
                    if (!stateData.state.documents) return [3, 4];
                    otherDocsPromiseCalls_1 = [];
                    stateData.state.documents.forEach(function (otherDocData) {
                        otherDocsPromiseCalls_1.push((0, generic_api_calls_1.fetchImnageURLFromName)(otherDocData.path));
                    });
                    return [4, getOtherDocsImgPromiseAPICalls(otherDocsPromiseCalls_1)];
                case 3:
                    otherDocsUrls = _a.sent();
                    setOtherImages(otherDocsUrls);
                    _a.label = 4;
                case 4: return [2];
            }
        });
    }); };
    React.useEffect(function () {
        fetchEtrfImages();
    }, [stateData]);
    return (React.createElement("div", { className: "user-details-wrapper etrf-details-page scrollable-content" },
        React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: backClick }),
                React.createElement("h2", null, "e-TRF Documents"))),
        React.createElement("div", { className: "user-card displayflex user-details-card" },
            React.createElement("div", { className: "user-details-wrapper" },
                React.createElement("div", { className: "user-name-wrapper" },
                    React.createElement("p", { className: "user-name" }, "Lab Number"),
                    React.createElement("span", { className: "user-id" },
                        React.createElement("p", null, stateData.state.lab_number))))),
        React.createElement("div", null,
            React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                React.createElement(Grid_1.default, { item: true, xs: 12, className: 'tab-heaidng profile-detail-heaidng' },
                    React.createElement("h2", null, "e-TRF Documents"))),
            React.createElement(Grid_1.default, { container: true, spacing: 3, columnSpacing: 4, className: 'ertfImageDocsContainer' }, etrfImages && etrfImages.length > 0 ? etrfImages.map(function (etrfImg) {
                return React.createElement(Grid_1.default, { item: true, xs: 2, md: 1.5, lg: 1.5, className: "image-item downloadable-image " },
                    React.createElement(React.Fragment, null,
                        React.createElement("img", { src: etrfImg }),
                        React.createElement("div", { className: "download-btn" },
                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(etrfImg), download: true }))));
            }) : React.createElement("div", { className: 'emptyDocs' }, "NO DOCUMENT UPLOADED ")),
            React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                React.createElement(Grid_1.default, { item: true, xs: 12, className: 'tab-heaidng profile-detail-heaidng' },
                    React.createElement("h2", null, "Other Documents"))),
            React.createElement(Grid_1.default, { container: true, spacing: 3, columnSpacing: 4, className: 'ertfImageDocsContainer' }, otherImages && otherImages.length > 0 ? otherImages.map(function (otherDocImg) {
                return React.createElement(Grid_1.default, { item: true, xs: 2, md: 1.5, lg: 1.5, className: "image-item downloadable-image " },
                    React.createElement(React.Fragment, null,
                        React.createElement("img", { src: otherDocImg }),
                        React.createElement("div", { className: "download-btn" },
                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(otherDocImg), download: true }))));
            }) : React.createElement("div", { className: 'emptyDocs' }, "NO DOCUMENT UPLOADED ")))));
};
exports.default = ETRFUpdatedDetails;
