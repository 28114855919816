"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchNotificationMessageUpdate = exports.fetchNotificationMessageList = exports.fetchNotificationCount = exports.NotificationDetails = exports.CreateNotificationList = exports.EditNotificationList = exports.deleteNotificationList = exports.convertNotificationListFromRes = exports.fetchNotificationList = void 0;
var react_hot_toast_1 = require("react-hot-toast");
var api_service_1 = require("../../api/api_service");
var NotificationConstants_1 = require("./NotificationConstants");
var fetchNotificationList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Notification_List,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            console.log(err, 'precious');
            return reject(err);
        });
    });
};
exports.fetchNotificationList = fetchNotificationList;
var convertNotificationListFromRes = function (res) {
    var convertedNotificationListArr = [];
    res.map(function (singleObj) {
        var userGroups = [];
        singleObj.user_groups.forEach(function (userGroup) {
            var singleUserGrp = NotificationConstants_1.userGroupsOptions.find(function (userGrpOption) { return userGrpOption.label === userGroup; });
            if (singleUserGrp) {
                userGroups.push(singleUserGrp);
            }
        });
        var convertedNotificationList = {
            id: singleObj._id ? singleObj._id : "",
            notificationTitle: singleObj.notification_title ? singleObj.notification_title : "",
            category: singleObj.category ? singleObj.category : "",
            users: userGroups ? userGroups : [],
            created_date: singleObj.created_date_time ? singleObj.created_date_time : "",
            startDate: singleObj.start_date ? singleObj.start_date : "",
            endDate: singleObj.end_date ? singleObj.end_date : "",
            executionDate: singleObj.execution_date ? singleObj.execution_date : "",
            createdDate: singleObj.created_date_time ? singleObj.created_date_time : "",
            execution_time: singleObj.execution_time ? singleObj.execution_time : "",
        };
        convertedNotificationListArr.push(convertedNotificationList);
    });
    return convertedNotificationListArr;
};
exports.convertNotificationListFromRes = convertNotificationListFromRes;
var deleteNotificationList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Notification_delete,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            else {
                react_hot_toast_1.default.error(response.data.message);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            console.log(err, 'precious');
            return reject(err);
        });
    });
};
exports.deleteNotificationList = deleteNotificationList;
var EditNotificationList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Notification_update,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log(response, 'precious');
            if (response.status === 200) {
                return resolve(response.data);
            }
            else {
                react_hot_toast_1.default.error(response.data.message);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.EditNotificationList = EditNotificationList;
var CreateNotificationList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Notification_creation,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log(response, 'precious');
            if (response.status === 200) {
                return resolve(response.data);
            }
            else {
                react_hot_toast_1.default.error(response.data.message);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.CreateNotificationList = CreateNotificationList;
var NotificationDetails = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.Notification_details,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log(response, 'precious');
            if (response.status === 200) {
                return resolve(response.data);
            }
            else {
                react_hot_toast_1.default.error(response.data.message);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.NotificationDetails = NotificationDetails;
var fetchNotificationCount = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.Notification_Count,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchNotificationCount = fetchNotificationCount;
var fetchNotificationMessageList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.Notification_Message_List,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchNotificationMessageList = fetchNotificationMessageList;
var fetchNotificationMessageUpdate = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Notification_Message_update,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchNotificationMessageUpdate = fetchNotificationMessageUpdate;
