"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var profile_grid_1 = require("../../../common/profile_grid/profile_grid");
var FECreationTabConstant_1 = require("../../../constants/FECreationTabConstant");
var images_1 = require("../../../constants/images");
var profile_detaile_lable_1 = require("../../../constants/profile_detaile_lable");
var route_path_1 = require("../../../constants/route_path");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var generic_api_calls_1 = require("../../generic_api_calls");
var fe_services_1 = require("../fe_services");
var FEProfileDetails = function () {
    var _a = React.useState(), profileData = _a[0], setProfileData = _a[1];
    console.log(profileData, 'profileData');
    var _b = React.useState(''), errorMessage = _b[0], setErrormsg = _b[1];
    var _c = React.useState(''), profileImageURL = _c[0], setProfileImageURL = _c[1];
    var _d = React.useState(''), profileImageDocName = _d[0], setProfileImageDocName = _d[1];
    var _e = React.useState(''), parmanentAddressImageURL = _e[0], setParmanentAddressImageURL = _e[1];
    var _f = React.useState(''), parmanentAddressUploadedURL = _f[0], setParmanentAddressUploadedURL = _f[1];
    var _g = React.useState(''), parmanentAddressUploadedDocName = _g[0], setParmanentAddressUploadedDocName = _g[1];
    var _h = React.useState(''), currentAddressImageURL = _h[0], setCurrentAddressImageURL = _h[1];
    var _j = React.useState(''), currentAddressUploadedURL = _j[0], setCurrentAddressUploadedURL = _j[1];
    var _k = React.useState(''), currentAddressUploadedDocName = _k[0], setCurrentAddressUploadedDocName = _k[1];
    var _l = React.useState(''), bankChequeDocImageURL = _l[0], setBankChequeDocImageURL = _l[1];
    var _m = React.useState(''), bankChequeDocUploadedURL = _m[0], setBankChequeDocUploadedURL = _m[1];
    var _o = React.useState(''), bankChequeDocUploadedDocName = _o[0], setBankChequeDocUploadedDocName = _o[1];
    var _p = React.useState(''), insuranceImageURL = _p[0], setInsuranceImageURL = _p[1];
    var _q = React.useState(''), insuranceUploadedURL = _q[0], setInsuranceUploadedURL = _q[1];
    var _r = React.useState(''), insuranceDocName = _r[0], setInsuranceDocName = _r[1];
    var _s = React.useState(''), drivingLicenceImageURL = _s[0], setDrivingLicenceImageURL = _s[1];
    var _t = React.useState(''), drivingLicenceUploadedURL = _t[0], setDrivingLicenceUploadedURL = _t[1];
    var _u = React.useState(''), drivingLicenceDocName = _u[0], setDrivingLicenceDocName = _u[1];
    var id = (0, react_router_1.useParams)().id;
    var nav = (0, react_router_1.useNavigate)();
    var emptyDetails = '--';
    var feManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.feManagement);
    var fetchProfileList = function (endPoint, payLoad, domain) {
        (0, fe_services_1.fetchFEProfileList)(endPoint, payLoad, domain)
            .then(function (response) {
            setErrormsg('');
            setProfileData(response);
            if (response.avatar != null &&
                response.avatar != undefined &&
                response.avatar != '') {
                var imagepath = response.avatar.split('/');
                (0, generic_api_calls_1.fetchImnageURLFromName)(response.avatar).then(function (res) {
                    setProfileImageURL(res.data.data.url);
                });
                setProfileImageDocName(imagepath[imagepath.length - 1]);
            }
            if ((response === null || response === void 0 ? void 0 : response.addresses) != null &&
                (response === null || response === void 0 ? void 0 : response.addresses) != undefined &&
                (response === null || response === void 0 ? void 0 : response.addresses) != '') {
                var nameArr_1 = response && response.addresses[1] && response.addresses[1].proof_of_doc
                    ? response.addresses[1].proof_of_doc.split('/')
                    : [];
                if (nameArr_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response === null || response === void 0 ? void 0 : response.addresses[1].proof_of_doc).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (nameArr_1[nameArr_1.length - 1].includes('jpg') ||
                            nameArr_1[nameArr_1.length - 1].includes('png') ||
                            nameArr_1[nameArr_1.length - 1].includes('jfif')) {
                            setParmanentAddressImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            setParmanentAddressUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (nameArr_1[nameArr_1.length - 1].includes('pdf')) {
                            setParmanentAddressImageURL(images_1.default.pdfImage);
                            setParmanentAddressUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            setParmanentAddressImageURL(images_1.default.csvImage);
                            setParmanentAddressUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                    setParmanentAddressUploadedDocName(nameArr_1[nameArr_1.length - 1]);
                }
                var nameArr1_1 = response && response.addresses[0] && response.addresses[0].proof_of_doc
                    ? response.addresses[0].proof_of_doc.split('/')
                    : [];
                if (nameArr1_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response === null || response === void 0 ? void 0 : response.addresses[0].proof_of_doc).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (nameArr1_1[nameArr1_1.length - 1].includes('jpg') ||
                            nameArr1_1[nameArr1_1.length - 1].includes('png') ||
                            nameArr1_1[nameArr1_1.length - 1].includes('jfif')) {
                            setCurrentAddressImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            setCurrentAddressUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (nameArr_1[nameArr_1.length - 1].includes('pdf')) {
                            setCurrentAddressImageURL(images_1.default.pdfImage);
                            setCurrentAddressUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            setCurrentAddressImageURL(images_1.default.csvImage);
                            setCurrentAddressUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                    setCurrentAddressUploadedDocName(nameArr1_1[nameArr1_1.length - 1]);
                }
            }
            if (response.insurance_photo != null &&
                response.insurance_photo != undefined &&
                response.insurance_photo != '') {
                var insuranceDoc_1 = response && response.insurance_photo
                    ? response.insurance_photo.split('/')
                    : [];
                if (insuranceDoc_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response === null || response === void 0 ? void 0 : response.insurance_photo).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (insuranceDoc_1[insuranceDoc_1.length - 1].includes('jpg') ||
                            insuranceDoc_1[insuranceDoc_1.length - 1].includes('png') ||
                            insuranceDoc_1[insuranceDoc_1.length - 1].includes('jfif')) {
                            setInsuranceImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            setInsuranceUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (insuranceDoc_1[insuranceDoc_1.length - 1].includes('pdf')) {
                            setInsuranceImageURL(images_1.default.pdfImage);
                            setInsuranceUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            setInsuranceImageURL(images_1.default.csvImage);
                            setInsuranceUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                }
                setInsuranceDocName(insuranceDoc_1[insuranceDoc_1.length - 1]);
            }
            if (response.driving_licence_doc != null &&
                response.driving_licence_doc != undefined &&
                response.driving_licence_doc != '') {
                var drivingLicenceDoc_1 = response && response.driving_licence_doc
                    ? response.driving_licence_doc.split('/')
                    : [];
                if (drivingLicenceDoc_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response === null || response === void 0 ? void 0 : response.driving_licence_doc).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (drivingLicenceDoc_1[drivingLicenceDoc_1.length - 1].includes('jpg') ||
                            drivingLicenceDoc_1[drivingLicenceDoc_1.length - 1].includes('png') ||
                            drivingLicenceDoc_1[drivingLicenceDoc_1.length - 1].includes('jfif')) {
                            setDrivingLicenceImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            setDrivingLicenceUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (drivingLicenceDoc_1[drivingLicenceDoc_1.length - 1].includes('pdf')) {
                            setDrivingLicenceImageURL(images_1.default.pdfImage);
                            setDrivingLicenceUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            setDrivingLicenceImageURL(images_1.default.csvImage);
                            setDrivingLicenceUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                }
                setDrivingLicenceDocName(drivingLicenceDoc_1[drivingLicenceDoc_1.length - 1]);
            }
            if (response === null || response === void 0 ? void 0 : response.bank_cheque_doc) {
                var bankChequeDoc_1 = response && response.bank_cheque_doc
                    ? response.bank_cheque_doc.split('/')
                    : [];
                if (bankChequeDoc_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response === null || response === void 0 ? void 0 : response.bank_cheque_doc).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (bankChequeDoc_1[bankChequeDoc_1.length - 1].includes('jpg') ||
                            bankChequeDoc_1[bankChequeDoc_1.length - 1].includes('png') ||
                            bankChequeDoc_1[bankChequeDoc_1.length - 1].includes('jfif')) {
                            setBankChequeDocImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            setBankChequeDocUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (bankChequeDoc_1[bankChequeDoc_1.length - 1].includes('pdf')) {
                            setBankChequeDocImageURL(images_1.default.pdfImage);
                            setBankChequeDocUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            setBankChequeDocImageURL(images_1.default.csvImage);
                            setBankChequeDocUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                }
                setBankChequeDocUploadedDocName(bankChequeDoc_1[bankChequeDoc_1.length - 1]);
            }
        })
            .catch(function (err) {
            setErrormsg(err);
        });
    };
    React.useEffect(function () {
        fetchProfileList(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, { user_id: id }, process.env.VENDOR_BASE_URL);
    }, []);
    var editProfileHnadler = function () {
        if (profileData && profileData.approval_status === 2) {
            react_hot_toast_1.default.error('Already previously updated details for the user are in approval state,Please wait until details get approved!');
        }
        else {
            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(id));
        }
    };
    var getLatLongs = function (profileData) {
        if (profileData.base_latitude && profileData.base_longitude) {
            return "".concat(profileData.base_latitude, ",").concat(profileData.base_longitude);
        }
        return emptyDetails;
    };
    var renderPage = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17;
        return !errorMessage ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "tab-heaidng displayflex profile-details-tab" },
                React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[0]),
                React.createElement(button_component_1.default, { color: "yellow-outline", onClickHandler: editProfileHnadler, buttonName: "Edit Profile", isError: !feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.editFE] })),
            React.createElement("div", { className: "fe-profile-tab-content " },
                React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 } },
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.firstname, value: profileData && profileData.first_name
                            ? profileData.first_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.lastname, value: profileData && profileData.last_name
                            ? profileData.last_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.dob, value: (_a = (profileData && (0, array_helpers_1.normaldateFormatte)(profileData.dob))) !== null && _a !== void 0 ? _a : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.gender, value: (_b = (profileData && profileData.gender)) !== null && _b !== void 0 ? _b : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.feId, value: (_c = (profileData && profileData.fe_id)) !== null && _c !== void 0 ? _c : emptyDetails }),
                    React.createElement(Grid_1.default, { item: true, lg: 12, xs: 12 },
                        React.createElement("div", { className: "user-profile-wrapper" },
                            React.createElement("small", { className: "user-label" }, " FE Profile Pics"),
                            React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 10, lg: 9 } },
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 2, className: "image-item downloadable-image" }, profileImageURL && profileImageURL ? (React.createElement(React.Fragment, null,
                                    React.createElement("img", { src: profileImageURL, alt: "profile" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(profileImageURL), download: true })),
                                    React.createElement("div", { className: "image-title" }, profileImageDocName))) : ('No pic uploaded'))))),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng headingtop0" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[1]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.referenceName1, value: (_d = (profileData && profileData.reference_name1)) !== null && _d !== void 0 ? _d : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.referenceMobileno1, value: (_e = (profileData && profileData.mobile)) !== null && _e !== void 0 ? _e : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.referenceName2, value: (_f = (profileData && profileData.reference_name2)) !== null && _f !== void 0 ? _f : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.referenceMobileno2, value: (_g = (profileData && profileData.reference_mobile2)) !== null && _g !== void 0 ? _g : emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[2]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.mobilenumber, value: (_h = (profileData && profileData.mobile)) !== null && _h !== void 0 ? _h : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.email, value: (_j = (profileData && profileData.email)) !== null && _j !== void 0 ? _j : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.emeregencyContact, value: (_k = (profileData && profileData.emergency_mobile)) !== null && _k !== void 0 ? _k : emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng  internal-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[3]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.line1, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[0].lane1
                            ? profileData.addresses[0].lane1
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.line2, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[0].lane2
                            ? profileData.addresses[0].lane2
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.landmark, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[0].landmark
                            ? profileData.addresses[0].landmark
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.city, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[0].city_name
                            ? profileData.addresses[0].city_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.state, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[0].state_name
                            ? profileData.addresses[0].state_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.country, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[0].country_name
                            ? profileData.addresses[0].country_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.pincode, value: profileData && profileData.addresses.length >= 1
                            ? profileData.addresses[0].pincode
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.addressProofType, value: (_l = (profileData &&
                            profileData.addresses[0] &&
                            profileData.addresses[0].proof_of_doc_type)) !== null && _l !== void 0 ? _l : emptyDetails }),
                    React.createElement(Grid_1.default, { item: true, xs: 9 },
                        React.createElement("div", { className: "user-profile-wrapper" },
                            React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default.PROFILE_LABLES.addressProof),
                            React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } },
                                React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" }, currentAddressImageURL && currentAddressUploadedURL ? (React.createElement(React.Fragment, null,
                                    React.createElement("img", { src: currentAddressImageURL, alt: "image not found" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(currentAddressUploadedURL), download: true })),
                                    React.createElement("div", { className: "image-title" }, currentAddressUploadedDocName))) : ('No document uploaded'))))),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[8]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.line1, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[1].lane1
                            ? profileData.addresses[1].lane1
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.line2, value: profileData && profileData.addresses.length >= 1
                            ? profileData.addresses[1].lane2
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.landmark, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[1].landmark
                            ? profileData.addresses[1].landmark
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.city, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[1].city_name
                            ? profileData.addresses[1].city_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.state, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[1].state_name
                            ? profileData.addresses[1].state_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.country, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[1].country_name
                            ? profileData.addresses[1].country_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.pincode, value: profileData &&
                            profileData.addresses.length >= 1 &&
                            profileData.addresses[1].pincode
                            ? profileData.addresses[1].pincode
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.addressProofType, value: (_m = (profileData &&
                            profileData.addresses[1] &&
                            profileData.addresses[1].proof_of_doc_type)) !== null && _m !== void 0 ? _m : emptyDetails }),
                    React.createElement(Grid_1.default, { item: true, xs: 9 },
                        React.createElement("div", { className: "user-profile-wrapper" },
                            React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default.PROFILE_LABLES.addressProof),
                            React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } },
                                React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" }, parmanentAddressImageURL && parmanentAddressUploadedURL ? (React.createElement(React.Fragment, null,
                                    React.createElement("img", { src: parmanentAddressImageURL, alt: "" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(parmanentAddressUploadedURL), download: true })),
                                    React.createElement("div", { className: "image-title" }, parmanentAddressUploadedDocName))) : ('No document uploaded'))))),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[4]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.accountNo, value: (_o = (profileData && profileData.bank_account)) !== null && _o !== void 0 ? _o : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.ifscCode, value: (_p = (profileData && profileData.bank_ifsc)) !== null && _p !== void 0 ? _p : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.bankName, value: (_q = (profileData && profileData.bank_name)) !== null && _q !== void 0 ? _q : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.bankBranch, value: (_r = (profileData && profileData.bank_branch)) !== null && _r !== void 0 ? _r : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.upi, value: (_s = (profileData && profileData.bank_upi_id)) !== null && _s !== void 0 ? _s : emptyDetails }),
                    React.createElement(Grid_1.default, { item: true, xs: 9 },
                        React.createElement("div", { className: "user-profile-wrapper" },
                            React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default.PROFILE_LABLES.bankAccountDocument),
                            React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } },
                                React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" }, bankChequeDocImageURL && bankChequeDocUploadedURL ? (React.createElement(React.Fragment, null,
                                    React.createElement("img", { src: bankChequeDocImageURL, alt: "" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(bankChequeDocUploadedURL), download: true })),
                                    React.createElement("div", { className: "image-title" }, bankChequeDocUploadedDocName))) : ('No document uploaded'))))),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng internal-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[5]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.vehicleType, value: (_t = (profileData && profileData.vehicle_type)) !== null && _t !== void 0 ? _t : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.drivingLicence, value: (_u = (profileData && profileData.driving_licence)) !== null && _u !== void 0 ? _u : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.drivingLicenceExDate, value: (_v = (profileData &&
                            profileData.driving_licence_expiry &&
                            (0, array_helpers_1.normaldateFormatte)(profileData.driving_licence_expiry))) !== null && _v !== void 0 ? _v : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.insurencExDate, value: (_w = (profileData &&
                            profileData.insurance_expiry &&
                            (0, array_helpers_1.normaldateFormatte)(profileData.insurance_expiry))) !== null && _w !== void 0 ? _w : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.insurencNo, value: (_x = (profileData && profileData.insurance)) !== null && _x !== void 0 ? _x : emptyDetails }),
                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true },
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement("div", { className: "user-profile-wrapper" },
                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default.PROFILE_LABLES.uploadInsurence),
                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, insuranceImageURL && insuranceUploadedURL ? (React.createElement(React.Fragment, null,
                                    React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" },
                                        React.createElement("img", { src: insuranceImageURL, alt: "" }),
                                        React.createElement("div", { className: "download-btn" },
                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(insuranceUploadedURL), download: true })),
                                        React.createElement("div", { className: "image-title" }, insuranceDocName)))) : ('No document uploaded')))),
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement("div", { className: "user-profile-wrapper" },
                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default.PROFILE_LABLES.drivingLicenceDetailsDOc),
                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, profileData && profileData.insurance_photo ? (React.createElement(React.Fragment, null,
                                    React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" },
                                        React.createElement("img", { src: drivingLicenceImageURL, alt: "" }),
                                        React.createElement("div", { className: "download-btn" },
                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(drivingLicenceUploadedURL), download: true })),
                                        React.createElement("div", { className: "image-title" }, drivingLicenceDocName)))) : ('No document uploaded'))))),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[6]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.feType, value: (_y = (profileData && profileData.fe_type)) !== null && _y !== void 0 ? _y : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.doj, value: (_z = (profileData &&
                            profileData.date_of_joining &&
                            (0, array_helpers_1.normaldateFormatte)(profileData.date_of_joining))) !== null && _z !== void 0 ? _z : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.company, value: (_0 = (profileData && profileData.company)) !== null && _0 !== void 0 ? _0 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.tenure, value: (_1 = (profileData && profileData.tenure)) !== null && _1 !== void 0 ? _1 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.labCode, value: (_2 = (profileData && profileData.lab_code)) !== null && _2 !== void 0 ? _2 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.labName, value: (_3 = (profileData && profileData.centre_name)) !== null && _3 !== void 0 ? _3 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.hub, value: (_4 = (profileData && profileData.hub_name)) !== null && _4 !== void 0 ? _4 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.regionalManager, value: (_5 = (profileData && profileData.regional_manager1)) !== null && _5 !== void 0 ? _5 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.zone, value: (_6 = (profileData && profileData.zone_name)) !== null && _6 !== void 0 ? _6 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.labState, value: (_7 = (profileData && profileData.centre_state)) !== null && _7 !== void 0 ? _7 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.labCity, value: (_8 = (profileData && profileData.centre_city)) !== null && _8 !== void 0 ? _8 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.feWorkingState, value: (_9 = (profileData && profileData.working_state)) !== null && _9 !== void 0 ? _9 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.feWorkingCity, value: (_10 = (profileData && profileData.working_city)) !== null && _10 !== void 0 ? _10 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.vendorName, value: (_11 = (profileData && profileData.vendor_name)) !== null && _11 !== void 0 ? _11 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.vendorCode, value: (_12 = (profileData && profileData.vendor_code)) !== null && _12 !== void 0 ? _12 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.feWorkingCode, value: (_13 = (profileData && profileData.fe_id)) !== null && _13 !== void 0 ? _13 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.baseLocationAddress, value: (_14 = (profileData && profileData.base_location_address)) !== null && _14 !== void 0 ? _14 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.baseLocationCoordinates, value: (_15 = (profileData && getLatLongs(profileData))) !== null && _15 !== void 0 ? _15 : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.employee_vendor_id, value: (_16 = (profileData && profileData.employee_id)) !== null && _16 !== void 0 ? _16 : emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[10]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.additionalRole, value: profileData ? (0, FECreationTabConstant_1.getAdditionalRoleInProfile)(profileData) : emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[9]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.PROFILE_LABLES.assignRouteType, value: (_17 = (profileData && profileData.fe_route_assignment_type)) !== null && _17 !== void 0 ? _17 : emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, profile_detaile_lable_1.default.PROFILE_HEADINGS[7]))),
                    React.createElement(Grid_1.default, { item: true, xs: 6 },
                        React.createElement("div", { className: "badge-list user-profile-wrapper" }, profileData &&
                            profileData.inventory &&
                            profileData.inventory.length >= 1
                            ? profileData &&
                                profileData.inventory &&
                                profileData.inventory.map(function (invetoryName) {
                                    return (React.createElement("div", { key: id, className: "badge" }, invetoryName.inventory_name && invetoryName.inventory_name
                                        ? invetoryName.inventory_name
                                        : emptyDetails));
                                })
                            : 'Inventory Not  Assigend')))),
            ' ')) : (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString()));
    };
    return React.createElement("div", { className: "fe_detials-tab-wrapper " }, renderPage());
};
exports.default = FEProfileDetails;
