"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var button_component_1 = require("../../../../common/buttons/button_component");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var react_router_1 = require("react-router");
require("./scf_styles.scss");
var schema_1 = require("../../../../constants/schema");
var material_1 = require("@mui/material");
var react_redux_1 = require("react-redux");
var CC_api_calls_1 = require("../../CC/CC_api_calls");
var orc_service_1 = require("../orc_management/orc_service");
var api_service_1 = require("../../../../api/api_service");
var react_hot_toast_1 = require("react-hot-toast");
var route_path_1 = require("../../../../constants/route_path");
var api_urls_1 = require("../../../../api/api_urls");
var scf_service_1 = require("./scf_service");
var initialValues = {
    warehouse_code: null,
    invoice_code: '',
    client_name: '',
    state: '',
    city: '',
    lab_code: '',
    lab_name: '',
    route_type: '',
    company_id: '',
    payment_type: null,
    monthly_daily: null,
    courier_charges_type: null,
    courier_charges: '',
    payout1: null,
    payout2: null,
    payout3: null,
    onSaleCourieChargesTypes: true
};
var CreateSCF = function () {
    var nav = (0, react_router_1.useNavigate)();
    var center_id = (0, react_router_1.useParams)().center_id;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), scf_courier_charges_types = _a.scf_courier_charges_types, scf_payment_Types = _a.scf_payment_Types, scf_payment_mode = _a.scf_payment_mode, scf_slabs_list = _a.scf_slabs_list;
    var _b = React.useState(initialValues), scfInitialValues = _b[0], setScfInitialValues = _b[1];
    var _c = React.useState(initialValues), profileData = _c[0], setProfileData = _c[1];
    var _d = React.useState([]), primaryClientData = _d[0], setPrimaryClinetData = _d[1];
    var ontextChange = function (searchString) {
        if (searchString === '' ||
            searchString === null ||
            searchString === undefined) {
            var payload = {};
            payload['page'] = 1;
            payload['size'] = 50;
            fetchWarehouseCode(payload);
        }
        else if (searchString.length > 2) {
            var payload = {
                search_string: searchString,
            };
            fetchWarehouseCode(payload);
        }
    };
    var onAutoCompleteClose = function () {
        var payload = {};
        payload['page'] = 1;
        payload['size'] = 50;
        fetchWarehouseCode(payload);
    };
    var fetchWarehouseCode = function (payload) {
        (0, CC_api_calls_1.fetchCCTimeSlotList)(payload)
            .then(function (response) {
            var slicedList = response.result.length > 0 ? response.result.slice(0, 30 || 10) : [];
            var convertedData = (0, orc_service_1.convertPrimaryClientData)(slicedList);
            setPrimaryClinetData(convertedData);
        })
            .catch(function (err) {
            setPrimaryClinetData([]);
        });
    };
    var checkPaymentType = function (values) {
        if (values.payment_type && (values.payment_type.id == scf_service_1.SCF_PAYMENT_TYPES.FIXED || values.payment_type.id == scf_service_1.SCF_PAYMENT_TYPES.MONTHLY || values.payment_type.id == scf_service_1.SCF_PAYMENT_TYPES.PER_DAY)) {
            return true;
        }
        else {
            return false;
        }
    };
    var submitHandlre = function (values) {
        if (center_id) {
            if (JSON.stringify(values) == JSON.stringify(profileData)) {
                react_hot_toast_1.default.error("No changes available");
                return;
            }
            else {
                var update_payload = {
                    center_id: values.warehouse_code.id,
                    status: scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE,
                    payment_type: values.payment_type.id,
                    payment_option: values.monthly_daily.id,
                    courier_charges_type: values.courier_charges_type.id,
                    courier_charges: Number(values.courier_charges)
                };
                if (!values.onSaleCourieChargesTypes) {
                    update_payload['slab_one'] = scf_slabs_list ? Object.values(scf_slabs_list)[0] : '--';
                    update_payload['slab_one_payout'] = Number(values.payout1.label);
                    update_payload['slab_two'] = scf_slabs_list ? Object.values(scf_slabs_list)[1] : '--';
                    update_payload['slab_two_payout'] = Number(values.payout2.label);
                    update_payload['slab_three'] = scf_slabs_list ? Object.values(scf_slabs_list)[2] : '--';
                    update_payload['slab_three_payout'] = Number(values.payout3.label);
                }
                else {
                    update_payload['courier_charges'] = Number(values.courier_charges);
                }
                api_service_1.api_service.put({
                    endPoint: api_urls_1.api_urls.UPDATE_SCF,
                    domain: process.env.APPROVAL_BASEURL,
                    payLoad: update_payload,
                }).then(function (res) {
                    if (res.data.statusCode == 200) {
                        react_hot_toast_1.default.success(res.data.message);
                        nav("/".concat(route_path_1.default.PATHS.scf_listing, "/").concat(route_path_1.default.PATHS.scf_level1_listing));
                    }
                    else {
                        react_hot_toast_1.default.error(res.data.message);
                    }
                }).catch(function (err) {
                    return err;
                });
            }
        }
        else {
            var crate_payload = {
                center_id: values.warehouse_code.id,
                payment_type: values.payment_type.id,
                payment_option: values.monthly_daily.id,
                courier_charges_type: !values.onSaleCourieChargesTypes ? 2 : values.courier_charges_type.id,
            };
            if (!values.onSaleCourieChargesTypes) {
                crate_payload['slab_one'] = scf_slabs_list ? Object.values(scf_slabs_list)[0] : '--';
                crate_payload['slab_one_payout'] = Number(values.payout1.label);
                crate_payload['slab_two'] = scf_slabs_list ? Object.values(scf_slabs_list)[1] : '--';
                crate_payload['slab_two_payout'] = Number(values.payout2.label);
                crate_payload['slab_three'] = scf_slabs_list ? Object.values(scf_slabs_list)[2] : '--';
                crate_payload['slab_three_payout'] = Number(values.payout3.label);
            }
            else {
                crate_payload['courier_charges'] = Number(values.courier_charges);
            }
            api_service_1.api_service.post({
                endPoint: api_urls_1.api_urls.CREATE_SCF,
                domain: process.env.APPROVAL_BASEURL,
                payLoad: crate_payload,
            }).then(function (res) {
                if (res.data.statusCode == 200) {
                    react_hot_toast_1.default.success(res.data.message);
                    nav("/".concat(route_path_1.default.PATHS.scf_listing, "/").concat(route_path_1.default.PATHS.scf_level1_listing));
                }
                else {
                    react_hot_toast_1.default.error(res.data.message);
                }
            }).catch(function (err) {
                return err;
            });
        }
    };
    var fetchSCFProfileDetails = function () {
        api_service_1.api_service.get({
            endPoint: "".concat(api_service_1.api_service.api_urls.SCF_DETAILS, "/").concat(center_id),
            domain: process.env.APPROVAL_BASEURL,
        }).then(function (res) {
            if (res.data.statusCode == 200) {
                var convertedData = (0, scf_service_1.convertProfiledataAsSCFInitialValues)(res.data.data);
                setScfInitialValues(convertedData);
                setProfileData(convertedData);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        }).catch(function (err) {
            return err;
        });
    };
    React.useEffect(function () {
        if (center_id) {
            fetchSCFProfileDetails();
        }
    }, []);
    React.useEffect(function () {
        var payload = {};
        payload['page'] = 1;
        payload['size'] = 50;
        fetchWarehouseCode(payload);
    }, []);
    return (React.createElement("div", { className: "block-slot-wrapper" },
        React.createElement("div", null,
            React.createElement(formik_1.Formik, { initialValues: scfInitialValues, validationSchema: schema_1.SCF_SCHEMA, validateOnBlur: false, enableReinitialize: true, onSubmit: submitHandlre }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                        React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                            React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: function () { return nav(-1); } }),
                            React.createElement("h2", null, center_id ? "Edit SCF" : "Add SCF")),
                        React.createElement("div", { className: "page-control-wrapper" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return nav(-1); } }),
                            React.createElement(button_component_1.default, { buttonName: "Submit", color: "yellow", type: "submit" }))),
                    React.createElement("div", { className: "add-slotform form-filed-wrapper" },
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { name: "warehouse_code", disableClearable: true, placeholder: "Select warehouse code", label: "Warehouse Code", isDisabled: center_id ? true : false, dropDownList: primaryClientData.length > 0 ? primaryClientData : [], onChange: function (val) {
                                        setFieldValue('invoice_code', val.invoice_code);
                                        setFieldValue('client_name', val.client_name);
                                        setFieldValue('state', val.state_name);
                                        setFieldValue('city', val.city_name);
                                        setFieldValue('lab_code', val.lab_code);
                                        setFieldValue('lab_name', val.lab_name);
                                        setFieldValue('route_type', val.route_type);
                                        setFieldValue('company_id', val.company_id);
                                        setFieldTouched('warehouse_code', true);
                                        setFieldValue('warehouse_code', val);
                                    }, value: values.warehouse_code, ontextChange: ontextChange, onClose: onAutoCompleteClose }),
                                errors['warehouse_code'] && touched['warehouse_code'] ? (React.createElement("div", { className: "error" },
                                    errors['warehouse_code'],
                                    " ")) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "invoice_code", label: "Invoice Code", value: values.invoice_code, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "client_name", label: "Client Name", value: values.client_name, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "state", label: "State", value: values.state, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "city", label: "City", value: values.city, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "lab_code", label: "Labcode", value: values.lab_code, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "lab_name", placeholder: "Lane", label: "Lab Name", value: values.lab_name, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "v", label: "Route Type", value: values.route_type, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "company_id", label: "Company ID", value: values.company_id, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { name: "payment_type", disableClearable: true, placeholder: "Select payment type", label: "Payment Type", dropDownList: scf_payment_Types ? scf_payment_Types : [], onChange: function (val) {
                                        if (val.id == scf_service_1.SCF_PAYMENT_TYPES.FIXED || val.id == scf_service_1.SCF_PAYMENT_TYPES.MONTHLY) {
                                            setFieldValue('onSaleCourieChargesTypes', true),
                                                setFieldValue('payout1', null),
                                                setFieldValue('payout2', null),
                                                setFieldValue('payout3', null),
                                                setFieldTouched('monthly_daily', false);
                                            setFieldValue('monthly_daily', scf_payment_mode[1]);
                                            setFieldTouched('courier_charges_type', false);
                                            setFieldValue('courier_charges_type', scf_courier_charges_types[0]);
                                        }
                                        else if (val.id == scf_service_1.SCF_PAYMENT_TYPES.ON_SALES) {
                                            setFieldValue('onSaleCourieChargesTypes', true),
                                                setFieldValue('payout1', null),
                                                setFieldValue('payout2', null),
                                                setFieldValue('payout3', null),
                                                setFieldTouched('monthly_daily', false);
                                            setFieldValue('monthly_daily', null);
                                            setFieldTouched('courier_charges_type', false);
                                            setFieldValue('courier_charges_type', scf_courier_charges_types[1]);
                                        }
                                        else if (val.id == scf_service_1.SCF_PAYMENT_TYPES.PER_DAY) {
                                            setFieldValue('onSaleCourieChargesTypes', true),
                                                setFieldValue('payout1', null);
                                            setFieldValue('payout2', null);
                                            setFieldValue('payout3', null);
                                            setFieldTouched('monthly_daily', false);
                                            setFieldValue('monthly_daily', scf_payment_mode[0]);
                                            setFieldTouched('courier_charges_type', false);
                                            setFieldValue('courier_charges_type', scf_courier_charges_types[0]);
                                        }
                                        else {
                                            setFieldValue('onSaleCourieChargesTypes', true),
                                                setFieldValue('payout1', null);
                                            setFieldValue('payout2', null);
                                            setFieldValue('payout3', null);
                                            setFieldTouched('monthly_daily', false);
                                            setFieldValue('monthly_daily', null);
                                            setFieldTouched('courier_charges_type', false);
                                            setFieldValue('courier_charges_type', null);
                                        }
                                        setFieldTouched('payment_type', true);
                                        setFieldValue('payment_type', val);
                                    }, value: values.payment_type }),
                                errors['payment_type'] && touched['payment_type'] ? (React.createElement("div", { className: "error" },
                                    errors['payment_type'],
                                    " ")) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { name: "monthly_daily", disableClearable: true, isDisabled: checkPaymentType(values), placeholder: "Select payment mode", label: "Payment Mode", dropDownList: scf_payment_mode ? scf_payment_mode : [], onChange: function (val) {
                                        setFieldValue('monthly_daily', true);
                                        setFieldValue('monthly_daily', val);
                                    }, value: values.monthly_daily }),
                                errors['monthly_daily'] && touched['monthly_daily'] ? (React.createElement("div", { className: "error" },
                                    errors['monthly_daily'],
                                    " ")) : null),
                            values.onSaleCourieChargesTypes && values.monthly_daily && values.payment_type.label !== 'ON_SALES' &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement(drop_down_component_1.default, { name: "courier_charges_type", disableClearable: true, isDisabled: true, placeholder: "Select courier charges type", label: "Courier Charges Type", dropDownList: scf_courier_charges_types ? scf_courier_charges_types : [], onChange: function (val) {
                                            setFieldValue('courier_charges', '');
                                            setFieldValue('courier_charges_type', true);
                                            setFieldValue('courier_charges_type', val);
                                        }, value: values.courier_charges_type }),
                                    errors['courier_charges_type'] && touched['courier_charges_type'] ? (React.createElement("div", { className: "error" },
                                        errors['courier_charges_type'],
                                        " ")) : null),
                            values.payment_type && values.payment_type.label == 'ON_SALES' && values.monthly_daily && values.monthly_daily != null &&
                                React.createElement(Grid_1.default, { item: true, xs: 12 },
                                    React.createElement("div", { className: "radio-btn-wrapper green-tick column-type-radios" },
                                        React.createElement(material_1.FormLabel, { className: "radio-btn-label" }, "Select Courier Charges Type"),
                                        React.createElement(material_1.RadioGroup, { row: true, name: "type", key: Math.random().toString(), defaultValue: values.onSaleCourieChargesTypes, onChange: function () {
                                                !values.onSaleCourieChargesTypes ? setFieldValue('courier_charges_type', { id: 1, label: "PERCENTAGE", value: 'PERCENTAGE' }) : setFieldValue('courier_charges_type', { id: 2, label: "SLABS", value: 'SLABS' });
                                                setFieldValue('courier_charges', null);
                                                setFieldValue('payout1', null);
                                                setFieldValue('payout2', null);
                                                setFieldValue('payout3', null);
                                                setFieldValue('onSaleCourieChargesTypes', true);
                                                setFieldValue('onSaleCourieChargesTypes', values.onSaleCourieChargesTypes ? false : true);
                                            } },
                                            React.createElement(material_1.FormControlLabel, { value: true, control: React.createElement(material_1.Radio, null), label: "Percentage" }),
                                            React.createElement(material_1.FormControlLabel, { value: false, control: React.createElement(material_1.Radio, null), label: "Slabs" })))),
                            values.onSaleCourieChargesTypes &&
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement("div", { className: 'input-wrapper' },
                                        React.createElement(material_1.TextField, { fullWidth: true, autoComplete: "off", name: "courier_charges", placeholder: "Enter courier charges", label: "Courier charges", onKeyPress: function (e) {
                                                var pattern = values.payment_type && values.payment_type.label == 'ON_SALES' ? new RegExp(/^[0-9.]+$/).test(e.key) : new RegExp(/^[0-9]+$/).test(e.key);
                                                if (pattern) {
                                                }
                                                else {
                                                    e.preventDefault();
                                                }
                                            }, variant: "filled", InputProps: {
                                                endAdornment: (React.createElement(React.Fragment, null, values.courier_charges_type && values.courier_charges_type.value != 'NUMBER' ? React.createElement(material_1.InputAdornment, { position: "end" }, "%") : React.createElement(React.Fragment, null))),
                                            }, value: values.courier_charges, onChange: function (event) {
                                                setFieldValue('courier_charges', true);
                                                setFieldValue('courier_charges', event.target.value);
                                            } })),
                                    errors['courier_charges'] && touched['courier_charges'] ? (React.createElement("div", { className: "error" },
                                        errors['courier_charges'],
                                        " ")) : null),
                            !values.onSaleCourieChargesTypes &&
                                React.createElement(React.Fragment, null,
                                    React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                        React.createElement(text_filed_component_1.default, { name: "saleValueTen_fifteen", label: scf_slabs_list ? Object.values(scf_slabs_list)[0] : '--', value: scf_slabs_list ? Object.values(scf_slabs_list)[0] : '--', isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                        React.createElement(drop_down_component_1.default, { name: "payout1", disableClearable: true, placeholder: "Select Payout", label: "Payout", dropDownList: scf_service_1.SCF_PAYOUT_LIST, onChange: function (val) {
                                                setFieldValue('payout1', true);
                                                setFieldValue('payout1', val);
                                            }, value: values.payout1 }),
                                        errors['payout1'] && touched['payout1'] ? (React.createElement("div", { className: "error" },
                                            errors['payout1'],
                                            " ")) : null),
                                    React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                        React.createElement(text_filed_component_1.default, { name: "saleValueSixteen_thirtyFive", label: scf_slabs_list ? Object.values(scf_slabs_list)[1] : '--', value: scf_slabs_list ? Object.values(scf_slabs_list)[1] : '--', isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                        React.createElement(drop_down_component_1.default, { name: "payout2", disableClearable: true, placeholder: "Select Payout", label: "Payout", dropDownList: scf_service_1.SCF_PAYOUT_LIST, onChange: function (val) {
                                                setFieldValue('payout2', true);
                                                setFieldValue('payout2', val);
                                            }, value: values.payout2 }),
                                        errors['payout2'] && touched['payout2'] ? (React.createElement("div", { className: "error" },
                                            errors['payout2'],
                                            " ")) : null),
                                    React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                        React.createElement(text_filed_component_1.default, { name: "saleValueGreaterthanthirtyFive", label: scf_slabs_list ? Object.values(scf_slabs_list)[2] : '--', value: scf_slabs_list ? Object.values(scf_slabs_list)[2] : '--', isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                        React.createElement(drop_down_component_1.default, { name: "payout3", disableClearable: true, placeholder: "Select Payout", label: "Payout", dropDownList: scf_service_1.SCF_PAYOUT_LIST, onChange: function (val) {
                                                setFieldValue('payout3', true);
                                                setFieldValue('payout3', val);
                                            }, value: values.payout3 }),
                                        errors['payout3'] && touched['payout3'] ? (React.createElement("div", { className: "error" },
                                            errors['payout3'],
                                            " ")) : null))))));
            }))));
};
exports.default = CreateSCF;
