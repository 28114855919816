"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var FE_Creation_action_1 = require("../../../../actions/FE_Creation_action");
var api_service_1 = require("../../../../api/api_service");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var file_upload_component_1 = require("../../../../common/file_upload/file_upload_component");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var vendor_creation_tab_constant_1 = require("../../../../constants/vendor_creation_tab_constant");
var generic_api_calls_1 = require("../../../generic_api_calls");
var Vendor_PersonalDetails = function (_a) {
    var initialValues = _a.initialValues, saveFormData = _a.saveFormData, updateValue = _a.updateValue, value = _a.value, sendItForApproval = _a.sendItForApproval, onBoardingStatus = _a.onBoardingStatus, User_ProfileInitialState = _a.User_ProfileInitialState, vendor_ids_list = _a.vendor_ids_list;
    var vendor_type = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).vendor_type;
    var dispatch = (0, react_redux_1.useDispatch)();
    var entity_type = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.entity_type; });
    var isNewVendor = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer.isNewVendor; });
    var location = (0, react_router_1.useLocation)();
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var _b = React.useState([]), vendorid = _b[0], setVendorId = _b[1];
    console.log(vendorid);
    var uploadFile = function (val) {
        var payload = new FormData();
        payload.append('cc_id', 'vendor');
        payload.append('file', val);
        payload.append('file_type', 'Other');
        return (0, generic_api_calls_1.fileUpload)(payload);
    };
    var vendor_code = [];
    React.useEffect(function () {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.VENDOR_LIST,
            payLoad: { onboard_status: 2 },
            domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
        })
            .then(function (res) {
            var _a;
            (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data.map(function (data) {
                console.log(data, 'dddaaa');
                vendor_code.push({
                    id: String("".concat(data.name)),
                    label: String("".concat(data.axapta_code, ",").concat(data.name)),
                });
            });
            setVendorId(vendor_code);
            vendor_ids_list(vendor_code, true);
        });
    }, [initialValues]);
    React.useEffect(function () {
        var vendor_ids = [];
        vendor_type === null || vendor_type === void 0 ? void 0 : vendor_type.map(function (data) {
            var _a;
            vendor_ids.push((_a = {}, _a['id'] = data.id, _a['label'] = data.id, _a));
        });
        setVendorId(vendor_ids);
    }, [vendor_type]);
    var formSubmitHnadler1 = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, personalDetailsObj;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log(values.uploadPhoto, 'vvvvvvvvv');
                    payload = {};
                    personalDetailsObj = {};
                    personalDetailsObj['name'] = values.vendorName;
                    personalDetailsObj['entity_type'] =
                        values.entityType != '' &&
                            values.entityType != null &&
                            values.entityType != undefined
                            ? values.entityType.id
                            : '';
                    personalDetailsObj['contact_person_designation'] = values.designation;
                    personalDetailsObj['contact_mobile'] = values.mobilenumber;
                    personalDetailsObj['contact_email'] = values.email;
                    personalDetailsObj['contact_name'] = values.contactperson;
                    if (values.alternateNumber) {
                        personalDetailsObj['alternate_mobile'] = values.alternateNumber;
                    }
                    if (values.alternateNumber) {
                        personalDetailsObj['contact_website'] = values.website;
                    }
                    if (!(typeof values.uploadPhoto === 'object')) return [3, 2];
                    return [4, uploadFile(values.uploadPhoto).then(function (res) {
                            if (res.data.statusCode === 200) {
                                personalDetailsObj['vendor_logo'] = res.data.data.name;
                            }
                            else {
                                personalDetailsObj['vendor_logo'] = values.uploadPhoto;
                            }
                        })];
                case 1:
                    _a.sent();
                    return [3, 3];
                case 2:
                    personalDetailsObj['vendor_logo'] = values.uploadPhoto;
                    _a.label = 3;
                case 3:
                    payload['axapta_code'] = isNewVendor
                        ? values.vendor_id
                        : values.vendor_id != '' &&
                            values.vendor_id != null &&
                            values.vendor_id != undefined
                            ? values.vendor_id.label.split(',')[0].toString().replace(/\s/g, '')
                            : '';
                    payload['personal_details'] = personalDetailsObj;
                    payload['is_final_submit'] = false;
                    api_service_1.api_service
                        .post({
                        endPoint: api_urls_1.api_urls.VENDOR_CREATION,
                        payLoad: payload,
                        domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
                    })
                        .then(function (res) {
                        if (res.data.statusCode === 200) {
                            react_hot_toast_1.default.success(res.data.message);
                            saveFormData(values);
                            if (location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor))) {
                                nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.editVendor, "/").concat(id, "/").concat(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTACTDETAILS));
                                updateValue(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTACTDETAILS);
                            }
                            else {
                                nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.createVendor, "/").concat(isNewVendor
                                    ? values.vendor_id
                                    : values.vendor_id.label
                                        .split(',')[0]
                                        .toString()
                                        .replace(/\s/g, ''), "/").concat(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTACTDETAILS));
                                updateValue(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTACTDETAILS);
                            }
                        }
                        else {
                            react_hot_toast_1.default.error(res.data.message);
                        }
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.data.errors);
                    });
                    return [2];
            }
        });
    }); };
    return (React.createElement("div", { className: "form-filed-wrapper" },
        React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", null, "Lets start with Vendor personal details"),
            React.createElement("p", { className: "sub-heaidng" }, "Take the information about the Vendor")),
        React.createElement(formik_1.Formik, { validateOnBlur: false, initialValues: __assign({}, initialValues), validationSchema: schema_1.schemas.VENDOR_PROFILE_DETAILS_SCHEMA, onSubmit: function (values) {
                formSubmitHnadler1(values);
            }, enableReinitialize: true }, function (_a) {
            var errors = _a.errors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched;
            return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        isNewVendor ? (React.createElement(formik_1.Field, { name: "vendor_id" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Axapta code", name: "vendor_id", required: true, maxLength: 255, isDisabled: location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor)) || id
                                    ? true
                                    : false, onKeyPress: function (e) {
                                    if (new RegExp(/^[a-zA-Z0-9 ]*$/).test(e.key)) {
                                    }
                                    else {
                                        e.preventDefault();
                                    }
                                    var key = e.charCode;
                                    if (key === 32) {
                                        event.preventDefault();
                                    }
                                }, onChange: function (e) {
                                    setFieldTouched('vendor_id', true);
                                    setFieldValue('vendor_id', e);
                                }, value: values === null || values === void 0 ? void 0 : values.vendor_id }));
                        })) : (React.createElement(formik_1.Field, { name: "vendor_id" }, function () {
                            return (React.createElement(drop_down_component_1.default, { name: "vendor_id", placeholder: "Select Vendor ID", required: true, dropDownList: vendorid, label: "Select Vendor ID", isDisabled: location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor)) && id
                                    ? true
                                    : false, onChange: function (val) {
                                    if (val === null ||
                                        val === '' ||
                                        val === undefined) {
                                        setFieldValue('vendor_id', '');
                                        setFieldValue('vendorName', '');
                                    }
                                    else {
                                        setFieldValue('vendorName', val.id);
                                        setFieldValue('vendor_id', val);
                                        setFieldTouched('vendor_id', true);
                                        setFieldTouched('vendorName', false);
                                    }
                                }, value: values === null || values === void 0 ? void 0 : values.vendor_id, key: vendorid.toString() }));
                        })),
                        React.createElement(formik_1.ErrorMessage, { name: "vendor_id" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" }, errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement("div", { className: "form-btn-wrapper" }, !id ? (React.createElement(button_component_1.default, { buttonName: !isNewVendor ? 'New Vendor' : 'Cancel', color: "yellow-outline", onClickHandler: function () {
                                setFieldValue('vendor_id', '');
                                setFieldValue('vendorName', '');
                                dispatch((0, FE_Creation_action_1.checkIsNewVendor)(!isNewVendor));
                            } })) : (React.createElement(React.Fragment, null)))),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "vendorName" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Vendor Name", name: "vendorName", required: true, minLength: 1, maxLength: 50, isDisabled: location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor)) || id
                                    ? true
                                    : false, defaultVal: values === null || values === void 0 ? void 0 : values.vendorName, onChange: function (e) {
                                    if (isNewVendor) {
                                        setFieldTouched('vendorName', true);
                                        setFieldValue('vendorName', e);
                                    }
                                }, value: values === null || values === void 0 ? void 0 : values.vendorName }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "vendorName" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" }, errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "entityType" }, function () {
                            return (React.createElement(drop_down_component_1.default, { name: "entityType", placeholder: "Entity Type", dropDownList: entity_type, label: "Entity Type", required: true, onChange: function (val) {
                                    setFieldTouched('entityType', true);
                                    setFieldValue('entityType', val);
                                }, value: values === null || values === void 0 ? void 0 : values.entityType }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "entityType" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" }, errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 12 },
                        React.createElement("h2", { className: "label-heading heading" }, "Contact Details")),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "contactperson" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Contact Person\u2019s Name", name: "contactperson", required: true, minLength: 1, maxLength: 50, defaultVal: values === null || values === void 0 ? void 0 : values.contactperson, value: values === null || values === void 0 ? void 0 : values.contactperson, onKeyPress: function (e) {
                                    if (new RegExp(/^[a-zA-Z ]*$/).test(e.key)) {
                                    }
                                    else {
                                        e.preventDefault();
                                    }
                                }, onChange: function (e) {
                                    setFieldTouched('contactperson', true);
                                    setFieldValue('contactperson', e);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "contactperson" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" }, errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "designation" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Designation", name: "designation", required: true, maxLength: 50, defaultVal: values === null || values === void 0 ? void 0 : values.designation, value: values === null || values === void 0 ? void 0 : values.designation, onKeyPress: function (e) {
                                    if (new RegExp(/^[a-zA-Z ]*$/).test(e.key)) {
                                    }
                                    else {
                                        e.preventDefault();
                                    }
                                }, onChange: function (e) {
                                    setFieldTouched('designation', true);
                                    setFieldValue('designation', e);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "designation" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" }, errorMsg))); }),
                        ' '),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "mobilenumber" }, function () {
                            return (React.createElement(text_filed_component_1.default, { label: "Mobile Number", name: "mobilenumber", required: true, defaultVal: values === null || values === void 0 ? void 0 : values.mobilenumber, value: values === null || values === void 0 ? void 0 : values.mobilenumber, onKeyPress: function (e) {
                                    if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                        e.target.value.length <= 9) {
                                    }
                                    else {
                                        e.preventDefault();
                                    }
                                }, onChange: function (e) {
                                    setFieldTouched('mobilenumber', true);
                                    setFieldValue('mobilenumber', e);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "mobilenumber" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" }, errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "email" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Email Address", type: "", name: "email", required: true, defaultVal: values === null || values === void 0 ? void 0 : values.email, value: values === null || values === void 0 ? void 0 : values.email, onChange: function (e) {
                                    setFieldTouched('email', true);
                                    setFieldValue('email', e);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "email" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" }, errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "alternateNumber" }, function () {
                            return (React.createElement(text_filed_component_1.default, { label: "Alternate Mobile Number", name: "alternateNumber", defaultVal: values === null || values === void 0 ? void 0 : values.alternateNumber, value: values === null || values === void 0 ? void 0 : values.alternateNumber, onKeyPress: function (e) {
                                    if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                        e.target.value.length <= 9) {
                                    }
                                    else {
                                        e.preventDefault();
                                    }
                                }, onChange: function (e) {
                                    setFieldTouched('alternateNumber', true);
                                    setFieldValue('alternateNumber', e);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "alternateNumber" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" }, errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "website" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Website", name: "website", maxLength: 250, defaultVal: values === null || values === void 0 ? void 0 : values.website, value: values === null || values === void 0 ? void 0 : values.website, onChange: function (e) {
                                    setFieldTouched('website', true);
                                    setFieldValue('website', e);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "website" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" }, errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 9 },
                        React.createElement(formik_1.Field, { name: "uploadPhoto" }, function () {
                            return (React.createElement(file_upload_component_1.default, { buttonName: "Upload Vendor Photo", name: "uploadPhoto", message: "Note:Only png, jpg, jpeg formats are allowed upto 2MB", value: values === null || values === void 0 ? void 0 : values.uploadPhoto, requiredClass: true, acceptedFiletypes: ['image/jpeg', 'image/png'], maxSize: {
                                    image: 2,
                                    pdf: 2,
                                    csv: 2,
                                }, onChange: function (e) {
                                    console.log(e, 'eeeee');
                                    setFieldTouched('uploadPhoto', true);
                                    setFieldValue('uploadPhoto', e);
                                }, key: '' }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "uploadPhoto" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true },
                            React.createElement("div", { className: "error" }, errorMsg))); }))),
                React.createElement("div", { className: "form-btns" },
                    React.createElement(button_component_1.default, { buttonName: "Save & Continue", type: "submit", color: "yellow" }))));
        })));
};
exports.default = Vendor_PersonalDetails;
