"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var conroldashboardstyles_1 = require("../../control_dashboard/list/conroldashboardstyles");
var AnswerTable_1 = require("./AnswerTable");
var ShortAnswerQuestion = function (_a) {
    var name = _a.name, is_image_required = _a.is_image_required, question_type = _a.question_type, questionNum = _a.questionNum, numeric = _a.numeric, options = _a.options, totalAttempts = _a.totalAttempts, images = _a.images, _id = _a._id;
    var classes = (0, conroldashboardstyles_1.useStyles)();
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleDrawerClose = function () {
        setOpen(false);
    };
    var displayTableData = function () {
        setOpen(!open);
    };
    return (React.createElement("div", { className: "question-container" },
        React.createElement("div", { className: "question-wrapper" },
            React.createElement("span", { className: "sr-no" }, "Q".concat(questionNum, ".")),
            React.createElement(text_filed_component_1.default, { label: name, isDisabled: true }),
            React.createElement("div", { className: "audit-attempts-wrapper" },
                React.createElement("p", { className: "attempts-label" }, "Total Attempts"),
                React.createElement("p", { className: "attempts-value" }, totalAttempts)),
            React.createElement(material_1.IconButton, { className: "icon-btn", onClick: function () { return displayTableData(); } },
                React.createElement("span", { className: "icon-Vector" }))),
        React.createElement("div", { className: "options-container " }, options.map(function (option) {
            return (React.createElement("div", { className: "option-wrapper audit-summary-options" },
                React.createElement("div", { className: "progress-label-wrapper" },
                    React.createElement("div", { className: "progress-bar-wrapper" },
                        React.createElement(material_1.LinearProgress, { variant: "determinate", value: option.percentage ? option.percentage : 0, color: "success" })),
                    React.createElement("label", { className: "progress-label" },
                        option.percentage ? Math.floor(option.percentage) : 0,
                        "%"))));
        })),
        open ?
            React.createElement(material_1.Drawer, { variant: "temporary", anchor: "bottom", open: open, className: classes.customDrawer },
                React.createElement("div", { onClick: handleDrawerClose, className: "drawer-close-btn" },
                    React.createElement("span", { className: "icon-close" })),
                React.createElement(AnswerTable_1.default, { question_id: _id, name: name, question_type: question_type }),
                ")") : ''));
};
exports.default = ShortAnswerQuestion;
