"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.masterShipmentJourney = exports.shipmentJourney = exports.getShipmentDetails = exports.getShipmentList = void 0;
var react_hot_toast_1 = require("react-hot-toast");
var api_service_1 = require("../../../api/api_service");
var getShipmentList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.shipmentList,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getShipmentList = getShipmentList;
var getShipmentDetails = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: payload,
            domain: process.env.SAMPLE_TRAKING_URL,
        })
            .then(function (res) {
            console.log(res, 'ijuhg');
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getShipmentDetails = getShipmentDetails;
var shipmentJourney = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.shipmentJourney, "?shipment_barcode=").concat(payload),
            domain: process.env.SAMPLE_TRAKING_URL,
        })
            .then(function (res) {
            console.log(res, 'ijuhg');
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.shipmentJourney = shipmentJourney;
var masterShipmentJourney = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.masterShipmentJourney, "?master_shipment_barcode=").concat(payload),
            domain: process.env.SAMPLE_TRAKING_URL,
        })
            .then(function (res) {
            console.log(res, 'ijuhg');
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.masterShipmentJourney = masterShipmentJourney;
