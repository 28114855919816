"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var SlotsComponent = function (_a) {
    var data = _a.data;
    console.log(data, 'params in skots component');
    return (React.createElement("div", null,
        React.createElement("ol", { type: "a" }, data.slots.map(function (singleItem) {
            return (React.createElement(React.Fragment, null,
                React.createElement("li", null, "".concat(singleItem.slot_name, ",").concat(singleItem.slot_from_time, "- ").concat(singleItem.slot_from_time))));
        }))));
};
exports.default = SlotsComponent;
