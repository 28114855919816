"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FE_Creation_action_1 = require("./FE_Creation_action");
var audit_actions_1 = require("./audit_actions");
var loader_action_1 = require("./loader_action");
var user_action_1 = require("./user_action");
var app_Actions = {
    user_Actions: user_action_1.default,
    loader_Actions: loader_action_1.default,
    FE_CREATION_ACTIONS: FE_Creation_action_1.FE_CREATION_ACTIONS,
    audit_actions: audit_actions_1.default,
};
exports.default = app_Actions;
