"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var maps_api_for_javascript_1 = require("@here/maps-api-for-javascript");
var ExpandMore_1 = require("@mui/icons-material/ExpandMore");
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var api_service_1 = require("../../../../api/api_service");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var ModalComponentWithoutButton_1 = require("../../../../common/modal/ModalComponentWithoutButton");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../../constants/appConstants");
var images_1 = require("../../../../constants/images");
var map_constants_1 = require("../../../../constants/map_constants");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var heat_map_api_service_1 = require("../../heat_maps/heat_map_api_service");
require(".././routers.scss");
var route_planner_api_service_1 = require("../api/route_planner_api_service");
var routePlannerConstant_1 = require("../Constants/routePlannerConstant");
var route_planner_service_1 = require("../service/route_planner_service");
var route_planner_form_1 = require("./route_planner_form");
var fesCount = 0;
var EMPTY_VALUE = '--';
var ModifyRoutePlanner = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var id = (0, react_router_1.useParams)().id;
    var visitTypesList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).visitTypesList;
    var pathname = (0, react_router_1.useLocation)().pathname;
    var nav = (0, react_router_1.useNavigate)();
    var mapRef = React.useRef(null);
    var _q = React.useState([]), selectedFEId = _q[0], setSlectedFEId = _q[1];
    var _r = React.useState([]), unassignedVisitData = _r[0], setUnAssignedVisitData = _r[1];
    var _s = React.useState([]), feId_list = _s[0], setFeIdList = _s[1];
    var _t = React.useState(), routePlannerDetils = _t[0], setRoutePlannerDetials = _t[1];
    var _u = React.useState([]), updatedLocationInfo = _u[0], setUpdatedLocationInfo = _u[1];
    var _v = React.useState(), routeVists = _v[0], setRouteVists = _v[1];
    var _w = React.useState(), unAssigedFes = _w[0], setUnAssigedFes = _w[1];
    var _x = React.useState(), errorMessage = _x[0], setErrorMessage = _x[1];
    var _y = React.useState(''), selctedLatLong = _y[0], setSelctedLatLong = _y[1];
    var _z = React.useState(false), showLatLng = _z[0], setshowLatLng = _z[1];
    var _0 = React.useState(false), open = _0[0], setOpen = _0[1];
    var _1 = React.useState(false), isAppRejectModa = _1[0], setIsAppRejectModa = _1[1];
    var _2 = React.useState(route_planner_service_1.APPROVE), approveOrReject = _2[0], setApproveOrReject = _2[1];
    var _3 = React.useState(false), isSendItForApproval = _3[0], setIsSendItForApproval = _3[1];
    var _4 = React.useState([]), downloadableRoutesDetails = _4[0], setDownloadableRoutesDetails = _4[1];
    console.log(selctedLatLong, showLatLng);
    console.log(unassignedVisitData, 'unassignedVisitData', unAssigedFes);
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Route_Planner);
    var getRoutePlannerDetails = function (endPoint, payLoad, domain) {
        (0, route_planner_api_service_1.globalRoutePlannerGetAPI)(endPoint, payLoad, domain)
            .then(function (response) {
            setRoutePlannerDetials(response);
            setErrorMessage('');
            if (Object.keys(response).length > 0) {
                if (response.route.length > 0) {
                    var data = (0, route_planner_service_1.filterRoutePlannerUniqueFEData)(response.route);
                    var removedNullUser = data && data.filter(function (val) { return val.fe_id; });
                    console.log(data, 'toatalFEs', removedNullUser);
                    var feId = (0, route_planner_service_1.filterFE_IdsForRoutePlanner)(removedNullUser);
                    if (response.unassigned_visits.length > 0) {
                        var unassigedData = (0, route_planner_service_1.filterRoutePlannerUnassiedVisit)(response.unassigned_visits);
                        setUnAssignedVisitData(unassigedData);
                    }
                    if (response.unassigned_fes && response.unassigned_fes.length > 0) {
                        var unassigedfes = (0, route_planner_service_1.filterUnassingedFEs)(response.unassigned_fes);
                        setUnAssigedFes(unassigedfes);
                    }
                    setFeIdList(feId);
                    setSlectedFEId(feId);
                    setRouteVists(removedNullUser);
                    var downloadableExcelData_1 = (0, route_planner_service_1.convertExcelDataFromRoutes)(response.route);
                    downloadableExcelData_1.map(function (singleRow, index) { return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    if (!singleRow.visit_type) return [3, 2];
                                    _a = downloadableExcelData_1[index];
                                    return [4, (0, array_helpers_1.getVisitTypeFromID)(parseInt(singleRow.visit_type.toString()), visitTypesList)];
                                case 1:
                                    _a.visit_type = _b.sent();
                                    _b.label = 2;
                                case 2: return [2];
                            }
                        });
                    }); });
                    setDownloadableRoutesDetails(downloadableExcelData_1);
                    console.log(data, 'printVists');
                }
            }
        })
            .catch(function (err) {
            setErrorMessage(err);
        });
    };
    var FE_IDropdownHandler = function (value) {
        setSlectedFEId(value);
    };
    console.log(selectedFEId, 'selectedFEIdd');
    var refetch = (0, react_query_1.useQuery)('route_planner_details', function () {
        getRoutePlannerDetails(api_urls_1.api_urls.ROUTE_PLANEER_PROFILE_DETAILS, { route_id: id }, process.env.ONE_TWO_LEVEL_APPROVAL_URL);
    }).refetch;
    var pageTitle = pathname.includes("".concat(route_path_1.PATHS.create_route_planner))
        ? 'Review Route'
        : 'View Route Details';
    console.log(routePlannerDetils, showLatLng);
    var callAssignedVisits = function (map, ui) {
        var singleFEVisitCount = 0;
        var selectedVisitList = (0, route_planner_service_1.filterSelectedRouteVisitList)(routeVists, selectedFEId);
        console.log(selectedVisitList, 'selectedVisitList');
        selectedVisitList &&
            selectedVisitList.map(function (element, index) {
                singleFEVisitCount++;
                fesCount = index >= 1 ? index - index : fesCount;
                var lineString = new maps_api_for_javascript_1.default.geo.LineString();
                var verticeGroup = new maps_api_for_javascript_1.default.map.Group();
                var feLatitude = element.fe_current_location.latitude;
                var feLongitude = element.fe_current_location.longitude;
                var FEstartEndInfo = {
                    startPointInfo: element,
                    endPointInfo: element,
                };
                var styleLine = {
                    style: {
                        lineWidth: 2.5,
                        fillColor: 'white',
                        strokeColor: map_constants_1.colorCodes[singleFEVisitCount],
                        lineTailCap: 'arrow-tail',
                        lineDash: [1, 1],
                        lineHeadCap: 'arrow-head',
                    },
                    data: {
                        id: element.user_id,
                        lat: feLatitude,
                        lng: feLongitude,
                        fe_id: element.fe_code,
                        visit_type: element.visit_type,
                        feName: element.fe_name,
                        base_location_address: element.base_location_address,
                        startLocation: FEstartEndInfo.startPointInfo.center_name,
                        endLocation: FEstartEndInfo.endPointInfo.center_name,
                    },
                };
                lineString.pushPoint({
                    lat: feLatitude,
                    lng: feLongitude,
                });
                var FEMarker = new maps_api_for_javascript_1.default.map.Marker({
                    lat: feLatitude,
                    lng: feLongitude,
                });
                var isDraggableFE = pathname.includes("".concat(route_path_1.PATHS.create_route_planner))
                    ? true
                    : false;
                FEMarker.draggable = isDraggableFE;
                (0, route_planner_service_1.markerEvents)(FEMarker, FEstartEndInfo.startPointInfo, ui, false);
                FEMarker.setData({
                    verticeIndex: index,
                    data: FEstartEndInfo.startPointInfo,
                });
                element.visites &&
                    element.visites.map(function (visitElementes, visitIndex) {
                        try {
                            var lat = visitElementes.latitude;
                            var lng = visitElementes.longitude;
                            var startEndInfo = {
                                startPointInfo: visitElementes,
                                endPointInfo: visitElementes,
                            };
                            console.log(startEndInfo.startPointInfo, 'startEndInfo');
                            var styleLine_1 = {
                                style: {
                                    lineWidth: 2.5,
                                    fillColor: 'white',
                                    strokeColor: map_constants_1.colorCodes[singleFEVisitCount],
                                    lineTailCap: 'arrow-tail',
                                    lineDash: [1, 1],
                                    lineHeadCap: 'arrow-head',
                                },
                                data: {
                                    id: element.user_id,
                                    lat: visitIndex == 0
                                        ? element.fe_current_location.latitude
                                        : element.visites[visitIndex].latitude,
                                    lng: visitIndex == 0
                                        ? element.fe_current_location.longitude
                                        : element.visites[visitIndex].longitude,
                                    scheduled_time: element.visites[visitIndex].scheduled_time,
                                    fe_id: element.fe_code,
                                    visit_type: visitIndex == 0
                                        ? element.visit_type
                                        : element.visites[visitIndex].visit_type,
                                    feName: element.fe_name,
                                    base_location_address: element.base_location_address,
                                    startLocation: startEndInfo.startPointInfo.center_name,
                                    endLocation: startEndInfo.endPointInfo.center_name,
                                },
                            };
                            lineString.pushPoint({
                                lat: lat,
                                lng: lng,
                            });
                            var myLocMarker = new maps_api_for_javascript_1.default.map.Marker({
                                lat: lat,
                                lng: lng,
                            });
                            myLocMarker.draggable = false;
                            (0, route_planner_service_1.markerEvents)(myLocMarker, startEndInfo.startPointInfo, ui, false);
                            myLocMarker.setData({
                                verticeIndex: index,
                                data: startEndInfo.startPointInfo,
                            });
                            verticeGroup.addObject(myLocMarker);
                            var draggableMarker_1 = (0, route_planner_service_1.draggableMarkers)(map, verticeGroup, lineString, styleLine_1, setSelctedLatLong, setshowLatLng, setUpdatedLocationInfo, routePlannerDetils && routePlannerDetils.changes);
                            return draggableMarker_1;
                        }
                        catch (e) {
                            console.log(e);
                        }
                    });
                verticeGroup.addObject(FEMarker);
                var draggableMarker = (0, route_planner_service_1.draggableMarkers)(map, verticeGroup, lineString, styleLine, setSelctedLatLong, setshowLatLng, setUpdatedLocationInfo, routePlannerDetils && routePlannerDetils.changes);
                map.getViewModel().setLookAtData({
                    bounds: verticeGroup.getBoundingBox(),
                });
                map.setZoom(14);
                return draggableMarker;
            });
    };
    var callUnassignedVists = function (map, ui) {
        var lineString = new maps_api_for_javascript_1.default.geo.LineString();
        unassignedVisitData.map(function (element, index) {
            try {
                var lat = element.latitude;
                var lng = element.longitude;
                console.log(lat, lng, 'latlonginfo');
                var startEndInfo = {
                    startPointInfo: element,
                    endPointInfo: element,
                };
                console.log(startEndInfo, 'locationInfo');
                var myLocMarker = new maps_api_for_javascript_1.default.map.Marker({
                    lat: lat,
                    lng: lng,
                });
                (0, route_planner_service_1.markerEvents)(myLocMarker, startEndInfo.startPointInfo, ui, true);
                map.addObject(myLocMarker);
                map.addObject(new maps_api_for_javascript_1.default.map.Polyline(lineString));
            }
            catch (e) {
                console.log(e);
            }
        });
    };
    var callUnassignedFEs = function (map, ui, behavior) {
        unAssigedFes.map(function (element, index) {
            try {
                var lat = element.fe_current_location.latitude;
                var lng = element.fe_current_location.longitude;
                console.log(lat, lng, 'latlonginfo');
                var startEndInfo = {
                    startPointInfo: element,
                    endPointInfo: element,
                };
                console.log(startEndInfo, 'locationInfo');
                var smoothDragging = {
                    volatility: true,
                };
                var myLocMarker = new maps_api_for_javascript_1.default.map.Marker({
                    lat: lat,
                    lng: lng,
                }, smoothDragging);
                myLocMarker.draggable = pathname.includes("".concat(route_path_1.PATHS.create_route_planner))
                    ? true
                    : false;
                myLocMarker.setData({
                    verticeIndex: index,
                    data: startEndInfo.startPointInfo,
                });
                (0, route_planner_service_1.markerEvents)(myLocMarker, startEndInfo.startPointInfo, ui, false, 'unassignedFE');
                map.addObject(myLocMarker);
                myLocMarker.addEventListener('dragstart', function (ev) {
                    var target = ev.target, pointer = ev.currentPointer;
                    if (target instanceof maps_api_for_javascript_1.default.map.Marker) {
                        var targetPosition = map.geoToScreen(target.getGeometry());
                        target['offset'] = new maps_api_for_javascript_1.default.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
                        behavior.disable();
                    }
                }, false);
                myLocMarker.addEventListener('dragend', function (evt) {
                    var target = evt.target;
                    var pointer = evt.currentPointer;
                    var geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);
                    evt.target.setGeometry(geoPoint);
                    console.log(evt.target.data.data, 'draggedInfo', geoPoint);
                    (0, route_planner_service_1.draggedInformation)(evt, setUpdatedLocationInfo, geoPoint, routePlannerDetils && routePlannerDetils.changes);
                    if (target instanceof maps_api_for_javascript_1.default.map.Marker) {
                        behavior.enable();
                    }
                }, false);
                myLocMarker.addEventListener('drag', function (ev) {
                    var target = ev.target, pointer = ev.currentPointer;
                    var geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);
                    ev.target.setGeometry(geoPoint);
                    setshowLatLng(true);
                    setSelctedLatLong("".concat(geoPoint.lat, ",").concat(geoPoint.lng));
                    if (target instanceof maps_api_for_javascript_1.default.map.Marker) {
                        target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
                    }
                }, false);
            }
            catch (e) {
                console.log(e);
            }
        });
    };
    var renderLabLocation = function (map, ui) {
        var _a, _b, _c, _d, _e;
        if (((_a = routePlannerDetils === null || routePlannerDetils === void 0 ? void 0 : routePlannerDetils.details) === null || _a === void 0 ? void 0 : _a.lab_details.latitude) &&
            ((_b = routePlannerDetils === null || routePlannerDetils === void 0 ? void 0 : routePlannerDetils.details) === null || _b === void 0 ? void 0 : _b.lab_details.longitude)) {
            var lat = (_c = routePlannerDetils === null || routePlannerDetils === void 0 ? void 0 : routePlannerDetils.details) === null || _c === void 0 ? void 0 : _c.lab_details.latitude;
            var lng = (_d = routePlannerDetils === null || routePlannerDetils === void 0 ? void 0 : routePlannerDetils.details) === null || _d === void 0 ? void 0 : _d.lab_details.longitude;
            console.log(lat, lng, 'latlonginfo');
            var startEndInfo = {
                startPointInfo: (_e = routePlannerDetils === null || routePlannerDetils === void 0 ? void 0 : routePlannerDetils.details) === null || _e === void 0 ? void 0 : _e.lab_details,
                endPointInfo: {},
            };
            console.log(startEndInfo, 'locationInfo');
            var myLocMarker = new maps_api_for_javascript_1.default.map.Marker({
                lat: lat,
                lng: lng,
            });
            (0, route_planner_service_1.LabMarkerEvents)(myLocMarker, startEndInfo.startPointInfo, ui);
            myLocMarker.setIcon(heat_map_api_service_1.lab_marker);
            map.addObject(myLocMarker);
        }
    };
    var addPolylineToMap = function (map, ui, behavior) {
        var _a, _b;
        if (routeVists && routeVists.length > 0) {
            callAssignedVisits(map, ui);
        }
        if (unassignedVisitData && unassignedVisitData.length >= 1) {
            callUnassignedVists(map, ui);
        }
        if (unAssigedFes && unAssigedFes.length >= 1) {
            callUnassignedFEs(map, ui, behavior);
        }
        if (routePlannerDetils &&
            ((_a = routePlannerDetils === null || routePlannerDetils === void 0 ? void 0 : routePlannerDetils.details) === null || _a === void 0 ? void 0 : _a.route_assignment_type) == 'STATIC' &&
            ((_b = routePlannerDetils === null || routePlannerDetils === void 0 ? void 0 : routePlannerDetils.details) === null || _b === void 0 ? void 0 : _b.lab_details)) {
            renderLabLocation(map, ui);
        }
    };
    var rejectRoute = function (values) {
        var payload = {
            route_id: Number(id),
            status: approveOrReject == 'Reject'
                ? routePlannerConstant_1.route_plan_status.Rejected
                : routePlannerConstant_1.route_plan_status.Sent_for_approval,
            rejection_comments: values.comments,
        };
        api_service_1.api_service
            .post({
            endPoint: api_urls_1.api_urls.APPROVE_REJECT_ROUTE,
            payLoad: payload,
            domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
        })
            .then(function (response) {
            if (response.status == 200) {
                react_hot_toast_1.default.success(response.data.message);
                approveOrReject == 'Reject'
                    ? nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_list))
                    : nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.approvals_route_planner));
                setIsAppRejectModa(false);
            }
            else {
                react_hot_toast_1.default.error(response.data.message);
            }
        })
            .catch(function (e) {
            react_hot_toast_1.default.error(e.message);
        });
    };
    var approveRoute = function (values) {
        var payload = {
            route_id: Number(id),
            status: routePlannerConstant_1.route_plan_status.Approved,
        };
        api_service_1.api_service
            .post({
            endPoint: api_urls_1.api_urls.APPROVE_REJECT_ROUTE,
            payLoad: payload,
            domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
        })
            .then(function (response) {
            if (response.status == 200) {
                react_hot_toast_1.default.success(response.data.message);
                nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_list));
                setIsAppRejectModa(false);
            }
            else {
                react_hot_toast_1.default.error(response.data.message);
            }
        })
            .catch(function (e) {
            react_hot_toast_1.default.error(e.message);
        });
    };
    var setStyle = function (map) {
        var provider = map.getBaseLayer().getProvider();
        var style = new maps_api_for_javascript_1.default.map.Style('/fonts/normalDay.yaml', 'https://js.api.here.com/v3/3.1.58.0/styles/omv/omv/');
        provider.setStyle(style);
    };
    React.useEffect(function () {
        refetch();
    }, []);
    React.useLayoutEffect(function () {
        if (!mapRef.current)
            return;
        var platform = new maps_api_for_javascript_1.default.service.Platform({
            apikey: process.env.HERE_MAPS_API_KEY,
        });
        var defaultLayers = platform.createDefaultLayers({ pois: true });
        var map = new maps_api_for_javascript_1.default.Map(mapRef.current, defaultLayers.vector.normal.map.setMin(2), {
            center: { lat: 23.6913, lng: 85.2722 },
            zoom: 5,
            pixelRatio: window.devicePixelRatio || 1,
        });
        setStyle(map);
        var behavior = new maps_api_for_javascript_1.default.mapevents.Behavior(new maps_api_for_javascript_1.default.mapevents.MapEvents(map));
        var ui = maps_api_for_javascript_1.default.ui.UI.createDefault(map, defaultLayers);
        ui.getElement().style.zIndex = 999;
        console.log(ui, behavior);
        addPolylineToMap(map, ui, behavior);
        return function () {
            map.dispose();
        };
    }, [mapRef, routeVists, selectedFEId]);
    var ApproveAndRejectModal = function () {
        return (React.createElement(ModalComponentWithoutButton_1.default, { open: isAppRejectModa },
            React.createElement("div", { className: "modal-body" },
                React.createElement(material_1.Typography, { pb: 3, className: "heading" }, approveOrReject == route_planner_service_1.APPROVE ? 'Approve Route' : 'Reject Route'),
                React.createElement("p", null, approveOrReject != route_planner_service_1.APPROVE
                    ? 'Please mention the reason for the reject route.'
                    : 'Are you sure you want approve the route?.'),
                approveOrReject != route_planner_service_1.APPROVE ? (React.createElement(formik_1.Formik, { initialValues: route_planner_service_1.REJECT_ROUTE_INITIAL_VALUES, validateOnBlur: false, validationSchema: schema_1.REJECT_ROUTE_SCHEMA, onSubmit: rejectRoute }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                        console.log(errors, 'values', values),
                        React.createElement(material_1.Grid, { sm: 3 },
                            ' ',
                            React.createElement(text_filed_component_1.default, { label: "Comments", onChange: function (value) {
                                    setFieldValue('comments', value);
                                    setFieldTouched('comments', true);
                                }, value: values.comments }),
                            errors['comments'] && touched['comments'] ? (React.createElement("div", { className: "error" }, errors['comments'])) : null),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: 'Cancel', color: "yellow-outline", onClickHandler: function () { return setIsAppRejectModa(false); } }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: 'Submit', color: "yellow" }))));
                })) : (React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: 'No', color: "yellow-outline", onClickHandler: function () { return setIsAppRejectModa(false); } }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: 'Yes', color: "yellow", onClickHandler: approveRoute }))))));
    };
    var sendItForAprrovalScreen = function () {
        return (React.createElement(ModalComponentWithoutButton_1.default, { open: isSendItForApproval },
            React.createElement("div", { className: "modal-body" },
                React.createElement(material_1.Typography, { pb: 3, className: "heading" }, "Sent for approval?"),
                React.createElement("p", null, "Please mention the changes made by you"),
                React.createElement(formik_1.Formik, { initialValues: route_planner_service_1.REJECT_ROUTE_INITIAL_VALUES, validateOnBlur: false, validationSchema: schema_1.REJECT_ROUTE_SCHEMA, onSubmit: rejectRoute }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                        console.log(errors, 'values', values),
                        React.createElement(material_1.Grid, { sm: 3, pt: 1.5, mb: 3 },
                            ' ',
                            React.createElement(text_filed_component_1.default, { label: "Comments", onChange: function (value) {
                                    setFieldValue('comments', value);
                                    setFieldTouched('comments', true);
                                }, value: values.comments }),
                            errors['comments'] && touched['comments'] ? (React.createElement("div", { className: "error" }, errors['comments'])) : null),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: 'Cancel', color: "yellow-outline", onClickHandler: function () { return setIsSendItForApproval(false); } }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: 'Submit', color: "yellow" }))));
                }))));
    };
    return (React.createElement("div", null, !errorMessage ? (React.createElement("div", { className: "fe-wrapper leave-tarcking-page" },
        React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom " },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: function () {
                        pathname.includes("".concat(route_path_1.PATHS.create_route_planner))
                            ? nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_list))
                            : pathname.includes("".concat(route_path_1.PATHS.route_planner_approved_details))
                                ? nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_list))
                                : nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.approvals_route_planner));
                    } }),
                React.createElement("h2", null,
                    pageTitle,
                    " - ",
                    '',
                    routePlannerDetils &&
                        routePlannerDetils.details &&
                        routePlannerDetils.details.route_id)),
            pathname.includes("".concat(route_path_1.PATHS.route_planner_details)) ? (React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Reject", color: "yellow-outline", onClickHandler: function () {
                        setApproveOrReject('Reject');
                        setIsAppRejectModa(true);
                    }, isError: !features[menu_constants_1.FEATURE_CONSTANTS.routeReject] }),
                React.createElement(button_component_1.default, { type: "submit", id: "myForm", buttonName: "Approve", color: "yellow", onClickHandler: function () {
                        setApproveOrReject(route_planner_service_1.APPROVE);
                        setIsAppRejectModa(true);
                    }, isError: !features[menu_constants_1.FEATURE_CONSTANTS.routeApprove] }))) : pathname.includes("".concat(route_path_1.PATHS.create_route_planner)) ? (React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { type: "submit", id: "myForm", buttonName: "ReGenerate route", color: "yellow", onClickHandler: function () { return setOpen(true); } }))) : null),
        React.createElement("div", { className: "panner-wrapper" },
            React.createElement("div", { className: "planner-accordian-wrapper" },
                React.createElement(material_1.Accordion, { disableGutters: true, elevation: 0 },
                    React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null), "aria-controls": "panel1a-content", id: "panel1a-header" },
                        React.createElement(material_1.Typography, { className: "accordian-heading" }, "Route Details")),
                    React.createElement(material_1.AccordionDetails, null,
                        React.createElement(material_1.Grid, { container: true, m: 0, className: "readonly-container" },
                            React.createElement(material_1.Grid, { lg: 3, md: 3 },
                                React.createElement("p", { className: "label-text" }, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Route_ID),
                                React.createElement("p", { className: "label-field" }, (_a = (routePlannerDetils &&
                                    routePlannerDetils.details &&
                                    routePlannerDetils.details.route_assignment_type)) !== null && _a !== void 0 ? _a : EMPTY_VALUE)),
                            React.createElement(material_1.Grid, { lg: 3, md: 3, className: "label-text" },
                                React.createElement("p", null, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Initiated_Date__Time),
                                React.createElement("p", { className: "label-field" }, (_b = (routePlannerDetils &&
                                    routePlannerDetils.details &&
                                    moment(routePlannerDetils.details.initiated_date_time).format("".concat(appConstants_1.fixedDateFormatt, "     hh:mm A")))) !== null && _b !== void 0 ? _b : EMPTY_VALUE)),
                            React.createElement(material_1.Grid, { lg: 3, md: 3, className: "label-text" },
                                React.createElement("p", null, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Created_By),
                                React.createElement("p", { className: "label-field" }, (_c = (routePlannerDetils &&
                                    routePlannerDetils.details &&
                                    routePlannerDetils.details.created_by)) !== null && _c !== void 0 ? _c : EMPTY_VALUE)),
                            React.createElement(material_1.Grid, { lg: 3, md: 3, className: "label-text" },
                                React.createElement("p", null, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Generated_Date_Time),
                                React.createElement("p", { className: "label-field" }, (_d = (routePlannerDetils &&
                                    routePlannerDetils.details &&
                                    moment(routePlannerDetils.details.generated_date_time).format("".concat(appConstants_1.fixedDateFormatt, "     hh:mm A")))) !== null && _d !== void 0 ? _d : EMPTY_VALUE))),
                        React.createElement("div", { className: "buffer-time-section" },
                            React.createElement("h2", { className: "heading" }, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Buffer_Times),
                            React.createElement(material_1.Grid, { container: true, m: 0, className: "readonly-container", columnGap: { md: 2.5 }, columns: 10, rowGap: 2.5 },
                                React.createElement(material_1.Grid, { lg: 2, md: 2 },
                                    React.createElement("p", { className: "label-text" }, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Lab_Code),
                                    React.createElement("p", { className: "label-field" }, (_e = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.lab_code)) !== null && _e !== void 0 ? _e : EMPTY_VALUE)),
                                React.createElement(material_1.Grid, { lg: 2, md: 2, className: "label-text" },
                                    React.createElement("p", null, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Route_Type),
                                    React.createElement("p", { className: "label-field" }, (_f = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.route_assignment_type)) !== null && _f !== void 0 ? _f : EMPTY_VALUE)),
                                React.createElement(material_1.Grid, { lg: 2, md: 2, className: "label-text" },
                                    React.createElement("p", null, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Speed_Kmhr),
                                    React.createElement("p", { className: "label-field" },
                                        ' ', (_g = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.configuration.speed)) !== null && _g !== void 0 ? _g : EMPTY_VALUE)),
                                React.createElement(material_1.Grid, { lg: 2, md: 2, className: "label-text" },
                                    React.createElement("p", null, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.FE_rdius_Km),
                                    React.createElement("p", { className: "label-field" },
                                        ' ', (_h = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.configuration.radius)) !== null && _h !== void 0 ? _h : EMPTY_VALUE)),
                                React.createElement(material_1.Grid, { className: "label-text" },
                                    React.createElement("p", null, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Time_for_CC_mins),
                                    React.createElement("p", { className: "label-field" },
                                        ' ', (_j = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.configuration.cc_buffer_time)) !== null && _j !== void 0 ? _j : EMPTY_VALUE)),
                                React.createElement(material_1.Grid, { lg: 2, md: 2 },
                                    React.createElement("p", { className: "label-text" }, route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Time_for_PSC_mins),
                                    React.createElement("p", { className: "label-field" },
                                        ' ', (_k = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.configuration &&
                                        routePlannerDetils.details.configuration.psc_buffer_time)) !== null && _k !== void 0 ? _k : EMPTY_VALUE)),
                                React.createElement(material_1.Grid, { lg: 2, md: 2, className: "label-text" },
                                    React.createElement("p", null,
                                        route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Time_for_FPSP_mins,
                                        " "),
                                    React.createElement("p", { className: "label-field" }, (_l = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.configuration &&
                                        routePlannerDetils.details.configuration.fpsc_buffer_time)) !== null && _l !== void 0 ? _l : EMPTY_VALUE)),
                                React.createElement(material_1.Grid, { lg: 2, md: 2, className: "label-text" },
                                    React.createElement("p", null,
                                        route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Time_for_PUP_mins,
                                        " "),
                                    React.createElement("p", { className: "label-field" },
                                        ' ', (_m = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.configuration &&
                                        routePlannerDetils.details.configuration.pup_buffer_time)) !== null && _m !== void 0 ? _m : EMPTY_VALUE)),
                                React.createElement(material_1.Grid, { lg: 2, md: 2, className: "label-text" },
                                    React.createElement("p", null,
                                        route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Time_for_HUB_mins,
                                        " "),
                                    React.createElement("p", { className: "label-field" }, (_o = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.configuration &&
                                        routePlannerDetils.details.configuration.hub_buffer_time)) !== null && _o !== void 0 ? _o : EMPTY_VALUE)),
                                React.createElement(material_1.Grid, { className: "label-text" },
                                    React.createElement("p", null,
                                        route_planner_service_1.ROUTE_PLANNER_LABLES_VALUES.Time_for_LAB_mins,
                                        " "),
                                    React.createElement("p", { className: "label-field" },
                                        ' ', (_p = (routePlannerDetils &&
                                        routePlannerDetils.details &&
                                        routePlannerDetils.details.configuration &&
                                        routePlannerDetils.details.configuration.lab_buffer_time)) !== null && _p !== void 0 ? _p : EMPTY_VALUE))))))),
            React.createElement(material_1.Grid, { columnGap: 2, container: true, alignItems: "center", px: 3, className: "map-section" }, pathname.includes("".concat(route_path_1.PATHS.create_route_planner)) ? (React.createElement(React.Fragment, null,
                ' ',
                React.createElement(material_1.Grid, { sm: 3, className: "map-block" },
                    React.createElement("p", { className: "text-label" }, "Latitude"),
                    React.createElement("p", { className: "text-value" }, selctedLatLong ? selctedLatLong.split(',')[0] : '')),
                React.createElement(material_1.Grid, { sm: 3, className: "map-block" },
                    React.createElement("p", { className: "text-label" }, "Longitude"),
                    React.createElement("p", { className: "text-value" }, selctedLatLong ? selctedLatLong.split(',')[1] : '')),
                React.createElement(material_1.Grid, null,
                    React.createElement(multi_selector_1.default, { label: "Select FE's", dropDownList: feId_list && feId_list, isDisabled: feId_list.length >= 1 ? false : true, onChangeHnadlre: FE_IDropdownHandler, value: selectedFEId })),
                React.createElement(material_1.Grid, { flex: 1, justifyContent: 'end' },
                    React.createElement(button_component_1.default, { buttonName: 'download', color: "yellow-outline", endIcon: "icon-import download-icon", onClickHandler: function () {
                            return (0, array_helpers_1.downloadExcel)(downloadableRoutesDetails, 'RouteDetails.xlsx', route_planner_service_1.downloadExcelHeaders, 'Route Details');
                        }, isError: !features[menu_constants_1.FEATURE_CONSTANTS.routeDownload] &&
                            downloadableRoutesDetails &&
                            downloadableRoutesDetails.length <= 0 })))) : (React.createElement(material_1.Grid, { flex: 1, display: 'flex', gap: 2, justifyContent: 'end' },
                React.createElement(material_1.Grid, null,
                    React.createElement(multi_selector_1.default, { label: "Select FE's", dropDownList: feId_list && feId_list, isDisabled: feId_list.length >= 1 ? false : true, onChangeHnadlre: FE_IDropdownHandler, value: selectedFEId })),
                pathname.includes("".concat(route_path_1.PATHS.route_planner_details)) ? (React.createElement(button_component_1.default, { buttonName: 'changes list', color: "yellow-outline", onClickHandler: function () {
                        return nav("/".concat(route_path_1.PATHS.approvals_route_planner, "/").concat(route_path_1.PATHS.route_planner_details, "/").concat(id, "/").concat(route_path_1.PATHS.route_planner_changes_list));
                    } })) : null,
                React.createElement(button_component_1.default, { buttonName: "download", endIcon: "icon-import download-icon", color: "yellow-outline", onClickHandler: function () {
                        return (0, array_helpers_1.downloadExcel)(downloadableRoutesDetails, 'RouteDetails.xlsx', route_planner_service_1.downloadExcelHeaders, 'Route Details');
                    }, isError: downloadableRoutesDetails &&
                        downloadableRoutesDetails.length <= 0 &&
                        !features[menu_constants_1.FEATURE_CONSTANTS.routeDownload] })))),
            React.createElement(material_1.Box, { p: 3, className: "map-wrapper" },
                React.createElement("div", { ref: mapRef, className: "profile-map-container" },
                    React.createElement("div", { className: "map-legend-accordian  left-align" },
                        React.createElement(material_1.Accordion, { className: "custon-legend-accordian" },
                            React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement("span", { className: "icon-dropdown-arrow" }) },
                                React.createElement("p", null, "Legend")),
                            React.createElement(material_1.AccordionDetails, null,
                                React.createElement("div", { className: "map-legend" },
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.feIcon, alt: "fe-icon" }),
                                            "FE"),
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.ccIcon, alt: "cc-icon" }),
                                            "CC"),
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.labIcon, alt: "hub-icon" }),
                                            "LAB"),
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.hubIcon, alt: "cc-icon" }),
                                            "HUB"),
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.unassignedIcon, alt: "cc-icon" }),
                                            "Un Assigned CC"),
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.red_scooter_icon, alt: "cc-icon" }),
                                            "Un Assigned FE")))))),
                    React.createElement("div", { className: "map-legend-accordian right-align" },
                        React.createElement(material_1.Accordion, { className: "custon-legend-accordian" },
                            React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement("span", { className: "icon-dropdown-arrow" }) },
                                React.createElement("p", null, "Client Assignment Count")),
                            React.createElement(material_1.AccordionDetails, null,
                                React.createElement("div", { className: "map-legend" },
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "round-item" },
                                            "Assigned Clients:",
                                            ' ',
                                            routePlannerDetils &&
                                                routePlannerDetils.visit_count_obj &&
                                                routePlannerDetils.visit_count_obj[0] &&
                                                routePlannerDetils.visit_count_obj[0].assigned
                                                ? routePlannerDetils.visit_count_obj[0].assigned
                                                : EMPTY_VALUE),
                                        React.createElement("div", { className: "round-item" },
                                            "Un-Assigend Clients:",
                                            ' ',
                                            routePlannerDetils &&
                                                routePlannerDetils.visit_count_obj &&
                                                routePlannerDetils.visit_count_obj[0] &&
                                                routePlannerDetils.visit_count_obj[0].unassigned
                                                ? routePlannerDetils.visit_count_obj[0].unassigned
                                                : EMPTY_VALUE))))))),
                pathname.includes("".concat(route_path_1.PATHS.create_route_planner)) ? (React.createElement("div", { className: "button-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () {
                            nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_list));
                            setIsSendItForApproval(false);
                        } }),
                    React.createElement(button_component_1.default, { type: "submit", id: "myForm", buttonName: "send for approval", color: "yellow", onClickHandler: function () {
                            setApproveOrReject('SendItApproval');
                            setIsSendItForApproval(true);
                        } }))) : null)),
        React.createElement(route_planner_form_1.default, { isModalOpend: open, setOpen: setOpen, profildeDetails: routePlannerDetils, updatedLocationInfo: updatedLocationInfo }),
        ApproveAndRejectModal(),
        sendItForAprrovalScreen())) : (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString()))));
};
exports.default = ModifyRoutePlanner;
