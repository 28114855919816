"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var styles_1 = require("@mui/styles");
exports.useStyles = (0, styles_1.makeStyles)({
    customPopover: {
        padding: "20px 24px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
        borderRadius: 6,
        backgroundColor: '#fff',
        '& .inner-container': {
            width: 520,
            maxHeight: 250,
            overflow: 'auto',
            '& .MuiLink-root': {
                font: 'normal normal 700 12px/24px DM Sans',
                color: '#005bb9',
            },
        },
        '& .MuiList-root': {
            paddingTop: 0,
            '& .MuiListItem-root': {
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 0px 8px 0px',
                borderBottom: "1px solid #E0E0E0",
                '& .MuiListItemText-root': {
                    margin: 0,
                    '& .MuiTypography-body1': {
                        color: "#333333",
                        font: 'normal normal 500 16px/24px DMSans-Medium',
                        paddingBottom: 6,
                    },
                    '& .MuiTypography-body2': {
                        color: "#828282",
                        font: 'normal normal 700 12px/24px DM Sans',
                        maxWidth: '80ch',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    },
                },
            },
        },
    },
});
