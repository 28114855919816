"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var ViewEditButton = function (_a) {
    var isViewDisabled = _a.isViewDisabled, isEditDisabled = _a.isEditDisabled;
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: isViewDisabled ? "icon-btn" : "icon-btn icon-disabled" },
            React.createElement("span", { className: "icon-Vector" })),
        React.createElement(material_1.IconButton, { className: isEditDisabled ? "icon-btn" : "icon-btn icon-disabled" },
            React.createElement("span", { className: "icon-edit" })),
        React.createElement(material_1.IconButton, { className: "icon-btn" },
            React.createElement("span", { className: "icon-delete_forever" }))));
};
exports.default = ViewEditButton;
