"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var shipmentApi_1 = require("./shipmentApi");
var ShipmentList = function () {
    var nav = (0, react_router_dom_1.useNavigate)();
    var _a = React.useState(undefined), dataList = _a[0], setDataList = _a[1];
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var shipmentRouteCreation = function () {
        nav("/".concat(route_path_1.default.PATHS.shipment_list, "/").concat(route_path_1.default.PATHS.shipment_create));
    };
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _d = React.useState(0), totalPageCount = _d[0], setTotalPageCount = _d[1];
    var _e = React.useState(1), pageCount = _e[0], setpageCount = _e[1];
    var _f = React.useState(appConstants_1.defaultPageLimit), PageLimit = _f[0], setPageLimit = _f[1];
    var _g = React.useState(0), totalCount = _g[0], setTotalCount = _g[1];
    var _h = React.useState(null), selectedLab = _h[0], setSelectedLab = _h[1];
    var _j = React.useState(false), openPop = _j[0], setOpenPop = _j[1];
    var _k = React.useState(null), deleteId = _k[0], setIdds = _k[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Shipment_Route_Master);
    var navigate = (0, react_router_dom_1.useNavigate)();
    var handleOpenPop = function (e) {
        setOpenPop(true);
    };
    var handleClosePop = function () {
        setOpenPop(false);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var labHandler = function (value) {
        setSelectedLab(value);
    };
    var navigateToMyReports = function () {
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var refetch = (0, react_query_1.useQuery)(['shipment-route-list', pageCount], function () {
        var payload = {};
        var currentPage = pageCount < 1 ? 1 : pageCount;
        if (selectedLab) {
            payload.lab_code = selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.value;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, shipmentApi_1.fetchShipmentRouteList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setDataList(response.result);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setDataList([]);
        });
    }).refetch;
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (selectedLab) {
            payload['lab_code'] = selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.value;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Shipment_download,
            payLoad: payload,
            domain: process.env.SAMPLE_TRAKING_URL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                setOpen(true);
            }
            else {
                react_hot_toast_1.toast.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.toast.error(err.data.errors);
        });
    };
    var rowClickHandler = function (event) {
        if (event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-edit')) {
            nav("/".concat(route_path_1.default.PATHS.shipment_list, "/").concat(route_path_1.default.PATHS.shipment_create, "/").concat(route_path_1.default.PATHS.shipment_edit, "/").concat(event.data.id));
        }
        if (event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-Vector')) {
            nav("/".concat(route_path_1.default.PATHS.shipment_list, "/").concat(route_path_1.default.PATHS.shipment_create, "/").concat(route_path_1.default.PATHS.shipment_view, "/").concat(event.data.id));
        }
        if (event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-delete_forever')) {
            handleOpenPop(event.data.id);
            setIdds(event.data.id);
        }
    };
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var deleteShipment = function () {
        var payload = {};
        payload.shipment_route_id = deleteId;
        (0, shipmentApi_1.DeleteShipmentRoute)(payload)
            .then(function (res) {
            react_hot_toast_1.toast.success(res.message);
            refetch();
        })
            .catch(function (error) {
            react_hot_toast_1.toast.error(error.message);
        });
        handleClosePop();
    };
    return (React.createElement("div", { className: "fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Shipment Route Master ")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "download", color: "yellow-outline", endIcon: "icon-import download-icon", isError: (dataList === null || dataList === void 0 ? void 0 : dataList.length) > 0 ? false : true, onClickHandler: autoGenerateReportHandler }),
                React.createElement(button_component_1.default, { buttonName: "CREATE ROUTE", color: "yellow", onClickHandler: shipmentRouteCreation, isError: !features[menu_constants_1.FEATURE_CONSTANTS.Shipment_Create] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" }),
            React.createElement("div", { className: "filter-options" },
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "lab", placeholder: "Select Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                        return a.label.localeCompare(b.label);
                    }), onChange: labHandler, value: selectedLab || null }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "unclickablerow " },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.SHIPMENT_ROUTE_LIST_COLUMNS, rows: dataList, errorMessage: errorMessage, onRowClicked: rowClickHandler, pagination: false, className: 'fe-roster-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false }))),
        React.createElement(material_1.Modal, { open: openPop },
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                    React.createElement("h2", null, "Delete Shipment Route")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, "Are you sure  want to delete this Shipment Route?")),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "No", color: "yellow-outline", onClickHandler: handleClosePop }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: "Yes", color: "yellow", onClickHandler: deleteShipment }))))));
};
exports.default = ShipmentList;
