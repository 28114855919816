"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var search_component_1 = require("../../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var audit_helpers_1 = require("../audit_helpers");
var AuditAnswersComponent = function (_a) {
    var option = _a.option, question_id = _a.question_id, name = _a.name, question_type = _a.question_type;
    var _b = React.useState(undefined), auditanswerList = _b[0], setAuditanswerList = _b[1];
    var _c = React.useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(''), searchTerm = _d[0], setSearchTerm = _d[1];
    var _e = React.useState(''), searchTermofAuditor = _e[0], setSearchTermofAuditor = _e[1];
    var searchingauditeeHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    var searchingauditorHandler = function (e) {
        setSearchTermofAuditor(e.target.value);
    };
    var refetch = (0, react_query_1.useQuery)(['total-workforce-list'], function () {
        var payload = {};
        if (option) {
            payload.question_id = question_id;
            payload.answer = option.option;
        }
        else {
            payload.question_id = question_id;
        }
        if (searchTerm.trim().length >= 3) {
            payload.auditee_id = searchTerm.toLocaleLowerCase().trim();
        }
        if (searchTermofAuditor.trim().length >= 3) {
            payload.auditor_id = searchTermofAuditor.toLocaleLowerCase().trim();
        }
        (0, audit_helpers_1.AuditAnswerDetails)(payload)
            .then(function (response) {
            console.log('fjhg', response);
            setAuditanswerList((0, array_helpers_1.convertAdditAnswerListFromRes)(response.data, question_type));
            setErrorMessage('');
        })
            .catch(function (err) {
            setErrorMessage(err);
            setAuditanswerList([]);
        });
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, [option, searchTerm, searchTermofAuditor]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "mid-page-heaidng" },
            React.createElement("span", { className: "F-18" }, "Question : ".concat(name))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "filter-options" },
                React.createElement("div", { className: "search-filter" },
                    React.createElement(search_component_1.default, { placeholder: "Search with Auditee id", searchValue: searchTerm, searchHandler: searchingauditeeHandler })),
                React.createElement("div", { className: "search-filter" },
                    React.createElement(search_component_1.default, { placeholder: "Search with auditor id", searchValue: searchTermofAuditor, searchHandler: searchingauditorHandler })))),
        React.createElement("div", { className: 'custom-pagination-table control-tower-modal-table' },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.AUDIT_ANSWER_LIST_COLUMNS, rows: auditanswerList && auditanswerList, errorMessage: errorMessage, pagination: true, className: 'intarcity-tat-pagination' }))));
};
exports.default = AuditAnswersComponent;
