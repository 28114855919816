"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var orc_service_1 = require("../orc_service");
var route_path_1 = require("../../../../../constants/route_path");
var AGgrid_table_component_1 = require("../../../../../common/tables/AGgrid_table_component");
var orc_api_service_1 = require("../orc_api_service");
var api_service_1 = require("../../../../../api/api_service");
var react_query_1 = require("react-query");
var table_data_1 = require("../../../../../constants/table_data");
var react_paginate_1 = require("../../../../../common/react_pagination/react_paginate");
var array_helpers_1 = require("../../../../../utils/array_helpers");
var menu_constants_1 = require("../../../../../menuList/menu_constants");
var ORCLevelOneList = function (_a) {
    var searchTerm = _a.searchTerm, isFilterButton = _a.isFilterButton, PageLimit = _a.PageLimit;
    var nav = (0, react_router_1.useNavigate)();
    var _b = React.useState(undefined), orcList = _b[0], setOrcList = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.ORC_Management);
    var viewHandlere = function (event) {
        var evenType = event.event.target.className;
        var data = event.data;
        if (evenType.includes('icon-Vector')) {
            nav("/".concat(route_path_1.default.PATHS.orc_listing, "/").concat(route_path_1.default.PATHS.orc_level1_listing, "/").concat(route_path_1.default.PATHS.orc_profile_details, "/").concat(data.id, "/").concat(data.center_id));
        }
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var getORCList = function (endPoint, payLoad, domain) {
        (0, orc_api_service_1.fetchORCList)(endPoint, payLoad, domain)
            .then(function (response) {
            setErrorMessage("");
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            var convertedData = (0, orc_service_1.convertORCListingAPIResponse)(response.result);
            setOrcList(convertedData);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setOrcList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['orc-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {
            search_string: searchTerm,
            page: currentPage,
            size: PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50,
            status: orc_service_1.ORC_CLIENT_STATUS_OBJ.PENDING_LEVEL_ONE
        };
        getORCList(api_service_1.api_service.api_urls.ORC_LISTING, payload, process.env.APPROVAL_BASEURL);
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [isFilterButton]);
    return (React.createElement("div", { className: "unclickablerow custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ORC_PENDING_LIST_COLUMNS, rows: !features[menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_PENDING_LIST] ? orcList : [], pagination: false, errorMessage: !features[menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_PENDING_LIST] ? errorMessage : 'Do not have this feature accesss', onRowClicked: viewHandlere }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: orcList === null || orcList === void 0 ? void 0 : orcList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })));
};
exports.default = ORCLevelOneList;
