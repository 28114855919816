"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var rounds_list_1 = require("../../../../common/map/rounds_list");
var images_1 = require("../../../../constants/images");
var map_constants_1 = require("../../../../constants/map_constants");
var view_routeMap_1 = require("./view_routeMap");
var RouteHeatMap = function (_a) {
    var errorMessage = _a.errorMessage, setRoundColors = _a.setRoundColors, selectedFEId = _a.selectedFEId, assignedVisitData = _a.assignedVisitData, unassignedVisitData = _a.unassignedVisitData, filterRounds = _a.filterRounds, rootType = _a.rootType;
    return (React.createElement("div", { className: "map-wrapper" }, (selectedFEId && selectedFEId.length >= 1) ||
        (unassignedVisitData && unassignedVisitData.length >= 1) ? (React.createElement(React.Fragment, null,
        React.createElement(view_routeMap_1.default, { assignedVisitData: assignedVisitData, unassignedVisitData: unassignedVisitData, selectedFEId: selectedFEId, errorMessage: errorMessage, setRoundColors: setRoundColors }),
        (rootType && rootType.value == map_constants_1.ASSIGNED_UNASSIGNED.assigned) ||
            (rootType &&
                rootType.value == map_constants_1.ASSIGNED_UNASSIGNED.assiendAndUnassigned) ? (React.createElement(rounds_list_1.default, { roundColors: filterRounds, isMultiFe: true })) : null,
        React.createElement("div", { className: "map-legend-accordian  left-align" },
            React.createElement(material_1.Accordion, { className: "custon-legend-accordian" },
                React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement("span", { className: "icon-dropdown-arrow" }) },
                    React.createElement("p", null, "Legend")),
                React.createElement(material_1.AccordionDetails, null,
                    React.createElement("div", { className: "map-legend" }, rootType && rootType.value == map_constants_1.ASSIGNED_UNASSIGNED.assigned ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "round-item" },
                            React.createElement("img", { src: images_1.default.feIcon, alt: "fe-icon" }),
                            "FE"),
                        React.createElement("div", { className: "round-item" },
                            React.createElement("img", { src: images_1.default.ccIcon, alt: "cc-icon" }),
                            "CC"),
                        React.createElement("div", { className: "round-item" },
                            React.createElement("img", { src: images_1.default.hubIcon, alt: "hub-icon" }),
                            "LAB"),
                        React.createElement("div", { className: "round-item" },
                            React.createElement("img", { src: images_1.default.hubIcon, alt: "cc-icon" }),
                            "HUB"))) : rootType &&
                        rootType.value == map_constants_1.ASSIGNED_UNASSIGNED.assiendAndUnassigned ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "round-item" },
                            React.createElement("img", { src: images_1.default.feIcon, alt: "fe-icon" }),
                            "FE"),
                        React.createElement("div", { className: "round-item" },
                            React.createElement("img", { src: images_1.default.ccIcon, alt: "cc-icon" }),
                            "CC"),
                        React.createElement("div", { className: "round-item" },
                            React.createElement("img", { src: images_1.default.labIcon, alt: "lab-icon" }),
                            "LAB"),
                        React.createElement("div", { className: "round-item" },
                            React.createElement("img", { src: images_1.default.hubIcon, alt: "hub-icon" }),
                            "HUB"),
                        React.createElement("div", { className: "round-item" },
                            React.createElement("img", { src: images_1.default.unassignedIcon, alt: "cc-icon" }),
                            "Un Assigned CC"))) : (React.createElement("div", { className: "round-item" },
                        React.createElement("img", { src: images_1.default.unassignedIcon, alt: "cc-icon" }),
                        "Un Assigned")))))))) : (React.createElement("div", { className: "no-data-conatiner" },
        React.createElement("div", { className: "no-data-badge" },
            ' ',
            errorMessage ? errorMessage.toString() : 'No Data Available')))));
};
exports.default = RouteHeatMap;
