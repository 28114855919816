"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var api_service_1 = require("../../../api/api_service");
var generic_api_calls_1 = require("../../generic_api_calls");
var DownloadLinkComponent = function (_a) {
    var filePath = _a.filePath, reportID = _a.reportID, name = _a.name;
    var _b = React.useState(''), downloadLink = _b[0], setDownloadLink = _b[1];
    React.useEffect(function () {
        if (filePath) {
            (0, generic_api_calls_1.fetchImnageURLFromName)(filePath).then(function (res) {
                setDownloadLink(res.data.data.url);
            });
        }
    }, [filePath]);
    var onClickHandler = function (e) {
        var payload = {};
        if (e.target.id) {
            payload['id'] = e.target.id;
        }
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.DOWNLOADREPORT_HISTORY,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode != 200) {
                var erroMessage = res.data
                    ? res.data.message
                    : 'Unable to save download history details';
                throw new Error(erroMessage);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err);
        });
    };
    return (React.createElement("div", null, downloadLink ? (React.createElement("a", { href: downloadLink, id: reportID, download: true, onClick: function () { return (name == 'visit' ? onClickHandler : null); } }, "Download")) : (React.createElement("p", null, "--"))));
};
exports.default = DownloadLinkComponent;
