"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnTimePickupFeListDownload = exports.OntimePickupGenerate = exports.IntercityTatFeListGenerate = exports.labHandoverTrendGraph = exports.OnTimePickupFeList = exports.OnTimePickupSampleListApi = exports.OntimePickupGraph = exports.IntercityTatFeList = exports.IntercityTatSample = exports.IntercityTatGraph = exports.hubMasterExcel = exports.centerMasterExcel = exports.labMasterExcel = exports.SuccessfulVisitsGenerate = exports.SuccessfulVisits = exports.convertNothingToPickupReportListFromRes = exports.FeAttendanceReportsGenerate = exports.ClientSlotwiseProductivityGenerate = exports.downloadRlabToPlabReports = exports.ClientSlotwiseProductivity = exports.OutstationMasterReports = exports.RlabToPlabReports = exports.FeAttendanceReports = exports.getCompletedAssignedList = exports.getAllVisitsList = exports.generateStateAndCitiesFromSelectedCities = exports.isAutoGenerateBtnEnabled = exports.getMyreportList = exports.baggingLifeCycleReportsGraphs = exports.baggingLifeCycleReports = exports.cashPendingReports = exports.cashCollectionReports = void 0;
var api_service_1 = require("../../../api/api_service");
var menu_constants_1 = require("../../../menuList/menu_constants");
var cashCollectionReports = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CASH_COLLECTION_REPORT,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                console.log(res, 'response');
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.cashCollectionReports = cashCollectionReports;
var cashPendingReports = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CASH_PENDING_REPORT,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                console.log(res, 'response');
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.cashPendingReports = cashPendingReports;
var baggingLifeCycleReports = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.BAGGING_LIFECYCLE_Graphs,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                console.log(res, 'response');
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.baggingLifeCycleReports = baggingLifeCycleReports;
var baggingLifeCycleReportsGraphs = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.BAGGING_LIFECYCLE_Graphs,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            var _a;
            if (((_a = res.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 200) {
                console.log(res, 'response');
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.baggingLifeCycleReportsGraphs = baggingLifeCycleReportsGraphs;
var getMyreportList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.MYREPORTS_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getMyreportList = getMyreportList;
var isAutoGenerateBtnEnabled = function (features, lifeCycleList) {
    var isEnabled;
    if (features[menu_constants_1.FEATURE_CONSTANTS.bagReportDownload] &&
        (lifeCycleList === null || lifeCycleList === void 0 ? void 0 : lifeCycleList.length) >= 1) {
        isEnabled = false;
        return isEnabled;
    }
    else if (features[menu_constants_1.FEATURE_CONSTANTS.bagReportDownload] &&
        (lifeCycleList === null || lifeCycleList === void 0 ? void 0 : lifeCycleList.length) == 0) {
        isEnabled = true;
        return isEnabled;
    }
    else if (!features[menu_constants_1.FEATURE_CONSTANTS.bagReportDownload] &&
        (lifeCycleList === null || lifeCycleList === void 0 ? void 0 : lifeCycleList.length) >= 1) {
        isEnabled = true;
        return isEnabled;
    }
};
exports.isAutoGenerateBtnEnabled = isAutoGenerateBtnEnabled;
var generateStateAndCitiesFromSelectedCities = function (selectedCities) {
    var stateAndCity = {
        states: [],
        cities: [],
    };
    selectedCities.map(function (selectedCityObj) {
        if (!stateAndCity.states.includes(selectedCityObj.state_name)) {
            stateAndCity.states.push(selectedCityObj.state_name);
        }
        stateAndCity.cities.push(selectedCityObj.short_code);
    });
    return stateAndCity;
};
exports.generateStateAndCitiesFromSelectedCities = generateStateAndCitiesFromSelectedCities;
var getAllVisitsList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ALL_VISITS,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getAllVisitsList = getAllVisitsList;
var getCompletedAssignedList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ASSIGNED_COMPLETED_REPORTS,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getCompletedAssignedList = getCompletedAssignedList;
var FeAttendanceReports = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Fe_Attendance_Report,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.FeAttendanceReports = FeAttendanceReports;
var RlabToPlabReports = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.RLABTOPLABLIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            console.log('resso1', res);
            if (res.data.statusCode === 200) {
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.RlabToPlabReports = RlabToPlabReports;
var OutstationMasterReports = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Outstation_Masterbox_Report,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.OutstationMasterReports = OutstationMasterReports;
var ClientSlotwiseProductivity = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.NothingTo_Pickup_Report,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            console.log('resso1', res);
            if (res.data.statusCode === 200) {
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.ClientSlotwiseProductivity = ClientSlotwiseProductivity;
var downloadRlabToPlabReports = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.DOWMLOADRLABTOPLAB,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            return resolve(res.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.downloadRlabToPlabReports = downloadRlabToPlabReports;
var ClientSlotwiseProductivityGenerate = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.NothingTo_Pickup_Report_Generate,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.ClientSlotwiseProductivityGenerate = ClientSlotwiseProductivityGenerate;
var FeAttendanceReportsGenerate = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Fe_Attendance_Report_Generate,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.FeAttendanceReportsGenerate = FeAttendanceReportsGenerate;
var convertNothingToPickupReportListFromRes = function (res) {
    var convertedRes = [];
    res.map(function (resObj) {
        var feObj = {
            center_name: resObj.center_name ? resObj.center_name : '',
            center_type: resObj.center_type ? resObj.center_type : '',
            invoice_code: resObj.invoice_code ? resObj.invoice_code : '',
            lab_code: resObj.lab_code ? resObj.lab_code : '',
            productivity: resObj.productivity ? resObj.productivity : '',
            total_assigned_rounds: resObj.total_assigned_rounds
                ? resObj.total_assigned_rounds
                : '',
            total_nothing_pickup_count: resObj.total_nothing_pickup_count
                ? resObj.total_nothing_pickup_count
                : '',
            warehouse_code: resObj.warehouse_code ? resObj.warehouse_code : '',
        };
        convertedRes.push(feObj);
    });
    return convertedRes;
};
exports.convertNothingToPickupReportListFromRes = convertNothingToPickupReportListFromRes;
var SuccessfulVisits = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.SUCCESSFUL_VISIT,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            console.log('resso1', res);
            if (res.data.statusCode === 200) {
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.SuccessfulVisits = SuccessfulVisits;
var SuccessfulVisitsGenerate = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.SUCCESSFUL_VISIT_GENERATE,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.SuccessfulVisitsGenerate = SuccessfulVisitsGenerate;
var labMasterExcel = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.LAB_MASTER_REPORT,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.labMasterExcel = labMasterExcel;
var centerMasterExcel = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CENTER_MASTER_REPORT,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.centerMasterExcel = centerMasterExcel;
var hubMasterExcel = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.HUB_MASTER_REPORT,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.hubMasterExcel = hubMasterExcel;
var IntercityTatGraph = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Intercity_tat_graph,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.IntercityTatGraph = IntercityTatGraph;
var IntercityTatSample = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Intercity_tat_sample_list,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.IntercityTatSample = IntercityTatSample;
var IntercityTatFeList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Intercity_tat_fe_list,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.IntercityTatFeList = IntercityTatFeList;
var OntimePickupGraph = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.On_Time_Pickup,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.OntimePickupGraph = OntimePickupGraph;
var OnTimePickupSampleListApi = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.On_Time_Pickup_List,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.OnTimePickupSampleListApi = OnTimePickupSampleListApi;
var OnTimePickupFeList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.On_Time_Pickup_Fe_List,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.OnTimePickupFeList = OnTimePickupFeList;
var labHandoverTrendGraph = function (payload, endPoint) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: endPoint,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.labHandoverTrendGraph = labHandoverTrendGraph;
var IntercityTatFeListGenerate = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Intercity_tat_fe_list_Generate,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.IntercityTatFeListGenerate = IntercityTatFeListGenerate;
var OntimePickupGenerate = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.On_Time_Pickup_Generate,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            console.log(res);
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.OntimePickupGenerate = OntimePickupGenerate;
var OnTimePickupFeListDownload = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.On_Time_Pickup_Fe_List_Download,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.OnTimePickupFeListDownload = OnTimePickupFeListDownload;
