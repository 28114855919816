"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../../reports/reports_helper");
var OnTimePickupSampleList = function (_a) {
    var lifeCycleList = _a.lifeCycleList, totalCount = _a.totalCount, totalPageCount = _a.totalPageCount, setpageCount = _a.setpageCount, pageCount = _a.pageCount, errorMsgGraphList = _a.errorMsgGraphList, startDate = _a.startDate, endDate = _a.endDate, labCode = _a.labCode, filterSelectedCities = _a.filterSelectedCities, onTimeEvent = _a.onTimeEvent, errorMessage = _a.errorMessage;
    var navigate = (0, react_router_1.useNavigate)();
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var PageLimit = 50;
    var labList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).labList;
    var autoGenerateReportHandler = function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(labCode, labList);
            payload['lab_code'] = selectedlabCode;
        }
        if (filterSelectedCities && filterSelectedCities.length > 0) {
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(filterSelectedCities);
            payload['state'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['city'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        if (onTimeEvent) {
            payload['type'] = (0, array_helpers_1.convertOntimePickXaxis)(onTimeEvent);
        }
        payload['page'] = pageCount;
        payload['size'] = 50;
        (0, reports_helper_1.OnTimePickupFeListDownload)(payload)
            .then(function (response) {
            if (response.data.data.statusCode === 200) {
                react_hot_toast_1.default.error(response.data.message);
            }
            else {
                setOpen(true);
            }
        })
            .catch(function (err) {
            console.log(err, 'rrr');
            react_hot_toast_1.default.error(err);
        });
    };
    var navigateToMyReports = function () {
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "mid-page-heaidng" },
            React.createElement("h2", { className: "F-18" }, "On Time Pickup Sample List"),
            React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, color: "yellow-outline", endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler })),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ON_TIME_PICKUP_LIST_COLUMNS, rows: lifeCycleList, pagination: false, enableCellTextSelection: true, className: 'bagging-lifecycle-table intracity-pagination', errorMessage: errorMessage }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: lifeCycleList === null || lifeCycleList === void 0 ? void 0 : lifeCycleList.length, currentPageLimits: PageLimit })),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = OnTimePickupSampleList;
