"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertApprovalDetailsFromRes = exports.levelTwoVendorPendingList = exports.levelOneVendorPendingList = exports.levelTwoPendingList = exports.levelOnePendingList = void 0;
var api_service_1 = require("../../../../api/api_service");
var array_helpers_1 = require("../../../../utils/array_helpers");
var lodash_1 = require("./../../../../utils/lodash");
var levelOnePendingList = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.LEVEL_ONE_PENDING_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: Object.keys(payLoad).length > 0 ? payLoad : undefined,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.levelOnePendingList = levelOnePendingList;
var levelTwoPendingList = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.LEVEL_TWO_PENDING_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: Object.keys(payLoad).length > 0 ? payLoad : undefined,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.levelTwoPendingList = levelTwoPendingList;
var levelOneVendorPendingList = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.VENDOR_LEVEL_ONE_PENDING_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: Object.keys(payLoad).length > 0 ? payLoad : undefined,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.levelOneVendorPendingList = levelOneVendorPendingList;
var levelTwoVendorPendingList = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.VENDOR_LEVEL_TWO_PENDING_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: Object.keys(payLoad).length > 0 ? payLoad : undefined,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.levelTwoVendorPendingList = levelTwoVendorPendingList;
var convertApprovalDetailsFromRes = function (res) {
    var _a, _b, _c, _d;
    var emptyDetails = '--';
    var convertedRes = {};
    var personal_details = {
        firstName: res.first_name ? res.first_name : emptyDetails,
        lastName: res.last_name ? res.last_name : emptyDetails,
        dob: res.dob ? (0, array_helpers_1.normaldateFormatte)(res.dob) : emptyDetails,
        mobileNumber: res.mobile ? res.mobile : emptyDetails,
        email: res.email ? res.email : emptyDetails,
        gender: res.gender ? res.gender : emptyDetails,
        emergencyContactNo: res.emergency_mobile
            ? res.emergency_mobile
            : emptyDetails,
        uploadPhoto: res.avatar ? res.avatar : '',
        referenceContactName1: res.reference_name1
            ? res.reference_name1
            : emptyDetails,
        referenceMobileNumber1: res.reference_mobile1
            ? res.reference_mobile1
            : emptyDetails,
        referenceContactName2: res.reference_name2
            ? res.reference_name2
            : emptyDetails,
        referenceMobileNumber2: res.reference_mobile2
            ? res.reference_mobile2
            : emptyDetails,
    };
    var contactDetails = {
        addresslane1: res.addresses && res.addresses[0] ? res.addresses[0].lane1 : emptyDetails,
        addresslane2: res.addresses && res.addresses[0] ? res.addresses[0].lane2 : emptyDetails,
        landmark: res.addresses && res.addresses[0] ? res.addresses[0].landmark : emptyDetails,
        state: res.addresses && res.addresses[0]
            ? res.addresses[0].state_name
            : emptyDetails,
        city: res.addresses && res.addresses[0]
            ? res.addresses[0].city_name
            : emptyDetails,
        country: res.addresses && res.addresses[0]
            ? res.addresses[0].country_name
            : emptyDetails,
        pincode: res.addresses && res.addresses[0]
            ? (_a = res.addresses[0].pincode) === null || _a === void 0 ? void 0 : _a.toString()
            : emptyDetails,
        addressProofType: res.addresses && res.addresses[0]
            ? res.addresses[0].proof_of_doc_type
            : emptyDetails,
        proofOfAddress: res.addresses && res.addresses[0] ? res.addresses[0].proof_of_doc : '',
        permenentAddresslane1: res.addresses && res.addresses[1] ? res.addresses[1].lane1 : emptyDetails,
        permenentAddresslane2: res.addresses && res.addresses[1] ? res.addresses[1].lane1 : emptyDetails,
        permenentLandmark: res.addresses && res.addresses[1] ? res.addresses[1].landmark : emptyDetails,
        permenentState: res.addresses && res.addresses[1]
            ? res.addresses[1].state_name
            : emptyDetails,
        permenentCity: res.addresses && res.addresses[1]
            ? res.addresses[1].city_name
            : emptyDetails,
        permenentCountry: res.addresses && res.addresses[1]
            ? res.addresses[1].country_name
            : emptyDetails,
        permenentPincode: res.addresses && res.addresses[1]
            ? (_b = res.addresses[1].pincode) === null || _b === void 0 ? void 0 : _b.toString()
            : emptyDetails,
        permanentProofOfAddressType: res.addresses && res.addresses[1]
            ? res.addresses[1].proof_of_doc_type
            : emptyDetails,
        permenentProofOfAddress: res.addresses && res.addresses[1] ? res.addresses[1].proof_of_doc : '',
    };
    var bank_details = {
        AccountNumber: res.bank_account ? res.bank_account.toString() : emptyDetails,
        Bank_Branch: res.bank_branch ? res.bank_branch.toString() : emptyDetails,
        Bank_Name: res.bank_name ? res.bank_name.toString() : emptyDetails,
        IFSC_Code: res.bank_ifsc ? res.bank_ifsc.toString() : emptyDetails,
        UPI_ID: res.bank_upi_id ? res.bank_upi_id : emptyDetails,
        proofOfBankAccountDetails: res.bank_cheque_doc ? res.bank_cheque_doc : '',
    };
    var driving_licence_details = {
        vehicleType: res.vehicle_type ? res.vehicle_type : emptyDetails,
        drivingLicence: res.driving_licence ? res.driving_licence : emptyDetails,
        drivingLicenceExpirydate: res.driving_licence_expiry
            ? (0, array_helpers_1.normaldateFormatte)(res.driving_licence_expiry)
            : emptyDetails,
        insuranceExpirydate: res.insurance_expiry
            ? (0, array_helpers_1.normaldateFormatte)(res.insurance_expiry)
            : emptyDetails,
        insuranceNumber: res.insurance ? res.insurance : emptyDetails,
        uploadInsuranceDoc: res.insurance_photo ? res.insurance_photo : '',
        proofOfDrivingLicence: res.driving_licence_doc ? res.driving_licence_doc : '',
    };
    var employmentDetails = {
        employee_vendor_id: res.employee_id ? res.employee_id : emptyDetails,
        fe_Type: res.fe_type ? res.fe_type : emptyDetails,
        dateOfJoining: res.date_of_joining
            ? (0, array_helpers_1.normaldateFormatte)(res.date_of_joining)
            : emptyDetails,
        company: res.company ? res.company : emptyDetails,
        tenure: res.tenure ? res.tenure : emptyDetails,
        labCode: res.lab_code ? res.lab_code : emptyDetails,
        labName: res.centre_name ? res.centre_name : emptyDetails,
        HudId: res.hub_name ? res.hub_name : emptyDetails,
        regionalManager: res.regional_manager1 ? res.regional_manager1 : emptyDetails,
        zone: res.zone_name ? res.zone_name : emptyDetails,
        labState: res.centre_state ? res.centre_state : emptyDetails,
        labCity: res.centre_city ? res.centre_city : emptyDetails,
        fe_working_state: res.working_state ? res.working_state : emptyDetails,
        fe_working_city: res.working_city ? res.working_city : emptyDetails,
        FEVendorCode: res.vendor_code ? res.vendor_code : emptyDetails,
        vendorName: res.vendor_name ? res.vendor_name : emptyDetails,
        baseLocation: res.base_latitude && res.base_longitude
            ? "".concat(res.base_latitude, " ,").concat(res.base_longitude)
            : emptyDetails,
        base_location_address: res.base_location_address
            ? res.base_location_address
            : emptyDetails,
        fe_route_assignment_type: res.fe_route_assignment_type
            ? res.fe_route_assignment_type
            : emptyDetails,
        supervisor: res.supervisor != null && res.supervisor != undefined
            ? Boolean(res.supervisor)
            : false,
        backup: res.backup != null && res.backup != undefined ? Boolean(res.backup) : false,
        vendorCode: res.vendor_code ? res.vendor_code : emptyDetails,
        canUpdateCCCoordinates: res.can_update_cc_coordinates != null &&
            res.can_update_cc_coordinates != undefined
            ? Boolean(res.can_update_cc_coordinates)
            : false,
    };
    convertedRes['perviousDetials'] = {
        personal_details: personal_details,
        contact_details: contactDetails,
        bank_details: bank_details,
        drivingLiecenceDetails: driving_licence_details,
        employment_details: employmentDetails,
        approval_status: res.approval_status ? res.approval_status : null,
        onboard_status: res.onboard_status ? res.onboard_status : null,
        fe_id: res.fe_id ? res.fe_id : emptyDetails,
    };
    if (res.updated_profile) {
        var updated_Employment_Details = {
            employee_vendor_id: res.updated_profile.employee_id === null
                ? emptyDetails
                : res.updated_profile.employee_id
                    ? res.updated_profile.employee_id
                    : '',
            fe_Type: res.updated_profile.fe_type ? res.updated_profile.fe_type : '',
            dateOfJoining: res.updated_profile.date_of_joining
                ? (0, array_helpers_1.normaldateFormatte)(res.updated_profile.date_of_joining)
                : '',
            company: res.updated_profile.company ? res.updated_profile.company : '',
            tenure: res.updated_profile.tenure ? res.updated_profile.tenure : '',
            labCode: res.updated_profile.lab_id ? res.updated_profile.lab_id : '',
            labName: res.updated_profile.center_name
                ? res.updated_profile.center_name
                : '',
            HudId: res.updated_profile.hub_name ? res.updated_profile.hub_name : '',
            regionalManager: res.updated_profile.regional_manager1
                ? res.updated_profile.regional_manager1
                : '',
            zone: res.updated_profile.zone_name ? res.updated_profile.zone_name : '',
            labState: res.updated_profile.center_state
                ? res.updated_profile.center_state
                : '',
            labCity: res.updated_profile.center_city
                ? res.updated_profile.center_city
                : '',
            fe_working_state: res.updated_profile.working_state
                ? res.updated_profile.working_state
                : '',
            fe_working_city: res.updated_profile.working_city
                ? res.updated_profile.working_city
                : '',
            FEVendorCode: res.updated_profile.vendor_code
                ? res.updated_profile.vendor_code
                : '',
            vendorName: res.updated_profile.vendor_name
                ? res.updated_profile.vendor_name
                : '',
            baseLocation: res.updated_profile.base_latitude && res.updated_profile.base_longitude
                ? "".concat(res.updated_profile.base_latitude, " ,").concat(res.updated_profile.base_longitude)
                : '',
            base_location_address: res.updated_profile.base_location_address
                ? res.updated_profile.base_location_address
                : '',
            fe_route_assignment_type: res.updated_profile.fe_route_assignment_type
                ? res.updated_profile.fe_route_assignment_type
                : '',
            supervisor: res.updated_profile.supervisor != null &&
                res.updated_profile.supervisor != undefined
                ? Boolean(res.updated_profile.supervisor)
                : Boolean(res.supervisor),
            backup: res.updated_profile.backup != null &&
                res.updated_profile.backup != undefined
                ? Boolean(res.updated_profile.backup)
                : Boolean(res.backup),
            vendorCode: res.updated_profile.vendor_code
                ? res.updated_profile.vendor_code
                : '',
            canUpdateCCCoordinates: res.updated_profile.can_update_cc_coordinates != null &&
                res.updated_profile.can_update_cc_coordinates != undefined
                ? Boolean(res.updated_profile.can_update_cc_coordinates)
                : Boolean(res.can_update_cc_coordinates),
        };
        var updated_Driving_Licence_Details = {
            vehicleType: res.updated_profile.vehicle_type
                ? res.updated_profile.vehicle_type
                : '',
            drivingLicence: res.updated_profile.driving_licence
                ? res.updated_profile.driving_licence
                : '',
            drivingLicenceExpirydate: res.updated_profile.driving_licence_expiry
                ? (0, array_helpers_1.normaldateFormatte)(res.updated_profile.driving_licence_expiry)
                : '',
            insuranceExpirydate: res.updated_profile.insurance_expiry
                ? (0, array_helpers_1.normaldateFormatte)(res.updated_profile.insurance_expiry)
                : '',
            insuranceNumber: res.updated_profile.insurance
                ? res.updated_profile.insurance
                : '',
            uploadInsuranceDoc: res.updated_profile.insurance_photo
                ? res.updated_profile.insurance_photo
                : '',
            proofOfDrivingLicence: res.updated_profile.driving_licence_doc
                ? res.updated_profile.driving_licence_doc
                : '',
        };
        var updated_Bank_Details = {
            AccountNumber: res.updated_profile.bank_account
                ? res.updated_profile.bank_account.toString()
                : '',
            Bank_Branch: res.updated_profile.bank_branch
                ? res.updated_profile.bank_branch.toString()
                : '',
            Bank_Name: res.updated_profile.bank_name
                ? res.updated_profile.bank_name.toString()
                : '',
            IFSC_Code: res.updated_profile.bank_ifsc
                ? res.updated_profile.bank_ifsc.toString()
                : '',
            UPI_ID: res.updated_profile.bank_upi_id
                ? res.updated_profile.bank_upi_id
                : '',
            proofOfBankAccountDetails: res.updated_profile.bank_cheque_doc
                ? res.updated_profile.bank_cheque_doc
                : '',
        };
        var currentDetailsObj = lodash_1.default.find(res.updated_profile.addresses, { type: 'present' });
        var permenentAddressDetails = lodash_1.default.find(res.updated_profile.addresses, { type: 'permanent' });
        var updated_Contact_Details = {
            addresslane1: currentDetailsObj ? currentDetailsObj.lane1 : '',
            addresslane2: currentDetailsObj ? currentDetailsObj.lane2 : '',
            landmark: currentDetailsObj ? currentDetailsObj.landmark : '',
            state: currentDetailsObj ? currentDetailsObj.state_name : '',
            city: currentDetailsObj ? currentDetailsObj.city_name : '',
            country: currentDetailsObj ? currentDetailsObj.country_name : '',
            pincode: currentDetailsObj ? (_c = currentDetailsObj.pincode) === null || _c === void 0 ? void 0 : _c.toString() : '',
            addressProofType: currentDetailsObj
                ? currentDetailsObj.proof_of_doc_type
                : '',
            proofOfAddress: currentDetailsObj ? currentDetailsObj.proof_of_doc : '',
            permenentAddresslane1: permenentAddressDetails
                ? permenentAddressDetails.lane1
                : '',
            permenentAddresslane2: permenentAddressDetails
                ? permenentAddressDetails.lane2
                : '',
            permenentLandmark: permenentAddressDetails
                ? permenentAddressDetails.landmark
                : '',
            permenentState: permenentAddressDetails
                ? permenentAddressDetails.state_name
                : '',
            permenentCity: permenentAddressDetails
                ? permenentAddressDetails.city_name
                : '',
            permenentCountry: permenentAddressDetails
                ? permenentAddressDetails.country_name
                : '',
            permenentPincode: permenentAddressDetails
                ? (_d = permenentAddressDetails.pincode) === null || _d === void 0 ? void 0 : _d.toString()
                : '',
            permanentProofOfAddressType: permenentAddressDetails
                ? permenentAddressDetails.proof_of_doc_type
                : '',
            permenentProofOfAddress: permenentAddressDetails
                ? permenentAddressDetails.proof_of_doc
                : '',
        };
        var updated_Profile_Details = {
            firstName: res.updated_profile.first_name
                ? res.updated_profile.first_name
                : '',
            lastName: res.updated_profile.last_name ? res.updated_profile.last_name : '',
            dob: res.updated_profile.dob
                ? (0, array_helpers_1.normaldateFormatte)(res.updated_profile.dob)
                : '',
            mobileNumber: res.updated_profile.mobile ? res.updated_profile.mobile : '',
            email: res.updated_profile.email ? res.updated_profile.email : '',
            gender: res.updated_profile.gender ? res.updated_profile.gender : '',
            emergencyContactNo: res.updated_profile.emergency_mobile
                ? res.updated_profile.emergency_mobile
                : '',
            uploadPhoto: res.updated_profile.avatar ? res.updated_profile.avatar : '',
            referenceContactName1: res.updated_profile.reference_name1
                ? res.updated_profile.reference_name1
                : '',
            referenceMobileNumber1: res.updated_profile.reference_mobile1
                ? res.updated_profile.reference_mobile1
                : '',
            referenceContactName2: res.updated_profile.reference_name2
                ? res.updated_profile.reference_name2
                : '',
            referenceMobileNumber2: res.updated_profile.reference_mobile2
                ? res.updated_profile.reference_mobile2
                : '',
        };
        convertedRes['updatedDetials'] = {
            personal_details: updated_Profile_Details,
            contact_details: updated_Contact_Details,
            bank_details: updated_Bank_Details,
            drivingLiecenceDetails: updated_Driving_Licence_Details,
            employment_details: updated_Employment_Details,
            approval_status: res.updated_profile.approval_status
                ? res.updated_profile.approval_status
                : null,
            onboard_status: res.updated_profile.onboard_status
                ? res.updated_profile.onboard_status
                : null,
            fe_id: res.updated_profile.fe_id ? res.updated_profile.fe_id : '',
        };
    }
    return convertedRes;
};
exports.convertApprovalDetailsFromRes = convertApprovalDetailsFromRes;
