"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var moment = require("moment");
var React = require("react");
var button_component_1 = require("../buttons/button_component");
require("../time_slot/time_slot.scss");
[];
var CCTimeSlots = function (_a) {
    var slots = _a.slots, setSelectedSlotId = _a.setSelectedSlotId, selectedSlotId = _a.selectedSlotId, setFieldValue = _a.setFieldValue, setSelectedButtonName = _a.setSelectedButtonName, selectedButton = _a.selectedButton;
    return (React.createElement("div", { className: "time-slot-wrapper" },
        React.createElement("div", { className: "time-slot-booking-group" },
            React.createElement("div", { className: "slot-wrapper" },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 14, xl: 16 } }, slots === null || slots === void 0 ? void 0 : slots.map(function (slotDetails) {
                    var slot = slotDetails.fromTime;
                    return (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 2, xl: 2 },
                        React.createElement(button_component_1.default, { buttonName: slotDetails === null || slotDetails === void 0 ? void 0 : slotDetails.buttonName, color: slotDetails.color
                                ? slotDetails.color
                                : slotDetails.id === selectedSlotId
                                    ? 'slot-btn status-selected'
                                    : 'slot-btn status-availabe', onClickHandler: function () {
                                setFieldValue('selectedSlots', slotDetails.id === selectedSlotId ? '' : slotDetails.id);
                                slot;
                                setSelectedSlotId(slotDetails.id === selectedSlotId ? '' : slotDetails.id);
                                setSelectedButtonName(slotDetails.id === selectedSlotId
                                    ? ''
                                    : slotDetails.fromTime);
                            }, isError: slot.split(":")[0] < moment().format('HH') })));
                }))))));
};
exports.default = CCTimeSlots;
