"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var button_component_1 = require("../buttons/button_component");
require("./modal.scss");
var ModalComponent = function (_a) {
    var buttonColor = _a.buttonColor, modalSize = _a.modalSize, buttonName = _a.buttonName, modalstyle = _a.modalstyle, value = _a.value, children = _a.children, isBtnDisabled = _a.isBtnDisabled, onClickHandler = _a.onClickHandler, open = _a.open, handleClose = _a.handleClose, type = _a.type;
    return (React.createElement("div", { className: "modal-component" },
        React.createElement(button_component_1.default, { type: type, buttonName: buttonName, color: buttonColor, onClickHandler: onClickHandler, isError: isBtnDisabled }),
        React.createElement(material_1.Modal, { open: open, onClose: handleClose },
            React.createElement(material_1.Box, { className: "modal-child ".concat(modalSize ? modalSize : '') }, children))));
};
exports.default = ModalComponent;
