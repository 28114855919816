"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var tabs_component_1 = require("../../../common/tabs/tabs_component");
require("../../../components/field_exicutive/fe-page");
var tabs_data_1 = require("../../../constants/tabs_data");
require("./control_center.scss");
var control_center_filters_1 = require("./control_center_filters");
var ControlCenter = function () {
    var _a = React.useState(''), searchVlaue = _a[0], setSearchValue = _a[1];
    var searchData = function (event) {
        setSearchValue(event.target.value);
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement(control_center_filters_1.default, { searchValue: searchVlaue, searchHadler: searchData, isDisabled: true }),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement(tabs_component_1.default, { tabList: tabs_data_1.TABS_DATA.CONTROL_CENTER_TABS_LIST }),
            React.createElement(react_router_1.Outlet, null))));
};
exports.default = ControlCenter;
