"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var modal_component_1 = require("../../../../common/modal/modal_component");
var text_field_with_datepicker_1 = require("../../../../common/text_filed/text_field_with_datepicker");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var schema_1 = require("../../../../constants/schema");
var UpdatePayrollDeduction = function (_a) {
    var buttonName = _a.buttonName, selectedDetails = _a.selectedDetails, value = _a.value;
    console.log('selectedDetails', selectedDetails);
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState(), comments = _c[0], setComments = _c[1];
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var reason = [
        {
            id: 1,
            label: 'Sick',
            value: 1,
        },
        {
            id: 2,
            label: 'Others',
            value: 2,
        },
    ];
    var formSubmitHandler = function (values) {
        console.log(values, comments, 'submithandler');
    };
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement("span", null, selectedDetails.deduction),
        React.createElement(modal_component_1.default, { buttonName: buttonName, value: value, onClickHandler: handleOpen, open: open },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("h2", { className: "heading" }, "Update deductions of Rami Reddy - HYDFE1100?"),
                React.createElement("p", { className: "sub-heading" }, "Update deductions with below details")),
            React.createElement("div", { className: "modal-body" },
                React.createElement(formik_1.Formik, { initialValues: {
                        deductionsDate: '',
                        reason: null,
                        deductionAmount: '',
                        comments: '',
                    }, validateOnBlur: false, validationSchema: schema_1.schemas.Update_Deductions_Schema, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                        console.log(errors, 'errorserrors'),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_field_with_datepicker_1.default, { name: "deductionsDate", label: "Deductions Date", onChange: function (e) {
                                        setFieldValue('deductionsDate', e);
                                        setFieldTouched('deductionsDate', true);
                                    }, minDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), isFutureDisable: true }),
                                errors['deductionsDate'] && touched['deductionsDate'] ? (React.createElement("div", { className: "error" }, errors['deductionsDate'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(drop_down_component_1.default, { name: "reason", placeholder: "Reason", dropDownList: reason, onChange: function (val) {
                                        setFieldValue('reason', val);
                                        setFieldTouched('reason', true);
                                    }, value: values.reason }),
                                errors['reason'] && touched['reason'] ? (React.createElement("div", { className: "error" }, errors['reason'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { label: "Deduction Amount", value: selectedDetails.deduction, onChange: function (e) {
                                        setFieldValue('deductionAmount', e);
                                        setFieldTouched('deductionAmount', true);
                                    }, onKeyPress: function (e) {
                                        if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                            e.target.value.length <= 5) {
                                        }
                                        else {
                                            e.preventDefault();
                                        }
                                    } }),
                                errors['deductionAmount'] && touched['deductionAmount'] ? (React.createElement("div", { className: "error" }, errors['deductionAmount'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 12 },
                                React.createElement(text_filed_component_1.default, { name: "comments", label: "Other Comments", value: values.comments, onChange: function (val) {
                                        setComments(val);
                                        setFieldValue('comments', val);
                                        setFieldTouched('comments', true);
                                    } }),
                                errors['comments'] && touched['comments'] ? (React.createElement("div", { className: "error" }, errors['comments'])) : null)),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: "Cancel", onClickHandler: handleClose, color: "yellow-outline" }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
                })))));
};
exports.default = UpdatePayrollDeduction;
