"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../api/api_service");
var api_urls_1 = require("../../../api/api_urls");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var status_buttons_1 = require("../../../common/buttons/status_buttons");
var rounds_list_1 = require("../../../common/map/rounds_list");
var modal_component_1 = require("../../../common/modal/modal_component");
var tabs_component_1 = require("../../../common/tabs/tabs_component");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var images_1 = require("../../../constants/images");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var tabs_data_1 = require("../../../constants/tabs_data");
var Pagenotfound_1 = require("../../../ErrorBoundary/Pagenotfound");
var menu_constants_1 = require("../../../menuList/menu_constants");
var routes_list_1 = require("../../../routes/routes_list");
var array_helpers_1 = require("../../../utils/array_helpers");
var ModalComponentWithoutButton_1 = require("../.././../common/modal/ModalComponentWithoutButton");
var generic_api_calls_1 = require("../../generic_api_calls");
require("../fe-page.scss");
var Exit_FEComponent_1 = require("../FE_Exit/Exit_FEComponent");
var fe_services_1 = require("../fe_services");
var map_details_1 = require("./map_details");
var emptyDetails = '--';
var FEDetails = function () {
    var _a = React.useState(), profileData = _a[0], setProfileData = _a[1];
    var _b = React.useState(false), isBypassModalOpen = _b[0], setIsBypassModalOpen = _b[1];
    var _c = React.useState(false), isVisteBypassed = _c[0], setIsVisteBypassed = _c[1];
    var _d = React.useState(''), profileImageURL = _d[0], setProfileImageURL = _d[1];
    var _e = React.useState(''), errorMessage = _e[0], setErrormsg = _e[1];
    var _f = React.useState(), inventoryList = _f[0], setInventoryList = _f[1];
    var _g = React.useState([]), roundColors = _g[0], setRoundColors = _g[1];
    var _h = React.useState(), trackFEList = _h[0], setTrackFEList = _h[1];
    var _j = React.useState(), feVisitList = _j[0], setFEVisitList = _j[1];
    var _k = React.useState(null), visitId = _k[0], setVisitId = _k[1];
    var _l = React.useState(null), feCuurentLatLng = _l[0], setFeCuurentLatLng = _l[1];
    var filterRounds = (0, array_helpers_1.removeDuplicatevalues)(roundColors);
    console.log(filterRounds, 'colorsData');
    var FE_InActiveAndExit = {
        reason: null,
        selectedInventoryList: [],
        fromDate: '',
        comments: '',
        exit_type: 'In-Active',
        inventory_amount: '',
    };
    var _m = React.useState(__assign({}, FE_InActiveAndExit)), feExitDetails = _m[0], setFeExitDetails = _m[1];
    var _o = React.useState(false), open = _o[0], setOpen = _o[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () {
        setOpen(false);
    };
    var id = (0, react_router_dom_1.useParams)().id;
    var tabs = tabs_data_1.TABS_DATA.FE_DETAILS__TABS_LIST;
    var feManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.feManagement);
    var feProfileRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.feManagement, routes_list_1.FE_PROFILE_DETIALS_SUB_ROUTES);
    var filteredTabs = (0, array_helpers_1.disableTabs)(tabs, feManagementFeatures);
    var nav = (0, react_router_dom_1.useNavigate)();
    var getVisitList = function (feDetails) {
        (0, generic_api_calls_1.fetchVisitList)(api_urls_1.api_urls.VISITS_LIST, {
            fe_id: id,
        }, process.env.VENDOR_BASE_URL)
            .then(function (res) {
            console.log(res, 'visitResponse');
            if (res.current_coordinates) {
                setFeCuurentLatLng(res.current_coordinates);
            }
            if (res === null || res === void 0 ? void 0 : res.result) {
                var visitResponse = (0, fe_services_1.customeTrackFEList)(res.result);
                setTrackFEList(visitResponse);
                setIsVisteBypassed(false);
                console.log(profileData, 'USER_PROFILEDATA');
                var visitRes = (0, fe_services_1.createCustomVisiteObj)(feDetails, res.result);
                setFEVisitList(visitRes);
            }
        })
            .catch(function (err) {
            console.log(err, 'foundError');
            setErrormsg(err);
        });
    };
    console.log(profileData && profileData.role_id, 'LLLLLLLLLLLL', feCuurentLatLng);
    var fetchProfileList = function (endPoint, payLoad, domain) {
        (0, fe_services_1.fetchFEProfileList)(endPoint, payLoad, domain)
            .then(function (response) {
            setProfileData(response);
            getVisitList(response);
            var list = [];
            var selectedInventoryIDs = [];
            response.inventory.map(function (data) {
                list.push({
                    id: data.id,
                    inventory_name: data.inventory_name,
                    inventory_id: data.inventory_id,
                });
                selectedInventoryIDs.push(data.inventory_id);
            });
            setInventoryList(list);
            setFeExitDetails(__assign(__assign({}, feExitDetails), { selectedInventoryList: selectedInventoryIDs }));
            if (response.avatar != null &&
                response.avatar != undefined &&
                response.avatar != '') {
                (0, generic_api_calls_1.fetchImnageURLFromName)(response.avatar).then(function (res) {
                    setProfileImageURL(res.data.data.url);
                });
            }
        })
            .catch(function (err) {
            setErrormsg(err);
        });
    };
    var feName = (0, array_helpers_1.concatFirstNameLastName)(profileData && profileData.first_name, profileData && profileData.last_name);
    var FE_ID = profileData && profileData.fe_id && profileData.fe_id.toString();
    React.useEffect(function () {
        if (id) {
            fetchProfileList(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, { user_id: id }, process.env.VENDOR_BASE_URL);
        }
    }, [isVisteBypassed]);
    var bypassVisitHandlere = function (values) {
        console.log(values, 'BYPASS', visitId);
        var payload = {
            visit_id: visitId,
            reason: values.comments,
        };
        (0, fe_services_1.byPassThePendingVisits)(api_service_1.api_service.api_urls.visits_by_pass, payload, process.env.VENDOR_BASE_URL)
            .then(function (res) {
            react_hot_toast_1.default.success('This visit has been bypass successfully');
            setIsBypassModalOpen(false);
            setIsVisteBypassed(true);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var openVisitBypassModal = function () {
        return (React.createElement(ModalComponentWithoutButton_1.default, { open: isBypassModalOpen },
            React.createElement("div", { className: "modal-header " },
                React.createElement("h2", { className: "heading" }, "Reason For Bypass")),
            React.createElement(formik_1.Formik, { initialValues: { comments: '' }, validateOnBlur: false, validationSchema: schema_1.REJECT_ROUTE_SCHEMA, onSubmit: bypassVisitHandlere }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement(Grid_1.default, { sm: 3, pt: 1.5, mb: 3 },
                        ' ',
                        React.createElement(text_filed_component_1.default, { label: "Comments", onChange: function (value) {
                                setFieldValue('comments', value);
                                setFieldTouched('comments', true);
                            }, value: values.comments }),
                        errors['comments'] && touched['comments'] ? (React.createElement("div", { className: "error" }, errors['comments'])) : null),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "CANCEL", color: "yellow-outline", onClickHandler: function () { return setIsBypassModalOpen(false); } }),
                        React.createElement(button_component_1.default, { buttonName: "SUBMIT", color: "yellow", type: "submit" }))));
            })));
    };
    var renderPage = function () {
        return !errorMessage ? (React.createElement(Grid_1.default, { container: true, columns: { xs: 13 }, className: "details-tab-wrapper" },
            React.createElement(Grid_1.default, { item: true, xs: 5.5 },
                React.createElement("div", { className: "details-wrapper fe-details-wrapper border-top" },
                    React.createElement("div", { className: "user-card displayflex " },
                        React.createElement("div", { className: "user-details-wrapper displayflex " },
                            React.createElement("div", { className: "avatar-wrapper" }, profileData && profileData.avatar ? (React.createElement(material_1.Avatar, { src: profileImageURL })) : (React.createElement(material_1.Avatar, null, feName && feName.charAt(0).toUpperCase()))),
                            React.createElement("div", { className: "user-name-wrapper" },
                                React.createElement("p", { className: "user-value" }, feName ? feName : emptyDetails),
                                React.createElement("span", { className: "user-value" }, profileData && profileData.fe_id ? profileData.fe_id : emptyDetails))),
                        React.createElement(status_buttons_1.default, { status: profileData && profileData.onboard_status })),
                    React.createElement(tabs_component_1.default, { tabList: filteredTabs }),
                    React.createElement(react_router_dom_1.Routes, null,
                        feProfileRoutes &&
                            feProfileRoutes.map(function (features) {
                                return (React.createElement(react_router_dom_1.Route, { path: "".concat(features.path), key: features.path, element: React.createElement(features.component, { trackFEList: trackFEList, role_id: profileData && profileData.role_id, setIsBypassModalOpen: setIsBypassModalOpen, setVisitId: setVisitId }) }));
                            }),
                        React.createElement(react_router_dom_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) })))),
            React.createElement(Grid_1.default, { item: true, xs: 7.5 }, !errorMessage && feVisitList && feVisitList.length > 1 ? (React.createElement("div", { className: "map-wrapper" },
                React.createElement(map_details_1.default, { errorMessage: errorMessage, visitInfo: feVisitList && feVisitList, setRoundColors: setRoundColors, feCuurentLatLng: feCuurentLatLng }),
                React.createElement(rounds_list_1.default, { roundColors: filterRounds }),
                React.createElement("div", { className: "map-legend-accordian  left-align" },
                    React.createElement(material_1.Accordion, { className: "custon-legend-accordian" },
                        React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement("span", { className: "icon-dropdown-arrow" }) },
                            React.createElement("p", null, "Legend")),
                        React.createElement(material_1.AccordionDetails, null,
                            React.createElement("div", { className: "map-legend left-align" },
                                React.createElement("div", { className: "round-item" },
                                    React.createElement("img", { src: images_1.default.yellow_map_icon, alt: "blue-map-icon" }),
                                    "Start Point"),
                                React.createElement("div", { className: "round-item" },
                                    React.createElement("img", { src: images_1.default.ccIcon, alt: "cc-icon" }),
                                    "CC"),
                                React.createElement("div", { className: "round-item" },
                                    React.createElement("img", { src: images_1.default.labIcon, alt: "lab-icon" }),
                                    "LAB"),
                                React.createElement("div", { className: "round-item" },
                                    React.createElement("img", { src: images_1.default.hubIcon, alt: "hub-icon" }),
                                    "HUB"),
                                React.createElement("div", { className: "round-item" },
                                    React.createElement("img", { src: images_1.default.rider_icon, alt: "hub-icon" }),
                                    "FE Current Location"))))))) : (React.createElement("div", { className: "no-data-conatiner hideMap borderbox" },
                React.createElement("div", { className: "no-data-badge" },
                    ' ',
                    errorMessage ? errorMessage.toString() : 'No Data Available')))),
            openVisitBypassModal())) : (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString()));
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", onClick: function () {
                        return nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.activeList));
                    }, icon: 'icon-left-arrow' }),
                React.createElement("h2", null, "FE Details")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Refresh", color: "yellow", onClickHandler: function () {
                        if (id) {
                            fetchProfileList(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, { user_id: id }, process.env.VENDOR_BASE_URL);
                        }
                    } }),
                React.createElement(modal_component_1.default, { buttonName: "In Active", buttonColor: "error-btn", onClickHandler: handleOpen, open: open, isBtnDisabled: !feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.inActiveFE] },
                    React.createElement(Exit_FEComponent_1.default, { feName: feName, fe_id: FE_ID, inventoryList: inventoryList, handleClose: handleClose, user_id: id, isAlreadyInacitve: false, minimumDate: '', maximumDate: '', initialValues: feExitDetails })))),
        renderPage()));
};
exports.default = FEDetails;
