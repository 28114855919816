"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Button_1 = require("@mui/material/Button");
var React = require("react");
require("./btn.scss");
var ButtonComponent = function (_a) {
    var type = _a.type, onChange = _a.onChange, color = _a.color, variant = _a.variant, buttonName = _a.buttonName, size = _a.size, endIcon = _a.endIcon, id = _a.id, ref = _a.ref, style = _a.style, isError = _a.isError, isModal = _a.isModal, form = _a.form, onClickHandler = _a.onClickHandler, startIcon = _a.startIcon;
    return (React.createElement(React.Fragment, null, buttonName === 'Pending' ? (React.createElement("div", { className: "payment-status-wrapper" },
        React.createElement("div", { className: "status-badge" },
            React.createElement("span", { className: "icon-emergency pending " }),
            React.createElement("p", null, buttonName)))) : buttonName === 'Approved' ? (React.createElement("div", { className: "payment-status-wrapper" },
        React.createElement("div", { className: "status-badge" },
            React.createElement("span", { className: "icon-emergency paid " }),
            React.createElement("p", null, buttonName)))) : (React.createElement("div", { className: "Button-wrapper" },
        React.createElement(Button_1.default, { id: id, type: type, disabled: isError, onClick: onClickHandler, className: "btn ".concat(color, " "), ref: ref, href: "", startIcon: startIcon, onChange: onChange },
            endIcon && React.createElement("span", { className: "btn-icon ".concat(endIcon) }),
            ' ',
            buttonName)))));
};
exports.default = ButtonComponent;
