"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedWarehouseDetils = exports.getSelectedLabDetils = exports.convertEditDataForLabCode = exports.convertEditData = exports.getHandOverFromEdit = exports.getRouteTypeFromEdit = exports.getDayFromId = exports.setTimeSlotColours = void 0;
var slotblocking_helper_1 = require("../../field_exicutive/slot_blocking/slotblocking.helper");
var route_type_enum = {
    non_route: 'NON_ROUTE',
    static: 'STATIC',
    dynamic: 'DYNAMIC',
    ultra_static: 'ULTRA_STATIC',
};
var setTimeSlotColours = function (weekDay, selectedSlots, daySlots) {
    console.log(selectedSlots, '9786tufhg');
    var slots = slotblocking_helper_1.CC_timeSlots.map(function (each) {
        selectedSlots.map(function (slot) {
            if (each.id === slot.id) {
                each['color'] = 'slot-btn status-selected';
            }
        });
        return each;
    });
    return slots;
};
exports.setTimeSlotColours = setTimeSlotColours;
var getDayFromId = function (dayId, weekDays) {
    var dayLabel;
    weekDays.map(function (day) {
        if (day.id === dayId) {
            dayLabel = day;
        }
    });
    return dayLabel;
};
exports.getDayFromId = getDayFromId;
var getRouteTypeFromEdit = function (editData) {
    var routeType;
    for (var i in route_type_enum) {
        if (route_type_enum[i] === editData) {
            routeType = route_type_enum[i];
        }
    }
    return routeType;
};
exports.getRouteTypeFromEdit = getRouteTypeFromEdit;
var getHandOverFromEdit = function (editData) {
    return editData === 1 ? true : editData === 0 ? false : '';
};
exports.getHandOverFromEdit = getHandOverFromEdit;
var convertEditData = function (data) {
    console.log(data, 'kjhgf');
    var dataObj = {};
    dataObj['label'] = data;
    dataObj['value'] = data;
    return dataObj;
};
exports.convertEditData = convertEditData;
var convertEditDataForLabCode = function (labCode, labList) {
    var labCOdeInfor = labList && labList.find(function (val) { return val.value == labCode; });
    console.log(labCOdeInfor, 'labCOdeInfor');
    var dataObj = {};
    dataObj['label'] = labCOdeInfor === null || labCOdeInfor === void 0 ? void 0 : labCOdeInfor.label;
    dataObj['value'] = labCOdeInfor === null || labCOdeInfor === void 0 ? void 0 : labCOdeInfor.value;
    return dataObj;
};
exports.convertEditDataForLabCode = convertEditDataForLabCode;
var getSelectedLabDetils = function (labList, labCode) {
    var filterLab = labList && labList.find(function (val) { return val.value == labCode; });
    var labCodeInfo = {
        label: "".concat((filterLab === null || filterLab === void 0 ? void 0 : filterLab.label) ? filterLab === null || filterLab === void 0 ? void 0 : filterLab.label : '').concat((filterLab === null || filterLab === void 0 ? void 0 : filterLab.center_name) ? ", ".concat(filterLab === null || filterLab === void 0 ? void 0 : filterLab.center_name) : ''),
        value: filterLab === null || filterLab === void 0 ? void 0 : filterLab.value,
    };
    console.log(labCodeInfo, 'labCodeInfo');
    return labCodeInfo;
};
exports.getSelectedLabDetils = getSelectedLabDetils;
var getSelectedWarehouseDetils = function (editData) {
    var warehouse_codeInfo = {
        label: "".concat(editData.warehouse_code ? editData.warehouse_code : '').concat(editData.invoice_code ? ", ".concat(editData.invoice_code) : '').concat(editData.state ? ", ".concat(editData.state) : '').concat(editData.city ? ", ".concat(editData.city) : ''),
        value: editData.warehouse_code,
    };
    return warehouse_codeInfo;
};
exports.getSelectedWarehouseDetils = getSelectedWarehouseDetils;
