"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid/Grid");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var File_Upload_With_Radio_Buttons_1 = require("../../../common/file_upload/File_Upload_With_Radio_Buttons");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var schema_1 = require("../../../constants/schema");
var table_data_1 = require("../../../constants/table_data");
var visitsList_api_calls_1 = require("./visitsList_api_calls");
var fileProps = {
    acceptedFiletypes: ['text/csv'],
    message: 'Note:Only .csv format is allowed upto 2MB',
    maxSize: {
        image: 2,
        pdf: 2,
        csv: 2,
    },
};
var initialValues = {
    uploadDoc: '',
    filetype: '',
};
var AddNewVisits = function () {
    var _a = React.useState('Ultra static'), filetype = _a[0], setfiletype = _a[1];
    var _b = React.useState(new Date().toString()), startDate = _b[0], setStartDate = _b[1];
    var _c = React.useState(new Date().toString()), endDate = _c[0], setEndDate = _c[1];
    var _d = React.useState(false), reloadPageAfterUpload = _d[0], setReloadPageAfterUpload = _d[1];
    var _e = React.useState(1), pageCount = _e[0], setpageCount = _e[1];
    var _f = React.useState(), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = React.useState(undefined), visitList = _g[0], setVisitlist = _g[1];
    var _h = React.useState(false), isDateRangeUpdated = _h[0], setIsDateRangeUpdated = _h[1];
    console.log(startDate, endDate, filetype, 'lkjhg');
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var refetch = (0, react_query_1.useQuery)(['cash-pending-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        payload['page'] = currentPage;
        payload['size'] = 50;
        (0, visitsList_api_calls_1.getVisitUploadHistoryList)(payload)
            .then(function (response) {
            console.log(response, 'lkjhgv');
            setVisitlist(response.data.result);
            setReloadPageAfterUpload(false);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setVisitlist([]);
        });
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [isDateRangeUpdated, reloadPageAfterUpload]);
    var formSubmitHnadler = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        return __generator(this, function (_a) {
            console.log('values inside formSubmitHnadler:', values);
            payload = new FormData();
            payload.append('file', values.uploadDoc);
            if (filetype === 'Ultra static') {
                payload.append('ultra_static', '1'),
                    payload.append('file_type', 'UltraStatic'),
                    payload.append('cc_id', 'ultrastatic');
            }
            api_service_1.api_service
                .post({
                endPoint: api_service_1.api_service.api_urls.ADD_VISITS,
                payLoad: payload,
                domain: process.env.BASE,
                headerType: 'multipart/form-data',
            })
                .then(function (res) {
                if (res.data.statusCode === 200) {
                    react_hot_toast_1.default.success(res.data.message);
                    setReloadPageAfterUpload(true);
                }
                else {
                    react_hot_toast_1.default.error(res.data.message);
                    setReloadPageAfterUpload(true);
                }
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err.data.errors);
            });
            return [2];
        });
    }); };
    return (React.createElement("div", { className: "form-filed-wrapper add-visit" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "form-heaidng" },
                React.createElement("h2", null, "Upload History")),
            React.createElement("div", { className: "filter-menu-wrapper" },
                React.createElement("div", { className: "filter-options" },
                    React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true })))),
        React.createElement("div", null,
            React.createElement(formik_1.Formik, { enableReinitialize: true, validateOnBlur: false, initialValues: __assign({}, initialValues), validationSchema: schema_1.schemas.ADD_VISITS, onSubmit: function (values, _a) {
                    var resetForm = _a.resetForm;
                    resetForm({ values: initialValues });
                    formSubmitHnadler(values);
                } }, function (_a) {
                var errors = _a.errors, setErrors = _a.setErrors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, resetForm = _a.resetForm, setFieldTouched = _a.setFieldTouched, dirty = _a.dirty;
                console.log('errors & touched inside Fe personalDetails Form1', errors, touched, dirty, values);
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper", autoComplete: "off" },
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                        React.createElement(Grid_1.default, { item: true, key: 'add visits', xs: 9, "xs-offset": 1 },
                            React.createElement(File_Upload_With_Radio_Buttons_1.default, { acceptedFiletypes: fileProps.acceptedFiletypes, maxSize: fileProps.maxSize, message: fileProps.message, buttonName: 'Upload', requiredClass: true, value: values.uploadDoc, setFieldValue: setFieldValue, setFieldTouched: setFieldTouched, filetype: filetype, setfiletype: setfiletype, onChange: function (val) {
                                    console.log('selected val:', val);
                                    setFieldValue('uploadDoc', val);
                                    setFieldTouched('uploadDoc', true);
                                } }),
                            errors['uploadDoc'] && touched['uploadDoc'] ? (React.createElement("div", { className: "error" }, errors['uploadDoc'])) : null)),
                    React.createElement("div", { className: "form-btns" },
                        React.createElement(button_component_1.default, { type: "submit", buttonName: 'Submit', color: "yellow", isError: false }))));
            }),
            React.createElement("div", { className: "unclickablerow " },
                React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ADD_VISIT_COLUMNS, rows: visitList, pagination: true, enableCellTextSelection: true, className: 'myreports-table', errorMessage: errorMessage })))));
};
exports.default = AddNewVisits;
