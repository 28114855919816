"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_paginate_1 = require("../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../common/tables/AGgrid_table_component");
var table_data_1 = require("../../constants/table_data");
var Region_Mapping_Helpers_1 = require("./Region_Mapping_Helpers");
var RegionList = function (_a) {
    var _b = React.useState(undefined), RegionList = _b[0], setRegionList = _b[1];
    var _c = React.useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var PageLimit = 50;
    var refetch = (0, react_query_1.useQuery)(['EventPerformance-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        payload['page'] = currentPage;
        payload['size'] = PageLimit;
        (0, Region_Mapping_Helpers_1.fetchRegionList)(payload)
            .then(function (response) {
            var _a, _b, _c;
            console.log(response.data, "oiuygtf");
            var pageSize = PageLimit;
            var count = Math.ceil(((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.count) / pageSize);
            setErrorMessage("");
            setTotalPageCount(count);
            setRegionList((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.result);
            setTotalCount((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setRegionList([]);
        });
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [
        PageLimit,
    ]);
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    return (React.createElement("div", null,
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.REGION_LISTING_COLUMNS, rows: RegionList && RegionList, errorMessage: errorMessage, pagination: false, enableCellTextSelection: true }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: RegionList === null || RegionList === void 0 ? void 0 : RegionList.length, currentPageLimits: PageLimit })));
};
exports.default = RegionList;
