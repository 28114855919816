"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.feManagementMarkeDetails = exports.byPassThePendingVisits = exports.customeTrackFEList = exports.createCustomVisiteObj = exports.fetchVendorList = exports.fetchVendorFEList = exports.fetchVendorProfileList = exports.fetchRejectedFEList = exports.fetchLeavesList = exports.fetchFEList = exports.fetchFEProfileList = void 0;
var api_service_1 = require("../../api/api_service");
var map_constants_1 = require("../../constants/map_constants");
var array_helpers_1 = require("../../utils/array_helpers");
var fetchFEProfileList = function (endPoint, payLoad, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .get({
                    endPoint: endPoint,
                    domain: domain,
                    payLoad: payLoad,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        return resolve(response.data.data);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchFEProfileList = fetchFEProfileList;
var fetchFEList = function (endPoint, payLoad, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .post({
                    endPoint: endPoint,
                    domain: domain,
                    payLoad: payLoad,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        var convertedActiveFEList = (0, array_helpers_1.convertActiveFEListFromRes)(response.data.data);
                        return resolve(convertedActiveFEList);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchFEList = fetchFEList;
var fetchLeavesList = function (endPoint, payLoad, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .post({
                    endPoint: endPoint,
                    domain: domain,
                    payLoad: payLoad,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        var convertedLeavesList = (0, array_helpers_1.convertLeavesListFromRes)(response.data.data);
                        return resolve(convertedLeavesList);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchLeavesList = fetchLeavesList;
var fetchRejectedFEList = function (endPoint, payLoad, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .post({
                    endPoint: endPoint,
                    domain: domain,
                    payLoad: payLoad,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        var convertedActiveFEList = (0, array_helpers_1.convertRejectedFEListFromRes)(response.data.data);
                        return resolve(convertedActiveFEList);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchRejectedFEList = fetchRejectedFEList;
var fetchVendorProfileList = function (endPoint, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .get({
                    endPoint: endPoint,
                    domain: domain,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        return resolve(response.data.data);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchVendorProfileList = fetchVendorProfileList;
var fetchVendorFEList = function (endPoint, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .get({
                    endPoint: endPoint,
                    domain: domain,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        return resolve(response.data.data);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchVendorFEList = fetchVendorFEList;
var fetchVendorList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.VEDNOR_LIST,
            payLoad: payload,
            domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchVendorList = fetchVendorList;
var createCustomVisiteObj = function (feDetails, visiteInfo) {
    visiteInfo.unshift({
        id: feDetails.id,
        latitude: feDetails.base_latitude,
        longitude: feDetails.base_longitude,
        fe_id: feDetails.fe_id,
        visit_type: 0,
        fe_first_name: feDetails.first_name,
        scheduled_start_time: feDetails.fe_start_time,
        center_address: feDetails.base_location_address,
    });
    return visiteInfo;
};
exports.createCustomVisiteObj = createCustomVisiteObj;
var customeTrackFEList = function (response) {
    var tractFe = {};
    var trackInfo = [];
    response.map(function (res) {
        trackInfo.push({
            slotTime: res.scheduled_start_time,
            labName: res.center_name,
            status: res.visit_status,
            bags: res.bag_count,
            cash: res.total_cash,
            timeStatus: res.is_delayed,
            visiteType: res.visit_type,
            center_address: res.center_address,
            warehouse_code: res.warehouse_code,
            assignment_type: res.assignment_type,
            mobile: res.mobile,
            visit_id: res.visit_id,
            bypassed_visit: res.bypassed_visit,
        });
    });
    tractFe = {
        workiginSlotStartTime: '',
        workiginSlotEndTime: '',
        trackList: trackInfo,
    };
    return tractFe;
};
exports.customeTrackFEList = customeTrackFEList;
var byPassThePendingVisits = function (endPoint, payLoad, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .post({
                    endPoint: endPoint,
                    domain: domain,
                    payLoad: payLoad,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        return resolve(response.data.data);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.byPassThePendingVisits = byPassThePendingVisits;
var feManagementMarkeDetails = function (pointeInfo) {
    var _a, _b;
    var codeTYpe = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? "".concat(map_constants_1.VISIT_TYPES.fe_code)
        : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP
            ? "".concat(map_constants_1.VISIT_TYPES.cc_code)
            : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB
                ? "".concat(map_constants_1.VISIT_TYPES.hub_code)
                : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB
                    ? "".concat(map_constants_1.VISIT_TYPES.lab_Code)
                    : "".concat(map_constants_1.VISIT_TYPES.hub_cash_code);
    return "<div classname='marker-info'><h3>".concat(codeTYpe, " Information</h3><hp>FE_CODE:").concat(pointeInfo === null || pointeInfo === void 0 ? void 0 : pointeInfo.fe_id, "</hp><p>Latitude:").concat((0, array_helpers_1.isValueEmpty)(pointeInfo === null || pointeInfo === void 0 ? void 0 : pointeInfo.latitude), "</p><p>Langitude:").concat((0, array_helpers_1.isValueEmpty)(pointeInfo === null || pointeInfo === void 0 ? void 0 : pointeInfo.longitude), "</p><p>").concat(pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? "FE Location:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo === null || pointeInfo === void 0 ? void 0 : pointeInfo.center_address))
        : "Center Name:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo === null || pointeInfo === void 0 ? void 0 : pointeInfo.center_name)), "</p>\n    ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>Warehouse Code:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo === null || pointeInfo === void 0 ? void 0 : pointeInfo.warehouse_code), "</p>")
        : '', "\n    ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>Invoice Code:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo === null || pointeInfo === void 0 ? void 0 : pointeInfo.invoice_code), "</p>")
        : '', "\n       ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>\n             Slots:[".concat(((_a = pointeInfo === null || pointeInfo === void 0 ? void 0 : pointeInfo.center_info) === null || _a === void 0 ? void 0 : _a.scheduled_time_slots)
            ? (_b = pointeInfo === null || pointeInfo === void 0 ? void 0 : pointeInfo.center_info) === null || _b === void 0 ? void 0 : _b.scheduled_time_slots.join(',')
            : '', "]</p>")
        : '', "\n</div>");
};
exports.feManagementMarkeDetails = feManagementMarkeDetails;
