"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var PreciousSampleIcon = function (_a) {
    var userData = _a.userData, isEditDisabled = _a.isEditDisabled, isDeleteDisabled = _a.isDeleteDisabled;
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn", disabled: !isDeleteDisabled },
            React.createElement("span", { className: "icon-delete_forever" })),
        React.createElement(material_1.IconButton, { className: "icon-btn", disabled: !isEditDisabled },
            React.createElement("span", { className: "icon-edit" }))));
};
exports.default = PreciousSampleIcon;
