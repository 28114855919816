"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var switch_button_1 = require("../../../common/buttons/switch_button");
var checkbox_component_1 = require("../../../common/checkbox/checkbox_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var file_upload_component_1 = require("../../../common/file_upload/file_upload_component");
var ModalComponentWithoutButton_1 = require("../../../common/modal/ModalComponentWithoutButton");
var text_area_component_1 = require("../../../common/text_area/text_area_component");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../constants/appConstants");
var schema_1 = require("../../../constants/schema");
require("./audit.scss");
var audit_helpers_1 = require("./audit_helpers");
var AddAudit = function (_a) {
    var initialValues = _a.initialValues, saveAuditDetails = _a.saveAuditDetails, setIsPhleboAudit = _a.setIsPhleboAudit;
    var id = (0, react_router_1.useParams)().id;
    var nav = (0, react_router_1.useNavigate)();
    var audit_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).audit_types;
    var cancelButtonHandler = function () {
        nav(-1);
    };
    var _b = React.useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var _c = React.useState('NORMAL AUDIT'), auditType = _c[0], setAuditType = _c[1];
    var formSubmitHnadler = function (values) {
        console.log('values inside formsubmit handler:', values);
        saveAuditDetails(values);
    };
    var renderOptions = function (singlequestion, values, setFieldValue, index, setFieldTouched, errors, touched) {
        switch (singlequestion.qtype.label) {
            case 'Multiple Choice':
                return (React.createElement(formik_1.FieldArray, { name: "questions[".concat(index, "].qoptions"), render: function (_a) {
                        var push = _a.push, remove = _a.remove, insert = _a.insert;
                        return (React.createElement("div", { className: "options-container" }, values.questions[index].qoptions.map(function (option, optionIndex) { return (React.createElement("div", { className: "option-error-wrapper" },
                            React.createElement("div", { className: "option-wrapper" },
                                React.createElement(checkbox_component_1.default, { label: '', disabled: true }),
                                React.createElement(text_filed_component_1.default, { className: "text-caps", name: "option ".concat(optionIndex), label: "Answer option ".concat(optionIndex + 1), isDisabled: values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT ? true : false, onChange: function (val) {
                                        values.questions[index].qoptions[optionIndex].option =
                                            val;
                                        setFieldValue('questions', __spreadArray([], values.questions, true));
                                    }, value: values.questions[index].qoptions[optionIndex].option }),
                                optionIndex <
                                    values.questions[index].qoptions.length - 1 ? (React.createElement(iconButton_component_1.default, { icon: "icon-remove_circle_outline", onClick: function () {
                                        remove(optionIndex);
                                    } })) : (React.createElement(iconButton_component_1.default, { icon: "icon-add", onClick: function () {
                                        push({
                                            option: '',
                                        });
                                    } }))),
                            errors['questions'] &&
                                errors['questions'][index] &&
                                errors['questions'][index].qoptions &&
                                errors['questions'][index].qoptions[optionIndex] &&
                                touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index].qoptions[optionIndex])) : null)); })));
                    } }));
            case 'Single Choice':
                return (React.createElement(formik_1.FieldArray, { name: "questions[".concat(index, "].qoptions"), render: function (_a) {
                        var push = _a.push, remove = _a.remove, insert = _a.insert;
                        return (React.createElement("div", { className: "options-container" }, values.questions[index].qoptions.map(function (option, optionIndex) { return (React.createElement("div", { className: "option-error-wrapper" },
                            React.createElement("div", { className: "option-wrapper" },
                                React.createElement(material_1.Radio, { disabled: true }),
                                React.createElement(text_filed_component_1.default, { className: "text-caps", name: "option ".concat(optionIndex), label: "Answer option ".concat(optionIndex + 1), onChange: function (val) {
                                        values.questions[index].qoptions[optionIndex].option =
                                            val;
                                        setFieldValue('questions', __spreadArray([], values.questions, true));
                                    }, value: values.questions[index].qoptions[optionIndex].option }),
                                optionIndex <
                                    values.questions[index].qoptions.length - 1 ? (React.createElement(iconButton_component_1.default, { icon: "icon-remove_circle_outline", onClick: function () {
                                        remove(optionIndex);
                                    } })) : (React.createElement(iconButton_component_1.default, { icon: "icon-add", onClick: function () {
                                        push({
                                            option: '',
                                        });
                                    } }))),
                            errors['questions'] &&
                                errors['questions'][index] &&
                                errors['questions'][index].qoptions &&
                                errors['questions'][index].qoptions[optionIndex] &&
                                touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index].qoptions[optionIndex])) : null)); })));
                    } }));
            case 'True/False':
                return (React.createElement(formik_1.FieldArray, { name: "questions[".concat(index, "].qoptions"), render: function (_a) {
                        var push = _a.push, remove = _a.remove, insert = _a.insert;
                        return (React.createElement("div", { className: "options-container" },
                            React.createElement("div", { className: "option-error-wrapper" },
                                React.createElement("div", { className: "option-wrapper" },
                                    React.createElement(material_1.Radio, { disabled: true }),
                                    React.createElement(text_filed_component_1.default, { className: "text-caps", label: "True", name: "option 1", onChange: function (val) {
                                            values.questions[index].qoptions[0].option = val;
                                            setFieldValue('questions', values.questions);
                                        }, value: values.questions[index].qoptions[0].option, readOnly: true })),
                                errors['questions'] &&
                                    errors['questions'][index] &&
                                    errors['questions'][index].qoptions &&
                                    errors['questions'][index].qoptions[0] &&
                                    touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index].qoptions[0].option)) : null),
                            React.createElement("div", { className: "option-error-wrapper" },
                                React.createElement("div", { className: "option-wrapper" },
                                    React.createElement(material_1.Radio, { disabled: true }),
                                    React.createElement(text_filed_component_1.default, { className: "text-caps", label: "False", name: "option 2", onChange: function (val) {
                                            values.questions[index].qoptions[1].option = val;
                                            setFieldValue('questions', values.questions);
                                        }, value: values.questions[index].qoptions[1].option, readOnly: true })),
                                errors['questions'] &&
                                    errors['questions'][index] &&
                                    errors['questions'][index].qoptions &&
                                    errors['questions'][index].qoptions[1] &&
                                    touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index].qoptions[1])) : null)));
                    } }));
            case 'Linear Scale':
                return (React.createElement(formik_1.FieldArray, { name: "questions[".concat(index, "].qoptions"), render: function (_a) {
                        var push = _a.push, remove = _a.remove, insert = _a.insert;
                        return (React.createElement(React.Fragment, null,
                            ' ',
                            React.createElement("div", { className: "linear-scale-limit" },
                                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.minDropdownList, placeholder: "MIN", value: values['questions'][index].minrange, isDisabled: false, onChange: function (val) {
                                        values.questions[index].minrange = val;
                                        setFieldValue('questions', values.questions);
                                    } }),
                                React.createElement("span", null, " to"),
                                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.maxDropdownList, placeholder: "Max", value: values['questions'][index].maxrange, isDisabled: false, onChange: function (val) {
                                        values.questions[index].maxrange = val;
                                        setFieldValue('questions', values.questions);
                                    } })),
                            errors['questions'] &&
                                errors['questions'][index] &&
                                errors['questions'][index].maxrange &&
                                touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index].maxrange)) : null,
                            errors['questions'] &&
                                errors['questions'][index] &&
                                errors['questions'][index].minrange &&
                                touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index].minrange)) : null,
                            React.createElement("div", { className: "options-container" },
                                React.createElement("div", { className: "option-error-wrapper linear-wrapper" },
                                    React.createElement("div", { className: "option-wrapper" },
                                        React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Label 1", name: "Label 1", onChange: function (val) {
                                                values.questions[index].qoptions[0].option = val;
                                                setFieldValue('questions', values.questions);
                                            }, value: values.questions[index].qoptions[0].option })),
                                    errors['questions'] &&
                                        errors['questions'][index] &&
                                        errors['questions'][index].qoptions &&
                                        errors['questions'][index].qoptions[0] &&
                                        touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index].qoptions[0])) : null),
                                React.createElement("div", { className: "option-error-wrapper linear-wrapper" },
                                    React.createElement("div", { className: "option-wrapper" },
                                        React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Label 2", name: "Label 2", onChange: function (val) {
                                                values.questions[index].qoptions[1].option = val;
                                                setFieldValue('questions', values.questions);
                                            }, value: values.questions[index].qoptions[1].option })),
                                    errors['questions'] &&
                                        errors['questions'][index] &&
                                        errors['questions'][index].qoptions &&
                                        errors['questions'][index].qoptions[1] &&
                                        touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index].qoptions[1])) : null))));
                    } }));
            case 'Short Answer':
                return (React.createElement(formik_1.FieldArray, { name: "questions[".concat(index, "].qoptions"), render: function (_a) {
                        var push = _a.push, remove = _a.remove, insert = _a.insert;
                        return (React.createElement("div", { className: "options-container" },
                            React.createElement("div", { className: "option-wrapper" },
                                React.createElement(checkbox_component_1.default, { checked: values.questions[index].qoptions[0].option === 'numeric', disabled: values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT ? true : false, label: "Numeric", handleChange: function (e) {
                                        if (e.target.checked) {
                                            values.questions[index].qoptions[0].option = 'numeric';
                                        }
                                        else {
                                            values.questions[index].qoptions[0].option =
                                                'non numeric';
                                        }
                                        setFieldValue('questions', values.questions);
                                    } }))));
                    } }));
            case 'Mobile Number':
                return (React.createElement(formik_1.FieldArray, { name: "questions[".concat(index, "].qoptions"), render: function (_a) {
                        var push = _a.push, remove = _a.remove, insert = _a.insert;
                        return (React.createElement("div", { className: "options-container" },
                            React.createElement("div", { className: "option-wrapper" },
                                React.createElement(checkbox_component_1.default, { checked: true, disabled: true, label: "Numeric", handleChange: function (e) {
                                        if (e.target.checked) {
                                            values.questions[index].qoptions[0].option = 'numeric';
                                        }
                                        else {
                                            values.questions[index].qoptions[0].option =
                                                'non numeric';
                                        }
                                        setFieldValue('questions', values.questions);
                                    } }))));
                    } }));
        }
    };
    return (React.createElement("div", { className: "page-wrapper" },
        React.createElement("div", { className: "create-audit-form" },
            React.createElement("div", { className: "input-fileds-wrapper" },
                React.createElement(formik_1.Formik, { enableReinitialize: false, validateOnBlur: false, initialValues: __assign({}, initialValues), validationSchema: schema_1.schemas.AUDIT_CREATE_SCHEMA, onSubmit: function (values, _a) {
                        var resetForm = _a.resetForm;
                        formSubmitHnadler(values);
                    } }, function (_a) {
                    var errors = _a.errors, setErrors = _a.setErrors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched;
                    return (React.createElement(formik_1.Form, null,
                        console.log(values, 'audit erros:', errors),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 12 },
                                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "warehouse_code", placeholder: "Select audit type", label: "AUDIT TYPE", value: values.audit_type, dropDownList: audit_types ? audit_types : [], onChange: function (val) {
                                        if (val.label != values.audit_type.label) {
                                            if (val.label == audit_helpers_1.AUDIT_TYPE.NORAM_AUDIT) {
                                                setAuditType(audit_helpers_1.AUDIT_TYPE.NORAM_AUDIT);
                                                setIsModalOpen(true);
                                            }
                                            else {
                                                setAuditType(audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT);
                                                setIsModalOpen(true);
                                            }
                                        }
                                    } })),
                            React.createElement(Grid_1.default, { item: true, xs: 7, container: true, columnSpacing: 3 },
                                React.createElement(Grid_1.default, { item: true, xs: 12 },
                                    React.createElement(text_filed_component_1.default, { className: "text-caps", name: 'auditTitle', label: "Audit Title", onChange: function (val) {
                                            setFieldValue('auditTitle', val);
                                            setFieldTouched('auditTitle', true);
                                        }, value: values.auditTitle }),
                                    errors['auditTitle'] && touched['auditTitle'] ? (React.createElement("div", { className: "error" }, errors['auditTitle'])) : null),
                                React.createElement(Grid_1.default, { item: true, xs: 12 },
                                    React.createElement(text_area_component_1.default, { className: "text-caps", minRows: 5, name: 'audit description', value: values.auditDescription, placeholder: "Audit Description", onChange: function (val) {
                                            setFieldTouched('auditDescription', true);
                                            setFieldValue('auditDescription', val);
                                        } }),
                                    errors['auditDescription'] &&
                                        touched['auditDescription'] ? (React.createElement("div", { className: "error" }, errors['auditDescription'])) : null)),
                            React.createElement(Grid_1.default, { item: true, xs: 4 },
                                React.createElement(file_upload_component_1.default, { buttonName: "Upload Photo", name: "AuditPhoto", key: 'fileupload', acceptedFiletypes: ['image/jpeg', 'image/png'], maxSize: {
                                        image: 2,
                                        pdf: 2,
                                        csv: 2,
                                    }, value: values.AuditPhoto, onChange: function (val) {
                                        setFieldValue('AuditPhoto', val);
                                        setFieldTouched('AuditPhoto', true);
                                    }, removeImage: function () {
                                        setFieldValue('AuditPhoto', '');
                                        setFieldTouched('AuditPhoto', true);
                                    }, message: 'Note:Only png, jpg, jpeg formats are allowed upto 2MB' }),
                                errors['AuditPhoto'] && touched['AuditPhoto'] ? (React.createElement("div", { className: "error" }, errors['AuditPhoto'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement("div", { className: " question-no-selection" },
                                    React.createElement("div", { className: "question-no-wrapper displayflex" },
                                        React.createElement("p", { className: "text-normal" }, "Total Number of Questions"),
                                        React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.totalQuestionsDropdown, placeholder: "Select", value: values['totalNoOfQuestion'], isDisabled: values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT ? true : false, onChange: function (val) {
                                                setFieldValue('totalNoOfQuestion', val);
                                                setFieldTouched('totalNoOfQuestion', true);
                                            } })),
                                    errors['totalNoOfQuestion'] &&
                                        touched['totalNoOfQuestion'] ? (React.createElement("div", { className: "error" }, errors['totalNoOfQuestion'])) : null))),
                        React.createElement(formik_1.FieldArray, { name: "questions", render: function (_a) {
                                var push = _a.push, remove = _a.remove, insert = _a.insert;
                                return (React.createElement(React.Fragment, null,
                                    React.createElement("div", null,
                                        values.questions.map(function (queston, index) { return (React.createElement(Grid_1.default, { item: true, xs: 12 },
                                            React.createElement("div", { className: "question-container" },
                                                React.createElement("div", { className: "question-header" },
                                                    React.createElement("div", { className: "question-type-wrapper" },
                                                        React.createElement(drop_down_component_1.default, { name: "question.".concat(index), key: appConstants_1.questionTypeDropdown.toString(), dropDownList: appConstants_1.questionTypeDropdown, isDisabled: values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT ? true : false, placeholder: "Question Type", value: values['questions'][index].qtype, onChange: function (val) {
                                                                values.questions[index].qtype = val;
                                                                if (val.label === 'Linear Scale') {
                                                                    values.questions[index].qoptions = [
                                                                        {
                                                                            option: '',
                                                                        },
                                                                        {
                                                                            option: '',
                                                                        },
                                                                    ];
                                                                    values.questions[index].minrange = null;
                                                                    values.questions[index].maxrange = null;
                                                                }
                                                                else if (val.label === 'True/False') {
                                                                    values.questions[index].qoptions = [
                                                                        {
                                                                            option: 'TRUE',
                                                                        },
                                                                        {
                                                                            option: 'FALSE',
                                                                        },
                                                                    ];
                                                                    delete values.questions[index].minrange;
                                                                    delete values.questions[index].maxrange;
                                                                }
                                                                else {
                                                                    if (val.label === 'Short Answer') {
                                                                        values.questions[index].qoptions = [
                                                                            {
                                                                                option: 'non numeric',
                                                                            },
                                                                        ];
                                                                        delete values.questions[index]
                                                                            .minrange;
                                                                        delete values.questions[index]
                                                                            .maxrange;
                                                                    }
                                                                    else if (val.label === 'Mobile Number') {
                                                                        values.questions[index].qoptions = [
                                                                            {
                                                                                option: 'numeric',
                                                                            },
                                                                        ];
                                                                        delete values.questions[index]
                                                                            .minrange;
                                                                        delete values.questions[index]
                                                                            .maxrange;
                                                                    }
                                                                    else {
                                                                        values.questions[index].qoptions = [
                                                                            {
                                                                                option: '',
                                                                            },
                                                                            {
                                                                                option: '',
                                                                            },
                                                                            {
                                                                                option: '',
                                                                            },
                                                                            {
                                                                                option: '',
                                                                            },
                                                                        ];
                                                                        delete values.questions[index]
                                                                            .minrange;
                                                                        delete values.questions[index]
                                                                            .maxrange;
                                                                    }
                                                                }
                                                                setFieldValue('questions', __spreadArray([], values.questions, true));
                                                                setErrors(__assign(__assign({}, errors), { questions: [] }));
                                                            } })),
                                                    React.createElement("div", { className: "question-action-items" },
                                                        React.createElement(iconButton_component_1.default, { className: "squar-btn icon-btn", icon: "icon-content_copy", isDisabled: values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT || values.questions[index].qtype.label == 'Mobile Number' ? true : false, onClick: function () {
                                                                if (values.totalNoOfQuestion) {
                                                                    if (values.questions.length <
                                                                        values.totalNoOfQuestion.value) {
                                                                        var options_1 = [];
                                                                        values.questions[index].qoptions.map(function (qoption) {
                                                                            return options_1.push({
                                                                                option: qoption.option,
                                                                            });
                                                                        });
                                                                        insert(index, __assign(__assign({}, values.questions[index]), { qoptions: options_1 }));
                                                                        setFieldTouched('questions', false);
                                                                    }
                                                                    else {
                                                                        react_hot_toast_1.default.error('Please Change Total no of Questions');
                                                                    }
                                                                }
                                                                else {
                                                                    react_hot_toast_1.default.error('Please Change Total no of Questions');
                                                                }
                                                            } }),
                                                        React.createElement(iconButton_component_1.default, { className: "squar-btn icon-btn", icon: "icon-delete", isDisabled: values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT || values.questions[index].qtype.label == 'Mobile Number' ? true : false, onClick: function () {
                                                                remove(index);
                                                            } }),
                                                        React.createElement(switch_button_1.default, { labelIcon: "icon-photo_camera", isDisabled: values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT || values.questions[index].qtype.label == 'Mobile Number' ? true : false, onChangeHandler: function (e) {
                                                                values.questions[index].isPhotoRequired =
                                                                    e.target.checked;
                                                                setFieldValue('questions', values.questions);
                                                            }, checked: values.questions[index].isPhotoRequired }))),
                                                React.createElement("div", { className: "question-error-wrapper" },
                                                    React.createElement("div", { className: "question-wrapper" },
                                                        React.createElement("span", { className: "sr-no" },
                                                            "Q",
                                                            index + 1,
                                                            "."),
                                                        React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Question Title", name: 'Question Title', isDisabled: values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT ? true : false, onChange: function (val) {
                                                                values.questions[index].qtitile = val;
                                                                setFieldValue('questions', values.questions);
                                                            }, value: values.questions[index].qtitile })),
                                                    errors['questions'] &&
                                                        errors['questions'][index] &&
                                                        errors['questions'][index]['qtitile'] &&
                                                        touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index]['qtitile'])) : null),
                                                renderOptions(queston, values, setFieldValue, index, setFieldTouched, errors, touched)),
                                            errors['questions'] &&
                                                typeof errors['questions'] === 'object' &&
                                                errors['questions'][index] &&
                                                typeof errors['questions'][index] === 'string' &&
                                                touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'][index])) : null)); }),
                                        React.createElement("div", { className: "question-container" },
                                            React.createElement("div", { className: " add-question-wrapper", onClick: function () {
                                                    if (values.audit_type.label != audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT) {
                                                        if (values.totalNoOfQuestion) {
                                                            if (values.questions.length <
                                                                values.totalNoOfQuestion.value) {
                                                                push({
                                                                    qtype: {
                                                                        id: 2,
                                                                        label: 'Multiple Choice',
                                                                        value: 2,
                                                                    },
                                                                    qtitile: '',
                                                                    qoptions: [
                                                                        {
                                                                            option: '',
                                                                        },
                                                                        {
                                                                            option: '',
                                                                        },
                                                                        {
                                                                            option: '',
                                                                        },
                                                                        {
                                                                            option: '',
                                                                        },
                                                                    ],
                                                                    isPhotoRequired: false,
                                                                });
                                                                setFieldTouched('questions', false);
                                                            }
                                                            else {
                                                                react_hot_toast_1.default.error('Please Change Total no of Questions');
                                                            }
                                                        }
                                                        else {
                                                            react_hot_toast_1.default.error('Please Change Total no of Questions');
                                                        }
                                                    }
                                                } },
                                                React.createElement("h2", { className: "text-normal ".concat(values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT ? 'is_phlebo_audit' : '') }, "Add Question"),
                                                React.createElement("span", { className: "icon-add ".concat(values.audit_type.label == audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT ? 'is_phlebo_audit' : 'cursor-pointer') }))),
                                        errors['questions'] &&
                                            typeof errors['questions'] === 'string' &&
                                            touched['questions'] ? (React.createElement("div", { className: "error" }, errors['questions'])) : null)));
                            } }),
                        React.createElement("div", { className: "form-btns" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: 'Cancel', color: "yellow-outline", isError: false, onClickHandler: cancelButtonHandler }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: 'Review', color: "yellow", isError: false })),
                        React.createElement(ModalComponentWithoutButton_1.default, { open: isModalOpen },
                            React.createElement("div", { className: "modal-header " },
                                React.createElement("h2", { className: "heading" }, "Confirmation"),
                                React.createElement("p", { className: "sub-heading" }, "API Integration with Partner Portal will ".concat(auditType == audit_helpers_1.AUDIT_TYPE.NORAM_AUDIT ? 'disabled' : 'Enabled', " for Phlebo Audit?."),
                                    React.createElement("br", null))),
                            React.createElement("div", { className: "modal-footer" },
                                React.createElement(button_component_1.default, { buttonName: "NO", color: "yellow-outline", onClickHandler: function () {
                                        setIsModalOpen(false);
                                        if (auditType == audit_helpers_1.AUDIT_TYPE.NORAM_AUDIT) {
                                            setAuditType(audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT);
                                        }
                                        else {
                                            setAuditType(audit_helpers_1.AUDIT_TYPE.NORAM_AUDIT);
                                        }
                                    } }),
                                React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", onClickHandler: function () {
                                        setIsModalOpen(false);
                                        if (auditType == audit_helpers_1.AUDIT_TYPE.NORAM_AUDIT) {
                                            if (id) {
                                                setIsPhleboAudit(false);
                                            }
                                            setFieldValue('audit_type', { label: audit_helpers_1.AUDIT_TYPE.NORAM_AUDIT, value: 0, id: 0 });
                                            setFieldValue('totalNoOfQuestion', null);
                                            setFieldValue('questions', []);
                                            setFieldTouched('totalNoOfQuestion', false);
                                        }
                                        else {
                                            if (id) {
                                                setIsPhleboAudit(true);
                                            }
                                            setFieldValue('audit_type', { label: audit_helpers_1.AUDIT_TYPE.PHLEBO_AUDIT, value: 1, id: 1 });
                                            values.questions = [
                                                {
                                                    qtype: {
                                                        id: 5,
                                                        label: "Short Answer",
                                                        value: 5
                                                    },
                                                    qtitile: "name of phlebo",
                                                    qoptions: [
                                                        {
                                                            option: "non numeric"
                                                        }
                                                    ],
                                                    isPhotoRequired: false
                                                },
                                                {
                                                    qtype: {
                                                        id: 6,
                                                        label: "Mobile Number",
                                                        value: 6
                                                    },
                                                    qtitile: "mobile no. of phlebo",
                                                    qoptions: [
                                                        {
                                                            option: "numeric"
                                                        }
                                                    ],
                                                    isPhotoRequired: false
                                                },
                                            ];
                                            setFieldValue('questions', __spreadArray([], values.questions, true));
                                            setFieldValue('totalNoOfQuestion', appConstants_1.totalQuestionsDropdown[1]);
                                            setFieldTouched('totalNoOfQuestion', true);
                                        }
                                    } })))));
                })))));
};
exports.default = AddAudit;
