"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchMisFeRouteeList = exports.fetchEventPerformanceList = void 0;
var api_service_1 = require("../../../api/api_service");
var fetchEventPerformanceList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.MIS_REPORTS_EVENT_PERFORMANCE,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            console.log(err, 'precious');
            return reject(err);
        });
    });
};
exports.fetchEventPerformanceList = fetchEventPerformanceList;
var fetchMisFeRouteeList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.MIS_REPORTS_FE_ROUTE,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            console.log(err, 'precious');
            return reject(err);
        });
    });
};
exports.fetchMisFeRouteeList = fetchMisFeRouteeList;
