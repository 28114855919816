"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPassword = exports.validateForgetPswd = exports.forgetPassword = void 0;
var api_service_1 = require("../../api/api_service");
var forgetPassword = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.FORGOT_PASSWORD,
            domain: process.env.AUTHENTICATION_SERVER_URL,
            payLoad: payload,
        })
            .then(function (response) {
            return resolve(response.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.forgetPassword = forgetPassword;
var validateForgetPswd = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.VALIDATE_FORGET_PASSWORD,
            domain: process.env.AUTHENTICATION_SERVER_URL,
            payLoad: payload,
        })
            .then(function (response) {
            return resolve(response.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.validateForgetPswd = validateForgetPswd;
var resetPassword = function (payload, token) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.RESET_PASSWORD,
            domain: process.env.AUTHENTICATION_SERVER_URL,
            payLoad: payload,
            customHeaders: token,
        })
            .then(function (response) {
            return resolve(response.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.resetPassword = resetPassword;
