"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var appConstants_1 = require("../../../constants/appConstants");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var status_buttons_1 = require("../../../common/buttons/status_buttons");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var Ondemand_Vertical_stepper_1 = require("../../../common/vertical_stepper/Ondemand_Vertical_stepper");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var auto_routing_api_calls_1 = require("./auto_routing_api_calls");
var AssignActiveClients = function () {
    var rowId = (0, react_router_1.useParams)().visit_id;
    var name = (0, react_router_1.useParams)().name;
    console.log(name, 'jhgfrtg');
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _a = React.useState(''), searchTerm = _a[0], setSearchTerm = _a[1];
    var _b = React.useState([]), FEList = _b[0], setFEList = _b[1];
    var _c = React.useState(), feId = _c[0], setFEId = _c[1];
    var _d = React.useState(false), renderstate = _d[0], setRenderState = _d[1];
    var _e = React.useState([]), stepperdata = _e[0], setStepperData = _e[1];
    var _f = React.useState(), fe_id = _f[0], setFeid = _f[1];
    var _g = React.useState([]), fe_code = _g[0], setFecode = _g[1];
    var _h = React.useState([]), fe_name = _h[0], setFename = _h[1];
    var _j = React.useState(), fe_image = _j[0], setFe_image = _j[1];
    var _k = React.useState(), distance = _k[0], setDistance = _k[1];
    console.log(feId, fe_image, 'fe_imhgage');
    console.log(fe_image, 'fe_image');
    var nav = (0, react_router_1.useNavigate)();
    var navigation = function () {
        nav("/".concat(route_path_1.default.PATHS.controlTower));
    };
    var searchFe = function (event) {
        setSearchTerm(event.target.value.trim());
    };
    var onSelectionChange = function (selectedRow) {
        console.log("selectedRow:", selectedRow);
        if (selectedRow) {
            if (fe_id === selectedRow.fe_id) {
                setFeid(null);
                setStepperData([]);
                setFename(null);
                setFecode(null);
                setRenderState(false);
            }
            else {
                setRenderState(true);
                setFecode(selectedRow.fe_code);
                setDistance(selectedRow.distance);
                setFename(selectedRow.fe_name);
                setFeid(selectedRow.fe_id);
                setStepperData(selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.schedule);
                setFe_image(selectedRow.schedule[0].fe_avatar);
            }
        }
        else {
            setFeid(null);
            setStepperData([]);
            setFename(null);
            setFecode(null);
            setRenderState(false);
            setFe_image(null);
        }
    };
    var assignFE = function () {
        if (fe_id) {
            var payload = {};
            payload['visit_id'] = parseInt(rowId);
            payload['fe_id'] = fe_id;
            (0, auto_routing_api_calls_1.unAssignFE)(payload)
                .then(function (response) {
                if (response.statusCode === 200) {
                    console.log(response.message, 'w34edrfc');
                    react_hot_toast_1.default.success(response.message);
                    UpdateListData.setIsListReloaded(true);
                    nav("/".concat(route_path_1.default.PATHS.controlTower));
                }
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err);
            });
        }
    };
    var getFeList = function () {
        var payload = {};
        if (searchTerm) {
            payload['search_string'] = searchTerm;
        }
        if (rowId) {
            payload['visit_id'] = rowId;
        }
        payload['visit_id'] = rowId;
        (0, auto_routing_api_calls_1.availableFEsList)(payload)
            .then(function (response) {
            setFEList(response);
            response.map(function (eachFE) {
                setFEId(eachFE.fe_id);
            });
        })
            .catch(function (err) {
            console.log(err.message);
        });
    };
    React.useEffect(function () {
        getFeList();
    }, [searchTerm, rowId]);
    return (React.createElement("div", { className: "page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { onClick: navigation, className: "btn-retun", icon: 'icon-left-arrow' }),
                React.createElement("h2", null, name === 'dynamic'
                    ? 'Unassigned Dynamic Pickups'
                    : 'Unassigned Static Pickups')),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigation }),
                React.createElement(button_component_1.default, { buttonName: "Assign", color: "yellow", onClickHandler: assignFE }))),
        React.createElement(Grid_1.default, { container: true, columns: 12, columnSpacing: 2 },
            React.createElement(Grid_1.default, { lg: FEList.length > 0 && renderstate ? 7 : 12 },
                React.createElement("div", { className: "list-wrapper p16" },
                    React.createElement("div", { className: "page-aligner" },
                        React.createElement("div", { className: "tool-bar-wrapper padding-12X24" },
                            React.createElement("div", { className: "page-heaidng" },
                                React.createElement("h2", null, "Available FE\u2019s"))),
                        React.createElement("div", { className: "filter-menu-wrapper padding-12X24" },
                            React.createElement("div", { className: "search-filter" },
                                React.createElement(search_component_1.default, { placeholder: "Search FE", searchValue: searchTerm, searchHandler: searchFe })))),
                    React.createElement("div", { className: "fe-wrapper pay-fe-wrapper nodata-alignment" },
                        React.createElement("div", { className: "scrollable-content clickable-row " },
                            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ASSIGN_ONDEMAND_REQUEST, rows: FEList && FEList, pagination: false, rowMultiSelectWithClick: true, onSelectionChange: onSelectionChange }))))),
            FEList.length > 0 && renderstate ? (React.createElement(Grid_1.default, { lg: FEList.length > 0 && renderstate ? 5 : 0 },
                React.createElement("div", { className: "fe-details-wrapper" },
                    React.createElement("div", { className: "user-card displayflex " },
                        React.createElement("div", { className: "user-details-wrapper displayflex " },
                            React.createElement("div", { className: "avatar-wrapper" }, stepperdata && fe_image ? (React.createElement(material_1.Avatar, { src: fe_image })) : (React.createElement(material_1.Avatar, null, fe_name && fe_name.toString().charAt(0).toUpperCase()))),
                            React.createElement("div", { className: "user-name-wrapper" },
                                React.createElement("p", { className: "user-name" }, fe_name),
                                React.createElement("span", { className: "user-id" }, fe_code))),
                        React.createElement(status_buttons_1.default, { status: 1 })),
                    React.createElement("div", { className: "user-tracking-details fe-track-wrapper" },
                        React.createElement("div", { className: "tab-heaidng " },
                            React.createElement("div", { className: "tab-heading-container flex-box-between" },
                                React.createElement("h2", null, "Pickups & Handovers"),
                                React.createElement("small", { className: "mini-header" }, " Today"))),
                        renderstate && (React.createElement(Ondemand_Vertical_stepper_1.default, { trackerInfo: stepperdata, distance: distance })))))) : (''))));
};
exports.default = AssignActiveClients;
