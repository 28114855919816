"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./orc_styles.scss");
var ExpandMore_1 = require("@mui/icons-material/ExpandMore");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var profile_grid_1 = require("../../../../common/profile_grid/profile_grid");
var orc_service_1 = require("./orc_service");
var delete_confirmation_modal_1 = require("../../../../common/confirmation/delete_confirmation_modal");
var ModalComponentWithoutButton_1 = require("../../../../common/modal/ModalComponentWithoutButton");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var formik_1 = require("formik");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var react_redux_1 = require("react-redux");
var schema_1 = require("../../../../constants/schema");
var api_service_1 = require("../../../../api/api_service");
var orc_api_service_1 = require("./orc_api_service");
var react_hot_toast_1 = require("react-hot-toast");
var route_path_1 = require("../../../../constants/route_path");
var array_helpers_1 = require("../../../../utils/array_helpers");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var EMPTY_VALUE = '--';
var InitialProfildeDetails = {
    primary_center_data: {},
    updated_data: {
        primary_center_data: {},
    },
    sameAsPrevious: [],
    deleted: [],
    modified: [],
    newlyAdded: []
};
var ORCProfileDetails = function () {
    var orc_rejection_reasons = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.orc_rejection_reasons; });
    var nav = (0, react_router_1.useNavigate)();
    var pathname = (0, react_router_1.useLocation)().pathname;
    var _a = (0, react_router_1.useParams)(), id = _a.id, center_id = _a.center_id;
    var _b = React.useState(false), approvalConfirModal = _b[0], setapprovalConfirModal = _b[1];
    var _c = React.useState(false), rejectedConfirModal = _c[0], setRejectedConfirModal = _c[1];
    var _d = React.useState(InitialProfildeDetails), profileData = _d[0], setProfileData = _d[1];
    var initialValues = {
        reject_type: null,
        reject_comment: '',
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.ORC_Management);
    var approvaRejectHandlere = function (payload, isModalOpened, actionType) {
        (0, orc_api_service_1.updateORCList)(api_service_1.api_service.api_urls.ORC_APPROVE_REJECT, payload, process.env.APPROVAL_BASEURL).then(function (res) {
            if (actionType == orc_service_1.ORC_CLIENT_STATUS_OBJ.LEVEL_ONE_REJECTED || actionType == orc_service_1.ORC_CLIENT_STATUS_OBJ.LEVEL_TWO_REJECTED) {
                setRejectedConfirModal(isModalOpened);
                nav("/".concat(route_path_1.default.PATHS.orc_listing, "/").concat(route_path_1.default.PATHS.orc_rejected_listing));
                react_hot_toast_1.default.success(res.message);
            }
            else {
                react_hot_toast_1.default.success(res.message);
                setapprovalConfirModal(isModalOpened);
                nav("/".concat(route_path_1.default.PATHS.orc_listing, "/").concat(route_path_1.default.PATHS.orc_leve2_listing));
            }
        }).catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var approvalSubmitHandlere = function (isModalOpened) {
        var status = pathname.split('/')[2] == route_path_1.default.PATHS.orc_level1_listing ? orc_service_1.ORC_CLIENT_STATUS_OBJ.PENDING_LEVEL_TWO : orc_service_1.ORC_CLIENT_STATUS_OBJ.LEVEL_TWO_APPROVED;
        var payload = {
            id: id,
            status: status
        };
        approvaRejectHandlere(payload, isModalOpened, status);
    };
    var approvalCloseHandlere = function (isModalOpened) {
        setapprovalConfirModal(isModalOpened);
    };
    var rejectedSubmitHanlere = function (values) {
        var status = pathname.split('/')[2] == route_path_1.default.PATHS.orc_level1_listing ? orc_service_1.ORC_CLIENT_STATUS_OBJ.LEVEL_ONE_REJECTED : orc_service_1.ORC_CLIENT_STATUS_OBJ.LEVEL_TWO_REJECTED;
        var payload = {
            id: id,
            status: status,
            rejected_comments: "".concat(values.reject_type.label, " - ").concat(values.reject_comment)
        };
        approvaRejectHandlere(payload, false, status);
    };
    var rejecteCloseHandlere = function () {
        setRejectedConfirModal(false);
    };
    var checkModifiedStatus = function (actionType) {
        if (actionType == orc_service_1.ORC_CLIENT_ACTION_STATUS.CREATE) {
            return 'new-child-client';
        }
        else if (actionType == orc_service_1.ORC_CLIENT_ACTION_STATUS.UPDATE) {
            return 'update-child-client';
        }
        else if (actionType == orc_service_1.ORC_CLIENT_ACTION_STATUS.DELETE) {
            return 'delete-child-client';
        }
        else {
            return '';
        }
    };
    var checkStatus = function (status) {
        if (status == orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE) {
            return "Deactive";
        }
        else if (status == orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED) {
            return "Deleted";
        }
        else {
            return 'Active';
        }
    };
    var checkActionStatus = function (status) {
        if (status == orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE) {
            return "Deactivated";
        }
        else if (status == orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE) {
            return "Activated";
        }
        else {
            return 'Created';
        }
    };
    React.useEffect(function () {
        if (id) {
            api_service_1.api_service.get({
                endPoint: "".concat(api_service_1.api_service.api_urls.ORC_PROFILE_DETAILS, "/").concat(center_id),
                domain: process.env.APPROVAL_BASEURL,
            }).then(function (res) {
                if (res.data.statusCode == 200) {
                    var convertedData = (0, orc_service_1.convertORCProfileAPIResponse)(res.data.data);
                    var finaData = (0, orc_service_1.orcProfildeDetailsConvertedAsPerUI)(convertedData);
                    setProfileData(finaData);
                }
                else {
                    setProfileData({
                        primary_center_data: {},
                        updated_data: {
                            primary_center_data: {},
                        },
                        sameAsPrevious: [],
                        deleted: [],
                        modified: [],
                        newlyAdded: []
                    });
                    react_hot_toast_1.default.error(res.data.message);
                }
            }).catch(function (err) {
                setProfileData({
                    primary_center_data: {},
                    updated_data: {
                        primary_center_data: {},
                    },
                    sameAsPrevious: [],
                    deleted: [],
                    modified: [],
                    newlyAdded: []
                });
                return err;
            });
        }
    }, [id]);
    var primary_center_data = profileData.primary_center_data, sameAsPrevious = profileData.sameAsPrevious, modified = profileData.modified, deleted = profileData.deleted, newlyAdded = profileData.newlyAdded, updated_data = profileData.updated_data;
    var renderSameAsPrevDeletedNewlyAddedClinet = function (data, grid_size, actionType) {
        return React.createElement(React.Fragment, null, data.map(function (val, index) {
            return React.createElement(material_1.Accordion, { key: index, className: checkModifiedStatus(actionType) },
                React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null) },
                    React.createElement("p", { className: "accoridan-heading" },
                        "Associate Client: ", "".concat(val.warehouse_code, " - ").concat(val.center_name))),
                React.createElement(material_1.AccordionDetails, null,
                    React.createElement("div", { className: "visit-details" },
                        React.createElement(material_1.Grid, { container: true, spacing: 2, columns: { xs: 12 } },
                            React.createElement(material_1.Grid, { item: true, xs: grid_size, container: true },
                                React.createElement(material_1.Grid, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Associative_clients, value: val.warehouse_code }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Invoice_Code, value: val.invoice_code }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Client_Name, value: "".concat(val.center_name.slice(0, 15), "...") }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.state, value: val.center_state }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.city, value: val.center_city }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Labcode, value: val.lab_code }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Lab_Name, value: "".concat(val.lab_name.slice(0, 15), "...") }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Route_Type, value: val.route_type }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Compnay_ID, value: val.data_area_id }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Percentage_of_sales, value: "".concat(val.sales_percentage, " ").concat(val.sales_percentage ? "%" : '') }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Capping_Amount, value: val.capping_amount })))))));
        }));
    };
    var renderModifiedClient = function (data) {
        return React.createElement(React.Fragment, null, data.map(function (val, index) {
            return React.createElement(material_1.Accordion, { key: index, className: 'update-child-client' },
                React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null) },
                    React.createElement("p", { className: "accoridan-heading" },
                        "Associate Client: ", "".concat(val.previous.warehouse_code, " - ").concat(val.previous.center_name))),
                React.createElement(material_1.AccordionDetails, null,
                    React.createElement("div", { className: "visit-details" },
                        React.createElement(material_1.Grid, { container: true, spacing: 2, columns: { xs: 12 } },
                            React.createElement(material_1.Grid, { item: true, xs: 6, container: true },
                                React.createElement(material_1.Grid, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                    React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                        React.createElement("h2", null, "Previous Data"))),
                                React.createElement(material_1.Grid, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Associative_clients, value: val.previous.warehouse_code }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Invoice_Code, value: val.previous.invoice_code }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Client_Name, value: "".concat(val.previous.center_name.slice(0, 15), "...") }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.state, value: val.previous.center_state }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.city, value: val.previous.center_city }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Labcode, value: val.previous.lab_code }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Lab_Name, value: "".concat(val.previous.lab_name.slice(0, 15), "...") }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Route_Type, value: val.previous.route_type }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Compnay_ID, value: val.previous.data_area_id }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Percentage_of_sales, value: "".concat(val.previous.sales_percentage, " ").concat(val.previous.sales_percentage ? "%" : '') }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Capping_Amount, value: val.previous.capping_amount }),
                                    React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Status, value: checkStatus(val.previous.status) }))),
                            React.createElement(material_1.Grid, { item: true, xs: 6, container: true },
                                React.createElement(material_1.Grid, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                    React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                        React.createElement("h2", null, "Updated Data"))),
                                React.createElement(material_1.Grid, { item: true, xs: 12, container: true, className: "update-mini-details-wrapper" },
                                    val.previous.sales_percentage != val.updated.sales_percentage &&
                                        React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Percentage_of_sales, value: "".concat(val.updated.sales_percentage, " ").concat(val.previous.sales_percentage ? "%" : '') }),
                                    val.previous.capping_amount != val.updated.capping_amount &&
                                        React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Capping_Amount, value: val.updated.capping_amount }),
                                    val.previous.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE.toString() && val.updated.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE.toString() &&
                                        React.createElement(React.Fragment, null,
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Status, value: checkActionStatus(val.updated.status) }),
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.ReasonFor_Deactivate, value: val.updated.status_reason.split('-')[0] })),
                                    val.previous.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE.toString() && val.updated.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE.toString() &&
                                        React.createElement(profile_grid_1.default, { grid_size: 4, lableName: orc_service_1.ocr_lables.Status, value: checkActionStatus(val.updated.status) })))))));
        }));
    };
    return (React.createElement("div", { className: "page-wrapper" },
        React.createElement("div", { className: "vendor-profile-details fe-wrapper page-wrapper" },
            React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom" },
                React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                    React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: function () { return nav(-1); } }),
                    React.createElement("h2", null, "ORC Details")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: 'Approve', color: "yellow-outline", isError: !features[menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_APPROVE_ACTION], onClickHandler: function () { return setapprovalConfirModal(true); } }),
                    React.createElement(button_component_1.default, { buttonName: 'Reject', color: 'yellow-outline', isError: !features[menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_APPROVE_ACTION], onClickHandler: function () { return setRejectedConfirModal(true); } }))),
            React.createElement(material_1.Grid, { container: true, xs: 12, className: "details-tab-wrapper" },
                React.createElement(material_1.Grid, { item: true, xs: 12 },
                    React.createElement("div", { className: "details-wrapper vendor-details-wrapper" },
                        React.createElement("div", { className: "orc-deatils-tab" },
                            React.createElement(material_1.Grid, { container: true, spacing: 0, columns: { xs: 18 }, columnSpacing: 3 },
                                React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.Primary_Client, value: primary_center_data && primary_center_data.warehouse_code ? primary_center_data.warehouse_code : EMPTY_VALUE }),
                                React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.Invoice_Code, value: primary_center_data && primary_center_data.invoice_code ? primary_center_data.invoice_code : EMPTY_VALUE }),
                                React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.Client_Name, value: primary_center_data && primary_center_data.center_name ? "".concat(primary_center_data.center_name.slice(0, 25)) : EMPTY_VALUE }),
                                React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.state, value: primary_center_data && primary_center_data.center_state ? primary_center_data.center_state : EMPTY_VALUE }),
                                React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.city, value: primary_center_data && primary_center_data.center_city ? primary_center_data.center_city : EMPTY_VALUE }),
                                React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.Labcode, value: primary_center_data && primary_center_data.lab_code ? primary_center_data.lab_code : EMPTY_VALUE }),
                                React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.Lab_Name, value: primary_center_data && primary_center_data.lab_name ? "".concat(primary_center_data.lab_name.slice(0, 25)) : EMPTY_VALUE }),
                                React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.Route_Type, value: primary_center_data && primary_center_data.route_type ? primary_center_data.route_type : EMPTY_VALUE }),
                                React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.Compnay_ID, value: primary_center_data && primary_center_data.data_area_id ? primary_center_data.data_area_id : EMPTY_VALUE }),
                                updated_data && updated_data.primary_center_data && updated_data.primary_center_data.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE.toString() && primary_center_data.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE.toString() &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement(profile_grid_1.default, { grid_size: 3, lableName: orc_service_1.ocr_lables.ReasonFor_Deactivate, value: updated_data && updated_data.primary_center_data && updated_data.primary_center_data.status_reason ? updated_data.primary_center_data.status_reason.split('-')[0] : EMPTY_VALUE }),
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'previous-status', lableName: orc_service_1.ocr_lables.PreviousStatus, value: checkStatus(primary_center_data.status) }),
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'updated-status', lableName: orc_service_1.ocr_lables.UpdatedStatus, value: checkStatus(updated_data.primary_center_data.status) })),
                                updated_data && updated_data.primary_center_data && updated_data.primary_center_data.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE.toString() && primary_center_data.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE.toString() &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'previous-status', lableName: orc_service_1.ocr_lables.PreviousStatus, value: checkStatus(primary_center_data.status) }),
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'updated-status', lableName: orc_service_1.ocr_lables.UpdatedStatus, value: checkStatus(updated_data.primary_center_data.status) })),
                                updated_data && updated_data.primary_center_data && updated_data.primary_center_data.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED.toString() && primary_center_data.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE.toString() &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'previous-status', lableName: orc_service_1.ocr_lables.PreviousStatus, value: checkStatus(primary_center_data.status) }),
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'updated-status', lableName: orc_service_1.ocr_lables.UpdatedStatus, value: checkStatus(updated_data.primary_center_data.status) }))))))),
            React.createElement(material_1.Grid, { className: "orc-tab-heading-wrapper", item: true, xs: 12 },
                React.createElement("div", { className: "tab-heaidng orc-profile-detail-heaidng", style: { display: 'flex' } },
                    React.createElement("h2", null, "Associate Client Details"),
                    React.createElement("div", { className: "cc-slot-legend align-center" },
                        React.createElement("div", { className: "legend-item bg-previous" }, "Same as preivous"),
                        React.createElement("div", { className: "legend-item bg-modified" }, "Modified"),
                        React.createElement("div", { className: "legend-item bg-deleted" }, "Deleted"),
                        React.createElement("div", { className: "legend-item bg-added" }, "Newly Added")))),
            React.createElement("div", { className: "orc-approve-pro-details" },
                React.createElement("div", { className: "visits-accordian-wrapper" },
                    modified && modified.length > 0 && renderModifiedClient(modified),
                    deleted && deleted.length > 0 && renderSameAsPrevDeletedNewlyAddedClinet(deleted, 12, orc_service_1.ORC_CLIENT_ACTION_STATUS.DELETE),
                    newlyAdded && newlyAdded.length > 0 && renderSameAsPrevDeletedNewlyAddedClinet(newlyAdded, 12, orc_service_1.ORC_CLIENT_ACTION_STATUS.CREATE),
                    sameAsPrevious && sameAsPrevious.length > 0 && renderSameAsPrevDeletedNewlyAddedClinet(sameAsPrevious, 12, null)))),
        React.createElement(delete_confirmation_modal_1.default, { deleteConfirModal: approvalConfirModal, deleteOpeneHandlere: approvalSubmitHandlere, deleteCloseHandlere: approvalCloseHandlere, mainTitle: "Confirmation", subTitle: "Are you sure  you want to approve ".concat(primary_center_data && primary_center_data.warehouse_code ? primary_center_data.warehouse_code : EMPTY_VALUE, " warehouse code") }),
        React.createElement(ModalComponentWithoutButton_1.default, { open: rejectedConfirModal },
            React.createElement("div", null,
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("h2", { className: "heading" },
                        "Are you sure  you want to Reject ",
                        primary_center_data && primary_center_data.warehouse_code ? primary_center_data.warehouse_code : EMPTY_VALUE,
                        " warehouse code"))),
            React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, validationSchema: schema_1.schemas.LEVEL_ONE_REJECT_SCHEMA, onSubmit: rejectedSubmitHanlere }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldTouched = _a.setFieldTouched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement(material_1.Grid, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                        React.createElement(material_1.Grid, { item: true, xs: 6 },
                            React.createElement(drop_down_component_1.default, { name: "reject_type", placeholder: "Reason", dropDownList: orc_rejection_reasons, onChange: function (val) {
                                    setFieldValue('reject_type', val);
                                    setFieldTouched('reject_type', true);
                                }, value: values.reject_type }),
                            errors['reject_type'] && touched['reject_type'] ? (React.createElement("div", { className: "error" }, errors['reject_type'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 12 },
                            React.createElement(text_filed_component_1.default, { name: "reject_comment", maxLength: 200, onChange: function (reject_comment) {
                                    setFieldTouched('reject_comment');
                                    setFieldValue('reject_comment', reject_comment);
                                }, label: "Other Comments", value: values.reject_comment }),
                            errors['reject_comment'] && touched['reject_comment'] ? (React.createElement("div", { className: "error" }, errors['reject_comment'])) : null)),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: rejecteCloseHandlere }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
            }))));
};
exports.default = ORCProfileDetails;
