"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Box_1 = require("@mui/material/Box");
var Grid_1 = require("@mui/material/Grid");
var Tab_1 = require("@mui/material/Tab");
var Tabs_1 = require("@mui/material/Tabs");
var Typography_1 = require("@mui/material/Typography");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../../actions/config_action");
var FE_Creation_action_1 = require("../../../../actions/FE_Creation_action");
var api_service_1 = require("../../../../api/api_service");
var api_urls_1 = require("../../../../api/api_urls");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var route_path_1 = require("../../../../constants/route_path");
var array_helpers_1 = require("../../../../utils/array_helpers");
var lodash_1 = require("../../../../utils/lodash");
var generic_api_calls_1 = require("../../../generic_api_calls");
var contract_details_1 = require("./contract_details");
var vendor_contact_details_1 = require("./vendor_contact_details");
var vendor_personal_details_1 = require("./vendor_personal_details");
var TabPanel = function (props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (React.createElement("div", __assign({ role: "tabpanel", hidden: value !== index, id: "vertical-tabpanel-".concat(index), "aria-labelledby": "vertical-tab-".concat(index) }, other), value === index && (React.createElement(Box_1.default, { sx: { p: 3 } },
        React.createElement(Typography_1.default, null, children)))));
};
var a11yProps = function (index) {
    return {
        id: "vertical-tab-".concat(index),
        'aria-controls': "vertical-tabpanel-".concat(index),
    };
};
var Vendor_Personal_DetailsInitialState = {
    vendor_id: null,
    vendorName: '',
    entityType: null,
    contactperson: '',
    designation: '',
    mobilenumber: '',
    email: '',
    alternateNumber: '',
    website: '',
    uploadPhoto: '',
};
var Vendor_ContactDetailsInitialState = {
    addresslane1: '',
    addresslane2: '',
    landmark: '',
    state: null,
    city: null,
    country: null,
    pincode: '',
    proofOfAddress: '',
    permenentAddresslane1: '',
    permenentAddresslane2: '',
    permenentLandmark: '',
    permenentState: null,
    permenentCity: null,
    permenentCountry: null,
    permenentPincode: '',
    permenentProofOfAddress: '',
};
var Vendor_Contract_Details_InitialState = {
    contract_type: null,
    contract_start_date: '',
    contractExpiry: '',
    totalSourceFe: '',
    parent_contract_id: '',
    operatingState: null,
    operatingcity: null,
    contractAgreement: '',
};
var CreateVedor = function () {
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), stateList = _a.stateList, countryList = _a.countryList, entity_type = _a.entity_type, vendor_contract_types = _a.vendor_contract_types;
    var isNewVendor = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer.isNewVendor; });
    var nav = (0, react_router_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_router_1.useParams)(), id = _b.id, activeTabId = _b.activeTabId;
    var vendorCode = (0, react_redux_1.useSelector)(function (state) { return state.user; }).vendorCode;
    var _c = React.useState(false), enableSendItForApproval = _c[0], setEnableSendItForApproval = _c[1];
    var _d = React.useState(null), onBoardingStatus = _d[0], setOnBoardingStatus = _d[1];
    var _e = React.useState(__assign({}, Vendor_Personal_DetailsInitialState)), VendorProfileDetails = _e[0], setVendorProfileDetails = _e[1];
    console.log(VendorProfileDetails, 'VendorProfileDetails');
    var _f = React.useState(__assign({}, Vendor_ContactDetailsInitialState)), VendorContactDetails = _f[0], setVendorContactDetails = _f[1];
    var _g = React.useState(__assign({}, Vendor_Contract_Details_InitialState)), VendorContractDetails = _g[0], setVendorContractDetails = _g[1];
    var _h = React.useState([]), vendor_ids = _h[0], setVendor_ids = _h[1];
    var _j = React.useState([]), selectedStates = _j[0], setSelectedStates = _j[1];
    var _k = React.useState([]), selectedCity = _k[0], setSelectedCity = _k[1];
    var _l = React.useState(false), venodrIdEmpty = _l[0], setVenodrIdEmpty = _l[1];
    var _m = React.useState(0), value = _m[0], setValue = _m[1];
    var _o = React.useState([]), editCityPayload = _o[0], setEditCityPaylioad = _o[1];
    var _p = React.useState([]), createCityPayload = _p[0], setCreateCityPaylioad = _p[1];
    var uploadFile = function (val) {
        var payload = new FormData();
        payload.append('cc_id', vendorCode);
        payload.append('file', val);
        payload.append('file_type', 'Other');
        return (0, generic_api_calls_1.fileUpload)(payload);
    };
    var handleChange = function (__event, newValue) {
        if (id &&
            location.pathname.includes("".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.createVendor))) {
            nav("/".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendormanagement, "/").concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.createVendor, "/").concat(id, "/").concat(newValue));
            setValue(newValue);
        }
        else if (id &&
            location.pathname.includes("".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.editVendor))) {
            nav("/".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendormanagement, "/").concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.editVendor, "/").concat(id, "/").concat(newValue));
            setValue(newValue);
        }
    };
    var updateValue = function (updatedValue) {
        setValue(updatedValue);
    };
    var vendorIdList = function (values, isVedorIdEMpty) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, setVendor_ids(values)];
                case 1:
                    _a.sent();
                    setVenodrIdEmpty(isVedorIdEMpty);
                    return [2];
            }
        });
    }); };
    var saveVendor_ProfileDetails = function (values) {
        setVendorProfileDetails(values);
        updateValue(value + 1);
    };
    var saveVendor_ContactDetails = function (values) {
        setVendorContactDetails(values);
        updateValue(value + 1);
    };
    console.log(VendorContactDetails, 'saveVendor_ContactDetails');
    var saveVendor_Contract_Details = function (values) {
        setVendorContractDetails(values);
    };
    var backButtonHandler = function (routes, tabId) {
        routes == "".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.editVendor)
            ? nav("/".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendormanagement, "/").concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.editVendor, "/").concat(id, "/").concat(tabId))
            : nav("/".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendormanagement, "/").concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.createVendor, "/").concat(id, "/").concat(tabId));
    };
    var clickHandler = function () {
        if (onBoardingStatus === 1) {
            nav("/".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendor, "/").concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendorProfileDetials, "/").concat(id));
        }
        else {
            nav("/".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendor, "/").concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.draft_vendors));
        }
    };
    var checkVendorPersonalDetailsValidation = function () {
        if (VendorProfileDetails.vendor_id == null ||
            VendorProfileDetails.vendor_id === undefined) {
            return false;
        }
        if (VendorProfileDetails.vendorName === '') {
            return false;
        }
        if (VendorProfileDetails.entityType === null) {
            return false;
        }
        if (VendorProfileDetails.email === '') {
            return false;
        }
        if (VendorProfileDetails.contactperson === '') {
            return false;
        }
        if (VendorProfileDetails.designation === '') {
            return false;
        }
        if (VendorProfileDetails.mobilenumber === '') {
            return false;
        }
        if (VendorProfileDetails.uploadPhoto === '') {
            return false;
        }
        return true;
    };
    var checkVendorContactDetailsValidation = function () {
        if (VendorContactDetails.addresslane1 === '') {
            return false;
        }
        if (VendorContactDetails.landmark === '') {
            return false;
        }
        if (VendorContactDetails.state === null) {
            return false;
        }
        if (VendorContactDetails.city === null) {
            return false;
        }
        if (VendorContactDetails.country === null) {
            return false;
        }
        if (VendorContactDetails.pincode === '') {
            return false;
        }
        if (VendorContactDetails.permenentAddresslane1 === '') {
            return false;
        }
        if (VendorContactDetails.permenentLandmark === '') {
            return false;
        }
        if (VendorContactDetails.permenentState === null) {
            return false;
        }
        if (VendorContactDetails.permenentCity === null) {
            return false;
        }
        if (VendorContactDetails.permenentCountry === null) {
            return false;
        }
        if (VendorContactDetails.permenentPincode === '') {
            return false;
        }
        return true;
    };
    var checkVendorContract_DetailsValidation = function () {
        if (VendorContractDetails.contract_type === null) {
            return false;
        }
        if (VendorContractDetails.contract_start_date === '') {
            return false;
        }
        if (VendorContractDetails.contractExpiry === '') {
            return false;
        }
        if (VendorContractDetails.operatingState === null) {
            return false;
        }
        if (VendorContractDetails.operatingcity === null) {
            return false;
        }
        return true;
    };
    var sendItForApproval = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, personalDetailsObj, contactDetailsarr, presentAdressObj, permanentAddressObj, contract_detials;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = {};
                    personalDetailsObj = {};
                    if (!(VendorProfileDetails.uploadPhoto !== '' &&
                        VendorProfileDetails.uploadPhoto !== null &&
                        VendorProfileDetails.uploadPhoto !== undefined)) return [3, 4];
                    if (!(typeof VendorProfileDetails.uploadPhoto === 'object')) return [3, 2];
                    return [4, uploadFile(VendorProfileDetails.uploadPhoto).then(function (res) {
                            if (res.data.statusCode === 200) {
                                personalDetailsObj['vendor_logo'] = res.data.data.name;
                            }
                            else {
                                personalDetailsObj['vendor_logo'] =
                                    VendorProfileDetails.uploadPhoto;
                            }
                        })];
                case 1:
                    _a.sent();
                    return [3, 3];
                case 2:
                    personalDetailsObj['vendor_logo'] = VendorProfileDetails.uploadPhoto;
                    _a.label = 3;
                case 3: return [3, 5];
                case 4:
                    react_hot_toast_1.default.error('Vendor logo in Personal Details section is  Invalid');
                    return [2, false];
                case 5:
                    if (VendorProfileDetails.vendorName !== '') {
                        personalDetailsObj['name'] = VendorProfileDetails.vendorName;
                    }
                    else {
                        react_hot_toast_1.default.error('Vendor name in Personal Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorProfileDetails.contactperson !== '') {
                        personalDetailsObj['contact_name'] = VendorProfileDetails.contactperson;
                    }
                    else {
                        react_hot_toast_1.default.error('Contact Person  in Personal Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorProfileDetails.email !== '') {
                        personalDetailsObj['contact_email'] = VendorProfileDetails.email;
                    }
                    else {
                        react_hot_toast_1.default.error('Contact email  in Personal Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorProfileDetails.entityType != undefined &&
                        VendorProfileDetails.entityType != null) {
                        personalDetailsObj['entity_type'] = VendorProfileDetails.entityType.id;
                    }
                    else {
                        react_hot_toast_1.default.error('Entity type  in Personal Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorProfileDetails.designation !== '') {
                        personalDetailsObj['contact_person_designation'] =
                            VendorProfileDetails.designation;
                    }
                    else {
                        react_hot_toast_1.default.error('Contact designation number in Personal Details section is  Invalid');
                    }
                    if (VendorProfileDetails.mobilenumber !== '') {
                        personalDetailsObj['contact_mobile'] = VendorProfileDetails.mobilenumber;
                    }
                    else {
                        react_hot_toast_1.default.error('Mobile number in Personal Details section is  Invalid');
                        return [2, false];
                    }
                    payload['personal_details'] = personalDetailsObj;
                    contactDetailsarr = [];
                    presentAdressObj = {
                        type: 'Office Address',
                    };
                    if (VendorContactDetails.addresslane1 !== '') {
                        presentAdressObj['lane1'] = VendorContactDetails.addresslane1;
                    }
                    else {
                        react_hot_toast_1.default.error('Cannot send for approval: Address Lane1 in  Current address of Contact Details  section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.addresslane1 !== '') {
                        presentAdressObj['lane2'] = VendorContactDetails.addresslane2;
                    }
                    if (VendorContactDetails.landmark !== '') {
                        presentAdressObj['landmark'] = VendorContactDetails.landmark;
                    }
                    else {
                        react_hot_toast_1.default.error('Landmark in  Current address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.state !== null) {
                        presentAdressObj['state_id'] = VendorContactDetails.state.id;
                    }
                    else {
                        react_hot_toast_1.default.error('state in   Current address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.city !== null) {
                        presentAdressObj['city_id'] = VendorContactDetails.city.id;
                    }
                    else {
                        react_hot_toast_1.default.error('city  in   Current address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.country !== null) {
                        presentAdressObj['country_id'] = VendorContactDetails.country.id;
                    }
                    else {
                        react_hot_toast_1.default.error('Country in   Current address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.pincode !== '') {
                        presentAdressObj['pincode'] = parseInt(VendorContactDetails.pincode);
                    }
                    else {
                        react_hot_toast_1.default.error('pincode  in   Current address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (!(VendorContactDetails.proofOfAddress != '' &&
                        VendorContactDetails.proofOfAddress != null &&
                        VendorContactDetails.proofOfAddress != undefined)) return [3, 8];
                    if (!(typeof VendorContactDetails.proofOfAddress === 'object')) return [3, 7];
                    return [4, uploadFile(VendorContactDetails.proofOfAddress).then(function (res) {
                            if (res.data.statusCode === 200) {
                                presentAdressObj['proof_of_doc'] = res.data.data.name;
                            }
                            else {
                                presentAdressObj['proof_of_doc'] =
                                    VendorContactDetails.proofOfAddress;
                            }
                        })];
                case 6:
                    _a.sent();
                    return [3, 8];
                case 7:
                    presentAdressObj['proof_of_doc'] = VendorContactDetails.proofOfAddress;
                    _a.label = 8;
                case 8:
                    contactDetailsarr.push(presentAdressObj);
                    permanentAddressObj = {
                        type: 'Registered Address',
                    };
                    if (VendorContactDetails.permenentAddresslane1 !== '') {
                        permanentAddressObj['lane1'] = VendorContactDetails.permenentAddresslane1;
                    }
                    else {
                        react_hot_toast_1.default.error('Address Lane1 in  permanent address of Contact Details  section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.permenentAddresslane2 !== '') {
                        permanentAddressObj['lane2'] = VendorContactDetails.permenentAddresslane1;
                    }
                    if (VendorContactDetails.permenentLandmark !== '') {
                        permanentAddressObj['landmark'] = VendorContactDetails.permenentLandmark;
                    }
                    else {
                        react_hot_toast_1.default.error('Landmark in  permanent address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.permenentState !== null) {
                        permanentAddressObj['state_id'] = VendorContactDetails.permenentState.id;
                    }
                    else {
                        react_hot_toast_1.default.error('state in  permanent address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.permenentCity !== null) {
                        permanentAddressObj['city_id'] = VendorContactDetails.permenentCity.id;
                    }
                    else {
                        react_hot_toast_1.default.error('city  in  permanent address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.permenentCountry !== null) {
                        permanentAddressObj['country_id'] =
                            VendorContactDetails.permenentCountry.id;
                    }
                    else {
                        react_hot_toast_1.default.error('Country  in  permanent address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContactDetails.permenentPincode !== '') {
                        permanentAddressObj['pincode'] = parseInt(VendorContactDetails.permenentPincode);
                    }
                    else {
                        react_hot_toast_1.default.error('pincode in  permanent address of Contact Details section is  Invalid');
                        return [2, false];
                    }
                    if (!(VendorContactDetails.permenentProofOfAddress != '' &&
                        VendorContactDetails.permenentProofOfAddress != null &&
                        VendorContactDetails.permenentProofOfAddress != undefined)) return [3, 11];
                    if (!(typeof VendorContactDetails.permenentProofOfAddress === 'object')) return [3, 10];
                    return [4, uploadFile(VendorContactDetails.permenentProofOfAddress).then(function (res) {
                            if (res.data.statusCode === 200) {
                                permanentAddressObj['proof_of_doc'] = res.data.data.name;
                            }
                            else {
                                permanentAddressObj['proof_of_doc'] =
                                    VendorContactDetails.permenentProofOfAddress;
                            }
                        })];
                case 9:
                    _a.sent();
                    return [3, 11];
                case 10:
                    permanentAddressObj['proof_of_doc'] =
                        VendorContactDetails.permenentProofOfAddress;
                    _a.label = 11;
                case 11:
                    contactDetailsarr.push(permanentAddressObj);
                    payload['contact_details'] = contactDetailsarr;
                    contract_detials = {};
                    if (VendorContractDetails.contract_type !== undefined &&
                        VendorContractDetails.contract_type !== null) {
                        contract_detials['contract_type'] =
                            VendorContractDetails.contract_type.id;
                    }
                    else {
                        react_hot_toast_1.default.error('Contract type  in  Vendor Agreement Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContractDetails.contract_start_date !== '' &&
                        VendorContractDetails.contract_start_date !== null &&
                        VendorContractDetails.contract_start_date !== undefined) {
                        contract_detials['contract_start_date'] = moment(VendorContractDetails.contract_start_date).format('YYYY-MM-DD');
                    }
                    else {
                        react_hot_toast_1.default.error('Contract start date in  Vendor Agreement Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContractDetails.contractExpiry !== '' &&
                        VendorContractDetails.contractExpiry !== null &&
                        VendorContractDetails.contractExpiry !== undefined) {
                        contract_detials['contract_expiry_date'] = moment(VendorContractDetails.contractExpiry).format('YYYY-MM-DD');
                    }
                    else {
                        react_hot_toast_1.default.error('Contract expiry date in  Vendor Agreement Details section is  Invalid');
                        return [2, false];
                    }
                    if (VendorContractDetails.totalSourceFe != '') {
                        permanentAddressObj['total_sourcing_fe'] =
                            VendorContractDetails.totalSourceFe;
                    }
                    if (VendorContractDetails.parent_contract_id != '') {
                        permanentAddressObj['parent_contract_id'] =
                            VendorContractDetails.parent_contract_id;
                    }
                    if (location.pathname.includes("".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.editVendor))
                        ? editCityPayload && editCityPayload != null
                        : editCityPayload && editCityPayload != null
                            ? editCityPayload && editCityPayload != null
                            : createCityPayload && createCityPayload !== null) {
                        contract_detials['operating_places'] = location.pathname.includes("".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.editVendor))
                            ? (0, array_helpers_1.removeDuplicateObjectInCity)(editCityPayload)
                            : createCityPayload.length != 0
                                ? createCityPayload
                                : (0, array_helpers_1.removeDuplicateObjectInCity)(editCityPayload);
                    }
                    else {
                        react_hot_toast_1.default.error('Contract Opearating places in Vendor Agreement Details section is  Invalid');
                        return [2, false];
                    }
                    if (!(VendorContractDetails.contractAgreement !== '' &&
                        VendorContractDetails.contractAgreement !== null &&
                        VendorContractDetails.contractAgreement !== undefined)) return [3, 14];
                    if (!(typeof VendorContractDetails.contractAgreement === 'object')) return [3, 13];
                    return [4, uploadFile(VendorContractDetails.contractAgreement).then(function (res) {
                            if (res.data.statusCode === 200) {
                                contract_detials['contract_doc'] = res.data.data.name;
                            }
                            else {
                                contract_detials['contract_doc'] =
                                    VendorContractDetails.contractAgreement;
                            }
                        })];
                case 12:
                    _a.sent();
                    return [3, 14];
                case 13:
                    contract_detials['contract_doc'] =
                        VendorContractDetails.contractAgreement;
                    _a.label = 14;
                case 14:
                    payload['contract_details'] = contract_detials;
                    payload['is_final_submit'] = true;
                    payload['axapta_code'] = id.toString();
                    api_service_1.api_service
                        .post({
                        endPoint: api_urls_1.api_urls.VENDOR_CREATION,
                        payLoad: payload,
                        domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
                    })
                        .then(function (res) {
                        if (res.data.statusCode === 200) {
                            if (onBoardingStatus === 1) {
                                react_hot_toast_1.default.success('Details are updated successfully');
                                nav("/".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendormanagement, "/").concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.activeList));
                            }
                            else {
                                react_hot_toast_1.default.success('Successfully sent for approval');
                                nav("/".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendormanagement, "/").concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.vendor_pending_approval));
                            }
                        }
                        else {
                            react_hot_toast_1.default.error(res.data.message);
                        }
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.data.errors);
                    });
                    return [2];
            }
        });
    }); };
    React.useEffect(function () {
        if (onBoardingStatus !== 1 && onBoardingStatus !== null) {
            setEnableSendItForApproval(true);
        }
    }, [onBoardingStatus]);
    React.useEffect(function () {
        var tabValue = location.pathname.split('/')[4];
        setValue(parseInt(tabValue) ? parseInt(tabValue) : 0);
        if (activeTabId !== null && activeTabId !== undefined) {
            setValue(parseInt(activeTabId));
        }
    }, [value]);
    React.useEffect(function () {
        if (id) {
            dispatch((0, FE_Creation_action_1.checkIsNewVendor)(true));
            api_service_1.api_service
                .get({
                endPoint: "".concat(api_urls_1.api_urls.VENDOR_PROFILE_DETAILS, "?axapta_code=").concat(id),
                domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
                showLoader: false,
            })
                .then(function (res) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                if (res.data.statusCode === 200) {
                    var responseObj_1 = res.data.data;
                    setOnBoardingStatus(responseObj_1.onboard_status);
                    var vendor_personal_details = {
                        vendorName: (_a = responseObj_1.name) !== null && _a !== void 0 ? _a : '',
                        contactperson: (_b = responseObj_1.contact_name) !== null && _b !== void 0 ? _b : '',
                        designation: (_c = responseObj_1.contact_person_designation) !== null && _c !== void 0 ? _c : '',
                        entityType: responseObj_1.entity_type
                            ? (0, array_helpers_1.filterData)({
                                filterArray: entity_type,
                                id: responseObj_1.entity_type,
                            })
                            : null,
                        vendor_id: isNewVendor
                            ? responseObj_1.axapta_code
                            : responseObj_1.axapta_code && vendor_ids && vendor_ids.length > 0
                                ? (0, array_helpers_1.filterDataVendorIds)({
                                    filterArray: vendor_ids,
                                    id: responseObj_1.axapta_code,
                                })
                                : null,
                        email: (_d = responseObj_1.contact_email) !== null && _d !== void 0 ? _d : '',
                        uploadPhoto: (_e = responseObj_1.vendor_logo) !== null && _e !== void 0 ? _e : '',
                        mobilenumber: (_f = responseObj_1.contact_mobile) !== null && _f !== void 0 ? _f : '',
                        alternateNumber: (_g = responseObj_1.alternate_mobile) !== null && _g !== void 0 ? _g : '',
                        website: (_h = responseObj_1.contact_website) !== null && _h !== void 0 ? _h : '',
                        isActiveVendor: responseObj_1.onboard_status === 1,
                    };
                    setVendorProfileDetails(vendor_personal_details);
                    if (responseObj_1.contact_details.length > 0) {
                        var currentDetailsObj_1 = lodash_1.default.find(responseObj_1.contact_details, {
                            type: 'Office Address',
                        });
                        var permenentAddressDetails_1 = lodash_1.default.find(responseObj_1.contact_details, {
                            type: 'Registered Address',
                        });
                        var stateIds = [];
                        if (currentDetailsObj_1.state_id ===
                            permenentAddressDetails_1.state_id &&
                            currentDetailsObj_1.state_id !== null) {
                            stateIds.push(currentDetailsObj_1.state_id);
                        }
                        else {
                            if (currentDetailsObj_1.state_id != null) {
                                stateIds.push(currentDetailsObj_1.state_id);
                            }
                            else {
                                dispatch((0, config_action_1.fetch_cities)([]));
                            }
                            if (permenentAddressDetails_1.state_id != null) {
                                stateIds.push(permenentAddressDetails_1.state_id);
                            }
                            else {
                                dispatch((0, config_action_1.fetch_permanentCities)([]));
                            }
                        }
                        var contactDetails_1 = {
                            addresslane1: currentDetailsObj_1.lane1 !== null
                                ? currentDetailsObj_1.lane1
                                : '',
                            addresslane2: currentDetailsObj_1.lane2 !== null
                                ? currentDetailsObj_1.lane2
                                : '',
                            landmark: currentDetailsObj_1.landmark !== null
                                ? currentDetailsObj_1.landmark
                                : '',
                            state: null,
                            city: null,
                            country: null,
                            pincode: currentDetailsObj_1.pincode !== null
                                ? currentDetailsObj_1.pincode.toString()
                                : '',
                            proofOfAddress: currentDetailsObj_1.proof_of_doc != null &&
                                currentDetailsObj_1.proof_of_doc != undefined &&
                                currentDetailsObj_1.proof_of_doc != ''
                                ? currentDetailsObj_1.proof_of_doc
                                : '',
                            permenentAddresslane1: permenentAddressDetails_1.lane1 !== null
                                ? permenentAddressDetails_1.lane1
                                : '',
                            permenentAddresslane2: permenentAddressDetails_1.lane2 !== null
                                ? permenentAddressDetails_1.lane2
                                : '',
                            permenentLandmark: permenentAddressDetails_1.landmark !== null
                                ? permenentAddressDetails_1.landmark
                                : '',
                            permenentState: null,
                            permenentCity: null,
                            permenentCountry: null,
                            permenentPincode: permenentAddressDetails_1.pincode !== null
                                ? permenentAddressDetails_1.pincode.toString()
                                : '',
                            permenentProofOfAddress: permenentAddressDetails_1.proof_of_doc != null &&
                                permenentAddressDetails_1.proof_of_doc != undefined &&
                                permenentAddressDetails_1.proof_of_doc != ''
                                ? permenentAddressDetails_1.proof_of_doc
                                : '',
                            isActiveVendor: responseObj_1.onboard_status === 1 ? true : false,
                        };
                        if (stateIds.length > 0) {
                            (0, generic_api_calls_1.fetchCitiesUnderStates)(stateIds)
                                .then(function (cityListFromStateId) {
                                dispatch((0, config_action_1.fetch_cities)(cityListFromStateId.filter(function (city) { return city.state_id === currentDetailsObj_1.state_id; })));
                                dispatch((0, config_action_1.fetch_permanentCities)(cityListFromStateId.filter(function (city) {
                                    return city.state_id === permenentAddressDetails_1.state_id;
                                })));
                                (contactDetails_1['state'] =
                                    currentDetailsObj_1.state_id !== null
                                        ? (0, array_helpers_1.filterData)({
                                            filterArray: stateList,
                                            id: currentDetailsObj_1.state_id,
                                            fieldname: ' state ',
                                        })
                                        : null),
                                    (contactDetails_1['city'] =
                                        currentDetailsObj_1.city_id !== null
                                            ? (0, array_helpers_1.filterData)({
                                                filterArray: cityListFromStateId,
                                                id: currentDetailsObj_1.city_id,
                                            })
                                            : null),
                                    (contactDetails_1['country'] =
                                        currentDetailsObj_1.country_id !== null
                                            ? (0, array_helpers_1.filterData)({
                                                filterArray: countryList,
                                                id: currentDetailsObj_1.country_id,
                                            })
                                            : null),
                                    (contactDetails_1['permenentState'] =
                                        permenentAddressDetails_1.state_id !== null
                                            ? (0, array_helpers_1.filterData)({
                                                filterArray: stateList,
                                                id: permenentAddressDetails_1.state_id,
                                            })
                                            : null),
                                    (contactDetails_1['permenentCity'] =
                                        permenentAddressDetails_1.city_id !== null
                                            ? (0, array_helpers_1.filterData)({
                                                filterArray: cityListFromStateId,
                                                id: permenentAddressDetails_1.city_id,
                                            })
                                            : null),
                                    (contactDetails_1['permenentCountry'] =
                                        permenentAddressDetails_1.country_id !== null
                                            ? (0, array_helpers_1.filterData)({
                                                filterArray: countryList,
                                                id: permenentAddressDetails_1.country_id,
                                            })
                                            : null);
                                setVendorContactDetails(contactDetails_1);
                            })
                                .catch(function (err) {
                                console.log('Unable to process your request');
                            });
                        }
                        else {
                            setVendorContactDetails(contactDetails_1);
                        }
                    }
                    var contract_stateIds_1 = [];
                    var operatingStates = [];
                    var operatingCities_1 = [];
                    if (responseObj_1.operating_places.length > 0) {
                        operatingStates = lodash_1.default.uniqBy(responseObj_1.operating_places, 'state_id');
                        operatingCities_1 = lodash_1.default.uniqBy(responseObj_1.operating_places, 'city_id');
                        console.log(operatingStates, 'operatingStates');
                        contract_stateIds_1 = operatingStates.map(function (o) { return o.state_id; });
                    }
                    else {
                        dispatch((0, config_action_1.fetch_cities)([]));
                    }
                    var vendor_contract_details1_1 = {
                        contractAgreement: (_j = responseObj_1.contract_doc) !== null && _j !== void 0 ? _j : '',
                        contract_start_date: (_k = responseObj_1.contract_start_date) !== null && _k !== void 0 ? _k : '',
                        contractExpiry: (_l = responseObj_1.contract_expiry_date) !== null && _l !== void 0 ? _l : '',
                        totalSourceFe: (_m = responseObj_1.total_sourcing_fe) !== null && _m !== void 0 ? _m : '',
                        parent_contract_id: (_o = responseObj_1.parent_contract_id) !== null && _o !== void 0 ? _o : '',
                        contract_type: responseObj_1.contract_type
                            ? (0, array_helpers_1.filterData)({
                                filterArray: vendor_contract_types,
                                id: responseObj_1.contract_type,
                            })
                            : null,
                        operatingState: null,
                        operatingcity: null,
                        isActiveVendor: responseObj_1.onboard_status === 1 ? true : false,
                    };
                    if (contract_stateIds_1.length > 0) {
                        var stateData_1 = (0, array_helpers_1.convertOperatingLabelState)(operatingStates);
                        (0, generic_api_calls_1.fetchCitiesUnderStates)(contract_stateIds_1)
                            .then(function (cityListFromStateId) {
                            var _a, _b, _c, _d, _e;
                            contract_stateIds_1.map(function (id) {
                                dispatch((0, config_action_1.fetch_cities)(cityListFromStateId.filter(function (city) { return city.state_id === id; })));
                            });
                            var selectedCities = lodash_1.default.filter(operatingCities_1, function (o) { return o.city_id; });
                            var vendor_contract_details = {
                                contractAgreement: (_a = responseObj_1.contract_doc) !== null && _a !== void 0 ? _a : '',
                                contract_start_date: (_b = responseObj_1.contract_start_date) !== null && _b !== void 0 ? _b : '',
                                contractExpiry: (_c = responseObj_1.contract_expiry_date) !== null && _c !== void 0 ? _c : '',
                                totalSourceFe: (_d = responseObj_1.total_sourcing_fe) !== null && _d !== void 0 ? _d : '',
                                parent_contract_id: (_e = responseObj_1.parent_contract_id) !== null && _e !== void 0 ? _e : '',
                                contract_type: responseObj_1.contract_type
                                    ? (0, array_helpers_1.filterData)({
                                        filterArray: vendor_contract_types,
                                        id: responseObj_1.contract_type,
                                    })
                                    : null,
                                operatingState: stateData_1,
                                operatingcity: (0, array_helpers_1.convertOperatingLabelCity)(operatingCities_1),
                                isActiveVendor: responseObj_1.onboard_status === 1 ? true : false,
                            };
                            setVendorContractDetails(vendor_contract_details);
                            setSelectedStates(stateData_1);
                            setSelectedCity((0, array_helpers_1.convertOperatingLabelCity)(selectedCities));
                        })
                            .catch(function (err) {
                            setVendorContractDetails(vendor_contract_details1_1);
                        });
                    }
                    else {
                        setVendorContractDetails(vendor_contract_details1_1);
                    }
                }
                else {
                    var erroMessage = res.data
                        ? res.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                }
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err.message);
            });
        }
    }, [id, venodrIdEmpty, activeTabId]);
    return (React.createElement("div", { className: "add-user-wrapper on-boarding-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: clickHandler }),
                React.createElement("h2", null, location.pathname.includes(route_path_1.ROUTE_CONSTAMNTS.PATHS.editVendor)
                    ? 'Edit Vendor'
                    : 'Add New Vendor'))),
        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: { xs: 1 } },
            React.createElement(Grid_1.default, { item: true, xs: 4, lg: 3 },
                React.createElement("div", { className: "vertical-tabs-wrapper tabs-aligner" },
                    React.createElement(Tabs_1.default, { orientation: "vertical", variant: "scrollable", value: value, onChange: handleChange, "aria-label": "Vertical tabs example" },
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "Vendor Personal Details" }, a11yProps(0), { className: value != 0 && checkVendorPersonalDetailsValidation()
                                ? 'tab-btn submitted'
                                : 'tab-btn ', "aria-label": "Vertical tabs example", value: 0 })),
                        value != 0 && checkVendorPersonalDetailsValidation() && (React.createElement("span", { className: "form-status-icon icon-double-tick  tab-one-status" }, ' ')),
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "Vendor Contact Details" }, a11yProps(1), { className: value != 1 && checkVendorContactDetailsValidation()
                                ? 'tab-btn submitted'
                                : 'tab-btn ', "aria-label": "Vertical tabs example", value: 1 })),
                        value != 1 && checkVendorContactDetailsValidation() && (React.createElement("span", { className: "form-status-icon icon-double-tick  tab-two-status" })),
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "Vendor Agreement Details" }, a11yProps(2), { className: value != 2 && checkVendorContract_DetailsValidation()
                                ? 'tab-btn submitted'
                                : 'tab-btn ', value: 2 })),
                        value != 2 && checkVendorContract_DetailsValidation() && (React.createElement("span", { className: "form-status-icon icon-double-tick  tab-three-status" }))))),
            React.createElement(Grid_1.default, { item: true, xs: 8, lg: 9 },
                React.createElement("div", { className: "scrollable-content form-wrapper" },
                    React.createElement(TabPanel, { value: value, index: 0 },
                        React.createElement(vendor_personal_details_1.default, { initialValues: VendorProfileDetails, saveFormData: saveVendor_ProfileDetails, updateValue: updateValue, onBoardingStatus: onBoardingStatus, value: value, User_ProfileInitialState: Vendor_Personal_DetailsInitialState, vendor_ids_list: vendorIdList, sendItForApproval: sendItForApproval })),
                    React.createElement(TabPanel, { value: value, index: 1 },
                        React.createElement(vendor_contact_details_1.default, { initialValues: VendorContactDetails, saveFormData: saveVendor_ContactDetails, backButtonhandler: backButtonHandler, updateValue: updateValue, value: value, onBoardingStatus: onBoardingStatus, vedore_personal_Details: VendorProfileDetails, USER_ContactDetailsInitialState: Vendor_ContactDetailsInitialState, sendItForApproval: sendItForApproval })),
                    React.createElement(TabPanel, { value: value, index: 2 },
                        React.createElement(contract_details_1.default, { initialValues: VendorContractDetails, saveFormData: saveVendor_Contract_Details, backButtonhandler: backButtonHandler, updateValue: updateValue, vedore_personal_Details: VendorProfileDetails, value: value, onBoardingStatus: onBoardingStatus, USER_ContactDetailsInitialState: Vendor_Contract_Details_InitialState, sendItForApproval: sendItForApproval, enableSendItForApproval: enableSendItForApproval, selectedStateList: selectedStates, selectedCityList: selectedCity, editCityPayloadData: setEditCityPaylioad, createCityPayload: setCreateCityPaylioad })))))));
};
exports.default = CreateVedor;
