"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertIntracityFEOntimePUPList = exports.Individual_fe_samplesFor_onTImePickup = exports.convertIntracityFETatList = exports.INTRACITU_FE_ONTIME_PUPCKUPS_TABLE_COLUMNS = exports.INTRACITU_FE_LAT_TABLE_COLUMNS = exports.Individual_fe_samples = exports.displayTableDataForOutStaionBox = exports.filterOutStationPendingMasterBoxResponse = exports.filterOutStationMasterBoxResponse = exports.filterDataBasedOnTimeOutStationBox = exports.convertOustationDataPending = exports.convertOustationDataRecieved = exports.dateTimeFormatterOutstationDownload = exports.downloadExcelHeadersOustStationPendingHeader = exports.downloadExcelHeadersOustStationHeaderReceived = exports.downloadExcelHeadersForR_lab_Plab = exports.convertRlabPlabData = exports.displayTableDataForR_labP_lab = exports.filterSeriesDataForR_lab_P_lab = exports.filterDataBasedOnTimeForRlabPlab = exports.filterRLabPLabResponse = exports.filterCumulativeForLabTrend = exports.R_LAB_P_LAB__HEADERS = exports.sampleTextColor = exports.consvertIntracityReportData = exports.downloadExcelHeaders = exports.filtertypeOfSampleData = exports.getTimeDifferenceData = exports.filterDelayedSampleData = exports.intracityFEOntimePupPerce = exports.displayTableDataBasedOnselectedGraph = exports.filterSeriesDataBasedOnSelectedEvent = exports.prepareIntracityFETableList = exports.convertHoursTOMillSecAndMillSecToHours = exports.filterCumulativeData = exports.filterDataBasedOnTime = exports.filterLab_handover_to_lab_receiving = exports.filterPickupt_to_lab_reveive = exports.filterPickupt_to_lab_handover = exports.filterRegistration_to_lab_receiving = exports.filterRegistration_to_lab_handover = exports.filterRegistration_to_Pickup = exports.sampleResponseForOutStationBox = exports.r_lab_p_lab_sampleRes = exports.sampleResponse = exports.x_axis_valuesForOutStation = exports.x_axis_values_for_Rlab_p_lab = exports.x_axis_values = exports.SAMPLE_TYPES = exports.sampleData = void 0;
exports.outstationMasterBoxCumulative = exports.LAB_HANDOVER_X_AXIS_VALUES = void 0;
var moment = require("moment");
var array_helpers_1 = require("../../../utils/array_helpers");
var lodash_1 = require("../../../utils/lodash");
var shipment_constants_1 = require("../constants/shipment_constants");
exports.sampleData = [
    '0:10',
    '0:18',
    '0:23',
    '0:45',
    '0:55',
    '0:35',
    '0:15',
    '0:25',
    '0:20',
    '1:05',
    '1:00',
    '1:30',
    '1:45',
    '1:55',
    '2:00',
    '2:21',
    '2:22',
    '2:23',
    '2:24',
    '2:25',
    '2:26',
    '2:27',
    '2:28',
    '2:29',
    '2:30',
    '2:45',
    '2:31',
    '3:00',
    '2:51',
    '2:15',
];
exports.SAMPLE_TYPES = {
    on_time: 'on_time',
    delayed: 'delayed',
    early: 'early',
};
exports.x_axis_values = [
    '<30 min',
    '60-min',
    '90-min',
    '120-min',
    '150-min',
    '> 180-min',
];
exports.x_axis_values_for_Rlab_p_lab = [
    '<60 min',
    '120-min',
    '180-min',
    '>180-min',
];
exports.x_axis_valuesForOutStation = [
    '<6 Hr',
    '6-12 Hr',
    '12-18 Hr',
    '18-24 Hr',
    '>24 Hr',
];
exports.sampleResponse = [
    {
        _id: '646b331919c367d5b4a7264c',
        bag_id: '646b331919c367d5b4a72637',
        lab_number: '390329238',
        reference_number: '390329238-118236-2',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        registration_source: '1xView',
        type: 1,
        precious_sample: false,
        registration_type: 1,
        registration_status_at_scan: 1,
        registration_timestamp: '2023-05-22 14:07',
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:47',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335698',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:49:12',
        time_slot: '04:00 - 05:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:15',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b331919c367d5b4a72645',
        bag_id: '646b331919c367d5b4a72637',
        lab_number: '390329235',
        reference_number: '390329235-118236-1',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        registration_source: '1xView',
        type: 1,
        precious_sample: false,
        registration_type: 1,
        registration_status_at_scan: 1,
        registration_timestamp: '2023-05-22 13:57',
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:47',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335698',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:39:12',
        time_slot: '04:00 - 05:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:19',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32f519c367d5b4a724a8',
        bag_id: '646b32f519c367d5b4a724a3',
        lab_number: '389975779',
        reference_number: '389975779-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP18218',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:46',
        bag_created_by: 'PARMANAND KUMAR (CLL651602)',
        bag_barcode: 'LP230401763071',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:36',
        invoice_code: 'C004300541',
        center_type: 'Pickup Point',
        center_name: 'SHROFF ENT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [325101, 325102],
        pickup_time_taken: '3.70',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:47:24',
        time_slot: '03:00 - 04:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PARMANAND KUMAR',
        pickup_fe_code: 'CLL651602',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:43',
        fe_reached_date_time: '2023-05-22 14:43:42',
        reached_latitude: '22.0760903',
        reached_longitude: '82.1320649',
        actual_lab_handover_time: '2023-05-22 15:40',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32df19c367d5b4a71f29',
        bag_id: '646b32df19c367d5b4a71f15',
        lab_number: '389975780',
        reference_number: '389975780-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP18218',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:46',
        bag_created_by: 'PARMANAND KUMAR (CLL651602)',
        bag_barcode: 'LP230401763072',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:36',
        invoice_code: 'C004300541',
        center_type: 'Pickup Point',
        center_name: 'SHROFF ENT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [325101, 325102],
        pickup_time_taken: '3.70',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:47:24',
        time_slot: '04:00 - 05:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PARMANAND KUMAR',
        pickup_fe_code: 'CLL651602',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:43',
        fe_reached_date_time: '2023-05-22 14:43:42',
        reached_latitude: '22.0760903',
        reached_longitude: '82.1320649',
        actual_lab_handover_time: '2023-05-22 14:25',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32df19c367d5b4a71f24',
        bag_id: '646b32df19c367d5b4a71f15',
        lab_number: '389975777',
        reference_number: '389975777-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP18218',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:46',
        bag_created_by: 'PARMANAND KUMAR (CLL651602)',
        bag_barcode: 'LP230401763072',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:36',
        invoice_code: 'C004300541',
        center_type: 'Pickup Point',
        center_name: 'SHROFF ENT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [325101, 325102],
        pickup_time_taken: '3.70',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:47:24',
        time_slot: '05:00 - 06:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PARMANAND KUMAR',
        pickup_fe_code: 'CLL651602',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:43',
        fe_reached_date_time: '2023-05-22 14:43:42',
        reached_latitude: '22.0760903',
        reached_longitude: '82.1320649',
        actual_lab_handover_time: '2023-05-22 14:25',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32df19c367d5b4a71f1f',
        bag_id: '646b32df19c367d5b4a71f15',
        lab_number: '389975781',
        reference_number: '389975781-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP18218',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:46',
        bag_created_by: 'PARMANAND KUMAR (CLL651602)',
        bag_barcode: 'LP230401763072',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:36',
        invoice_code: 'C004300541',
        center_type: 'Pickup Point',
        center_name: 'SHROFF ENT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [325101, 325102],
        pickup_time_taken: '3.70',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:47:24',
        time_slot: '06:00 - 07:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PARMANAND KUMAR',
        pickup_fe_code: 'CLL651602',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:43',
        fe_reached_date_time: '2023-05-22 14:43:42',
        reached_latitude: '22.0760903',
        reached_longitude: '82.1320649',
        actual_lab_handover_time: '2023-05-22 15:01',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32df19c367d5b4a71f1a',
        bag_id: '646b32df19c367d5b4a71f15',
        lab_number: '389975778',
        reference_number: '389975778-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP18218',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:46',
        bag_created_by: 'PARMANAND KUMAR (CLL651602)',
        bag_barcode: 'LP230401763072',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:36',
        invoice_code: 'C004300541',
        center_type: 'Pickup Point',
        center_name: 'SHROFF ENT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [325101, 325102],
        pickup_time_taken: '3.70',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:47:24',
        time_slot: '07:00 - 08:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PARMANAND KUMAR',
        pickup_fe_code: 'CLL651602',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:43',
        fe_reached_date_time: '2023-05-22 14:43:42',
        reached_latitude: '22.0760903',
        reached_longitude: '82.1320649',
        actual_lab_handover_time: '2023-05-22 15:01',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32a019c367d5b4a71bd4',
        bag_id: '646b32a019c367d5b4a71bb6',
        lab_number: '390329240',
        reference_number: '390329240-118236-1',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        registration_source: '1xView',
        type: 1,
        precious_sample: false,
        registration_type: 1,
        registration_status_at_scan: 1,
        registration_timestamp: '2023-05-22 14:23',
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:45',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335699',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:49:12',
        time_slot: '08:00 - 09:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:51',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32a019c367d5b4a71bcf',
        bag_id: '646b32a019c367d5b4a71bb6',
        lab_number: '390329239',
        reference_number: '390329239-118236-2',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        registration_source: '1xView',
        type: 1,
        precious_sample: false,
        registration_type: 1,
        registration_status_at_scan: 1,
        registration_timestamp: '2023-05-22 14:19',
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:45',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335699',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:49:12',
        time_slot: '09:00 - 10:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:51',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32a019c367d5b4a71bca',
        bag_id: '646b32a019c367d5b4a71bb6',
        lab_number: '390329237',
        reference_number: '390329237-118236-2',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        registration_source: '1xView',
        type: 1,
        precious_sample: false,
        registration_type: 1,
        registration_status_at_scan: 1,
        registration_timestamp: '2023-05-22 14:16',
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:45',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335699',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:49:12',
        time_slot: '10:00 - 11:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:51',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32a019c367d5b4a71bc5',
        bag_id: '646b32a019c367d5b4a71bb6',
        lab_number: '390329239',
        reference_number: '390329239-118236-1',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        registration_source: '1xView',
        type: 1,
        precious_sample: false,
        registration_type: 1,
        registration_status_at_scan: 1,
        registration_timestamp: '2023-05-22 14:19',
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:45',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335699',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:49:12',
        time_slot: '11:00 - 12:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:51',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32a019c367d5b4a71bc0',
        bag_id: '646b32a019c367d5b4a71bb6',
        lab_number: '390329237',
        reference_number: '390329237-118236-1',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        registration_source: '1xView',
        type: 1,
        precious_sample: false,
        registration_type: 1,
        registration_status_at_scan: 1,
        registration_timestamp: '2023-05-22 14:16',
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:45',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335699',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:49:12',
        time_slot: '12:00 - 13:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:51',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b32a019c367d5b4a71bbb',
        bag_id: '646b32a019c367d5b4a71bb6',
        lab_number: '390329238',
        reference_number: '390329238-118236-1',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        registration_source: '1xView',
        type: 1,
        precious_sample: false,
        registration_type: 1,
        registration_status_at_scan: 1,
        registration_timestamp: '2023-05-22 14:07',
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:45',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335699',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:49:12',
        time_slot: '13:00 - 14:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:51',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b327c19c367d5b4a71a17',
        bag_id: '646b327c19c367d5b4a71a03',
        lab_number: '389975776',
        reference_number: '389975776-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP18218',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:44',
        bag_created_by: 'PARMANAND KUMAR (CLL651602)',
        bag_barcode: 'LP230401763069',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:36',
        invoice_code: 'C004300541',
        center_type: 'Pickup Point',
        center_name: 'SHROFF ENT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [325101, 325102],
        pickup_time_taken: '3.70',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:47:24',
        time_slot: '14:00 - 15:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PARMANAND KUMAR',
        pickup_fe_code: 'CLL651602',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:43',
        fe_reached_date_time: '2023-05-22 14:43:42',
        reached_latitude: '22.0760903',
        reached_longitude: '82.1320649',
        actual_lab_handover_time: '2023-05-22 15:01',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b327c19c367d5b4a71a12',
        bag_id: '646b327c19c367d5b4a71a03',
        lab_number: '389975775',
        reference_number: '389975775-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP18218',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:44',
        bag_created_by: 'PARMANAND KUMAR (CLL651602)',
        bag_barcode: 'LP230401763069',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:36',
        invoice_code: 'C004300541',
        center_type: 'Pickup Point',
        center_name: 'SHROFF ENT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [325101, 325102],
        pickup_time_taken: '3.70',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:47:24',
        time_slot: '15:00 - 16:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PARMANAND KUMAR',
        pickup_fe_code: 'CLL651602',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:43',
        fe_reached_date_time: '2023-05-22 14:43:42',
        reached_latitude: '22.0760903',
        reached_longitude: '82.1320649',
        actual_lab_handover_time: '2023-05-22 15:01',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b327c19c367d5b4a71a0d',
        bag_id: '646b327c19c367d5b4a71a03',
        lab_number: '389975774',
        reference_number: '389975774-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP18218',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:44',
        bag_created_by: 'PARMANAND KUMAR (CLL651602)',
        bag_barcode: 'LP230401763069',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:36',
        invoice_code: 'C004300541',
        center_type: 'Pickup Point',
        center_name: 'SHROFF ENT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [325101, 325102],
        pickup_time_taken: '3.70',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:47:24',
        time_slot: '16:00 - 17:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PARMANAND KUMAR',
        pickup_fe_code: 'CLL651602',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:43',
        fe_reached_date_time: '2023-05-22 14:43:42',
        reached_latitude: '22.0760903',
        reached_longitude: '82.1320649',
        actual_lab_handover_time: '2023-05-22 15:01',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b327c19c367d5b4a71a08',
        bag_id: '646b327c19c367d5b4a71a03',
        lab_number: '389975773',
        reference_number: '389975773-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP18218',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:44',
        bag_created_by: 'PARMANAND KUMAR (CLL651602)',
        bag_barcode: 'LP230401763069',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:36',
        invoice_code: 'C004300541',
        center_type: 'Pickup Point',
        center_name: 'SHROFF ENT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [325101, 325102],
        pickup_time_taken: '3.70',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:47:24',
        time_slot: '17:00 - 18:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PARMANAND KUMAR',
        pickup_fe_code: 'CLL651602',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:43',
        fe_reached_date_time: '2023-05-22 14:43:42',
        reached_latitude: '22.0760903',
        reached_longitude: '82.1320649',
        actual_lab_handover_time: '2023-05-22 15:01',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b31df19c367d5b4a712dc',
        bag_id: '646b31df19c367d5b4a712cd',
        lab_number: '440978146',
        reference_number: '440978146-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP9182',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:41',
        bag_created_by: 'RAJESH SAH (LRA011267)',
        bag_barcode: 'LP221202011485',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:19',
        invoice_code: 'C004284239',
        center_type: 'Pickup Point',
        center_name: 'MEDICOS DIAGNOSTIC',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Bihar',
        pickup_fe_city: 'Darbhanga',
        pickup_fe_lab_name: 'LPL DARBHANGA',
        pickup_fe_lab_code: 'A01',
        visit_ids: [328084, 328085],
        pickup_time_taken: '2.13',
        scheduled_pick_up_time: '2023-05-22 14:10',
        actual_pick_up_time: '2023-05-22 14:43:09',
        time_slot: '18:00 - 19:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'RAJESH SAH',
        pickup_fe_code: 'LRA011267',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:41:01',
        reached_latitude: '26.1310239',
        reached_longitude: '85.9015068',
        actual_lab_handover_time: '2023-05-22 14:49',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b31df19c367d5b4a712d7',
        bag_id: '646b31df19c367d5b4a712cd',
        lab_number: '440978145',
        reference_number: '440978145-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP9182',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:41',
        bag_created_by: 'RAJESH SAH (LRA011267)',
        bag_barcode: 'LP221202011485',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:19',
        invoice_code: 'C004284239',
        center_type: 'Pickup Point',
        center_name: 'MEDICOS DIAGNOSTIC',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Bihar',
        pickup_fe_city: 'Darbhanga',
        pickup_fe_lab_name: 'LPL DARBHANGA',
        pickup_fe_lab_code: 'A01',
        visit_ids: [328084, 328085],
        pickup_time_taken: '2.13',
        scheduled_pick_up_time: '2023-05-22 14:10',
        actual_pick_up_time: '2023-05-22 14:43:09',
        time_slot: '19:00 - 20:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'RAJESH SAH',
        pickup_fe_code: 'LRA011267',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:41:01',
        reached_latitude: '26.1310239',
        reached_longitude: '85.9015068',
        actual_lab_handover_time: '2023-05-22 14:49',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b31df19c367d5b4a712d2',
        bag_id: '646b31df19c367d5b4a712cd',
        lab_number: '440978144',
        reference_number: '440978144-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP9182',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:41',
        bag_created_by: 'RAJESH SAH (LRA011267)',
        bag_barcode: 'LP221202011485',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:19',
        invoice_code: 'C004284239',
        center_type: 'Pickup Point',
        center_name: 'MEDICOS DIAGNOSTIC',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Bihar',
        pickup_fe_city: 'Darbhanga',
        pickup_fe_lab_name: 'LPL DARBHANGA',
        pickup_fe_lab_code: 'A01',
        visit_ids: [328084, 328085],
        pickup_time_taken: '2.13',
        scheduled_pick_up_time: '2023-05-22 14:10',
        actual_pick_up_time: '2023-05-22 14:43:09',
        time_slot: '20:00 - 21:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'RAJESH SAH',
        pickup_fe_code: 'LRA011267',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:41:01',
        reached_latitude: '26.1310239',
        reached_longitude: '85.9015068',
        actual_lab_handover_time: '2023-05-22 14:49',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b314519c367d5b4a70a97',
        bag_id: '646b314519c367d5b4a70a8d',
        lab_number: '433303483',
        reference_number: '433303483-104088-1',
        tube_colour_code: '104088',
        tube_color: 'GREY TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'PP7221',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:39',
        bag_created_by: 'AKSHAY SALVI (SDS961316)',
        bag_barcode: 'LP230401742102',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        invoice_code: 'C004260827',
        center_type: 'Pickup Point',
        center_name: 'MANIPAL HOSPITAL (GOA)',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'STATIC',
        pickup_fe_state: 'Goa',
        pickup_fe_city: 'Goa Velha',
        pickup_fe_lab_name: 'Goa lab ',
        pickup_fe_lab_code: 'S96',
        visit_ids: [327717, 298035],
        actual_lab_handover_time: '2023-05-22 14:42',
        is_lab_handover_delay: 0,
        pickup_time_taken: '1.50',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:40:13',
        time_slot: '21:00 - 22:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'AKSHAY SALVI',
        pickup_fe_code: 'SDS961316',
        pickup_fe_zone: 'WEST',
        visit_type: 'STATIC',
        fe_started_date_time: '2023-05-22 14:38',
        fe_reached_date_time: '2023-05-22 14:38:43',
        reached_latitude: '15.4888027',
        reached_longitude: '73.8207698',
    },
    {
        _id: '646b314519c367d5b4a70a92',
        bag_id: '646b314519c367d5b4a70a8d',
        lab_number: '433303484',
        reference_number: '433303484-104088-1',
        tube_colour_code: '104088',
        tube_color: 'GREY TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'PP7221',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:39',
        bag_created_by: 'AKSHAY SALVI (SDS961316)',
        bag_barcode: 'LP230401742102',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        invoice_code: 'C004260827',
        center_type: 'Pickup Point',
        center_name: 'MANIPAL HOSPITAL (GOA)',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'STATIC',
        pickup_fe_state: 'Goa',
        pickup_fe_city: 'Goa Velha',
        pickup_fe_lab_name: 'Goa lab ',
        pickup_fe_lab_code: 'S96',
        visit_ids: [327717, 298035],
        actual_lab_handover_time: '2023-05-22 14:42',
        is_lab_handover_delay: 0,
        pickup_time_taken: '1.50',
        scheduled_pick_up_time: '2023-05-22 14:25',
        actual_pick_up_time: '2023-05-22 14:40:13',
        time_slot: '21:00 - 22:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'AKSHAY SALVI',
        pickup_fe_code: 'SDS961316',
        pickup_fe_zone: 'WEST',
        visit_type: 'STATIC',
        fe_started_date_time: '2023-05-22 14:38',
        fe_reached_date_time: '2023-05-22 14:38:43',
        reached_latitude: '15.4888027',
        reached_longitude: '73.8207698',
    },
    {
        _id: '646b30c419c367d5b4a6f676',
        bag_id: '646b30c419c367d5b4a6f671',
        lab_number: '323157771',
        reference_number: '323157771-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'CC0863',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:37',
        bag_created_by: 'AKSHAY SALVI (SDS961316)',
        bag_barcode: 'LP230500137584',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        invoice_code: 'C001205991',
        center_type: 'Collection Center',
        center_name: 'DR. PRASAD NETRAVALKAR-(GOA CC)',
        center_state: 'GOA',
        center_city: 'Calapor',
        network_type: 'FE TOWN',
        time_slot: '21:00 - 22:00',
        route_type: 'STATIC',
        pickup_fe_state: 'Goa',
        pickup_fe_city: 'Goa Velha',
        pickup_fe_lab_name: 'Goa lab ',
        pickup_fe_lab_code: 'S96',
        visit_ids: [327716, 298035],
        actual_lab_handover_time: '2023-05-22 14:42',
        is_lab_handover_delay: 0,
        pickup_time_taken: '39.85',
        scheduled_pick_up_time: '2023-05-22 14:14',
        actual_pick_up_time: '2023-05-22 14:38:13',
        is_pickup_delay: 0,
        pickup_fe_name: 'AKSHAY SALVI',
        pickup_fe_code: 'SDS961316',
        pickup_fe_zone: 'WEST',
        visit_type: 'STATIC',
        fe_started_date_time: '2023-05-22 13:58',
        fe_reached_date_time: '2023-05-22 13:58:22',
        reached_latitude: '15.4975705',
        reached_longitude: '73.8240783',
    },
    {
        _id: '646b309919c367d5b4a6e9e8',
        bag_id: '646b309919c367d5b4a6e9e3',
        lab_number: '390540253',
        reference_number: '390540253-104258-1',
        tube_colour_code: '104258',
        tube_color: 'RED TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        time_slot: '21:00 - 22:00',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP10409',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:36',
        bag_created_by: 'TIKAM DHIWAR (CLL651601)',
        bag_barcode: 'LP230100012807',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 15:04',
        invoice_code: 'C004286892',
        center_type: 'Pickup Point',
        center_name: 'SVL LABORATORY',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [329298, 329299],
        pickup_time_taken: '3.90',
        scheduled_pick_up_time: '2023-05-22 14:53',
        actual_pick_up_time: '2023-05-22 14:40:03',
        is_pickup_delay: 0,
        pickup_fe_name: 'TIKAM DHIWAR',
        pickup_fe_code: 'CLL651601',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:36',
        fe_reached_date_time: '2023-05-22 14:36:09',
        reached_latitude: '22.09718',
        reached_longitude: '82.1643063',
        actual_lab_handover_time: '2023-05-22 14:59',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b302719c367d5b4a6dc64',
        bag_id: '646b302719c367d5b4a6dc4f',
        lab_number: '386709262',
        reference_number: '386709262-118236-1',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP17370',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:34',
        bag_created_by: 'ANIKET CHAWALE (SDS621935)',
        bag_barcode: 'LP220700807817',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 16:28',
        invoice_code: 'C004299074',
        center_type: 'Pickup Point',
        center_name: 'HLL LIFECARE LIMITED',
        center_state: 'Maharashtra',
        center_city: 'Nagpur',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330915, 330916],
        pickup_time_taken: '3.55',
        scheduled_pick_up_time: '2023-05-22 16:01',
        actual_pick_up_time: '2023-05-22 14:36:36',
        is_pickup_delay: 0,
        pickup_fe_name: 'ANIKET CHAWALE',
        pickup_fe_code: 'SDS621935',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:17',
        fe_reached_date_time: '2023-05-22 14:33:03',
        reached_latitude: '21.037042',
        reached_longitude: '79.0268514',
        actual_lab_handover_time: '2023-05-22 15:04',
        is_lab_handover_delay: 0,
        time_slot: '21:00 - 22:00',
    },
    {
        _id: '646b302719c367d5b4a6dc5f',
        bag_id: '646b302719c367d5b4a6dc4f',
        lab_number: '386709261',
        reference_number: '386709261-118236-1',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP17370',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:34',
        bag_created_by: 'ANIKET CHAWALE (SDS621935)',
        bag_barcode: 'LP220700807817',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 16:28',
        invoice_code: 'C004299074',
        center_type: 'Pickup Point',
        center_name: 'HLL LIFECARE LIMITED',
        center_state: 'Maharashtra',
        center_city: 'Nagpur',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330915, 330916],
        pickup_time_taken: '3.55',
        scheduled_pick_up_time: '2023-05-22 16:01',
        actual_pick_up_time: '2023-05-22 14:36:36',
        time_slot: '21:00 - 22:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'ANIKET CHAWALE',
        pickup_fe_code: 'SDS621935',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:17',
        fe_reached_date_time: '2023-05-22 14:33:03',
        reached_latitude: '21.037042',
        reached_longitude: '79.0268514',
        actual_lab_handover_time: '2023-05-22 15:04',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b302719c367d5b4a6dc5a',
        bag_id: '646b302719c367d5b4a6dc4f',
        lab_number: '386709263',
        reference_number: '386709263-118236-1',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP17370',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:34',
        bag_created_by: 'ANIKET CHAWALE (SDS621935)',
        bag_barcode: 'LP220700807817',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 16:28',
        invoice_code: 'C004299074',
        center_type: 'Pickup Point',
        center_name: 'HLL LIFECARE LIMITED',
        center_state: 'Maharashtra',
        center_city: 'Nagpur',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330915, 330916],
        pickup_time_taken: '3.55',
        scheduled_pick_up_time: '2023-05-22 16:01',
        actual_pick_up_time: '2023-05-22 14:36:36',
        time_slot: '21:00 - 22:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'ANIKET CHAWALE',
        pickup_fe_code: 'SDS621935',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:17',
        fe_reached_date_time: '2023-05-22 14:33:03',
        reached_latitude: '21.037042',
        reached_longitude: '79.0268514',
        actual_lab_handover_time: '2023-05-22 15:04',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f9119c367d5b4a6c281',
        bag_id: '646b2f9119c367d5b4a6c25c',
        lab_number: '432102656',
        reference_number: '432102656-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP10033',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:32',
        bag_created_by: 'GOPAL PATEL (CLL021578)',
        bag_barcode: 'LP220700861395',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 15:20',
        invoice_code: 'C004285871',
        center_type: 'Pickup Point',
        center_name: 'AL-HAYAT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Madhya Pradesh',
        pickup_fe_city: 'Indore',
        pickup_fe_lab_name: 'LPL-INDORE ',
        pickup_fe_lab_code: 'L02',
        visit_ids: [324168, 324172],
        pickup_time_taken: '1.77',
        scheduled_pick_up_time: '2023-05-22 14:44',
        actual_pick_up_time: '2023-05-22 14:32:45',
        time_slot: '21:00 - 22:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'GOPAL PATEL',
        pickup_fe_code: 'CLL021578',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:59',
        reached_latitude: '22.7318533',
        reached_longitude: '75.9125517',
        actual_lab_handover_time: '2023-05-22 15:02',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f9119c367d5b4a6c27c',
        bag_id: '646b2f9119c367d5b4a6c25c',
        lab_number: '432102657',
        reference_number: '432102657-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP10033',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:32',
        bag_created_by: 'GOPAL PATEL (CLL021578)',
        bag_barcode: 'LP220700861395',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 15:20',
        invoice_code: 'C004285871',
        center_type: 'Pickup Point',
        center_name: 'AL-HAYAT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Madhya Pradesh',
        pickup_fe_city: 'Indore',
        pickup_fe_lab_name: 'LPL-INDORE ',
        pickup_fe_lab_code: 'L02',
        visit_ids: [324168, 324172],
        pickup_time_taken: '1.77',
        scheduled_pick_up_time: '2023-05-22 14:44',
        actual_pick_up_time: '2023-05-22 14:32:45',
        time_slot: '21:00 - 22:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'GOPAL PATEL',
        pickup_fe_code: 'CLL021578',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:59',
        reached_latitude: '22.7318533',
        reached_longitude: '75.9125517',
        actual_lab_handover_time: '2023-05-22 15:02',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f9119c367d5b4a6c277',
        bag_id: '646b2f9119c367d5b4a6c25c',
        lab_number: '432102660',
        reference_number: '432102660-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP10033',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:32',
        bag_created_by: 'GOPAL PATEL (CLL021578)',
        bag_barcode: 'LP220700861395',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 15:20',
        invoice_code: 'C004285871',
        center_type: 'Pickup Point',
        center_name: 'AL-HAYAT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Madhya Pradesh',
        pickup_fe_city: 'Indore',
        pickup_fe_lab_name: 'LPL-INDORE ',
        pickup_fe_lab_code: 'L02',
        visit_ids: [324168, 324172],
        pickup_time_taken: '1.77',
        scheduled_pick_up_time: '2023-05-22 14:44',
        actual_pick_up_time: '2023-05-22 14:32:45',
        time_slot: '01:00 - 02:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'GOPAL PATEL',
        pickup_fe_code: 'CLL021578',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:59',
        reached_latitude: '22.7318533',
        reached_longitude: '75.9125517',
        actual_lab_handover_time: '2023-05-22 15:02',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f9119c367d5b4a6c272',
        bag_id: '646b2f9119c367d5b4a6c25c',
        lab_number: '432102676',
        reference_number: '432102676-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP10033',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:32',
        bag_created_by: 'GOPAL PATEL (CLL021578)',
        bag_barcode: 'LP220700861395',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 15:20',
        invoice_code: 'C004285871',
        center_type: 'Pickup Point',
        center_name: 'AL-HAYAT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Madhya Pradesh',
        pickup_fe_city: 'Indore',
        pickup_fe_lab_name: 'LPL-INDORE ',
        pickup_fe_lab_code: 'L02',
        visit_ids: [324168, 324172],
        pickup_time_taken: '1.77',
        scheduled_pick_up_time: '2023-05-22 14:44',
        actual_pick_up_time: '2023-05-22 14:32:45',
        time_slot: '01:00 - 02:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'GOPAL PATEL',
        pickup_fe_code: 'CLL021578',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:59',
        reached_latitude: '22.7318533',
        reached_longitude: '75.9125517',
        actual_lab_handover_time: '2023-05-22 15:02',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f9119c367d5b4a6c26d',
        bag_id: '646b2f9119c367d5b4a6c25c',
        lab_number: '432102654',
        reference_number: '432102654-104258-1',
        tube_colour_code: '104258',
        tube_color: 'RED TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP10033',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:32',
        bag_created_by: 'GOPAL PATEL (CLL021578)',
        bag_barcode: 'LP220700861395',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 15:20',
        invoice_code: 'C004285871',
        center_type: 'Pickup Point',
        center_name: 'AL-HAYAT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Madhya Pradesh',
        pickup_fe_city: 'Indore',
        pickup_fe_lab_name: 'LPL-INDORE ',
        pickup_fe_lab_code: 'L02',
        visit_ids: [324168, 324172],
        pickup_time_taken: '1.77',
        scheduled_pick_up_time: '2023-05-22 14:44',
        actual_pick_up_time: '2023-05-22 14:32:45',
        time_slot: '01:00 - 02:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'GOPAL PATEL',
        pickup_fe_code: 'CLL021578',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:59',
        reached_latitude: '22.7318533',
        reached_longitude: '75.9125517',
        actual_lab_handover_time: '2023-05-22 15:02',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f9119c367d5b4a6c265',
        bag_id: '646b2f9119c367d5b4a6c25c',
        lab_number: '432102658',
        reference_number: '432102658-104088-1',
        tube_colour_code: '104088',
        tube_color: 'GREY TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP10033',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:32',
        bag_created_by: 'GOPAL PATEL (CLL021578)',
        bag_barcode: 'LP220700861395',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 15:20',
        invoice_code: 'C004285871',
        center_type: 'Pickup Point',
        center_name: 'AL-HAYAT HOSPITAL',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Madhya Pradesh',
        pickup_fe_city: 'Indore',
        pickup_fe_lab_name: 'LPL-INDORE ',
        pickup_fe_lab_code: 'L02',
        visit_ids: [324168, 324172],
        pickup_time_taken: '1.77',
        scheduled_pick_up_time: '2023-05-22 14:44',
        actual_pick_up_time: '2023-05-22 14:32:45',
        time_slot: '02:00 - 03:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'GOPAL PATEL',
        pickup_fe_code: 'CLL021578',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:59',
        reached_latitude: '22.7318533',
        reached_longitude: '75.9125517',
        actual_lab_handover_time: '2023-05-22 15:02',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f7719c367d5b4a6bdae',
        bag_id: '646b2f7719c367d5b4a6bd8f',
        lab_number: '444038056',
        reference_number: '444038056-118236-1',
        tube_colour_code: '118236',
        tube_color: 'WHITE TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP8174',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:31',
        bag_created_by: 'VASANTH KUMAR R (CLL071958)',
        bag_barcode: 'LP230300077903',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 16:47',
        invoice_code: 'C004282123',
        center_type: 'Pickup Point',
        center_name: 'MEDEZER LLP',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Karnataka',
        pickup_fe_city: 'Mysore',
        pickup_fe_lab_name: 'LPL – MYSORE',
        pickup_fe_lab_code: 'L07',
        visit_ids: [324348, 324350],
        pickup_time_taken: '2.33',
        scheduled_pick_up_time: '2023-05-22 16:27',
        actual_pick_up_time: '2023-05-22 14:32:59',
        time_slot: '02:00 - 03:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'VASANTH KUMAR R',
        pickup_fe_code: 'CLL071958',
        pickup_fe_zone: 'SOUTH',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:39',
        reached_latitude: '12.2926995',
        reached_longitude: '76.6270933',
        actual_lab_handover_time: '2023-05-22 14:43',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f7719c367d5b4a6bda9',
        bag_id: '646b2f7719c367d5b4a6bd8f',
        lab_number: '444038057',
        reference_number: '444038057-104258-1',
        tube_colour_code: '104258',
        tube_color: 'RED TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP8174',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:31',
        bag_created_by: 'VASANTH KUMAR R (CLL071958)',
        bag_barcode: 'LP230300077903',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 16:47',
        invoice_code: 'C004282123',
        center_type: 'Pickup Point',
        center_name: 'MEDEZER LLP',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Karnataka',
        pickup_fe_city: 'Mysore',
        pickup_fe_lab_name: 'LPL – MYSORE',
        pickup_fe_lab_code: 'L07',
        visit_ids: [324348, 324350],
        pickup_time_taken: '2.33',
        scheduled_pick_up_time: '2023-05-22 16:27',
        actual_pick_up_time: '2023-05-22 14:32:59',
        time_slot: '02:00 - 03:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'VASANTH KUMAR R',
        pickup_fe_code: 'CLL071958',
        pickup_fe_zone: 'SOUTH',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:39',
        reached_latitude: '12.2926995',
        reached_longitude: '76.6270933',
        actual_lab_handover_time: '2023-05-22 14:43',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f7719c367d5b4a6bda1',
        bag_id: '646b2f7719c367d5b4a6bd8f',
        lab_number: '444038058',
        reference_number: '444038058-104258-1',
        tube_colour_code: '104258',
        tube_color: 'RED TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP8174',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:31',
        bag_created_by: 'VASANTH KUMAR R (CLL071958)',
        bag_barcode: 'LP230300077903',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 16:47',
        invoice_code: 'C004282123',
        center_type: 'Pickup Point',
        center_name: 'MEDEZER LLP',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Karnataka',
        pickup_fe_city: 'Mysore',
        pickup_fe_lab_name: 'LPL – MYSORE',
        pickup_fe_lab_code: 'L07',
        visit_ids: [324348, 324350],
        pickup_time_taken: '2.33',
        scheduled_pick_up_time: '2023-05-22 16:27',
        actual_pick_up_time: '2023-05-22 14:32:59',
        time_slot: '02:00 - 03:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'VASANTH KUMAR R',
        pickup_fe_code: 'CLL071958',
        pickup_fe_zone: 'SOUTH',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:39',
        reached_latitude: '12.2926995',
        reached_longitude: '76.6270933',
        actual_lab_handover_time: '2023-05-22 14:43',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2f0519c367d5b4a6b211',
        bag_id: '646b2f0519c367d5b4a6b207',
        lab_number: '441458266',
        reference_number: '441458266-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP8942',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:29',
        bag_created_by: 'SHOBHAM KUMAR KOSHALE (CLL651603)',
        bag_barcode: 'LP220801189324',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:22',
        invoice_code: 'C004283432',
        center_type: 'Pickup Point',
        center_name: 'MAA SARASWATI PATHOLOGY',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [330360, 330361],
        pickup_time_taken: '2.03',
        scheduled_pick_up_time: '2023-05-22 14:12',
        actual_pick_up_time: '2023-05-22 14:30:42',
        time_slot: '02:00 - 03:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'SHOBHAM KUMAR KOSHALE',
        pickup_fe_code: 'CLL651603',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:28',
        fe_reached_date_time: '2023-05-22 14:28:40',
        reached_latitude: '22.0778906',
        reached_longitude: '82.1571515',
        actual_lab_handover_time: '2023-05-22 14:37',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b2f0519c367d5b4a6b20c',
        bag_id: '646b2f0519c367d5b4a6b207',
        lab_number: '441458265',
        reference_number: '441458265-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP8942',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:29',
        bag_created_by: 'SHOBHAM KUMAR KOSHALE (CLL651603)',
        bag_barcode: 'LP220801189324',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:22',
        invoice_code: 'C004283432',
        center_type: 'Pickup Point',
        center_name: 'MAA SARASWATI PATHOLOGY',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Chhattisgarh',
        pickup_fe_city: 'Bilaspur',
        pickup_fe_lab_name: 'LPL BILASPUR',
        pickup_fe_lab_code: 'L65',
        visit_ids: [330360, 330361],
        pickup_time_taken: '2.03',
        scheduled_pick_up_time: '2023-05-22 14:12',
        actual_pick_up_time: '2023-05-22 14:30:42',
        time_slot: '02:00 - 03:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'SHOBHAM KUMAR KOSHALE',
        pickup_fe_code: 'CLL651603',
        pickup_fe_zone: 'WEST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:28',
        fe_reached_date_time: '2023-05-22 14:28:40',
        reached_latitude: '22.0778906',
        reached_longitude: '82.1571515',
        actual_lab_handover_time: '2023-05-22 14:37',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b2ec419c367d5b4a6ad92',
        bag_id: '646b2ec419c367d5b4a6ad61',
        lab_number: '390831189',
        reference_number: '390831189-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP14085',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:28',
        bag_created_by: 'KAILAS HULKE (SDS621331)',
        bag_barcode: 'LP220801221055',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:38',
        invoice_code: 'C004293367',
        center_type: 'Pickup Point',
        center_name: 'VAIBHAV SHISHIR BANAIT',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330974, 330975],
        pickup_time_taken: '4.68',
        scheduled_pick_up_time: '2023-05-22 14:30',
        actual_pick_up_time: '2023-05-22 14:29:53',
        time_slot: '03:00 - 04:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'KAILAS HULKE',
        pickup_fe_code: 'SDS621331',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:24',
        fe_reached_date_time: '2023-05-22 14:25:12',
        reached_latitude: '21.1347276',
        reached_longitude: '79.0773133',
        actual_lab_handover_time: '2023-05-22 14:39',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2ec419c367d5b4a6ad89',
        bag_id: '646b2ec419c367d5b4a6ad61',
        lab_number: '390831188',
        reference_number: '390831188-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP14085',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:28',
        bag_created_by: 'KAILAS HULKE (SDS621331)',
        bag_barcode: 'LP220801221055',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:38',
        invoice_code: 'C004293367',
        center_type: 'Pickup Point',
        center_name: 'VAIBHAV SHISHIR BANAIT',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330974, 330975],
        pickup_time_taken: '4.68',
        scheduled_pick_up_time: '2023-05-22 14:30',
        actual_pick_up_time: '2023-05-22 14:29:53',
        time_slot: '03:00 - 04:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'KAILAS HULKE',
        pickup_fe_code: 'SDS621331',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:24',
        fe_reached_date_time: '2023-05-22 14:25:12',
        reached_latitude: '21.1347276',
        reached_longitude: '79.0773133',
        actual_lab_handover_time: '2023-05-22 14:39',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2ec419c367d5b4a6ad84',
        bag_id: '646b2ec419c367d5b4a6ad61',
        lab_number: '390831188',
        reference_number: '390831188-103740-1',
        tube_colour_code: '103740',
        tube_color: 'PURPLE TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP14085',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:28',
        bag_created_by: 'KAILAS HULKE (SDS621331)',
        bag_barcode: 'LP220801221055',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:38',
        invoice_code: 'C004293367',
        center_type: 'Pickup Point',
        center_name: 'VAIBHAV SHISHIR BANAIT',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330974, 330975],
        pickup_time_taken: '4.68',
        scheduled_pick_up_time: '2023-05-22 14:30',
        actual_pick_up_time: '2023-05-22 14:29:53',
        time_slot: '03:00 - 04:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'KAILAS HULKE',
        pickup_fe_code: 'SDS621331',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:24',
        fe_reached_date_time: '2023-05-22 14:25:12',
        reached_latitude: '21.1347276',
        reached_longitude: '79.0773133',
        actual_lab_handover_time: '2023-05-22 14:39',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2ec419c367d5b4a6ad7f',
        bag_id: '646b2ec419c367d5b4a6ad61',
        lab_number: '390831189',
        reference_number: '390831189-103740-1',
        tube_colour_code: '103740',
        tube_color: 'PURPLE TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP14085',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:28',
        bag_created_by: 'KAILAS HULKE (SDS621331)',
        bag_barcode: 'LP220801221055',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:38',
        invoice_code: 'C004293367',
        center_type: 'Pickup Point',
        center_name: 'VAIBHAV SHISHIR BANAIT',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330974, 330975],
        pickup_time_taken: '4.68',
        scheduled_pick_up_time: '2023-05-22 14:30',
        actual_pick_up_time: '2023-05-22 14:29:53',
        time_slot: '03:00 - 04:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'KAILAS HULKE',
        pickup_fe_code: 'SDS621331',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:24',
        fe_reached_date_time: '2023-05-22 14:25:12',
        reached_latitude: '21.1347276',
        reached_longitude: '79.0773133',
        actual_lab_handover_time: '2023-05-22 14:39',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2ec419c367d5b4a6ad77',
        bag_id: '646b2ec419c367d5b4a6ad61',
        lab_number: '390831189',
        reference_number: '390831189-104088-1',
        tube_colour_code: '104088',
        tube_color: 'GREY TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP14085',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:28',
        bag_created_by: 'KAILAS HULKE (SDS621331)',
        bag_barcode: 'LP220801221055',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:38',
        invoice_code: 'C004293367',
        center_type: 'Pickup Point',
        center_name: 'VAIBHAV SHISHIR BANAIT',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330974, 330975],
        pickup_time_taken: '4.68',
        scheduled_pick_up_time: '2023-05-22 14:30',
        actual_pick_up_time: '2023-05-22 14:29:53',
        time_slot: '03:00 - 04:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'KAILAS HULKE',
        pickup_fe_code: 'SDS621331',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:24',
        fe_reached_date_time: '2023-05-22 14:25:12',
        reached_latitude: '21.1347276',
        reached_longitude: '79.0773133',
        actual_lab_handover_time: '2023-05-22 14:39',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2ec419c367d5b4a6ad70',
        bag_id: '646b2ec419c367d5b4a6ad61',
        lab_number: '390831188',
        reference_number: '390831188-104088-1',
        tube_colour_code: '104088',
        tube_color: 'GREY TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP14085',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:28',
        bag_created_by: 'KAILAS HULKE (SDS621331)',
        bag_barcode: 'LP220801221055',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:38',
        invoice_code: 'C004293367',
        center_type: 'Pickup Point',
        center_name: 'VAIBHAV SHISHIR BANAIT',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330974, 330975],
        pickup_time_taken: '4.68',
        scheduled_pick_up_time: '2023-05-22 14:30',
        actual_pick_up_time: '2023-05-22 14:29:53',
        time_slot: '03:00 - 04:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'KAILAS HULKE',
        pickup_fe_code: 'SDS621331',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:24',
        fe_reached_date_time: '2023-05-22 14:25:12',
        reached_latitude: '21.1347276',
        reached_longitude: '79.0773133',
        actual_lab_handover_time: '2023-05-22 14:39',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2ec419c367d5b4a6ad69',
        bag_id: '646b2ec419c367d5b4a6ad61',
        lab_number: '390831187',
        reference_number: '390831187-112023-1',
        tube_colour_code: '112023',
        tube_color: 'CONTAINER',
        type: 2,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'CONTAINER',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP14085',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:28',
        bag_created_by: 'KAILAS HULKE (SDS621331)',
        bag_barcode: 'LP220801221055',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:38',
        invoice_code: 'C004293367',
        center_type: 'Pickup Point',
        center_name: 'VAIBHAV SHISHIR BANAIT',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Maharashtra',
        pickup_fe_city: 'Nagpur',
        pickup_fe_lab_name: ' LPL-NAGPUR',
        pickup_fe_lab_code: 'S62',
        visit_ids: [330974, 330975],
        pickup_time_taken: '4.68',
        scheduled_pick_up_time: '2023-05-22 14:30',
        actual_pick_up_time: '2023-05-22 14:29:53',
        time_slot: '06:00 - 07:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'KAILAS HULKE',
        pickup_fe_code: 'SDS621331',
        pickup_fe_zone: 'WEST',
        visit_type: 'ON_DEMAND',
        fe_started_date_time: '2023-05-22 14:24',
        fe_reached_date_time: '2023-05-22 14:25:12',
        reached_latitude: '21.1347276',
        reached_longitude: '79.0773133',
        actual_lab_handover_time: '2023-05-22 14:39',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2ebc19c367d5b4a6acac',
        bag_id: '646b2ebc19c367d5b4a6aca5',
        lab_number: '385205176',
        reference_number: '385205176-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'LPP17511',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:28',
        bag_created_by: 'VASANTH KUMAR R (CLL071958)',
        bag_barcode: 'LP230300285895',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 16:47',
        invoice_code: 'C004299516',
        center_type: 'Pickup Point',
        center_name: 'LEELA CLINIC',
        center_state: ' ',
        center_city: ' ',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Karnataka',
        pickup_fe_city: 'Mysore',
        pickup_fe_lab_name: 'LPL – MYSORE',
        pickup_fe_lab_code: 'L07',
        visit_ids: [324346, 324350],
        pickup_time_taken: '1.92',
        scheduled_pick_up_time: '2023-05-22 16:05',
        actual_pick_up_time: '2023-05-22 14:30:05',
        time_slot: '06:00 - 07:00',
        is_pickup_delay: 0,
        pickup_fe_name: 'VASANTH KUMAR R',
        pickup_fe_code: 'CLL071958',
        pickup_fe_zone: 'SOUTH',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:28',
        fe_reached_date_time: '2023-05-22 14:28:10',
        reached_latitude: '12.2926721',
        reached_longitude: '76.6271326',
        actual_lab_handover_time: '2023-05-22 14:43',
        is_lab_handover_delay: 0,
    },
    {
        _id: '646b2e9c19c367d5b4a6aaa5',
        lab_number: '390329241',
        reference_number: '390329241-103740-1',
        tube_colour_code: '103740',
        tube_color: 'PURPLE TOP',
        registration_source: '1xView',
        precious_sample: false,
        registration_type: 1,
        registration_timestamp: '2023-05-22 14:27',
        status: 'Handover Done',
        bag_id: '646b331919c367d5b4a72637',
        registration_status_at_scan: 1,
        type: 1,
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:47',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335698',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:49:12',
        time_slot: '06:00 - 07:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:51',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b2e9c19c367d5b4a6aaa3',
        lab_number: '390329241',
        reference_number: '390329241-135947-1',
        tube_colour_code: '135947',
        tube_color: 'YELLOW TOP',
        registration_source: '1xView',
        precious_sample: false,
        registration_type: 1,
        registration_timestamp: '2023-05-22 14:27',
        status: 'Handover Done',
        bag_id: '646b331919c367d5b4a72637',
        registration_status_at_scan: 1,
        type: 1,
        tube_type: 'TUBE',
        registration_at_the_time_of_bagging: 'REGISTERED',
        client_code: 'CC10197',
        current_registration_status: 'REGISTERED',
        bag_creation_time: '2023-05-22 14:47',
        bag_created_by: 'PRADIP MALAKAR (LRA391961)',
        bag_barcode: 'LP220600335698',
        current_owner_type: 'LAB',
        bag_created_by_type: 'FE',
        scheduled_lab_handover_time: '2023-05-22 14:12',
        invoice_code: 'C004290884',
        center_type: 'Collection Center',
        center_name: 'DOCTORS POINT',
        center_state: 'Assam',
        center_city: 'Guwahati',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: ' ',
        pickup_fe_city: ' ',
        pickup_fe_lab_name: 'LPL Guwahati III',
        pickup_fe_lab_code: 'A39',
        visit_ids: [329156, 329159],
        pickup_time_taken: '8.22',
        scheduled_pick_up_time: '2023-05-22 13:39',
        actual_pick_up_time: '2023-05-22 14:49:12',
        time_slot: '06:00 - 07:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'PRADIP MALAKAR',
        pickup_fe_code: 'LRA391961',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:40',
        fe_reached_date_time: '2023-05-22 14:40:59',
        reached_latitude: '26.1839472',
        reached_longitude: '91.7693508',
        actual_lab_handover_time: '2023-05-22 14:51',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b2e8619c367d5b4a6a928',
        bag_id: '646b2e8519c367d5b4a6a91e',
        lab_number: '304984103F',
        reference_number: '304984103F-104088-1',
        tube_colour_code: '104088',
        tube_color: 'GREY TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'CC7869',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:27',
        bag_created_by: 'CC7869',
        bag_barcode: 'LP220901483741',
        current_owner_type: 'LAB',
        bag_created_by_type: 'CC',
        scheduled_lab_handover_time: '2023-05-22 13:59',
        invoice_code: 'C004277777',
        center_type: 'Collection Center',
        center_name: 'NEW  BIO  DIAGNOSTIC',
        center_state: 'Bihar',
        center_city: 'Patna',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Bihar',
        pickup_fe_city: 'Patna',
        pickup_fe_lab_name: 'PATNA LAB II',
        pickup_fe_lab_code: 'L96',
        visit_ids: [326075, 326076],
        pickup_time_taken: '1.42',
        scheduled_pick_up_time: '2023-05-22 13:50',
        actual_pick_up_time: '2023-05-22 14:31:37',
        time_slot: '06:00 - 07:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'CHANCHAL KUMAR',
        pickup_fe_code: 'LRL961585',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:12',
        reached_latitude: '25.5929',
        reached_longitude: '85.0719763',
        actual_lab_handover_time: '2023-05-22 14:47',
        is_lab_handover_delay: 1,
    },
    {
        _id: '646b2e8519c367d5b4a6a923',
        bag_id: '646b2e8519c367d5b4a6a91e',
        lab_number: '304984106P',
        reference_number: '304984106P-104088-1',
        tube_colour_code: '104088',
        tube_color: 'GREY TOP',
        type: 1,
        precious_sample: false,
        registration_type: 2,
        registration_status_at_scan: 2,
        status: 'Handover Done',
        tube_type: 'TUBE',
        registration_timestamp: '2023-05-22 09:38',
        registration_at_the_time_of_bagging: 'UNREGISTERED',
        client_code: 'CC7869',
        current_registration_status: 'UNREGISTERED',
        bag_creation_time: '2023-05-22 14:27',
        bag_created_by: 'CC7869',
        bag_barcode: 'LP220901483741',
        current_owner_type: 'LAB',
        bag_created_by_type: 'CC',
        scheduled_lab_handover_time: '2023-05-22 13:59',
        invoice_code: 'C004277777',
        center_type: 'Collection Center',
        center_name: 'NEW  BIO  DIAGNOSTIC',
        center_state: 'Bihar',
        center_city: 'Patna',
        network_type: 'FE TOWN',
        route_type: 'DYNAMIC',
        pickup_fe_state: 'Bihar',
        pickup_fe_city: 'Patna',
        pickup_fe_lab_name: 'PATNA LAB II',
        pickup_fe_lab_code: 'L96',
        visit_ids: [326075, 326076],
        pickup_time_taken: '1.42',
        scheduled_pick_up_time: '2023-05-22 13:50',
        actual_pick_up_time: '2023-05-22 14:31:37',
        time_slot: '06:00 - 07:00',
        is_pickup_delay: 1,
        pickup_fe_name: 'CHANCHAL KUMAR',
        pickup_fe_code: 'LRL961585',
        pickup_fe_zone: 'EAST',
        visit_type: 'DYNAMIC',
        fe_started_date_time: '2023-05-22 14:30',
        fe_reached_date_time: '2023-05-22 14:30:12',
        reached_latitude: '25.5929',
        reached_longitude: '85.0719763',
        actual_lab_handover_time: '2023-05-22 14:47',
        is_lab_handover_delay: 1,
    },
];
exports.r_lab_p_lab_sampleRes = [
    {
        _id: '647da8b09c701fc7aee14f57',
        barcode: '434343A22Z1121',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:42.705Z',
        processing_lab: 'S02',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-01T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 14:12',
        actual_lab_handover_time: '2023-05-22 14:49:12',
    },
    {
        _id: '647da8b19c701fc7aee14f6c',
        barcode: '434343A22Z1122',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:44.173Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 14:15',
        actual_lab_handover_time: '2023-05-22 15:49:12',
    },
    {
        _id: '647da8b39c701fc7aee14f94',
        barcode: '434343A22Z1123',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:45.875Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 15:15',
        actual_lab_handover_time: '2023-05-22 16:49:12',
    },
    {
        _id: '647da8b49c701fc7aee14fbb',
        barcode: '434343A22Z1124',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:47.357Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 13:15',
        actual_lab_handover_time: '2023-05-22 16:49:12',
    },
    {
        _id: '647da8b69c701fc7aee14fec',
        barcode: '434343A22Z1125',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:48.836Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 12:15',
        actual_lab_handover_time: '2023-05-22 13:49:12',
    },
    {
        _id: '647da8b79c701fc7aee15016',
        barcode: '434343A22Z1126',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:50.168Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 11:15',
        actual_lab_handover_time: '2023-05-22 13:49:12',
    },
    {
        _id: '647da8b99c701fc7aee15053',
        barcode: '434343A22Z1127',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:51.703Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 16:15',
        actual_lab_handover_time: '2023-05-22 18:49:12',
    },
    {
        _id: '647da8ba9c701fc7aee15076',
        barcode: '434343A22Z1128',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:53.137Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 18:15',
        actual_lab_handover_time: '2023-05-22 20:49:12',
    },
    {
        _id: '647da8bb9c701fc7aee150a2',
        barcode: '434343A22Z1129',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:54.694Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 18:15',
        actual_lab_handover_time: '2023-05-22 20:49:12',
    },
    {
        _id: '647da8bd9c701fc7aee150c3',
        barcode: '434343A22Z1130',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:56.567Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 14:15',
        actual_lab_handover_time: '2023-05-22 15:49:12',
    },
    {
        _id: '647da8bf9c701fc7aee150f4',
        barcode: '434343A22Z1131',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:57.996Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 14:15',
        actual_lab_handover_time: '2023-05-22 15:49:12',
    },
    {
        _id: '647da8c09c701fc7aee1514c',
        barcode: '434343A22Z1132',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:19:59.738Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 15:15',
        actual_lab_handover_time: '2023-05-22 17:49:12',
    },
    {
        _id: '647dac179c701fc7aee19730',
        barcode: '434343A22Z1133',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:34:13.001Z',
        processing_lab: 'S02',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-01T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 15:15',
        actual_lab_handover_time: '2023-05-22 18:49:12',
    },
    {
        _id: '647dac189c701fc7aee19747',
        barcode: '434343A22Z1134',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:34:14.936Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 15:15',
        actual_lab_handover_time: '2023-05-22 19:49:12',
    },
    {
        _id: '647dac1a9c701fc7aee1977a',
        barcode: '434343A22Z1135',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:34:16.611Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 17:15',
        actual_lab_handover_time: '2023-05-22 17:49:12',
    },
    {
        _id: '647dac1b9c701fc7aee197b1',
        barcode: '434343A22Z1136',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:34:18.041Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 13:15',
        actual_lab_handover_time: '2023-05-22 17:49:12',
    },
    {
        _id: '647dac1d9c701fc7aee197da',
        barcode: '434343A22Z1137',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:34:19.987Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 11:15',
        actual_lab_handover_time: '2023-05-22 17:49:12',
    },
    {
        _id: '647dac1f9c701fc7aee1980b',
        barcode: '434343A22Z1138',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:34:22.215Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 15:15',
        actual_lab_handover_time: '2023-05-22 13:49:12',
    },
    {
        _id: '647dac219c701fc7aee19853',
        barcode: '434343A22Z1139',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:34:24.544Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 15:15',
        actual_lab_handover_time: '2023-05-22 17:49:12',
    },
    {
        _id: '647dac249c701fc7aee19897',
        barcode: '434343A22Z1140',
        __v: 0,
        created_by: 'undefined',
        created_date_time: '2023-06-05T09:34:26.583Z',
        processing_lab: 'A45',
        registration_lab: 'L51',
        shipment_closure_time: '2023-06-16T00:00:00.000Z',
        shipment_creation_date: '2023-05-31T00:00:00.000Z',
        shipment_creation_initiation_time: '2023-05-31T00:00:00.000Z',
        starlims_user_id: 'SATENDRA',
        status: 1,
        actual_pickup_time: '2023-05-22 10:15',
        actual_lab_handover_time: '2023-05-22 13:49:12',
    },
];
exports.sampleResponseForOutStationBox = [
    {
        _id: '644f866eae98f2b99d03f217',
        barcode_id: 'L2301000108',
        created_by_type: 'CC',
        created_by_id: 'LPP1984',
        current_owner_id: '535d9aebe0d1475d83adf878efaba07e',
        current_owner_type: 'LAB',
        status: 'Handed over at lab',
        created_date_time: '2023-05-01T09:29:18.454Z',
        created_by_name: 'SUN VIEW',
        from_location: 'Baduria',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: '2023-05-02T06:44:29.091Z',
        to_location: 'KOLKATA',
        in_transit_time: '21:15:10',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'In transit',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '12:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'Handed over at lab',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '9:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'In transit',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '10:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'In transit',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '19:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'Handed over at lab',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '16:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'Handed over at lab',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '2:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'Handed over at lab',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '03:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'In transit',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '14:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'In transit',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '17:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'Handed over at lab',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '02:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'In transit',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '07:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'In transit',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '20:53:6',
    },
    {
        _id: '645a4a880b6b082168a9024a',
        barcode_id: 'L1230000000',
        created_by_type: 'FE',
        created_by_id: 'CLA671214',
        current_owner_id: 'transport',
        current_owner_type: 'transport',
        status: 'Handed over at lab',
        created_date_time: '2023-05-09T13:28:40.843Z',
        created_by_name: 'LOKANATH EXECUTIVE',
        from_location: '',
        tagged_lab_code: 'L51',
        tagged_lab_name: 'MEGACITY NURSING HOME PRIVATE LIMITED',
        movement_by: 'Bus',
        handover_date_time: null,
        to_location: null,
        in_transit_time: '23:53:6',
    },
];
var filterRegistration_to_Pickup = function (response) {
    var sampleInformation = {
        activeSampele: [],
        timeDiffereceData: [],
    };
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.registration_timestamp) && (val === null || val === void 0 ? void 0 : val.actual_pick_up_time)) {
            sampleInformation.activeSampele.push(val);
            var registration = moment(val.registration_timestamp.toString(), ' hh:mm:ss A');
            var actual_pick_up = moment(val.actual_pick_up_time, ' hh:mm:ss A');
            var duration = moment.duration(actual_pick_up.diff(registration));
            var hours = parseInt(duration.asHours());
            var minutes = parseInt(duration.asMinutes()) % 60;
            val.time_scheduled = "".concat(hours, ":").concat(minutes);
            sampleInformation.timeDiffereceData.push(val);
        }
    });
    console.log(sampleInformation.timeDiffereceData, 'timeDiffereceData');
    return sampleInformation;
};
exports.filterRegistration_to_Pickup = filterRegistration_to_Pickup;
var filterRegistration_to_lab_handover = function (response) {
    var sampleInformation = {
        activeSampele: [],
        timeDiffereceData: [],
    };
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.registration_timestamp) && (val === null || val === void 0 ? void 0 : val.actual_lab_handover_time)) {
            sampleInformation.activeSampele.push(val);
            var registration = moment(val.registration_timestamp.toString(), ' hh:mm:ss A');
            var labHandoverTime = moment(val.actual_lab_handover_time, ' hh:mm:ss A');
            var duration = moment.duration(labHandoverTime.diff(registration));
            var hours = parseInt(duration.asHours());
            var minutes = parseInt(duration.asMinutes()) % 60;
            val.time_scheduled = "".concat(hours, ":").concat(minutes);
            sampleInformation.timeDiffereceData.push(val);
        }
    });
    console.log(sampleInformation.timeDiffereceData, 'timeDiffereceData');
    return sampleInformation;
};
exports.filterRegistration_to_lab_handover = filterRegistration_to_lab_handover;
var filterRegistration_to_lab_receiving = function (response) {
    var sampleInformation = {
        activeSampele: [],
        timeDiffereceData: [],
    };
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.registration_timestamp) && (val === null || val === void 0 ? void 0 : val.lab_receiving_timestamp)) {
            sampleInformation.activeSampele.push(val);
            var registration = moment(val.registration_timestamp.toString(), ' hh:mm:ss A');
            var labReceivedTime = moment(val.lab_receiving_timestamp, ' hh:mm:ss A');
            var duration = moment.duration(labReceivedTime.diff(registration));
            var hours = parseInt(duration.asHours());
            var minutes = parseInt(duration.asMinutes()) % 60;
            val.time_scheduled = "".concat(hours, ":").concat(minutes);
            sampleInformation.timeDiffereceData.push(val);
        }
    });
    console.log(sampleInformation.timeDiffereceData, 'timeDiffereceData');
    return sampleInformation;
};
exports.filterRegistration_to_lab_receiving = filterRegistration_to_lab_receiving;
var filterPickupt_to_lab_handover = function (response) {
    var sampleInformation = {
        activeSampele: [],
        timeDiffereceData: [],
    };
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.actual_pick_up_time) && (val === null || val === void 0 ? void 0 : val.actual_lab_handover_time)) {
            sampleInformation.activeSampele.push(val);
            var pickupTime = moment(val.actual_pick_up_time.toString(), ' hh:mm:ss A');
            var labHandoverTime = moment(val.actual_lab_handover_time, ' hh:mm:ss A');
            var duration = moment.duration(labHandoverTime.diff(pickupTime));
            var hours = parseInt(duration.asHours());
            var minutes = parseInt(duration.asMinutes()) % 60;
            val.time_scheduled = "".concat(hours, ":").concat(minutes);
            sampleInformation.timeDiffereceData.push(val);
        }
    });
    console.log(sampleInformation, 'timeDiffereceDataFE');
    return sampleInformation;
};
exports.filterPickupt_to_lab_handover = filterPickupt_to_lab_handover;
var filterPickupt_to_lab_reveive = function (response) {
    var sampleInformation = {
        activeSampele: [],
        timeDiffereceData: [],
    };
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.actual_pick_up_time) && (val === null || val === void 0 ? void 0 : val.lab_receiving_timestamp)) {
            sampleInformation.activeSampele.push(val);
            var pickupTime = moment(val.actual_pick_up_time.toString(), ' hh:mm:ss A');
            var labReceivedTime = moment(val.lab_receiving_timestamp, ' hh:mm:ss A');
            var duration = moment.duration(labReceivedTime.diff(pickupTime));
            var hours = parseInt(duration.asHours());
            var minutes = parseInt(duration.asMinutes()) % 60;
            val.time_scheduled = "".concat(hours, ":").concat(minutes);
            sampleInformation.timeDiffereceData.push(val);
        }
    });
    console.log(sampleInformation.timeDiffereceData, 'timeDiffereceData');
    return sampleInformation;
};
exports.filterPickupt_to_lab_reveive = filterPickupt_to_lab_reveive;
var filterLab_handover_to_lab_receiving = function (response) {
    var sampleInformation = {
        activeSampele: [],
        timeDiffereceData: [],
    };
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.actual_lab_handover_time) && (val === null || val === void 0 ? void 0 : val.lab_receiving_timestamp)) {
            sampleInformation.activeSampele.push(val);
            var labHanoverTime = moment(val.actual_lab_handover_time.toString(), ' hh:mm:ss A');
            var labHandoverTime = moment(val.lab_receiving_timestamp, ' hh:mm:ss A');
            var duration = moment.duration(labHandoverTime.diff(labHanoverTime));
            var hours = parseInt(duration.asHours());
            var minutes = parseInt(duration.asMinutes()) % 60;
            val.time_scheduled = "".concat(hours, ":").concat(minutes);
            sampleInformation.timeDiffereceData.push(val);
        }
    });
    console.log(sampleInformation.timeDiffereceData, 'timeDiffereceData');
    return sampleInformation;
};
exports.filterLab_handover_to_lab_receiving = filterLab_handover_to_lab_receiving;
var filterDataBasedOnTime = function (timeInfo) {
    var totalInfo = {
        halfOnHourData: [],
        oneHourData: [],
        ondAndHalgHourData: [],
        twoHOursData: [],
        twoandHalfHourData: [],
        threeHourData: [],
    };
    timeInfo.map(function (val) {
        if (Number(val.time_scheduled.split(':')[0]) <= 0 &&
            Number(val.time_scheduled.split(':')[1]) <= 30) {
            totalInfo.halfOnHourData.push(val);
        }
        else if (Number(val.time_scheduled.split(':')[0]) <= 0 &&
            Number(val.time_scheduled.split(':')[1]) > 30 &&
            Number(val.time_scheduled.split(':')[1]) < 59) {
            totalInfo.oneHourData.push(val);
        }
        else if (Number(val.time_scheduled.split(':')[0]) == 1 &&
            Number(val.time_scheduled.split(':')[1]) <= 30) {
            totalInfo.ondAndHalgHourData.push(val);
        }
        else if (Number(val.time_scheduled.split(':')[0]) == 1 &&
            Number(val.time_scheduled.split(':')[1]) > 30 &&
            Number(val.time_scheduled.split(':')[1]) < 59) {
            totalInfo.twoHOursData.push(val);
        }
        else if (Number(val.time_scheduled.split(':')[0]) == 2 &&
            Number(val.time_scheduled.split(':')[1]) <= 30) {
            totalInfo.twoandHalfHourData.push(val);
        }
        else if (Number(val.time_scheduled.split(':')[0]) >= 2 &&
            Number(val.time_scheduled.split(':')[1]) < 59) {
            totalInfo.threeHourData.push(val);
        }
    });
    var seriesData = [];
    var series = [
        { data: totalInfo.halfOnHourData, timeDifference: '30' },
        { data: totalInfo.oneHourData, timeDifference: '60' },
        { data: totalInfo.ondAndHalgHourData, timeDifference: '90' },
        { data: totalInfo.twoHOursData, timeDifference: '120' },
        { data: totalInfo.twoandHalfHourData, timeDifference: '150' },
        { data: totalInfo.threeHourData, timeDifference: '180' },
    ];
    console.log(series, 'seriesSet');
    series.map(function (val) {
        var timeSet = val.timeDifference.split(':');
        var milllSec = +timeSet[0] * (60000 * 60) + +timeSet[1] * 60000;
        seriesData.push({
            timeDifference: val.timeDifference,
            totalSampleLength: val.data.length,
            millSec: milllSec,
            seriesSet: "".concat(milllSec, ",").concat(val.data.length),
            minutesData: val.data,
        });
    });
    console.log(seriesData, 'seriesData');
    var finalSeriesData = {};
    var filterSeriesData = [];
    var filterSeriesTableData = [];
    seriesData.map(function (val) {
        filterSeriesTableData.push(val.minutesData);
        return filterSeriesData.push([val.totalSampleLength]);
    });
    finalSeriesData = {
        seriesData: filterSeriesData,
        tableSeriesData: filterSeriesTableData,
    };
    console.log(finalSeriesData, 'finalSeriesData');
    return finalSeriesData;
};
exports.filterDataBasedOnTime = filterDataBasedOnTime;
var filterCumulativeData = function (convertedMinData, timeDiffData) {
    console.log(convertedMinData, timeDiffData, 'convertedMinData, timeDiffData');
    var totalPecercentageData = [];
    if (convertedMinData.length) {
        convertedMinData.map(function (val) {
            var percentage = (100 * val[0]) / timeDiffData.length;
            totalPecercentageData.push(Number(percentage.toFixed(1)));
        });
        var cumulativeSet_1 = [];
        var percentageIno_1 = 0;
        totalPecercentageData.map(function (val) {
            percentageIno_1 = percentageIno_1 + val;
            cumulativeSet_1.push(Number(percentageIno_1.toFixed(1)));
        });
        return cumulativeSet_1;
    }
};
exports.filterCumulativeData = filterCumulativeData;
var convertHoursTOMillSecAndMillSecToHours = function (sumOfFE_avg_time, totalSampeOrFE) {
    var totaAvgValue = [
        Math.floor(sumOfFE_avg_time.asHours()),
        sumOfFE_avg_time.minutes(),
    ].join(':');
    console.log(totaAvgValue, 'totaAvgValue');
    var toMilliseconds = (Number(totaAvgValue.split(':')[0]) * 60 * 60 +
        Number(totaAvgValue.split(':')[1]) * 60 +
        0) *
        1000;
    var avgHandoverTime = Number(toMilliseconds) / totalSampeOrFE;
    var milllSecToHouMin = moment.duration(avgHandoverTime, 'milliseconds');
    var hours = Math.floor(milllSecToHouMin.asHours());
    var mins = Math.floor(milllSecToHouMin.asMinutes()) - hours * 60;
    return "".concat(hours, ":").concat(mins);
};
exports.convertHoursTOMillSecAndMillSecToHours = convertHoursTOMillSecAndMillSecToHours;
var prepareIntracityFETableList = function (feList, setIntracityFETatTableData, setIntracityFEGrandTotl) {
    if (feList.length > 0) {
        feList.map(function (val) {
            if (val.no_of_fe_sampple.length > 0) {
                var sumOfFE_avg_time_1 = val.no_of_fe_sampple.reduce(function (acc, time) { return acc.add(moment.duration(time.time_scheduled)); }, moment.duration());
                var feAvgTime = (0, exports.convertHoursTOMillSecAndMillSecToHours)(sumOfFE_avg_time_1, val.no_of_fe_sampple.length);
                val.total_avg_pickupToHandOverTime = feAvgTime;
            }
        });
        setIntracityFETatTableData(feList);
        var sumOfFE_avg_time = feList.reduce(function (acc, time) { return acc.add(moment.duration(time.total_avg_pickupToHandOverTime)); }, moment.duration());
        var feGrandTotal = (0, exports.convertHoursTOMillSecAndMillSecToHours)(sumOfFE_avg_time, feList.length);
        console.log(feGrandTotal, 'feGrandTotal');
        setIntracityFEGrandTotl(feGrandTotal);
    }
};
exports.prepareIntracityFETableList = prepareIntracityFETableList;
var filterSeriesDataBasedOnSelectedEvent = function (responseData, selectedEvent, setLifeCycleList, setTotalSample, setSeriesData, setCumulativeSeties, setIntracityFETatTableData, setIntracityFEGrandTotl) {
    if (selectedEvent == shipment_constants_1.EVENTS_LIST[0].value) {
        var timeDiffData = (0, exports.filterRegistration_to_Pickup)(responseData);
        var convertedMinData = (0, exports.filterDataBasedOnTime)(timeDiffData.timeDiffereceData);
        var cumulativeList = (0, exports.filterCumulativeData)(convertedMinData.seriesData, timeDiffData.timeDiffereceData);
        setLifeCycleList(convertedMinData.tableSeriesData);
        setTotalSample(timeDiffData.timeDiffereceData.length);
        setSeriesData(convertedMinData.seriesData);
        setCumulativeSeties(cumulativeList);
        var filteredIndividual_fe_samples = (0, exports.Individual_fe_samples)(timeDiffData.timeDiffereceData);
        (0, exports.prepareIntracityFETableList)(filteredIndividual_fe_samples, setIntracityFETatTableData, setIntracityFEGrandTotl);
    }
    else if (selectedEvent == shipment_constants_1.EVENTS_LIST[1].value) {
        var timeDiffData = (0, exports.filterRegistration_to_lab_handover)(responseData);
        var convertedMinData = (0, exports.filterDataBasedOnTime)(timeDiffData.timeDiffereceData);
        var cumulativeList = (0, exports.filterCumulativeData)(convertedMinData.seriesData, timeDiffData.timeDiffereceData);
        setLifeCycleList(convertedMinData.tableSeriesData);
        setTotalSample(timeDiffData.timeDiffereceData.length);
        setSeriesData(convertedMinData.seriesData);
        setCumulativeSeties(cumulativeList);
        var filteredIndividual_fe_samples = (0, exports.Individual_fe_samples)(timeDiffData.timeDiffereceData);
        (0, exports.prepareIntracityFETableList)(filteredIndividual_fe_samples, setIntracityFETatTableData, setIntracityFEGrandTotl);
    }
    else if (selectedEvent == shipment_constants_1.EVENTS_LIST[2].value) {
        var timeDiffData = (0, exports.filterRegistration_to_lab_receiving)(responseData);
        var convertedMinData = (0, exports.filterDataBasedOnTime)(timeDiffData.timeDiffereceData);
        var cumulativeList = (0, exports.filterCumulativeData)(convertedMinData.seriesData, timeDiffData.timeDiffereceData);
        setLifeCycleList(convertedMinData.tableSeriesData);
        setTotalSample(timeDiffData.timeDiffereceData.length);
        setSeriesData(convertedMinData.seriesData);
        setCumulativeSeties(cumulativeList);
        var filteredIndividual_fe_samples = (0, exports.Individual_fe_samples)(timeDiffData.timeDiffereceData);
        (0, exports.prepareIntracityFETableList)(filteredIndividual_fe_samples, setIntracityFETatTableData, setIntracityFEGrandTotl);
    }
    else if (selectedEvent == shipment_constants_1.EVENTS_LIST[3].value) {
        var timeDiffData = (0, exports.filterPickupt_to_lab_handover)(responseData);
        var convertedMinData = (0, exports.filterDataBasedOnTime)(timeDiffData.timeDiffereceData);
        var cumulativeList = (0, exports.filterCumulativeData)(convertedMinData.seriesData, timeDiffData.timeDiffereceData);
        setLifeCycleList(convertedMinData.tableSeriesData);
        setTotalSample(timeDiffData.timeDiffereceData.length);
        setSeriesData(convertedMinData.seriesData);
        setCumulativeSeties(cumulativeList);
        var filteredIndividual_fe_samples = (0, exports.Individual_fe_samples)(timeDiffData.timeDiffereceData);
        (0, exports.prepareIntracityFETableList)(filteredIndividual_fe_samples, setIntracityFETatTableData, setIntracityFEGrandTotl);
    }
    else if (selectedEvent == shipment_constants_1.EVENTS_LIST[4].value) {
        var timeDiffData = (0, exports.filterPickupt_to_lab_reveive)(responseData);
        var convertedMinData = (0, exports.filterDataBasedOnTime)(timeDiffData.timeDiffereceData);
        var cumulativeList = (0, exports.filterCumulativeData)(convertedMinData.seriesData, timeDiffData.timeDiffereceData);
        setLifeCycleList(convertedMinData.tableSeriesData);
        setTotalSample(timeDiffData.timeDiffereceData.length);
        setSeriesData(convertedMinData.seriesData);
        setCumulativeSeties(cumulativeList);
        var filteredIndividual_fe_samples = (0, exports.Individual_fe_samples)(timeDiffData.timeDiffereceData);
        (0, exports.prepareIntracityFETableList)(filteredIndividual_fe_samples, setIntracityFETatTableData, setIntracityFEGrandTotl);
    }
    else if (selectedEvent == shipment_constants_1.EVENTS_LIST[5].value) {
        var timeDiffData = (0, exports.filterLab_handover_to_lab_receiving)(responseData);
        var convertedMinData = (0, exports.filterDataBasedOnTime)(timeDiffData.timeDiffereceData);
        var cumulativeList = (0, exports.filterCumulativeData)(convertedMinData.seriesData, timeDiffData.timeDiffereceData);
        setLifeCycleList(convertedMinData.tableSeriesData);
        setTotalSample(timeDiffData.timeDiffereceData.length);
        setSeriesData(convertedMinData.seriesData);
        setCumulativeSeties(cumulativeList);
        var filteredIndividual_fe_samples = (0, exports.Individual_fe_samples)(timeDiffData.timeDiffereceData);
        (0, exports.prepareIntracityFETableList)(filteredIndividual_fe_samples, setIntracityFETatTableData, setIntracityFEGrandTotl);
    }
};
exports.filterSeriesDataBasedOnSelectedEvent = filterSeriesDataBasedOnSelectedEvent;
var displayTableDataBasedOnselectedGraph = function (xAxisVaues, setTableData, allSeriedData) {
    if (exports.x_axis_values[0] == xAxisVaues) {
        setTableData(allSeriedData[0]);
    }
    else if (exports.x_axis_values[1] == xAxisVaues) {
        setTableData(allSeriedData[1]);
    }
    else if (exports.x_axis_values[2] == xAxisVaues) {
        setTableData(allSeriedData[2]);
    }
    else if (exports.x_axis_values[3] == xAxisVaues) {
        setTableData(allSeriedData[3]);
    }
    else if (exports.x_axis_values[4] == xAxisVaues) {
        setTableData(allSeriedData[4]);
    }
    else if (exports.x_axis_values[5] == xAxisVaues) {
        setTableData(allSeriedData[5]);
    }
};
exports.displayTableDataBasedOnselectedGraph = displayTableDataBasedOnselectedGraph;
var intracityFEOntimePupPerce = function (feSample) {
    feSample.map(function (sample) {
        var delayed_percentage = (Number(sample.delayed_list.length) /
            Number(sample.no_of_fe_sampple.length)) *
            100;
        var early_percentage = (Number(sample.early_list.length) / Number(sample.no_of_fe_sampple.length)) *
            100;
        var ontime_percentage = (Number(sample.ontime_list.length) /
            Number(sample.no_of_fe_sampple.length)) *
            100;
        var grandTotal = Number(delayed_percentage) +
            Number(early_percentage) +
            Number(ontime_percentage);
        sample.delayed_percentage = "".concat(delayed_percentage.toString(), "%");
        sample.early_percentage = "".concat(early_percentage.toString(), "%");
        sample.ontime_percentage = "".concat(ontime_percentage.toString(), "%");
        sample.grandTotal_percentage = "".concat(grandTotal.toString(), "%");
    });
    return feSample;
};
exports.intracityFEOntimePupPerce = intracityFEOntimePupPerce;
var filterDelayedSampleData = function (response, setIntracityFEOntimeTableData) {
    var samplesData = {
        delatedPickups: [],
        earlyPickups: [],
        on_TimePickps: [],
        totoalSample: [],
        fe_samples: [],
    };
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.scheduled_pick_up_time) &&
            (val === null || val === void 0 ? void 0 : val.actual_pick_up_time) &&
            !(val === null || val === void 0 ? void 0 : val.scheduled_pick_up_time.includes('T')) &&
            !(val === null || val === void 0 ? void 0 : val.actual_pick_up_time.includes('T'))) {
            samplesData.totoalSample.push(val);
            var scheduled_pick_up_time = val.scheduled_pick_up_time.split(' ')[1];
            var hoursTime = scheduled_pick_up_time.split(':')[0];
            var add_5_min = scheduled_pick_up_time.split(':')[1];
            var startTime = "".concat(hoursTime, ".").concat(Number(add_5_min) + 5);
            var reduce_5_min = scheduled_pick_up_time.split(':')[1];
            var endTime = "".concat(hoursTime, ".").concat(Number(reduce_5_min) - 5);
            if (val.actual_pick_up_time >= startTime &&
                val.actual_pick_up_time <= endTime) {
                val.FE_sampleType = exports.SAMPLE_TYPES.on_time;
                samplesData.on_TimePickps.push(val);
            }
            else if (val.actual_pick_up_time >= startTime) {
                val.FE_sampleType = exports.SAMPLE_TYPES.delayed;
                samplesData.delatedPickups.push(val);
            }
            else {
                val.FE_sampleType = exports.SAMPLE_TYPES.early;
                samplesData.earlyPickups.push(val);
            }
        }
    });
    var filteredFE_pickup_Samples = (0, exports.Individual_fe_samplesFor_onTImePickup)(samplesData.totoalSample);
    samplesData.fe_samples = filteredFE_pickup_Samples;
    if (filteredFE_pickup_Samples.length > 0) {
        var intracity_fe_pup_table_list = (0, exports.intracityFEOntimePupPerce)(filteredFE_pickup_Samples);
        console.log('intracity_fe_pup_table_list', intracity_fe_pup_table_list);
        if (intracity_fe_pup_table_list.length > 0) {
            var delayedGrandTotal_1 = 0;
            var earlyGrandTotal_1 = 0;
            var ontimeGrandTotal_1 = 0;
            intracity_fe_pup_table_list.map(function (val) {
                delayedGrandTotal_1 =
                    Number(delayedGrandTotal_1) + Number(val.delayed_percentage.split('%')[0]);
                earlyGrandTotal_1 =
                    Number(earlyGrandTotal_1) + Number(val.early_percentage.split('%')[0]);
                ontimeGrandTotal_1 =
                    Number(ontimeGrandTotal_1) + Number(val.ontime_percentage.split('%')[0]);
            });
            var delayedGrandTotalFEPer = Number(delayedGrandTotal_1) / intracity_fe_pup_table_list.length;
            var earlyGrandTotalFEPer = Number(earlyGrandTotal_1) / intracity_fe_pup_table_list.length;
            var ontimeGrandTotalFEPer = Number(ontimeGrandTotal_1) / intracity_fe_pup_table_list.length;
            var grandTotalPer = delayedGrandTotalFEPer + earlyGrandTotalFEPer + ontimeGrandTotalFEPer;
            setIntracityFEOntimeTableData({
                tableData: intracity_fe_pup_table_list,
                delayedPer: "".concat(delayedGrandTotalFEPer, "%"),
                earlyPerc: "".concat(earlyGrandTotalFEPer, "%"),
                OntimePerce: "".concat(ontimeGrandTotalFEPer, "%"),
                grandTotal: "".concat(grandTotalPer, "%"),
            });
        }
    }
    return samplesData;
};
exports.filterDelayedSampleData = filterDelayedSampleData;
var getTimeDifferenceData = function (response, typeOfPickup) {
    var totalSamples = [];
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.scheduled_pick_up_time) && (val === null || val === void 0 ? void 0 : val.actual_pick_up_time)) {
            var scheduled_pick_up_time = val.scheduled_pick_up_time.split(' ')[1];
            var hoursTime = scheduled_pick_up_time.split(':')[0];
            var add_5_min = scheduled_pick_up_time.split(':')[1];
            var sheduleTime = void 0;
            if (typeOfPickup == 'delayed') {
                sheduleTime = "".concat(hoursTime, ":").concat(Number(add_5_min) + 5);
            }
            else {
                sheduleTime = "".concat(hoursTime, ":").concat(Number(add_5_min) - 5);
            }
            var actual_pick = val.actual_pick_up_time.split(' ')[1];
            var filterAcuTimeHou = actual_pick.split(':')[0];
            var filterAcuTimeMin = actual_pick.split(':')[1];
            var actual_pick_up_time = "".concat(filterAcuTimeHou, ":").concat(filterAcuTimeMin);
            var startTime = moment(sheduleTime, 'hh:mm');
            var endTime = moment(actual_pick_up_time, 'hh:mm');
            var duration = moment.duration(endTime.diff(startTime));
            var hours = parseInt(duration.asHours());
            var minutes = parseInt(duration.asMinutes()) % 60;
            val.time_scheduled = "".concat(hours, ":").concat(minutes);
            totalSamples.push(val);
        }
    });
    console.log(totalSamples, 'sampleData');
    return totalSamples;
};
exports.getTimeDifferenceData = getTimeDifferenceData;
var filtertypeOfSampleData = function (response) {
    var samplesData = {
        totoalSample: [],
        delatedPickups: [],
        earlyPickups: [],
        on_TimePickps: [],
        x_axis_layouts: [],
        actual_labHandOver_sample: [],
    };
    var uniqueSlotes = [];
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.scheduled_lab_handover_time) &&
            (val === null || val === void 0 ? void 0 : val.actual_lab_handover_time) &&
            val.time_slot &&
            !(val === null || val === void 0 ? void 0 : val.scheduled_lab_handover_time.includes('T')) &&
            !(val === null || val === void 0 ? void 0 : val.actual_lab_handover_time.includes('T'))) {
            samplesData.totoalSample.push(val);
            var scheduled_lab_handover_time = val.scheduled_lab_handover_time.split(' ')[1];
            var actual_lab_handover = val.actual_lab_handover_time.split(' ')[1];
            var actual_lab_handover_time = actual_lab_handover.replace(':', '.');
            var hoursTime = scheduled_lab_handover_time.split(':')[0];
            var add_5_min = scheduled_lab_handover_time.split(':')[1];
            var startTime = "".concat(hoursTime, ".").concat(Number(add_5_min) - 5);
            var reduce_5_min = scheduled_lab_handover_time.split(':')[1];
            var endTime = "".concat(hoursTime, ".").concat(Number(reduce_5_min) + 5);
            if (actual_lab_handover_time >= startTime &&
                actual_lab_handover_time <= endTime) {
                val.type_of_smaple = exports.SAMPLE_TYPES.on_time;
                samplesData.on_TimePickps.push(val);
            }
            else if (actual_lab_handover_time >= startTime) {
                val.type_of_smaple = exports.SAMPLE_TYPES.delayed;
                samplesData.delatedPickups.push(val);
            }
            else {
                val.type_of_smaple = exports.SAMPLE_TYPES.early;
                samplesData.earlyPickups.push(val);
            }
        }
        if ((val === null || val === void 0 ? void 0 : val.actual_lab_handover_time) &&
            !(val === null || val === void 0 ? void 0 : val.actual_lab_handover_time.includes('T'))) {
            samplesData.actual_labHandOver_sample.push(val);
        }
    });
    var filterUniSlots = lodash_1.default.uniqBy(samplesData.totoalSample, 'time_slot');
    filterUniSlots === null || filterUniSlots === void 0 ? void 0 : filterUniSlots.map(function (val) {
        uniqueSlotes.push(val.time_slot);
    });
    samplesData.x_axis_layouts = uniqueSlotes.sort();
    var seriesSet = [];
    samplesData.x_axis_layouts.map(function (val) {
        var isMatched = false;
        var sampleData = [];
        samplesData.totoalSample.map(function (samObj) {
            if (val == samObj.time_slot) {
                isMatched = true;
                sampleData.push(samObj);
            }
        });
        if (isMatched) {
            var prePareSeriesSet = {
                time_slot: val,
                sampleData: sampleData,
            };
            seriesSet.push(prePareSeriesSet);
        }
    });
    var finalSeriesSetTableData = [];
    seriesSet.map(function (val) {
        var _a;
        var delayedData = [];
        var on_timeData = [];
        var earlyData = [];
        (_a = val.sampleData) === null || _a === void 0 ? void 0 : _a.map(function (samObj) {
            if (samObj.type_of_smaple == exports.SAMPLE_TYPES.on_time) {
                on_timeData.push(samObj);
            }
            else if (samObj.type_of_smaple == exports.SAMPLE_TYPES.delayed) {
                delayedData.push(samObj);
            }
            else {
                earlyData.push(samObj);
            }
        });
        finalSeriesSetTableData.push({
            timeSlot: val.time_slot,
            on_TimePickps: on_timeData,
            delatedPickups: delayedData,
            earlyPickups: earlyData,
        });
    });
    var totalInfoForSeriesSet = {
        x_axis_values: samplesData.x_axis_layouts,
        finalSeriesSetData: [],
        lab_trendSampleData: [],
        lab_trend_x_axis_values: [],
    };
    var delayedData = [];
    var on_timeData = [];
    var earlyData = [];
    var prePareSeriesSetForLabTrend = [];
    finalSeriesSetTableData.map(function (val) {
        delayedData.push({
            y: val.delatedPickups.length,
            custome: val.delatedPickups,
        });
        on_timeData.push({
            y: val.on_TimePickps.length,
            custome: val.on_TimePickps,
        });
        earlyData.push({
            y: val.earlyPickups.length,
            custome: val.earlyPickups,
        });
        var allSampleData = __spreadArray(__spreadArray(__spreadArray([], val.on_TimePickps, true), val.earlyPickups, true), val.delatedPickups, true);
        prePareSeriesSetForLabTrend.push({
            custome: allSampleData,
            y: allSampleData.length,
        });
    });
    totalInfoForSeriesSet.finalSeriesSetData.push({ name: 'ONTIME HANDOVER', data: on_timeData }, { name: 'EARLY HANDOVER', data: earlyData }, { name: 'DELAYED HANDOVER', data: delayedData });
    var labHandoverTrendData = function () {
        exports.LAB_HANDOVER_X_AXIS_VALUES.map(function (val) {
            var actual_lab_handover = val.time_slot.split('.')[0];
            for (var _i = 0, _a = samplesData.actual_labHandOver_sample; _i < _a.length; _i++) {
                var sample = _a[_i];
                var splitTimeFromData = sample.actual_lab_handover_time.split(' ')[1];
                var hourstTime = splitTimeFromData.toString().split(':')[0];
                if (Number(actual_lab_handover) == Number(hourstTime)) {
                    val.custome.push(sample);
                }
            }
        });
    };
    labHandoverTrendData();
    var filterLabTrendX_values = [];
    var filterLabHandoverSeriesSet = [];
    exports.LAB_HANDOVER_X_AXIS_VALUES.map(function (val) {
        val.y = val.custome.length;
        filterLabTrendX_values.push(val.time_slot);
        filterLabHandoverSeriesSet.push({
            y: val.custome.length,
            custome: val.custome,
        });
    });
    totalInfoForSeriesSet.lab_trend_x_axis_values = filterLabTrendX_values;
    totalInfoForSeriesSet.lab_trendSampleData = filterLabHandoverSeriesSet;
    return totalInfoForSeriesSet;
};
exports.filtertypeOfSampleData = filtertypeOfSampleData;
exports.downloadExcelHeaders = [
    'REFERENCE NUMBER',
    'LAB NUMBER',
    'TUBE TYPE',
    'TUBE COLOUR CODE',
    'TUBE COLOUR',
    'BAG ID',
    'PRECIOUS SAMPLE',
    'CURRENT STATUS',
    'REGISTRATION TIME',
    'REGISTRATION STATUS AT THE BAGGING OF THE TIME',
    'CURRENT REGISTRATION STATUS',
    'REGISTRATION SOURCE',
    'START PICKUP',
    'SCHEDULED PICK-UP TIME',
    'REACHED',
    'CLIENT LATITUDE',
    'CLIENT LONGITUDE',
    'BAG CREATION TIME',
    'BAG CREATED BY',
    'ACTUAL PICK-UP TIME',
    'TIME TAKEN IN PICK-UP',
    'PICKUP DELAYED',
    'SCHEDULED HUB HANDOVER TIME',
    'ACTUAL HUB HANDOVER TIME',
    'CONNECTOR FE CODE',
    'CONNECTOR FE NAME',
    'SCHEDULED LAB HANDOVER TIME',
    'ACTUAL LAB HANDOVER TIME',
    'LAB HANDOVER DELAYED',
    'RECEIVED AT LAB TIME',
    'NETWORK TYPE(FE TOWN/NON FE TOWN)',
    'ROUTE TYPE(FROM AX)',
    'VISIT TYPE',
    'VISIT ID',
    'UNASSIGNED BUCKET',
    'CLIENT CODE',
    'INVOICE CODE',
    'CLIENT TYPE',
    'CLIENT NAME',
    'CLIENT STATE',
    'CLIENT CITY',
    'PICKUP FE CODE',
    'PICKUP FE NAME',
    'PICKUP FE STATE',
    'PICKUP FE CITY',
    'LAB CODE',
    'LAB NAME',
    'ZONE',
];
var consvertIntracityReportData = function (response) {
    var convertedData = [];
    if (response.length > 0) {
        response.map(function (val) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20;
            var convertedObj = {
                reference_number: (_a = val.reference_number) !== null && _a !== void 0 ? _a : '',
                lab_number: (_b = val.lab_number) !== null && _b !== void 0 ? _b : '',
                tube_type: (_c = val.tube_type) !== null && _c !== void 0 ? _c : '',
                tube_colour_code: val.tube_colour_code,
                tube_color: (_d = val.tube_color) !== null && _d !== void 0 ? _d : '',
                bag_barcode: (_e = val.bag_barcode) !== null && _e !== void 0 ? _e : '',
                precious_sample: val.precious_sample ? true : false,
                status: (_f = val.status) !== null && _f !== void 0 ? _f : '',
                registration_timestamp: (_g = val.registration_timestamp) !== null && _g !== void 0 ? _g : '',
                registration_at_the_time_of_bagging: (_h = val.registration_at_the_time_of_bagging) !== null && _h !== void 0 ? _h : '',
                current_registration_status: val.current_registration_status,
                registration_source: (_j = val.registration_source) !== null && _j !== void 0 ? _j : '',
                fe_started_date_time: (_k = val.fe_started_date_time) !== null && _k !== void 0 ? _k : '',
                scheduled_pick_up_time: (_l = val.scheduled_pick_up_time) !== null && _l !== void 0 ? _l : '',
                fe_reached_date_time: (_m = val.fe_reached_date_time) !== null && _m !== void 0 ? _m : '',
                reached_latitude: (_o = val.reached_latitude) !== null && _o !== void 0 ? _o : '',
                reached_longitude: (_p = val.reached_latitude) !== null && _p !== void 0 ? _p : '',
                bag_creation_time: (_q = val.bag_creation_time) !== null && _q !== void 0 ? _q : '',
                bag_created_by: (_r = val.bag_created_by) !== null && _r !== void 0 ? _r : '',
                actual_pick_up_time: (_s = val.actual_pick_up_time) !== null && _s !== void 0 ? _s : '',
                pickup_time_taken: (_t = val.pickup_time_taken) !== null && _t !== void 0 ? _t : '',
                is_pickup_delay: (_u = val.is_pickup_delay) !== null && _u !== void 0 ? _u : null,
                scheduled_hub_handover_time: (_v = val.scheduled_hub_handover_time) !== null && _v !== void 0 ? _v : '',
                actual_hub_handover_time: (_w = val.actual_hub_handover_time) !== null && _w !== void 0 ? _w : '',
                connector_fe_code: (_x = val.connector_fe_code) !== null && _x !== void 0 ? _x : '',
                connector_fe_name: (_y = val.connector_fe_name) !== null && _y !== void 0 ? _y : '',
                scheduled_lab_handover_time: (_z = val.scheduled_lab_handover_time) !== null && _z !== void 0 ? _z : '',
                actual_lab_handover_time: (_0 = val.actual_lab_handover_time) !== null && _0 !== void 0 ? _0 : '',
                is_lab_handover_delay: (_1 = val.is_lab_handover_delay) !== null && _1 !== void 0 ? _1 : null,
                lab_receiving_timestamp: (_2 = val.lab_receiving_timestamp) !== null && _2 !== void 0 ? _2 : '',
                network_type: (_3 = val.network_type) !== null && _3 !== void 0 ? _3 : '',
                route_type: (_4 = val.route_type) !== null && _4 !== void 0 ? _4 : '',
                visit_type: (_5 = val.visit_type) !== null && _5 !== void 0 ? _5 : '',
                visit_ids: (_6 = val.visit_ids) !== null && _6 !== void 0 ? _6 : '',
                unassigned_bucket: (_7 = val.unassigned_bucket) !== null && _7 !== void 0 ? _7 : '',
                client_code: (_8 = val.client_code) !== null && _8 !== void 0 ? _8 : '',
                invoice_code: (_9 = val.invoice_code) !== null && _9 !== void 0 ? _9 : '',
                center_type: (_10 = val.center_type) !== null && _10 !== void 0 ? _10 : '',
                center_name: (_11 = val.center_name) !== null && _11 !== void 0 ? _11 : '',
                center_state: (_12 = val.center_state) !== null && _12 !== void 0 ? _12 : '',
                center_city: (_13 = val.center_city) !== null && _13 !== void 0 ? _13 : '',
                pickup_fe_code: (_14 = val.pickup_fe_code) !== null && _14 !== void 0 ? _14 : '',
                pickup_fe_name: (_15 = val.pickup_fe_name) !== null && _15 !== void 0 ? _15 : '',
                pickup_fe_state: (_16 = val.pickup_fe_state) !== null && _16 !== void 0 ? _16 : '',
                pickup_fe_city: (_17 = val.pickup_fe_city) !== null && _17 !== void 0 ? _17 : '',
                pickup_fe_lab_code: (_18 = val.pickup_fe_lab_code) !== null && _18 !== void 0 ? _18 : '',
                pickup_fe_lab_name: (_19 = val.pickup_fe_lab_name) !== null && _19 !== void 0 ? _19 : '',
                pickup_fe_zone: (_20 = val.pickup_fe_zone) !== null && _20 !== void 0 ? _20 : '',
            };
            convertedData.push(convertedObj);
        });
    }
    return convertedData;
};
exports.consvertIntracityReportData = consvertIntracityReportData;
var sampleTextColor = function (sampleName, color, percentage) {
    var colorName;
    if (sampleName == 'ONTIME HANDOVER') {
        if (percentage == 0) {
            colorName = color;
        }
        else {
            colorName = 'white';
        }
    }
    else {
        colorName = percentage == 0 ? color : 'black';
    }
    return colorName;
};
exports.sampleTextColor = sampleTextColor;
exports.R_LAB_P_LAB__HEADERS = [
    'BARCODE',
    'PROCESSING LAB CODE',
    'PROCESSING LAB NAME',
    'REGISTRATION LAB CODE',
    'REGISTRATION LAB NAME',
    'SHIPMENT CREATION DATE',
    'PICKUP DATE TIME',
    'HANDOVER DATE TIME',
    'STATUS',
];
var filterCumulativeForLabTrend = function (data) {
    var totalPecercentageData = [];
    if (data.length > 0) {
        var total_1 = 0;
        data.map(function (val) {
            total_1 += val.y;
        });
        data.map(function (val) {
            var percentage = (100 * val.y) / total_1;
            totalPecercentageData.push(percentage);
        });
        var cumulativeSet_2 = [];
        var percentageIno_2 = 0;
        totalPecercentageData.map(function (val) {
            percentageIno_2 = percentageIno_2 + val;
            cumulativeSet_2.push(Number(percentageIno_2.toFixed(1)));
        });
        console.log(cumulativeSet_2, 'cumulativeSet');
        return cumulativeSet_2;
    }
};
exports.filterCumulativeForLabTrend = filterCumulativeForLabTrend;
var filterRLabPLabResponse = function (response) {
    var sampleInformation = {
        activeSampele: [],
        timeDiffereceData: [],
    };
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.breach_by) && val.tat_breached) {
            sampleInformation.timeDiffereceData.push(val);
            sampleInformation.activeSampele.push(val);
        }
    });
    console.log(sampleInformation, 'timeDiffereceData');
    return sampleInformation;
};
exports.filterRLabPLabResponse = filterRLabPLabResponse;
var filterDataBasedOnTimeForRlabPlab = function (timeInfo) {
    var totalInfo = {
        oneHourData: [],
        twoHOursData: [],
        threeHourData: [],
        greaterThanThreeHour: [],
    };
    timeInfo.map(function (val) {
        if (Number(val.breach_by.split(':')[0]) == 0 &&
            Number(val.breach_by.split(':')[1]) <= 60) {
            totalInfo.oneHourData.push(val);
        }
        else if (Number(val.breach_by.split(':')[0]) == 1 &&
            Number(val.breach_by.split(':')[1]) <= 60) {
            totalInfo.twoHOursData.push(val);
        }
        else if (Number(val.breach_by.split(':')[0]) == 2 &&
            Number(val.breach_by.split(':')[1]) <= 60) {
            totalInfo.threeHourData.push(val);
        }
        else if (Number(val.breach_by.split(':')[0]) >= 3) {
            totalInfo.greaterThanThreeHour.push(val);
        }
    });
    var seriesData = [];
    var series = [
        { data: totalInfo.oneHourData, timeDifference: '60' },
        { data: totalInfo.twoHOursData, timeDifference: '120' },
        { data: totalInfo.threeHourData, timeDifference: '180' },
        { data: totalInfo.greaterThanThreeHour, timeDifference: '>180' },
    ];
    console.log(series, 'seriesSet');
    series.map(function (val) {
        var timeSet = val.timeDifference.split(':');
        var milllSec = +timeSet[0] * (60000 * 60) + +timeSet[1] * 60000;
        seriesData.push({
            timeDifference: val.timeDifference,
            totalSampleLength: val.data.length,
            millSec: milllSec,
            seriesSet: "".concat(milllSec, ",").concat(val.data.length),
            minutesData: val.data,
        });
    });
    console.log(seriesData, 'seriesData');
    var finalSeriesData = {};
    var filterSeriesData = [];
    var filterSeriesTableData = [];
    seriesData.map(function (val) {
        filterSeriesTableData.push(val.minutesData);
        return filterSeriesData.push([val.totalSampleLength]);
    });
    finalSeriesData = {
        seriesData: filterSeriesData,
        tableSeriesData: filterSeriesTableData,
    };
    console.log(finalSeriesData, 'finalSeriesData');
    return finalSeriesData;
};
exports.filterDataBasedOnTimeForRlabPlab = filterDataBasedOnTimeForRlabPlab;
var filterSeriesDataForR_lab_P_lab = function (responseData, setLifeCycleList, setTotalSample, setSeriesData, setCumulativeSeties) {
    var timeDiffData = (0, exports.filterRLabPLabResponse)(responseData);
    var convertedMinData = (0, exports.filterDataBasedOnTimeForRlabPlab)(timeDiffData.timeDiffereceData);
    var cumulativeList = (0, exports.filterCumulativeData)(convertedMinData.seriesData, timeDiffData.timeDiffereceData);
    setLifeCycleList(convertedMinData.tableSeriesData);
    setTotalSample(timeDiffData.timeDiffereceData.length);
    var isSeriesDataAvilable = false;
    if (convertedMinData.seriesData && convertedMinData.seriesData) {
        convertedMinData.seriesData.map(function (val) {
            if (val > 0) {
                isSeriesDataAvilable = true;
            }
        });
    }
    if (isSeriesDataAvilable) {
        setSeriesData(convertedMinData.seriesData);
    }
    else {
        setSeriesData([]);
    }
    setCumulativeSeties(cumulativeList);
};
exports.filterSeriesDataForR_lab_P_lab = filterSeriesDataForR_lab_P_lab;
var displayTableDataForR_labP_lab = function (xAxisVaues, setTableData, allSeriedData) {
    console.log(allSeriedData, 'allSeriedData');
    if (exports.x_axis_values_for_Rlab_p_lab[0] == xAxisVaues) {
        setTableData(allSeriedData[0]);
    }
    else if (exports.x_axis_values_for_Rlab_p_lab[1] == xAxisVaues) {
        setTableData(allSeriedData[1]);
    }
    else if (exports.x_axis_values_for_Rlab_p_lab[2] == xAxisVaues) {
        setTableData(allSeriedData[2]);
    }
    else if (exports.x_axis_values_for_Rlab_p_lab[3] == xAxisVaues) {
        setTableData(allSeriedData[3]);
    }
};
exports.displayTableDataForR_labP_lab = displayTableDataForR_labP_lab;
var convertRlabPlabData = function (response) {
    var convertedData = [];
    if (response.length > 0) {
        response.map(function (val) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
            var newOb = {
                master_barcode: (_a = val.master_barcode) !== null && _a !== void 0 ? _a : '',
                barcode: (_b = val.barcode) !== null && _b !== void 0 ? _b : '',
                r_lab_code: (_c = val.r_lab_code) !== null && _c !== void 0 ? _c : '',
                r_lab_name: (_d = val.r_lab_name) !== null && _d !== void 0 ? _d : '',
                r_lab_city: (_e = val.r_lab_city) !== null && _e !== void 0 ? _e : '',
                r_lab_state: (_f = val.r_lab_state) !== null && _f !== void 0 ? _f : '',
                scheduled_p_lab_code: (_g = val.scheduled_p_lab_code) !== null && _g !== void 0 ? _g : '',
                scheduled_p_lab_name: (_h = val.scheduled_p_lab_name) !== null && _h !== void 0 ? _h : '',
                scheduled_p_lab_city: (_j = val.scheduled_p_lab_city) !== null && _j !== void 0 ? _j : '',
                scheduled_p_lab_state: (_k = val.scheduled_p_lab_state) !== null && _k !== void 0 ? _k : '',
                actual_p_lab_code: (_l = val.actual_p_lab_code) !== null && _l !== void 0 ? _l : '',
                actual_p_lab_name: (_m = val.actual_p_lab_name) !== null && _m !== void 0 ? _m : '',
                actual_p_lab_city: (_o = val.actual_p_lab_city) !== null && _o !== void 0 ? _o : '',
                actual_p_lab_state: (_p = val.actual_p_lab_state) !== null && _p !== void 0 ? _p : '',
                shipment_creation_date: val.shipment_creation_date
                    ? (0, exports.dateTimeFormatterOutstationDownload)(val.shipment_creation_date)
                    : '',
                actual_pickup_time: val.actual_pickup_time
                    ? (0, exports.dateTimeFormatterOutstationDownload)(val.actual_pickup_time)
                    : '',
                actual_pickup_fe_code: (_q = val.actual_pickup_fe_code) !== null && _q !== void 0 ? _q : '',
                actual_pickup_fe_name: (_r = val.actual_pickup_fe_name) !== null && _r !== void 0 ? _r : '',
                actual_lab_handover_time: val.actual_lab_handover_time
                    ? (0, exports.dateTimeFormatterOutstationDownload)(val.actual_lab_handover_time)
                    : '',
                actual_handover_fe_code: (_s = val.actual_handover_fe_code) !== null && _s !== void 0 ? _s : '',
                actual_handover_fe_name: (_t = val.actual_handover_fe_name) !== null && _t !== void 0 ? _t : '',
                shipment_closure_time: val.shipment_closure_time
                    ? (0, exports.dateTimeFormatterOutstationDownload)(val.shipment_closure_time)
                    : '',
                lane: (_u = val.lane) !== null && _u !== void 0 ? _u : '',
                lane_route: (_v = val.lane_route) !== null && _v !== void 0 ? _v : '',
                pickup_cutoff_time: (_w = val.pickup_cutoff_time) !== null && _w !== void 0 ? _w : '',
                designated_hitting_time: (_x = val.designated_hitting_time) !== null && _x !== void 0 ? _x : '',
                tat: val.tat >= 0 ? val.tat : '',
                scheduled_tat: (_y = val.scheduled_tat) !== null && _y !== void 0 ? _y : '',
                actual_tat: (_z = val.actual_tat) !== null && _z !== void 0 ? _z : '',
                tat_breached: val.tat_breached ? (0, array_helpers_1.preciousSampleKey)(val.tat_breached) : '',
                breach_by: (_0 = val.breach_by) !== null && _0 !== void 0 ? _0 : '',
            };
            convertedData.push(newOb);
        });
    }
    return convertedData;
};
exports.convertRlabPlabData = convertRlabPlabData;
exports.downloadExcelHeadersForR_lab_Plab = [
    'MASTER BARCODE',
    'BARCODE',
    'R LAB CODE',
    'R LAB NAME',
    'R LAB CITY',
    'STATE',
    'SCHEDULED P LAB CODE',
    'SCHEDULED P LAB NAME',
    'SCHEDULED P LAB CITY',
    'SCHEDULED P LAB STATE',
    'ACTUAL P LAB CODE',
    'ACTUAL P LAB NAME',
    'ACTUAL P LAB CITY',
    'ACTUAL P LAB STATE',
    'SHIPMENT CREATION DATE',
    'ACTUAL PICKUP TIME',
    'ACTUAL PICKUP FECODE',
    'ACTUAL PICKUP FE NAME',
    'ACTUAL LAB HANDOVER TIME',
    'ACTUAL LAB HANDOVER FECODE',
    'ACTUAL LAB HANDOVER FE NAME',
    'ACTUAL LAB RECEIVING TIME',
    'LANE',
    'LANE ROUTE',
    'PICKUP CUTOFF TIME',
    'DESIGNATED HITTING TIME',
    'TAT IN DAYS',
    'SCHEDULED TAT',
    'ACTUAL TAT',
    'TAT BREACHED',
    'BREACH BY',
];
exports.downloadExcelHeadersOustStationHeaderReceived = [
    'Masterbox ID',
    'Hub Location',
    'To Location',
    'Created By',
    'Created By Client/FE Code',
    'Created By Client/FE Name',
    'Movement By',
    'Tagged Lab Code',
    'Tagged Lab Name',
    'Status',
    'Creation Date & Time',
    'Handover Date & Time',
    'Transit Time',
];
exports.downloadExcelHeadersOustStationPendingHeader = [
    'Masterbox ID',
    'Hub Location',
    'To Location',
    'Created By',
    'Created By Client/FE Code',
    'Created By Client/FE Name',
    'Movement By',
    'Tagged Lab Code',
    'Tagged Lab Name',
    'Status',
    'Creation Date & Time',
    'In Transit Since',
];
var dateTimeFormatterOutstationDownload = function (params) {
    var value = '--';
    return params ? moment(params).format('DD-MM-YYYY HH:mm:ss') : value;
};
exports.dateTimeFormatterOutstationDownload = dateTimeFormatterOutstationDownload;
var convertOustationDataRecieved = function (response) {
    var finalData = [];
    response.map(function (val) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var newOb = {
            barcode_id: (_a = val.barcode_id) !== null && _a !== void 0 ? _a : '',
            from_location: (_b = val.from_location) !== null && _b !== void 0 ? _b : '',
            to_location: (_c = val.to_location) !== null && _c !== void 0 ? _c : '',
            created_by_name: (_d = val.created_by_name) !== null && _d !== void 0 ? _d : '',
            created_by_id: (_e = val.created_by_id) !== null && _e !== void 0 ? _e : '',
            created_by_type: (_f = val.created_by_type) !== null && _f !== void 0 ? _f : '',
            movement_by: (_g = val.movement_by) !== null && _g !== void 0 ? _g : '',
            tagged_lab_code: val.tagged_lab_code,
            tagged_lab_name: (_h = val.tagged_lab_name) !== null && _h !== void 0 ? _h : '',
            status: (_j = val.status) !== null && _j !== void 0 ? _j : '',
            created_date_time: val.created_date_time
                ? (0, exports.dateTimeFormatterOutstationDownload)(val.created_date_time)
                : '',
            handover_date_time: val.handover_date_time
                ? (0, exports.dateTimeFormatterOutstationDownload)(val.handover_date_time)
                : '',
            in_transit_time: (_k = val.in_transit_time) !== null && _k !== void 0 ? _k : '',
        };
        finalData.push(newOb);
    });
    return finalData;
};
exports.convertOustationDataRecieved = convertOustationDataRecieved;
var convertOustationDataPending = function (response) {
    var finalData = [];
    response.map(function (val) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var newOb = {
            barcode_id: (_a = val.barcode_id) !== null && _a !== void 0 ? _a : '',
            from_location: (_b = val.from_location) !== null && _b !== void 0 ? _b : '',
            to_location: (_c = val.to_location) !== null && _c !== void 0 ? _c : '',
            created_by_name: (_d = val.created_by_name) !== null && _d !== void 0 ? _d : '',
            created_by_id: (_e = val.created_by_id) !== null && _e !== void 0 ? _e : '',
            created_by_type: (_f = val.created_by_type) !== null && _f !== void 0 ? _f : '',
            movement_by: (_g = val.movement_by) !== null && _g !== void 0 ? _g : '',
            tagged_lab_code: val.tagged_lab_code,
            tagged_lab_name: (_h = val.tagged_lab_name) !== null && _h !== void 0 ? _h : '',
            status: (_j = val.status) !== null && _j !== void 0 ? _j : '',
            created_date_time: val.created_date_time
                ? (0, exports.dateTimeFormatterOutstationDownload)(val.created_date_time)
                : '',
            in_transit_time: (_k = val.in_transit_time) !== null && _k !== void 0 ? _k : '',
        };
        finalData.push(newOb);
    });
    return finalData;
};
exports.convertOustationDataPending = convertOustationDataPending;
var filterDataBasedOnTimeOutStationBox = function (timeInfo) {
    var totalInfo = {
        lessThanSixHours: [],
        six_to_12_hours: [],
        TwelveTo_18_Hours: [],
        eightTo_24_Hours: [],
        greaterThan_24_hours: [],
    };
    timeInfo.map(function (val) {
        if (Number(val.in_transit_time.split(':')[0]) < 6) {
            totalInfo.lessThanSixHours.push(val);
        }
        else if (Number(val.in_transit_time.split(':')[0]) >= 6 &&
            Number(val.in_transit_time.split(':')[0]) < 12) {
            totalInfo.six_to_12_hours.push(val);
        }
        else if (Number(val.in_transit_time.split(':')[0]) >= 12 &&
            Number(val.in_transit_time.split(':')[0]) < 18) {
            totalInfo.TwelveTo_18_Hours.push(val);
        }
        else if (Number(val.in_transit_time.split(':')[0]) >= 18 &&
            Number(val.in_transit_time.split(':')[0]) < 24) {
            totalInfo.eightTo_24_Hours.push(val);
        }
        else if (Number(val.in_transit_time.split(':')[0]) >= 24) {
            totalInfo.greaterThan_24_hours.push(val);
        }
    });
    var seriesData = [];
    var series = [
        { data: totalInfo.lessThanSixHours, timeDifference: '6hr' },
        { data: totalInfo.six_to_12_hours, timeDifference: '12hr' },
        { data: totalInfo.TwelveTo_18_Hours, timeDifference: '18hr' },
        { data: totalInfo.eightTo_24_Hours, timeDifference: '>24hr' },
        { data: totalInfo.greaterThan_24_hours, timeDifference: '>24hr' },
    ];
    console.log(series, 'seriesSet');
    series.map(function (val) {
        var timeSet = val.timeDifference.split(':');
        var milllSec = +timeSet[0] * (60000 * 60) + +timeSet[1] * 60000;
        seriesData.push({
            timeDifference: val.timeDifference,
            totalSampleLength: val.data.length,
            millSec: milllSec,
            seriesSet: "".concat(milllSec, ",").concat(val.data.length),
            minutesData: val.data,
        });
    });
    console.log(seriesData, 'seriesData');
    var finalSeriesData = {};
    var filterSeriesData = [];
    var filterSeriesTableData = [];
    seriesData.map(function (val) {
        filterSeriesTableData.push(val.minutesData);
        return filterSeriesData.push([val.totalSampleLength]);
    });
    finalSeriesData = {
        seriesData: filterSeriesData,
        tableSeriesData: filterSeriesTableData,
    };
    console.log(finalSeriesData, 'finalSeriesData');
    return finalSeriesData;
};
exports.filterDataBasedOnTimeOutStationBox = filterDataBasedOnTimeOutStationBox;
var filterOutStationMasterBoxResponse = function (response) {
    var activeSampele = [];
    response.map(function (val) {
        console.log(val.in_transit_time, val.status, '____TESt');
        if ((val === null || val === void 0 ? void 0 : val.in_transit_time) && val.status == 'Handed over at lab') {
            activeSampele.push(val);
        }
    });
    return activeSampele;
};
exports.filterOutStationMasterBoxResponse = filterOutStationMasterBoxResponse;
var filterOutStationPendingMasterBoxResponse = function (response) {
    var activeSampele = [];
    response.map(function (val) {
        if ((val === null || val === void 0 ? void 0 : val.in_transit_time) && val.status != 'Handed over at lab') {
            activeSampele.push(val);
        }
    });
    return activeSampele;
};
exports.filterOutStationPendingMasterBoxResponse = filterOutStationPendingMasterBoxResponse;
var displayTableDataForOutStaionBox = function (xAxisVaues, setTableData, allSeriedData) {
    console.log(allSeriedData, 'allSeriedData', xAxisVaues);
    if (exports.x_axis_valuesForOutStation[0] == xAxisVaues) {
        setTableData(allSeriedData.tableSeriesData[0]);
    }
    else if (exports.x_axis_valuesForOutStation[1] == xAxisVaues) {
        setTableData(allSeriedData.tableSeriesData[1]);
    }
    else if (exports.x_axis_valuesForOutStation[2] == xAxisVaues) {
        setTableData(allSeriedData.tableSeriesData[2]);
    }
    else if (exports.x_axis_valuesForOutStation[3] == xAxisVaues) {
        setTableData(allSeriedData.tableSeriesData[3]);
    }
    else if (exports.x_axis_valuesForOutStation[4] == xAxisVaues) {
        setTableData(allSeriedData.tableSeriesData[4]);
    }
};
exports.displayTableDataForOutStaionBox = displayTableDataForOutStaionBox;
var Individual_fe_samples = function (activeSamples) {
    var createFeSample = [];
    if (activeSamples.length > 0) {
        var filterSampleByFEWise = lodash_1.default.uniqBy(activeSamples, 'pickup_fe_code');
        createFeSample = filterSampleByFEWise.map(function (val) {
            var _a, _b, _c;
            try {
                var newObj = {
                    lab_code: (_a = val.pickup_fe_lab_code) !== null && _a !== void 0 ? _a : '',
                    fe_code: (_b = val.pickup_fe_code) !== null && _b !== void 0 ? _b : '',
                    fe_name: (_c = val.pickup_fe_name) !== null && _c !== void 0 ? _c : '',
                    total_avg_pickupToHandOverTime: '',
                    no_of_fe_sampple: [],
                };
                for (var _i = 0, activeSamples_1 = activeSamples; _i < activeSamples_1.length; _i++) {
                    var sample = activeSamples_1[_i];
                    if (val.pickup_fe_code == sample.pickup_fe_code) {
                        newObj.no_of_fe_sampple = __spreadArray(__spreadArray([], newObj.no_of_fe_sampple, true), [sample], false);
                    }
                }
                return newObj;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    console.log(createFeSample, 'createFeSample');
    return createFeSample;
};
exports.Individual_fe_samples = Individual_fe_samples;
exports.INTRACITU_FE_LAT_TABLE_COLUMNS = [
    'LAB CODE',
    'PICKUP CODE',
    'PICKUP NAME',
    'AVERAGE OF FE PICKUP O HANDOVER TAT',
];
exports.INTRACITU_FE_ONTIME_PUPCKUPS_TABLE_COLUMNS = [
    'LAB CODE',
    'PICKUP CODE',
    'PICKUP NAME',
    'DELAYED PICKUP',
    'EARLY PICKUP',
    'ONTIME PICKUP',
    'GRAND TOTAL',
];
var convertIntracityFETatList = function (res) {
    var convertedData = [];
    if (res.length > 0) {
        res.map(function (val) {
            var newObj = {
                lab_code: val.lab_code,
                fe_code: val.fe_code,
                fe_name: val.fe_name,
                total_avg_pickupToHandOverTime: val.total_avg_pickupToHandOverTime,
            };
            convertedData.push(newObj);
        });
    }
    return convertedData;
};
exports.convertIntracityFETatList = convertIntracityFETatList;
var Individual_fe_samplesFor_onTImePickup = function (activeSamples) {
    var createFeSample = [];
    if (activeSamples.length > 0) {
        var filterSampleByFEWise = lodash_1.default.uniqBy(activeSamples, 'pickup_fe_code');
        createFeSample = filterSampleByFEWise.map(function (val) {
            var _a, _b, _c;
            try {
                var newObj = {
                    lab_code: (_a = val.pickup_fe_lab_code) !== null && _a !== void 0 ? _a : '',
                    fe_code: (_b = val.pickup_fe_code) !== null && _b !== void 0 ? _b : '',
                    fe_name: (_c = val.pickup_fe_name) !== null && _c !== void 0 ? _c : '',
                    delayed_percentage: '',
                    early_percentage: '',
                    ontime_percentage: '',
                    grandTotal_percentage: '',
                    delayed_list: [],
                    early_list: [],
                    ontime_list: [],
                    no_of_fe_sampple: [],
                };
                for (var _i = 0, activeSamples_2 = activeSamples; _i < activeSamples_2.length; _i++) {
                    var sample = activeSamples_2[_i];
                    if (val.pickup_fe_code == sample.pickup_fe_code) {
                        newObj.no_of_fe_sampple = __spreadArray(__spreadArray([], newObj.no_of_fe_sampple, true), [sample], false);
                        if (val.FE_sampleType == exports.SAMPLE_TYPES.delayed) {
                            newObj.delayed_list = __spreadArray(__spreadArray([], newObj.delayed_list, true), [sample], false);
                        }
                        else if (val.FE_sampleType == exports.SAMPLE_TYPES.early) {
                            newObj.early_list = __spreadArray(__spreadArray([], newObj.early_list, true), [sample], false);
                        }
                        else {
                            newObj.ontime_list = __spreadArray(__spreadArray([], newObj.ontime_list, true), [sample], false);
                        }
                    }
                }
                return newObj;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    console.log(createFeSample, 'createFeSamplePickp');
    return createFeSample;
};
exports.Individual_fe_samplesFor_onTImePickup = Individual_fe_samplesFor_onTImePickup;
var convertIntracityFEOntimePUPList = function (res) {
    var convertedData = [];
    if (res.length > 0) {
        res.map(function (val) {
            var _a, _b, _c, _d, _e, _f, _g;
            var newObj = {
                lab_code: (_a = val.lab_code) !== null && _a !== void 0 ? _a : '',
                fe_code: (_b = val.fe_code) !== null && _b !== void 0 ? _b : '',
                fe_name: (_c = val.fe_name) !== null && _c !== void 0 ? _c : '',
                delayed_percentage: (_d = val.delayed_percentage) !== null && _d !== void 0 ? _d : '',
                early_percentage: (_e = val.early_percentage) !== null && _e !== void 0 ? _e : '',
                ontime_percentage: (_f = val.ontime_percentage) !== null && _f !== void 0 ? _f : '',
                grandTotal_percentage: (_g = val.grandTotal_percentage) !== null && _g !== void 0 ? _g : '',
            };
            convertedData.push(newObj);
        });
    }
    return convertedData;
};
exports.convertIntracityFEOntimePUPList = convertIntracityFEOntimePUPList;
exports.LAB_HANDOVER_X_AXIS_VALUES = [
    {
        time_slot: '00.01-01.00',
        custome: [],
    },
    {
        time_slot: '01.01-02.00',
        custome: [],
    },
    {
        time_slot: '02.01-03.00',
        custome: [],
    },
    {
        time_slot: '03.01-04.00',
        custome: [],
    },
    {
        time_slot: '04.01-05.00',
        custome: [],
    },
    {
        time_slot: '05.01-06.00',
        custome: [],
    },
    {
        time_slot: '06.01-07.00',
        custome: [],
    },
    {
        time_slot: '07.01-08.00',
        custome: [],
    },
    {
        time_slot: '08.01-09.00',
        custome: [],
    },
    {
        time_slot: '09.01-10.00',
        custome: [],
    },
    {
        time_slot: '10.01-11.00',
        custome: [],
    },
    {
        time_slot: '11.01-12.00',
        custome: [],
    },
    {
        time_slot: '12.01-13.00',
        custome: [],
    },
    {
        time_slot: '13.01-14.00',
        custome: [],
    },
    {
        time_slot: '14.01-15.00',
        custome: [],
    },
    {
        time_slot: '15.01-16.00',
        custome: [],
    },
    {
        time_slot: '16.01-17.00',
        custome: [],
    },
    {
        time_slot: '17.01-18.00',
        custome: [],
    },
    {
        time_slot: '18.01-19.00',
        custome: [],
    },
    {
        time_slot: '19.01-20.00',
        custome: [],
    },
    {
        time_slot: '20.01-21.00',
        custome: [],
    },
    {
        time_slot: '21.01-22.00',
        custome: [],
    },
    {
        time_slot: '22.01-23.00',
        custome: [],
    },
    {
        time_slot: '23.01-00.00',
        custome: [],
    },
];
var outstationMasterBoxCumulative = function (data) {
    var cumulativeData = [];
    var initialValue = 0;
    data &&
        data.map(function (val) {
            initialValue = initialValue + Number(val);
            cumulativeData.push(initialValue);
        });
    return cumulativeData;
};
exports.outstationMasterBoxCumulative = outstationMasterBoxCumulative;
