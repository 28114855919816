"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Box_1 = require("@mui/material/Box");
var moment = require("moment");
var React = require("react");
var react_redux_1 = require("react-redux");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var array_helpers_1 = require("../../../utils/array_helpers");
var out_station_masterbox_metrics_1 = require("../graphs/out_station_masterbox_metrics");
var OutstationGraphFIltes = function () {
    var _a = React.useState(), updatedTime = _a[0], setUpdatedTIme = _a[1];
    var labList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).labList;
    var _b = React.useState(new Date().toString()), startDate = _b[0], setStartDate = _b[1];
    var _c = React.useState(false), isDateRangeUpdated = _c[0], setIsDateRangeUpdated = _c[1];
    var _d = React.useState(new Date().toString()), endDate = _d[0], setEndDate = _d[1];
    var _e = React.useState([]), labCode = _e[0], setLaboCode = _e[1];
    var _f = React.useState(false), filterButton = _f[0], setFilterButton = _f[1];
    var labCodeHandlere = function (selectedValues) {
        setLaboCode(selectedValues);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var FilterHandler = function () {
        setFilterButton(!filterButton);
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Intercity Outstation Master Box"))),
        React.createElement("div", { className: "orderlist-main-wrapper" },
            React.createElement("div", { className: "filter-menu-wrapper border-bottom" },
                React.createElement("div", { className: "filter-options auto-route" },
                    React.createElement(Box_1.default, { mr: 1 },
                        React.createElement(text_filed_with_fromto_Daterange_1.default, { presentDate: true, onChangeHandler: callBackOnDateRangeSelect })),
                    React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "Lab Code", dropDownList: labList &&
                            (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                                return a.label.localeCompare(b.label);
                            }), onChangeHnadlre: labCodeHandlere, value: labCode })),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }),
                    React.createElement(button_component_1.default, { buttonName: "Refresh", color: "yellow", endIcon: "icon-refresh", onClickHandler: function () { return setFilterButton(!filterButton); } }))),
            React.createElement(out_station_masterbox_metrics_1.default, { isDateRangeUpdated: isDateRangeUpdated, startDate: startDate, endDate: endDate, labCode: labCode, labList: labList, setUpdatedTIme: setUpdatedTIme, updatedTime: updatedTime, filterButton: filterButton }))));
};
exports.default = OutstationGraphFIltes;
