"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var TextField_1 = require("@mui/material/TextField");
var AdapterDateFns_1 = require("@mui/x-date-pickers/AdapterDateFns");
var DatePicker_1 = require("@mui/x-date-pickers/DatePicker");
var LocalizationProvider_1 = require("@mui/x-date-pickers/LocalizationProvider");
var React = require("react");
require("./input-field.scss");
var TextFiledWithDatepickerComponent = function (_a) {
    var type = _a.type, name = _a.name, placeholder = _a.placeholder, className = _a.className, value = _a.value, onChange = _a.onChange, style = _a.style, label = _a.label, error = _a.error, required = _a.required, isPastDisable = _a.isPastDisable, isFutureDisable = _a.isFutureDisable, minDate = _a.minDate, maxDate = _a.maxDate, isReadOnly = _a.isReadOnly, views = _a.views, isDisabled = _a.isDisabled, disableManulaEntry = _a.disableManulaEntry;
    var _b = React.useState(null), textFieldval = _b[0], setTextFieldVal = _b[1];
    var onChangeHandler = function (e) {
        console.log('inside onChangeHandler of datepicker', e);
        setTextFieldVal(e);
        onChange(e);
    };
    React.useEffect(function () {
        console.log(' inside textfield useeffect:', value);
        setTextFieldVal(value ? value : null);
    }, [value]);
    return (React.createElement("div", { className: required
            ? "input-wrapper validate-filed ".concat(className, " date-picker-wrapper")
            : "input-wrapper  ".concat(className, "  date-picker-wrapper") },
        React.createElement(LocalizationProvider_1.LocalizationProvider, { dateAdapter: AdapterDateFns_1.AdapterDateFns },
            React.createElement(DatePicker_1.DatePicker, { views: views, disabled: isDisabled, disablePast: isPastDisable ? isPastDisable : false, disableFuture: isFutureDisable ? isFutureDisable : false, minDate: minDate, maxDate: maxDate, label: label, value: textFieldval, onChange: onChangeHandler, readOnly: isReadOnly, inputFormat: "dd/MM/yyyy", renderInput: function (params) { return (React.createElement(TextField_1.default, __assign({ variant: "filled", disabled: true, onKeyDown: function (e) {
                        if (disableManulaEntry) {
                            e.preventDefault();
                        }
                    }, type: type, key: name, name: name, className: className, autoComplete: "off", helperText: error ? error : null, style: style, fullWidth: true }, params))); } }))));
};
exports.default = TextFiledWithDatepickerComponent;
