"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REACT_PAGINATION_CONSTANTS = void 0;
exports.REACT_PAGINATION_CONSTANTS = {
    PaginationClass: 'custom-pagination',
    disableClass: 'disabled-card',
    previousLabel: 'previous',
    nextLabel: 'next',
    marginPagesDisplayed: 3,
    pageRangeDisplayed: 3,
};
