"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_query_1 = require("react-query");
var drawer_1 = require("../../../../common/drawer/drawer");
var react_highchart_1 = require("../../../../common/high_charts/react_highchart");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../../reports/reports_helper");
var on_time_pickup_sample_list_1 = require("./on_time_pickup_sample_list");
var OnTimePickupGraphView = function (_a) {
    var endDate = _a.endDate, isDateRangeUpdated = _a.isDateRangeUpdated, startDate = _a.startDate, labList = _a.labList, labCode = _a.labCode, filterSelectedCities = _a.filterSelectedCities, filterSelectedStates = _a.filterSelectedStates, filterButton = _a.filterButton, setUpdatedTIme = _a.setUpdatedTIme;
    var _b = React.useState(null), Xaxis = _b[0], setXaxis = _b[1];
    var _c = React.useState([]), seriesData = _c[0], setSeriesData = _c[1];
    var _d = React.useState([]), cumulativeSeries = _d[0], setCumulativeSeties = _d[1];
    var _e = React.useState(false), open = _e[0], setOpen = _e[1];
    var _f = React.useState(undefined), tableData = _f[0], setTableData = _f[1];
    var _g = React.useState(0), totalPageCount = _g[0], setTotalPageCount = _g[1];
    var _h = React.useState(0), totalCount = _h[0], setTotalCount = _h[1];
    var _j = React.useState(1), pageCount = _j[0], setpageCount = _j[1];
    var _k = React.useState(), event = _k[0], setEvent = _k[1];
    var _l = React.useState(''), errorMessage = _l[0], setErrorMessage = _l[1];
    var closeHandlere = function () {
        setOpen(false);
    };
    var refetch = (0, react_query_1.useQuery)(['On-Time Pickup'], function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(labCode, labList);
            payload['lab_codes'] = selectedlabCode;
        }
        if (filterSelectedCities && filterSelectedCities.length > 0) {
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(filterSelectedCities);
            payload['states'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['cities'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        (0, reports_helper_1.OntimePickupGraph)(payload)
            .then(function (response) {
            if (response && response.data) {
                setErrorMessage('');
                setSeriesData(response.data.data.final_graph_response);
                setCumulativeSeties(response.data.data.cumulative);
                setXaxis(response.data.data.x_axis);
            }
        })
            .catch(function (err) {
            setErrorMessage(err);
            setSeriesData([]);
            setCumulativeSeties([]);
            setXaxis([]);
        });
    }).refetch;
    React.useEffect(function () {
        refetch();
        setUpdatedTIme(new Date().toLocaleString());
        setEvent();
    }, [filterButton]);
    var TableData = function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(labCode, labList);
            payload['lab_code'] = selectedlabCode;
        }
        if (filterSelectedCities && filterSelectedCities.length > 0) {
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(filterSelectedCities);
            payload['state'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['city'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        if (event) {
            payload['type'] = (0, array_helpers_1.convertOntimePickXaxis)(event);
        }
        payload['page'] = pageCount;
        payload['size'] = 50;
        (0, reports_helper_1.OnTimePickupSampleListApi)(payload)
            .then(function (response) {
            var pageSize = 50;
            var count = Math.ceil(response.data.data.count / pageSize);
            setTotalPageCount(count);
            if (response && response.data) {
                setTableData(response.data.data.result);
                setTotalCount(response.data.data.count);
            }
        })
            .catch(function (err) {
            setErrorMessage(err);
            setTableData([]);
        });
    };
    React.useEffect(function () {
        if (event) {
            TableData();
        }
    }, [event, pageCount]);
    return (React.createElement("div", null,
        seriesData && seriesData.length > 0 ? (React.createElement(react_highchart_1.default, { chartOptions: {
                chart: {
                    type: 'column',
                },
                title: {
                    text: null,
                },
                xAxis: {
                    labels: {
                        style: {
                            fontSize: 17,
                        },
                    },
                    categories: Xaxis,
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: 17,
                        },
                    },
                    stackLabels: {
                        enabled: false,
                        formatter: function () {
                            return this.total;
                        },
                        style: {
                            fontSize: 17,
                        },
                    },
                },
                colors: ['#0080ff', '#ffff00'],
                legend: {
                    itemStyle: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                    },
                    symbolWidth: 40,
                    symbolHeight: 10,
                    symbolRadius: 0,
                    squareSymbol: false,
                },
                tooltip: {
                    padding: 20,
                    style: {
                        fontSize: '1.1em',
                    },
                },
                credits: {
                    enabled: false,
                },
                plotOptions: {
                    column: {
                        borderRadius: 5,
                        pointPadding: 0.1,
                        pointWidth: 50,
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: 15,
                            },
                        },
                    },
                    series: {
                        lineWidth: 3,
                        cursor: 'pointer',
                        events: {
                            click: function (event) {
                                setEvent(event.point.category);
                                setOpen(true);
                            },
                            legendItemClick: function () {
                                return false;
                            },
                        },
                        cumulative: true,
                    },
                    line: {
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: '16px',
                            },
                        },
                        events: {
                            click: function () {
                                setOpen(false);
                            },
                        },
                    },
                },
                series: [
                    {
                        name: 'No of samples',
                        data: seriesData,
                    },
                    {
                        name: 'Cumulative',
                        type: 'line',
                        data: cumulativeSeries,
                    },
                ],
            } })) : (React.createElement("div", { className: "no-data-conatiner" },
            React.createElement("div", { className: "no-data-badge" },
                ' ',
                errorMessage ? errorMessage : 'No Data Available'))),
        React.createElement(drawer_1.default, { isOpend: open, closeModal: function () { return closeHandlere(); } },
            React.createElement(on_time_pickup_sample_list_1.default, { lifeCycleList: tableData, totalPageCount: totalPageCount, totalCount: totalCount, pageCount: pageCount, setpageCount: setpageCount, startDate: startDate, endDate: endDate, labCode: labCode, filterSelectedCities: filterSelectedCities, onTimeEvent: event, errorMessage: errorMessage }))));
};
exports.default = OnTimePickupGraphView;
