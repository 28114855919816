"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var button_names_1 = require("../../constants/button_names");
require("./btn.scss");
var StatusButton = function (_a) {
    var type = _a.type, variant = _a.variant, size = _a.size, style = _a.style, status = _a.status, className = _a.className, name = _a.name;
    var renderStatusbutton = function () {
        if (name === 'auditStatus') {
            switch (status) {
                case 0:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-active " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.draft)));
                case 1:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-active " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.created)));
                case 2:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-active " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.active)));
                case 3:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-exit " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.inacitve)));
                case 4:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-exit " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.deleted)));
                case 5:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-active " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.completed)));
                case 6:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-exit " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.incompleted)));
                default:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-emergency pending " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.pending)));
            }
        }
        else {
            switch (status) {
                case 0:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-active " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.draft)));
                case 1:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-active " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.active)));
                case 2:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-exit " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.inacitve)));
                default:
                    return (React.createElement("div", { className: "status-badge" },
                        React.createElement("span", { className: "icon-exit " }),
                        React.createElement("p", null, button_names_1.STATUS_BUTTONS.inacitve)));
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "payment-status-wrapper" }, renderStatusbutton())));
};
exports.default = StatusButton;
