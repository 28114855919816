"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Avatar_1 = require("@mui/material/Avatar");
var AvatarGroup_1 = require("@mui/material/AvatarGroup");
var colors_1 = require("@mui/material/colors");
var React = require("react");
var SelectedFEInList = function (_a) {
    var group = _a.group;
    var getCustomizedGroup = function (label) {
        switch (label) {
            case 'FE': return React.createElement(Avatar_1.default, { sx: { bgcolor: colors_1.orange[500] } }, "FE");
            case 'CC': return React.createElement(Avatar_1.default, { sx: { bgcolor: colors_1.lightBlue[500] } }, "CC");
            case 'CRE': return React.createElement(Avatar_1.default, { sx: { bgcolor: colors_1.lightGreen[500] } }, "CRE");
            case 'WEB': return React.createElement(Avatar_1.default, { sx: { bgcolor: colors_1.red[500] } }, "WEB");
        }
    };
    return (React.createElement("div", { className: "chips-wrapper avatr-group-wrapper" },
        React.createElement(AvatarGroup_1.default, { max: 4 }, group.map(function (selectedGrp) { return getCustomizedGroup(selectedGrp.value); }))));
};
exports.default = SelectedFEInList;
