"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalRoutePlannerGetAPI = exports.globalRoutePlannerPostAPI = void 0;
var api_service_1 = require("../../../../api/api_service");
var globalRoutePlannerPostAPI = function (endPoint, payLoad, domain) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: endPoint,
            domain: domain,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response, 'response');
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (error) {
            return reject(error);
        });
    });
};
exports.globalRoutePlannerPostAPI = globalRoutePlannerPostAPI;
var globalRoutePlannerGetAPI = function (endPoint, payLoad, domain) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: endPoint,
            domain: domain,
            payLoad: payLoad,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (error) {
            return reject(error);
        });
    });
};
exports.globalRoutePlannerGetAPI = globalRoutePlannerGetAPI;
