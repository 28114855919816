"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var tabs_component_1 = require("../../../../common/tabs/tabs_component");
var appConstants_1 = require("../../../../constants/appConstants");
var tabs_data_1 = require("../../../../constants/tabs_data");
var Pagenotfound_1 = require("../../../../ErrorBoundary/Pagenotfound");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var routes_list_1 = require("../../../../routes/routes_list");
var array_helpers_1 = require("../../../../utils/array_helpers");
var filters_component_1 = require("../../../field_exicutive/fe_list/filters_component");
require("../vendor-managment.scss");
var VendorListing = function () {
    var _a = React.useState(''), searchVlaue = _a[0], setSearchValue = _a[1];
    var _b = React.useState([]), selectedCities = _b[0], setSelectedCites = _b[1];
    var _c = React.useState(), cityList = _c[0], setCityList = _c[1];
    var _d = React.useState(), cityAndState = _d[0], setCityAndAState = _d[1];
    var _e = React.useState(false), isListReloaded = _e[0], setIsListReloaded = _e[1];
    var _f = React.useState(0), activeTab = _f[0], setActiveTab = _f[1];
    var _g = React.useState(false), isFilterButton = _g[0], setIsFilterButton = _g[1];
    var Vendorfeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.vendorManagement);
    console.log('Vendorfeatures:', Vendorfeatures);
    var searchData = function (event) {
        setSearchValue(event.target.value.trim());
    };
    var tabs = tabs_data_1.TABS_DATA.VENDOR_TABS_LIST;
    var vendorManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.vendorManagement);
    var filteredTabs = (0, array_helpers_1.disableTabs)(tabs, vendorManagementFeatures);
    var cityHandlere = function (value) {
        var cities = [];
        var cityAndStateData = [];
        value.map(function (val) {
            cities.push(val.id);
            console.log();
            cityAndStateData.push({
                city: val.label.split(',')[0],
                state: val.state_name,
            });
            setCityAndAState(cityAndStateData);
        });
        setSelectedCites(cities);
        setCityList(value);
    };
    var makeCityEmptyHandler = function () {
        setCityList([]);
        setSelectedCites([]);
    };
    var vendorManagementRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.vendorManagement, routes_list_1.VENDOR_MANGEMENT_LIST_SUB_ROUTES);
    var activeTabChangeHandler = function (index) {
        setActiveTab(index);
        setSearchValue('');
        setCityList([]);
        setSelectedCites([]);
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Vendor Management")),
            React.createElement("div", { className: "page-control-wrapper" })),
        React.createElement(filters_component_1.default, { placeHolder: "Search Vendor", searchValue: searchVlaue, searchHadler: searchData, cityHandlere: cityHandlere, citiesValues: cityList && cityList, makeCityEmpty: makeCityEmptyHandler, displayNoOfRowFiler: false, activetab: activeTab, setIsFilterButton: setIsFilterButton, isFilterButton: isFilterButton }),
        React.createElement("div", { className: "scrollable-content fe-managment-screen" },
            React.createElement(tabs_component_1.default, { tabList: filteredTabs, tabChangeHandler: activeTabChangeHandler }),
            React.createElement(react_router_dom_1.Routes, null,
                vendorManagementRoutes &&
                    vendorManagementRoutes.map(function (features) {
                        return (React.createElement(react_router_dom_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                                    isListReloaded: isListReloaded,
                                    setIsListReloaded: setIsListReloaded,
                                } },
                                React.createElement(features.component, { searchValue: searchVlaue, selectedCities: selectedCities, cityAndState: cityAndState, isFilterButton: isFilterButton, searchTerm: searchVlaue })) }));
                    }),
                React.createElement(react_router_dom_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) })))));
};
exports.default = VendorListing;
