"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var generic_api_calls_1 = require("../../components/generic_api_calls");
var images_1 = require("../../constants/images");
var fileUploadUtils_1 = require("../../utils/fileUploadUtils");
require("./file_upload.scss");
var FileUploadComponent = function (_a) {
    var buttonName = _a.buttonName, onChange = _a.onChange, variant = _a.variant, docName = _a.docName, requiredClass = _a.requiredClass, key = _a.key, style = _a.style, removeImage = _a.removeImage, value = _a.value, name = _a.name, acceptedFiletypes = _a.acceptedFiletypes, maxSize = _a.maxSize, minSize = _a.minSize, message = _a.message, isDisabled = _a.isDisabled;
    var hiddenFileInput = React.useRef(null);
    var _b = React.useState(''), previewImg = _b[0], setPreviewImage = _b[1];
    var _c = React.useState(''), fileName = _c[0], setFileName = _c[1];
    var handleClick = function (event) {
        hiddenFileInput.current.click();
    };
    var removeImageHandler = function () {
        setPreviewImage('');
        setFileName('');
        onChange('');
    };
    React.useEffect(function () {
        console.log('initial values in file upload component:', value, name, isDisabled);
        if (typeof value === 'string') {
            if (value != '') {
                (0, generic_api_calls_1.fetchImnageURLFromName)(value).then(function (res) {
                    var nameArr = value.split('/');
                    setFileName(nameArr[nameArr.length - 1]);
                    if (nameArr[nameArr.length - 1].includes('jpg') ||
                        nameArr[nameArr.length - 1].includes('png') ||
                        nameArr[nameArr.length - 1].includes('jfif') ||
                        nameArr[nameArr.length - 1].includes('jpeg')) {
                        setPreviewImage(res.data.data.url);
                    }
                    else if (nameArr[nameArr.length - 1].includes('pdf')) {
                        setPreviewImage(images_1.default.pdfImage);
                    }
                    else {
                        setPreviewImage(images_1.default.csvImage);
                    }
                });
            }
            else {
                setPreviewImage('');
                setFileName('');
            }
        }
        else if (typeof value === 'object' &&
            value != null) {
            if (value.type.includes('image')) {
                setPreviewImage(URL.createObjectURL(value));
            }
            else if (value.type.includes('pdf')) {
                setPreviewImage(images_1.default.pdfImage);
            }
            else {
                setPreviewImage(images_1.default.csvImage);
            }
            setFileName(value.name);
        }
    }, [value]);
    var handleChange = function (event) {
        var fileUploaded = event.target.files[0];
        var FileSize = (0, fileUploadUtils_1.getUploadedFileSize)(fileUploaded);
        var uploadedFileSize = FileSize / 1000 / 1000;
        var uploadedFileType = (0, fileUploadUtils_1.getUploadedFileType)(fileUploaded);
        var uploadedFileName = (0, fileUploadUtils_1.getUploadedFileName)(fileUploaded);
        if (acceptedFiletypes.includes(uploadedFileType)) {
            var maxfileSize = 0;
            if (uploadedFileType.includes('image')) {
                maxfileSize = maxSize.image;
            }
            else if (uploadedFileType.includes('pdf')) {
                maxfileSize = maxSize.pdf;
            }
            else {
                maxfileSize = maxSize.csv;
            }
            console.log('fileUploaded*************************', fileUploaded, acceptedFiletypes, uploadedFileSize, maxfileSize);
            if (uploadedFileSize <= maxfileSize) {
                onChange(fileUploaded);
                if (uploadedFileType.includes('image')) {
                    setPreviewImage(URL.createObjectURL(fileUploaded));
                }
                else if (uploadedFileType.includes('pdf')) {
                    setPreviewImage(images_1.default.pdfImage);
                }
                else {
                    setPreviewImage(images_1.default.csvImage);
                }
                setFileName(uploadedFileName);
            }
            else if (uploadedFileSize > maxfileSize) {
                react_hot_toast_1.default.error('File is too large');
                return false;
            }
        }
        else {
            react_hot_toast_1.default.error('Unsupported format');
            return false;
        }
    };
    return (React.createElement("div", { className: isDisabled
            ? 'attach-parent-wrapper doc_disabled'
            : 'attach-parent-wrapper' },
        React.createElement("div", { key: value && value.toString(), className: buttonName !== 'Upload Photo'
                ? 'file-upload-wrapper attach-file-wrapper'
                : 'file-upload-wrapper' },
            React.createElement("div", { className: "upload-inner-content" },
                previewImg ? (React.createElement("div", { className: "preview-assets-wrapper" },
                    React.createElement("div", { className: "asset-preview" },
                        React.createElement("img", { src: previewImg, alt: docName, loading: "lazy" }),
                        React.createElement("span", { className: "remove-image icon-close", onClick: removeImageHandler })),
                    React.createElement("div", { className: "cst-tooltip" },
                        React.createElement(material_1.Tooltip, { title: fileName },
                            React.createElement("p", { className: "asset-name-label" },
                                ' ', "".concat(fileName.substring(0, 15), " ").concat(fileName.length >= 15 ? '...' : '')))))) : (React.createElement("span", { className: "icon-photo_camera upoload-icon" })),
                !previewImg && (React.createElement(material_1.Button, { variant: "contained", className: " btn yellow-outline icon-btn", onClick: handleClick, disabled: isDisabled },
                    buttonName !== 'Upload Photo' && (React.createElement("span", { className: "icon-attach_file  btn-icon" })),
                    buttonName,
                    ' ',
                    requiredClass ? React.createElement("span", { className: "asterisk" }, "*") : '',
                    React.createElement("input", { hidden: true, accept: "image/*", type: "file", ref: hiddenFileInput, onChange: handleChange }))))),
        React.createElement("p", { className: "file-formate-label" }, message)));
};
exports.default = FileUploadComponent;
