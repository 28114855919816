"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterUnSelctedRegions = exports.filterRegionSelctedRegionModule = exports.filterSelctedRegions = exports.filterSelctedRegion = exports.filterRegionSelctedModule = exports.unSlectedEditData = exports.filterUnSelctedX_features = exports.filterSelctedX_features = exports.filterUnSelctedModule = exports.filterSelctedModule = void 0;
var filterSelctedModule = function (leftSideFeature, selectedModule) {
    var selectedData = leftSideFeature.filter(function (val) {
        return val.name == selectedModule;
    });
    return selectedData;
};
exports.filterSelctedModule = filterSelctedModule;
var filterUnSelctedModule = function (leftSideFeature, selectedModule) {
    var unSelectedData = leftSideFeature.filter(function (val) { return val.name != selectedModule; });
    return unSelectedData;
};
exports.filterUnSelctedModule = filterUnSelctedModule;
var filterSelctedX_features = function (selectedData, selectedIds, selectedModule) {
    var selectedX_features = selectedData[0].xFeature.filter(function (feature) { return selectedIds[selectedModule].includes(feature.id); });
    return selectedX_features;
};
exports.filterSelctedX_features = filterSelctedX_features;
var filterUnSelctedX_features = function (selectedData, selectedIds, selectedModule) {
    var unSelectedX_features = selectedData[0].xFeature.filter(function (feature) { return !selectedIds[selectedModule].includes(feature.id); });
    return unSelectedX_features;
};
exports.filterUnSelctedX_features = filterUnSelctedX_features;
var unSlectedEditData = function (array1, array2) {
    return array1.filter(function (object1) {
        return !array2.some(function (object2) {
            return object1.name === object2.name;
        });
    });
};
exports.unSlectedEditData = unSlectedEditData;
var filterRegionSelctedModule = function (leftSideFeature, selectedModule) {
    console.log(leftSideFeature, selectedModule, "kjhgfjhgfds");
    var selectedData = leftSideFeature.filter(function (val) { return val.name == selectedModule; });
    console.log(selectedData, 'selecjhgftedData');
    return selectedData;
};
exports.filterRegionSelctedModule = filterRegionSelctedModule;
var filterSelctedRegion = function (selectedData, selectedIds, selectedModule) {
    var selectedX_features = selectedData[0].filter(function (feature) { return selectedIds[selectedModule].includes(feature.fullName); });
    return selectedX_features;
};
exports.filterSelctedRegion = filterSelctedRegion;
var filterSelctedRegions = function (selectedData, selectedIds, selectedModule) {
    console.log(selectedData, selectedIds, selectedModule, 'selectedData');
    var selectedX_features = selectedData === null || selectedData === void 0 ? void 0 : selectedData.filter(function (feature) { var _a; return (_a = selectedIds[selectedModule]) === null || _a === void 0 ? void 0 : _a.includes(feature.id); });
    console.log(selectedX_features, 'selectedX_features');
    return selectedX_features;
};
exports.filterSelctedRegions = filterSelctedRegions;
var filterRegionSelctedRegionModule = function (leftSideFeature, selectedModule) {
    console.log(leftSideFeature, selectedModule, "kjhgfjhgfds");
    var selectedData = leftSideFeature.filter(function (val) {
        return val.fullName == selectedModule;
    });
    console.log(selectedData, 'selecjhgftedData');
    return selectedData;
};
exports.filterRegionSelctedRegionModule = filterRegionSelctedRegionModule;
var filterUnSelctedRegions = function (selectedData, selectedIds, selectedModule) {
    var unSelectedX_features = selectedData[0].filter(function (feature) { return !selectedIds[selectedModule].includes(feature.id); });
    return unSelectedX_features;
};
exports.filterUnSelctedRegions = filterUnSelctedRegions;
