"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var appConstants_1 = require("../../../../constants/appConstants");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var cre_api_calls_1 = require("../cre_api_calls");
var cre_popUp_1 = require("./cre_popUp");
var react_hot_toast_1 = require("react-hot-toast");
var CashReceivingIcons = function (_a) {
    var userData = _a.userData, isEditDisabled = _a.isEditDisabled;
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _b = React.useState(null), editData = _b[0], SeteditData = _b[1];
    var _c = React.useState(false), flag = _c[0], setFlag = _c[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.cre);
    var callBackToLoadlist = function (params) {
        UpdateListData.setIsListReloaded(true);
    };
    var onClickToEdit = function () {
        (0, cre_api_calls_1.fetchCreProfile)(userData === null || userData === void 0 ? void 0 : userData.id)
            .then(function (response) {
            SeteditData(response);
            setFlag(!flag);
        })
            .catch(function (err) {
            console.log(err.message);
            react_hot_toast_1.default.error(err.message);
        });
    };
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn", disabled: !features[menu_constants_1.FEATURE_CONSTANTS.updateCRE], onClick: onClickToEdit },
            React.createElement("span", { className: "icon-edit" })),
        React.createElement(cre_popUp_1.default, { editData: editData, SeteditData: SeteditData, editListUpdater: flag, callBackToLoadlist: callBackToLoadlist })));
};
exports.default = CashReceivingIcons;
