"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeToken = exports.saveToken = exports.login_with_sso = exports.generate_sso_token = exports.fetchVisitList = exports.getAuditStatus = exports.useDebounce = exports.fileUpload = exports.fetchHubList = exports.fetchAuditDetails = exports.fetchDraftedAuditList = exports.fetchAuditList = exports.validateMobileNumber1 = exports.validateMobileNumber = exports.validatePincode1 = exports.validatePincode = exports.fetchFEActiveData = exports.fetchImnageURLFromName = exports.fetchAuditorsUnderCC = exports.fetchAudienceUnderCitiesOrLabs = exports.fetchCitiesUnderStates = exports.fetchCities = exports.fetchStates = exports.fetchCountries = exports.fetchLabList = exports.fetchLabCodeData = exports.fetchConfigData = void 0;
var react_1 = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var ToastPosition = require("react-toastify");
var api_service_1 = require("../api/api_service");
var array_helpers_1 = require("../utils/array_helpers");
var fetchConfigData = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .get({
                    endPoint: api_service_1.api_service.api_urls.APP_CONFIG,
                    domain: process.env.VENDOR_BASE_URL,
                    customHeaders: {
                        'x-auth-token': token,
                    },
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        var res = [];
                        res.push(response.data.data);
                        return resolve(res);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    react_hot_toast_1.default.error(err.message, {
                        duration: 5 * 1000,
                        position: ToastPosition.toast.POSITION.TOP_RIGHT,
                    });
                    return reject(err.message);
                });
            })];
    });
}); };
exports.fetchConfigData = fetchConfigData;
var fetchLabCodeData = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                response = [];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4, api_service_1.api_service
                        .get({
                        endPoint: api_service_1.api_service.api_urls.LAB_LIST,
                        domain: process.env.BASE,
                    })
                        .then(function (res) {
                        if (res.status === 200) {
                            response.push(res.data.data);
                        }
                    })
                        .catch(function (e) {
                        return e;
                    })];
            case 2:
                _a.sent();
                return [3, 4];
            case 3:
                err_1 = _a.sent();
                return [2, err_1];
            case 4: return [2, response];
        }
    });
}); };
exports.fetchLabCodeData = fetchLabCodeData;
var fetchLabList = function (payload, token) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .post({
                    endPoint: api_service_1.api_service.api_urls.Lab_Master_List,
                    payLoad: payload,
                    domain: process.env.APPROVAL_BASEURL,
                    customHeaders: {
                        'x-auth-token': token,
                    },
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        var res = [];
                        res.push(response.data.data.result);
                        return resolve(res);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    react_hot_toast_1.default.error(err.message, {
                        duration: 5 * 1000,
                        position: ToastPosition.toast.POSITION.TOP_RIGHT,
                    });
                    return reject(err.message);
                });
            })];
    });
}); };
exports.fetchLabList = fetchLabList;
var fetchCountries = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .get({
                    endPoint: api_service_1.api_service.api_urls.COUNTRY,
                    domain: process.env.VENDOR_BASE_URL,
                    customHeaders: {
                        'x-auth-token': token,
                    },
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        var countryResponse = (0, array_helpers_1.convertLabelAndValueCountries)(response.data.data.countries);
                        var res = [];
                        res.push(countryResponse);
                        return resolve(res);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    react_hot_toast_1.default.error(err.message, {
                        duration: 5 * 1000,
                        position: ToastPosition.toast.POSITION.TOP_RIGHT,
                    });
                    return reject(err.message);
                });
            })];
    });
}); };
exports.fetchCountries = fetchCountries;
var fetchStates = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .get({
                    endPoint: api_service_1.api_service.api_urls.STATE,
                    domain: process.env.VENDOR_BASE_URL,
                    customHeaders: {
                        'x-auth-token': token,
                    },
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        var stateResponse = (0, array_helpers_1.convertLabelAndValueStates)(response.data.data.states);
                        var res = [];
                        res.push(stateResponse);
                        return resolve(res);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    react_hot_toast_1.default.error(err.message, {
                        duration: 5 * 1000,
                        position: ToastPosition.toast.POSITION.TOP_RIGHT,
                    });
                    return reject(err.message);
                });
            })];
    });
}); };
exports.fetchStates = fetchStates;
var fetchCities = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                response = [];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4, api_service_1.api_service
                        .get({
                        endPoint: api_service_1.api_service.api_urls.CITIES,
                        domain: process.env.VENDOR_BASE_URL,
                    })
                        .then(function (res) {
                        if (res.status === 200) {
                            var stateResponse = (0, array_helpers_1.convertLabelAndValueCity)(res.data.data.cities);
                            response.push(stateResponse);
                        }
                    })
                        .catch(function (e) {
                        return e;
                    })];
            case 2:
                _a.sent();
                return [3, 4];
            case 3:
                err_2 = _a.sent();
                return [2, err_2];
            case 4: return [2, response];
        }
    });
}); };
exports.fetchCities = fetchCities;
var fetchCitiesUnderStates = function (stateIds) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CITIES,
            payLoad: { state_id: stateIds && stateIds },
            showLoader: false,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                var cityResponse = [];
                if (res.data.data) {
                    if (res.data.data.cities) {
                        cityResponse = (0, array_helpers_1.convertLabelAndValueCity)(res.data.data.cities);
                    }
                }
                return resolve(cityResponse);
            }
            throw new Error('Unable to fetch cities list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.fetchCitiesUnderStates = fetchCitiesUnderStates;
var fetchAudienceUnderCitiesOrLabs = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.AUDIENCE_UNDER_CITIES,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                var ccResponse = [];
                if (res.data.data.cc_list.length > 0) {
                    ccResponse = (0, array_helpers_1.convertCCListFromResponse)(res.data.data.cc_list);
                }
                return resolve(ccResponse);
            }
            var erroMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.fetchAudienceUnderCitiesOrLabs = fetchAudienceUnderCitiesOrLabs;
var fetchAuditorsUnderCC = function (selectedAudities) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.AUDITORS_UNDER_CC,
            payLoad: {
                warehouse_codes: selectedAudities,
            },
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                var feResponse = [];
                if (res.data.data.result.length > 0) {
                    feResponse = (0, array_helpers_1.convertFEListFromResponse)(res.data.data.result);
                }
                return resolve(feResponse);
            }
            var erroMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.fetchAuditorsUnderCC = fetchAuditorsUnderCC;
var fetchImnageURLFromName = function (name) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.IMAGE_URL, "?name=").concat(name),
            domain: process.env.ASSET_UPLOAD_URL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            throw new Error('Unable to fetch cities list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.fetchImnageURLFromName = fetchImnageURLFromName;
var fetchFEActiveData = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.FE_LIST,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
            showLoader: true,
        })
            .then(function (res) {
            var feListResponse = (0, array_helpers_1.convertLabelAndValueFEList)(res.data.data.result);
            return resolve(feListResponse);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.fetchFEActiveData = fetchFEActiveData;
var validatePincode = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.VALIDATE_PINCODE,
            payLoad: payload,
            showLoader: false,
        })
            .then(function (res) {
            return resolve(res);
            throw new Error('Unable to fetch cities list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.validatePincode = validatePincode;
var validatePincode1 = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var awaitdPrmise;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, api_service_1.api_service
                    .post({
                    endPoint: api_service_1.api_service.api_urls.VALIDATE_PINCODE,
                    payLoad: payload,
                    showLoader: false,
                })
                    .then(function (res) {
                    if (res.data.message === 'Success') {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
                    .catch(function (e) {
                    return false;
                })];
            case 1:
                awaitdPrmise = _a.sent();
                return [2, awaitdPrmise];
        }
    });
}); };
exports.validatePincode1 = validatePincode1;
var validateMobileNumber = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.VALIDATE_MOBILENO),
            payLoad: payload,
        })
            .then(function (res) {
            return resolve(res);
            throw new Error('Unable to fetch cities list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.validateMobileNumber = validateMobileNumber;
var validateMobileNumber1 = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var awaitdPrmise;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, api_service_1.api_service
                    .get({
                    endPoint: "".concat(api_service_1.api_service.api_urls.VALIDATE_MOBILENO),
                    payLoad: payload,
                })
                    .then(function (res) {
                    if (res.data.message === 'Mobile does not exist') {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
                    .catch(function (e) {
                    return false;
                })];
            case 1:
                awaitdPrmise = _a.sent();
                return [2, awaitdPrmise];
        }
    });
}); };
exports.validateMobileNumber1 = validateMobileNumber1;
var fetchAuditList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.AUDIT_LIST,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data.data);
            }
            var errorMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(errorMessage);
        })
            .catch(function (e) {
            return reject(e);
        });
    });
};
exports.fetchAuditList = fetchAuditList;
var fetchDraftedAuditList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.AUDIT_LIST,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                var auditListRows_1 = [];
                var data = res.data.data.result;
                data === null || data === void 0 ? void 0 : data.map(function (audit) { return __awaiter(void 0, void 0, void 0, function () {
                    var auditRow;
                    return __generator(this, function (_a) {
                        auditRow = {
                            audit_id: audit._id ? audit._id : '---',
                            name: audit.name ? audit.name : '---',
                            description: audit.description,
                            status: audit.status,
                            questions_count: audit.questions_count ? audit.questions_count : '---',
                            created_by: audit.created_by ? audit.created_by : '---',
                            is_favourite: audit.is_favourite,
                            created_date_time: audit.created_date_time
                                ? audit.created_date_time
                                : null,
                        };
                        auditListRows_1.push(auditRow);
                        return [2];
                    });
                }); });
                return resolve(auditListRows_1);
            }
            throw new Error('Unable to fetch audit list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.fetchDraftedAuditList = fetchDraftedAuditList;
var fetchAuditDetails = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.AUDIT_DETAILS,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            throw new Error('Unable to fetch audit list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.fetchAuditDetails = fetchAuditDetails;
var fetchHubList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.HUBS_UNDER_LAB,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res);
            }
            throw new Error('Unable to fetch audit list!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.fetchHubList = fetchHubList;
var fileUpload = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .uploadFile({
            endPoint: api_service_1.api_service.api_urls.UPLOAD_FILE,
            payLoad: payload,
            domain: process.env.ASSET_UPLOAD_URL,
            headerType: 'multipart/form-data',
        })
            .then(function (res) {
            if (res.data.statusCode == 200) {
                return resolve(res);
            }
            var erroMessage = res.data
                ? res.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
            return reject(err.message);
        });
    });
};
exports.fileUpload = fileUpload;
function useDebounce(effect, dependencies, delay) {
    var callback = (0, react_1.useCallback)(effect, dependencies);
    (0, react_1.useEffect)(function () {
        var timeout = setTimeout(callback, delay);
        return function () { return clearTimeout(timeout); };
    }, [callback, delay]);
}
exports.useDebounce = useDebounce;
var getAuditStatus = function (status) {
    switch (status) {
        case '0':
            return 'DRAFT';
        case '1':
            return 'CREATED';
        case '2':
            return 'ACTIVE';
        case '5':
            return 'COMPLETED';
        case '6':
            return 'INCOMPLETED';
        default:
            return 'DRAFT';
    }
};
exports.getAuditStatus = getAuditStatus;
var fetchVisitList = function (endPoint, payLoad, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .post({
                    endPoint: endPoint,
                    payLoad: payLoad,
                    domain: domain,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        return resolve(response.data.data ? response.data.data : []);
                    }
                    var erroMessage = 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchVisitList = fetchVisitList;
var generate_sso_token = function (apiData) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get(apiData)
            .then(function (response) {
            return resolve(response);
        })
            .catch(function (err) {
            return reject(err.message);
        });
    });
};
exports.generate_sso_token = generate_sso_token;
var login_with_sso = function (apiData) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post(apiData)
            .then(function (response) {
            return resolve(response);
        })
            .catch(function (err) {
            return reject(err.message);
        });
    });
};
exports.login_with_sso = login_with_sso;
var saveToken = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.SAVE_TOKEN,
            domain: process.env.SIGN_IN_SIGN_OUT_URL,
            showLoader: true,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
            return reject(err);
        });
    });
};
exports.saveToken = saveToken;
var removeToken = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.REMOVE_TOKEN,
            domain: process.env.SIGN_IN_SIGN_OUT_URL,
            showLoader: true,
        })
            .then(function (res) {
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.removeToken = removeToken;
