"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../common/buttons/button_component");
var route_path_1 = require("../../constants/route_path");
var RegionUpdateButton = function (_a) {
    var data = _a.data, name = _a.name;
    var nav = (0, react_router_dom_1.useNavigate)();
    var EditHandler = function () {
        console.log('viewHandler clicked');
        nav("".concat(route_path_1.default.PATHS.region_create), { state: data });
    };
    return (React.createElement("div", null, name === 'region' ? React.createElement(material_1.IconButton, { className: "icon-btn", onClick: EditHandler, disabled: false },
        React.createElement("span", { className: "icon-edit" })) :
        React.createElement(button_component_1.default, { buttonName: 'Mapping', color: "yellow-outline", onClickHandler: EditHandler })));
};
exports.default = RegionUpdateButton;
