"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var map_constants_1 = require("../../constants/map_constants");
var track_fe_1 = require("../../constants/track_fe");
require("./stepper.scss");
var emptyValue = '--';
var OndemandStepperVertical = function (_a) {
    var clickAble = _a.clickAble, trackerInfo = _a.trackerInfo, distance = _a.distance;
    console.log(distance, 'distance');
    console.log(trackerInfo.length, distance, 'trackerInfo');
    return (React.createElement("div", { className: "stepper-wrapper" },
        React.createElement("ul", { className: "steps-list" }, (trackerInfo === null || trackerInfo === void 0 ? void 0 : trackerInfo.length) > 0 ? (trackerInfo === null || trackerInfo === void 0 ? void 0 : trackerInfo.map(function (data) {
            return (React.createElement("li", { className: "step-item" },
                React.createElement("div", { className: "step-content" },
                    React.createElement("div", { className: "steper-top-content displayflex" },
                        React.createElement("div", { className: "step-left-content displayflex" },
                            React.createElement("span", { className: "progress-status ".concat(data.visit_status === track_fe_1.VISIT_STATUS.Completed
                                    ? 'completed'
                                    : data.visit_status === track_fe_1.VISIT_STATUS.Started ||
                                        data.visit_status === track_fe_1.VISIT_STATUS.Reached
                                        ? 'inprogress'
                                        : 'next-step', " progress-indicator") }),
                            React.createElement("div", { className: "step-detials-wrapper" },
                                React.createElement("small", { className: "time-stamp-label" }, data.scheduled_start_time
                                    ? data.scheduled_start_time
                                    : emptyValue),
                                data.visit_type === map_constants_1.VIST_TYPE_INFO.PICKUP ? (React.createElement("span", { className: "badge time-badge pickup" }, map_constants_1.VISIT_TYPES_FOR_TRACING.cc_code)) : data.visit_type === map_constants_1.VIST_TYPE_INFO.HUB_RECEIVING ? (React.createElement("span", { className: "badge time-badge  handover-hub" }, map_constants_1.VISIT_TYPES_FOR_TRACING.hub_code)) : data.visit_type === map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB ? (React.createElement("span", { className: "badge time-badge handover-hub" }, map_constants_1.VISIT_TYPES_FOR_TRACING.handorver_hub)) : data.visit_type === map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ? (React.createElement("span", { className: "badge time-badge handover-hub" }, map_constants_1.VISIT_TYPES_FOR_TRACING.lab_Code)) : (React.createElement("span", { className: "badge time-badge handoverCash" }, map_constants_1.VISIT_TYPES_FOR_TRACING.hub_cash_code)),
                                data.visit_status === track_fe_1.VISIT_STATUS.Completed &&
                                    data.visit_type != map_constants_1.VIST_TYPE_INFO.CASH_HANDOVER ? (data.is_delayed === track_fe_1.TRACK_STATUS.onTime ? (React.createElement("span", { className: "badge time-badge ontime" }, "Ontime")) : (React.createElement("span", { className: "badge time-badge delayed" }, "Delayed"))) : null,
                                data.assignment_type === track_fe_1.TRACK_STATUS.ondemand ? (React.createElement("span", { className: "badge time-badge ondemand" }, "Ondemand")) : null,
                                React.createElement("h3", { className: "step-title" },
                                    data.warehouse_code
                                        ? data.warehouse_code
                                        : emptyValue,
                                    ' ',
                                    "-",
                                    data.center_name ? data.center_name : emptyValue),
                                React.createElement("p", { className: "step-deccription" }, data.center_address
                                    ? data.center_address
                                    : emptyValue))),
                        React.createElement("span", { className: "step-distance" },
                            distance,
                            " km")),
                    React.createElement("div", { className: "step-bottom-content displayflex" },
                        data.visit_status == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ? (React.createElement("p", { className: "badge-lg bags-badge" },
                            data.bag_count,
                            " Bags")) : null,
                        data.visit_status == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ? (React.createElement("p", { className: "badge-lg cash-badge" },
                            "\u20B9 ",
                            data.total_cash)) : null))));
        })) : (React.createElement(material_1.Stack, { direction: "row", justifyContent: "center", height: '59vh', alignItems: "center" },
            ' ',
            React.createElement("h2", null, "No Visits Found"))))));
};
exports.default = OndemandStepperVertical;
