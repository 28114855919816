"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var button_component_1 = require("../../../../common/buttons/button_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var profile_grid_1 = require("../../../../common/profile_grid/profile_grid");
var FECreationTabConstant_1 = require("../../../../constants/FECreationTabConstant");
var array_helpers_1 = require("../../../../utils/array_helpers");
require("../etrf.scss");
var EtrfDetails = function (_a) {
    var etrfProfileDetails = _a.etrfProfileDetails;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    return (React.createElement("div", { className: "user-tab etrf-tab" },
        React.createElement(Grid_1.default, { container: true, xs: 12, className: "user-profile-details-wrapper" },
            React.createElement(Grid_1.default, { item: true, xs: 8 },
                React.createElement("div", { className: "vendor-details-wrapper user-details" },
                    React.createElement("div", { className: "etrf-tab-details etrf-details-tab" },
                        React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 } },
                            React.createElement(Grid_1.default, { className: "tab-heading-wrapper ", item: true, xs: 12 },
                                React.createElement("div", { className: "tab-heaidng profile-detail-heaidng displayflex " },
                                    React.createElement("h2", null, "Basic"))),
                            React.createElement(profile_grid_1.default, { lableName: "Reference Number", value: etrfProfileDetails._id
                                    ? etrfProfileDetails._id
                                    : FECreationTabConstant_1.emptyDetails }),
                            React.createElement(profile_grid_1.default, { lableName: "Bar Code Number", value: etrfProfileDetails.etrf_barcode_id
                                    ? etrfProfileDetails.etrf_barcode_id
                                    : FECreationTabConstant_1.emptyDetails }),
                            React.createElement(profile_grid_1.default, { lableName: "City", value: etrfProfileDetails.city
                                    ? etrfProfileDetails.city
                                    : FECreationTabConstant_1.emptyDetails }),
                            React.createElement(profile_grid_1.default, { lableName: "Field Executive", value: etrfProfileDetails.fe_user
                                    ? etrfProfileDetails.fe_user
                                    : FECreationTabConstant_1.emptyDetails }),
                            React.createElement(profile_grid_1.default, { lableName: "Center Code", value: etrfProfileDetails.centre_code
                                    ? etrfProfileDetails.centre_code
                                    : FECreationTabConstant_1.emptyDetails }),
                            React.createElement(profile_grid_1.default, { lableName: "Status", value: etrfProfileDetails.status
                                    ? (0, array_helpers_1.convertETRFStatus)(etrfProfileDetails.status)
                                    : FECreationTabConstant_1.emptyDetails }),
                            React.createElement(profile_grid_1.default, { lableName: "Created On", value: etrfProfileDetails.created_date_time
                                    ? (0, array_helpers_1.normaldateandTimeFormatte)(etrfProfileDetails.created_date_time)
                                    : FECreationTabConstant_1.emptyDetails }),
                            React.createElement(profile_grid_1.default, { lableName: "Updated On", value: etrfProfileDetails.updated_date_time
                                    ? etrfProfileDetails.updated_date_time
                                    : FECreationTabConstant_1.emptyDetails }))))),
            React.createElement(Grid_1.default, { item: true, xs: 4 },
                React.createElement("div", { className: "etrf-tab-details etrf-docs-wrapper" },
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 } },
                        React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                            React.createElement("div", { className: "tab-heaidng profile-detail-heaidng displayflex " },
                                React.createElement("h2", null, "ETRF Images"))),
                        etrfProfileDetails.url ? (React.createElement(Grid_1.default, { item: true, xs: 9 },
                            React.createElement("div", { className: "user-profile-wrapper " },
                                React.createElement("small", { className: "user-label" }, "Manual TRF"),
                                React.createElement("div", { className: "user-value ertf-doc-image", onClick: handleOpen },
                                    React.createElement("img", { src: etrfProfileDetails.url, alt: "" }))))) : ('NO DOCUMENT UPLOADED'))))),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("h2", { className: "heading" }, "Image")),
            React.createElement("div", { className: "modal-body" },
                React.createElement("div", { className: "image-conatiner" },
                    React.createElement("img", { src: etrfProfileDetails.url }))),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Close", color: "yellow", onClickHandler: handleClose })))));
};
exports.default = EtrfDetails;
