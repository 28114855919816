"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var generic_api_calls_1 = require("../../components/generic_api_calls");
var array_helpers_1 = require("../../utils/array_helpers");
var AvatarComponent = function (_a) {
    var image = _a.image, fName = _a.fName, lName = _a.lName, className = _a.className;
    var feName = (0, array_helpers_1.concatFirstNameLastName)(fName, lName);
    var _b = React.useState(''), profileImageURL = _b[0], setProfileImageURL = _b[1];
    React.useEffect(function () {
        if (image != null && image != undefined && image != '') {
            (0, generic_api_calls_1.fetchImnageURLFromName)(image).then(function (res) {
                setProfileImageURL(res.data.data.url);
            });
        }
    }, []);
    return (React.createElement("div", { className: className },
        profileImageURL && profileImageURL ? (React.createElement(material_1.Avatar, { src: profileImageURL })) : (React.createElement(material_1.Avatar, null, feName && feName.charAt(0).toUpperCase())),
        feName ? React.createElement("span", null, feName) : React.createElement("span", null, "--")));
};
exports.default = AvatarComponent;
