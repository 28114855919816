"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCitiesList = void 0;
var api_service_1 = require("../../../../api/api_service");
var fetchCitiesList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CITIES,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            return resolve(response.data.data);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCitiesList = fetchCitiesList;
