"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var Stack_1 = require("@mui/material/Stack");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var modal_component_1 = require("../../../common/modal/modal_component");
var RoastersForMultipleDays_1 = require("../../../common/Roaster/RoastersForMultipleDays");
var time_slots_1 = require("../../../common/time_slot/time_slots");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var array_helpers_1 = require("../../../utils/array_helpers");
var search_fe_1 = require("./search_fe");
var slotblocking_apicalls_1 = require("./slotblocking.apicalls");
var slotblocking_helper_1 = require("./slotblocking.helper");
require("./slot_booking.scss");
var AddSlot = function () {
    var _a = React.useState([]), filterSelectedCities = _a[0], setSelectedFilterCities = _a[1];
    var fe_typesList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.fe_types; });
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var lablistInformation = (0, array_helpers_1.convertLabcodeResponse)(labList);
    console.log('fe_typesList', fe_typesList);
    var _b = React.useState([]), filteredFEs = _b[0], setFilteredFEs = _b[1];
    var _c = React.useState([]), weekDays = _c[0], setWeekDays = _c[1];
    var _d = React.useState([]), daySlotIds = _d[0], setDaySlotIds = _d[1];
    console.log('14slotIds', daySlotIds);
    var _e = React.useState([]), selecteFeIds = _e[0], setSelecteFeIds = _e[1];
    var hiddenInnerSubmitFormRef = React.useRef(null);
    var nav = (0, react_router_1.useNavigate)();
    var _f = React.useState(1), active = _f[0], setActive = _f[1];
    var pathname = window.location.pathname;
    var _g = React.useState(false), viewMode = _g[0], setViewMode = _g[1];
    var _h = React.useState(false), weekDaysModal = _h[0], setWeekDaysModal = _h[1];
    var _j = React.useState([]), feSlotDay = _j[0], setFeSlotDay = _j[1];
    var _k = React.useState(false), slotsLoad = _k[0], setSlotLoad = _k[1];
    var _l = React.useState('day1'), activeDayid = _l[0], setActiveDayid = _l[1];
    var _m = React.useState(false), timeSlotValidation = _m[0], setTimeSlotValidtion = _m[1];
    var _o = React.useState(null), selectedLab = _o[0], setSelectedLab = _o[1];
    var state = (0, react_router_1.useLocation)().state;
    var _p = React.useState(false), feOnchage = _p[0], setFeOnchange = _p[1];
    var _q = React.useState({
        day1: [],
        day2: [],
        day3: [],
        day4: [],
        day5: [],
        day6: [],
        day7: [],
    }), dayAndSlot = _q[0], setDayAndSlot = _q[1];
    var generatePayload = function () {
        var entries = Object.entries(dayAndSlot);
        var generatedEditPayload = entries.map(function (_a) {
            var key = _a[0], val = _a[1];
            var converteddayId = (0, array_helpers_1.convertDayIDIntoId)(key);
            var slots = val.filter(function (slot) {
                if ((slot === null || slot === void 0 ? void 0 : slot.color) === 'slot-btn status-selected')
                    return slot.id;
            });
            var slotIds = slots.map(function (e) {
                return e.id;
            });
            return {
                day: converteddayId,
                slots: slotIds,
            };
        });
        return generatedEditPayload;
    };
    var handleDayClick = function (id, day) {
        setActive(id);
        setActiveDayid((0, array_helpers_1.convertDayIntoDayid)(day));
    };
    var navigateTOListPage = function () {
        nav("/".concat(route_path_1.default.PATHS.slotBlocking));
    };
    var labHandler = function (value) {
        console.log('123dghs', value);
        setFilteredFEs([]);
        setSelecteFeIds([]);
        setSelectedLab(value);
    };
    var handleExternalButtonClick = function () {
        var isSlotSelected = generatePayload().some(function (each) { return each.slots.length > 0; });
        setTimeSlotValidtion(!isSlotSelected);
        hiddenInnerSubmitFormRef.current.click();
    };
    var callbackOnselectedFeType = function (val) {
        var weekDaysNull = weekDays === null || weekDays === void 0 ? void 0 : weekDays.map(function (e) {
            if (e.color) {
                e.color = null;
                return e;
            }
            return e;
        });
        setWeekDays(weekDaysNull);
        var selectedCityIds = [];
        filterSelectedCities.map(function (selectedCityObj) {
            selectedCityIds.push(selectedCityObj.short_code);
        });
        var payload = {
            onboard_status: ['Active'],
            city: selectedCityIds,
            role_id: val.id,
            lab_code: null,
        };
        if (selectedLab) {
            payload.lab_code = selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.value;
        }
        (0, slotblocking_apicalls_1.fetchFeOnSelectedFeType)(payload)
            .then(function (res) {
            setFilteredFEs((0, array_helpers_1.convertLabelAndValueFEList)(res));
        })
            .catch(function (e) { return console.log(e); });
    };
    var getSelectedFEDetails = function (payLoad) {
        (0, slotblocking_apicalls_1.fetchSelectedFeDeatils)(payLoad)
            .then(function (response) {
            setFeSlotDay(response === null || response === void 0 ? void 0 : response.data.data);
            if ((response === null || response === void 0 ? void 0 : response.data.data.length) === 0)
                react_hot_toast_1.default.error(response.data.message, { duration: 5000 });
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err);
        });
    };
    var callbackOnselectedFe = function (val) {
        setSelecteFeIds(val);
        var payload = { id: val[0].id };
        getSelectedFEDetails(payload);
        var isSlotSelected = generatePayload().some(function (each) { return each.slots.length > 0; });
        setTimeSlotValidtion(isSlotSelected);
    };
    var callBackToTimeSlots = function (dayAndSlotArray) {
        setDaySlotIds(dayAndSlotArray);
    };
    var callBackToTime = function () {
        var isSlotSelected = generatePayload().some(function (each) { return each.slots.length > 0; });
        setTimeSlotValidtion(!isSlotSelected);
    };
    var formSubmitHandler = function (params) {
        var generatedEditPayload = generatePayload();
        var isSlotSelected = generatedEditPayload.some(function (each) { return each.slots.length > 0; });
        var postFeIds = selecteFeIds.map(function (each) {
            return each.id;
        });
        var payload = {
            schedule_slots: generatedEditPayload,
            user_id: state ? [state.id] : postFeIds,
        };
        if (isSlotSelected) {
            (0, slotblocking_apicalls_1.submitFeTimeSlot)(payload)
                .then(function (res) {
                if (res.statusCode == 200) {
                    react_hot_toast_1.default.success(res.message);
                    navigateTOListPage();
                }
            })
                .catch(function (err) {
                console.log(err);
                react_hot_toast_1.default.error(err);
            });
        }
    };
    var FiltersDropdownsCall = function (setFieldValue, values, errors, touched) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                React.createElement(drop_down_component_1.default, { isDisabled: viewMode, disableClearable: true, name: "lab", placeholder: "Lab Code", dropDownList: lablistInformation, onChange: function (val) {
                        setFieldValue('selectedFe', []);
                        setFieldValue('selectedFes', '');
                        setFieldValue('fetype', '');
                        setFieldValue('labcode', val);
                        labHandler(val);
                    }, value: viewMode ? state === null || state === void 0 ? void 0 : state.lab : selectedLab || null, required: true }),
                errors['labcode'] && touched['labcode'] ? (React.createElement("div", { className: "error" },
                    errors['labcode'],
                    " ")) : null),
            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                React.createElement(drop_down_component_1.default, { disableClearable: true, isDisabled: viewMode, name: "fetype", value: viewMode ? state === null || state === void 0 ? void 0 : state.role : values.fetype, dropDownList: fe_typesList, onChange: function (val) {
                        setFeOnchange(!feOnchage);
                        setFieldValue('selectedFe', []);
                        setFieldValue('selectedFes', '');
                        setFilteredFEs([]);
                        setSelecteFeIds([]);
                        setFieldValue('fetype', val);
                        callbackOnselectedFeType(val);
                    }, placeholder: "FE Type" }),
                errors['fetype'] && touched['fetype'] ? (React.createElement("div", { className: "error" },
                    errors['fetype'],
                    " ")) : null),
            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                React.createElement(drop_down_component_1.default, { isDisabled: viewMode, placeholder: "Select FE", name: "selectedFes", dropDownList: filteredFEs, onChange: function (val) {
                        setFeOnchange(!feOnchage);
                        setFieldValue('selectedFes', val);
                        callbackOnselectedFe([val]);
                    }, value: viewMode ? state === null || state === void 0 ? void 0 : state.feName : values.selectedFes, required: true }),
                errors['selectedFes'] && touched['selectedFes'] ? (React.createElement("div", { className: "error" },
                    errors['selectedFes'],
                    " ")) : null)));
    };
    var SelectedFeScreenCall = function (setFieldValue) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { xs: 6, lg: 6, item: true },
                React.createElement(search_fe_1.default, { selecteFeIds: selecteFeIds, setSelecteFeIds: setSelecteFeIds, viewMode: viewMode, setFieldValue: setFieldValue, setFeOnchange: setFeOnchange, feOnchage: feOnchage }))));
    };
    var WeekDayComponentCall = function (errors, touched) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true },
                React.createElement("div", { className: "days-wrapper" },
                    React.createElement("h2", { className: "selected-felabel custom-form-label" }, "Select FE Working Hours"),
                    errors['weekDays'] && touched['weekDays'] ? (React.createElement("div", { className: "error" },
                        errors['weekDays'],
                        " ")) : null,
                    React.createElement(Stack_1.default, { direction: "row", alignItems: "center", justifyContent: "space-between", width: "100%" },
                        React.createElement("div", { className: "days-list-wrapper badge-list-hr" }, weekDays === null || weekDays === void 0 ? void 0 : weekDays.map(function (weekDaysDetails) {
                            return (React.createElement(button_component_1.default, { buttonName: weekDaysDetails.day, color: weekDaysDetails.id === active
                                    ? 'slot-btn day-badge selcted'
                                    : 'slot-btn day-badge', onClickHandler: function () {
                                    handleDayClick(weekDaysDetails.id, weekDaysDetails.day);
                                } }));
                        })),
                        React.createElement("div", null, ModalComponentCall()))))));
    };
    var TimeSlotCall = function (setFieldValue, touched, errors) {
        return (React.createElement(React.Fragment, null,
            errors['selectedSlots'] && touched['selectedSlots'] ? (React.createElement("div", { className: "error" },
                errors['selectedSlots'],
                " ")) : null,
            React.createElement(time_slots_1.default, { name: "selectedSlots", onHandler: callBackToTimeSlots, onChange: function (val) {
                    callBackToTime();
                }, active: active, feSlotDay: feSlotDay, slotsLoad: slotsLoad, dayAndSlot: dayAndSlot, setDayAndSlot: setDayAndSlot, viewMode: viewMode, selectedLab: selectedLab, setTimeSlotValidtion: setTimeSlotValidtion, feOnchage: feOnchage, setActive: setActive }),
            timeSlotValidation ? (React.createElement("div", { className: "error" },
                'Please Select Time Slots',
                " ")) : null));
    };
    var appForAlldaysModal = function () {
        var selectedDaySlots = dayAndSlot[activeDayid];
        var slotsSelected = [];
        selectedDaySlots.filter(function (each) {
            if (each.color === 'slot-btn status-selected') {
                slotsSelected.push(each);
            }
        });
        if (slotsSelected.length > 0) {
            setWeekDaysModal(!weekDaysModal);
        }
        else {
            react_hot_toast_1.default.error('Please Select atleast one slot', {
                duration: 5000,
            });
        }
    };
    var modalCloseHandler = function () {
        setWeekDaysModal(false);
    };
    var weekDaysSubmitHandler = function (selectedModalDays) {
        var selectedDaySlots = dayAndSlot[activeDayid];
        console.log('999999', selectedDaySlots);
        var obj = {};
        var k = JSON.parse(JSON.stringify(dayAndSlot));
        console.log('selectedModalDays', selectedModalDays);
        selectedModalDays === null || selectedModalDays === void 0 ? void 0 : selectedModalDays.forEach(function (week) {
            var arr = [];
            selectedDaySlots.map(function (each) {
                arr.push(each);
                k[week] = JSON.parse(JSON.stringify(dayAndSlot[activeDayid]));
            });
            obj[week] = arr;
        });
        console.log('9999 k');
        setDayAndSlot(k);
        react_hot_toast_1.default.success('Slots have been added succesfully for Selected Days', {
            duration: 2000,
        });
        appForAlldaysModal();
    };
    var ModalComponentCall = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(modal_component_1.default, { buttonName: "Apply For Multiple Days", buttonColor: "yellow-outline", onClickHandler: appForAlldaysModal, open: weekDaysModal, modalSize: "long-modal", isBtnDisabled: viewMode == 'view' ? true : false },
                React.createElement(RoastersForMultipleDays_1.default, { weekDaysSubmitHandler: function (selectedDays) { return weekDaysSubmitHandler(selectedDays); }, weekDays: weekDays, cancelhandler: modalCloseHandler }))));
    };
    var SubmitButtonCall = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { type: "submit", ref: hiddenInnerSubmitFormRef, className: "display_hide" })));
    };
    React.useEffect(function () {
        if (pathname.includes('addslot/view'))
            setViewMode('view');
        else if (pathname.includes('addslot/edit'))
            setViewMode('edit');
        else
            setViewMode(false);
    }, [pathname]);
    React.useEffect(function () {
        if (viewMode) {
            var payload = { id: state.id };
            getSelectedFEDetails(payload);
            setSelecteFeIds([state === null || state === void 0 ? void 0 : state.feName]);
            setSelectedFilterCities([state === null || state === void 0 ? void 0 : state.city]);
            setSlotLoad(!slotsLoad);
        }
    }, [viewMode]);
    React.useEffect(function () {
        (0, slotblocking_apicalls_1.getAllWeekDays)()
            .then(function (response) {
            setWeekDays(response.data);
        })
            .catch(function (e) { return console.log(e); });
    }, []);
    React.useEffect(function () {
        if (selectedLab) {
            callbackOnselectedFeType('');
        }
    }, [selectedLab]);
    return (React.createElement("div", { className: "block-slot-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTOListPage }),
                React.createElement("h2", null, viewMode
                    ? viewMode == 'view'
                        ? slotblocking_helper_1.FeRoaster_Labels.viewSlot
                        : slotblocking_helper_1.FeRoaster_Labels.editSlot
                    : slotblocking_helper_1.FeRoaster_Labels.addSlot)),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateTOListPage }),
                React.createElement(button_component_1.default, { type: "submit", id: "myForm", onClickHandler: handleExternalButtonClick, buttonName: "Submit", color: "yellow", isError: viewMode == 'view' ? true : false }))),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement("div", { className: "add-slotform" },
                React.createElement(formik_1.Formik, { initialValues: {
                        state: '',
                        city: '',
                        date: '',
                        fetype: null,
                        selectedFe: [],
                        weekDays: null,
                        selectedSlots: '',
                        labcode: null,
                        selectedFes: null,
                    }, "enableReinitialize:true": true, validationSchema: viewMode ? false : schema_1.schemas.FE_SlotBooking_Schema, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                    return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                        SubmitButtonCall(),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                            FiltersDropdownsCall(setFieldValue, values, errors, touched),
                            selecteFeIds.length !== 0 && (React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true }, SelectedFeScreenCall(setFieldValue))),
                            React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true }, WeekDayComponentCall(errors, touched)),
                            React.createElement(Grid_1.default, { item: true, xs: 14, lg: 12 }, TimeSlotCall(setFieldValue, touched, errors))),
                        React.createElement("div", { className: "cc-slot-legend align-center" },
                            React.createElement("div", { className: "legend-item bg-available" }, "Available"),
                            React.createElement("div", { className: "legend-item bg-selected" }, "Selected"))));
                })))));
};
exports.default = AddSlot;
