"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCF_MANGAGEMEN_BUTTON_ROUES = exports.SCF_MANGAGEMENT_SUB_ROUTES = exports.ORC_MANGAGEMEN_BUTTON_ROUES = exports.ORC_MANGAGEMENT_SUB_ROUTES = exports.SHIPMENT_MASTER_ROUTES = exports.FE_LIVE_TRAKING_ROUTES = exports.REGION_BUTTON_ROUES = exports.REGION_REPORT_SUB_ROUES = exports.CLIENT_SLOTWISE_REPORT_SUB_ROUTES = exports.SHIPMENT_TRACKING_ROUTES = exports.INTERCITY_GRAPH_REPORT_ROUTES = exports.INTRACITY_TAT_GRAPH_REPORT_BUTTON_ROUTES = exports.INTRACITY_GRAPH_REPORT_ROUTES = exports.SSO_MY_REPORTS_ROUTES = exports.VISITS_REPORT_SUB_ROUES = exports.MASTERBOX_TRACKING_ROUTES = exports.HUB_MASTER_ROUTES = exports.LAB_MASTER_ROUTES = exports.USER_LOGISTIC_ROUTES = exports.ROUTE_PLANNER_ROUTES = exports.CC_ROUSTER_BUTTONS = exports.REPORTS_BUTTTON_ROUES = exports.MY_REPORTS_ROUES = exports.CONTROL_TOWER_BUTTTON_ROUES = exports.MIST_SUB_ROUES = exports.CRE_SUB_ROUES = exports.HEAT_MAP_SUB_ROUES = exports.VISITS_BUTTON_ROUES = exports.PRICIOUS_BUTTON_ROUES = exports.ETRF_SUB_ROUTES = exports.ETRF_BUTTON_ROUES = exports.NOTIFICATIONS_BUTTON_ROUES = exports.SAMPLE_TRACKING_BUTTON_ROUES = exports.AUDIT_BUTTONS_ROUTES = exports.VENDOR_APPROVAL_BUTTON_FEATURES_ROUTES = exports.FE_APPROVAL_BUTTON_FEATURES_ROUTES = exports.APPROVAL_MANGEMENT_VENDOR_LIST_SUB_ROUTES = exports.APPROVAL_MANGEMENT_FE_LIST_SUB_ROUTES = exports.VENDOR_MANGEMENT_BUTTON_FEATURE_ROUTES = exports.VENDOR_MANGEMENT_LIST_SUB_ROUTES = exports.ROLE_MANAGEMNT_ROUTES = exports.ROUTESR_MANAGEMNT_ROUTES = exports.FE_PROFILE_DETIALS_SUB_ROUTES = exports.FE_MANGEMENT_BUTTON_FEATURE_ROUTES = exports.FE_MANGEMENT_LIST_SUB_ROUTES = exports.ROUTE_LIST = void 0;
var fe_details_1 = require("../components/field_exicutive/details/fe_details");
var fe_kpis_1 = require("../components/field_exicutive/details/fe_kpis");
var fe_profile_details_1 = require("../components/field_exicutive/details/fe_profile_details");
var fe_schedule_details_1 = require("../components/field_exicutive/details/fe_schedule_details");
var all_fe_list_1 = require("../components/field_exicutive/fe_list/all_fe_list");
var fe_drafted_list_1 = require("../components/field_exicutive/fe_list/fe_drafted_list");
var fe_exited_list_1 = require("../components/field_exicutive/fe_list/fe_exited_list");
var fe_inActive_list_1 = require("../components/field_exicutive/fe_list/fe_inActive_list");
var fe_inventory_pending_1 = require("../components/field_exicutive/fe_list/fe_inventory_pending");
var fe_rejected_list_1 = require("../components/field_exicutive/fe_list/fe_rejected_list");
var new_fe_approval_1 = require("../components/field_exicutive/fe_list/new_fe_approval");
var on_leave_today_1 = require("../components/field_exicutive/fe_list/on_leave_today");
var fe_live_tracking_details_1 = require("../components/field_exicutive/fe_live_tracking/details/fe_live_tracking_details");
var fe_live_tracking_filters_1 = require("../components/field_exicutive/fe_live_tracking/list/fe_live_tracking_filters");
var fe_management_1 = require("../components/field_exicutive/fe_management");
var FE_Registration_1 = require("../components/field_exicutive/Fe_registration/FE_Registration");
var leave_tracking_1 = require("../components/field_exicutive/leave_tracking/leave_tracking");
var add_slot_1 = require("../components/field_exicutive/slot_blocking/add_slot");
var slot_blocking_1 = require("../components/field_exicutive/slot_blocking/slot_blocking");
var Create_lab_master_1 = require("../components/Lab_Master/Create_lab_master");
var lab_list_1 = require("../components/Lab_Master/lab_list");
var MasterBoxDetailsScreen_1 = require("../components/Master_box_tracking/MasterBoxDetailsScreen");
var MasterBoxTracking_1 = require("../components/Master_box_tracking/MasterBoxTracking");
var CreateNotification_1 = require("../components/Notification_management/CreateNotification");
var NotificationList_1 = require("../components/Notification_management/NotificationList");
var Create_Region_1 = require("../components/RegionMapping/Create_Region");
var Region_1 = require("../components/RegionMapping/Region");
var RegionList_1 = require("../components/RegionMapping/RegionList");
var UserMapping_1 = require("../components/RegionMapping/UserMapping");
var outstation_graph_filtes_1 = require("../components/shipment_reports/filters/outstation_graph_filtes");
var rlab_plab_filter_1 = require("../components/shipment_reports/filters/rlab_plab_filter");
var approval_management_1 = require("../components/super_admin/approval_management/approval_management");
var fe_approval_details_1 = require("../components/super_admin/approval_management/apprvals/fe_approval_details");
var LevelOneVendorPendingList_1 = require("../components/super_admin/approval_management/apprvals/LevelOneVendorPendingList");
var LevelTwoVendorPendingList_1 = require("../components/super_admin/approval_management/apprvals/LevelTwoVendorPendingList");
var level_one_pendingList_1 = require("../components/super_admin/approval_management/apprvals/level_one_pendingList");
var level_two_pendingList_1 = require("../components/super_admin/approval_management/apprvals/level_two_pendingList");
var VendorApprovalManagement_1 = require("../components/super_admin/approval_management/VendorApprovalManagement");
var AssignAudit_1 = require("../components/super_admin/audit/AssignAudit");
var audit_1 = require("../components/super_admin/audit/audit");
var audit_details_1 = require("../components/super_admin/audit/audit_details");
var CreateAudit_1 = require("../components/super_admin/audit/CreateAudit");
var CC_listing_1 = require("../components/super_admin/CC/CC_listing");
var modify_cc_slot_1 = require("../components/super_admin/CC/modify_cc_slot");
var control_tower_1 = require("../components/super_admin/control_center/control_tower");
var assign_request_1 = require("../components/super_admin/control_center/onDemand_Request/assign_request");
var onDemand_request_1 = require("../components/super_admin/control_center/onDemand_Request/onDemand_request");
var assign_active_clients_1 = require("../components/super_admin/control_dashboard/assign_active_clients");
var cash_receiving_executive_1 = require("../components/super_admin/CRE/cash_receiving_executive");
var cre_active_list_1 = require("../components/super_admin/CRE/cre_list/cre_active_list");
var cre_approval_pending_list_1 = require("../components/super_admin/CRE/cre_list/cre_approval_pending_list");
var cre_exit_list_1 = require("../components/super_admin/CRE/cre_list/cre_exit_list");
var cre_inactive_list_1 = require("../components/super_admin/CRE/cre_list/cre_inactive_list");
var cre_onboarding_inprogress_list_1 = require("../components/super_admin/CRE/cre_list/cre_onboarding_inprogress_list");
var cre_rejected_list_1 = require("../components/super_admin/CRE/cre_list/cre_rejected_list");
var ETRF_list_1 = require("../components/super_admin/ETRF_docs/List/ETRF_list");
var Etrf_details_1 = require("../components/super_admin/ETRF_docs/Profile_Details/Etrf-details");
var heat_map_1 = require("../components/super_admin/heat_maps/heat_map");
var polyline_map_1 = require("../components/super_admin/heat_maps/polyline/polyline_map");
var routeMap_1 = require("../components/super_admin/heat_maps/route_map/routeMap");
var lab_handover_trend_filters_1 = require("../components/super_admin/Intracity_Graphs/intracity_lab_handover_trend/lab_handover_trend_filters");
var intracity_tat_filter_1 = require("../components/super_admin/Intracity_Graphs/Intracity_Tat/intracity_tat_filter");
var Intracity_tat_graph_list_1 = require("../components/super_admin/Intracity_Graphs/Intracity_Tat/Intracity_tat_graph_list");
var on_time_pickup_filters_1 = require("../components/super_admin/Intracity_Graphs/on_time_pickup/on_time_pickup_filters");
var on_time_pickup_graph_list_1 = require("../components/super_admin/Intracity_Graphs/on_time_pickup/on_time_pickup_graph_list");
var hubMasterList_1 = require("../components/super_admin/masters_data/hub master/hubMasterList");
var modify_hub_slots_1 = require("../components/super_admin/masters_data/hub master/modify_hub_slots");
var MyReportsHomePage_1 = require("../components/super_admin/MyReports/MyReportsHomePage");
var precious_sample_1 = require("../components/super_admin/precious_sample/precious_sample");
var event_performance_1 = require("../components/super_admin/reports/event_performance");
var mis_report_1 = require("../components/super_admin/reports/mis_report");
var reports_1 = require("../components/super_admin/reports/reports");
var all_reports_1 = require("../components/super_admin/reports/reports_submenu/all_reports");
var assigned_completed_1 = require("../components/super_admin/reports/reports_submenu/assigned_completed");
var bagging_lifecycle_1 = require("../components/super_admin/reports/reports_submenu/bagging_lifecycle");
var cash_pending_report_1 = require("../components/super_admin/reports/reports_submenu/cash_pending_report");
var clients_slotwise_productivity_reports_1 = require("../components/super_admin/reports/reports_submenu/clients_slotwise_productivity_reports");
var fe_attendance_report_1 = require("../components/super_admin/reports/reports_submenu/fe_attendance_report");
var outstation_masterbox_report_1 = require("../components/super_admin/reports/reports_submenu/outstation_masterbox_report");
var rlab_to_plab_intercity_report_1 = require("../components/super_admin/reports/reports_submenu/rlab_to_plab_intercity_report");
var successful_visits_reports_1 = require("../components/super_admin/reports/reports_submenu/successful_visits_reports");
var visits_report_1 = require("../components/super_admin/reports/reports_submenu/visits_report");
var tab_1 = require("../components/super_admin/reports/tab");
var List_1 = require("../components/super_admin/role_management/List");
var NewOrEdit_1 = require("../components/super_admin/role_management/NewOrEdit");
var changes_list_1 = require("../components/super_admin/route_planner/approvals/changes_list");
var RoutePlannerApprovalList_1 = require("../components/super_admin/route_planner/approvals/RoutePlannerApprovalList");
var route_planner_list_1 = require("../components/super_admin/route_planner/list/route_planner_list");
var create_route_planner_1 = require("../components/super_admin/route_planner/new_route/create_route_planner");
var basic_details_1 = require("../components/super_admin/sample_traking/basic_details");
var sample_traking_1 = require("../components/super_admin/sample_traking/sample_traking");
var shipmentlist_1 = require("../components/super_admin/ShimentRoute/shipmentlist");
var shipmentRouteCreate_1 = require("../components/super_admin/ShimentRoute/shipmentRouteCreate");
var shipment_list_1 = require("../components/super_admin/shipment_tracking/shipment_list");
var shipment_tracking_1 = require("../components/super_admin/shipment_tracking/shipment_tracking");
var sso_ondemand_list_1 = require("../components/super_admin/sso_ondemand/sso_ondemand_list");
var Create_User_Logistics_1 = require("../components/super_admin/User_Logistics/Create_User_Logistics");
var User_list_1 = require("../components/super_admin/User_Logistics/User_list");
var user_management_1 = require("../components/super_admin/user_management/user_management");
var create_vendor_1 = require("../components/super_admin/vendor_management/create/create_vendor");
var active_vendors_1 = require("../components/super_admin/vendor_management/list/active_vendors");
var draft_vendors_1 = require("../components/super_admin/vendor_management/list/draft_vendors");
var exit_vendors_1 = require("../components/super_admin/vendor_management/list/exit_vendors");
var rejectedVendors_1 = require("../components/super_admin/vendor_management/list/rejectedVendors");
var vendor_list_1 = require("../components/super_admin/vendor_management/list/vendor_list");
var vendor_profile_detials_1 = require("../components/super_admin/vendor_management/vendor_profile_detials");
var AddNewVisits_1 = require("../components/super_admin/Visits/AddNewVisits");
var VisitsList_1 = require("../components/super_admin/Visits/VisitsList");
var route_path_1 = require("../constants/route_path");
var menu_constants_1 = require("../menuList/menu_constants");
var orc_main_component_1 = require("../components/super_admin/orc_scf/orc_management/list/orc_main_component");
var scf_main_component_1 = require("../components/super_admin/orc_scf/scf_management/list/scf_main_component");
var orc_active_list_1 = require("../components/super_admin/orc_scf/orc_management/list/orc_active_list");
var orc_level1_list_1 = require("../components/super_admin/orc_scf/orc_management/list/orc_level1_list");
var orc_level2_list_1 = require("../components/super_admin/orc_scf/orc_management/list/orc_level2_list");
var orc_rejected_list_1 = require("../components/super_admin/orc_scf/orc_management/list/orc_rejected_list");
var create_orc_1 = require("../components/super_admin/orc_scf/orc_management/create_orc");
var ocr_profile_details_1 = require("../components/super_admin/orc_scf/orc_management/ocr_profile_details");
var scf_active_list_1 = require("../components/super_admin/orc_scf/scf_management/list/scf_active_list");
var scf_level1_list_1 = require("../components/super_admin/orc_scf/scf_management/list/scf_level1_list");
var scf_level2_list_1 = require("../components/super_admin/orc_scf/scf_management/list/scf_level2_list");
var scf_rejected_list_1 = require("../components/super_admin/orc_scf/scf_management/list/scf_rejected_list");
var create_scf_1 = require("../components/super_admin/orc_scf/scf_management/create_scf");
var scf_profile_details_1 = require("../components/super_admin/orc_scf/scf_management/scf_profile_details");
var ETRF_updated_details_1 = require("../components/super_admin/ETRF_docs/Profile_Details/ETRF_updated_details");
exports.ROUTE_LIST = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.fe),
        component: fe_management_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.feManagement,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.leaveTraking),
        component: leave_tracking_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.leaveManagement,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.slotBlocking),
        component: slot_blocking_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.rosterManagement,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.role_management),
        component: List_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.roleManagement,
    },
    {
        id: 5,
        path: "/".concat(route_path_1.PATHS.userManagement),
        component: user_management_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.userManagement,
    },
    {
        id: 6,
        path: "/".concat(route_path_1.PATHS.vendormanagement),
        component: vendor_list_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.vendorManagement,
    },
    {
        id: 7,
        path: "/".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.fe),
        component: approval_management_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.approvals,
    },
    {
        id: 8,
        path: "/".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.vendorlist),
        component: VendorApprovalManagement_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.approvals,
    },
    {
        id: 9,
        path: "/".concat(route_path_1.PATHS.audit),
        component: audit_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.audit,
    },
    {
        id: 10,
        path: "/".concat(route_path_1.PATHS.sampleTracking),
        component: sample_traking_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.sampleTracking,
    },
    {
        id: 11,
        path: "/".concat(route_path_1.PATHS.notificationManagement),
        component: NotificationList_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.notificationManagement,
    },
    {
        id: 12,
        path: "/".concat(route_path_1.PATHS.ETRFList),
        component: ETRF_list_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.ETRFDocuments,
    },
    {
        id: 13,
        path: "/".concat(route_path_1.PATHS.preciousSamples),
        component: precious_sample_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.preciousSample,
    },
    {
        id: 14,
        path: "/".concat(route_path_1.PATHS.Visits),
        component: AddNewVisits_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.visitManagement,
    },
    {
        id: 15,
        path: "/".concat(route_path_1.PATHS.ROUTE_MAP),
        component: heat_map_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.Heat_Maps,
    },
    {
        id: 16,
        path: "/".concat(route_path_1.PATHS.ROUTE_MAP),
        component: heat_map_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.cre,
    },
    {
        id: 17,
        path: "/".concat(route_path_1.PATHS.cre),
        component: cash_receiving_executive_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.cre,
    },
    {
        id: 18,
        path: "/".concat(route_path_1.PATHS.misReport),
        component: mis_report_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.misReports,
    },
    {
        id: 19,
        path: "/".concat(route_path_1.PATHS.controlTower),
        component: control_tower_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.controlTower,
    },
    {
        id: 20,
        path: "/".concat(route_path_1.PATHS.reports),
        component: MyReportsHomePage_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.reports,
    },
    {
        id: 21,
        path: "/".concat(route_path_1.PATHS.ccworkinghourslist),
        component: CC_listing_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.CC_Roster_Management,
    },
    {
        id: 22,
        path: "/".concat(route_path_1.PATHS.User_Logistics),
        component: User_list_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.Admin_User_Management,
    },
    {
        id: 23,
        path: "/".concat(route_path_1.PATHS.route_planner),
        component: route_planner_list_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.Route_Planner,
    },
    {
        id: 24,
        path: "/".concat(route_path_1.PATHS.LabMasterlist),
        component: lab_list_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.Lab_Roster_Management,
    },
    {
        id: 25,
        path: "/".concat(route_path_1.PATHS.hubMasterList),
        component: hubMasterList_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.HUB_Roster_Management,
    },
    {
        id: 26,
        path: "/".concat(route_path_1.PATHS.masterBoxtracking),
        component: MasterBoxTracking_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.master_box_tracking,
    },
    {
        id: 27,
        path: "/".concat(route_path_1.PATHS.intracity_tat_filters),
        component: intracity_tat_filter_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.feManagement,
    },
    {
        id: 28,
        path: "/".concat(route_path_1.PATHS.shipment_tracking),
        component: shipment_list_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.shipment_tracking,
    },
    {
        id: 29,
        path: "/".concat(route_path_1.PATHS.region_mapping),
        component: Region_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.misReports,
    },
    {
        id: 30,
        path: "/".concat(route_path_1.PATHS.fe_traking_list),
        component: fe_live_tracking_filters_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.FE_live_tracking,
    },
    {
        id: 31,
        path: "/".concat(route_path_1.PATHS.orc_listing),
        component: orc_main_component_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.ORC_Management,
    },
    {
        id: 32,
        path: "/".concat(route_path_1.PATHS.scf_listing),
        component: scf_main_component_1.default,
        moduleName: menu_constants_1.FEATURE_CONSTANTS.SCF_Management,
    },
];
exports.FE_MANGEMENT_LIST_SUB_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.activeList),
        component: all_fe_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.ACTIVE_FE,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.draftedFeList),
        component: fe_drafted_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.DRAFT_FE,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.feExisted),
        component: fe_exited_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.EXIT_FE_List,
    },
    {
        id: 4,
        path: "".concat(route_path_1.PATHS.feOnLeave),
        component: on_leave_today_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.ON_LEAVE_TODAY,
    },
    {
        id: 5,
        path: "".concat(route_path_1.PATHS.feApprovalPending),
        component: new_fe_approval_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.NEW_FE_PENDING_APPROVAL,
    },
    {
        id: 6,
        path: "".concat(route_path_1.PATHS.invetoryPending),
        component: fe_inventory_pending_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_INVENTORY_PENDING,
    },
    {
        id: 7,
        path: "".concat(route_path_1.PATHS.feInActiveList),
        component: fe_inActive_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_INACTIVE_FE_LIST,
    },
    {
        id: 7,
        path: "".concat(route_path_1.PATHS.feRejected),
        component: fe_rejected_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_REJECTED_LIST,
    },
];
exports.FE_MANGEMENT_BUTTON_FEATURE_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.feRegistration),
        component: FE_Registration_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CREATE_NEW_FE,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.addFE, "/:id"),
        component: FE_Registration_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CREATE_NEW_FE,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.addFE, "/:id/:activeTabId"),
        component: FE_Registration_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CREATE_NEW_FE,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.feReplaceRegistration, "/:replaceFeId/:replaceFeCode"),
        component: FE_Registration_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.RE_PLACE_FE,
    },
    {
        id: 5,
        path: "/".concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.editFE, "/:id"),
        component: FE_Registration_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Edit,
    },
    {
        id: 6,
        path: "/".concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.editFE, "/:id/:activeTabId"),
        component: FE_Registration_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Edit,
    },
    {
        id: 7,
        path: "/".concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.feDetails, "/:id"),
        component: fe_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Profile,
    },
];
exports.FE_PROFILE_DETIALS_SUB_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.trackFe),
        component: fe_schedule_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Track,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.feKpis),
        component: fe_kpis_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_KPIs,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.feProfileDetails),
        component: fe_profile_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Profile,
    },
];
exports.ROUTESR_MANAGEMNT_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.addSlot),
        component: add_slot_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.L_ROSTER_ADD_SLOTS,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.addSlot, "/").concat(route_path_1.PATHS.editslotFE),
        component: add_slot_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.L_ROSTER_ADD_SLOTS,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.addSlot, "/").concat(route_path_1.PATHS.ViewFeSlot),
        component: add_slot_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.L_ROSTER_LIST,
    },
];
exports.ROLE_MANAGEMNT_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.role_management),
        component: List_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Role_List,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.role_management, "/").concat(route_path_1.PATHS.addNewRole),
        component: NewOrEdit_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Role_Create,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.role_management, "/").concat(route_path_1.PATHS.editRole, "/:id"),
        component: NewOrEdit_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Role_Edit,
    },
];
exports.VENDOR_MANGEMENT_LIST_SUB_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.activeList),
        component: active_vendors_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_List,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.draft_vendors),
        component: draft_vendors_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Onboarding_In_progress_List,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.exit_vendors),
        component: exit_vendors_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Exit_List,
    },
    {
        id: 4,
        path: "".concat(route_path_1.PATHS.vendor_reject),
        component: rejectedVendors_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Rejected_List,
    },
    {
        id: 5,
        path: "".concat(route_path_1.PATHS.vendor_pending_approval),
        component: LevelOneVendorPendingList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Pending_List,
    },
    {
        id: 6,
        path: "".concat(route_path_1.PATHS.vendor_level2),
        component: LevelTwoVendorPendingList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Pending_List,
    },
];
exports.VENDOR_MANGEMENT_BUTTON_FEATURE_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.vendormanagement, "/").concat(route_path_1.PATHS.createVendor),
        component: create_vendor_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Creation,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.vendormanagement, "/").concat(route_path_1.PATHS.createVendor, "/:id/:activeTabId"),
        component: create_vendor_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Creation,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.vendormanagement, "/").concat(route_path_1.PATHS.editVendor, "/:id/:activeTabId"),
        component: create_vendor_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Edit,
    },
    {
        id: 4,
        path: "".concat(route_path_1.PATHS.vendor, "/").concat(route_path_1.PATHS.vendorProfileDetials, "/:id"),
        component: vendor_profile_detials_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Pending_List,
    },
    {
        id: 5,
        path: "".concat(route_path_1.PATHS.vendor, "/").concat(route_path_1.PATHS.vendor_pending_approval, "/").concat(route_path_1.PATHS.vendorProfileDetials, "/:id"),
        component: vendor_profile_detials_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Pending_List,
    },
    {
        id: 6,
        path: "".concat(route_path_1.PATHS.vendor, "/").concat(route_path_1.PATHS.vendor_level2, "/").concat(route_path_1.PATHS.vendorProfileDetials, "/:id"),
        component: vendor_profile_detials_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Pending_List,
    },
];
exports.APPROVAL_MANGEMENT_FE_LIST_SUB_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.levelOnePendingApproval),
        component: level_one_pendingList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Approval_Level_1_Pending_List,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.levelTwoPendingApproval),
        component: level_two_pendingList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Approval_Level_2_Pending_List,
    },
];
exports.APPROVAL_MANGEMENT_VENDOR_LIST_SUB_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.levelOnePendingApproval),
        component: LevelOneVendorPendingList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Level_1_Pending_List,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.levelTwoPendingApproval),
        component: LevelTwoVendorPendingList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Level_2_Pending_List,
    },
];
exports.FE_APPROVAL_BUTTON_FEATURES_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.feLevelOneDetails, "/:id/:onboardstatus"),
        component: fe_approval_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Approval_Level_1_Pending_List,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.feLevelTwoDetails, "/:id/:onboardstatus"),
        component: fe_approval_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Approval_Level_2_Pending_List,
    },
];
exports.VENDOR_APPROVAL_BUTTON_FEATURES_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.vendorlist, "/").concat(route_path_1.PATHS.vendorLevelOneDetails, "/:id/:status"),
        component: vendor_profile_detials_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Level_1_Pending_List,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.vendorlist, "/").concat(route_path_1.PATHS.vendorLevelTwoDetails, "/:id/:status"),
        component: vendor_profile_detials_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Level_2_Pending_List,
    },
];
exports.AUDIT_BUTTONS_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.audit),
        component: audit_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.audit,
    },
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.audit, "/").concat(route_path_1.PATHS.createAudit),
        component: CreateAudit_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Audit_Creation,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.audit, "/").concat(route_path_1.PATHS.createAudit, "/:id"),
        component: CreateAudit_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Audit_Creation,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.audit, "/").concat(route_path_1.PATHS.editAudit, "/:id"),
        component: CreateAudit_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Audit_Creation,
    },
    {
        id: 4,
        path: "".concat(route_path_1.PATHS.audit, "/").concat(route_path_1.PATHS.assignAudit),
        component: AssignAudit_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Audit_Assignment,
    },
    {
        id: 5,
        path: "".concat(route_path_1.PATHS.audit, "/").concat(route_path_1.PATHS.auditDetails, "/:id"),
        component: audit_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Audit_List,
    },
];
exports.SAMPLE_TRACKING_BUTTON_ROUES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.sampleTracking),
        component: sample_traking_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Sample_Bag_Details,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.sampleTracking, "/").concat(route_path_1.PATHS.sampleTraking_basicDetails, "/:id"),
        component: basic_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Sample_Bag_Details,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.masterbox_basicDeatils, "/").concat(route_path_1.PATHS.sampleTraking_basicDetails, "/:id"),
        component: basic_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Sample_Bag_Details,
    },
];
exports.NOTIFICATIONS_BUTTON_ROUES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.notificationManagement),
        component: NotificationList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.NOTIFICATIONS_LIST,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.notificationManagement, "/").concat(route_path_1.PATHS.createNotification),
        component: CreateNotification_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.createNotification,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.notificationManagement, "/").concat(route_path_1.PATHS.createNotification, "/:id"),
        component: CreateNotification_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.createNotification,
    },
];
exports.ETRF_BUTTON_ROUES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.ETRFList),
        component: ETRF_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.ETRF_List,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.ETRFList, "/").concat(route_path_1.PATHS.ETRF_Profile),
        component: ETRF_updated_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.ETRF_View,
    },
];
exports.ETRF_SUB_ROUTES = [
    {
        id: 1,
        path: "etrfprofile",
        component: Etrf_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.ETRF_List,
    },
];
exports.PRICIOUS_BUTTON_ROUES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.preciousSamples),
        component: precious_sample_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Precious_Sample_List,
    },
];
exports.VISITS_BUTTON_ROUES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.Visits),
        component: AddNewVisits_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.VISITS_Add,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.Visits, "/").concat(route_path_1.PATHS.VisitsList),
        component: VisitsList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.VISITS_List,
    },
];
exports.HEAT_MAP_SUB_ROUES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.ROUTE_MAP),
        component: heat_map_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Heat_Maps,
    },
    {
        id: 2,
        path: "",
        component: routeMap_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Tracking_FE_Location,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.POLYLINE_MAP),
        component: polyline_map_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Tracking_FE_Location,
    },
];
exports.CRE_SUB_ROUES = [
    {
        id: 1,
        path: "",
        component: cre_active_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CRE_list,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.creOnboardingInProgress),
        component: cre_onboarding_inprogress_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CRE_list,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.creExit),
        component: cre_exit_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CRE_list,
    },
    {
        id: 4,
        path: "".concat(route_path_1.PATHS.creNewApprovalPending),
        component: cre_approval_pending_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CRE_list,
    },
    {
        id: 5,
        path: "".concat(route_path_1.PATHS.creInactive),
        component: cre_inactive_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CRE_list,
    },
    {
        id: 5,
        path: "".concat(route_path_1.PATHS.creRejected),
        component: cre_rejected_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CRE_list,
    },
];
exports.MIST_SUB_ROUES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.eventPerformance),
        component: event_performance_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Event_Performance,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.feRoute),
        component: tab_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Route,
    },
];
exports.CONTROL_TOWER_BUTTTON_ROUES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.controlTower, "/").concat(route_path_1.PATHS.controlTowerRouting),
        component: control_tower_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Control_tower_List,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.autoRoutingDashboard, "/").concat(route_path_1.PATHS.onDemandRequest),
        component: onDemand_request_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.On_Demand_Request,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.controlTower, "/").concat(route_path_1.PATHS.onDemandRequest),
        component: onDemand_request_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.On_Demand_Request,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.controlTower, "/").concat(route_path_1.PATHS.onDemandRequest, "/").concat(route_path_1.PATHS.assignRequest),
        component: assign_request_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.On_Demand_Request,
    },
    {
        id: 5,
        path: "/".concat(route_path_1.PATHS.controlTower, "/").concat(route_path_1.PATHS.totalActiveClients, "/").concat(route_path_1.PATHS.assignRoute, "/:name/:visit_id"),
        component: assign_active_clients_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.On_Demand_Request,
    },
    {
        id: 6,
        path: "/".concat(route_path_1.PATHS.sso_ondemandRequest, "/").concat(route_path_1.PATHS.onDemandRequest, "/").concat(route_path_1.PATHS.sso_ondemandRequest_create),
        component: onDemand_request_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.On_Demand_Request,
    },
    {
        id: 7,
        path: "/".concat(route_path_1.PATHS.sso_ondemandRequest, "/").concat(route_path_1.PATHS.onDemandRequest),
        component: sso_ondemand_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.On_Demand_Request,
    },
];
exports.MY_REPORTS_ROUES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.MYREPORTS),
        component: MyReportsHomePage_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.My_Reports_List,
    },
];
exports.REPORTS_BUTTTON_ROUES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.cahCollectionReport),
        component: reports_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Cash_Report_List,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.cashPendingReport),
        component: cash_pending_report_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Cash_Pending_Report_List,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.baggingLifeCycle),
        component: bagging_lifecycle_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Bag_Report_List,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.visitsReport),
        component: visits_report_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Visit_Report_List,
    },
    {
        id: 5,
        path: "/".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.clientSlotwiseProductivityReports, "/").concat(route_path_1.PATHS.nothingTopickup),
        component: clients_slotwise_productivity_reports_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Clients_Slot_Wise_Productivity,
    },
    {
        id: 6,
        path: "/".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.feAttendanceReport),
        component: fe_attendance_report_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_Attendance,
    },
    {
        id: 7,
        path: "/".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.outstationMasterboxReport),
        component: outstation_masterbox_report_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Outstation_Master_Box,
    },
    {
        id: 8,
        path: "/".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.rLabtoPLabIntercityReport),
        component: rlab_to_plab_intercity_report_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.R_Lab_to_P_Lab_INTERCIT,
    },
];
exports.CC_ROUSTER_BUTTONS = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.addCCSlot),
        component: modify_cc_slot_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Update_CC_Time_Windows,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.ccworkinghourslist, "/").concat(route_path_1.PATHS.addCCSlot, "/:id"),
        component: modify_cc_slot_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Update_CC_Time_Windows,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.unTaggedClientsToCC, "/").concat(route_path_1.PATHS.addCCSlot, "/:id"),
        component: modify_cc_slot_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Update_CC_Time_Windows,
    },
];
exports.ROUTE_PLANNER_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.route_planner),
        component: route_planner_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Route_List,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.approvals_route_planner),
        component: RoutePlannerApprovalList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Route_List,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.approvals_route_planner, "/").concat(route_path_1.PATHS.route_planner_details, "/:id/").concat(route_path_1.PATHS.route_planner_changes_list),
        component: changes_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Route_List,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.approvals_route_planner, "/").concat(route_path_1.PATHS.route_planner_details, "/:id"),
        component: create_route_planner_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Route_List,
    },
    {
        id: 5,
        path: "/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.create_route_planner, "/:id"),
        component: create_route_planner_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Route_Edit,
    },
    {
        id: 6,
        path: "/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.view_route_planner, "/:id"),
        component: create_route_planner_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Route_Edit,
    },
    {
        id: 7,
        path: "/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_approved_details, "/:id"),
        component: create_route_planner_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Route_List,
    },
];
exports.USER_LOGISTIC_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.User_Logistics),
        component: User_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Admin_User_List,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.User_Logistics, "/").concat(route_path_1.PATHS.Create_User),
        component: Create_User_Logistics_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Admin_User_Create,
    },
];
exports.LAB_MASTER_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.LabMasterlist),
        component: lab_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.List_Lab_Time_Windows,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.LabMasterlist, "/").concat(route_path_1.PATHS.AddLabMaster),
        component: Create_lab_master_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Create_LAB_Time_Windows,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.LabMasterlist, "/").concat(route_path_1.PATHS.AddLabMaster, "/:id"),
        component: Create_lab_master_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Update_LAB_Time_Windows,
    },
];
exports.HUB_MASTER_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.hubMasterList),
        component: hubMasterList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.List_HUB_Time_Windows,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.hubMasterList, "/").concat(route_path_1.PATHS.create_hub_slot),
        component: modify_hub_slots_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Create_HUB_Time_Windows,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.hubMasterList, "/").concat(route_path_1.PATHS.edit_hub_slot),
        component: modify_hub_slots_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Update_HUB_Time_Windows,
    },
];
exports.MASTERBOX_TRACKING_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.masterBoxtracking),
        component: MasterBoxTracking_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.master_box_tracking_list,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.masterBoxDetails, "/:bag_id/:warehouse_code"),
        component: MasterBoxDetailsScreen_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.master_box_tracking_list,
    },
];
exports.VISITS_REPORT_SUB_ROUES = [
    {
        id: 1,
        path: "",
        component: all_reports_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Visit_Report_List,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.assignedCompletedReports),
        component: assigned_completed_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Visit_Report_List,
    },
];
exports.SSO_MY_REPORTS_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.MYREPORTS),
        component: MyReportsHomePage_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
    },
];
exports.INTRACITY_GRAPH_REPORT_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.controlTower),
        component: control_tower_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Auto_Routing,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.on_time_pickup),
        component: on_time_pickup_filters_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Ontime_Pickup,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.labHandoverTrend),
        component: lab_handover_trend_filters_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Lab_Handover_Trend,
    },
    {
        id: 4,
        path: "".concat(route_path_1.PATHS.intracity_tat_filters),
        component: intracity_tat_filter_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Intracity_TAT,
    },
];
exports.INTRACITY_TAT_GRAPH_REPORT_BUTTON_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.intracity_tat_filters, "/").concat(route_path_1.PATHS.intracity_tat_graph_list),
        component: Intracity_tat_graph_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Intracity_TAT,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.on_time_pickup, "/").concat(route_path_1.PATHS.on_time_pickup_list),
        component: on_time_pickup_graph_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Ontime_Pickup,
    },
];
exports.INTERCITY_GRAPH_REPORT_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.r_lab_to_p_lab),
        component: rlab_plab_filter_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.R_Lab_to_P_Lab_INTERCIT,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.outStationMasterBox),
        component: outstation_graph_filtes_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Outstation_Master_Box,
    },
];
exports.SHIPMENT_TRACKING_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.shipment_tracking),
        component: shipment_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.shipmentDetails,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.shipment_tracking, "/").concat(route_path_1.PATHS.shipment_details, "/:master_barcode"),
        component: shipment_tracking_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.shipmentDetails,
    },
];
exports.CLIENT_SLOTWISE_REPORT_SUB_ROUTES = [
    {
        id: 1,
        path: "",
        component: successful_visits_reports_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Clients_Slot_Wise_Productivity,
    },
];
exports.REGION_REPORT_SUB_ROUES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.region_listing),
        component: RegionList_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Region_List,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.user_region),
        component: UserMapping_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Region_List,
    },
];
exports.REGION_BUTTON_ROUES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.region_mapping, "/").concat(route_path_1.PATHS.region_create),
        component: Create_Region_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Region_creation,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.region_mapping, "/").concat(route_path_1.PATHS.user_region, "/").concat(route_path_1.PATHS.region_create),
        component: Create_Region_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Region_creation,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.region_mapping, "/").concat(route_path_1.PATHS.region_listing, "/").concat(route_path_1.PATHS.region_create),
        component: Create_Region_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Region_creation,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.region_mapping, "/").concat(route_path_1.PATHS.user_region, "/").concat(route_path_1.PATHS.region_create, "/:regionId"),
        component: Create_Region_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Region_creation,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.region_mapping, "/").concat(route_path_1.PATHS.region_listing, "/").concat(route_path_1.PATHS.region_create, "/:regionId"),
        component: Create_Region_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Region_creation,
    },
];
exports.FE_LIVE_TRAKING_ROUTES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.fe_traking_list),
        component: fe_live_tracking_filters_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_live_tracking_list,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.fe_traking_list, "/").concat(route_path_1.PATHS.fe_traking_details, "/:id"),
        component: fe_live_tracking_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.FE_live_tracking_Details,
    },
];
exports.SHIPMENT_MASTER_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.shipment_list),
        component: shipmentlist_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Shipment_List,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.shipment_list, "/").concat(route_path_1.PATHS.shipment_create),
        component: shipmentRouteCreate_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Shipment_Create,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.shipment_list, "/").concat(route_path_1.PATHS.shipment_create, "/").concat(route_path_1.PATHS.shipment_edit, "/:id"),
        component: shipmentRouteCreate_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Shipment_Edit,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.shipment_list, "/").concat(route_path_1.PATHS.shipment_create, "/").concat(route_path_1.PATHS.shipment_view, "/:id"),
        component: shipmentRouteCreate_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.Shipment_List,
    },
];
exports.ORC_MANGAGEMENT_SUB_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.orc_active_listing),
        component: orc_active_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.ACTIVATE_DEACTIVATE_LIST,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.orc_level1_listing),
        component: orc_level1_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_PENDING_LIST,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.orc_leve2_listing),
        component: orc_level2_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_PENDING_LIST,
    },
    {
        id: 4,
        path: "".concat(route_path_1.PATHS.orc_rejected_listing),
        component: orc_rejected_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_REJECTED_LIST,
    },
];
exports.ORC_MANGAGEMEN_BUTTON_ROUES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.create_orc),
        component: create_orc_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.CREATE_CLIENT,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.edit_orc, "/:id"),
        component: create_orc_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.MODIFY_CLIENT,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.orc_listing, "/").concat(route_path_1.PATHS.orc_level1_listing, "/").concat(route_path_1.PATHS.orc_profile_details, "/:id/:center_id"),
        component: ocr_profile_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_PENDING_LIST,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.orc_listing, "/").concat(route_path_1.PATHS.orc_leve2_listing, "/").concat(route_path_1.PATHS.orc_profile_details, "/:id/:center_id"),
        component: ocr_profile_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_PENDING_LIST,
    },
];
exports.SCF_MANGAGEMENT_SUB_ROUTES = [
    {
        id: 1,
        path: "".concat(route_path_1.PATHS.scf_active_listing),
        component: scf_active_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.SCF_ACTIVATE_DEACTIVATE_LIST,
    },
    {
        id: 2,
        path: "".concat(route_path_1.PATHS.scf_level1_listing),
        component: scf_level1_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.SCF_LEVEL_1_2_PENDING_LIST,
    },
    {
        id: 3,
        path: "".concat(route_path_1.PATHS.scf_leve2_listing),
        component: scf_level2_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.SCF_LEVEL_1_2_PENDING_LIST,
    },
    {
        id: 4,
        path: "".concat(route_path_1.PATHS.scf_rejected_listing),
        component: scf_rejected_list_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.SCF_LEVEL_1_2_REJECTED_LIST,
    },
];
exports.SCF_MANGAGEMEN_BUTTON_ROUES = [
    {
        id: 1,
        path: "/".concat(route_path_1.PATHS.create_scf),
        component: create_scf_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.SCF_CREATE_CLIENT,
    },
    {
        id: 2,
        path: "/".concat(route_path_1.PATHS.edit_scf, "/:center_id"),
        component: create_scf_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.SCF_MODIFY_CLIENT,
    },
    {
        id: 3,
        path: "/".concat(route_path_1.PATHS.scf_listing, "/").concat(route_path_1.PATHS.scf_level1_listing, "/").concat(route_path_1.PATHS.scf_profile_details, "/:center_id/:id"),
        component: scf_profile_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.SCF_LEVEL_1_2_PENDING_LIST,
    },
    {
        id: 4,
        path: "/".concat(route_path_1.PATHS.scf_listing, "/").concat(route_path_1.PATHS.scf_leve2_listing, "/").concat(route_path_1.PATHS.scf_profile_details, "/:center_id/:id"),
        component: scf_profile_details_1.default,
        featureName: menu_constants_1.FEATURE_CONSTANTS.SCF_LEVEL_1_2_PENDING_LIST,
    },
];
