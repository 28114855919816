"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid/Grid");
var formik_1 = require("formik");
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../../api/api_service");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var file_upload_component_1 = require("../../../../common/file_upload/file_upload_component");
var text_field_with_datepicker_1 = require("../../../../common/text_filed/text_field_with_datepicker");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var vendor_creation_tab_constant_1 = require("../../../../constants/vendor_creation_tab_constant");
var array_helpers_1 = require("../../../../utils/array_helpers");
var leavetracking_apicalls_1 = require("../../../field_exicutive/leave_tracking/leavetracking.apicalls");
var generic_api_calls_1 = require("../../../generic_api_calls");
var Contract_Details = function (_a) {
    var initialValues = _a.initialValues, saveFormData = _a.saveFormData, sendItForApproval = _a.sendItForApproval, backButtonhandler = _a.backButtonhandler, updateValue = _a.updateValue, vedore_personal_Details = _a.vedore_personal_Details, enableSendItForApproval = _a.enableSendItForApproval, selectedCityList = _a.selectedCityList, value = _a.value, onBoardingStatus = _a.onBoardingStatus, USER_ContactDetailsInitialState = _a.USER_ContactDetailsInitialState, selectedStateList = _a.selectedStateList, editCityPayloadData = _a.editCityPayloadData, createCityPayload = _a.createCityPayload;
    var stateList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.stateList; });
    var vendor_contract_types = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.vendor_contract_types; });
    var id = (0, react_router_dom_1.useParams)().id;
    var nav = (0, react_router_dom_1.useNavigate)();
    var buttonClicked = 'saveAndContinue';
    var _b = React.useState([]), selectedStates = _b[0], setSelectedStates = _b[1];
    var _c = React.useState([]), citiesData = _c[0], setCitiesData = _c[1];
    var _d = React.useState([]), selectedCities = _d[0], setSelectedCities = _d[1];
    console.log(selectedCities, 'selectedCities');
    var _e = React.useState([]), cityPayload = _e[0], setCityPaylioad = _e[1];
    var _f = React.useState([]), editCityPayload = _f[0], setEditCityPaylioad = _f[1];
    console.log(selectedStates, 'selectedStates');
    console.log(selectedStateList, 'selectedStateList');
    var uploadFile = function (val) {
        var payload = new FormData();
        payload.append('cc_id', 'vendor');
        payload.append('file', val);
        payload.append('file_type', 'Other');
        return (0, generic_api_calls_1.fileUpload)(payload);
    };
    var disablePresent = function () {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    };
    var fetchCities = function () { return __awaiter(void 0, void 0, void 0, function () {
        var selectedStateIds;
        return __generator(this, function (_a) {
            selectedStateIds = selectedStates.map(function (state) { return state.id; });
            (0, leavetracking_apicalls_1.fetchCitiesList)({ state_id: selectedStateIds })
                .then(function (citiesListResponse) {
                var formattedCitiesList = (0, array_helpers_1.convertLabelAndValueCity)(citiesListResponse.cities);
                setCitiesData(formattedCitiesList);
            })
                .catch(function (e) {
                setCitiesData([]);
            });
            return [2];
        });
    }); };
    React.useEffect(function () {
        if (selectedStates.length > 0) {
            fetchCities();
        }
        else {
            setCitiesData([]);
            setSelectedCities([]);
        }
    }, [selectedStates]);
    React.useEffect(function () {
        if (selectedStateList != null) {
            setSelectedStates(selectedStateList);
            setCitiesData(selectedCityList);
        }
        if (selectedCityList != null) {
            setSelectedCities(selectedCityList);
            var cityListPayload = (0, array_helpers_1.convertOperatingPlaces)(selectedCityList);
            setEditCityPaylioad(cityListPayload);
            editCityPayloadData(cityListPayload);
        }
        if (selectedStateList.length == 0) {
            setSelectedCities([]);
            setCitiesData([]);
        }
    }, [
        selectedStateList && selectedStateList,
        selectedCityList && selectedCityList,
    ]);
    var updateSelectedCities = function () {
        var filteredData = lodash_1.default.intersectionBy(selectedCities, stateList, 'state_id');
        setSelectedCities(filteredData);
    };
    React.useEffect(function () {
        if (selectedStateList.length >= 1) {
            fetchCities();
        }
        if (false) {
            updateSelectedCities();
        }
    }, [selectedStateList]);
    var submitHandler = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, personalDetailsObj, vendor_contract_Details;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = {};
                    personalDetailsObj = {};
                    personalDetailsObj['vendor_logo'] = vedore_personal_Details.uploadPhoto;
                    personalDetailsObj['contact_name'] = vedore_personal_Details.contactperson;
                    personalDetailsObj['name'] = vedore_personal_Details.vendorName;
                    personalDetailsObj['entity_type'] =
                        vedore_personal_Details.entityType != null
                            ? vedore_personal_Details.entityType.id
                            : '';
                    personalDetailsObj['contact_person_designation'] =
                        vedore_personal_Details.contactperson;
                    personalDetailsObj['contact_mobile'] = vedore_personal_Details.mobilenumber;
                    personalDetailsObj['contact_email'] = vedore_personal_Details.email;
                    if (vedore_personal_Details.alternateNumber) {
                        personalDetailsObj['alternate_mobile'] =
                            vedore_personal_Details.alternateNumber;
                    }
                    if (vedore_personal_Details.alternateNumber) {
                        personalDetailsObj['contact_website'] = vedore_personal_Details.website;
                    }
                    payload['personal_details'] = personalDetailsObj;
                    vendor_contract_Details = {};
                    vendor_contract_Details['contract_type'] =
                        values.contract_type != '' &&
                            values.contract_type != null &&
                            values.contract_type != undefined
                            ? values.contract_type.id
                            : '';
                    vendor_contract_Details['contract_start_date'] =
                        values.contract_start_date != '' &&
                            values.contract_start_date != null &&
                            values.contract_start_date != undefined
                            ? moment(values.contract_start_date).format('YYYY-MM-DD')
                            : '';
                    vendor_contract_Details['contract_expiry_date'] =
                        values.contractExpiry != '' &&
                            values.contractExpiry != null &&
                            values.contractExpiry != undefined
                            ? moment(values.contractExpiry).format('YYYY-MM-DD')
                            : '';
                    if (values.totalSourceFe) {
                        vendor_contract_Details['total_sourcing_fe'] = values.totalSourceFe;
                    }
                    vendor_contract_Details['parent_contract_id'] = values.parent_contract_id;
                    vendor_contract_Details['contract_state'] =
                        values.operatingState !== null ? values.operatingState.id : '';
                    vendor_contract_Details['operating_places'] = location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor))
                        ? (0, array_helpers_1.removeDuplicateObjectInCity)(editCityPayload)
                        : cityPayload.length != 0
                            ? cityPayload
                            : (0, array_helpers_1.removeDuplicateObjectInCity)(editCityPayload);
                    if (!(typeof values.contractAgreement === 'object')) return [3, 2];
                    return [4, uploadFile(values.contractAgreement).then(function (res) {
                            if (res.data.statusCode === 200) {
                                vendor_contract_Details['contract_doc'] = res.data.data.name;
                            }
                            else {
                                vendor_contract_Details['contract_doc'] = values.contractAgreement;
                            }
                        })];
                case 1:
                    _a.sent();
                    return [3, 3];
                case 2:
                    vendor_contract_Details['contract_doc'] = values.contractAgreement;
                    _a.label = 3;
                case 3:
                    payload['contract_details'] = vendor_contract_Details;
                    payload['is_final_submit'] = false;
                    payload['axapta_code'] = id.toString();
                    api_service_1.api_service
                        .post({
                        endPoint: api_urls_1.api_urls.VENDOR_CREATION,
                        payLoad: payload,
                        domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
                    })
                        .then(function (res) {
                        if (res.data.statusCode === 200) {
                            if (buttonClicked === 'sendForApproval') {
                                sendItForApproval();
                            }
                            else {
                                react_hot_toast_1.default.success('Data saved successfully');
                            }
                            saveFormData(values);
                            if (onBoardingStatus == 1) {
                                nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.activeList));
                            }
                            else if (location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor))) {
                                nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.editVendor, "/").concat(id, "/").concat(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTRACTDETAILS));
                            }
                            else {
                                nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.createVendor, "/").concat(id, "/").concat(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTRACTDETAILS));
                            }
                        }
                        else {
                            throw new Error(res.data.message);
                        }
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.message);
                    });
                    saveFormData(values);
                    return [2];
            }
        });
    }); };
    console.log(selectedCityList, 'selectedCityList');
    return (React.createElement("div", { className: "form-filed-wrapper" },
        React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", null, "Vendor Agreement Details"),
            React.createElement("p", { className: "sub-heaidng" }, "Please update the Vendor Agreement Details")),
        console.log(initialValues, 'initialValues'),
        React.createElement(formik_1.Formik, { initialValues: __assign({}, initialValues), validateOnBlur: false, enableReinitialize: false, validationSchema: schema_1.schemas.VENDOR_CONTRACT_DETAILS, onSubmit: function (values, _a) {
                var setSubmitting = _a.setSubmitting;
                submitHandler(values);
                setSubmitting(false);
            } }, function (_a) {
            var values = _a.values, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, dirty = _a.dirty, isSubmitting = _a.isSubmitting, submitForm = _a.submitForm;
            return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper", id: "contract-details-form" },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "contract_type" }, function () {
                            return (React.createElement(drop_down_component_1.default, { name: "contract_type", placeholder: "Contract Type", dropDownList: vendor_contract_types, label: "Contract Type", required: true, onChange: function (val) {
                                    setFieldTouched('contract_type', true);
                                    setFieldValue('contract_type', val);
                                }, value: values === null || values === void 0 ? void 0 : values.contract_type }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "contract_type" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "contract_start_date" }, function () {
                            return (React.createElement(text_field_with_datepicker_1.default, { name: "contract_start_date", placeholder: 'Contract Start Date', label: "Contract Start Date", type: "dateselection", required: true, onChange: function (e) {
                                    setFieldValue('contract_start_date', e);
                                    setFieldTouched('contract_start_date', true);
                                }, value: values === null || values === void 0 ? void 0 : values.contract_start_date }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "contract_start_date" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "contractExpiry" }, function () {
                            return (React.createElement(text_field_with_datepicker_1.default, { name: "contractExpiry", placeholder: 'Contract Expiry Date', label: "Contract Expiry Date", required: true, onChange: function (e) {
                                    setFieldValue('contractExpiry', e);
                                    setFieldTouched('contractExpiry', true);
                                }, value: values === null || values === void 0 ? void 0 : values.contractExpiry, isPastDisable: true, isFutureDisable: false, minDate: disablePresent() }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "contractExpiry" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "totalSourceFe" }, function () {
                            return (React.createElement(text_filed_component_1.default, { name: "totalSourceFe", placeholder: 'Total Sourcing FEs', label: "Total Sourcing FEs", required: false, minLength: 1, maxLength: 10, defaultVal: values === null || values === void 0 ? void 0 : values.totalSourceFe, value: values === null || values === void 0 ? void 0 : values.totalSourceFe, onChange: function (e) {
                                    setFieldTouched('totalSourceFe', true);
                                    setFieldValue('totalSourceFe', e);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "totalSourceFe" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "parent_contract_id" }, function () {
                            return (React.createElement(text_filed_component_1.default, { name: "parent_contract_id", placeholder: 'Parent Contract Id', label: "Parent Contract Id", required: false, minLength: 1, maxLength: 20, defaultVal: values === null || values === void 0 ? void 0 : values.parent_contract_id, value: values === null || values === void 0 ? void 0 : values.parent_contract_id, onChange: function (e) {
                                    setFieldTouched('parent_contract_id', true);
                                    setFieldValue('parent_contract_id', e);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "parent_contract_id" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "operatingState" }, function () {
                            return (React.createElement(multi_selector_1.default, { label: "Select States", size: "dropdown-lg", requiredClass: "validate-filed", dropDownList: stateList &&
                                    stateList.sort(function (a, b) {
                                        return a.label.localeCompare(b.label);
                                    }), onChangeHnadlre: function (value) {
                                    console.log(value, 'value state');
                                    setFieldTouched('operatingState', true);
                                    setFieldValue('operatingcity', '');
                                    setFieldValue('operatingState', value);
                                    setSelectedStates(value);
                                    setSelectedCities([]);
                                    setCitiesData([]);
                                }, value: selectedStates, key: 'stateList' }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "operatingState" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "operatingcity" }, function () {
                            return (React.createElement(multi_selector_1.default, { noOptions: 'No cities found', hasSelectAll: true, label: "Select Cities", size: "dropdown-lg", requiredClass: "validate-filed", dropDownList: citiesData &&
                                    citiesData.sort(function (a, b) {
                                        return a.label.localeCompare(b.label);
                                    }), onChangeHnadlre: function (value) {
                                    console.log(value, 'value');
                                    setFieldTouched('operatingcity', true);
                                    setFieldValue('operatingcity', value);
                                    setSelectedCities(value);
                                    var city = (0, array_helpers_1.convertOperatingPlaces)(value);
                                    setCityPaylioad(city);
                                    createCityPayload(city);
                                    if (location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor))) {
                                        setEditCityPaylioad(city);
                                        editCityPayloadData(city);
                                    }
                                }, value: selectedCities || [] }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "operatingcity" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 9 },
                        React.createElement(formik_1.Field, { name: "contractAgreement" }, function () {
                            return (React.createElement(file_upload_component_1.default, { buttonName: "Attach Contract Agreement", name: "contractAgreement", message: "Note:Only png, jpg, jpeg, pdf formats are allowed upto 2MB", value: values === null || values === void 0 ? void 0 : values.contractAgreement, acceptedFiletypes: [
                                    'image/jpeg',
                                    'image/png',
                                    'application/pdf',
                                ], maxSize: {
                                    image: 2,
                                    pdf: 2,
                                    csv: 2,
                                }, onChange: function (e) {
                                    setFieldValue('contractAgreement', e);
                                    setFieldTouched('contractAgreement', true);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "contractAgreement" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); }))),
                React.createElement("div", { className: "form-btns" },
                    React.createElement(button_component_1.default, { buttonName: "Back", color: "yellow-outline", onClickHandler: function () {
                            if (location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor))) {
                                backButtonhandler("".concat(route_path_1.default.PATHS.editVendor), vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTACTDETAILS);
                                updateValue(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTACTDETAILS);
                            }
                            else {
                                backButtonhandler("".concat(route_path_1.default.PATHS.createVendor), vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTACTDETAILS);
                                updateValue(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTACTDETAILS);
                            }
                        } }),
                    React.createElement(button_component_1.default, { buttonName: "Save & Continue", isError: isSubmitting, color: "yellow", onClickHandler: function () {
                            buttonClicked = '';
                            submitForm();
                        } }),
                    enableSendItForApproval && (React.createElement(button_component_1.default, { isError: isSubmitting, buttonName: "Send it for approval", color: "blue", onClickHandler: function () {
                            buttonClicked = 'sendForApproval';
                            submitForm();
                        } })))));
        })));
};
exports.default = Contract_Details;
