"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var button_component_1 = require("../../../../common/buttons/button_component");
var ModalComponentWithoutButton_1 = require("../../../../common/modal/ModalComponentWithoutButton");
var ViewComments = function (_a) {
    var clickHandlere = _a.clickHandlere, isModalOpen = _a.isModalOpen, rejection_comments = _a.rejection_comments;
    var _b = React.useState(false), openModal = _b[0], setIsModalOpned = _b[1];
    var openModalHandlere = function () {
        setIsModalOpned(isModalOpen);
        clickHandlere();
    };
    var closeModalHandlere = function () {
        setIsModalOpned(false);
    };
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { disabled: rejection_comments ? false : true, className: "icon-btn", onClick: openModalHandlere },
            React.createElement("span", { className: "icon-Vector" })),
        React.createElement(ModalComponentWithoutButton_1.default, { open: openModal },
            React.createElement("div", { className: "modal-body" },
                React.createElement(material_1.Box, { className: "form-container", pb: 1.5 },
                    React.createElement(material_1.Typography, { variant: "h2", mb: 3, className: "heading" }, "Initiation Comments"),
                    React.createElement(material_1.Typography, { className: "comments" }, rejection_comments ? rejection_comments : '--')),
                React.createElement(button_component_1.default, { buttonName: "OK", color: "yellow", onClickHandler: closeModalHandlere })))));
};
exports.default = ViewComments;
