"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../actions/config_action");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var auto_routing_api_calls_1 = require("./auto_routing_api_calls");
var auto_routing_helper_1 = require("./auto_routing_helper");
var OnDemandPickupList = function (_a) {
    var onDemandFilter = _a.onDemandFilter, selectedLab = _a.selectedLab, payloadexport = _a.payloadexport, PageLimit = _a.PageLimit, setReloadPage = _a.setReloadPage, setDrawer = _a.setDrawer;
    var dispatch = (0, react_redux_1.useDispatch)();
    var nav = (0, react_router_1.useNavigate)();
    var _b = React.useState(undefined), onDemandList = _b[0], setOnDemandList = _b[1];
    var _c = React.useState(''), header = _c[0], setHeader = _c[1];
    var _d = React.useState(0), totalPageCount = _d[0], setTotalPageCount = _d[1];
    var _e = React.useState(1), pageCount = _e[0], setpageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var _g = React.useState(false), open = _g[0], setOpen = _g[1];
    var _h = React.useState(''), errorMessage = _h[0], setErrorMessage = _h[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Control_tower);
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.sub_my_reports,
            subChaildModule: '',
        }));
        nav("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var refetch = (0, react_query_1.useQuery)(['static-clients-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        payloadexport['page'] = currentPage;
        payloadexport['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payloadexport['filter'] = onDemandFilter;
        (0, auto_routing_api_calls_1.onDemandPickupList)(payloadexport)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setTotalCount(response.count);
            if (onDemandFilter === 1) {
                var convertedData = (0, array_helpers_1.convertOndemandPickupsListFromRes)(response.result);
                setOnDemandList(convertedData);
            }
            else
                setOnDemandList(response.result);
        })
            .catch(function (e) {
            setErrorMessage(e);
            setOnDemandList([]);
            console.log(e);
        });
        if (onDemandFilter === 1) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickups);
        }
        else if (onDemandFilter === 2) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickupAssigned);
        }
        else if (onDemandFilter === 3) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandPickupUnassigned);
        }
        else if (onDemandFilter === 5) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandReject);
        }
        else if (onDemandFilter === 6) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandProcessingPickups);
        }
        else {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.onDemandProductivity);
        }
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [onDemandFilter, PageLimit, selectedLab, payloadexport]);
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        payloadexport['page'] = currentPage;
        payloadexport['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payloadexport['filter'] = onDemandFilter;
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.auto_routing_on_demand_generate,
            payLoad: payloadexport,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var retryHandler = function () {
        var payload = {};
        payload['visit_ids'] = [154354];
        if (onDemandList.length > 0) {
            payload['visit_ids'] = onDemandList.map(function (visits) {
                return visits.visit_id;
            });
        }
        (0, auto_routing_api_calls_1.retryList)(payload)
            .then(function (response) {
            if (response.statusCode === 200) {
                setDrawer(false);
                setReloadPage(true);
            }
            else {
                react_hot_toast_1.default.error(response.message, { duration: 2000 });
            }
        })
            .catch(function (e) {
            react_hot_toast_1.default.error(e.message);
        });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "mid-page-heaidng create-ondemand-button" },
            React.createElement("h2", { className: "F-18" }, "".concat(header)),
            React.createElement("div", { className: "displayflex" },
                onDemandFilter !== 6 && (React.createElement("div", { className: "button-style" },
                    React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, color: "yellow-outline", endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler, isError: !features[menu_constants_1.FEATURE_CONSTANTS.Control_tower_Download] ||
                            (onDemandList === null || onDemandList === void 0 ? void 0 : onDemandList.length) <= 0 }))),
                onDemandFilter === 6 && (React.createElement(button_component_1.default, { buttonName: 'Re-process', color: "yellow-outline", onClickHandler: retryHandler, isError: (onDemandList === null || onDemandList === void 0 ? void 0 : onDemandList.length) <= 0 })))),
        React.createElement("div", { className: "clickable-row custom-pagination-table control-tower-modal-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: onDemandFilter === 1
                    ? table_data_1.default.ONDEMAND_PICKUP_COLUMNS
                    : onDemandFilter === 5
                        ? table_data_1.default.ONDEMAND_REJECTED_COLUMNS
                        : onDemandFilter === 6
                            ? table_data_1.default.ONDEMAND_PROCESSING_PICKUPS_COLUMNS
                            : table_data_1.default.ONDEMAND_UNASSIGNED_COLUMNS, rows: onDemandList, pagination: false, errorMessage: errorMessage })),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: onDemandList === null || onDemandList === void 0 ? void 0 : onDemandList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = OnDemandPickupList;
