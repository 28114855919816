"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var moment = require("moment");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var table_data_1 = require("../../../../constants/table_data");
var reports_helper_1 = require("../reports_helper");
var react_query_1 = require("react-query");
var AssignedCompletedList = function (_a) {
    var searchTerm = _a.searchTerm, filterFromDate = _a.filterFromDate, filterToDate = _a.filterToDate, selectedLab = _a.selectedLab, PageLimit = _a.PageLimit, visitType = _a.visitType, setIsReportList = _a.setIsReportList, isFilter = _a.isFilter;
    var _b = React.useState(undefined), reportList = _b[0], setReportsList = _b[1];
    var _c = React.useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var refetch = (0, react_query_1.useQuery)(['AllReportsList', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if ((searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.trim().length) >= 3) {
            payload['mobile'] = searchTerm.toLocaleLowerCase().trim();
        }
        if (filterFromDate && filterToDate) {
            payload['start_date'] = moment(filterFromDate).format('YYYY-MM-DD');
            payload['end_date'] = moment(filterToDate).format('YYYY-MM-DD');
        }
        if (selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.length) {
            payload['lab_code'] = selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.map(function (data) {
                return data.value.toString();
            });
        }
        if (visitType !== null) {
            payload['visit_status'] = visitType.id;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, reports_helper_1.getCompletedAssignedList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setIsReportList(response.data.result > 0 && true);
            setIsReportList(response.data.result);
            setTotalPageCount(count);
            setReportsList(response.data.result);
            setTotalCount(response.data.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setReportsList([]);
        });
    }).refetch;
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [isFilter]);
    return (React.createElement("div", { className: "fe-wrapper custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ASSIGNED_COMPLETED_COLUMNS, rows: reportList, errorMessage: errorMessage, pagination: false, enableCellTextSelection: true }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: reportList === null || reportList === void 0 ? void 0 : reportList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })));
};
exports.default = AssignedCompletedList;
