"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var simple_element_resize_detector_1 = require("simple-element-resize-detector");
var images_1 = require("../../constants/images");
var map_constants_1 = require("../../constants/map_constants");
var button_component_1 = require("../buttons/button_component");
var modal_component_1 = require("../modal/modal_component");
var mapUtils_1 = require("./../../utils/mapUtils");
require("./map-modal.scss");
var SearchOnMap_1 = require("./SearchOnMap");
var HereMapComponent = (function (_super) {
    __extends(HereMapComponent, _super);
    function HereMapComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.onSuccess = function (result) {
            _this.setState({ feCurrentLocation: result.items[0].address.label });
        };
        _this.onError = function (error) {
            react_hot_toast_1.toast.error('not able to find address of selected location');
        };
        _this.reverseGeocode = function (platform, lat, lng) {
            var geocoder = platform.getSearchService(), reverseGeocodingParameters = {
                at: "".concat(lat, ",").concat(lng),
                limit: '1',
            };
            geocoder.reverseGeocode(reverseGeocodingParameters, _this.onSuccess, _this.onError);
        };
        _this.handleSearch = function (address, lat, lng) { return __awaiter(_this, void 0, void 0, function () {
            var fe_marker, behv, map, thisObj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(address, 'data');
                        if (this._markerInstance) {
                            console.log(this._markerInstance, 'yes');
                            this.map.removeObjects(this.map.getObjects());
                        }
                        this._markerInstance = new H.map.Marker({ lat: lat, lng: lng }, {
                            data: [],
                            volatility: true,
                        });
                        fe_marker = new H.map.Icon(images_1.default.map_pointer_icon, map_constants_1.FE_ICONS_SIZE);
                        this._markerInstance.setIcon(fe_marker);
                        this._markerInstance.draggable = true;
                        this.map.addObject(this._markerInstance);
                        this.map.setCenter({ lat: lat, lng: lng });
                        this.map.setZoom(17);
                        behv = this.behaviour;
                        map = this.map;
                        thisObj = this;
                        this.map.addEventListener('dragstart', function (ev) {
                            var target = ev.target, pointer = ev.currentPointer;
                            console.log('target inside drag start event:', target);
                            if (target instanceof H.map.Marker) {
                                var targetPosition = map.geoToScreen(target.getGeometry());
                                target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
                                behv.disable();
                            }
                        }, false);
                        this.map.addEventListener('dragend', function (ev) {
                            var target = ev.target;
                            console.log('target inside drag end event:', target);
                            if (target instanceof H.map.Marker) {
                                behv.enable();
                            }
                            thisObj.reverseGeocode(thisObj.platform, lat, lng);
                        }, false);
                        this.map.addEventListener('drag', function (ev) {
                            var target = ev.target, pointer = ev.currentPointer;
                            console.log('target inside drag event:', target, pointer, address);
                            if (target instanceof H.map.Marker) {
                                target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
                                lat = target['a'].lat;
                                lng = target['a'].lng;
                                thisObj.setState({ latAndLng: "".concat(lat, ",").concat(lng) });
                            }
                        }, false);
                        this.setState({ latAndLng: "".concat(lat, ",").concat(lng) });
                        if (!address) return [3, 1];
                        this.setState({ feCurrentLocation: address });
                        return [3, 3];
                    case 1: return [4, thisObj.reverseGeocode(thisObj.platform, lat, lng)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2];
                }
            });
        }); };
        _this.handleClear = function () {
            _this.setState({
                latAndLng: '',
                feCurrentLocation: '',
            });
        };
        _this.state = {
            latAndLng: '',
            feCurrentLocation: '',
            open: false,
        };
        _this.ref = React.createRef();
        _this.map = null;
        return _this;
    }
    HereMapComponent.prototype.componentDidMount = function () {
        if (!this.map) {
            this.hereMapUtilsInstance = new mapUtils_1.default(this.ref.current);
            var mapInstance_1 = this.hereMapUtilsInstance.instance;
            var behv = this.hereMapUtilsInstance.behaviour;
            var mapPlatform = this.hereMapUtilsInstance.platform;
            (0, simple_element_resize_detector_1.default)(this.ref.current, function () {
                mapInstance_1.getViewPort().resize();
            });
            this.map = mapInstance_1;
            this.behaviour = behv;
            this.platform = mapPlatform;
            this.draggedAddress = '';
        }
        if (this.props.baselocation_address && this.props.bast_lat_lng) {
            this.handleSearch(this.props.baselocation_address, this.props.bast_lat_lng.split(',')[0], this.props.bast_lat_lng.split(',')[1]);
        }
        else if (this.props.lab_lat_lng) {
            console.log(this.props.lab_lat_lng, 'lab_lat_lng');
            if (this.props.lab_lat_lng.lab_latittude &&
                this.props.lab_lat_lng.lab_longittude) {
                this.map.setCenter({
                    lat: this.props.lab_lat_lng.lab_latittude,
                    lng: this.props.lab_lat_lng.lab_longittude,
                });
                this.map.setZoom(17);
            }
        }
    };
    HereMapComponent.prototype.render = function () {
        var _this = this;
        console.log(this.props.bast_lat_lng, this.props.baselocation_address, 'coordinates');
        return (React.createElement("div", { className: "modal-wrapper map-modal-wrapper modal-component " },
            React.createElement("div", { className: "modal-component map-component" },
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "lat-log-wrapper" }, this.state.latAndLng),
                    React.createElement("div", { className: "modal-map-wrapper" },
                        React.createElement("div", { className: "location-search-wrapper" },
                            React.createElement(SearchOnMap_1.default, { handlereValue: this.handleSearch, value: this.state.feCurrentLocation, clearHandler: this.handleClear })),
                        React.createElement("div", { className: "map-container", ref: this.ref })),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return _this.props.close(); } }),
                        React.createElement(modal_component_1.default, { buttonName: "Submit", buttonColor: "error-btn", onClickHandler: function () {
                                if (_this.state.latAndLng) {
                                    _this.setState({ open: true });
                                }
                                else {
                                    react_hot_toast_1.toast.error('Please select an address', { duration: 5000 });
                                }
                            }, open: this.state.open },
                            React.createElement("div", { className: "modal-header header-wrapper" },
                                React.createElement("h2", null, "Confirmation")),
                            React.createElement("div", { className: "modal-body" },
                                React.createElement("p", { className: "header-text" }, "Are you sure you want to change the base location?")),
                            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return _this.setState({ open: false }); } }),
                                React.createElement(button_component_1.default, { buttonName: "Submit", color: "yellow", onClickHandler: function () {
                                        return _this.props.submitSelectedPlace(_this.state.latAndLng, _this.state.feCurrentLocation);
                                    } }))))))));
    };
    return HereMapComponent;
}(React.Component));
exports.default = HereMapComponent;
