"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var route_path_1 = require("../../../../constants/route_path");
var ViewApprovalRoutePlanner = function (_a) {
    var routeDetails = _a.routeDetails;
    var nav = (0, react_router_1.useNavigate)();
    var veiewRoutePlanner = function () {
        nav("/".concat(route_path_1.PATHS.approvals_route_planner, "/").concat(route_path_1.PATHS.route_planner_details, "/").concat(routeDetails.id));
    };
    return (React.createElement("div", null,
        React.createElement(button_component_1.default, { buttonName: 'View Route', onClickHandler: veiewRoutePlanner })));
};
exports.default = ViewApprovalRoutePlanner;
