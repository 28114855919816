"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../../../common/buttons/button_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../reports_helper");
var RLabtoPLabIntercityReport = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = React.useState(undefined), dataList = _a[0], setDataList = _a[1];
    var _b = React.useState(''), filterFromDate = _b[0], setFromDateFilter = _b[1];
    var _c = React.useState(''), filterToDate = _c[0], setToDateFilter = _c[1];
    var _d = React.useState(false), isDateRangeUpdated = _d[0], setIsDateRangeUpdated = _d[1];
    var _e = React.useState(''), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = React.useState([]), filterSelectedRLabs = _f[0], setSelectedRFilterLabs = _f[1];
    var _g = React.useState([]), filterSelectedPLabs = _g[0], setSelectedPFilterLabs = _g[1];
    var _h = React.useState(1), pageCount = _h[0], setpageCount = _h[1];
    var _j = React.useState(0), totalPageCount = _j[0], setTotalPageCount = _j[1];
    var _k = React.useState(appConstants_1.defaultPageLimit), PageLimit = _k[0], setPageLimit = _k[1];
    var _l = React.useState(0), totalCount = _l[0], setTotalCount = _l[1];
    var _m = React.useState(false), modelOpen = _m[0], setModalOpen = _m[1];
    var _o = React.useState(false), filterButton = _o[0], setFilterbutton = _o[1];
    var LabcodeList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var rlabCodeChangeHandler = function (val) {
        setSelectedRFilterLabs(val);
    };
    var plabCodeChangeHandler = function (val) {
        setSelectedPFilterLabs(val);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var navigateToMyReports = function () {
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var donwloadVisitHandlere = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (filterFromDate && filterToDate !== null) {
            payload['date_range'] = {
                start_date: filterFromDate,
                end_date: filterToDate,
            };
        }
        if (filterSelectedRLabs.length > 0) {
            payload['r_lab_code'] = filterSelectedRLabs === null || filterSelectedRLabs === void 0 ? void 0 : filterSelectedRLabs.map(function (data) {
                return data.value.toString();
            });
        }
        if (filterSelectedPLabs.length > 0) {
            payload['p_lab_code'] = filterSelectedPLabs === null || filterSelectedPLabs === void 0 ? void 0 : filterSelectedPLabs.map(function (data) {
                return data.value.toString();
            });
        }
        payload['limit'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, reports_helper_1.downloadRlabToPlabReports)(payload)
            .then(function (res) {
            if (res.statusCode === 200) {
                setModalOpen(true);
            }
            else {
                react_hot_toast_1.default.error(res.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['Rlab-Plab', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (filterFromDate && filterToDate !== null) {
            payload['date_range'] = {
                start_date: filterFromDate,
                end_date: filterToDate,
            };
        }
        if (filterSelectedRLabs.length > 0) {
            payload['r_lab_code'] =
                filterSelectedRLabs.length >= 30
                    ? ['ALL']
                    : filterSelectedRLabs === null || filterSelectedRLabs === void 0 ? void 0 : filterSelectedRLabs.map(function (data) {
                        return data.value.toString();
                    });
        }
        if (filterSelectedPLabs.length > 0) {
            payload['p_lab_code'] =
                filterSelectedPLabs.length >= 30
                    ? ['ALL']
                    : filterSelectedPLabs === null || filterSelectedPLabs === void 0 ? void 0 : filterSelectedPLabs.map(function (data) {
                        return data.value.toString();
                    });
        }
        payload['limit'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, reports_helper_1.RlabToPlabReports)(payload)
            .then(function (response) {
            console.log('fjhsj', response);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            console.log(pageSize, 'pageSize');
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setTotalCount(response.count);
            setDataList(response.result);
        })
            .catch(function (err) {
            setErrorMessage(err);
            react_hot_toast_1.default.error(err);
            setDataList([]);
        });
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [filterButton]);
    var FilterHandler = function () {
        setFilterbutton(!filterButton);
    };
    return (React.createElement("div", { className: "fe-wrapper pay  -fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Rlab-Plab Report")),
            React.createElement(button_component_1.default, { type: "button", buttonName: appConstants_1.reportDownload, endIcon: "icon-import download-icon", color: "yellow-outline", onClickHandler: donwloadVisitHandlere, isError: !(dataList === null || dataList === void 0 ? void 0 : dataList.length) })),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "filter-options" },
                React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "R Lab Code", dropDownList: LabcodeList &&
                        (0, array_helpers_1.convertLabcodeResponse)(LabcodeList).sort(function (a, b) {
                            return a.label.localeCompare(b.label);
                        }), onChangeHnadlre: rlabCodeChangeHandler, value: filterSelectedRLabs || [] }),
                React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "P Lab Code", dropDownList: LabcodeList &&
                        (0, array_helpers_1.convertLabcodeResponse)(LabcodeList).sort(function (a, b) {
                            return a.label.localeCompare(b.label);
                        }), onChangeHnadlre: plabCodeChangeHandler, value: filterSelectedPLabs || [] }),
                React.createElement(text_filed_with_fromto_Daterange_1.default, { presentDate: true, onChangeHandler: callBackOnDateRangeSelect }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.DASHBOARD_GRAPHS_RLABTOPLAB_REPORT_COLUMNS, rows: dataList, errorMessage: errorMessage, enableCellTextSelection: true, pagination: false, className: 'cash-collection-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(pop_up_1.default, { open: modelOpen },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setModalOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = RLabtoPLabIntercityReport;
