"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var button_component_1 = require("../../../common/buttons/button_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var table_data_1 = require("../../../constants/table_data");
var array_helpers_1 = require("../../../utils/array_helpers");
var report_dashboard_service_1 = require("../service/report_dashboard_service");
var RLabPLabGraphList = function (_a) {
    var list = _a.list;
    var downloadReport = function () {
        var convertedData = (0, report_dashboard_service_1.convertRlabPlabData)(list);
        (0, array_helpers_1.downloadExcel)(convertedData, 'r_lab_p_lab_list.xlsx', report_dashboard_service_1.downloadExcelHeadersForR_lab_Plab, 'R Lab P Lab List');
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "mid-page-heaidng" },
            React.createElement("h2", { className: "F-18" }, "Intercity R Lab To P Lab"),
            React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, color: "yellow-outline", endIcon: "icon-import download-icon", isError: list && list.length > 0 ? false : true, onClickHandler: function () { return downloadReport(); } })),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.DASHBOARD_GRAPHS_RLABTOPLAB_REPORT_COLUMNS, rows: list, pagination: true, enableCellTextSelection: true, className: 'bagging-lifecycle-table intracity-pagination' }))));
};
exports.default = RLabPLabGraphList;
