"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var button_component_1 = require("../../common/buttons/button_component");
[];
var LabMasterTimeslots = function (_a) {
    var _b;
    var onHandler = _a.onHandler, addedData = _a.addedData, setAddedData = _a.setAddedData, dayset = _a.dayset, onChange = _a.onChange, apislotdetails = _a.apislotdetails, selectedDay = _a.selectedDay, selectedSlots = _a.selectedSlots, selectedslotsObject = _a.selectedslotsObject, isDayselected = _a.isDayselected, daySlots = _a.daySlots, setDaySlots = _a.setDaySlots, slotid = _a.slotid, timeSlotsList = _a.timeSlotsList, setTimeSlotList = _a.setTimeSlotList, editData = _a.editData, weekDay = _a.weekDay, disabled = _a.disabled, setSelectedList = _a.setSelectedList, selectedList = _a.selectedList, weekdayid = _a.weekdayid;
    console.log(selectedList, 'selectedList');
    var clickHandlerOnTimeslot = function (OnchangeSlotValues) {
        var _a;
        console.log(OnchangeSlotValues, '87tuygnb');
        var setColor;
        if (OnchangeSlotValues.color) {
            console.log(selectedList, 'selectedList');
            var filterunSlectedList_1 = selectedList.find(function (val) {
                return val.parentslot.trim() == OnchangeSlotValues.from_time.trim() &&
                    val.weekday == weekdayid;
            });
            var selectedIndex_1 = selectedList.findIndex(function (val) {
                return val.parentslot.trim() == OnchangeSlotValues.from_time.trim() &&
                    val.weekday == weekdayid;
            });
            console.log('uiyghc', filterunSlectedList_1);
            if (filterunSlectedList_1) {
                console.log(filterunSlectedList_1, 'filterunSlectedList');
                setColor = daySlots["day".concat(weekDay)].map(function (slotObject, index) {
                    if ((filterunSlectedList_1.parentName == slotObject.name ||
                        filterunSlectedList_1.parentslot.trim() ==
                            slotObject.from_time.trim()) &&
                        filterunSlectedList_1.weekday == weekdayid) {
                        console.log(selectedList.weekday, weekdayid, 'oikhg');
                        slotObject['color'] = null;
                        slotObject['onehourslot'] = null;
                        selectedList.splice(selectedIndex_1, 1);
                        return slotObject;
                    }
                    if (filterunSlectedList_1.childName == slotObject.name ||
                        filterunSlectedList_1.childslot.trim() == slotObject.to_time.trim()) {
                        console.log('uyfgv');
                        slotObject['color'] = null;
                        slotObject['onehourslot'] = null;
                        return slotObject;
                    }
                    return slotObject;
                });
            }
            else {
                react_hot_toast_1.default.error('Child slot cannot be removed');
                return daySlots;
            }
        }
        else {
            if (selectedList.length > 0) {
                setColor = daySlots["day".concat(weekDay)].map(function (slotObject, index) {
                    if (slotObject.id === OnchangeSlotValues.id) {
                        console.log(daySlots["day".concat(weekDay)][index + 1].to_time, 'dddddddddddddd', daySlots["day".concat(weekDay)][index].from_time);
                        var filterObj = selectedList.find(function (val) {
                            return (val.parentName == daySlots["day".concat(weekDay)][index].name ||
                                val.parentslot.trim() == daySlots["day".concat(weekDay)][index + 1].from_time.trim()) && (val.weekday == weekdayid);
                        });
                        console.log(filterObj, 'filterObj');
                        selectedList;
                        if (filterObj) {
                            react_hot_toast_1.default.error('No Child for selected slots');
                        }
                        else {
                            slotObject['color'] = 'slot-btn status-selected';
                            daySlots["day".concat(weekDay)][index + 1]['color'] =
                                'slot-btn status-selected';
                            slotObject['onehourslot'] = "".concat(slotObject.from_time, " - ").concat(daySlots["day".concat(weekDay)][index + 1].to_time);
                            setSelectedList(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                                {
                                    parentslot: slotObject.from_time,
                                    childslot: daySlots["day".concat(weekDay)][index + 1].to_time,
                                    parentName: slotObject.name,
                                    childName: daySlots["day".concat(weekDay)][index + 1].name,
                                    weekday: weekdayid,
                                },
                            ], false); });
                            return slotObject;
                        }
                        return slotObject;
                    }
                    return slotObject;
                });
            }
            else {
                setColor = daySlots["day".concat(weekDay)].map(function (slotObject, index) {
                    if (slotObject.id === OnchangeSlotValues.id) {
                        slotObject['color'] = 'slot-btn status-selected';
                        daySlots["day".concat(weekDay)][index + 1]['color'] = 'slot-btn status-selected';
                        slotObject['onehourslot'] = "".concat(slotObject.from_time, " - ").concat(daySlots["day".concat(weekDay)][index + 1].to_time);
                        setSelectedList(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                            {
                                parentslot: slotObject.from_time,
                                childslot: daySlots["day".concat(weekDay)][index + 1].to_time,
                                parentName: slotObject.name,
                                childName: daySlots["day".concat(weekDay)][index + 1].name,
                                weekday: weekdayid,
                            },
                        ], false); });
                        return slotObject;
                    }
                    return slotObject;
                });
            }
        }
        setDaySlots(__assign(__assign({}, daySlots), (_a = {}, _a["day".concat(weekDay)] = setColor, _a)));
    };
    console.log(daySlots, 'daySlots');
    return (React.createElement("div", { className: "time-slot-wrapper" },
        React.createElement("div", { className: "time-slot-booking-group" },
            React.createElement("div", { className: "slot-wrapper" },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 14, xl: 16 } }, (_b = daySlots["day".concat(weekDay)]) === null || _b === void 0 ? void 0 : _b.map(function (slotDetails) {
                    return (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 2, xl: 2 },
                        React.createElement(button_component_1.default, { buttonName: "".concat(slotDetails.from_time, " - ").concat(slotDetails.to_time), color: slotDetails.color
                                ? slotDetails.color
                                : 'slot-btn status-existing', onClickHandler: function () {
                                clickHandlerOnTimeslot(slotDetails);
                            }, isError: disabled })));
                }))))));
};
exports.default = LabMasterTimeslots;
