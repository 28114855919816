"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var axios_1 = require("axios");
var React = require("react");
var array_helpers_1 = require("../../utils/array_helpers");
var lodash_1 = require("../../utils/lodash");
var SearchOnMap = function (_a) {
    var handlereValue = _a.handlereValue, value = _a.value, clearHandler = _a.clearHandler;
    var _b = React.useState([]), addressList = _b[0], setAddressList = _b[1];
    var fetchAddressList = function (searchValue) {
        var url = "https://autosuggest.search.hereapi.com/v1/autosuggest?at=52.93175,12.77165&limit=5&lang=en&q=".concat(searchValue, "&apiKey=").concat(process.env.HERE_MAPS_API_KEY);
        axios_1.default
            .get(url)
            .then(function (res) {
            var convartLabelFomate = (0, array_helpers_1.mapAddressList)(res.data.items);
            setAddressList(convartLabelFomate);
        })
            .catch(function (e) {
            console.log(e);
        });
    };
    var handleOnSearch = lodash_1.default.debounce(function (searchValue, results) {
        var latAndLong = searchValue.split(',');
        if (!isNaN(parseInt(latAndLong[0])) &&
            latAndLong[0].indexOf('.') != -1 &&
            !isNaN(parseInt(latAndLong[1])) &&
            latAndLong[1].indexOf('.') != -1) {
            handlereValue('', latAndLong[0], latAndLong[1]);
        }
        else {
            fetchAddressList(searchValue);
        }
    }, 500);
    var onCloseOfAutoComplete = function () {
        setAddressList([]);
    };
    return (React.createElement("div", { className: "here-map-dropdown" },
        React.createElement("div", { className: "map-search-wrapper" },
            React.createElement(material_1.Autocomplete, { loading: false, loadingText: 'Searching...', disabled: false, disableClearable: false, options: addressList, value: value ? value : null, onChange: function (event, val) {
                    if (val) {
                        handlereValue("".concat(val.label), val.lat, val.lng);
                    }
                    else {
                        clearHandler();
                    }
                }, onClose: onCloseOfAutoComplete, renderInput: function (params) { return (React.createElement("div", { className: 'input-wrapper' },
                    React.createElement(material_1.TextField, __assign({}, params, { name: 'search', label: 'Search Base Location', placeholder: 'Search Base Location', variant: "filled", onChange: function (e) {
                            handleOnSearch(e.target.value);
                        } })))); } }))));
};
exports.default = SearchOnMap;
