"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var button_component_1 = require("../../common/buttons/button_component");
var text_filed_component_1 = require("../../common/text_filed/text_filed_component");
var schema_1 = require("../../constants/schema");
require("./LoginComponent.scss");
var login_apicalls_1 = require("./login_apicalls");
var ForgotPasswordComponent = function () {
    var nav = (0, react_router_1.useNavigate)();
    var clickHandler = function () {
        nav('/');
    };
    var formSubmitHandler = function (values) {
        var payload = {
            username: values.email,
        };
        (0, login_apicalls_1.forgetPassword)(payload)
            .then(function (response) {
            if (response.status === 200) {
                react_hot_toast_1.default.success(response.message, {
                    duration: 4000,
                });
            }
            else {
                react_hot_toast_1.default.error(response.message, {
                    duration: 2000,
                });
            }
        })
            .catch(function (e) {
            react_hot_toast_1.default.error(e.message);
        });
    };
    return (React.createElement("div", { className: "login-wrapper" },
        React.createElement("div", { className: "login-container" },
            React.createElement("div", { className: "brand-logo" },
                React.createElement("img", { src: require('../../../assets/images/logo.svg'), alt: "logo", className: "login-log" })),
            React.createElement("div", { className: "form-filed-wrapper" },
                React.createElement(formik_1.Formik, { initialValues: {
                        email: '',
                        anotherEmail: '',
                    }, validateOnBlur: false, validationSchema: schema_1.schemas.FORGOT_PASSWORD_SCHEMA, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper login-fieldsr" },
                        React.createElement(text_filed_component_1.default, { label: "Please Enter Email", name: "email", onChange: function (val) {
                                setFieldValue('email', val);
                                setFieldTouched('email', true);
                            } }),
                        errors['email'] && touched['email'] ? (React.createElement("div", { className: "error" }, errors['email'])) : null,
                        React.createElement("div", { className: "form-btns login-btns" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Back", color: "yellow-outline", onClickHandler: clickHandler }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Reset Password", color: "yellow-outline" }))));
                })))));
};
exports.default = ForgotPasswordComponent;
