"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var approvals_helper_1 = require("./approvals_helper");
var LevelTwoVendorPendingList = function (_a) {
    var searchTerm = _a.searchTerm, isDateRangeUpdated = _a.isDateRangeUpdated, isFilterButton = _a.isFilterButton;
    var nav = (0, react_router_1.useNavigate)();
    var pathname = (0, react_router_1.useLocation)().pathname;
    var _b = React.useState(undefined), pendingList = _b[0], setPendingList = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var onRowClickedHandler = function (event) {
        if (pathname.includes(route_path_1.default.PATHS.approvals)) {
            nav("/".concat(route_path_1.default.PATHS.approvals, "/").concat(route_path_1.default.PATHS.vendorlist, "/").concat(route_path_1.default.PATHS.vendorLevelTwoDetails, "/").concat(event.data.axapta_code, "/").concat(event.data.onboard_status));
        }
    };
    var getPendingList = function (payLoad) {
        (0, approvals_helper_1.levelTwoVendorPendingList)(payLoad)
            .then(function (response) {
            setPendingList(response);
            setErrorMessage('');
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setPendingList([]);
            console.log(err.message);
        });
    };
    React.useEffect(function () {
        console.log(' searchTerm inside useEffect:', searchTerm);
        var payload = {};
        if (searchTerm) {
            payload['vendor_name'] = searchTerm;
        }
        getPendingList(payload);
    }, [isFilterButton]);
    return (React.createElement("div", { className: "fe-wrapper custom-pagination-table ".concat(pathname.includes(route_path_1.default.PATHS.approvals)
            ? 'clickable-row'
            : 'unclickablerow') },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.VENDOR_APPROVAL_LIST_COLUMN, rows: pendingList, errorMessage: errorMessage, onRowClicked: onRowClickedHandler, pagination: false, className: 'approvals-table' })));
};
exports.default = LevelTwoVendorPendingList;
