"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var array_helpers_1 = require("../../../utils/array_helpers");
var mis_reports_api_calls_1 = require("./mis_reports_api_calls");
var reports_helper_1 = require("./reports_helper");
var MisreportTab = function (_a) {
    var searchTerm = _a.searchTerm, selectedCities = _a.selectedCities, filterFromDate = _a.filterFromDate, filterToDate = _a.filterToDate, filetrLabs = _a.filetrLabs, PageLimit = _a.PageLimit, disableDumpDownloadHandler = _a.disableDumpDownloadHandler, isDateRangeUpdated = _a.isDateRangeUpdated, singledate = _a.singledate;
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _b = React.useState(undefined), reportList = _b[0], setReportsList = _b[1];
    var _c = React.useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState({}), gridParams = _f[0], setGridParams = _f[1];
    var _g = React.useState(0), totalCount = _g[0], setTotalCount = _g[1];
    var agGridTableHeaders = function (response, params) {
        var _a;
        var flattenKeys = Object.keys((0, array_helpers_1.flattenObj)(response === null || response === void 0 ? void 0 : response.data.result));
        var cityIndex = flattenKeys.findIndex(function (key) { return key === 'city'; });
        var Stateindex = flattenKeys.findIndex(function (key) { return key === 'state'; });
        if (Stateindex > -1 && cityIndex > -1) {
            flattenKeys[cityIndex] = 'state';
            flattenKeys[Stateindex] = 'city';
        }
        var colDef = [];
        if (flattenKeys.length) {
            flattenKeys.map(function (data) {
                var obj = {};
                obj['headerName'] = data.toLocaleUpperCase().replaceAll('_', ' ');
                obj['field'] = data;
                obj['suppressMovable'] = true;
                obj['unSortIcon'] = true;
                if (data === 'name') {
                    obj['lockPosition'] = 'left';
                    obj['headerName'] = 'FE NAME';
                    obj['tooltipField'] = data;
                }
                if (data === 'lab_id') {
                    obj['headerName'] = 'LAB CODE';
                }
                if (data === 'visit_date') {
                    obj['headerName'] = 'VISIT DATE';
                    obj['valueFormatter'] = array_helpers_1.dateFormatterForMIS;
                }
                if (data === 'lab_name') {
                    obj['tooltipField'] = data;
                }
                if (data === 'center_visit' || data === 'is_center_visit_started') {
                    obj['cellStyle'] = { textTransform: 'capitalize' };
                }
                if (data.includes('R-')) {
                    obj['lockPosition'] = 'right';
                }
                colDef.push(obj);
            });
        }
        (_a = params === null || params === void 0 ? void 0 : params.api) === null || _a === void 0 ? void 0 : _a.setColumnDefs(colDef);
    };
    var refetch = (0, react_query_1.useQuery)(['feRoute-list', pageCount, gridParams.toString()], function () {
        var payload = {};
        var currentPage = pageCount < 1 ? 1 : pageCount;
        if (searchTerm.trim().length >= 3) {
            payload.search_string = searchTerm.toLocaleLowerCase().trim();
        }
        if (singledate != null) {
            payload['visit_date'] = singledate;
        }
        if (selectedCities.length > 0) {
            console.log(selectedCities, 'filterSelectedCities');
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(selectedCities);
            payload['states'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['cities'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        if (filetrLabs.length > 0) {
            var labCode = (0, array_helpers_1.selecteAllOption)(filetrLabs, labList);
            payload['lab_codes'] = labCode;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, mis_reports_api_calls_1.fetchMisFeRouteeList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setTotalPageCount(count);
            agGridTableHeaders(response, gridParams);
            var data = response === null || response === void 0 ? void 0 : response.data.result.map(function (data) {
                return (0, array_helpers_1.flattenObj)(data);
            });
            var flattenKeys = Object.keys((0, array_helpers_1.flattenObj)(response === null || response === void 0 ? void 0 : response.data.result));
            var defaulValue = flattenKeys.reduce(function (result, key) {
                result[key] = '--';
                return result;
            }, {});
            var modifiedData = data.map(function (item) { return (__assign(__assign({}, defaulValue), item)); });
            console.log(modifiedData, 'modifiedData');
            if (response.data.result.length > 0) {
                disableDumpDownloadHandler(false);
            }
            else {
                disableDumpDownloadHandler(true);
            }
            setReportsList(modifiedData);
            setTotalCount(response.data.count);
            setErrorMessage('');
        })
            .catch(function (err) {
            console.log(err, "lkjhgfds");
            setErrorMessage(err.message);
            setReportsList([]);
        });
    }).refetch;
    var onGridReady = function (params) {
        setGridParams(params);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    React.useEffect(function () {
        if (Object.keys(gridParams).length != 0) {
            if (pageCount != 1) {
                setpageCount(1);
            }
            else {
                refetch();
            }
        }
    }, [
        selectedCities === null || selectedCities === void 0 ? void 0 : selectedCities.length,
        isDateRangeUpdated,
        filetrLabs,
        searchTerm,
        PageLimit,
        gridParams,
        singledate,
    ]);
    return (React.createElement("div", { className: "fe-wrapper custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, rows: reportList && reportList, onGridReady: onGridReady, errorMessage: errorMessage, pagination: false, enableCellTextSelection: true }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: reportList === null || reportList === void 0 ? void 0 : reportList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })));
};
exports.default = MisreportTab;
