"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var button_component_1 = require("../../common/buttons/button_component");
var text_field_password_1 = require("../../common/text_filed/text_field_password");
var schema_1 = require("../../constants/schema");
require("./LoginComponent.scss");
var login_apicalls_1 = require("./login_apicalls");
var ResetPassword = function () {
    var _a = React.useState(), authToken = _a[0], setAuthToken = _a[1];
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var clickHandler = function () {
        nav('/');
    };
    var fetchValidateForgetPswd = function () {
        var payload = {
            hash: id,
        };
        (0, login_apicalls_1.validateForgetPswd)(payload)
            .then(function (response) {
            if (response.status === 200) {
                setAuthToken(response.data.token);
            }
            else {
                nav('/');
            }
        })
            .catch(function (e) {
            nav('/');
        });
    };
    var formSubmitHandler = function (values) {
        if (authToken) {
            var payload = {
                newPassword: values.confirmPassword,
                hash: id,
            };
            var token = {
                'x-auth-token': authToken,
            };
            (0, login_apicalls_1.resetPassword)(payload, token)
                .then(function (response) {
                if (response.status === 200) {
                    react_hot_toast_1.default.success(response.message, {
                        duration: 1000,
                    });
                    nav('/');
                }
                else {
                    react_hot_toast_1.default.error(response.message, {
                        duration: 2000,
                    });
                }
            })
                .catch(function (e) {
                react_hot_toast_1.default.error(e.message);
            });
        }
    };
    React.useEffect(function () {
        fetchValidateForgetPswd();
    }, []);
    return (React.createElement("div", { className: "login-wrapper" },
        React.createElement("div", { className: "login-container" },
            React.createElement("div", { className: "brand-logo" },
                React.createElement("img", { src: require('../../../assets/images/logo.svg'), alt: "logo", className: "login-log" })),
            React.createElement("div", { className: "form-filed-wrapper" },
                React.createElement(formik_1.Formik, { initialValues: {
                        newPassword: '',
                        confirmPassword: '',
                    }, validateOnBlur: false, validateOnChange: false, validationSchema: schema_1.schemas.RESET_PASSWORD_SCHEMA, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper login-fieldsr" },
                        React.createElement(text_field_password_1.default, { label: "New Password", name: "newPassword", type: "password", onChange: function (val) {
                                setFieldValue('newPassword', val);
                                setFieldTouched('newPassword', true);
                            } }),
                        errors['newPassword'] && touched['newPassword'] ? (React.createElement("div", { className: "error" }, errors['newPassword'])) : null,
                        React.createElement(text_field_password_1.default, { label: "Confirm Password", name: "confirmPassword", type: "password", onChange: function (val) {
                                setFieldValue('confirmPassword', val);
                                setFieldTouched('confirmPassword', true);
                            } }),
                        errors['confirmPassword'] && touched['confirmPassword'] ? (React.createElement("div", { className: "error" }, errors['confirmPassword'])) : null,
                        React.createElement("div", { className: "form-btns login-btns" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Back", color: "yellow-outline", onClickHandler: clickHandler }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Reset Password", color: "yellow-outline" }))));
                })))));
};
exports.default = ResetPassword;
