"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var moment = require("moment");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var table_data_1 = require("../../../constants/table_data");
var array_helpers_1 = require("../../../utils/array_helpers");
var mis_reports_api_calls_1 = require("./mis_reports_api_calls");
var reports_helper_1 = require("./reports_helper");
var EventPerformance = function (_a) {
    var searchTerm = _a.searchTerm, selectedCities = _a.selectedCities, filterFromDate = _a.filterFromDate, filterToDate = _a.filterToDate, filetrLabs = _a.filetrLabs, PageLimit = _a.PageLimit, disableDumpDownloadHandler = _a.disableDumpDownloadHandler, isDateRangeUpdated = _a.isDateRangeUpdated;
    var _b = React.useState(undefined), reportList = _b[0], setReportsList = _b[1];
    var _c = React.useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var refetch = (0, react_query_1.useQuery)(['EventPerformance-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (searchTerm.trim().length >= 3) {
            payload.search_string = searchTerm.toLocaleLowerCase().trim();
        }
        if (filterFromDate && filterToDate !== null) {
            payload.date_range = {
                start_date: moment(filterFromDate).format('YYYY-MM-DD'),
                end_date: moment(filterToDate).format('YYYY-MM-DD'),
            };
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        if (selectedCities.length > 0) {
            console.log(selectedCities, 'filterSelectedCities');
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(selectedCities);
            payload['states'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['cities'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        if (filetrLabs.length > 0) {
            var labCode = (0, array_helpers_1.selecteAllOption)(filetrLabs, labList);
            payload['lab_code'] = labCode;
        }
        (0, mis_reports_api_calls_1.fetchEventPerformanceList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setErrorMessage("");
            setTotalPageCount(count);
            if (response.data.result.length > 0) {
                disableDumpDownloadHandler(false);
            }
            else {
                disableDumpDownloadHandler(true);
            }
            setReportsList(response.data.result);
            setTotalCount(response.data.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setReportsList([]);
        });
    }).refetch;
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [
        searchTerm,
        selectedCities === null || selectedCities === void 0 ? void 0 : selectedCities.length,
        filterToDate,
        PageLimit,
        filetrLabs === null || filetrLabs === void 0 ? void 0 : filetrLabs.length,
        isDateRangeUpdated,
    ]);
    return (React.createElement("div", { className: "fe-wrapper custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.MIS_REPORTS_EVENT_PERFORMANCE, rows: reportList, errorMessage: errorMessage, pagination: false, enableCellTextSelection: true }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: reportList === null || reportList === void 0 ? void 0 : reportList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })));
};
exports.default = EventPerformance;
