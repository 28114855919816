"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FiberManualRecord_1 = require("@mui/icons-material/FiberManualRecord");
var material_1 = require("@mui/material");
var React = require("react");
var react_infinite_scroll_component_1 = require("react-infinite-scroll-component");
var react_router_1 = require("react-router");
var avatar_component_1 = require("../../../../common/avatar/avatar_component");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var route_path_1 = require("../../../../constants/route_path");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var all_fe_map_view_1 = require("./all_fe_map_view");
var FETrackingList = function (_a) {
    var errorMessage = _a.errorMessage, feList = _a.feList, totalPageCount = _a.totalPageCount, pageCount = _a.pageCount, totalCount = _a.totalCount, dataListLength = _a.dataListLength, PageLimit = _a.PageLimit, setpageCount = _a.setpageCount;
    var nav = (0, react_router_1.useNavigate)();
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.FE_live_tracking);
    console.log(features, 'features');
    var fetchData = function () {
        setpageCount(pageCount + 1);
    };
    return (React.createElement("div", null,
        React.createElement(material_1.Grid, { container: true, columns: 8 },
            React.createElement(material_1.Grid, { item: true, xs: 2.2 },
                React.createElement("div", { id: "scrollableDiv", className: "user-tracking-section" },
                    React.createElement(react_infinite_scroll_component_1.default, { dataLength: feList.length, next: totalPageCount != feList.length && fetchData, hasMore: true, height: 750, loader: totalPageCount != feList.length ? ('') : feList.length == 0 ? ('') : (React.createElement("h4", null, pageCount != 1 ? 'No Data Available' : '')), scrollThreshold: "0px", scrollableTarget: "scrollableDiv" }, feList && feList.length > 0 ? (feList.map(function (val, index) {
                        return (React.createElement(material_1.Card, { className: "cst-card", elevation: 0, onClick: function () {
                                if (features[menu_constants_1.FEATURE_CONSTANTS.FE_live_tracking_Details])
                                    nav("/".concat(route_path_1.PATHS.fe_traking_list, "/").concat(route_path_1.PATHS.fe_traking_details, "/").concat(val.id));
                            } },
                            React.createElement(material_1.Stack, { paddingBottom: 3.5, direction: 'row', alignItems: 'center', justifyContent: 'space-between' },
                                React.createElement(material_1.Stack, { gap: 2, direction: 'row', alignItems: 'center' },
                                    React.createElement(avatar_component_1.default, { className: "avatar", fName: val.first_name.toString(), lName: val.last_name, image: val.image })),
                                React.createElement(material_1.Chip, { size: "small", avatar: React.createElement(FiberManualRecord_1.default, null), label: "Active", variant: "outlined" })),
                            React.createElement(material_1.CardContent, null,
                                React.createElement(material_1.Grid, { container: true, columns: 4, spacing: 3 },
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "FE Number"),
                                        React.createElement("p", { className: "info" }, val.mobile)),
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "Lab Code"),
                                        React.createElement("p", { className: "info" }, val.lab.lab_code)),
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "State"),
                                        React.createElement("p", { className: "info" }, val.state)),
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "City"),
                                        React.createElement("p", { className: "info" }, val.city))))));
                    })) : (React.createElement("div", { className: "no-data-conatiner" },
                        React.createElement("div", { className: "no-data-badge" }, errorMessage ? errorMessage : 'No Data Available')))),
                    false && (React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataListLength, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value, isFELiveTracking: true })))),
            React.createElement(material_1.Grid, { item: true, xs: 5.8 }, feList && feList.length > 0 ? (React.createElement(all_fe_map_view_1.default, { errorMessage: errorMessage, feList: feList })) : (React.createElement("div", { className: "no-data-conatiner" },
                React.createElement("div", { className: "no-data-badge" },
                    ' ',
                    errorMessage ? errorMessage.toString() : 'No Data Available')))))));
};
exports.default = FETrackingList;
