"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var ReasonTooltip = function (_a) {
    var data = _a.data;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    return (React.createElement(material_1.Tooltip, { open: open, placement: "bottom", onClose: handleClose, className: "tool-tip-wrapper", title: data && data.reason && data.reason.length > 1 ? (React.createElement("div", { className: "tool-tip-list" }, data.reason.slice(1, data.reason.length).map(function (val) {
            return React.createElement("div", null, val);
        }))) : (''), arrow: true },
        React.createElement("div", null,
            ' ',
            data && data.reason && data.reason.length > 0 ? (React.createElement("div", null,
                data.reason[0],
                data.reason.length > 1 ? (React.createElement("span", { onMouseOver: handleOpen, className: "more-values" },
                    "+ ",
                    data.reason.length - 1)) : (''))) : ('--'))));
};
exports.default = ReasonTooltip;
