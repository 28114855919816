"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./input-field.scss");
var TextFiledComponent = function (_a) {
    var type = _a.type, name = _a.name, placeholder = _a.placeholder, className = _a.className, value = _a.value, onChange = _a.onChange, minLength = _a.minLength, style = _a.style, label = _a.label, error = _a.error, required = _a.required, onClick = _a.onClick, isRequered = _a.isRequered, defaultVal = _a.defaultVal, isDisabled = _a.isDisabled, FormHelperTextProps = _a.FormHelperTextProps, onBlur = _a.onBlur, maxLength = _a.maxLength, onKeyPress = _a.onKeyPress, readOnly = _a.readOnly, onFocus = _a.onFocus, autoComplete = _a.autoComplete, id = _a.id;
    var _b = React.useState(''), textFieldval = _b[0], setTextFieldVal = _b[1];
    var onChangeHandler = function (e) {
        setTextFieldVal(e.target.value);
        if (onChange) {
            onChange(e.target.value);
        }
    };
    React.useEffect(function () {
        setTextFieldVal(value);
    }, [value]);
    var params = [];
    if (value) {
        params.push({ label: value });
    }
    return (React.createElement("div", { className: required ? 'input-wrapper validate-filed ' : 'input-wrapper' },
        React.createElement(material_1.TextField, { type: type, key: name, name: name, className: className, value: textFieldval, label: label, variant: "filled", disabled: isDisabled, onChange: onChangeHandler, onBlur: onBlur, error: !!error, onKeyPress: onKeyPress, autoComplete: "off", helperText: error ? error : null, style: style, fullWidth: true, inputProps: {
                readOnly: readOnly,
                minLength: minLength,
                maxLength: maxLength || 100,
            }, onFocus: onFocus, defaultValue: defaultVal, id: id })));
};
exports.default = TextFiledComponent;
