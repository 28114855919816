"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.markerEvents = exports.displayRouteInformation = exports.openInfoBubble = exports.markerInformation = exports.fetchLabVisitData = exports.unassignedFEIcon = exports.unassignedIcon = exports.FE_CURRENT_LOCATION_MARKER = exports.endMarker = exports.startMarker = exports.hub_marker = exports.fe_marker = exports.cc_marker = exports.lab_marker = void 0;
var api_service_1 = require("../../../api/api_service");
var images_1 = require("../../../constants/images");
var map_constants_1 = require("../../../constants/map_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
exports.lab_marker = new H.map.Icon(images_1.default.labIcon, map_constants_1.MPA_ICONS_SIZE);
exports.cc_marker = new H.map.Icon(images_1.default.ccIcon, map_constants_1.MPA_ICONS_SIZE);
exports.fe_marker = new H.map.Icon(images_1.default.feIcon, map_constants_1.FE_ICONS_SIZE);
exports.hub_marker = new H.map.Icon(images_1.default.hubIcon, map_constants_1.MPA_ICONS_SIZE);
exports.startMarker = new H.map.Icon(images_1.default.yellow_map_icon, map_constants_1.MPA_ICONS_SIZE);
exports.endMarker = new H.map.Icon(images_1.default.blue_map_icon, map_constants_1.MPA_ICONS_SIZE);
exports.FE_CURRENT_LOCATION_MARKER = new H.map.Icon(images_1.default.rider_icon, {
    size: { w: 45, h: 20 },
});
exports.unassignedIcon = new H.map.Icon(images_1.default.unassignedIcon, map_constants_1.MPA_ICONS_SIZE);
exports.unassignedFEIcon = new H.map.Icon(images_1.default.red_scooter_icon, map_constants_1.FE_ICONS_SIZE);
var fetchLabVisitData = function (endPoint, payload, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .post({
                    endPoint: endPoint,
                    payLoad: payload,
                    domain: domain,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        return resolve(response.data.data);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchLabVisitData = fetchLabVisitData;
var markerInformation = function (pointeInfo, isUnassigend) {
    var startTime = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? (0, array_helpers_1.isValueEmpty)(pointeInfo.fe_started_date_time)
        : (0, array_helpers_1.isValueEmpty)(pointeInfo.scheduled_time);
    var latitude = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? (0, array_helpers_1.isValueEmpty)(pointeInfo.fe_current_location.latitude)
        : (0, array_helpers_1.isValueEmpty)(pointeInfo.latitude);
    var longitude = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? (0, array_helpers_1.isValueEmpty)(pointeInfo.fe_current_location.longitude)
        : (0, array_helpers_1.isValueEmpty)(pointeInfo.longitude);
    var centerName = (0, array_helpers_1.isValueEmpty)(pointeInfo.center_name);
    var fe_base_location = (0, array_helpers_1.isValueEmpty)(pointeInfo.base_location_address);
    var codeTYpe = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? "".concat(map_constants_1.VISIT_TYPES.fe_code)
        : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP
            ? "".concat(map_constants_1.VISIT_TYPES.cc_code)
            : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HUB_RECEIVING
                ? "".concat(map_constants_1.VISIT_TYPES.hub_code)
                : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB
                    ? "".concat(map_constants_1.VISIT_TYPES.lab_Code)
                    : "".concat(map_constants_1.VISIT_TYPES.hub_cash_code);
    return "<div classname='marker-info'><h3>".concat(codeTYpe, " Information</h3>").concat(!isUnassigend ? "<p>FE Code:".concat(pointeInfo.fe_code, "</p>") : '', "<p>Latitude:").concat(latitude, "</p><p>Longitude:").concat(longitude, "</p><p>").concat(pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? "FE Location:".concat(fe_base_location)
        : "CenterName:".concat(centerName), "</p>\n    ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>Warehouse Code:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo.center_info.warehouse_code), "</p>")
        : '', "\n    ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>Invoice Code:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo.center_info.invoice_code), "</p>")
        : '', "\n      <p>Scheduled Start Time:").concat(startTime, "</p>\n     ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>\n             Slots:[".concat(pointeInfo.center_info.scheduled_time_slots
            ? pointeInfo.center_info.scheduled_time_slots.join(',')
            : '', "]</p>")
        : '', "\n  </div>");
};
exports.markerInformation = markerInformation;
var openInfoBubble = function (popupInfo, coord, ui, distaseValue, timeValue, setShowToolTip, setTooTipInfo) {
    setShowToolTip(true);
    var distase = Math.round(distaseValue * 10) / 10;
    var time = Math.round(timeValue * 10) / 10;
    var splitTime = time.toString().split('.')[0];
    var hours = Math.floor(parseInt(splitTime) / 60);
    var minutes = parseInt(splitTime) % 60;
    var tooTipInformation = {
        distanse: distase,
        hour: hours,
        minutes: minutes,
        startLocation: popupInfo.startLocation,
        endLocation: popupInfo.endLocation,
        feName: popupInfo.feName,
        fe_id: popupInfo.fe_id,
        id: popupInfo.id,
        scheduled_time: popupInfo.scheduled_time,
        visit_type: popupInfo.visit_type,
    };
    setTooTipInfo(tooTipInformation);
};
exports.openInfoBubble = openInfoBubble;
var displayRouteInformation = function (routeLine, map, ui, distase, time, setShowToolTip, setTooTipInfo) {
    routeLine.addEventListener('tap', function (e) {
        var data = e.target.data;
        var coord = map.screenToGeo(e.currentPointer.viewportX, e.currentPointer.viewportY);
        (0, exports.openInfoBubble)(data, coord, ui, distase, time, setShowToolTip, setTooTipInfo);
    });
    return routeLine;
};
exports.displayRouteInformation = displayRouteInformation;
var markerEvents = function (marker, startEndInfo, ui, isUnassigend) {
    marker.addEventListener('tap', function (evt) {
        var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
            content: (0, exports.markerInformation)(startEndInfo, isUnassigend),
        });
        ui.addBubble(bubble);
    }, false);
    if (isUnassigend) {
        marker.setIcon(exports.unassignedIcon);
    }
    else {
        marker.setIcon(startEndInfo.visit_type == 1
            ? exports.cc_marker
            : startEndInfo.visit_type == 3
                ? exports.hub_marker
                : startEndInfo.visit_type == 4 || startEndInfo.visit_type == 5
                    ? exports.lab_marker
                    : exports.fe_marker);
    }
};
exports.markerEvents = markerEvents;
