"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeRoaster_Labels = exports.preparePayloadForSearchFe = exports.slotdata = exports.editTimeSlot = exports.CC_timeSlots = exports.timeSlots = void 0;
var appConstants_1 = require("../../../constants/appConstants");
exports.timeSlots = [
    {
        id: 1,
        slotName: 's1',
        buttonName: '00:01  - 01:00 ',
        fromTime: '00:01',
        toTime: '01:00',
        color: '',
    },
    {
        id: 2,
        slotName: 's2',
        buttonName: '01:01 - 02:00 ',
        fromTime: '01:01',
        toTime: '02:00',
        color: '',
    },
    {
        id: 3,
        slotName: 's3',
        buttonName: '02:01 - 03:00 ',
        fromTime: '02:01',
        toTime: '03:00',
        color: '',
    },
    {
        id: 4,
        slotName: 's4',
        buttonName: '03:01  - 04:00 ',
        fromTime: '03:01',
        toTime: '04:00',
        color: '',
    },
    {
        id: 5,
        slotName: 's5',
        buttonName: '04:01  - 05:00 ',
        fromTime: '04:01',
        toTime: '05:00',
        color: '',
    },
    {
        id: 6,
        slotName: 's6',
        buttonName: '05:01  - 06:00 ',
        fromTime: '05:01',
        toTime: '06:00',
        color: '',
    },
    {
        id: 7,
        slotName: 's7',
        buttonName: '06:01 - 07:00',
        fromTime: '06:01',
        toTime: '07:00',
        color: '',
    },
    {
        id: 8,
        slotName: 's8',
        buttonName: '07:01 - 08:00',
        fromTime: '07:01',
        toTime: '08:00',
        color: '',
    },
    {
        id: 9,
        slotName: 's9',
        buttonName: '08:01 - 09:00',
        fromTime: '08:01',
        toTime: '09:00',
    },
    {
        id: 10,
        slotName: 's10',
        buttonName: '09:01 - 10:00',
        fromTime: '09:01',
        toTime: '10:00',
        color: '',
    },
    {
        id: 11,
        slotName: 's11',
        buttonName: '10:01  - 11:00',
        fromTime: '10:01',
        toTime: '11:00',
        color: '',
    },
    {
        id: 12,
        slotName: 's12',
        buttonName: '11:01 - 12:00',
        fromTime: '11:01',
        toTime: '12:00',
        color: '',
    },
    {
        id: 13,
        slotName: 's13',
        buttonName: '12:01 - 13:00',
        fromTime: '12:01',
        toTime: '13:00',
        color: '',
    },
    {
        id: 14,
        slotName: 's14',
        buttonName: '13:01 - 14:00',
        fromTime: '13:01',
        toTime: '14:00',
        color: '',
    },
    {
        id: 15,
        slotName: 's15',
        buttonName: '14:01 - 15:00',
        fromTime: '14:01',
        toTime: '15:00',
        color: '',
    },
    {
        id: 16,
        slotName: 's16',
        buttonName: '15:01 - 16:00',
        fromTime: '15:01',
        toTime: '16:00',
        color: '',
    },
    {
        id: 17,
        slotName: 's17',
        buttonName: '16:01 - 17:00',
        fromTime: '16:01',
        toTime: '17:00',
        color: '',
    },
    {
        id: 18,
        slotName: 's18',
        buttonName: '17:01 - 18:00',
        fromTime: '17:01',
        toTime: '18:00',
        color: '',
    },
    {
        id: 19,
        slotName: 's19',
        buttonName: '18:01 - 19:00',
        fromTime: '18:01',
        toTime: '19:00',
        color: '',
    },
    {
        id: 20,
        slotName: 's20',
        buttonName: '19:01 - 20:00',
        fromTime: '19:01',
        toTime: '20:00',
        color: '',
    },
    {
        id: 21,
        slotName: 's21',
        buttonName: '20:01 - 21:00',
        fromTime: '20:01',
        toTime: '21:00',
        color: '',
    },
    {
        id: 22,
        slotName: 's22',
        buttonName: '21:01 - 22:00',
        fromTime: '21:01',
        toTime: '22:00',
        color: '',
    },
    {
        id: 23,
        slotName: 's23`',
        buttonName: '22:01 - 23:00',
        fromTime: '22:01',
        toTime: '23:00',
        color: '',
    },
    {
        id: 24,
        slotName: 's24`',
        buttonName: '23:01 - 00:00',
        fromTime: '23:01',
        toTime: '00:00',
        color: '',
    },
];
exports.CC_timeSlots = [
    {
        id: 1,
        slotName: 's1',
        buttonName: '00:01  - 01:00 ',
    },
    {
        id: 2,
        slotName: 's2',
        buttonName: '01:01 - 02:00 ',
    },
    {
        id: 3,
        slotName: 's3',
        buttonName: '02:01 - 03:00 ',
    },
    {
        id: 4,
        slotName: 's4',
        buttonName: '03:01  - 04:00 ',
    },
    {
        id: 5,
        slotName: 's5',
        buttonName: '04:01  - 05:00 ',
    },
    {
        id: 6,
        slotName: 's6',
        buttonName: '05:01  - 06:00 ',
    },
    {
        id: 7,
        slotName: 's7',
        buttonName: '06:01 - 07:00',
    },
    {
        id: 8,
        slotName: 's8',
        buttonName: '07:01 - 08:00',
    },
    {
        id: 9,
        slotName: 's9',
        buttonName: '08:01 - 09:00',
    },
    {
        id: 10,
        slotName: 's10',
        buttonName: '09:01 - 10:00',
    },
    {
        id: 11,
        slotName: 's11',
        buttonName: '10:01  - 11:00',
    },
    {
        id: 12,
        slotName: 's12',
        buttonName: '11:01 - 12:00',
    },
    {
        id: 13,
        slotName: 's13',
        buttonName: '12:01 - 13:00',
    },
    {
        id: 14,
        slotName: 's14',
        buttonName: '13:01 - 14:00',
    },
    {
        id: 15,
        slotName: 's15',
        buttonName: '14:01 - 15:00',
    },
    {
        id: 16,
        slotName: 's16',
        buttonName: '15:01 - 16:00',
    },
    {
        id: 17,
        slotName: 's17',
        buttonName: '16:01 - 17:00',
    },
    {
        id: 18,
        slotName: 's18',
        buttonName: '17:01 - 18:00',
    },
    {
        id: 19,
        slotName: 's19',
        buttonName: '18:01 - 19:00',
    },
    {
        id: 20,
        slotName: 's20',
        buttonName: '19:01 - 20:00',
    },
    {
        id: 21,
        slotName: 's21',
        buttonName: '20:01 - 21:00',
    },
    {
        id: 22,
        slotName: 's22',
        buttonName: '21:01 - 22:00',
    },
    {
        id: 23,
        slotName: 's23`',
        buttonName: '22:01 - 23:00',
    },
    {
        id: 24,
        slotName: 's24`',
        buttonName: '23:01 - 00:00',
    },
];
exports.editTimeSlot = [
    {
        day: 'MONDAY',
        id: 1,
        slotName: 's1',
        buttonName: ['00:00  - 01:00', '03:00  - 04:00 ', '04:00  - 05:00 '],
        state: 'Andhra Pradesh',
        city: 'Vizayawada',
        slot_from_time: '06:00',
        slot_name: 's1',
        slot_to_time: '06:30',
    },
    {
        id: 2,
        slotName: 's2',
        buttonName: ['01:00 - 02:00 ', '03:00  - 04:00 ', '04:00  - 05:00 '],
        state: 'Andhra Pradesh',
        city: 'Vizayawada',
    },
    {
        id: 3,
        slotName: 's3',
        buttonName: ['02:00 - 03:00 ', '04:00  - 05:00 ', '05:00  - 06:00 '],
        state: 'Andhra Pradesh',
        city: 'Vizayawada',
    },
    {
        id: 4,
        slotName: 's4',
        buttonName: ['03:00  - 04:00 ', '04:00  - 05:00 ', '05:00  - 06:00 '],
        state: 'Andhra Pradesh',
        city: 'Vizayawada',
    },
    {
        id: 5,
        slotName: 's5',
        buttonName: ['04:00  - 05:00 ', '05:00  - 06:00 ', '07:00  - 08:00 '],
        state: 'Andhra Pradesh',
        city: 'Vizayawada',
    },
];
exports.slotdata = [
    {
        day: 'FRIDAY',
        id: 5,
        slots: [
            {
                name: 's22',
                buttonName: '21:00 - 22:00',
            },
            {
                name: 's23`',
                buttonName: '22:00 - 23:00',
            },
        ],
    },
    {
        day: 'MONDAY',
        id: 1,
        slots: [
            {
                name: 's1',
                buttonName: '00:00  - 01:00 ',
            },
            {
                name: 's2',
                buttonName: '01:00  - 02:00 ',
            },
            {
                name: 's3',
                buttonName: '02:00  - 03:00 ',
            },
        ],
    },
    {
        day: 'TUESDAY',
        id: 2,
        slots: [
            {
                name: 's7',
                buttonName: '06:00 - 07:00',
            },
            {
                name: 's8',
                buttonName: '07:00 - 08:00',
            },
        ],
    },
    {
        day: 'THURSDAY',
        id: 4,
        slots: [
            {
                name: 's18',
                buttonName: '17:00 - 18:00',
            },
            {
                name: 's19',
                buttonName: '18:00 - 19:00',
            },
        ],
    },
    {
        day: 'WEDNESDAY',
        id: 3,
        slots: [
            {
                name: 's11',
                buttonName: '10:00  - 11:00',
            },
            {
                name: 's12',
                buttonName: '11:00 - 12:00',
            },
        ],
    },
    {
        day: 'SATURDAY',
        id: 6,
        slots: [
            {
                name: 's13',
                buttonName: '12:00 - 13:00',
            },
            {
                name: 's14',
                buttonName: '13:00 - 14:00',
            },
        ],
    },
];
var preparePayloadForSearchFe = function (searchValue) {
    var payload;
    if (searchValue) {
        payload = {
            onboard_status: [appConstants_1.FE_USER_TYPES.active],
            search_string: searchValue,
        };
    }
    else {
        payload = {
            onboard_status: [appConstants_1.FE_USER_TYPES.active],
        };
    }
    return payload;
};
exports.preparePayloadForSearchFe = preparePayloadForSearchFe;
exports.FeRoaster_Labels = {
    viewSlot: 'FE Working Hours',
    addSlot: 'Add FE Working Hours',
    editSlot: 'Edit FE Working Hours',
};
