"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var styles_1 = require("@mui/styles");
exports.useStyles = (0, styles_1.makeStyles)({
    customDrawer: {
        '& .MuiPaper-root': {
            left: 180,
            overflow: 'visible',
            '& .drawer-close-btn': {
                margin: '0 auto',
                background: '#FFC233',
                position: 'relative',
                top: -18,
                height: 40,
                width: 40,
                borderRadius: '50%',
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
                cursor: 'pointer',
                '& .icon-close': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: 24,
                    color: '#424242',
                },
            },
        },
    },
});
