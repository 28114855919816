"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var api_urls_1 = require("../../../api/api_urls");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var tabs_component_1 = require("../../../common/tabs/tabs_component");
var text_field_with_datepicker_1 = require("../../../common/text_filed/text_field_with_datepicker");
var map_constants_1 = require("../../../constants/map_constants");
var tabs_data_1 = require("../../../constants/tabs_data");
var Pagenotfound_1 = require("../../../ErrorBoundary/Pagenotfound");
var menu_constants_1 = require("../../../menuList/menu_constants");
var routes_list_1 = require("../../../routes/routes_list");
var array_helpers_1 = require("../../../utils/array_helpers");
var heat_map_api_service_1 = require("./heat_map_api_service");
var MainHeatMap = function () {
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).labList;
    var _a = React.useState(null), selectedLab = _a[0], setSelectedLab = _a[1];
    var _b = React.useState(), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = React.useState(), selectedDate = _c[0], setSelectedDate = _c[1];
    var _d = React.useState([]), roundColors = _d[0], setRoundColors = _d[1];
    var _e = React.useState([]), selectedFEId = _e[0], setSlectedFEId = _e[1];
    var _f = React.useState([]), feId_list = _f[0], setFeIdList = _f[1];
    var _g = React.useState([]), downloadableVisitsList = _g[0], setDownlaodableVisitsList = _g[1];
    var _h = React.useState({
        id: 1,
        label: 'All',
        value: 'assigned_unAssigned',
    }), rootType = _h[0], setRootType = _h[1];
    var filterRounds = (0, array_helpers_1.removeDuplicateColors)(roundColors);
    var _j = React.useState([]), assignedVisitData = _j[0], setAssignedVisitData = _j[1];
    var _k = React.useState([]), unassignedVisitData = _k[0], setUnAssignedVisitData = _k[1];
    var pathname = window.location.pathname;
    var rootHandler = function (value) {
        if (value) {
            setRootType(value);
        }
        setSelectedDate('');
        setSelectedLab(null);
        setSlectedFEId([]);
        setAssignedVisitData([]);
        setUnAssignedVisitData([]);
        setFeIdList([]);
        setRoundColors([]);
    };
    var heatMapRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.Heat_Maps, routes_list_1.HEAT_MAP_SUB_ROUES);
    var FE_IDropdownHandler = function (value) {
        setSlectedFEId(value);
    };
    var labHandler = function (value) {
        setSelectedLab(value);
        setSlectedFEId([]);
        setAssignedVisitData([]);
        setUnAssignedVisitData([]);
        setFeIdList([]);
        setRoundColors([]);
    };
    console.log(pathname, 'pathname');
    var getLabVisitsData = function (endPoint, payload, domain) {
        (0, heat_map_api_service_1.fetchLabVisitData)(endPoint, payload, domain)
            .then(function (res) {
            setErrorMessage("");
            if (rootType.value == map_constants_1.ASSIGNED_UNASSIGNED.assiendAndUnassigned &&
                res.toString().length >= 1) {
                if (res.assigned.length >= 1) {
                    var feId = (0, array_helpers_1.filterFE_IdsForHeatMap)(res.assigned);
                    setFeIdList(feId);
                    var assignedData = (0, array_helpers_1.filterUniqueFEData)(res.assigned);
                    var downloadExcelData = (0, array_helpers_1.convertDownloadableExcelDataFromRes)(res.assigned);
                    setDownlaodableVisitsList(downloadExcelData);
                    console.log(assignedData, 'assignedData');
                    setAssignedVisitData(assignedData);
                }
                else {
                    react_hot_toast_1.default.error('No Assigend Visits Available');
                    setAssignedVisitData([]);
                    setFeIdList([]);
                }
                if (res.unassigned.length >= 1) {
                    var unAssignedData = (0, array_helpers_1.filterUnassiedVisitsData)(res.unassigned);
                    setUnAssignedVisitData(unAssignedData);
                }
                else {
                    react_hot_toast_1.default.error('No Unassigned Visits Available');
                    setUnAssignedVisitData([]);
                }
            }
            else if (rootType.value == map_constants_1.ASSIGNED_UNASSIGNED.assigned &&
                res.toString().length >= 1) {
                setUnAssignedVisitData([]);
                if (res.assigned.length >= 1) {
                    var feId = (0, array_helpers_1.filterFE_IdsForHeatMap)(res.assigned);
                    setFeIdList(feId);
                    var assignedData = (0, array_helpers_1.filterUniqueFEData)(res.assigned);
                    console.log(assignedData, 'assignedData');
                    setAssignedVisitData(assignedData);
                }
                else {
                    react_hot_toast_1.default.error('No Assigend Visits Available');
                    setAssignedVisitData([]);
                }
            }
            else if (rootType.value == map_constants_1.ASSIGNED_UNASSIGNED.unassigned &&
                res.toString().length >= 1) {
                setAssignedVisitData([]);
                setFeIdList([]);
                if (res.unassigned.length >= 1) {
                    var unAssignedData = (0, array_helpers_1.filterUnassiedVisitsData)(res.unassigned);
                    setUnAssignedVisitData(unAssignedData);
                }
                else {
                    react_hot_toast_1.default.error('No Unassigned Visits Available');
                    setUnAssignedVisitData([]);
                }
            }
            else {
                react_hot_toast_1.default.error('No Visits Available');
                setAssignedVisitData([]);
                setUnAssignedVisitData([]);
                setFeIdList([]);
            }
        })
            .catch(function (err) {
            setErrorMessage(err.message);
        });
    };
    var FilterHandler = function () {
        if (rootType && selectedLab && selectedDate) {
            getLabVisitsData(api_urls_1.api_urls.LAB_VISITES, {
                lab_code: selectedLab && selectedLab.label,
                visit_date: selectedDate && selectedDate,
            }, process.env.VENDOR_BASE_URL);
        }
    };
    React.useEffect(function () {
        setSelectedDate('');
        setSelectedLab(null);
        setSlectedFEId([]);
        setAssignedVisitData([]);
        setUnAssignedVisitData([]);
        setFeIdList([]);
        setRoundColors([]);
    }, [pathname]);
    var heatMapFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Heat_Maps);
    var downloadExcelHeaders = [
        'Route ID',
        'Invoice Code',
        'Warehouse Code',
        'Center Name',
        'Distance',
        'Start Time',
        'status',
        'Visit Type',
        'Visit Date',
        'Time Window',
        'FE Mobile',
        'FE Name',
        'Latittude',
        'Longittude',
        'Lab Code',
    ];
    return (React.createElement("div", null,
        React.createElement("div", { className: "fe-wrapper leave-tarcking-page" },
            React.createElement("div", { className: "tool-bar-wrapper" },
                React.createElement("div", { className: "page-heaidng" },
                    React.createElement("h2", null, "Heat Maps")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "download", isError: !heatMapFeatures[menu_constants_1.FEATURE_CONSTANTS.Heat_Map_Download] || !feId_list.length, endIcon: "icon-import download-icon", color: "yellow-outline", onClickHandler: function () {
                            return (0, array_helpers_1.downloadExcel)(downloadableVisitsList, 'Visits.xlsx', downloadExcelHeaders, 'Visits List');
                        } }))),
            React.createElement("div", { className: "filter-menu-wrapper no-search-filters" },
                React.createElement("div", { className: "filter-options" },
                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "Visit Type", placeholder: "Select Visit Type", dropDownList: map_constants_1.ASSIGNED_UNASSIGNED_ROOTES, onChange: rootHandler, value: rootType }),
                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "lab", placeholder: "Lab Code", isDisabled: rootType ? false : true, dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                            return a.label.localeCompare(b.label);
                        }), onChange: labHandler, value: selectedLab || null }),
                    React.createElement(text_field_with_datepicker_1.default, { label: "Select Date", value: selectedDate, disableManulaEntry: true, isDisabled: rootType ? false : true, onChange: function (dateValue) {
                            setSelectedDate(moment(dateValue).format('YYYY-MM-DD'));
                        }, isFutureDisable: false }),
                    React.createElement(multi_selector_1.default, { label: "Select FE's", dropDownList: feId_list && feId_list, isDisabled: feId_list.length >= 1 ? false : true, onChangeHnadlre: FE_IDropdownHandler, value: selectedFEId }),
                    React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler })))),
        React.createElement("div", { className: "fe-managment-screen" },
            React.createElement(tabs_component_1.default, { tabList: tabs_data_1.TABS_DATA.HEAT_MAP_TABS }),
            React.createElement(react_router_1.Routes, null,
                heatMapRoutes &&
                    heatMapRoutes.map(function (features) {
                        return (React.createElement(react_router_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(features.component, { errorMessage: errorMessage, setRoundColors: setRoundColors, selectedFEId: selectedFEId, assignedVisitData: assignedVisitData, unassignedVisitData: unassignedVisitData, filterRounds: filterRounds, rootType: rootType }) }));
                    }),
                React.createElement(react_router_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) })))));
};
exports.default = MainHeatMap;
