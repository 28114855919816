"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var map_constants_1 = require("../../../../constants/map_constants");
var heat_map_api_service_1 = require("../../../super_admin/heat_maps/heat_map_api_service");
var fe_live_tracking_service_1 = require("../service/fe_live_tracking_service");
var FEHistoryMapDetails = function (_a) {
    var errorMessage = _a.errorMessage, feDeatils = _a.feDeatils;
    var mapRef = React.useRef(null);
    var plotFEPolyline = function (map, ui) {
        var group = new H.map.Group();
        var lineString = new H.geo.LineString();
        var markerGroup = [];
        if (feDeatils && feDeatils.route && feDeatils.route.length > 0) {
            feDeatils.route.map(function (element, index) {
                try {
                    var lat = element.latitude;
                    var lng = element.longitude;
                    var startEndInfo = {
                        startPointInfo: element,
                    };
                    var styleLine = {
                        style: {
                            strokeColor: "blue",
                            lineWidth: 5,
                            fillColor: 'white',
                            lineTailCap: 'arrow-tail',
                            lineDash: [1, 1],
                            lineHeadCap: 'arrow-head',
                        },
                    };
                    var myLocMarker = new H.map.Marker({
                        lat: Number(lat),
                        lng: Number(lng),
                    });
                    if (index == 0) {
                        myLocMarker.setIcon(heat_map_api_service_1.startMarker);
                    }
                    else if (element.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB) {
                        myLocMarker.setIcon(heat_map_api_service_1.lab_marker);
                    }
                    else if (element.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB) {
                        myLocMarker.setIcon(heat_map_api_service_1.hub_marker);
                    }
                    else {
                        myLocMarker.setIcon(heat_map_api_service_1.cc_marker);
                    }
                    (0, fe_live_tracking_service_1.FEMarkerEvents)(myLocMarker, startEndInfo.startPointInfo, ui, false);
                    lineString.pushPoint({
                        lat: Number(lat),
                        lng: Number(lng),
                    });
                    markerGroup.push(myLocMarker);
                    group.addObjects(__spreadArray([], markerGroup, true));
                    map.addObject(group);
                    map.addObject(new H.map.Polyline(lineString, styleLine));
                }
                catch (e) {
                    console.log(e);
                }
            });
        }
    };
    var markFE_currentLocation = function (map) {
        var _a, _b, _c, _d, _e, _f;
        if (feDeatils &&
            ((_a = feDeatils.current_coordinates) === null || _a === void 0 ? void 0 : _a.current_latitude) &&
            ((_b = feDeatils.current_coordinates) === null || _b === void 0 ? void 0 : _b.current_longitude)) {
            var londonMarker = new H.map.Marker({
                lat: Number((_c = feDeatils.current_coordinates) === null || _c === void 0 ? void 0 : _c.current_latitude),
                lng: Number((_d = feDeatils.current_coordinates) === null || _d === void 0 ? void 0 : _d.current_longitude),
            });
            londonMarker.setIcon(heat_map_api_service_1.FE_CURRENT_LOCATION_MARKER);
            map.addObject(londonMarker);
            map.setCenter({
                lat: Number((_e = feDeatils.current_coordinates) === null || _e === void 0 ? void 0 : _e.current_latitude),
                lng: Number((_f = feDeatils.current_coordinates) === null || _f === void 0 ? void 0 : _f.current_longitude),
            });
            map.setZoom(17);
        }
    };
    var setStyle = function (map) {
        var provider = map.getBaseLayer().getProvider();
        var style = new H.map.Style('/fonts/normalDay.yaml', 'https://js.api.here.com/v3/3.1.58.0/styles/omv/omv/');
        provider.setStyle(style);
    };
    React.useLayoutEffect(function () {
        var _a, _b;
        if (!mapRef.current)
            return;
        var platform = new H.service.Platform({
            apikey: process.env.HERE_MAPS_API_KEY,
        });
        var defaultLayers = platform.createDefaultLayers({ pois: true });
        var map = new H.Map(mapRef.current, defaultLayers.vector.normal.map.setMin(2), {
            center: { lat: 23.6913, lng: 85.2722 },
            zoom: 5,
            pixelRatio: window.devicePixelRatio || 1,
        });
        setStyle(map);
        var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        var ui = H.ui.UI.createDefault(map, defaultLayers);
        ui.getElement().style.zIndex = 999;
        if (feDeatils && feDeatils.route && feDeatils.route.length > 0) {
            plotFEPolyline(map, ui);
        }
        if (feDeatils &&
            ((_a = feDeatils === null || feDeatils === void 0 ? void 0 : feDeatils.current_coordinates) === null || _a === void 0 ? void 0 : _a.current_latitude) &&
            ((_b = feDeatils === null || feDeatils === void 0 ? void 0 : feDeatils.current_coordinates) === null || _b === void 0 ? void 0 : _b.current_longitude)) {
            markFE_currentLocation(map);
        }
        console.log(ui, behavior);
        return function () {
            map.dispose();
        };
    }, [mapRef, feDeatils]);
    return !errorMessage ? (React.createElement("div", { ref: mapRef, className: "profile-map-container" })) : (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString()));
};
exports.default = FEHistoryMapDetails;
