"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var route_planner_api_service_1 = require("../api/route_planner_api_service");
var routePlannerConstant_1 = require("../Constants/routePlannerConstant");
var route_planner_service_1 = require("../service/route_planner_service");
var RoutePlannerApprovalList = function () {
    var _a = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }), labList = _a.labList, routing_assignment_type = _a.routing_assignment_type;
    var _b = React.useState(null), labCode = _b[0], setLabCode = _b[1];
    var _c = React.useState(null), route_type = _c[0], setRoute_type = _c[1];
    var _d = React.useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(1), pageCount = _f[0], setpageCount = _f[1];
    var _g = React.useState(0), totalCount = _g[0], setTotalCount = _g[1];
    var _h = React.useState(appConstants_1.defaultPageLimit), PageLimit = _h[0], setPageLimit = _h[1];
    var _j = React.useState(undefined), routePlannerList = _j[0], setRoutePlannerList = _j[1];
    var getRoutePlannerList = function (endPoint, payLoad, domain) {
        (0, route_planner_api_service_1.globalRoutePlannerPostAPI)(endPoint, payLoad, domain)
            .then(function (response) {
            var res = (0, route_planner_service_1.convertRoutePlannerListFromRes)(response);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setErrorMessage("");
            setTotalCount(response.count);
            setRoutePlannerList(res.result);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setRoutePlannerList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)('route_planner_list', function () {
        var payload = (0, route_planner_service_1.createPayloadForRouteList)(labCode && labCode.value, route_type && route_type.id, routePlannerConstant_1.route_plan_status.Sent_for_approval);
        payload.page = pageCount;
        payload.size = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        console.log(payload, 'checkpayload');
        getRoutePlannerList(api_urls_1.api_urls.ROUTE_PLANNER_LIST, payload, process.env.ONE_TWO_LEVEL_APPROVAL_URL);
    }).refetch;
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var onPageLimitChange = function (val) {
        setPageLimit(val);
    };
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    return (React.createElement("div", { className: 'fe-wrapper leave-tarcking-page' },
        React.createElement("div", { className: 'tool-bar-wrapper' },
            React.createElement("div", { className: 'page-heaidng' },
                React.createElement("h2", null, "Approvals"))),
        React.createElement("div", { className: 'filter-menu-wrapper' },
            React.createElement("div", { className: 'search-filter' }),
            React.createElement("div", { className: 'filter-options' },
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: 'labcode', placeholder: 'Lab Code', dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList), onChange: function (value) {
                        setRoute_type(null);
                        setLabCode(value);
                    }, value: labCode }),
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: 'route_type', placeholder: 'Route Type', dropDownList: routing_assignment_type, onChange: function (value) {
                        setLabCode(null);
                        setRoute_type(value);
                    }, value: route_type }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: onPageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: 'unclickablerow  custom-pagination-table' },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.APPROVAL_ROUTE_PLANNER_COLUMNS, rows: routePlannerList && routePlannerList, errorMessage: errorMessage, pagination: false, className: 'route-approvals-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: routePlannerList === null || routePlannerList === void 0 ? void 0 : routePlannerList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }))));
};
exports.default = RoutePlannerApprovalList;
