"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_idle_timer_1 = require("react-idle-timer");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var universal_cookie_1 = require("universal-cookie");
var user_action_1 = require("../../actions/user_action");
var api_service_1 = require("../../api/api_service");
var AutoIdleTimeout = function () {
    var navigate = (0, react_router_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var cookies = new universal_cookie_1.default();
    var handleOnIdle = function () {
        console.log('getLastActiveTime', getLastActiveTime);
        handleLogout();
    };
    var handleLogout = function () {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.LOGOUT,
            showLoader: true,
            domain: process.env.AUTHENTICATION_SERVER_URL,
        })
            .then(function (res) {
            if (res.data.status === 200) {
                react_hot_toast_1.default.success(res.data.message);
                dispatch(user_action_1.default.setLogout());
                cookies.remove('cookies_jwt_token');
                cookies.remove('cookies_refresh_token');
                navigate("/");
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var getLastActiveTime = (0, react_idle_timer_1.useIdleTimer)({
        timeout: 1200000,
        onIdle: handleOnIdle,
        debounce: 500,
    }).getLastActiveTime;
    return React.createElement(React.Fragment, null);
};
exports.default = AutoIdleTimeout;
