"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var material_1 = require("@mui/material");
var react_router_1 = require("react-router");
var Switch_1 = require("@mui/material/Switch");
var route_path_1 = require("../../../../constants/route_path");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var scf_service_1 = require("./scf_service");
var label = { inputProps: { 'aria-label': 'Switch demo' } };
var SCFActionIcons = function (_a) {
    var data = _a.data;
    var nav = (0, react_router_1.useNavigate)();
    var pathname = (0, react_router_1.useLocation)().pathname;
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.SCF_Management);
    var editHandlere = function () {
        nav("/".concat(route_path_1.default.PATHS.edit_scf, "/").concat(data.center_id));
    };
    var disableDeactiveteBtn = !features[menu_constants_1.FEATURE_CONSTANTS.SCF_DEACTIVATE_CLIENT] || !features[menu_constants_1.FEATURE_CONSTANTS.SCF_ACTIVATE_CLIENT] || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_ONE_REJECTED || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_TWO_REJECTED || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.PENDING_LEVEL_ONE || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.PENDING_LEVEL_TWO;
    return React.createElement("div", null,
        !pathname.includes(route_path_1.PATHS.scf_level1_listing) && !pathname.includes(route_path_1.PATHS.scf_leve2_listing) && React.createElement(React.Fragment, null,
            React.createElement(material_1.IconButton, { className: "icon-btn", disabled: !features[menu_constants_1.FEATURE_CONSTANTS.SCF_MODIFY_CLIENT] || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.PENDING_LEVEL_TWO, onClick: editHandlere },
                React.createElement("span", { className: "icon-edit" })),
            !pathname.includes(route_path_1.PATHS.scf_rejected_listing) &&
                React.createElement(material_1.IconButton, { className: "icon-btn", disabled: !features[menu_constants_1.FEATURE_CONSTANTS.SCF_DELETE_CLIENT] || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_ONE_REJECTED || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_TWO_REJECTED || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.PENDING_LEVEL_TWO },
                    React.createElement("span", { className: "icon-delete_forever" }))),
        Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.PENDING_LEVEL_ONE || Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.PENDING_LEVEL_TWO ?
            React.createElement(material_1.IconButton, { className: "icon-btn", style: { marginLeft: '15px' } },
                React.createElement("span", { className: "icon-Vector" })) :
            !pathname.includes(route_path_1.PATHS.scf_rejected_listing) &&
                React.createElement(material_1.IconButton, { className: "icon-btn", disabled: disableDeactiveteBtn }, Number(data.status) == scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE ?
                    React.createElement(Switch_1.default, __assign({}, label, { disabled: disableDeactiveteBtn, onClick: function (e) { return e.preventDefault(); } })) :
                    React.createElement(Switch_1.default, __assign({}, label, { defaultChecked: true, disabled: disableDeactiveteBtn, onClick: function (e) { return e.preventDefault(); } }))));
};
exports.default = SCFActionIcons;
