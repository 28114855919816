"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var api_service_1 = require("../../../../api/api_service");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var search_component_1 = require("../../../../common/search/search_component");
var appConstants_1 = require("../../../../constants/appConstants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var fe_services_1 = require("../../fe_services");
var fe_list_1 = require("./fe_list");
var FELiveTrackingFilters = function () {
    var stateList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).stateList;
    var _a = React.useState([]), citiesData = _a[0], setCitiesData = _a[1];
    var _b = React.useState(1), pageCount = _b[0], setpageCount = _b[1];
    var _c = React.useState(0), totalPageCount = _c[0], setTotalPageCount = _c[1];
    var _d = React.useState(0), totalCount = _d[0], setTotalCount = _d[1];
    var _e = React.useState([]), feActiveList = _e[0], setFeActiveList = _e[1];
    var _f = React.useState(''), searchVlaue = _f[0], setSearchValue = _f[1];
    var _g = React.useState(''), errorMsg = _g[0], setErrorMessage = _g[1];
    var _h = React.useState([]), selectedCities = _h[0], setSelectedCites = _h[1];
    var _j = React.useState(), cityList = _j[0], setCityList = _j[1];
    var _k = React.useState(), stateData = _k[0], setStateData = _k[1];
    var PageLimit = React.useState(appConstants_1.defaultPageLimit)[0];
    var searchHandlere = function (event) {
        setpageCount(1);
        setSearchValue(event.target.value.trim());
    };
    var cityHandlere = function (value) {
        setpageCount(1);
        var cities = [];
        var cityAndStateData = [];
        value.map(function (val) {
            cities.push(val.label.split(',')[0]);
            cityAndStateData.push({
                city: val.label.split(',')[0],
                state: val.state_name,
            });
        });
        setSelectedCites(cities);
        setCityList(value);
    };
    var fetchActiveFE = function (endPoint, payLoad, domain) {
        (0, fe_services_1.fetchFEList)(endPoint, payLoad, domain)
            .then(function (response) {
            console.log(response, 'DFFFF');
            if (response.result.length != 0) {
                if (searchVlaue || selectedCities.length > 0) {
                    setTotalPageCount(response.count);
                    setFeActiveList(response.result);
                    setTotalCount(response.count);
                }
                else {
                    setTotalPageCount(response.count);
                    if (pageCount > 1) {
                        var concatData = __spreadArray(__spreadArray([], feActiveList, true), response.result, true);
                        setFeActiveList(concatData);
                    }
                    else {
                        var concatData = response.result;
                        setFeActiveList(concatData);
                    }
                    setTotalCount(response.count);
                }
            }
            else {
                setTotalPageCount(0);
                setFeActiveList([]);
                setTotalCount(0);
            }
        })
            .catch(function (err) {
            console.log('error in the error', err);
            setErrorMessage(err.message);
            setFeActiveList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['active-fe'], function () {
        var payload = (0, array_helpers_1.createPayloadForFEList)(appConstants_1.FE_USER_TYPES.active, searchVlaue, selectedCities);
        if (searchVlaue || selectedCities.length > 0) {
            payload['page'] = 1;
        }
        else {
            payload.page = pageCount;
        }
        payload.size = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        fetchActiveFE(api_urls_1.api_urls.FE_LIST, payload, process.env.VENDOR_BASE_URL);
    }).refetch;
    var stateHandlere = function (value) {
        setpageCount(1);
        setStateData(value);
        makeCityEmptyHandler();
    };
    var makeCityEmptyHandler = function () {
        setCityList([]);
        setSelectedCites([]);
    };
    var fetchCities = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2, new Promise(function (resolve, reject) {
                    if (stateData && stateData.length >= 1) {
                        api_service_1.api_service
                            .post({
                            endPoint: api_service_1.api_service.api_urls.CITIES,
                            domain: process.env.VENDOR_BASE_URL,
                            payLoad: {
                                state_id: stateData &&
                                    stateData.map(function (state) { return state.id; }),
                            },
                        })
                            .then(function (response) {
                            if (response.status === 200) {
                                var stateResponse = (0, array_helpers_1.convertLabelAndValueCity)(response.data.data.cities);
                                setCitiesData(stateResponse);
                                return resolve(response.data.data);
                            }
                            throw new Error(response.data.message);
                        })
                            .catch(function (err) {
                            return reject(err);
                        });
                    }
                    else {
                        setCitiesData([]);
                    }
                })];
        });
    }); };
    console.log(selectedCities, 'FSFSF');
    React.useEffect(function () {
        fetchCities();
    }, [stateData]);
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    console.log(feActiveList, 'feActiveList');
    return (React.createElement("div", null,
        React.createElement("div", { className: "fe-wrapper fe-tracking" },
            React.createElement("div", { className: "tool-bar-wrapper" },
                React.createElement("div", { className: "page-heaidng" },
                    React.createElement("h2", null, "FE Tracking ")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "Refresh", color: "yellow", onClickHandler: function () { return refetch(); } }))),
            React.createElement("div", { className: "filter-menu-wrapper" },
                React.createElement("div", { className: "search-filter" },
                    React.createElement(search_component_1.default, { placeholder: 'Search FE Name / Mobile No', searchValue: searchVlaue, searchHandler: searchHandlere }),
                    React.createElement("h1", { className: "total_fe_count" },
                        "Total Active FE's : ",
                        totalPageCount)),
                React.createElement("div", { className: "filter-options" },
                    React.createElement(multi_selector_1.default, { label: "Select State", dropDownList: stateList && stateList.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: stateHandlere, value: stateData }),
                    React.createElement(multi_selector_1.default, { label: "Select City", dropDownList: citiesData && citiesData.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: cityHandlere, value: cityList, hasSelectAll: true, noOptions: 'No cities found' }),
                    ' ',
                    React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
            React.createElement("div", null,
                React.createElement(fe_list_1.default, { errorMessage: errorMsg, feList: feActiveList, totalPageCount: totalPageCount, pageCount: pageCount, totalCount: totalCount, dataListLength: feActiveList && feActiveList.length, PageLimit: PageLimit, setpageCount: setpageCount })))));
};
exports.default = FELiveTrackingFilters;
