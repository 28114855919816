"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../common/buttons/button_component");
var search_component_1 = require("../../common/search/search_component");
var AGgrid_table_component_1 = require("../../common/tables/AGgrid_table_component");
var route_path_1 = require("../../constants/route_path");
var table_data_1 = require("../../constants/table_data");
var menu_constants_1 = require("../../menuList/menu_constants");
var array_helpers_1 = require("../../utils/array_helpers");
var react_query_1 = require("react-query");
var appConstants_1 = require("../../constants/appConstants");
var NotificationHelpers_1 = require("./NotificationHelpers");
var react_paginate_1 = require("../../common/react_pagination/react_paginate");
var drop_down_component_1 = require("../../common/drop_down/drop_down_component");
var multi_selector_1 = require("../../common/checkbox/multi_selector");
var NotificationConstants_1 = require("./NotificationConstants");
var react_redux_1 = require("react-redux");
var moment = require("moment");
var text_filed_with_fromto_Daterange_1 = require("../../common/text_filed/text_filed_with_fromto_Daterange");
var react_hot_toast_1 = require("react-hot-toast");
var CC_helper_1 = require("../super_admin/CC/CC_helper");
var NotificationPopup_1 = require("./NotificationPopup");
var NotificationList = function () {
    var nav = (0, react_router_dom_1.useNavigate)();
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var _b = React.useState(1), pageCount = _b[0], setpageCount = _b[1];
    var _c = React.useState(0), totalPageCount = _c[0], setTotalPageCount = _c[1];
    var _d = React.useState(), viewdata = _d[0], setViewdata = _d[1];
    var _e = React.useState(appConstants_1.defaultPageLimit), PageLimit = _e[0], setPageLimit = _e[1];
    var _f = React.useState(new Date().toString()), startDate = _f[0], setStartDate = _f[1];
    var _g = React.useState(false), reloadPageAfterDelete = _g[0], setReloadPageAfterDelete = _g[1];
    var _h = React.useState(false), singleoccurance = _h[0], setSingleOccurance = _h[1];
    var _j = React.useState(false), yearoccurance = _j[0], setYearoccurance = _j[1];
    var _k = React.useState(new Date().toString()), endDate = _k[0], setEndDate = _k[1];
    var _l = React.useState(false), isDateRangeUpdated = _l[0], setIsDateRangeUpdated = _l[1];
    var _m = React.useState(0), totalCount = _m[0], setTotalCount = _m[1];
    var _o = React.useState(""), searchTerm = _o[0], setSearchTerm = _o[1];
    var _p = React.useState(undefined), notificationList = _p[0], setNotificationList = _p[1];
    var _q = React.useState(""), errorMessage = _q[0], setErrorMessage = _q[1];
    var _r = React.useState([]), selectedUsers = _r[0], setSelectedUsers = _r[1];
    var _s = React.useState([]), notificationCategory = _s[0], setNotificationCategory = _s[1];
    var notification_category = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).notification_category;
    var onClickHandler = function () {
        nav("".concat(route_path_1.default.PATHS.createNotification));
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.notificationManagement);
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var refetch = (0, react_query_1.useQuery)(['notification-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (searchTerm.trim().length >= 3) {
            payload.title = searchTerm.toLocaleLowerCase().trim();
        }
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (selectedUsers.length > 0) {
            var selectedUserGroups_1 = [];
            selectedUsers.forEach(function (user) { return selectedUserGroups_1.push(user.label); });
            payload.user_groups = selectedUserGroups_1;
        }
        if (notificationCategory.length > 0) {
            var selectedCategory_1 = [];
            notificationCategory.forEach(function (category) { return selectedCategory_1.push(category.id); });
            payload.category = selectedCategory_1;
        }
        payload.page = currentPage;
        payload.limit = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, NotificationHelpers_1.fetchNotificationList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setErrorMessage("");
            setTotalPageCount(count);
            var convertedData = (0, NotificationHelpers_1.convertNotificationListFromRes)(response.data.result);
            setNotificationList(convertedData);
            setTotalCount(response.data.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setNotificationList([]);
        });
        setReloadPageAfterDelete(false);
    }).refetch;
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value.trim());
    };
    var selectedUsersHandler = function (val) {
        setSelectedUsers(val);
    };
    var notificationCategoryHandler = function (val) {
        setNotificationCategory(val);
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [reloadPageAfterDelete]);
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var handleClose = function () { return setOpen(false); };
    var rowClickHandler = function (event) {
        if (event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-edit')) {
            nav("".concat(route_path_1.default.PATHS.createNotification, "/").concat(event.data.id));
        }
        if (event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-delete_forever')) {
            var id = event.data.id;
            var payload = {
                notification_id: id
            };
            (0, NotificationHelpers_1.deleteNotificationList)(payload)
                .then(function (response) {
                if (response.statusCode === 200) {
                    react_hot_toast_1.default.success(response.message);
                    setReloadPageAfterDelete(true);
                }
            })
                .catch(function (err) {
                console.log(err);
            });
        }
        else {
            var payload = {};
            payload['notification_id'] = event.data.id;
            (0, NotificationHelpers_1.NotificationDetails)(payload)
                .then(function (res) {
                var _a, _b;
                if (res.statusCode === 200) {
                    if ((res.data.frequency === 9) || (res.data.frequency === 8)) {
                        setYearoccurance(true);
                    }
                    else {
                        setYearoccurance(false);
                    }
                    if (res.data.frequency === 10) {
                        setSingleOccurance(true);
                    }
                    else {
                        setSingleOccurance(false);
                    }
                    setViewdata({
                        notificationCategory: (0, array_helpers_1.NotificationCat)(res.data.category),
                        userGroups: res.data.user_groups.map(function (e) {
                            return (0, CC_helper_1.convertEditData)(e);
                        }),
                        labCode: res.data.lab_codes.map(function (e) {
                            return (0, CC_helper_1.convertEditData)(e);
                        }),
                        occurrence: (0, CC_helper_1.convertEditData)((0, array_helpers_1.NotificationFreq)(res.data.frequency)),
                        startDate: res.data.start_date,
                        endDate: (res.data.end_date),
                        executionDate: res.data.execution_date,
                        notificationTitle: res.data.notification_title,
                        notificationMessage: res.data.notification_message,
                        execution_hour: res.data.execution_time ? (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.execution_time.split(":")[0] : '10',
                        execution_min: res.data.execution_time ? (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.execution_time.split(":")[1] : '00',
                    });
                }
            })
                .catch(function (err) {
                console.log(err);
            });
            setOpen(true);
        }
    };
    return (React.createElement("div", { className: "page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "User Notifications")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { color: "yellow", buttonName: "Create Notification", onClickHandler: onClickHandler, isError: !features[menu_constants_1.FEATURE_CONSTANTS.createNotification] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: "Search Notifications Title", searchValue: searchTerm, searchHandler: searchingHandler })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(multi_selector_1.default, { label: "Select Users", dropDownList: NotificationConstants_1.userGroupsOptions, onChangeHnadlre: selectedUsersHandler, value: selectedUsers }),
                React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true }),
                React.createElement(multi_selector_1.default, { label: "Select Category", dropDownList: notification_category, onChangeHnadlre: notificationCategoryHandler, value: notificationCategory }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "scrollable-content fe-managment-screen" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.NOTIFICATION_LIST_COLUMNS, rows: notificationList && notificationList, errorMessage: errorMessage, pagination: false, onRowClicked: rowClickHandler, className: 'bagging-lifecycle-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: notificationList === null || notificationList === void 0 ? void 0 : notificationList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(NotificationPopup_1.default, { open: open, handleClose: handleClose, notificationData: viewdata, name: "View", singleoccurence: singleoccurance, yearoccurance: yearoccurance })));
};
exports.default = NotificationList;
