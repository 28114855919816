"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_query_1 = require("react-query");
var react_router_1 = require("react-router");
var api_urls_1 = require("../../../../api/api_urls");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var route_planner_api_service_1 = require("../api/route_planner_api_service");
var route_planner_service_1 = require("../service/route_planner_service");
var EMPTY_VALUE = '--';
var ChangesList = function () {
    var id = (0, react_router_1.useParams)().id;
    var nav = (0, react_router_1.useNavigate)();
    var _a = React.useState([]), routePlannerDetils = _a[0], setRoutePlannerDetials = _a[1];
    var _b = React.useState(null), changesList = _b[0], setChangesList = _b[1];
    var _c = React.useState([]), profileDeatils = _c[0], setProfileDeatils = _c[1];
    var _d = React.useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    console.log(routePlannerDetils, 'gggroutePlannerDetils', changesList);
    var getRoutePlannerDetails = function (endPoint, payLoad, domain) {
        (0, route_planner_api_service_1.globalRoutePlannerGetAPI)(endPoint, payLoad, domain)
            .then(function (response) {
            setRoutePlannerDetials(response.changes);
            setErrorMessage("");
            if (response.changes.length > 0) {
                setProfileDeatils(response.changes);
                var changesData = (0, route_planner_service_1.converFEChangesList)(response.changes);
                setChangesList(changesData);
            }
        })
            .catch(function (err) {
            setErrorMessage(err);
        });
    };
    var refetch = (0, react_query_1.useQuery)('route_planner_details', function () {
        getRoutePlannerDetails(api_urls_1.api_urls.ROUTE_PLANEER_PROFILE_DETAILS, { route_id: id }, process.env.ONE_TWO_LEVEL_APPROVAL_URL);
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, []);
    return (React.createElement("div", null, !errorMessage ? (React.createElement("div", { className: "fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom " },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: function () { return nav(-1); } }),
                React.createElement("h2", null, "Changes List "))),
        React.createElement("div", { className: "change-list-container" }, profileDeatils && profileDeatils.length > 0 ? (React.createElement(React.Fragment, null,
            changesList && changesList.feChangesList.length > 0 ? (React.createElement(material_1.Box, null,
                React.createElement("h2", null, "FE Base Coordinates"),
                changesList.feChangesList.map(function (val) {
                    var _a, _b, _c, _d;
                    return (React.createElement(React.Fragment, null,
                        React.createElement(material_1.Grid, { container: true, columns: 15, paddingY: 2.5 },
                            React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, Object.keys(val)[0].toUpperCase()),
                                React.createElement("p", { className: "label-field" }, (_a = val.id) !== null && _a !== void 0 ? _a : EMPTY_VALUE)),
                            React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, Object.keys(val)[3]
                                    .toUpperCase()
                                    .split('_')
                                    .join(' ')),
                                React.createElement("p", { className: "label-field" }, (_b = val.fe_id) !== null && _b !== void 0 ? _b : EMPTY_VALUE)),
                            React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, Object.keys(val)[4].toUpperCase()),
                                React.createElement("p", { className: "label-field" }, (_c = val.latitude) !== null && _c !== void 0 ? _c : EMPTY_VALUE)),
                            React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, Object.keys(val)[5].toUpperCase()),
                                React.createElement("p", { className: "label-field" }, (_d = val.latitude) !== null && _d !== void 0 ? _d : EMPTY_VALUE))),
                        React.createElement(material_1.Divider, null)));
                }))) : null,
            changesList && changesList.routeConfigarations.length > 0 ? (React.createElement(material_1.Box, null,
                React.createElement("h2", null, "Route Congiguration"),
                changesList.routeConfigarations.map(function (val) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(material_1.Grid, { container: true, columns: 15, paddingY: 2.5 },
                            val.speed ? (React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, "Speed Km/hr"),
                                React.createElement("p", { className: "label-field" }, val.speed))) : null,
                            val.radius ? (React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, "FE Radius Km"),
                                React.createElement("p", { className: "label-field" }, val.radius))) : null,
                            val.center_type == route_planner_service_1.route_plan_center_types.CC ? (React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, "Buffer Time for CC (min)"),
                                React.createElement("p", { className: "label-field" }, val.buffer_time))) : null,
                            val.center_type ==
                                route_planner_service_1.route_plan_center_types.PSC ? (React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, "Buffer Time for PSC (min)"),
                                React.createElement("p", { className: "label-field" }, val.buffer_time))) : null,
                            val.center_type ==
                                route_planner_service_1.route_plan_center_types.FPSC ? (React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, "Buffer Time for FPSC (min)"),
                                React.createElement("p", { className: "label-field" }, val.buffer_time))) : null,
                            val.center_type ==
                                route_planner_service_1.route_plan_center_types.PUP ? (React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, "Buffer Time for PUB (min)"),
                                React.createElement("p", { className: "label-field" }, val.buffer_time))) : null,
                            val.center_type ==
                                route_planner_service_1.route_plan_center_types.HUB ? (React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, "Buffer Time for HUB (min)"),
                                React.createElement("p", { className: "label-field" }, val.buffer_time))) : null,
                            val.center_type ==
                                route_planner_service_1.route_plan_center_types.LAB ? (React.createElement(material_1.Grid, { md: 3, lg: 3 },
                                React.createElement("p", { className: "label-text" }, "Buffer Time for LAB (min)"),
                                React.createElement("p", { className: "label-field" }, val.buffer_time))) : null)));
                }))) : null,
            ' ')) : (React.createElement("div", { className: "no-data-found" },
            React.createElement("p", { className: "no-data-found-text" }, "No Changes Found")))))) : (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString()))));
};
exports.default = ChangesList;
