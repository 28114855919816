"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Timeline_1 = require("@mui/lab/Timeline");
var TimelineConnector_1 = require("@mui/lab/TimelineConnector");
var TimelineContent_1 = require("@mui/lab/TimelineContent");
var TimelineDot_1 = require("@mui/lab/TimelineDot");
var TimelineItem_1 = require("@mui/lab/TimelineItem");
var TimelineSeparator_1 = require("@mui/lab/TimelineSeparator");
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var api_urls_1 = require("../../../api/api_urls");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../constants/appConstants");
var profile_detaile_lable_1 = require("../../../constants/profile_detaile_lable");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var sample_tracking_api_calls_1 = require("./sample_tracking_api_calls");
var state_city_filter_1 = require("./state_city_filter");
var SampleTraking = function () {
    var Samplefeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.sampleTracking);
    console.log('Samplefeatures:', Samplefeatures);
    var nav = (0, react_router_1.useNavigate)();
    var obj = {};
    var emptyDetails = '--';
    var bagStatusList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.sample_status; });
    var LabcodeList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _a = React.useState(''), filterFromDate = _a[0], setFromDateFilter = _a[1];
    var _b = React.useState(''), filterToDate = _b[0], setToDateFilter = _b[1];
    var _c = React.useState(false), open = _c[0], setOpen = _c[1];
    var _d = React.useState([]), filteredCities = _d[0], setFilteredCities = _d[1];
    var _e = React.useState(0), totalCount = _e[0], setTotalCount = _e[1];
    var onChangeHandler = function (cityList) {
        setFilteredCities(cityList);
        obj['city'] = cityList;
    };
    var _f = React.useState(undefined), dataList = _f[0], setDataList = _f[1];
    var _g = React.useState([]), sampleBagDetails = _g[0], setSampleBagDetails = _g[1];
    var _h = React.useState(), ref_no = _h[0], setref_no = _h[1];
    console.log(ref_no, 'ref_no');
    var _j = React.useState(''), searchTerm = _j[0], setSearchTerm = _j[1];
    var _k = React.useState(null), bagStatus = _k[0], SetbagStatus = _k[1];
    var _l = React.useState([]), filetrLabs = _l[0], setFilteredLabs = _l[1];
    var _m = React.useState(1), pageCount = _m[0], setpageCount = _m[1];
    var _o = React.useState(0), totalPageCount = _o[0], setTotalPageCount = _o[1];
    var _p = React.useState(appConstants_1.defaultPageLimit), PageLimit = _p[0], setPageLimit = _p[1];
    var _q = React.useState(''), errorMessage = _q[0], setErrorMessage = _q[1];
    var fetchbagSampleJourney = function (endPoint, domain) {
        (0, sample_tracking_api_calls_1.fetchBagSampleJourney)(endPoint, domain)
            .then(function (response) {
            setSampleBagDetails(response);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var trackingBaghandleDrawerClose = function (e) {
        setOpen(false);
    };
    var navigateToBasicDetails = function (value) {
        var bag_barcode = value.data.bag_barcode;
        var ref_no = value.data.reference_number;
        setref_no(value.data.reference_number);
        console.log('bag_barcode:', bag_barcode);
        if (bag_barcode) {
            nav("/".concat(route_path_1.default.PATHS.sampleTracking, "/").concat(route_path_1.default.PATHS.sampleTraking_basicDetails, "/").concat(bag_barcode));
        }
        else {
            setOpen(true);
            fetchbagSampleJourney("".concat(api_urls_1.api_urls.SAMPLE_BAG_JOURNEY, "?reference_number=").concat(ref_no && ref_no), process.env.SAMPLE_TRAKING_URL);
        }
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var searchingHandler = function (event) {
        setSearchTerm(event.target.value.trim());
        obj['search'] = event.target.value.trim();
    };
    var bagStatusHandler = function (status) {
        SetbagStatus(status);
        obj['status'] = status;
    };
    var callbackUpdateSelectedStates = function (value) {
        if (value.length >= 0) {
            setFilteredLabs(value);
        }
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
            obj['fromdate'] = moment(dateRangeArray.start).format('YYYY-MM-DD');
            obj['todate'] = moment(dateRangeArray.end).format('YYYY-MM-DD');
        }
        if (dateRangeArray === null) {
            setFromDateFilter(null);
            setToDateFilter(null);
            obj['fromdate'] = null;
            obj['todate'] = null;
        }
    };
    var refetch = (0, react_query_1.useQuery)(['sample-tracking-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        var todaysDate = new Date();
        if (bagStatus !== null) {
            payload.status = bagStatus.id;
        }
        if (filetrLabs.length > 0) {
            var selecedLabcodes = (0, array_helpers_1.selecteAllOption)(filetrLabs, LabcodeList);
            payload.lab_code = selecedLabcodes;
        }
        if (filterFromDate && filterToDate !== null) {
            payload.date_range = {
                start_date: filterFromDate,
                end_date: filterToDate,
            };
        }
        else if (filterFromDate === '') {
            payload.date_range = {
                start_date: (0, array_helpers_1.dateFormateYMD)(todaysDate),
                end_date: (0, array_helpers_1.dateFormateYMD)(todaysDate),
            };
        }
        if (filteredCities.length > 0) {
            var data_1 = [];
            filteredCities.map(function (selectedCityObj) {
                data_1.push({
                    city: selectedCityObj.short_code,
                    state: selectedCityObj.state_name,
                });
            });
            payload.geographies = data_1;
        }
        if (searchTerm.trim().length >= 3) {
            payload.search_key = searchTerm.trim();
        }
        payload.page = currentPage;
        payload.size = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, sample_tracking_api_calls_1.fetchSampleTrackingList)(payload)
            .then(function (response) {
            console.log(response, 'datadat');
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setErrorMessage('');
            setTotalPageCount(count);
            setDataList(response.result);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setDataList([]);
        });
    }).refetch;
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    console.log(obj, 'okijhgfd');
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var SampleTrackingDetails = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "drawer-content drawer-track-bag" },
                React.createElement("div", { className: "drawer-header" },
                    React.createElement("div", { className: "drawer-heading-wrapper" },
                        React.createElement("h2", null,
                            " ",
                            profile_detaile_lable_1.default.SAMPLE_BAG_HEADINGS[5]),
                        React.createElement("small", { className: "sub-heading" },
                            "REF NO:",
                            ref_no ? ref_no : emptyDetails)),
                    React.createElement("div", { onClick: trackingBaghandleDrawerClose, className: "drawer-close-btn" },
                        React.createElement("span", { className: "icon-close" }))),
                React.createElement("div", { className: "stepper-container timeline-wrapper" },
                    React.createElement(Timeline_1.default, { position: "right" }, sampleBagDetails === null || sampleBagDetails === void 0 ? void 0 : sampleBagDetails.map(function (e) {
                        console.log(e.event_type, 'eee');
                        console.log('bag');
                        return (React.createElement(React.Fragment, null,
                            React.createElement(TimelineItem_1.default, null,
                                React.createElement(TimelineSeparator_1.default, null,
                                    React.createElement(TimelineDot_1.default, { className: "step-icon-wrapper " },
                                        React.createElement("span", { className: e.event_type === 'Bag Created'
                                                ? 'icon-menu-sheet step-icon'
                                                : e.event_type === 'Master_box_created'
                                                    ? 'icon-menu-sheet step-icon'
                                                    : e.event_type === 'In transit'
                                                        ? 'icon-directions_bike step-icon'
                                                        : e.event_type === 'Handover Done'
                                                            ? 'icon-Group-1 step-icon'
                                                            : e.event_type === 'Hub Receiving Done'
                                                                ? 'icon-building step-icon'
                                                                : e.event_type === 'Received at Lab'
                                                                    ? 'icon-Vector-25 step-icon'
                                                                    : e.event_type === 'Registered'
                                                                        ? 'icon-Vector-25 step-icon'
                                                                        : '' })),
                                    React.createElement(TimelineConnector_1.default, null)),
                                e.event_type === 'Bag Created' ? (React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" },
                                            ' ',
                                            e && e.event_type ? e.event_type : emptyDetails),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" },
                                                "Bag Created By Id:",
                                                e && e.created_by_id ? e.created_by_id : emptyDetails,
                                                ", NAME:",
                                                e && e.created_by_name ? e.created_by_name : emptyDetails,
                                                ", TYPE:",
                                                e && e.created_by_type ? e.created_by_type : emptyDetails,
                                                ' '),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp))),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" }))))) : e.event_type === 'Registered' ||
                                    e.event_type === 'Received at Lab' ? (React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" },
                                            ' ',
                                            e && e.event_type ? e.event_type.toUpperCase() : emptyDetails),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp)))))) : (React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" },
                                            ' ',
                                            e && e.event_type ? e.event_type.toUpperCase() : emptyDetails),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" },
                                                "Bag Handed over To",
                                                ' ',
                                                e && e.to_user_name ? e.to_user_name : emptyDetails,
                                                ",",
                                                ' ',
                                                e && e.to_user_type ? e.to_user_type : emptyDetails,
                                                ' ',
                                                e && e.to_user_id ? e.to_user_id : emptyDetails),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp))),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" },
                                                "Bag Received At",
                                                ' ',
                                                e && e.from_user_name ? e.from_user_name : emptyDetails,
                                                ",",
                                                ' ',
                                                e && e.from_user_type ? e.from_user_type : emptyDetails,
                                                ' ',
                                                e && e.from_user_id ? e.from_user_id : emptyDetails),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp)))))))));
                    }))))));
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Sample Tracking ")),
            React.createElement("div", { className: "page-control-wrapper" })),
        React.createElement("div", { className: "filter-menu-wrapper long-filter" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: "Search by Ref No,Bag Id", searchValue: searchTerm, searchHandler: searchingHandler })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true }),
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "status", placeholder: "Status", dropDownList: bagStatusList, onChange: bagStatusHandler, value: bagStatus || null }),
                React.createElement(state_city_filter_1.default, { onChangeHandler: onChangeHandler }),
                React.createElement(multi_selector_1.default, { label: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(LabcodeList), onChangeHnadlre: callbackUpdateSelectedStates, value: filetrLabs }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "scrollable-content fe-managment-screen" },
            React.createElement("div", { className: "custom-pagination-table" },
                React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.SAMPLE_TRACKING_COLUMNS, rows: dataList && dataList, pagination: false, errorMessage: errorMessage, multiSelector: "multiselect", onRowClicked: Samplefeatures[menu_constants_1.FEATURE_CONSTANTS.sampleBagDetails] &&
                        navigateToBasicDetails, className: 'sample-tracking-table' }),
                React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }))),
        React.createElement("div", { className: "sample-tracking-wrapper scroll-xs" },
            React.createElement(Grid_1.default, { container: true, xs: 12, className: "details-tab-wrapper" },
                React.createElement("div", { className: "drawer-container" },
                    React.createElement(material_1.Drawer, { variant: "temporary", anchor: "right", open: open, className: "track-bag-container" },
                        React.createElement(SampleTrackingDetails, null)))))));
};
exports.default = SampleTraking;
