"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var map_constants_1 = require("../../constants/map_constants");
var route_path_1 = require("../../constants/route_path");
var track_fe_1 = require("../../constants/track_fe");
var menu_constants_1 = require("../../menuList/menu_constants");
var array_helpers_1 = require("../../utils/array_helpers");
var button_component_1 = require("../buttons/button_component");
require("./stepper.scss");
var emptyValue = '--';
var StepperVertical = function (_a) {
    var clickAble = _a.clickAble, trackerInfo = _a.trackerInfo, distance = _a.distance, role_id = _a.role_id, setIsBypassModalOpen = _a.setIsBypassModalOpen, setVisitId = _a.setVisitId;
    var pathname = (0, react_router_1.useLocation)().pathname;
    var getVisitStatus = function (visit_type) {
        switch (visit_type) {
            case 1:
                return 'PICKUP';
            case 2:
                return 'HUB_RECEIVING';
            case 3:
                return 'HANDOVER_HUB';
            case 4:
                return 'HANDOVER_LAB';
            case 5:
                return 'CASH_HANDOVER';
            case 6:
                return 'MASTER_BOX_PICKUP';
            case 7:
                return 'INTERCITY_HANDOVER';
            case 8:
                return 'INTERCITY_PICKUP';
            case 9:
                return 'HUB_RETURN';
            default:
                return '';
        }
    };
    var feManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.feManagement);
    console.log(trackerInfo, 'trackerInfo', feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.Bypass_Visit]);
    return (React.createElement("div", { className: "stepper-wrapper" },
        React.createElement("ul", { className: "steps-list" }, trackerInfo &&
            trackerInfo.trackList &&
            trackerInfo.trackList.map(function (data) {
                return (React.createElement("li", { className: "step-item" },
                    React.createElement("div", { className: "step-content" },
                        React.createElement("div", { className: "steper-top-content displayflex" },
                            React.createElement("div", { className: "step-left-content displayflex" },
                                React.createElement("span", { className: "progress-status ".concat(data.status === track_fe_1.VISIT_STATUS.Completed
                                        ? 'completed'
                                        : data.status === track_fe_1.VISIT_STATUS.Started ||
                                            data.status === track_fe_1.VISIT_STATUS.Reached
                                            ? 'inprogress'
                                            : 'next-step', " progress-indicator") }),
                                React.createElement("div", { className: "step-detials-wrapper" },
                                    !pathname.includes("".concat(route_path_1.PATHS.onDemandRequest)) ? (React.createElement("small", { className: "time-stamp-label" }, data.slotTime ? data.slotTime : emptyValue)) : (React.createElement("small", { className: "time-stamp-label" }, data.scheduled_start_time
                                        ? data.scheduled_start_time
                                        : emptyValue)),
                                    pathname.includes("".concat(route_path_1.PATHS.onDemandRequest)) ? (React.createElement("span", { className: "badge time-badge pickup" }, getVisitStatus(data.visit_type))) : null,
                                    !pathname.includes("".concat(route_path_1.PATHS.onDemandRequest)) ? (data.visiteType === map_constants_1.VIST_TYPE_INFO.PICKUP ? (React.createElement("span", { className: "badge time-badge pickup" }, map_constants_1.VISIT_TYPES_FOR_TRACING.cc_code)) : data.visiteType === map_constants_1.VIST_TYPE_INFO.HUB_RECEIVING ? (React.createElement("span", { className: "badge time-badge  handover-hub" }, map_constants_1.VISIT_TYPES_FOR_TRACING.hub_code)) : data.visiteType === map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB ? (data.visiteType === map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB &&
                                        role_id &&
                                        role_id == 2 ? (React.createElement("span", { className: "badge time-badge handover-hub" }, map_constants_1.VISIT_TYPES_FOR_TRACING.hub_code)) : (React.createElement("span", { className: "badge time-badge handover-hub" }, map_constants_1.VISIT_TYPES_FOR_TRACING.handorver_hub))) : data.visiteType === map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ? (React.createElement("span", { className: "badge time-badge handover-hub" }, map_constants_1.VISIT_TYPES_FOR_TRACING.lab_Code)) : data.visiteType === map_constants_1.VIST_TYPE_INFO.PICKUP_MASTER_BOX ? (React.createElement("span", { className: "badge time-badge handover-hub" }, map_constants_1.VISIT_TYPES_FOR_TRACING.pickup_master_box)) : (React.createElement("span", { className: "badge time-badge handoverCash" }, map_constants_1.VISIT_TYPES_FOR_TRACING.hub_cash_code))) : null,
                                    data.status === track_fe_1.VISIT_STATUS.Completed &&
                                        data.visiteType != map_constants_1.VIST_TYPE_INFO.CASH_HANDOVER ? (data.timeStatus === track_fe_1.TRACK_STATUS.onTime ? (React.createElement("span", { className: "badge time-badge ontime" }, "Ontime")) : (React.createElement("span", { className: "badge time-badge delayed" }, "Delayed"))) : null,
                                    data.bypassed_visit && data.bypassed_visit ? (React.createElement("span", { className: "badge time-badge visit_bypassed" }, "VISIT BY PASSED")) : null,
                                    data.status == track_fe_1.VISIT_STATUS.Pending ||
                                        data.status == track_fe_1.VISIT_STATUS.Assigned ||
                                        data.status == track_fe_1.VISIT_STATUS.Started ||
                                        (data.status == track_fe_1.VISIT_STATUS.Reached &&
                                            feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.Bypass_Visit]) ? (React.createElement("span", { className: "bypass-btn" },
                                        React.createElement(button_component_1.default, { color: "yellow-outline-bypass", size: "small", buttonName: "BY PASS", onClickHandler: function () {
                                                setIsBypassModalOpen(true);
                                                setVisitId(data.visit_id);
                                            } }))) : null,
                                    data.assignment_type === track_fe_1.TRACK_STATUS.ondemand ? (React.createElement("span", { className: "badge time-badge ondemand" }, "Ondemand")) : null,
                                    React.createElement("h3", { className: "step-title" },
                                        data.warehouse_code ? data.warehouse_code : emptyValue,
                                        " -",
                                        data.labName ? data.labName : emptyValue),
                                    React.createElement("p", { className: "step-deccription" }, data.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP_MASTER_BOX
                                        ? data.mobile
                                        : data.center_address
                                            ? data.center_address
                                            : emptyValue))),
                            pathname.includes("".concat(route_path_1.PATHS.onDemandRequest)) ? (React.createElement("span", { className: "step-distance" },
                                distance,
                                " km")) : null),
                        React.createElement("div", { className: "step-bottom-content displayflex" },
                            data.status == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ? (React.createElement("p", { className: "badge-lg bags-badge" },
                                data.bags,
                                " Bags")) : null,
                            data.status == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ? (React.createElement("p", { className: "badge-lg cash-badge" },
                                "\u20B9 ",
                                data.cash)) : null))));
            }))));
};
exports.default = StepperVertical;
