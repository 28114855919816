"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Visibility_1 = require("@mui/icons-material/Visibility");
var VisibilityOff_1 = require("@mui/icons-material/VisibilityOff");
var material_1 = require("@mui/material");
var InputAdornment_1 = require("@mui/material/InputAdornment");
var TextField_1 = require("@mui/material/TextField");
var React = require("react");
require("./input-field.scss");
var TextFiledPassword = function (_a) {
    var type = _a.type, name = _a.name, placeholder = _a.placeholder, className = _a.className, value = _a.value, onChange = _a.onChange, minLength = _a.minLength, style = _a.style, label = _a.label, error = _a.error, required = _a.required, onClick = _a.onClick, isRequered = _a.isRequered, defaultVal = _a.defaultVal, isDisabled = _a.isDisabled, FormHelperTextProps = _a.FormHelperTextProps, onBlur = _a.onBlur, maxLength = _a.maxLength, onKeyPress = _a.onKeyPress, readOnly = _a.readOnly, onFocus = _a.onFocus, autoComplete = _a.autoComplete;
    var _b = React.useState(false), showPassword = _b[0], setShowPassword = _b[1];
    var onClicks = function () { return setShowPassword(!showPassword); };
    var _c = React.useState(''), textFieldval = _c[0], setTextFieldVal = _c[1];
    var onChangeHandler = function (e) {
        setTextFieldVal(e.target.value);
        if (onChange) {
            onChange(e.target.value);
        }
    };
    React.useEffect(function () {
        setTextFieldVal(value);
    }, [value]);
    var params = [];
    if (value) {
        params.push({ label: value });
    }
    return (React.createElement("div", { className: required ? 'input-wrapper validate-filed ' : 'input-wrapper' },
        React.createElement(TextField_1.default, { type: showPassword ? 'text' : 'password', key: name, name: name, className: className, InputProps: {
                endAdornment: (React.createElement(InputAdornment_1.default, { position: "end", onClick: onClicks },
                    React.createElement(material_1.IconButton, { disableRipple: false }, showPassword ? React.createElement(Visibility_1.default, null) : React.createElement(VisibilityOff_1.default, null)))),
            }, placeholder: placeholder, value: textFieldval, label: label, variant: "filled", onChange: onChangeHandler, error: !!error, onKeyPress: onKeyPress, autoComplete: "new-password", helperText: error ? error : null, style: style, fullWidth: true, defaultValue: defaultVal })));
};
exports.default = TextFiledPassword;
