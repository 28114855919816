"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_router_1 = require("react-router");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var precious_samples_api_calls_1 = require("./precious_samples_api_calls");
var Precious_Sample = function () {
    var addSample = {
        cpt_code: '',
        test_no: '',
        sample_type: '',
        container_mat_code: '',
        test_cat_code: '',
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.preciousSample);
    var _a = React.useState(null), getEditdata = _a[0], setEditdata = _a[1];
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState(false), Downloadopen = _c[0], setDownloadOpen = _c[1];
    var _d = React.useState(undefined), dataList = _d[0], setSamplesList = _d[1];
    var _e = React.useState([]), sampleTypeList = _e[0], setSampleTypeList = _e[1];
    var _f = React.useState([]), containerMatCodeList = _f[0], setContainerMatCodeList = _f[1];
    var _g = React.useState([]), catcodeList = _g[0], setCatcodeList = _g[1];
    var _h = React.useState(''), errorMessage = _h[0], setErrorMessage = _h[1];
    var _j = React.useState(''), searchTerm = _j[0], setSearchTerm = _j[1];
    var _k = React.useState(null), sampleType = _k[0], setSampleType = _k[1];
    var _l = React.useState(null), containerMatCode = _l[0], setContainerMatCode = _l[1];
    var _m = React.useState(null), testCatCode = _m[0], setTestCatCode = _m[1];
    var _o = React.useState(false), openPop = _o[0], setOpenPop = _o[1];
    var _p = React.useState(null), deleteId = _p[0], setIdds = _p[1];
    var _q = React.useState(1), pageCount = _q[0], setpageCount = _q[1];
    var _r = React.useState(0), totalPageCount = _r[0], setTotalPageCount = _r[1];
    var _s = React.useState(appConstants_1.defaultPageLimit), PageLimit = _s[0], setPageLimit = _s[1];
    var _t = React.useState(0), totalCount = _t[0], setTotalCount = _t[1];
    var navigate = (0, react_router_1.useNavigate)();
    var handleOpenPop = function (e) {
        setOpenPop(true);
    };
    var handleClosePop = function () {
        setOpenPop(false);
    };
    var handleOpen = function () {
        setEditdata(null);
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value.trim());
    };
    var sampleTypeHandler = function (e) {
        setSampleType(e);
    };
    var containerMatCodeHandler = function (e) {
        setContainerMatCode(e);
    };
    var testCatCodeHandler = function (e) {
        setTestCatCode(e);
    };
    var navigateToMyReports = function () {
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var refetch = (0, react_query_1.useQuery)(['Precious-Sample-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (searchTerm.trim().length >= 3) {
            payload.search_string = searchTerm.toLocaleLowerCase().trim();
        }
        if (sampleType === null || sampleType === void 0 ? void 0 : sampleType.length) {
            payload.sample_type = sampleType.map(function (data) {
                return data.value.toString();
            });
        }
        if (containerMatCode === null || containerMatCode === void 0 ? void 0 : containerMatCode.length) {
            payload.container_mat_code = containerMatCode.map(function (data) {
                return data.value.toString();
            });
        }
        if (testCatCode === null || testCatCode === void 0 ? void 0 : testCatCode.length) {
            payload.test_cat_code = testCatCode.map(function (data) {
                return data.value.toString();
            });
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, precious_samples_api_calls_1.fetchPreciousSamplesList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setSamplesList(response.result);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setSamplesList([]);
        });
    }).refetch;
    var formSubmitHandler = function (params) {
        var payload = {};
        (payload.test_id = params.test_id),
            (payload.cpt_code = params.cpt_code),
            (payload.test_no = params.test_no),
            (payload.sample_type = params.sample_type),
            (payload.container_mat_code = params.container_mat_code),
            (payload.test_cat_code = params.test_cat_code);
        if (getEditdata !== null) {
            (0, precious_samples_api_calls_1.editPreciousSample)(payload)
                .then(function (response) {
                react_hot_toast_1.default.success(response.message);
                refetch();
                getPreciousSampleTypes();
                getPreciousContainerMatcodeTypes();
                getPreciousSamplesCatCodetype();
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err.message);
            });
        }
        else if (getEditdata == null) {
            (0, precious_samples_api_calls_1.createPreciousSample)(payload)
                .then(function (response) {
                react_hot_toast_1.default.success(response.message);
                refetch();
                getPreciousSampleTypes();
                getPreciousContainerMatcodeTypes();
                getPreciousSamplesCatCodetype();
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err.message);
            });
        }
        setOpen(false);
    };
    var onRowClick = function (event) {
        if (event.event.target.className.includes('icon-edit')) {
            setEditdata({
                cpt_code: event.data.cpt_code,
                test_no: event.data.test_no,
                sample_type: event.data.sample_type,
                container_mat_code: event.data.container_mat_code,
                test_cat_code: event.data.test_cat_code,
                test_id: event.data.id,
            });
            setOpen(true);
        }
        if (event.event.target.className.includes('icon-delete_forever')) {
            handleOpenPop(event.data.id);
            setIdds(event.data.id);
        }
    };
    var deleteUser = function () {
        (0, precious_samples_api_calls_1.deletePreciousSample)("".concat(api_service_1.api_service.api_urls.DELETE_PRECIOUS_SAMPLES_LIST, "?test_id=").concat(deleteId))
            .then(function (res) {
            react_hot_toast_1.default.success(res.message);
            refetch();
            getPreciousSampleTypes();
            getPreciousContainerMatcodeTypes();
            getPreciousSamplesCatCodetype();
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
        handleClosePop();
    };
    var getPreciousSampleTypes = function () {
        (0, precious_samples_api_calls_1.fetchPreciousSampletypes)()
            .then(function (response) {
            var types = [];
            Object.entries(response === null || response === void 0 ? void 0 : response.data).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                types.push({
                    id: key,
                    label: value === null || value === void 0 ? void 0 : value.sample_type,
                    value: value === null || value === void 0 ? void 0 : value.sample_type,
                });
            });
            setSampleTypeList(types);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
        });
    };
    var getPreciousContainerMatcodeTypes = function () {
        (0, precious_samples_api_calls_1.fetchPreciousContainerMatcodeTypes)()
            .then(function (response) {
            var types = [];
            Object.entries(response === null || response === void 0 ? void 0 : response.data).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                types.push({
                    id: key,
                    label: value === null || value === void 0 ? void 0 : value.container_mat_code,
                    value: value === null || value === void 0 ? void 0 : value.container_mat_code,
                });
            });
            setContainerMatCodeList(types);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
        });
    };
    var getPreciousSamplesCatCodetype = function () {
        (0, precious_samples_api_calls_1.fetchPreciousSamplesCatCodetype)()
            .then(function (response) {
            setErrorMessage("");
            var types = [];
            Object.entries(response === null || response === void 0 ? void 0 : response.data).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                types.push({
                    id: key,
                    label: value === null || value === void 0 ? void 0 : value.test_cat_code,
                    value: value === null || value === void 0 ? void 0 : value.test_cat_code,
                });
            });
            setCatcodeList(types);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
        });
    };
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (searchTerm.trim().length >= 3) {
            payload.search_string = searchTerm.toLocaleLowerCase().trim();
        }
        if (sampleType === null || sampleType === void 0 ? void 0 : sampleType.length) {
            payload.sample_type = sampleType.map(function (data) {
                return data.value.toString();
            });
        }
        if (containerMatCode === null || containerMatCode === void 0 ? void 0 : containerMatCode.length) {
            payload.container_mat_code = containerMatCode.map(function (data) {
                return data.value.toString();
            });
        }
        if (testCatCode === null || testCatCode === void 0 ? void 0 : testCatCode.length) {
            payload.test_cat_code = testCatCode.map(function (data) {
                return data.value.toString();
            });
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Precious_sample_downlaod,
            payLoad: payload,
            domain: process.env.SAMPLE_TRAKING_URL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                setDownloadOpen(true);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    React.useEffect(function () {
        onRowClick;
    }, [getEditdata]);
    React.useEffect(function () {
        getPreciousSampleTypes();
        getPreciousContainerMatcodeTypes();
        getPreciousSamplesCatCodetype();
    }, []);
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    return (React.createElement("div", { className: "page-wrapper bagging-master-page" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, " Precious Sample Master")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "download", color: "yellow-outline", endIcon: "icon-import download-icon", isError: (dataList === null || dataList === void 0 ? void 0 : dataList.length) > 0 ? false : true, onClickHandler: autoGenerateReportHandler }),
                React.createElement(button_component_1.default, { buttonName: "Add Precious Sample", color: "yellow", onClickHandler: handleOpen, isError: !features[menu_constants_1.FEATURE_CONSTANTS.addSample] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: "Search", searchValue: searchTerm, searchHandler: searchingHandler })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(multi_selector_1.default, { label: "Select Sample Type", dropDownList: sampleTypeList, onChangeHnadlre: sampleTypeHandler, value: sampleType }),
                React.createElement(multi_selector_1.default, { label: "Select Container Mat Code", dropDownList: containerMatCodeList, onChangeHnadlre: containerMatCodeHandler, value: containerMatCode }),
                React.createElement(multi_selector_1.default, { label: "Select Cat Code", dropDownList: catcodeList, onChangeHnadlre: testCatCodeHandler, value: testCatCode }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.PRECIOUS_SAMPLE_MASTER_COLUMNS, rows: dataList, errorMessage: errorMessage, onRowClicked: onRowClick, pagination: false, className: 'precious-sample-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(pop_up_1.default, { open: Downloadopen },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setDownloadOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false }))),
        React.createElement(pop_up_1.default, { open: open, popUpSize: "popup-lg" },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" },
                    getEditdata !== null ? 'Edit Form' : ' Create Form',
                    ' ')),
            React.createElement("div", { className: "modal-body" },
                React.createElement(formik_1.Formik, { initialValues: __assign({}, (getEditdata ? getEditdata : addSample)), validationSchema: schema_1.schemas.PRECIOUS_SAMPLE_MASTER, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, value: values.cpt_code, label: "CPT Code", onChange: function (event) {
                                        return setFieldValue('cpt_code', event);
                                    } }),
                                errors['cpt_code'] &&
                                    touched['cpt_code'] ? (React.createElement("div", { className: "error" }, errors['cpt_code'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { value: values.test_no, required: true, label: "Test No", onChange: function (event) {
                                        return setFieldValue('test_no', event);
                                    } }),
                                errors['test_no'] &&
                                    touched['test_no'] ? (React.createElement("div", { className: "error" }, errors['test_no'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { value: values.sample_type, required: true, label: "Sample Type", onChange: function (event) {
                                        return setFieldValue('sample_type', event);
                                    } }),
                                errors['sample_type'] &&
                                    touched['sample_type'] ? (React.createElement("div", { className: "error" }, errors['sample_type'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { value: values.container_mat_code, required: true, label: "Container Mat Code", onChange: function (event) {
                                        return setFieldValue('container_mat_code', event);
                                    } }),
                                errors['container_mat_code'] &&
                                    touched['container_mat_code'] ? (React.createElement("div", { className: "error" }, errors['container_mat_code'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { value: values.test_cat_code, required: true, label: "Test Cat Code", onChange: function (event) {
                                        return setFieldValue('test_cat_code', event);
                                    } }),
                                errors['test_cat_code'] &&
                                    touched['test_cat_code'] ? (React.createElement("div", { className: "error" }, errors['test_cat_code'])) : null)),
                        React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose }),
                            React.createElement(button_component_1.default, { buttonName: "SAVE", color: "yellow", type: "submit" }))));
                }))),
        React.createElement(material_1.Modal, { open: openPop },
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                    React.createElement("h2", null, "Delete Sample")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, "Are you sure  want to delete the sample?")),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "No", color: "yellow-outline", onClickHandler: handleClosePop }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: "Yes", color: "yellow", onClickHandler: deleteUser }))))));
};
exports.default = Precious_Sample;
