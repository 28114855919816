"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Constants = {
    showLoader: 'showLoader',
};
var showLoader = function (data) { return ({
    type: Constants.showLoader,
    payload: data,
}); };
exports.default = {
    Constants: Constants,
    showLoader: showLoader,
};
