"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HUB_MASTER_INTITAL_VALUES = void 0;
exports.HUB_MASTER_INTITAL_VALUES = {
    hubCode: '',
    hubName: '',
    invoice_code: '',
    hubType: null,
    hubState: '',
    hubCity: '',
    labcode: null,
    baseLocation: '',
    base_location_address: '',
    address: '',
    geofence_enabled: 0,
    geofence_radius: 0,
};
