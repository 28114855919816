"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../reports_helper");
var SuccessfulVisitsReports = function (_a) {
    var filterFromDate = _a.filterFromDate, filterToDate = _a.filterToDate, selectedLab = _a.selectedLab, PageLimit = _a.PageLimit, isDateRangeUpdated = _a.isDateRangeUpdated, setPageNumber = _a.setPageNumber, setIsReportList = _a.setIsReportList, filter = _a.filter;
    var _b = React.useState(1), pageCount = _b[0], setpageCount = _b[1];
    var _c = React.useState(0), totalPageCount = _c[0], setTotalPageCount = _c[1];
    var _d = React.useState(0), totalCount = _d[0], setTotalCount = _d[1];
    var _e = React.useState(undefined), dataList = _e[0], setDataList = _e[1];
    var _f = React.useState(''), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = React.useState({}), gridParams = _g[0], setGridParams = _g[1];
    var onGridReady = function (params) {
        setGridParams(params);
    };
    var agGridTableHeaders = function (response, params) {
        var _a;
        var flattenKeys = Object.keys((0, array_helpers_1.flattenObjForReports)(response === null || response === void 0 ? void 0 : response.result));
        flattenKeys.sort();
        var colDef = [];
        if (flattenKeys.length) {
            flattenKeys.map(function (data, index) {
                var obj = {};
                obj['headerName'] = data.toLocaleUpperCase().replaceAll('_', ' ');
                obj['field'] = data ? data : '--';
                obj['suppressMovable'] = true;
                obj['unSortIcon'] = true;
                if (data.includes(':')) {
                    obj['lockPosition'] = 'right';
                }
                if (data == "visit_date") {
                    obj['valueFormatter'] = array_helpers_1.dateFormatterForMIS;
                }
                if ((obj === null || obj === void 0 ? void 0 : obj.headerName) !== "" && (obj === null || obj === void 0 ? void 0 : obj.headerName) !== "TOTAL TIME WINDOW SLOTS" && (obj === null || obj === void 0 ? void 0 : obj.headerName) !== "NOTHING TO PICKUP" && (obj === null || obj === void 0 ? void 0 : obj.headerName) !== "NOTHING TO PICKUPS" && (obj === null || obj === void 0 ? void 0 : obj.headerName) !== "VISIT STATUSES" && (obj === null || obj === void 0 ? void 0 : obj.headerName) !== "VISIT STATUS") {
                    colDef.push(obj);
                }
            });
        }
        (_a = params === null || params === void 0 ? void 0 : params.api) === null || _a === void 0 ? void 0 : _a.setColumnDefs(colDef);
    };
    var refetch = (0, react_query_1.useQuery)(['Successful-Visits', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        console.log("datedf0", filterFromDate);
        if (filterFromDate && filterToDate !== null) {
            console.log("datedf", filterFromDate);
            payload['date_range'] = {
                start_date: filterFromDate,
                end_date: filterToDate,
            };
        }
        if (selectedLab.length > 0) {
            payload['lab_codes'] = selectedLab.map(function (lab) {
                return lab.value;
            });
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, reports_helper_1.SuccessfulVisits)(payload)
            .then(function (response) {
            console.log("resso2", response);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setTotalCount(response.count);
            agGridTableHeaders(response, gridParams);
            var convertedResponse = (0, array_helpers_1.clientSlotwiseReportResponseConverter)(response === null || response === void 0 ? void 0 : response.result);
            var flattenKeys = Object.keys((0, array_helpers_1.flattenObjForReports)(convertedResponse));
            var defaulValue = flattenKeys.reduce(function (result, key) {
                result[key] = '--';
                return result;
            }, {});
            var modifiedData = convertedResponse.map(function (item) { return (__assign(__assign({}, defaulValue), item)); });
            console.log(modifiedData, 'modifiedData');
            setIsReportList(response === null || response === void 0 ? void 0 : response.result);
            setDataList(modifiedData);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err);
            setErrorMessage(err);
            setDataList([]);
        });
    }).refetch;
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
        setPageNumber(currentPage);
    };
    React.useEffect(function () {
        if (Object.keys(gridParams).length != 0) {
            if (pageCount != 1) {
                setpageCount(1);
            }
            else {
                refetch();
            }
        }
    }, [filter, gridParams]);
    return (React.createElement("div", { className: "fe-wrapper custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, rows: dataList, errorMessage: errorMessage, pagination: false, enableCellTextSelection: true, onGridReady: onGridReady }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })));
};
exports.default = SuccessfulVisitsReports;
