"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var moment = require("moment");
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var multi_selector_1 = require("../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../common/drop_down/drop_down_component");
var text_filed_with_fromto_Daterange_1 = require("../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../constants/appConstants");
var array_helpers_1 = require("../../utils/array_helpers");
var generic_api_calls_1 = require("../generic_api_calls");
var auto_routing_api_calls_1 = require("../super_admin/control_dashboard/auto_routing_api_calls");
var auto_routing_helper_1 = require("../super_admin/control_dashboard/auto_routing_helper");
var conroldashboardstyles_1 = require("../super_admin/control_dashboard/list/conroldashboardstyles");
var total_active_clients_1 = require("../super_admin/control_dashboard/total_active_clients");
var reports_helper_1 = require("../super_admin/reports/reports_helper");
var state_city_filter_1 = require("../super_admin/sample_traking/state_city_filter");
var ShipmentTower = function () {
    var classes = (0, conroldashboardstyles_1.useStyles)();
    var _a = React.useState({}), dashboard = _a[0], setDashboardData = _a[1];
    console.log(dashboard, 'dashboard');
    var _b = React.useState(1), activeClientsFilter = _b[0], setActiveClientsFilter = _b[1];
    var _c = React.useState(), payloadexport = _c[0], setPayload = _c[1];
    React.useState(1);
    var _d = React.useState(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients), statName = _d[0], setStatName = _d[1];
    var _e = React.useState(), classN = _e[0], setClassN = _e[1];
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _f = React.useState(appConstants_1.defaultPageLimit), PageLimit = _f[0], setPageLimit = _f[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _g = React.useState(null), selectedLab = _g[0], setSelectedLab = _g[1];
    var _h = React.useState(false), open = _h[0], setOpen = _h[1];
    var _j = React.useState([]), filterStateCity = _j[0], setFilterStateCity = _j[1];
    var _k = React.useState([]), hublist = _k[0], sethublist = _k[1];
    var _l = React.useState([]), selectedhub = _l[0], setselectedhub = _l[1];
    var _m = React.useState(new Date().toString()), startDate = _m[0], setStartDate = _m[1];
    var _o = React.useState(new Date().toString()), endDate = _o[0], setEndDate = _o[1];
    var callbackOnselectedhub = function (val) {
        (0, generic_api_calls_1.fetchHubList)(val)
            .then(function (res) {
            var hubListRes = (0, array_helpers_1.convertHubList)(res.data.data);
            sethublist(hubListRes);
        })
            .catch(function (e) { return console.log(e); });
    };
    var labHandler = function (value) {
        setSelectedLab(value);
        var payload = {};
        payload['lab_codes'] = value.map(function (data) { return data.value.toString(); });
        callbackOnselectedhub(payload);
    };
    var hubHandler = function (value) {
        setselectedhub(value);
    };
    var stateCityHandler = function (event) {
        if (event.length >= 0) {
            setFilterStateCity(event);
        }
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        if (dateRangeArray === null) {
            setStartDate(null);
            setEndDate(null);
        }
    };
    var refetch = (0, react_query_1.useQuery)(['dashbaord-counts'], function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload.date_range = dateRangeArr;
        }
        if ((filterStateCity === null || filterStateCity === void 0 ? void 0 : filterStateCity.length) > 0) {
            console.log(filterStateCity, 'filterSelectedCities');
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(filterStateCity);
            payload['states'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['cities'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        if (selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.length) {
            payload.lab_codes = selectedLab.map(function (data) {
                return data.value.toString();
            });
        }
        if (selectedhub === null || selectedhub === void 0 ? void 0 : selectedhub.length) {
            payload.hub_codes = selectedhub.map(function (data) {
                return data.value.toString();
            });
        }
        setPayload(payload);
        (0, auto_routing_api_calls_1.autoRoutingDashboard)(payload)
            .then(function (res) {
            setDashboardData(res.data);
            UpdateListData.setIsListReloaded(false);
            setOpen(false);
        })
            .catch(function (e) {
            console.log(e.message);
        });
    }).refetch;
    var handleDrawerClose = function () {
        setOpen(false);
    };
    React.useEffect(function () {
        refetch();
    }, [
        selectedLab,
        UpdateListData === null || UpdateListData === void 0 ? void 0 : UpdateListData.isListReloaded,
        selectedhub,
        filterStateCity === null || filterStateCity === void 0 ? void 0 : filterStateCity.length,
        endDate,
    ]);
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    return (React.createElement("div", { className: "orderlist-main-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Intercity Tower"))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" }),
            React.createElement("div", { className: "filter-options" },
                React.createElement(multi_selector_1.default, { label: "Lab Code", dropDownList: labList.sort(function (a, b) {
                        return a.label.localeCompare(b.label);
                    }), onChangeHnadlre: labHandler, value: selectedLab }),
                React.createElement(multi_selector_1.default, { label: "Hub Code", dropDownList: hublist && hublist, onChangeHnadlre: hubHandler, value: selectedhub }),
                React.createElement(state_city_filter_1.default, { onChangeHandler: stateCityHandler }),
                React.createElement(text_filed_with_fromto_Daterange_1.default, { presentDate: true, onChangeHandler: callBackOnDateRangeSelect }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }))),
        React.createElement("div", { className: " long-page-scroll" },
            React.createElement("div", { className: "routingc-card-nav" },
                React.createElement("div", { className: "routing-dashboard-wrapper" },
                    React.createElement(material_1.Grid, { container: true, columns: { xs: 12, lg: 12, xl: 12 }, columnSpacing: 3 },
                        React.createElement(material_1.Grid, { item: true, lg: 12, xs: 12, xl: 12, className: "cards-grids-item" },
                            React.createElement("h2", { className: "heading cards-heaidng" },
                                ' ',
                                "Intercity"),
                            React.createElement("div", { className: "orderlist-main" },
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled  ", onClick: function () {
                                        setActiveClientsFilter(1);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content " },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients
                                                    .total_active_clients
                                                ? dashboard
                                                    .active_clients
                                                    .total_active_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.totalActiveClients))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue ".concat(classN === 1 ? 'active-card' : ''), onClick: function () {
                                        setOpen(true);
                                        setClassN(1);
                                        setActiveClientsFilter(2);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients
                                                    .untagged_clients
                                                ? dashboard
                                                    .active_clients
                                                    .untagged_clients
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.UntaggedClients))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled  ", onClick: function () {
                                        setActiveClientsFilter(3);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients
                                                    .active_cc_or_psc
                                                ? dashboard
                                                    .active_clients
                                                    .active_cc_or_psc
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.activeCCPSc))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(4);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients
                                                    .active_pup
                                                ? dashboard
                                                    .active_clients
                                                    .active_pup
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.active_PUP))),
                                React.createElement(material_1.Card, { className: "route-card card-main bg-lightblue disabled", onClick: function () {
                                        setActiveClientsFilter(5);
                                        setStatName(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
                                    } },
                                    React.createElement(material_1.CardContent, { className: "card-content" },
                                        React.createElement("div", { className: "top-block" },
                                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, dashboard &&
                                                dashboard.active_clients &&
                                                dashboard.active_clients
                                                    .total_scheduled_pickups
                                                ? dashboard
                                                    .active_clients
                                                    .total_scheduled_pickups
                                                : 0)),
                                        React.createElement(material_1.Typography, { className: "content" }, auto_routing_helper_1.CONTROL_DASHBOARD_LABELS.totalScheduledPickups)))))))),
            React.createElement(material_1.Drawer, { variant: "temporary", anchor: "bottom", open: open, className: classes.customDrawer },
                React.createElement("div", { onClick: handleDrawerClose, className: "drawer-close-btn" },
                    React.createElement("span", { className: "icon-close" })),
                statName ===
                    auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients ? (React.createElement(total_active_clients_1.default, { activeClientsFilter: activeClientsFilter, selectedLab: selectedLab, PageLimit: PageLimit, payloadexport: payloadexport })) : ('')))));
};
exports.default = ShipmentTower;
