"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var route_path_1 = require("../../constants/route_path");
var TableLoader = function () {
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var tableCount = [1, 2, 3, 4, 5];
    React.useEffect(function () {
        if (pathname.split('/')[4] != route_path_1.default.PATHS.feKpis) {
            tableCount.splice(tableCount.length - 2, 2);
        }
    }, []);
    return (React.createElement("div", null,
        React.createElement("div", null, tableCount.map(function (val) {
            return (React.createElement(material_1.Box, { sx: { display: 'flex', alignItems: 'center' } },
                React.createElement(material_1.Box, { sx: { margin: 1 } },
                    React.createElement(material_1.Skeleton, { variant: "circular" },
                        React.createElement(material_1.Avatar, null))),
                React.createElement(material_1.Box, { sx: { width: '100%' } },
                    React.createElement(material_1.Skeleton, { width: "98%" },
                        React.createElement(material_1.Typography, { width: 565 }, ".")))));
        }))));
};
exports.default = TableLoader;
