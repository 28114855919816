"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Accordion_1 = require("@mui/material/Accordion");
var AccordionDetails_1 = require("@mui/material/AccordionDetails");
var AccordionSummary_1 = require("@mui/material/AccordionSummary");
var React = require("react");
var RoundList = function (_a) {
    var roundColors = _a.roundColors, isMultiFe = _a.isMultiFe;
    console.log(roundColors, 'roundColors');
    return (React.createElement("div", { className: "map-legend-accordian right-align" },
        React.createElement(Accordion_1.default, { className: "custon-legend-accordian" },
            React.createElement(AccordionSummary_1.default, { expandIcon: React.createElement("span", { className: "icon-dropdown-arrow" }) },
                React.createElement("p", null, " Rounds Legend")),
            React.createElement(AccordionDetails_1.default, null,
                React.createElement("div", { className: "map-legend " }, isMultiFe
                    ? roundColors &&
                        roundColors.map(function (val, index) {
                            return (React.createElement("div", { className: "round-item" },
                                React.createElement("span", { className: "icon-onleave", style: { color: "".concat(val.color) } }), "".concat(val.fe_code)));
                        })
                    : roundColors &&
                        roundColors.map(function (color, index) {
                            return (React.createElement("div", { className: "round-item" },
                                React.createElement("span", { className: "icon-onleave", style: { color: "".concat(color) } }), "Round ".concat(index + 1)));
                        }))))));
};
exports.default = RoundList;
