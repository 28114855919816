"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../common/buttons/button_component");
var modal_component_1 = require("../../../common/modal/modal_component");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
require("../control_dashboard/controldashboard.scss");
var auto_routing_api_calls_1 = require("./auto_routing_api_calls");
var AutoRoutingDualActionButtons = function (_a) {
    var visitId = _a.visitId, center_name = _a.center_name, time_window = _a.time_window, warehouse_code = _a.warehouse_code, data = _a.data;
    console.log(visitId, 'visitId');
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var nav = (0, react_router_1.useNavigate)();
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () {
        setOpen(false);
    };
    var clickHandler = function () {
        nav("".concat(route_path_1.default.PATHS.onDemandRequest, "/").concat(route_path_1.default.PATHS.assignRequest), { state: data });
    };
    var formSubmitHandler = function (values) {
        var payload = {
            visit_id: visitId,
            reason: values.reject_comment,
        };
        (0, auto_routing_api_calls_1.onDemandReject)(payload)
            .then(function (res) {
            console.log(res, 'ertyghdfgh');
            if (res === 200) {
                console.log('hgfdsdefgvb ccf');
                react_hot_toast_1.default.success(appConstants_1.RejectOndemand.Add);
                UpdateListData.setIsListReloaded(true);
                setOpen(false);
            }
        })
            .catch(function (err) {
            console.log(err, 'lkjhgvc');
            react_hot_toast_1.default.error(err);
        });
    };
    return (React.createElement("div", { className: 'table-col-btns' },
        React.createElement(button_component_1.default, { color: 'yellow-outline', buttonName: 'ASSIGN', onClickHandler: clickHandler }),
        React.createElement(modal_component_1.default, { buttonColor: 'yellow-outline', buttonName: 'REJECT', onClickHandler: handleOpen, open: open },
            React.createElement("div", null,
                React.createElement("div", { className: 'modal-header header-wrapper' },
                    React.createElement("h2", { className: 'heading' }, "Confirm")),
                React.createElement("div", { className: 'modal-body' },
                    React.createElement("div", { className: 'modal-message' }, "Are you sure you want to reject assigning the request?"))),
            React.createElement(formik_1.Formik, { initialValues: {
                    reject_comment: '',
                }, validateOnBlur: false, validationSchema: schema_1.schemas.REJECT_ASSIGNING_SCHEMA, onSubmit: formSubmitHandler }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldTouched = _a.setFieldTouched;
                return (React.createElement(formik_1.Form, { className: 'input-fileds-wrapper' },
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                        React.createElement(Grid_1.default, { item: true, xs: 12 },
                            React.createElement(text_filed_component_1.default, { name: 'reject_comment', maxLength: 200, onChange: function (reject_comment) {
                                    setFieldTouched('reject_comment', true);
                                    setFieldValue('reject_comment', reject_comment);
                                }, label: 'Reason for rejecting', value: values.reject_comment }),
                            errors['reject_comment'] && touched['reject_comment'] ? (React.createElement("div", { className: 'error' }, errors['reject_comment'])) : null)),
                    React.createElement("div", { className: 'modal-footer' },
                        React.createElement(button_component_1.default, { buttonName: 'Cancel', color: 'yellow-outline', onClickHandler: handleClose }),
                        React.createElement(button_component_1.default, { type: 'submit', buttonName: 'Submit', color: 'yellow' }))));
            }))));
};
exports.default = AutoRoutingDualActionButtons;
