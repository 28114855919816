"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var button_component_1 = require("../../../common/buttons/button_component");
var route_path_1 = require("../../../constants/route_path");
var react_router_1 = require("react-router");
var AssignActiveClientButton = function (_a) {
    var visit_id = _a.visit_id, visit_status = _a.visit_status, name = _a.name;
    var nav = (0, react_router_1.useNavigate)();
    console.log(name, 'oiuyt');
    var submitHandler = function () {
        nav("".concat(route_path_1.default.PATHS.totalActiveClients, "/").concat(route_path_1.default.PATHS.assignRoute, "/").concat(name, "/").concat(visit_id));
    };
    return (React.createElement("div", null,
        React.createElement(button_component_1.default, { buttonName: "Assign Route", color: "yellow-outline", onClickHandler: submitHandler })));
};
exports.default = AssignActiveClientButton;
