"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ExpandMore_1 = require("@mui/icons-material/ExpandMore");
var material_1 = require("@mui/material");
var Avatar_1 = require("@mui/material/Avatar");
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_router_1 = require("react-router");
var api_service_1 = require("../../../../api/api_service");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var status_buttons_1 = require("../../../../common/buttons/status_buttons");
var alert_modal_1 = require("../../../../common/modal/alert_modal");
var profile_grid_1 = require("../../../../common/profile_grid/profile_grid");
var FECreationTabConstant_1 = require("../../../../constants/FECreationTabConstant");
var images_1 = require("../../../../constants/images");
var profile_detaile_lable_1 = require("../../../../constants/profile_detaile_lable");
var route_path_1 = require("../../../../constants/route_path");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var generic_api_calls_1 = require("../../../generic_api_calls");
require("../../vendor_management/vendor-managment.scss");
var approval_api_service_1 = require("../approval_api_service");
require("./approval.scss");
var reject_user_1 = require("./reject_user");
var FEApprovalProfileDetails = function () {
    var _a, _b, _c, _d;
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var pathName = window.location.pathname;
    var _e = React.useState(), profileData = _e[0], setProfileData = _e[1];
    var _f = React.useState(), updatedProfileData = _f[0], setUpdatedProfileData = _f[1];
    var _g = React.useState(''), profileImageURL = _g[0], setProfileImageURL = _g[1];
    var _h = React.useState(''), updatedProfileImageURL = _h[0], setUpdatedProfileImageURL = _h[1];
    var _j = React.useState(''), profileImageDocName = _j[0], setProfileImageDocName = _j[1];
    var _k = React.useState(''), updatedProfileImageDocName = _k[0], setUpdatedProfileImageDocName = _k[1];
    var _l = React.useState(''), addressProfDoc = _l[0], setAddressProfDoc = _l[1];
    var _m = React.useState(''), addressProfDocURL = _m[0], setAddressProfDocURL = _m[1];
    var _o = React.useState(''), updatedAddressProfDoc = _o[0], setUpdatedAddressProfDoc = _o[1];
    var _p = React.useState(''), updatedAddressProfDocURL = _p[0], setUpdatedAddressProfDocURL = _p[1];
    var _q = React.useState(''), updatedCurrentAddressUploadedDocName = _q[0], setUpdatedCurrentAddressUploadedDocName = _q[1];
    var _r = React.useState(''), updatedPermanentAddressProfDoc = _r[0], setUpdatedPermanentAddressProfDoc = _r[1];
    var _s = React.useState(''), updatedPermanentAddressProfDocURL = _s[0], setUpdatedPermanentAddressProfDocURL = _s[1];
    var _t = React.useState(''), updatedPermanentCurrentAddressUploadedDocName = _t[0], setUpdatedPermanentCurrentAddressUploadedDocName = _t[1];
    var _u = React.useState(''), currentAddressUploadedDocName = _u[0], setCurrentAddressUploadedDocName = _u[1];
    var _v = React.useState(''), addressProfDoc1 = _v[0], setAddressProfDoc1 = _v[1];
    var _w = React.useState(''), addressProfDoc1URL = _w[0], setAddressProfDoc1URL = _w[1];
    var _x = React.useState(''), parmanentAddressUploadedDocName = _x[0], setParmanentAddressUploadedDocName = _x[1];
    var _y = React.useState(''), insurencepic = _y[0], setInsurence = _y[1];
    var _z = React.useState(''), insuranceUploadedURL = _z[0], setInsuranceUploadedURL = _z[1];
    var _0 = React.useState(''), insuranceDocName = _0[0], setInsuranceDocName = _0[1];
    var _1 = React.useState(''), drivingLicenceImageURL = _1[0], setDrivingLicenceImageURL = _1[1];
    var _2 = React.useState(''), drivingLicenceUploadedURL = _2[0], setDrivingLicenceUploadedURL = _2[1];
    var _3 = React.useState(''), drivingLicenceDocName = _3[0], setDrivingLicenceDocName = _3[1];
    var _4 = React.useState(''), updatedinsuranceImageURL = _4[0], setUpdatedinsuranceImageURL = _4[1];
    var _5 = React.useState(''), updatedInsuranceImageeUploadedURL = _5[0], setUpdatedInsuranceImageeUploadedURL = _5[1];
    var _6 = React.useState(''), updatedInsuranceDocName = _6[0], setUpdatedInsuranceDocName = _6[1];
    var _7 = React.useState(''), updatedDrivingLicenceImageURL = _7[0], setUpdatedDrivingLicenceImageURL = _7[1];
    var _8 = React.useState(''), updatedDrivingLicenceUploadedURL = _8[0], setUpdatedDrivingLicenceUploadedURL = _8[1];
    var _9 = React.useState(''), updatedDrivingLicenceDocName = _9[0], setUpdatedDrivingLicenceDocName = _9[1];
    var _10 = React.useState(''), bankChequeDocImageURL = _10[0], setBankChequeDocImageURL = _10[1];
    var _11 = React.useState(''), bankChequeDocUploadedURL = _11[0], setBankChequeDocUploadedURL = _11[1];
    var _12 = React.useState(''), bankChequeDocUploadedDocName = _12[0], setBankChequeDocUploadedDocName = _12[1];
    var _13 = React.useState(''), updatedBankChequeDocImageURL = _13[0], setUpdatedBankChequeDocImageURL = _13[1];
    var _14 = React.useState(''), updatedBankChequeDocUploadedURL = _14[0], setUpdatedBankChequeDocUploadedURL = _14[1];
    var _15 = React.useState(''), updatedBankChequeDocUploadedDocName = _15[0], setUpdatedBankChequeDocUploadedDocName = _15[1];
    var _16 = React.useState(""), errorMessage = _16[0], setErrorMessage = _16[1];
    var emptyDetails = '--';
    var exitUserPayload = {
        user_id: parseInt(id),
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.approvals);
    var levelOneDetails = pathName.includes('level1-details');
    var onclickhandler = function () {
        if (profileData.approval_status === 1) {
            nav("/".concat(route_path_1.default.PATHS.approvals, "/").concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.levelOnePendingApproval));
        }
        else {
            nav("/".concat(route_path_1.default.PATHS.approvals, "/").concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.levelTwoPendingApproval));
        }
    };
    var fetchPrfileDetials = function () {
        (0, approval_api_service_1.approvalsProfileDetails)(api_service_1.api_service.api_urls.FE_PROFILE_DETAILS, {
            user_id: id,
        }, process.env.VENDOR_BASE_URL)
            .then(function (response) {
            console.log(response, '????????');
            setErrorMessage("");
            if (response.perviousDetials) {
                setProfileData(response.perviousDetials);
                if (response.perviousDetials.personal_details.uploadPhoto) {
                    var imagepath = response.perviousDetials.personal_details.uploadPhoto.split('/');
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response.perviousDetials.personal_details
                        .uploadPhoto).then(function (res) {
                        setProfileImageURL(res.data.data.url);
                    });
                    setProfileImageDocName(imagepath[imagepath.length - 1]);
                }
                if (response.perviousDetials.contact_details.proofOfAddress) {
                    var nameArr_1 = response.perviousDetials
                        .contact_details.proofOfAddress
                        ? response.perviousDetials.contact_details.proofOfAddress.split('/')
                        : [];
                    if (nameArr_1.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.perviousDetials.contact_details
                            .proofOfAddress).then(function (res) {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            if (nameArr_1[nameArr_1.length - 1].includes('jpg') ||
                                nameArr_1[nameArr_1.length - 1].includes('png') ||
                                nameArr_1[nameArr_1.length - 1].includes('jfif')) {
                                setAddressProfDoc((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                                setAddressProfDocURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                            }
                            else if (nameArr_1[nameArr_1.length - 1].includes('pdf')) {
                                setAddressProfDoc(images_1.default.pdfImage);
                                setAddressProfDocURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                            }
                            else {
                                setAddressProfDoc(images_1.default.csvImage);
                                setAddressProfDocURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                            }
                        });
                        setCurrentAddressUploadedDocName(nameArr_1[nameArr_1.length - 1]);
                    }
                }
                if (response.perviousDetials.contact_details
                    .permenentProofOfAddress) {
                    var nameArr1_1 = response.perviousDetials
                        .contact_details.permenentProofOfAddress
                        ? response.perviousDetials.contact_details.permenentProofOfAddress.split('/')
                        : [];
                    if (nameArr1_1.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.perviousDetials.contact_details
                            .permenentProofOfAddress).then(function (res) {
                            if (nameArr1_1[nameArr1_1.length - 1].includes('jpg') ||
                                nameArr1_1[nameArr1_1.length - 1].includes('png') ||
                                nameArr1_1[nameArr1_1.length - 1].includes('jfif')) {
                                setAddressProfDoc1(res.data.data.url);
                                setAddressProfDoc1URL(res.data.data.url);
                            }
                            else if (nameArr1_1[nameArr1_1.length - 1].includes('pdf')) {
                                setAddressProfDoc1(images_1.default.pdfImage);
                                setAddressProfDoc1URL(res.data.data.url);
                            }
                            else {
                                setAddressProfDoc1(images_1.default.csvImage);
                                setAddressProfDoc1URL(res.data.data.url);
                            }
                        });
                        setParmanentAddressUploadedDocName(nameArr1_1[nameArr1_1.length - 1]);
                    }
                }
                if (response.perviousDetials.drivingLiecenceDetails
                    .uploadInsuranceDoc) {
                    var insurance_photo_1 = response.perviousDetials.drivingLiecenceDetails.uploadInsuranceDoc.split('/');
                    if (insurance_photo_1.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.perviousDetials.drivingLiecenceDetails
                            .uploadInsuranceDoc).then(function (res) {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            if (insurance_photo_1[insurance_photo_1.length - 1].includes('jpg') ||
                                insurance_photo_1[insurance_photo_1.length - 1].includes('png') ||
                                insurance_photo_1[insurance_photo_1.length - 1].includes('jfif')) {
                                setInsurence((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                                setInsuranceUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                            }
                            else if (insurance_photo_1[insurance_photo_1.length - 1].includes('pdf')) {
                                setInsurence(images_1.default.pdfImage);
                                setInsuranceUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                            }
                            else {
                                setInsurence(images_1.default.csvImage);
                                setInsuranceUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                            }
                        });
                        setInsuranceDocName(insurance_photo_1[insurance_photo_1.length - 1]);
                    }
                }
                if (response.perviousDetials.drivingLiecenceDetails
                    .proofOfDrivingLicence) {
                    var driving_licence_doc_1 = response.perviousDetials.drivingLiecenceDetails.proofOfDrivingLicence.split('/');
                    if (driving_licence_doc_1.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.perviousDetials.drivingLiecenceDetails
                            .proofOfDrivingLicence).then(function (res) {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            if (driving_licence_doc_1[driving_licence_doc_1.length - 1].includes('jpg') ||
                                driving_licence_doc_1[driving_licence_doc_1.length - 1].includes('png') ||
                                driving_licence_doc_1[driving_licence_doc_1.length - 1].includes('jfif')) {
                                setDrivingLicenceImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                                setDrivingLicenceUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                            }
                            else if (driving_licence_doc_1[driving_licence_doc_1.length - 1].includes('pdf')) {
                                setDrivingLicenceImageURL(images_1.default.pdfImage);
                                setDrivingLicenceUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                            }
                            else {
                                setDrivingLicenceImageURL(images_1.default.csvImage);
                                setDrivingLicenceUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                            }
                        });
                        setDrivingLicenceDocName(driving_licence_doc_1[driving_licence_doc_1.length - 1]);
                    }
                }
                if (response.perviousDetials.bank_details
                    .proofOfBankAccountDetails) {
                    var bankChequeDoc_1 = response.perviousDetials.bank_details &&
                        response.perviousDetials.bank_details
                            .proofOfBankAccountDetails
                        ? response.perviousDetials.bank_details.proofOfBankAccountDetails.split('/')
                        : [];
                    if (bankChequeDoc_1.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.perviousDetials.bank_details
                            .proofOfBankAccountDetails).then(function (res) {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            if (bankChequeDoc_1[bankChequeDoc_1.length - 1].includes('jpg') ||
                                bankChequeDoc_1[bankChequeDoc_1.length - 1].includes('png') ||
                                bankChequeDoc_1[bankChequeDoc_1.length - 1].includes('jfif')) {
                                setBankChequeDocImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                                setBankChequeDocUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                            }
                            else if (bankChequeDoc_1[bankChequeDoc_1.length - 1].includes('pdf')) {
                                setBankChequeDocImageURL(images_1.default.pdfImage);
                                setBankChequeDocUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                            }
                            else {
                                setBankChequeDocImageURL(images_1.default.csvImage);
                                setBankChequeDocUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                            }
                        });
                    }
                    setBankChequeDocUploadedDocName(bankChequeDoc_1[bankChequeDoc_1.length - 1]);
                }
            }
            if (response.updatedDetials) {
                setUpdatedProfileData(response.updatedDetials);
                if (response.updatedDetials.personal_details.uploadPhoto) {
                    var imagepath = response.updatedDetials.personal_details.uploadPhoto.split('/');
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response.updatedDetials.personal_details.uploadPhoto).then(function (res) {
                        setUpdatedProfileImageURL(res.data.data.url);
                    });
                    setUpdatedProfileImageDocName(imagepath[imagepath.length - 1]);
                }
                if (response.updatedDetials.contact_details.proofOfAddress) {
                    var nameArr_2 = response.updatedDetials
                        .contact_details.proofOfAddress
                        ? response.updatedDetials.contact_details.proofOfAddress.split('/')
                        : [];
                    if (nameArr_2.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.updatedDetials.contact_details
                            .proofOfAddress).then(function (res) {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            if (nameArr_2[nameArr_2.length - 1].includes('jpg') ||
                                nameArr_2[nameArr_2.length - 1].includes('png') ||
                                nameArr_2[nameArr_2.length - 1].includes('jfif')) {
                                setUpdatedAddressProfDoc((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                                setUpdatedAddressProfDocURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                            }
                            else if (nameArr_2[nameArr_2.length - 1].includes('pdf')) {
                                setUpdatedAddressProfDoc(images_1.default.pdfImage);
                                setUpdatedAddressProfDocURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                            }
                            else {
                                setUpdatedAddressProfDoc(images_1.default.csvImage);
                                setUpdatedAddressProfDocURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                            }
                        });
                        setUpdatedCurrentAddressUploadedDocName(nameArr_2[nameArr_2.length - 1]);
                    }
                }
                if (response.updatedDetials.contact_details
                    .permenentProofOfAddress) {
                    var nameArr1_2 = response.updatedDetials
                        .contact_details.permenentProofOfAddress
                        ? response.updatedDetials.contact_details.permenentProofOfAddress.split('/')
                        : [];
                    if (nameArr1_2.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.updatedDetials.contact_details
                            .permenentProofOfAddress).then(function (res) {
                            if (nameArr1_2[nameArr1_2.length - 1].includes('jpg') ||
                                nameArr1_2[nameArr1_2.length - 1].includes('png') ||
                                nameArr1_2[nameArr1_2.length - 1].includes('jfif')) {
                                setUpdatedPermanentAddressProfDoc(res.data.data.url);
                                setUpdatedPermanentAddressProfDocURL(res.data.data.url);
                            }
                            else if (nameArr1_2[nameArr1_2.length - 1].includes('pdf')) {
                                setUpdatedPermanentAddressProfDoc(images_1.default.pdfImage);
                                setUpdatedPermanentAddressProfDocURL(res.data.data.url);
                            }
                            else {
                                setUpdatedPermanentAddressProfDoc(images_1.default.csvImage);
                                setUpdatedPermanentAddressProfDocURL(res.data.data.url);
                            }
                        });
                        setUpdatedPermanentCurrentAddressUploadedDocName(nameArr1_2[nameArr1_2.length - 1]);
                    }
                }
                if (response.updatedDetials.drivingLiecenceDetails
                    .uploadInsuranceDoc) {
                    var insurance_photo_2 = response.updatedDetials.drivingLiecenceDetails.uploadInsuranceDoc.split('/');
                    if (insurance_photo_2.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.updatedDetials.drivingLiecenceDetails
                            .uploadInsuranceDoc).then(function (res) {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            if (insurance_photo_2[insurance_photo_2.length - 1].includes('jpg') ||
                                insurance_photo_2[insurance_photo_2.length - 1].includes('png') ||
                                insurance_photo_2[insurance_photo_2.length - 1].includes('jfif')) {
                                setUpdatedinsuranceImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                                setUpdatedInsuranceImageeUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                            }
                            else if (insurance_photo_2[insurance_photo_2.length - 1].includes('pdf')) {
                                setUpdatedinsuranceImageURL(images_1.default.pdfImage);
                                setUpdatedInsuranceImageeUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                            }
                            else {
                                setUpdatedinsuranceImageURL(images_1.default.csvImage);
                                setUpdatedInsuranceImageeUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                            }
                        });
                        setUpdatedInsuranceDocName(insurance_photo_2[insurance_photo_2.length - 1]);
                    }
                }
                if (response.updatedDetials.drivingLiecenceDetails
                    .proofOfDrivingLicence) {
                    var driving_licence_doc_2 = response.updatedDetials.drivingLiecenceDetails.proofOfDrivingLicence.split('/');
                    if (driving_licence_doc_2.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.updatedDetials.drivingLiecenceDetails
                            .proofOfDrivingLicence).then(function (res) {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            if (driving_licence_doc_2[driving_licence_doc_2.length - 1].includes('jpg') ||
                                driving_licence_doc_2[driving_licence_doc_2.length - 1].includes('png') ||
                                driving_licence_doc_2[driving_licence_doc_2.length - 1].includes('jfif')) {
                                setUpdatedDrivingLicenceImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                                setUpdatedDrivingLicenceUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                            }
                            else if (driving_licence_doc_2[driving_licence_doc_2.length - 1].includes('pdf')) {
                                setUpdatedDrivingLicenceImageURL(images_1.default.pdfImage);
                                setUpdatedDrivingLicenceUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                            }
                            else {
                                setUpdatedDrivingLicenceImageURL(images_1.default.csvImage);
                                setUpdatedDrivingLicenceUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                            }
                        });
                        setUpdatedDrivingLicenceDocName(driving_licence_doc_2[driving_licence_doc_2.length - 1]);
                    }
                }
                if (response.updatedDetials.bank_details
                    .proofOfBankAccountDetails) {
                    var bankChequeDoc_2 = response.updatedDetials.bank_details &&
                        response.updatedDetials.bank_details
                            .proofOfBankAccountDetails
                        ? response.updatedDetials.bank_details.proofOfBankAccountDetails.split('/')
                        : [];
                    if (bankChequeDoc_2.length > 0) {
                        (0, generic_api_calls_1.fetchImnageURLFromName)(response.updatedDetials.bank_details
                            .proofOfBankAccountDetails).then(function (res) {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            if (bankChequeDoc_2[bankChequeDoc_2.length - 1].includes('jpg') ||
                                bankChequeDoc_2[bankChequeDoc_2.length - 1].includes('png') ||
                                bankChequeDoc_2[bankChequeDoc_2.length - 1].includes('jfif')) {
                                setUpdatedBankChequeDocImageURL((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                                setUpdatedBankChequeDocUploadedURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                            }
                            else if (bankChequeDoc_2[bankChequeDoc_2.length - 1].includes('pdf')) {
                                setUpdatedBankChequeDocImageURL(images_1.default.pdfImage);
                                setUpdatedBankChequeDocUploadedURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                            }
                            else {
                                setUpdatedBankChequeDocImageURL(images_1.default.csvImage);
                                setUpdatedBankChequeDocUploadedURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                            }
                        });
                    }
                    setUpdatedBankChequeDocUploadedDocName(bankChequeDoc_2[bankChequeDoc_2.length - 1]);
                }
            }
        })
            .catch(function (err) {
            setErrorMessage(err.message);
        });
    };
    var feName = (0, array_helpers_1.concatFirstNameLastName)(profileData && profileData.personal_details.firstName, profileData && profileData.personal_details.lastName);
    React.useEffect(function () {
        fetchPrfileDetials();
    }, []);
    var referenceDetailskeys = [
        'referenceMobileNumber1',
        'referenceContactName1',
        'referenceContactName2',
        'referenceMobileNumber2',
    ];
    var checkUpdatedProfileDetails = function () {
        if (updatedProfileData) {
            for (var key in updatedProfileData.personal_details) {
                if (updatedProfileData.personal_details[key] &&
                    !referenceDetailskeys.includes(key)) {
                    return true;
                }
            }
        }
        return false;
    };
    var checkUpdatedReferenceDetails = function () {
        if (updatedProfileData) {
            for (var key in updatedProfileData.personal_details) {
                if (updatedProfileData.personal_details[key] &&
                    referenceDetailskeys.includes(key)) {
                    return true;
                }
            }
        }
        return false;
    };
    var presentAddresskeys = [
        'addresslane1',
        'addresslane2',
        'landmark',
        'state',
        'city',
        'country',
        'pincode',
        'addressProofType',
        'proofOfAddress',
    ];
    var checkUpdatedPresentAddressSetails = function () {
        if (updatedProfileData && profileData) {
            for (var key in updatedProfileData.contact_details) {
                if (updatedProfileData.contact_details[key] &&
                    presentAddresskeys.includes(key)) {
                    return true;
                }
            }
        }
        return false;
    };
    var checkUpdatedPermanentAddressSetails = function () {
        if (updatedProfileData && profileData) {
            for (var key in updatedProfileData.contact_details) {
                if (updatedProfileData.contact_details[key] &&
                    !presentAddresskeys.includes(key)) {
                    return true;
                }
            }
        }
        return false;
    };
    var checkUpdatedBankDetaiDetails = function () {
        if (updatedProfileData && profileData) {
            for (var key in updatedProfileData.bank_details) {
                if (updatedProfileData.bank_details[key]) {
                    return true;
                }
            }
        }
        return false;
    };
    var checkDrivingLiecenceDetails = function () {
        if (updatedProfileData && profileData) {
            for (var key in updatedProfileData.drivingLiecenceDetails) {
                if (updatedProfileData.drivingLiecenceDetails[key]) {
                    return true;
                }
            }
        }
        return false;
    };
    var checkEmploymentDetails = function () {
        if (updatedProfileData && profileData) {
            for (var key in updatedProfileData.employment_details) {
                if (key === 'supervisor' ||
                    key === 'backup' ||
                    key === 'canUpdateCCCoordinates') {
                    if (updatedProfileData.employment_details[key] !=
                        profileData.employment_details[key]) {
                        return true;
                    }
                }
                else if (updatedProfileData.employment_details[key]) {
                    return true;
                }
            }
        }
        return false;
    };
    return (React.createElement("div", { className: "details-screen-wrapper" }, errorMessage ? errorMessage :
        React.createElement("div", { className: "vendor-profile-details fe-wrapper page-wrapper" },
            React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom" },
                React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                    React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: onclickhandler }),
                    React.createElement("h2", null, "Approval Details")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(reject_user_1.default, { buttonName: "REJECT", user_id: parseInt(id), fe_name: feName, fe_id: profileData && profileData.fe_id
                            ? profileData.fe_id
                            : emptyDetails, endPoint: api_service_1.api_service.api_urls.LEVEL_ONE_REJECT, domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL, isBtnDisabled: levelOneDetails
                            ? !features[menu_constants_1.FEATURE_CONSTANTS.FELevelOneReject]
                            : !features[menu_constants_1.FEATURE_CONSTANTS.FELevelTwoReject], approvalStatus: profileData && profileData.approval_status, onBoardStatus: profileData && profileData.onboard_status }),
                    React.createElement(alert_modal_1.default, { isBtnDisabled: levelOneDetails
                            ? features[menu_constants_1.FEATURE_CONSTANTS.FELevelOneApprove]
                            : features[menu_constants_1.FEATURE_CONSTANTS.FELevelTwoApprove], alertIcon: 'icon-info', modalTitle: "Are you sure  you want to approve ".concat(feName, "?"), buttonName: "APPROVE", buttonColor: "yellow", endPoint: profileData &&
                            profileData.approval_status === 1 &&
                            profileData.onboard_status === 5
                            ? api_service_1.api_service.api_urls.APPROVAE_LEVEL_ONE
                            : api_service_1.api_service.api_urls.APPROVAL_LEVEL_TWO, payLoad: exitUserPayload, domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL, approvalStatus: profileData && profileData.approval_status, onBoardStatus: profileData && profileData.onboard_status }))),
            React.createElement(Grid_1.default, { container: true, xs: 12, className: "details-tab-wrapper" },
                React.createElement(Grid_1.default, { item: true, xs: 12 },
                    React.createElement("div", { className: "details-wrapper vendor-details-wrapper" },
                        React.createElement("div", { className: "user-card displayflex  " },
                            React.createElement("div", { className: "user-details-wrapper displayflex " },
                                React.createElement("div", { className: "avatar-wrapper" }, profileData &&
                                    profileData.personal_details
                                        .uploadPhoto ? (React.createElement(Avatar_1.default, { src: profileImageURL })) : (React.createElement(Avatar_1.default, null, feName &&
                                    feName
                                        .charAt(0)
                                        .toUpperCase()))),
                                React.createElement("div", { className: "user-name-wrapper" },
                                    React.createElement("p", { className: "user-value" }, feName ? feName : emptyDetails))),
                            React.createElement(status_buttons_1.default, { status: profileData &&
                                    profileData.onboard_status })),
                        React.createElement("div", { className: "vendor-deatils-tab approve-pro-details" },
                            React.createElement("div", { className: "visits-accordian-wrapper" },
                                React.createElement(material_1.Accordion, { className: checkUpdatedProfileDetails()
                                        ? 'details-edited'
                                        : '' },
                                    React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null) },
                                        React.createElement("p", { className: "accoridan-heading" }, "Personal Details")),
                                    React.createElement(material_1.AccordionDetails, null,
                                        React.createElement("div", { className: "visit-details" },
                                            React.createElement(Grid_1.default, { container: true, spacing: 2, columns: 12, className: "details-profile-grid" },
                                                React.createElement(Grid_1.default, { item: true, xs: checkUpdatedProfileDetails()
                                                        ? 6
                                                        : 12, container: true },
                                                    checkUpdatedProfileDetails() && (React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Previous Data")))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .firstname, value: profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .firstName
                                                                ? profileData
                                                                    .personal_details
                                                                    .firstName
                                                                : emptyDetails }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .lastname, value: profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .lastName
                                                                ? profileData
                                                                    .personal_details
                                                                    .lastName
                                                                : emptyDetails }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .dob, value: profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .dob }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .mobilenumber, value: profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .mobileNumber }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .email, value: profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .email }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .emeregencyContact, value: profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .emergencyContactNo }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .gender, value: profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .gender }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .feId, value: profileData &&
                                                                profileData.fe_id }),
                                                        React.createElement(Grid_1.default, { item: true, xs: 18 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" },
                                                                    ' ',
                                                                    "FE Profile Pics"),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 10,
                                                                        lg: 10,
                                                                    } },
                                                                    React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" }, profileImageURL &&
                                                                        profileImageURL ? (React.createElement(React.Fragment, null,
                                                                        React.createElement("img", { src: profileImageURL, alt: "profile" }),
                                                                        React.createElement("div", { className: "download-btn" },
                                                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(profileImageURL), download: true })),
                                                                        React.createElement("div", { className: "image-title" }, profileImageDocName))) : ('No pic uploaded'))))))),
                                                updatedProfileData &&
                                                    updatedProfileData.personal_details &&
                                                    checkUpdatedProfileDetails() && (React.createElement(Grid_1.default, { item: true, xs: 6, container: true },
                                                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Updated Data"))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .firstName && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .firstname, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .firstName
                                                                ? updatedProfileData
                                                                    .personal_details
                                                                    .firstName
                                                                : emptyDetails })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .lastName && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .lastname, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .lastName
                                                                ? updatedProfileData
                                                                    .personal_details
                                                                    .lastName
                                                                : emptyDetails })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .dob && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .dob, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .dob })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .gender && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .gender, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .gender })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .mobileNumber && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .mobilenumber, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .mobileNumber })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .emergencyContactNo && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .emeregencyContact, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .emergencyContactNo })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .email && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .email, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .email })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .uploadPhoto && (React.createElement(Grid_1.default, { item: true, xs: 18 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" },
                                                                    ' ',
                                                                    "FE Profile Pics"),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 10,
                                                                        lg: 10,
                                                                    } },
                                                                    React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" }, updatedProfileData &&
                                                                        updatedProfileData.personal_details &&
                                                                        updatedProfileImageURL &&
                                                                        updatedProfileImageDocName ? (React.createElement(React.Fragment, null,
                                                                        React.createElement("img", { src: updatedProfileImageURL, alt: "profile" }),
                                                                        React.createElement("div", { className: "download-btn" },
                                                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: updatedProfileImageURL, download: true })),
                                                                        React.createElement("div", { className: "image-title" }, updatedProfileImageDocName))) : ('No pic uploaded'))))))))))))),
                                React.createElement(material_1.Accordion, { className: checkUpdatedReferenceDetails()
                                        ? 'details-edited'
                                        : '' },
                                    React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null) },
                                        React.createElement("p", { className: "accoridan-heading" }, "Reference Numbers")),
                                    React.createElement(material_1.AccordionDetails, null,
                                        React.createElement("div", { className: "visit-details" },
                                            React.createElement(Grid_1.default, { container: true, spacing: 2, columns: 12, className: "details-profile-grid" },
                                                React.createElement(Grid_1.default, { item: true, xs: checkUpdatedReferenceDetails()
                                                        ? 6
                                                        : 12, container: true },
                                                    checkUpdatedReferenceDetails() && (React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Previous Data")))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .referenceName1, value: (_a = (profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .referenceContactName1)) !== null && _a !== void 0 ? _a : emptyDetails }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .referenceMobileno1, value: (_b = (profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .referenceMobileNumber1)) !== null && _b !== void 0 ? _b : emptyDetails }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .referenceName2, value: (_c = (profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .referenceContactName2)) !== null && _c !== void 0 ? _c : emptyDetails }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .referenceMobileno2, value: (_d = (profileData &&
                                                                profileData
                                                                    .personal_details
                                                                    .referenceMobileNumber2)) !== null && _d !== void 0 ? _d : emptyDetails }))),
                                                updatedProfileData &&
                                                    updatedProfileData.personal_details &&
                                                    checkUpdatedReferenceDetails() && (React.createElement(Grid_1.default, { item: true, xs: 6, container: true },
                                                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Updated Data"))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper", alignItems: "baseline" },
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .referenceContactName1 && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .referenceName1, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .referenceContactName1 })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .referenceMobileNumber1 && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .referenceMobileno1, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .referenceMobileNumber1 })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .referenceContactName2 && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .referenceName2, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .referenceContactName2 })),
                                                        updatedProfileData &&
                                                            updatedProfileData.personal_details &&
                                                            updatedProfileData
                                                                .personal_details
                                                                .referenceMobileNumber2 && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .referenceMobileno2, value: updatedProfileData &&
                                                                updatedProfileData.personal_details &&
                                                                updatedProfileData
                                                                    .personal_details
                                                                    .referenceMobileNumber2 }))))))))),
                                React.createElement(material_1.Accordion, { className: checkUpdatedPresentAddressSetails()
                                        ? 'details-edited'
                                        : '' },
                                    React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null) },
                                        React.createElement("p", { className: "accoridan-heading" }, "Present Address Details")),
                                    React.createElement(material_1.AccordionDetails, null,
                                        React.createElement("div", { className: "visit-details" },
                                            React.createElement(Grid_1.default, { container: true, spacing: 2, columns: { xs: 12 }, className: "details-profile-grid" },
                                                React.createElement(Grid_1.default, { item: true, xs: checkUpdatedPresentAddressSetails()
                                                        ? 6
                                                        : 12, container: true },
                                                    checkUpdatedPresentAddressSetails() && (React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Previous Data")))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .line1, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .addresslane1 }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .line2, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .addresslane2 }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .landmark, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .landmark }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .city, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .city }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .state, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .state }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .country, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .country }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .pincode, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .pincode }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .addressProofType, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .addressProofType }),
                                                        React.createElement(Grid_1.default, { item: true, xs: 18 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .addressProof),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } }, addressProfDoc &&
                                                                    addressProfDocURL ? (React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" },
                                                                    React.createElement("img", { src: addressProfDoc, alt: "" }),
                                                                    React.createElement("div", { className: "download-btn" },
                                                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(addressProfDocURL), download: true })),
                                                                    React.createElement("div", { className: "image-title" }, currentAddressUploadedDocName))) : ('No Document uploaded uploaded')))))),
                                                updatedProfileData &&
                                                    updatedProfileData.contact_details &&
                                                    checkUpdatedPresentAddressSetails() && (React.createElement(Grid_1.default, { item: true, xs: 6, container: true },
                                                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Updated Data"))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .addresslane1 && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .line1, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .addresslane1 })),
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .addresslane2 && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .line2, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .addresslane2 })),
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .landmark && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .landmark, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .landmark })),
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .state && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .state, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .state })),
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .city && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .city, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .city })),
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .pincode && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .pincode, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .pincode })),
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .addressProofType && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .addressProofType, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .addressProofType })),
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .proofOfAddress && (React.createElement(Grid_1.default, { item: true, xs: 18 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .addressProof),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } }, updatedAddressProfDocURL &&
                                                                    updatedAddressProfDoc ? (React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" },
                                                                    React.createElement("img", { src: updatedAddressProfDoc, alt: "" }),
                                                                    React.createElement("div", { className: "download-btn" },
                                                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(updatedAddressProfDocURL), download: true })),
                                                                    React.createElement("div", { className: "image-title" }, updatedCurrentAddressUploadedDocName))) : ('No Document uploaded uploaded')))))))))))),
                                React.createElement(material_1.Accordion, { className: checkUpdatedPermanentAddressSetails()
                                        ? 'details-edited'
                                        : '' },
                                    React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null) },
                                        React.createElement("p", { className: "accoridan-heading" }, "Permanent Address Details")),
                                    React.createElement(material_1.AccordionDetails, null,
                                        React.createElement("div", { className: "visit-details" },
                                            React.createElement(Grid_1.default, { container: true, spacing: 2, columns: { xs: 12 }, className: "details-profile-grid" },
                                                React.createElement(Grid_1.default, { item: true, xs: checkUpdatedPermanentAddressSetails()
                                                        ? 6
                                                        : 12, container: true },
                                                    checkUpdatedPermanentAddressSetails() && (React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Previous Data")))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .line1, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .permenentAddresslane1 }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .line2, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .permenentAddresslane2 }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .landmark, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .permenentLandmark }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .city, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .permenentCity }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .state, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .permenentState }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .country, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .permenentCountry }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .pincode, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .permenentPincode }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .addressProofType, value: profileData &&
                                                                profileData.contact_details &&
                                                                profileData
                                                                    .contact_details
                                                                    .addressProofType }),
                                                        React.createElement(Grid_1.default, { item: true, xs: 18 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .addressProof),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } }, addressProfDoc1 &&
                                                                    addressProfDoc1URL ? (React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" },
                                                                    React.createElement("img", { src: addressProfDoc1, alt: "" }),
                                                                    React.createElement("div", { className: "download-btn" },
                                                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(addressProfDoc1URL), download: true })),
                                                                    React.createElement("div", { className: "image-title" }, parmanentAddressUploadedDocName))) : ('No Document uploaded uploaded')))))),
                                                updatedProfileData &&
                                                    updatedProfileData.contact_details &&
                                                    checkUpdatedPermanentAddressSetails() && (React.createElement(Grid_1.default, { item: true, xs: 6, container: true },
                                                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Updated Data"))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .permenentAddresslane1 && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .line1, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .permenentAddresslane1 })),
                                                        updatedProfileData
                                                            .contact_details
                                                            .permenentAddresslane2 && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .line2, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .permenentAddresslane2 })),
                                                        updatedProfileData
                                                            .contact_details
                                                            .permenentLandmark && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .landmark, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .permenentLandmark })),
                                                        updatedProfileData
                                                            .contact_details
                                                            .permenentState && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .state, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .permenentState })),
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .permenentCity && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .city, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .permenentCity })),
                                                        updatedProfileData
                                                            .contact_details
                                                            .permenentPincode && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .pincode, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .permenentPincode })),
                                                        updatedProfileData
                                                            .contact_details
                                                            .permanentProofOfAddressType && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .addressProofType, value: updatedProfileData &&
                                                                updatedProfileData.contact_details &&
                                                                updatedProfileData
                                                                    .contact_details
                                                                    .permanentProofOfAddressType })),
                                                        updatedProfileData &&
                                                            updatedProfileData.contact_details &&
                                                            updatedProfileData
                                                                .contact_details
                                                                .permenentProofOfAddress && (React.createElement(Grid_1.default, { item: true, xs: 18 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .addressProof),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } }, updatedPermanentAddressProfDoc &&
                                                                    updatedPermanentAddressProfDocURL ? (React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" },
                                                                    React.createElement("img", { src: updatedPermanentAddressProfDoc, alt: "" }),
                                                                    React.createElement("div", { className: "download-btn" },
                                                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(updatedPermanentAddressProfDocURL), download: true })),
                                                                    React.createElement("div", { className: "image-title" }, updatedPermanentCurrentAddressUploadedDocName))) : ('No Document uploaded uploaded')))))))))))),
                                React.createElement(material_1.Accordion, { className: checkUpdatedBankDetaiDetails()
                                        ? 'details-edited'
                                        : '' },
                                    React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null) },
                                        React.createElement("p", { className: "accoridan-heading" }, "Bank Details")),
                                    React.createElement(material_1.AccordionDetails, null,
                                        React.createElement("div", { className: "visit-details" },
                                            React.createElement(Grid_1.default, { container: true, spacing: 2, columns: 12, className: "details-profile-grid" },
                                                React.createElement(Grid_1.default, { item: true, xs: checkUpdatedBankDetaiDetails()
                                                        ? 6
                                                        : 12, container: true },
                                                    checkUpdatedBankDetaiDetails() && (React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Previous Data")))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .accountNo, value: profileData &&
                                                                profileData.bank_details &&
                                                                profileData
                                                                    .bank_details
                                                                    .AccountNumber }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .ifscCode, value: profileData &&
                                                                profileData.bank_details &&
                                                                profileData
                                                                    .bank_details
                                                                    .IFSC_Code }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .bankName, value: profileData &&
                                                                profileData.bank_details &&
                                                                profileData
                                                                    .bank_details
                                                                    .Bank_Name }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .bankBranch, value: profileData &&
                                                                profileData.bank_details &&
                                                                profileData
                                                                    .bank_details
                                                                    .Bank_Branch }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .upi, value: profileData &&
                                                                profileData.bank_details &&
                                                                profileData
                                                                    .bank_details
                                                                    .UPI_ID }),
                                                        React.createElement(Grid_1.default, { item: true, xs: 9 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .bankAccountDocument),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } },
                                                                    React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" }, bankChequeDocImageURL &&
                                                                        bankChequeDocUploadedURL ? (React.createElement(React.Fragment, null,
                                                                        React.createElement("img", { src: bankChequeDocImageURL, alt: "" }),
                                                                        React.createElement("div", { className: "download-btn" },
                                                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(bankChequeDocUploadedURL), download: true })),
                                                                        React.createElement("div", { className: "image-title" }, bankChequeDocUploadedDocName))) : ('No document uploaded'))))))),
                                                updatedProfileData &&
                                                    updatedProfileData.bank_details &&
                                                    checkUpdatedBankDetaiDetails() && (React.createElement(Grid_1.default, { item: true, xs: 6, container: true },
                                                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Updated Data"))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        updatedProfileData &&
                                                            updatedProfileData.bank_details &&
                                                            updatedProfileData
                                                                .bank_details
                                                                .AccountNumber && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .accountNo, value: updatedProfileData &&
                                                                updatedProfileData.bank_details &&
                                                                updatedProfileData
                                                                    .bank_details
                                                                    .AccountNumber })),
                                                        updatedProfileData
                                                            .bank_details
                                                            .IFSC_Code && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .ifscCode, value: updatedProfileData
                                                                .bank_details
                                                                .IFSC_Code })),
                                                        updatedProfileData
                                                            .bank_details
                                                            .Bank_Name && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .bankName, value: updatedProfileData
                                                                .bank_details
                                                                .Bank_Name })),
                                                        updatedProfileData
                                                            .bank_details
                                                            .Bank_Branch && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .bankBranch, value: updatedProfileData
                                                                .bank_details
                                                                .Bank_Branch })),
                                                        updatedProfileData
                                                            .bank_details
                                                            .UPI_ID && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .upi, value: updatedProfileData
                                                                .bank_details
                                                                .UPI_ID })),
                                                        updatedProfileData &&
                                                            updatedProfileData.bank_details &&
                                                            updatedProfileData
                                                                .bank_details
                                                                .proofOfBankAccountDetails && (React.createElement(Grid_1.default, { item: true, xs: 9 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .bankAccountDocument),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } },
                                                                    React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" }, updatedBankChequeDocImageURL &&
                                                                        updatedBankChequeDocUploadedURL ? (React.createElement(React.Fragment, null,
                                                                        React.createElement("img", { src: updatedBankChequeDocImageURL, alt: "" }),
                                                                        React.createElement("div", { className: "download-btn" },
                                                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(updatedBankChequeDocUploadedURL), download: true })),
                                                                        React.createElement("div", { className: "image-title" }, updatedBankChequeDocUploadedDocName))) : ('No document uploaded'))))))))))))),
                                React.createElement(material_1.Accordion, { className: checkDrivingLiecenceDetails()
                                        ? 'details-edited'
                                        : '' },
                                    React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null) },
                                        React.createElement("p", { className: "accoridan-heading" }, "Driving Licence Details")),
                                    React.createElement(material_1.AccordionDetails, null,
                                        React.createElement("div", { className: "visit-details" },
                                            React.createElement(Grid_1.default, { container: true, spacing: 2, columns: 12, className: "details-profile-grid" },
                                                React.createElement(Grid_1.default, { item: true, xs: checkDrivingLiecenceDetails()
                                                        ? 6
                                                        : 12, container: true },
                                                    checkDrivingLiecenceDetails() && (React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Previous Data")))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .vehicleType, value: profileData &&
                                                                profileData.drivingLiecenceDetails &&
                                                                profileData
                                                                    .drivingLiecenceDetails
                                                                    .vehicleType }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .drivingLicence, value: profileData &&
                                                                profileData.drivingLiecenceDetails &&
                                                                profileData
                                                                    .drivingLiecenceDetails
                                                                    .drivingLicence }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .drivingLicenceExDate, value: profileData &&
                                                                profileData.drivingLiecenceDetails &&
                                                                profileData
                                                                    .drivingLiecenceDetails
                                                                    .drivingLicenceExpirydate }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .insurencExDate, value: profileData &&
                                                                profileData.drivingLiecenceDetails &&
                                                                profileData
                                                                    .drivingLiecenceDetails
                                                                    .insuranceExpirydate }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .insurencNo, value: profileData &&
                                                                profileData.drivingLiecenceDetails &&
                                                                profileData
                                                                    .drivingLiecenceDetails
                                                                    .insuranceNumber }),
                                                        React.createElement(Grid_1.default, { item: true, xs: 6.03 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .uploadInsurence),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } }, insurencepic &&
                                                                    insuranceUploadedURL ? (React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" },
                                                                    React.createElement("img", { src: insurencepic, alt: "" }),
                                                                    React.createElement("div", { className: "download-btn" },
                                                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(insuranceUploadedURL), download: true })),
                                                                    React.createElement("div", { className: "image-title" }, insuranceDocName))) : ('No document uploaded')))),
                                                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .drivingDoc),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } }, drivingLicenceImageURL &&
                                                                    drivingLicenceUploadedURL ? (React.createElement(React.Fragment, null,
                                                                    React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" },
                                                                        React.createElement("img", { src: drivingLicenceImageURL, alt: "" }),
                                                                        React.createElement("div", { className: "download-btn" },
                                                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(drivingLicenceUploadedURL), download: true })),
                                                                        React.createElement("div", { className: "image-title" }, drivingLicenceDocName)))) : ('No document uploaded')))))),
                                                updatedProfileData &&
                                                    updatedProfileData.drivingLiecenceDetails &&
                                                    checkDrivingLiecenceDetails() && (React.createElement(Grid_1.default, { item: true, xs: 6, container: true },
                                                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Updated Data"))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        updatedProfileData &&
                                                            updatedProfileData.drivingLiecenceDetails &&
                                                            updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .vehicleType && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .vehicleType, value: updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .vehicleType })),
                                                        updatedProfileData &&
                                                            updatedProfileData.drivingLiecenceDetails &&
                                                            updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .drivingLicence && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .drivingLicence, value: updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .drivingLicence })),
                                                        updatedProfileData &&
                                                            updatedProfileData.drivingLiecenceDetails &&
                                                            updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .insuranceNumber && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .insurencNo, value: updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .insuranceNumber })),
                                                        updatedProfileData &&
                                                            updatedProfileData.drivingLiecenceDetails &&
                                                            updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .drivingLicenceExpirydate && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .drivingLicenceExDate, value: updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .drivingLicenceExpirydate })),
                                                        updatedProfileData &&
                                                            updatedProfileData.drivingLiecenceDetails &&
                                                            updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .insuranceExpirydate && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .insurencExDate, value: updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .insuranceExpirydate })),
                                                        updatedProfileData &&
                                                            updatedProfileData.drivingLiecenceDetails &&
                                                            updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .proofOfDrivingLicence && (React.createElement(Grid_1.default, { item: true, xs: 9 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .drivingDoc),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } },
                                                                    React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" }, updatedProfileData &&
                                                                        updatedProfileData.drivingLiecenceDetails &&
                                                                        updatedProfileData
                                                                            .drivingLiecenceDetails
                                                                            .proofOfDrivingLicence ? (React.createElement(React.Fragment, null,
                                                                        React.createElement("img", { src: updatedDrivingLicenceImageURL, alt: "profile" }),
                                                                        React.createElement("div", { className: "download-btn" },
                                                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: updatedDrivingLicenceUploadedURL, download: true })),
                                                                        React.createElement("div", { className: "image-title" }, updatedDrivingLicenceDocName))) : ('No pic uploaded')))))),
                                                        updatedProfileData &&
                                                            updatedProfileData.drivingLiecenceDetails &&
                                                            updatedProfileData
                                                                .drivingLiecenceDetails
                                                                .uploadInsuranceDoc && (React.createElement(Grid_1.default, { item: true, xs: 9 },
                                                            React.createElement("div", { className: "user-profile-wrapper" },
                                                                React.createElement("small", { className: "user-label" }, profile_detaile_lable_1.default
                                                                    .PROFILE_LABLES
                                                                    .uploadInsurence),
                                                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: {
                                                                        xs: 9,
                                                                    } },
                                                                    React.createElement(Grid_1.default, { item: true, xs: 3, className: "image-item downloadable-image" }, updatedProfileData &&
                                                                        updatedProfileData.drivingLiecenceDetails &&
                                                                        updatedProfileData
                                                                            .drivingLiecenceDetails
                                                                            .uploadInsuranceDoc ? (React.createElement(React.Fragment, null,
                                                                        React.createElement("img", { src: updatedinsuranceImageURL, alt: "profile" }),
                                                                        React.createElement("div", { className: "download-btn" },
                                                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: updatedInsuranceImageeUploadedURL, download: true })),
                                                                        React.createElement("div", { className: "image-title" }, updatedInsuranceDocName))) : ('No pic uploaded'))))))))))))),
                                React.createElement(material_1.Accordion, { className: checkEmploymentDetails()
                                        ? 'details-edited'
                                        : '' },
                                    React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement(ExpandMore_1.default, null) },
                                        React.createElement("p", { className: "accoridan-heading" }, "Employment Details")),
                                    React.createElement(material_1.AccordionDetails, null,
                                        React.createElement("div", { className: "visit-details" },
                                            React.createElement(Grid_1.default, { container: true, spacing: 2, columns: 12, className: "details-profile-grid" },
                                                React.createElement(Grid_1.default, { item: true, xs: checkEmploymentDetails()
                                                        ? 6
                                                        : 12, container: true },
                                                    checkEmploymentDetails() && (React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Previous Data")))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .feType, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .fe_Type }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .doj, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .dateOfJoining }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .company, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .company }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .tenure, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .tenure }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .labCode, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .labCode }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .labName, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .labName }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .hub, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .HudId }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .regionalManager, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .regionalManager }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .zone, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .zone }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .labState, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .labState }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .labCity, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .labCity }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .feWorkingState, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .fe_working_state }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .feWorkingCity, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .fe_working_city }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .vendorName, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .vendorName }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .vendorCode, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .vendorCode }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .feWorkingCode, value: profileData &&
                                                                profileData.fe_id }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .employee_vendor_id, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .employee_vendor_id }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .baseLocationAddress, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .base_location_address }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .baseLocationCoordinates, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .baseLocation }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .assignRouteType, value: profileData &&
                                                                profileData.employment_details &&
                                                                profileData
                                                                    .employment_details
                                                                    .fe_route_assignment_type }),
                                                        React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .additionalRole, value: profileData &&
                                                                (0, FECreationTabConstant_1.getAdditionalRoleInApproval)(profileData) }))),
                                                updatedProfileData &&
                                                    updatedProfileData.employment_details &&
                                                    checkEmploymentDetails() && (React.createElement(Grid_1.default, { item: true, xs: 6, container: true },
                                                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                            React.createElement("h2", null, "Updated Data"))),
                                                    React.createElement(Grid_1.default, { item: true, xs: 12, container: true, className: "mini-details-wrapper" },
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .employee_vendor_id && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .employee_vendor_id, value: updatedProfileData
                                                                .employment_details
                                                                .employee_vendor_id })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .fe_Type && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .feType, value: updatedProfileData
                                                                .employment_details
                                                                .fe_Type })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .company && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .company, value: updatedProfileData
                                                                .employment_details
                                                                .company })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .tenure && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .tenure, value: updatedProfileData
                                                                .employment_details
                                                                .tenure })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .dateOfJoining && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .doj, value: updatedProfileData
                                                                .employment_details
                                                                .dateOfJoining })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .labCode && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .labCode, value: updatedProfileData
                                                                .employment_details
                                                                .labCode })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .labName && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .labName, value: updatedProfileData
                                                                .employment_details
                                                                .labName })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .labState && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .labState, value: updatedProfileData
                                                                .employment_details
                                                                .labState })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .labCity && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .labCity, value: updatedProfileData
                                                                .employment_details
                                                                .labCity })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .fe_working_state && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .feWorkingState, value: updatedProfileData
                                                                .employment_details
                                                                .fe_working_state })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .fe_working_city && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .feWorkingCity, value: updatedProfileData
                                                                .employment_details
                                                                .fe_working_city })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .HudId && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .hub, value: updatedProfileData
                                                                .employment_details
                                                                .HudId })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .zone && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .zone, value: updatedProfileData
                                                                .employment_details
                                                                .zone })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .regionalManager && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .regionalManager, value: updatedProfileData
                                                                .employment_details
                                                                .regionalManager })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .vendorName && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .vendorName, value: updatedProfileData
                                                                .employment_details
                                                                .vendorName })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .vendorCode && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .vendorCode, value: updatedProfileData
                                                                .employment_details
                                                                .vendorCode })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .base_location_address && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .baseLocationAddress, value: updatedProfileData
                                                                .employment_details
                                                                .base_location_address })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .baseLocation && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .baseLocationCoordinates, value: updatedProfileData
                                                                .employment_details
                                                                .baseLocation })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            (updatedProfileData
                                                                .employment_details
                                                                .backup !=
                                                                profileData
                                                                    .employment_details
                                                                    .backup ||
                                                                updatedProfileData
                                                                    .employment_details
                                                                    .supervisor !=
                                                                    profileData
                                                                        .employment_details
                                                                        .supervisor ||
                                                                updatedProfileData
                                                                    .employment_details
                                                                    .canUpdateCCCoordinates !=
                                                                    profileData
                                                                        .employment_details
                                                                        .canUpdateCCCoordinates) && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .additionalRole, value: (0, FECreationTabConstant_1.getAdditionalRoleInApproval)(updatedProfileData) })),
                                                        updatedProfileData &&
                                                            updatedProfileData.employment_details &&
                                                            updatedProfileData
                                                                .employment_details
                                                                .fe_route_assignment_type && (React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default
                                                                .PROFILE_LABLES
                                                                .assignRouteType, value: updatedProfileData
                                                                .employment_details
                                                                .fe_route_assignment_type })))))))))))))))));
};
exports.default = FEApprovalProfileDetails;
