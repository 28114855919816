"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var config_action_1 = require("../../../actions/config_action");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var map_1 = require("../../../common/map");
var dropdownFields_1 = require("../../../constants/dropdownFields");
var FECreationTabConstant_1 = require("../../../constants/FECreationTabConstant");
var formFieldConstants_1 = require("../../../constants/formFieldConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var array_helpers_1 = require("../../../utils/array_helpers");
var generic_api_calls_1 = require("../../generic_api_calls");
var lodash_1 = require("./../../../utils/lodash");
var FE_EmploymentDetailsForm1 = function (_a) {
    var initialValues = _a.initialValues, saveFormData = _a.saveFormData, enableSendItForApproval = _a.enableSendItForApproval, fepersonalDetails = _a.fepersonalDetails, sendItForApproval = _a.sendItForApproval, updateValue = _a.updateValue, value = _a.value, onBoardingStatus = _a.onBoardingStatus, FE_EmploymentDetailsInitialState = _a.FE_EmploymentDetailsInitialState, FEIdToBeReplaced = _a.FEIdToBeReplaced;
    var _b = React.useState('NO'), buttonState = _b[0], setButtonState = _b[1];
    var _c = React.useState(false), showMaps = _c[0], toggleMapView = _c[1];
    var stateList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.stateList; });
    var feWorkingCitiesList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).feWorkingCitiesList;
    var user_id = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer; }).user_id;
    console.log(FEIdToBeReplaced, 'FEIdToBkjhgfeReplaced');
    var nav = (0, react_router_dom_1.useNavigate)();
    var editUserId = (0, react_router_dom_1.useParams)().id;
    var location = (0, react_router_dom_1.useLocation)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), fe_types = _d.fe_types, tenure = _d.tenure, company = _d.company, labList = _d.labList, HudIdList = _d.HudIdList, zoneTypeList = _d.zoneTypeList, routing_assignment_type = _d.routing_assignment_type;
    var onChangeHandler = function (e) {
        console.log('inside switch component:', e.target.checked);
        if (e.target.checked) {
            setButtonState('YES');
        }
        else {
            setButtonState('NO');
        }
    };
    var getDropdownList = function (filedname) {
        switch (filedname) {
            case 'zone':
                return zoneTypeList;
            case 'labCode':
                return (0, array_helpers_1.convertLabcodeResponse)(labList);
            case 'company':
                return company;
            case 'tenure':
                return tenure;
            case 'fe_Type':
                return fe_types;
            case 'HudId':
                return HudIdList;
            case 'assign_route':
                return routing_assignment_type;
            case 'fe_working_state':
                return stateList;
            case 'fe_working_city':
                return feWorkingCitiesList;
            default:
                return [];
        }
    };
    var renderInputFiled = function (field, values, setFieldValue, setFieldTouched, errors, touched) {
        console.log('errors & touched', values, errors, touched);
        if (!dropdownFields_1.dropdownFields.includes(field.name)) {
            return (React.createElement(React.Fragment, null,
                React.createElement(field.inputComponent, { name: field.name, className: 'text-caps', type: field.name === 'uploadPhoto'
                        ? 'file'
                        : field.name === 'DateOfBirth'
                            ? 'date'
                            : 'text', key: values['baseLocation'], buttonName: field.label, placeholder: field.placeholder, required: field.required, label: field.label, swicthLabel: field.swicthLabel, value: values[field.fieldValue], defaultVal: field.defaultVal, isPastDisable: field.isPastDisable, isFutureDisable: field.isFutureDisable, isDisabled: field.isDisabled, minDate: field.minDate, maxDate: field.maxDate, require: field.require, onClick: field.name !== 'base_location_address'
                        ? function () { }
                        : function () { return toggleMapView(!showMaps); }, checked: values[field.fieldValue], onChange: function (val) {
                        setFieldValue(field.fieldValue, field.type !== 'dateselection' ? val.trim() : val);
                        setFieldTouched(field.fieldValue, true);
                    }, onChangeHandler: onChangeHandler, handleChange: function (val) {
                        setFieldValue(field.fieldValue, val.target.checked);
                        setFieldTouched(field.fieldValue, true);
                    }, buttonState: buttonState }),
                errors[field.fieldValue] && touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue])) : null));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(field.inputComponent, { name: field.name, className: 'text-caps', type: "text", dropDownList: getDropdownList(field.name), key: field.name === 'labCode'
                        ? labList
                        : field.name === 'HudId'
                            ? HudIdList
                            : 'key', placeholder: field.placeholder, swicthLabel: field.swicthLabel, dateState: field.dateState, required: field.required, label: field.label, value: values[field.fieldValue], defaultVal: field.defaultVal, isDisabled: field.isDisabled, displayRemark: field.displayRemark, onChange: function (val) {
                        console.log('selected value:', val);
                        if (field.name === 'labCode') {
                            console.log('onChange of labcode triggered:', val);
                            if (!val) {
                                setFieldValue('labName', '');
                                setFieldValue('FEWorkingState', '');
                                setFieldValue('FEWorkingCity', '');
                                setFieldValue('HudId', null);
                            }
                            else {
                                setFieldValue('labName', val.center_name ? val.center_name : '');
                                setFieldValue('FEWorkingState', val.center_state ? val.center_state : '');
                                setFieldValue('FEWorkingCity', val.center_city ? val.center_city : '');
                                (0, generic_api_calls_1.fetchHubList)({
                                    lab_code: val.value,
                                })
                                    .then(function (res) {
                                    if (res.data.statusCode === 200) {
                                        var hubListRes = (0, array_helpers_1.convertHubListFromRes)(res.data.data);
                                        console.log('HUbListRes', hubListRes);
                                        dispatch((0, config_action_1.fetch_HubIDList)(hubListRes));
                                    }
                                    else {
                                        dispatch((0, config_action_1.fetch_HubIDList)([]));
                                    }
                                })
                                    .catch(function (e) { return dispatch((0, config_action_1.fetch_HubIDList)([])); });
                            }
                            setFieldValue('HudId', null);
                            setFieldValue('baseLocation', '');
                            setFieldValue('base_location_address', '');
                        }
                        if (field.name === 'zone') {
                            if (val === null || val === '' || val === undefined) {
                                setFieldValue('regionalManager', '');
                            }
                            else {
                                setFieldValue('regionalManager', val.regional_manager);
                            }
                        }
                        if (field.name === 'fe_working_state') {
                            setFieldValue('fe_working_city', null);
                            if (val != null && val != '' && val != undefined) {
                                (0, generic_api_calls_1.fetchCitiesUnderStates)([val.id]).then(function (res) {
                                    dispatch((0, config_action_1.fetch_fe_working_cities)(res));
                                });
                            }
                            else {
                                dispatch((0, config_action_1.fetch_fe_working_cities)([]));
                            }
                        }
                        setFieldValue(field.fieldValue, val);
                        setFieldTouched(field.fieldValue, true);
                    } }),
                errors[field.fieldValue] && touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue])) : null));
        }
    };
    var renderMapsComponent = function (setFileValue, setFieldTouched, values) {
        if (showMaps) {
            return (React.createElement(React.Fragment, null,
                React.createElement(map_1.default, { bast_lat_lng: values.baseLocation, baselocation_address: values.base_location_address, lab_lat_lng: values.labCode, submitSelectedPlace: function (latAndLng, fe_current_location) {
                        if (latAndLng) {
                            setFileValue('baseLocation', "".concat(latAndLng));
                            setFieldTouched('baseLocation', true);
                            setFileValue('base_location_address', "".concat(fe_current_location));
                            setFieldTouched('base_location_address', true);
                            toggleMapView(false);
                        }
                    }, close: function () { return toggleMapView(false); } })));
        }
        return React.createElement(React.Fragment, null);
    };
    var formSubmitHandler = function (values) {
        console.log(values, 'values');
        if (lodash_1.default.isEqual(values, initialValues)) {
            if (onBoardingStatus === 1) {
                if (location.pathname.includes('edit')) {
                    nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.INVENTORYDETAILS), { replace: true });
                    updateValue(FECreationTabConstant_1.CreationTabConstants.INVENTORYDETAILS);
                }
                else {
                    nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.INVENTORYDETAILS), { replace: true });
                }
            }
            else {
                react_hot_toast_1.default.error('No change in the details');
            }
            return;
        }
        if (onBoardingStatus === 1) {
            saveFormData(values, false);
        }
        else {
            var payload = {};
            var personalDetailsObj = {};
            personalDetailsObj['first_name'] = fepersonalDetails.firstName
                .trim()
                .toUpperCase();
            personalDetailsObj['last_name'] = fepersonalDetails.lastName
                .trim()
                .toUpperCase();
            personalDetailsObj['dob'] =
                fepersonalDetails.dob != '' &&
                    fepersonalDetails.dob != null &&
                    fepersonalDetails.dob != undefined
                    ? moment(fepersonalDetails.dob).format('YYYY-MM-DD')
                    : '';
            personalDetailsObj['mobile'] = fepersonalDetails.mobileNumber;
            personalDetailsObj['email'] = fepersonalDetails.email
                .trim()
                .toUpperCase();
            personalDetailsObj['gender'] =
                fepersonalDetails.gender != null ? fepersonalDetails.gender.label : '';
            personalDetailsObj['avatar'] = fepersonalDetails.uploadPhoto;
            personalDetailsObj['emergency_mobile'] =
                fepersonalDetails.emergencyContactNo;
            personalDetailsObj['reference_name1'] =
                fepersonalDetails.referenceContactName1.trim().toUpperCase();
            personalDetailsObj['reference_name2'] =
                fepersonalDetails.referenceContactName2.trim().toUpperCase();
            personalDetailsObj['reference_mobile1'] =
                fepersonalDetails.referenceMobileNumber1;
            personalDetailsObj['reference_mobile2'] =
                fepersonalDetails.referenceMobileNumber2;
            var employmentDetails = {};
            employmentDetails['role_id'] =
                values.fe_Type != null ? values.fe_Type.id : '';
            employmentDetails['date_of_joining'] =
                values.dateOfJoining != '' &&
                    values.dateOfJoining != null &&
                    values.dateOfJoining != undefined
                    ? moment(new Date(values.dateOfJoining)).format('YYYY-MM-DD')
                    : '';
            employmentDetails['company_id'] =
                values.company != null ? values.company.id : '';
            employmentDetails['lab_id'] =
                values.labCode != null ? values.labCode.value : '';
            employmentDetails['tenure_id'] =
                values.tenure != null ? values.tenure.id : '';
            employmentDetails['zone_id'] = values.zone != null ? values.zone.id : '';
            employmentDetails['working_state_id'] = values.fe_working_state != null || values.fe_working_state != undefined ? Number(values.fe_working_state.id) : '';
            employmentDetails['working_city_id'] = values.fe_working_city != null || values.fe_working_city != undefined ? Number(values.fe_working_city.id) : '';
            employmentDetails['hub_id'] = values.HudId != null ? values.HudId.value : '';
            employmentDetails['base_latitude'] = values.baseLocation
                ? values.baseLocation.split(',')[0].toString()
                : '';
            employmentDetails['base_longitude'] = values.baseLocation
                ? values.baseLocation.split(',')[1].toString()
                : '';
            employmentDetails['base_location_address'] = values.base_location_address
                ? values.base_location_address
                : '';
            employmentDetails['employee_id'] = values.employee_vendor_id
                ? values.employee_vendor_id
                : '';
            employmentDetails['fe_route_assignment_type'] = values.assign_route
                ? values.assign_route.id
                : '';
            employmentDetails['supervisor'] = values.isSuperVisor
                ? values.isSuperVisor
                : false;
            employmentDetails['backup'] = values.isBackUpFE
                ? values.isBackUpFE
                : false;
            employmentDetails['can_update_cc_coordinates'] =
                values.canEditCCCoordinates ? values.canEditCCCoordinates : false;
            if (FEIdToBeReplaced) {
                payload['personal_details'] = personalDetailsObj;
                payload['employment_details'] = employmentDetails;
                payload['is_final_submit'] = false;
                payload['user_id'] = user_id;
                payload['is_replacement'] = true;
                payload['replacement_fe_id'] = parseInt(FEIdToBeReplaced);
            }
            else {
                payload['personal_details'] = personalDetailsObj;
                payload['employment_details'] = employmentDetails;
                payload['is_final_submit'] = false;
                payload['user_id'] = user_id;
            }
            api_service_1.api_service
                .post({
                endPoint: api_service_1.api_service.api_urls.FE_CREATION,
                payLoad: payload,
                domain: process.env.VENDOR_BASE_URL,
            })
                .then(function (res) {
                if (res.data.statusCode === 200) {
                    react_hot_toast_1.default.success(res.data.message);
                    saveFormData(values, false);
                    if (onBoardingStatus === 1) {
                        if (location.pathname.includes('edit')) {
                            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(res.data.data.user_id, "/").concat(FECreationTabConstant_1.CreationTabConstants.INVENTORYDETAILS));
                            updateValue(FECreationTabConstant_1.CreationTabConstants.INVENTORYDETAILS);
                        }
                        else {
                            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(res.data.data.user_id, "/").concat(FECreationTabConstant_1.CreationTabConstants.INVENTORYDETAILS));
                            updateValue(FECreationTabConstant_1.CreationTabConstants.INVENTORYDETAILS);
                        }
                    }
                }
                else {
                    react_hot_toast_1.default.error(res.data.errors[0]);
                }
            })
                .catch(function (err) {
                react_hot_toast_1.default.error(err.data.errors);
            });
        }
    };
    var backButtonHandler = function () {
        if (onBoardingStatus === 1) {
            updateValue(value - 1);
        }
        else {
            if (location.pathname.includes('edit')) {
                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.DRIVINGLICENCEDETAILS), { replace: true });
                updateValue(FECreationTabConstant_1.CreationTabConstants.DRIVINGLICENCEDETAILS);
            }
            else {
                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.DRIVINGLICENCEDETAILS), { replace: true });
                updateValue(FECreationTabConstant_1.CreationTabConstants.DRIVINGLICENCEDETAILS);
            }
        }
    };
    console.log('initialValues inside employment details:', initialValues);
    return (React.createElement("div", { className: "form-filed-wrapper" },
        React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", null, "Employment details"),
            React.createElement("p", { className: "sub-heaidng" }, "Please update the FE's employment details")),
        React.createElement("div", null,
            React.createElement(formik_1.Formik, { validateOnBlur: false, initialValues: __assign({}, initialValues), validationSchema: schema_1.schemas.FE_EmployementDetailsSchema, onSubmit: function (values) {
                    formSubmitHandler(values);
                } }, function (_a) {
                var errors = _a.errors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, resetForm = _a.resetForm, setFieldTouched = _a.setFieldTouched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 }, formFieldConstants_1.FE_EmploymentDetails_Fields &&
                        formFieldConstants_1.FE_EmploymentDetails_Fields.map(function (section) {
                            return (React.createElement(React.Fragment, null,
                                React.createElement(Grid_1.default, { item: true, xs: 9 },
                                    React.createElement("h2", { className: "label-heading heading" },
                                        section.title,
                                        section.description && (React.createElement("p", { className: "sub-heaidng label-sub-heading" }, section.description)))),
                                section.fields.map(function (field, index) {
                                    return (React.createElement(Grid_1.default, { item: true, key: index, xs: field.type === 'fileupload' ? 9 : 5, "xs-offset": field.type === 'fileupload' ? 1 : 0 }, renderInputFiled(field, values, setFieldValue, setFieldTouched, errors, touched)));
                                })));
                        })),
                    renderMapsComponent(setFieldValue, setFieldTouched, values),
                    React.createElement("div", { className: "form-btns" },
                        React.createElement(button_component_1.default, { type: "button", buttonName: "Back", color: "yellow-outline", onClickHandler: backButtonHandler }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: 'Save & Continue', color: "yellow", isError: false }),
                        React.createElement(button_component_1.default, { type: "button", buttonName: 'Reset', color: "yellow", isError: user_id !== null &&
                                user_id !== undefined &&
                                JSON.stringify(values) !== JSON.stringify(initialValues)
                                ? false
                                : true, onClickHandler: function () { return resetForm({ values: initialValues }); } }),
                        enableSendItForApproval && (React.createElement(button_component_1.default, { buttonName: "Send it for approval", color: "blue", onClickHandler: sendItForApproval })))));
            }))));
};
exports.default = FE_EmploymentDetailsForm1;
