"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../../reports/reports_helper");
var Intracity_tat_graph_list = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate, selectedEvent = _a.selectedEvent, labCode = _a.labCode, labList = _a.labList;
    var _b = React.useState(undefined), IntracityTatList = _b[0], setIntracityTatList = _b[1];
    var _c = React.useState(1), pageCount = _c[0], setpageCount = _c[1];
    var _d = React.useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = React.useState(), feGrandtotal = _e[0], setFegrandtotal = _e[1];
    var _f = React.useState(false), open = _f[0], setOpen = _f[1];
    var state = (0, react_router_dom_1.useLocation)().state;
    console.log(state, 'state');
    var nav = (0, react_router_dom_1.useNavigate)();
    var refetch = (0, react_query_1.useQuery)(['dynamic-clients-list', pageCount], function () {
        var payload = {};
        if (state.startDate && state.endDate) {
            var dateRangeArr = {
                start_date: moment(state.startDate).format('YYYY-MM-DD'),
                end_date: moment(state.endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (state.labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(state.labCode, state.labList);
            payload['lab_code'] = selectedlabCode;
        }
        if (state.selectedEvent != null) {
            payload['type'] = state.selectedEvent;
        }
        (0, reports_helper_1.IntercityTatFeList)(payload)
            .then(function (response) {
            setErrorMessage('');
            setIntracityTatList(response.data.data.result);
            setFegrandtotal(response.data.data.grand_total);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setIntracityTatList([]);
        });
    }).refetch;
    var navigateToIntracityFilter = function () {
        nav("/".concat(route_path_1.default.PATHS.intracity_tat_filters));
    };
    var navigateToMyReports = function () {
        nav("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var autoGenerateReportHandler = function () {
        var payload = {};
        if (state.startDate && state.endDate) {
            var dateRangeArr = {
                start_date: moment(state.startDate).format('YYYY-MM-DD'),
                end_date: moment(state.endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (state.labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(state.labCode, state.labList);
            payload['lab_code'] = selectedlabCode;
        }
        if (state.selectedEvent != null) {
            payload['type'] = state.selectedEvent;
        }
        (0, reports_helper_1.IntercityTatFeListGenerate)(payload)
            .then(function (response) {
            console.log(response.data.data.statusCode, 'response.data.data.statusCode');
            if (response.data.data.statusCode === 200) {
                react_hot_toast_1.default.error(response.data.message);
            }
            else {
                setOpen(true);
            }
        })
            .catch(function (err) {
            console.log(err, 'rrr');
            react_hot_toast_1.default.error(err);
        });
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [state.labCode, state.selectedEvent, state.startDate, state.endDate]);
    return (React.createElement("div", { className: "fe-wrapper pay  -fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateToIntracityFilter }),
                React.createElement("h2", null, "FE Intracity TAT")),
            React.createElement("h2", { className: "F-18 intracity-grand_totol" },
                (0, array_helpers_1.HeadingBasedOnEvent)(state.selectedEvent),
                React.createElement("span", null, feGrandtotal ? feGrandtotal : ' 0%')),
            React.createElement(button_component_1.default, { type: "button", buttonName: 'Download', color: "yellow-outline", isError: (IntracityTatList === null || IntracityTatList === void 0 ? void 0 : IntracityTatList.length) > 0 ? false : true, endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler })),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.INTRACITY_TAT_FE_LIST_COLUMNS, rows: IntracityTatList, pagination: true, errorMessage: errorMessage, className: 'intarcity-tat-pagination' })),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = Intracity_tat_graph_list;
