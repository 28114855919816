"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var otp_input_react_1 = require("otp-input-react");
var React = require("react");
var OTPFIeldComponent = function (props) {
    var _a = React.useState(''), OTP = _a[0], setOTP = _a[1];
    var requestForOtp = function (payload) {
    };
    var onChangeHandler = function (e) {
        setOTP(e);
        props.onChange(e);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(otp_input_react_1.default, { value: OTP, onChange: onChangeHandler, autoFocus: true, OTPLength: 4, otpType: "number", disabled: false }),
        React.createElement(otp_input_react_1.ResendOTP, { onResendClick: requestForOtp })));
};
exports.default = OTPFIeldComponent;
