"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LAB_HANDOVER_X_AXIS_VALUES = exports.convertLabHandoverTrendSamples = exports.LAB_HANDOVER_TREND_SMAPLES_X_VALUES = exports.convertLabHandoverResponse = exports.convertFloatToPercentage = exports.sumCumulativeData = exports.converCumulativeData = void 0;
var converCumulativeData = function (data) {
    var covneredData = [];
    data &&
        data.map(function (val) {
            var cumulativeValue = Number(val.toFixed(1));
            covneredData.push(cumulativeValue);
        });
    return covneredData;
};
exports.converCumulativeData = converCumulativeData;
var sumCumulativeData = function (response) {
    var cumulativeData = [];
    response &&
        response.map(function (val, index) {
            if (val == 0 && index > 0) {
                if (cumulativeData.includes(100)) {
                    cumulativeData.push(100);
                }
                else {
                    cumulativeData.push(response[index - 1]);
                }
            }
            else {
                cumulativeData.push(val);
            }
        });
    return cumulativeData;
};
exports.sumCumulativeData = sumCumulativeData;
var convertFloatToPercentage = function (value) {
    var covnertedValue = '--';
    if (value !== null || value != undefined) {
        covnertedValue = Number.isInteger(value)
            ? "".concat(value, "%")
            : "".concat(Number(value.toFixed(1)), "%");
    }
    return covnertedValue;
};
exports.convertFloatToPercentage = convertFloatToPercentage;
var convertLabHandoverResponse = function (response) {
    var resultedObj = {
        ontime_handover: [],
        delayed_handover: [],
        early_handover: [],
        x_axis: [],
    };
    response.map(function (each) {
        resultedObj.x_axis.push(each._id);
        resultedObj.ontime_handover.push(each.ONTIME_HANDOVER);
        resultedObj.delayed_handover.push(each.DELAYED_HANDOVER);
        resultedObj.early_handover.push(each.EARLY_HANDOVER);
    });
    return resultedObj;
};
exports.convertLabHandoverResponse = convertLabHandoverResponse;
exports.LAB_HANDOVER_TREND_SMAPLES_X_VALUES = [
    '00:01-01:00',
    '01:01-02:00',
    '02:01-03:00',
    '03:01-04:00',
    '04:01-05:00',
    '05:01-06:00',
    '06:01-07:00',
    '07:01-08:00',
    '08:01-09:00',
    '09:01-10:00',
    '10:01-11:00',
    '11:01-12:00',
    '12:01-13:00',
    '13:01-14:00',
    '14:01-15:00',
    '15:01-16:00',
    '16:01-17:00',
    '17:01-18:00',
    '18:01-19:00',
    '19:01-20:00',
    '20:01-21:00',
    '21:01-22:00',
    '22:01-23:00',
    '23:01-00:00',
];
var convertLabHandoverTrendSamples = function (response) {
    var resultedObj = {
        cumulative: [],
        samples_count: [],
    };
    console.log(response, 'yyuuygyyu');
    var refernceSlots = JSON.parse(JSON.stringify(exports.LAB_HANDOVER_X_AXIS_VALUES));
    var cumulativeZeroValue = [];
    if (response.length > 0) {
        response.map(function (each) {
            console.log(response, 'ugyiuio');
            refernceSlots.map(function (val) {
                if (each.time_slot.split(':')[0] === val.time_slot.split(':')[0]) {
                    val.count = each.sample_count;
                    val.cumulative = each.sample_percentage;
                    cumulativeZeroValue.push(val.count);
                }
            });
        });
        console.log(resultedObj, 'resultedObjresultedObj');
        var isValueGreZero = cumulativeZeroValue.filter(function (val) { return val > 0; });
        if (isValueGreZero.length > 0) {
            refernceSlots.map(function (sample) {
                resultedObj.samples_count.push(sample.count);
                resultedObj.cumulative.push(sample.cumulative);
            });
        }
    }
    return resultedObj;
};
exports.convertLabHandoverTrendSamples = convertLabHandoverTrendSamples;
exports.LAB_HANDOVER_X_AXIS_VALUES = [
    {
        time_slot: '00:01-01:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '01:01-02:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '02:01-03:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '03:01-04:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '04:01-05:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '05:01-06:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '06:01-07:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '07:01-08:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '08:01-09:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '09:01-10:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '10:01-11:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '11:01-12:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '12:01-13:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '13:01-14:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '14:01-15:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '15:01-16:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '16:01-17:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '17:01-18:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '18:01-19:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '19:01-20:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '20:01-21:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '21:01-22:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '22:01-23:00',
        count: 0,
        cumulative: 0,
    },
    {
        time_slot: '23:01-00:00',
        count: 0,
        cumulative: 0,
    },
];
