"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var UserListTooltip = function (_a) {
    var tooltipData = _a.tooltipData;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    var emptyValue = '--';
    return (React.createElement(material_1.Tooltip, { open: open, placement: "bottom", onClose: handleClose, className: "tool-tip-wrapper", title: React.createElement("div", { className: "tool-tip-list" }, tooltipData && tooltipData.length > 0
            ? tooltipData.slice(1, tooltipData.length).map(function (list, index) {
                return React.createElement("div", { key: index }, list && list);
            })
            : ''), arrow: true },
        React.createElement("div", null, React.createElement("div", null,
            ' ',
            tooltipData && tooltipData[0] ? tooltipData[0] : emptyValue,
            ' ',
            tooltipData.length <= 1 ? ('') : (React.createElement("span", { onMouseOver: handleOpen, className: "more-values" },
                "+",
                tooltipData.length - 1))))));
};
exports.default = UserListTooltip;
