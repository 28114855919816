"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderApp = void 0;
var React = require("react");
var react_dom_1 = require("react-dom");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_router_dom_1 = require("react-router-dom");
var react_1 = require("redux-persist/lib/integration/react");
require("../assets/styles/base.scss");
var app_1 = require("./app");
var network_page_1 = require("./components/network_page/network_page");
require("./i18n");
var index_1 = require("./store/index");
var Provider = require('react-redux').Provider;
(0, index_1.initReduxStore)(function (err, state) {
    if (!err) {
        renderApp();
    }
});
var rootElement = document.getElementById('root');
var initialLoader = document.getElementById('loader');
function renderApp() {
    var client = new react_query_1.QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });
    initialLoader.style.display = 'none';
    return (React.createElement(Provider, { store: index_1.store },
        React.createElement(react_1.PersistGate, { loading: null, persistor: index_1.persistor },
            React.createElement(react_query_1.QueryClientProvider, { client: client },
                React.createElement(react_router_dom_1.BrowserRouter, null,
                    React.createElement(network_page_1.default, null),
                    React.createElement(app_1.default, null),
                    React.createElement(react_hot_toast_1.Toaster, { position: "top-center", toastOptions: {
                            className: '',
                            duration: 900,
                            style: {
                                background: '#363636',
                                color: '#fff',
                                margin: '9%',
                            },
                        } }))))));
}
exports.renderApp = renderApp;
(0, react_dom_1.render)(renderApp(), rootElement);
