"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_paginate_1 = require("react-paginate");
require("./pagination.scss");
var pagination_constants_1 = require("./pagination_constants");
var ReactPagination = function (_a) {
    var pageCount = _a.pageCount, onPageChange = _a.onPageChange, currentPage = _a.currentPage, onPageLimitChange = _a.onPageLimitChange, currentPageLimit = _a.currentPageLimit, fromCount = _a.fromCount, toCount = _a.toCount, total = _a.total, currentPageLimits = _a.currentPageLimits, dataLength = _a.dataLength, isFELiveTracking = _a.isFELiveTracking;
    var start = (currentPage - 1) * dataLength + 1;
    var end = total;
    if (dataLength < total) {
        end = dataLength * currentPage;
        if (end > total || dataLength < currentPageLimits) {
            start = total - dataLength + 1;
            end = total;
        }
    }
    return (React.createElement(material_1.Stack, { gap: 2, direction: 'row', alignItems: 'center', className: pagination_constants_1.REACT_PAGINATION_CONSTANTS.PaginationClass },
        total && !isFELiveTracking ? (React.createElement("p", null,
            ' ',
            React.createElement("span", null,
                " ",
                start),
            " \u00A0 to \u00A0 ",
            React.createElement("span", null, end),
            " of \u00A0",
            React.createElement("span", null, total),
            ' ')) : null,
        React.createElement(react_paginate_1.default, { previousLabel: pageCount < 1 ? '' : pagination_constants_1.REACT_PAGINATION_CONSTANTS.previousLabel, nextLabel: pageCount < 1 ? '' : pagination_constants_1.REACT_PAGINATION_CONSTANTS.nextLabel, pageCount: pageCount, onPageChange: onPageChange, disabledClassName: pagination_constants_1.REACT_PAGINATION_CONSTANTS.disableClass, marginPagesDisplayed: pagination_constants_1.REACT_PAGINATION_CONSTANTS.marginPagesDisplayed, pageRangeDisplayed: pagination_constants_1.REACT_PAGINATION_CONSTANTS.pageRangeDisplayed, forcePage: currentPage > 0 ? currentPage - 1 : currentPage })));
};
exports.default = ReactPagination;
