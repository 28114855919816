"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Avatar_1 = require("@mui/material/Avatar");
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_router_1 = require("react-router");
var api_urls_1 = require("../../../api/api_urls");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var status_buttons_1 = require("../../../common/buttons/status_buttons");
var alert_modal_1 = require("../../../common/modal/alert_modal");
var profile_grid_1 = require("../../../common/profile_grid/profile_grid");
var tool_tip_1 = require("../../../common/tool_tip/tool_tip");
var appConstants_1 = require("../../../constants/appConstants");
var images_1 = require("../../../constants/images");
var profile_detaile_lable_1 = require("../../../constants/profile_detaile_lable");
var route_path_1 = require("../../../constants/route_path");
var vendor_creation_tab_constant_1 = require("../../../constants/vendor_creation_tab_constant");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var fe_services_1 = require("../../field_exicutive/fe_services");
var generic_api_calls_1 = require("../../generic_api_calls");
var reject_user_1 = require("../approval_management/apprvals/reject_user");
var draft_vendors_1 = require("./list/draft_vendors");
require("./vendor-managment.scss");
var VendorProfileDetails = function () {
    var pathName = window.location.pathname;
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.approvals);
    var vendorFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.vendorManagement);
    var levelOneDetails = pathName.includes('vendorlevel1-details');
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)();
    var _a = React.useState(), profileData = _a[0], setProfileData = _a[1];
    var _b = React.useState(), feData = _b[0], setFedata = _b[1];
    var _c = React.useState(), feId = _c[0], setFeId = _c[1];
    console.log(profileData, 'profileData', feId);
    console.log(profileData === null || profileData === void 0 ? void 0 : profileData.id, 'jhgfcx');
    var _d = React.useState(''), errorMessage = _d[0], setErrormsg = _d[1];
    var emptyDetails = '--';
    var _e = React.useState(''), profileImageURL = _e[0], setProfileImageURL = _e[1];
    var _f = React.useState(''), profileFeImageURL = _f[0], setFEProfileImageURL = _f[1];
    var _g = React.useState(''), contactDoc = _g[0], setContactDoc = _g[1];
    var _h = React.useState(''), contactDocURL = _h[0], setContactDocURL = _h[1];
    var _j = React.useState(''), contactDocName = _j[0], setContactDocName = _j[1];
    var _k = React.useState(''), addressProfDoc = _k[0], setAddressProfDoc = _k[1];
    var _l = React.useState(''), addressProfDocURL = _l[0], setAddressProfDocURL = _l[1];
    var _m = React.useState(''), addressProfDocName = _m[0], setAddressProfDocName = _m[1];
    var _o = React.useState(''), addressProfDoc1 = _o[0], setAddressProfDoc1 = _o[1];
    var _p = React.useState(''), addressProfDoc1URL = _p[0], setAddressProfDoc1URL = _p[1];
    var _q = React.useState(''), addressProfDoc1Name = _q[0], setAddressProfDoc1Name = _q[1];
    var vendorLevelOneApprvoa = { vendor_id: profileData && profileData.id };
    var exitVendorPayload = { vendor_id: profileData && profileData.id };
    var clickHandler = function () {
        if (location.pathname.includes("".concat(route_path_1.default.PATHS.vendorLevelTwoDetails))) {
            nav("/".concat(route_path_1.default.PATHS.approvals, "/").concat(route_path_1.default.PATHS.vendorlist, "/").concat(route_path_1.default.PATHS.levelTwoPendingApproval));
        }
        else if (location.pathname.includes("".concat(route_path_1.default.PATHS.vendorLevelOneDetails))) {
            nav("/".concat(route_path_1.default.PATHS.approvals, "/").concat(route_path_1.default.PATHS.vendorlist, "/").concat(route_path_1.default.PATHS.levelOnePendingApproval));
        }
        else {
            nav("/".concat(route_path_1.default.PATHS.vendor, "/").concat(route_path_1.default.PATHS.activeList));
        }
    };
    var fetchProfileList = function (endPoint, domain) {
        (0, fe_services_1.fetchVendorProfileList)(endPoint, domain)
            .then(function (response) {
            setProfileData(response);
            setErrormsg("");
            getFEListUnderAVendor("".concat(api_urls_1.api_urls.VENDOR_FE_PROFILE_LIST, "?vendor_id=").concat(response.id && response.id), process.env.ONE_TWO_LEVEL_APPROVAL_URL);
            profileImageURL;
            setFeId(response.id);
            if (response.vendor_logo != null &&
                response.vendor_logo != undefined &&
                response.vendor_logo != '') {
                (0, generic_api_calls_1.fetchImnageURLFromName)(response.vendor_logo).then(function (res) {
                    setProfileImageURL(res.data.data.url);
                });
            }
            if (response.contract_doc) {
                var contract_doc_1 = response.contract_doc.split('/');
                if (contract_doc_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response.contract_doc).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (contract_doc_1[contract_doc_1.length - 1].includes('jpg') ||
                            contract_doc_1[contract_doc_1.length - 1].includes('png') ||
                            contract_doc_1[contract_doc_1.length - 1].includes('jfif')) {
                            setContactDoc((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            setContactDocURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (contract_doc_1[contract_doc_1.length - 1].includes('pdf')) {
                            setContactDoc(images_1.default.pdfImage);
                            setContactDocURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            setContactDoc(images_1.default.csvImage);
                            setContactDocURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                }
                setContactDocName(contract_doc_1[contract_doc_1.length - 1]);
            }
            if (response.contact_details && response.contact_details.length > 0) {
                var nameArr_1 = response.contact_details[0].proof_of_doc
                    ? response.contact_details[0].proof_of_doc.split('/')
                    : [];
                if (nameArr_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response.contact_details[0].proof_of_doc).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (nameArr_1[nameArr_1.length - 1].includes('jpg') ||
                            nameArr_1[nameArr_1.length - 1].includes('png') ||
                            nameArr_1[nameArr_1.length - 1].includes('jfif')) {
                            setAddressProfDoc((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            setAddressProfDocURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (nameArr_1[nameArr_1.length - 1].includes('pdf')) {
                            setAddressProfDoc(images_1.default.pdfImage);
                            setAddressProfDocURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            setAddressProfDoc(images_1.default.csvImage);
                            setAddressProfDocURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                    setAddressProfDocName(nameArr_1[nameArr_1.length - 1]);
                }
            }
            if (response.contact_details && response.contact_details.length > 0) {
                var nameArr1_1 = response.contact_details[1].proof_of_doc
                    ? response.contact_details[1].proof_of_doc.split('/')
                    : [];
                if (nameArr1_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response.contact_details[1].proof_of_doc).then(function (res) {
                        if (nameArr1_1[nameArr1_1.length - 1].includes('jpg') ||
                            nameArr1_1[nameArr1_1.length - 1].includes('png') ||
                            nameArr1_1[nameArr1_1.length - 1].includes('jfif')) {
                            setAddressProfDoc1(res.data.data.url);
                            setAddressProfDoc1URL(res.data.data.url);
                        }
                        else if (nameArr1_1[nameArr1_1.length - 1].includes('pdf')) {
                            setAddressProfDoc1(images_1.default.pdfImage);
                            setAddressProfDoc1URL(res.data.data.url);
                        }
                        else {
                            setAddressProfDoc1(images_1.default.csvImage);
                            setAddressProfDoc1URL(res.data.data.url);
                        }
                    });
                    setAddressProfDoc1Name(nameArr1_1[nameArr1_1.length - 1]);
                }
            }
        })
            .catch(function (err) {
            setErrormsg(err.message);
        });
    };
    var vendorDetails = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 18 },
                React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                    React.createElement("h2", null, profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_HEADINGS[0]))),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.vendor_name, value: profileData && profileData.name
                    ? profileData.name.substring(0, 10) +
                        "".concat(profileData.name.length > 10 ? '...' : '')
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.entity_type_name, value: (_a = (profileData && profileData.entity_type_name)) !== null && _a !== void 0 ? _a : emptyDetails })));
    };
    var vendorContactDetails = function () {
        var _a, _b, _c, _d, _e, _f;
        return (React.createElement(React.Fragment, null,
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.contat_nmae, value: (_a = (profileData && profileData.contact_name)) !== null && _a !== void 0 ? _a : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.designation, value: (_b = (profileData && profileData.contact_person_designation)) !== null && _b !== void 0 ? _b : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.mobilenumber, value: (_c = (profileData && profileData.contact_mobile)) !== null && _c !== void 0 ? _c : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.alternateMobile, value: (_d = (profileData && profileData.alternate_mobile)) !== null && _d !== void 0 ? _d : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.email, value: (_e = (profileData && profileData.contact_email)) !== null && _e !== void 0 ? _e : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.website, value: (_f = (profileData && profileData.contact_website)) !== null && _f !== void 0 ? _f : emptyDetails })));
    };
    var vendorOfficeAddressDetails = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.addressline1, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[0].lane1
                    ? profileData.contact_details[0].lane1
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.addressline2, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[0].lane2
                    ? profileData.contact_details[0].lane2
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.landmark, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[0].landmark
                    ? profileData.contact_details[0].landmark
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.state, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[0].state_name
                    ? profileData.contact_details[0].state_name
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.city, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[0].city_name
                    ? profileData.contact_details[0].city_name
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.country, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[0].country_name
                    ? profileData.contact_details[0].country_name
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.pincode, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[0].pincode
                    ? profileData.contact_details[0].pincode
                    : emptyDetails }),
            ' ',
            React.createElement(Grid_1.default, { item: true, xs: 9 },
                React.createElement("div", { className: "user-profile-wrapper" },
                    React.createElement("small", { className: "user-label" }, "Office Certificate"),
                    React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } },
                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" }, addressProfDoc && addressProfDocURL ? (React.createElement(React.Fragment, null,
                            React.createElement("img", { src: addressProfDoc, alt: "profile" }),
                            React.createElement("div", { className: "download-btn" },
                                React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(addressProfDocURL), download: true })),
                            React.createElement("div", { className: "image-title" }, addressProfDocName))) : ('No document uploaded')))))));
    };
    var vendorRegisteredaddressDetails = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.addressline1, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[1].lane1
                    ? profileData.contact_details[1].lane1
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.addressline2, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[1].lane2
                    ? profileData.contact_details[1].lane2
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.landmark, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[1].landmark
                    ? profileData.contact_details[1].landmark
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.state, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[1].state_name
                    ? profileData.contact_details[1].state_name
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.city, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[1].city_name
                    ? profileData.contact_details[1].city_name
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.country, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[1].country_name
                    ? profileData.contact_details[1].country_name
                    : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.pincode, value: profileData &&
                    profileData.contact_details.length >= 1 &&
                    profileData.contact_details[1].pincode
                    ? profileData.contact_details[1].pincode
                    : emptyDetails }),
            React.createElement(Grid_1.default, { item: true, xs: 9 },
                React.createElement("div", { className: "user-profile-wrapper" },
                    React.createElement("small", { className: "user-label" }, "Registered Certificate"),
                    React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } },
                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" }, addressProfDoc1 && addressProfDoc1URL ? (React.createElement(React.Fragment, null,
                            React.createElement("img", { src: addressProfDoc1, alt: "profile" }),
                            React.createElement("div", { className: "download-btn" },
                                React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(addressProfDoc1URL), download: true })),
                            React.createElement("div", { className: "image-title" }, addressProfDoc1Name))) : ('No document uploaded')))))));
    };
    var VendorContractDetails = function () {
        var _a, _b, _c, _d, _e;
        return (React.createElement(React.Fragment, null,
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.contract_type, value: (_a = (profileData && profileData.contract_type_name)) !== null && _a !== void 0 ? _a : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.contract_start_date, value: (_b = (profileData &&
                    (0, array_helpers_1.normaldateFormatte)(profileData.contract_start_date))) !== null && _b !== void 0 ? _b : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.contract_expiry_date, value: (_c = (profileData &&
                    (0, array_helpers_1.normaldateFormatte)(profileData.contract_expiry_date))) !== null && _c !== void 0 ? _c : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.total_sourcing_fe, value: (_d = (profileData && profileData.total_sourcing_fe)) !== null && _d !== void 0 ? _d : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.parent_contract_id, value: (_e = (profileData && profileData.parent_contract_id)) !== null && _e !== void 0 ? _e : emptyDetails }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.contract_state, value: profileData && profileData.operating_places ? (React.createElement(tool_tip_1.default, { isState: "state", stateCityData: profileData.operating_places })) : (emptyDetails) }),
            React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_DETAILS.contract_city, value: profileData && profileData.operating_places ? (React.createElement(tool_tip_1.default, { isState: "city", stateCityData: profileData.operating_places })) : (emptyDetails) }),
            React.createElement(Grid_1.default, { item: true, xs: 9 },
                React.createElement("div", { className: "user-profile-wrapper" },
                    React.createElement("small", { className: "user-label" }, "Contract Doc"),
                    React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } },
                        React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" }, contactDoc && contactDocURL ? (React.createElement(React.Fragment, null,
                            React.createElement("img", { src: contactDoc, alt: "profile" }),
                            React.createElement("div", { className: "download-btn" },
                                React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(contactDocURL), download: true })),
                            React.createElement("div", { className: "image-title" }, contactDocName))) : ('No pic uploaded')))))));
    };
    var getFEListUnderAVendor = function (endPoint, domain) {
        (0, fe_services_1.fetchVendorFEList)(endPoint, domain)
            .then(function (response) {
            setFedata(response);
            setErrormsg("");
            if (response.avatar != null &&
                response.avatar != undefined &&
                response.avatar != '') {
                (0, generic_api_calls_1.fetchImnageURLFromName)(response.avatar).then(function (res) {
                    console.log(res, 'resresres');
                    setFEProfileImageURL(res.data.data.url);
                });
            }
        })
            .catch(function (err) {
            setErrormsg(err.message);
        });
    };
    React.useEffect(function () {
        fetchProfileList("".concat(api_urls_1.api_urls.VENDOR_PROFILE, "?axapta_code=").concat(id.id), process.env.ONE_TWO_LEVEL_APPROVAL_URL);
    }, []);
    return (React.createElement(React.Fragment, null, errorMessage ? (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString())) : (React.createElement("div", { className: "vendor-profile-details fe-wrapper page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: clickHandler }),
                React.createElement("h2", null, "Vendor Details")),
            (profileData &&
                profileData.onboard_status == appConstants_1.VENDOR_STATUS.levelOne) ||
                (profileData &&
                    profileData.onboard_status == appConstants_1.VENDOR_STATUS.levelTwo) ? (React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(alert_modal_1.default, { alertIcon: 'icon-info', modalTitle: "Are you sure  you want to approve ".concat(profileData && profileData.name, "?"), buttonName: "APPROVE", buttonColor: "yellow-outline", endPoint: levelOneDetails
                        ? api_urls_1.api_urls.VENODR_LEVEL_ONE_APPROVAL
                        : api_urls_1.api_urls.VENDOR_LEVEL_TWO_APPROVAL, payLoad: vendorLevelOneApprvoa, domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL, onClickHandler: function () {
                        return (0, draft_vendors_1.deleteDraftedVednor)(profileData && profileData.id);
                    }, onCloseHandler: draft_vendors_1.colseVendorModalHandler, isBtnDisabled: levelOneDetails
                        ? features[menu_constants_1.FEATURE_CONSTANTS.vendorLevelOneApprove]
                        : features[menu_constants_1.FEATURE_CONSTANTS.vendorLevelTwoApprove] }),
                React.createElement(reject_user_1.default, { buttonName: "REJECT", user_id: profileData && profileData.id, fe_name: profileData && profileData.name, fe_id: profileData.axapta_code, endPoint: api_urls_1.api_urls.VENDOR_REJECT, domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL, isBtnDisabled: levelOneDetails
                        ? !features[menu_constants_1.FEATURE_CONSTANTS.vendorLevelOneReject]
                        : !features[menu_constants_1.FEATURE_CONSTANTS.vendorLevelTwoReject] }))) : (React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Edit Profile", color: "yellow-outline", isError: !vendorFeatures[menu_constants_1.FEATURE_CONSTANTS.vendorEdit], onClickHandler: function () {
                        return nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.editVendor, "/").concat(profileData && profileData.axapta_code, "/").concat(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.PERSONALDETAILS));
                    } }),
                React.createElement(alert_modal_1.default, { alertIcon: 'icon-info', modalTitle: "Are you sure  you want to exit ".concat(profileData && profileData.name, "?"), buttonName: "Exit Vendor", isBtnDisabled: vendorFeatures[menu_constants_1.FEATURE_CONSTANTS.vendorexit], buttonColor: "error-btn", endPoint: api_urls_1.api_urls.EXIT_VEDNOR, payLoad: exitVendorPayload, domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL, onClickHandler: function () {
                        return (0, draft_vendors_1.deleteDraftedVednor)(profileData && profileData.id);
                    }, onCloseHandler: draft_vendors_1.colseVendorModalHandler })))),
        React.createElement(Grid_1.default, { container: true, xs: 12, className: "details-tab-wrapper" },
            React.createElement(Grid_1.default, { item: true, xs: 8 },
                React.createElement("div", { className: "details-wrapper vendor-details-wrapper" },
                    React.createElement("div", { className: "user-card displayflex  " },
                        React.createElement("div", { className: "user-details-wrapper displayflex " },
                            React.createElement("div", { className: "avatar-wrapper" },
                                React.createElement(Avatar_1.default, { src: profileImageURL })),
                            React.createElement("div", { className: "user-name-wrapper" },
                                React.createElement("p", { className: "user-name" }, profileData === null || profileData === void 0 ? void 0 : profileData.name),
                                React.createElement("span", { className: "user-id" }, profileData &&
                                    profileData.contact_details.length >= 1 &&
                                    profileData.contact_details[1].lane1
                                    ? profileData.contact_details[1].lane1
                                    : emptyDetails))),
                        React.createElement(status_buttons_1.default, { status: profileData && profileData.onboard_status })),
                    React.createElement("div", { className: "vendor-deatils-tab" },
                        React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 18 } },
                            vendorDetails(),
                            React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 18 },
                                React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                                    React.createElement("h2", null, profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_HEADINGS[1]))),
                            vendorContactDetails(),
                            React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 18 },
                                React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                                    React.createElement("h2", null, profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_HEADINGS[3]))),
                            vendorOfficeAddressDetails(),
                            React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 18 },
                                React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                                    React.createElement("h2", null, profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_HEADINGS[4]))),
                            vendorRegisteredaddressDetails(),
                            React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 18 },
                                React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                                    React.createElement("h2", null, profile_detaile_lable_1.PROFILE_DETAILE_LABLES.VENDOR_PROFILE_HEADINGS[5]))),
                            VendorContractDetails())))),
            React.createElement(Grid_1.default, { item: true, xs: 4 },
                React.createElement("div", { className: "fe-list-conatainer" },
                    React.createElement("h2", { className: "heaidng" }, "List Of Active FE'S"),
                    React.createElement("div", { className: "fe-list-wrapper" }, feData && feData.length > 0 ? (feData === null || feData === void 0 ? void 0 : feData.map(function (data) {
                        console.log(data.avatar, 'jhgf');
                        return (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "fe-list-item" },
                                React.createElement(Avatar_1.default, { src: profileFeImageURL, alt: "fe_list" }),
                                React.createElement("div", { className: "fe-name-conatct" },
                                    React.createElement("div", { className: "user-name" }, data === null || data === void 0 ? void 0 :
                                        data.first_name,
                                        "_", data === null || data === void 0 ? void 0 :
                                        data.last_name),
                                    React.createElement("span", { className: "user-conatct" }, data === null || data === void 0 ? void 0 : data.mobile)))));
                    })) : (React.createElement("div", { className: "no-data-found" },
                        React.createElement("p", null, "No Data Found")))))))))));
};
exports.default = VendorProfileDetails;
