"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var material_1 = require("@mui/material");
var react_router_1 = require("react-router");
var orc_service_1 = require("../orc_service");
var route_path_1 = require("../../../../../constants/route_path");
var AGgrid_table_component_1 = require("../../../../../common/tables/AGgrid_table_component");
var delete_confirmation_modal_1 = require("../../../../../common/confirmation/delete_confirmation_modal");
var orc_api_service_1 = require("../orc_api_service");
var api_service_1 = require("../../../../../api/api_service");
var react_query_1 = require("react-query");
var table_data_1 = require("../../../../../constants/table_data");
var react_paginate_1 = require("../../../../../common/react_pagination/react_paginate");
var react_hot_toast_1 = require("react-hot-toast");
var array_helpers_1 = require("../../../../../utils/array_helpers");
var menu_constants_1 = require("../../../../../menuList/menu_constants");
var ModalComponentWithoutButton_1 = require("../../../../../common/modal/ModalComponentWithoutButton");
var formik_1 = require("formik");
var schema_1 = require("../../../../../constants/schema");
var drop_down_component_1 = require("../../../../../common/drop_down/drop_down_component");
var text_filed_component_1 = require("../../../../../common/text_filed/text_filed_component");
var button_component_1 = require("../../../../../common/buttons/button_component");
var react_redux_1 = require("react-redux");
var ORCActiveList = function (_a) {
    var searchTerm = _a.searchTerm, isFilterButton = _a.isFilterButton, PageLimit = _a.PageLimit;
    var nav = (0, react_router_1.useNavigate)();
    var orc_deactivation_reasons = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.orc_deactivation_reasons; });
    var _b = React.useState(false), deleteConfirModal = _b[0], setDeleteConfirModal = _b[1];
    var _c = React.useState(undefined), orcList = _c[0], setOrcList = _c[1];
    var _d = React.useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = React.useState(1), pageCount = _e[0], setpageCount = _e[1];
    var _f = React.useState(0), totalPageCount = _f[0], setTotalPageCount = _f[1];
    var _g = React.useState(0), totalCount = _g[0], setTotalCount = _g[1];
    var _h = React.useState(null), selectedOCRObj = _h[0], setSelectedOCRObj = _h[1];
    var _j = React.useState(false), realoadList = _j[0], setRealoadList = _j[1];
    var _k = React.useState(false), deactiveConfirModal = _k[0], setdeactiveConfirModal = _k[1];
    var _l = React.useState(false), activeConfirModal = _l[0], setActiveConfirModal = _l[1];
    var initialValues = {
        reject_type: null,
        reject_comment: '',
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.ORC_Management);
    var deleteAndDeactiveOpenHandlere = function (event) {
        var evenType = event.event.target.className;
        var data = event.data;
        setSelectedOCRObj(data);
        if (evenType.includes('PrivateSwitchBase')) {
            if (data && data.status == orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE.toString()) {
                setdeactiveConfirModal(true);
            }
            else {
                setActiveConfirModal(true);
            }
        }
        else if (evenType.includes('icon-delete_forever')) {
            setDeleteConfirModal(true);
        }
        else if (evenType.includes('icon-Vector')) {
            nav("/".concat(route_path_1.default.PATHS.orc_listing, "/").concat(route_path_1.default.PATHS.orc_profile_details, "/").concat(data.primary_client));
        }
    };
    var updateORCListService = function (payload, isModalOpened, actionType) {
        (0, orc_api_service_1.updateORCList)(api_service_1.api_service.api_urls.UPDATE_ORC, payload, process.env.APPROVAL_BASEURL).then(function (res) {
            if (actionType == orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED) {
                react_hot_toast_1.default.success(res.message);
                setRealoadList(!realoadList);
                setDeleteConfirModal(isModalOpened);
            }
            else {
                if (actionType == orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE) {
                    setActiveConfirModal(isModalOpened);
                }
                else {
                    setdeactiveConfirModal(isModalOpened);
                }
                react_hot_toast_1.default.success(res.message);
                setRealoadList(!realoadList);
            }
        }).catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var deactivateCloseHandlere = function () {
        setdeactiveConfirModal(false);
    };
    var activateCloseHandlere = function () {
        setActiveConfirModal(false);
    };
    var deactivateSubmitHandlere = function (values) {
        var payload = {
            primary_client_id: selectedOCRObj ? selectedOCRObj.center_id : 0,
            status: orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE,
            inactive_reason: "".concat(values.reject_type.label, " -").concat(values.reject_comment)
        };
        updateORCListService(payload, false, orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE);
    };
    var activateSubmitHandlere = function (isModalOpened) {
        var payload = {
            primary_client_id: selectedOCRObj ? selectedOCRObj.center_id : 0,
            status: orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE
        };
        updateORCListService(payload, isModalOpened, orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE);
    };
    var deleteSubmitHandlere = function (isModalOpened) {
        var payload = {
            primary_client_id: selectedOCRObj ? selectedOCRObj.center_id : 0,
            status: orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED
        };
        updateORCListService(payload, isModalOpened, orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED);
    };
    var deleteCloseHandlere = function (isModalOpened) {
        setDeleteConfirModal(isModalOpened);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var getORCList = function (endPoint, payLoad, domain) {
        (0, orc_api_service_1.fetchORCList)(endPoint, payLoad, domain)
            .then(function (response) {
            setErrorMessage("");
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            var convertedData = (0, orc_service_1.convertORCListingAPIResponse)(response.result);
            setOrcList(convertedData);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setOrcList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['orc-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {
            search_string: searchTerm,
            page: currentPage,
            size: PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50,
            status: "".concat(orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE, ",").concat(orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE)
        };
        getORCList(api_service_1.api_service.api_urls.ORC_LISTING, payload, process.env.APPROVAL_BASEURL);
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [isFilterButton, realoadList]);
    return (React.createElement("div", { className: "unclickablerow custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ORC_ACTIVE_DEACTIVE_LIST_COLUMNS, rows: !features[menu_constants_1.FEATURE_CONSTANTS.ACTIVATE_DEACTIVATE_LIST] ? orcList : [], pagination: false, errorMessage: !features[menu_constants_1.FEATURE_CONSTANTS.ACTIVATE_DEACTIVATE_LIST] ? errorMessage : 'Do not have this feature accesss', onRowClicked: deleteAndDeactiveOpenHandlere }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: orcList === null || orcList === void 0 ? void 0 : orcList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }),
        React.createElement(ModalComponentWithoutButton_1.default, { open: deactiveConfirModal },
            React.createElement("div", null,
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("h2", { className: "heading" }, "Are you sure  you want to Deactivate ".concat(selectedOCRObj && selectedOCRObj.primary_client ? selectedOCRObj.primary_client : '--', " warehouse code")))),
            React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, validationSchema: schema_1.schemas.LEVEL_ONE_REJECT_SCHEMA, onSubmit: deactivateSubmitHandlere }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldTouched = _a.setFieldTouched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement(material_1.Grid, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                        React.createElement(material_1.Grid, { item: true, xs: 6 },
                            React.createElement(drop_down_component_1.default, { name: "reject_type", placeholder: "Reason", dropDownList: orc_deactivation_reasons, onChange: function (val) {
                                    setFieldValue('reject_type', val);
                                    setFieldTouched('reject_type', true);
                                }, value: values.reject_type }),
                            errors['reject_type'] && touched['reject_type'] ? (React.createElement("div", { className: "error" }, errors['reject_type'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 12 },
                            React.createElement(text_filed_component_1.default, { name: "reject_comment", maxLength: 200, onChange: function (reject_comment) {
                                    setFieldTouched('reject_comment');
                                    setFieldValue('reject_comment', reject_comment);
                                }, label: "Other Comments", value: values.reject_comment }),
                            errors['reject_comment'] && touched['reject_comment'] ? (React.createElement("div", { className: "error" }, errors['reject_comment'])) : null)),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: deactivateCloseHandlere }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
            })),
        React.createElement(delete_confirmation_modal_1.default, { deleteConfirModal: activeConfirModal, deleteOpeneHandlere: activateSubmitHandlere, deleteCloseHandlere: activateCloseHandlere, mainTitle: "Confirmation", subTitle: "Are you sure  you want to Activate ".concat(selectedOCRObj && selectedOCRObj.primary_client ? selectedOCRObj.primary_client : '--', " warehouse code") }),
        React.createElement(delete_confirmation_modal_1.default, { deleteConfirModal: deleteConfirModal, deleteOpeneHandlere: deleteSubmitHandlere, deleteCloseHandlere: deleteCloseHandlere, mainTitle: "Confirmation", subTitle: "Are you sure  you want to delete ".concat(selectedOCRObj && selectedOCRObj.primary_client ? selectedOCRObj.primary_client : '', " warehouse code") })));
};
exports.default = ORCActiveList;
