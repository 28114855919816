"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Box_1 = require("@mui/material/Box");
var moment = require("moment");
var React = require("react");
var react_redux_1 = require("react-redux");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var array_helpers_1 = require("../../../utils/array_helpers");
var leavetracking_apicalls_1 = require("../../field_exicutive/leave_tracking/leavetracking.apicalls");
var r_lab_to_p_lab_graph_1 = require("../graphs/r_lab_to_p_lab_graph");
var RlabPlabFilter = function () {
    var _a = React.useState(), updatedTime = _a[0], setUpdatedTIme = _a[1];
    var _b = React.useState([]), filterSelectedStates = _b[0], setSelectedFilterStates = _b[1];
    var _c = React.useState([]), citiesData = _c[0], setCitiesData = _c[1];
    var _d = React.useState([]), filterSelectedCities = _d[0], setSelectedFilterCities = _d[1];
    var _e = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }), labList = _e.labList, stateList = _e.stateList;
    var _f = React.useState(new Date().toString()), startDate = _f[0], setStartDate = _f[1];
    var _g = React.useState(false), isDateRangeUpdated = _g[0], setIsDateRangeUpdated = _g[1];
    var _h = React.useState(new Date().toString()), endDate = _h[0], setEndDate = _h[1];
    var _j = React.useState([]), r_lab = _j[0], setRLab = _j[1];
    var _k = React.useState([]), p_lab = _k[0], setP_lab = _k[1];
    var _l = React.useState(false), filterButton = _l[0], setFilterButton = _l[1];
    var rLabeHandlere = function (selectedValues) {
        setRLab(selectedValues);
    };
    var p_labeHandlere = function (selectedValues) {
        setP_lab(selectedValues);
    };
    var fetchCities = function () { return __awaiter(void 0, void 0, void 0, function () {
        var stateIdArray, payloadToFetchCities;
        return __generator(this, function (_a) {
            stateIdArray = filterSelectedStates.map(function (state) { return state.id; });
            payloadToFetchCities = {
                state_id: stateIdArray,
            };
            (0, leavetracking_apicalls_1.fetchCitiesList)(payloadToFetchCities)
                .then(function (citiesListResponse) {
                var formattedCitiesList = (0, array_helpers_1.convertLabelAndValueCity)(citiesListResponse.cities);
                setCitiesData(formattedCitiesList);
            })
                .catch(function (e) {
                setCitiesData([]);
            });
            return [2];
        });
    }); };
    var callbackUpdateSelectedStates = function (value) {
        setSelectedFilterStates(value);
        setCitiesData([]);
        setSelectedFilterCities([]);
    };
    var callbackUpdateSelectedCities = function (value) {
        setSelectedFilterCities(value);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        console.log(dateRangeArray, 'dateRangeArray');
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    React.useEffect(function () {
        if (filterSelectedStates.length >= 1) {
            fetchCities();
        }
    }, [filterSelectedStates]);
    var FilterHandler = function () {
        setFilterButton(!filterButton);
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Intercity R Lab to P Lab"))),
        React.createElement("div", { className: "orderlist-main-wrapper" },
            React.createElement("div", { className: "filter-menu-wrapper border-bottom" },
                React.createElement("div", { className: "filter-options auto-route" },
                    React.createElement(Box_1.default, { mr: 1 },
                        React.createElement(text_filed_with_fromto_Daterange_1.default, { presentDate: true, onChangeHandler: callBackOnDateRangeSelect })),
                    React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "R-Lab", dropDownList: labList &&
                            (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                                return a.label.localeCompare(b.label);
                            }), onChangeHnadlre: rLabeHandlere, value: r_lab }),
                    React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "P-Lab", dropDownList: labList &&
                            (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                                return a.label.localeCompare(b.label);
                            }), onChangeHnadlre: p_labeHandlere, value: p_lab }),
                    ' ',
                    React.createElement(multi_selector_1.default, { label: "Select State", dropDownList: stateList && stateList.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: callbackUpdateSelectedStates, value: filterSelectedStates }),
                    React.createElement(multi_selector_1.default, { noOptions: 'No cities found', hasSelectAll: false, label: "Select City", dropDownList: citiesData && citiesData.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: callbackUpdateSelectedCities, value: filterSelectedCities || [] })),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }),
                    React.createElement(button_component_1.default, { buttonName: "Refresh", color: "yellow", endIcon: "icon-refresh", onClickHandler: function () { return setFilterButton(!filterButton); } }))),
            React.createElement(r_lab_to_p_lab_graph_1.default, { isDateRangeUpdated: isDateRangeUpdated, startDate: startDate, endDate: endDate, labList: labList, setUpdatedTIme: setUpdatedTIme, filterSelectedCities: filterSelectedCities, rLab: r_lab, Plab: p_lab, updatedTime: updatedTime, filterButton: filterButton }))));
};
exports.default = RlabPlabFilter;
