"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var maps_api_for_javascript_1 = require("@here/maps-api-for-javascript");
var material_1 = require("@mui/material");
var React = require("react");
var react_toastify_1 = require("react-toastify");
var map_tool_tip_1 = require("../../../common/tool_tip/map_tool_tip");
var images_1 = require("../../../constants/images");
var map_constants_1 = require("../../../constants/map_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var heat_map_api_service_1 = require("../../super_admin/heat_maps/heat_map_api_service");
var fe_services_1 = require("../fe_services");
var visitCount = 0;
var MapDetails = function (_a) {
    var errorMessage = _a.errorMessage, visitInfo = _a.visitInfo, setRoundColors = _a.setRoundColors, feCuurentLatLng = _a.feCuurentLatLng;
    var mapRef = React.useRef(null);
    var _b = React.useState(false), showToolTip = _b[0], setShowToolTip = _b[1];
    var _c = React.useState(), toolTipInfo = _c[0], setTooTipInfo = _c[1];
    var _d = React.useState([]), roundinfoColors = _d[0], setRoundInfoColors = _d[1];
    var filterRounds = (0, array_helpers_1.removeDuplicatevalues)(roundinfoColors);
    console.log(filterRounds, 'roundinfoColors,', roundinfoColors, feCuurentLatLng);
    var markerInformation = function (pointeInfo) {
        console.log(pointeInfo, 'pointeInfo');
        var codeTYpe = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
            ? "".concat(map_constants_1.VISIT_TYPES.fe_code)
            : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP
                ? "".concat(map_constants_1.VISIT_TYPES.cc_code)
                : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB
                    ? "".concat(map_constants_1.VISIT_TYPES.hub_code)
                    : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB
                        ? "".concat(map_constants_1.VISIT_TYPES.lab_Code)
                        : "".concat(map_constants_1.VISIT_TYPES.hub_cash_code);
        return "<div classname='marker-info'><h3>".concat(codeTYpe, " Information</h3><hp>FE_CODE:").concat(pointeInfo.fe_id, "</hp><p>Latitude:").concat((0, array_helpers_1.isValueEmpty)(pointeInfo.latitude), "</p><p>Langitude:").concat((0, array_helpers_1.isValueEmpty)(pointeInfo.longitude), "</p><p>").concat(pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
            ? "FE Location:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo.center_address))
            : "Center Name:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo.center_name)), "</p>\n    ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
            ? "<p>Warehouse Code:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo.warehouse_code), "</p>")
            : '', "\n    ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
            ? "<p>Invoice Code:".concat((0, array_helpers_1.isValueEmpty)(pointeInfo.invoice_code), "</p>")
            : '', "\n       ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
            ? "<p>\n             Slots:[".concat(pointeInfo.center_info.scheduled_time_slots
                ? pointeInfo.center_info.scheduled_time_slots.join(',')
                : '', "]</p>")
            : '', "\n</div>");
    };
    var visitsInfo = function (vistList, mappedList, index) {
        if (vistList[index].visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB) {
            visitCount = visitCount + 1;
        }
        else if (vistList[index].visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB) {
            if (vistList[index - 1].visit_type != map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB) {
                visitCount = visitCount + 1;
            }
        }
        else if (vistList[index].visit_type == map_constants_1.VIST_TYPE_INFO.CASH_HANDOVER) {
            if (vistList[index - 1].visit_type != map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB &&
                vistList[index - 2].visit_type != map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB) {
                visitCount = visitCount + 1;
            }
        }
    };
    var openInfoBubble = function (popupInfo, coord, ui, distaseValue, timeValue, isOpned) {
        setShowToolTip(true);
        var distase = Math.round(distaseValue * 10) / 10;
        var time = Math.round(timeValue * 10) / 10;
        var splitTime = time.toString().split('.')[0];
        var hours = Math.floor(parseInt(splitTime) / 60);
        var minutes = parseInt(splitTime) % 60;
        var tooTipInformation = {
            distanse: distase,
            hour: hours,
            minutes: minutes,
            startLocation: popupInfo.startLocation,
            endLocation: popupInfo.endLocation,
            feName: popupInfo.feName,
            fe_id: popupInfo.fe_id,
            id: popupInfo.id,
            scheduled_time: popupInfo.scheduled_time,
            visit_type: popupInfo.visit_type,
        };
        setTooTipInfo(tooTipInformation);
    };
    var generarePolyline = function (map, ui, visitsData) {
        var numOfRounds = ['#FF6633'];
        var lab_marker = new maps_api_for_javascript_1.default.map.Icon(images_1.default.labIcon, map_constants_1.MPA_ICONS_SIZE);
        var cc_marker = new maps_api_for_javascript_1.default.map.Icon(images_1.default.ccIcon, map_constants_1.MPA_ICONS_SIZE);
        var hub_marker = new maps_api_for_javascript_1.default.map.Icon(images_1.default.hubIcon, map_constants_1.MPA_ICONS_SIZE);
        var FE_startMarker = new maps_api_for_javascript_1.default.map.Icon(images_1.default.yellow_map_icon, {
            size: { w: 36, h: 36 },
        });
        var group = new maps_api_for_javascript_1.default.map.Group();
        var markerGroup = [];
        var styleLine;
        var startLatLng = {
            lat: visitsData[0].latitude,
            lng: visitsData[0].longitude,
        };
        var count = 0;
        visitsData.map(function (element, index) {
            var endLatLng = {
                lat: visitsData[index].latitude,
                lng: visitsData[index].longitude,
            };
            try {
                var lat = element.latitude;
                var lng = element.longitude;
                var lineString = new maps_api_for_javascript_1.default.geo.LineString();
                lineString.pushPoint(startLatLng);
                lineString.pushPoint({ lat: lat, lng: lng });
                if (index > 0 &&
                    (visitsData[index - 1].visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB ||
                        visitsData[index - 1].visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ||
                        visitsData[index - 1].visit_type == map_constants_1.VIST_TYPE_INFO.CASH_HANDOVER) &&
                    element.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP) {
                    count++;
                    numOfRounds.push(map_constants_1.colorCodes[count]);
                }
                styleLine = {
                    style: {
                        strokeColor: map_constants_1.colorCodes[count],
                        lineWidth: 5,
                        fillColor: 'white',
                        lineTailCap: 'arrow-tail',
                        lineDash: [1, 1],
                        lineHeadCap: 'arrow-head',
                    },
                };
                var myLocMarker = new maps_api_for_javascript_1.default.map.Marker({
                    lat: Number(lat),
                    lng: Number(lng),
                });
                myLocMarker.setIcon(element.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP
                    ? cc_marker
                    : element.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB
                        ? hub_marker
                        : element.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ||
                            element.visit_type == map_constants_1.VIST_TYPE_INFO.CASH_HANDOVER
                            ? lab_marker
                            : FE_startMarker);
                myLocMarker.addEventListener('tap', function (evt) {
                    var bubble = new maps_api_for_javascript_1.default.ui.InfoBubble(evt.target.getGeometry(), {
                        content: (0, fe_services_1.feManagementMarkeDetails)(element),
                    });
                    ui.addBubble(bubble);
                }, false);
                markerGroup.push(myLocMarker);
                group.addObjects(__spreadArray([], markerGroup, true));
                map.addObject(group);
                map.addObject(new maps_api_for_javascript_1.default.map.Polyline(lineString, styleLine));
                startLatLng = endLatLng;
            }
            catch (e) {
                console.log(e);
            }
        });
        setRoundInfoColors(numOfRounds);
    };
    var calculateRouteMethod = function (visites, map, ui, platform) {
        setRoundInfoColors([]);
        var onResult = function (result, routeObj, ui, color, startEndInfo) {
            var distase = result.routes[0].sections[0].summary.length / 1000;
            var time = result.routes[0].sections[0].summary.baseDuration / 60;
            if (result.routes.length) {
                result.routes[0].sections.forEach(function (section) {
                    var linestring = maps_api_for_javascript_1.default.geo.LineString.fromFlexiblePolyline(section.polyline);
                    console.log(color, 'color');
                    setRoundInfoColors(function (prev) { return __spreadArray(__spreadArray([], prev, true), ["".concat(color)], false); });
                    var styleLine = {
                        style: {
                            lineWidth: 6,
                            fillColor: 'white',
                            strokeColor: "".concat(color),
                            lineTailCap: 'arrow-tail',
                            lineDash: [1, 1],
                            lineHeadCap: 'arrow-head',
                        },
                        data: {
                            id: routeObj.visit_id,
                            feName: routeObj.fe_first_name,
                            lat: routeObj.latitude,
                            lng: routeObj.longitude,
                            scheduled_time: routeObj.scheduled_start_time,
                            fe_id: routeObj.fe_id,
                            visit_type: routeObj.visit_type,
                            center_name: routeObj.center_name,
                            startLocation: startEndInfo.startPointInfo.visit_type == 0
                                ? routeObj.center_address
                                : startEndInfo.startPointInfo.center_name,
                            endLocation: startEndInfo.endPointInfo.center_name,
                        },
                    };
                    var lab_marker = new maps_api_for_javascript_1.default.map.Icon(images_1.default.labIcon, map_constants_1.MPA_ICONS_SIZE);
                    var cc_marker = new maps_api_for_javascript_1.default.map.Icon(images_1.default.ccIcon, map_constants_1.MPA_ICONS_SIZE);
                    var hub_marker = new maps_api_for_javascript_1.default.map.Icon(images_1.default.hubIcon, map_constants_1.MPA_ICONS_SIZE);
                    var FE_startMarker = new maps_api_for_javascript_1.default.map.Icon(images_1.default.yellow_map_icon, {
                        size: { w: 36, h: 36 },
                    });
                    var routeLine = new maps_api_for_javascript_1.default.map.Polyline(linestring, styleLine);
                    routeLine.addEventListener('tap', function (e) {
                        var data = e.target.data;
                        var coord = map.screenToGeo(e.currentPointer.viewportX, e.currentPointer.viewportY);
                        openInfoBubble(data, coord, ui, distase, time);
                    });
                    var startMarker = new maps_api_for_javascript_1.default.map.Marker(section.departure.place.location);
                    startMarker.addEventListener('tap', function (evt) {
                        console.log(startEndInfo.startPointInfo, 'startEndInfo');
                        var bubble = new maps_api_for_javascript_1.default.ui.InfoBubble(evt.target.getGeometry(), {
                            content: markerInformation(startEndInfo.startPointInfo),
                        });
                        ui.addBubble(bubble);
                    }, false);
                    startMarker.setIcon(startEndInfo.startPointInfo.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP
                        ? cc_marker
                        : startEndInfo.startPointInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB
                            ? hub_marker
                            : startEndInfo.startPointInfo.visit_type ==
                                map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ||
                                startEndInfo.startPointInfo.visit_type == map_constants_1.VIST_TYPE_INFO.CASH_HANDOVER
                                ? lab_marker
                                : FE_startMarker);
                    var endMarker = new maps_api_for_javascript_1.default.map.Marker(section.arrival.place.location);
                    endMarker.addEventListener('tap', function (evt) {
                        console.log(startEndInfo.endPointInfo, 'startEndInfo.endPointInfo');
                        var bubble = new maps_api_for_javascript_1.default.ui.InfoBubble(evt.target.getGeometry(), {
                            content: markerInformation(startEndInfo.endPointInfo),
                        });
                        ui.addBubble(bubble);
                    }, false);
                    endMarker.setIcon(startEndInfo.endPointInfo.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP
                        ? cc_marker
                        : startEndInfo.endPointInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_HUB
                            ? hub_marker
                            : startEndInfo.endPointInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB ||
                                startEndInfo.endPointInfo.visit_type == map_constants_1.VIST_TYPE_INFO.CASH_HANDOVER
                                ? lab_marker
                                : FE_startMarker);
                    map.addObjects([routeLine, startMarker, endMarker]);
                });
            }
        };
        console.log(visites, 'visites');
        try {
            visites &&
                visites.map(function (element, index) {
                    var routingParameters = {
                        routingMode: 'fast',
                        transportMode: 'scooter',
                        origin: index == visites.length - 1
                            ? "".concat(visites[index - 1].latitude, ",").concat(visites[index - 1].longitude)
                            : "".concat(element.latitude, ",").concat(element.longitude),
                        destination: index == visites.length - 1
                            ? "".concat(element.latitude, ",").concat(element.longitude)
                            : "".concat(visites[index + 1].latitude, ",").concat(visites[index + 1].longitude),
                        return: ['polyline', 'summary'],
                    };
                    var startEndobj = {
                        startPointInfo: index == visites.length - 1 ? visites[index - 1] : element,
                        endPointInfo: index == visites.length - 1 ? element : visites[index + 1],
                    };
                    visitsInfo(visites, element, index);
                    var colorCode = map_constants_1.colorCodes[visitCount];
                    console.log(visitCount, 'visitCount');
                    var router = platform.getRoutingService(null, 8);
                    console.log(index, 'index', element);
                    router.calculateRoute(routingParameters, function (result) {
                        console.log(index, 'list', element);
                        onResult(result, element, ui, colorCode, startEndobj);
                    }, function (error) {
                        react_toastify_1.toast.error(error.message);
                    });
                });
        }
        catch (err) {
            console.log(err, 'error');
        }
    };
    var markFE_currentLocation = function (map) {
        if ((feCuurentLatLng === null || feCuurentLatLng === void 0 ? void 0 : feCuurentLatLng.current_latitude) && (feCuurentLatLng === null || feCuurentLatLng === void 0 ? void 0 : feCuurentLatLng.current_longitude)) {
            var londonMarker = new maps_api_for_javascript_1.default.map.Marker({
                lat: Number(feCuurentLatLng.current_latitude),
                lng: Number(feCuurentLatLng.current_longitude),
            });
            londonMarker.setIcon(heat_map_api_service_1.FE_CURRENT_LOCATION_MARKER);
            map.addObject(londonMarker);
            map.setCenter({
                lat: Number(feCuurentLatLng.current_latitude),
                lng: Number(feCuurentLatLng.current_longitude),
            });
            map.setZoom(17);
        }
    };
    var setStyle = function (map) {
        var provider = map.getBaseLayer().getProvider();
        var style = new maps_api_for_javascript_1.default.map.Style('/fonts/normalDay.yaml', 'https://js.api.here.com/v3/3.1.58.0/styles/omv/omv/');
        provider.setStyle(style);
    };
    React.useLayoutEffect(function () {
        if (!mapRef.current)
            return;
        var platform = new maps_api_for_javascript_1.default.service.Platform({
            apikey: process.env.HERE_MAPS_API_KEY,
        });
        var defaultLayers = platform.createDefaultLayers();
        var map = new maps_api_for_javascript_1.default.Map(mapRef.current, defaultLayers.vector.normal.map.setMin(2), {
            center: { lat: 23.6913, lng: 85.2722 },
            zoom: 5,
            pixelRatio: window.devicePixelRatio || 1,
        });
        setStyle(map);
        var behavior = new maps_api_for_javascript_1.default.mapevents.Behavior(new maps_api_for_javascript_1.default.mapevents.MapEvents(map));
        var ui = maps_api_for_javascript_1.default.ui.UI.createDefault(map, defaultLayers);
        ui.getElement().style.zIndex = 999;
        console.log(ui, behavior);
        if (visitInfo && visitInfo.length > 1) {
            generarePolyline(map, ui, visitInfo);
            markFE_currentLocation(map);
        }
        if (false) {
            calculateRouteMethod;
        }
        return function () {
            map.dispose();
        };
    }, [mapRef, visitInfo]);
    console.log(visitInfo, 'visitInfo');
    React.useEffect(function () {
        setRoundColors(filterRounds && filterRounds);
    }, [roundinfoColors]);
    return !errorMessage && visitInfo && visitInfo.length > 1 ? (React.createElement("div", { ref: mapRef, className: "profile-map-container" },
        React.createElement(material_1.Modal, { open: showToolTip, onClose: function () { return setShowToolTip(false); }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement(material_1.Box, { className: " modal-map-tooltip" },
                React.createElement(map_tool_tip_1.default, { tooTipInformation: toolTipInfo }))))) : (React.createElement("div", { className: "server-error" }, errorMessage ? errorMessage.toString() : 'No Data Available'));
};
exports.default = MapDetails;
