"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var polyline_heatmap_1 = require("./polyline_heatmap");
var PolylineMap = function (_a) {
    var errorMessage = _a.errorMessage, setRoundColors = _a.setRoundColors, selectedFEId = _a.selectedFEId, assignedVisitData = _a.assignedVisitData, unassignedVisitData = _a.unassignedVisitData, filterRounds = _a.filterRounds, rootType = _a.rootType;
    return (React.createElement("div", null,
        React.createElement(polyline_heatmap_1.default, { errorMessage: errorMessage, setRoundColors: setRoundColors, selectedFEId: selectedFEId, assignedVisitData: assignedVisitData, unassignedVisitData: unassignedVisitData, filterRounds: filterRounds, rootType: rootType })));
};
exports.default = PolylineMap;
