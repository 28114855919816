"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var TextAreaComponent = function (_a) {
    var minRows = _a.minRows, placeholder = _a.placeholder, style = _a.style, value = _a.value, onChange = _a.onChange, name = _a.name, className = _a.className, required = _a.required;
    var _b = React.useState(''), textFieldval = _b[0], setTextFieldVal = _b[1];
    React.useEffect(function () {
        setTextFieldVal(value);
    }, [value]);
    return (React.createElement("div", { className: required ? 'input-wrapper validate-filed ' : 'input-wrapper' },
        React.createElement(material_1.TextareaAutosize, { className: className, value: textFieldval, minRows: minRows, required: required, placeholder: placeholder, onChange: function (e) { return onChange(e.target.value); } })));
};
exports.default = TextAreaComponent;
