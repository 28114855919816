"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Avatar_1 = require("@mui/material/Avatar");
var React = require("react");
var audit_helpers_1 = require("./audit_helpers");
var Stack_1 = require("@mui/material/Stack");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var react_redux_1 = require("react-redux");
var array_helpers_1 = require("../../../utils/array_helpers");
var PendingAuditee = function (_a) {
    var id = _a.id;
    var userTypeList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.center_auditees_types; });
    var _b = React.useState([]), pendingAuditDetailsList = _b[0], setPendingAuditDetailsList = _b[1];
    var _c = React.useState([]), selectedAuditeetype = _c[0], setSelectedAuditeeType = _c[1];
    var _d = React.useState([]), selectedLabCode = _d[0], setSelectedLabCode = _d[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var convertedLabList = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var pendingAuditDetails = function () {
        var payload = {
            survey_id: id,
        };
        if (selectedLabCode.length > 0) {
            payload['lab_code'] = selectedLabCode.map(function (selectedLab) { return selectedLab.value; });
        }
        if (selectedAuditeetype.length > 0) {
            payload['auditee_type'] = selectedAuditeetype.map(function (selectedAuditee) { return selectedAuditee.value; });
        }
        (0, audit_helpers_1.pendingAuditDetailsInfo)(payload)
            .then(function (response) {
            setPendingAuditDetailsList(response.data);
            console.log(response, 'reasons list');
        })
            .catch(function (err) {
            console.log(err.message);
        });
    };
    React.useEffect(function () {
        pendingAuditDetails();
    }, [selectedLabCode, selectedAuditeetype]);
    return (React.createElement("div", { className: "auditee-card" },
        React.createElement("h2", { className: "heaidng" }, "Pending Auditee"),
        React.createElement(Stack_1.default, { direction: "row", alignItems: 'center', gap: 2, marginTop: 1.5 },
            React.createElement(multi_selector_1.default, { label: "Select Auditee", dropDownList: userTypeList ? userTypeList : [], onChangeHnadlre: function (val) { return setSelectedAuditeeType(val); }, value: selectedAuditeetype, hasSelectAll: true }),
            React.createElement(multi_selector_1.default, { label: "Select Lab code", dropDownList: convertedLabList.sort(function (a, b) {
                    return a.label.localeCompare(b.label);
                }), onChangeHnadlre: function (val) { return setSelectedLabCode(val); }, value: selectedLabCode })),
        pendingAuditDetailsList.length > 0 ? (React.createElement("div", { className: "fe-list-wrapper" }, pendingAuditDetailsList
            ? pendingAuditDetailsList.map(function (each, index) {
                return (React.createElement("div", { className: "fe-list-item" },
                    React.createElement(Avatar_1.default, null, index),
                    React.createElement("div", { className: "fe-name-conatct" },
                        React.createElement("div", { className: "user-name" }, each.center_name),
                        React.createElement("span", { className: "user-conatct" }, each.warehouse_code),
                        React.createElement("span", { className: "user-conatct" }, each.lab_code))));
            })
            : '')) : (React.createElement("div", { className: "no-data-message" }, "No data available"))));
};
exports.default = PendingAuditee;
