"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOGIN_TYPE = void 0;
var user_action_1 = require("../actions/user_action");
var lodash_1 = require("../utils/lodash");
var LOGIN_TYPE;
(function (LOGIN_TYPE) {
    LOGIN_TYPE["LOGIN_BY_PASSWORD"] = "login_by_password";
    LOGIN_TYPE["LOGIN_BY_SSO"] = "login_by_sso";
})(LOGIN_TYPE = exports.LOGIN_TYPE || (exports.LOGIN_TYPE = {}));
var initialState = {
    loginUserDetails: undefined,
    jwt_token: '',
    refresh_token: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    userRole: null,
    loginStatus: false,
    sessionExpired: false,
    apiErrorMessage: '',
    otpIDToBeEntered: null,
    pageNotFound: false,
    userLoginType: LOGIN_TYPE.LOGIN_BY_PASSWORD,
    is_sso_user: false,
};
var User = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case user_action_1.default.Constants.loginStatus: {
            return __assign(__assign({}, state), { loginStatus: action.payload.loginStatus, jwt_token: action.payload.jwt_token, refresh_token: action.payload.refresh_token, vendorCode: action.payload.code, vendorName: "".concat(action.payload.firstName, " ").concat(action.payload.lastName), vendorId: action.payload.id, userLoginType: action.payload.loginType, is_sso_user: action.payload.is_sso_user });
        }
        case user_action_1.default.Constants.loginUserDetails: {
            return lodash_1.default.extend({}, state, { loginUserDetails: action.payload });
        }
        case user_action_1.default.Constants.logout: {
            return lodash_1.default.extend({}, __assign({}, initialState));
        }
        case user_action_1.default.Constants.userRole: {
            return lodash_1.default.extend({}, state, { userRole: action.payload });
        }
        case user_action_1.default.Constants.sessionExpired: {
            return lodash_1.default.extend({}, state, { sessionExpired: action.payload });
        }
        case user_action_1.default.Constants.apiErrorMessage: {
            return lodash_1.default.extend({}, state, {
                apiErrorMessage: action.payload.apiErrorMessage,
            });
        }
        case user_action_1.default.Constants.otpIdForUser: {
            return lodash_1.default.extend({}, state, {
                otpIDToBeEntered: action.payload.otpIDToBeEntered,
            });
        }
        case user_action_1.default.Constants.pageNotFound: {
            return lodash_1.default.extend({}, state, {
                pageNotFound: action.payload.pageNotFound,
            });
        }
        case user_action_1.default.Constants.logout: {
            return __assign({}, initialState);
        }
        default:
            return state;
    }
};
exports.default = User;
