"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var button_component_1 = require("../../../common/buttons/button_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var array_helpers_1 = require("../../../utils/array_helpers");
var report_dashboard_service_1 = require("../service/report_dashboard_service");
var OutStationMaterboxList = function (_a) {
    var list = _a.list, tableCoulumns = _a.tableCoulumns;
    console.log(list, 'myOUSTSTS');
    var downloadReport = function () {
        var isReceivedBox = tableCoulumns &&
            tableCoulumns.find(function (val) { return val.headerName == 'Handover Date & Time'; });
        var columns = isReceivedBox
            ? report_dashboard_service_1.downloadExcelHeadersOustStationHeaderReceived
            : report_dashboard_service_1.downloadExcelHeadersOustStationPendingHeader;
        console.log(columns, 'columns');
        var convertedData = isReceivedBox
            ? (0, report_dashboard_service_1.convertOustationDataRecieved)(list)
            : (0, report_dashboard_service_1.convertOustationDataPending)(list);
        (0, array_helpers_1.downloadExcel)(convertedData, 'OutStation_master_box.xlsx', columns, 'Out Station Master Box');
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "mid-page-heaidng" },
            React.createElement("h2", { className: "F-18" }, "Intercity Out Station Master Box"),
            React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, color: "yellow-outline", endIcon: "icon-import download-icon", isError: list && list.length > 0 ? false : true, onClickHandler: function () { return downloadReport(); } })),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: tableCoulumns, rows: list, pagination: true, enableCellTextSelection: true, className: 'bagging-lifecycle-table intracity-pagination' }))));
};
exports.default = OutStationMaterboxList;
