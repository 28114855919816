"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initReduxStore = exports.persistor = exports.store = void 0;
var redux_1 = require("redux");
var redux_devtools_extension_1 = require("redux-devtools-extension");
var redux_logger_1 = require("redux-logger");
var redux_persist_1 = require("redux-persist");
var redux_thunk_1 = require("redux-thunk");
var index_1 = require("./../reducers/index");
var persistedReducer = (0, redux_persist_1.persistReducer)(index_1.persistConfig, index_1.rootReducer);
var composeEnhancers = (0, redux_devtools_extension_1.composeWithDevTools)({});
function _getMiddleWare() {
    var middleware = [redux_thunk_1.default, redux_logger_1.default];
    if (!__DEV__) {
        middleware.pop();
    }
    return middleware;
}
function _enableHotLoader(_store) {
    if (!__DEV__) {
        return;
    }
    var hot = module.hot;
    if (hot) {
        hot.accept('./../reducers', function () {
            var nextRootReducer = require('./../reducers');
            _store.replaceReducer(nextRootReducer);
        });
    }
}
function configureStore() {
    var _store = (0, redux_1.createStore)(persistedReducer, composeEnhancers(redux_1.applyMiddleware.apply(void 0, _getMiddleWare())));
    _enableHotLoader(_store);
    return _store;
}
exports.store = configureStore();
exports.persistor = (0, redux_persist_1.persistStore)(exports.store);
function initReduxStore(cb) {
    (0, redux_persist_1.persistStore)(exports.store, null);
}
exports.initReduxStore = initReduxStore;
