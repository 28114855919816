"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./radio_btn.scss");
var RadioButtons = function (_a) {
    var selectedValue = _a.selectedValue, onchange = _a.onchange, name = _a.name, fieldTitle = _a.fieldTitle, lableNames = _a.lableNames, style = _a.style, checked = _a.checked, value = _a.value, defaultValue = _a.defaultValue;
    return (React.createElement("div", { className: "radio-wrapper" },
        React.createElement(material_1.FormControl, null,
            React.createElement(material_1.RadioGroup, { className: "radio-group", "aria-labelledby": "demo-radio-buttons-group-label", defaultValue: defaultValue, name: "radio-buttons-group", onChange: onchange }, lableNames &&
                lableNames.map(function (list) {
                    return (React.createElement(material_1.FormControlLabel, { value: list.value, control: React.createElement(material_1.Radio, null), label: list.label, className: "radio-btn", disabled: list.disabled === true }));
                })))));
};
exports.default = RadioButtons;
