"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../../api/api_service");
var button_component_1 = require("../../../../common/buttons/button_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../../common/modal/pop-up");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var leavetracking_apicalls_1 = require("../../../field_exicutive/leave_tracking/leavetracking.apicalls");
var reports_helper_1 = require("../reports_helper");
var CashPendingReport = function () {
    var stateList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).stateList;
    var _a = React.useState(undefined), cashPendingList = _a[0], setCashPendingList = _a[1];
    var _b = React.useState(''), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = React.useState(''), startDate = _c[0], setStartDate = _c[1];
    var _d = React.useState(''), endDate = _d[0], setEndDate = _d[1];
    var _e = React.useState([]), filterSelectedStates = _e[0], setSelectedFilterStates = _e[1];
    var _f = React.useState([]), citiesData = _f[0], setCitiesData = _f[1];
    var _g = React.useState([]), filterSelectedCities = _g[0], setSelectedFilterCities = _g[1];
    var _h = React.useState(0), totalPageCount = _h[0], setTotalPageCount = _h[1];
    var _j = React.useState(1), pageCount = _j[0], setpageCount = _j[1];
    var _k = React.useState(false), open = _k[0], setOpen = _k[1];
    var _l = React.useState(appConstants_1.defaultPageLimit), PageLimit = _l[0], setPageLimit = _l[1];
    var _m = React.useState(0), totalCount = _m[0], setTotalCount = _m[1];
    var _o = React.useState(false), isDateRangeUpdated = _o[0], setIsDateRangeUpdated = _o[1];
    var _p = React.useState(), errorMessage = _p[0], setErrorMessage = _p[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var searchFe = function (event) {
        setSearchTerm(event.target.value.trim());
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var fetchCities = function () { return __awaiter(void 0, void 0, void 0, function () {
        var stateIdArray, payloadToFetchCities;
        return __generator(this, function (_a) {
            stateIdArray = filterSelectedStates.map(function (state) { return state.id; });
            payloadToFetchCities = {
                state_id: stateIdArray,
            };
            (0, leavetracking_apicalls_1.fetchCitiesList)(payloadToFetchCities)
                .then(function (citiesListResponse) {
                var formattedCitiesList = (0, array_helpers_1.convertLabelAndValueCity)(citiesListResponse.cities);
                setCitiesData(formattedCitiesList);
            })
                .catch(function (e) {
                setCitiesData([]);
            });
            return [2];
        });
    }); };
    var updateSelectedCities = function () {
        var filteredData = lodash_1.default.intersectionBy(filterSelectedCities, stateList, 'state_id');
        setSelectedFilterCities(filteredData);
    };
    var callbackUpdateSelectedStates = function (value) {
        setCitiesData([]);
        setSelectedFilterCities([]);
        setSelectedFilterStates(value);
    };
    var callbackUpdateSelectedCities = function (value) {
        setSelectedFilterCities(value);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.reports);
    React.useEffect(function () {
        if (filterSelectedStates.length >= 1) {
            fetchCities();
        }
        if (false) {
            updateSelectedCities();
        }
    }, [filterSelectedStates]);
    var refetch = (0, react_query_1.useQuery)(['cash-pending-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (filterSelectedCities.length > 0) {
            console.log(filterSelectedCities, 'filterSelectedCities');
            var states_1 = [];
            var cities_1 = [];
            filterSelectedCities.map(function (selectedCityObj) {
                cities_1.push(selectedCityObj.short_code);
            });
            filterSelectedStates.map(function (selectedStateObj) {
                states_1.push(selectedStateObj.label);
            });
            payload['state'] =
                filterSelectedStates.length >= 30 ? ['ALL'] : states_1;
            payload['city'] =
                filterSelectedCities.length >= 500 ? ['ALL'] : cities_1;
        }
        if (searchTerm) {
            payload['search_string'] = searchTerm;
        }
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, reports_helper_1.cashPendingReports)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setErrorMessage("");
            setTotalPageCount(count);
            setCashPendingList(response.result);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setCashPendingList([]);
        });
    }).refetch;
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var cashPendingExcelReportGenerator = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (searchTerm) {
            payload['search_string'] = searchTerm;
        }
        if (filterSelectedCities.length > 0) {
            console.log(filterSelectedCities, 'filterSelectedCities');
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(filterSelectedCities);
            payload['state'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['city'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CASH_PENDING_REPORT_EXCELGENERATION,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var navigateToMyReports = function () {
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    return (React.createElement("div", { className: "fe-wrapper pay  -fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Cash Pending Report")),
            React.createElement(button_component_1.default, { type: "button", buttonName: appConstants_1.reportDownload, endIcon: "icon-import download-icon", color: "yellow-outline", onClickHandler: cashPendingExcelReportGenerator, isError: !features[menu_constants_1.FEATURE_CONSTANTS.cashPendingReportDownload] || (cashPendingList === null || cashPendingList === void 0 ? void 0 : cashPendingList.length) <= 0 })),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "filter-options" },
                React.createElement("div", { className: "search-filter" },
                    React.createElement(search_component_1.default, { placeholder: "Search FE Name/FE Code", searchValue: searchTerm, searchHandler: searchFe })),
                React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true }),
                React.createElement(multi_selector_1.default, { label: "Select State", dropDownList: stateList &&
                        stateList.sort(function (a, b) {
                            return a.label.localeCompare(b.label);
                        }), onChangeHnadlre: callbackUpdateSelectedStates, value: filterSelectedStates }),
                React.createElement(multi_selector_1.default, { noOptions: 'No cities found', label: "Select City", dropDownList: citiesData &&
                        citiesData.sort(function (a, b) {
                            return a.label.localeCompare(b.label);
                        }), onChangeHnadlre: callbackUpdateSelectedCities, value: filterSelectedCities || [] }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.CASH_PENDING_COLUMNS, rows: cashPendingList, pagination: false, enableCellTextSelection: true, className: 'cash-pending-table', errorMessage: errorMessage }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: cashPendingList === null || cashPendingList === void 0 ? void 0 : cashPendingList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = CashPendingReport;
