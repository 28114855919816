"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_query_1 = require("react-query");
var api_service_1 = require("../../../../api/api_service");
var react_highchart_1 = require("../../../../common/high_charts/react_highchart");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../../reports/reports_helper");
var intracity_tat_service_1 = require("../Intracity_Tat/intracity_tat_service");
var LabhandoverTrendGraph = function (_a) {
    var endDate = _a.endDate, isDateRangeUpdated = _a.isDateRangeUpdated, startDate = _a.startDate, labList = _a.labList, labCode = _a.labCode, filterSelectedCities = _a.filterSelectedCities, filterSelectedStates = _a.filterSelectedStates, setUpdatedTIme = _a.setUpdatedTIme, filterButton = _a.filterButton;
    var _b = React.useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = React.useState(null), Xaxis = _c[0], setXaxis = _c[1];
    var _d = React.useState([]), seriesData = _d[0], setSeriesData = _d[1];
    console.log(seriesData, 'seriesData');
    var refetch = (0, react_query_1.useQuery)(['Lab-Handover-Trend_ontime'], function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(labCode, labList);
            payload['lab_code'] = selectedlabCode;
        }
        if (filterSelectedCities && filterSelectedCities.length > 0) {
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(filterSelectedCities);
            payload['states'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['cities'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        (0, reports_helper_1.labHandoverTrendGraph)(payload, api_service_1.api_service.api_urls.LAB_HANDOVER_GRAPH)
            .then(function (response) {
            setErrorMessage('');
            if (response && response.data) {
                console.log(response, 'rsgfgdu');
                var data = (0, intracity_tat_service_1.convertLabHandoverResponse)(response.data.data.result);
                console.log(data, 'fsfsrfsrststst');
                var ontimeTimeData = (0, intracity_tat_service_1.converCumulativeData)(data.ontime_handover);
                var delayedimeData = (0, intracity_tat_service_1.converCumulativeData)(data.delayed_handover);
                var earlyTimeData = (0, intracity_tat_service_1.converCumulativeData)(data.early_handover);
                var seriesData_1 = [
                    { name: 'ONTIME HANDOVER', data: ontimeTimeData },
                    { name: 'EARLY HANDOVER', data: earlyTimeData },
                    { name: 'DELAYED HANDOVER', data: delayedimeData },
                ];
                var sortXaxis = data.x_axis.sort(function (a, b) { return a.localeCompare(b); });
                setSeriesData(seriesData_1);
                setXaxis(sortXaxis);
            }
        })
            .catch(function (err) {
            setErrorMessage(err);
            setSeriesData([]);
            setXaxis([]);
        });
    }).refetch;
    React.useEffect(function () {
        refetch();
        setUpdatedTIme(new Date().toLocaleString());
    }, [filterButton]);
    return (React.createElement("div", null, seriesData &&
        seriesData.length > 0 &&
        seriesData[0].data.length != 0 &&
        seriesData[1].data.length != 0 &&
        seriesData[2].data.length != 0 ? (React.createElement(React.Fragment, null,
        React.createElement(react_highchart_1.default, { chartOptions: {
                chart: {
                    type: 'column',
                },
                title: {
                    text: null,
                },
                xAxis: {
                    labels: {
                        style: {
                            fontSize: 17,
                        },
                    },
                    categories: Xaxis ? Xaxis : [],
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    plotBands: {
                        borderColor: 'black',
                    },
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: 17,
                        },
                    },
                },
                colors: ['#0000FF', '#00FF00', '#FFFF00'],
                legend: {
                    itemStyle: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                    },
                    symbolWidth: 40,
                    symbolHeight: 10,
                    symbolRadius: 0,
                    squareSymbol: false,
                },
                tooltip: {
                    padding: 20,
                    pointFormat: '<svg width="10" height="10" style="color:{series.color}">●</svg> <b>{series.name}: {point.y}%</b><br/>',
                    style: {
                        fontSize: '1.1em',
                    },
                },
                credits: {
                    enabled: false,
                },
                plotOptions: {
                    column: {
                        borderRadius: 5,
                        pointPadding: 0.1,
                        pointWidth: 50,
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            borderColor: 'red',
                            format: '{y}%',
                            style: {
                                fontSize: 15,
                            },
                        },
                    },
                    series: {
                        lineWidth: 3,
                        cursor: 'pointer',
                    },
                },
                series: seriesData,
            } }))) : (React.createElement("div", { className: "no-data-conatiner" },
        React.createElement("div", { className: "no-data-badge" }, errorMessage ? errorMessage : 'No Data Available')))));
};
exports.default = LabhandoverTrendGraph;
