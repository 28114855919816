"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var orc_service_1 = require("../orc_management/orc_service");
var scf_service_1 = require("./scf_service");
require("./scf_styles.scss");
var SCFStatusBar = function (_a) {
    var status = _a.status;
    var renderClassName = function () {
        if (status == scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE) {
            return React.createElement("div", { className: "status-badge" },
                React.createElement("span", { className: "icon-active" }),
                React.createElement("p", null, orc_service_1.ORC_STATUS_TYPE.Active));
        }
        else if (status == scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE) {
            return (React.createElement("div", { className: "status-badge" },
                React.createElement("span", { className: "icon-deactive" }),
                React.createElement("p", null, orc_service_1.ORC_STATUS_TYPE.Deactivated)));
        }
        else if (status == scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_ONE_REJECTED || status == scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_TWO_REJECTED) {
            return (React.createElement("div", { className: "status-badge" },
                React.createElement("span", { className: "icon-rejected" }),
                React.createElement("p", null, orc_service_1.ORC_STATUS_TYPE.Rejected)));
        }
        else if (status == scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_TWO_APPROVED) {
            return (React.createElement("div", { className: "status-badge" },
                React.createElement("span", { className: "icon-approval" }),
                React.createElement("p", null, orc_service_1.ORC_STATUS_TYPE.PendingForApprovalTwo)));
        }
        else {
            return (React.createElement("div", { className: "status-badge" },
                React.createElement("span", { className: "icon-approval" }),
                React.createElement("p", null, orc_service_1.ORC_STATUS_TYPE.PendingForApprovalOne)));
        }
    };
    return React.createElement("div", { className: "payment-status-wrapper" }, renderClassName());
};
exports.default = SCFStatusBar;
