"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteSelectedAudit = void 0;
var material_1 = require("@mui/material");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var modal_component_1 = require("../../../common/modal/modal_component");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var lodash_1 = require("../../../utils/lodash");
var generic_api_calls_1 = require("../../generic_api_calls");
var audit_helpers_1 = require("./audit_helpers");
var deleteSelectedAudit = function (audit_id) { };
exports.deleteSelectedAudit = deleteSelectedAudit;
var Audit = function () {
    var nav = (0, react_router_1.useNavigate)();
    var addAudit = function () {
        nav("".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.createAudit));
    };
    var _a = React.useState(undefined), auditList = _a[0], setAuditList = _a[1];
    var _b = React.useState(), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = React.useState([]), selectedUsers = _c[0], setSelectedUsers = _c[1];
    var _d = React.useState([]), selectedStatus = _d[0], setSelectedStatus = _d[1];
    var _e = React.useState(''), searchName = _e[0], setSearchName = _e[1];
    var _f = React.useState(), startDate = _f[0], setStartDate = _f[1];
    var _g = React.useState(), endDate = _g[0], setEndDate = _g[1];
    var _h = React.useState(false), displayModal = _h[0], setDisplayMdoal = _h[1];
    var _j = React.useState(''), selectedAuditID = _j[0], setSelectedAuditID = _j[1];
    var _k = React.useState(''), selectedAuditTitle = _k[0], setSelectedAuditTitle = _k[1];
    var _l = React.useState(false), isDateRangeUpdated = _l[0], setIsDateRangeUpdated = _l[1];
    var _m = React.useState(1), pageCount = _m[0], setpageCount = _m[1];
    var _o = React.useState(0), totalPageCount = _o[0], setTotalPageCount = _o[1];
    var _p = React.useState(appConstants_1.defaultPageLimit), PageLimit = _p[0], setPageLimit = _p[1];
    var _q = React.useState(0), totalCount = _q[0], setTotalCount = _q[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.audit);
    console.log('featuresfeatures:', features);
    var userTypeList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.center_auditees_types; });
    var userTypeChangeHandler = function (value) {
        setSelectedUsers(value);
        console.log('selectedUsers :', value);
    };
    var statusChangeHandler = function (value) {
        setSelectedStatus(value);
        console.log('selectedstatis:', value);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var onRowClick = function (event) {
        var status = event.data.status;
        console.log('event inside onRowClick:', event);
        if (event.type === 'rowClicked' &&
            !event.event.target.className.includes('PrivateSwitchBase') &&
            !event.event.target.className.includes('icon-delete_forever') &&
            !event.event.target.className.includes('icon-edit')) {
            if (status === 'ACTIVE' ||
                status === 'COMPLETED' ||
                status === 'INCOMPLETED') {
                nav("/".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.audit, "/").concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.auditDetails, "/").concat(event.data.audit_id));
            }
            else {
                if (status === 'DRAFT' || status === 'CREATED') {
                    nav("".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.editAudit, "/").concat(event.data.audit_id));
                }
            }
        }
        else if (event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-delete_forever')) {
            if (event.data.status === 'DRAFT' || event.data.status === 'CREATED') {
                setDisplayMdoal(true);
                setSelectedAuditID(event.data.audit_id);
                setSelectedAuditTitle(event.data.name);
            }
        }
        else if (event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-edit')) {
            if (event.data.status === 'DRAFT' || event.data.status === 'CREATED') {
                if (event.data.audit_id) {
                    nav("".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.editAudit, "/").concat(event.data.audit_id));
                }
            }
        }
        else {
            bookMarkAudit(event.event, event.data.audit_id);
        }
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var refetch = (0, react_query_1.useQuery)(['audit-list', pageCount], function () {
        var payload = {};
        var currentPage = pageCount < 1 ? 1 : pageCount;
        if (selectedUsers.length > 0) {
            var users_1 = [];
            selectedUsers.forEach(function (user) {
                users_1.push(user.label);
            });
            payload['users'] = users_1;
        }
        if (selectedStatus.length > 0) {
            var status_1 = [];
            selectedStatus.forEach(function (singleStatus) {
                status_1.push(singleStatus.id);
            });
            payload['status'] = status_1;
        }
        if (searchName != '') {
            payload['name'] = searchName;
        }
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, generic_api_calls_1.fetchAuditList)(payload)
            .then(function (res) {
            var convertedRes = (0, audit_helpers_1.convertAuditListFromRes)(res);
            var resultantData = [];
            convertedRes.forEach(function (audit) {
                var auditStatus = (0, generic_api_calls_1.getAuditStatus)(audit.status);
                resultantData.push(__assign(__assign({}, audit), { status: auditStatus, cloneHandler: function (id) { return cloneAudit(id); } }));
            });
            setAuditList(resultantData);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(res.count / pageSize);
            setErrorMessage('');
            setTotalPageCount(count);
            setTotalCount(res.count);
        })
            .catch(function (err) {
            console.log('error in the error', err);
            setErrorMessage(err.message);
            setAuditList([]);
        });
    }).refetch;
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var deleteAudit = function (audit_id) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.DELETE_AUDIT,
            payLoad: { audit_id: audit_id },
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                react_hot_toast_1.default.success(res.data.message);
                setDisplayMdoal(false);
                setSelectedAuditID('');
                setSelectedAuditTitle('');
                refetch();
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var cloneAudit = function (id) {
        nav("".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.createAudit, "/").concat(id));
    };
    var bookMarkAudit = function (event, id) {
        var isChecked = !event.target.checked;
        var payload = {
            audit_id: id,
            favourite: isChecked,
        };
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.BOOKMARK_AUDIT,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (isChecked) {
                    react_hot_toast_1.default.success(appConstants_1.bookMark.Add);
                }
                else {
                    react_hot_toast_1.default.success(appConstants_1.bookMark.remove);
                }
                refetch();
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
        return false;
    };
    var auditStatusList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.auditStatusList; });
    var handleSearchValueChange = lodash_1.default.debounce(function (event) {
        setSearchName(event.target.value);
    }, 500);
    return (React.createElement("div", { className: "page-wrapper audit-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Audit")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Assign Audit", color: "yellow-outline", onClickHandler: function () { return nav("".concat(route_path_1.ROUTE_CONSTAMNTS.PATHS.assignAudit)); }, endIcon: "icon-label", isError: !features[menu_constants_1.FEATURE_CONSTANTS.auditAssignment] }),
                React.createElement(button_component_1.default, { buttonName: "Create New Audit", color: "yellow", onClickHandler: addAudit, isError: !features[menu_constants_1.FEATURE_CONSTANTS.auditCreation] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: "Search Audit", searchHandler: handleSearchValueChange })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(multi_selector_1.default, { label: "Select Users", dropDownList: userTypeList ? userTypeList : [], onChangeHnadlre: userTypeChangeHandler, value: selectedUsers, hasSelectAll: true }),
                React.createElement(multi_selector_1.default, { label: "Select Status", dropDownList: auditStatusList, onChangeHnadlre: statusChangeHandler, value: selectedStatus }),
                React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "scrollable-content clickable-row" },
            React.createElement("div", { className: "bookmark-checkbox" },
                React.createElement(AGgrid_table_component_1.default, { mutilselector: "single", isSortable: true, columnDefs: table_data_1.default.AUDITMODULE_COLUMNS, rows: auditList, errorMessage: errorMessage, pagination: false, onRowClicked: features[menu_constants_1.FEATURE_CONSTANTS.auditStats] && onRowClick, className: 'audit-table' }),
                React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: auditList === null || auditList === void 0 ? void 0 : auditList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }),
                React.createElement(modal_component_1.default, { open: displayModal },
                    React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                        React.createElement("div", { className: "modal-header header-wrapper" },
                            React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                            React.createElement("h2", null, "Confirmation")),
                        React.createElement("div", { className: "modal-body" },
                            React.createElement("div", { className: "modal-message" },
                                "Are you sure,you want to delete -",
                                selectedAuditTitle,
                                "?")),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: 'Delete', color: "yellow", onClickHandler: function () {
                                    deleteAudit(selectedAuditID);
                                } }),
                            React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () {
                                    setDisplayMdoal(false);
                                    setSelectedAuditID('');
                                    setSelectedAuditTitle('');
                                } }))))))));
};
exports.default = Audit;
