"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CC_INTITAL_VALUES = exports.FILED_NAMES_CC = void 0;
exports.FILED_NAMES_CC = {
    warehouse_code: 'warehouse_code',
    id: 'id',
    is_active: 'is_active',
    schedule_time_slot: 'schedule_time_slot',
    is_deleted: 'is_deleted',
    day: 'day',
};
exports.CC_INTITAL_VALUES = {
    labcode: null,
    id: null,
    state: '',
    city: '',
    day: null,
    warehouse_code: null,
    schedule_time_slot: '',
    activate: 'NON_ROUTE',
    handoverdone: true,
    baseLocation: '',
    base_location_address: '',
    invoice_code: '',
    raiseOnDemandRequest: true,
    warehouse_codes: ''
};
