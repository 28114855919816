"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FiberManualRecord_1 = require("@mui/icons-material/FiberManualRecord");
var material_1 = require("@mui/material");
var moment = require("moment");
var React = require("react");
var react_query_1 = require("react-query");
var react_router_1 = require("react-router");
var api_urls_1 = require("../../../../api/api_urls");
var avatar_component_1 = require("../../../../common/avatar/avatar_component");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var text_field_with_datepicker_1 = require("../../../../common/text_filed/text_field_with_datepicker");
var FECreationTabConstant_1 = require("../../../../constants/FECreationTabConstant");
var images_1 = require("../../../../constants/images");
var route_path_1 = require("../../../../constants/route_path");
var live_tracking_api_service_1 = require("../api/live_tracking_api_service");
var fe_history_map_details_1 = require("./fe_history_map_details");
var FELiveTrackingDetails = function () {
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var _a = React.useState(''), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = React.useState(null), FedeDetails = _b[0], setFeDeDetails = _b[1];
    var _c = React.useState(moment(new Date().toString()).format('YYYY-MM-DD')), selctedDate = _c[0], setSelctedDate = _c[1];
    var refetch = (0, react_query_1.useQuery)(['fe_live_tracking_details'], function () {
        var payload = {
            fe_id: id,
            visit_date: selctedDate,
        };
        (0, live_tracking_api_service_1.fetchFELiveTrackingDetails)(api_urls_1.api_urls.fe_live_tracking_detials, payload, process.env.VENDOR_BASE_URL)
            .then(function (res) {
            if (res) {
                console.log(res, 'RRRRRRRR');
                setFeDeDetails(res);
            }
            else {
                setFeDeDetails(null);
            }
        })
            .catch(function (err) {
            setErrorMessage(err.message);
        });
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, [selctedDate]);
    console.log(FedeDetails, 'FedeDetails');
    return (React.createElement("div", null,
        React.createElement("div", { className: "fe-wrapper fe-tracking" },
            React.createElement("div", { className: "tool-bar-wrapper border-bottom" },
                React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                    React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: function () { return nav("/".concat(route_path_1.PATHS.fe_traking_list)); } }),
                    React.createElement("h2", null, "FE Live Tracking Details ")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement("div", { className: "filter-options" },
                        React.createElement(text_field_with_datepicker_1.default, { label: "Select Date", value: selctedDate, disableManulaEntry: true, onChange: function (dateValue) {
                                setSelctedDate(moment(dateValue).format('YYYY-MM-DD'));
                            } }),
                        React.createElement(button_component_1.default, { buttonName: "Refresh", color: "yellow", onClickHandler: function () { return refetch(); } })))),
            React.createElement("div", null,
                React.createElement(material_1.Grid, { container: true, columns: 8 },
                    React.createElement(material_1.Grid, { item: true, xs: 2.2 },
                        React.createElement(material_1.Card, { elevation: 0, className: "cst-card1 cst-card" },
                            React.createElement(material_1.Stack, { className: "border-bottom", paddingBottom: 2.5, marginBottom: 2.5, direction: 'row', alignItems: 'center', justifyContent: 'space-between' },
                                React.createElement(material_1.Stack, { gap: 2, direction: 'row', alignItems: 'center' },
                                    React.createElement(avatar_component_1.default, { className: "avatar", fName: FedeDetails && FedeDetails.fe_name
                                            ? FedeDetails.fe_name
                                            : FECreationTabConstant_1.emptyDetails, lName: '', image: null })),
                                React.createElement(material_1.Chip, { size: "small", avatar: React.createElement(FiberManualRecord_1.default, null), label: "Active", variant: "outlined" })),
                            React.createElement(material_1.CardContent, null,
                                React.createElement(material_1.Grid, { container: true, columns: 4, spacing: 3 },
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "FE Code"),
                                        React.createElement("p", { className: "info" }, FedeDetails && FedeDetails.fe_code
                                            ? FedeDetails.fe_code
                                            : FECreationTabConstant_1.emptyDetails)),
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "FE Number"),
                                        React.createElement("p", { className: "info" }, FedeDetails && FedeDetails.mobile
                                            ? FedeDetails.mobile
                                            : FECreationTabConstant_1.emptyDetails)),
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "Lab_Code"),
                                        React.createElement("p", { className: "info" }, FedeDetails && FedeDetails.lab_code
                                            ? FedeDetails.lab_code
                                            : FECreationTabConstant_1.emptyDetails)),
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "Lab_Name"),
                                        React.createElement("p", { className: "info" }, FedeDetails && FedeDetails.lab_name
                                            ? FedeDetails.lab_name
                                            : FECreationTabConstant_1.emptyDetails)),
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "State"),
                                        React.createElement("p", { className: "info" }, FedeDetails && FedeDetails.state
                                            ? FedeDetails.state
                                            : FECreationTabConstant_1.emptyDetails)),
                                    React.createElement(material_1.Grid, { item: true, xs: 2 },
                                        React.createElement("p", { className: "title" }, "City"),
                                        React.createElement("p", { className: "info" }, FedeDetails && FedeDetails.city ? FedeDetails.city : FECreationTabConstant_1.emptyDetails)))))),
                    React.createElement(material_1.Grid, { item: true, xs: 5.8 }, FedeDetails && FedeDetails.route && FedeDetails.route.length > 0 ? (React.createElement("div", { className: "map-wrapper" },
                        React.createElement(fe_history_map_details_1.default, { errorMessage: errorMessage, feDeatils: FedeDetails }),
                        React.createElement("div", { className: "map-legend-accordian  left-align" },
                            React.createElement(material_1.Accordion, { className: "custon-legend-accordian" },
                                React.createElement(material_1.AccordionSummary, { expandIcon: React.createElement("span", { className: "icon-dropdown-arrow" }) },
                                    React.createElement("p", null, "Legend")),
                                React.createElement(material_1.AccordionDetails, null,
                                    React.createElement("div", { className: "map-legend left-align" },
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("div", { className: "dynamic-value" },
                                                React.createElement("img", { src: images_1.default.yellow_map_icon, alt: "blue-map-icon" })),
                                            "Start Point"),
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.ccIcon, alt: "cc-icon" }),
                                            "CC"),
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.labIcon, alt: "lab-icon" }),
                                            "LAB"),
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.hubIcon, alt: "hub-icon" }),
                                            "HUB"),
                                        React.createElement("div", { className: "round-item" },
                                            React.createElement("img", { src: images_1.default.rider_icon, alt: "hub-icon" }),
                                            "FE Current Location"))))))) : (React.createElement("div", { className: "no-data-conatiner borderbox" },
                        React.createElement("div", { className: "no-data-badge" },
                            ' ',
                            errorMessage ? errorMessage.toString() : 'No Data Available')))))))));
};
exports.default = FELiveTrackingDetails;
