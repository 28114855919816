"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var lodash_1 = require("../../../utils/lodash");
var visitsList_api_calls_1 = require("./visitsList_api_calls");
var VisitsList = function () {
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var modefiedLabList = (0, array_helpers_1.concatenateLabCodeWithAddress)(labList);
    var _a = React.useState(undefined), visistsList = _a[0], setVisitsList = _a[1];
    var _b = React.useState([]), downloadableVisitsList = _b[0], setDownlaodableVisitsList = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(new Date().toString()), filterFromDate = _d[0], setFromDateFilter = _d[1];
    var _e = React.useState(new Date().toString()), filterToDate = _e[0], setToDateFilter = _e[1];
    var _f = React.useState(''), searchTerm = _f[0], setSearchTerm = _f[1];
    var _g = React.useState(null), labcode = _g[0], Setlabcode = _g[1];
    var _h = React.useState(null), routing_type = _h[0], SetRouting_type = _h[1];
    var LabcodeList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var Routing_Assignment = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.routing_assignments; });
    console.log(Routing_Assignment, 'Routing_Assignment');
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _j = React.useState(false), open = _j[0], setOpen = _j[1];
    var _k = React.useState(false), confirmPop = _k[0], setConfirmPop = _k[1];
    var _l = React.useState([]), filteredFEs = _l[0], setFilteredFEs = _l[1];
    var _m = React.useState(false), modelOpen = _m[0], setModalOpen = _m[1];
    var openCofirmPop = function () { return setConfirmPop(true); };
    var closeConfirmPop = function () {
        setConfirmPop(false);
    };
    var openSwapPop = function () { return setOpen(true); };
    var closeSwapPop = function () { return setOpen(false); };
    var nav = (0, react_router_dom_1.useNavigate)();
    var _o = React.useState([]), toFilteredFE = _o[0], setTofilteredFEs = _o[1];
    var _p = React.useState(), fromToFE = _p[0], setFromToFe = _p[1];
    var _q = React.useState(false), isDateRangeUpdated = _q[0], setIsDateRangeUpdated = _q[1];
    var _r = React.useState(1), pageCount = _r[0], setpageCount = _r[1];
    var _s = React.useState(appConstants_1.defaultPageLimit), PageLimit = _s[0], setPageLimit = _s[1];
    var _t = React.useState(0), totalPageCount = _t[0], setTotalPageCount = _t[1];
    var _u = React.useState(0), totalCount = _u[0], setTotalCount = _u[1];
    var _v = React.useState(null), selectedLab = _v[0], setSelectedLab = _v[1];
    var callBackToSwap = function () {
        var payload = {};
        payload.from_fe_id = fromToFE.from_fe_id;
        payload.to_fe_id = fromToFE.to_fe_id;
        (0, visitsList_api_calls_1.swapFE)(payload)
            .then(function (res) {
            react_hot_toast_1.default.success(res.message);
            closeConfirmPop();
            closeSwapPop();
            setSelectedLab(null);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var formSubmitHandler = function (params) {
        var object = {};
        object.from_fe_id = params.from_fe_id.id;
        object.to_fe_id = params.to_fe_id.id;
        setFromToFe(object);
        openCofirmPop();
    };
    var onSelectedFromFe = function (params) {
        setTofilteredFEs([]);
        var payload = {
            lab_code: selectedLab,
            fe_type: params.fe_type,
        };
        (0, visitsList_api_calls_1.callToFeListOnlabCode)(payload).then(function (res) {
            var removedFromFE = res === null || res === void 0 ? void 0 : res.data.data.filter(function (fe) {
                return fe.id !== params.id;
            });
            setTofilteredFEs((0, array_helpers_1.convertLabelAndValueFEList)(removedFromFE));
        });
    };
    var getToFeListOnlabCode = function (params) {
        var payload = {
            lab_code: params.value,
        };
        setSelectedLab(params.value);
        setFilteredFEs([]);
        setTofilteredFEs([]);
        (0, visitsList_api_calls_1.callToFeListOnlabCode)(payload).then(function (res) {
            setFilteredFEs((0, array_helpers_1.convertLabelAndValueFEList)(res === null || res === void 0 ? void 0 : res.data.data));
        });
    };
    var getVisitsList = function (payload) {
        (0, visitsList_api_calls_1.callToVisistsList)(payload)
            .then(function (res) {
            var _a, _b, _c;
            var convertedRes = (0, array_helpers_1.convertVisitsFromRes)((_a = res.data) === null || _a === void 0 ? void 0 : _a.result);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(((_b = res.data) === null || _b === void 0 ? void 0 : _b.count) / pageSize);
            setErrorMessage('');
            setTotalPageCount(count);
            setTotalCount((_c = res.data) === null || _c === void 0 ? void 0 : _c.count);
            var convertedDownloadReportRes = (0, array_helpers_1.convertDownloadReportVisitsFromRes)(res.data.result);
            setDownlaodableVisitsList(convertedDownloadReportRes);
            setVisitsList(convertedRes);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setVisitsList([]);
            setDownlaodableVisitsList([]);
        });
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var donwloadVisitHandlere = function () {
        var payload = {};
        if (searchTerm.length === 10) {
            payload['mobile'] = searchTerm;
        }
        if (filterFromDate && filterToDate) {
            payload['date_range'] = {
                start_date: moment(filterFromDate).format('YYYY-MM-DD'),
                end_date: moment(filterToDate).format('YYYY-MM-DD'),
            };
        }
        if (labcode) {
            payload['lab_code'] = labcode.value.toString();
        }
        if (routing_type) {
            payload['assignment_type'] = routing_type.value.toString();
        }
        payload.page = 1;
        payload.size = 5000;
        (0, visitsList_api_calls_1.downloadVisits)(payload)
            .then(function (res) {
            if (res.statusCode === 200) {
                setModalOpen(true);
            }
            else {
                react_hot_toast_1.default.error(res.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['active-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (searchTerm.length === 10) {
            payload['mobile'] = searchTerm;
        }
        if (filterFromDate && filterToDate) {
            payload['date_range'] = {
                start_date: filterFromDate,
                end_date: filterToDate,
            };
        }
        if (labcode) {
            payload['lab_code'] = labcode.value.toString();
        }
        if (routing_type) {
            payload['assignment_type'] = routing_type.value.toString();
        }
        payload.page = currentPage;
        payload.size = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        getVisitsList(payload);
    }).refetch;
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var searchingHandler = lodash_1.default.debounce(function (e) {
        setSearchTerm(e.target.value.trim());
    }, 500);
    var onRowClickedHandler = function (e) {
        nav("/".concat(route_path_1.default.PATHS.Visits, "/").concat(route_path_1.default.PATHS.VisitsList, "/").concat(route_path_1.default.PATHS.VisitDetails));
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var filteredModuleFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.visitManagement);
    var labcodeHandler = function (labcode) {
        Setlabcode(labcode);
    };
    var Routing_Assignment_Handler = function (routing_assignment) {
        SetRouting_type(routing_assignment);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var navigateToMyReports = function () {
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    return (React.createElement("div", { className: "unclickablerow " },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Visits List")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "download", isError: !filteredModuleFeatures[menu_constants_1.FEATURE_CONSTANTS.VISITS_LIST_DOWNLOAD] ||
                        downloadableVisitsList.length <= 0, endIcon: "icon-import download-icon", color: "yellow-outline", onClickHandler: function () { return donwloadVisitHandlere(); } }),
                React.createElement(button_component_1.default, { buttonName: "Visits Transfer", color: "yellow", onClickHandler: openSwapPop, isError: !filteredModuleFeatures[menu_constants_1.FEATURE_CONSTANTS.VISITS_TRANSFER] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" },
                React.createElement(search_component_1.default, { placeholder: "Search Mobile no", searchValue: searchTerm, searchHandler: searchingHandler, keypressHandler: function (e) {
                        if (new RegExp(/^[0-9]+$/).test(e.key) && e.target.value.length <= 9) {
                        }
                        else {
                            e.preventDefault();
                        }
                    } })),
            React.createElement(drop_down_component_1.default, { disableClearable: true, name: "LabCode", placeholder: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(LabcodeList), onChange: labcodeHandler, value: labcode || null }),
            React.createElement(drop_down_component_1.default, { disableClearable: true, name: "Routing Assignment", placeholder: "Routing Assignment", dropDownList: Routing_Assignment, onChange: Routing_Assignment_Handler, value: routing_type || null }),
            React.createElement("div", { className: "filter-options" },
                React.createElement("div", { className: "cursor-pointer date-range-right" },
                    React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true })),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.VISITS_LIST_COLUMNS, rows: visistsList && visistsList, errorMessage: errorMessage, onRowClicked: onRowClickedHandler, pagination: false, className: 'visits-list-table' }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: visistsList === null || visistsList === void 0 ? void 0 : visistsList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }),
        React.createElement(pop_up_1.default, { open: open, popUpSize: "popup-lg" },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("h2", { className: "heading" }, "Visits Transfer"),
                React.createElement("h2", { className: "sub-heading" },
                    "This feature will transfer the today's incomplete visits from one FE To Other FE of same type.",
                    React.createElement("br", null),
                    "For example : From : \"X\" Static/OBC/OTC FE",
                    React.createElement("span", { className: "icon-left-arrow" }),
                    "To : \"Y\" Static/OBC/OTC FE ",
                    React.createElement("br", null),
                    React.createElement(material_1.Typography, { width: '70%', textAlign: 'center' }, "(OR)"),
                    React.createElement(material_1.Typography, { pl: 11.5 },
                        "From : \"X\" Dynamic FE",
                        React.createElement("span", { className: "icon-left-arrow" }),
                        "To : \"Y\" Dynamic FE."))),
            React.createElement(formik_1.Formik, { initialValues: {
                    labcode: '',
                    from_fe_id: '',
                    to_fe_id: '',
                }, validateOnBlur: false, onSubmit: formSubmitHandler, validationSchema: schema_1.schemas.SWAP_FE_SCHEMA }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "modal-body" },
                        React.createElement("div", { className: "grey-bg-conatiner swap-input-filed" },
                            React.createElement("div", { className: "input-fileds-wrapper" },
                                React.createElement(material_1.Grid, { container: true, columns: { xs: 11 }, columnSpacing: 3, alignItems: 'center' },
                                    React.createElement(material_1.Grid, { item: true, xs: 7 },
                                        React.createElement(drop_down_component_1.default, { disableClearable: false, name: "lab", placeholder: "Lab Code", dropDownList: modefiedLabList.sort(function (a, b) {
                                                return a.label.localeCompare(b.label);
                                            }), onChange: function (val) {
                                                setFieldValue('from_fe_id', '');
                                                setFieldValue('to_fe_id', '');
                                                setFieldValue('labcode', val);
                                                setFieldTouched('labcode', true);
                                                getToFeListOnlabCode(val);
                                            }, value: values.labcode, key: values.labcode }),
                                        errors['labcode'] && touched['labcode'] ? (React.createElement("div", { className: "error" }, errors['labcode'])) : null),
                                    React.createElement(material_1.Grid, { item: true, xs: 5 },
                                        React.createElement(drop_down_component_1.default, { isDisabled: values.labcode ? false : true, noOptionsText: "FE's Not Available ", dropDownList: filteredFEs, placeholder: "From", onChange: function (val) {
                                                setFieldValue('to_fe_id', '');
                                                setFieldValue('from_fe_id', val);
                                                setFieldTouched('from_fe_id', true);
                                                onSelectedFromFe(val);
                                            }, value: values.from_fe_id }),
                                        errors['from_fe_id'] && touched['from_fe_id'] ? (React.createElement("div", { className: "error" }, errors['from_fe_id'])) : null),
                                    React.createElement(material_1.Grid, { item: true, xs: 1, className: "swap-btn-wrapper" },
                                        React.createElement(iconButton_component_1.default, { className: "squar-btn icon-btn swap-btn", icon: "icon-left-arrow" })),
                                    React.createElement(material_1.Grid, { item: true, xs: 5 },
                                        React.createElement(drop_down_component_1.default, { isDisabled: values.from_fe_id ? false : true, dropDownList: toFilteredFE, placeholder: "To", onChange: function (val) {
                                                setFieldValue('to_fe_id', val);
                                                setFieldTouched('to_fe_id', true);
                                            }, value: values.to_fe_id }),
                                        errors['to_fe_id'] && touched['to_fe_id'] ? (React.createElement("div", { className: "error" }, errors['to_fe_id'])) : null))))),
                    React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Close", color: "yellow-outline", onClickHandler: closeSwapPop }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Done", color: "yellow" }))));
            })),
        React.createElement(pop_up_1.default, { open: confirmPop, popUpSize: "modal-confirm" },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("h2", { className: "heading" }, "Confirm Swap")),
            React.createElement("div", { className: "modal-body" },
                React.createElement("p", { className: "confirm-msg" }, "Are you sure that you want to swap?")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "No", color: "yellow-outline", onClickHandler: closeConfirmPop }),
                React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", onClickHandler: callBackToSwap }))),
        React.createElement(pop_up_1.default, { open: modelOpen },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setModalOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = VisitsList;
