"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var ViewIcon = function () {
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn" },
            React.createElement("span", { className: "icon-Vector" }))));
};
exports.default = ViewIcon;
