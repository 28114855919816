"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUserLogistic = exports.userlogisticsMobileAndEmailExist = exports.fetchUserLogisticsList = void 0;
var api_service_1 = require("../../../api/api_service");
var fetchUserLogisticsList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.USER_LOGISTICS_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            var _a;
            if (response.status === 200) {
                return resolve((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchUserLogisticsList = fetchUserLogisticsList;
var userlogisticsMobileAndEmailExist = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.MOBILEANDEMAILVALIDATE),
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            return resolve(res);
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.userlogisticsMobileAndEmailExist = userlogisticsMobileAndEmailExist;
var createUserLogistic = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CREATEUSERLOGISTIC,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data.message);
            }
            throw response.data.message;
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.createUserLogistic = createUserLogistic;
