"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Timeline_1 = require("@mui/lab/Timeline");
var TimelineConnector_1 = require("@mui/lab/TimelineConnector");
var TimelineContent_1 = require("@mui/lab/TimelineContent");
var TimelineDot_1 = require("@mui/lab/TimelineDot");
var TimelineItem_1 = require("@mui/lab/TimelineItem");
var TimelineSeparator_1 = require("@mui/lab/TimelineSeparator");
var React = require("react");
require("./tool_tip.scss");
var emptyValue = '--';
var MapToolTip = function (_a) {
    var tooTipInformation = _a.tooTipInformation;
    console.log(tooTipInformation && tooTipInformation, 'tooTipInformation');
    return (React.createElement("div", { className: "map-tool-tip-wrapper" },
        React.createElement("div", { className: "tool-tip-heading" },
            React.createElement("h2", null, "FE_CODE:",
                tooTipInformation && tooTipInformation.fe_id
                    ? tooTipInformation.fe_id
                    : emptyValue),
            React.createElement("p", { className: "sub-heaidng" }, tooTipInformation && tooTipInformation.feName
                ? tooTipInformation.feName
                : emptyValue)),
        React.createElement("div", { className: "tool-tip-body" },
            React.createElement(Timeline_1.default, { position: "right", className: "time-line-sm" },
                React.createElement(TimelineItem_1.default, null,
                    React.createElement(TimelineSeparator_1.default, null,
                        React.createElement(TimelineDot_1.default, { className: "step-icon-wrapper" },
                            React.createElement("span", { className: "icon-person_pin_circle step-icon" })),
                        React.createElement(TimelineConnector_1.default, { className: "custom-connector" })),
                    React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                        React.createElement("div", { className: "step-content" },
                            React.createElement("p", { className: "step-label" },
                                ' ',
                                tooTipInformation && tooTipInformation.visit_type == 0
                                    ? 'Base Location'
                                    : tooTipInformation && tooTipInformation.startLocation
                                        ? tooTipInformation.startLocation
                                        : emptyValue),
                            React.createElement("div", { className: "step-details-wrapper" },
                                React.createElement("div", { className: "time-line-sm-content" },
                                    React.createElement("span", { className: "icon-import_export content-icon" }),
                                    React.createElement("span", { className: "content-label" }, "Distance:"),
                                    React.createElement("span", { className: "content-value" }, tooTipInformation && tooTipInformation.distanse
                                        ? "".concat(tooTipInformation.distanse, "km")
                                        : emptyValue)),
                                React.createElement("div", { className: "time-line-sm-content" },
                                    React.createElement("span", { className: "icon-hourglass_empty content-icon" }),
                                    React.createElement("span", { className: "content-label" }, "Duration:"),
                                    React.createElement("span", { className: "content-value" },
                                        tooTipInformation && tooTipInformation.hour == 0
                                            ? ''
                                            : tooTipInformation && tooTipInformation.hour
                                                ? "".concat(tooTipInformation.hour, "hr:")
                                                : emptyValue,
                                        tooTipInformation && tooTipInformation.minutes
                                            ? "".concat(tooTipInformation.minutes, "min")
                                            : emptyValue)),
                                React.createElement("div", { className: "time-line-sm-content" },
                                    React.createElement("span", { className: "icon-access_time content-icon" }),
                                    React.createElement("span", { className: "content-label" }, "ETA:"),
                                    React.createElement("span", { className: "content-value" }, tooTipInformation && tooTipInformation.scheduled_time
                                        ? tooTipInformation.scheduled_time
                                        : emptyValue)))))),
                React.createElement(TimelineItem_1.default, null,
                    React.createElement(TimelineSeparator_1.default, null,
                        React.createElement(TimelineDot_1.default, { className: "step-icon-wrapper" },
                            React.createElement("span", { className: "icon-outlined_flag step-icon" }))),
                    React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                        React.createElement("div", { className: "step-content" },
                            React.createElement("p", { className: "step-label" },
                                ' ',
                                tooTipInformation && tooTipInformation.endLocation
                                    ? tooTipInformation.endLocation
                                    : emptyValue))))))));
};
exports.default = MapToolTip;
