"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var ExpandLess_1 = require("@mui/icons-material/ExpandLess");
var ExpandMore_1 = require("@mui/icons-material/ExpandMore");
var Password_1 = require("@mui/icons-material/Password");
var material_1 = require("@mui/material");
var Badge_1 = require("@mui/material/Badge");
var Box_1 = require("@mui/material/Box");
var Drawer_1 = require("@mui/material/Drawer");
var IconButton_1 = require("@mui/material/IconButton");
var Menu_1 = require("@mui/material/Menu");
var MenuItem_1 = require("@mui/material/MenuItem");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var react_router_dom_1 = require("react-router-dom");
var universal_cookie_1 = require("universal-cookie");
var config_action_1 = require("../../actions/config_action");
var FE_Creation_action_1 = require("../../actions/FE_Creation_action");
var user_action_1 = require("../../actions/user_action");
var api_service_1 = require("../../api/api_service");
require("../../components/layout/layout.scss");
var route_path_1 = require("../../constants/route_path");
var routes_1 = require("../../routes/routes");
var array_helpers_1 = require("../../utils/array_helpers");
var generic_api_calls_1 = require("../generic_api_calls");
var idle_timeout_1 = require("../idle_timeout/idle_timeout");
var NotificationHelpers_1 = require("../Notification_management/NotificationHelpers");
var lodash_1 = require("./../../utils/lodash");
var menu_module_list_1 = require("./menu_module_list");
var notification_styles_1 = require("./notification_styles");
var events = ['click', 'scroll', 'keydown', 'mousemove'];
var MenuLayout = function () {
    var pathname = (0, react_router_1.useLocation)().pathname;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.currentMenu; }), mainModule = _a.mainModule, subModule = _a.subModule, subChaildModule = _a.subChaildModule;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.user; }), is_sso_user = _b.is_sso_user, jwt_token = _b.jwt_token;
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), features = _c.features, featureslist = _c.featureslist;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var vendorName = (0, react_redux_1.useSelector)(function (vendorName) { return vendorName.user.vendorName; });
    var cookies = new universal_cookie_1.default();
    var _d = React.useState(false), expandMainModule = _d[0], setExpandMainModule = _d[1];
    var _e = React.useState(false), expandSubMainModule = _e[0], setExpandSubMainModule = _e[1];
    var _f = React.useState(null), anchorEl = _f[0], setAnchorEl = _f[1];
    var _g = React.useState([]), orderedMenu = _g[0], setOrderedMenu = _g[1];
    var _h = React.useState(0), totalCount = _h[0], setTotalCount = _h[1];
    var _j = React.useState(undefined), notificationList = _j[0], setNotificationList = _j[1];
    var _k = React.useState(''), errorMessage = _k[0], setErrorMessage = _k[1];
    var timer;
    var navigateToProfile = function () {
        navigate("/".concat(route_path_1.default.PATHS.userProfile));
    };
    var navigateToChangePassword = function () {
        navigate("/".concat(route_path_1.default.PATHS.changePassword));
    };
    var checkSubmodulesAvailble = function (mainModuleObj) {
        if (mainModuleObj.subMenu) {
            return expandMainModule && mainModule == mainModuleObj.moduleName ? (React.createElement(ExpandLess_1.default, null)) : (React.createElement(ExpandMore_1.default, null));
        }
    };
    var checkSubModulesAvailbleforChild = function (mainModuleObj) {
        if (mainModuleObj.subMenu) {
            return expandSubMainModule && subModule == mainModuleObj.moduleName ? (React.createElement(ExpandLess_1.default, null)) : (React.createElement(ExpandMore_1.default, null));
        }
    };
    var overviewHandlere = function (moduleName) {
        navigate("/");
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: moduleName,
            subModule: '',
            subChaildModule: '',
        }));
        if (moduleName == mainModule) {
            setExpandMainModule(!expandMainModule);
        }
        else {
            setExpandMainModule(true);
        }
        setExpandSubMainModule(false);
    };
    var expandMainMenuHandlere = function (obj) {
        if (obj.path) {
            navigate("/".concat(obj.path));
        }
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: obj.moduleName,
            subModule: '',
            subChaildModule: '',
        }));
        if (obj.moduleName == mainModule) {
            setExpandMainModule(!expandMainModule);
        }
        else {
            setExpandMainModule(true);
        }
        setExpandSubMainModule(false);
    };
    var expandSubMenuHandlere = function (obj) {
        if (obj.path) {
            navigate("/".concat(obj.path));
        }
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: mainModule,
            subModule: obj.moduleName,
            subChaildModule: '',
        }));
        if (obj.moduleName == subModule) {
            setExpandSubMainModule(!expandSubMainModule);
        }
        else {
            setExpandSubMainModule(true);
        }
    };
    var expandSubChildMenuHandlere = function (obj) {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: mainModule,
            subModule: subModule,
            subChaildModule: obj.moduleName,
        }));
        navigate("/".concat(obj.path));
    };
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleLogout = function () {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.LOGOUT,
            showLoader: true,
            domain: process.env.AUTHENTICATION_SERVER_URL,
        })
            .then(function (res) {
            if (res.data.status === 200) {
                (0, generic_api_calls_1.removeToken)();
                react_hot_toast_1.default.success(res.data.message);
                dispatch(user_action_1.default.setLogout());
                cookies.remove('cookies_jwt_token');
                cookies.remove('cookies_refresh_token');
                navigate("/");
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var startTimer = function () {
        var timeOutIntervalInMilliSeconds = Number(process.env.IDLE_TIMEOUT) || 1200000;
        timer = setTimeout(function () {
        }, timeOutIntervalInMilliSeconds);
    };
    var eventHandler = function () {
        clearTimeout(timer);
        startTimer();
    };
    var addEvents = function () {
        __spreadArray([], events, true).forEach(function (eventName) {
            window.addEventListener(eventName, eventHandler);
        });
    };
    var removeEvents = function () {
        events.forEach(function (eventName) {
            window.removeEventListener(eventName, eventHandler);
        });
    };
    var convertedMenuList = function () {
        var filterList = (0, array_helpers_1.compareModulesToMenuOrder)(menu_module_list_1.MENU_LIST_DATA, features, featureslist, is_sso_user);
        (0, array_helpers_1.resticSubRoute)(filterList);
        setOrderedMenu(filterList);
    };
    var getCountries = function () {
        (0, generic_api_calls_1.fetchCountries)(jwt_token)
            .then(function (countriesRes) {
            dispatch((0, config_action_1.fetch_countries)(countriesRes[0]));
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var getStates = function () {
        (0, generic_api_calls_1.fetchStates)(jwt_token)
            .then(function (stateRes) {
            dispatch((0, config_action_1.fetch_states)(stateRes[0]));
        })
            .catch(function (err) {
            console.log(err, 'stateErr');
        });
    };
    var getLabList = function () {
        (0, generic_api_calls_1.fetchLabList)({}, jwt_token)
            .then(function (labListRes) {
            var convertedlabCodeResponse = (0, array_helpers_1.convertLabelAndValueLabCode)(labListRes[0]);
            convertedlabCodeResponse = lodash_1.default.uniqBy(convertedlabCodeResponse, 'warehouse_code');
            convertedlabCodeResponse = lodash_1.default.sortBy(convertedlabCodeResponse, 'warehouse_code');
            dispatch((0, config_action_1.fetch_lab_code)(convertedlabCodeResponse));
        })
            .catch(function (err) {
            console.log(err, 'labError');
        });
    };
    React.useEffect(function () {
        convertedMenuList();
        getCountries();
        getStates();
        getLabList();
    }, []);
    React.useEffect(function () {
        if (!pathname.includes("".concat(route_path_1.default.PATHS.controlTower))) {
            dispatch((0, FE_Creation_action_1.getAutoRoutingPreviousFiltees)(null));
        }
        addEvents();
        return function () {
            removeEvents();
        };
    }, [mainModule, subModule, subChaildModule]);
    var refetch = (0, react_query_1.useQuery)(['notification-count'], function () {
        var payload = {};
        (0, NotificationHelpers_1.fetchNotificationCount)(payload)
            .then(function (response) {
            setTotalCount(response.data.count);
        })
            .catch(function (err) { });
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, []);
    var notificationMessageList = function () {
        var payload = {};
        payload['is_read'] = 0;
        (0, NotificationHelpers_1.fetchNotificationMessageList)(payload)
            .then(function (response) {
            (response === null || response === void 0 ? void 0 : response.data.length) === 0
                ? setErrorMessage('No Messages Available')
                : setErrorMessage('');
            setNotificationList(response.data);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
        });
    };
    React.useEffect(function () {
        notificationMessageList();
    }, []);
    var handleListClick = function (event) {
        var payload = {};
        (payload['is_read'] = 1), (payload['object_id'] = event._id);
        (0, NotificationHelpers_1.fetchNotificationMessageUpdate)(payload);
    };
    var _l = React.useState(null), anchorE2 = _l[0], setAnchorE2 = _l[1];
    var handleClickPopOver = function (event) {
        setAnchorE2(event.currentTarget);
    };
    var handleClosePopOver = function () {
        setAnchorE2(null);
        refetch();
        notificationMessageList();
    };
    var openPopover = Boolean(anchorE2);
    var id = openPopover ? 'simple-popover' : undefined;
    var classes = (0, notification_styles_1.useStyles)();
    return (React.createElement(Box_1.default, { className: "layout-wrapper" },
        React.createElement(idle_timeout_1.default, null),
        React.createElement(material_1.Container, { maxWidth: "xl", className: "main-container", disableGutters: true },
            React.createElement("div", { className: "content-wrapper-grid" },
                React.createElement("div", { className: "side-nav-wrapper" },
                    React.createElement(Drawer_1.default, { variant: "permanent", anchor: "left", className: "side-nav-bar" },
                        React.createElement("div", { className: "logo-wrapper" },
                            React.createElement("img", { src: require('../../../assets/images/logo.svg'), alt: "lpl-logo", className: "side-bar-logo" })),
                        React.createElement("div", { className: "nav-wrapper" },
                            React.createElement("ul", { className: "nav-list-wrapper" },
                                React.createElement(material_1.ListItemButton, { className: mainModule == 'Overview' ? 'active' : '', onClick: function () { return overviewHandlere('Overview'); } },
                                    React.createElement(material_1.ListItemIcon, null,
                                        React.createElement("span", { className: "icon-wrapper" },
                                            React.createElement("span", { className: 'icon-graph' }, " "))),
                                    React.createElement(material_1.ListItemText, { primary: 'Overview' })),
                                orderedMenu.map(function (mainObj, index) {
                                    return (React.createElement(React.Fragment, null,
                                        React.createElement(material_1.ListItemButton, { key: index, className: mainModule == mainObj.moduleName ? 'active' : '', onClick: function () { return expandMainMenuHandlere(mainObj); } },
                                            React.createElement(material_1.ListItemIcon, null,
                                                React.createElement("span", { className: "icon-wrapper" },
                                                    React.createElement("span", { className: mainObj.menuicon }, " "))),
                                            React.createElement(material_1.ListItemText, { primary: mainObj.moduleName }),
                                            checkSubmodulesAvailble(mainObj)),
                                        mainObj.subMenu &&
                                            mainObj.subMenu.map(function (subObj) {
                                                return (React.createElement(material_1.Collapse, { key: subObj.moduleName, in: expandMainModule && mainModule == mainObj.moduleName, timeout: "auto", unmountOnExit: true },
                                                    React.createElement(material_1.List, { component: "div", disablePadding: true },
                                                        React.createElement(material_1.ListItemButton, { className: subModule == subObj.moduleName ? 'active' : '', onClick: function () { return expandSubMenuHandlere(subObj); }, sx: {
                                                                pl: 4,
                                                            } },
                                                            React.createElement(material_1.ListItemIcon, null,
                                                                React.createElement("span", { className: "icon-wrapper" },
                                                                    React.createElement("span", { className: subObj.menuicon }, " ")),
                                                                ' '),
                                                            React.createElement(material_1.ListItemText, { primary: subObj.moduleName }),
                                                            checkSubModulesAvailbleforChild(subObj)),
                                                        subObj.subMenu &&
                                                            subObj.subMenu.map(function (subChildObj) {
                                                                return (React.createElement(material_1.Collapse, { key: subChildObj.moduleName, in: expandSubMainModule && subModule == subObj.moduleName, timeout: "auto", unmountOnExit: true },
                                                                    React.createElement(material_1.List, { component: "div", disablePadding: true },
                                                                        React.createElement(material_1.ListItemButton, { className: subChaildModule == subChildObj.moduleName
                                                                                ? 'active'
                                                                                : '', sx: {
                                                                                pl: 8,
                                                                            }, onClick: function () { return expandSubChildMenuHandlere(subChildObj); } },
                                                                            React.createElement(material_1.ListItemText, { primary: subChildObj.moduleName })))));
                                                            }))));
                                            })));
                                }))))),
                React.createElement("div", { className: "main-content" },
                    React.createElement("nav", { className: "main-header" },
                        React.createElement("div", { className: "control-wrapper" },
                            React.createElement("div", { className: "header-controller" },
                                React.createElement(IconButton_1.default, { className: "mail-icon-wrapper app-controllers" },
                                    React.createElement("span", { className: "icon-question icons" })),
                                React.createElement(IconButton_1.default, { className: "notify-icon-wrapper app-controllers", onClick: handleClickPopOver },
                                    React.createElement(Badge_1.default, { badgeContent: totalCount, color: "error" },
                                        React.createElement("span", { className: "icon-bell  icons " }))),
                                React.createElement(material_1.Popover, { PaperProps: { className: classes.customPopover }, open: openPopover, id: id, anchorEl: anchorE2, onClose: handleClosePopOver, anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    } },
                                    React.createElement("div", { className: "inner-container" },
                                        React.createElement(material_1.List, null, (notificationList === null || notificationList === void 0 ? void 0 : notificationList.length) > 0 ? (notificationList &&
                                            notificationList.map(function (message) {
                                                return (React.createElement(material_1.ListItem, { alignItems: "flex-start", disabled: message.is_read === 1, key: message.notificationTitle, onClick: function () { return handleListClick(message); } },
                                                    React.createElement(material_1.ListItemText, { primary: message.title, secondary: message.message })));
                                            })) : (React.createElement("div", { className: "no-data-conatiner-notification" },
                                            React.createElement("div", { className: "no-data-badge" }, errorMessage ? errorMessage : 'No Messages Available')))),
                                        React.createElement(material_1.Link, { component: "button", variant: "body2" }))),
                                React.createElement(IconButton_1.default, { className: "user-name-btn", onClick: handleClick },
                                    React.createElement("span", { className: "icon-user" }),
                                    React.createElement("p", { className: "user-name-indicator", title: vendorName }, vendorName.toUpperCase())),
                                React.createElement(Menu_1.default, { className: "menu-dropdown main-dropdown", anchorEl: anchorEl, open: open, onClose: handleClose },
                                    React.createElement(MenuItem_1.default, { onClick: handleClose, className: "dropdown-item" },
                                        React.createElement("span", { className: "icon-user item-icon" }),
                                        React.createElement("p", { className: "profile-user-name", onClick: navigateToProfile }, "View Profile")),
                                    React.createElement(MenuItem_1.default, { onClick: handleClose, className: "dropdown-item" },
                                        React.createElement("span", null,
                                            React.createElement(Password_1.default, { className: "item-icon" })),
                                        React.createElement("p", { className: "profile-user-name", onClick: navigateToChangePassword }, "Change Password")),
                                    React.createElement(MenuItem_1.default, { onClick: handleLogout, className: "dropdown-item" },
                                        React.createElement("span", { className: "icon-Exit item-icon" }),
                                        "Logout"),
                                    React.createElement("div", { className: "verison-number" }, "v0.0.120a"))))),
                    React.createElement(routes_1.default, null))))));
};
exports.default = MenuLayout;
