"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAutoRoutingPreviousFiltees = exports.checkIsNewVendor = exports.updateReplaceId = exports.updateUserID = exports.createNewFE = exports.AUTOROUTING_ACTIONS = exports.FE_CREATION_ACTIONS = void 0;
exports.FE_CREATION_ACTIONS = {
    FECreation: 'FECreation',
    saveUserID: 'saveUserID',
    isNewVendor: 'IS_NEW_VENDOR',
    isReplaceId: 'isReplaceId',
};
exports.AUTOROUTING_ACTIONS = {
    AUTO_ROUTING_FILTERS: 'AUTO_ROUTING_FILTERS',
};
var createNewFE = function (data) { return ({
    type: exports.FE_CREATION_ACTIONS.FECreation,
    payload: data,
}); };
exports.createNewFE = createNewFE;
var updateUserID = function (data) { return ({
    type: exports.FE_CREATION_ACTIONS.saveUserID,
    payload: data,
}); };
exports.updateUserID = updateUserID;
var updateReplaceId = function (data) { return ({
    type: exports.FE_CREATION_ACTIONS.isReplaceId,
    payload: data,
}); };
exports.updateReplaceId = updateReplaceId;
var checkIsNewVendor = function (isNewVendor) { return ({
    type: exports.FE_CREATION_ACTIONS.isNewVendor,
    payload: isNewVendor,
}); };
exports.checkIsNewVendor = checkIsNewVendor;
var getAutoRoutingPreviousFiltees = function (selectedFilters) {
    console.log(selectedFilters, 'selectedFilters');
    return {
        type: exports.AUTOROUTING_ACTIONS.AUTO_ROUTING_FILTERS,
        payload: selectedFilters,
    };
};
exports.getAutoRoutingPreviousFiltees = getAutoRoutingPreviousFiltees;
