"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var route_path_1 = require("../../../../constants/route_path");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var ViewRoutePlanner = function (_a) {
    var routeDetails = _a.routeDetails;
    var nav = (0, react_router_1.useNavigate)();
    var pathname = (0, react_router_1.useLocation)().pathname;
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Route_Planner);
    var veiewRoutePlanner = function () {
        nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.create_route_planner, "/").concat(routeDetails.id));
    };
    var veiewAPlanner = function () {
        if (pathname.includes("".concat(route_path_1.PATHS.approvals_route_planner))) {
            nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.approveProfileDetails, "/").concat(routeDetails.id));
        }
        else {
            nav("/".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_approved_details, "/").concat(routeDetails.id));
        }
    };
    var renderButtonBasedOnStatus = function (status) {
        switch (status) {
            case 'Ready for review':
                return (React.createElement(button_component_1.default, { buttonName: 'Review Route', onClickHandler: veiewRoutePlanner, isError: !features[menu_constants_1.FEATURE_CONSTANTS.routeEdit] }));
            case 'Approved':
                return (React.createElement(button_component_1.default, { buttonName: 'View Route', onClickHandler: veiewAPlanner }));
            default:
                return '--';
        }
    };
    return React.createElement("div", null, renderButtonBasedOnStatus(routeDetails.status));
};
exports.default = ViewRoutePlanner;
