"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateHubDetails = exports.createHubslot = exports.createHub = exports.getScheduleList = exports.deleteHub = exports.fetchHubListing = void 0;
var react_hot_toast_1 = require("react-hot-toast");
var api_service_1 = require("../../../../api/api_service");
var fetchHubListing = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.hub_master_list,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (response) {
            var _a;
            if (response.status === 200) {
                return resolve((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchHubListing = fetchHubListing;
var deleteHub = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.deleteHub, "?hub_code=").concat(payload),
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.deleteHub = deleteHub;
var getScheduleList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.SCHEDULE_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getScheduleList = getScheduleList;
var createHub = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CREATE_HUB,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.createHub = createHub;
var createHubslot = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CREATE_HUB_SLOTS,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.createHubslot = createHubslot;
var updateHubDetails = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.EDIT_HUB,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                return resolve(res.data);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.updateHubDetails = updateHubDetails;
