"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var cre_api_calls_1 = require("../cre_api_calls");
var react_query_1 = require("react-query");
var CREActiveList = function (_a) {
    var pageLoad = _a.pageLoad, searchTerm = _a.searchTerm, selectedLab = _a.selectedLab, PageLimit = _a.PageLimit, setCount = _a.setCount, setData = _a.setData, filter = _a.filter;
    var _b = React.useState(undefined), dataList = _b[0], setDataList = _b[1];
    var _c = React.useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var refetch = (0, react_query_1.useQuery)(['Precious-Sample-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (searchTerm.trim().length >= 3) {
            payload.search_string = searchTerm.trim();
        }
        if (selectedLab) {
            payload.lab_code = selectedLab.value;
        }
        setCount(currentPage);
        payload.page = currentPage;
        payload.size = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, cre_api_calls_1.fetchCreUsersList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setErrorMessage("");
            setTotalPageCount(count);
            var convertedData = (0, array_helpers_1.convertCREListFromRes)(response === null || response === void 0 ? void 0 : response.result);
            setDataList(convertedData);
            setData(convertedData);
            UpdateListData.setIsListReloaded(false);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setDataList([]);
        });
    }).refetch;
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    React.useEffect(function () {
        refetch();
    }, [UpdateListData.isListReloaded]);
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [filter]);
    return (React.createElement("div", { className: 'unclickablerow' },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.CRE_ACTIVE_LIST, rows: dataList && dataList, errorMessage: errorMessage, pagination: false, className: 'cre-table' }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })));
};
exports.default = CREActiveList;
