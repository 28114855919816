"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../../actions/config_action");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var modal_component_1 = require("../../../../common/modal/modal_component");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var approval_api_service_1 = require("../approval_api_service");
var RejectUser = function (_a) {
    var user_id = _a.user_id, buttonName = _a.buttonName, fe_name = _a.fe_name, endPoint = _a.endPoint, domain = _a.domain, fe_id = _a.fe_id, isBtnDisabled = _a.isBtnDisabled, approvalStatus = _a.approvalStatus, onBoardStatus = _a.onBoardStatus;
    var nav = (0, react_router_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var fe_reject_reasons_list = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.fe_reject_reasons_list; });
    var reasonToBeHidden = onBoardStatus === 1 ? 2 : 3;
    var filtered_fe_reject_reasons_list = fe_reject_reasons_list.filter(function (singleReason) { return singleReason.id != reasonToBeHidden; });
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    var initialValues = {
        reject_type: '',
        reject_comment: '',
    };
    var formSubmitHandler = function (values) {
        var FElevelOneApprovalPayload = {
            user_id: user_id,
            reject_type: values.reject_type.id,
            reject_comment: values.reject_comment,
        };
        var vendorLevelOneApprovalPayload = {
            vendor_id: user_id,
            reject_type: values.reject_type.id,
            reject_comment: values.reject_comment,
        };
        if (location.pathname.includes("".concat(route_path_1.default.PATHS.vendormanagement))) {
            (0, approval_api_service_1.levelOneRejectApproval)(endPoint, vendorLevelOneApprovalPayload, domain)
                .then(function (res) {
                if (res.statusCode === 200 || res.statusCode === 201) {
                    react_hot_toast_1.default.success(res.message);
                    dispatch((0, config_action_1.featchCurrentActiveMenu)({
                        mainModule: 'Master Management',
                        subModule: menu_constants_1.FEATURE_CONSTANTS.vendorManagement,
                        subChaildModule: '',
                    }));
                    nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.vendor_reject), {
                        replace: true,
                    });
                    handleClose();
                }
            })
                .catch(function (e) {
                react_hot_toast_1.default.error(e.message);
                console.log(e);
            });
        }
        else {
            (0, approval_api_service_1.levelOneRejectApproval)(endPoint, FElevelOneApprovalPayload, domain)
                .then(function (res) {
                if (res.statusCode === 200 || res.statusCode === 201) {
                    react_hot_toast_1.default.success(res.message);
                    if (onBoardStatus === 1) {
                        dispatch((0, config_action_1.featchCurrentActiveMenu)({
                            mainModule: menu_constants_1.FEATURE_CONSTANTS.feManagement,
                            subModule: menu_constants_1.FEATURE_CONSTANTS.feManagement,
                            subChaildModule: '',
                        }));
                        nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.activeList), {
                            replace: true,
                        });
                    }
                    else {
                        dispatch((0, config_action_1.featchCurrentActiveMenu)({
                            mainModule: menu_constants_1.FEATURE_CONSTANTS.feManagement,
                            subModule: menu_constants_1.FEATURE_CONSTANTS.feManagement,
                            subChaildModule: '',
                        }));
                        nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.feRejected), {
                            replace: true,
                        });
                    }
                    handleClose();
                }
            })
                .catch(function (e) {
                react_hot_toast_1.default.error(e.message);
            });
        }
    };
    return (React.createElement("div", null,
        React.createElement(modal_component_1.default, { buttonColor: "yellow-outline", buttonName: buttonName, onClickHandler: handleOpen, open: open, isBtnDisabled: isBtnDisabled },
            React.createElement("div", null,
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("h2", { className: "heading" },
                        "Rejection of ",
                        fe_name,
                        " (",
                        fe_id,
                        ")"))),
            React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, validationSchema: schema_1.schemas.LEVEL_ONE_REJECT_SCHEMA, onSubmit: formSubmitHandler }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldTouched = _a.setFieldTouched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement(drop_down_component_1.default, { name: "reject_type", placeholder: "Reason", dropDownList: filtered_fe_reject_reasons_list, onChange: function (val) {
                                    setFieldValue('reject_type', val);
                                    setFieldTouched('reject_type', true);
                                }, value: values.reject_type }),
                            errors['reject_type'] && touched['reject_type'] ? (React.createElement("div", { className: "error" }, errors['reject_type'])) : null),
                        React.createElement(Grid_1.default, { item: true, xs: 12 },
                            React.createElement(text_filed_component_1.default, { name: "reject_comment", maxLength: 200, onChange: function (reject_comment) {
                                    setFieldTouched('reject_comment');
                                    setFieldValue('reject_comment', reject_comment);
                                }, label: "Other Comments", value: values.reject_comment }),
                            errors['reject_comment'] && touched['reject_comment'] ? (React.createElement("div", { className: "error" }, errors['reject_comment'])) : null)),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
            }))));
};
exports.default = RejectUser;
