"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.preparePayloadForRevokeLeave = exports.preparePayloadForApproveLeave = exports.preparePayloadForLeaveRequest = exports.preparePayloadForSearchByFe = exports.reasonsList = void 0;
var moment = require("moment");
var appConstants_1 = require("../../../constants/appConstants");
exports.reasonsList = [
    { id: 1, label: 'SICK' },
    { id: 2, label: 'PERSONAL' },
    { id: 3, label: 'VACATION' },
    { id: 4, label: 'EMERGENCY' },
];
var preparePayloadForSearchByFe = function (searchValue) {
    var payload;
    if (searchValue) {
        payload = {
            onboard_status: [appConstants_1.FE_USER_TYPES.active],
            search_string: searchValue,
        };
    }
    else {
        payload = {
            onboard_status: [appConstants_1.FE_USER_TYPES.active],
        };
    }
    return payload;
};
exports.preparePayloadForSearchByFe = preparePayloadForSearchByFe;
var preparePayloadForLeaveRequest = function (requestObj, vendorId, totalDays) {
    var payload = {
        from_date: moment(requestObj.fromDate).format('YYYY-MM-DD'),
        to_date: moment(requestObj.toDate).format('YYYY-MM-DD'),
        comment: requestObj.comments,
        number_of_days: totalDays,
        user_id: requestObj.searchByFe.id,
        reason_id: requestObj.reason.id,
    };
    return payload;
};
exports.preparePayloadForLeaveRequest = preparePayloadForLeaveRequest;
var preparePayloadForApproveLeave = function (requestObj, values, vendorId) {
    var payload = {
        id: requestObj.leave_id,
        comment: values.comments,
    };
    return payload;
};
exports.preparePayloadForApproveLeave = preparePayloadForApproveLeave;
var preparePayloadForRevokeLeave = function (requestObj, status, comment) {
    var payload = {
        id: requestObj.leave_id,
        status: status,
        comment: comment,
    };
    return payload;
};
exports.preparePayloadForRevokeLeave = preparePayloadForRevokeLeave;
