"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROFILE_DETAILE_LABLES = exports.SHIPMENT_TRACKING_LABELS = void 0;
var PROFILE_HEADINGS = [
    'Personal Details',
    'Reference Numbers',
    'Contact Details',
    'Current Address',
    'Bank Account Details',
    'Driving Licence Details',
    'Employment Details',
    'Assigned Inventory',
    'Permanent Address',
    'Assign Route Details',
    'Additional Roles',
];
var VENDOR_PROFILE_HEADINGS = [
    'Vendor Details',
    'Contact Details',
    'List of FE’s',
    'Office Address',
    'Registered Address',
    'Contract Details',
];
var SAMPLE_BAG_HEADINGS = [
    'Basic Information',
    'Process Data',
    'Other Information of the Bag',
    'Sample Tracking',
    'Tracking Bag Details',
    'Sample Tracking Details',
    'Tracking Masterbox Details',
];
var date = new Date();
var PROFILE_DATA = {
    firstname: 'revanth',
    lastname: 'Kumar',
    fecode: 'FD33353R',
    dob: date,
    feimage: 'image',
    referenceName1: 'Sake',
    referenceMobileno1: 1234567899,
    referenceName2: 'Sake',
    referenceMobileno2: 1234567899,
    mobilenumber: 1234567898,
    email: 'sake@gmail.com',
    emeregencyContact: 9876543222,
    line1: 'HYD',
    line2: 'HYD',
    landmark: 'water tank',
    state: 'Telangana',
    city: 'HYD',
    country: 'India',
    pincode: 767888,
    accountNo: 123456789879,
    ifscCode: '76GGG00',
    branchName: 'SBI',
    upi: 'Sake@djkj888',
    vehicleType: 'Motor Cycle',
    drivingLicence: 324576543245,
    drivingLicenceExDate: date,
    insurencNo: 2345678987654,
    insurencExDate: date,
    uploadInsurence: 'image',
    feType: 'Partime',
    doj: date,
    company: 'Xcube',
    tenure: 'FEB',
    labCode: '334ddd',
    labName: 'Diagnostic Lab',
    regionalManager: 'siva',
    zone: 'South Zone',
    payments: 'online',
    feWorkingHub: 'Kondapur',
    workingCity: 'HYD',
    feWorkingCode: 'FH37JJ',
    vendorName: 'Suresh',
    isRouteAssigned: true,
    assignInventories: ['Rain Coat', 'Mobile', 'ID Card', 'Bike', 'Sensor'],
};
var PROFILE_LABLES = {
    firstname: 'First Name',
    lastname: 'Last Name',
    fecode: 'FE Code',
    feId: 'FE ID',
    dob: 'Date of Birth',
    feimage: 'FE Profile Pics',
    referenceName1: 'Name 1',
    referenceMobileno1: 'Contact Number1',
    referenceName2: 'Name 2',
    referenceMobileno2: 'Contact Number2',
    mobilenumber: 'Mobile Number',
    email: 'Email Address',
    emeregencyContact: 'Emergency Contact Number',
    line1: 'Address Lane 1',
    line2: 'Address Lane 2',
    landmark: 'Landmark',
    state: 'State',
    city: 'City',
    country: 'Country',
    pincode: 'Pincode',
    addressProof: 'Address Proof',
    accountNo: 'A/C Number',
    ifscCode: 'IFSC Code',
    branchName: 'Branch Name',
    upi: 'UPI ID',
    vehicleType: 'Vehicle Type',
    drivingLicence: 'Driving Licence',
    drivingLicenceExDate: 'Driving Licence Expiry Date',
    insurencNo: 'Insurance Number',
    insurencExDate: 'Insurance Expiry Date',
    uploadInsurence: 'Insurance Documents',
    drivingLicenceDetailsDOc: 'Driving Licence Document',
    drivingDoc: 'Driving Licence Document',
    feType: 'FE Type',
    doj: 'Date of Joining',
    company: 'Company',
    tenure: 'Tenure',
    labCode: 'Lab Code',
    labName: 'Lab Name',
    regionalManager: 'Regional Manager',
    zone: 'Zone',
    payments: 'Payment',
    feWorkingHub: 'Working Hub',
    labCity: 'Lab City',
    feWorkingCode: 'FE Vendor Code',
    vendorName: 'Vendor Name',
    isRouteAssigned: 'Assigned Route',
    assignInventories: 'Assigned Inventory',
    bankAccountDocument: 'Bank Details proof',
    baseLocationAddress: 'Base Location Address',
    employee_vendor_id: 'Employee Vendor ID',
    assignRouteType: 'Assign Route Type',
    gender: 'Gender',
    addressProofType: 'Address Proof Type',
    bankName: 'Bank Name',
    bankBranch: 'Bank Branch',
    hub: 'HUB',
    baseLocationCoordinates: 'Base Location Coordinates',
    labState: 'Lab State',
    additionalRole: 'Role',
    vendorCode: 'Vendor Code',
    employmentID: 'Employment ID',
    feWorkingState: "FE Working State",
    feWorkingCity: "FE Working City"
};
var VENDOR_PROFILE_DETAILS = {
    vendor_name: 'Vendor  Name',
    entity_type_name: 'Entity Type',
    contract_duration: 'Contract Duration',
    operating_state: 'Operating State (s)',
    operating_city: 'Operating City (s)',
    contract_end_date: 'Contract End Date',
    firstname: 'First Name',
    mobilenumber: 'Mobile Number',
    alternateMobile: 'Alternate Mobile',
    email: 'Email ID',
    website: 'Website',
    addressline1: 'Address  Lane 1',
    addressline2: 'Address  Lane 2',
    designation: 'Designation',
    landmark: 'Landmark',
    state: 'State',
    city: 'City',
    country: 'Country',
    pincode: 'Pincode',
    contract_type: 'Contract Type',
    contract_start_date: 'Contract Start Date',
    contract_expiry_date: 'Contract End date',
    total_sourcing_fe: 'Total Source FE',
    contract_state: 'Operating State',
    contract_city: 'Operating City',
    contract_doc: 'Contract Doc',
    contat_nmae: 'Contact Name',
    parent_contract_id: 'Parent Contract ID',
};
var SAMPLE_BAG_DETAILS = {
    bag_id: 'Bag Id',
    lab_handover_to: 'Lab Handover To',
    route_type: 'Route Type',
    bag_created_by: 'Bag Created By',
    current_status: 'Current Status',
    pickup_bag_photo: 'Pickup Bag Photo',
    lab_handover_photo: 'Lab Handover Photo',
    center_name: 'Centre Name',
    center_code: 'Centre Code',
    center_state: 'Center_state',
    center_city: 'Center_city',
    bag_creation_time: 'Bag Creation Time',
    lab_code: 'Lab Code',
    lab_name: 'Lab Name',
    lab_state: 'Lab State',
    lab_city: 'Lab City',
    pickup_fe_code: 'Pickup FE Code',
    pickup_fe_name: 'FE Name',
    pickup_fe_state: 'FE State',
    pickup_fe_city: 'FE City',
    connector1_fe_code: 'Connector1 Fe Code',
    connector2_fe_code: 'Connector2 Fe Code',
    connector1_fe_name: 'Connector1 Fe Name',
    connector2_fe_name: 'Connector2 Fe Name',
    connector_fe_state: 'CC State',
    connector_fe_city: 'CC City',
    scheduled_pickup_date: 'Scheduled_pickup_date',
    scheduled_pickup_time: 'Scheduled  Pickup Time',
    actual_pickup_time: 'Actual Pickup Time',
    pickup_coordinates: 'Pick up Fe Lat& Long',
    latitude: 'Latitude',
    longitude: 'Longitude',
    scheduled_hub_handover_date: 'Scheduled Hub Handover Date',
    scheduled_hub_handover_time: 'Scheduled Hub Handover Time',
    actual_hub_handover_time: 'Actual Hub Handover Time',
    hub_handover_coordinates: 'Hub Receiving Lat & Long',
    scheduled_lab_handover_date: 'Scheduled_lab_handover_date',
    scheduled_lab_handover_time: 'Scheduled Lab Handover Time',
    actual_lab_handover_time: 'Actual_lab_handover_time',
    lab_handover_coordinates: 'Lab Handover Lat & Long',
    lab_number_count: 'Lab No. Count',
    tube_count: 'Tube Count',
    tube_type: 'Tube Type',
    tube_code: 'Tube Code',
    samples: 'Samples',
    unique_tracking_id: 'Unique Tracking Id',
    lab_number: 'Lab Number',
    tube_color: 'Tube Color',
    bag_handed_over_from_type: 'bag_handed_over_from_type',
    bag_handed_over_from_id: 'bag_handed_over_from_id',
    bag_handed_over_to_type: 'bag_handed_over_to_type',
    bag_handed_over_to_id: 'bag_handed_over_to_id',
    timestamp: 'timestamp',
    registration_status_at_scan: 'Registration Status at the time of Bag Creation',
    registration_timestamp: 'Registration Time',
    tube_colour_code: 'Tube Colour Code',
    current_registration_status: 'Current Registration Status',
};
var MASTERBOXDETAILS_HEADING = [
    'Basic Information',
    'Process Data',
    'Bag Tracking',
];
var MASTERBOX_DETAILS = {
    masterBoXID: 'Masterbox ID',
    currentStatus: 'Current Status',
    masterBoxPhoto: 'Master Box Photo',
    labHandoverPhoto: 'Lab Handover Photo',
    intercityHandoverPhoto: 'Intercity Handover Photo',
    intercityreceivingPhoto: 'Intercity Receiving Photo',
    bagNoCount: 'Bag No Count',
    boxCreationTime: 'Box Creation Time',
    labCode: 'Lab Code',
    labName: 'Lab Name',
    labState: 'Lab State',
    labCity: 'Lab City',
    pickupFECode: 'Pickup FE Code',
    feName: 'FE Name',
    feState: 'FE State',
    feCity: 'FE City',
    ccState: 'CC State',
    ccCity: 'CC City',
};
exports.SHIPMENT_TRACKING_LABELS = {
    basicInformation: 'Basic Information',
    processData: 'Process Data',
    otherInfo: 'Other Information of the Bag',
    shipmentTracking: 'Shipment Tracking',
    masterShipmentBarcode: 'Master Shipment Barcode',
    currentStatus: 'Current Status',
    shipmentPhoto: 'Shipment Photo',
    connectorHandoverPhoto: 'Connector Handover Photo',
    hubHandoverPhoto: 'Hub Handover Photo',
    hubReceivingPhoto: 'Hub Receiving Photo',
    labHandoverPhoto: 'Lab Handover Photo',
    rlabCode: 'R Lab Code',
    pLabCode: 'P Lab Code',
    rLabState: 'R Lab State',
    pLabState: 'P Lab State',
    rLabCity: 'R Lab City',
    pLabCity: 'P Lab City',
    rLabName: 'R Lab Name',
    pLabName: 'P Lab Name',
    noOfBoxes: 'No. Of Boxes',
    creationTime: 'Master Shipment Creation Time',
    pickupCutOffTime: 'Pickup Cut Off Time',
    DesignatedHittingTime: 'Designated Hitting Time',
    tat: 'TAT',
    tatBreach: 'TAT Breach',
    tatBreachedBy: 'TAT Breached by',
    pickUpFECode: 'Pickup FE Code',
    pickupFEName: 'Pickup FE Name',
    connectorFECode: 'Connector FE Code',
    connectorFEName: 'Connector FE Name',
    FEState: 'FE State',
    FECity: 'FE City',
    actualPickupTime: 'Actual Pick-up Time',
    actualHubHandoverTime: 'Actual Hub Handover Time',
    actualpLabHandoverTime: 'Actual P Lab Handover Time',
    pickupFELatLong: 'Pickup FE Lat & Long',
    hubReceivingLatLong: 'Hub Receiving Lat & Long',
    labHandoverLatLong: 'Lab Handover Lat & long',
    shipmentBarcodeNo: 'Shipment Barcode No.',
    noOfSamples: 'No. Of Samples',
    shipmentCreationTime: 'Shipment Creation Time',
    labNo: 'Lab No.',
    tubeType: 'Tube Type',
    tubeTypeCount: 'Tube Type Count',
    shipmentTrackingDetails: 'Shipment Tracking Details',
};
var MASTER_BOX_DETAILS = {
    Masterbox_id: 'Masterbox Id',
    lab_handover_to: 'Lab Handover To',
    route_type: 'Route Type',
    bag_created_by: 'Bag Created By',
    current_status: 'Current Status',
    pickup_bag_photo: 'Pickup Bag Photo',
    lab_handover_photo: 'Lab Handover Photo',
    center_name: 'Centre Name',
    center_code: 'Centre Code',
    center_state: 'Center_state',
    center_city: 'Center_city',
    bag_creation_time: 'Bag Creation Time',
    lab_code: 'Lab Code',
    lab_name: 'Lab Name',
    lab_state: 'Lab State',
    lab_city: 'Lab City',
    pickup_fe_code: 'Pickup FE Code',
    pickup_fe_name: 'FE Name',
    pickup_fe_state: 'FE State',
    pickup_fe_city: 'FE City',
    connector1_fe_code: 'Connector1 Fe Code',
    connector2_fe_code: 'Connector2 Fe Code',
    connector1_fe_name: 'Connector1 Fe Name',
    connector2_fe_name: 'Connector2 Fe Name',
    connector_fe_state: 'CC State',
    connector_fe_city: 'CC City',
    scheduled_pickup_date: 'Scheduled_pickup_date',
    scheduled_pickup_time: 'Scheduled  Pickup Time',
    actual_pickup_time: 'Actual Pickup Time',
    pickup_coordinates: 'Pick up Fe Lat& Long',
    latitude: 'Latitude',
    longitude: 'Longitude',
    scheduled_hub_handover_date: 'Scheduled Hub Handover Date',
    scheduled_hub_handover_time: 'Scheduled Hub Handover Time',
    actual_hub_handover_time: 'Actual Hub Handover Time',
    hub_handover_coordinates: 'Hub Receiving Lat & Long',
    scheduled_lab_handover_date: 'Scheduled_lab_handover_date',
    scheduled_lab_handover_time: 'Scheduled Lab Handover Time',
    actual_lab_handover_time: 'Actual_lab_handover_time',
    lab_handover_coordinates: 'Lab Handover Lat & Long',
    lab_number_count: 'Lab No. Count',
    tube_count: 'Tube Count',
    tube_type: 'Tube Type',
    tube_code: 'Tube Code',
    samples: 'Samples',
    unique_tracking_id: 'Unique Tracking Id',
    lab_number: 'Lab Number',
    tube_color: 'Tube Color',
    bag_handed_over_from_type: 'bag_handed_over_from_type',
    bag_handed_over_from_id: 'bag_handed_over_from_id',
    bag_handed_over_to_type: 'bag_handed_over_to_type',
    bag_handed_over_to_id: 'bag_handed_over_to_id',
    timestamp: 'timestamp',
    registration_status_at_scan: 'Registration Status at the time of Bag Creation',
    registration_timestamp: 'Registration Time',
    tube_colour_code: 'Tube Colour Code',
    current_registration_status: 'Current Registration Status',
};
var MASTER_BOX_HEADINGS = [
    'Basic Information',
    'Process Data',
    'Other Information of the Box',
    'Box Tracking',
    'Tracking Bag Details',
    'Sample Tracking Details',
];
exports.PROFILE_DETAILE_LABLES = {
    PROFILE_DATA: PROFILE_DATA,
    PROFILE_HEADINGS: PROFILE_HEADINGS,
    PROFILE_LABLES: PROFILE_LABLES,
    VENDOR_PROFILE_DETAILS: VENDOR_PROFILE_DETAILS,
    VENDOR_PROFILE_HEADINGS: VENDOR_PROFILE_HEADINGS,
    SAMPLE_BAG_DETAILS: SAMPLE_BAG_DETAILS,
    SAMPLE_BAG_HEADINGS: SAMPLE_BAG_HEADINGS,
    MASTER_BOX_HEADINGS: MASTER_BOX_HEADINGS,
    MASTER_BOX_DETAILS: MASTER_BOX_DETAILS,
    MASTERBOXDETAILS_HEADING: MASTERBOXDETAILS_HEADING,
    MASTERBOX_DETAILS: MASTERBOX_DETAILS,
    SHIPMENT_TRACKING_LABELS: exports.SHIPMENT_TRACKING_LABELS,
};
exports.default = exports.PROFILE_DETAILE_LABLES;
