"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var vertical_stepper_1 = require("../../../common/vertical_stepper/vertical_stepper");
var FEScheduleDetails = function (_a) {
    var trackFEList = _a.trackFEList, role_id = _a.role_id, setIsBypassModalOpen = _a.setIsBypassModalOpen, setVisitId = _a.setVisitId;
    console.log(trackFEList, 'trackFEList', role_id);
    return (React.createElement(React.Fragment, null, trackFEList && trackFEList.trackList.length != 0 ? (React.createElement("div", { className: "fe-track-wrapper" },
        React.createElement("div", { className: "tab-heaidng displayflex border-bottom sticky-position " },
            React.createElement("div", { className: "tab-heading-container" },
                React.createElement("h2", null, "Pickups & Handovers"))),
        React.createElement(vertical_stepper_1.default, { clickAble: false, trackerInfo: trackFEList && trackFEList, role_id: role_id, setIsBypassModalOpen: setIsBypassModalOpen, setVisitId: setVisitId }))) : (React.createElement("div", { className: "no-data-conatiner" },
        React.createElement("div", { className: "no-data-badge" }, "No Data Available")))));
};
exports.default = FEScheduleDetails;
