"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i18next_1 = require("i18next");
var i18next_browser_languagedetector_1 = require("i18next-browser-languagedetector");
var react_i18next_1 = require("react-i18next");
i18next_1.default &&
    i18next_1.default
        .use(i18next_browser_languagedetector_1.default)
        .use(react_i18next_1.initReactI18next)
        .init({
        debug: false,
        fallbackLng: 'en',
        preload: ['en'],
        saveMissing: true,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    welcome: 'Welcome to LPL',
                    hello: 'Hellow LPL',
                },
            },
        },
    });
exports.default = i18next_1.default;
