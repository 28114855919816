"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var iconButton_component_1 = require("../common/buttons/iconButton_component");
var status_buttons_1 = require("../common/buttons/status_buttons");
var profile_grid_1 = require("../common/profile_grid/profile_grid");
var array_helpers_1 = require("../utils/array_helpers");
var user_profile_api_calls_1 = require("./user_profile_api_calls");
var UserProfile = function () {
    var entity_type = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.entity_type; });
    var _a = React.useState(), userDetails = _a[0], setUserDetails = _a[1];
    var nav = (0, react_router_dom_1.useNavigate)();
    React.useEffect(function () {
        (0, user_profile_api_calls_1.fetchUserProfileDeatils)()
            .then(function (response) {
            var userProfileDatails = (0, array_helpers_1.convertUserDatails)(response, entity_type);
            setUserDetails(userProfileDatails);
        })
            .catch(function (err) { return console.log(err); });
    }, []);
    return (React.createElement("div", { className: "page-wrapper" },
        React.createElement("div", { className: "vendor-profile-details fe-wrapper page-wrapper" },
            React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom" },
                React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                    React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: function () { return nav(-1); } }),
                    React.createElement("h2", null, "Profile Details"))),
            React.createElement(material_1.Grid, { container: true, xs: 12, className: "details-tab-wrapper" },
                React.createElement(material_1.Grid, { item: true, xs: 12 },
                    React.createElement("div", { className: "details-wrapper vendor-details-wrapper" },
                        React.createElement("div", { className: "user-card displayflex  " },
                            React.createElement("div", { className: "user-details-wrapper displayflex " },
                                React.createElement("div", { className: "avatar-wrapper" },
                                    React.createElement("p", { className: "user-intial" }, userDetails === null || userDetails === void 0 ? void 0 :
                                        userDetails.name.charAt(0).toUpperCase(),
                                        ' ')),
                                React.createElement("div", { className: "user-name-wrapper" },
                                    React.createElement("p", { className: "user-name" }, userDetails === null || userDetails === void 0 ? void 0 : userDetails.name))),
                            React.createElement(status_buttons_1.default, { status: userDetails === null || userDetails === void 0 ? void 0 : userDetails.onBoardStatus })),
                        React.createElement("div", { className: "vendor-deatils-tab approve-pro-details" },
                            React.createElement(material_1.Grid, { container: true, spacing: 0, columns: { xs: 18 } },
                                React.createElement(material_1.Grid, { className: "tab-heading-wrapper", item: true, xs: 18 }),
                                React.createElement(profile_grid_1.default, { lableName: "Name", value: userDetails === null || userDetails === void 0 ? void 0 : userDetails.name }),
                                React.createElement(profile_grid_1.default, { lableName: "Mobile Number", value: userDetails === null || userDetails === void 0 ? void 0 : userDetails.phone }),
                                React.createElement(profile_grid_1.default, { lableName: "Email ID", value: userDetails === null || userDetails === void 0 ? void 0 : userDetails.email }),
                                React.createElement(profile_grid_1.default, { lableName: "State", value: userDetails === null || userDetails === void 0 ? void 0 : userDetails.state }),
                                React.createElement(profile_grid_1.default, { lableName: "City", value: userDetails === null || userDetails === void 0 ? void 0 : userDetails.city }),
                                React.createElement(profile_grid_1.default, { lableName: "Entity Type", value: userDetails === null || userDetails === void 0 ? void 0 : userDetails.entityType }),
                                React.createElement(profile_grid_1.default, { lableName: "Code", value: userDetails === null || userDetails === void 0 ? void 0 : userDetails.code }),
                                React.createElement(profile_grid_1.default, { lableName: "Employee Code", value: userDetails === null || userDetails === void 0 ? void 0 : userDetails.emaployeeCode })))))))));
};
exports.default = UserProfile;
