"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.user_action = void 0;
var Constants = {
    loginUserDetails: 'loginUserDetails',
    userRole: 'userRole',
    loginStatus: 'loginStatus',
    logout: 'LOGOUT',
    sessionExpired: 'sessionExpired',
    apiErrorMessage: 'apiErrorMessage',
    otpIdForUser: 'otpIdForUser',
    pageNotFound: 'pageNotFound',
};
var setLoginStatus = function (data) { return ({
    type: Constants.loginStatus,
    payload: data,
}); };
var setPageNotFound = function (data) { return ({
    type: Constants.pageNotFound,
    payload: data,
}); };
var setUserRole = function (data) { return ({
    type: Constants.userRole,
    payload: data,
}); };
var setSessionExpired = function (data) { return ({
    type: Constants.sessionExpired,
    payload: data,
}); };
var loginUserDetails = function (data) { return ({
    type: Constants.loginUserDetails,
    payload: data,
}); };
var setLogout = function () { return ({
    type: 'LOGOUT',
}); };
var setApiErrorMessage = function (data) { return ({
    type: Constants.apiErrorMessage,
    payload: data,
}); };
var setOtpIdForUser = function (data) { return ({
    type: Constants.otpIdForUser,
    payload: data,
}); };
exports.user_action = {
    Constants: Constants,
    setUserRole: setUserRole,
    loginUserDetails: loginUserDetails,
    setLoginStatus: setLoginStatus,
    setLogout: setLogout,
    setSessionExpired: setSessionExpired,
    setApiErrorMessage: setApiErrorMessage,
    setOtpIdForUser: setOtpIdForUser,
    setPageNotFound: setPageNotFound,
};
exports.default = exports.user_action;
