"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_query_1 = require("react-query");
var api_service_1 = require("../../../../api/api_service");
var react_highchart_1 = require("../../../../common/high_charts/react_highchart");
var array_helpers_1 = require("../../../../utils/array_helpers");
var reports_helper_1 = require("../../reports/reports_helper");
var intracity_tat_service_1 = require("../Intracity_Tat/intracity_tat_service");
var LabHandoverTrendGraphView = function (_a) {
    var endDate = _a.endDate, isDateRangeUpdated = _a.isDateRangeUpdated, startDate = _a.startDate, labList = _a.labList, labCode = _a.labCode, filterSelectedCities = _a.filterSelectedCities, filterSelectedStates = _a.filterSelectedStates, setUpdatedTIme = _a.setUpdatedTIme, filterButton = _a.filterButton;
    var _b = React.useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = React.useState(), cumulativeSeries = _c[0], setCumulativeSeries = _c[1];
    var _d = React.useState(), seriesData = _d[0], setSeriesData = _d[1];
    var refetch = (0, react_query_1.useQuery)(['Lab-Handover-Trend_samples'], function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(labCode, labList);
            payload['lab_code'] = selectedlabCode;
        }
        if (filterSelectedCities && filterSelectedCities.length > 0) {
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(filterSelectedCities);
            payload['states'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['cities'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        (0, reports_helper_1.labHandoverTrendGraph)(payload, api_service_1.api_service.api_urls.LAB_HANDOVER_SAMPLES)
            .then(function (response) {
            var _a;
            setErrorMessage('');
            if (response && response.data) {
                var result = (0, intracity_tat_service_1.convertLabHandoverTrendSamples)((_a = response.data) === null || _a === void 0 ? void 0 : _a.data.result);
                setSeriesData(result.samples_count);
                var cumulative = (0, intracity_tat_service_1.converCumulativeData)(result.cumulative);
                var cumulativeSum = (0, intracity_tat_service_1.sumCumulativeData)(cumulative);
                setCumulativeSeries(cumulativeSum);
            }
        })
            .catch(function (err) {
            setErrorMessage(err);
            setSeriesData([]);
            setCumulativeSeries([]);
        });
    }).refetch;
    React.useEffect(function () {
        refetch();
        setUpdatedTIme(new Date().toLocaleString());
    }, [filterButton]);
    return (React.createElement("div", null, seriesData && seriesData.length > 0 ? (React.createElement(react_highchart_1.default, { chartOptions: {
            chart: {
                type: 'column',
            },
            title: {
                text: null,
            },
            xAxis: {
                labels: {
                    style: {
                        fontSize: 17,
                    },
                },
                categories: intracity_tat_service_1.LAB_HANDOVER_TREND_SMAPLES_X_VALUES,
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    style: {
                        fontSize: 17,
                    },
                },
                stackLabels: {
                    enabled: false,
                    formatter: function () {
                        return this.total;
                    },
                    style: {
                        fontSize: 17,
                    },
                },
            },
            colors: ['#0080ff', '#ffff00'],
            legend: {
                itemStyle: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                },
                symbolWidth: 40,
                symbolHeight: 10,
                symbolRadius: 0,
                squareSymbol: false,
            },
            tooltip: {
                padding: 20,
                style: {
                    fontSize: '1.1em',
                },
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    pointPadding: 0.1,
                    pointWidth: 50,
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: 15,
                        },
                    },
                },
                series: {
                    lineWidth: 3,
                    cumulative: true,
                },
                line: {
                    dataLabels: {
                        format: '{y}%',
                        enabled: true,
                        style: {
                            fontSize: '16px',
                        },
                    },
                },
            },
            series: [
                {
                    name: 'No of Samples',
                    data: seriesData,
                },
                {
                    name: 'Cumulative',
                    type: 'line',
                    data: cumulativeSeries,
                },
            ],
        } })) : (React.createElement("div", { className: "no-data-conatiner" },
        React.createElement("div", { className: "no-data-badge" },
            ' ',
            errorMessage ? errorMessage : 'No Data Available')))));
};
exports.default = LabHandoverTrendGraphView;
