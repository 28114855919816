"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Avatar_1 = require("@mui/material/Avatar");
var Chip_1 = require("@mui/material/Chip");
var React = require("react");
require("./selected_fe.scss");
var SelectedFeScreen = function (_a) {
    var selecteFeIds = _a.selecteFeIds, setSelecteFeIds = _a.setSelecteFeIds, viewMode = _a.viewMode, setFieldValue = _a.setFieldValue, setFeOnchange = _a.setFeOnchange, feOnchage = _a.feOnchage;
    var handleDelete = function (data) { return function () {
        viewMode ? null : setSelecteFeIds(function (chips) { return chips.filter(function (chip) { return chip.id !== data.id; }); });
        setFieldValue('selectedFes', '');
        setFeOnchange(!feOnchage);
    }; };
    return (React.createElement("div", { className: 'selected_fe-wrapper' },
        React.createElement("h2", { className: 'selected-felabel label-heading' }, "Selected FE(s)"),
        React.createElement("div", { className: 'chips-wrapper' }, selecteFeIds === null || selecteFeIds === void 0 ? void 0 : selecteFeIds.map(function (values) {
            return (React.createElement(Chip_1.default, { disabled: viewMode ? true : false, size: 'medium', deleteIcon: React.createElement("span", { className: viewMode ? '' : 'icon-close' }), onDelete: handleDelete(values), label: values.label, className: 'boxed-chip', avatar: React.createElement(Avatar_1.default, { src: require('../../../../assets/images/use.jpg') }) }));
        }))));
};
exports.default = SelectedFeScreen;
