"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coonvertMyReportListFromRes = void 0;
var coonvertMyReportListFromRes = function (res, report_status) {
    var convertedResArr = [];
    res.forEach(function (resObj) {
        var convertedRes = {
            report_type: resObj.type ? resObj.type : '',
            generatedBy: resObj.generated_by ? resObj.generated_by : '',
            status: resObj.status ? resObj.status : '',
            generatedTimeStamp: resObj.created_date_time
                ? resObj.created_date_time
                : '',
            downloadLink: resObj.url ? resObj.url : '',
            fileName: resObj.file_name ? resObj.file_name : '',
            start_date: resObj.filters &&
                resObj.filters.date_range &&
                resObj.filters.date_range.start_date
                ? resObj.filters.date_range.start_date
                : '',
            end_date: resObj.filters &&
                resObj.filters.date_range &&
                resObj.filters.date_range.end_date
                ? resObj.filters.date_range.end_date
                : '',
            filePath: resObj.s3_file_path ? resObj.s3_file_path : '',
            id: resObj['_id'] ? resObj['_id'] : '',
        };
        convertedResArr.push(convertedRes);
    });
    return convertedResArr;
};
exports.coonvertMyReportListFromRes = coonvertMyReportListFromRes;
