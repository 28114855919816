"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var search_component_1 = require("../../../common/search/search_component");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var state_city_filter_1 = require("../sample_traking/state_city_filter");
var ControlCenterFilters = function (_a) {
    var searchHadler = _a.searchHadler, searchValue = _a.searchValue, cityHandlere = _a.cityHandlere, citiesValues = _a.citiesValues, makeCityEmpty = _a.makeCityEmpty, isDisabled = _a.isDisabled;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" },
                React.createElement(search_component_1.default, { placeholder: "Search FE", searchValue: searchValue, searchHandler: searchHadler, isDisabled: isDisabled })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(state_city_filter_1.default, { isDisabled: true }),
                React.createElement(multi_selector_1.default, { label: "Select Service Type", isDisabled: isDisabled }),
                React.createElement(text_filed_with_fromto_Daterange_1.default, { disabled: isDisabled })))));
};
exports.default = ControlCenterFilters;
