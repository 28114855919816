"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabMarkerEvents = exports.markerInformationForLab = exports.converFEChangesList = exports.createPayloadForRouteList = exports.filterUnassingedFEs = exports.APPROVE = exports.REJECT_ROUTE_INITIAL_VALUES = exports.filterSelectedRouteVisitList = exports.filterFE_IdsForRoutePlanner = exports.converProfileCongData = exports.convertEditPayloadData = exports.convertExcelDataFromRoutes = exports.ROUTE_ASSIGNMENT_TYPES = exports.markerEvents = exports.openInfoBubble = exports.markerInformation = exports.draggableMarkers = exports.draggedInformation = exports.filterRoutePlannerUnassiedVisit = exports.downloadExcelHeaders = exports.filterRoutePlannerUniqueFEData = exports.ROUTE_PLANNER_LABLES_VALUES = exports.connvertLabCOnfigarationData = exports.converCreateFormDataToPayload = exports.converEditFormDataToPayload = exports.convertCreateChangedValues = exports.convertEditChangedValues = exports.STATIC_PAYLOAD_FOR_CREATE_ROUTE = exports.route_plan_data_types = exports.route_plan_center_types = exports.CREATE_ROUTE_PALNNER_INITIAL_STATE = exports.convertRoutePlannerListFromRes = void 0;
var maps_api_for_javascript_1 = require("@here/maps-api-for-javascript");
var map_constants_1 = require("../../../../constants/map_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var lodash_1 = require("../../../../utils/lodash");
var heat_map_api_service_1 = require("../../heat_maps/heat_map_api_service");
var convertRoutePlannerListFromRes = function (res) {
    var convertedRes = [];
    res.result.map(function (response) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var obj = {
            id: response.id,
            lab_code: (_a = response.lab_code) !== null && _a !== void 0 ? _a : '',
            route_id: (_b = response.route_id) !== null && _b !== void 0 ? _b : null,
            created_by: (_c = response.created_by) !== null && _c !== void 0 ? _c : '',
            created_date_time: (_d = response.created_date_time) !== null && _d !== void 0 ? _d : '',
            failed_reason: (_e = response.failed_reason) !== null && _e !== void 0 ? _e : '',
            rejection_comments: (_f = response.rejection_comments) !== null && _f !== void 0 ? _f : '',
            route_assignment_type: response.route_assignment_type,
            status: (_g = response.status) !== null && _g !== void 0 ? _g : null,
            generated_date_time: (_h = response.generated_date_time) !== null && _h !== void 0 ? _h : '',
            initiated_date_time: (_j = response.initiated_date_time) !== null && _j !== void 0 ? _j : '',
            radius: (_k = response.radius) !== null && _k !== void 0 ? _k : null,
            speed: (_l = response.speed) !== null && _l !== void 0 ? _l : null,
        };
        convertedRes.push(obj);
    });
    var finalConvertedObj = {
        page: res.page,
        count: res.count,
        size: res.size,
        result: convertedRes,
    };
    return finalConvertedObj;
};
exports.convertRoutePlannerListFromRes = convertRoutePlannerListFromRes;
exports.CREATE_ROUTE_PALNNER_INITIAL_STATE = {
    lab_code: null,
    route_assignment_type: null,
    speed: null,
    radius: null,
    cc_buffer_time: null,
    psc_buffer_time: null,
    fpsc_buffer_time: null,
    pup_buffer_time: null,
    hub_buffer_time: null,
    lab_buffer_time: null,
};
exports.route_plan_center_types = {
    FE: 0,
    CC: 1,
    PSC: 2,
    FPSC: 3,
    PUP: 4,
    LAB: 5,
    HUB: 6,
    DEFAULT: 7,
};
exports.route_plan_data_types = {
    FE: 1,
    HUB: 2,
    CC: 3,
    LAB: 4,
    SPEED: 5,
    BUFFER_TIME: 6,
    FE_RADIUS: 7,
};
exports.STATIC_PAYLOAD_FOR_CREATE_ROUTE = {
    lab_code: 'A51',
    route_assignment_type: 1,
    changes: [
        {
            data_type: 5,
            speed: 30,
        },
        {
            id: 3,
            center_code: 'CC7887',
            latitude: '23.23',
            longitude: '23.34',
        },
        { id: 1, fe_id: 893, latitude: '23.23', longitude: '23.34' },
        {
            data_type: 7,
            radius: 30,
        },
        {
            data_type: 6,
            center_type: 1,
            buffer_time: 30,
        },
        {
            data_type: 6,
            center_type: 2,
            buffer_time: 30,
        },
        {
            data_type: 6,
            center_type: 3,
            buffer_time: 30,
        },
        {
            data_type: 6,
            center_type: 4,
            buffer_time: 30,
        },
        {
            data_type: 6,
            center_type: 6,
            buffer_time: 30,
        },
        {
            data_type: 6,
            center_type: 5,
            buffer_time: 30,
        },
    ],
};
var convertEditChangedValues = function (payload, formData, configList, profiledetailsChangesList) {
    var changesData = [];
    console.log(formData, 'formData');
    configList.map(function (val) {
        var newObject = {};
        if (val.data_type == exports.route_plan_data_types.SPEED &&
            val.speed != formData.speed) {
            var filteredSpeedData = profiledetailsChangesList.find(function (val) { return val.data_type == exports.route_plan_data_types.SPEED; });
            newObject = {
                data_type: exports.route_plan_data_types.SPEED,
                speed: parseInt(formData.speed.toString()),
            };
            if (filteredSpeedData) {
                newObject['id'] = filteredSpeedData.id;
            }
            changesData.push(newObject);
        }
        if (val.data_type == exports.route_plan_data_types.FE_RADIUS &&
            val.radius != formData.radius) {
            var filteredRadiousData = profiledetailsChangesList.find(function (val) { return val.data_type == exports.route_plan_data_types.FE_RADIUS; });
            newObject = {
                data_type: exports.route_plan_data_types.FE_RADIUS,
                radius: parseInt(formData.radius.toString()),
            };
            if (filteredRadiousData) {
                newObject['id'] = filteredRadiousData.id;
            }
            changesData.push(newObject);
        }
        if (val.center_type == exports.route_plan_center_types.CC &&
            val.buffer_time != formData.cc_buffer_time) {
            var filteredRadiousData = profiledetailsChangesList.find(function (val) { return val.center_type == exports.route_plan_center_types.CC; });
            newObject = {
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.CC,
                buffer_time: parseInt(formData.cc_buffer_time.toString()),
            };
            if (filteredRadiousData) {
                newObject['id'] = filteredRadiousData.id;
            }
            changesData.push(newObject);
        }
        if (val.center_type == exports.route_plan_center_types.PSC &&
            val.buffer_time != formData.psc_buffer_time) {
            var filteredRadiousData = profiledetailsChangesList.find(function (val) { return val.center_type == exports.route_plan_center_types.PSC; });
            newObject = {
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.PSC,
                buffer_time: parseInt(formData.psc_buffer_time.toString()),
            };
            if (filteredRadiousData) {
                newObject['id'] = filteredRadiousData.id;
            }
            changesData.push(newObject);
        }
        if (val.center_type == exports.route_plan_center_types.FPSC &&
            val.buffer_time != formData.fpsc_buffer_time) {
            var filteredRadiousData = profiledetailsChangesList.find(function (val) { return val.center_type == exports.route_plan_center_types.FPSC; });
            newObject = {
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.FPSC,
                buffer_time: parseInt(formData.fpsc_buffer_time.toString()),
            };
            if (filteredRadiousData) {
                newObject['id'] = filteredRadiousData.id;
            }
            changesData.push(newObject);
        }
        if (val.center_type == exports.route_plan_center_types.PUP &&
            val.buffer_time != formData.pup_buffer_time) {
            var filteredRadiousData = profiledetailsChangesList.find(function (val) { return val.center_type == exports.route_plan_center_types.PUP; });
            newObject = {
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.PUP,
                buffer_time: parseInt(formData.pup_buffer_time.toString()),
            };
            if (filteredRadiousData) {
                newObject['id'] = filteredRadiousData.id;
            }
            changesData.push(newObject);
        }
        if (val.center_type == exports.route_plan_center_types.HUB &&
            val.buffer_time != formData.hub_buffer_time) {
            var filteredRadiousData = profiledetailsChangesList.find(function (val) { return val.center_type == exports.route_plan_center_types.HUB; });
            newObject = {
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.HUB,
                buffer_time: parseInt(formData.hub_buffer_time.toString()),
            };
            if (filteredRadiousData) {
                newObject['id'] = filteredRadiousData.id;
            }
            changesData.push(newObject);
        }
        if (val.center_type == exports.route_plan_center_types.LAB &&
            val.buffer_time != formData.lab_buffer_time) {
            var filteredRadiousData = profiledetailsChangesList.find(function (val) { return val.center_type == exports.route_plan_center_types.LAB; });
            newObject = {
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.LAB,
                buffer_time: parseInt(formData.lab_buffer_time.toString()),
            };
            if (filteredRadiousData) {
                newObject['id'] = filteredRadiousData.id;
            }
            changesData.push(newObject);
        }
    });
    return changesData;
};
exports.convertEditChangedValues = convertEditChangedValues;
var convertCreateChangedValues = function (payload, formData, configList, profiledetailsChangesList) {
    var changesData = [];
    console.log(formData, 'formData');
    configList.map(function (val) {
        if (val.data_type == exports.route_plan_data_types.SPEED &&
            val.speed != formData.speed) {
            changesData.push({
                data_type: exports.route_plan_data_types.SPEED,
                speed: parseInt(formData.speed.toString()),
            });
        }
        if (val.data_type == exports.route_plan_data_types.FE_RADIUS &&
            val.radius != formData.radius) {
            changesData.push({
                data_type: exports.route_plan_data_types.FE_RADIUS,
                radius: parseInt(formData.radius.toString()),
            });
        }
        if (val.center_type == exports.route_plan_center_types.CC &&
            val.buffer_time != formData.cc_buffer_time) {
            changesData.push({
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.CC,
                buffer_time: parseInt(formData.cc_buffer_time.toString()),
            });
        }
        if (val.center_type == exports.route_plan_center_types.PSC &&
            val.buffer_time != formData.psc_buffer_time) {
            changesData.push({
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.PSC,
                buffer_time: parseInt(formData.psc_buffer_time.toString()),
            });
        }
        if (val.center_type == exports.route_plan_center_types.FPSC &&
            val.buffer_time != formData.fpsc_buffer_time) {
            changesData.push({
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.FPSC,
                buffer_time: parseInt(formData.fpsc_buffer_time.toString()),
            });
        }
        if (val.center_type == exports.route_plan_center_types.PUP &&
            val.buffer_time != formData.pup_buffer_time) {
            changesData.push({
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.PUP,
                buffer_time: parseInt(formData.pup_buffer_time.toString()),
            });
        }
        if (val.center_type == exports.route_plan_center_types.HUB &&
            val.buffer_time != formData.hub_buffer_time) {
            changesData.push({
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.HUB,
                buffer_time: parseInt(formData.hub_buffer_time.toString()),
            });
        }
        if (val.center_type == exports.route_plan_center_types.LAB &&
            val.buffer_time != formData.lab_buffer_time) {
            changesData.push({
                data_type: exports.route_plan_data_types.BUFFER_TIME,
                center_type: exports.route_plan_center_types.LAB,
                buffer_time: parseInt(formData.lab_buffer_time.toString()),
            });
        }
    });
    return changesData;
};
exports.convertCreateChangedValues = convertCreateChangedValues;
var converEditFormDataToPayload = function (formData, configList, profiledetailsChangesList) {
    var payload = {
        lab_code: formData.lab_code.label,
        route_assignment_type: formData.route_assignment_type.value,
    };
    console.log(payload, 'changesDatapayload');
    var changesData = (0, exports.convertEditChangedValues)(payload, formData, configList, profiledetailsChangesList);
    if (changesData.length > 0) {
        payload['changes'] = changesData;
    }
    console.log(changesData, 'changesData', payload);
    return payload;
};
exports.converEditFormDataToPayload = converEditFormDataToPayload;
var converCreateFormDataToPayload = function (formData, configList) {
    var payload = {
        lab_code: formData.lab_code.value,
        route_assignment_type: formData.route_assignment_type.value,
    };
    console.log(payload, 'changesDatapayload');
    var changesData = (0, exports.convertCreateChangedValues)(payload, formData, configList);
    if (changesData.length > 0) {
        payload['changes'] = changesData;
    }
    console.log(changesData, 'changesData', payload);
    return payload;
};
exports.converCreateFormDataToPayload = converCreateFormDataToPayload;
var connvertLabCOnfigarationData = function (configData, setFieldValue, setFieldTouched) {
    console.log(configData, 'configData');
    configData &&
        configData.map(function (val) {
            if (val.data_type == exports.route_plan_data_types.SPEED) {
                setFieldValue("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[2]), val.speed);
                setFieldTouched("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[2]), true);
            }
            else if (val.data_type == exports.route_plan_data_types.FE_RADIUS) {
                setFieldValue("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[3]), val.radius);
                setFieldTouched("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[3]), true);
            }
            else if (val.center_type == exports.route_plan_center_types.CC) {
                setFieldValue("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[4]), val.buffer_time);
                setFieldTouched("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[4]), true);
            }
            else if (val.center_type == exports.route_plan_center_types.PSC) {
                setFieldValue("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[5]), val.buffer_time);
                setFieldTouched("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[5]), true);
            }
            else if (val.center_type == exports.route_plan_center_types.FPSC) {
                setFieldValue("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[6]), val.buffer_time);
                setFieldTouched("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[6]), true);
            }
            else if (val.center_type == exports.route_plan_center_types.PUP) {
                setFieldValue("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[7]), val.buffer_time);
                setFieldTouched("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[7]), true);
            }
            else if (val.center_type == exports.route_plan_center_types.HUB) {
                setFieldValue("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[8]), val.buffer_time);
                setFieldTouched("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[8]), true);
            }
            else if (val.center_type == exports.route_plan_center_types.LAB) {
                setFieldValue("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[9]), val.buffer_time);
                setFieldTouched("".concat(Object.keys(exports.CREATE_ROUTE_PALNNER_INITIAL_STATE)[9]), true);
            }
        });
};
exports.connvertLabCOnfigarationData = connvertLabCOnfigarationData;
exports.ROUTE_PLANNER_LABLES_VALUES = {
    Route_ID: 'Route ID',
    Initiated_Date__Time: 'Initiated Date & Time',
    Created_By: 'Created By',
    Created_Date_Time: 'Created Date & Time',
    Generated_Date_Time: 'Generated Date & Time',
    Buffer_Times: 'Buffer Times',
    Lab_Code: 'Lab Code',
    Route_Type: 'Route Type',
    Speed_Kmhr: 'Speed Km/hr',
    FE_rdius_Km: 'FE Radius Km',
    Time_for_CC_mins: 'Time for CC (mins)',
    Time_for_PSC_mins: 'Time for PSC (mins)',
    Time_for_FPSP_mins: 'Time for FPSP (mins)',
    Time_for_PUP_mins: 'Time for PUP (mins)',
    Time_for_HUB_mins: 'Time for HUB (mins)',
    Time_for_LAB_mins: 'Time for LAB (mins)',
};
var filterRoutePlannerUniqueFEData = function (response) {
    console.log(response, 'response');
    var data = [];
    var uniqueFes = lodash_1.default.uniqBy(response, 'fe_id');
    console.log(uniqueFes, 'valData');
    data = uniqueFes.map(function (val) {
        try {
            var obj = {
                fe_current_location: {
                    latitude: val.fe_latitude,
                    longitude: val.fe_longitude,
                },
                fe_id: val.fe_id,
                visit_type: 0,
                visites: [],
                fe_name: val.fe_name,
                fe_mobile: val.fe_mobile,
                center_name: val.center_name,
            };
            for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
                var res = _a[_i];
                if (val.fe_id == res.fe_id) {
                    obj.visites = __spreadArray(__spreadArray([], obj.visites, true), [
                        {
                            latitude: res.center_latitude,
                            longitude: res.center_longitude,
                            visit_type: res.visit_type,
                            center_name: res.center_name,
                            center_info: {
                                warehouse_code: res.warehouse_code,
                                invoice_code: res.invoice_code,
                            },
                        },
                    ], false);
                }
            }
            return obj;
        }
        catch (error) {
            console.log(error);
        }
    });
    return data;
};
exports.filterRoutePlannerUniqueFEData = filterRoutePlannerUniqueFEData;
exports.downloadExcelHeaders = [
    'Route ID',
    'Invoice Code',
    'Warehouse Code',
    'Center Name',
    'Distance',
    'ETA',
    'Status',
    'Visit Type',
    'Created Date Time',
    'Time Window Slot',
    'FE Mobile',
    'FE Name',
    'Latittude',
    'Longittude',
    'Lab code',
];
var filterRoutePlannerUnassiedVisit = function (response) {
    var data = [];
    data = response.map(function (val) {
        try {
            var obj = {
                latitude: val.center_latitude,
                longitude: val.center_longitude,
                visit_type: val.visit_type,
                center_name: val.center_name,
                center_info: {
                    invoice_code: val.invoice_code,
                    warehouse_code: val.warehouse_code,
                },
            };
            return obj;
        }
        catch (error) {
            console.log(error);
        }
    });
    return data;
};
exports.filterRoutePlannerUnassiedVisit = filterRoutePlannerUnassiedVisit;
var draggedInformation = function (evt, setUpdatedLocationInfo, geoPoint, routePlannerDetils) {
    var newObject = {};
    setUpdatedLocationInfo(function (prev) {
        var matchedIndex = -1;
        if (prev.length > 0) {
            prev.map(function (val, index) {
                if (evt.target.data.data.visit_type == 0 && val.visit_type == 0) {
                    if (val.fe_id == evt.target.data.data.fe_id) {
                        matchedIndex = index;
                    }
                }
                if (evt.target.data.data.visit_type == 1 && val.visit_type == 1) {
                    if (val.center_code == evt.target.data.data.center_info.warehouse_code) {
                        matchedIndex = index;
                    }
                }
                if (evt.target.data.data.visit_type == 3 && val.visit_type == 3) {
                    if (val.center_code == evt.target.data.data.center_info.warehouse_code) {
                        matchedIndex = index;
                    }
                }
                if (evt.target.data.data.visit_type == 4 && val.visit_type == 4) {
                    if (val.center_code == evt.target.data.data.center_info.warehouse_code) {
                        matchedIndex = index;
                    }
                }
                if (evt.target.data.data.visit_type == 5 && val.visit_type == 5) {
                    if (val.center_code == evt.target.data.data.center_info.warehouse_code) {
                        matchedIndex = index;
                    }
                }
                return prev;
            });
            console.log(matchedIndex, 'matchedIndex');
            if (matchedIndex > -1) {
                if (evt.target.data.data.visit_type == 0) {
                    (prev[matchedIndex].latitude = geoPoint.lat.toString()),
                        (prev[matchedIndex].longitude = geoPoint.lng.toString());
                }
                if (evt.target.data.data.visit_type == 1) {
                    (prev[matchedIndex].latitude = geoPoint.lat.toString()),
                        (prev[matchedIndex].longitude = geoPoint.lng.toString());
                }
                if (evt.target.data.data.visit_type == 3) {
                    (prev[matchedIndex].latitude = geoPoint.lat.toString()),
                        (prev[matchedIndex].longitude = geoPoint.lng.toString());
                }
                if (evt.target.data.data.visit_type == 4) {
                    (prev[matchedIndex].latitude = geoPoint.lat.toString()),
                        (prev[matchedIndex].longitude = geoPoint.lng.toString());
                }
                if (evt.target.data.data.visit_type == 5) {
                    (prev[matchedIndex].latitude = geoPoint.lat.toString()),
                        (prev[matchedIndex].longitude = geoPoint.lng.toString());
                }
            }
            else {
                if (evt.target.data.data.visit_type == 0) {
                    var filteredFEData = routePlannerDetils.find(function (val) { return val.fe_id == evt.target.data.data.fe_id; });
                    newObject = {
                        data_type: exports.route_plan_data_types.FE,
                        fe_id: evt.target.data.data.fe_id.toString(),
                        latitude: geoPoint.lat.toString(),
                        longitude: geoPoint.lng.toString(),
                        visit_type: 0,
                    };
                    if (filteredFEData) {
                        newObject['id'] = filteredFEData.id;
                    }
                    prev.push(newObject);
                }
                if (evt.target.data.data.visit_type == 1) {
                    var filteredCenterCodeData = routePlannerDetils.find(function (val) { return val.center_code == evt.target.data.data.center_info.warehouse_code; });
                    newObject = {
                        data_type: exports.route_plan_data_types.CC,
                        center_code: evt.target.data.data.center_info.warehouse_code,
                        latitude: geoPoint.lat.toString(),
                        longitude: geoPoint.lng.toString(),
                        visit_type: 1,
                    };
                    if (filteredCenterCodeData) {
                        newObject['id'] = filteredCenterCodeData.id;
                    }
                    prev.push(newObject);
                }
                if (evt.target.data.data.visit_type == 3) {
                    var filteredCenterCodeData = routePlannerDetils.find(function (val) { return val.center_code == evt.target.data.data.center_info.warehouse_code; });
                    newObject = {
                        data_type: exports.route_plan_data_types.HUB,
                        center_code: evt.target.data.data.center_info.warehouse_code,
                        latitude: geoPoint.lat.toString(),
                        longitude: geoPoint.lng.toString(),
                        visit_type: 3,
                    };
                    if (filteredCenterCodeData) {
                        newObject['id'] = filteredCenterCodeData.id;
                    }
                    prev.push(newObject);
                }
                if (evt.target.data.data.visit_type == 4) {
                    var filteredCenterCodeData = routePlannerDetils.find(function (val) { return val.center_code == evt.target.data.data.center_info.warehouse_code; });
                    newObject = {
                        data_type: exports.route_plan_data_types.LAB,
                        center_code: evt.target.data.data.center_info.warehouse_code,
                        latitude: geoPoint.lat.toString(),
                        longitude: geoPoint.lng.toString(),
                        visit_type: 4,
                    };
                    if (filteredCenterCodeData) {
                        newObject['id'] = filteredCenterCodeData.id;
                    }
                    prev.push(newObject);
                }
                if (evt.target.data.data.visit_type == 5) {
                    var filteredCenterCodeData = routePlannerDetils.find(function (val) { return val.center_code == evt.target.data.data.center_info.warehouse_code; });
                    newObject = {
                        data_type: exports.route_plan_data_types.LAB,
                        center_code: evt.target.data.data.center_info.warehouse_code,
                        latitude: geoPoint.lat.toString(),
                        longitude: geoPoint.lng.toString(),
                        visit_type: 5,
                    };
                    if (filteredCenterCodeData) {
                        newObject['id'] = filteredCenterCodeData.id;
                    }
                    prev.push(newObject);
                }
                return prev;
            }
            return prev;
        }
        else {
            if (evt.target.data.data.visit_type == 0) {
                var filteredFEData = routePlannerDetils.find(function (val) { return val.fe_id == evt.target.data.data.fe_id; });
                newObject = {
                    data_type: exports.route_plan_data_types.FE,
                    fe_id: evt.target.data.data.fe_id.toString(),
                    latitude: geoPoint.lat.toString(),
                    longitude: geoPoint.lng.toString(),
                    visit_type: 0,
                };
                if (filteredFEData) {
                    newObject['id'] = filteredFEData.id;
                }
                prev.push(newObject);
            }
            if (evt.target.data.data.visit_type == 1) {
                var filteredCenterCodeData = routePlannerDetils.find(function (val) { return val.center_code == evt.target.data.data.center_info.warehouse_code; });
                newObject = {
                    data_type: exports.route_plan_data_types.CC,
                    center_code: evt.target.data.data.center_info.warehouse_code,
                    latitude: geoPoint.lat.toString(),
                    longitude: geoPoint.lng.toString(),
                    visit_type: 1,
                };
                if (filteredCenterCodeData) {
                    newObject['id'] = filteredCenterCodeData.id;
                }
                prev.push(newObject);
            }
            if (evt.target.data.data.visit_type == 3) {
                var filteredCenterCodeData = routePlannerDetils.find(function (val) { return val.center_code == evt.target.data.data.center_info.warehouse_code; });
                newObject = {
                    data_type: exports.route_plan_data_types.HUB,
                    center_code: evt.target.data.data.center_info.warehouse_code,
                    latitude: geoPoint.lat.toString(),
                    longitude: geoPoint.lng.toString(),
                    visit_type: 3,
                };
                if (filteredCenterCodeData) {
                    newObject['id'] = filteredCenterCodeData.id;
                }
                prev.push(newObject);
            }
            if (evt.target.data.data.visit_type == 4) {
                var filteredCenterCodeData = routePlannerDetils.find(function (val) { return val.center_code == evt.target.data.data.center_info.warehouse_code; });
                newObject = {
                    data_type: exports.route_plan_data_types.LAB,
                    center_code: evt.target.data.data.center_info.warehouse_code,
                    latitude: geoPoint.lat.toString(),
                    longitude: geoPoint.lng.toString(),
                    visit_type: 4,
                };
                if (filteredCenterCodeData) {
                    newObject['id'] = filteredCenterCodeData.id;
                }
                prev.push(newObject);
            }
            if (evt.target.data.data.visit_type == 5) {
                var filteredCenterCodeData = routePlannerDetils.find(function (val) { return val.center_code == evt.target.data.data.center_info.warehouse_code; });
                newObject = {
                    data_type: exports.route_plan_data_types.LAB,
                    center_code: evt.target.data.data.center_info.warehouse_code,
                    latitude: geoPoint.lat.toString(),
                    longitude: geoPoint.lng.toString(),
                    visit_type: 5,
                };
                if (filteredCenterCodeData) {
                    newObject['id'] = filteredCenterCodeData.id;
                }
                prev.push(newObject);
            }
            return prev;
        }
    });
};
exports.draggedInformation = draggedInformation;
var draggableMarkers = function (map, verticeGroup, lineString, styleLine, setSelctedLatLong, setshowLatLng, setUpdatedLocationInfo, routePlannerDetils) {
    var polyline = new maps_api_for_javascript_1.default.map.Polyline(lineString, styleLine);
    var gruopOptions = {
        volatility: true,
        objects: [polyline, verticeGroup],
    };
    var mainGroup = new maps_api_for_javascript_1.default.map.Group(gruopOptions);
    var polylineTimeout;
    map.addObject(mainGroup);
    mainGroup.addEventListener('pointerenter', function (evt) {
        if (polylineTimeout) {
            clearTimeout(polylineTimeout);
            polylineTimeout = null;
        }
    }, true);
    mainGroup.addEventListener('pointerleave', function (evt) {
        var timeout = evt.currentPointer.type == 'touch' ? 1000 : 0;
        polylineTimeout = setTimeout(function () {
        }, timeout);
    }, true);
    verticeGroup.addEventListener('pointerenter', function (evt) {
        document.body.style.cursor = 'pointer';
    }, true);
    verticeGroup.addEventListener('pointerleave', function (evt) {
        document.body.style.cursor = 'default';
    }, true);
    mainGroup.addEventListener('dragend', function (evt) {
        console.log(routePlannerDetils, 'routePlannerDetils');
        var pointer = evt.currentPointer, geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);
        evt.target.setGeometry(geoPoint);
        console.log(evt.target.data.data, 'geoPoint', geoPoint);
        (0, exports.draggedInformation)(evt, setUpdatedLocationInfo, geoPoint, routePlannerDetils);
        document.body.style.cursor = 'default';
    }, true);
    verticeGroup.addEventListener('drag', function (evt) {
        var pointer = evt.currentPointer, geoLineString = polyline.getGeometry(), geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);
        evt.target.setGeometry(geoPoint);
        setshowLatLng(true);
        setSelctedLatLong("".concat(geoPoint.lat, ",").concat(geoPoint.lng));
        geoLineString.removePoint(evt.target.getData()['verticeIndex']);
        geoLineString.insertPoint(evt.target.getData()['verticeIndex'], geoPoint);
        polyline.setGeometry(geoLineString);
        evt.stopPropagation();
    }, true);
    return verticeGroup;
};
exports.draggableMarkers = draggableMarkers;
var markerInformation = function (pointeInfo, isUnassigend) {
    var latitude = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? (0, array_helpers_1.isValueEmpty)(pointeInfo.fe_current_location.latitude)
        : (0, array_helpers_1.isValueEmpty)(pointeInfo.latitude);
    var longitude = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? (0, array_helpers_1.isValueEmpty)(pointeInfo.fe_current_location.longitude)
        : (0, array_helpers_1.isValueEmpty)(pointeInfo.longitude);
    var centerName = (0, array_helpers_1.isValueEmpty)(pointeInfo.center_name);
    var labCode = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP
        ? 'Warehouse Code'
        : 'Lab Code';
    var codeTYpe = pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? "".concat(map_constants_1.VISIT_TYPES.fe_code)
        : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.PICKUP
            ? "".concat(map_constants_1.VISIT_TYPES.cc_code)
            : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HUB_RECEIVING
                ? "".concat(map_constants_1.VISIT_TYPES.hub_code)
                : pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.HANDOVER_LAB
                    ? "".concat(map_constants_1.VISIT_TYPES.lab_Code)
                    : "".concat(map_constants_1.VISIT_TYPES.hub_cash_code);
    return "<div classname='marker-info'><h3>".concat(codeTYpe, " Information</h3>\n    ").concat(pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>FE ID: ".concat((0, array_helpers_1.isValueEmpty)(pointeInfo.fe_id), "</p>")
        : '', "\n  ").concat(pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>FE Name: ".concat(pointeInfo.fe_name, "</p>")
        : "<p>CenterName: ".concat(centerName, "</p>"), "\n  ").concat(pointeInfo.visit_type == map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>Mobile Number: ".concat((0, array_helpers_1.isValueEmpty)(pointeInfo.fe_mobile), "</p>")
        : '', "\n  <p>Latitude:").concat(latitude, "</p><p>Longitude:").concat(longitude, "</p><p>\n   ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>".concat(labCode, ": ").concat((0, array_helpers_1.isValueEmpty)(pointeInfo.center_info.warehouse_code), "</p>")
        : '', "\n   ").concat(pointeInfo.visit_type != map_constants_1.VIST_TYPE_INFO.FE
        ? "<p>Invoice Code: ".concat((0, array_helpers_1.isValueEmpty)(pointeInfo.center_info.invoice_code), "</p>")
        : '', "\n  </div>");
};
exports.markerInformation = markerInformation;
var openInfoBubble = function (popupInfo, coord, ui, distaseValue, timeValue, setShowToolTip, setTooTipInfo) {
    setShowToolTip(true);
    var distase = Math.round(distaseValue * 10) / 10;
    var time = Math.round(timeValue * 10) / 10;
    var splitTime = time.toString().split('.')[0];
    var hours = Math.floor(parseInt(splitTime) / 60);
    var minutes = parseInt(splitTime) % 60;
    var tooTipInformation = {
        distanse: distase,
        hour: hours,
        minutes: minutes,
        startLocation: popupInfo.startLocation,
        endLocation: popupInfo.endLocation,
        feName: popupInfo.feName,
        fe_id: popupInfo.fe_id,
        id: popupInfo.id,
        scheduled_time: popupInfo.scheduled_time,
        visit_type: popupInfo.visit_type,
    };
    setTooTipInfo(tooTipInformation);
};
exports.openInfoBubble = openInfoBubble;
var markerEvents = function (marker, startEndInfo, ui, isUnassigend, unassignedFE) {
    marker.addEventListener('tap', function (evt) {
        console.log(startEndInfo, 'startEndInfo');
        var bubble = new maps_api_for_javascript_1.default.ui.InfoBubble(evt.target.getGeometry(), {
            content: (0, exports.markerInformation)(startEndInfo, isUnassigend),
        });
        ui.addBubble(bubble);
    }, false);
    if (isUnassigend) {
        marker.setIcon(heat_map_api_service_1.unassignedIcon);
    }
    else if (unassignedFE) {
        marker.setIcon(heat_map_api_service_1.unassignedFEIcon);
    }
    else {
        marker.setIcon(startEndInfo.visit_type == 1
            ? heat_map_api_service_1.cc_marker
            : startEndInfo.visit_type == 3
                ? heat_map_api_service_1.hub_marker
                : startEndInfo.visit_type == 4 || startEndInfo.visit_type == 5
                    ? heat_map_api_service_1.lab_marker
                    : heat_map_api_service_1.fe_marker);
    }
};
exports.markerEvents = markerEvents;
exports.ROUTE_ASSIGNMENT_TYPES = {
    dynamic: {
        type: 'DYNAMIC',
        value: 1,
    },
    static: {
        type: 'STATIC',
        value: 2,
    },
};
var convertExcelDataFromRoutes = function (response) {
    var convertedRoutesExcelDataarr = [];
    for (var _i = 0, _a = response && response; _i < _a.length; _i++) {
        var data = _a[_i];
        var convertedRoutesExcelData = {
            route_id: data.route_id ? data.route_id : '',
            invoice_code: data.invoice_code ? data.invoice_code : '',
            warehouse_code: data.warehouse_code ? data.warehouse_code : '',
            center_name: data.center_name ? data.center_name : '',
            distance: data.distance ? data.distance.toFixed(2) : '',
            startTime: data.eta ? data.eta : '',
            status: '1',
            visit_type: data.visit_type ? data.visit_type : '',
            visit_date: (0, array_helpers_1.normaldateFormatte)(new Date()),
            time_window_slot: data.time_window_slot ? data.time_window_slot : '',
            fe_mobile: data.fe_mobile ? data.fe_mobile : '',
            fe_name: data.fe_name ? data.fe_name : '',
            center_latitude: data.center_latitude ? data.center_latitude : '',
            center_longitude: data.center_longitude ? data.center_longitude : '',
            lab_code: data.lab_code ? data.lab_code : '',
        };
        convertedRoutesExcelDataarr.push(convertedRoutesExcelData);
    }
    return convertedRoutesExcelDataarr;
};
exports.convertExcelDataFromRoutes = convertExcelDataFromRoutes;
var convertEditPayloadData = function (data) {
    var newArray = [];
    data &&
        data.map(function (val) {
            var FE_visits = {
                data_type: val.data_type,
                fe_id: val.fe_id,
                latitude: val.latitude,
                longitude: val.longitude,
            };
            if (val.id) {
                FE_visits['id'] = val.id;
            }
            var center_visits = {
                data_type: val.data_type,
                center_code: val.center_code,
                latitude: val.latitude,
                longitude: val.longitude,
            };
            if (val.id) {
                center_visits['id'] = val.id;
            }
            if (val.data_type == exports.route_plan_data_types.FE) {
                newArray.push(FE_visits);
            }
            else {
                newArray.push(center_visits);
            }
        });
    console.log(newArray, 'newArray');
    return newArray;
};
exports.convertEditPayloadData = convertEditPayloadData;
var converProfileCongData = function (configData) {
    var changesData = [];
    changesData.push({
        data_type: exports.route_plan_data_types.SPEED,
        speed: parseInt(configData.speed.toString()),
    }, {
        data_type: exports.route_plan_data_types.FE_RADIUS,
        radius: parseInt(configData.radius.toString()),
    }, {
        data_type: exports.route_plan_data_types.BUFFER_TIME,
        center_type: exports.route_plan_center_types.CC,
        buffer_time: parseInt(configData.cc_buffer_time.toString()),
    }, {
        data_type: exports.route_plan_data_types.BUFFER_TIME,
        center_type: exports.route_plan_center_types.PSC,
        buffer_time: parseInt(configData.psc_buffer_time.toString()),
    }, {
        data_type: exports.route_plan_data_types.BUFFER_TIME,
        center_type: exports.route_plan_center_types.FPSC,
        buffer_time: parseInt(configData.fpsc_buffer_time.toString()),
    }, {
        data_type: exports.route_plan_data_types.BUFFER_TIME,
        center_type: exports.route_plan_center_types.PUP,
        buffer_time: parseInt(configData.pup_buffer_time.toString()),
    }, {
        data_type: exports.route_plan_data_types.BUFFER_TIME,
        center_type: exports.route_plan_center_types.HUB,
        buffer_time: parseInt(configData.hub_buffer_time.toString()),
    }, {
        data_type: exports.route_plan_data_types.BUFFER_TIME,
        center_type: exports.route_plan_center_types.LAB,
        buffer_time: parseInt(configData.lab_buffer_time.toString()),
    });
    return changesData;
};
exports.converProfileCongData = converProfileCongData;
var filterFE_IdsForRoutePlanner = function (response) {
    var FE_Ids = [];
    response.map(function (list) {
        FE_Ids.push({
            id: list.fe_id,
            label: "".concat(list.fe_id, " - ").concat(list.fe_name),
            value: list.fe_id,
        });
    });
    return FE_Ids;
};
exports.filterFE_IdsForRoutePlanner = filterFE_IdsForRoutePlanner;
var filterSelectedRouteVisitList = function (response, selectedFEId) {
    var filterFEIDs = [];
    selectedFEId.map(function (res) {
        filterFEIDs.push(res.id);
    });
    var finalVistsList = response.filter(function (res) {
        return filterFEIDs && filterFEIDs.includes(res.fe_id);
    });
    return finalVistsList;
};
exports.filterSelectedRouteVisitList = filterSelectedRouteVisitList;
exports.REJECT_ROUTE_INITIAL_VALUES = {
    comments: '',
};
exports.APPROVE = 'Approve';
var filterUnassingedFEs = function (response) {
    var data = [];
    data = response.map(function (val) {
        try {
            var obj = {
                fe_current_location: {
                    latitude: Number(val.base_latitude),
                    longitude: Number(val.base_longitude),
                },
                fe_id: val.id,
                fe_mobile: val.mobile,
                fe_name: "".concat(val.first_name, " ").concat(val.last_name),
                visit_type: 0,
            };
            return obj;
        }
        catch (error) {
            console.log(error);
        }
    });
    return data;
};
exports.filterUnassingedFEs = filterUnassingedFEs;
var createPayloadForRouteList = function (labCode, routeType, status) {
    var payload = {
        page: 1,
        size: 50,
    };
    if (labCode) {
        payload['lab_code'] = labCode;
    }
    if (routeType) {
        payload['route_type'] = routeType;
    }
    if (status) {
        payload['status'] = status;
    }
    return payload;
};
exports.createPayloadForRouteList = createPayloadForRouteList;
var converFEChangesList = function (response) {
    var changesList = {};
    var feChangesList = response.filter(function (val) { return val.data_type == exports.route_plan_data_types.FE; });
    changesList['feChangesList'] = feChangesList;
    var routeCongigurations = response.filter(function (val) {
        return val.data_type == exports.route_plan_data_types.SPEED ||
            val.data_type == exports.route_plan_data_types.FE_RADIUS ||
            val.center_type;
    });
    changesList['routeConfigarations'] = routeCongigurations;
    return changesList;
};
exports.converFEChangesList = converFEChangesList;
var markerInformationForLab = function (pointeInfo) {
    var warehouse_code = (0, array_helpers_1.isValueEmpty)(pointeInfo.warehouse_code);
    var invoice_code = (0, array_helpers_1.isValueEmpty)(pointeInfo.invoice_code);
    var center_name = (0, array_helpers_1.isValueEmpty)(pointeInfo.center_name);
    var latitude = (0, array_helpers_1.isValueEmpty)(pointeInfo.latitude);
    var longitude = (0, array_helpers_1.isValueEmpty)(pointeInfo.longitude);
    return "<div classname='marker-info'>\n<p>Center Name:".concat(center_name, "</p>\n <p>Latitude:").concat(latitude, "</p><p>Longitude:").concat(longitude, "</p>\n <p>Warehouse Code:").concat(warehouse_code, "</p><p>Invoice Code:").concat(invoice_code, "</p>\n\n  </div>");
};
exports.markerInformationForLab = markerInformationForLab;
var LabMarkerEvents = function (marker, startEndInfo, ui) {
    marker.addEventListener('tap', function (evt) {
        var bubble = new maps_api_for_javascript_1.default.ui.InfoBubble(evt.target.getGeometry(), {
            content: (0, exports.markerInformationForLab)(startEndInfo),
        });
        ui.addBubble(bubble);
    }, false);
};
exports.LabMarkerEvents = LabMarkerEvents;
