"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAdditionalRoleInProfile = exports.getAdditionalRoleInApproval = exports.emptyDetails = exports.canUpdateCCcoordinates = exports.feBackup = exports.feSuperVisor = exports.assignRouteDropdown = exports.CreationTabConstants = void 0;
exports.CreationTabConstants = {
    PERSONALDETAILS: 0,
    CONTACTDETAILS: 1,
    BANKACCOUNTDETAILS: 2,
    DRIVINGLICENCEDETAILS: 3,
    EMPLOYMENTDETAILS: 4,
    INVENTORYDETAILS: 5,
};
exports.assignRouteDropdown = [
    {
        id: 1,
        label: 'Dynamic',
        value: 1,
    },
    {
        id: 2,
        label: 'Static',
        value: 2,
    },
];
exports.feSuperVisor = 'Supervisor';
exports.feBackup = 'Backup';
exports.canUpdateCCcoordinates = 'can Update CC coordinates';
exports.emptyDetails = '--';
var getAdditionalRoleInApproval = function (profileData) {
    var assingedRoleString = '';
    if (profileData.employment_details.backup) {
        assingedRoleString += ",".concat(exports.feBackup);
    }
    if (profileData.employment_details.supervisor) {
        assingedRoleString += ",".concat(exports.feSuperVisor);
    }
    if (profileData.employment_details.canUpdateCCCoordinates) {
        assingedRoleString += ",".concat(exports.canUpdateCCcoordinates);
    }
    if (assingedRoleString) {
        return assingedRoleString.slice(1, assingedRoleString.length);
    }
    return exports.emptyDetails;
};
exports.getAdditionalRoleInApproval = getAdditionalRoleInApproval;
var getAdditionalRoleInProfile = function (profileData) {
    var assingedRoleString = '';
    if (profileData.backup) {
        assingedRoleString += ",".concat(exports.feBackup);
    }
    if (profileData.supervisor) {
        assingedRoleString += ",".concat(exports.feSuperVisor);
    }
    if (profileData.can_update_cc_coordinates) {
        assingedRoleString += ",".concat(exports.canUpdateCCcoordinates);
    }
    if (assingedRoleString) {
        return assingedRoleString.slice(1, assingedRoleString.length);
    }
    return exports.emptyDetails;
};
exports.getAdditionalRoleInProfile = getAdditionalRoleInProfile;
