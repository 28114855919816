"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEATURE_CONSTANTS = exports.MENU_LISTS_AND_PATHS = void 0;
var route_path_1 = require("../constants/route_path");
exports.MENU_LISTS_AND_PATHS = [
    { moduleName: 'Overview', menuOrder: 1, menuicon: 'icon-graph', path: '' },
    {
        moduleName: 'FE Management',
        menuOrder: 2,
        menuicon: 'icon-search-user',
        path: "".concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.activeList),
    },
    {
        moduleName: 'Payroll',
        menuOrder: 3,
        menuicon: 'icon-cash',
        path: "".concat(route_path_1.PATHS.payroll),
    },
    {
        moduleName: 'Leave Management',
        menuOrder: 4,
        menuicon: 'icon-date',
        path: "".concat(route_path_1.PATHS.leaveTraking),
    },
    {
        moduleName: 'FE Roster Management',
        menuOrder: 5,
        menuicon: 'icon-slot',
        path: "".concat(route_path_1.PATHS.slotBlocking),
    },
    {
        moduleName: 'Role Management',
        menuOrder: 6,
        menuicon: 'icon-search-user',
        path: "".concat(route_path_1.PATHS.role_management),
    },
    {
        moduleName: 'User Management',
        menuOrder: 7,
        menuicon: 'icon-supervisor_account',
        path: "".concat(route_path_1.PATHS.userManagement),
    },
    {
        moduleName: 'Vendor Management',
        menuOrder: 8,
        menuicon: 'icon-business_center',
        path: "".concat(route_path_1.PATHS.vendormanagement, "/").concat(route_path_1.PATHS.activeList),
    },
    {
        moduleName: 'Approvals',
        menuOrder: 9,
        menuicon: 'icon-thumbs_up_down',
        submenu: [
            {
                id: 1,
                label: 'FE',
                fieldName: 'FE',
                module: 'Approvals',
                path: "".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.levelOnePendingApproval),
            },
            {
                id: 2,
                label: 'Vendor',
                fieldName: 'Vendor',
                module: 'Approvals',
                path: "".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.vendorlist, "/").concat(route_path_1.PATHS.levelOnePendingApproval),
            },
        ],
    },
    {
        moduleName: 'Notification Management',
        menuOrder: 10,
        menuicon: 'icon-bell',
        path: "".concat(route_path_1.PATHS.notificationManagement),
    },
    {
        moduleName: 'User Masters',
        menuOrder: 11,
        menuicon: 'icon-person_pin',
        path: "".concat(route_path_1.PATHS.userMasters),
    },
    {
        moduleName: 'Audit',
        menuOrder: 12,
        menuicon: 'icon-fact_check',
        path: "".concat(route_path_1.PATHS.audit),
    },
    {
        moduleName: 'Sample Tracking',
        menuOrder: 13,
        menuicon: 'icon-moped',
        path: "".concat(route_path_1.PATHS.sampleTracking),
    },
    {
        moduleName: 'ETRF Management',
        menuOrder: 14,
        menuicon: 'icon-create_new_folder',
        path: "".concat(route_path_1.PATHS.ETRFList),
    },
    {
        moduleName: 'Control Tower',
        menuOrder: 15,
        menuicon: 'icon-routing',
        path: "".concat(route_path_1.PATHS.controlTower),
    },
    {
        moduleName: 'Reports',
        menuOrder: 16,
        menuicon: 'icon-text_snippet',
        submenu: [
            {
                id: 1,
                label: 'My Reports',
                fieldName: 'Cash_Report',
                module: 'Reports',
                path: "".concat(route_path_1.PATHS.MYREPORTS),
            },
            {
                id: 2,
                label: 'Cash Collection Report',
                fieldName: 'Cash_Report',
                module: 'Reports',
                path: "".concat(route_path_1.PATHS.cahCollectionReport),
            },
            {
                id: 3,
                label: 'Cash Pending Report',
                fieldName: 'FE_Cash_Pending',
                module: 'Reports',
                path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.cashPendingReport),
            },
            {
                id: 4,
                label: 'Bagging LifeCycle',
                fieldName: 'Bag_Report',
                module: 'Reports',
                path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.baggingLifeCycle),
            },
            {
                id: 5,
                label: 'Visits Report',
                fieldName: 'Visits_Report',
                module: 'Reports',
                path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.visitsReport),
            },
        ],
    },
    {
        moduleName: 'Other Documents',
        menuOrder: 17,
        menuicon: 'icon-create_new_folder',
        path: "".concat(route_path_1.PATHS.otherDocuments),
    },
    {
        moduleName: 'Masters',
        menuOrder: 18,
        menuicon: 'icon-person_pin',
        path: "".concat(route_path_1.PATHS.mastersData),
    },
    {
        moduleName: 'Precious Samples',
        menuOrder: 19,
        menuicon: 'icon-local_police',
        path: "".concat(route_path_1.PATHS.preciousSamples),
    },
    {
        moduleName: 'Heat Maps',
        menuOrder: 20,
        menuicon: 'icon-my_location',
        path: "".concat(route_path_1.PATHS.ROUTE_MAP),
    },
    {
        moduleName: 'MIS Reports',
        menuOrder: 21,
        menuicon: 'icon-assignment',
        path: "".concat(route_path_1.PATHS.misReport, "/").concat(route_path_1.PATHS.eventPerformance),
    },
    {
        moduleName: 'Visit Management',
        menuOrder: 22,
        menuicon: 'icon-money',
        submenu: [
            {
                id: 1,
                label: 'Add Visits ',
                fieldName: 'Add',
                module: 'Visit Management',
                path: "".concat(route_path_1.PATHS.Visits, "/").concat(route_path_1.PATHS.AddVisits),
            },
            {
                id: 2,
                label: 'Visits List',
                fieldName: 'List',
                module: 'Visit Management',
                path: "".concat(route_path_1.PATHS.Visits, "/").concat(route_path_1.PATHS.VisitsList),
            },
        ],
    },
    {
        moduleName: 'CRE Management',
        menuOrder: 22,
        path: "".concat(route_path_1.PATHS.cre),
        menuicon: 'icon-search-user',
    },
    {
        moduleName: 'Client Roster Management',
        menuOrder: 24,
        menuicon: 'icon-text_snippet',
        path: "".concat(route_path_1.PATHS.ccworkinghourslist),
    },
    {
        moduleName: 'Admin User Management',
        menuOrder: 25,
        path: "".concat(route_path_1.PATHS.User_Logistics),
        menuicon: 'icon-search-user',
    },
    {
        moduleName: 'Route Planner',
        menuOrder: 26,
        menuicon: 'icon-text_snippet',
        submenu: [
            {
                id: 2,
                label: 'Planner',
                fieldName: 'Route_List',
                module: 'Route Planner',
                path: "".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_list),
            },
            {
                id: 2,
                label: 'Approvals',
                fieldName: 'Route_Approve',
                module: 'Route Planner',
                path: "".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.approvals_route_planner),
            },
        ],
    },
    {
        moduleName: 'Lab Roster Management',
        menuOrder: 27,
        menuicon: 'icon-text_snippet',
        path: "".concat(route_path_1.PATHS.LabMasterlist),
    },
    {
        moduleName: 'Hub Roster Management',
        menuOrder: 28,
        menuicon: 'icon-search-user',
        path: "".concat(route_path_1.PATHS.hubMasterList),
    },
    {
        moduleName: 'Master Box',
        menuOrder: 29,
        menuicon: 'icon-search-user',
        path: "".concat(route_path_1.PATHS.masterBoxtracking),
    },
    {
        moduleName: 'Regon',
        menuOrder: 29,
        menuicon: 'icon-search-user',
        path: "".concat(route_path_1.PATHS.region_mapping),
    },
    {
        moduleName: 'Shipment Tracking',
        menuOrder: 30,
        menuicon: 'icon-routing',
        path: "".concat(route_path_1.PATHS.shipment_tracking),
    },
];
exports.FEATURE_CONSTANTS = {
    feManagement: 'FE Management',
    createFE: 'FE_Creation',
    replaceFE: 'FE_Replace',
    editFE: 'FE_Edit',
    exitFE: 'FE_Exit',
    inActiveFE: 'FE_In-active',
    deleteFE: 'FE_Delete_Onboard_List',
    fEDeleteOnboardList: 'FE_Delete_Onboard_List',
    reports: 'Reports',
    autoRoutingReportList: 'Auto_Routing_Report_List',
    bagReportList: 'Bag_Report_List',
    cashPendingList: 'FE_Cash_Pending_Report_List',
    cashReportList: 'Cash_Report_List',
    cashReportDownload: 'Cash_Report_Download',
    autoRoutingReportDownload: 'Auto_Routing_Report_Download',
    cashPendingReportDownload: 'FE_Cash_Pending_Report_Download',
    bagReportDownload: 'Bag_Report_Download',
    sampleTracking: 'Sample Tracking',
    sampleTrackingList: 'Sample_Tracking_List',
    bagJourney: 'Bag_Journey',
    sampleBagDetails: 'Sample_Bag_Details',
    sampleJourney: 'Sample_Journey',
    audit: 'Audit',
    auditList: 'Audit List',
    auditCreation: 'Audit_Creation',
    auditAssignment: 'Audit_Assignment',
    auditDelete: 'Audit_Delete',
    auditStats: 'Audit_Stats',
    auditEdit: 'Audit_Edit',
    auditDownload: 'Audit_Download',
    roleManagement: 'Role Management',
    roleEdit: 'Role_Edit',
    roleDelete: 'Role_Delete',
    roleList: 'Role List',
    roleCreate: 'Role_Create',
    vendorManagement: 'Vendor Management',
    vendorCreation: 'Vendor_Creation',
    vendorEdit: 'Vendor_Edit',
    vendorexit: 'Vendor_Exit',
    vendorDelete: 'Vendor_Delete',
    vendorApproval: 'Vendor Approval',
    vendorApprovalLevel1: 'Vendor_Approval_Level_1',
    vendorApprovalLevel2: 'Vendor_Approval_Level_2',
    controlTower: 'Control Tower',
    userManagement: 'User Management',
    createUser: 'User_Create',
    editUser: 'User_Edit',
    userListDownload: 'User_List_Download',
    leaveManagement: 'Leave Management',
    leaveApprove: 'Approve',
    leaveCreate: 'Create',
    leaveDelete: 'Delete',
    leaveReject: 'Reject',
    misReports: 'MIS Reports',
    eventPerformance: 'FE_Event_Performance_Download',
    feRouteDownload: 'FE_Route_Download',
    visitManagement: 'Visit Management',
    preciousSample: 'Precious Samples',
    addSample: 'Precious_Sample_Creation',
    deleteSample: 'Precious_Sample_Delete',
    editSample: 'Precious_Sample_Edit',
    rosterManagement: 'FE Roster Management',
    addSlot: 'Add_Slots',
    rosterManagementDownload: 'Download',
    roasterManagemntListing: 'List',
    notificationManagement: 'Notification Management',
    createNotification: 'Create',
    ETRFDocuments: 'ETRF Management',
    ETRFDownload: 'ETRF_Download',
    ETRFView: 'ETRF_View',
    approvals: 'Approvals',
    FEApprovalLevelOnePendingList: 'FE_Approval_Level_1_Pending_List',
    FEApprovalLevelTwoPendingList: 'FE_Approval_Level_2_Pending_List',
    FELevelOneApprove: 'FE_Level_1_Approve',
    FELevelTwoApprove: 'FE_Level_2_Approve',
    FELevelOneReject: 'FE_Level_1_Reject',
    FELevelTwoReject: 'FE_Level_2_Reject',
    vendorApprovalLevelOnePendingList: 'Vendor_Approval_Level_1_Pending_List',
    vendorApprovalLevelTwoPendingList: 'Vendor_Approval_Level_2_Pending_List',
    vendorLevelOneApprove: 'Vendor_Level_1_Approve',
    vendorLevelTwoApprove: 'Vendor_Level_2_Approve',
    vendorLevelOneReject: 'Vendor_Level_1_Reject',
    vendorLevelTwoReject: 'Vendor_Level_2_Reject',
    cre: 'CRE Management',
    updateCRE: 'Update_CRE',
    addCRE: 'Add_CRE',
    creList: 'CRE_List',
    createHUBTimeWindows: 'Create_Hub_Time_Windows',
    updateHUBTimeWindows: 'Update_Hub_Time_Windows',
    listHUBTimeWindows: 'List_Hub_Time_Windows',
    deleteHUBTimeWindows: 'Delete_Hub_Time_Windows',
    createLABTime_Windows: 'Create_LAB_Time_Windows',
    updateLABTimeWindows: 'Update_LAB_Time_Windows',
    listLabTimeWindows: 'List_Lab_Time_Windows',
    routeGenerate: 'Route_Generate',
    routeEdit: 'Route_Edit',
    routeDownload: 'Route_List_Downaload',
    routeApprove: 'Route_Approve',
    routeReject: 'Route_Reject',
    updateCCTimeWindow: 'Update_CC_Time_Windows',
    adminUserCreate: 'Admin_User_Create',
    ACTIVE_FE: 'FE List',
    DRAFT_FE: 'FE Onboard In-progress List',
    EXIT_FE_List: 'FE Exit List',
    ON_LEAVE_TODAY: 'FE On Leave List',
    NEW_FE_PENDING_APPROVAL: 'FE Approval Pending List',
    FE_INVENTORY_PENDING: 'FE Inventory Pending',
    FE_INACTIVE_FE_LIST: 'FE In-active List',
    FE_REJECTED_LIST: 'FE Rejected List',
    CREATE_NEW_FE: 'FE Creation',
    FE_Edit: 'FE Edit',
    FE_Delete_Onboard_List: 'FE Delete Onboard List',
    FE_Profile: 'FE Profile',
    FE_KPIs: 'FE KPIs',
    FE_Track: 'FE Track',
    FE_EXIT_BUTTON: 'EXIT_FE',
    RE_PLACE_FE: 'FE Replace',
    FE_LIST_DOWNLOAD: 'FE_List_Download',
    Bypass_Visit: 'Bypass_Visit',
    L_ROSTER_DOWNLOAD: 'Download',
    L_ROSTER_LIST: 'List',
    L_ROSTER_ADD_SLOTS: 'Add Slots',
    Role_List: 'Role List',
    Role_Delete: 'Role Delete',
    Role_Edit: 'Role Edit',
    Role_Create: 'Role Create',
    approval_vendor: 'Vendor',
    Vendor_Edit: 'Vendor Edit',
    Vendor_Onboarding_In_progress_List: 'Vendor Onboarding In-progress List',
    Vendor_Exit: 'Vendor Exit',
    Vendor_Approval_Pending_List: 'Vendor Approval Pending List',
    Vendor_Rejected_List: 'Vendor Rejected List',
    Vendor_Exit_List: 'Vendor Exit List',
    Vendor_List: 'Vendor List',
    Vendor_Creation: 'Vendor Creation',
    Vendor_Delete: 'Vendor Delete',
    Vendor_Approval_Level_2_Pending_List: 'Vendor Approval Level 2 Pending List',
    FE_Level_2_Reject: 'FE Level 2 Reject',
    FE_Level_1_Reject: 'FE Level 1 Reject',
    FE_Level_1_Approve: 'FE Level 1 Approve',
    Vendor_Level_2_Approve: 'Vendor Level 2 Approve',
    FE_Approval_Level_1_Pending_List: 'FE Approval Level 1 Pending List',
    Vendor_Approval_Level_1_Pending_List: 'Vendor Approval Level 1 Pending List',
    Vendor_Level_2_Reject: 'Vendor Level 2 Reject',
    Vendor_Level_1_Approve: 'Vendor Level 1 Approve',
    FE_Approval_Level_2_Pending_List: 'FE Approval Level 2 Pending List',
    FE_Level_2_Approve: 'FE Level 2 Approve',
    Vendor_Level_1_Reject: 'Vendor Level 1 Reject',
    Audits_Approvals: 'Audits & Approvals',
    Audit_List: 'Audit List',
    Audit_Stats: 'Audit Stats',
    Audit_Edit: 'Audit Edit',
    Audit_Assignment: 'Audit Assignment',
    Audit_Creation: 'Audit Creation',
    Audit_Delete: 'Audit Delete',
    CREATE_NOTIFICATIONS: 'Create',
    NOTIFICATIONS_LIST: 'List',
    Sample_Bag_Details: 'Sample Bag Details',
    Bag_Journey: 'Bag Journey',
    Sample_Journey: 'Sample Journey',
    Sample_Tracking_List: 'Sample Tracking List',
    ETRF_Download: 'ETRF Download',
    ETRF_List: 'ETRF List',
    ETRF_View: 'ETRF View',
    Precious_Samples: 'Precious Samples',
    Precious_Sample_List: 'Precious Sample List',
    Precious_Sample_Creation: 'Precious Sample Creation',
    Precious_Sample_Edit: 'Precious Sample Edit',
    Heat_Maps: 'Heat Maps',
    Tracking_FE_Location: 'Tracking FE Location',
    Heat_Map_Download: 'Download_visit_list',
    VISITS_List: 'List',
    VISITS_Add: 'Add',
    VISITS_LIST_DOWNLOAD: 'Download',
    VISITS_TRANSFER: 'Visit_Transfer',
    Add_CRE: 'Add CRE',
    Update_CRE: 'Update CRE',
    CRE_list: 'CRE List',
    E_Event_Performance_Download: 'E Event Performance Download',
    FE_Route_Download: 'FE Route Download',
    FE_Route: 'FE Route',
    FE_Event_Performance: 'FE Event Performance',
    Auto_Routing_Dashboard: 'Auto-Routing Dashboard',
    Control_tower: 'Control Tower',
    Total_Workforce: 'Total Workforce',
    Static_Routes: 'Static Routes',
    Pickup_Static: 'Pickup Static',
    Control_tower_List: 'LisT',
    On_Demand_Request: 'On Demand Request',
    Add_New_Route: 'Add New Route',
    Pickup_Dynamic: 'Pickup Dynamic',
    Control_tower_Download: 'Download',
    Static_Clients: 'Static Clients',
    Dynamic_Clients: 'Dynamic Clients',
    Assign_Route: 'Assign Route',
    Cash_Report_List: 'Cash Report List',
    FE_Cash_Pending_Report_List: 'FE Cash Pending Report List',
    Bag_Report_Download: 'Bag Report Download',
    Auto_Routing_Report_Download: 'Auto Routing Report Download',
    Auto_Routing_Report_List: 'Auto Routing Report List',
    Bag_Report_List: 'Bag Report List',
    My_Reports_List: 'My Reports List',
    Cash_Report_Download: 'Cash Report Download',
    FE_Cash_Pending_Report_Download: 'FE Cash Pending Report Download',
    Visit_Report_List: 'Visits Report List',
    FE_Attendance: 'FE Attendance',
    Clients_Slot_Wise_Productivity: 'Clients Slot Wise Productivity',
    CC_Roster_Management: 'Client Roster Management',
    Update_CC_Time_Windows: 'Update CC Time Windows',
    List_CC_Tim_eWindows: 'List CC Time Windows',
    User_Logistics: 'UserLogistics',
    Route_Planner: 'Route Planner',
    Route_List: 'Route List',
    Route_Edit: 'Route Edit',
    Admin_User_Management: 'Admin User Management',
    Admin_User_Create: 'Admin User Create',
    Admin_User_List: 'Admin User List',
    Lab_Master: 'Lab Master',
    Lab_Roster_Management: 'Lab Roster Management',
    Create_LAB_Time_Windows: 'Create LAB Time Windows',
    Update_LAB_Time_Windows: 'Update LAB Time Windows',
    List_Lab_Time_Windows: 'List Lab Time Windows',
    HUB_Roster_Management: 'Hub Roster Management',
    Create_HUB_Time_Windows: 'Create Hub Time Windows',
    Update_HUB_Time_Windows: 'Update Hub Time Windows',
    List_HUB_Time_Windows: 'List Hub Time Windows',
    Delete_HUB_Time_Windows: 'Delete Hub Time Windows',
    master_box_tracking: 'Master Box',
    master_box_tracking_list: 'List',
    master_box_tracking_download: 'Download',
    sub_vendor: 'Vendor',
    sub_FE: 'FE',
    sub_Planner: 'Planner',
    sub_planner_approval: 'Approvals',
    sub_add_visite: 'Add Visits',
    sub_visite_list: 'Visits List',
    sub_my_reports: 'My Reports',
    sub_cash_collection: 'Cash Collection Report',
    sub_cash_pending: 'Cash Pending Report',
    sub_bagining_cycle: 'Bagging LifeCycle',
    sub_visite_reports: 'Visits Report',
    Operations_Management: 'Operations Management',
    intracity: 'Intracity',
    Lab_Handover_Trend: 'Lab Handover Trend ',
    Ontime_Pickup: 'Ontime Pickup',
    Auto_Routing: 'Auto Routing',
    Intracity_TAT: 'Intracity TAT',
    Tat: 'Tat',
    intercity: 'Intercity',
    R_Lab_to_P_Lab_INTERCIT: 'R-Lab to P-Lab INTERCITY',
    Outstation_Master_Box: 'Outstation Master Box',
    shipment_tracking: 'Shipment Tracking',
    track_shipment: 'Track_Shipment',
    track_master_shipment: 'Track_Master_Shipment',
    shipment_details: 'Shipment_Details',
    shipmentDetails: 'Shipment Details',
    Region_Management: 'Region Management',
    Region_to_user_Mapping: 'Region to User Mapping',
    Region_update: 'Region Update',
    Region_creation: 'Region Creation',
    Region_List: 'Region List',
    FE_live_tracking: 'FE Tracking',
    FE_live_tracking_list: 'List',
    FE_live_tracking_Details: 'Details',
    Master_Management: 'Master Management',
    Shipment_Route_Master: 'Shipment Route Master',
    Shipment_List: 'List',
    Shipment_Edit: 'Edit',
    Shipment_Create: 'Create',
    ORC_SCF_Management: 'ORC & SCF',
    ORC_Management: 'ORC Management',
    ACTIVATE_DEACTIVATE_LIST: 'Active & Deactivate List',
    LEVEL_1_2_PENDING_LIST: 'Pending List',
    LEVEL_1_2_REJECTED_LIST: 'Rejected List',
    CREATE_CLIENT: 'Create',
    ACTIVATE_CLIENT: 'Activate',
    DELETE_CLIENT: 'Delete',
    DOWNLOAD_REPORT: 'Download',
    MODIFY_CLIENT: 'Modify',
    DEACTIVATE_CLIENT: 'Deactivate',
    LEVEL_1_2_APPROVE_ACTION: 'Approve',
    SCF_Management: 'SCF Management',
    SCF_DOWNLOAD_REPORT: 'Download',
    SCF_REJECT_CLIENT: 'Reject',
    SCF_LEVEL_1_2_APPROVE_ACTION: 'Approve',
    SCF_CREATE_CLIENT: 'Create',
    SCF_ACTIVATE_DEACTIVATE_LIST: 'Active & Deactivate List',
    SCF_ACTIVATE_CLIENT: 'Activate',
    SCF_DELETE_CLIENT: 'Delete',
    SCF_MODIFY_CLIENT: 'Modify',
    SCF_LEVEL_1_2_REJECTED_LIST: 'Rejected List',
    SCF_DEACTIVATE_CLIENT: 'Deactivate',
    SCF_LEVEL_1_2_PENDING_LIST: 'Pending List',
};
