"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var formik_1 = require("formik");
var schema_1 = require("../../constants/schema");
var checkbox_component_1 = require("../checkbox/checkbox_component");
var array_helpers_1 = require("../../utils/array_helpers");
var button_component_1 = require("../buttons/button_component");
var material_1 = require("@mui/material");
var RoastersForMultipleDays = function (_a) {
    var weekDaysSubmitHandler = _a.weekDaysSubmitHandler, weekDays = _a.weekDays, cancelhandler = _a.cancelhandler;
    var _b = React.useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ]), checkedArr = _b[0], setCheckedArr = _b[1];
    var checkboxHandle = function (e, values, value, id, setFieldValue) {
        var selectedModalDays = values.selectedWeeks;
        var checked = e.target.checked;
        if (checked) {
            selectedModalDays.push((0, array_helpers_1.convertDayIntoDayid)(value));
            checkedArr[--id] = true;
        }
        else {
            var index = selectedModalDays.indexOf((0, array_helpers_1.convertDayIntoDayid)(value));
            if (index > -1) {
                selectedModalDays.splice(index, 1);
            }
            checkedArr[--id] = false;
        }
        setFieldValue('selectedWeeks', selectedModalDays);
        setCheckedArr(checkedArr);
    };
    var formsubmitHandler = function (values) {
        weekDaysSubmitHandler(values.selectedWeeks);
    };
    var handleSelectAll = function (e, setFieldValue) {
        if (e.target.checked) {
            var selecteddays_1 = [];
            weekDays.map(function (weekDay) {
                return selecteddays_1.push((0, array_helpers_1.convertDayIntoDayid)(weekDay.day));
            });
            setFieldValue('selectedWeeks', selecteddays_1);
            setCheckedArr([true, true, true, true, true, true, true]);
        }
        else {
            setFieldValue('selectedWeeks', []);
            setCheckedArr([false, false, false, false, false, false, false]);
        }
    };
    return (React.createElement(formik_1.Formik, { initialValues: {
            selectedWeeks: [],
        }, validateOnBlur: false, validationSchema: schema_1.schemas.WeekDaysSchema, onSubmit: formsubmitHandler }, function (_a) {
        var errors = _a.errors, values = _a.values, setFieldValue = _a.setFieldValue;
        return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
            React.createElement("div", { className: "modal-header " },
                React.createElement("h2", { className: "heading h1tagcolor" }, "Select Week Days")),
            React.createElement("div", { className: "modal-body" },
                React.createElement(material_1.FormControlLabel, { label: "Select All", control: React.createElement(material_1.Checkbox, { checked: !(checkedArr.indexOf(false) >= 0), onChange: function (e) { return handleSelectAll(e, setFieldValue); } }) }), weekDays === null || weekDays === void 0 ? void 0 :
                weekDays.map(function (weekDaysDetails, key) {
                    return (React.createElement(checkbox_component_1.default, { label: weekDaysDetails.day, handleChange: function (e) {
                            return checkboxHandle(e, values, weekDaysDetails.day, weekDaysDetails.id, setFieldValue);
                        }, value: key + 1, checked: checkedArr[key] }));
                })),
            errors['selectedWeeks'] ? (React.createElement("div", { className: "error" },
                errors['selectedWeeks'],
                " ")) : null,
            React.createElement("div", { className: "modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "CANCEL", color: "yellow-outline", onClickHandler: cancelhandler }),
                React.createElement(button_component_1.default, { buttonName: "SAVE", type: "submit", color: "yellow" }))));
    }));
};
exports.default = RoastersForMultipleDays;
