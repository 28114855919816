"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var button_component_1 = require("../../../../common/buttons/button_component");
var AssignButton = function (_a) {
    var visitId = _a.visitId, center_name = _a.center_name, time_window = _a.time_window;
    console.log(visitId, 'visitId');
    return (React.createElement("div", { className: "table-col-btns" },
        React.createElement("div", { className: "btn-modify" },
            React.createElement(button_component_1.default, { color: "yellow", buttonName: "RESCHEDULE" })),
        React.createElement(material_1.IconButton, { className: 'icon-btn' },
            React.createElement("span", { className: "icon-cross" }))));
};
exports.default = AssignButton;
