"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_error_boundary_1 = require("react-error-boundary");
var react_redux_1 = require("react-redux");
require("./components/layout/layout.scss");
var loader_1 = require("./components/loader/loader");
var menu_layout_1 = require("./components/menu_layout/menu_layout");
var Pagenotfound_1 = require("./ErrorBoundary/Pagenotfound");
require("./i18n");
var unAuthRoutes_1 = require("./routes/unAuthRoutes");
var App = function () {
    var isLoggedIn = (0, react_redux_1.useSelector)(function (state) { return state.user.loginStatus; });
    console.log('isLoggedIn', isLoggedIn);
    return (React.createElement("div", { className: "main-wrapper" },
        React.createElement(loader_1.default, null),
        React.createElement(react_error_boundary_1.ErrorBoundary, { FallbackComponent: Pagenotfound_1.default }, !isLoggedIn ? React.createElement(unAuthRoutes_1.default, null) : React.createElement(menu_layout_1.default, null))));
};
exports.default = App;
