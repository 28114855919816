"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_dom_1 = require("react-router-dom");
var route_path_1 = require("../../../constants/route_path");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var EditIconButton = function (_a) {
    var reject_reason_id = _a.reject_reason_id, user_id = _a.user_id;
    var nav = (0, react_router_dom_1.useNavigate)();
    var _b = React.useState(true), disable = _b[0], setDisable = _b[1];
    var feManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.feManagement);
    var editRejectedFE = function (e) {
        if (user_id) {
            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(user_id), { replace: true });
        }
        else {
            react_hot_toast_1.toast.error('Invalid User ID');
        }
    };
    React.useEffect(function () {
        if (reject_reason_id === 1 &&
            feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.editFE]) {
            setDisable(false);
        }
    }, []);
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn", onClick: editRejectedFE, disabled: disable },
            React.createElement("span", { className: "icon-edit" }))));
};
exports.default = EditIconButton;
