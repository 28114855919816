"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Delete_1 = require("@mui/icons-material/Delete");
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var styles_1 = require("@mui/styles");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var ModalComponentWithoutButton_1 = require("../../../common/modal/ModalComponentWithoutButton");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var array_helpers_1 = require("../../../utils/array_helpers");
var CC_helper_1 = require("../CC/CC_helper");
var shipmentApi_1 = require("./shipmentApi");
require("./shipmentRoute.scss");
var useStyles = (0, styles_1.makeStyles)({
    customSelect: {
        backgroundColor: '#f5f7fa !important',
        marginTop: 8,
        '& .MuiList-root': {
            maxHeight: '180px',
            '& .MuiMenuItem-root': {
                fontSize: '14px',
            },
        },
    },
});
var shipmentRouteCreate = function () {
    var nav = (0, react_router_1.useNavigate)();
    var pathname = window.location.pathname;
    var rowId = (0, react_router_1.useParams)().id;
    var _a = React.useState(false), mode = _a[0], setMode = _a[1];
    var _b = React.useState(false), isShipmentCreated = _b[0], setIsShipmentCreated = _b[1];
    var _c = React.useState(false), isLabsSame = _c[0], setIsLabsSame = _c[1];
    ;
    var LabcodeList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var converteLabList = (0, array_helpers_1.convertLabcodeResponse)(LabcodeList);
    var emptySlots = [
        {
            id: Math.random(),
            pickUp_hour: '10',
            pickUp_min: '00',
            designated_hour: '10',
            designated_min: '01',
            isError: false,
            transport_mode: {
                id: 1,
                label: 'MULTIMODAL',
                value: 'MULTIMODAL',
            },
            tat: {
                id: 0,
                label: 'Same Day',
                value: 0,
                hours: 0,
            },
        },
    ];
    var initialValues = {
        rlab: null,
        plab: null,
        lane: '',
        slots: emptySlots,
    };
    var _d = React.useState(initialValues), createShipmentFormValues = _d[0], setCreateShipmentFormValues = _d[1];
    var navigateTOListPage = function () {
        nav("/".concat(route_path_1.default.PATHS.shipment_list));
    };
    var formSubmitHandler = function (values) {
        var _a;
        var payload = {};
        var shipmentSlots = [];
        (_a = values === null || values === void 0 ? void 0 : values.slots) === null || _a === void 0 ? void 0 : _a.map(function (each) {
            var slot = {};
            slot['pickup_cut_off_time'] = "".concat(each === null || each === void 0 ? void 0 : each.pickUp_hour, ":").concat(each === null || each === void 0 ? void 0 : each.pickUp_min);
            slot['designated_hitting_time'] = "".concat(each === null || each === void 0 ? void 0 : each.designated_hour, ":").concat(each === null || each === void 0 ? void 0 : each.designated_min);
            slot['tat'] = each.tat.value;
            slot['transport_mode'] = each.transport_mode.value;
            shipmentSlots.push(slot);
        });
        if (values.rlab) {
            payload['registration_lab'] = values.rlab.value;
        }
        if (values.plab) {
            payload['processing_lab'] = values.plab.value;
        }
        if (values.rlab && values.plab) {
            payload['lane'] = "".concat(values.rlab.value, " - ").concat(values.plab.value);
        }
        if (values.slots) {
            payload['slots'] = shipmentSlots;
        }
        if (rowId) {
            payload['shipment_route_id'] = parseInt(rowId);
        }
        var endpoint;
        if (pathname.includes('ShipmentEdit')) {
            endpoint = api_service_1.api_service.api_urls.SHIPMENT_UPDATE;
        }
        else {
            endpoint = api_service_1.api_service.api_urls.SHIPMENT_CREATE;
        }
        (0, shipmentApi_1.createShipmentRoute)(payload, endpoint)
            .then(function (response) {
            if (response.statusCode === 200) {
                navigateTOListPage();
            }
            else {
                react_hot_toast_1.default.error(response.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
        setIsShipmentCreated(false);
    };
    var formSubmit = function (values) {
        var _a;
        var isError = false;
        if (values === null || values === void 0 ? void 0 : values.slots) {
            (_a = values.slots) === null || _a === void 0 ? void 0 : _a.map(function (each) {
                if (each.isError) {
                    isError = true;
                }
            });
        }
        if (!isError && !isLabsSame) {
            setIsShipmentCreated(true);
        }
    };
    var EditData = function () {
        var payload = {};
        if (rowId) {
            payload['shipment_route_id'] = rowId;
        }
        else {
            payload['notification_id'] = '';
        }
        (0, shipmentApi_1.EditShipmentRoute)(payload)
            .then(function (res) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if (res.statusCode === 200) {
                var shipmentSlots_1 = [];
                var transportMode = ((_a = res.data) === null || _a === void 0 ? void 0 : _a.shipment_slots[0].transport_mode)
                    ? (_b = res.data) === null || _b === void 0 ? void 0 : _b.shipment_slots[0].transport_mode
                    : '';
                (_d = (_c = res.data) === null || _c === void 0 ? void 0 : _c.shipment_slots) === null || _d === void 0 ? void 0 : _d.map(function (each) {
                    var slot = {};
                    slot['pickUp_hour'] = each.pickup_cut_off_time.split(':')[0];
                    slot['pickUp_min'] = each.pickup_cut_off_time.split(':')[1];
                    slot['designated_hour'] = each.designated_hitting_time.split(':')[0];
                    slot['designated_min'] = each.designated_hitting_time.split(':')[1];
                    slot['tat'] = (0, array_helpers_1.ShipmentTat)(each.tat);
                    slot['transport_mode'] = (0, CC_helper_1.convertEditData)(each.transport_mode);
                    shipmentSlots_1.push(slot);
                });
                setCreateShipmentFormValues({
                    rlab: ((_e = res.data) === null || _e === void 0 ? void 0 : _e.registration_lab)
                        ? (0, CC_helper_1.convertEditDataForLabCode)((_f = res.data) === null || _f === void 0 ? void 0 : _f.registration_lab, converteLabList)
                        : '',
                    plab: ((_g = res.data) === null || _g === void 0 ? void 0 : _g.processing_lab)
                        ? (0, CC_helper_1.convertEditDataForLabCode)((_h = res.data) === null || _h === void 0 ? void 0 : _h.processing_lab, converteLabList)
                        : '',
                    transPortMode: {
                        label: transportMode,
                        value: transportMode,
                    },
                    lane: res.data.lane ? res.data.lane : '',
                    slots: shipmentSlots_1.length > 0
                        ? shipmentSlots_1
                        : pathname.includes('ShipmentView')
                            ? []
                            : emptySlots,
                });
            }
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var calculteTime = function (values, index) {
        var _a, _b;
        var pickupTimeH = values.slots[index].pickUp_hour
            .toString()
            .replaceAll(' ', '');
        var designatedH = values.slots[index].designated_hour
            .toString()
            .replaceAll(' ', '');
        var startDate = new Date();
        var endDate = new Date();
        var tatType = ((_a = values.slots[index].tat) === null || _a === void 0 ? void 0 : _a.value) != null ? (_b = values.slots[index].tat) === null || _b === void 0 ? void 0 : _b.value : 0;
        console.log(tatType, 'tatType');
        startDate.setDate(startDate.getDate() + 0);
        endDate.setDate(endDate.getDate() + tatType);
        var currentDate = startDate.toISOString().split('T')[0];
        var nextDate = endDate.toISOString().split('T')[0];
        var date1 = new Date("".concat(currentDate, " ").concat(pickupTimeH, ":").concat(values.slots[index].pickUp_min));
        var date2 = new Date("".concat(nextDate, " ").concat(designatedH, ":").concat(values.slots[index].designated_min));
        var diff = date2.getTime() - date1.getTime();
        var msec = diff;
        var hh = Math.floor(msec / 1000 / 60 / 60);
        msec -= hh * 1000 * 60 * 60;
        var mm = Math.floor(msec / 1000 / 60);
        msec -= mm * 1000 * 60;
        console.log("".concat(hh, ":").concat(mm, " (H:M)"));
        return "".concat(hh, ":").concat(mm, " (H:M)");
    };
    var validateTimeSlots = function (index, setFieldValue, pickupHour, pickupMin, designateHour, designatedMin, tatHour) {
        var beginningTime = moment({
            h: parseInt(pickupHour),
            m: parseInt(pickupMin),
        });
        var endTime = moment({
            h: parseInt(designateHour) + tatHour,
            m: parseInt(designatedMin),
        });
        var startTime = moment("".concat(pickupHour, ":").concat(pickupMin), 'hh:mm');
        var finishTime = moment("".concat(designateHour + tatHour, ":").concat(designatedMin), 'hh:mm');
        if (startTime.isSame(finishTime)) {
            setFieldValue("slots[".concat(index, "].isError"), false);
        }
        else if (beginningTime.isBefore(endTime)) {
            setFieldValue("slots[".concat(index, "].isError"), false);
        }
        else {
            setFieldValue("slots[".concat(index, "].isError"), true);
        }
    };
    React.useEffect(function () {
        if (rowId) {
            EditData();
        }
    }, [rowId]);
    React.useEffect(function () {
        if (pathname.includes('/ShipmentCreate/ShipmentView'))
            setMode('view');
        else if (pathname.includes('/ShipmentCreate/ShipmentEdit'))
            setMode('edit');
        else
            setMode(false);
    }, [pathname]);
    var classes = useStyles();
    var timeSlots = function (values, setFieldValue, setFieldTouched, errors, touched, remove, index) {
        var _a;
        return (React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, spacing: 3, alignItems: "center" },
            React.createElement(Grid_1.default, { item: true, xs: 4, lg: 3 },
                React.createElement("p", { className: "cut-off-time" }, "Pickup Cutoff Time"),
                React.createElement(material_1.Stack, { direction: "row", gap: 2, alignItems: "center", className: "cst-timepicker", width: "70%" },
                    React.createElement(material_1.FormControl, { fullWidth: true },
                        React.createElement(material_1.InputLabel, { id: "hour" }),
                        React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, key: values.pickUp_hour, labelId: "hour", id: "hour", name: "hour", value: values.slots[index].pickUp_hour, defaultValue: values.pickUp_hour, disabled: mode === 'view' ? true : false, onChange: function (e) {
                                if (values.slots[index].tat.value === appConstants_1.TAT[0].value) {
                                    validateTimeSlots(index, setFieldValue, e.target.value, values.slots[index].pickUp_min, values.slots[index].designated_hour, values.slots[index].designated_min, 0);
                                }
                                else {
                                    setFieldValue("slots[".concat(index, "].isError"), false);
                                }
                                setFieldValue("slots[".concat(index, "].pickUp_hour"), e.target.value);
                            } }, appConstants_1.Hours === null || appConstants_1.Hours === void 0 ? void 0 : appConstants_1.Hours.map(function (name, index) { return (React.createElement(material_1.MenuItem, { key: index, value: name }, name)); }))),
                    React.createElement("div", null, ":"),
                    React.createElement(material_1.FormControl, { fullWidth: true },
                        React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, value: values.slots[index].pickUp_min, defaultValue: values.pickUp_min, disabled: mode === 'view' ? true : false, onChange: function (e) {
                                if (values.slots[index].tat.value === appConstants_1.TAT[0].value) {
                                    validateTimeSlots(index, setFieldValue, values.slots[index].pickUp_hour, e.target.value, values.slots[index].designated_hour, values.slots[index].designated_min, 0);
                                }
                                else {
                                    setFieldValue("slots[".concat(index, "].isError"), false);
                                }
                                setFieldValue("slots[".concat(index, "].pickUp_min"), e.target.value);
                            } }, appConstants_1.Minutes === null || appConstants_1.Minutes === void 0 ? void 0 : appConstants_1.Minutes.map(function (name) { return (React.createElement(material_1.MenuItem, { key: name, value: name }, name)); }))))),
            React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "tat", value: values.slots[index].tat, isDisabled: mode === 'view' ? true : false, dropDownList: appConstants_1.TAT, placeholder: "TAT", onChange: function (val) {
                        if (val.value === appConstants_1.TAT[0].value) {
                            validateTimeSlots(index, setFieldValue, values.slots[index].pickUp_hour, values.slots[index].pickUp_min, values.slots[index].designated_hour, values.slots[index].designated_min, val.hours);
                        }
                        else {
                            setFieldValue("slots[".concat(index, "].isError"), false);
                        }
                        setFieldValue("slots[".concat(index, "].tat"), val);
                        setFieldTouched('tat', true);
                    } })),
            React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                React.createElement(drop_down_component_1.default, { name: "transPortMode", disableClearable: true, placeholder: "Transport Mode", label: "Transport Mode", dropDownList: appConstants_1.TransportMode &&
                        appConstants_1.TransportMode.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChange: function (val) {
                        setFieldValue("slots[".concat(index, "].transport_mode"), val);
                    }, value: values.slots[index].transport_mode, isDisabled: mode === 'view' ? true : false }),
                errors['transPortMode'] && touched['transPortMode'] ? (React.createElement("div", { className: "error" },
                    errors['transPortMode'],
                    " ")) : null),
            React.createElement(Grid_1.default, { item: true, xs: 4, lg: 3 },
                React.createElement("p", { className: "cut-off-time" }, "Designated Hitting Time"),
                React.createElement(material_1.Stack, { direction: "row", gap: 2, alignItems: "center", className: "cst-timepicker", width: '70%' },
                    React.createElement(material_1.FormControl, { fullWidth: true },
                        React.createElement(material_1.InputLabel, { id: "hour" }),
                        React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, key: values.designated_hour, labelId: "hour", id: "hour", name: "hour", value: values.slots[index].designated_hour, defaultValue: values.slots[index].designated_hour, disabled: mode === 'view' ? true : false, onChange: function (e) {
                                if (values.slots[index].tat.value === appConstants_1.TAT[0].value) {
                                    validateTimeSlots(index, setFieldValue, values.slots[index].pickUp_hour, values.slots[index].pickUp_min, e.target.value, values.slots[index].designated_min, 0);
                                }
                                else {
                                    setFieldValue("slots[".concat(index, "].isError"), false);
                                }
                                setFieldValue("slots[".concat(index, "].designated_hour"), e.target.value);
                            } }, appConstants_1.Hours === null || appConstants_1.Hours === void 0 ? void 0 : appConstants_1.Hours.map(function (name, index) { return (React.createElement(material_1.MenuItem, { key: index, value: name }, name)); }))),
                    React.createElement("div", null, ":"),
                    React.createElement(material_1.FormControl, { fullWidth: true },
                        React.createElement(material_1.Select, { MenuProps: { classes: { paper: classes.customSelect } }, value: values.slots[index].designated_min, defaultValue: values.slots[index].designated_min, disabled: mode === 'view' ? true : false, onChange: function (e) {
                                if (values.slots[index].tat.value === appConstants_1.TAT[0].value) {
                                    validateTimeSlots(index, setFieldValue, values.slots[index].pickUp_hour, values.slots[index].pickUp_min, values.slots[index].designated_hour, e.target.value, 0);
                                }
                                else {
                                    setFieldValue("slots[".concat(index, "].isError"), false);
                                }
                                setFieldValue("slots[".concat(index, "].designated_min"), e.target.value);
                            } }, appConstants_1.Minutes === null || appConstants_1.Minutes === void 0 ? void 0 : appConstants_1.Minutes.map(function (name) { return (React.createElement(material_1.MenuItem, { key: name, value: name }, name)); })))),
                React.createElement(material_1.Box, { className: "error", mt: 1 },
                    ((_a = values === null || values === void 0 ? void 0 : values.slots[index]) === null || _a === void 0 ? void 0 : _a.isError)
                        ? 'Designated time should be greater than pickup cut off time'
                        : '',
                    ' ')),
            React.createElement(Grid_1.default, { item: true, xs: 1, lg: 1 },
                React.createElement(text_filed_component_1.default, { name: "eta", placeholder: "ETA", label: "ETA", value: calculteTime(values, index), isDisabled: true }),
                errors['lane'] && touched['lane'] ? (React.createElement("div", { className: "error" },
                    errors['lane'],
                    " ")) : null),
            React.createElement(Grid_1.default, { item: true, xs: 1, lg: 1, className: "DeleteIcon" }, index != 0 && (React.createElement(button_component_1.default, { type: "button", color: "yellow-outline", variant: "outlined", onClickHandler: function () { return remove(index); }, startIcon: React.createElement(Delete_1.default, null), buttonName: '', isError: pathname.includes('ShipmentView') })))));
    };
    return (React.createElement("div", { className: "block-slot-wrapper" },
        React.createElement("div", null,
            React.createElement(formik_1.Formik, { initialValues: createShipmentFormValues, validateOnBlur: false, enableReinitialize: true, validationSchema: schema_1.schemas.SHIPMENT_ROUTE_Schema, onSubmit: formSubmit }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                        React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                            React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTOListPage }),
                            React.createElement("h2", null, mode === 'edit'
                                ? 'Edit Shipment Route'
                                : mode === 'view'
                                    ? 'View Shipment Route'
                                    : 'Add Shipment Route')),
                        console.log(values, 'values'),
                        React.createElement("div", { className: "page-control-wrapper" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateTOListPage }),
                            React.createElement(button_component_1.default, { isError: pathname.includes('ShipmentView'), buttonName: "Submit", color: "yellow", type: "submit" }))),
                    React.createElement("div", { className: "add-slotform form-filed-wrapper" },
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { name: "rlab", disableClearable: true, placeholder: "R lab", label: "R lab", dropDownList: converteLabList &&
                                        converteLabList.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChange: function (val) {
                                        setFieldTouched('rlab', true);
                                        setFieldValue('rlab', val);
                                        if (values.plab.value === val.value) {
                                            setIsLabsSame(true);
                                        }
                                        else {
                                            setIsLabsSame(false);
                                        }
                                    }, value: values.rlab, isDisabled: mode === 'view' || pathname.includes('ShipmentEdit') ? true : false }),
                                errors['rlab'] && touched['rlab'] ? (React.createElement("div", { className: "error" },
                                    errors['rlab'],
                                    " ")) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { name: "plab", disableClearable: true, placeholder: "P lab", label: "P lab", dropDownList: converteLabList &&
                                        converteLabList.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChange: function (val) {
                                        setFieldTouched('plab', true);
                                        setFieldValue('plab', val);
                                        if (values.rlab.value === val.value) {
                                            setIsLabsSame(true);
                                        }
                                        else {
                                            setIsLabsSame(false);
                                        }
                                    }, value: values.plab, isDisabled: mode === 'view' || pathname.includes('ShipmentEdit') ? true : false }),
                                isLabsSame ? (React.createElement("div", { className: "error" }, "R Lab should not match with P Lab. ")) : null,
                                errors['plab'] && touched['plab'] ? (React.createElement("div", { className: "error" },
                                    errors['plab'],
                                    " ")) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "lane", placeholder: "Lane", label: "Lane", value: values.rlab && values.plab
                                        ? "".concat(values.rlab.value, " - ").concat(values.plab.value)
                                        : '', isDisabled: true }),
                                errors['lane'] && touched['lane'] ? (React.createElement("div", { className: "error" },
                                    errors['lane'],
                                    " ")) : null))),
                    React.createElement(formik_1.FieldArray, { name: "slots" }, function (_a) {
                        var _b;
                        var push = _a.push, remove = _a.remove;
                        return (React.createElement("div", { className: "add-slotform form-filed-wrapper" },
                            React.createElement(button_component_1.default, { buttonName: "Add", isError: pathname.includes('ShipmentView'), onClickHandler: function () {
                                    return push({
                                        id: Math.random(),
                                        pickUp_hour: '10',
                                        pickUp_min: '00',
                                        designated_hour: '10',
                                        designated_min: '01',
                                        isError: false,
                                        transport_mode: {
                                            id: 2,
                                            label: 'Bus',
                                            value: 'Bus',
                                        },
                                        tat: {
                                            id: 0,
                                            label: 'Same Day',
                                            value: 0,
                                            hours: 0,
                                        },
                                    });
                                }, color: "yellow" }),
                            React.createElement("div", { className: "field-array-wrapper" }, (_b = values === null || values === void 0 ? void 0 : values.slots) === null || _b === void 0 ? void 0 : _b.map(function (p, index) {
                                return (React.createElement("div", { key: p.id }, timeSlots(values, setFieldValue, setFieldTouched, errors, touched, remove, index)));
                            }))));
                    }),
                    React.createElement(ModalComponentWithoutButton_1.default, { open: isShipmentCreated },
                        React.createElement("div", { className: "modal-header " },
                            React.createElement("h2", { className: "heading" }, "Confirmation"),
                            React.createElement("p", { className: "sub-heading" }, "Are you sure you want to submit?")),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { buttonName: "NO", color: "yellow-outline", onClickHandler: function () {
                                    setIsShipmentCreated(false);
                                } }),
                            React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", onClickHandler: function () {
                                    formSubmitHandler(values);
                                } })))));
            }))));
};
exports.default = shipmentRouteCreate;
