"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../../common/buttons/button_component");
var search_component_1 = require("../../../../../common/search/search_component");
var route_path_1 = require("../../../../../constants/route_path");
var tabs_component_1 = require("../../../../../common/tabs/tabs_component");
var tabs_data_1 = require("../../../../../constants/tabs_data");
var Pagenotfound_1 = require("../../../../../ErrorBoundary/Pagenotfound");
var drop_down_component_1 = require("../../../../../common/drop_down/drop_down_component");
var appConstants_1 = require("../../../../../constants/appConstants");
var api_service_1 = require("../../../../../api/api_service");
var react_hot_toast_1 = require("react-hot-toast");
var pop_up_1 = require("../../../../../common/modal/pop-up");
var react_redux_1 = require("react-redux");
var config_action_1 = require("../../../../../actions/config_action");
var menu_constants_1 = require("../../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../../utils/array_helpers");
var routes_list_1 = require("../../../../../routes/routes_list");
var orc_service_1 = require("../orc_service");
var ORCMainComponent = function () {
    var nav = (0, react_router_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var pathname = (0, react_router_1.useLocation)().pathname;
    var _a = React.useState(''), searchTerm = _a[0], setSearchTerm = _a[1];
    var _b = React.useState(0), activeTab = _b[0], setActiveTab = _b[1];
    var _c = React.useState(false), isFilterButton = _c[0], setIsFilterButton = _c[1];
    var _d = React.useState(false), popUpOpen = _d[0], setPopUpOpen = _d[1];
    var ORC_ManagSubRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.ORC_Management, routes_list_1.ORC_MANGAGEMENT_SUB_ROUTES);
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.ORC_Management);
    var filteredTabs = (0, array_helpers_1.disableTabs)(tabs_data_1.TABS_DATA.ORC_TABS_LIST, features);
    var _e = React.useState(appConstants_1.defaultPageLimit), PageLimit = _e[0], setPageLimit = _e[1];
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value.trim());
    };
    var filterHandler = function () {
        setIsFilterButton(!isFilterButton);
    };
    var activeTabChangeHandler = function (index) {
        setActiveTab(index);
        setSearchTerm('');
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var navigateToMyReports = function () {
        dispatch((0, config_action_1.featchCurrentActiveMenu)({
            mainModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subModule: menu_constants_1.FEATURE_CONSTANTS.reports,
            subChaildModule: '',
        }));
        nav("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var checkReportName = function () {
        if (pathname.includes(route_path_1.PATHS.orc_active_listing)) {
            return [orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE, orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE];
        }
        else if (pathname.includes(route_path_1.PATHS.orc_level1_listing)) {
            return [orc_service_1.ORC_CLIENT_STATUS_OBJ.PENDING_LEVEL_ONE];
        }
        else if (pathname.includes(route_path_1.PATHS.orc_leve2_listing)) {
            return [orc_service_1.ORC_CLIENT_STATUS_OBJ.PENDING_LEVEL_TWO];
        }
        else if (pathname.includes(route_path_1.PATHS.orc_rejected_listing)) {
            return [orc_service_1.ORC_CLIENT_STATUS_OBJ.LEVEL_ONE_REJECTED, orc_service_1.ORC_CLIENT_STATUS_OBJ.LEVEL_TWO_REJECTED];
        }
        else {
            return [orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE, orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE];
        }
    };
    var autoGenerateReportHandler = function () {
        var payload = {
            status: checkReportName()
        };
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ORC_DOWNLOAD,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                setPopUpOpen(true);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "ORC Management")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "download", color: "yellow-outline", endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler, isError: !features[menu_constants_1.FEATURE_CONSTANTS.DOWNLOAD_REPORT] }),
                React.createElement(button_component_1.default, { isError: !features[menu_constants_1.FEATURE_CONSTANTS.CREATE_CLIENT], buttonName: "Create ORC", color: "yellow", onClickHandler: function () { return nav("/".concat(route_path_1.default.PATHS.create_orc)); } }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" },
                React.createElement(search_component_1.default, { placeholder: "Search warehousecode/Invoicecode", searchValue: searchTerm, className: 'fe-search-module', searchHandler: searchingHandler, activetab: activeTab })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: filterHandler }))),
        React.createElement("div", { className: "scrollable-content fe-managment-screen" },
            React.createElement(tabs_component_1.default, { tabList: filteredTabs, tabChangeHandler: activeTabChangeHandler }),
            React.createElement(react_router_1.Routes, null,
                ORC_ManagSubRoutes &&
                    ORC_ManagSubRoutes.map(function (features) {
                        return (React.createElement(react_router_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(features.component, { searchTerm: searchTerm, isFilterButton: isFilterButton, PageLimit: PageLimit }) }));
                    }),
                React.createElement(react_router_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) }))),
        React.createElement(pop_up_1.default, { open: popUpOpen },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setPopUpOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = ORCMainComponent;
