"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
require("./tabs.scss");
var TabsComponent = function (_a) {
    var tabList = _a.tabList, tabChangeHandler = _a.tabChangeHandler;
    var TAB_ACTIVE_CLASSNAME = 'tab-item active-tab';
    var clickHandler = function (e, linkDisabled, index) {
        if (linkDisabled) {
            e.preventDefault();
        }
        else if (tabChangeHandler) {
            tabChangeHandler(index);
        }
    };
    return (React.createElement("div", { className: "tabs-wrapper" },
        React.createElement("nav", { className: "tab-links" }, tabList.map(function (list, index) {
            return (React.createElement(react_router_dom_1.NavLink, { end: index === 0, key: list.path, to: "".concat(list.path), className: function (_a) {
                    var isActive = _a.isActive;
                    if (isActive) {
                        return TAB_ACTIVE_CLASSNAME;
                    }
                    else if ((isActive && (list === null || list === void 0 ? void 0 : list.islinkDisabled)) ||
                        list.islinkDisabled) {
                        return 'disable-tab tab-item';
                    }
                    else {
                        return 'tab-item';
                    }
                }, onClick: function (e) {
                    clickHandler(e, list.islinkDisabled, index);
                } }, list.lableName));
        }))));
};
exports.default = TabsComponent;
