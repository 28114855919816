"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appConfig = void 0;
var AppConfig = (function () {
    function AppConfig() {
        this.logLevel = 'info';
        this.lang = window.navigator.language.split('-')[0];
        this.timezone = '330';
        this.source = process.env.SOURCEID;
        this.deviceType = 'WEB';
    }
    AppConfig.prototype.updateToken = function (token) {
        this.AuthToken = token;
    };
    return AppConfig;
}());
exports.appConfig = new AppConfig();
