"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var button_component_1 = require("../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../constants/appConstants");
var table_data_1 = require("../../../constants/table_data");
var reports_helper_1 = require("../reports/reports_helper");
var MyReportsHelpers_1 = require("./MyReportsHelpers");
var MyReportsHomePage = function () {
    var _a = React.useState(undefined), myReportList = _a[0], setMyReportList = _a[1];
    var _b = React.useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = React.useState(null), selectedReportTypes = _c[0], setSelectedReportTypes = _c[1];
    var _d = React.useState(new Date().toString()), filterFromDate = _d[0], setFromDateFilter = _d[1];
    var _e = React.useState(new Date().toString()), filterToDate = _e[0], setToDateFilter = _e[1];
    var callbackUpdateSelectedStates = function (value) {
        setSelectedReportTypes(value);
    };
    var _f = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), reportStatusList = _f.reportStatusList, reportTypeList = _f.reportTypeList;
    var _g = React.useState(false), isDateRangeUpdated = _g[0], setIsDateRangeUpdated = _g[1];
    var _h = React.useState(1), pageCount = _h[0], setpageCount = _h[1];
    var _j = React.useState(0), totalPageCount = _j[0], setTotalPageCount = _j[1];
    var _k = React.useState(appConstants_1.defaultPageLimit), PageLimit = _k[0], setPageLimit = _k[1];
    var _l = React.useState(0), totalCount = _l[0], setTotalCount = _l[1];
    var _m = React.useState(false), refreshPage = _m[0], setRefreshPage = _m[1];
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var refetch = (0, react_query_1.useQuery)(['myreports-list', pageCount], function () {
        var payload = {};
        var currentPage = pageCount < 1 ? 1 : pageCount;
        if (selectedReportTypes) {
            payload['report_type'] = selectedReportTypes.label;
        }
        if (filterToDate && filterFromDate) {
            payload['date_range'] = {
                start_date: moment(filterFromDate).format('YYYY-MM-DD'),
                end_date: moment(filterToDate).format('YYYY-MM-DD'),
            };
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, reports_helper_1.getMyreportList)(payload)
            .then(function (response) {
            var convertedRes = (0, MyReportsHelpers_1.coonvertMyReportListFromRes)(response.result, reportStatusList);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setErrorMessage('');
            setTotalPageCount(count);
            setTotalCount(response.count);
            setMyReportList(convertedRes);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setMyReportList([]);
        });
    }).refetch;
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "My Reports"))),
        React.createElement("div", { className: "filter-menu-wrapper right-align-content" },
            React.createElement("div", { className: "filter-options " },
                React.createElement(drop_down_component_1.default, { label: "Report Type", placeholder: "Report Type", dropDownList: reportTypeList, onChange: callbackUpdateSelectedStates, value: selectedReportTypes, isDisabled: false, disableClearable: true }),
                React.createElement("div", { className: "cursor-pointer" },
                    React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true })),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler })),
            React.createElement(button_component_1.default, { buttonName: "Refresh", color: "yellow", endIcon: "icon-refresh", onClickHandler: function () {
                    refetch();
                    setRefreshPage(!refreshPage);
                } })),
        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.MYREPORTS_LIST, rows: myReportList, pagination: false, errorMessage: errorMessage, enableCellTextSelection: true, className: 'myreports-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: myReportList === null || myReportList === void 0 ? void 0 : myReportList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }))));
};
exports.default = MyReportsHomePage;
