"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Avatar_1 = require("@mui/material/Avatar");
var AvatarGroup_1 = require("@mui/material/AvatarGroup");
var colors_1 = require("@mui/material/colors");
var React = require("react");
var AuditUsers = function (_a) {
    var users = _a.users;
    console.log('audit users:', users);
    var colorArr = [colors_1.deepOrange[500], colors_1.deepPurple[500], colors_1.deepOrange[500]];
    return (React.createElement("div", { className: "chips-wrapper avatr-group-wrapper" },
        React.createElement(AvatarGroup_1.default, { max: 4 }, users &&
            users.map(function (user, index) { return (React.createElement(Avatar_1.default, { sx: { bgcolor: colorArr[index] } }, user)); }))));
};
exports.default = AuditUsers;
