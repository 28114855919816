"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var button_component_1 = require("../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var Create_User_Logistics_1 = require("./Create_User_Logistics");
var user_logistics_api_1 = require("./user_logistics_api");
var UserLogisticsList = function (_a) {
    var _b = React.useState(''), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = React.useState(false), openPop = _c[0], setOpenPop = _c[1];
    var _d = React.useState(appConstants_1.defaultPageLimit), PageLimit = _d[0], setPageLimit = _d[1];
    var searchFe = function (event) {
        setSearchTerm(event.target.value.trim());
    };
    var popUpOpen = function () {
        setOpenPop(true);
    };
    var _e = React.useState(undefined), dataList = _e[0], setDataList = _e[1];
    var _f = React.useState(1), pageCount = _f[0], setpageCount = _f[1];
    var _g = React.useState(false), pageReload = _g[0], setPageReload = _g[1];
    var _h = React.useState(0), totalPageCount = _h[0], setTotalPageCount = _h[1];
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _j = React.useState(0), totalCount = _j[0], setTotalCount = _j[1];
    var _k = React.useState(false), filterButton = _k[0], setFilterbutton = _k[1];
    var _l = React.useState(''), errorMessage = _l[0], setErrorMessage = _l[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Admin_User_Management);
    var refetch = (0, react_query_1.useQuery)(['active-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (searchTerm.trim().length >= 3) {
            payload.search_string = searchTerm.trim();
        }
        payload.page = currentPage;
        payload.size = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, user_logistics_api_1.fetchUserLogisticsList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            var convertedData = (0, array_helpers_1.convertUserLogisticsListFromRes)(response === null || response === void 0 ? void 0 : response.result);
            console.log('convertedData', convertedData);
            setDataList(convertedData);
            UpdateListData.setIsListReloaded(false);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            console.log(err.message);
            react_hot_toast_1.default.error(err.message);
            setDataList([]);
        });
    }).refetch;
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [filterButton, pageReload]);
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var FilterHandler = function () {
        setFilterbutton(!filterButton);
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Admin User")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "ADD User", color: "yellow", onClickHandler: popUpOpen, isError: !features[menu_constants_1.FEATURE_CONSTANTS.adminUserCreate] })),
                React.createElement(Create_User_Logistics_1.default, { openPopToAdd: openPop, setPopToAdd: setOpenPop, setPageload: setPageReload }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: "Search by Name, EMail, Mobile", searchValue: searchTerm, searchHandler: searchFe })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "unclickablerow" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.USER_LOGISTICS_LIST, rows: dataList && dataList, errorMessage: errorMessage, pagination: false, className: 'admin-user-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }))));
};
exports.default = UserLogisticsList;
