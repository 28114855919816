"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../api/api_service");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var array_helpers_1 = require("../../../utils/array_helpers");
var fe_services_1 = require("../fe_services");
var AllFEList = function (_a) {
    var searchValue = _a.searchValue, selectedCities = _a.selectedCities, CurrentPagelimit = _a.CurrentPagelimit, isFilterButton = _a.isFilterButton;
    var _b = React.useState(undefined), feActiveList = _b[0], setFeActiveList = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var onRowClickedHandler = function (e) {
        navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.feDetails, "/").concat(e.data.id, "/").concat(route_path_1.default.PATHS.trackFe));
    };
    console.log('');
    var fetchActiveFE = function (endPoint, payLoad, domain) {
        (0, fe_services_1.fetchFEList)(endPoint, payLoad, domain)
            .then(function (response) {
            setErrorMessage("");
            var pageSize = CurrentPagelimit
                ? parseInt(CurrentPagelimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setFeActiveList(response.result);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            console.log('error in the error', err);
            setErrorMessage(err);
            setFeActiveList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['active-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = (0, array_helpers_1.createPayloadForFEList)(appConstants_1.FE_USER_TYPES.active, searchValue, selectedCities);
        payload.page = currentPage;
        payload.size = CurrentPagelimit
            ? parseInt(CurrentPagelimit.value.toString())
            : 50;
        fetchActiveFE(api_service_1.api_service.api_urls.FE_LIST, payload, process.env.VENDOR_BASE_URL);
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [isFilterButton]);
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    return (React.createElement("div", { className: "custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.FE_COLUMNS, rows: feActiveList && feActiveList, onRowClicked: onRowClickedHandler, errorMessage: errorMessage, pagination: false }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: feActiveList === null || feActiveList === void 0 ? void 0 : feActiveList.length, currentPageLimits: CurrentPagelimit === null || CurrentPagelimit === void 0 ? void 0 : CurrentPagelimit.value })));
};
exports.default = AllFEList;
