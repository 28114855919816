"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Box_1 = require("@mui/material/Box");
var moment = require("moment");
var React = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var multi_selector_1 = require("../../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var text_filed_with_fromto_Daterange_1 = require("../../../../common/text_filed/text_filed_with_fromto_Daterange");
var route_path_1 = require("../../../../constants/route_path");
var array_helpers_1 = require("../../../../utils/array_helpers");
var shipment_constants_1 = require("../../../shipment_reports/constants/shipment_constants");
var Intracity_graph_view_1 = require("./Intracity_graph_view");
var IntracityTatFilters = function () {
    var nav = (0, react_router_1.useNavigate)();
    var pathname = (0, react_router_1.useLocation)().pathname;
    var _a = React.useState(), updatedTime = _a[0], setUpdatedTIme = _a[1];
    console.log(pathname, updatedTime, 'pathname');
    var labList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).labList;
    var _b = React.useState(pathname.includes(route_path_1.PATHS.intracity_tat_report) ? shipment_constants_1.EVENTS_LIST[0] : null), selectedEvent = _b[0], setSelectedEvent = _b[1];
    var _c = React.useState(new Date().toString()), startDate = _c[0], setStartDate = _c[1];
    var _d = React.useState(false), isDateRangeUpdated = _d[0], setIsDateRangeUpdated = _d[1];
    var _e = React.useState(new Date().toString()), endDate = _e[0], setEndDate = _e[1];
    var _f = React.useState([]), labCode = _f[0], setLaboCode = _f[1];
    var _g = React.useState(false), filterButton = _g[0], setFilterButton = _g[1];
    var eventHandlere = function (selectedValues) {
        setSelectedEvent(selectedValues);
    };
    var labCodeHandlere = function (selectedValues) {
        setLaboCode(selectedValues);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        console.log(dateRangeArray, 'dateRangeArray');
        if (dateRangeArray) {
            setStartDate(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setEndDate(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    React.useEffect(function () {
        setStartDate(new Date().toString());
        setEndDate(new Date().toString());
        setLaboCode([]);
    }, [pathname]);
    var navigateToGraphView = function () {
        var PayloadData = {
            startDate: startDate,
            endDate: endDate,
            labCode: labCode,
            selectedEvent: selectedEvent === null || selectedEvent === void 0 ? void 0 : selectedEvent.value,
            labList: labList,
        };
        nav("/".concat(route_path_1.default.PATHS.intracity_tat_filters, "/").concat(route_path_1.default.PATHS.intracity_tat_graph_list), { state: PayloadData });
    };
    var FilterHandler = function () {
        setFilterButton(!filterButton);
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Intracity - TAT"))),
        React.createElement("div", { className: "orderlist-main-wrapper" },
            React.createElement("div", { className: "filter-menu-wrapper border-bottom" },
                React.createElement("div", { className: "filter-options auto-route" },
                    React.createElement(Box_1.default, { mr: 1 },
                        React.createElement(text_filed_with_fromto_Daterange_1.default, { presentDate: true, onChangeHandler: callBackOnDateRangeSelect })),
                    React.createElement("div", { className: "tat_wrapper" },
                        React.createElement(drop_down_component_1.default, { name: "events", placeholder: "Event", dropDownList: shipment_constants_1.EVENTS_LIST, onChange: eventHandlere, value: selectedEvent })),
                    React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "Lab Code", dropDownList: labList &&
                            (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                                return a.label.localeCompare(b.label);
                            }), onChangeHnadlre: labCodeHandlere, value: labCode })),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }),
                    React.createElement(button_component_1.default, { buttonName: "View FE Intracity TAT", color: "yellow", onClickHandler: navigateToGraphView }),
                    React.createElement(button_component_1.default, { buttonName: "Refresh", color: "yellow", endIcon: "icon-refresh", onClickHandler: function () { return setFilterButton(!filterButton); } }))),
            React.createElement("div", { className: "overview-content grid-card-height" },
                React.createElement(material_1.Grid, { container: true, spacing: 3 },
                    React.createElement(material_1.Grid, { item: true, xs: 12, lg: 12 },
                        React.createElement(material_1.Card, { className: "dash-board-card" },
                            React.createElement(material_1.CardHeader, { title: 'TAT', subheader: "Last updated : ".concat(updatedTime) }),
                            React.createElement(material_1.CardContent, null,
                                React.createElement(Intracity_graph_view_1.default, { isDateRangeUpdated: isDateRangeUpdated, startDate: startDate, endDate: endDate, labCode: labCode, selectedEvent: selectedEvent === null || selectedEvent === void 0 ? void 0 : selectedEvent.value, labList: labList, setUpdatedTIme: setUpdatedTIme, filterButton: filterButton })))))))));
};
exports.default = IntracityTatFilters;
