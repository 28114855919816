"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InputAdornment_1 = require("@mui/material/InputAdornment");
var TextField_1 = require("@mui/material/TextField");
var React = require("react");
var lodash_1 = require("./../../utils/lodash");
require("./search.scss");
var SearchComponent = function (_a) {
    var searchHandler = _a.searchHandler, searchValue = _a.searchValue, placeholder = _a.placeholder, onChange = _a.onChange, isDisabled = _a.isDisabled, keypressHandler = _a.keypressHandler, activetab = _a.activetab, className = _a.className;
    var handleSearchValueChange = lodash_1.default.debounce(function (event) {
        searchHandler(event);
    }, 500);
    console.log('search value inside search component:', searchValue, activetab);
    return (React.createElement("div", { className: "search-wrapper" },
        React.createElement(TextField_1.default, { disabled: isDisabled, className: className, key: activetab, onChange: handleSearchValueChange, placeholder: placeholder, onKeyPress: keypressHandler, InputProps: {
                startAdornment: (React.createElement(InputAdornment_1.default, { position: "start" },
                    React.createElement("span", { className: "icon-search" }))),
            } })));
};
exports.default = SearchComponent;
