"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Images = {
    Logo: require('../../assets/images/personImg.jpg'),
    PageNotFound: require('../../assets/images/404.svg'),
    UnknownErorr: require('../../assets/images/unknown.svg'),
    noData: require('../../assets/images/nodata.jpg'),
    doc: require('../../assets/images/doc.jpg'),
    user_placeholder: require('../../assets/images/userfront.jpg'),
    pdfImage: require('../../assets/images/pdf-thumbnail.jpg'),
    csvImage: require('../../assets/images/csv-thumbnail.jpg'),
    labSample: require('../../assets/images/lab.jpg'),
    donutGraph: require('../../assets/images/donut-placeholder.jpg'),
    captcha: require('../../assets/images/captchaimg.gif'),
    etrfdoc: require('../../assets/images/etrf.jpg'),
    map: require('../../assets/images/pick-location.jpg'),
    yellow_Marker: require('../../assets/images/yellow_marker2.jpg'),
    orange_Marker: require('../../assets/images/orange_marker.svg'),
    block_marker: require('../../assets/images/block_marker.svg'),
    red_marker: require('../../assets/images/red_marker.svg'),
    bule_marker: require('../../assets/images/blue_marker.svg'),
    greenSample: require('../../assets/images/green_thumb.jpg'),
    yellowSample: require('../../assets/images/yellow_thumb.jpg'),
    blueSample: require('../../assets/images/blue_thumb.jpg'),
    redSample: require('../../assets/images/red_thumb.jpg'),
    greySample: require('../../assets/images/grey_thumb.jpg'),
    whiteSample: require('../../assets/images/white_thumb.jpg'),
    purpleSample: require('../../assets/images/tubepurple.jpg'),
    container: require('../../assets/images/container.jpg'),
    others: require('../../assets/images/Others.jpg'),
    feIcon: require('../../assets/images/fe.svg'),
    ccIcon: require('../../assets/images/cc.svg'),
    labIcon: require('../../assets/images/lab.svg'),
    hubIcon: require('../../assets/images/hub.svg'),
    unassignedIcon: require('../../assets/images/unassigned.svg'),
    map_pointer_icon: require('../../assets/images/map_pointer_icon.svg'),
    red_scooter_icon: require('../../assets/images/redscooter.svg'),
    blue_map_icon: require('../../assets/images/bluemap.svg'),
    yellow_map_icon: require('../../assets/images/yellowmap.svg'),
    rider_icon: require('../../assets/images/rider.svg'),
};
exports.default = Images;
