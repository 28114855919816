"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var api_service_1 = require("../../../../api/api_service");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var checkbox_component_1 = require("../../../../common/checkbox/checkbox_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var file_upload_component_1 = require("../../../../common/file_upload/file_upload_component");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var vendor_creation_tab_constant_1 = require("../../../../constants/vendor_creation_tab_constant");
var generic_api_calls_1 = require("../../../generic_api_calls");
var lodash_1 = require("./../../../../utils/lodash");
var Vendor_ContactDetails = function (_a) {
    var initialValues = _a.initialValues, saveFormData = _a.saveFormData, updateValue = _a.updateValue, USER_ContactDetailsInitialState = _a.USER_ContactDetailsInitialState, backButtonhandler = _a.backButtonhandler, onBoardingStatus = _a.onBoardingStatus, vedore_personal_Details = _a.vedore_personal_Details;
    var _b = React.useState(false), isCheckboxSelected = _b[0], setIsCheckboxSelected = _b[1];
    var stateList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.stateList; });
    var id = (0, react_router_1.useParams)().id;
    var nav = (0, react_router_1.useNavigate)();
    var _c = React.useState([]), tempCityList = _c[0], setTempCityList = _c[1];
    var _d = React.useState([]), permCityList = _d[0], setPermCityList = _d[1];
    var countryList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.countryList; });
    var pincodeValidation = function (e, values, errors, setErrors) {
        var payload = {
            state_id: values.state.id,
            pincode: parseInt(e.target.value),
        };
        (0, generic_api_calls_1.validatePincode)(payload).then(function (res) {
            if (res.data.message != 'Success') {
                setErrors(__assign(__assign({}, errors), { pincode: res.data.message }));
            }
        });
    };
    var permanentPincodeValidation = function (e, values, errors, setErrors) {
        var payload = {
            state_id: values.permenentState.id,
            pincode: parseInt(e.target.value),
        };
        (0, generic_api_calls_1.validatePincode)(payload).then(function (res) {
            if (res.data.message != 'Success') {
                setErrors(__assign(__assign({}, errors), { permenentPincode: res.data.message }));
            }
        });
    };
    console.log(permanentPincodeValidation, pincodeValidation);
    var uploadFile = function (val) {
        var payload = new FormData();
        payload.append('cc_id', 'vendor');
        payload.append('file', val);
        payload.append('file_type', 'Other');
        return (0, generic_api_calls_1.fileUpload)(payload);
    };
    var checkboxChangeHandler = function (_a) {
        var values = _a.values, setFieldValue = _a.setFieldValue, setValues = _a.setValues, setTouched = _a.setTouched, e = _a.e;
        var isCheckBoxChecked = e.target.checked;
        setIsCheckboxSelected(isCheckBoxChecked);
        var valuesObj = lodash_1.default.extend({}, values, {
            permenentAddresslane1: isCheckBoxChecked ? values.addresslane1 : '',
            permenentAddresslane2: isCheckBoxChecked ? values.addresslane2 : '',
            permenentLandmark: isCheckBoxChecked ? values.landmark : '',
            permenentState: isCheckBoxChecked ? values.state : '',
            permenentCity: isCheckBoxChecked ? values.city : '',
            permenentCountry: isCheckBoxChecked ? values.country : '',
            permenentPincode: isCheckBoxChecked ? values.pincode : '',
            permenentProofOfAddress: isCheckBoxChecked ? values.proofOfAddress : '',
        });
        console.log(valuesObj, 'valuesObj');
        setValues(valuesObj);
    };
    React.useEffect(function () {
        console.log('inside useeffect of fe contact details:', initialValues);
        if (JSON.stringify(initialValues) !=
            JSON.stringify(USER_ContactDetailsInitialState)) {
            if (initialValues.addresslane1 == initialValues.permenentAddresslane1 &&
                initialValues.addresslane2 == initialValues.permenentAddresslane2 &&
                initialValues.landmark == initialValues.landmark &&
                JSON.stringify(initialValues.state) ==
                    JSON.stringify(initialValues.permenentState) &&
                JSON.stringify(initialValues.city) ==
                    JSON.stringify(initialValues.permenentCity) &&
                JSON.stringify(initialValues.country) ==
                    JSON.stringify(initialValues.permenentCountry) &&
                initialValues.pincode == initialValues.permenentPincode &&
                initialValues.proofOfAddress == initialValues.permenentProofOfAddress) {
                setIsCheckboxSelected(true);
            }
        }
        if (initialValues.state || initialValues.permenentState) {
            var statedIDS = [];
            if (initialValues.state) {
                statedIDS.push(initialValues.state.id);
            }
            if (initialValues.permenentState) {
                statedIDS.push(initialValues.permenentState.id);
            }
            (0, generic_api_calls_1.fetchCitiesUnderStates)(statedIDS).then(function (res) {
                var cityFilteredByState = res.filter(function (city) { return initialValues.state.id === city.state_id; });
                var cityFilteredByPermanentState = res.filter(function (city) { return initialValues.permenentState.id === city.state_id; });
                setTempCityList(cityFilteredByState);
                setPermCityList(cityFilteredByPermanentState);
            });
        }
    }, [initialValues]);
    var formSubmitHnadler = function (values, setFieldError, setSubmitting) { return __awaiter(void 0, void 0, void 0, function () {
        var pincodevalidation, permanentpincodevalidation, payload, personalDetailsObj, vendor_contact_Details, vendor_presentAddressObj, vendor_permanentAddressObj, validationPayload, validationPayload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pincodevalidation = true;
                    permanentpincodevalidation = true;
                    payload = {};
                    personalDetailsObj = {};
                    personalDetailsObj['vendor_logo'] = vedore_personal_Details.uploadPhoto;
                    personalDetailsObj['contact_name'] = vedore_personal_Details.contactperson;
                    personalDetailsObj['name'] = vedore_personal_Details.vendorName;
                    personalDetailsObj['entity_type'] =
                        vedore_personal_Details.entityType != null
                            ? vedore_personal_Details.entityType.id
                            : '';
                    personalDetailsObj['contact_person_designation'] =
                        vedore_personal_Details.contactperson;
                    personalDetailsObj['contact_mobile'] = vedore_personal_Details.mobilenumber;
                    personalDetailsObj['contact_email'] = vedore_personal_Details.email;
                    if (vedore_personal_Details.alternateNumber) {
                        personalDetailsObj['alternate_mobile'] =
                            vedore_personal_Details.alternateNumber;
                    }
                    if (vedore_personal_Details.alternateNumber) {
                        personalDetailsObj['contact_website'] = vedore_personal_Details.website;
                    }
                    payload['personal_details'] = personalDetailsObj;
                    vendor_contact_Details = [];
                    vendor_presentAddressObj = {
                        type: 'Office Address',
                    };
                    vendor_permanentAddressObj = {
                        type: 'Registered Address',
                    };
                    vendor_presentAddressObj['lane1'] = values.addresslane1;
                    vendor_presentAddressObj['lane2'] = values.addresslane2;
                    vendor_presentAddressObj['landmark'] = values.landmark;
                    vendor_presentAddressObj['state_id'] =
                        values.state !== null ? values.state.id : '';
                    vendor_presentAddressObj['city_id'] =
                        values.city !== null ? values.city.id : '';
                    vendor_presentAddressObj['country_id'] =
                        values.country !== null ? values.country.id : '';
                    if (!(values.pincode && values.state)) return [3, 2];
                    validationPayload = {
                        state_id: values.state.id,
                        pincode: parseInt(values.pincode),
                    };
                    return [4, (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                            if (res.data.statusCode === 200 && res.data.message != 'Success') {
                                setFieldError('pincode', res.data.message);
                                setSubmitting(false);
                                return false;
                            }
                            return true;
                        })];
                case 1:
                    pincodevalidation = _a.sent();
                    _a.label = 2;
                case 2:
                    vendor_presentAddressObj['pincode'] = parseInt(values.pincode);
                    if (!(typeof values.proofOfAddress === 'object')) return [3, 4];
                    return [4, uploadFile(values.proofOfAddress).then(function (res) {
                            if (res.data.statusCode === 200) {
                                vendor_presentAddressObj['proof_of_doc'] = res.data.data.name;
                            }
                            else {
                                vendor_presentAddressObj['proof_of_doc'] = values.proofOfAddress;
                            }
                        })];
                case 3:
                    _a.sent();
                    return [3, 5];
                case 4:
                    vendor_presentAddressObj['proof_of_doc'] = values.proofOfAddress;
                    _a.label = 5;
                case 5:
                    vendor_contact_Details.push(vendor_presentAddressObj);
                    vendor_permanentAddressObj['lane1'] = values.permenentAddresslane1;
                    vendor_permanentAddressObj['lane2'] = values.permenentAddresslane2;
                    vendor_permanentAddressObj['landmark'] = values.permenentLandmark;
                    vendor_permanentAddressObj['state_id'] =
                        values.permenentState !== null ? values.permenentState.id : '';
                    vendor_permanentAddressObj['city_id'] =
                        values.permenentCity !== null ? values.permenentCity.id : '';
                    vendor_permanentAddressObj['country_id'] =
                        values.permenentCountry !== null ? values.permenentCountry.id : '';
                    if (!(values.permenentPincode && values.permenentState)) return [3, 7];
                    validationPayload = {
                        state_id: values.permenentState.id,
                        pincode: parseInt(values.permenentPincode),
                    };
                    return [4, (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                            if (res.data.statusCode === 200 && res.data.message != 'Success') {
                                setFieldError('permenentPincode', res.data.message);
                                setSubmitting(false);
                                return false;
                            }
                            return true;
                        })];
                case 6:
                    permanentpincodevalidation = _a.sent();
                    _a.label = 7;
                case 7:
                    if (!permanentpincodevalidation || !pincodevalidation) {
                        return [2];
                    }
                    vendor_permanentAddressObj['pincode'] = parseInt(values.permenentPincode);
                    if (!(typeof values.permenentProofOfAddress === 'object')) return [3, 9];
                    return [4, uploadFile(values.permenentProofOfAddress).then(function (res) {
                            if (res.data.statusCode === 200) {
                                vendor_permanentAddressObj['proof_of_doc'] = res.data.data.name;
                            }
                            else {
                                vendor_permanentAddressObj['proof_of_doc'] =
                                    values.permenentProofOfAddress;
                            }
                        })];
                case 8:
                    _a.sent();
                    return [3, 10];
                case 9:
                    vendor_permanentAddressObj['proof_of_doc'] =
                        values.permenentProofOfAddress;
                    _a.label = 10;
                case 10:
                    vendor_contact_Details.push(vendor_permanentAddressObj);
                    payload['contact_details'] = vendor_contact_Details;
                    payload['is_final_submit'] = false;
                    payload['axapta_code'] = id.toString();
                    api_service_1.api_service
                        .post({
                        endPoint: api_urls_1.api_urls.VENDOR_CREATION,
                        payLoad: payload,
                        domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
                    })
                        .then(function (res) {
                        if (res.data.statusCode === 200) {
                            react_hot_toast_1.default.success(res.data.message);
                            saveFormData(values);
                            if (location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor))) {
                                nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.editVendor, "/").concat(id, "/").concat(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTRACTDETAILS));
                                updateValue(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTRACTDETAILS);
                            }
                            else {
                                nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.createVendor, "/").concat(id, "/").concat(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTRACTDETAILS));
                                updateValue(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.CONTRACTDETAILS);
                            }
                        }
                        else {
                            react_hot_toast_1.default.error(res.data.message);
                        }
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.data.errors);
                    });
                    return [2];
            }
        });
    }); };
    return (React.createElement("div", { className: "form-filed-wrapper" },
        React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", null, "Vendor contact information"),
            React.createElement("p", { className: "sub-heaidng" }, "Please collect all the required fields from Vendor")),
        React.createElement(formik_1.Formik, { initialValues: __assign({}, initialValues), validateOnBlur: false, enableReinitialize: true, validationSchema: schema_1.schemas.VENDOR_ContactDetailsSchema, onSubmit: function (values, _a) {
                var setFieldError = _a.setFieldError, setSubmitting = _a.setSubmitting;
                formSubmitHnadler(values, setFieldError, setSubmitting);
            } }, function (_a) {
            var errors = _a.errors, values = _a.values, setFieldValue = _a.setFieldValue, setErrors = _a.setErrors, setFieldTouched = _a.setFieldTouched, setValues = _a.setValues, setTouched = _a.setTouched;
            console.log('Errors:', errors);
            return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 12 },
                        React.createElement("h2", { className: "label-heading heading" }, "Office Address")),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "addresslane1" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", name: "addresslane1", placeholder: 'Address lane 1', label: "Address lane 1", required: true, minLength: 2, maxLength: 150, defaultVal: values === null || values === void 0 ? void 0 : values.addresslane1, value: values === null || values === void 0 ? void 0 : values.addresslane1, onChange: function (data) {
                                    setFieldTouched('addresslane1', true);
                                    setFieldValue('addresslane1', data);
                                    setIsCheckboxSelected(false);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "addresslane1" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "addresslane2" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", name: "addresslane2", placeholder: 'Address lane 2', label: "Address lane 2", required: false, defaultVal: values === null || values === void 0 ? void 0 : values.addresslane2, value: values === null || values === void 0 ? void 0 : values.addresslane2, onChange: function (e) {
                                    setFieldValue('addresslane2', e);
                                    setFieldTouched('addresslane2', true);
                                    setIsCheckboxSelected(false);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "addresslane2" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "landmark" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: 'text-caps', name: "landmark", placeholder: 'Landmark', label: "Landmark", minLength: 2, maxLength: 150, required: true, defaultVal: values === null || values === void 0 ? void 0 : values.landmark, value: values === null || values === void 0 ? void 0 : values.landmark, onChange: function (e) {
                                    setFieldTouched('landmark', true);
                                    setFieldValue('landmark', e);
                                    setIsCheckboxSelected(false);
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "landmark" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "state" }, function () {
                            return (React.createElement(drop_down_component_1.default, { name: "state", placeholder: "Select State", label: "Select State", dropDownList: stateList, onChange: function (val) {
                                    setFieldValue('city', '');
                                    setFieldValue('pincode', '');
                                    setFieldTouched('state', true);
                                    setFieldValue('state', val);
                                    setIsCheckboxSelected(false);
                                    (0, generic_api_calls_1.fetchCitiesUnderStates)([val.id]).then(function (res) {
                                        var cityFilteredByState = lodash_1.default.sortBy(res, 'short_code');
                                        setTempCityList(cityFilteredByState);
                                    });
                                }, value: values === null || values === void 0 ? void 0 : values.state }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "state" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "city" }, function () {
                            return (React.createElement(drop_down_component_1.default, { name: "city", placeholder: "Select City", label: "Select City", required: true, dropDownList: __spreadArray([], tempCityList, true), onChange: function (val) {
                                    setFieldTouched('city', true);
                                    setFieldValue('city', val);
                                    setIsCheckboxSelected(false);
                                }, value: values === null || values === void 0 ? void 0 : values.city, key: tempCityList.toString() }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "city" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "country" }, function () {
                            return (React.createElement(drop_down_component_1.default, { name: "country", placeholder: "Select Country", label: "Select Country", required: true, dropDownList: countryList, onChange: function (val) {
                                    setFieldTouched('country', true);
                                    setFieldValue('country', val);
                                    setIsCheckboxSelected(false);
                                }, value: values === null || values === void 0 ? void 0 : values.country }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "country" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "pincode" }, function () {
                            return (React.createElement(text_filed_component_1.default, { name: "pincode", placeholder: 'Pincode', label: "Pincode", required: true, defaultVal: values === null || values === void 0 ? void 0 : values.pincode, value: values === null || values === void 0 ? void 0 : values.pincode, onKeyPress: function (e) {
                                    if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                        e.target.value.length <= 5) {
                                    }
                                    else {
                                        e.preventDefault();
                                    }
                                }, onChange: function (e) {
                                    setFieldTouched('pincode', true);
                                    setFieldValue('pincode', e);
                                    setIsCheckboxSelected(false);
                                    if (e.length === 6 && values.state != null) {
                                        var validationPayload = {
                                            state_id: values.state.id,
                                            pincode: parseInt(e),
                                        };
                                        (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                                            if (res.data.statusCode === 200 &&
                                                res.data.message != 'Success') {
                                                setErrors(__assign(__assign({}, errors), { pincode: res.data.message }));
                                            }
                                        });
                                    }
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "pincode" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 9 },
                        React.createElement(formik_1.Field, { name: "proofOfAddress" }, function () {
                            return (React.createElement(file_upload_component_1.default, { buttonName: "Attach proof of Address", name: "proofOfAddress", message: "Note:Only png, jpg, jpeg, pdf formats are allowed upto 2MB", onChange: function (e) {
                                    setFieldValue('proofOfAddress', e);
                                    setFieldTouched('proofOfAddress', true);
                                    setIsCheckboxSelected(false);
                                }, key: '', value: values === null || values === void 0 ? void 0 : values.proofOfAddress, acceptedFiletypes: [
                                    'image/jpeg',
                                    'image/png',
                                    'application/pdf',
                                ], maxSize: {
                                    image: 2,
                                    pdf: 2,
                                    csv: 2,
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "proofOfAddress" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 8, lg: 9 },
                        React.createElement("h2", { className: "label-heading heading" }, "Registered Address"),
                        React.createElement(checkbox_component_1.default, { checked: isCheckboxSelected, label: "Same as Office Address", handleChange: function (e) {
                                checkboxChangeHandler({
                                    values: values,
                                    setFieldValue: setFieldValue,
                                    setValues: setValues,
                                    setTouched: setTouched,
                                    e: e,
                                });
                            } })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "permenentAddresslane1" }, function () {
                            return (React.createElement(text_filed_component_1.default, { name: "permenentAddresslane1", className: 'text-caps', placeholder: 'Address lane 1', label: "Address lane 1", required: true, minLength: 2, maxLength: 150, defaultVal: values === null || values === void 0 ? void 0 : values.permenentAddresslane1, isDisabled: isCheckboxSelected, onChange: function (e) {
                                    setFieldValue('permenentAddresslane1', e);
                                    setFieldTouched('permenentAddresslane1', true);
                                }, value: values === null || values === void 0 ? void 0 : values.permenentAddresslane1 }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "permenentAddresslane1" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "permenentAddresslane2" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", name: "permenentAddresslane2", placeholder: 'Address lane 2', label: "Address lane 2", required: false, isDisabled: isCheckboxSelected, defaultVal: values === null || values === void 0 ? void 0 : values.permenentAddresslane2, onChange: function (e) {
                                    setFieldValue('permenentAddresslane2', e);
                                    setFieldTouched('permenentAddresslane2', true);
                                }, value: values === null || values === void 0 ? void 0 : values.permenentAddresslane2 }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "permenentAddresslane2" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "permenentLandmark" }, function () {
                            return (React.createElement(text_filed_component_1.default, { className: "text-caps", name: "permenentLandmark", placeholder: 'Landmark', label: "Landmark", required: true, minLength: 2, maxLength: 150, isDisabled: isCheckboxSelected, onChange: function (e) {
                                    setFieldValue('permenentLandmark', e);
                                    setFieldTouched('permenentLandmark', true);
                                }, defaultVal: values === null || values === void 0 ? void 0 : values.permenentLandmark, value: values === null || values === void 0 ? void 0 : values.permenentLandmark }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "permenentLandmark" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "permenentState" }, function () {
                            return (React.createElement(drop_down_component_1.default, { name: "permenentState", placeholder: "Select State", label: "Select State", required: true, dropDownList: stateList, isDisabled: isCheckboxSelected, onChange: function (val) {
                                    setFieldValue('permenentCity', '');
                                    setFieldValue('permenentPincode', '');
                                    setFieldTouched('permenentState', true);
                                    setFieldValue('permenentState', val);
                                    if (val) {
                                        (0, generic_api_calls_1.fetchCitiesUnderStates)([val.id]).then(function (res) {
                                            console.log(res, 'res');
                                            setPermCityList(res);
                                        });
                                    }
                                }, value: values === null || values === void 0 ? void 0 : values.permenentState }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "permenentState" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "permenentCity" }, function () {
                            return (React.createElement(drop_down_component_1.default, { name: "permenentCity", placeholder: "Select City", label: "Select City", required: true, dropDownList: __spreadArray([], permCityList, true), isDisabled: isCheckboxSelected, onChange: function (val) {
                                    setFieldTouched('permenentCity', true);
                                    setFieldValue('permenentCity', val);
                                }, value: values === null || values === void 0 ? void 0 : values.permenentCity, key: Math.random().toString() }));
                        }),
                        console.log(permCityList, 'permCityList'),
                        React.createElement(formik_1.ErrorMessage, { name: "permenentCity" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "permenentCountry" }, function () {
                            return (React.createElement(drop_down_component_1.default, { name: "permenentCountry", placeholder: "Select Country", label: "Select Country", required: true, dropDownList: countryList, isDisabled: isCheckboxSelected, onChange: function (val) {
                                    setFieldTouched('permenentCountry', true);
                                    setFieldValue('permenentCountry', val);
                                }, value: values === null || values === void 0 ? void 0 : values.permenentCountry }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "permenentCountry" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 6, lg: 5 },
                        React.createElement(formik_1.Field, { name: "permenentPincode" }, function () {
                            return (React.createElement(text_filed_component_1.default, { name: "permenentPincode", placeholder: 'Pincode', label: "Pincode", required: true, isDisabled: isCheckboxSelected, defaultVal: values === null || values === void 0 ? void 0 : values.permenentPincode, value: values === null || values === void 0 ? void 0 : values.permenentPincode, onKeyPress: function (e) {
                                    if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                        e.target.value.length <= 5) {
                                    }
                                    else {
                                        e.preventDefault();
                                    }
                                }, onChange: function (e) {
                                    setFieldTouched('permenentPincode', true);
                                    setFieldValue('permenentPincode', e);
                                    if (e.length === 6 &&
                                        values.permenentState != null) {
                                        var validationPayload = {
                                            state_id: values.permenentState.id,
                                            pincode: parseInt(e),
                                        };
                                        (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                                            if (res.data.statusCode === 200 &&
                                                res.data.message != 'Success') {
                                                setErrors(__assign(__assign({}, errors), { permenentPincode: res.data.message }));
                                            }
                                        });
                                    }
                                } }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "permenentPincode" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true, sx: { marginLeft: '0' } },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); })),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 10 },
                        React.createElement(formik_1.Field, { name: "permenentProofOfAddress" }, function () {
                            return (React.createElement(file_upload_component_1.default, { buttonName: "Attach proof of Address", name: "permenentProofOfAddress", message: "Note:Only png, jpg, jpeg, pdf formats are allowed upto 2MB", acceptedFiletypes: [
                                    'image/jpeg',
                                    'image/png',
                                    'application/pdf',
                                ], maxSize: {
                                    image: 2,
                                    pdf: 2,
                                    csv: 2,
                                }, onChange: function (e) {
                                    setFieldValue('permenentProofOfAddress', e);
                                    setFieldTouched('permenentProofOfAddress', true);
                                }, value: values === null || values === void 0 ? void 0 : values.permenentProofOfAddress }));
                        }),
                        React.createElement(formik_1.ErrorMessage, { name: "permenentProofOfAddress" }, function (errorMsg) { return (React.createElement(material_1.FormHelperText, { error: true },
                            React.createElement("div", { className: "error" },
                                " ",
                                errorMsg))); }))),
                React.createElement("div", { className: "form-btns" },
                    React.createElement(button_component_1.default, { buttonName: "Back", color: "yellow-outline", onClickHandler: function () {
                            if (location.pathname.includes("".concat(route_path_1.default.PATHS.editVendor))) {
                                backButtonhandler("".concat(route_path_1.default.PATHS.editVendor), vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.PERSONALDETAILS);
                                updateValue(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.PERSONALDETAILS);
                            }
                            else {
                                backButtonhandler("".concat(route_path_1.default.PATHS.createVendor), vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.PERSONALDETAILS);
                                updateValue(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.PERSONALDETAILS);
                            }
                        } }),
                    React.createElement(button_component_1.default, { buttonName: "Save & Continue", type: "sumbit", color: "yellow" }))));
        })));
};
exports.default = Vendor_ContactDetails;
