"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var Stack_1 = require("@mui/system/Stack");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var switch_button_1 = require("../../../../common/buttons/switch_button");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var map_1 = require("../../../../common/map");
var modal_component_1 = require("../../../../common/modal/modal_component");
var RoastersForMultipleDays_1 = require("../../../../common/Roaster/RoastersForMultipleDays");
var text_filed_with_icon_1 = require("../../../../common/text_filed/text-filed-with-icon");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var array_helpers_1 = require("../../../../utils/array_helpers");
var lodash_1 = require("../../../../utils/lodash");
var lab_master_api_calls_1 = require("../../../Lab_Master/lab_master_api_calls");
var Lab_Master_slots_1 = require("../../../Lab_Master/Lab_Master_slots");
var CC_api_calls_1 = require("../../CC/CC_api_calls");
var CC_helper_1 = require("../../CC/CC_helper");
var reverseGeoCode_1 = require("../../CC/reverseGeoCode");
require("../../control_center/control_center.scss");
var hubMasterHelper_1 = require("./hubMasterHelper");
var hubMaster_api_calls_1 = require("./hubMaster_api_calls");
var ModifyHubTimeSlot = function () {
    var hubTypes = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.hub_type; });
    var state = (0, react_router_1.useLocation)().state;
    var isMounted = false;
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _a = React.useState([]), selectedList = _a[0], setSelectedList = _a[1];
    var _b = React.useState(1), weekdayid = _b[0], setWeekayid = _b[1];
    var _c = React.useState([]), gettimeSlotsList = _c[0], setgetTimeSlotList = _c[1];
    var convertedLabList = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var _d = React.useState(1), activeTab = _d[0], setActiveTab = _d[1];
    var _e = React.useState([]), weekDays = _e[0], setWeekDays = _e[1];
    var _f = React.useState([]), editData = _f[0], setEditdata = _f[1];
    var _g = React.useState(), selectedDay = _g[0], setSelectedDay = _g[1];
    var _h = React.useState(false), isDayselected = _h[0], setDayselected = _h[1];
    var _j = React.useState(), daySlots = _j[0], setDaySlots = _j[1];
    var _k = React.useState(), addedData = _k[0], setAddedData = _k[1];
    var _l = React.useState([]), arr = _l[0], setArr = _l[1];
    var _m = React.useState(false), showMaps = _m[0], toggleMapView = _m[1];
    var _o = React.useState(false), weekDaysModal = _o[0], setWeekDaysModal = _o[1];
    var _p = React.useState(), slotid = _p[0], setSlotid = _p[1];
    var _q = React.useState([]), selectedSlots = _q[0], setSelectedSlots = _q[1];
    var hiddenInnerSubmitFormRef = React.useRef(null);
    var _r = React.useState(1), weekDay = _r[0], setWeekDay = _r[1];
    var _s = React.useState(), editLocation = _s[0], setEditLocation = _s[1];
    var _t = React.useState(false), getLoactionFromLatLong = _t[0], setGetLoactionFromLatLong = _t[1];
    var _u = React.useState(hubMasterHelper_1.HUB_MASTER_INTITAL_VALUES), initialValues = _u[0], setInitialValues = _u[1];
    var _v = React.useState([]), hubDetails = _v[0], setHubDetails = _v[1];
    var nav = (0, react_router_1.useNavigate)();
    var pathName = window.location.pathname;
    var header = pathName.includes('editTimeSlot')
        ? 'Modify Time Window'
        : 'Add Hub';
    var navigateTOListPage = function () {
        nav("/".concat(route_path_1.default.PATHS.hubMasterList));
    };
    var getWeekDays = function () {
        (0, CC_api_calls_1.getAllWeekDays)()
            .then(function (response) {
            setWeekDays(response.data);
            var obj = {};
            response.data.map(function (each) {
                obj["day".concat(each.id)] = JSON.parse(JSON.stringify(gettimeSlotsList));
            });
            setDaySlots(__assign({}, obj));
            if (state) {
                getEditScheduleSlots();
            }
        })
            .catch(function (e) { return console.log(e); });
    };
    var getEditScheduleSlots = function () {
        var payload = {
            hub_code: state.hub_code,
        };
        (0, hubMaster_api_calls_1.getScheduleList)(payload)
            .then(function (res) {
            var scheduleArr = [];
            res.data.map(function (each) {
                var obj = {};
                obj['day'] = each.day;
                obj['schedule_time_slot'] = each.schedule_time_slot;
                scheduleArr.push(obj);
            });
            setEditdata(scheduleArr);
            editInitialValues(state, '');
        })
            .catch(function (e) { return console.log(e); });
    };
    var editInitialValues = function (state, hubcode) {
        if (state) {
            setInitialValues({
                hubCode: state.hub_code ? state.hub_code : hubcode ? hubcode : '',
                hubName: state.hub_name
                    ? state.hub_name
                    : state.center_name
                        ? state.center_name
                        : '',
                invoice_code: state.invoice_code ? state.invoice_code : '',
                hubType: state.hub_type ? (0, CC_helper_1.convertEditData)(state.hub_type) : '',
                hubState: state.hub_state ? state.hub_state : state.hub_state ? state : '',
                hubCity: state.hub_city ? state.hub_city : state.city ? state.city : '',
                labcode: state.lab_code
                    ? (0, CC_helper_1.convertEditDataForLabCode)(state.lab_code, convertedLabList)
                    : '',
                baseLocation: state.latitude && state.longitude
                    ? "".concat(state.latitude, ",").concat(state.longitude)
                    : '',
                base_location_address: state.base_location_address
                    ? state.base_location_address
                    : !state.latitude && !state.longitude
                        ? ''
                        : editLocation
                            ? editLocation
                            : '',
                id: state.id,
                address: state.address
                    ? state.address
                    : state.center_address
                        ? state.center_address
                        : '',
                geofence_enabled: state.geofence_enabled ? state.geofence_enabled : 0,
                geofence_radius: state.geofence_radius ? state.geofence_radius : 0,
            });
        }
    };
    var setColor;
    var weekDayColorHandler = function (onChangeWeekValues) {
        setActiveTab(onChangeWeekValues.id);
        if (onChangeWeekValues.color) {
            setColor = weekDays.map(function (weekDaysObject) {
                if (weekDaysObject.id === onChangeWeekValues.id) {
                    weekDaysObject['className'] = null;
                    return weekDaysObject;
                }
                return weekDaysObject;
            });
        }
        else {
            setDayselected(!isDayselected);
            setColor = weekDays.map(function (weekDaysObject) {
                if (weekDaysObject['className'] === 'tab-item active-tab') {
                    weekDaysObject['className'] = null;
                }
                if (weekDaysObject.id === onChangeWeekValues.id) {
                    weekDaysObject['className'] = 'tab-item active-tab';
                    return weekDaysObject;
                }
                return weekDaysObject;
            });
        }
        setColor.map(function (e) {
            if ((e === null || e === void 0 ? void 0 : e.className) === 'tab-item active-tab') {
                setSelectedDay(e);
            }
        });
        setWeekDays(setColor);
    };
    var submitUpdatedHubDetails = function (payload, slotsPayload) {
        (0, hubMaster_api_calls_1.updateHubDetails)(payload)
            .then(function (res) {
            if (res.statusCode == 200) {
                createHubSlots(slotsPayload, 'edit');
            }
            else {
                react_hot_toast_1.default.error(res.message);
            }
            navigateTOListPage();
        })
            .catch(function (err) {
            navigateTOListPage();
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var submitCreateHub = function (payload, slotsPayload) {
        (0, hubMaster_api_calls_1.createHub)(payload)
            .then(function (res) {
            if (res.statusCode == 200) {
                createHubSlots(slotsPayload, 'create');
            }
            else {
                react_hot_toast_1.default.error(res.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err);
        });
    };
    var createHubSlots = function (payload, operation) {
        var slotsPayload = {
            HubSchedule: payload,
        };
        (0, hubMaster_api_calls_1.createHubslot)(slotsPayload)
            .then(function (res) {
            if (res.statusCode == 200) {
                react_hot_toast_1.default.success(operation === 'edit'
                    ? 'Hub Updated successfully'
                    : 'Hub created successfully', { duration: 3000 });
            }
            else {
                react_hot_toast_1.default.error(res.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        })
            .finally(function () {
            navigateTOListPage();
        });
    };
    var formSubmitHandler = function (values) {
        var getSelectedSlots = function (perDaySlots, weekId) {
            var selectedArr = [];
            perDaySlots.map(function (each) {
                var _a;
                if (each.onehourslot) {
                    selectedArr.push((_a = {},
                        _a['day'] = weekId,
                        _a['schedule_time_slot'] = each.onehourslot,
                        _a));
                    return each;
                }
            });
            return selectedArr;
        };
        var payloadSlots = [];
        weekDays.map(function (weekday) {
            var selectedSlotsPerDay = getSelectedSlots(daySlots["day".concat(weekday.id)], weekday.id);
            payloadSlots.push.apply(payloadSlots, selectedSlotsPerDay);
        });
        var hubPayload = {
            lab_code: values.labcode.value,
            hub_code: values.hubCode,
            latitude: values.baseLocation.split(',')[0],
            longitude: values.baseLocation.split(',')[1],
            hub_name: values.hubName,
            invoice_code: values.invoice_code,
            base_location_address: values.base_location_address,
            hub_type: values.hubType.value,
            hub_state: values.hubState,
            hub_city: values.hubCity,
            is_active: true,
            is_deleted: false,
            address: values.address,
            geofence_radius: values.geofence_radius,
            geofence_enabled: values.geofence_enabled ? true : false,
        };
        var slotsPayload = [];
        var hubSchedule = {};
        if (payloadSlots.length > 0) {
            payloadSlots.map(function (each) {
                hubSchedule = {
                    hub_code: values.hubCode,
                    hub_name: values.hubName,
                    invoice_code: values.invoice_code,
                    is_active: true,
                    is_deleted: false,
                };
                hubSchedule['schedule_time_slot'] = each.schedule_time_slot;
                hubSchedule['day'] = each.day;
                slotsPayload.push(hubSchedule);
            });
        }
        else {
            hubSchedule['hub_code'] = state ? state.hub_code : values.hubCode;
            slotsPayload.push(hubSchedule);
        }
        setArr(function (prev) { return __spreadArray(__spreadArray([], prev, true), [hubPayload], false); });
        console.log(slotsPayload, 'oiujhg');
        if (state) {
            submitUpdatedHubDetails(hubPayload, slotsPayload);
        }
        else {
            submitCreateHub(hubPayload, slotsPayload);
        }
    };
    var weekDaysSubmitHandler = function (selectedDays) {
        selectedDays.push("day".concat(weekDay));
        var selectedDaySlots = daySlots["day".concat(weekDay)];
        var colorIncludedSlots = [];
        var filterUniqueDyas = (0, lab_master_api_calls_1.remove_duplicates_Slots)(selectedDays);
        var obj = {};
        var k = JSON.parse(JSON.stringify(daySlots));
        filterUniqueDyas === null || filterUniqueDyas === void 0 ? void 0 : filterUniqueDyas.forEach(function (week) {
            var arr = [];
            selectedDaySlots.map(function (each, index) {
                arr.push(each);
                k[week] = JSON.parse(JSON.stringify(daySlots["day".concat(weekDay)]));
                selectedList.map(function (val) {
                    if (each.from_time.toString().trim() == val.parentslot.toString().trim() &&
                        val.weekday == activeTab) {
                        colorIncludedSlots.push({
                            parentslot: each.from_time,
                            childslot: selectedDaySlots[index + 1].to_time,
                            weekday: (0, lab_master_api_calls_1.getWeekDay)(week),
                        });
                    }
                });
            });
            obj[week] = arr;
        });
        setDaySlots(k);
        var comapreSlots = function (obj) {
            return colorIncludedSlots.find(function (singleObh) { return singleObh.weekday === obj.weekday; });
        };
        var removedDuplicates = selectedList.filter(function (singleEle) { return !comapreSlots(singleEle); });
        var concatSlots = __spreadArray(__spreadArray([], removedDuplicates, true), colorIncludedSlots, true);
        var uniqueSlots = lodash_1.default.uniqWith(concatSlots, function (slotA, slotB) {
            return slotA.parentslot == slotB.parentslot && slotA.weekday == slotB.weekday;
        });
        setSelectedList(uniqueSlots);
        react_hot_toast_1.default.success('Slots have been added succesfully for Selected Days', {
            duration: 2000,
        });
        daysModal();
    };
    var daysModal = function () {
        var selectedDaySlots = daySlots["day".concat(weekDay)];
        var slotsSelected = [];
        selectedDaySlots.filter(function (each) {
            if (each.color === 'slot-btn status-selected') {
                slotsSelected.push(each);
            }
            return arr;
        });
        if (slotsSelected.length > 0) {
            setWeekDaysModal(!weekDaysModal);
        }
        else {
            react_hot_toast_1.default.error('Please Select atleast one slot', {
                duration: 5000,
            });
        }
    };
    var editDataSlots = function () {
        if (weekDays.length > 0) {
            var day_1;
            editData === null || editData === void 0 ? void 0 : editData.map(function (schedule) {
                var _a;
                day_1 = (0, CC_helper_1.getDayFromId)(schedule.day, weekDays);
                var editFromTime = schedule.schedule_time_slot.split('-')[0];
                setSelectedList(function (preSlot) {
                    if (preSlot.length > 0) {
                        preSlot.map(function (val) {
                            if (!val.parentslot.includes(schedule.schedule_time_slot.split('-')[0])) {
                                return __spreadArray(__spreadArray([], preSlot, true), [
                                    {
                                        parentslot: schedule.schedule_time_slot.split('-')[0],
                                        childslot: schedule.schedule_time_slot.split('-')[1],
                                        weekday: schedule.day,
                                    },
                                ], false);
                            }
                        });
                    }
                    else {
                        setSelectedList(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                            {
                                parentslot: schedule.schedule_time_slot.split('-')[0],
                                childslot: schedule.schedule_time_slot.split('-')[1],
                                weekday: schedule.day,
                            },
                        ], false); });
                    }
                    return preSlot;
                });
                if (day_1) {
                    console.log(daySlots, 'uihjn');
                    (_a = daySlots["day".concat(day_1.id)]) === null || _a === void 0 ? void 0 : _a.forEach(function (slot, index) {
                        var slotFromTime = slot.from_time.split('-')[0];
                        if (editFromTime.toString().trim() == slotFromTime.toString().trim()) {
                            slot['color'] = 'slot-btn status-selected';
                            slot['onehourslot'] = schedule.schedule_time_slot;
                            daySlots["day".concat(day_1.id)][index + 1]['color'] =
                                'slot-btn status-selected';
                            daySlots["day".concat(day_1.id)][index + 1]['onehourslot'] =
                                schedule.schedule_time_slot;
                        }
                    });
                    setDaySlots(daySlots);
                }
            });
        }
    };
    var getHubDetails = function (val) {
        var hubcode = val.target.value;
        var payload = {
            warehouse_code: val.target.value,
        };
        (0, CC_api_calls_1.fetchCCEditList)(payload)
            .then(function (res) {
            if (res.statusCode == 200) {
                setHubDetails(res.data);
                editInitialValues(res.data, hubcode);
            }
        })
            .catch(function (err) {
            console.log(err.message);
        });
    };
    React.useEffect(function () {
        (0, lab_master_api_calls_1.getAllWeekDaysMasterSlots)()
            .then(function (res) {
            setgetTimeSlotList(res.data);
        })
            .catch(function (e) { return console.log(e); });
    }, []);
    React.useEffect(function () {
        if (state &&
            !state.base_location_address &&
            state.latitude &&
            state.longitude) {
            setGetLoactionFromLatLong(true);
        }
        else if (hubDetails &&
            !hubDetails.base_location_address &&
            hubDetails.latitude &&
            hubDetails.longitude) {
            setGetLoactionFromLatLong(true);
        }
    }, [state, hubDetails]);
    React.useEffect(function () {
        if (!isMounted) {
            getWeekDays();
            isMounted = true;
        }
    }, [editLocation, gettimeSlotsList]);
    React.useEffect(function () {
        editDataSlots();
    }, [editData]);
    var modalCloseHandler = function () {
        setWeekDaysModal(false);
    };
    var returnweekdays = function (setFieldValue, touched, errors) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true },
                React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true },
                    React.createElement("h2", { className: "selected-felabel custom-form-label p-24" }, "Select Hub Time Window Slot Hours"),
                    errors['day'] && touched['day'] ? (React.createElement("div", { className: "error" },
                        errors['day'],
                        " ")) : null)),
            React.createElement(Grid_1.default, { item: true, xs: 12 },
                React.createElement("div", { className: "days-list-wrapper tabs-list-wrapper" },
                    React.createElement(Stack_1.default, { className: "tabs-wrapper", direction: "row", alignItems: "center", justifyContent: "space-between", width: "100%" },
                        React.createElement(material_1.Tabs, { onChange: function (event, newValue) { return setSlotid(newValue); }, className: "tab-links" }, weekDays === null || weekDays === void 0 ? void 0 : weekDays.map(function (weekDaysDetails) {
                            return (React.createElement(material_1.Tab, { className: activeTab == weekDaysDetails.id ? 'tab-item active-tab' : 'tab-item', label: weekDaysDetails.day, onClick: function () {
                                    setWeekDay(weekDaysDetails.id);
                                    setWeekayid(weekDaysDetails.id);
                                    weekDayColorHandler(weekDaysDetails);
                                    var postOnchangeSlotDetails = weekDays === null || weekDays === void 0 ? void 0 : weekDays.filter(function (weekDaysObjects) {
                                        if (weekDaysObjects['className'] === 'tab-item active-tab') {
                                            return weekDaysObjects.id;
                                        }
                                    });
                                    setFieldValue('day', postOnchangeSlotDetails);
                                } }));
                        })),
                        React.createElement(modal_component_1.default, { buttonName: "Apply For Multiple Days", buttonColor: "yellow-outline", onClickHandler: daysModal, open: weekDaysModal, modalSize: "long-modal" },
                            React.createElement(RoastersForMultipleDays_1.default, { weekDaysSubmitHandler: function (selectedDays) {
                                    return weekDaysSubmitHandler(selectedDays);
                                }, weekDays: weekDays, cancelhandler: modalCloseHandler })))))));
    };
    var returnslots = function (setFieldValue) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid_1.default, { item: true, xs: 14, lg: 12 },
                React.createElement("div", { className: "p-24" },
                    daySlots && (React.createElement(Lab_Master_slots_1.default, { name: "selectedSlots", onChange: function (val) {
                            setFieldValue('selectedSlots', val);
                        }, selectedSlots: selectedSlots, setDaySlots: setDaySlots, setSelectedSlots: setSelectedSlots, selectedDay: selectedDay, isDayselected: isDayselected, slotid: slotid, selectedList: selectedList, setSelectedList: setSelectedList, daySlots: daySlots, dayset: '', addedData: addedData, setAddedData: setAddedData, editData: editData, weekDay: weekDay && weekDay, weekdayid: weekdayid && weekdayid })),
                    React.createElement("div", { className: "cc-slot-legend" },
                        React.createElement("div", { className: "legend-item bg-existing" }, "Available"),
                        React.createElement("div", { className: "legend-item bg-selected" }, "Selected"))))));
    };
    return (React.createElement("div", { className: "block-slot-wrapper" },
        React.createElement("div", { className: "scrollable-content" },
            React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, enableReinitialize: true, validationSchema: schema_1.schemas.HUB_Master_Schema, onSubmit: formSubmitHandler }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                        React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                            React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTOListPage }),
                            React.createElement("h2", null, header)),
                        React.createElement("div", { className: "page-control-wrapper" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateTOListPage }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))),
                    React.createElement("div", { className: "add-slotform form-filed-wrapper" },
                        React.createElement("button", { type: "submit", ref: hiddenInnerSubmitFormRef, className: "display_hide" }, "Submit"),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "hubCode", placeholder: "Hub Code", label: "Hub Code", value: values.hubCode, isDisabled: state && true, onChange: function (val) {
                                        setFieldValue('hubCode', val.toUpperCase());
                                        setFieldTouched('hubCode', true);
                                    }, onBlur: function (val) { return getHubDetails(val); } }),
                                errors['hubCode'] && touched['hubCode'] ? (React.createElement("div", { className: "error" },
                                    errors['hubCode'],
                                    " ")) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 6, lg: 6 },
                                React.createElement(text_filed_component_1.default, { name: "hubName", placeholder: "Hub Name", label: "Hub Name", value: values.hubName, onChange: function (val) {
                                        setFieldValue('hubName', val);
                                        setFieldTouched('hubName', true);
                                    }, isDisabled: hubDetails.center_name && true }),
                                errors['hubName'] && touched['hubName'] ? (React.createElement("div", { className: "error" },
                                    errors['hubName'],
                                    " ")) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "invoice_code", placeholder: "Invoice Code", label: "Invoice Code", value: values.invoice_code, onChange: function (val) {
                                        setFieldValue('invoice_code', val.toUpperCase());
                                        setFieldTouched('invoice_code', true);
                                    }, isDisabled: hubDetails.invoice_code && true }),
                                errors['invoice_code'] && touched['invoice_code'] ? (React.createElement("div", { className: "error" },
                                    errors['invoice_code'],
                                    " ")) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "hubType", value: values.hubType, dropDownList: hubTypes, placeholder: "Hub Type", onChange: function (val) {
                                        setFieldValue('hubType', val);
                                        setFieldTouched('hubType', true);
                                    } }),
                                errors['hubType'] && touched['hubType'] ? (React.createElement("div", { className: "error" },
                                    errors['hubType'],
                                    " ")) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement("div", { className: "clear-dropdown" },
                                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "labcode", value: values.labcode, dropDownList: convertedLabList, placeholder: "Lab Code", required: true, isDisabled: hubDetails.lab_code && true, onChange: function (val) {
                                            if (val == null) {
                                                setFieldValue('hubState', '');
                                                setFieldValue('hubCity', '');
                                                setFieldValue('labcode', '');
                                            }
                                            setFieldValue('hubState', val.center_state);
                                            setFieldValue('hubCity', val.center_city);
                                            setFieldValue('labcode', val);
                                            setFieldTouched('labcode', true);
                                        } }),
                                    errors['labcode'] && touched['labcode'] ? (React.createElement("div", { className: "error" },
                                        errors['labcode'],
                                        " ")) : null)),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "hubState", placeholder: "Hub State", label: "Hub State", isDisabled: true, value: values.hubState, onChange: function (val) { return setFieldValue('hubState', val); } })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "hubCity", placeholder: 'Hub City', label: "Hub City", isDisabled: true, value: values.hubCity, onChange: function (val) { return setFieldValue('hubCity', val); } })),
                            React.createElement(Grid_1.default, { item: true, xs: 6, lg: 6 },
                                React.createElement(text_filed_component_1.default, { name: "address", placeholder: 'Hub Address', label: "Hub Address", value: values.address, onChange: function (val) { return setFieldValue('address', val); }, maxLength: 250, isDisabled: hubDetails.center_address && true })),
                            React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                React.createElement(Grid_1.default, { item: true, xs: 12 },
                                    React.createElement("h2", { className: "label-heading heading" }, "HUB Base Location")),
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement(text_filed_with_icon_1.default, { name: "base_location_address", placeholder: "Base Location Address", label: "Base Location Address", onClick: function () { return toggleMapView(!showMaps); }, value: values.base_location_address, key: editLocation, isDisabled: (hubDetails.latitude && hubDetails.longitude) ||
                                            (hubDetails.base_location_address && true) }),
                                    errors['base_location_address'] &&
                                        touched['base_location_address'] ? (React.createElement("div", { className: "error" },
                                        errors['base_location_address'],
                                        " ")) : null),
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement(text_filed_component_1.default, { name: "baseLocation", placeholder: "FE Base Location", label: "Latitude & Longitude", onChange: function (val) {
                                            setFieldValue('baseLocation', val);
                                            setFieldTouched('baseLocation', true);
                                        }, value: values.baseLocation, isDisabled: true })),
                                React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2, className: "geofence-switch-btn" },
                                    React.createElement(switch_button_1.default, { swicthLabel: "Geofence Enable", defaultChecked: values.geofence_enabled, value: values.geofence_enabled, key: values.geofence_enabled, onChangeHandler: function (event) {
                                            setFieldValue('geofence_enabled', event.target.checked);
                                            setFieldTouched('geofence_enabled', true);
                                        } })),
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                    React.createElement(text_filed_component_1.default, { isDisabled: values.geofence_enabled ? false : true, name: "geofence_radius", placeholder: 'Geofence Radius', label: "Geofence Radius", onChange: function (val) {
                                            setFieldValue('geofence_radius', Number(val));
                                            setFieldTouched('geofence_radius', true);
                                        }, value: values.geofence_radius, onKeyPress: function (e) {
                                            if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                                e.target.value.length <= 2) {
                                            }
                                            else {
                                                e.preventDefault();
                                            }
                                        } }),
                                    errors['geofence_radius'] && touched['geofence_radius'] ? (React.createElement("div", { className: "error" },
                                        errors['geofence_radius'],
                                        " ")) : null)))),
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                        returnweekdays(setFieldValue, touched, errors),
                        returnslots(setFieldValue),
                        showMaps && (React.createElement(React.Fragment, null,
                            React.createElement(map_1.default, { bast_lat_lng: values.baseLocation, baselocation_address: values.base_location_address, submitSelectedPlace: function (latAndLng, fe_current_location) {
                                    if (latAndLng) {
                                        setFieldValue('baseLocation', "".concat(latAndLng));
                                        setFieldTouched('baseLocation', true);
                                        setFieldValue('base_location_address', "".concat(fe_current_location));
                                        setFieldTouched('base_location_address', true);
                                        toggleMapView(false);
                                    }
                                }, close: function () { return toggleMapView(false); } })))),
                    getLoactionFromLatLong && (React.createElement(reverseGeoCode_1.default, { setFieldValue: setFieldValue, setEditLocation: setEditLocation, lat: (state === null || state === void 0 ? void 0 : state.latitude) ? state.latitude : hubDetails === null || hubDetails === void 0 ? void 0 : hubDetails.latitude, lng: (state === null || state === void 0 ? void 0 : state.longitude) ? state.longitude : hubDetails === null || hubDetails === void 0 ? void 0 : hubDetails.longitude }))));
            }))));
};
exports.default = ModifyHubTimeSlot;
