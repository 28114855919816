"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var switch_button_1 = require("../../../common/buttons/switch_button");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var radio_buttons_1 = require("../../../common/radio_buttons/radio_buttons");
var text_field_with_datepicker_1 = require("../../../common/text_filed/text_field_with_datepicker");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
require("../fe-page.scss");
var Exit_FEComponent = function (_a) {
    var feName = _a.feName, fe_id = _a.fe_id, handleClose = _a.handleClose, inventoryList = _a.inventoryList, user_id = _a.user_id, isAlreadyInacitve = _a.isAlreadyInacitve, minimumDate = _a.minimumDate, maximumDate = _a.maximumDate, initialValues = _a.initialValues;
    console.log(new Date(minimumDate), 'date2');
    console.log(initialValues, 'jhgfds');
    var _b = React.useState([]), checkedValue = _b[0], setCheckedValue = _b[1];
    var _c = React.useState(), inventoryAmount = _c[0], setInventoryAmount = _c[1];
    var _d = React.useState(false), inventorystate = _d[0], setInventorystate = _d[1];
    console.log(checkedValue, 'checkedValue');
    var _e = React.useState([]), uncheckedValue = _e[0], setUnCheckedValue = _e[1];
    var b = inventoryList.length;
    console.log(b, 'aaaaaaaaa');
    var _f = React.useState(isAlreadyInacitve ? false : true), inActiveFE = _f[0], setInActiveFE = _f[1];
    console.log(inventorystate, 'inventorystate');
    var nav = (0, react_router_dom_1.useNavigate)();
    console.log(uncheckedValue, __spreadArray([], initialValues.selectedInventoryList, true), 'cccc');
    var emptyDetails = '--';
    var fe_exit_reasons_list = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.fe_exit_reasons_list; });
    var date1 = new Date('2022-11-15 10:34:23');
    console.log(date1, 'date1');
    var date3 = new Date('2022-11-20 10:34:23');
    var a = Math.round((date3 - date1) / (1000 * 60 * 60 * 24));
    console.log(a, 'aaaaaaaaaaaa');
    var handleChange = function (e, value, setFieldValue) {
        var checked = e.target.checked;
        console.log('inside handle change:', e.target.value);
        if (!checked) {
            console.log('checked');
            var index = uncheckedValue.indexOf(value);
            uncheckedValue.splice(index, 1);
            console.log(uncheckedValue, 'uncheckedValue');
            setCheckedValue(uncheckedValue);
            setInventorystate(true);
        }
        else if (checked && uncheckedValue.indexOf(value) == -1) {
            console.log('hhhhhhhhgghhb');
            uncheckedValue.push(parseInt(value));
            setUnCheckedValue(uncheckedValue);
            console.log(uncheckedValue.length, 'ldngggggg');
            if (uncheckedValue.length === inventoryList.length) {
                setInventorystate(false);
                setInventoryAmount();
            }
        }
    };
    var formSubmitHandler = function (values) {
        console.log(values, 'jhgfds');
        var payload = {
            user_id: parseInt(user_id),
            reason: values.reason.label,
            comments: values.comments,
            exit_type: values.exit_type,
        };
        if (values.exit_type === 'Exited') {
            payload['inventory_ids'] = uncheckedValue;
            payload['exit_date'] = moment(values.fromDate).format('YYYY-MM-DD');
            payload['inventory_amount'] = parseInt(inventoryAmount);
        }
        else {
            payload['startDate'] = moment(values.fromDate).format('YYYY-MM-DD');
        }
        api_service_1.api_service
            .put({
            endPoint: api_service_1.api_service.api_urls.USER_EXIT,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (res) {
            if (res.status === 200) {
                react_hot_toast_1.default.success(res.data.message);
                handleClose();
                if (values.exit_type === 'In-Active') {
                    nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.feInActiveList));
                }
                else {
                    nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.feExisted));
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
        console.log('inventoryList inside exit FE:', inventoryList, payload);
    };
    var schemaValidation = function () {
        if (inventorystate && !inActiveFE) {
            return schema_1.schemas.FE_ExitProcessSchemaAmount;
        }
        if (!inActiveFE) {
            return schema_1.schemas.FE_ExitProcessSchema;
        }
        else {
            return schema_1.schemas.InactiveFeSchema;
        }
    };
    React.useEffect(function () {
        console.log(initialValues, 'initialValues.selectedInventoryList');
        if (initialValues.selectedInventoryList.length > 0) {
            setUnCheckedValue(__spreadArray([], initialValues.selectedInventoryList, true));
        }
    }, []);
    return (React.createElement("div", null,
        React.createElement("div", { className: "modal-header header-wrapper" },
            React.createElement("h2", { className: "heading" },
                "Are you sure you want to ",
                inActiveFE ? 'In Active ' : 'exit ',
                feName
                    ? feName.substring(0, 20) + "".concat(feName.length >= 20 ? '...' : '', "-")
                    : emptyDetails,
                fe_id ? fe_id : emptyDetails,
                " ?"),
            !inActiveFE ? (React.createElement("p", { className: "sub-heading" }, "If you want to exit FE, please provide below details")) : (React.createElement("p", { className: "sub-heading" }, "If you want to Inactive FE, please provide below details"))),
        React.createElement(formik_1.Formik, { validateOnBlur: false, enableReinitialize: true, initialValues: __assign({}, initialValues), validationSchema: schemaValidation(), onSubmit: function (values, _a) {
                var resetForm = _a.resetForm;
                formSubmitHandler(values);
            } }, function (_a) {
            var errors = _a.errors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched;
            return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                console.log(values, 'vvvvvv'),
                React.createElement("div", { className: inActiveFE ? 'modal-body no-overflow ' : 'modal-body overflow' },
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                        !isAlreadyInacitve && (React.createElement(Grid_1.default, { item: true, xs: 12 },
                            React.createElement(radio_buttons_1.default, { lableNames: [
                                    { label: 'Inactive', value: 'In-Active' },
                                ], name: "exit_type", onchange: function (event) {
                                    setFieldValue('exit_type', event.target.value);
                                    if (event.target.value === 'In-Active') {
                                        setInActiveFE(true);
                                        setCheckedValue(__spreadArray([], initialValues.selectedInventoryList, true));
                                    }
                                    else {
                                        setInActiveFE(false);
                                    }
                                    setFieldTouched('reason', false);
                                    setFieldTouched('comments', false);
                                    setFieldTouched('fromDate', false);
                                    setFieldValue('reason', '');
                                    setFieldValue('comments', '');
                                    setFieldValue('fromDate', '');
                                }, defaultValue: 'In-Active' }))),
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            inActiveFE ? (React.createElement(text_filed_with_fromto_Daterange_1.default, { required: true, minimumDate: new Date(), maximumDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000), onChangeHandler: function (e) {
                                    console.log(e, 'daterange');
                                    if (e) {
                                        console.log(e, 'e in condition');
                                        setFieldValue('fromDate', e.start);
                                        setFieldTouched('fromDate', true);
                                    }
                                    if (e === null) {
                                        setFieldValue('fromDate', null);
                                        setFieldTouched('fromDate', true);
                                    }
                                } })) : (React.createElement(text_field_with_datepicker_1.default, { required: true, minDate: minimumDate, label: "".concat(inActiveFE ? 'From Date' : 'Exit Date'), onChange: function (e) {
                                    setFieldValue('fromDate', e);
                                    setFieldTouched('fromDate', true);
                                }, name: "fromDate", value: values.fromDate })),
                            errors['fromDate'] && touched['fromDate'] ? (React.createElement("div", { className: "error" }, errors['fromDate'])) : null),
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement(drop_down_component_1.default, { required: true, name: "selectedExit", placeholder: "Reason for leaving", dropDownList: fe_exit_reasons_list, onChange: function (val) {
                                    setFieldValue('reason', val);
                                    setFieldTouched('reason', true);
                                }, value: values.reason }),
                            errors['reason'] && touched['reason'] ? (React.createElement("div", { className: "error" }, errors['reason'])) : null),
                        console.log(errors, 'errors', touched, 'touched'),
                        !inActiveFE && b >= 1 ? (React.createElement(Grid_1.default, { item: true, xs: 12 },
                            React.createElement("p", { className: "sub-heading modal-sub-heaidng" }, "Please collect the below inventory that assigned to FE"),
                            React.createElement("div", { className: "switch-group switch-scroll" }, inventoryList.map(function (inventory) {
                                return (React.createElement(React.Fragment, null,
                                    React.createElement(switch_button_1.default, { swicthLabel: inventory.inventory_name, defaultChecked: true, value: inventory.inventory_id, key: inventory.inventory_id, name: inventory.inventory_name, onChangeHandler: function (e) {
                                            return handleChange(e, inventory.inventory_id, setFieldValue);
                                        } })));
                            })),
                            errors['selectedInventoryList'] &&
                                touched['selectedInventoryList'] ? (React.createElement("div", { className: "error" }, errors['selectedInventoryList'])) : null)) : (' '),
                        inventorystate && !inActiveFE ? (React.createElement(Grid_1.default, { item: true, xs: 12 },
                            React.createElement(text_filed_component_1.default, { label: "Amount", required: true, name: "inventory_amount", onKeyPress: function (e) {
                                    if (new RegExp(/^[0-9]+$/).test(e.key)) {
                                    }
                                    else {
                                        e.preventDefault();
                                    }
                                }, onChange: function (val) {
                                    setInventoryAmount(val);
                                    setFieldValue('inventory_amount', val);
                                    setFieldTouched('inventory_amount', true);
                                }, value: inventoryAmount }),
                            errors['inventory_amount'] &&
                                touched['inventory_amount'] ? (React.createElement("div", { className: "error" }, errors['inventory_amount'])) : null)) : (''),
                        React.createElement(Grid_1.default, { item: true, xs: 12 },
                            React.createElement(text_filed_component_1.default, { label: "Other Comments", required: true, name: "comments", onChange: function (val) {
                                    setFieldValue('comments', val);
                                    setFieldTouched('comments', true);
                                }, value: values.comments }),
                            errors['comments'] && touched['comments'] ? (React.createElement("div", { className: "error" }, errors['comments'])) : null))),
                React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
        })));
};
exports.default = Exit_FEComponent;
