"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Timeline_1 = require("@mui/lab/Timeline");
var TimelineConnector_1 = require("@mui/lab/TimelineConnector");
var TimelineContent_1 = require("@mui/lab/TimelineContent");
var TimelineDot_1 = require("@mui/lab/TimelineDot");
var TimelineItem_1 = require("@mui/lab/TimelineItem");
var TimelineSeparator_1 = require("@mui/lab/TimelineSeparator");
var Accordion_1 = require("@mui/material/Accordion");
var AccordionDetails_1 = require("@mui/material/AccordionDetails");
var AccordionSummary_1 = require("@mui/material/AccordionSummary");
var Drawer_1 = require("@mui/material/Drawer");
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../actions/config_action");
var api_urls_1 = require("../../../api/api_urls");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var profile_grid_1 = require("../../../common/profile_grid/profile_grid");
var images_1 = require("../../../constants/images");
var profile_detaile_lable_1 = require("../../../constants/profile_detaile_lable");
var route_path_1 = require("../../../constants/route_path");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var generic_api_calls_1 = require("../../generic_api_calls");
require("./sample-tracking.scss");
var sample_tracking_api_calls_1 = require("./sample_tracking_api_calls");
var emptyDetails = '--';
var BasicDetials = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var Samplefeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.sampleTracking);
    var pathname = location.pathname;
    console.log('Samplefeatures:', Samplefeatures);
    var rowId = (0, react_router_1.useParams)().id;
    console.log(rowId, 'rowId');
    var _a = React.useState([]), dataList = _a[0], setDataList = _a[1];
    var nav = (0, react_router_1.useNavigate)();
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState([]), sampleBagDetails = _c[0], setSampleBagDetails = _c[1];
    var _d = React.useState(false), trackBagopen = _d[0], setTrackBagopen = _d[1];
    var _e = React.useState(''), picupImageDoc = _e[0], setPickupImageDoc = _e[1];
    var _f = React.useState(''), pickupImageURL = _f[0], setpickupImageURL = _f[1];
    var _g = React.useState([]), bagDetails = _g[0], setBagDetails = _g[1];
    var _h = React.useState(''), labImageDoc = _h[0], setlabImageDoc = _h[1];
    var _j = React.useState(''), labImageURL = _j[0], setlabImageURL = _j[1];
    var _k = React.useState(''), hubImageDoc = _k[0], sethubImageDoc = _k[1];
    var _l = React.useState(''), hubImageURL = _l[0], sethubImageURL = _l[1];
    var trackingBaghandleDrawerOpen = function () {
        setTrackBagopen(true);
        setOpen(true);
        fetchbagJourney("".concat(api_urls_1.api_urls.BAG_JOURNEY, "?bag_barcode=").concat(rowId), process.env.SAMPLE_TRAKING_URL);
    };
    var trackingBaghandleDrawerClose = function (e) {
        setTrackBagopen(true);
        setOpen(false);
    };
    var handleDrawerOpen = function (value) {
        console.log(value, 'vvvvvvvv');
        setOpen(true);
        setTrackBagopen(false);
        fetchbagSampleJourney("".concat(api_urls_1.api_urls.SAMPLE_BAG_JOURNEY, "?reference_number=").concat(value && value), process.env.SAMPLE_TRAKING_URL);
    };
    var handleDrawerClose = function () {
        setOpen(false);
    };
    var navigateToSampleTracking = function () {
        if (pathname.includes("".concat(route_path_1.default.PATHS.sampleTracking))) {
            nav("/".concat(route_path_1.default.PATHS.sampleTracking));
        }
        else {
            dispatch((0, config_action_1.featchCurrentActiveMenu)({
                mainModule: 'Master Management',
                subModule: menu_constants_1.FEATURE_CONSTANTS.master_box_tracking,
                subChaildModule: '',
            }));
            nav(-1);
        }
    };
    var getBagDetails = function (endPoint, domain) {
        var payload = {};
        payload.user_id = rowId;
        (0, sample_tracking_api_calls_1.fetchBagList)(endPoint, domain)
            .then(function (response) {
            setDataList(response);
            console.log(response, 'response');
            if (response.pickup_bag_photo) {
                console.log('imagee');
                var pickup_bag_photo_1 = response.pickup_bag_photo.split('/');
                if (pickup_bag_photo_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response.pickup_bag_photo).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (pickup_bag_photo_1[pickup_bag_photo_1.length - 1].includes('jpg') ||
                            pickup_bag_photo_1[pickup_bag_photo_1.length - 1].includes('png') ||
                            pickup_bag_photo_1[pickup_bag_photo_1.length - 1].includes('jfif')) {
                            setPickupImageDoc((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            setpickupImageURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (pickup_bag_photo_1[pickup_bag_photo_1.length - 1].includes('pdf')) {
                            setPickupImageDoc(images_1.default.pdfImage);
                            setpickupImageURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            setPickupImageDoc(images_1.default.csvImage);
                            setpickupImageURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                }
            }
            if (response.hub_receiving_photo) {
                console.log('imagee');
                var hub_receiving_photo_1 = response.hub_receiving_photo.split('/');
                if (hub_receiving_photo_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response.hub_receiving_photo).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (hub_receiving_photo_1[hub_receiving_photo_1.length - 1].includes('jpg') ||
                            hub_receiving_photo_1[hub_receiving_photo_1.length - 1].includes('png') ||
                            hub_receiving_photo_1[hub_receiving_photo_1.length - 1].includes('jfif')) {
                            sethubImageDoc((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            sethubImageURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (hub_receiving_photo_1[hub_receiving_photo_1.length - 1].includes('pdf')) {
                            sethubImageDoc(images_1.default.pdfImage);
                            sethubImageURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            sethubImageDoc(images_1.default.csvImage);
                            sethubImageURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                }
            }
            if (response === null || response === void 0 ? void 0 : response.lab_handover_photo) {
                console.log('imagee');
                var lab_handover_photo_1 = response.lab_handover_photo.split('/');
                if (lab_handover_photo_1.length > 0) {
                    (0, generic_api_calls_1.fetchImnageURLFromName)(response.lab_handover_photo).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (lab_handover_photo_1[lab_handover_photo_1.length - 1].includes('jpg') ||
                            lab_handover_photo_1[lab_handover_photo_1.length - 1].includes('png') ||
                            lab_handover_photo_1[lab_handover_photo_1.length - 1].includes('jfif')) {
                            setlabImageDoc((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url);
                            setlabImageURL((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.url);
                        }
                        else if (lab_handover_photo_1[lab_handover_photo_1.length - 1].includes('pdf')) {
                            setlabImageDoc(images_1.default.pdfImage);
                            setlabImageURL((_f = (_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.url);
                        }
                        else {
                            setlabImageDoc(images_1.default.csvImage);
                            setlabImageURL((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.url);
                        }
                    });
                }
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var fetchbagSampleJourney = function (endPoint, domain) {
        (0, sample_tracking_api_calls_1.fetchBagSampleJourney)(endPoint, domain)
            .then(function (response) {
            setSampleBagDetails(response);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var fetchbagJourney = function (endPoint, domain) {
        (0, sample_tracking_api_calls_1.fetchBagJourney)(endPoint, domain)
            .then(function (response) {
            setBagDetails(response);
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    React.useEffect(function () {
        getBagDetails("".concat(api_urls_1.api_urls.BAG_DETAILS, "?bag_barcode=").concat(rowId), process.env.SAMPLE_TRAKING_URL);
    }, []);
    var BasicInformation = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "sample-details-wrapper small-overflow-hide" },
                React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 } },
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                            React.createElement("h2", null,
                                " ",
                                profile_detaile_lable_1.default.SAMPLE_BAG_HEADINGS[0]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.current_status, value: dataList && dataList.current_status
                            ? dataList.current_status +
                                "".concat(dataList.current_status.length > 30 ? '...' : '')
                            : emptyDetails }),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                            React.createElement("h2", null,
                                " ",
                                profile_detaile_lable_1.default.SAMPLE_BAG_HEADINGS[1]))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.bag_id, value: dataList && dataList.bag_id
                            ? dataList.bag_id.substring(0, 30) +
                                "".concat(dataList.bag_id.length > 30 ? '...' : '')
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.bag_created_by, value: dataList && dataList.bag_created_by
                            ? dataList.bag_created_by +
                                "".concat(dataList.bag_created_by.length > 30 ? '...' : '')
                            : emptyDetails }),
                    React.createElement(Grid_1.default, { item: true, container: true, xs: 12, className: "lab-sample-images" },
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement("div", { className: "user-profile-wrapper" },
                                React.createElement("small", { className: "user-label" }, "Pickup Bag Photo"),
                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, picupImageDoc && pickupImageURL ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" },
                                    React.createElement(React.Fragment, null,
                                        React.createElement("img", { src: dataList.pickup_bag_photo, alt: "profile" }),
                                        React.createElement("div", { className: "download-btn" },
                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(dataList.pickup_bag_photo), download: true }))))) : ('NO DOCUMENT UPLOADED')))),
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement("div", { className: "user-profile-wrapper" },
                                React.createElement("small", { className: "user-label" }, "Lab Handover Photo"),
                                React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, labImageDoc && labImageURL ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" },
                                    React.createElement(React.Fragment, null,
                                        React.createElement("img", { src: dataList.lab_handover_photo, alt: "profile" }),
                                        React.createElement("div", { className: "download-btn" },
                                            React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(dataList.lab_handover_photo), download: true }))))) : ('NO DOCUMENT UPLOADED'))))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.lab_handover_to, value: dataList && dataList.lab_handover_to
                            ? dataList.lab_handover_to.substring(0, 30) +
                                "".concat(dataList.lab_handover_to.length > 30 ? '...' : '')
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.center_name, value: dataList && dataList.center_name
                            ? dataList.center_name.substring(0, 30) +
                                "".concat(dataList.center_name.length > 30 ? '...' : '')
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.center_code, value: dataList && dataList.center_code
                            ? dataList.center_code.substring(0, 30) +
                                "".concat(dataList.center_code.length > 30 ? '...' : '')
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.lab_number_count, value: dataList && dataList.lab_number_count
                            ? dataList.lab_number_count
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.tube_count, value: dataList && dataList.tube_count ? dataList.tube_count : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.bag_creation_time, value: dataList && dataList.bag_creation_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.bag_creation_time)
                            : emptyDetails }),
                    React.createElement(Grid_1.default, { item: true, xs: 6 },
                        React.createElement("div", { className: "user-profile-wrapper" },
                            React.createElement("small", { className: "user-label" }, "Hub Receiving Photo"),
                            React.createElement(Grid_1.default, { container: true, spacing: 0, className: "image-list", columns: { xs: 9 } }, hubImageDoc && hubImageURL ? (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image" },
                                React.createElement(React.Fragment, null,
                                    React.createElement("img", { src: dataList.hub_receiving_photo, alt: "profile" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: "".concat(dataList.hub_receiving_photo), download: true }))))) : ('NO DOCUMENT UPLOADED')))),
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 18 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng section-heaidng" },
                            React.createElement("h2", null, "Other Information Of The Bag"))),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.lab_code, value: dataList && dataList.lab_code ? dataList.lab_code : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.lab_name, value: dataList && dataList.lab_name ? dataList.lab_name : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.pickup_fe_code, value: dataList && dataList.pickup_fe_code
                            ? dataList.pickup_fe_code
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.pickup_fe_name, value: dataList && dataList.pickup_fe_name
                            ? dataList.pickup_fe_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.connector1_fe_code, value: dataList && dataList.connector_fe_code
                            ? dataList.connector_fe_code
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.connector1_fe_name, value: dataList && dataList.connector_fe_name
                            ? dataList.connector_fe_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.connector2_fe_code, value: dataList && dataList.connector2_fe_code
                            ? dataList.connector2_fe_code
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.connector2_fe_name, value: dataList && dataList.connector2_fe_name
                            ? dataList.connector2_fe_name
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.lab_state, value: dataList && dataList.lab_state ? dataList.lab_state : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.lab_city, value: dataList && dataList.lab_city ? dataList.lab_city : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.connector_fe_state, value: dataList && dataList.center_state ? dataList.center_state : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.connector_fe_city, value: dataList && dataList.center_city ? dataList.center_city : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.pickup_fe_state, value: dataList && dataList.pickup_fe_state
                            ? dataList.pickup_fe_state
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.pickup_fe_city, value: dataList && dataList.pickup_fe_city
                            ? dataList.pickup_fe_city
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.scheduled_lab_handover_time, value: dataList && dataList.scheduled_lab_handover_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.scheduled_lab_handover_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.actual_lab_handover_time, value: dataList && dataList.actual_lab_handover_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.actual_lab_handover_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.scheduled_hub_handover_time, value: dataList && dataList.scheduled_hub_handover_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.scheduled_hub_handover_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.actual_pickup_time, value: dataList && dataList.actual_pickup_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.actual_pickup_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.scheduled_pickup_time, value: dataList && dataList.scheduled_pickup_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.scheduled_pickup_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.actual_hub_handover_time, value: dataList && dataList.actual_hub_handover_time
                            ? (0, array_helpers_1.normaldateandTimeFormatte)(dataList.actual_hub_handover_time)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.pickup_coordinates, value: ((_a = dataList === null || dataList === void 0 ? void 0 : dataList.pickup_coordinates) === null || _a === void 0 ? void 0 : _a.latitude) &&
                            ((_b = dataList === null || dataList === void 0 ? void 0 : dataList.pickup_coordinates) === null || _b === void 0 ? void 0 : _b.longitude)
                            ?
                                "".concat((_c = dataList === null || dataList === void 0 ? void 0 : dataList.pickup_coordinates) === null || _c === void 0 ? void 0 : _c.latitude, ",").concat((_d = dataList === null || dataList === void 0 ? void 0 : dataList.pickup_coordinates) === null || _d === void 0 ? void 0 : _d.longitude)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.hub_handover_coordinates, value: ((_e = dataList === null || dataList === void 0 ? void 0 : dataList.hub_handover_coordinates) === null || _e === void 0 ? void 0 : _e.latitude) &&
                            ((_f = dataList === null || dataList === void 0 ? void 0 : dataList.hub_handover_coordinates) === null || _f === void 0 ? void 0 : _f.longitude)
                            ? "".concat((_g = dataList === null || dataList === void 0 ? void 0 : dataList.hub_handover_coordinates) === null || _g === void 0 ? void 0 : _g.latitude, ",").concat((_h = dataList === null || dataList === void 0 ? void 0 : dataList.hub_handover_coordinates) === null || _h === void 0 ? void 0 : _h.longitude)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.lab_handover_coordinates, value: ((_j = dataList === null || dataList === void 0 ? void 0 : dataList.lab_handover_coordinates) === null || _j === void 0 ? void 0 : _j.latitude) &&
                            ((_k = dataList === null || dataList === void 0 ? void 0 : dataList.lab_handover_coordinates) === null || _k === void 0 ? void 0 : _k.longitude)
                            ? "".concat((_l = dataList === null || dataList === void 0 ? void 0 : dataList.lab_handover_coordinates) === null || _l === void 0 ? void 0 : _l.latitude, ",").concat((_m = dataList === null || dataList === void 0 ? void 0 : dataList.lab_handover_coordinates) === null || _m === void 0 ? void 0 : _m.longitude)
                            : emptyDetails }),
                    React.createElement(profile_grid_1.default, { lableName: profile_detaile_lable_1.default.SAMPLE_BAG_DETAILS.route_type, value: dataList && dataList.route_type ? dataList.route_type : emptyDetails })))));
    };
    var SamplesTracking = function () {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "sample-details-wrapper accordian-container small-overflow-hide" },
                React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 } },
                    React.createElement(Grid_1.default, { className: "tab-heading-wrapper", item: true, xs: 12 },
                        React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                            React.createElement("h2", null,
                                " ",
                                profile_detaile_lable_1.default.SAMPLE_BAG_HEADINGS[3])))), (_a = dataList === null || dataList === void 0 ? void 0 : dataList.samples) === null || _a === void 0 ? void 0 :
                _a.map(function (e, i) {
                    console.log(i, 'iiiiii');
                    return (React.createElement(Accordion_1.default, { className: "custom-accordian" },
                        React.createElement("div", { className: "accordion-tab-wrapper " },
                            React.createElement("div", { className: "accordian-summary" },
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3, className: "image-item downloadable-image-sample" },
                                    React.createElement("img", { src: e === null || e === void 0 ? void 0 : e.image, alt: "profile" }),
                                    React.createElement("div", { className: "download-btn" },
                                        React.createElement("a", { className: "icon-Vector", target: "_blank", href: e === null || e === void 0 ? void 0 : e.image, download: true }))),
                                React.createElement("div", { className: "label-values-wrapper" },
                                    React.createElement("small", { className: "item-label" }, "Unique Tracking Id"),
                                    React.createElement("p", { className: "item-value" }, (e === null || e === void 0 ? void 0 : e.unique_tracking_id) ? e.unique_tracking_id : emptyDetails)),
                                React.createElement("div", { className: "label-values-wrapper" },
                                    React.createElement("small", { className: "item-label" }, "Lab Number"),
                                    React.createElement("p", { className: "item-value blue-text" }, (e === null || e === void 0 ? void 0 : e.lab_number) ? e.lab_number : emptyDetails)),
                                React.createElement("div", { className: "label-values-wrapper" },
                                    React.createElement("small", { className: "item-label" }, "Tube Color"),
                                    React.createElement("p", { className: "item-value" }, (e === null || e === void 0 ? void 0 : e.tube_color) ? e.tube_color : emptyDetails)),
                                React.createElement("div", { className: "accordion-btn", "aria-controls": "panel1a-content", id: "panel1a-header" },
                                    React.createElement(AccordionSummary_1.default, { className: "accordian-tab" },
                                        React.createElement("span", { className: "icon-right accordion-icon" })))),
                            React.createElement("div", { className: "accordion-item-details" },
                                React.createElement("div", { className: "bag-details-wrapper" },
                                    React.createElement("p", { className: "item-status" },
                                        "Current Status : ",
                                        React.createElement("span", null, e === null || e === void 0 ? void 0 : e.status),
                                        ' '),
                                    React.createElement("small", { className: "item-details" },
                                        (e === null || e === void 0 ? void 0 : e.status) === 'Handover Done'
                                            ? 'Bag handovered by '
                                            : 'Bag handovered to ', dataList === null || dataList === void 0 ? void 0 :
                                        dataList.pickup_fe_name,
                                        ", ", e === null || e === void 0 ? void 0 :
                                        e.bag_handed_over_to_id,
                                        ",",
                                        ' ', e === null || e === void 0 ? void 0 :
                                        e.bag_handed_over_to_type),
                                    React.createElement("small", { className: "item-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp))),
                                React.createElement("div", { className: "track-link" },
                                    React.createElement(button_component_1.default, { onClickHandler: function () { return handleDrawerOpen(e === null || e === void 0 ? void 0 : e.unique_tracking_id); }, buttonName: "Track", endIcon: "icon-right", color: "transparent btn-icon-right", isError: !Samplefeatures[menu_constants_1.FEATURE_CONSTANTS.bagJourney] })))),
                        React.createElement(AccordionDetails_1.default, { className: "custom-accordion-details" },
                            React.createElement(Grid_1.default, { container: true, spacing: 0, columns: { xs: 12 } },
                                React.createElement(profile_grid_1.default, { lableName: "Tube Name", value: e && e.tube_name ? e.tube_name : emptyDetails }),
                                React.createElement(profile_grid_1.default, { lableName: "Tube Code", value: e && e.tube_code ? e.tube_code : emptyDetails }),
                                React.createElement(profile_grid_1.default, { lableName: "Tube Colour Code", value: e && e.tube_colour_code ? e.tube_colour_code : emptyDetails }),
                                React.createElement(profile_grid_1.default, { lableName: "Tube Count", value: e && e.tube_count ? e.tube_count : emptyDetails }),
                                React.createElement(profile_grid_1.default, { lableName: "Registration Status at the time of Bag Creation", value: e && e.registration_status_at_scan
                                        ? e.registration_status_at_scan
                                        : emptyDetails }),
                                React.createElement(profile_grid_1.default, { lableName: "Current Registration Status", value: e && e.current_registration_status
                                        ? e.current_registration_status
                                        : emptyDetails }),
                                React.createElement(profile_grid_1.default, { lableName: "Registration Time", value: e && e.registration_timestamp
                                        ? (0, array_helpers_1.normaldateandTimeFormatte)(e.registration_timestamp)
                                        : emptyDetails })))));
                }))));
    };
    var TrackingBagDetails = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "drawer-content drawer-track-bag" },
                React.createElement("div", { className: "drawer-header" },
                    React.createElement("div", { className: "drawer-heading-wrapper" },
                        React.createElement("h2", null,
                            " ",
                            profile_detaile_lable_1.default.SAMPLE_BAG_HEADINGS[4]),
                        React.createElement("small", { className: "sub-heading" },
                            "BAG ID:",
                            dataList && dataList.bag_id ? dataList === null || dataList === void 0 ? void 0 : dataList.bag_id : emptyDetails)),
                    React.createElement("div", { onClick: handleDrawerClose, className: "drawer-close-btn" },
                        React.createElement("span", { className: "icon-close" }))),
                React.createElement("div", { className: "stepper-container timeline-wrapper" },
                    React.createElement(Timeline_1.default, { position: "right" }, bagDetails === null || bagDetails === void 0 ? void 0 : bagDetails.map(function (e) {
                        console.log(e, 'eee');
                        console.log('sample');
                        return (React.createElement(React.Fragment, null,
                            React.createElement(TimelineItem_1.default, null,
                                React.createElement(TimelineSeparator_1.default, null,
                                    React.createElement(TimelineDot_1.default, { className: "step-icon-wrapper " },
                                        React.createElement("span", { className: e.event_type === 'Created'
                                                ? 'icon-menu-sheet step-icon'
                                                : e.event_type === 'Master_box_created'
                                                    ? 'icon-menu-sheet step-icon'
                                                    :
                                                        e.event_type === 'Picked up at destination'
                                                            ? 'icon-Group-1 step-icon'
                                                            : e.event_type === 'Picked by FE'
                                                                ? 'icon-directions_bike step-icon'
                                                                : e.event_type === 'Handed over at lab'
                                                                    ? 'icon-Group-1 step-icon'
                                                                    : e.event_type === 'Picked by Connector'
                                                                        ? 'icon-directions_bike step-icon'
                                                                        : e.event_type === 'Bag Created'
                                                                            ? 'icon-Vector-25 step-icon'
                                                                            : e.event_type === 'Bag Created'
                                                                                ? 'icon-Vector-25 step-icon'
                                                                                : '' }),
                                        React.createElement("span", { className: e.event_type === 'Bag Created'
                                                ? 'icon-Vector-25 step-icon'
                                                : e.event_type === 'In transit'
                                                    ? 'icon-directions_bike step-icon'
                                                    : e.event_type === 'Handover Done'
                                                        ? 'icon-Group-1 step-icon'
                                                        : e.event_type === 'Bag Prepared'
                                                            ? 'icon-building step-icon'
                                                            : e.event_type === 'Bag Created'
                                                                ? 'icon-Vector-25 step-icon'
                                                                : e.event_type === 'Bag Created'
                                                                    ? 'icon-Vector-25 step-icon'
                                                                    : '' })),
                                    React.createElement(TimelineConnector_1.default, null)),
                                (e === null || e === void 0 ? void 0 : e.event_type) === 'Created' ? (React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" },
                                            ' ',
                                            e && e.event_type ? e.event_type.toUpperCase() : emptyDetails),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" },
                                                "Bag Created By:",
                                                ' ',
                                                e && e.created_by ? e.created_by.toUpperCase() : emptyDetails),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp))),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" }))))) : (React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" }, e && e.event_type ? e.event_type.toUpperCase() : emptyDetails),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" },
                                                "Bag Handed Over To",
                                                ' ',
                                                e && e.to_user_name ? e.to_user_name : emptyDetails,
                                                ",",
                                                ' ',
                                                e && e.to_user_type ? e.to_user_type : emptyDetails,
                                                ' ',
                                                e && e.to_user_id ? e.to_user_id : emptyDetails),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp))),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" },
                                                "Bag Received",
                                                ' ',
                                                e.event_type === 'Handed over at lab' ? 'By' : 'At',
                                                ' ',
                                                e && e.from_user_name ? e.from_user_name : emptyDetails,
                                                ",",
                                                ' ',
                                                e && e.from_user_type ? e.from_user_type : emptyDetails,
                                                ' ',
                                                e && e.from_user_id ? e.from_user_id : emptyDetails),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp)))))))));
                    }))))));
    };
    var SampleTrackingDetails = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "drawer-content drawer-track-bag" },
                React.createElement("div", { className: "drawer-header" },
                    React.createElement("div", { className: "drawer-heading-wrapper" },
                        React.createElement("h2", null,
                            " ",
                            profile_detaile_lable_1.default.SAMPLE_BAG_HEADINGS[5]),
                        React.createElement("small", { className: "sub-heading" },
                            "BAG ID:",
                            dataList && dataList.bag_id ? dataList.bag_id : emptyDetails)),
                    React.createElement("div", { onClick: trackingBaghandleDrawerClose, className: "drawer-close-btn" },
                        React.createElement("span", { className: "icon-close" }))),
                React.createElement("div", { className: "stepper-container timeline-wrapper" },
                    React.createElement(Timeline_1.default, { position: "right" }, sampleBagDetails === null || sampleBagDetails === void 0 ? void 0 : sampleBagDetails.map(function (e) {
                        console.log(e.event_type, 'eee');
                        console.log('bag');
                        return (React.createElement(React.Fragment, null,
                            React.createElement(TimelineItem_1.default, null,
                                React.createElement(TimelineSeparator_1.default, null,
                                    React.createElement(TimelineDot_1.default, { className: "step-icon-wrapper " },
                                        React.createElement("span", { className: e.event_type === 'Bag Created'
                                                ? 'icon-menu-sheet step-icon'
                                                : e.event_type === 'In transit'
                                                    ? 'icon-directions_bike step-icon'
                                                    : e.event_type === 'Handover Done'
                                                        ? 'icon-Group-1 step-icon'
                                                        : e.event_type === 'Hub Receiving Done'
                                                            ? 'icon-building step-icon'
                                                            : e.event_type === 'Received at Lab'
                                                                ? 'icon-Vector-25 step-icon'
                                                                : e.event_type === 'Registered'
                                                                    ? 'icon-Vector-25 step-icon'
                                                                    : '' })),
                                    React.createElement(TimelineConnector_1.default, null)),
                                e.event_type === 'Bag Created' ? (React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" },
                                            ' ',
                                            e && e.event_type ? e.event_type.toUpperCase() : emptyDetails),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" },
                                                "Bag Created By Id:",
                                                e && e.created_by_id ? e.created_by_id : emptyDetails,
                                                ", Name:",
                                                e && e.created_by_name ? e.created_by_name : emptyDetails,
                                                ", Type:",
                                                e && e.created_by_type ? e.created_by_type : emptyDetails,
                                                ' '),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp))),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" }))))) : (e.event_type === 'Registered' || e.event_type === 'Received at Lab') ? (React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" },
                                            ' ',
                                            e && e.event_type ? e.event_type.toUpperCase() : emptyDetails),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp)))))) : (React.createElement(TimelineContent_1.default, { className: "step-label-content" },
                                    React.createElement("div", { className: "step-content" },
                                        React.createElement("p", { className: "step-label" },
                                            ' ',
                                            e && e.event_type ? e.event_type.toUpperCase() : emptyDetails),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" },
                                                e.event_type === 'Handover Done'
                                                    ? 'Bag Handed Over At '
                                                    : 'Bag Handed Over By ',
                                                e && e.to_user_name ? e.to_user_name : emptyDetails,
                                                ",",
                                                ' ',
                                                e && e.to_user_type ? e.to_user_type : emptyDetails,
                                                ' ',
                                                e && e.to_user_id ? e.to_user_id : emptyDetails),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp))),
                                        React.createElement("div", { className: "step-details-wrapper" },
                                            React.createElement("p", { className: "step-desc" },
                                                "Bag Received ",
                                                e.event_type === 'Handover Done' ? 'By' : 'At',
                                                ' ',
                                                e && e.from_user_name ? e.from_user_name : emptyDetails,
                                                ",",
                                                ' ',
                                                e && e.from_user_type ? e.from_user_type : emptyDetails,
                                                ' ',
                                                e && e.from_user_id ? e.from_user_id : emptyDetails),
                                            React.createElement("p", { className: "step-time-stamp" }, (0, array_helpers_1.normaldateandTimeFormatte)(e === null || e === void 0 ? void 0 : e.timestamp)))))))));
                    }))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "page-wrapper" },
            React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom" },
                React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                    React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateToSampleTracking }),
                    React.createElement("h2", null, "Bag Details")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: "Track Bag", onClickHandler: trackingBaghandleDrawerOpen, color: "yellow-outline", isError: !Samplefeatures[menu_constants_1.FEATURE_CONSTANTS.sampleJourney] }),
                    React.createElement(button_component_1.default, { buttonName: "Download", color: "yellow-outline", isError: true }))),
            React.createElement("div", { className: "sample-tracking-wrapper scroll-xs" },
                React.createElement(Grid_1.default, { container: true, xs: 12, className: "details-tab-wrapper" },
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 }, React.createElement(BasicInformation, null)),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 }, React.createElement(SamplesTracking, null)),
                    React.createElement("div", { className: "drawer-container" },
                        React.createElement(Drawer_1.default, { variant: "temporary", anchor: "right", open: open, className: "track-bag-container" }, !trackBagopen ? React.createElement(SampleTrackingDetails, null) : React.createElement(TrackingBagDetails, null))))))));
};
exports.default = BasicDetials;
