"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var api_urls_1 = require("../../../../api/api_urls");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var vendor_api_service_1 = require("../api/vendor_api_service");
var RejectedVendors = function (_a) {
    var searchValue = _a.searchValue, selectedCities = _a.selectedCities, isFilterButton = _a.isFilterButton;
    var _b = React.useState(undefined), vendorRejectedList = _b[0], setExitedList = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var fetchRejectedVednors = function (endPoint, payLoad, domain) {
        (0, vendor_api_service_1.fetchVednors)(endPoint, payLoad, domain)
            .then(function (response) {
            setExitedList(response);
            setErrorMessage("");
        })
            .catch(function (err) {
            setErrorMessage(err);
            setExitedList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)('rejected-vendor', function () {
        var payload = (0, array_helpers_1.createPayloadForVendorList)(appConstants_1.VENDOR_STATUS.rejected, searchValue, selectedCities);
        fetchRejectedVednors(api_urls_1.api_urls.VEDNOR_LIST, payload, process.env.ONE_TWO_LEVEL_APPROVAL_URL);
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, [isFilterButton]);
    return (React.createElement("div", { className: 'unclickablerow' },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.VENDOR_EXIT_COLUMNS, rows: vendorRejectedList && vendorRejectedList, errorMessage: errorMessage, pagination: false, className: 'vendor-table' })));
};
exports.default = RejectedVendors;
