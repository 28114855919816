"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var CC_helper_1 = require("../../super_admin/CC/CC_helper");
var state_city_filter_1 = require("../../super_admin/sample_traking/state_city_filter");
var slotblocking_apicalls_1 = require("./slotblocking.apicalls");
var SlotBlocking = function () {
    var nav = (0, react_router_dom_1.useNavigate)();
    var _a = React.useState(undefined), dataList = _a[0], setDataList = _a[1];
    var _b = React.useState([]), filterStateCity = _b[0], setFilterStateCity = _b[1];
    var _c = React.useState(''), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = React.useState(''), errorMessage = _d[0], setErrorMessage = _d[1];
    var navigateToaddSlot = function () {
        nav("/".concat(route_path_1.default.PATHS.addSlot));
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.rosterManagement);
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var fe_typesList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.fe_types; });
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(1), pageCount = _f[0], setpageCount = _f[1];
    var _g = React.useState(appConstants_1.defaultPageLimit), PageLimit = _g[0], setPageLimit = _g[1];
    var _h = React.useState(0), totalCount = _h[0], setTotalCount = _h[1];
    var _j = React.useState(null), selectedLab = _j[0], setSelectedLab = _j[1];
    console.log('useNavigate', react_router_dom_1.useNavigate);
    var _k = React.useState({}), feParams = _k[0], setFeparams = _k[1];
    console.log('feParams', feParams);
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var labHandler = function (value) {
        console.log('dghs', value);
        setSelectedLab(value);
    };
    var stateCityHandler = function (event) {
        console.log('jhgdfjh', event);
        if (event.length >= 0) {
            setFilterStateCity(event);
        }
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    var refetch = (0, react_query_1.useQuery)(['slot-blocking', pageCount], function () {
        var payload = {};
        var currentPage = pageCount < 1 ? 1 : pageCount;
        if (filterStateCity.length > 0) {
            var data_1 = [];
            console.log('34filterStateCity', filterStateCity);
            filterStateCity.map(function (selectedCityObj) {
                data_1.push(selectedCityObj.short_code);
            });
            console.log('34filterStateCity', data_1);
            payload.city = data_1;
        }
        if (searchTerm.trim().length >= 3) {
            payload.search_string = searchTerm.toLocaleLowerCase().trim();
        }
        if (selectedLab) {
            payload.lab_code = selectedLab === null || selectedLab === void 0 ? void 0 : selectedLab.value;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payload.onboard_status = ['Active'];
        (0, slotblocking_apicalls_1.fetchFEList)(payload)
            .then(function (response) {
            console.log('fjhg', response);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setDataList(response.result);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setDataList([]);
        });
    }).refetch;
    var onRowClicked = function (event) {
        console.log("event.event.target.className.includes('icon-edit')", event.event.target.className);
        console.log('feParams', feParams);
        setFeparams(event.data);
        var FeType = fe_typesList.filter(function (each) {
            console.log('dfas', each);
            return each.id === (event === null || event === void 0 ? void 0 : event.data.role_id);
        });
        console.log('FeTyp123e', FeType);
        var feName = (0, array_helpers_1.concatFirstNameLastName)(event === null || event === void 0 ? void 0 : event.data.first_name, event === null || event === void 0 ? void 0 : event.data.last_name, event === null || event === void 0 ? void 0 : event.data.mobile);
        var feData = {
            id: event.data.id,
            state: (0, array_helpers_1.convertIntoIdLabel)(event.data.state),
            city: (0, array_helpers_1.convertIntoIdLabel)(event.data.city),
            role: FeType[0],
            feName: (0, array_helpers_1.convertIntoIdLabel)(feName),
            lab: (0, CC_helper_1.getSelectedLabDetils)(labList, event === null || event === void 0 ? void 0 : event.data.lab.lab_code),
        };
        if (features[menu_constants_1.FEATURE_CONSTANTS.addSlot]) {
            if (event.type === 'rowClicked' &&
                event.event.target.className.includes('icon-edit')) {
                nav("/".concat(route_path_1.default.PATHS.addSlot, "/").concat(route_path_1.default.PATHS.editslotFE), { state: feData });
            }
        }
        if (features[menu_constants_1.FEATURE_CONSTANTS.roasterManagemntListing]) {
            if (event.type === 'rowClicked' &&
                event.event.target.className.includes('icon-Vector')) {
                nav("/".concat(route_path_1.default.PATHS.addSlot, "/").concat(route_path_1.default.PATHS.ViewFeSlot), { state: feData });
            }
        }
    };
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    return (React.createElement("div", { className: "fe-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "FE Roster Management ")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Add Fe Working Hours", color: "yellow", onClickHandler: navigateToaddSlot, isError: !features[menu_constants_1.FEATURE_CONSTANTS.addSlot] }))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" },
                React.createElement(search_component_1.default, { placeholder: "Search FE Name / Mobile No", searchValue: searchTerm, searchHandler: searchingHandler })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "lab", placeholder: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList).sort(function (a, b) {
                        return a.label.localeCompare(b.label);
                    }), onChange: labHandler, value: selectedLab || null }),
                React.createElement(state_city_filter_1.default, { onChangeHandler: stateCityHandler }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "unclickablerow " },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.WORKINGHOURS_COLUMNS, rows: dataList, errorMessage: errorMessage, onRowClicked: features[menu_constants_1.FEATURE_CONSTANTS.roasterManagemntListing] &&
                    onRowClicked, pagination: false, className: 'fe-roster-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }))));
};
exports.default = SlotBlocking;
