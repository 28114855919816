"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Backdrop_1 = require("@mui/material/Backdrop");
var CircularProgress_1 = require("@mui/material/CircularProgress");
var React = require("react");
var react_redux_1 = require("react-redux");
var loader_style_1 = require("./loader_style");
function Loader(props) {
    var classes = (0, loader_style_1.default)(props);
    var showLoader = (0, react_redux_1.useSelector)(function (state) {
        return state.loader.showLoader;
    });
    return (React.createElement("div", { className: "loader-wrapper" },
        React.createElement(Backdrop_1.default, { className: classes.backdrop, open: showLoader },
            React.createElement(CircularProgress_1.default, { color: "inherit" }))));
}
exports.default = Loader;
