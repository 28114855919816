"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../../common/buttons/button_component");
var modal_component_1 = require("../../../common/modal/modal_component");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../constants/appConstants");
var button_names_1 = require("../../../constants/button_names");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var leavetracking_apicalls_1 = require("./leavetracking.apicalls");
var leavetracking_helper_1 = require("./leavetracking.helper");
var LeaveTrakingStatusbutton = function (_a) {
    var buttonName = _a.buttonName, selectedFeDetails = _a.selectedFeDetails, toDate = _a.toDate;
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.leaveManagement);
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var _b = React.useState(), comments = _b[0], setComments = _b[1];
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _c = React.useState(false), rejectLeave = _c[0], setRejectLeave = _c[1];
    var vendorId = (0, react_redux_1.useSelector)(function (state) { return state.user.vendorId; });
    var _d = React.useState(false), open = _d[0], setOpen = _d[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () {
        setOpen(false);
        setRejectLeave(false);
        setComments('');
    };
    var today = new Date();
    var formSubmitHandler = function (values) {
        if (!rejectLeave) {
            var payload = (0, leavetracking_helper_1.preparePayloadForApproveLeave)(selectedFeDetails, values, vendorId);
            (0, leavetracking_apicalls_1.submitApproveLeaveRequestForFE)(payload)
                .then(function (approveLeaveResponse) {
                if (approveLeaveResponse.statusCode === 200) {
                    react_hot_toast_1.default.success(approveLeaveResponse.message);
                    handleClose();
                    UpdateListData.setIsListReloaded(true);
                }
                else {
                    react_hot_toast_1.default.error(approveLeaveResponse.message);
                }
            })
                .catch(function (e) { return react_hot_toast_1.default.error(e.data.message); });
        }
        else {
            var payload = (0, leavetracking_helper_1.preparePayloadForRevokeLeave)(selectedFeDetails, 'REVOKED', values.comments);
            (0, leavetracking_apicalls_1.submitRevokeLeaveRequestForFE)(payload)
                .then(function (approveLeaveResponse) {
                if (approveLeaveResponse.statusCode === 200) {
                    react_hot_toast_1.default.success(approveLeaveResponse.message);
                    handleClose();
                    UpdateListData.setIsListReloaded(true);
                }
                else {
                    react_hot_toast_1.default.error(approveLeaveResponse.message);
                }
            })
                .catch(function (e) { return react_hot_toast_1.default.error(e.data.message); });
        }
    };
    var revokeRequest = function () {
        setRejectLeave(true);
        handleOpen();
    };
    return (React.createElement("div", { className: "dual-btns-leave" },
        React.createElement("div", { className: "payment-status-wrapper ".concat(pathname.split('/')[2] === route_path_1.default.PATHS.feOnLeave &&
                buttonName.toLocaleLowerCase() ===
                    button_names_1.STATUS_BUTTONS.approved.toLocaleLowerCase()
                ? ''
                : moment(toDate).format('YYYY-MM-DD') <
                    moment(today).format('YYYY-MM-DD') ||
                    buttonName.toLocaleLowerCase() ===
                        button_names_1.STATUS_BUTTONS.revoked.toLocaleLowerCase() ||
                    buttonName.toLocaleLowerCase() ===
                        button_names_1.STATUS_BUTTONS.approved.toLocaleLowerCase()
                    ? ' '
                    : 'dual-btn-toggle') },
            React.createElement("div", { className: "status-badge" },
                React.createElement("span", { className: "icon-emergency ".concat(buttonName.toLocaleLowerCase() ===
                        button_names_1.STATUS_BUTTONS.approved.toLocaleLowerCase()
                        ? 'paid'
                        : 'pending', " ") }),
                React.createElement("p", null, buttonName))),
        React.createElement("div", { className: "dual-btn-wrapper" },
            React.createElement(modal_component_1.default, { buttonColor: "yellow-outline", buttonName: "APPROVE", onClickHandler: handleOpen, open: open, isBtnDisabled: !features[menu_constants_1.FEATURE_CONSTANTS.leaveReject] },
                React.createElement(formik_1.Formik, { validateOnBlur: false, initialValues: {
                        fromDate: '',
                        toDate: '',
                        totalDays: '',
                        comments: '',
                        reason: '',
                    }, validationSchema: schema_1.schemas.FE_ApproveLeavesSchema, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                        React.createElement("div", { className: "modal-header header-wrapper" },
                            React.createElement("h2", { className: "heading" }, "".concat(rejectLeave ? 'Reject Leave of FE' : 'Approve leave of FE', " ").concat(selectedFeDetails.first_name, " ").concat(selectedFeDetails.last_name, " (").concat(selectedFeDetails.user_id, ")")),
                            React.createElement("p", { className: "sub-heading" }, "Leave details")),
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { label: "fromDate", value: moment(selectedFeDetails.from_date).format('DD/MM/YYYY'), isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { label: "toDate", value: moment(selectedFeDetails.to_date).format('DD/MM/YYYY'), isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { name: "totalDays", isDisabled: true, value: selectedFeDetails.total_days, label: "Total Days" })),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { name: "reason", placeholder: "Reason", label: "Reason", value: selectedFeDetails.reason, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 12 },
                                React.createElement(text_filed_component_1.default, { label: "Other Comments", required: true, name: "comments", onChange: function (val) {
                                        console.log('val:', val);
                                        setComments(val);
                                        setFieldValue('comments', val);
                                        setFieldTouched('comments', true);
                                    }, value: comments }),
                                errors['comments'] && touched['comments'] ? (React.createElement("div", { className: "error" }, errors['comments'])) : null)),
                        console.log(errors, 'dddddddddddddddddd'),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { onClickHandler: handleClose, buttonName: "Cancel", color: "yellow-outline" }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
                })),
            React.createElement(button_component_1.default, { color: "yellow-outline", buttonName: "REJECT", onClickHandler: revokeRequest, isError: !features[menu_constants_1.FEATURE_CONSTANTS.leaveReject] }))));
};
exports.default = LeaveTrakingStatusbutton;
