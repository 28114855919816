"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var NetworkError = function () {
    var clearTime;
    React.useEffect(function () {
        if (clearTime) {
            clearTimeout(clearTime);
            return;
        }
        clearTime = function () {
            setTimeout(function () {
                if (!navigator.onLine) {
                    return react_hot_toast_1.default.error('You are offline');
                }
            }, 5000);
        };
        window.addEventListener('offline', clearTime);
        return function () {
            window.removeEventListener('offline', clearTime);
        };
    }, []);
    return React.createElement(React.Fragment, null);
};
exports.default = NetworkError;
