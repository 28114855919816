"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var button_component_1 = require("../buttons/button_component");
var DeleteConfirmationPopup = function (_a) {
    var deleteConfirModal = _a.deleteConfirModal, deleteOpeneHandlere = _a.deleteOpeneHandlere, deleteCloseHandlere = _a.deleteCloseHandlere, mainTitle = _a.mainTitle, subTitle = _a.subTitle;
    return React.createElement(material_1.Modal, { open: deleteConfirModal },
        React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                React.createElement("h2", null, mainTitle)),
            React.createElement("div", { className: "modal-body" },
                React.createElement("div", { className: "modal-message" }, subTitle)),
            React.createElement("div", { className: "modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return deleteCloseHandlere(false); } }),
                React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow", onClickHandler: function () { return deleteOpeneHandlere(false); } }))));
};
exports.default = DeleteConfirmationPopup;
