"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Drawer_1 = require("@mui/material/Drawer");
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var react_toastify_1 = require("react-toastify");
var universal_cookie_1 = require("universal-cookie");
var user_action_1 = require("../../actions/user_action");
var api_service_1 = require("../../api/api_service");
var button_component_1 = require("../../common/buttons/button_component");
var iconButton_component_1 = require("../../common/buttons/iconButton_component");
var drop_down_component_1 = require("../../common/drop_down/drop_down_component");
var search_component_1 = require("../../common/search/search_component");
var route_path_1 = require("../../constants/route_path");
require("./dash-board.scss");
var Dashboard = function () {
    var nav = (0, react_router_1.useNavigate)();
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var pathVariables = (0, react_router_1.useParams)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var cookies = new universal_cookie_1.default();
    var handleDrawerOpen = function () {
        setOpen(true);
        console.log(open);
    };
    var handleDrawerClose = function () {
        setOpen(false);
    };
    React.useEffect(function () {
        if (location.pathname.includes(''.concat(route_path_1.default.PATHS.SSO_LOGIN)) &&
            pathVariables['*']) {
            api_service_1.api_service
                .post({
                endPoint: api_service_1.api_service.api_urls.LOGOUT,
                showLoader: true,
                domain: process.env.AUTHENTICATION_SERVER_URL,
            })
                .then(function (res) {
                if (res.data.status === 200) {
                    dispatch(user_action_1.default.setLogout());
                    cookies.remove('cookies_jwt_token');
                    cookies.remove('cookies_refresh_token');
                    nav('/sso/'.concat(pathVariables['*']));
                }
            })
                .catch(function (err) {
                react_toastify_1.toast.error(err.message);
            });
        }
    }, []);
    return (React.createElement("div", { className: "dash-board-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Overview")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { type: "button", buttonName: "Download", color: "yellow-outline", endIcon: "icon-import download-icon" }),
                React.createElement(button_component_1.default, { type: "button", buttonName: "Add Gadget", color: "yellow", onClickHandler: handleDrawerOpen }))),
        React.createElement("div", { className: "scrollable-content overview-content-wrapper" },
            React.createElement("div", { className: "overview-content" },
                React.createElement(Grid_1.default, { container: true, columnSpacing: 3, columnGap: { xs: 2, lg: 0 }, columns: { xs: 12, lg: 12 }, className: "dash-board-cards" },
                    React.createElement(Grid_1.default, { item: true, lg: 6, xs: 12 },
                        React.createElement("div", { className: "dash-board-card" },
                            React.createElement("div", { className: "dashboard-card-header" },
                                React.createElement("div", { className: "heading-wrapper" },
                                    React.createElement("h2", { className: "heading" }, "FE Payments"),
                                    React.createElement("span", { className: "sub-heading" }, "Last updated: 12/07/2022 3:30 PM")),
                                React.createElement("div", { className: "dashboard-filters displayflex" },
                                    React.createElement(drop_down_component_1.default, { placeholder: "Monthly", dropDownList: [{ label: 'dummy' }] }),
                                    React.createElement("div", { className: "control-buttons displayflex" },
                                        React.createElement(iconButton_component_1.default, { className: "squar-btn icon-btn", icon: "icon-refresh" }),
                                        React.createElement("div", { className: "list-view-toggler displayflex" },
                                            React.createElement(iconButton_component_1.default, { className: "squar-btn", icon: "icon-List" }),
                                            React.createElement(iconButton_component_1.default, { className: "squar-btn", icon: "icon-chart" })),
                                        React.createElement(iconButton_component_1.default, { className: "squar-btn", icon: "icon-resize" }),
                                        React.createElement(iconButton_component_1.default, { className: "squar-btn", icon: "icon-menu" })))),
                            React.createElement("div", { className: "card-content" },
                                React.createElement("div", { className: "chart-container" },
                                    React.createElement("div", { className: "donut-chart" },
                                        React.createElement("img", { src: require('../../../assets/images/donut-placeholder.jpg'), alt: "chart" })),
                                    React.createElement("div", { className: "chart-desc" },
                                        React.createElement("div", { className: "chart-items-desc" },
                                            React.createElement("div", { className: "percentage-wrapper text-red" }, " 66%"),
                                            React.createElement("div", { className: "value-label-conatiner" },
                                                React.createElement("small", { className: "basic-card-lable" }, "Total Net Pay"),
                                                React.createElement("p", { className: "basic-card-value" }, "\u20B9 1,24,000.00"))),
                                        React.createElement("div", { className: "chart-items-desc" },
                                            React.createElement("div", { className: "percentage-wrapper text-green" }, "20%"),
                                            React.createElement("div", { className: "value-label-conatiner" },
                                                React.createElement("small", { className: "basic-card-lable" }, "Deductions"),
                                                React.createElement("p", { className: "basic-card-value" }, "\u20B94,000.00"))),
                                        React.createElement("div", { className: "chart-items-desc" },
                                            React.createElement("div", { className: "percentage-wrapper text-blue" }, "14%"),
                                            React.createElement("div", { className: "value-label-conatiner" },
                                                React.createElement("small", { className: "basic-card-lable" }, "Incentives"),
                                                React.createElement("p", { className: "basic-card-value" }, "\u20B94,000.00")))))))),
                    React.createElement(Grid_1.default, { item: true, lg: 6, xs: 12 },
                        React.createElement("div", { className: "dash-board-card" },
                            React.createElement("div", { className: "dashboard-card-header" },
                                React.createElement("div", { className: "heading-wrapper" },
                                    React.createElement("h2", { className: "heading" }, "FE Payments"),
                                    React.createElement("span", { className: "sub-heading" }, "Last updated: 12/07/2022 3:30 PM")),
                                React.createElement("div", { className: "dashboard-filters displayflex" },
                                    React.createElement(drop_down_component_1.default, { placeholder: "Monthly", dropDownList: [{ label: 'dummy' }] }),
                                    React.createElement("div", { className: "control-buttons displayflex" },
                                        React.createElement(iconButton_component_1.default, { className: "squar-btn icon-btn", icon: "icon-refresh" }),
                                        React.createElement("div", { className: "list-view-toggler displayflex" },
                                            React.createElement(iconButton_component_1.default, { className: "squar-btn", icon: "icon-List" }),
                                            React.createElement(iconButton_component_1.default, { className: "squar-btn", icon: "icon-chart" })),
                                        React.createElement(iconButton_component_1.default, { className: "squar-btn", icon: "icon-resize" }),
                                        React.createElement(iconButton_component_1.default, { className: "squar-btn", icon: "icon-menu" })))),
                            React.createElement("div", { className: "card-content" },
                                React.createElement("div", { className: "chart-container" },
                                    React.createElement("div", { className: "donut-chart" },
                                        React.createElement("img", { src: require('../../../assets/images/donut-placeholder.jpg'), alt: "chart" })),
                                    React.createElement("div", { className: "chart-desc" },
                                        React.createElement("div", { className: "chart-items-desc" },
                                            React.createElement("div", { className: "percentage-wrapper text-red" }, " 66%"),
                                            React.createElement("div", { className: "value-label-conatiner" },
                                                React.createElement("small", { className: "basic-card-lable" }, "Total Net Pay"),
                                                React.createElement("p", { className: "basic-card-value" }, "\u20B9 1,24,000.00"))),
                                        React.createElement("div", { className: "chart-items-desc" },
                                            React.createElement("div", { className: "percentage-wrapper text-green" }, "20%"),
                                            React.createElement("div", { className: "value-label-conatiner" },
                                                React.createElement("small", { className: "basic-card-lable" }, "Deductions"),
                                                React.createElement("p", { className: "basic-card-value" }, "\u20B94,000.00"))),
                                        React.createElement("div", { className: "chart-items-desc" },
                                            React.createElement("div", { className: "percentage-wrapper text-blue" }, "14%"),
                                            React.createElement("div", { className: "value-label-conatiner" },
                                                React.createElement("small", { className: "basic-card-lable" }, "Incentives"),
                                                React.createElement("p", { className: "basic-card-value" }, "\u20B94,000.00"))))))))))),
        React.createElement("div", { className: "create-gadget-wrapper" },
            React.createElement(Drawer_1.default, { variant: "temporary", anchor: "right", open: open, className: "add-gadget-container" },
                React.createElement("div", { className: "drawer-content" },
                    React.createElement("div", { className: "drawer-header" },
                        React.createElement("h2", { className: "heading" }, "Add Gadget"),
                        React.createElement("div", { onClick: handleDrawerClose, className: "drawer-close-btn" },
                            React.createElement("span", { className: "icon-cross" }))),
                    React.createElement("div", { className: "drawer-body" },
                        React.createElement("div", { className: "drawer-tools" },
                            React.createElement(search_component_1.default, { placeholder: "Search by gadget" }),
                            React.createElement(drop_down_component_1.default, { placeholder: "Category", dropDownList: [{ label: 'dummy' }] })),
                        React.createElement("div", { className: "gadget-cards-wrapper" },
                            React.createElement("div", { className: "gadget-card" },
                                React.createElement("div", { className: "heading-wrapper" },
                                    React.createElement("h2", { className: "basic-card-value heading" }, "Gadget Name"),
                                    React.createElement("small", { className: "basic-card-label sub-heading" }, "Gadget Name")),
                                React.createElement(button_component_1.default, { type: "button", buttonName: "ADD", color: "yellow" })),
                            React.createElement("div", { className: "gadget-card" },
                                React.createElement("div", { className: "heading-wrapper" },
                                    React.createElement("h2", { className: "basic-card-value heading" }, "Gadget Name"),
                                    React.createElement("small", { className: "basic-card-label sub-heading" }, "Gadget Name")),
                                React.createElement(button_component_1.default, { type: "button", buttonName: "ADD", color: "yellow" })))))))));
};
exports.default = Dashboard;
