"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var styles_1 = require("@mui/styles");
var useStyles = (0, styles_1.makeStyles)(function (theme) {
    return (0, material_1.createStyles)({
        '@global': {
            '*::-webkit-scrollbar': {
                width: '7px',
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#0054a5',
                borderRadius: '2px',
            },
        },
        backdrop: {
            zIndex: 99999,
            color: '#fece43',
            opacity: 0.7,
        },
        button: {
            marginTop: 100,
        },
    });
});
exports.default = useStyles;
