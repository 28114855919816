"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var button_component_1 = require("./button_component");
var EditIcon = function (_a) {
    var size = _a.size, icon = _a.icon, color = _a.color, isEditVendor = _a.isEditVendor, style = _a.style, user_id = _a.user_id, endPoint = _a.endPoint, domain = _a.domain, deleteRowHandler = _a.deleteRowHandler, editRowHandler = _a.editRowHandler, cloneRowHandler = _a.cloneRowHandler, audit_id = _a.audit_id, name = _a.name, status = _a.status;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn", style: { cursor: 'not-allowed' } },
            React.createElement("span", { className: "icon-edit" })),
        React.createElement(material_1.Modal, { open: open },
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                    React.createElement("h2", null, "Eidt data")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, "Edit data")),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose }))))));
};
exports.default = EditIcon;
