"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid/Grid");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var config_action_1 = require("../../../actions/config_action");
var FE_Creation_action_1 = require("../../../actions/FE_Creation_action");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var checkbox_component_1 = require("../../../common/checkbox/checkbox_component");
var dropdownFields_1 = require("../../../constants/dropdownFields");
var FECreationTabConstant_1 = require("../../../constants/FECreationTabConstant");
var formFieldConstants_1 = require("../../../constants/formFieldConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var generic_api_calls_1 = require("../../generic_api_calls");
var lodash_1 = require("./../../../utils/lodash");
var FE_ContactDetailsForm1 = function (_a) {
    var initialValues = _a.initialValues, saveFormData = _a.saveFormData, fepersonalDetails = _a.fepersonalDetails, updateValue = _a.updateValue, value = _a.value, FE_ContactDetailsInitialState = _a.FE_ContactDetailsInitialState, sendItForApproval = _a.sendItForApproval, onBoardingStatus = _a.onBoardingStatus, FEIdToBeReplaced = _a.FEIdToBeReplaced, vendorCode = _a.vendorCode;
    var user_id = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer; }).user_id;
    console.log('initial values inside contact details form:', initialValues);
    var _b = React.useState(false), isCheckboxSelected = _b[0], setIsCheckboxSelected = _b[1];
    var _c = React.useState(''), pincodeError = _c[0], setPincodeError = _c[1];
    var _d = React.useState(''), permanentPincodeError = _d[0], setPermanentPincodeError = _d[1];
    console.log(FEIdToBeReplaced, 'FEIdToBeReplaced');
    var nav = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var editUserId = (0, react_router_dom_1.useParams)().id;
    var checkboxChangeHandler = function (_a) {
        var values = _a.values, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, errors = _a.errors, touched = _a.touched, setTouched = _a.setTouched, setValues = _a.setValues, e = _a.e;
        var CheckBoxChecked = e.target.checked;
        console.log(CheckBoxChecked, 'isCheckBoxChecked');
        setIsCheckboxSelected(CheckBoxChecked);
        setTouched({
            permenentAddresslane1: CheckBoxChecked,
            permenentAddresslane2: CheckBoxChecked,
            permenentLandmark: CheckBoxChecked,
            permenentState: CheckBoxChecked,
            permenentCity: CheckBoxChecked,
            permenentCountry: CheckBoxChecked,
            permenentPincode: CheckBoxChecked,
            permenentProofOfAddress: CheckBoxChecked,
            permanentProofOfAddressType: CheckBoxChecked,
        }, false);
        setPermanentPincodeError('');
        var valuesObj = lodash_1.default.extend({}, values, {
            permenentAddresslane1: CheckBoxChecked ? values.addresslane1 : '',
            permenentAddresslane2: CheckBoxChecked ? values.addresslane2 : '',
            permenentLandmark: CheckBoxChecked ? values.landmark : '',
            permenentState: CheckBoxChecked ? values.state : '',
            permenentCity: CheckBoxChecked ? values.city : '',
            permenentCountry: CheckBoxChecked ? values.country : '',
            permenentPincode: CheckBoxChecked ? values.pincode : '',
            permenentProofOfAddress: CheckBoxChecked ? values.proofOfAddress : '',
            permanentProofOfAddressType: CheckBoxChecked
                ? values.addressProofType
                : '',
        });
        console.log(values);
        setValues(valuesObj, 'valuesObj');
    };
    var uploadFile = function (val) {
        console.log('inside fileUpload', val);
        var payload = new FormData();
        payload.append('cc_id', vendorCode);
        payload.append('file', val);
        payload.append('file_type', 'Other');
        return (0, generic_api_calls_1.fileUpload)(payload);
    };
    var renderInputFiled = function (field, values, setFieldValue, setFieldTouched, errors, touched, setFieldError) {
        console.log('errors & touched', errors, touched);
        if (!dropdownFields_1.dropdownFields.includes(field.name)) {
            return (React.createElement(React.Fragment, null,
                React.createElement(field.inputComponent, { name: field.name, className: " text-caps", type: field.name === 'uploadPhoto'
                        ? 'file'
                        : field.name === 'DateOfBirth'
                            ? 'date'
                            : 'text', buttonName: field.label, placeholder: field.placeholder, required: field.required, label: field.label, swicthLabel: field.swicthLabel, value: values[field.fieldValue], defaultVal: field.defaultVal, isPastDisable: field.isPastDisable, isFutureDisable: field.isFutureDisable, isDisabled: field.name === 'proofOfAddress'
                        ? values['addressProofType'] === null
                        : field.name === 'permenentProofOfAddress'
                            ? values['permanentProofOfAddressType'] === null
                            : field.isDisabled, require: field.require, acceptedFiletypes: field.acceptedFiletypes, maxSize: field.maxSize, message: field.message, onKeyPress: function (e) {
                        if (field.name === 'pincode') {
                            if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                e.target.value.length <= 5) {
                            }
                            else {
                                e.preventDefault();
                            }
                        }
                        if (field.name === 'permenentPincode') {
                            if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                e.target.value.length <= 5) {
                            }
                            else {
                                e.preventDefault();
                            }
                        }
                    }, onChange: function (val) {
                        console.log('val:', val);
                        setIsCheckboxSelected(false);
                        if (field.type === 'fileupload') {
                            console.log('val:', val);
                            setFieldValue(field.fieldValue, val);
                            setFieldTouched(field.fieldValue, true);
                        }
                        else {
                            setFieldValue(field.fieldValue, val);
                            setFieldTouched(field.fieldValue, true);
                            if (field.name === 'pincode' && values.state != null) {
                                if (val.length === 6) {
                                    var validationPayload = {
                                        state_id: values.state.id,
                                        pincode: parseInt(val),
                                    };
                                    (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                                        if (res.data.statusCode === 200 &&
                                            res.data.message != 'Success') {
                                            setPincodeError(res.data.message);
                                        }
                                        else {
                                            setPincodeError('');
                                        }
                                    });
                                }
                                else {
                                    setPincodeError('');
                                }
                            }
                            if (field.name === 'permenentPincode' &&
                                values.permenentState != null) {
                                if (val.length === 6) {
                                    var validationPayload = {
                                        state_id: values.permenentState.id,
                                        pincode: parseInt(val),
                                    };
                                    (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                                        if (res.data.statusCode === 200 &&
                                            res.data.message != 'Success') {
                                            setPermanentPincodeError(res.data.message);
                                        }
                                        else {
                                            setPermanentPincodeError('');
                                        }
                                    });
                                }
                                else {
                                    setPermanentPincodeError('');
                                }
                            }
                        }
                    } }),
                errors[field.fieldValue] && touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue])) : null,
                field.name === 'pincode' && pincodeError ? (React.createElement("div", { className: "error" }, pincodeError)) : null,
                field.name === 'permenentPincode' && permanentPincodeError ? (React.createElement("div", { className: "error" }, permanentPincodeError)) : null));
        }
        else {
            var stateList_1 = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.stateList; });
            var countryList_1 = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.countryList; });
            var _a = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), presentCityList_1 = _a.presentCityList, permenentCityList_1 = _a.permenentCityList;
            var addressProofType_1 = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.addressProofType; });
            var dropdownLists = function (fieldName) {
                switch (fieldName) {
                    case 'state':
                        return stateList_1;
                        break;
                    case 'permenentState':
                        return stateList_1;
                        break;
                    case 'city':
                        return presentCityList_1;
                        break;
                    case 'permenentCity':
                        return permenentCityList_1;
                        break;
                    case 'addressProofType':
                        return addressProofType_1;
                        break;
                    case 'permanentProofOfAddressType':
                        return addressProofType_1;
                        break;
                    default:
                        return countryList_1;
                }
            };
            console.log(addressProofType_1, 'addressProofType---------');
            return (React.createElement(React.Fragment, null,
                React.createElement(field.inputComponent, { name: field.name, key: dropdownLists(field.name), type: "text", dropDownList: dropdownLists(field.name), placeholder: field.placeholder, swicthLabel: field.swicthLabel, dateState: field.dateState, required: field.required, label: field.label, value: values[field.fieldValue], defaultVal: field.defaultVal, isDisabled: field.isDisabled, onChange: function (val) {
                        console.log('selected value:', val);
                        setIsCheckboxSelected(false);
                        setFieldValue(field.fieldValue, val);
                        setFieldTouched(field.fieldValue, true);
                        if (field.name === 'state') {
                            setFieldValue('city', '');
                            setFieldValue('pincode', '');
                            setFieldTouched('pincode', true);
                            if (val != null && val != '' && val != undefined) {
                                (0, generic_api_calls_1.fetchCitiesUnderStates)([val.id]).then(function (res) {
                                    console.log('response inside:', res);
                                    dispatch((0, config_action_1.fetch_cities)(res));
                                });
                            }
                            else {
                                dispatch((0, config_action_1.fetch_cities)([]));
                            }
                        }
                        if (field.name === 'permenentState') {
                            setFieldValue('permenentCity', '');
                            setFieldValue('permenentPincode', '');
                            setFieldTouched('permenentPincode', true);
                            if (val != null && val != '' && val != undefined) {
                                (0, generic_api_calls_1.fetchCitiesUnderStates)([val.id]).then(function (res) {
                                    dispatch((0, config_action_1.fetch_permanentCities)(res));
                                });
                            }
                            else {
                                dispatch((0, config_action_1.fetch_permanentCities)([]));
                            }
                        }
                        if (field.name === 'city') {
                            setFieldValue('pincode', '');
                            setFieldTouched('pincode', true);
                        }
                        if (field.name === 'permenentCity') {
                            setFieldValue('permenentPincode', '');
                            setFieldTouched('permenentPincode', true);
                        }
                    } }),
                errors[field.fieldValue] && touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue])) : null));
        }
    };
    var formSubmitHnadler1 = function (values, setErrors, setSubmitting, setFieldError) { return __awaiter(void 0, void 0, void 0, function () {
        var validationPayload, validationPayload, payload, personalDetailsObj, contactDetailsarr, permanentAddressObj_1, presentAddressObj_1, validationPayload, validationPayload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('values inside contactDetails form:', values, fepersonalDetails);
                    if (lodash_1.default.isEqual(values, initialValues)) {
                        updateValue(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS);
                        if (location.pathname.includes('edit')) {
                            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS));
                        }
                        else {
                            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS));
                        }
                        return [2];
                    }
                    if (!(onBoardingStatus === 1)) return [3, 5];
                    if (!(values.pincode && values.state)) return [3, 2];
                    validationPayload = {
                        state_id: values.state.id,
                        pincode: parseInt(values.pincode),
                    };
                    return [4, (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                            if (res.data.statusCode === 200 && res.data.message != 'Success') {
                                setPincodeError(res.data.message);
                                setSubmitting(false);
                                return false;
                            }
                            else {
                                setPincodeError('');
                            }
                            return true;
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (!(values.permenentPincode && values.permenentState)) return [3, 4];
                    validationPayload = {
                        state_id: values.permenentState.id,
                        pincode: parseInt(values.permenentPincode),
                    };
                    return [4, (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                            if (res.data.statusCode === 200 && res.data.message != 'Success') {
                                setPermanentPincodeError(res.data.message);
                                setSubmitting(false);
                                return false;
                            }
                            else {
                                setPermanentPincodeError('');
                            }
                            return true;
                        })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    if (pincodeError || permanentPincodeError) {
                        return [2];
                    }
                    saveFormData(values, false);
                    return [3, 16];
                case 5:
                    payload = {};
                    personalDetailsObj = {};
                    personalDetailsObj['first_name'] = fepersonalDetails.firstName
                        .trim()
                        .toUpperCase();
                    personalDetailsObj['last_name'] = fepersonalDetails.lastName
                        .trim()
                        .toUpperCase();
                    personalDetailsObj['dob'] =
                        fepersonalDetails.dob != '' &&
                            fepersonalDetails.dob != null &&
                            fepersonalDetails.dob != undefined
                            ? moment(fepersonalDetails.dob).format('YYYY-MM-DD')
                            : '';
                    personalDetailsObj['mobile'] = fepersonalDetails.mobileNumber;
                    personalDetailsObj['email'] = fepersonalDetails.email
                        .trim()
                        .toUpperCase();
                    personalDetailsObj['gender'] =
                        fepersonalDetails.gender != null ? fepersonalDetails.gender.label : '';
                    personalDetailsObj['avatar'] = fepersonalDetails.uploadPhoto;
                    personalDetailsObj['emergency_mobile'] =
                        fepersonalDetails.emergencyContactNo;
                    personalDetailsObj['reference_name1'] =
                        fepersonalDetails.referenceContactName1.trim().toUpperCase();
                    personalDetailsObj['reference_name2'] =
                        fepersonalDetails.referenceContactName2.trim().toUpperCase();
                    personalDetailsObj['reference_mobile1'] =
                        fepersonalDetails.referenceMobileNumber1;
                    personalDetailsObj['reference_mobile2'] =
                        fepersonalDetails.referenceMobileNumber2;
                    contactDetailsarr = [];
                    permanentAddressObj_1 = {
                        address_type: 'permanent',
                    };
                    presentAddressObj_1 = {
                        address_type: 'present',
                    };
                    presentAddressObj_1['lane1'] = values.addresslane1.trim().toUpperCase();
                    presentAddressObj_1['lane2'] = values.addresslane2.trim().toUpperCase();
                    presentAddressObj_1['landmark'] = values.landmark.trim().toUpperCase();
                    presentAddressObj_1['state_id'] =
                        values.state != null ? values.state.id : '';
                    presentAddressObj_1['city_id'] = values.city != null ? values.city.id : '';
                    presentAddressObj_1['country_id'] =
                        values.country != null ? values.country.id : '';
                    if (!(values.addressProofType && values.proofOfAddress)) return [3, 8];
                    if (!(typeof values.proofOfAddress === 'object')) return [3, 7];
                    return [4, uploadFile(values.proofOfAddress).then(function (res) {
                            if (res.data.statusCode === 200) {
                                presentAddressObj_1['proof_of_doc_type'] =
                                    values.addressProofType.label;
                                presentAddressObj_1['proof_of_doc'] = res.data.data.name;
                            }
                        })];
                case 6:
                    _a.sent();
                    return [3, 8];
                case 7:
                    presentAddressObj_1['proof_of_doc_type'] =
                        values.addressProofType.label;
                    presentAddressObj_1['proof_of_doc'] = values.proofOfAddress;
                    _a.label = 8;
                case 8:
                    if (!(values.pincode && values.state)) return [3, 10];
                    validationPayload = {
                        state_id: values.state.id,
                        pincode: parseInt(values.pincode),
                    };
                    return [4, (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                            if (res.data.statusCode === 200 && res.data.message != 'Success') {
                                setPincodeError(res.data.message);
                                setSubmitting(false);
                                return false;
                            }
                            else {
                                setPincodeError('');
                            }
                            return true;
                        })];
                case 9:
                    _a.sent();
                    _a.label = 10;
                case 10:
                    presentAddressObj_1['pincode'] = parseInt(values.pincode);
                    contactDetailsarr.push(presentAddressObj_1);
                    permanentAddressObj_1['lane1'] = values.permenentAddresslane1
                        .trim()
                        .toUpperCase();
                    permanentAddressObj_1['lane2'] = values.permenentAddresslane2
                        .trim()
                        .toUpperCase();
                    permanentAddressObj_1['landmark'] = values.permenentLandmark
                        .trim()
                        .toUpperCase();
                    permanentAddressObj_1['state_id'] =
                        values.permenentState != null ? values.permenentState.id : '';
                    if (!(values.permanentProofOfAddressType &&
                        values.permenentProofOfAddress)) return [3, 13];
                    if (!(typeof values.permenentProofOfAddress === 'object')) return [3, 12];
                    return [4, uploadFile(values.permenentProofOfAddress).then(function (res) {
                            if (res.data.statusCode === 200) {
                                permanentAddressObj_1['proof_of_doc_type'] =
                                    values.permanentProofOfAddressType.label;
                                permanentAddressObj_1['proof_of_doc'] = res.data.data.name;
                            }
                        })];
                case 11:
                    _a.sent();
                    return [3, 13];
                case 12:
                    permanentAddressObj_1['proof_of_doc_type'] =
                        values.permanentProofOfAddressType.label;
                    permanentAddressObj_1['proof_of_doc'] = values.permenentProofOfAddress;
                    _a.label = 13;
                case 13:
                    permanentAddressObj_1['city_id'] =
                        values.permenentCity != null ? values.permenentCity.id : '';
                    permanentAddressObj_1['country_id'] =
                        values.permenentCountry != null ? values.permenentCountry.id : '';
                    if (!(values.permenentPincode && values.permenentState)) return [3, 15];
                    validationPayload = {
                        state_id: values.permenentState.id,
                        pincode: parseInt(values.permenentPincode),
                    };
                    return [4, (0, generic_api_calls_1.validatePincode)(validationPayload).then(function (res) {
                            if (res.data.statusCode === 200 && res.data.message != 'Success') {
                                setPermanentPincodeError(res.data.message);
                                setSubmitting(false);
                                return false;
                            }
                            else {
                                setPermanentPincodeError('');
                            }
                            return true;
                        })];
                case 14:
                    _a.sent();
                    _a.label = 15;
                case 15:
                    if (pincodeError || permanentPincodeError) {
                        return [2];
                    }
                    permanentAddressObj_1['pincode'] = parseInt(values.permenentPincode);
                    contactDetailsarr.push(permanentAddressObj_1);
                    if (FEIdToBeReplaced) {
                        payload['personal_details'] = personalDetailsObj;
                        payload['contact_details'] = contactDetailsarr;
                        payload['is_final_submit'] = false;
                        payload['user_id'] = user_id;
                        payload['is_replacement'] = true;
                        payload['replacement_fe_id'] = parseInt(FEIdToBeReplaced);
                    }
                    else {
                        payload['personal_details'] = personalDetailsObj;
                        payload['contact_details'] = contactDetailsarr;
                        payload['is_final_submit'] = false;
                        payload['user_id'] = user_id;
                    }
                    api_service_1.api_service
                        .post({
                        endPoint: api_service_1.api_service.api_urls.FE_CREATION,
                        payLoad: payload,
                        domain: process.env.VENDOR_BASE_URL,
                    })
                        .then(function (res) {
                        if (res.data.statusCode === 200) {
                            react_hot_toast_1.default.success(res.data.message);
                            dispatch((0, FE_Creation_action_1.updateUserID)({
                                user_id: res.data.data.user_id,
                            }));
                            saveFormData(values, false);
                            if (location.pathname.includes('edit')) {
                                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(res.data.data.user_id, "/").concat(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS));
                                updateValue(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS);
                            }
                            else {
                                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(res.data.data.user_id, "/").concat(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS));
                                updateValue(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS);
                            }
                        }
                        else {
                            react_hot_toast_1.default.error(res.data.message);
                        }
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.data.errors);
                    });
                    _a.label = 16;
                case 16: return [2];
            }
        });
    }); };
    React.useEffect(function () {
        console.log('inside useeffect of fe contact details:', initialValues);
        if (JSON.stringify(initialValues) !=
            JSON.stringify(FE_ContactDetailsInitialState)) {
            if (initialValues.addresslane1 === initialValues.permenentAddresslane1 &&
                initialValues.addresslane2 === initialValues.permenentAddresslane2 &&
                initialValues.landmark === initialValues.permenentLandmark &&
                JSON.stringify(initialValues.state) ===
                    JSON.stringify(initialValues.permenentState) &&
                JSON.stringify(initialValues.city) ===
                    JSON.stringify(initialValues.permenentCity) &&
                JSON.stringify(initialValues.country) ===
                    JSON.stringify(initialValues.permenentCountry) &&
                JSON.stringify(initialValues.addressProofType) ===
                    JSON.stringify(initialValues.permanentProofOfAddressType) &&
                initialValues.pincode === initialValues.permenentPincode &&
                initialValues.proofOfAddress === initialValues.permenentProofOfAddress) {
                setIsCheckboxSelected(true);
            }
        }
    }, [initialValues]);
    var backButtonHandler = function () {
        if (onBoardingStatus === 1) {
            updateValue(value - 1);
        }
        else {
            if (location.pathname.includes('edit')) {
                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.PERSONALDETAILS));
                updateValue(FECreationTabConstant_1.CreationTabConstants.PERSONALDETAILS);
            }
            else {
                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.PERSONALDETAILS));
                updateValue(FECreationTabConstant_1.CreationTabConstants.PERSONALDETAILS);
            }
        }
    };
    return (React.createElement("div", { className: "form-filed-wrapper" },
        React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", null, "FE Contact information"),
            React.createElement("p", { className: "sub-heaidng" }, "Please collect all the required fields from FE")),
        React.createElement("div", null,
            React.createElement(formik_1.Formik, { enableReinitialize: true, validateOnBlur: false, initialValues: __assign({}, initialValues), validationSchema: schema_1.schemas.FE_ContactDetailsSchema, onSubmit: function (values, _a) {
                    var resetForm = _a.resetForm, setErrors = _a.setErrors, setSubmitting = _a.setSubmitting, setFieldError = _a.setFieldError;
                    formSubmitHnadler1(values, setErrors, setSubmitting, setFieldError);
                } }, function (_a) {
                var errors = _a.errors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, resetForm = _a.resetForm, setFieldTouched = _a.setFieldTouched, setFieldError = _a.setFieldError, setValues = _a.setValues, setTouched = _a.setTouched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 }, formFieldConstants_1.FE_ContactDetails_Fields &&
                        formFieldConstants_1.FE_ContactDetails_Fields.map(function (section) {
                            return (React.createElement(React.Fragment, null,
                                section.title ? (section.title === 'Permanent Address' ? (React.createElement(Grid_1.default, { item: true, xs: 9 },
                                    React.createElement("h2", { className: "label-heading heading" }, section.title),
                                    React.createElement(checkbox_component_1.default, { checked: isCheckboxSelected, label: "Same as current address", handleChange: function (e) {
                                            checkboxChangeHandler({
                                                errors: errors,
                                                touched: touched,
                                                values: values,
                                                setFieldValue: setFieldValue,
                                                setFieldTouched: setFieldTouched,
                                                setTouched: setTouched,
                                                setValues: setValues,
                                                e: e,
                                            });
                                        } }))) : (React.createElement(Grid_1.default, { item: true, xs: 9 },
                                    React.createElement("h2", { className: "label-heading heading" },
                                        section.title,
                                        section.description && (React.createElement("p", { className: "sub-heaidng label-sub-heading" }, section.description)))))) : null,
                                section.fields.map(function (field, index) {
                                    return (React.createElement(Grid_1.default, { item: true, key: index, xs: field.type === 'fileupload' ? 9 : 5, "xs-offset": field.type === 'fileupload' ? 1 : 0 },
                                        console.log('Errros in contactdetails:', errors, touched),
                                        renderInputFiled(field, values, setFieldValue, setFieldTouched, errors, touched, setFieldError)));
                                })));
                        })),
                    React.createElement("div", { className: "form-btns" },
                        React.createElement(button_component_1.default, { type: "button", buttonName: "Back", color: "yellow-outline", onClickHandler: backButtonHandler }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: 'Save & Continue', color: "yellow", isError: false }),
                        React.createElement(button_component_1.default, { type: "button", buttonName: 'Reset', color: "yellow", isError: user_id != null &&
                                user_id != undefined &&
                                JSON.stringify(values) != JSON.stringify(initialValues)
                                ? false
                                : true, onClickHandler: function () { return resetForm({ values: initialValues }); } }))));
            }))));
};
exports.default = FE_ContactDetailsForm1;
