"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FE_creation_reducer = void 0;
var FE_Creation_action_1 = require("../actions/FE_Creation_action");
var initialState = {
    user_id: null,
    isNewVendor: false,
    auto_routing_filtes: null,
};
var FE_creation_reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FE_Creation_action_1.FE_CREATION_ACTIONS.saveUserID:
            return __assign(__assign({}, state), { user_id: action.payload.user_id });
        case FE_Creation_action_1.FE_CREATION_ACTIONS.isNewVendor:
            return __assign(__assign({}, state), { isNewVendor: action.payload });
        case FE_Creation_action_1.AUTOROUTING_ACTIONS.AUTO_ROUTING_FILTERS:
            return __assign(__assign({}, state), { auto_routing_filtes: action.payload });
        case 'LOGOUT':
            return __assign({}, initialState);
        default:
            return state;
    }
};
exports.FE_creation_reducer = FE_creation_reducer;
