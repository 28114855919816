"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var table_data_1 = require("../../../../constants/table_data");
var CREOnboardingInprogressList = function () {
    return (React.createElement("div", { className: "unclickablerow" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.FE_EXIT_COLUMNS, rows: table_data_1.default.CRE_ROWS })));
};
exports.default = CREOnboardingInprogressList;
