"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var ETRF_apicalls_1 = require("../ETRF_apicalls");
var ETRFList = function () {
    var nav = (0, react_router_1.useNavigate)();
    var _a = React.useState(undefined), etrfList = _a[0], setETRFList = _a[1];
    var _b = React.useState(''), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = React.useState(appConstants_1.defaultPageLimit), PageLimit = _c[0], setPageLimit = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var _g = React.useState(''), errorMessage = _g[0], setErrorMessage = _g[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.ETRFDocuments);
    var clickRow = function (event) {
        var evenType = event.event.target.className;
        if (features[menu_constants_1.FEATURE_CONSTANTS.ETRFView]) {
            if (evenType.includes('icon-Vector')) {
                nav("/".concat(route_path_1.default.PATHS.ETRFList, "/").concat(route_path_1.default.PATHS.ETRF_Profile), { state: event.data });
            }
        }
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var refetch = (0, react_query_1.useQuery)(['active-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if ((searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.trim().length) >= 3) {
            payload['lab_number'] = searchTerm.toLocaleLowerCase().trim();
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, ETRF_apicalls_1.getETRFList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setErrorMessage("");
            setTotalPageCount(count);
            setETRFList((0, array_helpers_1.convertETRFResponse)(response.data.result));
            setTotalCount(response.data.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setETRFList([]);
        });
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, []);
    return (React.createElement("div", { className: "page-wrapper etrf-docs-page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "e-TRF Documents"))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter search-lg" },
                React.createElement(search_component_1.default, { placeholder: "Search By Labnumber", searchValue: searchTerm, searchHandler: searchingHandler })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: function () { return refetch(); } }))),
        React.createElement("div", { className: "scrollable-content ".concat(features[menu_constants_1.FEATURE_CONSTANTS.ETRFView] ? 'clickable-row' : 'unclickablerow') },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ETRF_UPDATED_COLUMNS, rows: etrfList, errorMessage: errorMessage, multiSelector: "multiselect", onRowClicked: clickRow, pagination: false, className: 'etrf-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: etrfList === null || etrfList === void 0 ? void 0 : etrfList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }))));
};
exports.default = ETRFList;
