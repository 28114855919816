"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var generic_api_calls_1 = require("../../../generic_api_calls");
var conroldashboardstyles_1 = require("../../control_dashboard/list/conroldashboardstyles");
var AnswerTable_1 = require("./AnswerTable");
var LinearScale = function (_a) {
    var name = _a.name, is_image_required = _a.is_image_required, question_type = _a.question_type, questionNum = _a.questionNum, numeric = _a.numeric, options = _a.options, totalAttempts = _a.totalAttempts, images = _a.images, rating_options = _a.rating_options, _id = _a._id;
    var classes = (0, conroldashboardstyles_1.useStyles)();
    var _b = React.useState([]), imageURL = _b[0], setItems = _b[1];
    var _c = React.useState(), label1 = _c[0], setLabel1 = _c[1];
    var _d = React.useState(), label2 = _d[0], setLabel2 = _d[1];
    var _e = React.useState(false), open = _e[0], setOpen = _e[1];
    var _f = React.useState({}), optionlist = _f[0], setOptionlist = _f[1];
    var convertImageUrlsIntoSignedUrls = function () { return __awaiter(void 0, void 0, void 0, function () {
        var uploadedImages, _loop_1, i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    uploadedImages = [];
                    if (!images) return [3, 5];
                    _loop_1 = function (i) {
                        var imageSignedUrl;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    imageSignedUrl = '';
                                    return [4, (0, generic_api_calls_1.fetchImnageURLFromName)(images[i].name).then(function (res) {
                                            imageSignedUrl = res.data.data.url;
                                            uploadedImages.push(imageSignedUrl);
                                        })];
                                case 1:
                                    _b.sent();
                                    return [2];
                            }
                        });
                    };
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < images.length)) return [3, 4];
                    return [5, _loop_1(i)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    i++;
                    return [3, 1];
                case 4:
                    console.log(uploadedImages, 'uploadedImages');
                    setItems(__spreadArray([], uploadedImages, true));
                    _a.label = 5;
                case 5: return [2];
            }
        });
    }); };
    React.useEffect(function () {
        convertImageUrlsIntoSignedUrls();
        options === null || options === void 0 ? void 0 : options.map(function (option) {
            if (option.label1) {
                setLabel1(option.label1);
            }
            else if (option.label2) {
                setLabel2(option.label2);
            }
        });
    }, []);
    var handleDrawerClose = function () {
        setOpen(false);
    };
    var displayTableData = function (e) {
        console.log(e, imageURL, "lkjhgfdfg");
        setOpen(!open);
        setOptionlist(e);
    };
    return (React.createElement("div", { className: "question-container" },
        React.createElement("div", { className: "question-wrapper" },
            React.createElement("span", { className: "sr-no" }, "Q".concat(questionNum, ".")),
            React.createElement(text_filed_component_1.default, { label: name, isDisabled: true }),
            React.createElement("div", { className: "audit-attempts-wrapper" },
                React.createElement("p", { className: "attempts-label" }, "Total Attempts"),
                React.createElement("p", { className: "attempts-value" }, totalAttempts))),
        React.createElement("div", { className: "options-container audit-summary-options" },
            React.createElement("div", { className: "linear-summary-wrapper" },
                React.createElement("div", { className: "linear-scale-conatiner flex-dr-col linear-summary-wrapper" },
                    React.createElement("label", { className: "linear-scale-label" }, label1),
                    React.createElement("div", { className: "linear-scale" },
                        React.createElement(material_1.FormControl, { className: "linear-from-control" },
                            React.createElement(material_1.RadioGroup, { "aria-labelledby": "demo-form-control-label-placement", name: "position", defaultValue: "top" }, rating_options === null || rating_options === void 0 ? void 0 : rating_options.map(function (label) {
                                return (React.createElement("div", { className: "linear-opt-wrapper displayflex" },
                                    React.createElement(material_1.FormControlLabel, { value: label.option, control: React.createElement(material_1.Radio, null), label: label.option, labelPlacement: "end", disabled: true }),
                                    React.createElement("div", { className: "progress-label-wrapper" },
                                        React.createElement("div", { className: "progress-bar-wrapper" },
                                            React.createElement(material_1.LinearProgress, { variant: "determinate", value: label.percentage
                                                    ? parseInt(label.percentage)
                                                    : 0, color: "success" })),
                                        React.createElement("label", { className: "progress-label" },
                                            label.percentage ? parseInt(label.percentage) : 0,
                                            "%"),
                                        React.createElement(material_1.IconButton, { className: parseInt(label.percentage) > 0
                                                ? "icon-btn"
                                                : "icon-btn icon-disabled", disabled: parseInt(label.percentage) > 0 ? false : true, onClick: function () { return displayTableData(label); } },
                                            React.createElement("span", { className: "icon-Vector" })))));
                            })))),
                    React.createElement("label", { className: "linear-scale-label" }, label2)))),
        open ?
            React.createElement(material_1.Drawer, { variant: "temporary", anchor: "bottom", open: open, className: classes.customDrawer },
                React.createElement("div", { onClick: handleDrawerClose, className: "drawer-close-btn" },
                    React.createElement("span", { className: "icon-close" })),
                React.createElement(AnswerTable_1.default, { option: optionlist, question_id: _id, name: name, question_type: question_type }),
                ")") : ''));
};
exports.default = LinearScale;
