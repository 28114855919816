"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_redux_1 = require("react-redux");
var react_query_1 = require("react-query");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../constants/appConstants");
var table_data_1 = require("../../../constants/table_data");
var shipment_helper_1 = require("./shipment_helper");
var array_helpers_1 = require("../../../utils/array_helpers");
var ShipmentTrackingList = function () {
    var _a = React.useState(undefined), shipmentList = _a[0], setShipmentList = _a[1];
    var _b = React.useState([]), filetrLabs = _b[0], setFilteredLabs = _b[1];
    var _c = React.useState(''), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = React.useState(new Date().toString()), filterFromDate = _d[0], setFromDateFilter = _d[1];
    var _e = React.useState(new Date().toString()), filterToDate = _e[0], setToDateFilter = _e[1];
    var _f = React.useState(0), totalPageCount = _f[0], setTotalPageCount = _f[1];
    var _g = React.useState(1), pageCount = _g[0], setpageCount = _g[1];
    var _h = React.useState(0), totalCount = _h[0], setTotalCount = _h[1];
    var _j = React.useState(null), shipmentStatusType = _j[0], setShipmentstatusType = _j[1];
    var shipmentStatus = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.shipment_status; });
    var LabcodeList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _k = React.useState(appConstants_1.defaultPageLimit), PageLimit = _k[0], setPageLimit = _k[1];
    var _l = React.useState(), errorMessage = _l[0], setErrorMessage = _l[1];
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var refetch = (0, react_query_1.useQuery)(['shipment-tracking-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (shipmentStatusType !== null) {
            payload['status'] = shipmentStatusType.label;
        }
        if (filetrLabs.length > 0) {
            var selecedLabcodes = filetrLabs.map(function (lab) { return lab.value.toString(); });
            payload['lab_codes'] = selecedLabcodes;
        }
        if (filterFromDate && filterToDate) {
            payload.date_range = {
                start_date: moment(filterFromDate).format('YYYY-MM-DD'),
                end_date: moment(filterToDate).format('YYYY-MM-DD'),
            };
        }
        if (searchTerm.trim()) {
            payload['search_string'] = searchTerm.trim();
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        (0, shipment_helper_1.getShipmentList)(payload)
            .then(function (response) {
            console.log(response, 'datadat');
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setTotalPageCount(count);
            setShipmentList(response.data.result);
            setTotalCount(response.data.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setShipmentList([]);
        });
    }).refetch;
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value);
    };
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
    };
    var LabHandler = function (value) {
        if (value.length >= 0) {
            setFilteredLabs(value);
        }
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var statusHandler = function (statusType) {
        setShipmentstatusType(statusType);
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
            React.createElement("div", { className: "tool-bar-wrapper" },
                React.createElement("div", { className: "page-heaidng" },
                    React.createElement("h2", null, "Shipment Tracking ")),
                React.createElement("div", { className: "page-control-wrapper" })),
            React.createElement("div", { className: "filter-menu-wrapper long-filter" },
                React.createElement("div", { className: "search-filter search-lg" },
                    React.createElement(search_component_1.default, { placeholder: "Search Master Shipment Barcode, Shipment Bar code", searchValue: searchTerm, searchHandler: searchingHandler })),
                React.createElement("div", { className: "filter-options" },
                    React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true }),
                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "status", placeholder: "Status", dropDownList: shipmentStatus, onChange: statusHandler, value: shipmentStatusType || null }),
                    React.createElement(multi_selector_1.default, { label: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(LabcodeList), onChangeHnadlre: LabHandler, value: filetrLabs }),
                    React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                    React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
            React.createElement("div", { className: "scrollable-content fe-managment-screen" },
                React.createElement("div", { className: "custom-pagination-table" },
                    React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.SHIPMENT_TRACKING_COLUMNS, rows: shipmentList, pagination: false, multiSelector: "multiselect", errorMessage: errorMessage }),
                    React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: shipmentList === null || shipmentList === void 0 ? void 0 : shipmentList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }))))));
};
exports.default = ShipmentTrackingList;
