"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../common/buttons/button_component");
var iconButton_component_1 = require("../common/buttons/iconButton_component");
var schema_1 = require("../constants/schema");
var change_password_api_calls_1 = require("./change_password_api_calls");
var text_field_password_1 = require("../common/text_filed/text_field_password");
var ChangePassword = function () {
    var _a = React.useState(null), passwordValidation = _a[0], setPasswordValidation = _a[1];
    var nav = (0, react_router_dom_1.useNavigate)();
    var formSubmitHandler = function (values) {
        var payload = {};
        payload.oldPassword = values.currentPassword;
        payload.newPassword = values.confirmPassword;
        (0, change_password_api_calls_1.changePassword)(payload)
            .then(function (response) {
            react_hot_toast_1.default.success(response.message);
            console.log(' ChangePassword then', response);
            nav(-1);
            console.log('ChangePassword success');
            setPasswordValidation(null);
        })
            .catch(function (error) {
            setPasswordValidation(error.message);
            console.log('ChangePassword failed');
        });
    };
    return (React.createElement("div", { className: "page-wrapper change-psw-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom " },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", onClick: function () { return nav(-1); }, icon: 'icon-left-arrow' }),
                React.createElement("h2", null, "Change Password"))),
        React.createElement(material_1.Stack, { direction: 'row', justifyContent: "center" },
            React.createElement("div", { className: "password-wrapper" },
                React.createElement("div", { className: "psw-list-container" },
                    React.createElement("p", { className: "label" },
                        "In order to protect your account, make sure your password:",
                        ' '),
                    React.createElement("ul", null,
                        React.createElement("li", null, "password should contain atleast one digit [0-9]"),
                        React.createElement("li", null, "one lowercase character [a-z]"),
                        React.createElement("li", null, "one uppercase character [A-Z]"),
                        React.createElement("li", null, "one special character"),
                        React.createElement("li", null, "at least 8 characters and a maximum of 64 characters and should not contain white spaces."))),
                React.createElement("div", { className: "page-aligner" },
                    React.createElement(formik_1.Formik, { initialValues: {
                            currentPassword: '',
                            newPassword: '',
                            confirmPassword: '',
                        }, validationSchema: schema_1.schemas.changePassword, validateOnChange: false, validateOnBlur: false, onSubmit: formSubmitHandler }, function (_a) {
                        var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched, setErrors = _a.setErrors;
                        return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                            React.createElement(material_1.Grid, { container: true, columns: { xs: 8 }, rowGap: 1.5 },
                                React.createElement(material_1.Grid, { item: true, xs: 6 },
                                    React.createElement(text_field_password_1.default, { required: true, name: "currentPassword", onChange: function (event) {
                                            setFieldValue('currentPassword', event);
                                            setFieldTouched('currentPassword', true);
                                            setPasswordValidation(null);
                                        }, label: "Current Password " }),
                                    errors['currentPassword'] &&
                                        touched['currentPassword'] ? (React.createElement("div", { className: "error" }, errors['currentPassword'])) : null),
                                React.createElement(material_1.Grid, { item: true, xs: 6 },
                                    React.createElement(text_field_password_1.default, { required: true, name: "newPassword", onChange: function (event) {
                                            setFieldValue('newPassword', event);
                                            setFieldTouched('newPassword', true);
                                            setPasswordValidation(null);
                                        }, label: "New Password" }),
                                    errors['newPassword'] && touched['newPassword'] ? (React.createElement("div", { className: "error" }, errors['newPassword'])) : null),
                                React.createElement(material_1.Grid, { item: true, xs: 6, pb: 1.5 },
                                    React.createElement(text_field_password_1.default, { required: true, name: "confirmPassword", onChange: function (event) {
                                            setFieldValue('confirmPassword', event);
                                            setFieldTouched('confirmPassword', true);
                                            setPasswordValidation(null);
                                        }, label: "Confirm Password " }),
                                    errors['confirmPassword'] &&
                                        touched['confirmPassword'] ? (React.createElement("div", { className: "error" }, errors['confirmPassword'])) : null,
                                    passwordValidation ? (React.createElement("div", { className: "error" }, passwordValidation)) : null)),
                            React.createElement("div", { className: "form-btns p-16" },
                                React.createElement(button_component_1.default, { buttonName: "cancel", color: "yellow-outline", onClickHandler: function () { return nav(-1); } }),
                                React.createElement(button_component_1.default, { type: "submit", buttonName: "Save", color: "yellow" }))));
                    }))))));
};
exports.default = ChangePassword;
