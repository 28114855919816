"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var search_component_1 = require("../../common/search/search_component");
require("../../components/super_admin/role_management/styles/transfer-list.scss");
var array_helpers_1 = require("../../utils/array_helpers");
var user_logistics_api_1 = require("../super_admin/User_Logistics/user_logistics_api");
var UserRegionTransfer = function (_a) {
    var setSelectedFeatures = _a.setSelectedFeatures, updateEditFeaturesIds = _a.updateEditFeaturesIds, role_id = _a.role_id, pathName = _a.pathName;
    var _b = React.useState([]), Idarray = _b[0], setIdarray = _b[1];
    var _c = React.useState([]), rightIdarray = _c[0], setrightIdarray = _c[1];
    var _d = React.useState([]), left = _d[0], setLeft = _d[1];
    var _e = React.useState([]), moduleList = _e[0], setVenorModuleList = _e[1];
    var _f = React.useState([]), leftlabs = _f[0], setleftlabs = _f[1];
    var _g = React.useState([]), rightlabs = _g[0], setrightlabs = _g[1];
    var _h = React.useState([]), rightlabsforsearch = _h[0], setrightlabsforsearch = _h[1];
    var _j = React.useState(""), searchTerm = _j[0], setSearchTerm = _j[1];
    var _k = React.useState(""), rightsearchTerm = _k[0], setRightSearchTerm = _k[1];
    var _l = React.useState([]), right = _l[0], setRight = _l[1];
    var _m = React.useState([]), rightIds = _m[0], setrightIds = _m[1];
    var _o = React.useState([]), leftIds = _o[0], setLeftIds = _o[1];
    var _p = React.useState({}), selectedIds = _p[0], setSelectedIds = _p[1];
    var _q = React.useState([]), selectedrightlabs = _q[0], setSelectedrightlabs = _q[1];
    var _r = React.useState([]), selectedleftabs = _r[0], setSelectedleftlabs = _r[1];
    var _s = React.useState(false), isChipSelected = _s[0], setIsChipSelected = _s[1];
    var _t = React.useState(), errorMessage = _t[0], setErrorMessage = _t[1];
    var _u = React.useState(false), reloadPage = _u[0], setReloadPage = _u[1];
    var serachFunction = function (Str, arr, CompareIds, side) {
        var dat = [];
        arr.map(function (feature, index) {
            console.log("fffff12", Str, Idarray);
            console.log("fffff13", (feature.userName).includes(Str));
            if ((feature.userName).includes(Str.toUpperCase())) {
                console.log("featur43e", feature);
                dat.push(feature);
            }
        });
        if (Str.length >= 3) {
            return dat;
        }
        else {
            var filterLefeData = moduleList.filter(function (feature, index) {
                return !CompareIds.includes(feature.id);
            });
            var filterLefeDataRight = rightlabsforsearch.filter(function (feature, index) {
                return !CompareIds.includes(feature.id);
            });
            console.log(filterLefeDataRight, 'filterLefeDataRight');
            return (side === 'left' ? filterLefeData : filterLefeDataRight);
        }
    };
    var searchingHandler = function (e) {
        console.log("fffff1", e.target.value);
        setSearchTerm(e.target.value.trim());
        var filtered = serachFunction(e.target.value, left, rightIds, "left");
        console.log("filtered2354", filtered);
        setLeft(filtered);
    };
    var searchingrightHandler = function (e) {
        setRightSearchTerm(e.target.value.trim());
        var filtered = serachFunction(e.target.value, right, leftIds, "right");
        setRight(filtered);
    };
    var getFeatures = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        return __generator(this, function (_a) {
            payload = {};
            if (searchTerm.trim().length >= 3) {
                payload['search_string'] = searchTerm.toLocaleLowerCase().trim();
            }
            payload['page'] = 1;
            payload['size'] = 50;
            (0, user_logistics_api_1.fetchUserLogisticsList)(payload)
                .then(function (response) {
                var resData = response === null || response === void 0 ? void 0 : response.result.filter(function (d) {
                    if (d.first_name !== "")
                        return d;
                });
                var convertedUsersList = (0, array_helpers_1.convertUserLogisticsListFromRes)(resData);
                setErrorMessage("");
                setLeft(convertedUsersList);
                setVenorModuleList(convertedUsersList);
                setReloadPage(false);
            })
                .catch(function (err) {
                setErrorMessage(err.message);
                setLeft([]);
            });
            return [2];
        });
    }); };
    var selectedFeatureHandler = function (event, moduleName, featuresId, feature) {
        var prevSelectedIDS = selectedIds[moduleName]
            ? selectedIds[moduleName]
            : [];
        if (event.target.checked) {
            var obj = {};
            obj[moduleName] = __spreadArray(__spreadArray([], prevSelectedIDS, true), [featuresId], false);
            setSelectedIds(__assign(__assign({}, selectedIds), obj));
            setIsChipSelected(true);
        }
        else {
            var index = selectedIds[moduleName].indexOf(featuresId);
            var index2 = rightlabs.indexOf(feature);
            var index3 = leftlabs.indexOf(feature);
            var index4 = rightIdarray.indexOf(featuresId);
            var index5 = Idarray.indexOf(featuresId);
            var index6 = rightlabsforsearch.indexOf(feature);
            if (index > -1) {
                selectedIds[moduleName].splice(index, 1);
                leftlabs.splice(index3, 1);
                rightlabs.splice(index2, 1);
                rightIdarray.splice(index4, 1);
                Idarray.splice(index5, 1);
                rightlabsforsearch.splice(index6, 1);
                setSelectedIds(__assign({}, selectedIds));
                setleftlabs(__spreadArray([], leftlabs, true));
                setrightlabs(__spreadArray([], rightlabs, true));
                setrightIdarray(__spreadArray([], rightIdarray, true));
                setrightlabsforsearch(__spreadArray([], rightlabsforsearch, true));
                setLeftIds(__spreadArray([], rightIdarray, true));
                setIdarray(__spreadArray([], Idarray, true));
                setrightIds(__spreadArray([], Idarray, true));
                if (selectedIds && selectedIds[moduleName] >= 1) {
                    setIsChipSelected(true);
                }
                else {
                    setIsChipSelected(false);
                }
            }
        }
    };
    var handleAllLeft = function (selectedModule) {
        if (isChipSelected) {
            setRight(__spreadArray(__spreadArray([], leftlabs, true), right, true));
            setSelectedFeatures(__spreadArray(__spreadArray([], right, true), leftlabs, true));
            setrightlabsforsearch(__spreadArray(__spreadArray([], right, true), leftlabs, true));
            var filterLefeData = left.filter(function (feature, index) {
                return !Idarray.includes(feature.id);
            });
            setLeft(__spreadArray([], filterLefeData, true));
            setIsChipSelected(false);
            setIdarray([]);
            setSelectedIds([]);
            setleftlabs([]);
        }
        else {
            setRight(__spreadArray(__spreadArray([], leftlabs, true), right, true));
            setSelectedFeatures(__spreadArray(__spreadArray([], right, true), leftlabs, true));
            var filterLefeData = left.filter(function (feature, index) {
                return !Idarray.includes(feature.id);
            });
            setLeft(__spreadArray([], filterLefeData, true));
            setIsChipSelected(false);
            setIdarray([]);
            setSelectedIds([]);
            setleftlabs([]);
        }
    };
    var handleAllRight = function (selectedModule) {
        if (isChipSelected) {
            setLeft(__spreadArray(__spreadArray([], rightlabs, true), left, true));
            var filterLefeData = right.filter(function (feature, index) {
                return !rightIdarray.includes(feature.id);
            });
            setRight(__spreadArray([], filterLefeData, true));
            setSelectedFeatures(__spreadArray([], filterLefeData, true));
            setIsChipSelected(false);
            setSelectedIds([]);
            setrightlabs([]);
            setrightIdarray([]);
        }
        else {
            setLeft(__spreadArray(__spreadArray([], rightlabs, true), left, true));
            var filterLefeData = right.filter(function (feature, index) {
                return !rightIdarray.includes(feature.id);
            });
            setRight(__spreadArray([], filterLefeData, true));
            setSelectedFeatures(__spreadArray([], filterLefeData, true));
            setIsChipSelected(false);
            setSelectedIds([]);
            setrightlabs([]);
            setrightIdarray([]);
        }
    };
    var editRoleProcess = function () { return __awaiter(void 0, void 0, void 0, function () {
        var filterLefeData, filterLefeDataRight;
        return __generator(this, function (_a) {
            filterLefeData = left.filter(function (feature, index) {
                return !updateEditFeaturesIds.includes(feature.id);
            });
            filterLefeDataRight = left.filter(function (feature, index) {
                return updateEditFeaturesIds.includes(feature.id);
            });
            setReloadPage(true);
            setLeft(__spreadArray([], filterLefeData, true));
            setRight(__spreadArray([], filterLefeDataRight, true));
            setSelectedFeatures(__spreadArray([], filterLefeDataRight, true));
            return [2];
        });
    }); };
    var renderChipsLeft = function (items, iconName) { return (React.createElement("div", { className: "list-container" },
        React.createElement(search_component_1.default, { placeholder: "Search by users", searchValue: searchTerm, searchHandler: searchingHandler }),
        React.createElement(material_1.List, { className: "list-wrapper" },
            React.createElement(material_1.ListItem, { className: "feature-container" },
                React.createElement("div", { className: "feature-heading-wrapper" },
                    React.createElement("h2", { className: "feature-name section-title" },
                        ' ',
                        'All Users'),
                    React.createElement("span", { className: "icon-swap swap-icon", onClick: function () {
                            if (!isChipSelected && Idarray.length == 0) {
                                setRight(__spreadArray(__spreadArray([], left, true), right, true));
                                setSelectedFeatures(__spreadArray(__spreadArray([], left, true), right, true));
                                setrightlabsforsearch(__spreadArray(__spreadArray([], left, true), right, true));
                                setLeft([]);
                            }
                            else {
                                handleAllLeft(selectedleftabs);
                            }
                        } })),
                React.createElement("p", { className: "section-desc" }, "Click on the above icon to add all the features or select one by one from below list."),
                React.createElement("div", { className: 'feature-chips-wrapper' }, items && (items === null || items === void 0 ? void 0 : items.map(function (feature) {
                    return (React.createElement("div", null,
                        React.createElement("div", { className: "feature-chips-wrapper" },
                            React.createElement("label", { key: feature.id, className: "chip-label custom-chip boxed-chip  feature-chip ".concat(selectedIds &&
                                    selectedIds[feature.fullName] &&
                                    selectedIds[feature.fullName].includes(feature.id)
                                    ? 'activeChip'
                                    : '') },
                                React.createElement("input", { type: "checkbox", key: feature.id, name: "chip", id: "feature-chip", className: "boxed-chip  feature-chip", onChange: function () {
                                        setSelectedleftlabs(feature);
                                        setleftlabs(function (prev) { return __spreadArray(__spreadArray([], prev, true), [feature], false); });
                                        setIdarray(function (prev) { return __spreadArray(__spreadArray([], prev, true), [feature.id], false); });
                                        setrightlabsforsearch(function (prev) { return __spreadArray(__spreadArray([], prev, true), [feature], false); });
                                        setrightIds(function (prev) { return __spreadArray(__spreadArray([], prev, true), [feature.id], false); });
                                        selectedFeatureHandler(event, feature.fullName, feature.id, feature);
                                    } }),
                                feature.fullName,
                                ' ',
                                React.createElement("span", { className: "".concat(iconName, " ") })))));
                }))))))); };
    var renderChipsRight = function (items, iconName) { return (React.createElement("div", { className: "list-container" },
        React.createElement(search_component_1.default, { placeholder: "Search by users", searchValue: rightsearchTerm, searchHandler: searchingrightHandler }),
        React.createElement(material_1.List, { className: "list-wrapper" },
            React.createElement(material_1.ListItem, { className: "feature-container" },
                React.createElement("div", { className: "feature-heading-wrapper" },
                    React.createElement("h2", { className: "feature-name section-title" },
                        ' ',
                        'Selected Users'),
                    React.createElement("span", { className: "icon-swap swap-icon", onClick: function () {
                            if (!isChipSelected && rightIdarray.length == 0) {
                                setLeft(__spreadArray(__spreadArray([], right, true), left, true));
                                setRight([]);
                            }
                            else {
                                handleAllRight(selectedrightlabs);
                            }
                        } })),
                React.createElement("p", { className: "section-desc" }, "Click on the above icon to add all the features or select one by one from below list."),
                React.createElement("div", { className: 'feature-chips-wrapper' }, items && (items === null || items === void 0 ? void 0 : items.map(function (feature) {
                    return (React.createElement("div", null,
                        React.createElement("div", { className: "feature-chips-wrapper" },
                            React.createElement("label", { key: feature.id, className: "chip-label custom-chip boxed-chip  feature-chip ".concat(selectedIds &&
                                    selectedIds[feature.userName] &&
                                    selectedIds[feature.userName].includes(feature.id)
                                    ? 'activeChip'
                                    : '') },
                                React.createElement("input", { type: "checkbox", key: feature.id, name: "chip", id: "feature-chip", className: "boxed-chip  feature-chip", onChange: function () {
                                        setSelectedrightlabs(feature);
                                        setrightlabs(function (prev) { return __spreadArray(__spreadArray([], prev, true), [feature], false); });
                                        setrightIdarray(function (prev) { return __spreadArray(__spreadArray([], prev, true), [feature.id], false); });
                                        setLeftIds(function (prev) { return __spreadArray(__spreadArray([], prev, true), [feature.id], false); });
                                        selectedFeatureHandler(event, feature.userName, feature.id, feature);
                                    } }),
                                feature.fullName,
                                ' ',
                                React.createElement("span", { className: "".concat(iconName, " ") })))));
                }))))))); };
    var renderPage = function () {
        return !errorMessage ? (React.createElement("div", { className: "transfer-list-wrapper" },
            React.createElement(material_1.Grid, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                React.createElement(material_1.Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: "list-container-wrapper" }, renderChipsLeft(left, 'icon-add'))),
                React.createElement(material_1.Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: "list-container-wrapper" },
                        React.createElement("div", null, renderChipsRight(right, 'icon-cross feature-delete'))))))) : (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString()));
    };
    React.useEffect(function () {
        getFeatures();
    }, []);
    React.useEffect(function () {
        if (updateEditFeaturesIds.length > 0) {
            editRoleProcess();
        }
    }, [moduleList, updateEditFeaturesIds, reloadPage]);
    if (errorMessage) {
        return (React.createElement("div", { className: "server-error" },
            ' ',
            errorMessage && errorMessage.toString()));
    }
    return React.createElement(React.Fragment, null, renderPage());
};
exports.default = UserRegionTransfer;
