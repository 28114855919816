"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./input-field.scss");
var TextFiledWithIcon = function (_a) {
    var type = _a.type, name = _a.name, placeholder = _a.placeholder, className = _a.className, value = _a.value, onChange = _a.onChange, minLength = _a.minLength, style = _a.style, label = _a.label, error = _a.error, required = _a.required, isRequered = _a.isRequered, defaultVal = _a.defaultVal, isDisabled = _a.isDisabled, FormHelperTextProps = _a.FormHelperTextProps, onBlur = _a.onBlur, maxLength = _a.maxLength, onKeyPress = _a.onKeyPress, readOnly = _a.readOnly, onFocus = _a.onFocus, onClick = _a.onClick, isIconDisabled = _a.isIconDisabled;
    var _b = React.useState(''), textFieldval = _b[0], setTextFieldVal = _b[1];
    var onChangeHandler = function (e) {
        setTextFieldVal(e.target.value);
        if (onChange) {
            onChange(e.target.value);
        }
    };
    console.log('textFieldval', value);
    React.useEffect(function () {
        console.log(' inside textfield useeffect:', value);
        setTextFieldVal(value);
    }, [value]);
    var params = [];
    if (value) {
        params.push({ label: value });
    }
    return (React.createElement("div", { className: required ? 'input-wrapper validate-filed ' : 'input-wrapper' },
        React.createElement(material_1.TextField, { type: type, key: name, name: name, className: className, InputProps: {
                readOnly: true,
                endAdornment: (React.createElement(material_1.InputAdornment, { position: "end", onClick: isIconDisabled && onClick },
                    React.createElement("span", { className: "icon-map-pin" }))),
            }, placeholder: placeholder, value: textFieldval, label: label, variant: "filled", onChange: onChangeHandler, onBlur: onBlur, error: !!error, onKeyPress: onKeyPress, autoComplete: "off", helperText: error ? error : null, style: style, fullWidth: true, onFocus: onClick, defaultValue: defaultVal, disabled: isDisabled })));
};
exports.default = TextFiledWithIcon;
