"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATIC_FE_DROPDOWN_LIST = exports.FE_ICONS_SIZE = exports.MPA_ICONS_SIZE = exports.ASSIGNED_UNASSIGNED = exports.ASSIGNED_UNASSIGNED_ROOTES = exports.VIST_TYPE_INFO = exports.VISIT_TYPES_FOR_TRACING = exports.VISIT_TYPES = exports.UN_ASSIGNED_VISIT_STATIC_DATA = exports.LAB_VISITES_DATA = exports.colorCodes = exports.map_locations = void 0;
exports.map_locations = [
    {
        id: 1,
        latitude: 17.440081,
        longitude: 78.348915,
        visit_type: 0,
        scheduled_start_time: '8:30',
        fe_id: 'C12WRS34',
        center_name: 'Base location',
        fe_first_name: 'Revanth',
        center_address: 'Kadapa',
    },
    {
        latitude: 17.4486,
        longitude: 78.3908,
        id: 2,
        visit_type: 1,
        scheduled_start_time: '9:30',
        fe_id: 'C12WRS34',
        center_name: 'CC1',
        fe_first_name: 'Revanth',
        center_info: {
            scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
        },
    },
    {
        latitude: 17.4559,
        longitude: 78.4252,
        id: 3,
        visit_type: 5,
        scheduled_start_time: '10:30',
        fe_id: 'C12WRS34',
        center_name: 'LAB',
        fe_first_name: 'Revanth',
        center_info: {
            scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
        },
    },
    {
        latitude: 17.437462,
        longitude: 78.448288,
        id: 4,
        visit_type: 1,
        fe_first_name: 'Revanth',
        scheduled_start_time: '11:30',
        fe_id: 'C12WRS34',
        center_name: 'CC2',
        center_info: {
            scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
        },
    },
    {
        latitude: 17.4239,
        longitude: 78.4738,
        id: 5,
        visit_type: 1,
        fe_first_name: 'Revanth',
        scheduled_start_time: '12:30',
        fe_id: 'C12WRS34',
        center_name: 'CC3',
        center_info: {
            scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
        },
    },
    {
        latitude: 17.389673,
        longitude: 78.496014,
        id: 6,
        visit_type: 3,
        scheduled_start_time: '1:30',
        fe_id: 'C12WRS34',
        center_name: 'LAB',
        fe_first_name: 'Revanth',
        center_info: {
            scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
        },
    },
    {
        latitude: 17.399272,
        longitude: 78.46271,
        id: 7,
        visit_type: 1,
        scheduled_start_time: '2:30',
        fe_id: 'C12WRS34',
        fe_first_name: 'Revanth',
        center_name: 'CC4',
        center_info: {
            scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
        },
    },
    {
        latitude: 17.338,
        longitude: 78.3826,
        id: 8,
        visit_type: 4,
        scheduled_start_time: '3:30',
        fe_id: 'C12WRS34',
        fe_first_name: 'Revanth',
        center_name: 'LAB',
        center_info: {
            scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
        },
    },
];
exports.colorCodes = [
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#FFFF99',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
];
exports.LAB_VISITES_DATA = [
    {
        fe_code: 'CLS281129',
        fe_reached_date_time: null,
        fe_started_date_time: '9.30',
        fe_current_location: {
            latitude: 17.440081,
            longitude: 78.348915,
        },
        visit_type: 0,
        user_id: 1129,
        fe_name: 'Revanth',
        visites: [
            {
                latitude: 17.4486,
                longitude: 78.3908,
                scheduled_time: '10:00',
                visit_type: 1,
                center_name: 'CC1',
                fe_code: 'CLS281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.4559,
                longitude: 78.4252,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'CLS281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.4239,
                longitude: 78.4738,
                scheduled_time: '10:30',
                visit_type: 1,
                center_name: 'CC3',
                fe_code: 'CLS281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.389673,
                longitude: 78.496014,
                scheduled_time: '10:45',
                visit_type: 1,
                center_name: 'CC4',
                fe_code: 'CLS281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.399272,
                longitude: 78.46271,
                scheduled_time: '11:00',
                visit_type: 3,
                center_name: 'LAB',
                fe_code: 'CLS281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
        ],
    },
    {
        fe_code: 'CDDS81129',
        fe_reached_date_time: null,
        fe_started_date_time: '9.00',
        fe_current_location: {
            latitude: 17.338,
            longitude: 78.3826,
        },
        fe_name: 'Kumar',
        visit_type: 0,
        user_id: 1234,
        visites: [
            {
                latitude: 17.3757,
                longitude: 78.494,
                scheduled_time: '10:00',
                visit_type: 1,
                center_name: 'CC1',
                fe_code: 'CDDS81129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.376,
                longitude: 78.5371,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'CDDS81129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.3444,
                longitude: 78.5183,
                scheduled_time: '10:30',
                visit_type: 1,
                center_name: 'CC3',
                fe_code: 'CDDS81129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.399272,
                longitude: 78.46271,
                scheduled_time: '10:45',
                visit_type: 3,
                center_name: 'LAB',
                fe_code: 'CDDS81129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
        ],
    },
    {
        fe_code: 'CSDFSS81129',
        fe_reached_date_time: null,
        fe_started_date_time: '10.00',
        fe_current_location: {
            latitude: 17.2619,
            longitude: 78.388,
        },
        fe_name: 'Kumar',
        visit_type: 0,
        user_id: 4321,
        visites: [
            {
                latitude: 17.3525,
                longitude: 78.4773,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC1',
                fe_code: 'CSDFSS81129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.4734,
                longitude: 78.4872,
                scheduled_time: '10:30',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'CSDFSS81129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.3168,
                longitude: 78.5513,
                scheduled_time: '10:45',
                visit_type: 1,
                center_name: 'CC3',
                fe_code: 'CSDFSS81129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.399272,
                longitude: 78.46271,
                scheduled_time: '11:00',
                visit_type: 3,
                center_name: 'LAB',
                fe_code: 'CSDFSS81129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
        ],
    },
    {
        fe_code: 'S281129',
        fe_reached_date_time: null,
        fe_started_date_time: '9.30',
        fe_current_location: {
            latitude: 17.4569,
            longitude: 79.2592,
        },
        visit_type: 0,
        user_id: 1129,
        fe_name: 'Kiran',
        visites: [
            {
                latitude: 17.5078,
                longitude: 78.8802,
                scheduled_time: '10:00',
                visit_type: 1,
                center_name: 'CC1',
                fe_code: 'S281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.3765,
                longitude: 79.0216,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'S281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 16.8739,
                longitude: 79.5662,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'S281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.2041,
                longitude: 79.1965,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'S281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.232,
                longitude: 79.1261,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'S281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.3454,
                longitude: 78.8241,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'S281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.3786,
                longitude: 78.6519,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'S281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.4531,
                longitude: 78.6851,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'S281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.4629,
                longitude: 78.5609,
                scheduled_time: '10:15',
                visit_type: 1,
                center_name: 'CC2',
                fe_code: 'S281129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
            {
                latitude: 17.399272,
                longitude: 78.46271,
                scheduled_time: '11:00',
                visit_type: 3,
                center_name: 'LAB',
                fe_code: 'CSDFSS81129',
                center_info: {
                    scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
                },
            },
        ],
    },
];
exports.UN_ASSIGNED_VISIT_STATIC_DATA = [
    {
        latitude: 17.399272,
        longitude: 78.46271,
        scheduled_time: '11:00',
        visit_type: 3,
        center_name: 'LAB',
        fe_code: 'CSDFSS81129',
        center_info: {
            scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
        },
    },
    {
        latitude: 17.4629,
        longitude: 78.5609,
        scheduled_time: '10:15',
        visit_type: 1,
        center_name: 'CC2',
        fe_code: 'S281129',
        center_info: {
            scheduled_time_slots: ['10:10-11:00', '11:10-12:00'],
        },
    },
];
exports.VISIT_TYPES = {
    fe_code: 'Field Executive',
    cc_code: 'Pickup',
    hub_code: 'Hub Receiving',
    lab_Code: 'Handover Lab',
    hub_cash_code: 'Cash Handover',
};
exports.VISIT_TYPES_FOR_TRACING = {
    cc_code: 'Pickup',
    hub_code: 'Hub Receiving',
    lab_Code: 'Handover To Lab',
    hub_cash_code: 'Handover Cash',
    handorver_hub: 'Handover To Hub',
    pickup_master_box: 'Pickup Master Box',
};
exports.VIST_TYPE_INFO = {
    FE: 0,
    PICKUP: 1,
    HUB_RECEIVING: 2,
    HANDOVER_HUB: 3,
    HANDOVER_LAB: 4,
    CASH_HANDOVER: 5,
    PICKUP_MASTER_BOX: 6,
};
exports.ASSIGNED_UNASSIGNED_ROOTES = [
    {
        id: 1,
        label: 'All',
        value: 'assigned_unAssigned',
    },
    {
        id: 2,
        label: 'Assigned',
        value: 'assigned',
    },
    {
        id: 3,
        label: 'Un Assigned',
        value: 'unassigned',
    },
];
exports.ASSIGNED_UNASSIGNED = {
    assiendAndUnassigned: 'assigned_unAssigned',
    assigned: 'assigned',
    unassigned: 'unassigned',
};
exports.MPA_ICONS_SIZE = {
    size: { w: 26, h: 26 },
};
exports.FE_ICONS_SIZE = {
    size: { w: 40, h: 40 },
};
exports.STATIC_FE_DROPDOWN_LIST = [
    {
        id: 1,
        label: 'FE1',
        value: 'FE1',
    },
    {
        id: 2,
        label: 'FE2',
        value: 'FE2',
    },
    {
        id: 3,
        label: 'FE3',
        value: 'FE3',
    },
    {
        id: 4,
        label: 'FE4',
        value: 'FE4',
    },
];
