"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var IconButtonComponent = function (_a) {
    var size = _a.size, icon = _a.icon, color = _a.color, style = _a.style, className = _a.className, onClick = _a.onClick, isDisabled = _a.isDisabled;
    return (React.createElement("div", { className: "icon-btn-wrapper" },
        React.createElement(material_1.IconButton, { className: "icon-btn ".concat(className), onClick: onClick, disabled: isDisabled ? true : false },
            React.createElement("span", { className: icon }))));
};
exports.default = IconButtonComponent;
