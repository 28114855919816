"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUserProfileDeatils = void 0;
var api_service_1 = require("../api/api_service");
var fetchUserProfileDeatils = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.VENDOR_USER_DETAILS,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchUserProfileDeatils = fetchUserProfileDeatils;
