"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertFEList = exports.FEMarkerEvents = exports.markerInformation = exports.markerInformationSingleFe = exports.feSampleHistory = exports.sampleFEData = void 0;
var array_helpers_1 = require("../../../../utils/array_helpers");
var lodash_1 = require("../../../../utils/lodash");
exports.sampleFEData = [
    {
        id: 1199,
        vendor_name: 'CONCORD LOGISTICS & SERVICES',
        first_name: 'EFV',
        last_name: 'EF',
        fe_id: 'CLS031199',
        mobile: '3343434343',
        state: 'Andhra Pradesh',
        city: 'Araku',
        base_latitude: '17.74725',
        base_longitude: '83.2628',
        lab: {
            lab_code: 'S03',
            center_city: 'Delhi',
        },
        base_location_address: 'Medplus Muralinagar Sai Complex, Wide Road, Murali Nagar, Visakhapatnam 530007, India',
        exit_reason: '',
        date_of_exit: '',
        updated_date_time: '2023-06-12T11:25:35.000Z',
        role_id: 2,
    },
    {
        id: 1275,
        vendor_name: 'CONCORD LOGISTICS & SERVICES',
        first_name: 'RAZZZAZAZAZAZA',
        last_name: 'QQQAAQQA',
        fe_id: '',
        mobile: '4544445445',
        state: 'Andhra Pradesh',
        city: 'Vijayawada',
        base_latitude: '28.47717',
        base_longitude: '77.06846',
        lab: {
            lab_code: 'S02',
            center_city: 'Gurugram',
        },
        base_location_address: 'Gurugram, Haryana, India',
        exit_reason: '',
        date_of_exit: '',
        updated_date_time: '2023-05-29T16:53:10.000Z',
        role_id: 1,
    },
    {
        id: 1347,
        vendor_name: 'CONCORD LOGISTICS & SERVICES',
        first_name: 'PAVANI',
        last_name: 'SETTI',
        fe_id: 'CLS021347',
        mobile: '8328632057',
        state: 'Andhra Pradesh',
        city: 'Gudivada',
        base_latitude: '28.47717',
        base_longitude: '77.06846',
        lab: {
            lab_code: 'S02',
            center_city: 'Gurugram',
        },
        base_location_address: 'Gurugram, Haryana, India',
        exit_reason: '',
        date_of_exit: '',
        updated_date_time: '2023-05-29T11:12:16.000Z',
        role_id: 1,
    },
    {
        id: 848,
        vendor_name: 'CONCORD LOGISTICS & SERVICES',
        first_name: 'DONTEDITQARAMYA',
        last_name: 'LASTNAME',
        fe_id: 'CLS740848',
        mobile: '9912759405',
        state: 'Andhra Pradesh',
        city: 'Vijayawada',
        base_latitude: '28.47717',
        base_longitude: '77.06846',
        lab: {
            lab_code: 'S02',
            center_city: 'Gurugram',
        },
        base_location_address: 'Gurugram, Haryana, India',
        exit_reason: 'Better offer',
        date_of_exit: '',
        updated_date_time: '2023-05-26T06:55:47.000Z',
        role_id: 7,
    },
    {
        id: 1153,
        vendor_name: 'CONCORD LOGISTICS & SERVICES',
        first_name: 'MUKESH',
        last_name: 'LPL',
        fe_id: 'CLS021153',
        mobile: '9205890407',
        state: 'Haryana',
        city: 'Gurugram',
        base_latitude: '28.4002515',
        base_longitude: '77.04498443',
        lab: {
            lab_code: 'S02',
            center_city: 'Gurugram',
        },
        base_location_address: '',
        exit_reason: '',
        date_of_exit: '',
        updated_date_time: '2023-05-24T11:44:51.000Z',
        role_id: 2,
    },
    {
        id: 1284,
        vendor_name: 'CONCORD LOGISTICS & SERVICES',
        first_name: 'DFEDCFCEDCWCSD',
        last_name: 'DDCVDF',
        fe_id: 'CLM141284',
        mobile: '3545654645',
        state: 'Andhra Pradesh',
        city: 'Visakhapatnam',
        base_latitude: '52.52478',
        base_longitude: '13.33023',
        lab: {
            lab_code: '',
            center_city: '',
        },
        base_location_address: 'Telangana Association of Germany, Gotzkowskystraße 26, 10555 Berlin, Germany',
        exit_reason: '',
        date_of_exit: '',
        updated_date_time: '2023-05-23T09:13:11.000Z',
        role_id: 1,
    },
];
exports.feSampleHistory = [
    {
        latitude: '17.440081',
        longitude: '78.348915',
    },
    {
        latitude: '17.4559',
        longitude: '78.4252',
    },
    {
        latitude: '17.4486',
        longitude: '78.3908',
    },
    {
        latitude: '17.437462',
        longitude: '78.448288',
    },
    {
        latitude: '17.4239',
        longitude: '78.4738,',
    },
    {
        latitude: '17.389673',
        longitude: '78.496014',
    },
    {
        latitude: '17.399272',
        longitude: '78.46271',
    },
    {
        latitude: '17.338',
        longitude: '78.3826',
    },
];
var markerInformationSingleFe = function (pointeInfo) {
    var visit_id = pointeInfo.visit_id;
    var center_name = (0, array_helpers_1.isValueEmpty)(pointeInfo.center_name);
    var latitude = (0, array_helpers_1.isValueEmpty)(pointeInfo.latitude);
    var longitude = (0, array_helpers_1.isValueEmpty)(pointeInfo.longitude);
    var warehouse_code = (0, array_helpers_1.isValueEmpty)(pointeInfo.warehouse_code);
    var created_date_time = (0, array_helpers_1.dateTimeFormatterWithSeconds)(pointeInfo.created_date_time);
    return "<div classname='marker-info'>\n ".concat(visit_id && visit_id != 0 ? "<p>Visit ID:".concat(visit_id, "</p>") : '', "\n ").concat(center_name && center_name != '--' ? "<p>Center Name:".concat(center_name, "</p>") : '', "\n    <p>").concat('FE Visited Time:', " ").concat(created_date_time, "</p>\n <p>Latitude:").concat(latitude, "</p><p>Longitude:").concat(longitude, "</p>\n ").concat(warehouse_code && warehouse_code != '--'
        ? "<p>Warehouse Code:".concat(warehouse_code, "</p>")
        : '', "\n  </div>");
};
exports.markerInformationSingleFe = markerInformationSingleFe;
var markerInformation = function (pointeInfo) {
    var FEId = (0, array_helpers_1.isValueEmpty)(pointeInfo.id);
    var feName = (0, array_helpers_1.isValueEmpty)(pointeInfo.first_name);
    var latitude = (0, array_helpers_1.isValueEmpty)(pointeInfo.base_latitude);
    var longitude = (0, array_helpers_1.isValueEmpty)(pointeInfo.base_longitude);
    var baselocation = (0, array_helpers_1.isValueEmpty)(pointeInfo.base_location_address);
    return "<div classname='marker-info'>\n    <p>FE ID:".concat(FEId, "</p>\n    <p>Name:").concat(feName, "</p>\n    <p>Location:").concat(baselocation, "</p>\n <p>Latitude:").concat(latitude, "</p><p>Longitude:").concat(longitude, "</p>\n  </div>");
};
exports.markerInformation = markerInformation;
var FEMarkerEvents = function (marker, startEndInfo, ui, isSingleFe) {
    marker.addEventListener('tap', function (evt) {
        var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
            content: isSingleFe
                ? (0, exports.markerInformation)(startEndInfo)
                : (0, exports.markerInformationSingleFe)(startEndInfo),
        });
        ui.addBubble(bubble);
    }, false);
};
exports.FEMarkerEvents = FEMarkerEvents;
var convertFEList = function (res) {
    var feList = [];
    if (res && res.length > 0) {
        res.map(function (val) {
            if (val.base_latitude &&
                !lodash_1.default.isNaN(Number(val.base_latitude)) &&
                val.base_longitude &&
                !lodash_1.default.isNaN(Number(val.base_longitude))) {
                feList.push(val);
            }
        });
    }
    return feList;
};
exports.convertFEList = convertFEList;
