"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Avatar_1 = require("@mui/material/Avatar");
var AvatarGroup_1 = require("@mui/material/AvatarGroup");
var Grid_1 = require("@mui/material/Grid");
var moment = require("moment");
var React = require("react");
require("react-alice-carousel/lib/scss/alice-carousel.scss");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var generic_api_calls_1 = require("../../generic_api_calls");
var audit_helpers_1 = require("./audit_helpers");
var audit_status_button_1 = require("./audit_status_button");
var pending_auditee_1 = require("./pending_auditee");
var linearScale_1 = require("./questions/linearScale");
var multiple_choice_1 = require("./questions/multiple_choice");
var ShortAnswerQuestion_1 = require("./questions/ShortAnswerQuestion");
var single_choice_1 = require("./questions/single_choice");
var AuditDetails = function () {
    var id = (0, react_router_dom_1.useParams)().id;
    var _a = React.useState(), auditDetails = _a[0], setAuditDetails = _a[1];
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.audit);
    var auditDetailsList = function () {
        (0, audit_helpers_1.auditDetailsInfo)(id)
            .then(function (response) {
            setAuditDetails(response);
            console.log(response, 'reasons list');
        })
            .catch(function (err) {
            console.log(err.message);
        });
    };
    var autoGenerateReportHandler = function () {
        var payload = {};
        payload['audit_id'] = id;
        (0, audit_helpers_1.auditGenerate)(payload)
            .then(function (response) {
            console.log(response.data, 'ygvvghg');
            if (response.data.statusCode === 200) {
                if (response.data.message != 'Success') {
                    react_hot_toast_1.default.error(response.data.message);
                }
                else {
                    setOpen(true);
                }
            }
        })
            .catch(function (err) {
            console.log(err.message);
        });
        ;
    };
    var navigateToMyReports = function () {
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var isDownloadEnable = function () {
        if (features[menu_constants_1.FEATURE_CONSTANTS.auditDownload]) {
            if ((auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.attempted_auditees_cnt) > 0) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    };
    React.useEffect(function () {
        auditDetailsList();
    }, []);
    var navigate = (0, react_router_dom_1.useNavigate)();
    var navigationHandler = function () {
        navigate(-1);
    };
    return (React.createElement("div", { className: "vendor-profile-details fe-wrapper page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper  border-bottom no-filter-toolbar" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigationHandler }),
                React.createElement("h2", null, "Audit Details")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { type: "button", buttonName: 'Download', endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler, color: "yellow-outline", isError: isDownloadEnable() }))),
        React.createElement(Grid_1.default, { container: true, xs: 12, className: "details-tab-wrapper scroll-xs" },
            React.createElement(Grid_1.default, { item: true, xs: 12, lg: 8 },
                React.createElement("div", { className: "details-wrapper vendor-details-wrapper" },
                    React.createElement("div", { className: "user-card displayflex" },
                        React.createElement("div", { className: "user-details-wrapper displayflex " },
                            React.createElement("div", { className: "user-name-wrapper" },
                                React.createElement("p", { className: "user-name" }, auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.name),
                                React.createElement("span", { className: "user-id" },
                                    ' ',
                                    moment(new Date()).format(appConstants_1.fixedDateTimeFormatt),
                                    ' '),
                                React.createElement("p", { className: "audit-desc" }, auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.description))),
                        React.createElement(audit_status_button_1.default, { status: (0, generic_api_calls_1.getAuditStatus)(auditDetails
                                ? auditDetails.status.toString()
                                : '0') })),
                    React.createElement("div", { className: "user-dashboard-wrapper border-top" },
                        React.createElement("div", { className: "user-kpi-wrapper" },
                            React.createElement("span", { className: "text-grey" }, "Targeted Users (Auditee)"),
                            React.createElement("div", { className: "avatr-group-wrapper audit-target" },
                                React.createElement(AvatarGroup_1.default, { max: 4 },
                                    React.createElement(Avatar_1.default, { className: "bg-yellow" }, auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.target_users)))),
                        React.createElement("div", { className: "user-kpi-wrapper" },
                            React.createElement("span", { className: "text-grey" }, "Number of Questions"),
                            React.createElement("h2", { className: "text-lg" }, (auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.questions_count)
                                ? auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.questions_count
                                : 0)),
                        React.createElement("div", { className: "user-kpi-wrapper" },
                            React.createElement("span", { className: "text-grey" }, "Total Number of attempt(s)"),
                            React.createElement("h2", { className: "text-lg" }, "".concat((auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.attempted_auditees_cnt)
                                ? auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.attempted_auditees_cnt
                                : 0, "/").concat((auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.total_auditees_cnt)
                                ? auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.total_auditees_cnt
                                : 0))),
                        React.createElement("div", { className: "user-kpi-wrapper" },
                            React.createElement("span", { className: "text-grey" }, "Completion Ratio"),
                            React.createElement("h2", { className: "text-lg" }, "".concat((auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.completion_ratio)
                                ? auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.completion_ratio
                                : 0, "%"))))),
                React.createElement("div", { className: "question-summary-details small-overflow-hide" },
                    React.createElement("form", { className: "input-fileds-wrapper" }, auditDetails === null || auditDetails === void 0 ? void 0 : auditDetails.questions.map(function (question, key) {
                        return question.question_type ===
                            'Multiple Choice' ? (React.createElement(multiple_choice_1.default, { name: question.name, is_image_required: question.is_image_required, question_type: question.question_type, questionNum: key + 1, numeric: question.numeric, options: question.options, images: question.images, _id: question._id, totalAttempts: auditDetails.attempted_auditees_cnt })) : question.question_type ===
                            'Single Choice' ? (React.createElement(single_choice_1.default, { name: question.name, is_image_required: question.is_image_required, question_type: question.question_type, questionNum: key + 1, numeric: question.numeric, options: question.options, totalAttempts: auditDetails.attempted_auditees_cnt, images: question.images, _id: question._id })) : question.question_type ===
                            'Linear Scale' ? (React.createElement(linearScale_1.default, { name: question.name, is_image_required: question.is_image_required, question_type: question.question_type, questionNum: key + 1, numeric: question.numeric, options: question.options, totalAttempts: auditDetails.attempted_auditees_cnt, images: question.images, rating_options: question === null || question === void 0 ? void 0 : question.rating_options, _id: question._id })) : (React.createElement(ShortAnswerQuestion_1.default, { name: question.name, is_image_required: question.is_image_required, question_type: question.question_type, questionNum: key + 1, numeric: question.numeric, options: question.options, totalAttempts: auditDetails.attempted_auditees_cnt, images: question.images, _id: question._id }));
                    })))),
            React.createElement(Grid_1.default, { item: true, xs: 10, lg: 4, className: "trends-wrapper " },
                React.createElement("div", { className: "small-overflow-hide" },
                    React.createElement("div", { className: "fe-list-conatainer audit-aside  kpi-content " },
                        React.createElement(pending_auditee_1.default, { id: id }))))),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = AuditDetails;
