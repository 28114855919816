"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Card_1 = require("@mui/material/Card");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var api_urls_1 = require("../../../api/api_urls");
var array_helpers_1 = require("../../../utils/array_helpers");
var api_service_1 = require("../api/api_service");
var report_dashboard_service_1 = require("../service/report_dashboard_service");
var out_station_pending_box_1 = require("./out_station_pending_box");
var out_station_received_box_1 = require("./out_station_received_box");
var OustStationMasterBoxGraph = function (_a) {
    var endDate = _a.endDate, isDateRangeUpdated = _a.isDateRangeUpdated, startDate = _a.startDate, labList = _a.labList, labCode = _a.labCode, updatedTime = _a.updatedTime, setUpdatedTIme = _a.setUpdatedTIme, filterSelectedCities = _a.filterSelectedCities, setTableData = _a.setTableData, setOpen = _a.setOpen, setTableColumns = _a.setTableColumns, filterButton = _a.filterButton;
    var _b = React.useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = React.useState(null), listDataRecieved = _c[0], setListDataReceived = _c[1];
    var _d = React.useState(null), listDataPending = _d[0], setListDataPending = _d[1];
    var _e = React.useState([]), seriesDataForRecived = _e[0], setSeriesDataForReceived = _e[1];
    var _f = React.useState([]), seriesDataForPending = _f[0], setSeriesDataForPending = _f[1];
    var _g = React.useState([]), cumulativeSeriesReceived = _g[0], setCumulativeSetiesRecieved = _g[1];
    var _h = React.useState([]), cumulativeSeriesPending = _h[0], setCumulativeSetiesPending = _h[1];
    var refetch = (0, react_query_1.useQuery)(['bagging-lifecycle'], function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (labCode.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(labCode, labList);
            payload['lab_codes'] = selectedlabCode;
        }
        payload['page'] = 1;
        payload['size'] = Infinity;
        (0, api_service_1.fetchDashboardData)(api_urls_1.api_urls.oustationMasterBoxList, payload, process.env.APPROVAL_BASEURL)
            .then(function (response) {
            setErrorMessage('');
            if (response && response.result && response.result.length > 0) {
                var activeSampleData = (0, report_dashboard_service_1.filterOutStationMasterBoxResponse)(response.result);
                var data = (0, report_dashboard_service_1.filterDataBasedOnTimeOutStationBox)(activeSampleData);
                setSeriesDataForReceived(data.seriesData);
                setListDataReceived(data);
                var cumulativeList = (0, report_dashboard_service_1.outstationMasterBoxCumulative)(data.seriesData);
                setCumulativeSetiesRecieved(cumulativeList);
                var activePendingSampleData = (0, report_dashboard_service_1.filterOutStationPendingMasterBoxResponse)(response.result);
                var pendingData = (0, report_dashboard_service_1.filterDataBasedOnTimeOutStationBox)(activePendingSampleData);
                setSeriesDataForPending(pendingData.seriesData);
                setListDataPending(pendingData);
                var cumulativeListPending = (0, report_dashboard_service_1.outstationMasterBoxCumulative)(pendingData.seriesData);
                setCumulativeSetiesPending(cumulativeListPending);
            }
            else {
                setSeriesDataForReceived([]);
                setListDataReceived(null);
                setCumulativeSetiesRecieved([]);
                setSeriesDataForPending([]);
                setListDataPending(null);
                setCumulativeSetiesPending([]);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.toast.error(err.message);
            setErrorMessage(err.message);
        });
    }).refetch;
    React.useEffect(function () {
        refetch();
        setUpdatedTIme(new Date().toLocaleString());
    }, [filterButton]);
    return (React.createElement("div", { className: "overview-content grid-card-height" },
        React.createElement(material_1.Grid, { container: true, spacing: 3 },
            React.createElement(material_1.Grid, { item: true, xs: 12, lg: 12 },
                React.createElement(Card_1.default, { className: "dash-board-card" },
                    React.createElement(material_1.CardHeader, { title: 'Received Boxes', subheader: "Last updated : ".concat(updatedTime) }),
                    React.createElement(material_1.CardContent, null,
                        React.createElement(out_station_received_box_1.default, { errorMessage: errorMessage, seriesData: seriesDataForRecived, setOpen: setOpen, setTableData: setTableData, listData: listDataRecieved, cumulativeSeries: cumulativeSeriesReceived, setTableColumns: setTableColumns })))),
            React.createElement(material_1.Grid, { item: true, xs: 12, lg: 12 },
                React.createElement(Card_1.default, { className: "dash-board-card" },
                    React.createElement(material_1.CardHeader, { title: 'Pending Boxes', subheader: "Last updated : ".concat(updatedTime) }),
                    React.createElement(material_1.CardContent, null,
                        React.createElement(out_station_pending_box_1.default, { errorMessage: errorMessage, seriesData: seriesDataForPending, setOpen: setOpen, setTableData: setTableData, listData: listDataPending, cumulativeSeries: cumulativeSeriesPending, setTableColumns: setTableColumns })))))));
};
exports.default = OustStationMasterBoxGraph;
