"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_multi_select_component_1 = require("react-multi-select-component");
var appConstants_1 = require("../../constants/appConstants");
require("./multi_select.scss");
var MultiSelector = function (_a) {
    var dropDownList = _a.dropDownList, label = _a.label, value = _a.value, requiredClass = _a.requiredClass, size = _a.size, key = _a.key, onChangeHnadlre = _a.onChangeHnadlre, onSelect = _a.onSelect, hasSelectAll = _a.hasSelectAll, noOptions = _a.noOptions, isDisabled = _a.isDisabled, search = _a.search, searchable = _a.searchable, disableSearch = _a.disableSearch, disableClearIcon = _a.disableClearIcon;
    var customValueRenderer = function (selected, _options) {
        var selectedValues = selected.length && selected.map(function () { return "".concat(selected[0] + selected.length); });
        var length = selectedValues.length >= 2
            ? " & ".concat(selectedValues.length - 1, "  Others")
            : '';
        return (selectedValues.length &&
            "".concat(JSON.stringify(selected[0].label)
                .replace('"', '')
                .replace('"', ''), " ").concat(length));
    };
    var filterOnSearch = function (options, filter) {
        if (!filter || filter.length < 3) {
            return !dropDownList ? [] : dropDownList.slice(0, appConstants_1.maxDropdownOptions);
        }
        var filterOptions = dropDownList.filter(function (listitem) {
            return listitem.label.toUpperCase().includes(filter.trim().toUpperCase());
        });
        return filterOptions.length > appConstants_1.maxDropdownOptions
            ? filterOptions.slice(1, appConstants_1.maxDropdownOptions)
            : filterOptions;
    };
    return (React.createElement("div", { className: "react-multi-select ".concat(size, " ").concat(requiredClass, " ").concat(isDisabled ? 'disable-mutli-selector' : '') },
        React.createElement(react_multi_select_component_1.MultiSelect, { key: key, options: !dropDownList ? [] : dropDownList.slice(0, appConstants_1.maxDropdownOptions), value: value || [], onChange: onChangeHnadlre, labelledBy: "Select", valueRenderer: customValueRenderer, overrideStrings: {
                selectSomeItems: label,
                selectAllFiltered: 'Select All',
                noOptions: noOptions ? noOptions : 'No data found ',
            }, disableSearch: dropDownList && dropDownList.length > 0 ? false : true, hasSelectAll: hasSelectAll ? false : true, disabled: isDisabled, closeOnChangedValue: false, filterOptions: function (options, filter) { return filterOnSearch(options, filter); }, ClearIcon: false })));
};
exports.default = MultiSelector;
