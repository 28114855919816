"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUTO_ROUTING_VISIT_TYPE = exports.AUTO_ROUTING_VISIT_STATUS = exports.dynamicPickupResponseConverter = exports.CONTROL_DASHBOARD_TABLE_LABLES = exports.CONTROL_DASHBOARD_LABELS = void 0;
exports.CONTROL_DASHBOARD_LABELS = {
    totalActiveClients: 'total active clients(Axapta)',
    UntaggedClients: 'Approved Untagged clients',
    activeCCPSc: "Approved CC's/PSC's",
    active_PUP: 'Approved PUP’s',
    totalScheduledPickups: 'total scheduled Pickups',
    assignedDynamicPickups: 'Assigned Dynamic Pickups',
    assignedStaticPickups: 'Assigned Static Pickups',
    unassignedDynamicPickups: 'Unassigned Dynamic Pickups',
    unassignedStaticPickups: 'Unassigned Static Pickups',
    totalInactiveClients: 'Total Inactive Clients(Serviceable)',
    activeNonRouteClients: 'Approved Non Route Clients',
    activeDynamicClients: 'Approved Dynamic Clients',
    activeStaticClients: 'Approved Static Clients',
    approvedClients: 'Clients (Serviceable)',
    notApprovedClients: 'Clients (Non Serviceable Groups)',
    ccPscUntagged: 'Approved CC/PSC`s Untagged',
    ccPscDynamic: 'Approved CC/PSC`s Dynamic',
    ccPscNonRoute: 'Approved CC/PSC`s Non Route',
    ccPscStatic: 'Approved CC/PSC`s Static',
    pupUntagged: 'Approved PUP`s Untagged',
    pupDynamic: 'Approved PUP`s Dynamic',
    pupNonRoute: 'Approved PUP`s Non Route',
    pupStatic: 'Approved PUP`s Static',
    AssignedUSPickups: 'Assigned Ultra Static Pickups',
    UnassignedUSPickups: 'Unassigned Ultra Static Pickups',
    PupUSBreakdown: 'Approved PUP`s Ultra Static',
    ccPscBreakdown: 'Approved CC/PSC`S Ultra Static ',
    ActiveUSClients: 'Approved Ultra Static Clients',
    totalFE: 'Total FE',
    AvailableFEAsPerRoster: 'Available FE’s As Per Roster',
    UnavailableFEsasperRoster: 'Unavailable FEs as per Roster',
    AvailableDynamicFEs: 'Available Dynamic FEs',
    AvailableStaticFEs: 'Available Static FEs',
    Productivity: 'Productivity',
    DynamicRouteProductivity: 'Dynamic Route Productivity',
    StaticRouteProductivity: 'Static Route Productivity',
    UltrastaticFe: 'Available Ultra Static FE',
    LoggedInDynamic: 'Logged In Dynamic',
    LoggedInStatic: 'Logged In Static',
    LoggedInUltraStatic: 'Logged In Ultra Static',
    LoggedInUsers: 'Logged In Users',
    LoggedOutUsers: 'Logged Out Users',
    OnDemandPickups: 'On Demand Pickups',
    OnDemandPickupsAssigned: 'On Demand Pickups Assigned',
    OnDemandPickupUnAssigned: 'On Demand Pickup UnAssigned',
    OnDemandProductivity: 'On Demand Productivity',
    OnDemandReject: 'On Demand Reject',
    OnDemandProcessingPickups: 'On Demand Processing Pickups',
    totalPickups: 'total Pickups',
    TotalPendingPickups: 'Total Pending Pickups',
    TotalCompletedPickups: 'Total Completed Pickups',
    ProductivePickups: 'Productive Pickups',
    NothingtoPickups: 'Nothing to Pickups',
    DelayedPickups: 'Delayed Pickups',
    DelayedDynamicPickups: 'Delayed Dynamic Pickups',
    DelayedStaticPickups: 'Delayed Static Pickups',
    AtRiskPendingPickups: 'At Risk Pending Pickups',
    OnTimeLabHandover: 'On Time Lab Handover %',
    OnTimePickup: 'On Time Pickups  %',
    DelayedUSPickups: 'Delayed Ultra Static Pickups',
};
exports.CONTROL_DASHBOARD_TABLE_LABLES = {
    totalFE: 'Total FE',
    totalPickUps: 'Total Pickups',
    availableFEAsperRoster: "Available FE's As Per Roster",
    unAvailableFEAsperRoster: "Unavailable FE's As Per Roster",
    availableDynamicFE: "Available Dynamic FE's",
    availableStaticFE: "Available Static FE's",
    loggedOutUsers: 'Logged Out Users',
    onDemandPickups: 'On Demand Pickups',
    onDemandPickupAssigned: 'On Demand Pickup Assigned',
    onDemandPickupUnassigned: 'On Demand Pickup UnAssigned',
    onDemandProductivity: 'On Demand Productivity',
    onDemandReject: 'On Demand Reject',
    onDemandProcessingPickups: 'On Demand Processing Pickups',
    totalActiveClients: 'total active clients(Axapta)',
    untaggedClients: 'Approved Untagged clients',
    activeCCorPsc: "Active CC/Psc's",
    activePUP: "Active PUP's",
    totalScheduledPickups: 'Total Scheduled Pickups',
    assignedDynamicPickups: 'Assigned Dynamic Pickups',
    assignedStaticPickups: 'Assigned Static Pickups',
    unAssignedDynamicPickups: 'Unassigned Dynamic Pickups',
    unassignedStaticPickups: 'Unassigned Static Pickups',
    totalPickups: 'Total Pickups',
    totalPendingPickups: 'Total Pending Pickups',
    totalCompletedPickups: 'Total Completed Pickups',
    productivePickups: 'Productive Pickups',
    nothingToPickups: 'Nothing To Pickups',
    delayedPickups: 'Delayed Pickups',
    delayedDynamicPickups: 'Delayed Dynamic Pickups',
    delayedStaticPickups: 'Delayed Static Pickups',
    atRiskPendingPickups: 'AT Risk Pending Pickups',
    onTimePickupsPercentage: 'On Time Pickups %',
    onTimeLabHandover: 'On Time Lab Handover %',
};
var dynamicPickupResponseConverter = function (params) {
    var convertedData = [];
    params.map(function (obj) {
        var convertedObj = {};
        return ((convertedObj['fe_code'] = obj.fe_code),
            (convertedObj['invoice_code'] = obj.invoice_code),
            (convertedObj['mobile_no'] = obj.mobile_no),
            (convertedObj['center_state'] = obj.center_state),
            (convertedObj['center_city'] = obj.center_city),
            (convertedObj['mobile'] = obj.mobile),
            (convertedObj['center_name'] = obj.center_name),
            (convertedObj['fe_id'] = obj.fe_id),
            (convertedObj['name'] = obj.fe_name),
            (convertedObj['time_window'] = obj.time_window),
            (convertedObj['warehouse_code'] = obj.warehouse_code),
            (convertedObj['lab_code'] = obj.lab_code),
            (convertedObj['visit_id'] = obj.visit_id),
            (convertedObj['visit_status'] = obj.visit_status),
            convertedData.push(convertedObj));
    });
    return convertedData;
};
exports.dynamicPickupResponseConverter = dynamicPickupResponseConverter;
exports.AUTO_ROUTING_VISIT_STATUS = {
    0: 'Pending',
    1: 'Assigned',
    2: 'Started',
    3: 'Reached',
    4: 'Completed',
    5: 'Reassigned',
    6: 'Rejected',
    7: 'Unassigned',
    8: 'Processing',
};
exports.AUTO_ROUTING_VISIT_TYPE = {
    1: 'PICKUP',
    2: 'HUB_RECEIVING',
    3: 'HANDOVER_HUB',
    4: 'HANDOVER_LAB',
    5: 'CASH_HANDOVER',
    6: 'MASTER_BOX_PICKUP',
    7: 'INTERCITY_HANDOVER',
    8: 'INTERCITY_PICKUP',
    9: 'HUB_RETURN'
};
