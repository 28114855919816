"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid/Grid");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var FE_Creation_action_1 = require("../../../actions/FE_Creation_action");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var dropdownFields_1 = require("../../../constants/dropdownFields");
var FECreationTabConstant_1 = require("../../../constants/FECreationTabConstant");
var formFieldConstants_1 = require("../../../constants/formFieldConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var generic_api_calls_1 = require("../../generic_api_calls");
var lodash_1 = require("./../../../utils/lodash");
var FE_DrivingLiecenseDetailsForm1 = function (_a) {
    var initialValues = _a.initialValues, saveFormData = _a.saveFormData, fepersonalDetails = _a.fepersonalDetails, updateValue = _a.updateValue, value = _a.value, FE_DrivingLicenceDetailsInitialState = _a.FE_DrivingLicenceDetailsInitialState, sendItForApproval = _a.sendItForApproval, onBoardingStatus = _a.onBoardingStatus, FEIdToBeReplaced = _a.FEIdToBeReplaced, vendorCode = _a.vendorCode;
    var dispatch = (0, react_redux_1.useDispatch)();
    var user_id = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer; }).user_id;
    var nav = (0, react_router_dom_1.useNavigate)();
    var editUserId = (0, react_router_dom_1.useParams)().id;
    var location = (0, react_router_dom_1.useLocation)();
    var uploadFile = function (val) {
        console.log('inside fileUpload', val);
        var payload = new FormData();
        payload.append('cc_id', vendorCode);
        payload.append('file', val);
        payload.append('file_type', 'Other');
        return (0, generic_api_calls_1.fileUpload)(payload);
    };
    var renderInputFiled = function (field, values, setFieldValue, setFieldTouched, errors, touched, setTouched) {
        var _a, _b, _c;
        console.log('errors & touched', errors, touched);
        if (!dropdownFields_1.dropdownFields.includes(field.name)) {
            return (React.createElement(React.Fragment, null,
                React.createElement(field.inputComponent, { name: field.name, className: 'text-caps', type: field.name === 'uploadPhoto'
                        ? 'file'
                        : field.name === 'DateOfBirth'
                            ? 'date'
                            : 'text', buttonName: field.label, placeholder: field.placeholder, required: field.required, label: field.label, swicthLabel: field.swicthLabel, value: values[field.fieldValue], defaultVal: field.defaultVal, isPastDisable: field.isPastDisable, isFutureDisable: field.isFutureDisable, isDisabled: ((_a = values['vehicleType']) === null || _a === void 0 ? void 0 : _a.id) === 4 && field.name != 'vehicleType'
                        ? true
                        : field.isDisabled, isReadOnly: ((_b = values['vehicleType']) === null || _b === void 0 ? void 0 : _b.id) === 4 ? true : field.isDisabled, require: field.require, acceptedFiletypes: field.acceptedFiletypes, maxSize: field.maxSize, message: field.message, deleteUploadedImage: function () { return setFieldValue(field.fieldValue, ''); }, onChange: function (val) {
                        console.log('val:------------------------', val);
                        if (field.type === 'fileupload') {
                            console.log('val:', val);
                            setFieldValue(field.fieldValue, val);
                            setFieldTouched(field.fieldValue, true);
                        }
                        else {
                            if (field.type != 'dateselection') {
                                setFieldValue(field.fieldValue, val);
                            }
                            else {
                                setFieldValue(field.fieldValue, val);
                            }
                            setFieldTouched(field.fieldValue, true);
                        }
                    } }),
                errors[field.fieldValue] && touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue])) : null));
        }
        else {
            var vehicleTypeList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.vehicleTypeList; });
            return (React.createElement(React.Fragment, null,
                React.createElement(field.inputComponent, { name: field.name, type: "text", dropDownList: field.name === 'vehicleType' ? vehicleTypeList : [], placeholder: field.placeholder, swicthLabel: field.swicthLabel, dateState: field.dateState, required: field.required, label: field.label, value: values[field.fieldValue], defaultVal: field.defaultVal, isDisabled: ((_c = values['vehicleType']) === null || _c === void 0 ? void 0 : _c.id) === 4 && field.name != 'vehicleType'
                        ? true
                        : field.isDisabled, acceptedFiletypes: field.acceptedFiletypes, maxSize: field.maxSize, onChange: function (val) {
                        console.log('selected value:==================', val);
                        if (field.name === 'vehicleType' && val.id === 4) {
                            setFieldValue('drivingLicence', '');
                            setFieldValue('insuranceNumber', '');
                            setFieldValue('uploadInsuranceDoc', '');
                            setFieldValue('insuranceExpirydate', '');
                            setFieldValue('drivingLicenceExpirydate', '');
                            setFieldValue('proofOfDrivingLicence', '');
                        }
                        setFieldValue(field.fieldValue, val);
                        setFieldTouched(field.fieldValue, true);
                        setTouched({
                            drivingLicence: false,
                            insuranceNumber: false,
                            uploadInsuranceDoc: false,
                            insuranceExpirydate: false,
                            drivingLicenceExpirydate: false,
                            proofOfDrivingLicence: false,
                        }, true);
                    } }),
                errors[field.fieldValue] && touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue])) : null));
        }
    };
    var formSubmitHnadler1 = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, personalDetailsObj, drivingLicenceDetails_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('values inside driving liecence details:', values, fepersonalDetails);
                    if (lodash_1.default.isEqual(values, initialValues)) {
                        updateValue(FECreationTabConstant_1.CreationTabConstants.EMPLOYMENTDETAILS);
                        if (location.pathname.includes('edit')) {
                            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.EMPLOYMENTDETAILS), { replace: true });
                        }
                        else {
                            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.EMPLOYMENTDETAILS), { replace: true });
                        }
                        return [2];
                    }
                    if (!(onBoardingStatus === 1)) return [3, 1];
                    saveFormData(values, false);
                    return [3, 10];
                case 1:
                    payload = {};
                    personalDetailsObj = {};
                    personalDetailsObj['first_name'] = fepersonalDetails.firstName
                        .trim()
                        .toUpperCase();
                    personalDetailsObj['last_name'] = fepersonalDetails.lastName
                        .trim()
                        .toUpperCase();
                    personalDetailsObj['dob'] =
                        fepersonalDetails.dob != '' &&
                            fepersonalDetails.dob != null &&
                            fepersonalDetails.dob != undefined
                            ? moment(fepersonalDetails.dob).format('YYYY-MM-DD')
                            : '';
                    personalDetailsObj['mobile'] = fepersonalDetails.mobileNumber;
                    personalDetailsObj['email'] = fepersonalDetails.email
                        .trim()
                        .toUpperCase();
                    personalDetailsObj['avatar'] = fepersonalDetails.uploadPhoto;
                    personalDetailsObj['emergency_mobile'] =
                        fepersonalDetails.emergencyContactNo;
                    personalDetailsObj['reference_name1'] =
                        fepersonalDetails.referenceContactName1.trim().toUpperCase();
                    personalDetailsObj['reference_name2'] =
                        fepersonalDetails.referenceContactName2.trim().toUpperCase();
                    personalDetailsObj['reference_mobile1'] =
                        fepersonalDetails.referenceMobileNumber1;
                    personalDetailsObj['reference_mobile2'] =
                        fepersonalDetails.referenceMobileNumber2;
                    drivingLicenceDetails_1 = {};
                    drivingLicenceDetails_1['vehicle_type'] =
                        values.vehicleType != null ? values.vehicleType.id : '';
                    if (!(values.vehicleType != null)) return [3, 9];
                    if (!(values.vehicleType.id != 4)) return [3, 8];
                    if (!(typeof values.uploadInsuranceDoc === 'object')) return [3, 3];
                    return [4, uploadFile(values.uploadInsuranceDoc).then(function (res) {
                            if (res.data.statusCode === 200) {
                                drivingLicenceDetails_1['insurance_photo'] = res.data.data.name;
                            }
                            else {
                                drivingLicenceDetails_1['insurance_photo'] = '';
                            }
                        })];
                case 2:
                    _a.sent();
                    return [3, 4];
                case 3:
                    drivingLicenceDetails_1['insurance_photo'] =
                        values.uploadInsuranceDoc;
                    _a.label = 4;
                case 4:
                    if (!(typeof values.proofOfDrivingLicence === 'object')) return [3, 6];
                    return [4, uploadFile(values.proofOfDrivingLicence).then(function (res) {
                            if (res.data.statusCode === 200) {
                                drivingLicenceDetails_1['driving_licence_doc'] =
                                    res.data.data.name;
                            }
                            else {
                                drivingLicenceDetails_1['driving_licence_doc'] = '';
                            }
                        })];
                case 5:
                    _a.sent();
                    return [3, 7];
                case 6:
                    drivingLicenceDetails_1['driving_licence_doc'] =
                        values.proofOfDrivingLicence;
                    _a.label = 7;
                case 7:
                    drivingLicenceDetails_1['driving_licence'] = values.drivingLicence
                        .trim()
                        .toUpperCase();
                    drivingLicenceDetails_1['insurance'] = values.insuranceNumber
                        .trim()
                        .toUpperCase();
                    drivingLicenceDetails_1['insurance_expiry'] =
                        values.insuranceExpirydate != '' &&
                            values.insuranceExpirydate != null &&
                            values.insuranceExpirydate != undefined
                            ? moment(values.insuranceExpirydate).format('YYYY-MM-DD')
                            : '';
                    drivingLicenceDetails_1['driving_licence_expiry'] =
                        values.drivingLicenceExpirydate !== '' &&
                            values.drivingLicenceExpirydate !== null &&
                            values.drivingLicenceExpirydate !== undefined
                            ? moment(values.drivingLicenceExpirydate).format('YYYY-MM-DD')
                            : '';
                    return [3, 9];
                case 8:
                    drivingLicenceDetails_1['insurance_photo'] = '';
                    drivingLicenceDetails_1['driving_licence_doc'] = '';
                    drivingLicenceDetails_1['driving_licence'] = '';
                    drivingLicenceDetails_1['insurance'] = '';
                    drivingLicenceDetails_1['insurance_expiry'] = '';
                    drivingLicenceDetails_1['driving_licence_expiry'] = '';
                    _a.label = 9;
                case 9:
                    if (FEIdToBeReplaced) {
                        payload['personal_details'] = personalDetailsObj;
                        payload['driving_licence_details'] = drivingLicenceDetails_1;
                        payload['is_final_submit'] = false;
                        payload['user_id'] = user_id;
                        payload['is_replacement'] = true;
                        payload['replacement_fe_id'] = parseInt(FEIdToBeReplaced);
                    }
                    else {
                        payload['personal_details'] = personalDetailsObj;
                        payload['driving_licence_details'] = drivingLicenceDetails_1;
                        payload['is_final_submit'] = false;
                        payload['user_id'] = user_id;
                    }
                    api_service_1.api_service
                        .post({
                        endPoint: api_service_1.api_service.api_urls.FE_CREATION,
                        payLoad: payload,
                        domain: process.env.VENDOR_BASE_URL,
                    })
                        .then(function (res) {
                        if (res.data.statusCode === 200) {
                            react_hot_toast_1.default.success(res.data.message);
                            dispatch((0, FE_Creation_action_1.updateUserID)({
                                user_id: res.data.data.user_id,
                            }));
                            saveFormData(values, false);
                            if (location.pathname.includes('edit')) {
                                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(res.data.data.user_id, "/").concat(FECreationTabConstant_1.CreationTabConstants.EMPLOYMENTDETAILS));
                                updateValue(FECreationTabConstant_1.CreationTabConstants.EMPLOYMENTDETAILS);
                            }
                            else {
                                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(res.data.data.user_id, "/").concat(FECreationTabConstant_1.CreationTabConstants.EMPLOYMENTDETAILS));
                                updateValue(FECreationTabConstant_1.CreationTabConstants.EMPLOYMENTDETAILS);
                            }
                        }
                        else {
                            react_hot_toast_1.default.error(res.data.message);
                        }
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.data.errors);
                    });
                    _a.label = 10;
                case 10: return [2];
            }
        });
    }); };
    var backButtonHandler = function () {
        if (onBoardingStatus === 1) {
            updateValue(value - 1);
        }
        else {
            if (location.pathname.includes('edit')) {
                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS));
                updateValue(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS);
            }
            else {
                nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS));
                updateValue(FECreationTabConstant_1.CreationTabConstants.BANKACCOUNTDETAILS);
            }
        }
    };
    return (React.createElement("div", { className: "form-filed-wrapper" },
        React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", null, "Driving Licence details"),
            React.createElement("p", { className: "sub-heaidng" }, "Please update the FE's drving licence details")),
        React.createElement(formik_1.Formik, { enableReinitialize: true, validateOnBlur: false, initialValues: __assign({}, initialValues), validationSchema: schema_1.schemas.FE_DrivingLicenceDetails_Schema, onSubmit: function (values, _a) {
                var resetForm = _a.resetForm;
                formSubmitHnadler1(values);
            } }, function (_a) {
            var errors = _a.errors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, resetForm = _a.resetForm, setFieldTouched = _a.setFieldTouched, setTouched = _a.setTouched;
            return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 }, formFieldConstants_1.FE_DrivingLicenceDetails_Fields &&
                    formFieldConstants_1.FE_DrivingLicenceDetails_Fields.map(function (section) {
                        return (React.createElement(React.Fragment, null,
                            React.createElement(Grid_1.default, { item: true, xs: 9 },
                                React.createElement("h2", { className: "label-heading heading" },
                                    section.title,
                                    section.description && (React.createElement("p", { className: "sub-heaidng label-sub-heading" }, section.description)))),
                            section.fields.map(function (field, index) {
                                return (React.createElement(Grid_1.default, { item: true, key: index, xs: field.type === 'fileupload' ? 9 : 5, "xs-offset": field.type === 'fileupload' ? 1 : 0 }, renderInputFiled(field, values, setFieldValue, setFieldTouched, errors, touched, setTouched)));
                            })));
                    })),
                React.createElement("div", { className: "form-btns" },
                    React.createElement(button_component_1.default, { type: "button", buttonName: "Back", color: "yellow-outline", onClickHandler: backButtonHandler }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: 'Save & Continue', color: "yellow", isError: false }),
                    React.createElement(button_component_1.default, { type: "button", buttonName: 'Reset', color: "yellow", isError: user_id !== null &&
                            user_id !== undefined &&
                            JSON.stringify(values) !== JSON.stringify(initialValues)
                            ? false
                            : true, onClickHandler: function () { return resetForm({ values: initialValues }); } }))));
        }),
        React.createElement("div", null)));
};
exports.default = FE_DrivingLiecenseDetailsForm1;
