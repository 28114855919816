"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.differenceBetweenDatesMaxValidation = exports.differenceBetweenDatesMinValidation = void 0;
var moment = require("moment");
var differenceBetweenDatesMinValidation = function (startDate, endDate, inTermsOf, minVal) {
    var formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    var formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    return (moment(formattedEndDate, 'YYYY-MM-DD').diff(moment(formattedStartDate, 'YYYY-MM-DD'), inTermsOf) > minVal);
};
exports.differenceBetweenDatesMinValidation = differenceBetweenDatesMinValidation;
var differenceBetweenDatesMaxValidation = function (startDate, endDate, inTermsOf, maxVal) {
    var formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    var formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    return (moment(formattedEndDate, 'YYYY-MM-DD').diff(moment(formattedStartDate, 'YYYY-MM-DD'), inTermsOf) < maxVal);
};
exports.differenceBetweenDatesMaxValidation = differenceBetweenDatesMaxValidation;
