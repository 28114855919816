"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropdownFields = void 0;
exports.dropdownFields = [
    'state',
    'permenentState',
    'permenentCity',
    'permenentCountry',
    'city',
    'country',
    'tenure',
    'fe_Type',
    'company',
    'labCode',
    'FEIdToBeRelaced',
    'vehicleType',
    'zone',
    'user_Role',
    'genderType',
    'addressProofType',
    'permanentProofOfAddressType',
    'HudId',
    'assign_route',
    'fe_working_state',
    'fe_working_city'
];
