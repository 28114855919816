"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../common/buttons/button_component");
var tabs_component_1 = require("../../common/tabs/tabs_component");
var appConstants_1 = require("../../constants/appConstants");
var route_path_1 = require("../../constants/route_path");
var tabs_data_1 = require("../../constants/tabs_data");
var menu_constants_1 = require("../../menuList/menu_constants");
var routes_list_1 = require("../../routes/routes_list");
var array_helpers_1 = require("../../utils/array_helpers");
var RegionMapping = function () {
    var nav = (0, react_router_dom_1.useNavigate)();
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var _a = React.useState(false), isListReloaded = _a[0], setIsListReloaded = _a[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Region_Management);
    console.log(features, 'activeTabfeatures');
    var filteredTabs = (0, array_helpers_1.disableTabs)(tabs_data_1.TABS_DATA.REGION_MAPPING_TABS_LIST, features);
    var _b = React.useState(appConstants_1.defaultPageLimit), PageLimit = _b[0], setPageLimit = _b[1];
    var RegionReportRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.Region_Management, routes_list_1.REGION_REPORT_SUB_ROUES);
    console.log(RegionReportRoutes, 'activeTab', PageLimit);
    var tabChangeHandler = function (val) {
        setPageLimit(appConstants_1.defaultPageLimit);
    };
    var createNewUser = function () {
        nav("".concat(route_path_1.default.PATHS.region_create));
    };
    return (React.createElement("div", { className: "orderlist-main-wrapper mis-reports-page" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", { className: "title" }, pathname.includes('userRegion') ? 'User Region Mapping' : 'Region Management')),
            pathname.includes('userRegion') ?
                ''
                : React.createElement(button_component_1.default, { color: "yellow", buttonName: "Create Region", onClickHandler: createNewUser })),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement(tabs_component_1.default, { tabList: filteredTabs, tabChangeHandler: tabChangeHandler }),
            React.createElement(react_router_dom_1.Routes, null, RegionReportRoutes &&
                RegionReportRoutes.map(function (features) {
                    return (React.createElement(react_router_dom_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                                isListReloaded: isListReloaded,
                                setIsListReloaded: setIsListReloaded,
                            } },
                            React.createElement(features.component, null)) }));
                })))));
};
exports.default = RegionMapping;
