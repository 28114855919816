"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TABS_DATA = void 0;
var route_path_1 = require("./route_path");
var FE_TABS_LIST = [
    {
        id: 1,
        lableName: "ACTIVE FE'S",
        path: "".concat(route_path_1.PATHS.activeList),
        fieldName: 'FE_List',
    },
    {
        id: 2,
        lableName: 'ONBOARDING IN-PROGRESS',
        path: "".concat(route_path_1.PATHS.draftedFeList),
        fieldName: 'FE_Onboard_In-progress_List',
    },
    {
        id: 3,
        lableName: "EXIT FE'S",
        path: "".concat(route_path_1.PATHS.feExisted),
        fieldName: 'FE_Exit_List',
    },
    {
        id: 4,
        lableName: 'ON LEAVE TODAY',
        path: "".concat(route_path_1.PATHS.feOnLeave),
        fieldName: 'FE_On_Leave_List',
    },
    {
        id: 5,
        lableName: 'NEW FE-APPROVAL PENDING',
        path: "".concat(route_path_1.PATHS.feApprovalPending),
        fieldName: 'FE_Approval_Pending_List',
    },
    {
        id: 6,
        lableName: 'FE INVENTORY-PENDING',
        path: "".concat(route_path_1.PATHS.invetoryPending),
        fieldName: 'FE_Inventory_Pending',
    },
    {
        id: 7,
        lableName: "IN-ACTIVE FE'S",
        path: "".concat(route_path_1.PATHS.feInActiveList),
        fieldName: 'FE_In-active_List',
    },
    {
        id: 8,
        lableName: "REJECTED FE'S",
        path: "".concat(route_path_1.PATHS.feRejected),
        fieldName: 'FE_Rejected_List',
    },
];
var VENDOR_TABS_LIST = [
    {
        id: 1,
        lableName: 'ACTIVE VENDORS',
        path: "".concat(route_path_1.PATHS.activeList),
        fieldName: 'Vendor_List',
    },
    {
        id: 2,
        lableName: 'ONBOARDING IN-PROGRESS',
        path: "".concat(route_path_1.PATHS.draft_vendors),
        fieldName: 'Vendor_Onboarding_In-progress_List',
    },
    {
        id: 3,
        lableName: 'EXIT VENDORS',
        path: "".concat(route_path_1.PATHS.exit_vendors),
        fieldName: 'Vendor_Exit_List',
    },
    {
        id: 4,
        lableName: 'LEVEL-1 APPROVAL',
        path: "".concat(route_path_1.PATHS.vendor_pending_approval),
        fieldName: 'Vendor_Approval_Pending_List',
    },
    {
        id: 5,
        lableName: 'LEVEL-2 APPROVAL',
        path: "".concat(route_path_1.PATHS.vendor_level2),
        fieldName: 'Vendor_Approval_Pending_List',
    },
    {
        id: 6,
        lableName: 'VENDOR REJECTED',
        path: "".concat(route_path_1.PATHS.vendor_reject),
        fieldName: 'Vendor_Rejected_List',
    },
];
var FE_PAYROLE_TABS_LIST = [
    {
        id: 1,
        lableName: 'PAY RUNS',
        path: "/".concat(route_path_1.PATHS.payroll),
    },
    {
        id: 2,
        lableName: 'HISTORY',
        path: "".concat(route_path_1.PATHS.payroleHistory),
    },
];
var FE_DETAILS__TABS_LIST = [
    {
        id: 1,
        lableName: 'Track FE',
        path: "".concat(route_path_1.PATHS.trackFe),
        fieldName: 'FE_Track',
    },
    {
        id: 2,
        lableName: 'KPI’s',
        path: "".concat(route_path_1.PATHS.feKpis),
        fieldName: 'FE_KPIs',
    },
    {
        id: 3,
        lableName: 'Profile Details',
        path: "".concat(route_path_1.PATHS.feProfileDetails),
        fieldName: 'FE_Profile',
    },
];
var USER_PROFILE_DETAILS = [
    {
        id: 1,
        lableName: 'Profile Details',
        path: "".concat(route_path_1.PATHS.userDetials),
    },
];
var ETRF_DETAILS = [
    {
        id: 1,
        lableName: 'Transaction Details (Manual TRF)',
        path: "etrfprofile",
        fieldName: 'ETRF_View',
    },
];
var USERMASTERS_TABS_LIST = [
    {
        id: 1,
        lableName: ' FE MASTERS',
        path: "/".concat(route_path_1.PATHS.userMasters),
    },
    {
        id: 2,
        lableName: ' CC MASTERS',
        path: "".concat(route_path_1.PATHS.ccMasters),
    },
];
var CONTROL_CENTER_TABS_LIST = [
    {
        id: 1,
        lableName: 'SAMPLE LIST',
        path: "/".concat(route_path_1.PATHS.controlTower),
    },
    {
        id: 2,
        lableName: 'FE DETAILS',
        path: "".concat(route_path_1.PATHS.controCenterFedetails),
    },
];
var ORDERS_LIST_TABS_LIST = [
    {
        id: 1,
        lableName: 'SAMPLE LIST',
        path: "/".concat(route_path_1.PATHS.controlDashboard),
    },
    {
        id: 2,
        lableName: 'FE DETAILS',
        path: "".concat(route_path_1.PATHS.controCenterFedetails),
    },
];
var MIS_REPORTS_TABS_LIST = [
    {
        id: 1,
        lableName: 'EVENT PERFORMANCE',
        path: "".concat(route_path_1.PATHS.eventPerformance),
        fieldName: 'FE_Event_Performance',
    },
    {
        id: 2,
        lableName: 'FE ROUTE MIS',
        path: "".concat(route_path_1.PATHS.feRoute),
        fieldName: 'FE_Route',
    },
];
var FE_APPROVAL_TABs = [
    {
        id: 1,
        lableName: 'LEVEL ONE APPROVAL',
        path: "".concat(route_path_1.PATHS.levelOnePendingApproval),
        fieldName: 'FE_Approval_Level_1_Pending_List',
    },
    {
        id: 2,
        lableName: 'LEVEL TWO APPROVAL',
        path: "".concat(route_path_1.PATHS.levelTwoPendingApproval),
        fieldName: 'FE_Approval_Level_2_Pending_List',
    },
];
var VENDOR_APPROVAL_TABs = [
    {
        id: 1,
        lableName: 'LEVEL ONE APPROVAL',
        path: "".concat(route_path_1.PATHS.levelOnePendingApproval),
        fieldName: 'Vendor_Approval_Level_1_Pending_List',
    },
    {
        id: 2,
        lableName: 'LEVEL TWO APPROVAL',
        path: "".concat(route_path_1.PATHS.levelTwoPendingApproval),
        fieldName: 'Vendor_Approval_Level_2_Pending_List',
    },
];
var CRE_TABS_LIST = [];
var HEAT_MAP_TABS = [
    {
        id: 1,
        lableName: 'ROUTES',
        path: "/".concat(route_path_1.PATHS.ROUTE_MAP),
    },
    {
        id: 2,
        lableName: 'SHOW POLYLINE',
        path: "".concat(route_path_1.PATHS.POLYLINE_MAP),
    },
];
var VISITS_REPORT_TABS_LIST = [
    {
        id: 1,
        lableName: 'ALL VISITS ',
        path: "/".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.visitsReport),
        fieldName: 'Visits_Report_List',
    },
    {
        id: 2,
        lableName: 'ASSIGNED-COMPLETED',
        path: "".concat(route_path_1.PATHS.assignedCompletedReports),
        fieldName: 'Visits_Report_List',
    },
];
var CLIENT_SLOTWISE_PRODUCTIVITY_REPORTS_TABS_LIST = [
    {
        id: 2,
        lableName: 'Successful Visits',
        path: "/".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.clientSlotwiseProductivityReports, "/").concat(route_path_1.PATHS.nothingTopickup),
        fieldName: 'Visits_Report_List',
    },
];
var REGION_MAPPING_TABS_LIST = [
    {
        id: 1,
        lableName: 'REGIONS',
        path: "/".concat(route_path_1.PATHS.region_mapping, "/").concat(route_path_1.PATHS.region_listing),
        fieldName: 'Region_List',
    },
    {
        id: 2,
        lableName: 'USER REGION MAPPING',
        path: "".concat(route_path_1.PATHS.user_region),
        fieldName: 'Region_List',
    },
];
var ORC_TABS_LIST = [
    {
        id: 1,
        lableName: 'ACTIVE & DEACTIVE',
        path: "".concat(route_path_1.PATHS.orc_active_listing),
        fieldName: 'Active_&_Deactivate_List',
    },
    {
        id: 2,
        lableName: 'LEVEL-1 APPROVAL',
        path: "".concat(route_path_1.PATHS.orc_level1_listing),
        fieldName: 'Pending_List',
    },
    {
        id: 3,
        lableName: 'LEVEL-2 APPROVAL',
        path: "".concat(route_path_1.PATHS.orc_leve2_listing),
        fieldName: 'Pending_List',
    },
    {
        id: 4,
        lableName: 'ORC REJECTED',
        path: "".concat(route_path_1.PATHS.orc_rejected_listing),
        fieldName: 'Rejected_List',
    },
];
var SCF_TABS_LIST = [
    {
        id: 1,
        lableName: 'ACTIVE & DEACTIVE',
        path: "".concat(route_path_1.PATHS.scf_active_listing),
        fieldName: 'Active_&_Deactivate_List',
    },
    {
        id: 2,
        lableName: 'LEVEL-1 APPROVAL',
        path: "".concat(route_path_1.PATHS.scf_level1_listing),
        fieldName: 'Pending_List',
    },
    {
        id: 3,
        lableName: 'LEVEL-2 APPROVAL',
        path: "".concat(route_path_1.PATHS.scf_leve2_listing),
        fieldName: 'Pending_List',
    },
    {
        id: 4,
        lableName: 'SCF REJECTED',
        path: "".concat(route_path_1.PATHS.scf_rejected_listing),
        fieldName: 'Rejected_List',
    },
];
exports.TABS_DATA = {
    FE_TABS_LIST: FE_TABS_LIST,
    FE_PAYROLE_TABS_LIST: FE_PAYROLE_TABS_LIST,
    FE_DETAILS__TABS_LIST: FE_DETAILS__TABS_LIST,
    USER_PROFILE_DETAILS: USER_PROFILE_DETAILS,
    ETRF_DETAILS: ETRF_DETAILS,
    USERMASTERS_TABS_LIST: USERMASTERS_TABS_LIST,
    VENDOR_TABS_LIST: VENDOR_TABS_LIST,
    CONTROL_CENTER_TABS_LIST: CONTROL_CENTER_TABS_LIST,
    ORDERS_LIST_TABS_LIST: ORDERS_LIST_TABS_LIST,
    MIS_REPORTS_TABS_LIST: MIS_REPORTS_TABS_LIST,
    FE_APPROVAL_TABs: FE_APPROVAL_TABs,
    VENDOR_APPROVAL_TABs: VENDOR_APPROVAL_TABs,
    CRE_TABS_LIST: CRE_TABS_LIST,
    HEAT_MAP_TABS: HEAT_MAP_TABS,
    VISITS_REPORT_TABS_LIST: VISITS_REPORT_TABS_LIST,
    REGION_MAPPING_TABS_LIST: REGION_MAPPING_TABS_LIST,
    CLIENT_SLOTWISE_PRODUCTIVITY_REPORTS_TABS_LIST: CLIENT_SLOTWISE_PRODUCTIVITY_REPORTS_TABS_LIST,
    ORC_TABS_LIST: ORC_TABS_LIST,
    SCF_TABS_LIST: SCF_TABS_LIST
};
