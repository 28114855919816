"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../common/buttons/button_component");
require("../../../components/field_exicutive/fe-page");
var route_path_1 = require("../../../constants/route_path");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var control_dashboard_1 = require("../control_dashboard/list/control_dashboard");
var ControlTower = function () {
    var nav = (0, react_router_1.useNavigate)();
    var Controlfeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.controlTower);
    console.log('Controlfeatures:', Controlfeatures);
    var handleExternalButtonClick = function () {
        nav("".concat(route_path_1.default.PATHS.onDemandRequest));
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper fe-mangamnet-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Auto-Routing Dashboard")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { id: "myForm", onClickHandler: handleExternalButtonClick, buttonName: "ON DEMAND CREATION", color: "yellow" }))),
        React.createElement(control_dashboard_1.default, null)));
};
exports.default = ControlTower;
