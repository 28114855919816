"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_router_dom_1 = require("react-router-dom");
var api_urls_1 = require("../../../../api/api_urls");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var vendor_api_service_1 = require("../api/vendor_api_service");
var ActiveVendors = function (_a) {
    var searchValue = _a.searchValue, selectedCities = _a.selectedCities, isFilterButton = _a.isFilterButton;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = React.useState(undefined), activeList = _b[0], setActiveList = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var onRowClickedHandler = function (e) {
        navigate("/".concat(route_path_1.default.PATHS.vendor, "/").concat(route_path_1.default.PATHS.vendorProfileDetials, "/").concat(e.data.axapta_code));
    };
    var fetchActiveVendors = function (endPoint, payLoad, domain) {
        (0, vendor_api_service_1.fetchVednors)(endPoint, payLoad, domain)
            .then(function (response) {
            setActiveList(response);
            setErrorMessage("");
        })
            .catch(function (err) {
            setErrorMessage(err);
            setActiveList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)('active-vendor', function () {
        var payload = (0, array_helpers_1.createPayloadForVendorList)(appConstants_1.VENDOR_STATUS.active, searchValue, selectedCities);
        fetchActiveVendors(api_urls_1.api_urls.VEDNOR_LIST, payload, process.env.ONE_TWO_LEVEL_APPROVAL_URL);
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, [isFilterButton]);
    return (React.createElement("div", null,
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.VENDOR_COLUMNS, rows: activeList && activeList, onRowClicked: onRowClickedHandler, errorMessage: errorMessage, pagination: false, className: 'vendor-table' })));
};
exports.default = ActiveVendors;
