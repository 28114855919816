"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var api_urls_1 = require("../../../api/api_urls");
var react_highchart_1 = require("../../../common/high_charts/react_highchart");
var array_helpers_1 = require("../../../utils/array_helpers");
var api_service_1 = require("../api/api_service");
var report_dashboard_service_1 = require("../service/report_dashboard_service");
var RLabToPLabGraphView = function (_a) {
    var endDate = _a.endDate, isDateRangeUpdated = _a.isDateRangeUpdated, startDate = _a.startDate, labList = _a.labList, rLab = _a.rLab, Plab = _a.Plab, setTableData = _a.setTableData, setUpdatedTIme = _a.setUpdatedTIme, filterSelectedCities = _a.filterSelectedCities, setOpen = _a.setOpen, filterButton = _a.filterButton;
    var _b = React.useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = React.useState(null), lifeCycleList = _c[0], setLifeCycleList = _c[1];
    var _d = React.useState(0), totalSample = _d[0], setTotalSample = _d[1];
    var _e = React.useState([]), seriesData = _e[0], setSeriesData = _e[1];
    var _f = React.useState([]), cumulativeSeries = _f[0], setCumulativeSeties = _f[1];
    var refetch = (0, react_query_1.useQuery)(['r-lab-p-lab'], function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (rLab.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(rLab, labList);
            payload['r_lab_codes'] = selectedlabCode;
        }
        if (Plab.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(Plab, labList);
            payload['p_lab_codes'] = selectedlabCode;
        }
        payload['page'] = 1;
        payload['size'] = Infinity;
        (0, api_service_1.fetchDashboardData)(api_urls_1.api_urls.InterCity_R_and_P_Lab_list, payload, process.env.APPROVAL_BASEURL)
            .then(function (response) {
            if (response && response.result && response.result.length > 0) {
                (0, report_dashboard_service_1.filterSeriesDataForR_lab_P_lab)(response.result, setLifeCycleList, setTotalSample, setSeriesData, setCumulativeSeties);
            }
            else {
                setErrorMessage('');
                setLifeCycleList(null);
                setTotalSample(0);
                setSeriesData([]);
                setCumulativeSeties([]);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.toast.error(err.message);
            setErrorMessage(err.message);
        });
    }).refetch;
    console.log(totalSample);
    React.useEffect(function () {
        refetch();
        setUpdatedTIme(new Date().toLocaleString());
    }, [filterButton]);
    return (React.createElement("div", null, seriesData && seriesData.length > 0 ? (React.createElement(react_highchart_1.default, { chartOptions: {
            chart: {
                type: 'column',
            },
            title: {
                text: null,
            },
            xAxis: {
                labels: {
                    style: {
                        fontSize: 17,
                    },
                },
                categories: report_dashboard_service_1.x_axis_values_for_Rlab_p_lab,
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    style: {
                        fontSize: 17,
                    },
                },
                stackLabels: {
                    enabled: false,
                    style: {
                        fontSize: 17,
                    },
                },
            },
            colors: ['#0080ff', '#ffff00'],
            legend: {
                itemStyle: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                },
                symbolWidth: 40,
                symbolHeight: 10,
                symbolRadius: 0,
                squareSymbol: false,
            },
            tooltip: {
                padding: 20,
                style: {
                    fontSize: '1.1em',
                },
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    pointPadding: 0.1,
                    pointWidth: 50,
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: 15,
                        },
                    },
                },
                series: {
                    lineWidth: 3,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            (0, report_dashboard_service_1.displayTableDataForR_labP_lab)(event.point.category, setTableData, lifeCycleList);
                            setOpen(true);
                        },
                        legendItemClick: function () {
                            return false;
                        },
                    },
                    cumulative: true,
                },
                line: {
                    dataLabels: {
                        format: '{y}%',
                        enabled: true,
                        style: {
                            fontSize: '16px',
                        },
                    },
                    events: {
                        click: function () {
                            setOpen(false);
                        },
                    },
                },
            },
            series: [
                {
                    name: 'No of shipments',
                    data: seriesData,
                },
                {
                    name: 'Cumulative',
                    type: 'line',
                    data: cumulativeSeries,
                },
            ],
        } })) : (React.createElement("div", { className: "no-data-conatiner" },
        React.createElement("div", { className: "no-data-badge" },
            ' ',
            errorMessage ? errorMessage : 'No Data Available')))));
};
exports.default = RLabToPLabGraphView;
