"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationOccuranceOptions = exports.userGroupsOptions = exports.notificationCategoryOptions = void 0;
exports.notificationCategoryOptions = [
    {
        id: 1,
        label: "None",
        value: "None",
    },
    {
        id: 2,
        label: "Emergency",
        value: "Emergency",
    },
    {
        id: 3,
        label: "General",
        value: "General",
    },
    {
        id: 4,
        label: "Important",
        value: "Important",
    },
];
exports.userGroupsOptions = [
    {
        id: 1,
        label: "FE",
        value: "FE",
    },
    {
        id: 2,
        label: "CC",
        value: "CC",
    },
    {
        id: 3,
        label: "CRE",
        value: "CRE",
    },
    {
        id: 4,
        label: "WEB",
        value: "WEB",
    },
];
exports.notificationOccuranceOptions = [
    {
        id: 1,
        label: "Every Monday",
        value: "Every Monday",
    },
    {
        id: 2,
        label: "Semi Monthly",
        value: "Semi Monthly",
    },
    {
        id: 3,
        label: "Monthly",
        value: "Monthly",
    },
    {
        id: 4,
        label: "6 Months",
        value: "6 Months",
    }
];
