"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var maps_api_for_javascript_1 = require("@here/maps-api-for-javascript");
var HereMapUtils = (function () {
    function HereMapUtils(mapInstance) {
        this._platform = new maps_api_for_javascript_1.default.service.Platform({
            apikey: process.env.HERE_MAPS_API_KEY,
        });
        var layers = this._platform.createDefaultLayers();
        var map = new maps_api_for_javascript_1.default.Map(mapInstance, layers.vector.normal.map.setMin(2), {
            pixelRatio: window.devicePixelRatio || 1,
            center: { lat: 25.3176, lng: 82.9739 },
            zoom: 5.8,
        });
        window.addEventListener('resize', function () { return map.getViewPort().resize(); });
        this.setStyle(map);
        var behavior = new maps_api_for_javascript_1.default.mapevents.Behavior(new maps_api_for_javascript_1.default.mapevents.MapEvents(map));
        console.log(behavior);
        this._behaviour = behavior;
        this._mapInstance = map;
        this._ui = maps_api_for_javascript_1.default.ui.UI.createDefault(map, layers);
        this._ui.getControl('zoom').setDisabled(false);
        this._ui.getControl('zoom').setAlignment(maps_api_for_javascript_1.default.ui.LayoutAlignment.BOTTOM_RIGHT);
        var mapSettings = this._ui.getControl('mapsettings');
        var zoom = this._ui.getControl('zoom');
        var scalebar = this._ui.getControl('scalebar');
        mapSettings.setAlignment('bottom-right');
        zoom.setAlignment('bottom-right');
        scalebar.setAlignment('bottom-right');
    }
    Object.defineProperty(HereMapUtils.prototype, "instance", {
        get: function () {
            return this._mapInstance;
        },
        set: function (ins) {
            this._mapInstance = ins;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HereMapUtils.prototype, "behaviour", {
        get: function () {
            return this._behaviour;
        },
        set: function (ins) {
            this._behaviour = ins;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HereMapUtils.prototype, "platform", {
        get: function () {
            return this._platform;
        },
        set: function (ins) {
            this._platform = ins;
        },
        enumerable: false,
        configurable: true
    });
    HereMapUtils.prototype.initiateHereMap = function () { };
    HereMapUtils.prototype.setStyle = function (map) {
        var provider = map.getBaseLayer().getProvider();
        var style = new maps_api_for_javascript_1.default.map.Style('/fonts/normalDay.yaml', 'https://js.api.here.com/v3/3.1.58.0/styles/omv/omv/');
        provider.setStyle(style);
    };
    HereMapUtils.prototype.searchPlaceByAddress = function (address) {
        var _this = this;
        var service = this._platform.getSearchService();
        service.geocode({
            q: address,
        }, function (result) {
            result.items.forEach(function (item) {
                if (_this._markerInstance) {
                    console.log(_this._markerInstance, 'yes');
                    _this._mapInstance.removeObjects(_this._mapInstance.getObjects());
                }
                _this._markerInstance = new maps_api_for_javascript_1.default.map.Marker(item.position);
                _this._mapInstance.addObject(_this._markerInstance);
                _this._mapInstance.setCenter(item.position);
                _this._mapInstance.setZoom(10);
            }, alert);
        });
    };
    HereMapUtils.prototype.searchPlaceByCoOrd = function () { };
    HereMapUtils.prototype.addMarker = function () { };
    HereMapUtils.prototype.addPolyLine = function () { };
    return HereMapUtils;
}());
exports.default = HereMapUtils;
