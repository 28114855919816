"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_circular_progressbar_1 = require("react-circular-progressbar");
require("react-circular-progressbar/dist/styles.css");
require("./progress.scss");
var ProgressBar = function (_a) {
    var styles = _a.styles, value = _a.value;
    return (React.createElement("div", { className: "circular-progress" },
        React.createElement(react_circular_progressbar_1.CircularProgressbar, { value: value, strokeWidth: 12, className: styles })));
};
exports.default = ProgressBar;
