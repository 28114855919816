"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./btn.scss");
var SwitchButtonComponent = function (_a) {
    var value = _a.value, checked = _a.checked, onChangeHandler = _a.onChangeHandler, labelIcon = _a.labelIcon, swicthLabel = _a.swicthLabel, required = _a.required, defaultChecked = _a.defaultChecked, name = _a.name, buttonState = _a.buttonState, isDisabled = _a.isDisabled;
    return (React.createElement("div", { className: "switch-wrapper" },
        labelIcon && React.createElement("span", { className: "".concat(labelIcon, " switch-icon") }),
        React.createElement("div", { className: "switch-label" },
            swicthLabel,
            required ? React.createElement("span", { className: "switch-required" }, "*") : ''),
        React.createElement(material_1.Switch, { defaultChecked: defaultChecked, checked: checked, name: name, disabled: isDisabled, onChange: onChangeHandler, value: value, inputProps: { 'aria-label': 'controlled' }, className: "yellow-switch" }),
        buttonState && React.createElement("span", { className: "switch-label" },
            " ",
            buttonState)));
};
exports.default = SwitchButtonComponent;
