"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var material_1 = require("@mui/material");
var react_router_1 = require("react-router");
var route_path_1 = require("../../../../../constants/route_path");
var AGgrid_table_component_1 = require("../../../../../common/tables/AGgrid_table_component");
var delete_confirmation_modal_1 = require("../../../../../common/confirmation/delete_confirmation_modal");
var api_service_1 = require("../../../../../api/api_service");
var react_query_1 = require("react-query");
var table_data_1 = require("../../../../../constants/table_data");
var react_paginate_1 = require("../../../../../common/react_pagination/react_paginate");
var react_hot_toast_1 = require("react-hot-toast");
var array_helpers_1 = require("../../../../../utils/array_helpers");
var menu_constants_1 = require("../../../../../menuList/menu_constants");
var ModalComponentWithoutButton_1 = require("../../../../../common/modal/ModalComponentWithoutButton");
var button_component_1 = require("../../../../../common/buttons/button_component");
var orc_api_service_1 = require("../../orc_management/orc_api_service");
var scf_service_1 = require("../scf_service");
var scf_api_service_1 = require("../scf_api_service");
var react_redux_1 = require("react-redux");
var SCFActiveList = function (_a) {
    var searchTerm = _a.searchTerm, isFilterButton = _a.isFilterButton, PageLimit = _a.PageLimit;
    var nav = (0, react_router_1.useNavigate)();
    var scf_slabs_list = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).scf_slabs_list;
    var _b = React.useState(false), deleteConfirModal = _b[0], setDeleteConfirModal = _b[1];
    var _c = React.useState(undefined), scfList = _c[0], setScfList = _c[1];
    var _d = React.useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = React.useState(1), pageCount = _e[0], setpageCount = _e[1];
    var _f = React.useState(0), totalPageCount = _f[0], setTotalPageCount = _f[1];
    var _g = React.useState(0), totalCount = _g[0], setTotalCount = _g[1];
    var _h = React.useState(null), selectedScfObj = _h[0], setSelectedScfObj = _h[1];
    var _j = React.useState(false), realoadList = _j[0], setRealoadList = _j[1];
    var _k = React.useState(false), deactiveConfirModal = _k[0], setdeactiveConfirModal = _k[1];
    var _l = React.useState(false), activeConfirModal = _l[0], setActiveConfirModal = _l[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.SCF_Management);
    var checkPaymentMode = function (value) {
        if (value != '' || value != null || value != undefined) {
            if (value == 'DAILY') {
                return 0;
            }
            else if (value == 'MONTHLY') {
                return 1;
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    };
    var checkCourierChargesType = function (value) {
        if (value != '' || value != null || value != undefined) {
            if (value == 'NUMBER') {
                return 0;
            }
            else if (value == 'PERCENTAGE') {
                return 1;
            }
            else if (value == 'SLABS') {
                return 2;
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    };
    var checkPaymentType = function (value) {
        if (value != '' || value != null || value != undefined) {
            if (value == Object.keys(scf_service_1.SCF_PAYMENT_TYPES)[0]) {
                return scf_service_1.SCF_PAYMENT_TYPES.FIXED;
            }
            else if (value == Object.keys(scf_service_1.SCF_PAYMENT_TYPES)[1]) {
                return scf_service_1.SCF_PAYMENT_TYPES.MONTHLY;
            }
            else if (value == Object.keys(scf_service_1.SCF_PAYMENT_TYPES)[2]) {
                return scf_service_1.SCF_PAYMENT_TYPES.PER_DAY;
            }
            else if (value == Object.keys(scf_service_1.SCF_PAYMENT_TYPES)[3]) {
                return scf_service_1.SCF_PAYMENT_TYPES.ON_SALES;
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    };
    var deleteAndDeactiveOpenHandlere = function (event) {
        var evenType = event.event.target.className;
        var data = event.data;
        setSelectedScfObj(data);
        if (evenType.includes('PrivateSwitchBase')) {
            if (data && data.status == scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE.toString()) {
                setdeactiveConfirModal(true);
            }
            else {
                setActiveConfirModal(true);
            }
        }
        else if (evenType.includes('icon-delete_forever')) {
            setDeleteConfirModal(true);
        }
        else if (evenType.includes('icon-Vector')) {
            nav("/".concat(route_path_1.default.PATHS.scf_listing, "/").concat(route_path_1.default.PATHS.scf_profile_details, "/").concat(data.primary_client));
        }
    };
    var updateSCFListService = function (payload, isModalOpened, actionType) {
        (0, scf_api_service_1.updateSCFList)(api_service_1.api_service.api_urls.UPDATE_SCF, payload, process.env.APPROVAL_BASEURL).then(function (res) {
            if (actionType == scf_service_1.SCF_CLIENT_STATUS_OBJ.DELETED) {
                react_hot_toast_1.default.success(res.message);
                setRealoadList(!realoadList);
                setDeleteConfirModal(isModalOpened);
            }
            else {
                if (actionType == scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE) {
                    setActiveConfirModal(isModalOpened);
                }
                else {
                    setdeactiveConfirModal(isModalOpened);
                }
                react_hot_toast_1.default.success(res.message);
                setRealoadList(!realoadList);
            }
        }).catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var deactivateCloseHandlere = function () {
        setdeactiveConfirModal(false);
    };
    var activateCloseHandlere = function () {
        setActiveConfirModal(false);
    };
    var deactivateSubmitHandlere = function () {
        var payload = {
            center_id: selectedScfObj ? selectedScfObj.center_id : 0,
            status: scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE,
            payment_type: checkPaymentType(selectedScfObj.payment_type),
            payment_option: checkPaymentMode(selectedScfObj.payment_option),
            courier_charges_type: checkCourierChargesType(selectedScfObj.courier_charges_type),
        };
        if (Number(selectedScfObj.courier_charges_type == 'SLABS')) {
            payload['slab_one'] = scf_slabs_list && Object.values(scf_slabs_list)[0],
                payload['slab_one_payout'] = Number(selectedScfObj.slab_one_payout),
                payload['slab_two'] = scf_slabs_list && Object.values(scf_slabs_list)[1],
                payload['slab_two_payout'] = Number(selectedScfObj.slab_two_payout),
                payload['slab_three'] = scf_slabs_list && Object.values(scf_slabs_list)[2],
                payload['slab_three_payout'] = Number(selectedScfObj.slab_three_payout);
        }
        else {
            payload['courier_charges'] = Number(selectedScfObj.courier_charges);
        }
        updateSCFListService(payload, false, scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE);
    };
    var activateSubmitHandlere = function (isModalOpened) {
        var payload = {
            center_id: selectedScfObj ? selectedScfObj.center_id : 0,
            status: scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE,
            payment_type: checkPaymentType(selectedScfObj.payment_type),
            payment_option: checkPaymentMode(selectedScfObj.payment_option),
            courier_charges_type: checkCourierChargesType(selectedScfObj.courier_charges_type),
        };
        if (Number(selectedScfObj.courier_charges_type == 'SLABS')) {
            payload['slab_one'] = scf_slabs_list && Object.values(scf_slabs_list)[0],
                payload['slab_one_payout'] = Number(selectedScfObj.slab_one_payout),
                payload['slab_two'] = scf_slabs_list && Object.values(scf_slabs_list)[1],
                payload['slab_two_payout'] = Number(selectedScfObj.slab_two_payout),
                payload['slab_three'] = scf_slabs_list && Object.values(scf_slabs_list)[2],
                payload['slab_three_payout'] = Number(selectedScfObj.slab_three_payout);
        }
        else {
            payload['courier_charges'] = Number(selectedScfObj.courier_charges);
        }
        updateSCFListService(payload, isModalOpened, scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE);
    };
    var deleteSubmitHandlere = function (isModalOpened) {
        var payload = {
            center_id: selectedScfObj ? selectedScfObj.center_id : 0,
            status: scf_service_1.SCF_CLIENT_STATUS_OBJ.DELETED,
            payment_type: checkPaymentType(selectedScfObj.payment_type),
            payment_option: checkPaymentMode(selectedScfObj.payment_option),
            courier_charges_type: checkCourierChargesType(selectedScfObj.courier_charges_type),
        };
        if (Number(selectedScfObj.courier_charges_type == 'SLABS')) {
            payload['slab_one'] = scf_slabs_list && Object.values(scf_slabs_list)[0],
                payload['slab_one_payout'] = Number(selectedScfObj.slab_one_payout),
                payload['slab_two'] = scf_slabs_list && Object.values(scf_slabs_list)[1],
                payload['slab_two_payout'] = Number(selectedScfObj.slab_two_payout),
                payload['slab_three'] = scf_slabs_list && Object.values(scf_slabs_list)[2],
                payload['slab_three_payout'] = Number(selectedScfObj.slab_three_payout);
        }
        else {
            payload['courier_charges'] = Number(selectedScfObj.courier_charges);
        }
        updateSCFListService(payload, isModalOpened, scf_service_1.SCF_CLIENT_STATUS_OBJ.DELETED);
    };
    var deleteCloseHandlere = function (isModalOpened) {
        setDeleteConfirModal(isModalOpened);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var getSCFList = function (endPoint, payLoad, domain) {
        (0, orc_api_service_1.fetchORCList)(endPoint, payLoad, domain)
            .then(function (response) {
            setErrorMessage("");
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            var convertedData = (0, scf_service_1.convertSCFListingAPIResponse)(response.result);
            setScfList(convertedData);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err.message);
            setScfList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['SCF-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {
            search_string: searchTerm,
            page: currentPage,
            size: PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50,
            status: "".concat(scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE, ",").concat(scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE)
        };
        getSCFList(api_service_1.api_service.api_urls.SCF_LISTING, payload, process.env.APPROVAL_BASEURL);
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [isFilterButton, realoadList]);
    return (React.createElement("div", { className: "unclickablerow custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.SCF_ACTIVE_DEACTIVE_LIST_COLUMNS, rows: !features[menu_constants_1.FEATURE_CONSTANTS.SCF_ACTIVATE_DEACTIVATE_LIST] ? scfList : [], pagination: false, errorMessage: !features[menu_constants_1.FEATURE_CONSTANTS.SCF_ACTIVATE_DEACTIVATE_LIST] ? errorMessage : 'Do not have this feature accesss', onRowClicked: deleteAndDeactiveOpenHandlere }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: scfList === null || scfList === void 0 ? void 0 : scfList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }),
        React.createElement(ModalComponentWithoutButton_1.default, { open: deactiveConfirModal },
            React.createElement("div", null,
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("h2", { className: "heading" }, "Are you sure  you want to Deactivate ".concat(selectedScfObj && selectedScfObj.primary_client ? selectedScfObj.primary_client : '--', " warehouse code")))),
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                    React.createElement("h2", null, " Confirmation")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, "Are you sure  you want to Deactivate ".concat(selectedScfObj && selectedScfObj.primary_client ? selectedScfObj.primary_client : '--', " warehouse code"))),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: deactivateCloseHandlere }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow", onClickHandler: deactivateSubmitHandlere }))))),
        React.createElement(delete_confirmation_modal_1.default, { deleteConfirModal: activeConfirModal, deleteOpeneHandlere: activateSubmitHandlere, deleteCloseHandlere: activateCloseHandlere, mainTitle: "Confirmation", subTitle: "Are you sure  you want to Activate ".concat(selectedScfObj && selectedScfObj.primary_client ? selectedScfObj.primary_client : '--', " warehouse code") }),
        React.createElement(delete_confirmation_modal_1.default, { deleteConfirModal: deleteConfirModal, deleteOpeneHandlere: deleteSubmitHandlere, deleteCloseHandlere: deleteCloseHandlere, mainTitle: "Confirmation", subTitle: "Are you sure  you want to delete ".concat(selectedScfObj && selectedScfObj.primary_client ? selectedScfObj.primary_client : '', " warehouse code") })));
};
exports.default = SCFActiveList;
