"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var FE_Creation_action_1 = require("../../../actions/FE_Creation_action");
var api_service_1 = require("../../../api/api_service");
var modal_component_1 = require("../../../common/modal/modal_component");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var table_data_1 = require("../../../constants/table_data");
var array_helpers_1 = require("../../../utils/array_helpers");
var FE_AssignInventoryComponent_1 = require("../Fe_registration/FE_AssignInventoryComponent");
var fe_services_1 = require("../fe_services");
var FEInventoryPending = function (_a) {
    var searchValue = _a.searchValue, selectedCities = _a.selectedCities, CurrentPagelimit = _a.CurrentPagelimit, isFilterButton = _a.isFilterButton;
    var _b = React.useState(undefined), inventoryPending = _b[0], setInventoryPending = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = React.useState(false), displayModal = _d[0], setDisplayModal = _d[1];
    var _e = React.useState(null), selectedFEID = _e[0], setSelectedFEID = _e[1];
    var _f = React.useState(1), pageCount = _f[0], setpageCount = _f[1];
    var _g = React.useState(0), totalPageCount = _g[0], setTotalPageCount = _g[1];
    var _h = React.useState(0), totalCount = _h[0], setTotalCount = _h[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    var handleDisplayModel = function () {
        setDisplayModal(false);
    };
    var fetchPendingForApproval = function (endPoint, payLoad, domain) {
        (0, fe_services_1.fetchFEList)(endPoint, payLoad, domain)
            .then(function (response) {
            setErrorMessage("");
            var pageSize = CurrentPagelimit
                ? parseInt(CurrentPagelimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setInventoryPending(response.result);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setInventoryPending([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['indevntory-pending-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = (0, array_helpers_1.createPayloadForFEList)(appConstants_1.FE_USER_TYPES.inventoryPending, searchValue, selectedCities);
        payload.page = currentPage;
        payload.size = CurrentPagelimit
            ? parseInt(CurrentPagelimit.value.toString())
            : 50;
        fetchPendingForApproval(api_service_1.api_service.api_urls.FE_LIST, payload, process.env.VENDOR_BASE_URL);
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [isFilterButton]);
    var onRowClickedHandler = function (e) {
        console.log('Row Clicked:', e.data);
        setSelectedFEID(e.data.fe_id);
        setDisplayModal(true);
        dispatch((0, FE_Creation_action_1.updateUserID)({
            user_id: e.data.id,
        }));
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    return (React.createElement("div", { className: "custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.FE_COLUMNS, rows: inventoryPending && inventoryPending, errorMessage: errorMessage, onRowClicked: onRowClickedHandler, pagination: false }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: inventoryPending === null || inventoryPending === void 0 ? void 0 : inventoryPending.length, currentPageLimits: CurrentPagelimit === null || CurrentPagelimit === void 0 ? void 0 : CurrentPagelimit.value }),
        React.createElement(modal_component_1.default, { open: displayModal },
            React.createElement("div", { className: "modal-header header-wrapper margin-top0" },
                React.createElement("h2", { className: "heading" }, "Assign Inventory"),
                React.createElement("p", { className: "sub-heading" },
                    "Onboarded Successfully as ID: ",
                    selectedFEID)),
            React.createElement("div", { className: "modal-body small-modal" },
                React.createElement(FE_AssignInventoryComponent_1.default, { handleDisplayModel: handleDisplayModel, FEID: selectedFEID })))));
};
exports.default = FEInventoryPending;
