"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MENU_LIST_DATA = void 0;
var route_path_1 = require("../../constants/route_path");
var menu_constants_1 = require("../../menuList/menu_constants");
exports.MENU_LIST_DATA = [
    {
        id: 2,
        moduleName: 'FE Management',
        menuicon: 'icon-search-user',
        subMenu: [
            {
                id: 1,
                moduleName: 'FE Management',
                path: "".concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.activeList),
                parentModule: 'FE Management',
                menuicon: 'icon-search-user',
                chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.ACTIVE_FE],
            },
            {
                id: 2,
                moduleName: 'Leave Management',
                path: "".concat(route_path_1.PATHS.leaveTraking),
                parentModule: 'FE Management',
                menuicon: 'icon-date',
            },
            {
                id: 3,
                moduleName: 'FE Tracking',
                path: "".concat(route_path_1.PATHS.fe_traking_list),
                parentModule: 'FE Management',
                menuicon: 'icon-date',
            },
        ],
    },
    {
        id: 3,
        moduleName: 'Audits & Approvals',
        menuicon: 'icon-thumbs_up_down',
        subMenu: [
            {
                id: 1,
                moduleName: 'Approvals',
                parentModule: 'Audits & Approvals',
                menuicon: 'icon-thumbs_up_down',
                subMenu: [
                    {
                        id: 1,
                        moduleName: 'FE',
                        path: "".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.fe, "/").concat(route_path_1.PATHS.levelOnePendingApproval),
                        parentModule: 'Audits & Approvals',
                        subModuleParentName: 'Approvals',
                        chaildFetures: [
                            menu_constants_1.FEATURE_CONSTANTS.FE_Approval_Level_1_Pending_List,
                            menu_constants_1.FEATURE_CONSTANTS.FE_Approval_Level_2_Pending_List,
                        ],
                    },
                    {
                        id: 2,
                        moduleName: 'Vendor',
                        path: "".concat(route_path_1.PATHS.approvals, "/").concat(route_path_1.PATHS.vendorlist, "/").concat(route_path_1.PATHS.levelOnePendingApproval),
                        parentModule: 'Audits & Approvals',
                        subModuleParentName: 'Approvals',
                        chaildFetures: [
                            menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Level_1_Pending_List,
                            menu_constants_1.FEATURE_CONSTANTS.Vendor_Approval_Level_2_Pending_List,
                        ],
                    },
                ],
            },
            {
                id: 2,
                moduleName: 'Audit',
                path: "".concat(route_path_1.PATHS.audit),
                menuicon: 'icon-fact_check',
                parentModule: 'Audits & Approvals',
            },
            {
                id: 3,
                moduleName: 'Notification Management',
                path: "".concat(route_path_1.PATHS.notificationManagement),
                parentModule: 'Audits & Approvals',
                menuicon: 'icon-bell',
            },
        ],
    },
    {
        id: 5,
        moduleName: 'Reports',
        menuicon: 'icon-text_snippet',
        subMenu: [
            {
                id: 1,
                moduleName: 'MIS Reports',
                path: "".concat(route_path_1.PATHS.misReport, "/").concat(route_path_1.PATHS.eventPerformance),
                parentModule: 'Reports',
                menuicon: 'icon-assignment',
            },
            {
                id: 2,
                moduleName: 'Reports',
                parentModule: 'Reports',
                subMenu: [
                    {
                        id: 1,
                        moduleName: 'My Reports',
                        path: "".concat(route_path_1.PATHS.MYREPORTS),
                        parentModule: 'Reports',
                        subModuleParentName: 'Reports',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.My_Reports_List],
                    },
                    {
                        id: 2,
                        moduleName: 'Cash Collection Report',
                        path: "".concat(route_path_1.PATHS.cahCollectionReport),
                        parentModule: 'Reports',
                        subModuleParentName: 'Reports',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Cash_Report_List],
                    },
                    {
                        id: 3,
                        moduleName: 'Cash Pending Report',
                        path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.cashPendingReport),
                        parentModule: 'Reports',
                        subModuleParentName: 'Reports',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.FE_Cash_Pending_Report_List],
                    },
                    {
                        id: 4,
                        moduleName: 'Bagging LifeCycle',
                        path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.baggingLifeCycle),
                        parentModule: 'Reports',
                        subModuleParentName: 'Reports',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Bag_Report_List],
                    },
                    {
                        id: 5,
                        moduleName: 'Visits Report',
                        path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.visitsReport),
                        parentModule: 'Reports',
                        subModuleParentName: 'Reports',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Visit_Report_List],
                    },
                    {
                        id: 6,
                        moduleName: 'Client Slotwise Productivity Reports',
                        path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.clientSlotwiseProductivityReports, "/").concat(route_path_1.PATHS.nothingTopickup),
                        parentModule: 'Reports',
                        subModuleParentName: 'Reports',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Clients_Slot_Wise_Productivity],
                    },
                    {
                        id: 7,
                        moduleName: 'FE Attendance Report',
                        path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.feAttendanceReport),
                        parentModule: 'Reports',
                        subModuleParentName: 'Reports',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.FE_Attendance],
                    },
                    {
                        id: 8,
                        moduleName: 'Outstation Masterbox Report ',
                        path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.outstationMasterboxReport),
                        parentModule: 'Reports',
                        subModuleParentName: 'Reports',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Outstation_Master_Box],
                    },
                    {
                        id: 9,
                        moduleName: 'R-Lab to P-Lab INTERCITY Report',
                        path: "".concat(route_path_1.PATHS.reports, "/").concat(route_path_1.PATHS.rLabtoPLabIntercityReport),
                        parentModule: 'Reports',
                        subModuleParentName: 'Reports',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.R_Lab_to_P_Lab_INTERCIT],
                    },
                ],
            },
        ],
    },
    {
        id: 6,
        moduleName: 'Operations Management',
        menuicon: 'icon-text_snippet',
        subMenu: [
            {
                id: 1,
                moduleName: 'ETRF Management',
                path: "".concat(route_path_1.PATHS.ETRFList),
                menuicon: 'icon-create_new_folder',
                parentModule: 'Operations Management',
            },
            {
                id: 2,
                moduleName: 'Heat Maps',
                path: "".concat(route_path_1.PATHS.ROUTE_MAP),
                menuicon: 'icon-my_location',
                parentModule: 'Operations Management',
            },
            {
                id: 3,
                moduleName: 'Route Planner',
                parentModule: 'Operations Management',
                menuicon: 'icon-text_snippet',
                subMenu: [
                    {
                        id: 1,
                        moduleName: 'Planner',
                        path: "".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.route_planner_list),
                        parentModule: 'Operations Management',
                        subModuleParentName: 'Route Planner',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Route_List],
                    },
                    {
                        id: 1,
                        moduleName: 'Approvals',
                        path: "".concat(route_path_1.PATHS.route_planner, "/").concat(route_path_1.PATHS.approvals_route_planner),
                        parentModule: 'Operations Management',
                        subModuleParentName: 'Route Planner',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Route_List],
                    },
                ],
            },
            {
                id: 4,
                moduleName: 'Sample Tracking',
                path: "".concat(route_path_1.PATHS.sampleTracking),
                menuicon: 'icon-moped',
                parentModule: 'Operations Management',
            },
            {
                id: 5,
                moduleName: 'Visit Management',
                menuicon: 'icon-money',
                parentModule: 'Operations Management',
                subMenu: [
                    {
                        id: 1,
                        moduleName: 'Add Visits',
                        path: "".concat(route_path_1.PATHS.Visits, "/").concat(route_path_1.PATHS.AddVisits),
                        parentModule: 'Operations Management',
                        subModuleParentName: 'Visit Management',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.VISITS_Add],
                    },
                    {
                        id: 1,
                        moduleName: 'Visits List',
                        path: "".concat(route_path_1.PATHS.Visits, "/").concat(route_path_1.PATHS.VisitsList),
                        parentModule: 'Operations Management',
                        subModuleParentName: 'Visit Management',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.VISITS_List],
                    },
                ],
            },
            {
                id: 6,
                moduleName: 'Master Box',
                path: "".concat(route_path_1.PATHS.masterBoxtracking),
                menuicon: 'icon-search-user',
                parentModule: 'Operations Management',
            },
            {
                id: 5,
                moduleName: 'Shipment Tracking',
                menuicon: 'icon-routing',
                path: "".concat(route_path_1.PATHS.shipment_tracking),
                parentModule: 'Operations Management',
            },
        ],
    },
    {
        id: 7,
        moduleName: 'Roster Management',
        menuicon: 'icon-text_snippet',
        subMenu: [
            {
                id: 1,
                moduleName: 'Client Roster Management',
                path: "".concat(route_path_1.PATHS.ccworkinghourslist),
                menuicon: 'icon-text_snippet',
                parentModule: 'Roster Management',
            },
            {
                id: 2,
                moduleName: 'FE Roster Management',
                path: "".concat(route_path_1.PATHS.slotBlocking),
                menuicon: 'icon-slot',
                parentModule: 'Roster Management',
            },
            {
                id: 3,
                moduleName: 'Lab Roster Management',
                menuicon: 'icon-text_snippet',
                path: "".concat(route_path_1.PATHS.LabMasterlist),
                parentModule: 'Roster Management',
            },
            {
                id: 4,
                moduleName: 'Hub Roster Management',
                menuicon: 'icon-search-user',
                path: "".concat(route_path_1.PATHS.hubMasterList),
                parentModule: 'Roster Management',
            },
            {
                id: 5,
                moduleName: 'Shipment Route Master',
                menuicon: 'icon-graph',
                path: "".concat(route_path_1.PATHS.shipment_list),
                parentModule: 'Roster Management',
            },
        ],
    },
    {
        id: 8,
        moduleName: 'Master Management',
        menuicon: 'icon-search-user',
        subMenu: [
            {
                id: 1,
                moduleName: 'Admin User Management',
                path: "".concat(route_path_1.PATHS.User_Logistics),
                parentModule: 'Master Management',
                menuicon: 'icon-search-user',
            },
            {
                id: 2,
                moduleName: 'CRE Management',
                menuicon: 'icon-search-user',
                path: "".concat(route_path_1.PATHS.cre),
                parentModule: 'Master Management',
            },
            {
                id: 3,
                moduleName: 'Precious Samples',
                menuicon: 'icon-local_police',
                path: "".concat(route_path_1.PATHS.preciousSamples),
                parentModule: 'Master Management',
            },
            {
                id: 4,
                moduleName: 'Role Management',
                path: "".concat(route_path_1.PATHS.role_management),
                menuicon: 'icon-search-user',
                parentModule: 'Master Management',
            },
            {
                id: 5,
                moduleName: 'User Management',
                menuicon: 'icon-supervisor_account',
                path: "".concat(route_path_1.PATHS.userManagement),
                parentModule: 'Master Management',
            },
            {
                id: 7,
                moduleName: 'Region Management',
                menuicon: 'icon-routing',
                path: "".concat(route_path_1.PATHS.region_mapping, "/").concat(route_path_1.PATHS.region_listing),
                parentModule: 'Master Management',
            },
            {
                id: 6,
                moduleName: 'Vendor Management',
                path: "".concat(route_path_1.PATHS.vendormanagement, "/").concat(route_path_1.PATHS.activeList),
                parentModule: 'Master Management',
                menuicon: 'icon-business_center',
                chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Vendor_List],
            },
            {
                id: 7,
                moduleName: 'ORC Management',
                path: "".concat(route_path_1.PATHS.orc_listing, "/").concat(route_path_1.PATHS.orc_active_listing),
                parentModule: 'Master Management',
                menuicon: 'icon-routing',
                chaildFetures: [
                    menu_constants_1.FEATURE_CONSTANTS.ACTIVATE_DEACTIVATE_LIST,
                    menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_PENDING_LIST,
                    menu_constants_1.FEATURE_CONSTANTS.LEVEL_1_2_REJECTED_LIST
                ]
            },
            {
                id: 8,
                moduleName: 'SCF Management',
                path: "".concat(route_path_1.PATHS.scf_listing, "/").concat(route_path_1.PATHS.scf_active_listing),
                parentModule: 'Master Management',
                menuicon: 'icon-routing',
                chaildFetures: [
                    menu_constants_1.FEATURE_CONSTANTS.SCF_ACTIVATE_DEACTIVATE_LIST,
                    menu_constants_1.FEATURE_CONSTANTS.SCF_LEVEL_1_2_PENDING_LIST,
                    menu_constants_1.FEATURE_CONSTANTS.SCF_LEVEL_1_2_REJECTED_LIST
                ]
            },
        ],
    },
    {
        id: 9,
        moduleName: 'Control Tower',
        menuicon: 'icon-routing',
        subMenu: [
            {
                id: 1,
                moduleName: 'Intracity',
                menuicon: 'icon-create_new_folder',
                parentModule: 'Control Tower',
                subMenu: [
                    {
                        id: 1,
                        moduleName: 'Auto Routing',
                        path: "".concat(route_path_1.PATHS.controlTower),
                        parentModule: 'Control Tower',
                        subModuleParentName: 'Intracity',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Auto_Routing],
                    },
                    {
                        id: 2,
                        moduleName: 'Lab Handover Trend ',
                        path: "".concat(route_path_1.PATHS.labHandoverTrend),
                        parentModule: 'Control Tower',
                        subModuleParentName: 'Intracity',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Lab_Handover_Trend],
                    },
                    {
                        id: 3,
                        moduleName: 'Ontime Pickup',
                        path: "".concat(route_path_1.PATHS.on_time_pickup),
                        parentModule: 'Control Tower',
                        subModuleParentName: 'Intracity',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Ontime_Pickup],
                    },
                    {
                        id: 4,
                        moduleName: 'Intracity TAT',
                        path: "".concat(route_path_1.PATHS.intracity_tat_filters),
                        parentModule: 'Control Tower',
                        subModuleParentName: 'Intracity',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Intracity_TAT],
                    },
                ],
            },
            {
                id: 2,
                moduleName: 'Intercity',
                menuicon: 'icon-create_new_folder',
                parentModule: 'Control Tower',
                subMenu: [
                    {
                        id: 1,
                        moduleName: 'R-Lab to P-Lab INTERCITY',
                        path: "".concat(route_path_1.PATHS.r_lab_to_p_lab),
                        parentModule: 'Control Tower',
                        subModuleParentName: 'Intercity',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.R_Lab_to_P_Lab_INTERCIT],
                    },
                    {
                        id: 2,
                        moduleName: 'Outstation Master Box',
                        path: "".concat(route_path_1.PATHS.outStationMasterBox),
                        parentModule: 'Control Tower',
                        subModuleParentName: 'Intercity',
                        chaildFetures: [menu_constants_1.FEATURE_CONSTANTS.Outstation_Master_Box],
                    },
                ],
            },
        ],
    },
];
