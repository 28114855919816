"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var react_paginate_1 = require("../../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var table_data_1 = require("../../../../constants/table_data");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var route_planner_api_service_1 = require("../api/route_planner_api_service");
var route_planner_form_1 = require("../new_route/route_planner_form");
var route_planner_service_1 = require("../service/route_planner_service");
var RoutePlannerList = function () {
    var _a = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }), labList = _a.labList, routing_assignment_type = _a.routing_assignment_type, route_planner_status_types = _a.route_planner_status_types;
    var pathname = (0, react_router_1.useLocation)().pathname;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState(null), labCode = _c[0], setLabCode = _c[1];
    var _d = React.useState(1), pageCount = _d[0], setpageCount = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var _g = React.useState(appConstants_1.defaultPageLimit), PageLimit = _g[0], setPageLimit = _g[1];
    var _h = React.useState(null), route_type = _h[0], setRoute_type = _h[1];
    var _j = React.useState(null), status = _j[0], setStatus = _j[1];
    var _k = React.useState(false), reloadPage = _k[0], setRealoadPage = _k[1];
    var _l = React.useState(), errorMessage = _l[0], setErrorMessage = _l[1];
    var _m = React.useState(undefined), routePlannerList = _m[0], setRoutePlannerList = _m[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Route_Planner);
    var getRoutePlannerList = function (endPoint, payLoad, domain) {
        (0, route_planner_api_service_1.globalRoutePlannerPostAPI)(endPoint, payLoad, domain)
            .then(function (response) {
            var res = (0, route_planner_service_1.convertRoutePlannerListFromRes)(response);
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setTotalCount(response.count);
            console.log(res.result, 'iuhyg');
            var convertedResponse = (0, array_helpers_1.routeResponseConverter)(res.result);
            setRoutePlannerList(convertedResponse);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setRoutePlannerList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['route_planner_list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = (0, route_planner_service_1.createPayloadForRouteList)(labCode && labCode.value, route_type && route_type.id, status && status.id);
        console.log(payload, 'checkpayload');
        payload.page = currentPage;
        payload.size = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        getRoutePlannerList(api_urls_1.api_urls.ROUTE_PLANNER_LIST, payload, process.env.ONE_TWO_LEVEL_APPROVAL_URL);
    }).refetch;
    var onPageLimitChange = function (val) {
        setPageLimit(val);
    };
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [reloadPage]);
    return (React.createElement("div", { className: "fe-wrapper leave-tarcking-page" },
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null,
                    ' ',
                    pathname.includes("".concat(route_path_1.PATHS.route_planner))
                        ? 'Planner'
                        : 'Approvals',
                    ' ')),
            pathname.includes("".concat(route_path_1.PATHS.route_planner)) ? (React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(iconButton_component_1.default, { icon: "icon-refresh", onClick: function () { return refetch(); } }),
                React.createElement(button_component_1.default, { buttonName: "GENERATE ROUTE", color: "yellow", onClickHandler: function () { return setOpen(true); }, isError: !features[menu_constants_1.FEATURE_CONSTANTS.routeGenerate] }))) : null),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" }),
            React.createElement("div", { className: "filter-options" },
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "labcode", placeholder: "Lab Code", dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList), onChange: function (value) {
                        setLabCode(value);
                    }, value: labCode }),
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "route_type", placeholder: "Route Type", dropDownList: routing_assignment_type && routing_assignment_type.slice(0, 2), onChange: function (value) {
                        setRoute_type(value);
                    }, value: route_type }),
                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "status", placeholder: "Status", dropDownList: route_planner_status_types, onChange: function (value) {
                        setStatus(value);
                    }, value: status }),
                React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: onPageLimitChange, value: PageLimit }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "unclickablerow  custom-pagination-table" },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ROUTE_PLANNER_COLUMNS, rows: routePlannerList && routePlannerList, errorMessage: errorMessage, pagination: false, className: 'planner-table' }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: routePlannerList === null || routePlannerList === void 0 ? void 0 : routePlannerList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value })),
        React.createElement(route_planner_form_1.default, { isModalOpend: open, setOpen: setOpen, setRealoadPage: setRealoadPage })));
};
exports.default = RoutePlannerList;
