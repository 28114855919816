"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var Tab_1 = require("@mui/material/Tab");
var Tabs_1 = require("@mui/material/Tabs");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../api/api_service");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var appConstants_1 = require("../../../constants/appConstants");
var array_helpers_1 = require("../../../utils/array_helpers");
var generic_api_calls_1 = require("../../generic_api_calls");
var AddAudit_1 = require("./AddAudit");
var AssignUsersToDraftAudit_1 = require("./AssignUsersToDraftAudit");
var audit_helpers_1 = require("./audit_helpers");
var ReviewAudit1_1 = require("./ReviewAudit1");
var createAuditInitialState = {
    auditTitle: '',
    auditDescription: '',
    audit_type: { label: audit_helpers_1.AUDIT_TYPE.NORAM_AUDIT, value: 0, id: 0 },
    AuditPhoto: '',
    totalNoOfQuestion: null,
    questions: [],
};
var AssignUsersIntitalState = {
    startDate: '',
    endDate: '',
    country: [],
    state: [],
    city: [],
    labcode: null,
    selectedAuditors: null,
    selectedAuditee: null,
    auditees: [],
    auditors: [],
};
var CreateAudit = function () {
    var _a = React.useState(0), value = _a[0], setValue = _a[1];
    var _b = React.useState(createAuditInitialState), auditDetails = _b[0], setAuditDetails = _b[1];
    var _c = React.useState(AssignUsersIntitalState), assignUsersToAuditDetails = _c[0], setAssignUsersToAuditDetails = _c[1];
    var vendorCode = (0, react_redux_1.useSelector)(function (state) { return state.user; }).vendorCode;
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), countryList = _d.countryList, stateList = _d.stateList, labList = _d.labList;
    var _e = React.useState(false), isAuditSubmitted = _e[0], setIsAuditSubmitted = _e[1];
    var _f = React.useState(''), createdAuditID = _f[0], setCreatedAuditID = _f[1];
    var _g = React.useState(false), isPhleboAudit = _g[0], setIsPhleboAudit = _g[1];
    var editAuditID = (0, react_router_dom_1.useParams)().id;
    var uploadFile = function (val) {
        var payload = new FormData();
        payload.append('cc_id', vendorCode ? vendorCode : 'vendor');
        payload.append('file', val);
        payload.append('file_type', 'Other');
        return (0, generic_api_calls_1.fileUpload)(payload);
    };
    var getAuditDetailsById = function (editAuditID) {
        (0, generic_api_calls_1.fetchAuditDetails)({ survey_id: editAuditID })
            .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
            var auditDetailsFromRes, auditAssignmentDetailsFromRes, questionObjFromRes, assignments_1, selectedCitiesIDs_1, selectedLab, selectedCountries_1, selectedStates_1, selectedStateids_1, selectedAuditeeType, selectedAuditorType, targetAudience, auditTypeLabOrCity, payLoad, selectedAuditeesWarehouseCode_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        auditDetailsFromRes = __assign({}, createAuditInitialState);
                        auditAssignmentDetailsFromRes = __assign({}, AssignUsersIntitalState);
                        auditDetailsFromRes['auditTitle'] = res.data.data.name;
                        auditDetailsFromRes['auditDescription'] = res.data.data.description;
                        auditDetailsFromRes['AuditPhoto'] = res.data.data.image;
                        auditDetailsFromRes['audit_type'] = (0, audit_helpers_1.convertAuditTypes)(res.data.data.audit_type);
                        auditDetailsFromRes['totalNoOfQuestion'] = appConstants_1.totalQuestionsDropdown.find(function (singleOption) {
                            return singleOption.value === res.data.data.questions.length;
                        });
                        questionObjFromRes = [];
                        res.data.data.questions.map(function (singleQuestion) {
                            var _a, _b;
                            var questionObj = {
                                qtype: null,
                                qtitile: '',
                                qoptions: [],
                                isPhotoRequired: false,
                            };
                            questionObj['qtype'] =
                                singleQuestion.question_type === 'Single Choice' &&
                                    singleQuestion.options.length < 4
                                    ? appConstants_1.questionTypeDropdown.find(function (questionType) { return questionType.label === 'True/False'; })
                                    : appConstants_1.questionTypeDropdown.find(function (questionType) {
                                        return questionType.label === singleQuestion.question_type;
                                    });
                            questionObj['qtitile'] = singleQuestion.name;
                            var OptonsForQuestionFromRes = [];
                            if (singleQuestion.question_type != 'Linear Scale') {
                                singleQuestion.options.map(function (singleOption) {
                                    OptonsForQuestionFromRes.push({ option: singleOption.option });
                                });
                            }
                            else {
                                OptonsForQuestionFromRes.push({
                                    option: (_a = singleQuestion.options[0]) === null || _a === void 0 ? void 0 : _a.label1,
                                });
                                OptonsForQuestionFromRes.push({
                                    option: (_b = singleQuestion.options[1]) === null || _b === void 0 ? void 0 : _b.label2,
                                });
                                questionObj['minrange'] = appConstants_1.minDropdownList.find(function (minOption) { return minOption.value === singleQuestion.options[2].from; });
                                questionObj['maxrange'] = appConstants_1.maxDropdownList.find(function (maxOption) { return maxOption.value === singleQuestion.options[3].to; });
                            }
                            if (singleQuestion.question_type === 'Short Answer' || singleQuestion.question_type === 'Mobile Number') {
                                if (singleQuestion.numeric) {
                                    OptonsForQuestionFromRes.push({ option: 'numeric' });
                                }
                                else {
                                    OptonsForQuestionFromRes.push({ option: 'non numeric' });
                                }
                            }
                            questionObj['qoptions'] = OptonsForQuestionFromRes;
                            questionObjFromRes.push(questionObj);
                        });
                        auditDetailsFromRes['questions'] = questionObjFromRes;
                        if (!res.data.data.assignments) return [3, 6];
                        assignments_1 = res.data.data.assignments;
                        auditAssignmentDetailsFromRes['startDate'] = assignments_1.start_date;
                        auditAssignmentDetailsFromRes['endDate'] = assignments_1.end_date;
                        selectedCitiesIDs_1 = [];
                        selectedLab = null;
                        if (!res.data.data.assignments.labs) return [3, 1];
                        selectedLab = (0, array_helpers_1.filterDataByLabel)({
                            filterArray: labList,
                            name: res.data.data.assignments.labs[0],
                        });
                        auditAssignmentDetailsFromRes['labcode'] = selectedLab;
                        return [3, 3];
                    case 1:
                        selectedCountries_1 = [];
                        assignments_1.countries.forEach(function (item) {
                            var singleCountry = (0, array_helpers_1.filterDataByLabel)({
                                filterArray: countryList ? countryList : [],
                                name: item,
                            });
                            if (singleCountry) {
                                selectedCountries_1.push(singleCountry);
                            }
                        });
                        auditAssignmentDetailsFromRes['country'] = selectedCountries_1;
                        selectedStates_1 = [];
                        selectedStateids_1 = [];
                        assignments_1.states.forEach(function (item) {
                            var selectedState = (0, array_helpers_1.filterDataByLabel)({
                                filterArray: stateList ? stateList : [],
                                name: item,
                            });
                            if (selectedState) {
                                selectedStates_1.push(selectedState);
                                selectedStateids_1.push(selectedState.id);
                            }
                        });
                        auditAssignmentDetailsFromRes['state'] = selectedStates_1;
                        if (!(selectedStates_1.length > 0)) return [3, 3];
                        return [4, (0, generic_api_calls_1.fetchCitiesUnderStates)(selectedStateids_1)
                                .then(function (cityListFromStateId) {
                                var selectedCities = (0, array_helpers_1.filterDataArrByLabel)({
                                    filterArray: cityListFromStateId ? cityListFromStateId : [],
                                    namearr: assignments_1.cities,
                                });
                                selectedCities.forEach(function (city) {
                                    return selectedCitiesIDs_1.push({
                                        id: city.id,
                                        city: city.label.split(',')[0],
                                    });
                                });
                                auditAssignmentDetailsFromRes['state'] = selectedStates_1;
                                auditAssignmentDetailsFromRes['city'] = selectedCities;
                            })
                                .catch(function (err) {
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!assignments_1.audit_mappings) return [3, 6];
                        selectedAuditeeType = (0, array_helpers_1.filterDataByLabelInAudit)({
                            filterArray: appConstants_1.targetAuditeeeDropdownList,
                            name: assignments_1.audit_mappings[0].auditee_type,
                        });
                        selectedAuditorType = (0, array_helpers_1.filterDataByLabelInAudit)({
                            filterArray: appConstants_1.targetAuditorsDropdownList,
                            name: assignments_1.audit_mappings[0].auditor_type,
                        });
                        targetAudience = [
                            selectedAuditorType.label,
                            selectedAuditeeType.label,
                        ];
                        auditTypeLabOrCity = res.data.data.assignments.labs ? [selectedLab.value.toString()] : selectedCitiesIDs_1;
                        payLoad = res.data.data.assignments.labs ? {
                            labs: auditTypeLabOrCity,
                            audience_type: targetAudience,
                        } : {
                            cities: auditTypeLabOrCity,
                            audience_type: targetAudience,
                        };
                        selectedAuditeesWarehouseCode_1 = [];
                        return [4, (0, generic_api_calls_1.fetchAudienceUnderCitiesOrLabs)(payLoad)
                                .then(function (res) {
                                var auditeesFromRes = [];
                                assignments_1.audit_mappings[0].auditees.map(function (auditee) {
                                    auditeesFromRes.push(auditee.auditee_id);
                                    selectedAuditeesWarehouseCode_1.push(auditee.auditee_id);
                                });
                                var selectedAuditees = (0, array_helpers_1.filterAuditeesArray)({
                                    filterArray: res ? res : [],
                                    namearr: auditeesFromRes,
                                });
                                auditAssignmentDetailsFromRes['auditees'] = selectedAuditees;
                            })
                                .catch(function (err) {
                                auditAssignmentDetailsFromRes['auditees'] = [];
                            })];
                    case 4:
                        _a.sent();
                        return [4, (0, generic_api_calls_1.fetchAuditorsUnderCC)(selectedAuditeesWarehouseCode_1)
                                .then(function (res) {
                                var auditorsFromRes = [];
                                assignments_1.audit_mappings[0].auditors.map(function (auditor) {
                                    auditorsFromRes.push(auditor);
                                });
                                var seelctedAuditors = (0, array_helpers_1.filterAuditorsArray)({
                                    filterArray: res ? res : [],
                                    namearr: auditorsFromRes,
                                });
                                console.log(seelctedAuditors, 'seelctedAuditors');
                                auditAssignmentDetailsFromRes['auditors'] = seelctedAuditors;
                            })
                                .catch(function (err) {
                                auditAssignmentDetailsFromRes['auditors'] = [];
                            })];
                    case 5:
                        _a.sent();
                        auditAssignmentDetailsFromRes['selectedAuditee'] =
                            selectedAuditeeType;
                        auditAssignmentDetailsFromRes['selectedAuditors'] =
                            selectedAuditorType;
                        _a.label = 6;
                    case 6:
                        setAuditDetails(auditDetailsFromRes);
                        setAssignUsersToAuditDetails(auditAssignmentDetailsFromRes);
                        return [2];
                }
            });
        }); })
            .catch(function (err) {
            react_hot_toast_1.default.error(err);
        });
    };
    var saveAuditHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, questions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = {};
                    if (editAuditID && location.pathname.includes('editAudit')) {
                        payload['audit_id'] = editAuditID;
                    }
                    payload['name'] = auditDetails.auditTitle.trim().toUpperCase();
                    payload['description'] = auditDetails.auditDescription.trim().toUpperCase();
                    if (!(typeof auditDetails.AuditPhoto === 'object')) return [3, 2];
                    return [4, uploadFile(auditDetails.AuditPhoto).then(function (res) {
                            if (res.data.statusCode === 200) {
                                payload['image'] = res.data.data.name;
                            }
                        })];
                case 1:
                    _a.sent();
                    return [3, 3];
                case 2:
                    if (typeof auditDetails.AuditPhoto === 'string') {
                        payload['image'] = auditDetails.AuditPhoto;
                    }
                    _a.label = 3;
                case 3:
                    payload['audit_type'] = auditDetails.audit_type.value;
                    questions = [];
                    auditDetails.questions.map(function (singleQuestion, index) {
                        var _a, _b;
                        var question = {};
                        var uppperCasaeOptions = singleQuestion.qoptions.map(function (option, index) {
                            var upperCaseObj = {
                                option: option.option.trim().toUpperCase(),
                            };
                            return upperCaseObj;
                        });
                        question['name'] = singleQuestion.qtitile.trim().toUpperCase();
                        question['is_image_required'] = singleQuestion.isPhotoRequired;
                        question['question_type'] =
                            singleQuestion.qtype.label === 'True/False'
                                ? 'Single Choice'
                                : singleQuestion.qtype.label;
                        var options = [];
                        var labelArr = ['label1', 'label2'];
                        var linearScaleArr = ['from', 'to', ''];
                        if (singleQuestion.qtype.label === 'Linear Scale') {
                            var fromObj = {};
                            fromObj[linearScaleArr[0]] = (_a = singleQuestion.minrange) === null || _a === void 0 ? void 0 : _a.value;
                            options.push(fromObj);
                            var toObj = {};
                            toObj[linearScaleArr[1]] = (_b = singleQuestion.maxrange) === null || _b === void 0 ? void 0 : _b.value;
                            options.push(toObj);
                            var label1 = {};
                            label1[labelArr[0]] = singleQuestion.qoptions ? singleQuestion.qoptions[0].option : null;
                            var label2 = {};
                            label2[labelArr[1]] = singleQuestion.qoptions ? singleQuestion.qoptions[1].option : null;
                            question['options'] = __spreadArray([label1, label2], options, true);
                        }
                        else if (singleQuestion.qtype.label === 'Short Answer' || singleQuestion.qtype.label === 'Mobile Number') {
                            question['numeric'] =
                                singleQuestion.qoptions[0].option === 'numeric' ||
                                    singleQuestion.qoptions[0].option === 'NUMERIC'
                                    ? true
                                    : false;
                        }
                        else {
                            question['options'] = uppperCasaeOptions;
                        }
                        questions.push(__assign({}, question));
                    });
                    payload['questions'] = questions;
                    api_service_1.api_service
                        .post({
                        endPoint: api_service_1.api_service.api_urls.CREATE_AUDIT,
                        payLoad: payload,
                        domain: process.env.APPROVAL_BASEURL,
                    })
                        .then(function (res) {
                        if (res.data.statusCode === 200 || res.data.statusCode === 201) {
                            react_hot_toast_1.default.success(res.data.message);
                            setIsAuditSubmitted(true);
                            setCreatedAuditID(res.data.data['_id']);
                            setValue(function (val) { return val + 1; });
                        }
                        else {
                            react_hot_toast_1.default.error(res.data.message);
                        }
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.message);
                    });
                    return [2];
            }
        });
    }); };
    React.useEffect(function () {
        if (editAuditID && !isPhleboAudit) {
            getAuditDetailsById(editAuditID);
        }
    }, [isPhleboAudit]);
    var backButtonHandler = function () {
        setValue(function (val) { return val - 1; });
    };
    var TabPanel = function (props) {
        var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
        return (React.createElement("div", __assign({ role: "tabpanel", hidden: value !== index, id: "vertical-tabpanel-".concat(index), "aria-labelledby": "vertical-tab-".concat(index) }, other), value === index && React.createElement("div", { className: "tab-pane-item" }, children)));
    };
    var a11yProps = function (index) {
        return {
            id: "vertical-tab-".concat(index),
            'aria-controls': "vertical-tabpanel-".concat(index),
        };
    };
    var navigate = (0, react_router_dom_1.useNavigate)();
    var navigationHandler = function () {
        navigate(-1);
    };
    var handleChange = function (__event, newValue) {
        if (JSON.stringify(auditDetails) != JSON.stringify(createAuditInitialState)) {
            if (newValue < 2) {
                setValue(newValue);
            }
            else if (editAuditID && location.pathname.includes('editAudit')) {
                setValue(newValue);
            }
            else if (isAuditSubmitted &&
                location.pathname.includes('createAudit')) {
                setValue(newValue);
            }
        }
    };
    var checkAuditDetailsValidation = function () {
        return true;
    };
    return (React.createElement("div", { className: "on-boarding-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper border-bottom" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", onClick: navigationHandler, icon: 'icon-left-arrow' }),
                location.pathname.includes('editAudit') ? (React.createElement("h2", null, "Edit Audit")) : (React.createElement("h2", null, "Create New Audit")))),
        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 } },
            React.createElement(Grid_1.default, { item: true, xs: 4, lg: 3 },
                React.createElement("div", { className: "vertical-tabs-wrapper tabs-aligner audit-tabs" },
                    React.createElement(Tabs_1.default, { orientation: "vertical", variant: "scrollable", value: value, onChange: handleChange, "aria-label": "Vertical tabs example" },
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "Create Audit" }, a11yProps(0), { className: value != 0 &&
                                JSON.stringify(auditDetails) !=
                                    JSON.stringify(createAuditInitialState)
                                ? 'tab-btn submitted'
                                : 'tab-btn ', "aria-label": "Vertical tabs example", value: 0 })),
                        value != 0 &&
                            JSON.stringify(auditDetails) !=
                                JSON.stringify(createAuditInitialState) ? (checkAuditDetailsValidation() ? (React.createElement("span", { className: "form-status-icon icon-double-tick  tab-one-status" }, ' ')) : (React.createElement("span", { className: "form-status-icon icon-warning  tab-one-status" }, ' '))) : (''),
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "Review Audit" }, a11yProps(1), { className: value != 1 && isAuditSubmitted
                                ? 'tab-btn submitted'
                                : 'tab-btn ', "aria-label": "Vertical tabs example", value: 1 })),
                        value != 1 && isAuditSubmitted && (React.createElement("span", { className: "form-status-icon icon-double-tick  tab-two-status" }, ' ')),
                        React.createElement(Tab_1.default, __assign({ key: Math.random(), label: "Assign users" }, a11yProps(1), { className: 'tab-btn ', "aria-label": "Vertical tabs example", value: 2 }))))),
            React.createElement(Grid_1.default, { item: true, xs: 8, lg: 9 },
                React.createElement("div", { className: "scrollable-content form-wrapper" },
                    React.createElement(TabPanel, { value: value, index: 0 }, React.createElement(AddAudit_1.default, { saveAuditDetails: function (values) {
                            setAuditDetails(values);
                            setValue(function (val) { return val + 1; });
                        }, initialValues: auditDetails, setIsPhleboAudit: setIsPhleboAudit })),
                    React.createElement(TabPanel, { value: value, index: 1 }, React.createElement(ReviewAudit1_1.default, { auditdetails: auditDetails, backButtonHandler: backButtonHandler, saveAuditHandler: saveAuditHandler })),
                    React.createElement(TabPanel, { value: value, index: 2 }, React.createElement(AssignUsersToDraftAudit_1.default, { auditID: createdAuditID ? createdAuditID : editAuditID, initialvalues: assignUsersToAuditDetails })))))));
};
exports.default = CreateAudit;
