"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
require("../../../common/buttons/btn.scss");
var button_names_1 = require("../../../constants/button_names");
var AuditStatusButton = function (_a) {
    var type = _a.type, variant = _a.variant, size = _a.size, style = _a.style, status = _a.status, className = _a.className;
    var renderStatusbutton = function () {
        switch (status) {
            case 'INCOMPLETED':
                return (React.createElement("div", { className: "status-badge" },
                    React.createElement("span", { className: "icon-emergency" }),
                    React.createElement("p", null, button_names_1.STATUS_BUTTONS.incompleted)));
            case 'CREATED':
                return (React.createElement("div", { className: "status-badge" },
                    React.createElement("span", { className: "icon-emergency pending " }),
                    React.createElement("p", null, button_names_1.STATUS_BUTTONS.created)));
            case 'ACTIVE':
                return (React.createElement("div", { className: "status-badge" },
                    React.createElement("span", { className: "icon-active " }),
                    React.createElement("p", null, button_names_1.STATUS_BUTTONS.active)));
            case 'COMPLETED':
                return (React.createElement("div", { className: "status-badge" },
                    React.createElement("span", { className: "icon-active " }),
                    React.createElement("p", null, button_names_1.STATUS_BUTTONS.completed)));
            case 'DRAFT':
                return (React.createElement("div", { className: "status-badge" },
                    React.createElement("span", { className: "icon-emergency pending " }),
                    React.createElement("p", null, button_names_1.STATUS_BUTTONS.draft)));
            default:
                return (React.createElement("div", { className: "status-badge" },
                    React.createElement("span", { className: "icon-emergency pending " }),
                    React.createElement("p", null, button_names_1.STATUS_BUTTONS.draft)));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "payment-status-wrapper" }, renderStatusbutton())));
};
exports.default = AuditStatusButton;
