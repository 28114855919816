"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var cc_timeSlots_1 = require("../../../../common/cc_timeslots/cc_timeSlots");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var modal_component_1 = require("../../../../common/modal/modal_component");
var profile_grid_1 = require("../../../../common/profile_grid/profile_grid");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var slotblocking_helper_1 = require("../../../field_exicutive/slot_blocking/slotblocking.helper");
require("../../../field_exicutive/slot_blocking/slot_booking.scss");
var CC_api_calls_1 = require("../../CC/CC_api_calls");
require("../control_center.tsx");
var control_center_api_calls_1 = require("../control_center_api_calls");
var OnDemandRequest = function () {
    var sso_user_details = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).sso_user_details;
    var is_sso_user = (0, react_redux_1.useSelector)(function (state) { return state.user; }).is_sso_user;
    var pathname = (0, react_router_1.useLocation)().pathname;
    var _a = React.useState([]), warehouseCodeList = _a[0], setWarehouseCodeList = _a[1];
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState(false), confirmationModal = _c[0], setConfirmationModal = _c[1];
    var _d = React.useState(0), selectedSlotId = _d[0], setSelectedSlotId = _d[1];
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var nav = (0, react_router_1.useNavigate)();
    var _e = React.useState(), CCName = _e[0], setCCName = _e[1];
    var _f = React.useState([]), existingTimeSlots = _f[0], setExistingTimeSlots = _f[1];
    var _g = React.useState(), onDemandRequestValues = _g[0], setRequestValues = _g[1];
    var _h = React.useState([]), rowData = _h[0], setRowdata = _h[1];
    var staticSlots = JSON.parse(JSON.stringify(slotblocking_helper_1.timeSlots));
    var _j = React.useState(staticSlots), slotsList = _j[0], setSlotsList = _j[1];
    var _k = React.useState([]), existondemandslot = _k[0], setexistondemandslot = _k[1];
    var _l = React.useState(''), monthandYear = _l[0], setMonthandyear = _l[1];
    var _m = React.useState(''), ondemandAllowed = _m[0], setOndemandAllowed = _m[1];
    var _o = React.useState(''), usedCredits = _o[0], setUsedCredits = _o[1];
    var lablistInformation = (0, array_helpers_1.convertLabcodeResponse)(labList);
    var currentdate = new Date().getDay();
    var clearSlots = function () {
        setSlotsList(JSON.parse(JSON.stringify(slotblocking_helper_1.timeSlots)));
    };
    var existingData = function () {
        var arr = [];
        slotsList.map(function (slot) {
            existingTimeSlots &&
                (existingTimeSlots === null || existingTimeSlots === void 0 ? void 0 : existingTimeSlots.map(function (each) {
                    if (each.day === (0, array_helpers_1.getCurrentday)(currentdate)) {
                        if (each['schedule_time_slot'].split(':')[0] ===
                            slot['fromTime'].split(':')[0]) {
                            slot['color'] = 'slot-btn status-exist';
                        }
                    }
                }));
            arr.push(slot);
        });
        setSlotsList(arr);
    };
    React.useEffect(function () {
        existingData();
    }, [existingTimeSlots]);
    var existingOndemandslots = function () {
        var arr = [];
        slotsList.map(function (slot) {
            existondemandslot &&
                (existondemandslot === null || existondemandslot === void 0 ? void 0 : existondemandslot.map(function (each) {
                    if (each['time_window_slot'].split(':')[0] === slot['fromTime'].split(':')[0]) {
                        slot['color'] = 'slot-btn yellow-shadecolor';
                    }
                }));
            arr.push(slot);
        });
        setSlotsList(arr);
    };
    React.useEffect(function () {
        existingOndemandslots();
    }, [existondemandslot]);
    var navigateTOListPage = function () {
        if (pathname.includes("".concat(route_path_1.default.PATHS.sso_ondemandRequest))) {
            nav("/".concat(route_path_1.default.PATHS.sso_ondemandRequest, "/").concat(route_path_1.default.PATHS.onDemandRequest));
        }
        else {
            nav("/".concat(route_path_1.default.PATHS.controlTower));
        }
    };
    var navigateOndemand_creation = function () {
        setConfirmationModal(false);
        setOpen(false);
    };
    var handleOpen = function (values) {
        var isAllValuesFilled = false;
        var arr = Object.values(values);
        arr.forEach(function (item) {
            item === '' ? (isAllValuesFilled = true) : (isAllValuesFilled = false);
        });
        if (isAllValuesFilled) {
        }
        else {
            setOpen(true);
        }
    };
    var handleClose = function () {
        setOpen(false);
    };
    var proceedModal = function () {
        submitOndemanRequest(confirmationModal);
    };
    var proceedModal1 = function () {
        setConfirmationModal(!confirmationModal);
        if (confirmationModal) {
            setOpen(false);
            nav("/".concat(route_path_1.default.PATHS.controlTower));
        }
    };
    var callbackOnselectedLab = function (val) {
        var payload = {
            lab_code: val.value,
            ondemand_request: true,
        };
        (0, CC_api_calls_1.fetchCCTimeSlotList)(payload)
            .then(function (res) {
            var data = (0, array_helpers_1.convertCCListResponse)(res.result);
            setWarehouseCodeList(data);
        })
            .catch(function (e) { return console.log(e); });
    };
    var callbackOnselectedCC = function (val) {
        setSelectedSlotId(0);
        var payload = {
            warehouse_code: val,
        };
        (0, CC_api_calls_1.fetchCCEditList)(payload)
            .then(function (res) {
            var data = res.data.schedule;
            setExistingTimeSlots(data);
            setMonthandyear((0, array_helpers_1.getLatAndLng)(res.data.month, res.data.year));
            setOndemandAllowed(res.data.available_credits);
            setUsedCredits(res.data.used_credits);
        })
            .catch(function (e) { return console.log(e); });
    };
    var callbackCCondemandtimeslots = function (val) {
        setSelectedSlotId(0);
        var payload = {
            warehouse_code: val,
        };
        (0, control_center_api_calls_1.fetchCCOndemandtimeslotList)(payload)
            .then(function (res) {
            var data = res.data.result;
            setexistondemandslot(data);
        })
            .catch(function (e) { return console.log(e); });
    };
    React.useEffect(function () {
        if (is_sso_user) {
            console.log('____LLLLL');
            callbackOnselectedCC(sso_user_details.warehouse_code);
        }
    }, []);
    var submitOndemanRequest = function (confirmationModal) {
        (0, control_center_api_calls_1.submitCCTimeSlot)(onDemandRequestValues)
            .then(function (res) {
            if (res.statusCode == 200) {
                setOpen(false);
                setConfirmationModal(!confirmationModal);
                react_hot_toast_1.default.success(appConstants_1.CreateOnDemandRequest.Add);
                clearSlots();
                if (pathname.includes("".concat(route_path_1.default.PATHS.sso_ondemandRequest))) {
                    nav("/".concat(route_path_1.default.PATHS.sso_ondemandRequest, "/").concat(route_path_1.default.PATHS.onDemandRequest));
                }
                else {
                    nav("/".concat(route_path_1.default.PATHS.controlTower));
                }
            }
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    var formSubmitHandler = function (values) {
        var selectedSlot;
        slotsList.map(function (timeslot) {
            if (timeslot.id === selectedSlotId) {
                selectedSlot = timeslot;
            }
        });
        var payload = {
            warehouse_code: values['selectedCC']['value'],
            time_window_slot: selectedSlot.buttonName,
        };
        setRowdata([
            {
                cc_name: CCName,
                time_slot: selectedSlot.buttonName,
            },
        ]);
        setRequestValues(payload);
    };
    return (React.createElement("div", { className: "block-slot-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateTOListPage }),
                React.createElement("h2", null, "On Demand Request"))),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement("div", { className: "add-slotform" },
                React.createElement("div", { className: "filter-menu-wrapper filter-inside-page" }),
                React.createElement(formik_1.Formik, { initialValues: {
                        state: is_sso_user ? sso_user_details.center_state : '',
                        city: is_sso_user ? sso_user_details.center_city : '',
                        labcode: is_sso_user
                            ? {
                                label: sso_user_details.lab_code,
                                value: sso_user_details.lab_code,
                            }
                            : '',
                        selectedCC: is_sso_user
                            ? {
                                label: sso_user_details.warehouse_code,
                                value: sso_user_details.warehouse_code,
                            }
                            : '',
                        selectedSlots: '',
                    }, validationSchema: schema_1.schemas.OnDemand_Request_Schema, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched, resetForm = _a.resetForm;
                    return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3, rowSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { required: true, name: "labcode", placeholder: "Select Lab Code", dropDownList: lablistInformation, isDisabled: is_sso_user ? true : false, onChange: function (val) {
                                        clearSlots();
                                        setFieldValue('selectedCC', '');
                                        setFieldValue('city', '');
                                        setFieldValue('state', '');
                                        setFieldValue('selectedSlots', '');
                                        setMonthandyear('');
                                        setOndemandAllowed('');
                                        setUsedCredits('');
                                        setFieldValue('labcode', val);
                                        setFieldTouched('labcode', true);
                                        callbackOnselectedLab(val);
                                    }, value: values.labcode }),
                                errors['labcode'] &&
                                    touched['labcode'] &&
                                    values['labcode'] === '' ? (React.createElement("div", { className: "error" }, errors['labcode'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { key: Math.random().toString(), required: true, name: "selectedCC", isDisabled: is_sso_user ? true : false, placeholder: "Select CC", dropDownList: warehouseCodeList && warehouseCodeList, onChange: function (val) {
                                        clearSlots();
                                        setFieldValue('selectedCC', val);
                                        setFieldValue('selectedSlots', '');
                                        setFieldValue('state', val.centre_state);
                                        setFieldValue('city', val.center_city);
                                        callbackOnselectedCC(val.value);
                                        callbackCCondemandtimeslots(val.value);
                                        setFieldTouched('selectedCC', true);
                                        setCCName(val.label);
                                    }, value: values.selectedCC }),
                                errors['selectedCC'] &&
                                    touched['selectedCC'] &&
                                    values['selectedCC'] === '' ? (React.createElement("div", { className: "error" }, errors['selectedCC'])) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { className: "text-caps", placeholder: "State", name: "state", value: values.state, label: "State", isDisabled: true, key: values.state })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { className: "text-caps", placeholder: "City", name: "city", value: values.city, label: "City", isDisabled: true, key: values.city })),
                            React.createElement(Grid_1.default, { item: true, xs: 4 },
                                React.createElement(profile_grid_1.default, { lableName: 'Month & Year', value: monthandYear ? monthandYear : '--' })),
                            React.createElement(Grid_1.default, { item: true, xs: 4 },
                                React.createElement(profile_grid_1.default, { lableName: 'Available On-Demand request', value: ondemandAllowed ? ondemandAllowed : '--' })),
                            React.createElement(Grid_1.default, { item: true, xs: 4 },
                                React.createElement(profile_grid_1.default, { lableName: 'Used On-Demand request', value: usedCredits >= 0 ? usedCredits : '--' })),
                            React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true, container: true },
                                React.createElement(Grid_1.default, { xs: 12, lg: 12, item: true },
                                    React.createElement("div", { className: "days-wrapper" },
                                        React.createElement("h2", { className: "selected-felabel custom-form-label" }, "Select On Demand Request Time Slots")))),
                            React.createElement(Grid_1.default, { item: true, xs: 14, lg: 12 },
                                React.createElement(cc_timeSlots_1.default, { slots: slotsList, setSelectedSlotId: setSelectedSlotId, selectedSlotId: selectedSlotId, setFieldValue: setFieldValue, existingTimeSlots: existingTimeSlots }),
                                errors['selectedSlots'] && touched['selectedSlots'] ? (React.createElement("div", { className: "error" },
                                    errors['selectedSlots'],
                                    " ")) : null,
                                React.createElement("div", { className: "cc-slot-legend align-center" },
                                    React.createElement("div", { className: "legend-item bg-available" }, "Available Time Slots"),
                                    React.createElement("div", { className: "legend-item bg-expired" }, "Expired Time Slots"),
                                    React.createElement("div", { className: "legend-item bg-existing" }, "Regular Time Slots"),
                                    React.createElement("div", { className: "legend-item bg-selected" }, "Selected Time Slots"),
                                    React.createElement("div", { className: "legend-item bg-ondemand" }, "Today Ondemand Request Time Slots")))),
                        React.createElement("div", { className: "page-control-wrapper-elements align-end" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateTOListPage }),
                            React.createElement(modal_component_1.default, { buttonName: "Create", type: "submit", buttonColor: "yellow", onClickHandler: function () {
                                    if (Object.keys(errors).length == 0) {
                                        handleOpen(values);
                                    }
                                }, open: open, modalSize: "mini-modal" },
                                React.createElement("div", { className: "modal-header" },
                                    React.createElement("h2", null, "Create On Demand Request"),
                                    React.createElement("div", { className: "modal-body" },
                                        React.createElement("p", { className: "sub-heading" }, "System will try to assign if there is any FE available nearby. Please Check the status of the request in Auto Routing dashboard"),
                                        React.createElement("p", { className: "sub-heading" }, "If FE is in the middle of any visit activity this request will be handled only after completing the existing visit activity"))),
                                React.createElement("div", { className: "modal-footer" },
                                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose }),
                                    React.createElement(modal_component_1.default, { buttonName: "Proceed", buttonColor: "yellow", onClickHandler: proceedModal1, open: confirmationModal, modalSize: "mini-modal" },
                                        React.createElement("div", { className: "modal-header" },
                                            React.createElement("p", { className: "sub-heading text-green" }, "You can sit back and relax now. Your On Demand Request has been scheduled successfully")),
                                        React.createElement("div", { className: "unclickablerow custom-pagination-table" },
                                            React.createElement("div", { className: "modal-table-wrapper" },
                                                React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ONDEMAND_REQUEST_COLUMNS, rows: rowData, pagination: false }))),
                                        React.createElement("div", { className: "modal-footer" },
                                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateOndemand_creation }),
                                            React.createElement(button_component_1.default, { buttonName: "Ok", color: "yellow", onClickHandler: proceedModal }))))))));
                })))));
};
exports.default = OnDemandRequest;
