"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FormControl_1 = require("@mui/material/FormControl");
var FormControlLabel_1 = require("@mui/material/FormControlLabel");
var Grid_1 = require("@mui/material/Grid/Grid");
var Radio_1 = require("@mui/material/Radio");
var RadioGroup_1 = require("@mui/material/RadioGroup");
var React = require("react");
var button_component_1 = require("../../../common/buttons/button_component");
var checkbox_component_1 = require("../../../common/checkbox/checkbox_component");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var ReviewAudit1 = function (_a) {
    var auditdetails = _a.auditdetails, backButtonHandler = _a.backButtonHandler, saveAuditHandler = _a.saveAuditHandler;
    var renderOptions = function (singleQuestion) {
        var _a, _b;
        switch (singleQuestion.qtype.label) {
            case 'Multiple Choice':
                return (React.createElement("div", { className: "options-container" }, singleQuestion.qoptions.map(function (option, optionIndex) { return (React.createElement("div", { className: "option-wrapper" },
                    React.createElement(checkbox_component_1.default, { label: '', disabled: true }),
                    React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Answer option ".concat(optionIndex + 1), value: option.option, readOnly: true }))); })));
            case 'Single Choice':
                return (React.createElement("div", { className: "options-container" }, singleQuestion.qoptions.map(function (option, optionIndex) { return (React.createElement("div", { className: "option-wrapper" },
                    React.createElement(Radio_1.default, { disabled: true }),
                    React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Answer option ".concat(optionIndex + 1), value: option.option, readOnly: true }))); })));
            case 'True/False':
                return (React.createElement("div", { className: "options-container" },
                    React.createElement("div", { className: "option-wrapper" },
                        React.createElement(Radio_1.default, { disabled: true }),
                        React.createElement(text_filed_component_1.default, { className: "text-caps", label: "True/YES", value: singleQuestion.qoptions[0].option, readOnly: true })),
                    React.createElement("div", { className: "option-wrapper" },
                        React.createElement(Radio_1.default, { disabled: true }),
                        React.createElement(text_filed_component_1.default, { className: "text-caps", label: "False/NO", value: singleQuestion.qoptions[1].option, readOnly: true }))));
            case 'Linear Scale':
                var labelArr = [];
                for (var i = parseInt((_a = singleQuestion.minrange) === null || _a === void 0 ? void 0 : _a.value.toString()); i <= parseInt((_b = singleQuestion.maxrange) === null || _b === void 0 ? void 0 : _b.value.toString()); i++) {
                    labelArr.push(i);
                }
                return (React.createElement("div", { className: "options-container" },
                    React.createElement("div", { className: "linear-scale-conatiner" },
                        React.createElement("label", { className: "linear-scale-label" }, singleQuestion.qoptions[0].option.toUpperCase()),
                        React.createElement("div", { className: "linear-scale" },
                            React.createElement(FormControl_1.default, null,
                                React.createElement(RadioGroup_1.default, { row: true, "aria-labelledby": "demo-form-control-label-placement", name: "position", defaultValue: "top" }, labelArr.map(function (label) { return (React.createElement(FormControlLabel_1.default, { value: label, control: React.createElement(Radio_1.default, null), label: label, labelPlacement: "top", disabled: true })); })))),
                        React.createElement("label", { className: "linear-scale-label" }, singleQuestion.qoptions[1].option.toUpperCase()))));
            case 'Short Answer':
                return (React.createElement("div", { className: "options-container" }));
        }
    };
    return (React.createElement("div", { className: "page-wrapper audit-details-page" },
        React.createElement("div", { className: "scrollable-content" },
            React.createElement("div", { className: "create-audit-review" },
                React.createElement("div", { className: "input-fileds-wrapper" },
                    React.createElement("form", null,
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 } },
                            React.createElement(Grid_1.default, { item: true, xs: 10 },
                                React.createElement("div", { className: "audit-details-wrapper " },
                                    React.createElement("div", { className: "audit-title-details" },
                                        React.createElement("p", { className: "audit-title" },
                                            ' ',
                                            auditdetails.auditTitle.toUpperCase()),
                                        React.createElement("p", { className: "audit-desc" }, auditdetails.auditDescription.toUpperCase()),
                                        React.createElement("p", { className: "text-normal audit-note" }, "*All questions are mandatory.")))),
                            React.createElement(Grid_1.default, { item: true, xs: 10 },
                                React.createElement("div", { className: "audit-preview" },
                                    auditdetails.questions.map(function (singlequestion, index) { return (React.createElement("div", { className: "question-container" },
                                        React.createElement("div", { className: "question-wrapper" },
                                            React.createElement("span", { className: "sr-no" },
                                                "Q",
                                                index + 1,
                                                "."),
                                            React.createElement(text_filed_component_1.default, { className: "text-caps", label: "Question Title", value: singlequestion.qtitile, readOnly: true }),
                                            singlequestion.isPhotoRequired && (React.createElement("div", { className: "audit-photo" },
                                                React.createElement("div", { className: "file-upload-audit" },
                                                    React.createElement(button_component_1.default, { buttonName: "Upload Photo", color: "transparent" }),
                                                    React.createElement("input", { hidden: true, accept: "image/*", type: "file" }),
                                                    React.createElement("span", { className: "icon-photo_camera upload-icon" }))))),
                                        renderOptions(singlequestion))); }),
                                    React.createElement("div", { className: "btns-wrapper form-btn-wrapper" },
                                        React.createElement(button_component_1.default, { buttonName: "Back", color: "yellow-outline", onClickHandler: backButtonHandler }),
                                        React.createElement(button_component_1.default, { buttonName: "Save Audit", color: "yellow", onClickHandler: saveAuditHandler })))))))))));
};
exports.default = ReviewAudit1;
