"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertProfiledataAsSCFInitialValues = exports.convertSCFProfileDetails = exports.convertSCFListingAPIResponse = exports.SCF_PAYOUT_LIST = exports.SCF_PAYMENT_TYPES = exports.scf_lables = exports.SCF_CLIENT_STATUS_OBJ = void 0;
exports.SCF_CLIENT_STATUS_OBJ = {
    PENDING_LEVEL_ONE: 0,
    LEVEL_ONE_REJECTED: 1,
    PENDING_LEVEL_TWO: 2,
    LEVEL_TWO_REJECTED: 3,
    LEVEL_TWO_APPROVED: 4,
    ACTIVE: 5,
    INACTIVE: 6,
    DELETED: 7
};
exports.scf_lables = {
    Primary_Client: 'Warehouse Code ',
    Invoice_Code: 'Invoice Code',
    Client_Name: 'Client Name',
    state: 'State',
    city: 'City',
    Labcode: 'Labcode',
    Lab_Name: 'Lab Name',
    Route_Type: 'Route Type',
    Compnay_ID: 'Compnay ID',
    Percentage_of_sales: 'Percentage of sales',
    Capping_Amount: 'Capping Amount',
    Payment_type: 'Payment Type',
    MontlyOrDaily: 'Payment Mode',
    Couirer_charges_type: 'Courier Charges Type',
    Couirer_charges: 'Courier Charges',
    Slabs_one_payout: "Payout",
    Slabs_two_payout: "Payout",
    Slabs_three_payout: "Payout"
};
exports.SCF_PAYMENT_TYPES = {
    FIXED: 0,
    MONTHLY: 1,
    PER_DAY: 2,
    ON_SALES: 3
};
exports.SCF_PAYOUT_LIST = [
    { id: 1, label: '100', value: 100 },
    { id: 1, label: '200', value: 200 },
    { id: 1, label: '300', value: 300 },
    { id: 1, label: '400', value: 400 },
    { id: 1, label: '500', value: 500 },
    { id: 1, label: '600', value: 600 },
    { id: 1, label: '700', value: 700 },
    { id: 1, label: '800', value: 800 },
    { id: 1, label: '900', value: 900 },
    { id: 1, label: '1000', value: 1000 },
    { id: 1, label: '1100', value: 1100 },
    { id: 1, label: '1200', value: 1200 },
    { id: 1, label: '1300', value: 1300 },
    { id: 1, label: '1400', value: 1400 },
    { id: 1, label: '1500', value: 1500 },
    { id: 1, label: '1600', value: 1600 },
    { id: 1, label: '1700', value: 1700 },
    { id: 1, label: '1800', value: 1800 },
    { id: 1, label: '1900', value: 1900 },
    { id: 1, label: '2000', value: 2000 },
];
var renderPaymentMode = function (value) {
    if (value != null || value != undefined) {
        if (value == exports.SCF_PAYMENT_TYPES.FIXED) {
            return Object.keys(exports.SCF_PAYMENT_TYPES)[0];
        }
        else if (value == exports.SCF_PAYMENT_TYPES.MONTHLY) {
            return Object.keys(exports.SCF_PAYMENT_TYPES)[1];
        }
        else if (value == exports.SCF_PAYMENT_TYPES.PER_DAY) {
            return Object.keys(exports.SCF_PAYMENT_TYPES)[2];
        }
        else if (value == exports.SCF_PAYMENT_TYPES.ON_SALES) {
            return Object.keys(exports.SCF_PAYMENT_TYPES)[3];
        }
        else {
            return '';
        }
    }
    else {
        return '';
    }
};
var renderPaymentType = function (value) {
    if (value != null || value != undefined) {
        if (value == 0) {
            return 'DAILY';
        }
        else if (value == 1) {
            return 'MONTHLY';
        }
        else {
            return '';
        }
    }
    else {
        return '';
    }
};
var renderCourierChargesType = function (value) {
    if (value != null || value != undefined) {
        if (value == 0) {
            return 'NUMBER';
        }
        else if (value == 1) {
            return 'PERCENTAGE';
        }
        else if (value == 2) {
            return 'SLABS';
        }
        else {
            return '';
        }
    }
    else {
        return '';
    }
};
var convertSCFListingAPIResponse = function (response) {
    var convertedData = [];
    if (response.length > 0) {
        response.map(function (val) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            var newObj = {
                primary_client: (_a = val.warehouse_code) !== null && _a !== void 0 ? _a : '',
                invoice_code: (_b = val.invoice_code) !== null && _b !== void 0 ? _b : '',
                client_name: (_c = val.center_name) !== null && _c !== void 0 ? _c : '',
                state: (_d = val.center_state) !== null && _d !== void 0 ? _d : '',
                city: (_e = val.center_city) !== null && _e !== void 0 ? _e : '',
                status: (_f = val.status) !== null && _f !== void 0 ? _f : '',
                lab_code: (_g = val.lab_code) !== null && _g !== void 0 ? _g : '',
                lab_name: (_h = val.lab_name) !== null && _h !== void 0 ? _h : '',
                route_type: (_j = val.route_type) !== null && _j !== void 0 ? _j : '',
                company_id: (_k = val.data_area_id) !== null && _k !== void 0 ? _k : '',
                center_id: (_l = val.center_id) !== null && _l !== void 0 ? _l : '',
                id: (_m = val.id) !== null && _m !== void 0 ? _m : '',
                created_date_time: (_o = val.created_date_time) !== null && _o !== void 0 ? _o : '',
                updated_date_time: (_p = val.updated_date_time) !== null && _p !== void 0 ? _p : '',
                payment_option: renderPaymentType(val.payment_option),
                courier_charges: (_q = val.courier_charges) !== null && _q !== void 0 ? _q : '',
                courier_charges_type: renderCourierChargesType(val.courier_charges_type),
                payment_type: renderPaymentMode(val.payment_type),
                rejected_comments: val.rejected_comments ? val.rejected_comments.toString().split('-')[0] : '',
                status_reason: val.status_reason ? val.status_reason.toString().split('-')[0] : '',
                slab_one: (_r = val.slab_one) !== null && _r !== void 0 ? _r : '',
                slab_one_payout: (_s = val.slab_one_payout) !== null && _s !== void 0 ? _s : '',
                slab_two: (_t = val.slab_two) !== null && _t !== void 0 ? _t : '',
                slab_two_payout: (_u = val.slab_two_payout) !== null && _u !== void 0 ? _u : '',
                slab_three: (_v = val.slab_three) !== null && _v !== void 0 ? _v : '',
                slab_three_payout: (_w = val.slab_three_payout) !== null && _w !== void 0 ? _w : '',
            };
            convertedData.push(newObj);
        });
    }
    return convertedData;
};
exports.convertSCFListingAPIResponse = convertSCFListingAPIResponse;
var convertSCFProfileDetails = function (responseData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
    var finalObj = {
        center_data: {
            center_id: null,
            warehouse_code: '',
            invoice_code: '',
            center_name: '',
            center_city: '',
            center_state: '',
            lab_code: '',
            route_type: '',
            data_area_id: '',
            payment_type: '',
            payment_option: '',
            courier_charges_type: '',
            courier_charges: null,
            status: null,
            lab_name: '',
            slab_one: '',
            slab_one_payout: null,
            slab_two: '',
            slab_two_payout: null,
            slab_three: '',
            slab_three_payout: null
        },
        updated_data: {
            payment_type: '',
            payment_option: '',
            courier_charges_type: '',
            courier_charges: null,
            action: null,
            status: null,
            slab_one: '',
            slab_one_payout: null,
            slab_two: '',
            slab_two_payout: null,
            slab_three: '',
            slab_three_payout: null
        }
    };
    if (Object.keys(responseData.center_data).length) {
        var center_data = responseData.center_data;
        finalObj.center_data.center_id = (_a = center_data.center_id) !== null && _a !== void 0 ? _a : null,
            finalObj.center_data.warehouse_code = (_b = center_data.warehouse_code) !== null && _b !== void 0 ? _b : '--',
            finalObj.center_data.invoice_code = (_c = center_data.invoice_code) !== null && _c !== void 0 ? _c : '--',
            finalObj.center_data.center_name = (_d = center_data.center_name) !== null && _d !== void 0 ? _d : '--',
            finalObj.center_data.center_city = (_e = center_data.center_city) !== null && _e !== void 0 ? _e : '--',
            finalObj.center_data.center_state = (_f = center_data.center_state) !== null && _f !== void 0 ? _f : '--',
            finalObj.center_data.lab_code = (_g = center_data.lab_code) !== null && _g !== void 0 ? _g : '--',
            finalObj.center_data.route_type = (_h = center_data.route_type) !== null && _h !== void 0 ? _h : '--',
            finalObj.center_data.data_area_id = (_j = center_data.data_area_id) !== null && _j !== void 0 ? _j : '--',
            finalObj.center_data.payment_type = (_k = renderPaymentMode(center_data.payment_type)) !== null && _k !== void 0 ? _k : '--',
            finalObj.center_data.payment_option = (_l = renderPaymentType(center_data.payment_option)) !== null && _l !== void 0 ? _l : '--',
            finalObj.center_data.courier_charges_type = (_m = renderCourierChargesType(center_data.courier_charges_type)) !== null && _m !== void 0 ? _m : '--',
            finalObj.center_data.courier_charges = (_o = center_data.courier_charges) !== null && _o !== void 0 ? _o : null,
            finalObj.center_data.status = (_p = center_data.status) !== null && _p !== void 0 ? _p : null,
            finalObj.center_data.lab_name = (_q = center_data.lab_name) !== null && _q !== void 0 ? _q : null,
            finalObj.center_data.slab_one = (_r = center_data.slab_one) !== null && _r !== void 0 ? _r : '',
            finalObj.center_data.slab_one_payout = (_s = center_data.slab_one_payout) !== null && _s !== void 0 ? _s : null,
            finalObj.center_data.slab_two = (_t = center_data.slab_two) !== null && _t !== void 0 ? _t : '',
            finalObj.center_data.slab_two_payout = (_u = center_data.slab_two_payout) !== null && _u !== void 0 ? _u : null,
            finalObj.center_data.slab_three = (_v = center_data.slab_three) !== null && _v !== void 0 ? _v : '',
            finalObj.center_data.slab_three_payout = (_w = center_data.slab_three_payout) !== null && _w !== void 0 ? _w : null;
    }
    if (Object.keys(responseData.updated_data).length) {
        var updated_data = responseData.updated_data;
        finalObj.updated_data.payment_type = (_x = renderPaymentMode(updated_data.payment_type)) !== null && _x !== void 0 ? _x : '--',
            finalObj.updated_data.payment_option = (_y = renderPaymentType(updated_data.payment_option)) !== null && _y !== void 0 ? _y : '--',
            finalObj.updated_data.courier_charges_type = (_z = renderCourierChargesType(updated_data.courier_charges_type)) !== null && _z !== void 0 ? _z : '--',
            finalObj.updated_data.courier_charges = (_0 = updated_data.courier_charges) !== null && _0 !== void 0 ? _0 : null,
            finalObj.updated_data.action = (_1 = updated_data.action) !== null && _1 !== void 0 ? _1 : null,
            finalObj.updated_data.status = (_2 = updated_data.status) !== null && _2 !== void 0 ? _2 : null,
            finalObj.updated_data.slab_one = (_3 = updated_data.slab_one) !== null && _3 !== void 0 ? _3 : '',
            finalObj.updated_data.slab_one_payout = (_4 = updated_data.slab_one_payout) !== null && _4 !== void 0 ? _4 : null,
            finalObj.updated_data.slab_two = (_5 = updated_data.slab_two) !== null && _5 !== void 0 ? _5 : '',
            finalObj.updated_data.slab_two_payout = (_6 = updated_data.slab_two_payout) !== null && _6 !== void 0 ? _6 : null,
            finalObj.updated_data.slab_three = (_7 = updated_data.slab_three) !== null && _7 !== void 0 ? _7 : '',
            finalObj.updated_data.slab_three_payout = (_8 = updated_data.slab_three_payout) !== null && _8 !== void 0 ? _8 : null;
    }
    return finalObj;
};
exports.convertSCFProfileDetails = convertSCFProfileDetails;
var convertProfiledataAsSCFInitialValues = function (responseData) {
    var finalObj = {
        warehouse_code: null,
        invoice_code: '',
        client_name: '',
        state: '',
        city: '',
        lab_code: '',
        lab_name: '',
        route_type: '',
        company_id: '',
        payment_type: null,
        monthly_daily: null,
        courier_charges_type: null,
        courier_charges: '',
        onSaleCourieChargesTypes: true,
        payout1: null,
        payout2: null,
        payout3: null,
    };
    if (Object.keys(responseData.center_data).length) {
        var center_data = responseData.center_data;
        finalObj.warehouse_code = {
            id: Number(center_data.center_id),
            label: "".concat(center_data.warehouse_code, ", ").concat(center_data.center_name),
            value: center_data.warehouse_code,
            invoice_code: center_data.invoice_code,
            client_name: center_data.center_name,
            state_name: center_data.center_state,
            city_name: center_data.center_city,
            lab_code: center_data.lab_code,
            lab_name: center_data.lab_name,
            route_type: center_data.route_type,
            company_id: center_data.data_area_id,
        },
            finalObj.invoice_code = center_data.invoice_code,
            finalObj.client_name = center_data.center_name,
            finalObj.state = center_data.center_state,
            finalObj.city = center_data.center_city,
            finalObj.lab_code = center_data.lab_code,
            finalObj.lab_name = center_data.lab_name,
            finalObj.route_type = center_data.route_type,
            finalObj.company_id = center_data.data_area_id;
        finalObj.payment_type = { id: Number(center_data.payment_type), label: renderPaymentMode(center_data.payment_type), value: renderPaymentMode(center_data.payment_type) },
            finalObj.monthly_daily = { id: Number(center_data.payment_option), label: renderPaymentType(center_data.payment_option), value: renderPaymentType(center_data.payment_option) },
            finalObj.courier_charges_type = { id: Number(center_data.courier_charges_type), label: renderCourierChargesType(center_data.courier_charges_type), value: renderCourierChargesType(center_data.courier_charges_type) },
            finalObj.courier_charges = center_data.courier_charges ? center_data.courier_charges.toString() : '',
            finalObj.onSaleCourieChargesTypes = Number(center_data.courier_charges_type) == 2 ? false : true,
            finalObj.payout1 = center_data.slab_one_payout ? { id: Number(center_data.slab_one_payout), label: center_data.slab_one_payout.toString(), value: center_data.slab_one_payout } : null,
            finalObj.payout2 = center_data.slab_two_payout ? { id: Number(center_data.slab_two_payout), label: center_data.slab_two_payout.toString(), value: center_data.slab_two_payout } : null,
            finalObj.payout3 = center_data.slab_three_payout ? { id: Number(center_data.slab_three_payout), label: center_data.slab_three_payout.toString(), value: center_data.slab_three_payout } : null;
    }
    return finalObj;
};
exports.convertProfiledataAsSCFInitialValues = convertProfiledataAsSCFInitialValues;
