"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_router_1 = require("react-router");
var api_urls_1 = require("../../../api/api_urls");
var button_component_1 = require("../../../common/buttons/button_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var api_service_1 = require("../api/api_service");
var array_helpers_1 = require("../../../utils/array_helpers");
var react_hot_toast_1 = require("react-hot-toast");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var moment = require("moment");
var IntracityRlabtoPlabMetricsList = function (_a) {
    var rlabToplabFilter = _a.rlabToplabFilter, labList = _a.labList, startDate = _a.startDate, endDate = _a.endDate;
    var nav = (0, react_router_1.useNavigate)();
    var _b = React.useState(false), modelOpen = _b[0], setModalOpen = _b[1];
    var _c = React.useState([]), rlabToPlabList = _c[0], setRlabtoPlabList = _c[1];
    var _d = React.useState(''), header = _d[0], setHeader = _d[1];
    var _e = React.useState(0), totalPageCount = _e[0], setTotalPageCount = _e[1];
    var _f = React.useState(1), pageCount = _f[0], setpageCount = _f[1];
    var _g = React.useState(0), totalCount = _g[0], setTotalCount = _g[1];
    var _h = React.useState([]), selectedRlabs = _h[0], setSelectedRlabs = _h[1];
    var _j = React.useState([]), selectedPlabs = _j[0], setSelectedPlabs = _j[1];
    var PageLimit = 50;
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var navigateToMyReports = function () {
        nav("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var refetch = (0, react_query_1.useQuery)(['rlabToplab-list'], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (selectedRlabs.length > 0) {
            var labCode = (0, array_helpers_1.selecteAllOption)(selectedRlabs, labList);
            payload['r_lab_codes'] = labCode;
        }
        if (selectedPlabs.length > 0) {
            var labCode = (0, array_helpers_1.selecteAllOption)(selectedPlabs, labList);
            payload['p_lab_codes'] = labCode;
        }
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.toString()) : 50;
        payload['filter'] = rlabToplabFilter;
        (0, api_service_1.fetchDashboardData)(api_urls_1.api_urls.RlabToPlab_Dashboard_List, payload, process.env.APPROVAL_BASEURL)
            .then(function (response) {
            var pageSize = PageLimit ? parseInt(PageLimit.toString()) : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setTotalCount(response.count);
            setRlabtoPlabList(response.result);
        })
            .catch(function (e) {
            console.log(e.message);
        });
        if (rlabToplabFilter === 1) {
            setHeader('Pickup Not captured');
        }
        else if (rlabToplabFilter === 2) {
            setHeader('Lab Handover Not Captured');
        }
        else {
            setHeader('Both Event Not Captured');
        }
    }).refetch;
    var rLabHandler = function (selectedValues) {
        setSelectedRlabs(selectedValues);
    };
    var pLabHandler = function (selectedValues) {
        setSelectedPlabs(selectedValues);
    };
    var donwloadReport = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (selectedRlabs.length > 0) {
            var labCode = (0, array_helpers_1.selecteAllOption)(selectedRlabs, labList);
            payload['r_lab_codes'] = labCode;
        }
        if (selectedPlabs.length > 0) {
            var labCode = (0, array_helpers_1.selecteAllOption)(selectedPlabs, labList);
            payload['p_lab_codes'] = labCode;
        }
        payload['page'] = currentPage;
        payload['size'] = PageLimit ? parseInt(PageLimit.toString()) : 50;
        payload['filter'] = rlabToplabFilter;
        (0, api_service_1.downloadRlabToPlabMetricsReport)(payload)
            .then(function (res) {
            if (res.statusCode === 200) {
                setModalOpen(true);
            }
            else {
                react_hot_toast_1.default.error(res.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "mid-page-heaidng" },
            React.createElement("h2", { className: "F-18" }, header),
            React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, color: "yellow-outline", endIcon: "icon-import download-icon", onClickHandler: donwloadReport, isError: rlabToPlabList.length <= 0 })),
        React.createElement("div", { className: "filter-menu-wrapper long-filter" },
            React.createElement("div", { className: "search-filter search-lg" }),
            React.createElement("div", { className: "filter-options" },
                React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "R-Lab", dropDownList: labList && labList.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: rLabHandler, value: selectedRlabs }),
                React.createElement(multi_selector_1.default, { noOptions: 'No lab codes found', hasSelectAll: false, label: "P-Lab", dropDownList: labList && labList.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: pLabHandler, value: selectedPlabs }),
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: 'custom-pagination-table control-tower-modal-table' },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.RLABTOPLAB_METRICS_COLUMNS, rows: rlabToPlabList, pagination: false })),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: rlabToPlabList === null || rlabToPlabList === void 0 ? void 0 : rlabToPlabList.length, currentPageLimits: PageLimit }),
        React.createElement(pop_up_1.default, { open: modelOpen },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setModalOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = IntracityRlabtoPlabMetricsList;
