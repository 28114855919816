"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var images_1 = require("../constants/images");
require("./no-datapage.scss");
var Pagenotfound = function () {
    console.log('jhgfdrtfg');
    return (React.createElement("div", { className: "error-page-wraper 404-error-wrapper" },
        React.createElement("div", { className: "error-icon-wrapper" },
            React.createElement("img", { src: images_1.default.PageNotFound, alt: "404 Erorr" })),
        React.createElement("h2", { className: "no-data-text" }, "Page Not Found"),
        React.createElement("p", { className: "no-data-desc" }, "The page you\u2019re looking for is currently under maintenance and will be back soon.")));
};
exports.default = Pagenotfound;
