"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hideToaster = exports.showToaster = exports.TOASTER_ACTIONS = void 0;
exports.TOASTER_ACTIONS = {
    SHOW_TOAST: 'SHOW_TOAST',
    HIDE_TOAST: 'HIDE_TOAST',
};
var showToaster = function (toastInfo) {
    return {
        type: exports.TOASTER_ACTIONS.SHOW_TOAST,
        payload: toastInfo,
    };
};
exports.showToaster = showToaster;
var hideToaster = function () {
    return {
        type: exports.TOASTER_ACTIONS.HIDE_TOAST,
    };
};
exports.hideToaster = hideToaster;
