"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var react_router_2 = require("react-router");
var api_service_1 = require("../../../../api/api_service");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var status_buttons_1 = require("../../../../common/buttons/status_buttons");
var Assign_slots_1 = require("../../../../common/cc_timeslots/Assign_slots");
var modal_component_1 = require("../../../../common/modal/modal_component");
var search_component_1 = require("../../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../../common/tables/AGgrid_table_component");
var Ondemand_Vertical_stepper_1 = require("../../../../common/vertical_stepper/Ondemand_Vertical_stepper");
var appConstants_1 = require("../../../../constants/appConstants");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var table_data_1 = require("../../../../constants/table_data");
var array_helpers_1 = require("../../../../utils/array_helpers");
var slotblocking_helper_1 = require("../../../field_exicutive/slot_blocking/slotblocking.helper");
var CC_api_calls_1 = require("../../CC/CC_api_calls");
var control_center_api_calls_1 = require("../control_center_api_calls");
var AssignRequest = function () {
    var state = (0, react_router_1.useLocation)().state;
    var rowId = state.visit_id;
    var warehouse_code = state.warehouse_code;
    var time_window = state.request_raising_time;
    var centre_name = state.center_name;
    console.log(rowId, time_window, centre_name, 'rowId');
    var _a = React.useState(), distance = _a[0], setDistance = _a[1];
    var _b = React.useState([]), assignOndemandrequest = _b[0], setAssignOndemandRequest = _b[1];
    var _c = React.useState([]), stepperdata = _c[0], setStepperData = _c[1];
    var _d = React.useState(), fe_image = _d[0], setFe_image = _d[1];
    var _e = React.useState(), fe_id = _e[0], setFeid = _e[1];
    var _f = React.useState(), rowdata = _f[0], setRowdata = _f[1];
    var _g = React.useState([]), fe_code = _g[0], setFecode = _g[1];
    var _h = React.useState([]), fe_name = _h[0], setFename = _h[1];
    var _j = React.useState(), dataid = _j[0], setdataid = _j[1];
    var _k = React.useState(''), searchTerm = _k[0], setSearchTerm = _k[1];
    var _l = React.useState(false), open = _l[0], setOpen = _l[1];
    var _m = React.useState(false), slotscondition = _m[0], setSlotscondition = _m[1];
    var _o = React.useState([]), selectslots = _o[0], setselectslots = _o[1];
    var _p = React.useState(), selectslotsbutton = _p[0], setselectslotsButton = _p[1];
    var _q = React.useState(), existingTimeSlotsList = _q[0], setExistingTimeSlotsList = _q[1];
    var _r = React.useState(false), renderstate = _r[0], setRenderState = _r[1];
    var staticSlots = JSON.parse(JSON.stringify(slotblocking_helper_1.timeSlots));
    var _s = React.useState(staticSlots), slots = _s[0], setSlots = _s[1];
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var nav = (0, react_router_2.useNavigate)();
    console.log((existingTimeSlotsList === null || existingTimeSlotsList === void 0 ? void 0 : existingTimeSlotsList.split('-')[0]), existingTimeSlotsList, dataid, "okokokokk");
    var _t = React.useState([]), existondemandslot = _t[0], setexistondemandslot = _t[1];
    var _u = React.useState([]), existingTimeSlots = _u[0], setExistingTimeSlots = _u[1];
    var currentdate = new Date().getDay();
    var navigateToListingPage = function () {
        checkdata(event);
    };
    var clearSlots = function () {
        console.log('98iuyhjgb');
        setSlots(JSON.parse(JSON.stringify(slotblocking_helper_1.timeSlots)));
        console.log(slots, 'uuuyuytrt');
    };
    var searchFe = function (event) {
        setSearchTerm(event.target.value.trim());
    };
    var navigation = function () {
        nav("/".concat(route_path_1.default.PATHS.controlTower));
        clearSlots;
    };
    var handleClose = function () {
        setOpen(false);
        setSlotscondition(false);
        setselectslots([]);
    };
    var handleopen = function () {
        setOpen(true);
        setRowdata([
            {
                centre_name: centre_name,
                time_slot: time_window,
                fe_name: (0, array_helpers_1.concatFirstNameLastName)(fe_name, fe_code),
            },
        ]);
    };
    var existingData = function () {
        var arr = [];
        slots.map(function (slot) {
            console.log(existingTimeSlots, 'existingTimeSlots');
            existingTimeSlots &&
                (existingTimeSlots === null || existingTimeSlots === void 0 ? void 0 : existingTimeSlots.map(function (each) {
                    if (each.day === (0, array_helpers_1.getCurrentday)(currentdate)) {
                        console.log(each.schedule_time_slot, 'jhgfd');
                        if (each['schedule_time_slot'].split(':')[0] ===
                            slot['fromTime'].split(':')[0]) {
                            slot['color'] = 'slot-btn status-exist';
                        }
                    }
                }));
            console.log(slot, 'slotttt');
            arr.push(slot);
        });
        setSlots(arr);
    };
    React.useEffect(function () {
        existingData();
    }, [existingTimeSlots]);
    var existingOndemandslots = function () {
        var arr = [];
        slots.map(function (slot) {
            console.log(existondemandslot, 'existondemandslot');
            existondemandslot &&
                (existondemandslot === null || existondemandslot === void 0 ? void 0 : existondemandslot.map(function (each) {
                    if (each['time_window_slot'].split(':')[0] ===
                        slot['fromTime'].split(':')[0]) {
                        slot['color'] = 'slot-btn yellow-shadecolor';
                    }
                }));
            console.log(slot, 'slotttt');
            arr.push(slot);
        });
        setSlots(arr);
    };
    React.useEffect(function () {
        existingOndemandslots();
    }, [existondemandslot]);
    var onSelectionChange = function (selectedRow) {
        console.log("selectedRow:", selectedRow);
        setExistingTimeSlotsList(time_window);
        if (selectedRow) {
            if (fe_id === selectedRow.fe_id) {
                setFeid(null);
                setStepperData([]);
                setFename(null);
                setFecode(null);
                setRenderState(false);
            }
            else {
                setRenderState(true);
                setFecode(selectedRow.fe_code);
                setDistance(selectedRow.distance);
                setFename(selectedRow.fe_name);
                setFeid(selectedRow.fe_id);
                setStepperData(selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.schedule);
                setFe_image(selectedRow.schedule[0].fe_avatar);
            }
        }
        else {
            setFeid(null);
            setStepperData([]);
            setFename(null);
            setFecode(null);
            setRenderState(false);
            setFe_image(null);
        }
    };
    var onRowclickofAssign = function (event) {
        console.log(event.event.target.className.includes('CANCEL'), 'kjhgfd');
        if (event.type === 'rowClicked' &&
            event.event.target.className.includes('yellow')) {
            setSlotscondition(true);
        }
        else if (event.type === 'rowClicked' &&
            event.event.target.className.includes('icon-cross')) {
            setselectslots([]);
            setSlotscondition(false);
            setRowdata([
                {
                    centre_name: centre_name,
                    time_slot: time_window,
                    fe_name: (0, array_helpers_1.concatFirstNameLastName)(fe_name, fe_code),
                },
            ]);
        }
    };
    React.useEffect(function () {
        setRowdata([
            {
                centre_name: centre_name,
                time_slot: (0, array_helpers_1.modifiedslots)(time_window, selectslotsbutton),
                fe_name: (0, array_helpers_1.concatFirstNameLastName)(fe_name, fe_code),
            },
        ]);
    }, [selectslotsbutton]);
    var getAssignOndemandRequestList = function (payload) {
        (0, control_center_api_calls_1.assignOndemandRequest)(payload)
            .then(function (response) {
            console.log(response, 'kjhg');
            setAssignOndemandRequest(response);
            response.map(function (e) {
                setdataid(e.fe_id);
            });
        })
            .catch(function (err) {
            console.log(err.message);
        });
    };
    var callbackOnselectedCC = function (val) {
        var payload = {
            warehouse_code: warehouse_code,
        };
        (0, CC_api_calls_1.fetchCCEditList)(payload)
            .then(function (res) {
            var data = res.data.schedule;
            setExistingTimeSlots(data);
        })
            .catch(function (e) { return console.log(e); });
    };
    var callbackCCondemandtimeslots = function (val) {
        var payload = {
            warehouse_code: warehouse_code,
        };
        (0, control_center_api_calls_1.fetchCCOndemandtimeslotList)(payload)
            .then(function (res) {
            var data = res.data.result;
            console.log(data, 'poiuytrew');
            setexistondemandslot(data);
        })
            .catch(function (e) { return console.log(e); });
    };
    React.useEffect(function () {
        callbackCCondemandtimeslots(warehouse_code);
        callbackOnselectedCC(warehouse_code);
    }, [warehouse_code]);
    React.useEffect(function () {
        var payload = {};
        if (searchTerm) {
            payload['search_string'] = searchTerm;
        }
        if (rowId) {
            payload['visit_id'] = rowId;
        }
        getAssignOndemandRequestList(payload);
    }, [searchTerm, rowId]);
    var checkdata = function (event) {
        var isChecked = !event.target.checked;
        var payload2 = {
            visit_id: parseInt(rowId),
            fe_id: fe_id,
            time_window_slot: selectslotsbutton,
        };
        var payload1 = {
            visit_id: parseInt(rowId),
            fe_id: fe_id,
        };
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ASSIGN_ONDEMAND_REQUEST,
            payLoad: selectslotsbutton ? payload2 : payload1,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                nav("/".concat(route_path_1.default.PATHS.controlTower));
                if (isChecked) {
                    react_hot_toast_1.default.success(appConstants_1.AddVisit.Add);
                    UpdateListData.setIsListReloaded(true);
                    setExistingTimeSlotsList([]);
                    clearSlots();
                }
                else {
                    react_hot_toast_1.default.success(appConstants_1.AddVisit.remove);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.message);
        });
        return false;
    };
    return (React.createElement("div", { className: "page-wrapper" },
        React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-bottom" },
            React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                React.createElement(iconButton_component_1.default, { onClick: navigation, className: "btn-retun", icon: 'icon-left-arrow' }),
                React.createElement("h2", null, "Assign OnDemand Request")),
            React.createElement("div", { className: "page-control-wrapper" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigation }),
                React.createElement(modal_component_1.default, { buttonName: "Assign", buttonColor: "yellow", isBtnDisabled: (assignOndemandrequest === null || assignOndemandrequest === void 0 ? void 0 : assignOndemandrequest.length) > 0 && renderstate ? false : true, onClickHandler: handleopen, open: open, modalSize: "popup-large" },
                    React.createElement("div", { className: "modal-header " },
                        React.createElement("h2", { className: "heading text-green" }, "Assign OnDemand Request"),
                        React.createElement("p", { className: "sub-heading text-green" }),
                        React.createElement("div", { className: "modal-footer" })),
                    React.createElement("div", { className: "modal-body" },
                        React.createElement("div", { className: "clickable-row" },
                            React.createElement("div", { className: "modal-table-wrapper" },
                                React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.UNASSIGNED_DATA, rows: rowdata, pagination: false, onRowClicked: onRowclickofAssign })),
                            slotscondition ? (React.createElement(React.Fragment, null,
                                React.createElement("h2", { className: "selected-felabel custom-form-label" }, "Select CC Time Slots"),
                                React.createElement(formik_1.Formik, { initialValues: {
                                        selectedSlots: '',
                                    }, validationSchema: schema_1.schemas.OnDemand_Request_Schema, onSubmit: function (value) {
                                        console.log(value);
                                    } }, function (_a) {
                                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched, resetForm = _a.resetForm;
                                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                                        React.createElement(Grid_1.default, { item: true, xs: 14, lg: 12 },
                                            React.createElement(Assign_slots_1.default, { slots: slots, setSelectedSlotId: setselectslots, selectedSlotId: selectslots, setSelectedButtonName: setselectslotsButton, selectedButton: selectslotsbutton, setFieldValue: setFieldValue, existingTimeSlots: existingTimeSlotsList }),
                                            React.createElement("div", { className: "cc-slot-legend align-center" },
                                                React.createElement("div", { className: "legend-item bg-available" }, "Available Time Slots"),
                                                React.createElement("div", { className: "legend-item bg-expired" }, "Expired Time Slots"),
                                                React.createElement("div", { className: "legend-item bg-existing" }, "Regular Time Slots"),
                                                React.createElement("div", { className: "legend-item bg-selected" }, "Selected Time Slots"),
                                                React.createElement("div", { className: "legend-item bg-ondemand" }, "Today Ondemand Request Time Slots")))));
                                }))) : (''))),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "NO", color: "yellow-outline", onClickHandler: handleClose }),
                        React.createElement(button_component_1.default, { buttonName: "Yes", color: "yellow", type: "submit", onClickHandler: navigateToListingPage }))))),
        React.createElement(Grid_1.default, { container: true, columns: 12, columnSpacing: 2 },
            React.createElement(Grid_1.default, { lg: assignOndemandrequest.length > 0 && renderstate ? 7 : 12 },
                React.createElement("div", { className: "list-wrapper p16" },
                    React.createElement("div", { className: "page-aligner" },
                        React.createElement("div", { className: "tool-bar-wrapper padding-12X24" },
                            React.createElement("div", { className: "page-heaidng" },
                                React.createElement("h2", null, "Available FE\u2019s"))),
                        React.createElement("div", { className: "filter-menu-wrapper padding-12X24" },
                            React.createElement("div", { className: "search-filter" },
                                React.createElement(search_component_1.default, { placeholder: "Search FE", searchValue: searchTerm, searchHandler: searchFe })))),
                    React.createElement("div", { className: "fe-wrapper pay-fe-wrapper nodata-alignment" },
                        React.createElement("div", { className: "scrollable-content clickable-row " },
                            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.ASSIGN_ONDEMAND_REQUEST, rows: assignOndemandrequest && assignOndemandrequest, onSelectionChange: onSelectionChange, pagination: false, rowMultiSelectWithClick: true }))))),
            assignOndemandrequest.length > 0 && renderstate ? (React.createElement(Grid_1.default, { lg: assignOndemandrequest.length > 0 && renderstate ? 5 : 0 },
                React.createElement("div", { className: "fe-details-wrapper" },
                    React.createElement("div", { className: "user-card displayflex " },
                        React.createElement("div", { className: "user-details-wrapper displayflex " },
                            React.createElement("div", { className: "avatar-wrapper" }, stepperdata && fe_image ? (React.createElement(material_1.Avatar, { src: fe_image })) : (React.createElement(material_1.Avatar, null, fe_name && fe_name.toString().charAt(0).toUpperCase()))),
                            React.createElement("div", { className: "user-name-wrapper" },
                                React.createElement("p", { className: "user-name" }, fe_name),
                                React.createElement("span", { className: "user-id" }, fe_code))),
                        React.createElement(status_buttons_1.default, { status: 1 })),
                    React.createElement("div", { className: "user-tracking-details fe-track-wrapper" },
                        React.createElement("div", { className: "tab-heaidng " },
                            React.createElement("div", { className: "tab-heading-container flex-box-between" },
                                React.createElement("h2", null, "Pickups & Handovers"),
                                React.createElement("small", { className: "mini-header" }, " Today"))),
                        renderstate && (React.createElement(Ondemand_Vertical_stepper_1.default, { trackerInfo: stepperdata, distance: distance })))))) : (''))));
};
exports.default = AssignRequest;
