"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../../common/buttons/button_component");
var search_component_1 = require("../../../common/search/search_component");
var tabs_component_1 = require("../../../common/tabs/tabs_component");
var appConstants_1 = require("../../../constants/appConstants");
var tabs_data_1 = require("../../../constants/tabs_data");
var Pagenotfound_1 = require("../../../ErrorBoundary/Pagenotfound");
var menu_constants_1 = require("../../../menuList/menu_constants");
var routes_list_1 = require("../../../routes/routes_list");
var array_helpers_1 = require("../../../utils/array_helpers");
var VendorApprovalManagement = function () {
    var _a = React.useState(''), searchTerm = _a[0], setSearchTerm = _a[1];
    var _b = React.useState(false), isListReloaded = _b[0], setIsListReloaded = _b[1];
    var _c = React.useState(0), activeTab = _c[0], setActiveTab = _c[1];
    var _d = React.useState(false), isFilterButton = _d[0], setIsFilterButton = _d[1];
    var vendorAppManagSubRoutes = (0, array_helpers_1.getAccessibleRoutes)(menu_constants_1.FEATURE_CONSTANTS.approvals, routes_list_1.APPROVAL_MANGEMENT_VENDOR_LIST_SUB_ROUTES);
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.approvals);
    var filteredTabs = (0, array_helpers_1.disableTabs)(tabs_data_1.TABS_DATA.VENDOR_APPROVAL_TABs, features);
    var isHavingFeature = false;
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value.trim());
    };
    var tabsCheck = function () {
        filteredTabs.map(function (tab) {
            if (tab.islinkDisabled === false) {
                isHavingFeature = true;
            }
        });
    };
    React.useEffect(function () {
        tabsCheck();
    }, []);
    var FilterHandler = function () {
        setIsFilterButton(!isFilterButton);
    };
    var activeTabChangeHandler = function (index) {
        setActiveTab(index);
        setSearchTerm('');
    };
    return (React.createElement("div", { className: "fe-wrapper page-wrapper" }, isHavingFeature ? (React.createElement(Pagenotfound_1.default, null)) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "tool-bar-wrapper" },
            React.createElement("div", { className: "page-heaidng" },
                React.createElement("h2", null, "Vendor Approval List"))),
        React.createElement("div", { className: "filter-menu-wrapper" },
            React.createElement("div", { className: "search-filter" },
                React.createElement(search_component_1.default, { placeholder: "Search Vendor", searchValue: searchTerm, searchHandler: searchingHandler, activetab: activeTab })),
            React.createElement("div", { className: "filter-options" },
                React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
        React.createElement("div", { className: "scrollable-content" },
            React.createElement(tabs_component_1.default, { tabList: filteredTabs, tabChangeHandler: activeTabChangeHandler }),
            React.createElement(react_router_dom_1.Routes, null,
                vendorAppManagSubRoutes &&
                    vendorAppManagSubRoutes.map(function (features) {
                        return (React.createElement(react_router_dom_1.Route, { index: true, path: "".concat(features.path), key: features.path, element: React.createElement(appConstants_1.ReloadListContext.Provider, { value: {
                                    isListReloaded: isListReloaded,
                                    setIsListReloaded: setIsListReloaded,
                                } },
                                React.createElement(features.component, { searchTerm: searchTerm, isFilterButton: isFilterButton })) }));
                    }),
                React.createElement(react_router_dom_1.Route, { path: '*', element: React.createElement(Pagenotfound_1.default, null) })))))));
};
exports.default = VendorApprovalManagement;
