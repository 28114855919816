"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Card_1 = require("@mui/material/Card");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var api_urls_1 = require("../../../api/api_urls");
var drawer_1 = require("../../../common/drawer/drawer");
var array_helpers_1 = require("../../../utils/array_helpers");
var conroldashboardstyles_1 = require("../../super_admin/control_dashboard/list/conroldashboardstyles");
var reports_helper_1 = require("../../super_admin/reports/reports_helper");
var api_service_1 = require("../api/api_service");
var intracity_RlabtoPlab_metrics_list_1 = require("../list/intracity_RlabtoPlab_metrics_list");
var r_lab_p_lab_table_1 = require("../list/r_lab_p_lab_table");
var r_lab_to_p_lab_graph_view_1 = require("./r_lab_to_p_lab_graph_view");
var RLabToPLabGraph = function (_a) {
    var endDate = _a.endDate, isDateRangeUpdated = _a.isDateRangeUpdated, startDate = _a.startDate, labList = _a.labList, rLab = _a.rLab, Plab = _a.Plab, setUpdatedTIme = _a.setUpdatedTIme, filterSelectedCities = _a.filterSelectedCities, updatedTime = _a.updatedTime, filterButton = _a.filterButton;
    var _b = React.useState([]), tableData = _b[0], setTableData = _b[1];
    var _c = React.useState(null), countMatrixData = _c[0], setCountMatrixData = _c[1];
    var _d = React.useState(false), open = _d[0], setOpen = _d[1];
    var closeHandlere = function () {
        setOpen(false);
    };
    var classes = (0, conroldashboardstyles_1.useStyles)();
    var _e = React.useState(false), openDrawer = _e[0], setOpenDrawer = _e[1];
    var _f = React.useState(1), filter = _f[0], setFilter = _f[1];
    var _g = React.useState(), classN = _g[0], setClassN = _g[1];
    var handleDrawerClose = function () {
        setOpenDrawer(false);
    };
    var refetch = (0, react_query_1.useQuery)(['bagging-lifecycle'], function () {
        var payload = {};
        if (startDate && endDate) {
            var dateRangeArr = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            payload['date_range'] = dateRangeArr;
        }
        if (filterSelectedCities && filterSelectedCities.length > 0) {
            var selectedStateAndCity = (0, reports_helper_1.generateStateAndCitiesFromSelectedCities)(filterSelectedCities);
            payload['state'] =
                selectedStateAndCity.states.length >= 30
                    ? ['ALL']
                    : selectedStateAndCity.states;
            payload['city'] =
                selectedStateAndCity.cities.length >= 500
                    ? ['ALL']
                    : selectedStateAndCity.cities;
        }
        if (rLab.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(rLab, labList);
            payload['r_lab_codes'] = selectedlabCode;
        }
        if (Plab.length > 0) {
            var selectedlabCode = (0, array_helpers_1.selecteAllOption)(Plab, labList);
            payload['p_lab_codes'] = selectedlabCode;
        }
        payload['page'] = 1;
        payload['size'] = Infinity;
        (0, api_service_1.fetchDashboardData)(api_urls_1.api_urls.InterCity_R_and_P_Lab_Metrics, payload, process.env.APPROVAL_BASEURL)
            .then(function (response) {
            if (Object.keys(response).length > 0) {
                setCountMatrixData(response);
            }
            console.log(response, 'Cheresponse');
        })
            .catch(function (err) {
            react_hot_toast_1.toast.error(err.message);
        });
    }).refetch;
    React.useEffect(function () {
        refetch();
    }, [filterButton]);
    return (React.createElement(material_1.Box, { px: 3, mt: 3 },
        React.createElement("div", { className: "orderlist-main-wrapper" },
            React.createElement("div", { className: "orderlist-main" },
                React.createElement(Card_1.default, { className: "card-main bg-lightblue disabled" },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, countMatrixData && countMatrixData.total_shipments
                                ? countMatrixData.total_shipments
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "Total Shipments"))),
                React.createElement(Card_1.default, { className: "route-card card-main bg-lightblue ".concat(classN === 1 ? 'active-card' : ''), onClick: function () {
                        setOpenDrawer(true);
                        setFilter(1);
                        setClassN(1);
                    } },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, countMatrixData && countMatrixData.pickup_not_captured
                                ? countMatrixData.pickup_not_captured
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "Pickup not captured"))),
                React.createElement(Card_1.default, { className: "route-card card-main bg-lightblue ".concat(classN === 2 ? 'active-card' : ''), onClick: function () {
                        setOpenDrawer(true);
                        setFilter(2);
                        setClassN(2);
                    } },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, countMatrixData && countMatrixData.lab_handover_not_captured
                                ? countMatrixData.lab_handover_not_captured
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "Lab Handover not captured"))),
                React.createElement(Card_1.default, { className: "route-card card-main bg-lightblue ".concat(classN === 3 ? 'active-card' : ''), onClick: function () {
                        setOpenDrawer(true);
                        setFilter(3);
                        setClassN(3);
                    } },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, countMatrixData && countMatrixData.both_event_not_captured
                                ? countMatrixData.both_event_not_captured
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "Both event not captured"))),
                React.createElement(Card_1.default, { className: "card-main bg-lightblue disabled" },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, countMatrixData && countMatrixData.tracked_shipments
                                ? countMatrixData.tracked_shipments
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "Tracked shipments"))),
                React.createElement(Card_1.default, { className: "card-main bg-lightblue disabled" },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, countMatrixData && countMatrixData.with_in_tat
                                ? countMatrixData.with_in_tat
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "within tAT"))),
                React.createElement(Card_1.default, { className: "card-main bg-lightblue disabled" },
                    React.createElement(material_1.CardContent, { className: "card-content " },
                        React.createElement("div", { className: "top-block" },
                            React.createElement(material_1.Typography, { gutterBottom: true, className: "title" }, countMatrixData && countMatrixData.tat_breached
                                ? countMatrixData.tat_breached
                                : 0)),
                        React.createElement(material_1.Typography, { className: "content" }, "TAT Breached"))))),
        React.createElement(material_1.Box, { className: "overview-content grid-card-height1", p: 0, pt: 2 },
            React.createElement(material_1.Grid, { container: true, spacing: 3 },
                React.createElement(material_1.Grid, { item: true, lg: 12, mx: 0.125 },
                    React.createElement(Card_1.default, { className: "dash-board-card " },
                        React.createElement(material_1.CardHeader, { title: "R-Lab to P-Lab TAT - Breach by", subheader: "Last Updated".concat(updatedTime) }),
                        React.createElement(material_1.CardContent, null,
                            React.createElement(r_lab_to_p_lab_graph_view_1.default, { isDateRangeUpdated: isDateRangeUpdated, startDate: startDate, endDate: endDate, labList: labList, setUpdatedTIme: setUpdatedTIme, filterSelectedCities: filterSelectedCities, rLab: rLab, Plab: Plab, setTableData: setTableData, setOpen: setOpen, filterButton: filterButton })))))),
        React.createElement(drawer_1.default, { isOpend: open, closeModal: function () { return closeHandlere(); } },
            React.createElement(r_lab_p_lab_table_1.default, { list: tableData })),
        React.createElement(material_1.Drawer, { variant: "temporary", anchor: "bottom", open: openDrawer, className: classes.customDrawer },
            React.createElement("div", { onClick: handleDrawerClose, className: "drawer-close-btn" },
                React.createElement("span", { className: "icon-close" })),
            React.createElement(intracity_RlabtoPlab_metrics_list_1.default, { rlabToplabFilter: filter, labList: labList, startDate: startDate, endDate: endDate }))));
};
exports.default = RLabToPLabGraph;
