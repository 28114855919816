"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCCOndemandtimeslotList = exports.getvisitSchedule = exports.assignOndemandRequest = exports.fetchCCTimeSlots = exports.submitCCTimeSlot = exports.fetchCCOnSelectedLab = void 0;
var react_hot_toast_1 = require("react-hot-toast");
var api_service_1 = require("../../../api/api_service");
var fetchCCOnSelectedLab = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: "".concat(api_service_1.api_service.api_urls.WAREHOUSE_CODE, "?lab_code=").concat(payLoad.lab_code),
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            else {
                react_hot_toast_1.default.error(response.data.message);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCCOnSelectedLab = fetchCCOnSelectedLab;
var submitCCTimeSlot = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ONDEMAND_REQUEST,
            domain: process.env.LOCAL_BASEURL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            else {
                react_hot_toast_1.default.error(response.data.message, { duration: 3000 });
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    });
};
exports.submitCCTimeSlot = submitCCTimeSlot;
var fetchCCTimeSlots = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CC_EDIT,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCCTimeSlots = fetchCCTimeSlots;
var assignOndemandRequest = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.ASSIGN_ONDEMAND_REQUEST_LIST,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                console.log(res, 'response');
                return resolve(res.data.data);
            }
            throw new Error('Unable to fetch assign on demand request data!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.assignOndemandRequest = assignOndemandRequest;
var getvisitSchedule = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.VISITS_LIST,
            payLoad: payload,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                console.log(res, 'response');
                return resolve(res.data.data.result);
            }
            throw new Error('Unable to fetch assign on demand request data!');
        })
            .catch(function (e) {
            return reject(e.message);
        });
    });
};
exports.getvisitSchedule = getvisitSchedule;
var fetchCCOndemandtimeslotList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.On_Demand_time_slots,
            domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCCOndemandtimeslotList = fetchCCOndemandtimeslotList;
