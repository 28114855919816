"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KPI_HEADER_NAMES = exports.KPI_DETAILS = void 0;
exports.KPI_DETAILS = {
    time: '10:00 PM',
    totalMilage: 52,
    milagePerceentage: 2,
    battery: 18,
    batteryPercetage: 82,
    sensor: 6,
    sensorPersentage: 2,
    totalPickups: 15,
    totalPickupsPecentage: 4.2,
    ontimePickups: 5,
    ontimePickupsPecentage: 4.2,
    rating: 4.2,
    ratingPecentage: 4.2,
    earning: 2000,
    earningPercentage: 4.2,
};
exports.KPI_HEADER_NAMES = {
    totalMilage: 'Total Milage',
    batary: 'Mobile Battery',
    sensor: 'Bag Sensor',
    totalPickups: 'Total Pickups',
    onTimePickups: 'Ontime Pickups',
    rating: 'Overall Rating',
    earning: 'Earnings',
};
