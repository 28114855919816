"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var api_urls_1 = require("../../../../api/api_urls");
var button_component_1 = require("../../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var trasfer_list_1 = require("../../../../common/chips/trasfer_list");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var route_path_1 = require("../../../../constants/route_path");
var schema_1 = require("../../../../constants/schema");
var array_helpers_1 = require("../../../../utils/array_helpers");
var api_1 = require("../api");
var x_Featureids = [];
var removedFeatures = [];
var CreateNewRole = function () {
    var pathname = (0, react_router_1.useLocation)().pathname;
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var _a = React.useState([]), selectedFeatures = _a[0], setSelectedFeatures = _a[1];
    var _b = React.useState({}), editRoleList = _b[0], setEditRoleList = _b[1];
    var _c = React.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var navigateToRoleList = function () {
        return nav("/".concat(route_path_1.default.PATHS.role_management));
    };
    var initialValues = {
        name: id ? editRoleList.name : '',
        description: id ? editRoleList.description : '',
    };
    var fetchRoleListByRoleId = function () {
        (0, api_1.getRoleListByRoleId)("".concat(api_urls_1.api_urls.ROLE_LIST, "/").concat(id), process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL)
            .then(function (res) {
            setErrorMessage("");
            setEditRoleList(function (editRoleList) {
                return __assign(__assign({}, editRoleList), res.data.result);
            });
        })
            .catch(function (err) {
            setErrorMessage(err);
            setEditRoleList({});
        });
    };
    var formSubmitHandler = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var newAddedIds, modifiedIds, deleteIds, editRolepayload, createRolepayload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log(selectedFeatures, 'selectedFeatures');
                    selectedFeatures.length >= 1 &&
                        selectedFeatures.map(function (val) {
                            return val.xFeature.map(function (val) {
                                x_Featureids.push(val.id);
                            });
                        });
                    if (!id) return [3, 3];
                    console.log(x_Featureids, 'x_Featureids');
                    newAddedIds = x_Featureids.filter(function (id) { return !editRoleList && editRoleList.added_feature_ids.includes(id); });
                    console.log(newAddedIds, 'newAddedIds');
                    return [4, x_Featureids.push.apply(x_Featureids, newAddedIds)];
                case 1:
                    _a.sent();
                    return [4, (0, array_helpers_1.removeDuplicatevalues)(x_Featureids)];
                case 2:
                    modifiedIds = _a.sent();
                    console.log(modifiedIds, 'modifiedIds');
                    deleteIds = editRoleList.added_feature_ids &&
                        (0, array_helpers_1.deletedDataList)(editRoleList.added_feature_ids, x_Featureids);
                    removedFeatures.push.apply(removedFeatures, deleteIds);
                    console.log(removedFeatures, 'removedFeatures');
                    editRolepayload = {
                        attributes: {
                            app_id: parseInt(process.env.ROLES_APP_ID, 10),
                            name: values.name,
                            description: values.description,
                            is_value_change: initialValues.name == values.name &&
                                initialValues.description == values.description
                                ? false
                                : true,
                            added_feature_ids: modifiedIds,
                            removed_feature_ids: removedFeatures,
                        },
                    };
                    (0, api_1.editRole)("".concat(api_urls_1.api_urls.ROLE_LIST, "/").concat(id), editRolepayload, process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL)
                        .then(function (res) {
                        react_hot_toast_1.default.success(res.message);
                        nav("/".concat(route_path_1.default.PATHS.role_management));
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err);
                    });
                    return [3, 4];
                case 3:
                    createRolepayload = {
                        attributes: {
                            app_id: parseInt(process.env.ROLES_APP_ID, 10),
                            name: values.name,
                            description: values.description,
                            added_feature_ids: x_Featureids,
                        },
                    };
                    (0, api_1.createRole)(api_urls_1.api_urls.ROLE_LIST, createRolepayload, process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL)
                        .then(function (res) {
                        react_hot_toast_1.default.success(res.message);
                        nav("/".concat(route_path_1.default.PATHS.role_management));
                    })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.message);
                    });
                    _a.label = 4;
                case 4:
                    x_Featureids = [];
                    removedFeatures = [];
                    return [2];
            }
        });
    }); };
    var renderForm = function () {
        return !errorMessage ? (React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, validationSchema: schema_1.schemas.CreateRoleSchema, onSubmit: formSubmitHandler, enableReinitialize: true }, function (_a) {
            var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldTouched = _a.setFieldTouched;
            return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                    React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                        React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateToRoleList }),
                        React.createElement("h2", null, pathname.split('/')[2] == route_path_1.default.PATHS.addNewRole
                            ? 'Create Role'
                            : 'Edit Role')),
                    React.createElement("div", { className: "page-control-wrapper" },
                        React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateToRoleList }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Save", color: "yellow", isError: selectedFeatures &&
                                selectedFeatures.length >= 1 &&
                                values.name &&
                                values.description
                                ? false
                                : true }))),
                React.createElement("div", { className: "scrollable-content" },
                    React.createElement("div", { className: "add-slotform" },
                        React.createElement("div", { className: "input-fileds-wrapper" },
                            React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3.5 },
                                    React.createElement(text_filed_component_1.default, { name: "name", minLength: 5, maxLength: 25, onChange: function (value) {
                                            setFieldTouched('name');
                                            setFieldValue('name', value);
                                        }, label: "Role Name", value: values.name }),
                                    errors['name'] && touched['name'] ? (React.createElement("div", { className: "error" }, errors['name'])) : null),
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3.5 },
                                    React.createElement(text_filed_component_1.default, { name: "description", minLength: 5, maxLength: 200, onChange: function (descriptionValue) {
                                            setFieldTouched('description');
                                            setFieldValue('description', descriptionValue);
                                        }, label: "Description", value: values.description }),
                                    errors['description'] && touched['description'] ? (React.createElement("div", { className: "error" }, errors['description'])) : null)))),
                    console.log(editRoleList),
                    React.createElement(trasfer_list_1.default, { setSelectedFeatures: setSelectedFeatures, updateEditFeaturesIds: editRoleList && editRoleList.added_feature_ids
                            ? editRoleList.added_feature_ids
                            : [], role_id: id }))));
        })) : (React.createElement("div", { className: "server-error" },
            ' ',
            errorMessage && errorMessage.toString()));
    };
    React.useEffect(function () {
        if (id) {
            fetchRoleListByRoleId();
        }
    }, [id]);
    return React.createElement("div", { className: "create-role-wrapper" }, renderForm());
};
exports.default = CreateNewRole;
