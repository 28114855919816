"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUploadedFileLastModifiedDate = exports.getUploadedFileName = exports.getUploadedFileSize = exports.getUploadedFileType = void 0;
var getUploadedFileType = function (value) {
    return value.type;
};
exports.getUploadedFileType = getUploadedFileType;
var getUploadedFileSize = function (value) {
    return value.size;
};
exports.getUploadedFileSize = getUploadedFileSize;
var getUploadedFileName = function (value) {
    return value.name;
};
exports.getUploadedFileName = getUploadedFileName;
var getUploadedFileLastModifiedDate = function (value) {
    return value.lastModifiedDate;
};
exports.getUploadedFileLastModifiedDate = getUploadedFileLastModifiedDate;
