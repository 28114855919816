"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var api_service_1 = require("../../../api/api_service");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var table_data_1 = require("../../../constants/table_data");
var array_helpers_1 = require("../../../utils/array_helpers");
var fe_services_1 = require("../fe_services");
('../../../constants/appConstants');
var FE_REJECTEDLIST = function (_a) {
    var searchValue = _a.searchValue, selectedCities = _a.selectedCities, CurrentPagelimit = _a.CurrentPagelimit, isFilterButton = _a.isFilterButton;
    var _b = React.useState(undefined), feRejectedList = _b[0], setFeRejectedList = _b[1];
    var _c = React.useState(0), totalCount = _c[0], setTotalCount = _c[1];
    var _d = React.useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    var fe_reject_reasons_list = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).fe_reject_reasons_list;
    var _e = React.useState(1), pageCount = _e[0], setpageCount = _e[1];
    var _f = React.useState(0), totalPageCount = _f[0], setTotalPageCount = _f[1];
    var fetchRejectedFE = function (endPoint, payLoad, domain) {
        (0, fe_services_1.fetchRejectedFEList)(endPoint, payLoad, domain)
            .then(function (response) {
            response.result.forEach(function (obj) {
                var _a;
                obj['reject_reason'] = obj.reject_reason
                    ? (_a = (0, array_helpers_1.filterData)({
                        filterArray: fe_reject_reasons_list,
                        id: obj.reject_reason,
                    })) === null || _a === void 0 ? void 0 : _a.label
                    : '';
            });
            var pageSize = CurrentPagelimit
                ? parseInt(CurrentPagelimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setErrorMessage("");
            setTotalPageCount(count);
            setFeRejectedList(response.result);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setFeRejectedList([]);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['searchrejected-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = (0, array_helpers_1.createPayloadForFEList)(appConstants_1.FE_USER_TYPES.rejected, searchValue, selectedCities);
        payload.page = currentPage;
        payload.size = CurrentPagelimit
            ? parseInt(CurrentPagelimit.value.toString())
            : 50;
        fetchRejectedFE(api_service_1.api_service.api_urls.FE_LIST, payload, process.env.VENDOR_BASE_URL);
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [isFilterButton]);
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    return (React.createElement("div", { className: "unclickablerow custom-pagination-table" },
        React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.FE_REJECT_COLUMNS, rows: feRejectedList && feRejectedList, errorMessage: errorMessage, pagination: false }),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: feRejectedList === null || feRejectedList === void 0 ? void 0 : feRejectedList.length, currentPageLimits: CurrentPagelimit === null || CurrentPagelimit === void 0 ? void 0 : CurrentPagelimit.value })));
};
exports.default = FE_REJECTEDLIST;
