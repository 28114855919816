"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var Delete_1 = require("@mui/icons-material/Delete");
var material_1 = require("@mui/material");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var button_component_1 = require("../../../../common/buttons/button_component");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var react_router_1 = require("react-router");
require("./orc_styles.scss");
var schema_1 = require("../../../../constants/schema");
var orc_service_1 = require("./orc_service");
var material_2 = require("@mui/material");
var CC_api_calls_1 = require("../../CC/CC_api_calls");
var api_service_1 = require("../../../../api/api_service");
var api_urls_1 = require("../../../../api/api_urls");
var react_hot_toast_1 = require("react-hot-toast");
var route_path_1 = require("../../../../constants/route_path");
var ModalComponentWithoutButton_1 = require("../../../../common/modal/ModalComponentWithoutButton");
var react_redux_1 = require("react-redux");
var label = { inputProps: { 'aria-label': 'Switch demo' } };
var selectedIndex = 0;
var CreateOrc = function () {
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var orc_deactivation_reasons = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.orc_deactivation_reasons; });
    var _a = React.useState([]), primaryClientData = _a[0], setPrimaryClinetData = _a[1];
    var _b = React.useState(false), deactiveConfirModal = _b[0], setDeactiveConfirModal = _b[1];
    var _c = React.useState(false), deleteOractiveteConfirModal = _c[0], setDeleteOractiveteConfirModal = _c[1];
    var _d = React.useState(orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE), modalActionType = _d[0], setModalActionType = _d[1];
    var _e = React.useState(orc_service_1.initialValues), ocrInitialValues = _e[0], setOcrInitialValues = _e[1];
    var _f = React.useState(null), editProfileData = _f[0], setEditProfileData = _f[1];
    var ActiveDeactiveInitialValues = {
        reject_type: null,
        reject_comment: '',
    };
    var onKeyPress = function (e) {
        if (new RegExp(/^[0-9]+$/).test(e.key)) {
        }
        else {
            e.preventDefault();
        }
    };
    var deleteOrActivateSubmitHandlere = function (setFieldTouched, setFieldValue, remove) {
        if (modalActionType == orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED) {
            remove(selectedIndex);
        }
        else {
            setFieldTouched("associative_clients[".concat(selectedIndex, "].status"), true);
            setFieldValue("associative_clients[".concat(selectedIndex, "].status"), modalActionType);
        }
        setDeleteOractiveteConfirModal(false);
    };
    var deleteOrActivateCloseHandlere = function (isModalOpened) {
        setDeleteOractiveteConfirModal(isModalOpened);
    };
    var deactivateSubmitHandlere = function (values, setFieldTouched, setFieldValue, remove) {
        setFieldTouched("associative_clients[".concat(selectedIndex, "].inactive_reason"), true);
        setFieldValue("associative_clients[".concat(selectedIndex, "].inactive_reason"), "".concat(values.reject_type.label, "-").concat(values.reject_comment));
        setFieldTouched("associative_clients[".concat(selectedIndex, "].status"), true);
        setFieldValue("associative_clients[".concat(selectedIndex, "].status"), modalActionType);
        setDeactiveConfirModal(false);
    };
    var deactivateCloseHandlere = function (isModalOpened) {
        setDeactiveConfirModal(isModalOpened);
    };
    var ontextChange = function (searchString) {
        if (searchString === '' ||
            searchString === null ||
            searchString === undefined) {
            var payload = {};
            payload['page'] = 1;
            payload['size'] = 50;
            fetchWarehouseCode(payload);
        }
        else if (searchString.length > 2) {
            var payload = {
                search_string: searchString,
            };
            fetchWarehouseCode(payload);
        }
    };
    var onAutoCompleteClose = function () {
        var payload = {};
        payload['page'] = 1;
        payload['size'] = 50;
        fetchWarehouseCode(payload);
    };
    var fetchWarehouseCode = function (payload) {
        (0, CC_api_calls_1.fetchCCTimeSlotList)(payload)
            .then(function (response) {
            var slicedList = response.result.length > 0 ? response.result.slice(0, 30 || 10) : [];
            var convertedData = (0, orc_service_1.convertPrimaryClientData)(slicedList);
            setPrimaryClinetData(convertedData);
        })
            .catch(function (err) {
            setPrimaryClinetData([]);
        });
    };
    var typeOfActioHandlere = function (action) {
        switch (action) {
            case orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED:
                return 'Delete';
            case orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE:
                return 'Active';
            default:
                return 'Delete';
        }
    };
    var filterChientIds = function (data) {
        var childClientId = [];
        data.map(function (val) {
            childClientId.push(Number(val.associative_client.id));
        });
        return childClientId;
    };
    var checkObjectComparison = function (values) {
        var modifiedChild = [];
        var currentChildClientId = filterChientIds(values.associative_clients);
        var deletedChild = [];
        editProfileData.associative_clients.map(function (val) {
            if (currentChildClientId.includes(Number(val.associative_client.id))) {
                var findCurrentObj = values.associative_clients.find(function (obj) { return obj.associative_client.id == val.associative_client.id; });
                if (JSON.stringify(val) != JSON.stringify(findCurrentObj)) {
                    modifiedChild.push(findCurrentObj);
                }
            }
            else {
                val.status = orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED;
                deletedChild.push(val);
            }
        });
        var editChildClientId = filterChientIds(editProfileData.associative_clients);
        var newlyAddedChild = [];
        values.associative_clients.map(function (obj) {
            if (!editChildClientId.includes(Number(obj.associative_client.id))) {
                newlyAddedChild.push(obj);
            }
        });
        return __spreadArray(__spreadArray(__spreadArray([], deletedChild, true), modifiedChild, true), newlyAddedChild, true);
    };
    var updatePayloadPreparation = function (values) {
        if (JSON.stringify(editProfileData.associative_clients) == JSON.stringify(values.associative_clients)) {
            return values.associative_clients;
        }
        else {
            if (editProfileData.associative_clients.length == values.associative_clients.length) {
                var modifiedData = checkObjectComparison(values);
                return modifiedData;
            }
            else {
                if (editProfileData.associative_clients.length < values.associative_clients.length) {
                    var convertstring_1 = [];
                    editProfileData.associative_clients.map(function (val) {
                        convertstring_1.push(JSON.stringify(val));
                    });
                    var newaddedChildClient_1 = [];
                    values.associative_clients.map(function (val) {
                        if (!convertstring_1.includes(JSON.stringify(val))) {
                            newaddedChildClient_1.push(val);
                        }
                    });
                    return newaddedChildClient_1;
                }
                else {
                    var childClientId_1 = filterChientIds(values.associative_clients);
                    var deletedChild_1 = [];
                    var modifiedChild_1 = [];
                    editProfileData.associative_clients.map(function (val) {
                        if (childClientId_1.includes(Number(val.associative_client.id))) {
                            var findCurrentObj = values.associative_clients.find(function (obj) { return obj.associative_client.id == val.associative_client.id; });
                            if (JSON.stringify(val) != JSON.stringify(findCurrentObj)) {
                                modifiedChild_1.push(findCurrentObj);
                            }
                        }
                        else {
                            val.status = orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED;
                            deletedChild_1.push(val);
                        }
                    });
                    return __spreadArray(__spreadArray([], deletedChild_1, true), modifiedChild_1, true);
                }
            }
        }
    };
    var submitHandlre = function (values) {
        if (!id) {
            var associativeClients_1 = [];
            values.associative_clients.map(function (val) {
                var newObj = {
                    id: val.associative_client.id,
                    sales_percentage: Number(val.percentage_of_sales),
                    capping_amount: Number(val.compping_amount)
                };
                associativeClients_1.push(newObj);
            });
            var createPayload = {
                primary_center: values.primary_client.id,
                associated_center: associativeClients_1
            };
            api_service_1.api_service.post({
                endPoint: api_urls_1.api_urls.CREATE_ORC,
                domain: process.env.APPROVAL_BASEURL,
                payLoad: createPayload
            }).then(function (res) {
                if (res.data.statusCode === 200) {
                    react_hot_toast_1.default.success(res.data.message);
                    nav("/".concat(route_path_1.default.PATHS.orc_listing, "/").concat(route_path_1.default.PATHS.orc_level1_listing));
                }
                else {
                    react_hot_toast_1.default.error(res.data.message);
                }
            }).catch(function (err) {
                return err;
            });
        }
        else {
            if (JSON.stringify(values) == JSON.stringify(editProfileData)) {
                react_hot_toast_1.default.error("No changes available");
                return;
            }
            else {
                var updatedChildData = updatePayloadPreparation(values);
                var associativeClients_2 = [];
                updatedChildData.map(function (val) {
                    var newObj = {
                        id: val.associative_client.id,
                        sales_percentage: Number(val.percentage_of_sales),
                        capping_amount: Number(val.compping_amount),
                        status: val.status,
                    };
                    if (Number(val.status) == orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE) {
                        newObj['inactive_reason'] = val.inactive_reason;
                    }
                    associativeClients_2.push(newObj);
                });
                var updatePayload = {
                    primary_client_id: values.primary_client.id,
                    associated_client: associativeClients_2,
                };
                api_service_1.api_service.put({
                    endPoint: api_urls_1.api_urls.UPDATE_ORC,
                    domain: process.env.APPROVAL_BASEURL,
                    payLoad: updatePayload
                }).then(function (res) {
                    if (res.data.statusCode === 200) {
                        react_hot_toast_1.default.success(res.data.message);
                        nav("/".concat(route_path_1.default.PATHS.orc_listing, "/").concat(route_path_1.default.PATHS.orc_level1_listing));
                    }
                    else {
                        react_hot_toast_1.default.error(res.data.message);
                    }
                }).catch(function (err) {
                    return err;
                });
            }
        }
    };
    var getChildClient = function (primary_client_id, setFieldValue, setFieldTouched) {
        if (primary_client_id) {
            api_service_1.api_service.get({
                endPoint: "".concat(api_service_1.api_service.api_urls.ORC_PROFILE_DETAILS, "/").concat(primary_client_id),
                domain: process.env.APPROVAL_BASEURL,
            }).then(function (res) {
                if (res.data.statusCode == 200) {
                    if (res.data.data.associate_center_data && res.data.data.associate_center_data.length > 0) {
                        var convertedData = (0, orc_service_1.convertORCProfileAPIResponse)(res.data.data);
                        var formInitialValues = (0, orc_service_1.convertInitialValuedFormate)(convertedData);
                        setFieldValue('associative_clients', formInitialValues.associative_clients);
                    }
                    else {
                        setFieldValue('associative_clients', orc_service_1.associative_clinet_initial_values);
                    }
                }
                else {
                    react_hot_toast_1.default.error(res.data.message);
                    setFieldValue('associative_clients', orc_service_1.associative_clinet_initial_values);
                }
            }).catch(function (err) {
                return err;
            });
        }
    };
    React.useEffect(function () {
        var payload = {};
        payload['page'] = 1;
        payload['size'] = 50;
        fetchWarehouseCode(payload);
    }, []);
    React.useEffect(function () {
        if (id) {
            api_service_1.api_service.get({
                endPoint: "".concat(api_service_1.api_service.api_urls.ORC_PROFILE_DETAILS, "/").concat(id),
                domain: process.env.APPROVAL_BASEURL,
            }).then(function (res) {
                if (res.data.statusCode == 200) {
                    var convertedData = (0, orc_service_1.convertORCProfileAPIResponse)(res.data.data);
                    var formInitialValues = (0, orc_service_1.convertInitialValuedFormate)(convertedData);
                    setOcrInitialValues(formInitialValues);
                    setEditProfileData(formInitialValues);
                }
                else {
                    setOcrInitialValues(orc_service_1.initialValues);
                    setEditProfileData(null);
                    react_hot_toast_1.default.error(res.data.message);
                }
            }).catch(function (err) {
                return err;
            });
        }
    }, []);
    var renderDeleteORActivateModal = function (setFieldTouched, setFieldValue, remove) {
        return React.createElement(material_1.Modal, { open: deleteOractiveteConfirModal },
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                    React.createElement("h2", null, "Confirmation")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, "Are you sure  you want to  ".concat(typeOfActioHandlere(modalActionType)))),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return deleteOrActivateCloseHandlere(false); } }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow", onClickHandler: function () { return deleteOrActivateSubmitHandlere(setFieldTouched, setFieldValue, remove); } }))));
    };
    var renderDeactiveModal = function (setFieldTouched, setFieldValue, remove) {
        return React.createElement(ModalComponentWithoutButton_1.default, { open: deactiveConfirModal },
            React.createElement("div", null,
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("h2", { className: "heading" }, "Are you sure  you want to  Deactivate "))),
            React.createElement(formik_1.Formik, { initialValues: ActiveDeactiveInitialValues, validateOnBlur: false, validationSchema: schema_1.schemas.LEVEL_ONE_REJECT_SCHEMA, onSubmit: function (values) { return deactivateSubmitHandlere(values, setFieldTouched, setFieldValue, remove); } }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldTouched = _a.setFieldTouched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                            React.createElement(drop_down_component_1.default, { name: "reject_type", placeholder: "Reason", dropDownList: orc_deactivation_reasons, onChange: function (val) {
                                    setFieldValue('reject_type', val);
                                    setFieldTouched('reject_type', true);
                                }, value: values.reject_type }),
                            errors['reject_type'] && touched['reject_type'] ? (React.createElement("div", { className: "error" }, errors['reject_type'])) : null),
                        React.createElement(Grid_1.default, { item: true, xs: 12 },
                            React.createElement(text_filed_component_1.default, { name: "reject_comment", maxLength: 200, onChange: function (reject_comment) {
                                    setFieldTouched('reject_comment');
                                    setFieldValue('reject_comment', reject_comment);
                                }, label: "Other Comments", value: values.reject_comment }),
                            errors['reject_comment'] && touched['reject_comment'] ? (React.createElement("div", { className: "error" }, errors['reject_comment'])) : null)),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return deactivateCloseHandlere(false); } }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
            }));
    };
    return (React.createElement("div", { className: "block-slot-wrapper" },
        React.createElement("div", null,
            React.createElement(formik_1.Formik, { initialValues: ocrInitialValues, validationSchema: schema_1.OCR_SCHEMA, validateOnBlur: false, enableReinitialize: true, onSubmit: submitHandlre }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                return (React.createElement(formik_1.Form, { id: "myForm", className: "input-fileds-wrapper" },
                    React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                        React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                            React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: function () { return nav(-1); } }),
                            React.createElement("h2", null, id ? "Edit ORC" : "Add ORC")),
                        React.createElement("div", { className: "page-control-wrapper" },
                            React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: function () { return nav(-1); } }),
                            React.createElement(button_component_1.default, { buttonName: "Submit", color: "yellow", type: "submit" }))),
                    React.createElement("div", { className: "add-slotform form-filed-wrapper" },
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(drop_down_component_1.default, { name: "rlab", disableClearable: true, placeholder: "Select Primary Client", label: "Primary Client", isDisabled: id ? true : false, dropDownList: primaryClientData.length > 0 ? primaryClientData : [], onChange: function (val) {
                                        getChildClient(val.id, setFieldValue, setFieldTouched);
                                        setFieldValue('invoice_code', val.invoice_code);
                                        setFieldValue('client_name', val.client_name);
                                        setFieldValue('state', val.state_name);
                                        setFieldValue('city', val.city_name);
                                        setFieldValue('lab_code', val.lab_code);
                                        setFieldValue('lab_name', val.lab_name);
                                        setFieldValue('route_type', val.route_type);
                                        setFieldValue('company_id', val.company_id);
                                        setFieldTouched('primary_client', true);
                                        setFieldValue('primary_client', val);
                                    }, ontextChange: ontextChange, onClose: onAutoCompleteClose, value: values.primary_client }),
                                errors['primary_client'] && touched['primary_client'] ? (React.createElement("div", { className: "error" },
                                    errors['primary_client'],
                                    " ")) : null),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "invoice_code", label: "Invoice Code", value: values.invoice_code, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "client_name", label: "Client Name", value: values.client_name, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "state", label: "State", value: values.state, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "city", label: "City", value: values.city, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "lab_code", label: "Labcode", value: values.lab_code, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "lab_name", placeholder: "Lane", label: "Lab Name", value: values.lab_name, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "v", label: "Route Type", value: values.route_type, isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3 },
                                React.createElement(text_filed_component_1.default, { name: "company_id", label: "Company ID", value: values.company_id, isDisabled: true })))),
                    React.createElement(formik_1.FieldArray, { name: "associative_clients" }, function (_a) {
                        var push = _a.push, remove = _a.remove;
                        return (React.createElement("div", { className: "add-slotform form-filed-wrapper" },
                            React.createElement(button_component_1.default, { buttonName: "Add Associate Clients", onClickHandler: function () {
                                    return push(orc_service_1.associative_clinet_initial_values[0]);
                                }, color: "yellow" }),
                            React.createElement("div", { className: "field-array-wrapper associative-client" }, values.associative_clients.map(function (val, index) {
                                return React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3, rowSpacing: 0, alignItems: "center", className: "".concat(Math.abs(index % 2) == 1 ? "associative-child-even" : "associative-child-odd") },
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(drop_down_component_1.default, { name: "rlab", disableClearable: true, placeholder: "Select Associate Client", label: "Primary Client", isDisabled: editProfileData && values.associative_clients[index].associative_client && editProfileData.associative_clients.some(function (val) { return val.associative_client.value == values.associative_clients[index].associative_client.value ? true : false; }), dropDownList: values.primary_client == null ? [] : primaryClientData.filter(function (val) { return val.label != values.primary_client.label; }), onChange: function (val) {
                                                setFieldValue("associative_clients[".concat(index, "].invoice_code"), val.invoice_code);
                                                setFieldValue("associative_clients[".concat(index, "].client_name"), val.client_name);
                                                setFieldValue("associative_clients[".concat(index, "].state"), val.state_name);
                                                setFieldValue("associative_clients[".concat(index, "].city"), val.city_name);
                                                setFieldValue("associative_clients[".concat(index, "].lab_code"), val.lab_code);
                                                setFieldValue("associative_clients[".concat(index, "].lab_name"), val.lab_name);
                                                setFieldValue("associative_clients[".concat(index, "].route_type"), val.route_type);
                                                setFieldValue("associative_clients[".concat(index, "].company_id"), val.company_id);
                                                setFieldTouched("associative_clients[".concat(index, "].associative_client"), true);
                                                setFieldValue("associative_clients[".concat(index, "].associative_client"), val);
                                            }, ontextChange: ontextChange, onClose: onAutoCompleteClose, value: values.associative_clients[index].associative_client }),
                                        errors['associative_clients'] &&
                                            errors['associative_clients'][index] &&
                                            errors['associative_clients'][index].associative_client &&
                                            touched['associative_clients'] ? (React.createElement("div", { className: "error" }, errors['associative_clients'][index].associative_client)) : null,
                                        errors['associative_clients'] &&
                                            errors['associative_clients'][index] &&
                                            typeof (errors['associative_clients'][index]) == 'string' &&
                                            touched['associative_clients'] ? (React.createElement("div", { className: "error" }, errors['associative_clients'][index])) : null),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(text_filed_component_1.default, { name: "invoice_code", label: "Invoice Code", value: values.associative_clients[index].invoice_code, isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(text_filed_component_1.default, { name: "client_name", label: "Client Name", value: values.associative_clients[index].client_name, isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(text_filed_component_1.default, { name: "state", label: "State", value: values.associative_clients[index].state, isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(text_filed_component_1.default, { name: "city", label: "City", value: values.associative_clients[index].city, isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(text_filed_component_1.default, { name: "lab_code", label: "Labcode", value: values.associative_clients[index].lab_code, isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(text_filed_component_1.default, { name: "lab_name", placeholder: "Lane", label: "Lab Name", value: values.associative_clients[index].lab_name, isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(text_filed_component_1.default, { name: "v", label: "Route Type", value: values.associative_clients[index].route_type, isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(text_filed_component_1.default, { name: "company_id", label: "Company ID", value: values.associative_clients[index].company_id, isDisabled: true })),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement("div", { className: 'input-wrapper' },
                                            React.createElement(material_2.TextField, { fullWidth: true, autoComplete: "off", name: "percentage_of_sales", placeholder: "Enter percentage of sales", label: "Percentage of sales", onKeyPress: onKeyPress, variant: "filled", InputProps: {
                                                    endAdornment: (React.createElement(material_2.InputAdornment, { position: "end" }, "%")),
                                                }, value: values.associative_clients[index].percentage_of_sales, onChange: function (event) {
                                                    setFieldTouched("associative_clients[".concat(index, "].percentage_of_sales"), true);
                                                    setFieldValue("associative_clients[".concat(index, "].percentage_of_sales"), event.target.value);
                                                } })),
                                        errors['associative_clients'] &&
                                            errors['associative_clients'][index] &&
                                            errors['associative_clients'][index].percentage_of_sales &&
                                            touched['associative_clients'] ? (React.createElement("div", { className: "error" }, errors['associative_clients'][index].percentage_of_sales)) : null),
                                    React.createElement(Grid_1.default, { item: true, xs: 2, lg: 2 },
                                        React.createElement(text_filed_component_1.default, { name: "compping_amount", placeholder: "Enter capping amount", label: "Capping Amount", value: values.associative_clients[index].compping_amount, onKeyPress: onKeyPress, onChange: function (event) {
                                                setFieldTouched("associative_clients[".concat(index, "].compping_amount"), true);
                                                setFieldValue("associative_clients[".concat(index, "].compping_amount"), event);
                                            } }),
                                        errors['associative_clients'] &&
                                            errors['associative_clients'][index] &&
                                            errors['associative_clients'][index].compping_amount &&
                                            touched['associative_clients'] ? (React.createElement("div", { className: "error" }, errors['associative_clients'][index].compping_amount)) : null),
                                    values.associative_clients.length > 1 && React.createElement(Grid_1.default, { item: true, xs: 1, lg: 1 },
                                        React.createElement(button_component_1.default, { type: "button", color: "yellow-outline", variant: "outlined", startIcon: React.createElement(Delete_1.default, null), buttonName: '', onClickHandler: function () {
                                                selectedIndex = index;
                                                setDeleteOractiveteConfirModal(true);
                                                setModalActionType(orc_service_1.ORC_CLIENT_STATUS_OBJ.DELETED);
                                            } })),
                                    id && React.createElement(Grid_1.default, { item: true, xs: 1, lg: 1 },
                                        React.createElement(material_2.Switch, __assign({}, label, { key: Math.random(), defaultChecked: values.associative_clients[index].status == 5 ? true : false, onClick: function (e) {
                                                selectedIndex = index;
                                                if (values.associative_clients[index].status == orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE) {
                                                    setDeactiveConfirModal(true);
                                                    setModalActionType(orc_service_1.ORC_CLIENT_STATUS_OBJ.INACTIVE);
                                                }
                                                else {
                                                    setDeleteOractiveteConfirModal(true);
                                                    setModalActionType(orc_service_1.ORC_CLIENT_STATUS_OBJ.ACTIVE);
                                                }
                                                e.preventDefault();
                                            } }))),
                                    renderDeleteORActivateModal(setFieldTouched, setFieldValue, remove),
                                    renderDeactiveModal(setFieldTouched, setFieldValue, remove));
                            }))));
                    })));
            }))));
};
exports.default = CreateOrc;
