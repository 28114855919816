"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteShipmentRoute = exports.EditShipmentRoute = exports.createShipmentRoute = exports.fetchShipmentRouteList = void 0;
var api_service_1 = require("../../../api/api_service");
var fetchShipmentRouteList = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.Shipment_List,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchShipmentRouteList = fetchShipmentRouteList;
var createShipmentRoute = function (payLoad, endpoint) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: endpoint,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.createShipmentRoute = createShipmentRoute;
var EditShipmentRoute = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.Shipment_details,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.EditShipmentRoute = EditShipmentRoute;
var DeleteShipmentRoute = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.Shipment_delete,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payLoad,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.DeleteShipmentRoute = DeleteShipmentRoute;
