"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var modal_component_1 = require("../../../common/modal/modal_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var search_component_1 = require("../../../common/search/search_component");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var text_field_with_datepicker_1 = require("../../../common/text_filed/text_field_with_datepicker");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var text_filed_with_fromto_Daterange_1 = require("../../../common/text_filed/text_filed_with_fromto_Daterange");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var lodash_1 = require("../../../utils/lodash");
var generic_api_calls_1 = require("../../generic_api_calls");
var leavetracking_apicalls_1 = require("./leavetracking.apicalls");
var leavetracking_helper_1 = require("./leavetracking.helper");
var LeaveTracking = function () {
    var navigate = (0, react_router_1.useNavigate)();
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.leaveManagement);
    var _a = React.useState(null), filterLeaveStatus = _a[0], setLeaveStatusFilter = _a[1];
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var _b = React.useState(new Date().toString()), filterFromDate = _b[0], setFromDateFilter = _b[1];
    var _c = React.useState(new Date().toString()), filterToDate = _c[0], setToDateFilter = _c[1];
    var _d = React.useState(undefined), dataList = _d[0], setDataList = _d[1];
    var _e = React.useState(''), searchTerm = _e[0], setSearchTerm = _e[1];
    var _f = React.useState([]), filterSelectedStates = _f[0], setSelectedFilterStates = _f[1];
    var _g = React.useState([]), filterSelectedCities = _g[0], setSelectedFilterCities = _g[1];
    var _h = React.useState([]), citiesData = _h[0], setCitiesData = _h[1];
    var _j = React.useState(''), errorMessage = _j[0], setErrorMessage = _j[1];
    var _k = React.useState(false), open = _k[0], setOpen = _k[1];
    var _l = React.useState(false), isReportDownloadOpend = _l[0], setIsReportDownloadOpend = _l[1];
    var _m = React.useState([]), feList = _m[0], setFeList = _m[1];
    var _o = React.useState([]), reasonsList = _o[0], setReasonsList = _o[1];
    var stateList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).stateList;
    var vendorId = (0, react_redux_1.useSelector)(function (state) { return state.user.vendorId; });
    var _p = React.useState(1), pageCount = _p[0], setpageCount = _p[1];
    var _q = React.useState(0), totalPageCount = _q[0], setTotalPageCount = _q[1];
    var _r = React.useState(appConstants_1.defaultPageLimit), PageLimit = _r[0], setPageLimit = _r[1];
    var _s = React.useState(0), totalCount = _s[0], setTotalCount = _s[1];
    var _t = React.useState(false), isDateRangeUpdated = _t[0], setIsDateRangeUpdated = _t[1];
    var callBackOnDateRangeSelect = function (dateRangeArray) {
        if (dateRangeArray) {
            setFromDateFilter(moment(dateRangeArray.start).format('YYYY-MM-DD'));
            setToDateFilter(moment(dateRangeArray.end).format('YYYY-MM-DD'));
        }
        else {
            setFromDateFilter(null);
            setToDateFilter(null);
        }
        setLeaveStatusFilter(null);
        setIsDateRangeUpdated(!isDateRangeUpdated);
    };
    var handleOpen = function () {
        fetchFEData((0, leavetracking_helper_1.preparePayloadForSearchByFe)(null));
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var callbackUpdateSelectedStates = function (value) {
        setCitiesData([]);
        setSelectedFilterCities([]);
        setSelectedFilterStates(value);
        setLeaveStatusFilter(null);
    };
    var fetchFEData = function (payload) {
        (0, generic_api_calls_1.fetchFEActiveData)(payload).then(function (res) {
            var slicedList = res.slice(0, 5);
            setFeList(slicedList);
        });
    };
    console.log(dataList, 'dataListdat');
    var ontextChange = function (searchString) {
        if (searchString === '' ||
            searchString === null ||
            searchString === undefined) {
            fetchFEData((0, leavetracking_helper_1.preparePayloadForSearchByFe)(null));
        }
        else if (searchString.length > 2) {
            var payload = (0, leavetracking_helper_1.preparePayloadForSearchByFe)(searchString);
            fetchFEData(payload);
        }
    };
    var onAutoCompleteClose = function () {
        fetchFEData((0, leavetracking_helper_1.preparePayloadForSearchByFe)(null));
    };
    var callbackUpdateSelectedCities = function (value) {
        setSelectedFilterCities(value);
        setLeaveStatusFilter(null);
    };
    var fetchCities = function () { return __awaiter(void 0, void 0, void 0, function () {
        var stateIdArray, payloadToFetchCities;
        return __generator(this, function (_a) {
            stateIdArray = filterSelectedStates.map(function (state) { return state.id; });
            payloadToFetchCities = {
                state_id: stateIdArray,
            };
            (0, leavetracking_apicalls_1.fetchCitiesList)(payloadToFetchCities)
                .then(function (citiesListResponse) {
                var formattedCitiesList = (0, array_helpers_1.convertLabelAndValueCity)(citiesListResponse.cities);
                setCitiesData(formattedCitiesList);
            })
                .catch(function (e) {
                setCitiesData([]);
            });
            return [2];
        });
    }); };
    var statusHandler = function (e) {
        setLeaveStatusFilter(e);
    };
    var searchingHandler = function (e) {
        setSearchTerm(e.target.value.trim());
    };
    var totalDays = function (startDate, endDate) {
        var fromDate = moment(startDate).format('DD-MM-YYYY');
        var toDate = moment(endDate).format('DD-MM-YYYY');
        var totalDays = moment(toDate + ' 23:59:00', 'DD-MM-YYYY hh:mm:ss').diff(moment(fromDate + ' 00:00:00', 'DD-MM-YYYY'), 'h');
        totalDays = Math.ceil(totalDays / 24);
        if (totalDays >= 1) {
            return totalDays;
        }
        else if (totalDays === 0) {
            return 1;
        }
        else {
            return 'Invalid';
        }
    };
    var disablePresent = function () {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    };
    var formSubmitHandler = function (values) {
        var daysDiff = totalDays(values.fromDate, values.toDate);
        var payload = (0, leavetracking_helper_1.preparePayloadForLeaveRequest)(__assign({}, values), vendorId, daysDiff);
        (0, leavetracking_apicalls_1.submitLeaveRequestForFE)(payload)
            .then(function (submitLeaveResponse) {
            if (submitLeaveResponse.status === 200 ||
                submitLeaveResponse.status === 201) {
                react_hot_toast_1.default.success(submitLeaveResponse.data.message);
                refetch();
                handleClose();
            }
            else {
                react_hot_toast_1.default.error(submitLeaveResponse.data.message);
            }
        })
            .catch(function (e) { return react_hot_toast_1.default.error(e.message); });
    };
    var refetch = (0, react_query_1.useQuery)(['searchrejected-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (filterLeaveStatus !== null) {
            payload.status = [filterLeaveStatus.value.toString()];
        }
        if (filterFromDate && filterToDate !== null) {
            payload.date_range = {
                start_date: moment(filterFromDate).format('YYYY-MM-DD'),
                end_date: moment(filterToDate).format('YYYY-MM-DD'),
            };
        }
        if (filterSelectedCities.length > 0) {
            var data_1 = [];
            filterSelectedCities.map(function (selectedCityObj) {
                data_1.push({
                    city: selectedCityObj.short_code,
                    state: selectedCityObj.state_name,
                });
            });
            payload.geographies = data_1;
        }
        if (searchTerm.trim().length >= 3) {
            payload.search_key = searchTerm.toLocaleLowerCase().trim();
        }
        payload.page = currentPage;
        payload.limit = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payload.vendor_id = vendorId;
        (0, leavetracking_apicalls_1.fetchLeavesList)(payload)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.data.count / pageSize);
            setErrorMessage('');
            setTotalPageCount(count);
            var convertedData = (0, array_helpers_1.convertLeaveListFromRes)(response.data);
            setDataList(convertedData);
            setTotalCount(response.data.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setDataList([]);
        });
    }).refetch;
    var navigateToMyReports = function () {
        navigate("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var updateSelectedCities = function () {
        var filteredData = lodash_1.default.intersectionBy(filterSelectedCities, stateList, 'state_id');
        setSelectedFilterCities(filteredData);
    };
    React.useEffect(function () {
        if (filterSelectedStates.length >= 1) {
            fetchCities();
        }
        if (false) {
            updateSelectedCities();
        }
    }, [filterSelectedStates]);
    var FilterHandler = function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            if (searchTerm.length >= 3) {
                refetch();
            }
            else if (searchTerm.length < 3 && searchTerm.length === 0) {
                if (filterSelectedCities || filterToDate || filterLeaveStatus) {
                    refetch();
                }
            }
        }
    };
    React.useEffect(function () {
        if (UpdateListData.isListReloaded) {
            refetch();
        }
    }, [UpdateListData.isListReloaded]);
    React.useEffect(function () {
        fetchFEData((0, leavetracking_helper_1.preparePayloadForSearchByFe)(null));
        (0, leavetracking_apicalls_1.fetchReasonsList)()
            .then(function (response) {
            var reasons = (0, array_helpers_1.covertKeyValuesReasonsList)(response);
            setReasonsList(reasons);
        })
            .catch(function (err) {
            console.log(err.message);
        });
    }, []);
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var handlePageLimitChange = function (val) {
        setPageLimit(val);
    };
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = {};
        if (filterLeaveStatus !== null) {
            payload.status = [filterLeaveStatus.value.toString()];
        }
        if (filterFromDate && filterToDate !== null) {
            payload.date_range = {
                start_date: moment(filterFromDate).format('YYYY-MM-DD'),
                end_date: moment(filterToDate).format('YYYY-MM-DD'),
            };
        }
        if (filterSelectedCities.length > 0) {
            var data_2 = [];
            filterSelectedCities.map(function (selectedCityObj) {
                data_2.push({
                    city: selectedCityObj.short_code,
                    state: selectedCityObj.state_name,
                });
            });
            payload.geographies = data_2;
        }
        if (searchTerm.trim().length >= 3) {
            payload.search_key = searchTerm.toLocaleLowerCase().trim();
        }
        payload.page = currentPage;
        payload.limit = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payload.vendor_id = vendorId;
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.leaveTrackingDownload,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                setIsReportDownloadOpend(true);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "fe-wrapper leave-tarcking-page" },
            React.createElement("div", { className: "tool-bar-wrapper" },
                React.createElement("div", { className: "page-heaidng" },
                    React.createElement("h2", null, "Leave Tracking")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { type: "button", buttonName: appConstants_1.reportDownload, color: "yellow-outline", isError: features[menu_constants_1.FEATURE_CONSTANTS.leaveCreate] &&
                            dataList &&
                            dataList.length > 0
                            ? false
                            : true, endIcon: "icon-import download-icon", onClickHandler: autoGenerateReportHandler }),
                    React.createElement(modal_component_1.default, { buttonName: "APPLY LEAVE", buttonColor: "yellow", onClickHandler: handleOpen, open: open, isBtnDisabled: !features[menu_constants_1.FEATURE_CONSTANTS.leaveCreate] },
                        React.createElement("div", { className: "modal-header header-wrapper" },
                            React.createElement("h2", { className: "heading" }, "Apply leave for FE"),
                            React.createElement("p", { className: "sub-heading" }, "Update leave details")),
                        React.createElement("div", { className: "modal-body" },
                            React.createElement(formik_1.Formik, { initialValues: {
                                    searchByFe: '',
                                    fromDate: moment().add(1, 'd').format('YYYY-MM-DD'),
                                    toDate: moment().add(1, 'd').format('YYYY-MM-DD'),
                                    totalDays: '',
                                    comments: '',
                                    reason: '',
                                }, validateOnBlur: false, validationSchema: schema_1.schemas.Apply_leave_schema, onSubmit: formSubmitHandler }, function (_a) {
                                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched;
                                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                                            React.createElement(drop_down_component_1.default, { name: "searchByFe", key: 'searchByFe', placeholder: "Search by FE", dropDownList: feList, onChange: function (val) {
                                                    setFieldValue('searchByFe', val);
                                                    setFieldTouched('searchByFe', true);
                                                }, value: values.searchByFe, ontextChange: ontextChange, onClose: onAutoCompleteClose }),
                                            errors['searchByFe'] && touched['searchByFe'] ? (React.createElement("div", { className: "error" }, errors['searchByFe'])) : null),
                                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                                            React.createElement(drop_down_component_1.default, { name: "reason", placeholder: "Reason", dropDownList: reasonsList, onChange: function (val) {
                                                    setFieldValue('reason', val);
                                                    setFieldTouched('reason', true);
                                                }, value: values.reason }),
                                            errors['reason'] &&
                                                touched['reason'] &&
                                                values['reason'] === '' ? (React.createElement("div", { className: "error" }, errors['reason'])) : null),
                                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                                            React.createElement(text_field_with_datepicker_1.default, { label: "fromDate", value: values.fromDate, onChange: function (e) {
                                                    setFieldValue('fromDate', e);
                                                    setFieldTouched('fromDate', true);
                                                }, isFutureDisable: false, minDate: disablePresent() }),
                                            errors['fromDate'] && touched['fromDate'] ? (React.createElement("div", { className: "error" }, errors['fromDate'])) : null),
                                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                                            React.createElement(text_field_with_datepicker_1.default, { label: "toDate", onChange: function (e) {
                                                    setFieldValue('toDate', e);
                                                    setFieldTouched('toDate', true);
                                                }, isPastDisable: true, isFutureDisable: false, value: values.toDate, minDate: disablePresent() }),
                                            errors['toDate'] && touched['toDate'] ? (React.createElement("div", { className: "error" }, errors['toDate'])) : null),
                                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                                            React.createElement(text_filed_component_1.default, { name: "totalDays", isDisabled: true, defaultVal: 1, value: totalDays(values.fromDate, values.toDate), label: "Total Days" }),
                                            errors['totalDays'] && touched['totalDays'] ? (React.createElement("div", { className: "error" }, errors['totalDays'])) : null),
                                        React.createElement(Grid_1.default, { item: true, xs: 12 },
                                            React.createElement(text_filed_component_1.default, { name: "comments", onChange: function (event) {
                                                    setFieldValue('comments', event);
                                                    setFieldTouched('comments', true);
                                                }, label: "Other Comments" }),
                                            errors['comments'] && touched['comments'] ? (React.createElement("div", { className: "error" }, errors['comments'])) : null)),
                                    React.createElement("div", { className: "modal-footer" },
                                        React.createElement(button_component_1.default, { onClickHandler: handleClose, buttonName: "Cancel", color: "yellow-outline" }),
                                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
                            }))))),
            React.createElement("div", { className: "filter-menu-wrapper" },
                React.createElement("div", { className: "search-filter" },
                    React.createElement(search_component_1.default, { placeholder: "Search FE", searchValue: searchTerm, searchHandler: searchingHandler })),
                React.createElement("div", { className: "filter-options" },
                    React.createElement(multi_selector_1.default, { label: "Select State", dropDownList: stateList && stateList.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: callbackUpdateSelectedStates, value: filterSelectedStates }),
                    React.createElement(multi_selector_1.default, { noOptions: 'No cities found', hasSelectAll: true, label: "Select City", dropDownList: citiesData && citiesData.sort(function (a, b) { return a.label.localeCompare(b.label); }), onChangeHnadlre: callbackUpdateSelectedCities, value: filterSelectedCities || [] }),
                    React.createElement("div", { className: "cursor-pointer" },
                        React.createElement(text_filed_with_fromto_Daterange_1.default, { onChangeHandler: callBackOnDateRangeSelect, presentDate: true })),
                    React.createElement(drop_down_component_1.default, { disableClearable: true, name: "status", placeholder: "Status", dropDownList: [
                            { id: 1, label: 'APPROVED', value: 'APPROVED' },
                            { id: 2, label: 'PENDING', value: 'PENDING' },
                            { id: 3, label: 'REVOKED', value: 'REVOKED' },
                        ], onChange: statusHandler, value: filterLeaveStatus || null }),
                    React.createElement(drop_down_component_1.default, { dropDownList: appConstants_1.paginationDropdown, onChange: handlePageLimitChange, value: PageLimit }),
                    React.createElement(button_component_1.default, { buttonName: "FILTERS", color: "yellow-outline", onClickHandler: FilterHandler }))),
            React.createElement("div", { className: "unclickablerow" },
                React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.FE_LEAVE_TRACKING_COLUMNS, rows: dataList, errorMessage: errorMessage, pagination: false, className: 'leave-tracking-table' }),
                React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: dataList === null || dataList === void 0 ? void 0 : dataList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }))),
        React.createElement(pop_up_1.default, { open: isReportDownloadOpend },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setIsReportDownloadOpend(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = LeaveTracking;
