"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editPreciousSample = exports.deletePreciousSample = exports.createPreciousSample = exports.fetchPreciousSamplesCatCodetype = exports.fetchPreciousContainerMatcodeTypes = exports.fetchPreciousSampletypes = exports.fetchPreciousSamplesList = void 0;
var api_service_1 = require("./../../../api/api_service");
var fetchPreciousSamplesList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.PRECIOUS_SAMPLES_LIST,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payload,
        })
            .then(function (response) {
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            console.log(err, 'precious');
            return reject(err);
        });
    });
};
exports.fetchPreciousSamplesList = fetchPreciousSamplesList;
var fetchPreciousSampletypes = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.PRECIOUS_SAMPLES_TYPE_LIST,
            domain: process.env.SAMPLE_TRAKING_URL,
        })
            .then(function (response) {
            console.log(response, 'precious');
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            console.log(err, 'precious');
            return reject(err);
        });
    });
};
exports.fetchPreciousSampletypes = fetchPreciousSampletypes;
var fetchPreciousContainerMatcodeTypes = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.PRECIOUS_SAMPLES_cONTAINER_TYPE_LIST,
            domain: process.env.SAMPLE_TRAKING_URL,
        })
            .then(function (response) {
            console.log(response, 'precious');
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            console.log(err, 'precious');
            return reject(err);
        });
    });
};
exports.fetchPreciousContainerMatcodeTypes = fetchPreciousContainerMatcodeTypes;
var fetchPreciousSamplesCatCodetype = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.PRECIOUS_SAMPLES_CAT_CCODE_LIST,
            domain: process.env.SAMPLE_TRAKING_URL,
        })
            .then(function (response) {
            console.log(response, 'precious');
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchPreciousSamplesCatCodetype = fetchPreciousSamplesCatCodetype;
var createPreciousSample = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CREATE_PRECIOUS_SAMPLES_LIST,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log(response, 'precious');
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.createPreciousSample = createPreciousSample;
var deletePreciousSample = function (endpoint) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: endpoint,
            domain: process.env.SAMPLE_TRAKING_URL,
        })
            .then(function (response) {
            console.log(response, 'precious');
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.deletePreciousSample = deletePreciousSample;
var editPreciousSample = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.UPDATE_PRECIOUS_SAMPLES_LIST,
            domain: process.env.SAMPLE_TRAKING_URL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log(response, 'precious');
            if (response.status === 200) {
                return resolve(response.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.editPreciousSample = editPreciousSample;
