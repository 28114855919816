"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var slotblocking_apicalls_1 = require("../../components/field_exicutive/slot_blocking/slotblocking.apicalls");
var array_helpers_1 = require("../../utils/array_helpers");
var button_component_1 = require("../buttons/button_component");
require("./time_slot.scss");
[];
var TimeSlot = function (_a) {
    var _b;
    var active = _a.active, onHandler = _a.onHandler, feSlotDay = _a.feSlotDay, slotsLoad = _a.slotsLoad, dayAndSlot = _a.dayAndSlot, setDayAndSlot = _a.setDayAndSlot, onChange = _a.onChange, viewMode = _a.viewMode, selectedLab = _a.selectedLab, setActive = _a.setActive, setTimeSlotValidtion = _a.setTimeSlotValidtion, feOnchage = _a.feOnchage;
    var _c = React.useState([]), timeSlotsList = _c[0], setTimeSlotList = _c[1];
    var _d = React.useState(false), render = _d[0], SetRender = _d[1];
    var _e = React.useState([]), payloadslot = _e[0], setPaylodSlot = _e[1];
    var arrayDaySlots = React.useState([])[0];
    var clickHandlerOnTimeslot = function (OnchangeSlotValues) {
        var _a;
        var _b, _c;
        var setColor;
        if (OnchangeSlotValues.color) {
            setColor = (_b = dayAndSlot["day".concat(active)]) === null || _b === void 0 ? void 0 : _b.map(function (slotObject) {
                if (slotObject.id === OnchangeSlotValues.id) {
                    slotObject['color'] = null;
                    return slotObject;
                }
                return slotObject;
            });
        }
        else {
            setColor = (_c = dayAndSlot["day".concat(active)]) === null || _c === void 0 ? void 0 : _c.map(function (slotObject) {
                if (slotObject.id === OnchangeSlotValues.id) {
                    slotObject['color'] = 'slot-btn status-selected';
                    return slotObject;
                }
                return slotObject;
            });
        }
        setDayAndSlot(__assign(__assign({}, dayAndSlot), (_a = {}, _a["day".concat(active)] = setColor, _a)));
        onChange();
    };
    var compareDayAndSlotEditFEslot = function (dayAndSlot) {
        var fedayAndSlot = {};
        feSlotDay.map(function (e) {
            console.log('fasdf', e);
            var dayWithId;
            dayWithId = (0, array_helpers_1.convertDayIntoDayid)(e.day);
            fedayAndSlot[dayWithId] = e.slots;
        });
        for (var dayAndSlotslot in dayAndSlot) {
            var _loop_1 = function (editSlots) {
                if (dayAndSlotslot === editSlots) {
                    dayAndSlot[dayAndSlotslot].map(function (mainObjslot) {
                        fedayAndSlot[editSlots].map(function (editObjSlot) {
                            if (mainObjslot.name === editObjSlot.slot_name) {
                                mainObjslot['color'] = 'slot-btn status-selected';
                            }
                        });
                    });
                }
            };
            for (var editSlots in fedayAndSlot) {
                _loop_1(editSlots);
            }
        }
        var entries = Object.entries(dayAndSlot);
        var generatedEditPayload = entries.map(function (_a) {
            var key = _a[0], val = _a[1];
            var converteddayId = (0, array_helpers_1.convertDayIDIntoId)(key);
            var slots = val.filter(function (slot) {
                if ((slot === null || slot === void 0 ? void 0 : slot.color) === 'slot-btn status-selected')
                    return slot.id;
            });
            var slotIds = slots.map(function (e) {
                return e.id;
            });
            return {
                day: converteddayId,
                slots: slotIds,
            };
        });
        setPaylodSlot(generatedEditPayload);
        onHandler(payloadslot);
    };
    var selectedDayslots = function () {
        var _a;
        var postOnchangeSlotDetails = (_a = dayAndSlot["day".concat(active)]) === null || _a === void 0 ? void 0 : _a.filter(function (slotObjects) {
            console.log('slotObjects', slotObjects);
            if ((slotObjects === null || slotObjects === void 0 ? void 0 : slotObjects.color) === 'slot-btn status-selected') {
                console.log('postOnchangeSlotDetails', slotObjects.id);
                return slotObjects.id;
            }
        });
        return postOnchangeSlotDetails;
    };
    React.useEffect(function () {
        var isDayMatched;
        var onchangeSlotDetails;
        var daySlotObj;
        var selectedSlotIds = [];
        onchangeSlotDetails = selectedDayslots();
        selectedSlotIds = onchangeSlotDetails.map(function (e) {
            return e.id;
        });
        daySlotObj = {
            day: active,
            slots: selectedSlotIds,
        };
        isDayMatched = arrayDaySlots.findIndex(function (p) { return p.day == daySlotObj.day; });
        if (isDayMatched > -1)
            arrayDaySlots[isDayMatched] = daySlotObj;
        else
            arrayDaySlots.push(daySlotObj);
        if ((payloadslot === null || payloadslot === void 0 ? void 0 : payloadslot.length) > 0) {
            payloadslot.map(function (each1) {
                arrayDaySlots.map(function (sEach) {
                    if (each1.day === sEach.day) {
                        payloadslot.map(function (day) {
                            if (day.day === each1.day) {
                                day.slots = sEach.slots;
                            }
                        });
                    }
                });
            });
        }
        onHandler(payloadslot);
    }, [timeSlotsList, dayAndSlot]);
    React.useEffect(function () {
        SetRender(!render);
        (0, slotblocking_apicalls_1.getAllTimeSlots)()
            .then(function (res) {
            for (var key in dayAndSlot) {
                dayAndSlot[key] = JSON.parse(JSON.stringify(res.data));
            }
            compareDayAndSlotEditFEslot(dayAndSlot);
            setTimeSlotList(res.data);
        })
            .catch(function (e) { return console.log(e); });
    }, [feSlotDay]);
    var clearSlots = function () {
        for (var key in dayAndSlot) {
            dayAndSlot[key] = JSON.parse(JSON.stringify(timeSlotsList));
        }
        setActive(1);
        setTimeSlotValidtion(false);
    };
    React.useEffect(function () {
        clearSlots();
    }, [selectedLab, feOnchage]);
    return (React.createElement("div", { className: "time-slot-wrapper" },
        React.createElement("div", { className: "time-slot-booking-group" },
            React.createElement("div", { className: viewMode == 'view' ? 'slot-wrapper view-only' : 'slot-wrapper' },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 14, xl: 16 } }, (_b = dayAndSlot["day".concat(active)]) === null || _b === void 0 ? void 0 : _b.map(function (slotDetails) {
                    return (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 2, xl: 2 },
                        React.createElement(button_component_1.default, { isError: viewMode == 'view'
                                ? (slotDetails === null || slotDetails === void 0 ? void 0 : slotDetails.color) === 'slot-btn status-selected'
                                    ? false
                                    : true
                                : false, buttonName: "".concat(slotDetails.from_time, " - ").concat(slotDetails.to_time), color: slotDetails.color
                                ? slotDetails.color
                                : 'slot-btn status-availabe', onClickHandler: function () {
                                viewMode == 'view'
                                    ? null
                                    : clickHandlerOnTimeslot(slotDetails);
                            } })));
                }))))));
};
exports.default = TimeSlot;
