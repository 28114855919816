"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var config_action_1 = require("../../../actions/config_action");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var auto_routing_api_calls_1 = require("./auto_routing_api_calls");
var auto_routing_helper_1 = require("./auto_routing_helper");
var TotalActiveClientsComponent = function (_a) {
    var activeClientsFilter = _a.activeClientsFilter, selectedLab = _a.selectedLab, PageLimit = _a.PageLimit, payloadexport = _a.payloadexport;
    var nav = (0, react_router_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = React.useState(undefined), activeClientsList = _b[0], setActiveClientsList = _b[1];
    var _c = React.useState(''), header = _c[0], setHeader = _c[1];
    var _d = React.useState(0), totalPageCount = _d[0], setTotalPageCount = _d[1];
    var _e = React.useState(1), pageCount = _e[0], setpageCount = _e[1];
    var _f = React.useState(0), totalCount = _f[0], setTotalCount = _f[1];
    var _g = React.useState(false), open = _g[0], setOpen = _g[1];
    var _h = React.useState(''), errorMessage = _h[0], setErrorMessage = _h[1];
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Control_tower);
    console.log(features, 'lkjhgc');
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    var navigateToMyReports = function () {
        nav("/".concat(route_path_1.default.PATHS.MYREPORTS));
    };
    var refetch = (0, react_query_1.useQuery)(['total-workforce-list', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        payloadexport['page'] = currentPage;
        payloadexport['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payloadexport['filter'] = activeClientsFilter;
        (0, auto_routing_api_calls_1.fetchTotalActiveClientsList)(payloadexport)
            .then(function (response) {
            var pageSize = PageLimit
                ? parseInt(PageLimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setTotalCount(response.count);
            console.log(response.result, 'iyhjbn');
            setActiveClientsList(response.result);
        })
            .catch(function (e) {
            setErrorMessage(e.message);
            setActiveClientsList([]);
            console.log(e.message, "oiuytrdesa");
        });
        if (activeClientsFilter === 1) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalActiveClients);
        }
        else if (activeClientsFilter === 2) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.untaggedClients);
        }
        else if (activeClientsFilter === 3) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.activeCCorPsc);
        }
        else if (activeClientsFilter === 4) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.activePUP);
        }
        else if (activeClientsFilter === 5) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.totalScheduledPickups);
        }
        else if (activeClientsFilter === 6) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.assignedDynamicPickups);
        }
        else if (activeClientsFilter === 7) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.assignedStaticPickups);
        }
        else if (activeClientsFilter === 8) {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.unAssignedDynamicPickups);
        }
        else {
            setHeader(auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.unassignedStaticPickups);
        }
    }).refetch;
    console.log(activeClientsFilter, 'activeClientsFilter', header);
    var navgaToClinetRoster = function (untaggedList) {
        console.log(untaggedList, 'untaggedList');
        if (header == auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.untaggedClients) {
            dispatch((0, config_action_1.featchCurrentActiveMenu)({
                mainModule: 'Roster Management',
                subModule: menu_constants_1.FEATURE_CONSTANTS.CC_Roster_Management,
                subChaildModule: '',
            }));
            nav("/".concat(route_path_1.default.PATHS.unTaggedClientsToCC, "/").concat(route_path_1.default.PATHS.addCCSlot, "/").concat(untaggedList.data.warehouse_code));
        }
    };
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [activeClientsFilter, PageLimit, selectedLab, payloadexport]);
    var autoGenerateReportHandler = function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        payloadexport['page'] = currentPage;
        payloadexport['size'] = PageLimit ? parseInt(PageLimit.value.toString()) : 50;
        payloadexport['filter'] = activeClientsFilter;
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.auto_routing_clients_data_generate,
            payLoad: payloadexport,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.message != 'Success') {
                    react_hot_toast_1.default.error(res.data.message);
                }
                else {
                    setOpen(true);
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "mid-page-heaidng" },
            React.createElement("h2", { className: "F-18" }, header),
            React.createElement(button_component_1.default, { buttonName: appConstants_1.reportDownload, color: "yellow-outline", endIcon: "icon-import download-icon", isError: !features[menu_constants_1.FEATURE_CONSTANTS.Control_tower_Download] ||
                    (activeClientsList === null || activeClientsList === void 0 ? void 0 : activeClientsList.length) <= 0, onClickHandler: autoGenerateReportHandler })),
        React.createElement("div", { className: "".concat(header == auto_routing_helper_1.CONTROL_DASHBOARD_TABLE_LABLES.untaggedClients
                ? 'custom-pagination-table control-tower-modal-table untagged-clients'
                : 'custom-pagination-table control-tower-modal-table') },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: activeClientsFilter === 2
                    ? table_data_1.default.UNTAGGED_CLIENTS_COLUMNS
                    : activeClientsFilter === 9
                        ? table_data_1.default.UNASSIGNED_STATIC_PICKUPS
                        : table_data_1.default.UNASSIGNED_DYNAMIC_PICKUPS, rows: activeClientsList, errorMessage: errorMessage, onRowClicked: navgaToClinetRoster, pagination: false })),
        React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: activeClientsList === null || activeClientsList === void 0 ? void 0 : activeClientsList.length, currentPageLimits: PageLimit === null || PageLimit === void 0 ? void 0 : PageLimit.value }),
        React.createElement(pop_up_1.default, { open: open },
            React.createElement("div", { className: "modal-header" },
                React.createElement("h2", { className: "heading" }, "Request Submitted"),
                React.createElement("h2", { className: "sub-heading" }, "Please view your report in My Reports section")),
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", isError: false, onClickHandler: function () { return setOpen(false); } }),
                React.createElement(button_component_1.default, { buttonName: "My Reports", color: "yellow", onClickHandler: navigateToMyReports, isError: false })))));
};
exports.default = TotalActiveClientsComponent;
