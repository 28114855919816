"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCCEditList = exports.getAllWeekDays = exports.fetchCCTimeSlotListWareHouse = exports.fetchCCTimeSlotList = void 0;
var react_hot_toast_1 = require("react-hot-toast");
var api_service_1 = require("../../../api/api_service");
var fetchCCTimeSlotList = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CC_TIMESLOT_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data.data);
            }
            var erroMessage = response.data
                ? response.data.message
                : 'Unable to process your request.';
            throw new Error(erroMessage);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCCTimeSlotList = fetchCCTimeSlotList;
var fetchCCTimeSlotListWareHouse = function (payLoad) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CC_TIMESLOT_LIST,
            domain: process.env.APPROVAL_BASEURL,
            payLoad: payLoad,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCCTimeSlotListWareHouse = fetchCCTimeSlotListWareHouse;
var getAllWeekDays = function () {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .get({
            endPoint: api_service_1.api_service.api_urls.WEEK_DAYS_FOR_SLOTS,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.getAllWeekDays = getAllWeekDays;
var fetchCCEditList = function (payload) {
    return new Promise(function (resolve, reject) {
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.CC_EDIT,
            domain: process.env.ONE_TWO_LEVEL_APPROVAL_URL,
            payLoad: payload,
        })
            .then(function (response) {
            console.log(response);
            if (response.status === 200) {
                return resolve(response.data);
            }
            else {
                react_hot_toast_1.default.error(response.data.message);
            }
            throw new Error(response.message);
        })
            .catch(function (err) {
            return reject(err);
        });
    });
};
exports.fetchCCEditList = fetchCCEditList;
