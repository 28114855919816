"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATUS_BUTTONS = void 0;
exports.STATUS_BUTTONS = {
    active: 'Active',
    inacitve: 'In Active',
    onHold: 'On Hold',
    draft: 'Draft',
    emergency: 'Emergency',
    onLeave: 'On Leave',
    exitEmployee: 'Exit Employee',
    approved: 'Approved',
    approve: 'APPROVE',
    reject: 'REJECT',
    pending: 'Pending',
    update: 'UPDATE',
    download: 'DOWNLOAD',
    paid: 'PAID',
    view: 'VIEW',
    addFe: 'ADD FE',
    exiFe: 'EXIT FE',
    editProfile: 'EDIT PROFILE',
    updaloadPhoto: 'UPLOAD PHOTO',
    savaAndContinue: 'SAVE & CONTINUE',
    saveDraft: 'SAVE AS DRAFT',
    deleteDraft: 'DELETE DRAFT',
    attachProofAdderess: 'ATTACH PROOF ADDRESS',
    back: 'BACK',
    submit: 'SUBMIT',
    cancel: 'CANCEL',
    continue: 'CONTINUE',
    revoked: 'REVOKED',
    deleted: 'DELETED',
    completed: 'Completed',
    incompleted: 'Incompleted',
    created: 'Created',
};
