"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid/Grid");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var FE_Creation_action_1 = require("../../../actions/FE_Creation_action");
var toaster_actions_1 = require("../../../actions/toaster_actions");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var dropdownFields_1 = require("../../../constants/dropdownFields");
var FECreationTabConstant_1 = require("../../../constants/FECreationTabConstant");
var formFieldConstants_1 = require("../../../constants/formFieldConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var generic_api_calls_1 = require("../../generic_api_calls");
var lodash_1 = require("./../../../utils/lodash");
var FE_PersonalDetailsForm1 = function (_a) {
    var initialValues = _a.initialValues, saveFormData = _a.saveFormData, FEIdToBeReplaced = _a.FEIdToBeReplaced, EditUserId = _a.EditUserId, updateValue = _a.updateValue, value = _a.value, FE_PersonalDetailsInitialState = _a.FE_PersonalDetailsInitialState, sendItForApproval = _a.sendItForApproval, onBoardingStatus = _a.onBoardingStatus, feEmploymentDetails = _a.feEmploymentDetails, replaceFeIdFromApi = _a.replaceFeIdFromApi, vendorCode = _a.vendorCode;
    console.log('initial values in fe personal details:', initialValues);
    var dispatch = (0, react_redux_1.useDispatch)();
    var nav = (0, react_router_dom_1.useNavigate)();
    var location = (0, react_router_dom_1.useLocation)();
    var editUserId = (0, react_router_dom_1.useParams)().id;
    var _b = React.useState(''), mobileNoError = _b[0], setMobileNoError = _b[1];
    console.log('FEIdToBeReplaced', FEIdToBeReplaced);
    console.log('replaceFeIdFromApi', replaceFeIdFromApi);
    var user_id = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer; }).user_id;
    var uploadFile = function (val) {
        console.log('inside fileUpload', val);
        var payload = new FormData();
        payload.append('cc_id', vendorCode);
        payload.append('file', val);
        payload.append('file_type', 'Other');
        return (0, generic_api_calls_1.fileUpload)(payload);
    };
    var mobileNumberValidation = function (e, value, errors, setErrors) {
        var payload = {
            mobile: value,
        };
        if (user_id) {
            payload['user_id'] = user_id;
        }
        (0, generic_api_calls_1.validateMobileNumber)(payload).then(function (res) {
            if (res.data.statusCode === 200) {
                if (res.data.data.is_exist) {
                    setMobileNoError('Mobile number is  already in use');
                }
                else {
                    setMobileNoError('');
                }
            }
            else {
                setMobileNoError('');
            }
        });
    };
    console.log(onBoardingStatus, 'MonBoardingStatus');
    var formSubmitHnadler = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, employmentDetails, personalDetailsObj_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('formSubmitHnadler1 called', values, initialValues);
                    if (lodash_1.default.isEqual(values, initialValues)) {
                        updateValue(FECreationTabConstant_1.CreationTabConstants.CONTACTDETAILS);
                        if (location.pathname.includes('edit')) {
                            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.CONTACTDETAILS), { replace: true });
                        }
                        else {
                            nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.CONTACTDETAILS), { replace: true });
                        }
                        return [2];
                    }
                    if (!(onBoardingStatus === 1)) return [3, 1];
                    saveFormData(values, false);
                    return [3, 5];
                case 1:
                    if (mobileNoError) {
                        return [2];
                    }
                    payload = {};
                    employmentDetails = {};
                    employmentDetails['role_id'] =
                        feEmploymentDetails.fe_Type != null ? feEmploymentDetails.fe_Type.id : '';
                    employmentDetails['date_of_joining'] =
                        feEmploymentDetails.dateOfJoining != '' &&
                            feEmploymentDetails.dateOfJoining != null &&
                            feEmploymentDetails.dateOfJoining != undefined
                            ? moment(feEmploymentDetails.dateOfJoining).format('YYYY-MM-DD')
                            : '';
                    employmentDetails['company_id'] =
                        feEmploymentDetails.company != null ? feEmploymentDetails.company.id : '';
                    employmentDetails['lab_id'] =
                        feEmploymentDetails.labCode != null
                            ? feEmploymentDetails.labCode.value
                            : '';
                    employmentDetails['tenure_id'] =
                        feEmploymentDetails.tenure != null ? feEmploymentDetails.tenure.id : '';
                    employmentDetails['zone_id'] =
                        feEmploymentDetails.zone != null ? feEmploymentDetails.zone.id : '';
                    employmentDetails['hub_id'] =
                        feEmploymentDetails.HudId != null ? feEmploymentDetails.HudId.value : '';
                    employmentDetails['base_latitude'] = feEmploymentDetails.baseLocation
                        ? feEmploymentDetails.baseLocation.split(',')[0].toString()
                        : '';
                    employmentDetails['base_longitude'] = feEmploymentDetails.baseLocation
                        ? feEmploymentDetails.baseLocation.split(',')[1].toString()
                        : '';
                    employmentDetails['base_location_address'] =
                        feEmploymentDetails.base_location_address
                            ? feEmploymentDetails.base_location_address
                            : '';
                    employmentDetails['fe_route_assignment_type'] =
                        feEmploymentDetails.assign_route ? feEmploymentDetails.assign_route.id : '';
                    employmentDetails['supervisor'] = feEmploymentDetails.isSuperVisor
                        ? feEmploymentDetails.isSuperVisor
                        : false;
                    employmentDetails['backup'] = feEmploymentDetails.isBackUpFE
                        ? feEmploymentDetails.isBackUpFE
                        : false;
                    employmentDetails['can_update_cc_coordinates'] =
                        feEmploymentDetails.canEditCCCoordinates
                            ? feEmploymentDetails.canEditCCCoordinates
                            : false;
                    employmentDetails['working_state_id'] = feEmploymentDetails.fe_working_state != null || feEmploymentDetails.fe_working_state != undefined ? Number(feEmploymentDetails.fe_working_state.id) : '';
                    employmentDetails['working_city_id'] = feEmploymentDetails.fe_working_city != null || feEmploymentDetails.fe_working_city != undefined ? Number(feEmploymentDetails.fe_working_city.id) : '';
                    personalDetailsObj_1 = {};
                    if (!(typeof values.uploadPhoto === 'object')) return [3, 3];
                    return [4, uploadFile(values.uploadPhoto).then(function (res) {
                            if (res.data.statusCode === 200) {
                                personalDetailsObj_1['avatar'] = res.data.data.name;
                            }
                            else {
                                personalDetailsObj_1['avatar'] = '';
                            }
                        })];
                case 2:
                    _a.sent();
                    return [3, 4];
                case 3:
                    personalDetailsObj_1['avatar'] = values.uploadPhoto;
                    _a.label = 4;
                case 4:
                    personalDetailsObj_1['first_name'] = values.firstName.trim().toUpperCase();
                    personalDetailsObj_1['last_name'] = values.lastName.trim().toUpperCase();
                    personalDetailsObj_1['dob'] =
                        values.dob && values.dob != 'Invalid Date'
                            ? moment(values.dob).format('YYYY-MM-DD')
                            : '';
                    personalDetailsObj_1['mobile'] = values.mobileNumber;
                    personalDetailsObj_1['email'] = values.email.trim().toUpperCase();
                    personalDetailsObj_1['gender'] =
                        values.gender != '' && values.gender != null && values.gender != undefined
                            ? values.gender.label
                            : '';
                    personalDetailsObj_1['emergency_mobile'] = values.emergencyContactNo;
                    personalDetailsObj_1['reference_name1'] = values.referenceContactName1
                        .trim()
                        .toUpperCase();
                    personalDetailsObj_1['reference_name2'] = values.referenceContactName2
                        .trim()
                        .toUpperCase();
                    personalDetailsObj_1['reference_mobile1'] = values.referenceMobileNumber1;
                    personalDetailsObj_1['reference_mobile2'] = values.referenceMobileNumber2;
                    if (FEIdToBeReplaced) {
                        payload['personal_details'] = personalDetailsObj_1;
                        payload['employment_details'] = employmentDetails;
                        payload['is_final_submit'] = false;
                        payload['is_replacement'] = true;
                        payload['replacement_fe_id'] = parseInt(FEIdToBeReplaced);
                    }
                    else {
                        payload['personal_details'] = personalDetailsObj_1;
                        payload['is_final_submit'] = false;
                    }
                    api_service_1.api_service
                        .post({
                        endPoint: api_service_1.api_service.api_urls.FE_CREATION,
                        payLoad: user_id != null && user_id != undefined
                            ? __assign(__assign({}, payload), { user_id: user_id }) : payload,
                        domain: process.env.VENDOR_BASE_URL,
                    })
                        .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.statusCode === 200)) return [3, 2];
                                    return [4, dispatch((0, toaster_actions_1.showToaster)({
                                            text: res.data.message,
                                            type: 'success',
                                            time: '900',
                                        }))];
                                case 1:
                                    _a.sent();
                                    dispatch((0, FE_Creation_action_1.updateUserID)({
                                        user_id: res.data.data.user_id,
                                    }));
                                    saveFormData(values, false);
                                    if (location.pathname.includes('edit')) {
                                        nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(res.data.data.user_id, "/").concat(FECreationTabConstant_1.CreationTabConstants.CONTACTDETAILS));
                                        updateValue(FECreationTabConstant_1.CreationTabConstants.CONTACTDETAILS);
                                    }
                                    else {
                                        nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.addFE, "/").concat(res.data.data.user_id, "/").concat(FECreationTabConstant_1.CreationTabConstants.CONTACTDETAILS));
                                        updateValue(FECreationTabConstant_1.CreationTabConstants.CONTACTDETAILS);
                                    }
                                    return [3, 3];
                                case 2:
                                    react_hot_toast_1.default.error(res.data.message);
                                    _a.label = 3;
                                case 3: return [2];
                            }
                        });
                    }); })
                        .catch(function (err) {
                        react_hot_toast_1.default.error(err.data.errors);
                    });
                    _a.label = 5;
                case 5: return [2];
            }
        });
    }); };
    var renderErrorMessage = function (field, errors, touched) {
        switch (field.name) {
            case 'MobileNumber':
                return (errors[field.fieldValue] || mobileNoError) &&
                    touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue] ? errors[field.fieldValue] : mobileNoError)) : null;
            default:
                return errors[field.fieldValue] && touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue])) : null;
        }
    };
    var renderInputFiled = function (field, values, setFieldValue, setFieldTouched, errors, touched, setErrors) {
        console.log('errors & touched', errors, touched);
        if (!dropdownFields_1.dropdownFields.includes(field.name)) {
            return (React.createElement(React.Fragment, null,
                React.createElement(field.inputComponent, { name: field.name, className: "text-caps", type: field.name === 'uploadPhoto'
                        ? 'file'
                        : field.name === 'DateOfBirth'
                            ? 'date'
                            : 'text', buttonName: field.label, placeholder: field.placeholder, required: field.required, label: field.label, swicthLabel: field.swicthLabel, value: values[field.fieldValue], defaultVal: field.defaultVal, isPastDisable: field.isPastDisable, isFutureDisable: field.isFutureDisable, isDisabled: field.name === 'MobileNumber'
                        ? onBoardingStatus != 1 &&
                            onBoardingStatus != null &&
                            onBoardingStatus != 3 &&
                            onBoardingStatus != 4
                            ? true
                            : false
                        : field.isDisabled, require: field.require, acceptedFiletypes: field.acceptedFiletypes, maxSize: field.maxSize, minSize: field.minSize, message: field.message, deleteUploadedImage: function () { return setFieldValue(field.fieldValue, ''); }, onKeyPress: function (e) {
                        if (field.name === 'firstName' || field.name === 'lastName') {
                            if (new RegExp(/^[a-zA-Z ]*$/).test(e.key)) {
                            }
                            else {
                                e.preventDefault();
                            }
                        }
                        else if (field.name === 'MobileNumber' ||
                            field.name === 'emergencyContactNo' ||
                            field.name === 'referenceMobileNumber1' ||
                            field.name === 'referenceMobileNumber2') {
                            if (new RegExp(/^[0-9]+$/).test(e.key) && e.target.value.length <= 9) {
                            }
                            else {
                                e.preventDefault();
                            }
                        }
                    }, onChange: function (val) {
                        if (field.type === 'fileupload') {
                            setFieldValue(field.fieldValue, val);
                            setFieldTouched(field.fieldValue, true);
                        }
                        else {
                            if (field.type != 'dateselection' && field.type != 'fileupload') {
                                setFieldValue(field.fieldValue, val);
                            }
                            else {
                                setFieldValue(field.fieldValue, val);
                            }
                            setFieldTouched(field.fieldValue, true);
                        }
                        if (field.name === 'MobileNumber' && val.length === 10) {
                            mobileNumberValidation(event, val, errors, setErrors);
                        }
                    } }),
                renderErrorMessage(field, errors, touched)));
        }
        else {
            var genderTypeList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.genderTypeList; });
            return (React.createElement(React.Fragment, null,
                React.createElement(field.inputComponent, { name: field.name, key: genderTypeList.toString(), type: "text", dropDownList: genderTypeList, placeholder: field.placeholder, swicthLabel: field.swicthLabel, dateState: field.dateState, required: field.required, label: field.label, value: values[field.fieldValue], defaultVal: field.defaultVal, isDisabled: field.isDisabled, onChange: function (val) {
                        setFieldValue(field.fieldValue, val);
                        setFieldTouched(field.fieldValue, true);
                    } }),
                errors[field.fieldValue] && touched[field.fieldValue] ? (React.createElement("div", { className: "error" }, errors[field.fieldValue])) : null));
        }
    };
    return (React.createElement("div", { className: "form-filed-wrapper" },
        React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", null, "Lets start with FE personal details"),
            React.createElement("p", { className: "sub-heaidng" }, "Take the information about the FE")),
        React.createElement("div", null,
            React.createElement(formik_1.Formik, { enableReinitialize: true, validateOnBlur: false, initialValues: __assign({}, initialValues), validationSchema: schema_1.schemas.FE_RegistrationSchema, onSubmit: function (values, _a) {
                    var resetForm = _a.resetForm;
                    formSubmitHnadler(values);
                } }, function (_a) {
                var errors = _a.errors, setErrors = _a.setErrors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, resetForm = _a.resetForm, setFieldTouched = _a.setFieldTouched, dirty = _a.dirty;
                console.log('errors & touched inside Fe personalDetails Form1', errors, touched, dirty);
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper", autoComplete: "off" },
                    React.createElement(Grid_1.default, { container: true, columns: { xs: 12 }, columnSpacing: 3 }, FEIdToBeReplaced || replaceFeIdFromApi
                        ? formFieldConstants_1.FE_ReplaceFEPersonalDetails_Fields &&
                            formFieldConstants_1.FE_ReplaceFEPersonalDetails_Fields.map(function (section) {
                                return (React.createElement(React.Fragment, null,
                                    React.createElement(Grid_1.default, { item: true, xs: 9 },
                                        console.log('--------------------------------------------------------------------------'),
                                        ' ',
                                        React.createElement("h2", { className: "label-heading heading" },
                                            section.title,
                                            section.description && (React.createElement("p", { className: "sub-heaidng label-sub-heading" }, section.description)))),
                                    section.fields.map(function (field, index) {
                                        return (React.createElement(Grid_1.default, { item: true, key: index, xs: field.type === 'fileupload' ? 9 : 5, "xs-offset": field.type === 'fileupload' ? 1 : 0 }, renderInputFiled(field, values, setFieldValue, setFieldTouched, errors, touched, setErrors)));
                                    })));
                            })
                        : formFieldConstants_1.FE_PersonalDetails_Fields &&
                            formFieldConstants_1.FE_PersonalDetails_Fields.map(function (section) {
                                return (React.createElement(React.Fragment, null,
                                    React.createElement(Grid_1.default, { item: true, xs: 9 },
                                        React.createElement("h2", { className: "label-heading heading" },
                                            section.title,
                                            section.description && (React.createElement("p", { className: "sub-heaidng label-sub-heading" }, section.description)))),
                                    section.fields.map(function (field, index) {
                                        return (React.createElement(Grid_1.default, { item: true, key: index, xs: field.type === 'fileupload' ? 9 : 5, "xs-offset": field.type === 'fileupload' ? 1 : 0 }, renderInputFiled(field, values, setFieldValue, setFieldTouched, errors, touched, setErrors)));
                                    })));
                            })),
                    React.createElement("div", { className: "form-btns" },
                        React.createElement(button_component_1.default, { type: "submit", buttonName: 'Save & Continue', color: "yellow", isError: false }),
                        React.createElement(button_component_1.default, { type: "button", buttonName: 'Reset', color: "yellow", isError: JSON.stringify(values) !== JSON.stringify(initialValues)
                                ? false
                                : true, onClickHandler: function () { return resetForm({ values: initialValues }); } }))));
            }))));
};
exports.default = FE_PersonalDetailsForm1;
