"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_redux_1 = require("react-redux");
var button_component_1 = require("../../common/buttons/button_component");
var multi_selector_1 = require("../../common/checkbox/multi_selector");
var pop_up_1 = require("../../common/modal/pop-up");
var text_field_with_datepicker_1 = require("../../common/text_filed/text_field_with_datepicker");
var text_filed_component_1 = require("../../common/text_filed/text_filed_component");
var NotificationConstants_1 = require("./NotificationConstants");
var NotificationPopup = function (_a) {
    var _b, _c;
    var notificationData = _a.notificationData, open = _a.open, singleoccurence = _a.singleoccurence, onClickHandler = _a.onClickHandler, handleClose = _a.handleClose, name = _a.name, yearoccurance = _a.yearoccurance;
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).labList;
    return (React.createElement(pop_up_1.default, { open: open, popUpSize: "popup-lg" },
        React.createElement("div", { className: "modal-header" },
            React.createElement("h2", { className: "heading" }, name === "View" ? 'View Notification' : 'Review Notification'),
            React.createElement("h3", { className: "heading" }, name === "View" ? " " : 'Review the Notification before sending to it’s Users')),
        React.createElement("div", { className: "modal-body" },
            React.createElement(Grid_1.default, { container: true, columns: { xs: 12 } },
                React.createElement("div", { className: "input-fileds-wrapper" },
                    React.createElement("form", null,
                        React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 2 },
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { value: name === 'View' ? notificationData === null || notificationData === void 0 ? void 0 : notificationData.notificationCategory : (_b = notificationData === null || notificationData === void 0 ? void 0 : notificationData.notificationCategory) === null || _b === void 0 ? void 0 : _b.value, placeholder: "Select Catagory", label: 'Select Catagory', isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(multi_selector_1.default, { label: "Users", isDisabled: true, size: "dropdown-lg", dropDownList: NotificationConstants_1.userGroupsOptions &&
                                        NotificationConstants_1.userGroupsOptions.sort(function (a, b) { return a.label.localeCompare(b.label); }), value: name === "View" ? notificationData === null || notificationData === void 0 ? void 0 : notificationData.userGroups : notificationData.userGroups, disableClearIcon: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(multi_selector_1.default, { label: "Lab Code", key: "labCode", size: "dropdown-lg", isDisabled: true, dropDownList: labList &&
                                        labList.sort(function (a, b) { return a.label.localeCompare(b.label); }), value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.labCode, disableClearIcon: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { label: 'Occurence', value: (_c = notificationData === null || notificationData === void 0 ? void 0 : notificationData.occurrence) === null || _c === void 0 ? void 0 : _c.value, placeholder: "Occurrence", isDisabled: true })),
                            singleoccurence ?
                                React.createElement(React.Fragment, null,
                                    React.createElement(Grid_1.default, { item: true, xs: 6 },
                                        React.createElement(text_field_with_datepicker_1.default, { label: "Date", isDisabled: true, value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.executionDate })),
                                    React.createElement(Grid_1.default, { item: true, xs: 3 },
                                        React.createElement(material_1.Stack, { direction: 'row', gap: 2, alignItems: 'center' },
                                            React.createElement(text_filed_component_1.default, { label: 'Hour', value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.execution_hour, placeholder: "Hour", isDisabled: true }),
                                            React.createElement(text_filed_component_1.default, { label: 'Min', value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.execution_min, placeholder: "Min", isDisabled: true })))) :
                                yearoccurance ?
                                    React.createElement(React.Fragment, null,
                                        React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                                React.createElement(text_field_with_datepicker_1.default, { label: " Start Date", isDisabled: true, value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.startDate })),
                                            React.createElement(Grid_1.default, { item: true, xs: 6 },
                                                React.createElement(text_field_with_datepicker_1.default, { label: "End Date", isDisabled: true, value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.endDate }))),
                                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                                            React.createElement(text_field_with_datepicker_1.default, { label: "Date", isDisabled: true, value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.executionDate })),
                                        React.createElement(Grid_1.default, { item: true, xs: 3 },
                                            React.createElement(material_1.Stack, { direction: 'row', gap: 2, alignItems: 'center' },
                                                React.createElement(text_filed_component_1.default, { label: 'Hour', value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.execution_hour, placeholder: "Hour", isDisabled: true }),
                                                React.createElement(text_filed_component_1.default, { label: 'Min', value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.execution_min, placeholder: "Min", isDisabled: true })))) :
                                    React.createElement(Grid_1.default, { item: true, container: true, xs: 12, columnSpacing: 3 },
                                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                                            React.createElement(text_field_with_datepicker_1.default, { label: " Start Date", isDisabled: true, value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.startDate })),
                                        React.createElement(Grid_1.default, { item: true, xs: 6 },
                                            React.createElement(text_field_with_datepicker_1.default, { label: "End Date", isDisabled: true, value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.endDate })),
                                        React.createElement(Grid_1.default, { item: true, xs: 3 },
                                            React.createElement(material_1.Stack, { direction: 'row', gap: 2, alignItems: 'center' },
                                                React.createElement(text_filed_component_1.default, { label: 'Hour', value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.execution_hour, placeholder: "Hour", isDisabled: true }),
                                                React.createElement(text_filed_component_1.default, { label: 'Min', value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.execution_min, placeholder: "Min", isDisabled: true })))),
                            React.createElement(Grid_1.default, { item: true, xs: 10 },
                                React.createElement(text_filed_component_1.default, { label: "Notification title", value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.notificationTitle, placeholder: "Notification title", isDisabled: true })),
                            React.createElement(Grid_1.default, { item: true, xs: 10 },
                                React.createElement(text_filed_component_1.default, { label: "Notification Message", value: notificationData === null || notificationData === void 0 ? void 0 : notificationData.notificationMessage, placeholder: "Notification Message", isDisabled: true }))))))),
        name === "View" ?
            React.createElement("div", { className: "modal-footer sticky-modal-footer" },
                React.createElement("div", { className: "form-btns" },
                    React.createElement(button_component_1.default, { type: "button", buttonName: 'Cancel', color: "yellow-outline", onClickHandler: handleClose }))) : React.createElement("div", { className: "modal-footer sticky-modal-footer" },
            React.createElement("div", { className: "form-btns" },
                React.createElement(button_component_1.default, { type: "button", buttonName: 'Edit', color: "yellow-outline", onClickHandler: handleClose }),
                React.createElement(button_component_1.default, { buttonName: 'Submit', color: "yellow", onClickHandler: onClickHandler, isError: false })))));
};
exports.default = NotificationPopup;
