"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var menu_constants_1 = require("../../menuList/menu_constants");
var array_helpers_1 = require("../../utils/array_helpers");
var NotificationActionIcon = function () {
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.notificationManagement);
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: features[menu_constants_1.FEATURE_CONSTANTS.createNotification]
                ? "icon-btn"
                : "icon-btn icon-disabled" },
            React.createElement("span", { className: "icon-edit" })),
        React.createElement(material_1.IconButton, { className: features[menu_constants_1.FEATURE_CONSTANTS.createNotification]
                ? "icon-btn"
                : "icon-btn icon-disabled" },
            React.createElement("span", { className: "icon-delete_forever" }))));
};
exports.default = NotificationActionIcon;
