"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRACK_STATUS = exports.VISIT_STATUS = void 0;
exports.VISIT_STATUS = {
    Pending: 0,
    Assigned: 1,
    Started: 2,
    Reached: 3,
    Completed: 4,
    Reassigned: 5,
};
exports.TRACK_STATUS = {
    onTime: 0,
    delayed: 1,
    ondemand: 3,
};
