"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var client_side_row_model_1 = require("@ag-grid-community/client-side-row-model");
var core_1 = require("@ag-grid-community/core");
var csv_export_1 = require("@ag-grid-community/csv-export");
var excel_export_1 = require("@ag-grid-enterprise/excel-export");
var menu_1 = require("@ag-grid-enterprise/menu");
require("ag-grid-community/dist/styles/ag-grid.css");
require("ag-grid-community/dist/styles/ag-theme-alpine.css");
var ag_grid_react_1 = require("ag-grid-react");
var React = require("react");
var react_1 = require("react");
var table_loader_1 = require("../loader/table_loader");
require("./table.scss");
core_1.ModuleRegistry.registerModules([
    client_side_row_model_1.ClientSideRowModelModule,
    csv_export_1.CsvExportModule,
    excel_export_1.ExcelExportModule,
    menu_1.MenuModule,
]);
var AGgrid_table_component = function (_a) {
    var isSortable = _a.isSortable, downloadReportVlaue = _a.downloadReportVlaue, columnDefs = _a.columnDefs, rows = _a.rows, onRowClicked = _a.onRowClicked, onMouseHover = _a.onMouseHover, overlayLoadingTemplate = _a.overlayLoadingTemplate, serchVlaue = _a.serchVlaue, errorMessage = _a.errorMessage, multiSelector = _a.multiSelector, onSelectionChange = _a.onSelectionChange, pagination = _a.pagination, onGridReady = _a.onGridReady, enableCellTextSelection = _a.enableCellTextSelection, isRowSelectable = _a.isRowSelectable, rowMultiSelectWithClick = _a.rowMultiSelectWithClick, className = _a.className;
    var _b = React.useState(false), shouldDownload = _b[0], setShouldDownload = _b[1];
    var gridRef = React.useRef(null);
    var pageSize = 50;
    var defaultColDef = (0, react_1.useMemo)(function () { return ({
        resizable: false,
        sortable: isSortable ? true : false,
    }); }, []);
    var defaultExcelExportParams = (0, react_1.useMemo)(function () {
        return {
            rowHeight: function (params) { return (params.rowIndex === 1 ? 82 : 20); },
        };
    }, []);
    var onBtExport = React.useCallback(function () {
        gridRef.current.api.exportDataAsCsv();
    }, []);
    React.useEffect(function () {
        if (shouldDownload) {
            onBtExport();
        }
        else {
            setShouldDownload(true);
        }
    }, [downloadReportVlaue]);
    var onSelectionChanged = React.useCallback(function () {
        var selectedRows = gridRef.current.api.getSelectedRows();
        if (onSelectionChange) {
            onSelectionChange(selectedRows[0]);
        }
    }, []);
    return (React.createElement("div", { className: "ag-theme-alpine custom-table ".concat(className ? className : '', " ").concat(rows && rows.length === 0 ? 'scoll-wrapper' : '') },
        React.createElement(ag_grid_react_1.AgGridReact, { rowSelection: multiSelector ? 'multiple' : 'single', animateRows: true, ref: gridRef, rowData: rows, columnDefs: columnDefs, defaultColDef: defaultColDef, onRowClicked: onRowClicked, defaultExcelExportParams: defaultExcelExportParams, serverSideInfiniteScroll: true, pagination: pagination, paginationPageSize: pageSize, loadingOverlayComponent: table_loader_1.default, overlayNoRowsTemplate: "<span  style=\"padding: 10px;\n        border: 2px solid #E0E0E0;\n        border-radius:4px;\n        font-family:inherit;\n        background-color: whitesmoke;\">".concat(errorMessage ? errorMessage : 'No Data Available', "</span>"), onCellMouseOver: onMouseHover, suppressCellSelection: true, onSelectionChanged: onSelectionChanged, onGridReady: onGridReady, enableCellTextSelection: enableCellTextSelection, enableBrowserTooltips: true, rowMultiSelectWithClick: rowMultiSelectWithClick })));
};
exports.default = AGgrid_table_component;
