"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Avatar_1 = require("@mui/material/Avatar");
var Chip_1 = require("@mui/material/Chip");
var React = require("react");
require("../components/field_exicutive/slot_blocking/selected_fe.scss");
var SelectedCCScreen = function (_a) {
    var selecteFeIds = _a.selecteFeIds, setSelecteFeIds = _a.setSelecteFeIds, name = _a.name, setFieldValue = _a.setFieldValue, clearSlots = _a.clearSlots, setlabcode = _a.setlabcode;
    var handleDelete = function (data) { return function () {
        if (name === 'LAB') {
            setlabcode(null);
            setFieldValue('labcode', '');
        }
        setSelecteFeIds(function (chips) { return chips.id !== data.id; });
        setFieldValue('warehouse_code', '');
        setFieldValue('state', '');
        setFieldValue('city', '');
        setFieldValue('baseLocation', '');
        setFieldValue('base_location_address', '');
        clearSlots();
    }; };
    return selecteFeIds ? (React.createElement("div", { className: "selected_fe-wrapper" },
        React.createElement("h2", { className: "selected-felabel label-heading" }, name === 'CC' ? 'Selected CC(s) ' : 'Selected LABS(s)'),
        React.createElement("div", { className: "chips-wrapper" }, React.createElement(Chip_1.default, { size: "medium", deleteIcon: React.createElement("span", { className: "icon-close" }), onDelete: handleDelete(selecteFeIds), label: selecteFeIds === null || selecteFeIds === void 0 ? void 0 : selecteFeIds.label, className: "boxed-chip", avatar: React.createElement(Avatar_1.default, { src: require('../../assets/images/use.jpg') }) })))) : (React.createElement(React.Fragment, null));
};
exports.default = SelectedCCScreen;
