"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var conroldashboardstyles_1 = require("../../components/super_admin/control_dashboard/list/conroldashboardstyles");
var ModalDrawer = function (_a) {
    var isOpend = _a.isOpend, closeModal = _a.closeModal, children = _a.children;
    var classes = (0, conroldashboardstyles_1.useStyles)();
    return (React.createElement(material_1.Drawer, { variant: "temporary", anchor: "bottom", open: isOpend, className: classes.customDrawer },
        React.createElement("div", { onClick: closeModal, className: "drawer-close-btn" },
            React.createElement("span", { className: "icon-close" })),
        children));
};
exports.default = ModalDrawer;
