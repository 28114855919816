"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_highchart_1 = require("../../../common/high_charts/react_highchart");
var table_data_1 = require("../../../constants/table_data");
var report_dashboard_service_1 = require("../service/report_dashboard_service");
var OutStationPendingBoxes = function (_a) {
    var errorMessage = _a.errorMessage, seriesData = _a.seriesData, setTableData = _a.setTableData, setOpen = _a.setOpen, listData = _a.listData, cumulativeSeries = _a.cumulativeSeries, setTableColumns = _a.setTableColumns;
    return (React.createElement("div", null, seriesData && seriesData.length > 0 ? (React.createElement(react_highchart_1.default, { chartOptions: {
            chart: {
                type: 'column',
            },
            title: {
                text: null,
            },
            xAxis: {
                labels: {
                    style: {
                        fontSize: 17,
                    },
                },
                categories: report_dashboard_service_1.x_axis_valuesForOutStation,
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    style: {
                        fontSize: 17,
                    },
                },
                stackLabels: {
                    enabled: false,
                    style: {
                        fontSize: 17,
                    },
                },
            },
            colors: ['#0080ff', '#ffff00'],
            legend: {
                itemStyle: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                },
                symbolWidth: 40,
                symbolHeight: 10,
                symbolRadius: 0,
                squareSymbol: false,
            },
            tooltip: {
                padding: 20,
                style: {
                    fontSize: '1.1em',
                },
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    pointPadding: 0.1,
                    pointWidth: 50,
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: 15,
                        },
                    },
                },
                series: {
                    lineWidth: 3,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            (0, report_dashboard_service_1.displayTableDataForOutStaionBox)(event.point.category, setTableData, listData);
                            setTableColumns(table_data_1.Outstation_Master_Box_GRAPH_PENDIGN_COLUN);
                            setOpen(true);
                        },
                        legendItemClick: function () {
                            return false;
                        },
                    },
                    cumulative: true,
                },
                line: {
                    events: {
                        click: function () {
                            setOpen(false);
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '16px',
                        },
                    },
                },
            },
            series: [
                {
                    name: 'No of In Transit Boxes',
                    data: seriesData,
                },
                {
                    name: 'Total In Transit Boxes',
                    type: 'line',
                    data: cumulativeSeries,
                },
            ],
        } })) : (React.createElement("div", { className: "no-data-conatiner" },
        React.createElement("div", { className: "no-data-badge" },
            ' ',
            errorMessage ? errorMessage : 'No Data Available')))));
};
exports.default = OutStationPendingBoxes;
