"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var button_component_1 = require("../../../common/buttons/button_component");
[];
var CCRosterTimeslots = function (_a) {
    var _b;
    var onHandler = _a.onHandler, addedData = _a.addedData, setAddedData = _a.setAddedData, dayset = _a.dayset, onChange = _a.onChange, apislotdetails = _a.apislotdetails, selectedDay = _a.selectedDay, selectedSlots = _a.selectedSlots, selectedslotsObject = _a.selectedslotsObject, isDayselected = _a.isDayselected, daySlots = _a.daySlots, setDaySlots = _a.setDaySlots, slotid = _a.slotid, timeSlotsList = _a.timeSlotsList, setTimeSlotList = _a.setTimeSlotList, editData = _a.editData, weekDay = _a.weekDay, disabled = _a.disabled;
    console.log(weekDay, 'weekDayweekDay');
    var clickHandlerOnTimeslot = function (OnchangeSlotValues) {
        var _a;
        console.log(daySlots, '87tuygnb');
        var setColor;
        if (OnchangeSlotValues.color) {
            console.log('uiyghc');
            setColor = daySlots["day".concat(weekDay)].map(function (slotObject) {
                if (slotObject.id === OnchangeSlotValues.id) {
                    slotObject['color'] = null;
                    return slotObject;
                }
                return slotObject;
            });
        }
        else {
            console.log('65t7y8');
            setColor = daySlots["day".concat(weekDay)].map(function (slotObject) {
                if (slotObject.id === OnchangeSlotValues.id) {
                    slotObject['color'] = 'slot-btn status-selected';
                    return slotObject;
                }
                return slotObject;
            });
        }
        console.log(setColor, 'iyuthfgv');
        setDaySlots(__assign(__assign({}, daySlots), (_a = {}, _a["day".concat(weekDay)] = setColor, _a)));
    };
    return (React.createElement("div", { className: "time-slot-wrapper" },
        React.createElement("div", { className: "time-slot-booking-group" },
            React.createElement("div", { className: "slot-wrapper" },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 14, xl: 16 } }, (_b = daySlots["day".concat(weekDay)]) === null || _b === void 0 ? void 0 : _b.map(function (slotDetails) {
                    return (React.createElement(Grid_1.default, { item: true, xs: 3, lg: 2, xl: 2 },
                        React.createElement(button_component_1.default, { buttonName: slotDetails === null || slotDetails === void 0 ? void 0 : slotDetails.buttonName, color: slotDetails.color
                                ? slotDetails.color
                                : 'slot-btn status-existing', onClickHandler: function () {
                                clickHandlerOnTimeslot(slotDetails);
                            }, isError: disabled })));
                }))))));
};
exports.default = CCRosterTimeslots;
