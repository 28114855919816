"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../api/api_service");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var progress_bar_1 = require("../../../common/progress_bar/progress_bar");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var kpi_details_1 = require("../../../constants/kpi_details");
var table_data_1 = require("../../../constants/table_data");
var FEKPIsComponent = function () {
    var _a = React.useState(undefined), feOnleaveList = _a[0], setFeOnleaveList = _a[1];
    console.log(feOnleaveList, 'feOnleaveList');
    var id = (0, react_router_dom_1.useParams)().id;
    var _b = React.useState(), errorMessage = _b[0], setErrorMessage = _b[1];
    var fetchFEOnLeaveData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4, api_service_1.api_service
                            .post({
                            endPoint: api_service_1.api_service.api_urls.onLeaveToday,
                            payLoad: { leave_id: Number(id) },
                            domain: process.env.VENDOR_BASE_URL,
                        })
                            .then(function (res) {
                            if (res.status === 200) {
                                setErrorMessage("");
                                setFeOnleaveList(res.data.data);
                            }
                            else {
                                setErrorMessage(res.data.message);
                                setFeOnleaveList([]);
                            }
                        })
                            .catch(function (e) { return console.log(e, 'errorData'); })];
                case 1:
                    _a.sent();
                    return [3, 3];
                case 2:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3, 3];
                case 3: return [2];
            }
        });
    }); };
    React.useEffect(function () {
        fetchFEOnLeaveData();
    }, []);
    return (React.createElement("div", { className: "scrollable-content KPIs-tab-wrapper" },
        React.createElement("div", { className: "tab-heaidng displayflex sticky-position border-bottom" },
            React.createElement("div", { className: "tab-heading-container " },
                React.createElement("h2", null, "KPI\u2019s & Leave History"),
                React.createElement("small", { className: "tab-sub-text" }, "Last updated Today 10:00 PM")),
            React.createElement(drop_down_component_1.default, { placeholder: "Today", dropDownList: appConstants_1.dummyDropdownList })),
        React.createElement("div", { className: "kpi-content" },
            React.createElement("div", { className: "header" },
                React.createElement("h2", null, "KPI\u2019s")),
            React.createElement("div", { className: "kpi-cards-wrapper" },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, spacing: 0 },
                    React.createElement(Grid_1.default, { item: true, lg: 4, xs: 12 },
                        React.createElement("div", { className: "kpi-card-sm small-kpi-card" },
                            React.createElement("small", { className: "card-label" }, kpi_details_1.KPI_HEADER_NAMES.totalMilage),
                            React.createElement("p", { className: "card-value" }, kpi_details_1.KPI_DETAILS.totalMilage),
                            React.createElement("small", { className: "card-desc text-green" },
                                "+",
                                kpi_details_1.KPI_DETAILS.milagePerceentage,
                                "% Vs. Yesterday"))),
                    React.createElement(Grid_1.default, { item: true, lg: 4, xs: 12 },
                        React.createElement("div", { className: "kpi-card-sm  small-kpi-card" },
                            React.createElement("small", { className: "card-label" }, kpi_details_1.KPI_HEADER_NAMES.batary),
                            React.createElement("p", { className: "card-value" },
                                kpi_details_1.KPI_DETAILS.battery,
                                "%"),
                            React.createElement("small", { className: "card-desc text-red" },
                                "-",
                                kpi_details_1.KPI_DETAILS.batteryPercetage,
                                "% Vs. Yesterday"))),
                    React.createElement(Grid_1.default, { item: true, lg: 4, xs: 12 },
                        React.createElement("div", { className: "kpi-card-sm small-kpi-card" },
                            React.createElement("small", { className: "card-label" }, kpi_details_1.KPI_HEADER_NAMES.sensor),
                            React.createElement("p", { className: "card-value" },
                                "-",
                                kpi_details_1.KPI_DETAILS.sensor,
                                "\u00B0C"),
                            React.createElement("small", { className: "card-desc text-green" },
                                "+",
                                kpi_details_1.KPI_DETAILS.sensorPersentage,
                                "% Vs. Yesterday"))))),
            React.createElement("div", { className: "kpi-cards-wrapper" },
                React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnGap: { xs: 1, lg: 0 } },
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 },
                        React.createElement("div", { className: "kpi-card-sm kpi-card-lg" },
                            React.createElement("div", { className: "card-main" },
                                React.createElement("div", { className: "card-body-desc" },
                                    React.createElement("small", { className: "card-label" }, kpi_details_1.KPI_HEADER_NAMES.totalPickups),
                                    React.createElement("p", { className: "card-value" },
                                        kpi_details_1.KPI_DETAILS.totalPickups,
                                        "/",
                                        React.createElement("small", null, "12"))),
                                React.createElement(progress_bar_1.default, { styles: "progress-error", value: 60 })),
                            React.createElement("div", { className: "card-footer" },
                                React.createElement("span", { className: "card-desc  lg-card-desc" },
                                    React.createElement("span", { className: "icon-down-arrow text-red" }),
                                    React.createElement("span", { className: "text-red" },
                                        ' ',
                                        kpi_details_1.KPI_DETAILS.totalPickupsPecentage,
                                        "%"),
                                    ' ',
                                    "Vs. Yesterday")))),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 },
                        React.createElement("div", { className: "kpi-card-sm kpi-card-lg" },
                            React.createElement("div", { className: "card-main" },
                                React.createElement("div", { className: "card-body-desc" },
                                    React.createElement("small", { className: "card-label" }, kpi_details_1.KPI_HEADER_NAMES.onTimePickups),
                                    React.createElement("p", { className: "card-value" }, kpi_details_1.KPI_DETAILS.ontimePickups)),
                                React.createElement(progress_bar_1.default, { styles: "progress-sucess", value: 80 })),
                            React.createElement("div", { className: "card-footer" },
                                React.createElement("span", { className: "card-desc  lg-card-desc" },
                                    React.createElement("span", { className: "icon-up-arrow text-green" }),
                                    React.createElement("span", { className: "text-green" },
                                        ' ',
                                        kpi_details_1.KPI_DETAILS.ontimePickupsPecentage,
                                        "%"),
                                    ' ',
                                    "Vs. Yesterday")))),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 },
                        React.createElement("div", { className: "kpi-card-sm kpi-card-lg" },
                            React.createElement("div", { className: "card-main" },
                                React.createElement("div", { className: "card-body-desc" },
                                    React.createElement("small", { className: "card-label" }, kpi_details_1.KPI_HEADER_NAMES.rating),
                                    React.createElement("p", { className: "card-value" }, kpi_details_1.KPI_DETAILS.rating))),
                            React.createElement("div", { className: "card-footer" },
                                React.createElement("span", { className: "card-desc  lg-card-desc" },
                                    React.createElement("span", { className: "icon-up-arrow text-green" }),
                                    React.createElement("span", { className: "text-green" },
                                        ' ',
                                        kpi_details_1.KPI_DETAILS.ratingPecentage,
                                        "%"),
                                    ' ',
                                    "Vs. Yesterday")))),
                    React.createElement(Grid_1.default, { item: true, xs: 12, lg: 6 },
                        React.createElement("div", { className: "kpi-card-sm kpi-card-lg" },
                            React.createElement("div", { className: "card-main" },
                                React.createElement("div", { className: "card-body-desc" },
                                    React.createElement("small", { className: "card-label" }, kpi_details_1.KPI_HEADER_NAMES.earning),
                                    React.createElement("p", { className: "card-value" },
                                        "\u20B9",
                                        kpi_details_1.KPI_DETAILS.earning))),
                            React.createElement("div", { className: "card-footer" },
                                React.createElement("span", { className: "card-desc  lg-card-desc" },
                                    React.createElement("span", { className: "icon-down-arrow text-red" }),
                                    React.createElement("span", { className: "text-red" },
                                        ' ',
                                        kpi_details_1.KPI_DETAILS.earningPercentage,
                                        "%"),
                                    ' ',
                                    "Vs. Yesterday")))))),
            React.createElement("div", { className: "kpi-table-wrapper" },
                React.createElement("div", { className: "header" },
                    React.createElement("h2", null, "Leaves History")),
                React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.KPI_LEAVES_COLUMNS, rows: [], errorMessage: errorMessage })))));
};
exports.default = FEKPIsComponent;
