"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var formik_1 = require("formik");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var checkbox_component_1 = require("../../../common/checkbox/checkbox_component");
var FECreationTabConstant_1 = require("../../../constants/FECreationTabConstant");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var FE_Inventory = function (_a) {
    var initialValues = _a.initialValues, FEID = _a.FEID, name = _a.name, onBoardingStatus = _a.onBoardingStatus, updateValue = _a.updateValue, value = _a.value, handleDisplayModel = _a.handleDisplayModel, key = _a.key, isActiveFESendItForApprovalDisabled = _a.isActiveFESendItForApprovalDisabled;
    var user_id = (0, react_redux_1.useSelector)(function (state) { return state.FE_creation_reducer; }).user_id;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var editUserId = (0, react_router_dom_1.useParams)().id;
    var _b = React.useState(initialValues.selectedInventoryList), checkedValue = _b[0], setCheckedValue = _b[1];
    var inventoryList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.inventories; });
    console.log('initialValues inside FE_Invemtory:', checkedValue, initialValues);
    var handleChange = function (e, value, setFieldValue) {
        var checked = e.target.checked;
        console.log('inside handle change:', checked);
        if (checked) {
            console.log('inside if:', checkedValue);
            setCheckedValue(function (prevState) { return __spreadArray(__spreadArray([], prevState, true), [parseInt(value)], false); });
            setFieldValue('selectedInventoryList', __spreadArray(__spreadArray([], checkedValue, true), [
                parseInt(value),
            ], false));
        }
        else {
            var index = checkedValue.indexOf(value);
            if (index > -1) {
                checkedValue.splice(index, 1);
                console.log('inside else:', checkedValue);
                setCheckedValue(__spreadArray([], checkedValue, true));
                setFieldValue('selectedInventoryList', __spreadArray([], checkedValue, true));
            }
        }
        console.log('checkedValue:', checkedValue);
    };
    var backButtonHandler = function () {
        if (onBoardingStatus === 1) {
            updateValue(value - 1);
        }
        else {
            navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.editFE, "/").concat(editUserId, "/").concat(FECreationTabConstant_1.CreationTabConstants.EMPLOYMENTDETAILS));
            updateValue(FECreationTabConstant_1.CreationTabConstants.EMPLOYMENTDETAILS);
        }
    };
    var formSubmitHnadler = function (values) {
        var inventoryPayload = [];
        inventoryList.forEach(function (inventoryItem) {
            if (values.selectedInventoryList.includes(inventoryItem.id)) {
                inventoryPayload.push({
                    id: inventoryItem.id,
                    assign: true,
                });
            }
            else {
                inventoryPayload.push({
                    id: inventoryItem.id,
                    assign: false,
                });
            }
        });
        var payload = {
            user_id: user_id,
            inventory_ids: inventoryPayload,
        };
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ASSIGN_INVENTORY,
            payLoad: payload,
            domain: process.env.VENDOR_BASE_URL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                react_hot_toast_1.default.success('inventory added successfully');
                setCheckedValue([]);
                if (isActiveFESendItForApprovalDisabled) {
                    navigate("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.activeList));
                }
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.message);
        });
    };
    return (React.createElement("div", { className: "form-filed-wrapper assign-invetory-popup" },
        onBoardingStatus === 1 && (React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", null,
                name,
                " Onboarded Successfully as FE (",
                FEID,
                ")"),
            React.createElement("p", { className: "sub-heaidng" }, "Please select atleast one inventory."))),
        React.createElement("div", { className: "assign-invetory-wrapper" },
            React.createElement(formik_1.Formik, { initialValues: __assign({}, initialValues), key: key, validateOnBlur: false, validationSchema: schema_1.schemas.FE_InventoryDetailsSchema, onSubmit: formSubmitHnadler }, function (_a) {
                var errors = _a.errors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    console.log('values inside inventory:', values),
                    inventoryList.map(function (chechboxItem) {
                        return (React.createElement(checkbox_component_1.default, { checked: values.selectedInventoryList.includes(chechboxItem.id), label: chechboxItem.label, key: chechboxItem.value, handleChange: function (e) {
                                return handleChange(e, chechboxItem.value, setFieldValue);
                            } }));
                    }),
                    errors['selectedInventoryList'] ? (React.createElement("div", { className: "error" }, errors['selectedInventoryList'])) : null,
                    React.createElement("div", { className: "form-btns" },
                        onBoardingStatus === 1 ? (React.createElement(button_component_1.default, { type: "button", buttonName: "Back", color: "yellow-outline", onClickHandler: backButtonHandler })) : (React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleDisplayModel })),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow", isError: Object.keys(errors).length > 0 }))));
            }))));
};
exports.default = FE_Inventory;
