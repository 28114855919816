"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Highcharts = require("highcharts");
var HighchartsReact = require("highcharts-react-official");
var React = require("react");
var ReactHighChart = function (_a) {
    var chartOptions = _a.chartOptions;
    var chartComponentRef = React.useRef(null);
    return (React.createElement("div", null,
        React.createElement(HighchartsReact.HighchartsReact, { highcharts: Highcharts, options: chartOptions, ref: chartComponentRef })));
};
exports.default = ReactHighChart;
