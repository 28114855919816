"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_router_1 = require("react-router");
var api_1 = require("../../components/super_admin/role_management/api");
var appConstants_1 = require("../../constants/appConstants");
var route_path_1 = require("../../constants/route_path");
var vendor_creation_tab_constant_1 = require("../../constants/vendor_creation_tab_constant");
var menu_constants_1 = require("../../menuList/menu_constants");
var array_helpers_1 = require("../../utils/array_helpers");
var button_component_1 = require("./button_component");
var MultiIconButton = function (_a) {
    var size = _a.size, icon = _a.icon, color = _a.color, isEditVendor = _a.isEditVendor, userName = _a.userName, style = _a.style, user_id = _a.user_id, endPoint = _a.endPoint, domain = _a.domain, deleteRowHandler = _a.deleteRowHandler, editRowHandler = _a.editRowHandler, cloneRowHandler = _a.cloneRowHandler, audit_id = _a.audit_id, name = _a.name, status = _a.status, isDeleteDisable = _a.isDeleteDisable, isEditDisable = _a.isEditDisable;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var nav = (0, react_router_1.useNavigate)();
    var location = (0, react_router_1.useLocation)();
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var Auditfeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.audit);
    var Rolefeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.roleManagement);
    console.log('featuresfeatures:', Auditfeatures);
    console.log(Rolefeatures, 'Rolefeatures');
    var editRole = function () {
        if (location.pathname.includes("".concat(route_path_1.default.PATHS.vendormanagement)) &&
            isEditVendor) {
            nav("/".concat(route_path_1.default.PATHS.vendormanagement, "/").concat(route_path_1.default.PATHS.editVendor, "/").concat(user_id, "/").concat(vendor_creation_tab_constant_1.VENDOR_CREATION_TAB_COUNT.PERSONALDETAILS));
        }
        else {
            nav("/".concat(route_path_1.default.PATHS.role_management, "/").concat(route_path_1.default.PATHS.editRole, "/").concat(user_id));
        }
    };
    console.log(editRole);
    var deleteRow = function (event) {
        console.log('inside deleteRow', audit_id);
        event.stopPropagation();
        if (deleteRowHandler) {
            deleteRowHandler(audit_id);
        }
    };
    var editRow = function (event) {
        console.log('inside editRow', audit_id);
        event.stopPropagation();
        if (editRowHandler) {
            editRowHandler(audit_id);
        }
    };
    var cloneRow = function (event) {
        console.log('inside editRow', audit_id);
        event.stopPropagation();
        cloneRowHandler(audit_id);
    };
    var deleteUser = function () {
        (0, api_1.deleteRole)("".concat(endPoint, "/").concat(user_id), domain)
            .then(function (res) {
            react_hot_toast_1.default.success(res.message);
            UpdateListData.setIsListReloaded(true);
            handleClose();
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    return (React.createElement("div", null,
        name === 'AuditActions' ? (React.createElement(material_1.IconButton, { className: status === 'DRAFT' || status === 'CREATED'
                ? "icon-btn"
                : "icon-btn icon-disabled", onClick: name
                ? status === 'DRAFT' || status === 'CREATED'
                    ? deleteRow
                    : undefined
                : handleOpen, disabled: isDeleteDisable },
            React.createElement("span", { className: "icon-delete_forever" }))) : (React.createElement(material_1.IconButton, { className: 'icon-btn', onClick: name
                ? status === 'DRAFT' || status === 'CREATED'
                    ? deleteRow
                    : undefined
                : handleOpen, disabled: isDeleteDisable },
            React.createElement("span", { className: "icon-delete_forever" }))),
        name === 'AuditActions' ? (React.createElement(React.Fragment, null,
            React.createElement(material_1.IconButton, { className: "icon-btn", onClick: cloneRow },
                React.createElement("span", { className: "icon-content_copy" })),
            React.createElement(material_1.IconButton, { className: status === 'DRAFT' || status === 'CREATED'
                    ? "icon-btn"
                    : "icon-btn icon-disabled", onClick: editRow, disabled: isEditDisable },
                React.createElement("span", { className: "icon-edit" })))) : (React.createElement(material_1.IconButton, { className: "icon-btn", onClick: editRole, disabled: isEditDisable },
            React.createElement("span", { className: "icon-edit" }))),
        React.createElement(material_1.Modal, { open: open },
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "icon-warning modal-alert-icon" }),
                    React.createElement("h2", null, "Confirmation")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, "Are you sure  you want to delete the (".concat(userName, ") ?"))),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow", onClickHandler: deleteUser }))))));
};
exports.default = MultiIconButton;
