"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_ContactDetails_Fields = exports.USER_ProfileDetails_Fields = exports.VENDOR_ADDRESS_TYPES = exports.ADDRESS_TYPES = exports.FE_EmploymentDetails_Fields = exports.FE_ContactDetails_Fields = exports.FE_BankAccountDetails_Fields = exports.FE_DrivingLicenceDetails_Fields = exports.Login_Form_Fields = exports.FE_ReplaceFEPersonalDetails_Fields = exports.FE_PersonalDetails_Fields = exports.FEOnboardingConstants = exports.loginByOtpConstants = exports.getOtpConstants = exports.loginConstants = void 0;
var checkbox_component_1 = require("../common/checkbox/checkbox_component");
var drop_down_component_1 = require("../common/drop_down/drop_down_component");
var file_upload_component_1 = require("../common/file_upload/file_upload_component");
var OTPFIeldComponent_1 = require("../common/otp/OTPFIeldComponent");
var text_filed_with_icon_1 = require("../common/text_filed/text-filed-with-icon");
var text_field_password_1 = require("../common/text_filed/text_field_password");
var text_field_with_datepicker_1 = require("../common/text_filed/text_field_with_datepicker");
var text_filed_component_1 = require("../common/text_filed/text_filed_component");
exports.loginConstants = [
    {
        name: 'user_name',
        placeholder: 'username ',
        label: 'userName',
        fieldValue: 'user_name',
        inputComponent: text_filed_component_1.default,
    },
    {
        name: 'password',
        placeholder: 'password ',
        label: 'password',
        fieldValue: 'password',
        inputComponent: text_filed_component_1.default,
    },
];
exports.getOtpConstants = [
    {
        name: 'username',
        placeholder: 'userName ',
        label: 'userName',
        fieldValue: 'username',
        inputComponent: text_filed_component_1.default,
    },
];
exports.loginByOtpConstants = [
    {
        name: 'userName',
        placeholder: 'username ',
        label: 'userName',
        fieldValue: 'userName',
        inputComponent: text_filed_component_1.default,
    },
    {
        name: 'otp',
        placeholder: 'otp',
        label: 'otp',
        fieldValue: 'otp',
        inputComponent: OTPFIeldComponent_1.default,
    },
];
exports.FEOnboardingConstants = [
    {
        firstSection: [
            {
                name: 'first_name',
                placeholder: 'FirstName ',
                label: 'FirstName',
                fieldValue: 'first_name',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'last_name',
                placeholder: 'LastName',
                label: 'LastName',
                fieldValue: 'last_name',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'date_birth',
                placeholder: 'Date Of Birth',
                label: 'LasDate Of BirthtName',
                fieldValue: 'date_birth',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'MobileNumber',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                fieldValue: 'MobileNumber',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'emailId',
                placeholder: 'Email Address',
                label: 'Email Address',
                fieldValue: 'emailId',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'emergencyContactNo',
                placeholder: 'Emergency Contact Number',
                label: 'Emergency Contact Number',
                fieldValue: 'emergencyContactNo',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'uploadPhoto',
                placeholder: '',
                label: 'Upload Photo',
                fieldValue: 'uploadPhoto',
                inputComponent: text_filed_component_1.default,
            },
        ],
        secondSection: [
            {
                name: 'uploadPhoto',
                placeholder: '',
                label: 'Upload Photo',
                fieldValue: 'uploadPhoto',
                inputComponent: text_filed_component_1.default,
            },
        ],
    },
];
exports.FE_PersonalDetails_Fields = [
    {
        title: '',
        fields: [
            {
                name: 'firstName',
                placeholder: 'First Name ',
                label: 'First Name',
                fieldValue: 'firstName',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'lastName',
                placeholder: 'Last Name ',
                label: 'Last Name',
                fieldValue: 'lastName',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'DateOfBirth',
                placeholder: 'Date Of Birth ',
                label: 'Date Of Birth',
                defaultVal: '2022-08-24',
                fieldValue: 'dob',
                required: true,
                isFutureDisable: true,
                type: 'dateselection',
                inputComponent: text_field_with_datepicker_1.default,
            },
            {
                name: 'genderType',
                placeholder: 'Gender Type ',
                label: 'Gender Type ',
                fieldValue: 'gender',
                inputComponent: drop_down_component_1.default,
            },
        ],
    },
    {
        title: 'Contact Details',
        fields: [
            {
                name: 'MobileNumber',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                fieldValue: 'mobileNumber',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'email',
                placeholder: 'Email Address',
                label: 'Email',
                fieldValue: 'email',
                required: false,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'emergencyContactNo',
                placeholder: 'Emergency Contact Number',
                label: 'Emergency Contact Number',
                fieldValue: 'emergencyContactNo',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'uploadPhoto',
                placeholder: 'Upload Photo',
                label: 'Upload Profile Photo',
                fieldValue: 'uploadPhoto',
                required: false,
                type: 'fileupload',
                acceptedFiletypes: ['image/jpeg', 'image/png'],
                message: 'Note:Only png, jpg, jpeg formats are allowed upto 2MB',
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
                inputComponent: file_upload_component_1.default,
            },
        ],
    },
    {
        title: 'Take Two References',
        fields: [
            {
                name: 'referenceContactName1',
                placeholder: 'Contact Name1',
                label: 'Contact Name1',
                required: true,
                fieldValue: 'referenceContactName1',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'referenceMobileNumber1',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                required: true,
                fieldValue: 'referenceMobileNumber1',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'referenceContactName2',
                placeholder: 'Contact Name2',
                label: 'Contact Name2',
                required: true,
                fieldValue: 'referenceContactName2',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'referenceMobileNumber2',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                required: true,
                fieldValue: 'referenceMobileNumber2',
                inputComponent: text_filed_component_1.default,
            },
        ],
    },
];
exports.FE_ReplaceFEPersonalDetails_Fields = [
    {
        title: '',
        fields: [
            {
                name: 'replaceFE_FECode',
                placeholder: 'FE ID to replace with new one ',
                label: 'FE ID to replace with new one',
                fieldValue: 'replaceFE_FECode',
                required: true,
                inputComponent: text_filed_component_1.default,
                isDisabled: true,
            },
            {
                name: 'firstName',
                placeholder: 'First Name ',
                label: 'First Name',
                fieldValue: 'firstName',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'lastName',
                placeholder: 'Last Name ',
                label: 'Last Name',
                fieldValue: 'lastName',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'DateOfBirth',
                placeholder: 'Date Of Birth ',
                label: 'Date Of Birth',
                defaultVal: '2022-08-24',
                fieldValue: 'dob',
                required: true,
                isFutureDisable: true,
                type: 'dateselection',
                inputComponent: text_field_with_datepicker_1.default,
            },
            {
                name: 'genderType',
                placeholder: 'Gender Type ',
                label: 'Gender Type ',
                fieldValue: 'gender',
                inputComponent: drop_down_component_1.default,
            },
        ],
    },
    {
        title: 'Contact Details',
        fields: [
            {
                name: 'MobileNumber',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                fieldValue: 'mobileNumber',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'email',
                placeholder: 'Email Address',
                label: 'Email',
                fieldValue: 'email',
                required: false,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'emergencyContactNo',
                placeholder: 'Emergency Contact Number',
                label: 'Emergency Contact Number',
                fieldValue: 'emergencyContactNo',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'uploadPhoto',
                placeholder: 'Upload Photo',
                label: 'Upload Profile Photo',
                fieldValue: 'uploadPhoto',
                required: false,
                type: 'fileupload',
                acceptedFiletypes: ['image/jpeg', 'image/png'],
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
                inputComponent: file_upload_component_1.default,
            },
        ],
    },
    {
        title: 'Take Two References',
        fields: [
            {
                name: 'referenceContactName1',
                placeholder: 'Contact Name1',
                label: 'Contact Name1',
                required: true,
                fieldValue: 'referenceContactName1',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'referenceMobileNumber1',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                required: true,
                fieldValue: 'referenceMobileNumber1',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'referenceContactName2',
                placeholder: 'Contact Name2',
                label: 'Contact Name2',
                required: true,
                fieldValue: 'referenceContactName2',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'referenceMobileNumber2',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                required: true,
                fieldValue: 'referenceMobileNumber2',
                inputComponent: text_filed_component_1.default,
            },
        ],
    },
];
exports.Login_Form_Fields = [
    {
        name: 'username',
        placeholder: 'Username ',
        label: 'Username',
        fieldValue: 'username',
        required: true,
        inputComponent: text_filed_component_1.default,
    },
    {
        name: 'password',
        placeholder: 'password',
        label: 'password',
        fieldValue: 'password',
        inputComponent: text_field_password_1.default,
    },
];
exports.FE_DrivingLicenceDetails_Fields = [
    {
        fields: [
            {
                name: 'vehicleType',
                placeholder: 'Vehicle Type ',
                label: 'Vehicle Type ',
                fieldValue: 'vehicleType',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'drivingLicence',
                placeholder: 'Driving Licence',
                label: 'Driving Licence',
                fieldValue: 'drivingLicence',
                inputComponent: text_filed_component_1.default,
                require: true,
            },
            {
                name: 'drivingLicenceExpirydate',
                placeholder: 'Driving Licence Expiry Date',
                label: 'Driving Licence Expiry Date',
                defaultVal: '2022-08-24',
                fieldValue: 'drivingLicenceExpirydate',
                isPastDisable: true,
                type: 'dateselection',
                inputComponent: text_field_with_datepicker_1.default,
                require: true,
            },
            {
                name: 'insuranceNumber',
                placeholder: 'Insurance Number',
                label: 'Insurance Number',
                fieldValue: 'insuranceNumber',
                inputComponent: text_filed_component_1.default,
                require: true,
            },
            {
                name: 'insuranceExpirydate',
                placeholder: 'Insurance Expiry Date',
                label: 'Insurance  Expiry Date',
                defaultVal: '2022-08-24',
                fieldValue: 'insuranceExpirydate',
                isPastDisable: true,
                inputComponent: text_field_with_datepicker_1.default,
                require: true,
                type: 'dateselection',
            },
            {
                name: 'uploadInsuranceDoc',
                placeholder: 'ATTACH INSURANCE DOCUMENT',
                label: 'ATTACH INSURANCE DOCUMENT ',
                fieldValue: 'uploadInsuranceDoc',
                inputComponent: file_upload_component_1.default,
                type: 'fileupload',
                acceptedFiletypes: ['image/jpeg', 'image/png', 'application/pdf'],
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
                message: 'Note:Only png, jpg, jpeg, pdf formats are allowed upto 2MB  ',
            },
            {
                name: 'proofOfDrivingLicence',
                placeholder: 'ATTACH Driving Licence',
                label: 'ATTACH Driving Licence',
                fieldValue: 'proofOfDrivingLicence',
                required: false,
                type: 'fileupload',
                inputComponent: file_upload_component_1.default,
                acceptedFiletypes: ['image/jpeg', 'image/png', 'application/pdf'],
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
                message: 'Note:Only png, jpg, jpeg, pdf formats are allowed upto 2MB  ',
            },
        ],
    },
];
exports.FE_BankAccountDetails_Fields = [
    {
        title: '',
        fields: [
            {
                name: 'AccountNumber',
                placeholder: 'A/C Number',
                label: 'A/C Number',
                fieldValue: 'AccountNumber',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'IFSC_Code',
                placeholder: 'IFSC Code',
                label: 'IFSC Code',
                fieldValue: 'IFSC_Code',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'Bank_name',
                placeholder: 'Bank Name',
                label: 'Bank Name',
                fieldValue: 'Bank_Name',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'Bank_Branch',
                placeholder: 'Bank Branch',
                label: 'Bank Branch',
                fieldValue: 'Bank_Branch',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'UPI_ID',
                placeholder: 'UPI ID',
                label: 'UPI ID',
                fieldValue: 'UPI_ID',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'proofOfBankAccountDetails',
                placeholder: 'ATTACH PROOF OF Bank Account',
                label: 'ATTACH PROOF OF Bank Account ',
                fieldValue: 'proofOfBankAccountDetails',
                required: false,
                type: 'fileupload',
                inputComponent: file_upload_component_1.default,
                acceptedFiletypes: ['image/jpeg', 'image/png', 'application/pdf'],
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
                message: 'Note:Only png, jpg, jpeg, pdf formats are allowed upto 2MB  ',
            },
        ],
    },
];
exports.FE_ContactDetails_Fields = [
    {
        title: 'Current Address',
        fields: [
            {
                name: 'addresslane1',
                placeholder: 'Address lane 1',
                label: 'Address lane 1',
                fieldValue: 'addresslane1',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'addresslane2',
                placeholder: 'Address lane 2',
                label: 'Address lane 2',
                fieldValue: 'addresslane2',
                required: false,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'landmark',
                placeholder: 'Landmark ',
                label: 'Landmark',
                fieldValue: 'landmark',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'state',
                placeholder: 'Select State ',
                label: 'State',
                fieldValue: 'state',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'city',
                placeholder: 'Select City',
                label: 'City',
                fieldValue: 'city',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'country',
                placeholder: 'Select Country',
                label: 'Country',
                fieldValue: 'country',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'pincode',
                placeholder: 'Pincode ',
                label: 'Pincode',
                fieldValue: 'pincode',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'addressProofType',
                placeholder: 'Address proof type',
                label: 'Address proof type',
                fieldValue: 'addressProofType',
                required: false,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'proofOfAddress',
                placeholder: 'ATTACH PROOF OF ADDRESS',
                label: 'ATTACH PROOF OF ADDRESS ',
                fieldValue: 'proofOfAddress',
                required: false,
                type: 'fileupload',
                inputComponent: file_upload_component_1.default,
                acceptedFiletypes: ['image/jpeg', 'image/png', 'application/pdf'],
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
                message: 'Note:Only png, jpg, jpeg, pdf formats are allowed upto 2MB  ',
            },
        ],
    },
    {
        title: 'Permanent Address',
        fields: [
            {
                name: 'permenentAddresslane1',
                placeholder: 'Address lane 1',
                label: 'Address lane 1',
                fieldValue: 'permenentAddresslane1',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'permenentAddresslane2',
                placeholder: 'Address lane 2',
                label: 'Address lane 2',
                fieldValue: 'permenentAddresslane2',
                required: false,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'permenentLandmark',
                placeholder: 'Landmark ',
                label: 'Landmark',
                fieldValue: 'permenentLandmark',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'permenentState',
                placeholder: 'Select State',
                label: 'State',
                fieldValue: 'permenentState',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'permenentCity',
                placeholder: 'Select City',
                label: 'City',
                fieldValue: 'permenentCity',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'permenentCountry',
                placeholder: 'Select Country',
                label: 'country',
                fieldValue: 'permenentCountry',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'permenentPincode',
                placeholder: 'Pincode ',
                label: 'Pincode',
                fieldValue: 'permenentPincode',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'permanentProofOfAddressType',
                placeholder: 'Address proof type',
                label: 'Address proof type',
                fieldValue: 'permanentProofOfAddressType',
                required: false,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'permenentProofOfAddress',
                placeholder: 'ATTACH PROOF OF ADDRESS',
                label: 'ATTACH PROOF OF ADDRESS ',
                fieldValue: 'permenentProofOfAddress',
                required: false,
                type: 'fileupload',
                acceptedFiletypes: ['image/jpeg', 'image/png', 'application/pdf'],
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
                inputComponent: file_upload_component_1.default,
                message: 'Note:Only png, jpg, jpeg, pdf formats are allowed upto 2MB  ',
            },
        ],
    },
];
exports.FE_EmploymentDetails_Fields = [
    {
        title: '',
        fields: [
            {
                name: 'employee_vendor_id',
                placeholder: 'Employee Vendor ID',
                label: 'Employee Vendor ID',
                fieldValue: 'employee_vendor_id',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'fe_Type',
                placeholder: 'FE Type',
                label: 'FE Type',
                fieldValue: 'fe_Type',
                inputComponent: drop_down_component_1.default,
                displayRemark: true,
            },
            {
                name: 'dateOfJoining',
                placeholder: 'Date of joining ',
                label: 'Date of joining',
                fieldValue: 'dateOfJoining',
                type: 'dateselection',
                inputComponent: text_field_with_datepicker_1.default,
            },
            {
                name: 'company',
                placeholder: 'Company',
                label: 'Company',
                fieldValue: 'company',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'tenure',
                placeholder: 'Tenure',
                label: 'Tenure',
                fieldValue: 'tenure',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'labCode',
                placeholder: 'Lab Code',
                label: 'Lab Code',
                fieldValue: 'labCode',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'labName',
                placeholder: 'Lab Name',
                label: 'Lab Name',
                fieldValue: 'labName',
                inputComponent: text_filed_component_1.default,
                isDisabled: true,
            },
            {
                name: 'HudId',
                placeholder: 'Hub',
                label: 'Hub',
                fieldValue: 'HudId',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'FEWorkingState',
                placeholder: 'Lab State',
                label: 'Lab State',
                fieldValue: 'FEWorkingState',
                inputComponent: text_filed_component_1.default,
                isDisabled: true,
            },
            {
                name: 'FEWorkingCity',
                placeholder: 'Lab City',
                label: 'Lab City',
                fieldValue: 'FEWorkingCity',
                inputComponent: text_filed_component_1.default,
                isDisabled: true,
            },
            {
                name: 'fe_working_state',
                placeholder: 'FE Working State',
                label: 'FE Working State',
                fieldValue: 'fe_working_state',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'fe_working_city',
                placeholder: 'FE working City',
                label: 'FE Working City',
                fieldValue: 'fe_working_city',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'zone',
                placeholder: 'Zone',
                label: 'Zone',
                fieldValue: 'zone',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'regionalManager',
                placeholder: 'Regional Manager',
                label: 'Regional Manager',
                fieldValue: 'regionalManager',
                inputComponent: text_filed_component_1.default,
                isDisabled: true,
            },
            {
                name: 'FEVendorCode',
                placeholder: 'FE Vendor Code',
                label: 'FE Vendor Code',
                fieldValue: 'FEVendorCode',
                inputComponent: text_filed_component_1.default,
                isDisabled: true,
            },
            {
                name: 'vendorName',
                placeholder: 'Vendor Name',
                label: 'Vendor Name',
                fieldValue: 'vendorName',
                inputComponent: text_filed_component_1.default,
                isDisabled: true,
            },
        ],
    },
    {
        title: 'FE Base Location',
        description: '',
        fields: [
            {
                name: 'base_location_address',
                placeholder: 'Base Location Address',
                label: 'Base Location Address',
                fieldValue: 'base_location_address',
                inputComponent: text_filed_with_icon_1.default,
                isDisabled: false,
            },
            {
                name: 'baseLocation',
                placeholder: 'FE Base Location',
                label: 'Base Location',
                fieldValue: 'baseLocation',
                inputComponent: text_filed_component_1.default,
                isDisabled: true,
            },
        ],
    },
    {
        title: 'Assign Additional Roles',
        description: 'please select any of the following checkboxes to assign multiple roles if required',
        fields: [
            {
                name: 'isSuperVisor',
                placeholder: 'isSuperVisor',
                label: 'Supervisor FE',
                fieldValue: 'isSuperVisor',
                inputComponent: checkbox_component_1.default,
                isDisabled: false,
            },
            {
                name: 'isBackUpFE',
                placeholder: 'isBackUpFE',
                label: 'Backup FE',
                fieldValue: 'isBackUpFE',
                inputComponent: checkbox_component_1.default,
                isDisabled: false,
            },
            {
                name: 'canEditCCCoordinates',
                placeholder: 'canEditCCCoordinates',
                label: 'Can Edit CC Coordinates',
                fieldValue: 'canEditCCCoordinates',
                inputComponent: checkbox_component_1.default,
                isDisabled: false,
            },
        ],
    },
    {
        title: 'Assign Route',
        description: '',
        fields: [
            {
                name: 'assign_route',
                placeholder: 'Assign Route',
                label: 'Assign Route',
                fieldValue: 'assign_route',
                inputComponent: drop_down_component_1.default,
                isDisabled: false,
            },
        ],
    },
];
exports.ADDRESS_TYPES = {
    present: 'present',
    permanent: 'permanent',
};
exports.VENDOR_ADDRESS_TYPES = {
    OfficeAddress: 'Office Address',
    RegisteredAddress: 'Registered Address',
};
exports.USER_ProfileDetails_Fields = [
    {
        title: '',
        fields: [
            {
                name: 'user_Role',
                placeholder: 'Select Role',
                label: 'user_Role',
                fieldValue: 'user_Role',
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'firstName',
                placeholder: 'First Name ',
                label: 'First Name',
                fieldValue: 'firstName',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'lastName',
                placeholder: 'Last Name ',
                label: 'Last Name',
                fieldValue: 'lastName',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'DateOfBirth',
                placeholder: 'Date Of Birth ',
                label: 'Date Of Birth',
                defaultVal: '2022-08-24',
                fieldValue: 'dob',
                required: true,
                isFutureDisable: true,
                type: 'dateselection',
                inputComponent: text_field_with_datepicker_1.default,
            },
        ],
    },
    {
        title: 'Contact Details',
        fields: [
            {
                name: 'MobileNumber',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                fieldValue: 'mobileNumber',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'email',
                placeholder: 'Email Address',
                label: 'Email',
                fieldValue: 'email',
                required: false,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'emergencyContactNo',
                placeholder: 'Emergency Contact Number',
                label: 'Emergency Contact Number',
                fieldValue: 'emergencyContactNo',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'uploadPhoto',
                placeholder: 'Upload Photo',
                label: 'Upload Photo',
                fieldValue: 'uploadPhoto',
                required: false,
                type: 'fileupload',
                acceptedFiletypes: ['image/jpeg', 'image/png'],
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
                inputComponent: file_upload_component_1.default,
            },
        ],
    },
    {
        title: 'Take Two References',
        fields: [
            {
                name: 'referenceContactName1',
                placeholder: 'Contact Name1',
                label: 'Contact Name1',
                required: true,
                fieldValue: 'referenceContactName1',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'referenceMobileNumber1',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                required: true,
                fieldValue: 'referenceMobileNumber1',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'referenceContactName2',
                placeholder: 'Contact Name2',
                label: 'Contact Name2',
                required: true,
                fieldValue: 'referenceContactName2',
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'referenceMobileNumber2',
                placeholder: 'Mobile Number',
                label: 'Mobile Number',
                required: true,
                fieldValue: 'referenceMobileNumber2',
                inputComponent: text_filed_component_1.default,
            },
        ],
    },
];
exports.USER_ContactDetails_Fields = [
    {
        title: 'Current Address',
        fields: [
            {
                name: 'addresslane1',
                placeholder: 'Address lane 1',
                label: 'Address lane 1',
                fieldValue: 'addresslane1',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'addresslane2',
                placeholder: 'Address lane 2',
                label: 'Address lane 2',
                fieldValue: 'addresslane2',
                required: false,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'landmark',
                placeholder: 'Landmark ',
                label: 'Landmark',
                fieldValue: 'landmark',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'state',
                placeholder: 'Select State ',
                label: 'State',
                fieldValue: 'state',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'city',
                placeholder: 'Select City',
                label: 'City',
                fieldValue: 'city',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'country',
                placeholder: 'Select Country',
                label: 'Country',
                fieldValue: 'country',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'pincode',
                placeholder: 'Pincode ',
                label: 'Pincode',
                fieldValue: 'pincode',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'proofOfAddress',
                placeholder: 'ATTACH PROOF OF ADDRESS',
                label: 'ATTACH PROOF OF ADDRESS ',
                fieldValue: 'proofOfAddress',
                required: false,
                type: 'fileupload',
                inputComponent: file_upload_component_1.default,
                acceptedFiletypes: ['application/pdf'],
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
            },
        ],
    },
    {
        title: 'Permanent Address',
        fields: [
            {
                name: 'permenentAddresslane1',
                placeholder: 'Address lane 1',
                label: 'Address lane 1',
                fieldValue: 'permenentAddresslane1',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'permenentAddresslane2',
                placeholder: 'Address lane 2',
                label: 'Address lane 2',
                fieldValue: 'permenentAddresslane2',
                required: false,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'permenentLandmark',
                placeholder: 'Landmark ',
                label: 'Landmark',
                fieldValue: 'permenentLandmark',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'permenentState',
                placeholder: 'Select State',
                label: 'State',
                fieldValue: 'permenentState',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'permenentCity',
                placeholder: 'Select City',
                label: 'City',
                fieldValue: 'permenentCity',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'permenentCountry',
                placeholder: 'Select Country',
                label: 'country',
                fieldValue: 'permenentCountry',
                required: true,
                inputComponent: drop_down_component_1.default,
            },
            {
                name: 'permenentPincode',
                placeholder: 'Pincode ',
                label: 'Pincode',
                fieldValue: 'permenentPincode',
                required: true,
                inputComponent: text_filed_component_1.default,
            },
            {
                name: 'permenentProofOfAddress',
                placeholder: 'ATTACH PROOF OF ADDRESS',
                label: 'ATTACH PROOF OF ADDRESS ',
                fieldValue: 'permenentProofOfAddress',
                required: false,
                type: 'fileupload',
                acceptedFiletypes: ['application/pdf'],
                maxSize: {
                    image: 2,
                    pdf: 2,
                    csv: 2,
                },
                inputComponent: file_upload_component_1.default,
            },
        ],
    },
];
