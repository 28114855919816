"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.route_plan_status = void 0;
exports.route_plan_status = {
    Initiated: 1,
    Ready_for_review: 2,
    Sent_for_approval: 3,
    Approved: 4,
    Rejected: 5,
    Error: 6,
};
