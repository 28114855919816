"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransportMode = exports.TAT = exports.AM = exports.Minutes = exports.Hours = exports.auditeeTypeList = exports.maxDropdownOptions = exports.defaultPageLimit = exports.paginationDropdown = exports.reportDownload = exports.ReloadListContext = exports.dummyDropdownList = exports.fixedDateTimeFormatt = exports.UpdatedDateFormat = exports.fixedDateFormatt = exports.LOGINAPIFAIL = exports.CONFIGAPIFAIL = exports.ERRORMESSAGE = exports.maxDropdownList = exports.minDropdownList = exports.questionTypeDropdown = exports.totalQuestionsDropdown = exports.targetAuditorsDropdownList = exports.targetAuditeeeDropdownList = exports.targetAudienceDropdownList = exports.countryDropdownList = exports.RejectOndemand = exports.CreateOnDemandRequest = exports.AddVisit = exports.bookMark = exports.VENDOR_STATUS = exports.FE_USER_TYPES = exports.Paths = exports.days = exports.Days = exports.Months = exports.AppConstants = void 0;
var React = require("react");
exports.AppConstants = {
    ComponentsType: {
        Dashboard: 'dashboard',
        Settings: 'settings',
    },
    RoleDetails: [
        { id: 1, name: 'LG Super Admin' },
        { id: 2, name: 'Vendor Admin' },
        { id: 3, name: 'CC Admin' },
    ],
    languages: ['English', 'Spanish'],
};
exports.Months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
exports.Days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];
exports.days = {
    Monday: '',
    Tuesday: '',
    Wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
};
exports.Paths = {
    default: '/',
    login: '/login',
    profile: '/profile',
    dashboard: '/dashboard',
};
exports.FE_USER_TYPES = {
    active: 'Active',
    drafted: 'Draft',
    exited: 'Exited',
    onLeaveToday: 'APPROVED',
    rejected: 'Rejected',
    inactive: 'In-Active',
    inventoryPending: 'Approved',
    inActive: 'In-Active',
    levelOne: 'Pending for approval',
    levelTwo: 'Pending for approval',
};
exports.VENDOR_STATUS = {
    active: 1,
    drafted: 2,
    exited: 3,
    levelOne: 5,
    levelTwo: 6,
    rejected: 8,
};
exports.bookMark = {
    Add: 'Added to bookmarks list',
    remove: 'Removed from bookmarks list',
};
exports.AddVisit = {
    Add: 'On Demand Request Assigned Successfully',
    remove: 'On Demand Request is not Assigned',
};
exports.CreateOnDemandRequest = {
    Add: 'On Demand Request Created Successfully',
    remove: 'Same slot is existed for the user',
};
exports.RejectOndemand = {
    Add: 'Rejected OnDemand Request Successfully',
    remove: '',
};
exports.countryDropdownList = [
    {
        id: 1,
        label: 'India',
        value: 1,
    },
];
exports.targetAudienceDropdownList = [
    {
        id: 1,
        label: 'FE',
        value: 1,
        disabled: true,
    },
    {
        id: 2,
        label: 'CC',
        value: 2,
        disabled: true,
    },
    {
        id: 3,
        label: 'Lab',
        value: 3,
        disabled: true,
    },
    {
        id: 4,
        label: 'Vendor',
        value: 4,
        disabled: true,
    },
];
exports.targetAuditeeeDropdownList = [
    {
        id: 1,
        label: 'ALL',
        value: 1,
        disabled: false,
    },
    {
        id: 2,
        label: 'CC',
        value: 2,
        disabled: false,
    },
    {
        id: 3,
        label: 'PUP',
        value: 3,
        disabled: false,
    },
    {
        id: 4,
        label: 'PSC',
        value: 4,
        disabled: false,
    },
];
exports.targetAuditorsDropdownList = [
    {
        id: 1,
        label: 'FE',
        value: 1,
        disabled: false,
    },
    {
        id: 2,
        label: 'CC',
        value: 2,
        disabled: true,
    },
    {
        id: 3,
        label: 'Lab',
        value: 3,
        disabled: true,
    },
    {
        id: 4,
        label: 'Vendor',
        value: 4,
        disabled: true,
    },
];
exports.totalQuestionsDropdown = [
    {
        id: 1,
        label: '1',
        value: 1,
    },
    {
        id: 2,
        label: '2',
        value: 2,
    },
    {
        id: 3,
        label: '3',
        value: 3,
    },
    {
        id: 4,
        label: '4',
        value: 4,
    },
    {
        id: 5,
        label: '5',
        value: 5,
    },
    {
        id: 6,
        label: '6',
        value: 6,
    },
];
exports.questionTypeDropdown = [
    {
        id: 1,
        label: 'Single Choice',
        value: 1,
    },
    {
        id: 2,
        label: 'Multiple Choice',
        value: 2,
    },
    {
        id: 3,
        label: 'True/False',
        value: 3,
    },
    {
        id: 4,
        label: 'Linear Scale',
        value: 4,
    },
    {
        id: 5,
        label: 'Short Answer',
        value: 5,
    },
    {
        id: 6,
        label: 'Mobile Number',
        value: 6,
    },
];
exports.minDropdownList = [
    {
        id: 1,
        label: '1',
        value: 1,
    },
    {
        id: 2,
        label: '2',
        value: 2,
    },
    {
        id: 3,
        label: '3',
        value: 3,
    },
    {
        id: 4,
        label: '4',
        value: 4,
    },
    {
        id: 5,
        label: '5',
        value: 5,
    },
];
exports.maxDropdownList = [
    {
        id: 1,
        label: '1',
        value: 1,
    },
    {
        id: 2,
        label: '2',
        value: 2,
    },
    {
        id: 3,
        label: '3',
        value: 3,
    },
    {
        id: 4,
        label: '4',
        value: 4,
    },
    {
        id: 5,
        label: '5',
        value: 5,
    },
];
exports.ERRORMESSAGE = 'Unable to process your request';
exports.CONFIGAPIFAIL = 'unable to fetch application config data,please contact your admin';
exports.LOGINAPIFAIL = 'Invalid Credentails';
exports.fixedDateFormatt = 'DD-MM-YYYY';
exports.UpdatedDateFormat = 'DD/MM/YYYY';
exports.fixedDateTimeFormatt = 'DD-MM-YYYY HH:MM:SS';
exports.dummyDropdownList = [
    {
        id: 1,
        value: 'dummy',
        label: 'dummy',
    },
    {
        id: 2,
        value: 'dummy',
        label: 'dummy',
    },
    {
        id: 3,
        value: 'dummy',
        label: 'dummy',
    },
    {
        id: 4,
        value: 'dummy',
        label: 'dummy',
    },
];
exports.ReloadListContext = React.createContext(null);
exports.reportDownload = 'Download';
exports.paginationDropdown = [
    {
        id: 1,
        label: '50',
        value: 50,
    },
    {
        id: 2,
        label: '100',
        value: 100,
    },
    {
        id: 3,
        label: '250',
        value: 250,
    },
    {
        id: 4,
        label: '500',
        value: 500,
    },
];
exports.defaultPageLimit = {
    id: 5,
    label: '50',
    value: 50,
};
exports.maxDropdownOptions = 2000;
exports.auditeeTypeList = [
    {
        id: 2,
        label: 'CC',
        value: 'CC',
        disabled: false,
    },
    {
        id: 1,
        label: 'FE',
        value: 'FE',
        disabled: true,
    },
    {
        id: 3,
        label: 'Lab',
        value: 'Lab',
        disabled: true,
    },
    {
        id: 4,
        label: 'Vendor',
        value: 'Vendor',
        disabled: true,
    },
];
exports.Hours = [
    '00',
    '01',
    '02 ',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22 ',
    '23',
];
exports.Minutes = [
    '00',
    '01',
    '02 ',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22 ',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32 ',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42 ',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52 ',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
];
exports.AM = ['AM', 'PM'];
exports.TAT = [
    {
        id: 0,
        label: 'Same Day',
        value: 0,
        hours: 0,
    },
    {
        id: 1,
        label: 'Next Day',
        value: 1,
        hours: 24,
    },
    {
        id: 2,
        label: 'Day plus One',
        value: 2,
        hours: 48,
    },
    {
        id: 3,
        label: 'Day plus Two',
        value: 3,
        hours: 72,
    },
];
exports.TransportMode = [
    {
        id: 1,
        label: 'MULTIMODAL',
        value: 'MULTIMODAL',
    },
    {
        id: 2,
        label: 'OBC/OTC',
        value: 'OBC/OTC',
    },
    {
        id: 3,
        label: 'HAND DELIVERY',
        value: 'HAND DELIVERY',
    },
    {
        id: 4,
        label: 'AIRLINES VENDOR',
        value: 'AIRLINES VENDOR',
    },
    {
        id: 5,
        label: 'COURIER VENDOR',
        value: 'COURIER VENDOR',
    },
    {
        id: 6,
        label: 'OTHER',
        value: 'OTHER',
    },
    {
        id: 7,
        label: 'VAN',
        value: 'VAN',
    },
];
