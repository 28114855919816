"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_query_1 = require("react-query");
var api_service_1 = require("../../../api/api_service");
var modal_component_1 = require("../../../common/modal/modal_component");
var react_paginate_1 = require("../../../common/react_pagination/react_paginate");
var AGgrid_table_component_1 = require("../../../common/tables/AGgrid_table_component");
var appConstants_1 = require("../../../constants/appConstants");
var table_data_1 = require("../../../constants/table_data");
var menu_constants_1 = require("../../../menuList/menu_constants");
var array_helpers_1 = require("../../../utils/array_helpers");
var fe_exit_process_1 = require("../FE_Exit/fe_exit_process");
var fe_services_1 = require("../fe_services");
var FEInActiveList = function (_a) {
    var searchValue = _a.searchValue, selectedCities = _a.selectedCities, CurrentPagelimit = _a.CurrentPagelimit, isFilterButton = _a.isFilterButton;
    var _b = React.useState(undefined), feInActiveList = _b[0], setFeInActiveList = _b[1];
    var _c = React.useState(0), totalCount = _c[0], setTotalCount = _c[1];
    var _d = React.useState(), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = React.useState(false), open = _e[0], setOpen = _e[1];
    var _f = React.useState(), exit_date = _f[0], setExit_date = _f[1];
    var date = new Date(exit_date);
    var maximumDate = new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    var _g = React.useState(''), selectedUserId = _g[0], setSelectedUserId = _g[1];
    var _h = React.useState([]), selectedUserInventoryList = _h[0], setSelectedUserInventoryList = _h[1];
    var _j = React.useState(''), feName = _j[0], setFeName = _j[1];
    var _k = React.useState(''), feID = _k[0], setFEID = _k[1];
    var _l = React.useState(1), pageCount = _l[0], setpageCount = _l[1];
    var _m = React.useState(0), totalPageCount = _m[0], setTotalPageCount = _m[1];
    var FE_InActiveAndExit = {
        reason: null,
        selectedInventoryList: [],
        fromDate: '',
        comments: '',
        exit_type: 'Exited',
        inventory_amount: '',
        inactivedate: date,
    };
    var _o = React.useState(__assign({}, FE_InActiveAndExit)), feExitDetails = _o[0], setFeExitDetails = _o[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () {
        setOpen(false);
        setExit_date(null);
    };
    var feManagementFeatures = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.feManagement);
    var fetchInActiveFE = function (endPoint, payLoad, domain) {
        (0, fe_services_1.fetchFEList)(endPoint, payLoad, domain)
            .then(function (response) {
            setErrorMessage("");
            var pageSize = CurrentPagelimit
                ? parseInt(CurrentPagelimit.value.toString())
                : 50;
            var count = Math.ceil(response.count / pageSize);
            setTotalPageCount(count);
            setFeInActiveList(response.result);
            setTotalCount(response.count);
        })
            .catch(function (err) {
            setErrorMessage(err);
            setFeInActiveList([]);
        });
    };
    var onRowClickedHandler = function (e) {
        new Date(exit_date);
        setExit_date(new Date(e === null || e === void 0 ? void 0 : e.data.updated_date_time));
        var endPoint = api_service_1.api_service.api_urls.ASSIGNED_INVENTORY;
        var payLoad = { fe_id: e.data.id };
        var domain = process.env.FE_LIST_BASE_URL;
        api_service_1.api_service
            .get({
            endPoint: endPoint,
            domain: domain,
            payLoad: payLoad,
        })
            .then(function (response) {
            if (response.status === 200) {
                var inventoryList_1 = [];
                var selectedInventory_1 = [];
                response.data.data.map(function (data) {
                    inventoryList_1.push({
                        id: data.id,
                        inventory_name: data.item,
                        inventory_id: data.inventory_id,
                    });
                    selectedInventory_1.push(data.inventory_id);
                });
                var feName_1 = (0, array_helpers_1.concatFirstNameLastName)(e.data.first_name, e.data.last_name);
                setFeExitDetails(__assign(__assign({}, feExitDetails), { selectedInventoryList: selectedInventory_1, inactivedate: e.data.updated_date_time }));
                setSelectedUserInventoryList(inventoryList_1);
                setFeName(feName_1);
                setFEID(e.data.fe_id);
                setSelectedUserId(e.data.id);
                handleOpen();
                return;
            }
            throw new Error(response.data.message);
        })
            .catch(function (err) {
            return react_hot_toast_1.default.error(err.data.message);
        });
    };
    var refetch = (0, react_query_1.useQuery)(['in-active-fe', pageCount], function () {
        var currentPage = pageCount < 1 ? 1 : pageCount;
        var payload = (0, array_helpers_1.createPayloadForFEList)(appConstants_1.FE_USER_TYPES.inActive, searchValue, selectedCities);
        payload.page = currentPage;
        payload.size = CurrentPagelimit
            ? parseInt(CurrentPagelimit.value.toString())
            : 50;
        fetchInActiveFE(api_service_1.api_service.api_urls.FE_LIST, payload, process.env.VENDOR_BASE_URL);
    }).refetch;
    React.useEffect(function () {
        if (pageCount != 1) {
            setpageCount(1);
        }
        else {
            refetch();
        }
    }, [isFilterButton]);
    var handlePageClick = function (data) {
        var currentPage = data.selected + 1;
        setpageCount(currentPage);
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "custom-pagination-table ".concat(!feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.exitFE] && 'unclickablerow') },
            React.createElement(AGgrid_table_component_1.default, { isSortable: true, columnDefs: table_data_1.default.FE_IN_ACTIVE_COLUMNS, rows: feInActiveList && feInActiveList, errorMessage: errorMessage, onRowClicked: feManagementFeatures[menu_constants_1.FEATURE_CONSTANTS.exitFE] &&
                    onRowClickedHandler, pagination: false }),
            React.createElement(react_paginate_1.default, { onPageChange: handlePageClick, pageCount: totalPageCount, currentPage: pageCount, total: totalCount, dataLength: feInActiveList === null || feInActiveList === void 0 ? void 0 : feInActiveList.length, currentPageLimits: CurrentPagelimit === null || CurrentPagelimit === void 0 ? void 0 : CurrentPagelimit.value })),
        React.createElement("div", { className: "page-control-wrapper" },
            React.createElement(modal_component_1.default, { open: open },
                React.createElement(fe_exit_process_1.default, { feName: feName, fe_id: feID, inventoryList: selectedUserInventoryList, handleClose: handleClose, user_id: selectedUserId, isAlreadyInacitve: true, minimumDate: exit_date, maximumDate: maximumDate, initialValues: feExitDetails })))));
};
exports.default = FEInActiveList;
