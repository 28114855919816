"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./modal.scss");
var ModalComponentWithoutButton = function (_a) {
    var buttonColor = _a.buttonColor, buttonName = _a.buttonName, modalstyle = _a.modalstyle, value = _a.value, children = _a.children, onClickHandler = _a.onClickHandler, open = _a.open;
    return (React.createElement("div", { className: "modal-component" },
        React.createElement(material_1.Modal, { open: open, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement(material_1.Box, { className: "modal-child" }, children))));
};
exports.default = ModalComponentWithoutButton;
