"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_router_1 = require("react-router");
var route_path_1 = require("../../constants/route_path");
var IconViewComponent = function (_a) {
    var masterShipmentId = _a.masterShipmentId, barcode = _a.barcode;
    var nav = (0, react_router_1.useNavigate)();
    var onClickHandler = function () {
        if (masterShipmentId) {
            nav("/".concat(route_path_1.default.PATHS.shipment_tracking, "/").concat(route_path_1.default.PATHS.shipment_details, "/").concat(masterShipmentId));
        }
        else {
            nav("/".concat(route_path_1.default.PATHS.shipment_tracking, "/").concat(route_path_1.default.PATHS.shipment_details, "/").concat(barcode, "-barcode"));
        }
    };
    return (React.createElement("div", null,
        React.createElement(material_1.IconButton, { className: "icon-btn" },
            React.createElement("span", { className: "icon-Vector", onClick: onClickHandler }))));
};
exports.default = IconViewComponent;
