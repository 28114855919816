"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var react_router_2 = require("react-router");
var config_action_1 = require("../../actions/config_action");
var api_service_1 = require("../../api/api_service");
var api_urls_1 = require("../../api/api_urls");
var vendor_api_service_1 = require("../../components/super_admin/vendor_management/api/vendor_api_service");
var appConstants_1 = require("../../constants/appConstants");
var route_path_1 = require("../../constants/route_path");
var menu_constants_1 = require("../../menuList/menu_constants");
var button_component_1 = require("../buttons/button_component");
require("./modal.scss");
var AlertModal = function (_a) {
    var buttonColor = _a.buttonColor, endPoint = _a.endPoint, payLoad = _a.payLoad, domain = _a.domain, alertIcon = _a.alertIcon, buttonName = _a.buttonName, isBtnDisabled = _a.isBtnDisabled, isCancelDisabled = _a.isCancelDisabled, isIcondisabled = _a.isIcondisabled, onClickHandler = _a.onClickHandler, modalTitle = _a.modalTitle, icon = _a.icon, vendorFeatures = _a.vendorFeatures, VendorApprovalLevelOnefeatures = _a.VendorApprovalLevelOnefeatures, VendorApprovalLevelTwofeatures = _a.VendorApprovalLevelTwofeatures, onCloseHandler = _a.onCloseHandler, disableIcon = _a.disableIcon, approvalStatus = _a.approvalStatus, onBoardStatus = _a.onBoardStatus;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var UpdateListData = React.useContext(appConstants_1.ReloadListContext);
    var nav = (0, react_router_2.useNavigate)();
    var path = location.pathname.split('/')[1];
    var pathLocation = location.pathname.split('/')[3];
    var pathname = (0, react_router_1.useLocation)().pathname;
    var handleOpen = function () {
        setOpen(true);
        if (path == route_path_1.default.PATHS.approvals) {
            setOpen(true);
        }
        else {
            setOpen(true);
            onClickHandler();
        }
    };
    var handleClose = function () {
        if (path == route_path_1.default.PATHS.approvals) {
            setOpen(false);
        }
        else {
            setOpen(false);
            UpdateListData.setIsListReloaded(false);
            onCloseHandler();
        }
    };
    var submit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2, new Promise(function (resolve, reject) {
                    api_service_1.api_service
                        .post({
                        endPoint: endPoint,
                        domain: domain,
                        payLoad: payLoad,
                    })
                        .then(function (response) {
                        if (response.status === 200) {
                            react_hot_toast_1.default.success(response.data.message);
                            setOpen(false);
                            if (pathLocation == route_path_1.default.PATHS.vendorLevelOneDetails) {
                                nav("/".concat(route_path_1.default.PATHS.approvals, "/").concat(route_path_1.default.PATHS.vendorlist, "/").concat(route_path_1.default.PATHS.levelTwoPendingApproval), {
                                    replace: true,
                                });
                            }
                            else if (pathLocation == route_path_1.default.PATHS.vendorLevelTwoDetails) {
                                dispatch((0, config_action_1.featchCurrentActiveMenu)({
                                    mainModule: 'Master Management',
                                    subModule: menu_constants_1.FEATURE_CONSTANTS.vendorManagement,
                                    subChaildModule: '',
                                }));
                                nav("/".concat(route_path_1.default.PATHS.vendor, "/").concat(route_path_1.default.PATHS.activeList), {
                                    replace: true,
                                });
                            }
                            else if (location.pathname.includes("".concat(route_path_1.default.PATHS.vendor_reject))) {
                                dispatch((0, config_action_1.featchCurrentActiveMenu)({
                                    mainModule: 'Master Management',
                                    subModule: menu_constants_1.FEATURE_CONSTANTS.vendorManagement,
                                    subChaildModule: '',
                                }));
                                nav("/".concat(route_path_1.default.PATHS.vendor_reject), {
                                    replace: true,
                                });
                            }
                            else if (location.pathname.includes("".concat(route_path_1.default.PATHS.feLevelTwoDetails))) {
                                if (approvalStatus === 2 && onBoardStatus === 1) {
                                    dispatch((0, config_action_1.featchCurrentActiveMenu)({
                                        mainModule: menu_constants_1.FEATURE_CONSTANTS.feManagement,
                                        subModule: menu_constants_1.FEATURE_CONSTANTS.feManagement,
                                        subChaildModule: '',
                                    }));
                                    nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.activeList), {
                                        replace: true,
                                    });
                                }
                                else {
                                    dispatch((0, config_action_1.featchCurrentActiveMenu)({
                                        mainModule: menu_constants_1.FEATURE_CONSTANTS.feManagement,
                                        subModule: menu_constants_1.FEATURE_CONSTANTS.feManagement,
                                        subChaildModule: '',
                                    }));
                                    nav("/".concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.invetoryPending), {
                                        replace: true,
                                    });
                                }
                            }
                            else if (location.pathname.includes("".concat(route_path_1.default.PATHS.feLevelOneDetails))) {
                                nav("/".concat(route_path_1.default.PATHS.approvals, "/").concat(route_path_1.default.PATHS.fe, "/").concat(route_path_1.default.PATHS.levelTwoPendingApproval), {
                                    replace: true,
                                });
                            }
                            if (path == route_path_1.default.PATHS.approvals) {
                                handleClose();
                            }
                            else {
                                handleClose();
                                UpdateListData.setIsListReloaded(true);
                            }
                            return resolve(response.data.data);
                        }
                        console.log(response, 'printResonse');
                        throw new Error(response.data.message);
                    })
                        .catch(function (err) {
                        console.log(err, 'printResonse', err.message);
                        react_hot_toast_1.default.error(err.message);
                        return reject(err.message);
                    });
                })];
        });
    }); };
    var exitUser = function () {
        (0, vendor_api_service_1.exit_vendor)(endPoint, payLoad, domain)
            .then(function (res) {
            react_hot_toast_1.default.success(res.message);
            nav("/".concat(route_path_1.default.PATHS.vendor, "/").concat(route_path_1.default.PATHS.exit_vendors));
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err);
        });
    };
    return (React.createElement("div", { className: "modal-component" },
        icon ? (React.createElement(material_1.IconButton, { className: "icon-btn", onClick: handleOpen, disabled: isIcondisabled },
            React.createElement("span", { className: icon }))) : (React.createElement(button_component_1.default, { buttonName: buttonName, color: buttonColor, onClickHandler: handleOpen, isError: !isBtnDisabled })),
        React.createElement(material_1.Modal, { open: open },
            React.createElement(material_1.Box, { className: "modal-child  alert-modal" },
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("span", { className: "".concat(alertIcon, " modal-alert-icon") }),
                    React.createElement("h2", null, "Confirmation")),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("div", { className: "modal-message" }, modalTitle)),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: handleClose, isError: isCancelDisabled }),
                    React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow", onClickHandler: !pathname.includes(api_urls_1.api_urls.PROFILE_DETAILS) ? submit : exitUser }))))));
};
exports.default = AlertModal;
