"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./scf_styles.scss");
var iconButton_component_1 = require("../../../../common/buttons/iconButton_component");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../../common/buttons/button_component");
var profile_grid_1 = require("../../../../common/profile_grid/profile_grid");
var scf_service_1 = require("./scf_service");
var delete_confirmation_modal_1 = require("../../../../common/confirmation/delete_confirmation_modal");
var ModalComponentWithoutButton_1 = require("../../../../common/modal/ModalComponentWithoutButton");
var text_filed_component_1 = require("../../../../common/text_filed/text_filed_component");
var formik_1 = require("formik");
var drop_down_component_1 = require("../../../../common/drop_down/drop_down_component");
var react_redux_1 = require("react-redux");
var schema_1 = require("../../../../constants/schema");
var menu_constants_1 = require("../../../../menuList/menu_constants");
var array_helpers_1 = require("../../../../utils/array_helpers");
var api_service_1 = require("../../../../api/api_service");
var react_hot_toast_1 = require("react-hot-toast");
var route_path_1 = require("../../../../constants/route_path");
var scf_api_service_1 = require("./scf_api_service");
var orc_service_1 = require("../orc_management/orc_service");
var SCFProfileDetails = function () {
    var pathname = (0, react_router_1.useLocation)().pathname;
    var orc_rejection_reasons = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }).orc_rejection_reasons;
    var nav = (0, react_router_1.useNavigate)();
    var _a = (0, react_router_1.useParams)(), center_id = _a.center_id, id = _a.id;
    var _b = React.useState(false), approvalConfirModal = _b[0], setapprovalConfirModal = _b[1];
    var _c = React.useState(false), rejectedConfirModal = _c[0], setRejectedConfirModal = _c[1];
    var _d = React.useState({
        center_data: {
            center_id: null,
            warehouse_code: '',
            invoice_code: '',
            center_name: '',
            center_city: '',
            center_state: '',
            lab_code: '',
            route_type: '',
            data_area_id: '',
            payment_type: '',
            payment_option: '',
            courier_charges_type: '',
            courier_charges: null,
            status: null,
            lab_name: '',
            slab_one: '',
            slab_one_payout: null,
            slab_two: '',
            slab_two_payout: null,
            slab_three: '',
            slab_three_payout: null
        },
        updated_data: {
            payment_type: '',
            payment_option: '',
            courier_charges_type: '',
            courier_charges: null,
            action: null,
            status: null,
            slab_one: '',
            slab_one_payout: null,
            slab_two: '',
            slab_two_payout: null,
            slab_three: '',
            slab_three_payout: null
        }
    }), scfProfileDeatials = _d[0], setScfProfileDeatials = _d[1];
    var initialValues = {
        reject_type: null,
        reject_comment: '',
    };
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.SCF_Management);
    var checkStatus = function (status) {
        if (status == scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE) {
            return "Deactive";
        }
        else if (status == scf_service_1.SCF_CLIENT_STATUS_OBJ.DELETED) {
            return "Deleted";
        }
        else {
            return 'Active';
        }
    };
    var approvaRejectHandlere = function (payload, isModalOpened, actionType) {
        (0, scf_api_service_1.scfApprovalUpdates)(api_service_1.api_service.api_urls.SCF_APPROVE_REJECT, payload, process.env.APPROVAL_BASEURL).then(function (res) {
            if (actionType == scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_ONE_REJECTED || actionType == scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_TWO_REJECTED) {
                setRejectedConfirModal(isModalOpened);
                nav("/".concat(route_path_1.default.PATHS.scf_listing, "/").concat(route_path_1.default.PATHS.scf_rejected_listing));
                react_hot_toast_1.default.success(res.message);
            }
            else {
                react_hot_toast_1.default.success(res.message);
                setapprovalConfirModal(isModalOpened);
                nav("/".concat(route_path_1.default.PATHS.scf_listing, "/").concat(route_path_1.default.PATHS.scf_leve2_listing));
            }
        }).catch(function (err) {
            react_hot_toast_1.default.error(err.message);
        });
    };
    var approvalSubmitHandlere = function (isModalOpened) {
        var status = pathname.split('/')[2] == route_path_1.default.PATHS.scf_level1_listing ? scf_service_1.SCF_CLIENT_STATUS_OBJ.PENDING_LEVEL_TWO : scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_TWO_APPROVED;
        var payload = {
            id: id,
            status: status
        };
        approvaRejectHandlere(payload, isModalOpened, status);
    };
    var approvalCloseHandlere = function (isModalOpened) {
        setapprovalConfirModal(isModalOpened);
    };
    var rejectedSubmitHanlere = function (values) {
        var status = pathname.split('/')[2] == route_path_1.default.PATHS.orc_level1_listing ? scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_ONE_REJECTED : scf_service_1.SCF_CLIENT_STATUS_OBJ.LEVEL_TWO_REJECTED;
        var payload = {
            id: id,
            status: status,
            rejected_comments: "".concat(values.reject_type.label, " - ").concat(values.reject_comment)
        };
        approvaRejectHandlere(payload, false, status);
    };
    var rejecteCloseHandlere = function () {
        setRejectedConfirModal(false);
    };
    var fetchSCFProfileDetails = function () {
        api_service_1.api_service.get({
            endPoint: "".concat(api_service_1.api_service.api_urls.SCF_DETAILS, "/").concat(center_id),
            domain: process.env.APPROVAL_BASEURL,
        }).then(function (res) {
            if (res.data.statusCode == 200) {
                var convertedData = (0, scf_service_1.convertSCFProfileDetails)(res.data.data);
                setScfProfileDeatials(convertedData);
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        }).catch(function (err) {
            return err;
        });
    };
    React.useEffect(function () {
        if (center_id) {
            fetchSCFProfileDetails();
        }
    }, []);
    var center_data = scfProfileDeatials.center_data, updated_data = scfProfileDeatials.updated_data;
    return (React.createElement("div", { className: "page-wrapper" },
        React.createElement("div", { className: "vendor-profile-details fe-wrapper page-wrapper" },
            React.createElement("div", { className: "tool-bar-wrapper no-filter-toolbar border-top" },
                React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                    React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: function () { return nav(-1); } }),
                    React.createElement("h2", null, "SCF Details")),
                React.createElement("div", { className: "page-control-wrapper" },
                    React.createElement(button_component_1.default, { buttonName: 'Approval', color: "yellow-outline", isError: !features[menu_constants_1.FEATURE_CONSTANTS.SCF_LEVEL_1_2_APPROVE_ACTION], onClickHandler: function () { return setapprovalConfirModal(true); } }),
                    React.createElement(button_component_1.default, { isError: !features[menu_constants_1.FEATURE_CONSTANTS.SCF_REJECT_CLIENT], buttonName: 'Reject', color: 'yellow-outline', onClickHandler: function () { return setRejectedConfirModal(true); } }))),
            React.createElement(material_1.Grid, { container: true, xs: 12, className: "details-tab-wrapper" },
                React.createElement(material_1.Grid, { item: true, xs: 12 },
                    React.createElement("div", { className: "details-wrapper vendor-details-wrapper" },
                        React.createElement("div", { className: "vendor-deatils-tab approve-pro-details" },
                            React.createElement(material_1.Grid, { container: true, spacing: 0, rowSpacing: 3, columns: { xs: 18 }, columnSpacing: 3 },
                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Primary_Client, value: center_data.warehouse_code }),
                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Invoice_Code, value: center_data.invoice_code }),
                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Client_Name, value: center_data.center_name }),
                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.state, value: center_data.center_state }),
                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.city, value: center_data.center_city }),
                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Labcode, value: center_data.lab_code }),
                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Lab_Name, value: center_data.lab_name }),
                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Route_Type, value: center_data.route_type }),
                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Compnay_ID, value: center_data.data_area_id }),
                                center_data && (center_data.status != updated_data.status || updated_data.action == null) &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Payment_type, value: center_data.payment_type }),
                                        React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.MontlyOrDaily, value: center_data.payment_option }),
                                        React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Couirer_charges_type, value: center_data.courier_charges_type }),
                                        center_data.courier_charges_type != "SLABS" &&
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Couirer_charges, value: center_data.courier_charges }),
                                        center_data.courier_charges_type == "SLABS" &&
                                            React.createElement(React.Fragment, null,
                                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: center_data.slab_one ? center_data.slab_one : '', value: center_data.slab_one_payout }),
                                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: center_data.slab_two ? center_data.slab_two : '', value: center_data.slab_two_payout }),
                                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: center_data.slab_three ? center_data.slab_three : '', value: center_data.slab_three_payout }))),
                                updated_data && updated_data.action && updated_data.action == 1 && updated_data.status == scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE && center_data.status == scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'previous-status', lableName: orc_service_1.ocr_lables.PreviousStatus, value: checkStatus(scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE) }),
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'updated-status', lableName: orc_service_1.ocr_lables.UpdatedStatus, value: checkStatus(scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE) })),
                                updated_data && updated_data.action && updated_data.action == 1 && updated_data.status == scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE && center_data.status == scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'previous-status', lableName: orc_service_1.ocr_lables.PreviousStatus, value: checkStatus(scf_service_1.SCF_CLIENT_STATUS_OBJ.INACTIVE) }),
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'updated-status', lableName: orc_service_1.ocr_lables.UpdatedStatus, value: checkStatus(scf_service_1.SCF_CLIENT_STATUS_OBJ.ACTIVE) })),
                                updated_data && updated_data.action && updated_data.action == 2 && updated_data.status == scf_service_1.SCF_CLIENT_STATUS_OBJ.DELETED &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'previous-status', lableName: orc_service_1.ocr_lables.PreviousStatus, value: checkStatus(center_data.status) }),
                                        React.createElement(profile_grid_1.default, { grid_size: 3, className: 'updated-status', lableName: orc_service_1.ocr_lables.UpdatedStatus, value: checkStatus(scf_service_1.SCF_CLIENT_STATUS_OBJ.DELETED) }))),
                            updated_data && updated_data && updated_data.action && updated_data.action == 1 && center_data.status == updated_data.status &&
                                React.createElement(material_1.Grid, { display: 'flex', spacing: 0, rowSpacing: 3, columns: { xs: 12 }, columnSpacing: 3 },
                                    React.createElement(material_1.Grid, { item: true, xs: 6, container: true, className: 'payment-deatails' },
                                        React.createElement(material_1.Grid, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                            React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                React.createElement("h2", null, "Previous Data"))),
                                        React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Payment_type, value: center_data.payment_type }),
                                        React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.MontlyOrDaily, value: center_data.payment_option }),
                                        React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Couirer_charges_type, value: center_data.courier_charges_type }),
                                        center_data.courier_charges_type != 'SLABS' &&
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Couirer_charges, value: center_data.courier_charges }),
                                        center_data.courier_charges_type == 'SLABS' &&
                                            React.createElement(React.Fragment, null,
                                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: center_data.slab_one ? center_data.slab_one : '', value: center_data.slab_one_payout }),
                                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: center_data.slab_two ? center_data.slab_two : '', value: center_data.slab_two_payout }),
                                                React.createElement(profile_grid_1.default, { grid_size: 4, lableName: center_data.slab_three ? center_data.slab_three : '', value: center_data.slab_three_payout }))),
                                    React.createElement(material_1.Grid, { item: true, xs: 6, container: true, className: 'payment-deatails' },
                                        React.createElement(material_1.Grid, { className: "tab-heading-wrapper", item: true, xs: 12 },
                                            React.createElement("div", { className: "tab-heaidng profile-detail-heaidng" },
                                                React.createElement("h2", null, "Updated Data"))),
                                        center_data && updated_data && center_data.payment_type != updated_data.payment_type &&
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Payment_type, value: updated_data.payment_type }),
                                        center_data && updated_data && center_data.payment_option != updated_data.payment_option &&
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.MontlyOrDaily, value: updated_data.payment_option }),
                                        center_data && updated_data && center_data.courier_charges_type != updated_data.courier_charges_type &&
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Couirer_charges_type, value: updated_data.courier_charges_type }),
                                        center_data && updated_data && updated_data.courier_charges_type !== 'SLABS' && center_data.courier_charges != updated_data.courier_charges &&
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: scf_service_1.scf_lables.Couirer_charges, value: updated_data.courier_charges }),
                                        center_data && updated_data && updated_data.courier_charges_type == 'SLABS' && center_data.slab_one_payout != updated_data.slab_one_payout &&
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: updated_data.slab_one ? updated_data.slab_one : '', value: updated_data.slab_one_payout }),
                                        center_data && updated_data && updated_data.courier_charges_type == 'SLABS' && center_data.slab_two_payout != updated_data.slab_two_payout &&
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: updated_data.slab_two ? updated_data.slab_two : '', value: updated_data.slab_two_payout }),
                                        center_data && updated_data && updated_data.courier_charges_type == 'SLABS' && center_data.slab_three_payout != updated_data.slab_three_payout &&
                                            React.createElement(profile_grid_1.default, { grid_size: 4, lableName: updated_data.slab_three ? updated_data.slab_three : '', value: updated_data.slab_three_payout })))))))),
        React.createElement(delete_confirmation_modal_1.default, { deleteConfirModal: approvalConfirModal, deleteOpeneHandlere: approvalSubmitHandlere, deleteCloseHandlere: approvalCloseHandlere, mainTitle: "Confirmation", subTitle: "Are you sure  you want to approve" }),
        React.createElement(ModalComponentWithoutButton_1.default, { open: rejectedConfirModal },
            React.createElement("div", null,
                React.createElement("div", { className: "modal-header header-wrapper" },
                    React.createElement("h2", { className: "heading" },
                        "Rejection of ",
                        center_data && center_data.warehouse_code ? center_data.warehouse_code : '--'))),
            React.createElement(formik_1.Formik, { initialValues: initialValues, validateOnBlur: false, validationSchema: schema_1.schemas.LEVEL_ONE_REJECT_SCHEMA, onSubmit: rejectedSubmitHanlere }, function (_a) {
                var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldTouched = _a.setFieldTouched;
                return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                    React.createElement(material_1.Grid, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                        React.createElement(material_1.Grid, { item: true, xs: 6 },
                            React.createElement(drop_down_component_1.default, { name: "reject_type", placeholder: "Reason", dropDownList: orc_rejection_reasons ? orc_rejection_reasons : [], onChange: function (val) {
                                    setFieldValue('reject_type', val);
                                    setFieldTouched('reject_type', true);
                                }, value: values.reject_type }),
                            errors['reject_type'] && touched['reject_type'] ? (React.createElement("div", { className: "error" }, errors['reject_type'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 12 },
                            React.createElement(text_filed_component_1.default, { name: "reject_comment", maxLength: 200, onChange: function (reject_comment) {
                                    setFieldTouched('reject_comment');
                                    setFieldValue('reject_comment', reject_comment);
                                }, label: "Other Comments", value: values.reject_comment }),
                            errors['reject_comment'] && touched['reject_comment'] ? (React.createElement("div", { className: "error" }, errors['reject_comment'])) : null)),
                    React.createElement("div", { className: "modal-footer" },
                        React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: rejecteCloseHandlere }),
                        React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
            }))));
};
exports.default = SCFProfileDetails;
