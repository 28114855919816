"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
var api_urls_1 = require("../../api/api_urls");
var api_1 = require("../../components/super_admin/role_management/api");
require("../../components/super_admin/role_management/styles/transfer-list.scss");
var lodash_1 = require("../../utils/lodash");
var chips_service_1 = require("./chips_service");
var TransferList = function (_a) {
    var setSelectedFeatures = _a.setSelectedFeatures, updateEditFeaturesIds = _a.updateEditFeaturesIds, role_id = _a.role_id;
    var _b = React.useState([]), left = _b[0], setLeft = _b[1];
    var _c = React.useState([]), moduleList = _c[0], setVenorModuleList = _c[1];
    var _d = React.useState([]), right = _d[0], setRight = _d[1];
    var _e = React.useState({}), selectedIds = _e[0], setSelectedIds = _e[1];
    var _f = React.useState(false), isChipSelected = _f[0], setIsChipSelected = _f[1];
    var _g = React.useState(), errorMessage = _g[0], setErrorMessage = _g[1];
    var getFeatures = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, (0, api_1.fetchFeatures)(api_urls_1.api_urls.FEATURES, { app_id: parseInt(process.env.ROLES_APP_ID, 10) }, process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL)
                        .then(function (res) {
                        setErrorMessage("");
                        setLeft(res.data.result);
                        setVenorModuleList(res.data.result);
                    })
                        .catch(function (err) {
                        setErrorMessage(err);
                        setLeft([]);
                    })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    var selectedFeatureHandler = function (event, moduleName, featuresId) {
        var prevSelectedIDS = selectedIds[moduleName]
            ? selectedIds[moduleName]
            : [];
        if (event.target.checked) {
            var obj = {};
            obj[moduleName] = __spreadArray(__spreadArray([], prevSelectedIDS, true), [featuresId], false);
            setSelectedIds(__assign(__assign({}, selectedIds), obj));
            console.log(selectedIds, 'selectedIds');
            setIsChipSelected(true);
        }
        else {
            var index = selectedIds[moduleName].indexOf(featuresId);
            if (index > -1) {
                selectedIds[moduleName].splice(index, 1);
                setSelectedIds(__assign({}, selectedIds));
                if (selectedIds && selectedIds[moduleName] >= 1) {
                    setIsChipSelected(true);
                }
                else {
                    setIsChipSelected(false);
                }
            }
        }
    };
    var handleAllLeft = function (selectedModule) {
        console.log(selectedModule, 'selectedModule');
        var selectedData = (0, chips_service_1.filterSelctedModule)(left, selectedModule);
        var unSelectedData = (0, chips_service_1.filterUnSelctedModule)(left, selectedModule);
        if (isChipSelected) {
            var selectedX_features = (0, chips_service_1.filterSelctedX_features)(selectedData, selectedIds, selectedModule);
            var selectedObjList = [];
            var selectedObj_1 = {
                name: selectedModule,
                xFeature: selectedX_features,
            };
            selectedObjList.push(selectedObj_1);
            var isModulePresentRight_1 = false;
            console.log(selectedObjList, 'selectedObjList');
            right.map(function (singleModule, index) {
                if (singleModule.name === selectedModule) {
                    isModulePresentRight_1 = true;
                    right[index].xFeature = __spreadArray(__spreadArray([], right[index].xFeature, true), selectedObj_1.xFeature, true);
                }
            });
            if (isModulePresentRight_1) {
                setRight(__spreadArray([], right, true));
                setSelectedFeatures(__spreadArray([], right, true));
            }
            else {
                setRight(__spreadArray(__spreadArray([], right, true), selectedObjList, true));
                setSelectedFeatures(__spreadArray(__spreadArray([], right, true), selectedObjList, true));
            }
            var unSelectedX_features_1 = (0, chips_service_1.filterUnSelctedX_features)(selectedData, selectedIds, selectedModule);
            left.map(function (singleObj, index) {
                if (singleObj.name === selectedModule) {
                    if (singleObj.xFeature.length == 1) {
                        left.splice(index, 1);
                    }
                    else {
                        if (singleObj.xFeature.length ==
                            [selectedIds][0][selectedModule].length) {
                            left.splice(index, 1);
                        }
                        else {
                            left[index].xFeature = unSelectedX_features_1;
                        }
                    }
                }
            });
            setLeft(__spreadArray([], left, true));
            selectedIds[selectedModule] = [];
            setSelectedIds(__assign({}, selectedIds));
            setIsChipSelected(false);
        }
        else {
            var isModulePresentRight_2 = false;
            console.log(right, 'rightjhgfds');
            right.map(function (singleModule, index) {
                if (singleModule.name === selectedModule) {
                    selectedData.map(function (val) {
                        isModulePresentRight_2 = true;
                        right[index].xFeature = __spreadArray(__spreadArray([], right[index].xFeature, true), val.xFeature, true);
                    });
                }
            });
            if (isModulePresentRight_2) {
                setRight(__spreadArray([], right, true));
                setSelectedFeatures(__spreadArray([], right, true));
                setLeft(unSelectedData);
                setSelectedIds({});
            }
            else {
                if ([selectedIds] &&
                    [selectedIds][0] &&
                    [selectedIds][0][selectedModule] &&
                    [selectedIds][0][selectedModule].length >= 1) {
                    console.log("poiuytrdxcv");
                    var selectedX_features = (0, chips_service_1.filterSelctedX_features)(selectedData, selectedIds, selectedModule);
                    var selectedObjList = [];
                    var selectedObj = {
                        name: selectedModule,
                        xFeature: selectedX_features,
                    };
                    selectedObjList.push(selectedObj);
                    setRight(__spreadArray(__spreadArray([], right, true), selectedObjList, true));
                    setSelectedFeatures(__spreadArray(__spreadArray([], right, true), selectedObjList, true));
                    var unSelectedX_features_2 = (0, chips_service_1.filterUnSelctedX_features)(selectedData, selectedIds, selectedModule);
                    left.map(function (singleObj, index) {
                        if (singleObj.name === selectedModule) {
                            if (singleObj.xFeature.length == 1) {
                                left.splice(index, 1);
                            }
                            else {
                                if (singleObj.xFeature.length ==
                                    [selectedIds][0][selectedModule].length) {
                                    left.splice(index, 1);
                                }
                                else {
                                    left[index].xFeature = unSelectedX_features_2;
                                }
                            }
                        }
                    });
                    setLeft(__spreadArray([], left, true));
                    selectedIds[selectedModule] = [];
                    setSelectedIds(__assign({}, selectedIds));
                    setIsChipSelected(false);
                }
                else {
                    setRight(__spreadArray(__spreadArray([], right, true), selectedData, true));
                    setSelectedFeatures(__spreadArray(__spreadArray([], right, true), selectedData, true));
                    setLeft(unSelectedData);
                    setSelectedIds({});
                }
            }
        }
    };
    var handleAllRight = function (selectedModule) {
        var selectedData = (0, chips_service_1.filterSelctedModule)(right, selectedModule);
        var unSelectedData = (0, chips_service_1.filterUnSelctedModule)(right, selectedModule);
        if (isChipSelected) {
            var selectedX_features = (0, chips_service_1.filterSelctedX_features)(selectedData, selectedIds, selectedModule);
            var selectedObjList = [];
            var selectedObj_2 = {
                name: selectedModule,
                xFeature: selectedX_features,
            };
            selectedObjList.push(selectedObj_2);
            var isModulePresentRight_3 = false;
            left.map(function (singleModule, index) {
                if (singleModule.name === selectedModule) {
                    isModulePresentRight_3 = true;
                    left[index].xFeature = __spreadArray(__spreadArray([], left[index].xFeature, true), selectedObj_2.xFeature, true);
                }
            });
            if (isModulePresentRight_3) {
                setLeft(__spreadArray([], left, true));
                setIsChipSelected(false);
            }
            else {
                setLeft(__spreadArray(__spreadArray([], left, true), selectedObjList, true));
                setIsChipSelected(false);
            }
            var unSelectedX_features_3 = (0, chips_service_1.filterUnSelctedX_features)(selectedData, selectedIds, selectedModule);
            right.map(function (singleObj, index) {
                if (singleObj.name === selectedModule) {
                    if (singleObj.xFeature.length == 1) {
                        right.splice(index, 1);
                        setIsChipSelected(false);
                    }
                    else {
                        if (singleObj.xFeature.length ==
                            [selectedIds][0][selectedModule].length) {
                            right.splice(index, 1);
                        }
                        else {
                            right[index].xFeature = unSelectedX_features_3;
                        }
                    }
                }
            });
            setRight(__spreadArray([], right, true));
            setSelectedFeatures(__spreadArray([], right, true));
            selectedIds[selectedModule] = [];
            setSelectedIds(__assign({}, selectedIds));
            setIsChipSelected(false);
        }
        else {
            var isModulePresentRight_4 = false;
            left.map(function (singleModule, index) {
                if (singleModule.name === selectedModule) {
                    selectedData.map(function (val) {
                        isModulePresentRight_4 = true;
                        left[index].xFeature = __spreadArray(__spreadArray([], left[index].xFeature, true), val.xFeature, true);
                    });
                }
            });
            if (isModulePresentRight_4) {
                setLeft(__spreadArray([], left, true));
                setRight(unSelectedData);
                setSelectedFeatures(unSelectedData);
                setSelectedIds({});
            }
            else {
                if ([selectedIds] &&
                    [selectedIds][0] &&
                    [selectedIds][0][selectedModule] &&
                    [selectedIds][0][selectedModule].length >= 1) {
                    var selectedX_features = (0, chips_service_1.filterSelctedX_features)(selectedData, selectedIds, selectedModule);
                    var selectedObjList = [];
                    var selectedObj = {
                        name: selectedModule,
                        xFeature: selectedX_features,
                    };
                    selectedObjList.push(selectedObj);
                    setLeft(__spreadArray(__spreadArray([], left, true), selectedObjList, true));
                    var unSelectedX_features_4 = (0, chips_service_1.filterUnSelctedX_features)(selectedData, selectedIds, selectedModule);
                    right.map(function (singleObj, index) {
                        if (singleObj.name === selectedModule) {
                            if (singleObj.xFeature.length == 1) {
                                right.splice(index, 1);
                            }
                            else {
                                if (singleObj.xFeature.length ==
                                    [selectedIds][0][selectedModule].length) {
                                    right.splice(index, 1);
                                }
                                else {
                                    right[index].xFeature = unSelectedX_features_4;
                                }
                            }
                        }
                    });
                    setRight(__spreadArray([], right, true));
                    setSelectedFeatures(__spreadArray([], right, true));
                    selectedIds[selectedModule] = [];
                    setSelectedIds(__assign({}, selectedIds));
                    setIsChipSelected(false);
                }
                else {
                    setLeft(__spreadArray(__spreadArray([], left, true), selectedData, true));
                    setRight(unSelectedData);
                    setSelectedFeatures(unSelectedData);
                    setSelectedIds({});
                }
            }
        }
    };
    var editRoleProcess = function () { return __awaiter(void 0, void 0, void 0, function () {
        var editObjList, leftDuplicate;
        return __generator(this, function (_a) {
            editObjList = [];
            leftDuplicate = __spreadArray([], left, true);
            try {
                left.map(function (features, leftIndex) { return __awaiter(void 0, void 0, void 0, function () {
                    var parentIndex;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                parentIndex = leftIndex;
                                return [4, features.xFeature.map(function (xFeatureList, xFeatureIndex) {
                                        if (updateEditFeaturesIds.includes(xFeatureList.id)) {
                                            console.log(updateEditFeaturesIds, '::', xFeatureList.id);
                                            var isModuleAvailable_1 = false;
                                            editObjList &&
                                                editObjList.length > 0 &&
                                                editObjList.map(function (val, index) {
                                                    if (val.name == features.name) {
                                                        isModuleAvailable_1 = true;
                                                        editObjList[index].xFeature = __spreadArray(__spreadArray([], editObjList[index].xFeature, true), [
                                                            __assign({}, xFeatureList),
                                                        ], false);
                                                    }
                                                });
                                            if (isModuleAvailable_1) {
                                                console.log(editObjList, 'editObjList');
                                                var remvoeDuplicateObj = lodash_1.default.uniqBy(editObjList, 'name');
                                                console.log(remvoeDuplicateObj, 'remvoeDuplicateObj');
                                                editObjList = __spreadArray([], remvoeDuplicateObj, true);
                                            }
                                            else {
                                                var newObj = {
                                                    name: features.name,
                                                    xFeature: [xFeatureList],
                                                };
                                                editObjList.push(newObj);
                                            }
                                            leftDuplicate[parentIndex].xFeature = leftDuplicate[parentIndex].xFeature.filter(function (duplicateXFeature) {
                                                return duplicateXFeature.id != xFeatureList.id;
                                            });
                                        }
                                    })];
                            case 1:
                                _a.sent();
                                return [2];
                        }
                    });
                }); });
            }
            catch (e) {
                console.log(e);
            }
            console.log('sdfgeqrqrt:', editObjList, leftDuplicate);
            setLeft(leftDuplicate.filter(function (module, index) { return module.xFeature.length > 0; }));
            setRight(__spreadArray([], editObjList, true));
            setSelectedFeatures(__spreadArray([], editObjList, true));
            return [2];
        });
    }); };
    var renderChipsLeft = function (items, iconName) { return (React.createElement("div", { className: "list-container" },
        React.createElement(material_1.List, { className: "list-wrapper" }, items
            .sort(function (a, b) { return (a.name > b.name ? 1 : -1); })
            .map(function (feature) {
            return (React.createElement(material_1.ListItem, { key: feature.name, className: "feature-container" },
                React.createElement("div", { className: "feature-heading-wrapper" },
                    React.createElement("h2", { className: "feature-name section-title" },
                        ' ',
                        feature.name),
                    React.createElement("span", { className: "icon-swap swap-icon", onClick: function () { return handleAllLeft(feature.name); } })),
                React.createElement("p", { className: "section-desc" }, "Click on the above icon to add all the features or select one by one from below list."),
                React.createElement("div", { className: "feature-chips-wrapper" }, feature.xFeature.map(function (x_feature, index) {
                    return (React.createElement("label", { key: x_feature.id, className: "chip-label custom-chip boxed-chip  feature-chip ".concat(selectedIds &&
                            selectedIds[feature.name] &&
                            selectedIds[feature.name].includes(x_feature.id)
                            ? 'activeChip'
                            : '') },
                        React.createElement("input", { type: "checkbox", key: x_feature.id, name: "chip", id: "feature-chip", className: "boxed-chip  feature-chip", onChange: function () {
                                selectedFeatureHandler(event, feature.name, x_feature.id);
                            } }),
                        x_feature.name,
                        ' ',
                        React.createElement("span", { className: "".concat(iconName, " ") })));
                }))));
        })))); };
    var renderChipsRight = function (items, iconName) { return (React.createElement("div", { className: "list-container" },
        React.createElement(material_1.List, { className: "list-wrapper" }, items
            .sort(function (a, b) { return (a.name > b.name ? 1 : -1); })
            .map(function (feature) {
            console.log(feature, 'fijuhygtfeature');
            return (React.createElement(material_1.ListItem, { key: feature.name, className: "feature-container" },
                React.createElement("div", { className: "feature-heading-wrapper" },
                    React.createElement("h2", { className: "feature-name section-title" },
                        ' ',
                        feature.name),
                    React.createElement("span", { className: "icon-swap swap-icon", onClick: function () { return handleAllRight(feature.name); } })),
                React.createElement("p", { className: "section-desc" }, "Click on the above icon to add all the features or select one by one from below list."),
                React.createElement("div", { className: "feature-chips-wrapper" }, feature.xFeature.map(function (x_feature) {
                    return (React.createElement("label", { key: x_feature.id, className: "chip-label custom-chip boxed-chip  feature-chip ".concat(selectedIds &&
                            selectedIds[feature.name] &&
                            selectedIds[feature.name].includes(x_feature.id)
                            ? 'activeChip'
                            : '') },
                        React.createElement("input", { type: "checkbox", name: "chip", id: "feature-chip", className: "boxed-chip feature-chip", onChange: function () {
                                return selectedFeatureHandler(event, feature.name, x_feature.id);
                            } }),
                        x_feature.name,
                        ' ',
                        React.createElement("span", { className: "".concat(iconName, " ") })));
                }))));
        })))); };
    var renderPage = function () {
        return !errorMessage ? (React.createElement("div", { className: "transfer-list-wrapper" },
            React.createElement(material_1.Grid, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                React.createElement(material_1.Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: "options-containers features-details-wrapper" },
                        React.createElement("h2", { className: "section-title" }, "All features"),
                        React.createElement("p", { className: "section-desc" }, "Please add what features assigned to this role from Left side")),
                    React.createElement("div", { className: "list-container-wrapper" }, renderChipsLeft(left, 'icon-add'))),
                React.createElement(material_1.Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: "options-selcted-containers features-details-wrapper" },
                        React.createElement("h2", { className: "section-title" }, "Selected features"),
                        React.createElement("p", { className: "section-desc" }, "After adding all the features that you want to add, click on \u201CSAVE\u201D to create role")),
                    React.createElement("div", { className: "list-container-wrapper" }, right.length == 0 ? (React.createElement("div", { className: "no-feature-container" },
                        React.createElement("p", { className: "no-feature-message" }, "No features available for selection"))) : (React.createElement("div", null, renderChipsRight(right, 'icon-cross feature-delete')))))))) : (React.createElement("div", { className: "server-error" }, errorMessage && errorMessage.toString()));
    };
    React.useEffect(function () {
        getFeatures();
    }, []);
    React.useEffect(function () {
        if (updateEditFeaturesIds.length > 0 && role_id && moduleList.length > 0) {
            editRoleProcess();
        }
    }, [moduleList, updateEditFeaturesIds]);
    if (errorMessage) {
        return (React.createElement("div", { className: "server-error" },
            ' ',
            errorMessage && errorMessage.toString()));
    }
    return React.createElement(React.Fragment, null, renderPage());
};
exports.default = TransferList;
