"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MASTERBOX_TRANSPORT_MODES = exports.MASTER_BOX_EVENT_NAMES = exports.MASTER_BOX_EVENT_TYPES = exports.fetchMastersTrackingDetails = exports.convertMasterBoxDetailsFromRes = exports.convertMasterBoxListFromRes = exports.fetchMasterBoxDetails = exports.fetchMasterBoxList = void 0;
var api_service_1 = require("../../api/api_service");
var fetchMasterBoxList = function (endPoint, payLoad, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .post({
                    endPoint: endPoint,
                    domain: domain,
                    payLoad: payLoad,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        return resolve(response.data.data);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchMasterBoxList = fetchMasterBoxList;
var fetchMasterBoxDetails = function (endPoint, payLoad, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .get({
                    endPoint: endPoint,
                    domain: domain,
                    payLoad: payLoad,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        return resolve(response.data.data);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchMasterBoxDetails = fetchMasterBoxDetails;
var convertMasterBoxListFromRes = function (result) {
    var convertedres = [];
    result.map(function (singleObj) {
        var convertedObj = {
            id: singleObj.master_box_barcode
                ? singleObj.master_box_barcode
                : '',
            bag_id: singleObj.barcode_id ? singleObj.barcode_id : '',
            center_code: singleObj.warehouse_code
                ? singleObj.warehouse_code
                : '',
            center_name: singleObj.center_name ? singleObj.center_name : '',
            created_by: singleObj.created_by_type
                ? singleObj.created_by_type
                : '',
            current_status: singleObj.status ? singleObj.status : '',
            created_datetime: singleObj.created_date_time
                ? singleObj.created_date_time
                : '',
            center_city: singleObj.center_city ? singleObj.center_city : '',
            center_state: singleObj.center_state ? singleObj.center_state : '',
            lab_code: singleObj.lab_code ? singleObj.lab_code : '',
            warehouse_code: singleObj.warehouse_code
                ? singleObj.warehouse_code
                : '',
        };
        convertedres.push(convertedObj);
    });
    return convertedres;
};
exports.convertMasterBoxListFromRes = convertMasterBoxListFromRes;
var convertMasterBoxDetailsFromRes = function (singleObj) {
    var bagDetailsObj = [];
    singleObj.bags.map(function (bag) {
        var singleBagDetails = {
            barcode_id: bag.barcode_id ? bag.barcode_id : '',
            warehouse_code: bag.warehouse_code ? bag.warehouse_code : '',
            status: bag.status ? bag.status : '',
            created_date_time: bag.created_date_time
                ? bag.created_date_time
                : '',
            bag_handover_to_name: bag.bag_handover_to_name
                ? bag.bag_handover_to_name
                : '',
            bag_handover_to_fe_id: bag.bag_handover_to_fe_id
                ? bag.bag_handover_to_fe_id
                : '',
            bag_handover_to_city: bag.bag_handover_to_city
                ? bag.bag_handover_to_city
                : '',
            center_name: bag.center_name ? bag.center_name : '',
        };
        bagDetailsObj.push(singleBagDetails);
    });
    var convertedObj = {
        master_box_id: singleObj.master_box_id ? singleObj.master_box_id : '',
        status: singleObj.status ? singleObj.status : '',
        master_box_image: singleObj.master_box_image
            ? singleObj.master_box_image
            : '',
        intercity_handover_image: singleObj.intercity_handover_image
            ? singleObj.intercity_handover_image
            : '',
        intercity_pickup_image: singleObj.intercity_pickup_image
            ? singleObj.intercity_pickup_image
            : '',
        lab_handover_image: singleObj.lab_handover_image
            ? singleObj.lab_handover_image
            : '',
        bags_count: singleObj.bags_count ? singleObj.bags_count : null,
        box_cerated_date_time: singleObj.box_cerated_date_time
            ? singleObj.box_cerated_date_time
            : '',
        lab_code: singleObj.lab_code ? singleObj.lab_code : '',
        lab_name: singleObj.lab_name ? singleObj.lab_name : '',
        lab_state: singleObj.lab_state ? singleObj.lab_state : '',
        lab_city: singleObj.lab_city ? singleObj.lab_city : '',
        pickup_fe_code: singleObj.pickup_fe_code
            ? singleObj.pickup_fe_code
            : '',
        fe_name: singleObj.fe_name ? singleObj.fe_name : '',
        fe_state: singleObj.fe_state ? singleObj.fe_state : '',
        fe_city: singleObj.fe_city ? singleObj.fe_city : '',
        cc_state: singleObj.cc_state ? singleObj.cc_state : '',
        cc_city: singleObj.cc_city ? singleObj.cc_city : '',
        bags: bagDetailsObj,
    };
    return convertedObj;
};
exports.convertMasterBoxDetailsFromRes = convertMasterBoxDetailsFromRes;
var fetchMastersTrackingDetails = function (endPoint, payLoad, domain) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2, new Promise(function (resolve, reject) {
                api_service_1.api_service
                    .get({
                    endPoint: endPoint,
                    domain: domain,
                    payLoad: payLoad,
                })
                    .then(function (response) {
                    if (response.status === 200) {
                        return resolve(response.data.data);
                    }
                    var erroMessage = response.data
                        ? response.data.message
                        : 'Unable to process your request.';
                    throw new Error(erroMessage);
                })
                    .catch(function (err) {
                    return reject(err);
                });
            })];
    });
}); };
exports.fetchMastersTrackingDetails = fetchMastersTrackingDetails;
exports.MASTER_BOX_EVENT_TYPES = {
    Created: 'Created',
    In_transit: 'In transit',
    Picked_up_at_destination: 'Picked up at destination',
    Handed_over_at_lab: 'Handed over at lab',
};
exports.MASTER_BOX_EVENT_NAMES = {
    Created: 'Created',
    In_transit: 'Handover To Intercity',
    Picked_up_at_destination: 'Received At Intercity',
    Handed_over_at_lab: 'Handover To Lab',
};
exports.MASTERBOX_TRANSPORT_MODES = {
    Bus: 'Bus',
    Train: 'Train',
    Air: 'Air',
    Courier: 'Courier',
    OBC_OTC: 'OBC/OTC',
    Others: 'Others',
};
