"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var moment = require("moment");
var React = require("react");
var react_hot_toast_1 = require("react-hot-toast");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var api_service_1 = require("../../../api/api_service");
var button_component_1 = require("../../../common/buttons/button_component");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var text_field_with_datepicker_1 = require("../../../common/text_filed/text_field_with_datepicker");
var appConstants_1 = require("../../../constants/appConstants");
var route_path_1 = require("../../../constants/route_path");
var schema_1 = require("../../../constants/schema");
var generic_api_calls_1 = require("../../generic_api_calls");
var array_helpers_1 = require("../../../utils/array_helpers");
var AssignUsersToDraftAudit = function (_a) {
    var initialvalues = _a.initialvalues, auditID = _a.auditID;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList; }), stateList = _b.stateList, countryList = _b.countryList, labList = _b.labList, center_auditees_types = _b.center_auditees_types;
    var _c = React.useState([]), cityList = _c[0], setCityList = _c[1];
    var _d = React.useState([]), ccList = _d[0], setCcList = _d[1];
    var _e = React.useState([]), feList = _e[0], setFeList = _e[1];
    var targetAuditorsList = React.useState(appConstants_1.targetAuditorsDropdownList)[0];
    var _f = React.useState(true), labOrStateAudit = _f[0], setlabOrStateAudit = _f[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var intializedropdowns = function () { return __awaiter(void 0, void 0, void 0, function () {
        var selectedCitiesIDs, selectedStateIDS, targetAudience, auditTypeLabOrCity, payLoad, selectedWarehouseCodes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    selectedCitiesIDs = [];
                    if (!initialvalues.labcode) return [3, 1];
                    setlabOrStateAudit(true);
                    return [3, 4];
                case 1:
                    if (!(initialvalues.state.length > 0)) return [3, 3];
                    selectedStateIDS = initialvalues.state.map(function (state) { return state.id; });
                    return [4, (0, generic_api_calls_1.fetchCitiesUnderStates)(selectedStateIDS)
                            .then(function (cityListFromStateId) {
                            setCityList(cityListFromStateId);
                        })
                            .catch(function (err) {
                            setCityList([]);
                        })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    if (initialvalues.city.length > 0 &&
                        initialvalues.selectedAuditee &&
                        initialvalues.selectedAuditors) {
                        initialvalues.city.map(function (city) {
                            return selectedCitiesIDs.push({
                                id: city.id,
                                city: city.label.split(',')[0],
                            });
                        });
                    }
                    setlabOrStateAudit(false);
                    _a.label = 4;
                case 4:
                    targetAudience = [
                        initialvalues.selectedAuditors.label,
                        initialvalues.selectedAuditee.label,
                    ];
                    auditTypeLabOrCity = initialvalues.labcode ? [initialvalues.labcode.value.toString()] : selectedCitiesIDs;
                    payLoad = initialvalues.labcode ? {
                        labs: auditTypeLabOrCity,
                        audience_type: targetAudience,
                    } : {
                        cities: auditTypeLabOrCity,
                        audience_type: targetAudience,
                    };
                    selectedWarehouseCodes = [];
                    return [4, (0, generic_api_calls_1.fetchAudienceUnderCitiesOrLabs)(payLoad)
                            .then(function (res) {
                            res.map(function (singleAuditee) {
                                return selectedWarehouseCodes.push(singleAuditee.short_code);
                            });
                            setCcList(res);
                        })
                            .catch(function (err) {
                            setCcList([]);
                        })];
                case 5:
                    _a.sent();
                    return [4, (0, generic_api_calls_1.fetchAuditorsUnderCC)(selectedWarehouseCodes)
                            .then(function (res) {
                            setFeList(res);
                        })
                            .catch(function (err) {
                            setFeList([]);
                        })];
                case 6:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    React.useEffect(function () {
        intializedropdowns();
    }, []);
    var formSubmitHnadler = function (values) {
        var payload = {};
        payload['audit_id'] = auditID;
        payload['start_date'] = values.startDate
            ? moment(values.startDate).format('YYYY-MM-DD')
            : '';
        payload['end_date'] = values.endDate
            ? moment(values.endDate).format('YYYY-MM-DD')
            : '';
        if (labOrStateAudit) {
            payload['labs'] = [values.labcode.value.toString()];
        }
        else {
            var countries_1 = [];
            values.country.map(function (country) {
                return country.label ? countries_1.push(country.label) : '';
            });
            payload['countries'] = countries_1;
            var states_1 = [];
            values.state.map(function (state) {
                return state.label ? states_1.push(state.label) : '';
            });
            payload['states'] = states_1;
            var cities_1 = [];
            values.city.map(function (city) {
                return city.label ? cities_1.push(city.label) : '';
            });
            payload['cities'] = cities_1;
        }
        var audit_mapping_api = {};
        audit_mapping_api['auditor_type'] = values.selectedAuditors.label;
        audit_mapping_api['auditee_type'] = values.selectedAuditee.label;
        var auditees = [];
        values.auditees.map(function (auditee) {
            return auditee.short_code ? auditees.push(auditee.short_code) : '';
        });
        audit_mapping_api['auditees'] = auditees;
        var auditors = [];
        values.auditors.map(function (auditor) {
            return auditor.FE_id ? auditors.push(auditor.FE_id) : '';
        });
        audit_mapping_api['auditors'] = auditors;
        payload['audit_mappings'] = [audit_mapping_api];
        api_service_1.api_service
            .post({
            endPoint: api_service_1.api_service.api_urls.ASSIGN_AUDIT,
            payLoad: payload,
            domain: process.env.APPROVAL_BASEURL,
        })
            .then(function (res) {
            if (res.data.statusCode === 200) {
                react_hot_toast_1.default.success(res.data.message);
                navigate("/".concat(route_path_1.default.PATHS.audit));
            }
            else {
                react_hot_toast_1.default.error(res.data.message);
            }
        })
            .catch(function (err) {
            react_hot_toast_1.default.error(err.data.errors);
        });
    };
    var getDropdownList = function (match) {
        var matchString = match ? match.label : '';
        switch (matchString) {
            case 'CC':
            case 'PUP':
            case 'ALL':
            case 'PSC':
                return ccList;
            case 'FE':
                return feList;
            default:
                return [];
        }
    };
    var cancelButtonHandler = function () {
        navigate(-1);
    };
    return (React.createElement("div", { className: "form-filed-wrapper p-24" },
        React.createElement("div", { className: "form-heaidng" },
            React.createElement("h2", { className: "F-18" }, "Assign Audit"),
            React.createElement("p", { className: "sub-heaidng text-medium" }, "Please assign audit to Targeted Users here")),
        React.createElement("div", { className: "create-audit-form p-0" },
            React.createElement(formik_1.Formik, { enableReinitialize: false, validateOnBlur: false, initialValues: __assign({}, initialvalues), validationSchema: labOrStateAudit ? schema_1.schemas.ASSIGN_USERS_TO_LAB_AUDIT_SCHEMA : schema_1.schemas.ASSIGN_USERS_TO_CITY_AUDIT_SCHEMA, onSubmit: function (values, _a) {
                    var resetForm = _a.resetForm;
                    formSubmitHnadler(values);
                } }, function (_a) {
                var errors = _a.errors, setErrors = _a.setErrors, touched = _a.touched, values = _a.values, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched;
                return (React.createElement(formik_1.Form, null,
                    React.createElement(material_1.Grid, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(text_field_with_datepicker_1.default, { required: true, name: 'startDate', placeholder: "Start Date", label: "Start Date", isPastDisable: true, isFutureDisable: false, value: values['startDate'], type: "date", onChange: function (val) {
                                    setFieldValue('startDate', val);
                                    setFieldTouched('startDate', true);
                                } }),
                            errors['startDate'] && touched['startDate'] ? (React.createElement("div", { className: "error" }, errors['startDate'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(text_field_with_datepicker_1.default, { required: true, name: 'endDate', placeholder: "End Date", label: "End Date", isPastDisable: true, isFutureDisable: false, value: values['endDate'], onChange: function (val) {
                                    setFieldValue('endDate', val);
                                    setFieldTouched('endDate', true);
                                } }),
                            errors['endDate'] && touched['endDate'] ? (React.createElement("div", { className: "error" }, errors['endDate'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 12 },
                            React.createElement("div", { className: "radio-btn-wrapper green-tick column-type-radios" },
                                React.createElement(material_1.FormLabel, { className: "radio-btn-label" }, "Select Lab OR State & City type"),
                                React.createElement(material_1.RadioGroup, { row: true, name: "handoverdone", key: Math.random().toString(), defaultValue: labOrStateAudit, onChange: function () {
                                        setFieldValue('city', []);
                                        setFieldValue('selectedAuditee', null);
                                        setFieldValue('selectedAuditors', null);
                                        setFieldValue('auditees', []);
                                        setFieldValue('auditors', []);
                                        setFieldValue('state', []);
                                        setFieldValue('country', []);
                                        setFieldValue('labcode', null);
                                        setlabOrStateAudit(!labOrStateAudit);
                                    } },
                                    React.createElement(material_1.FormControlLabel, { value: true, control: React.createElement(material_1.Radio, null), label: "Labcode" }),
                                    React.createElement(material_1.FormControlLabel, { value: false, control: React.createElement(material_1.Radio, null), label: "State & City" })))),
                        labOrStateAudit &&
                            React.createElement(material_1.Grid, { item: true, xs: 5 },
                                React.createElement(drop_down_component_1.default, { disableClearable: true, name: "labcode", placeholder: "Select Labcode", label: "Labcode", value: values['labcode'], dropDownList: (0, array_helpers_1.convertLabcodeResponse)(labList), onChange: function (val) {
                                        setFieldValue('selectedAuditee', null);
                                        setFieldValue('selectedAuditors', null);
                                        setFieldValue('labcode', val);
                                        setFieldTouched('labcode', true);
                                    } }),
                                labOrStateAudit && errors['labcode'] && touched['labcode'] ? (React.createElement("div", { className: "error" }, errors['labcode'])) : null),
                        !labOrStateAudit &&
                            React.createElement(React.Fragment, null,
                                React.createElement(material_1.Grid, { item: true, xs: 5 },
                                    React.createElement(multi_selector_1.default, { size: "dropdown-lg", requiredClass: 'validate-filed', dropDownList: countryList, label: 'Country', value: values['country'], onChangeHnadlre: function (val) {
                                            setFieldValue('city', []);
                                            setFieldValue('selectedAuditee', null);
                                            setFieldValue('selectedAuditors', null);
                                            setFieldValue('auditees', []);
                                            setFieldValue('auditors', []);
                                            setFieldValue('state', []);
                                            setFieldValue('country', val);
                                            setFieldTouched('country', true);
                                        } }),
                                    errors['country'] && touched['country'] ? (React.createElement("div", { className: "error" }, errors['country'])) : null),
                                React.createElement(material_1.Grid, { item: true, xs: 5 },
                                    React.createElement(multi_selector_1.default, { size: "dropdown-lg", requiredClass: 'validate-filed', dropDownList: stateList, label: 'State', value: values['state'], onChangeHnadlre: function (val) {
                                            if (val.length > 0) {
                                                var stateid = val.map(function (singleval) { return singleval.id; });
                                                (0, generic_api_calls_1.fetchCitiesUnderStates)(stateid).then(function (res) {
                                                    setCityList(res);
                                                });
                                            }
                                            else {
                                                setCityList([]);
                                            }
                                            setFieldValue('city', []);
                                            setFieldValue('selectedAuditee', null);
                                            setFieldValue('selectedAuditors', null);
                                            setFieldValue('auditees', []);
                                            setFieldValue('auditors', []);
                                            setFieldValue('state', val);
                                            setFieldTouched('state', true);
                                        } }),
                                    errors['state'] && touched['state'] ? (React.createElement("div", { className: "error" }, errors['state'])) : null),
                                React.createElement(material_1.Grid, { item: true, xs: 5 },
                                    React.createElement(multi_selector_1.default, { noOptions: 'No cities found', requiredClass: 'validate-filed', hasSelectAll: true, size: "dropdown-lg", label: "City", dropDownList: cityList, value: values['city'], onChangeHnadlre: function (val) {
                                            setFieldValue('selectedAuditee', null);
                                            setFieldValue('selectedAuditors', null);
                                            setFieldValue('auditees', []);
                                            setFieldValue('auditors', []);
                                            setFieldTouched('city', true);
                                            setFieldValue('city', val);
                                            setCcList([]);
                                            setFeList([]);
                                        } }),
                                    errors['city'] && touched['city'] ? (React.createElement("div", { className: "error" }, errors['city'])) : null)),
                        React.createElement(material_1.Grid, { item: true, container: true, xs: 10, columnSpacing: 3 },
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement("div", { className: "gray-badge-lg" },
                                    React.createElement("p", null, "Targeted Auditees"))),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement("div", { className: "gray-badge-lg" },
                                    React.createElement("p", null, "Targeted Auditors")))),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(drop_down_component_1.default, { required: true, name: "auditiesList", placeholder: "Select Auditees", value: values['selectedAuditee'], dropDownList: center_auditees_types ? center_auditees_types : [], isDisabled: labOrStateAudit ? values.labcode == null : values.city && values.city.length <= 0, onChange: function (val) {
                                    setFieldValue('selectedAuditors', null);
                                    setFieldValue('auditees', []);
                                    setFieldValue('auditors', []);
                                    setFieldValue('selectedAuditee', val);
                                    setFieldTouched('selectedAuditee', true);
                                } }),
                            errors['selectedAuditee'] && touched['selectedAuditee'] ? (React.createElement("div", { className: "error" }, errors['selectedAuditee'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(drop_down_component_1.default, { required: true, name: "auditorsList", placeholder: "Select Auditors", value: values['selectedAuditors'], isDisabled: labOrStateAudit ? values.labcode == null : values.city.length <= 0, dropDownList: targetAuditorsList, onChange: function (val) {
                                    var targetAudience = [
                                        val.label,
                                        values.selectedAuditee.label,
                                    ];
                                    var citiesIDs = values.city.map(function (singleval) {
                                        return {
                                            id: singleval.id,
                                            city: singleval.label.split(',')[0],
                                        };
                                    });
                                    var auditTypeLabOrCity = labOrStateAudit ? [values.labcode.value.toString()] : citiesIDs;
                                    var payLoad = labOrStateAudit ? {
                                        labs: auditTypeLabOrCity,
                                        audience_type: targetAudience,
                                    } : {
                                        cities: auditTypeLabOrCity,
                                        audience_type: targetAudience,
                                    };
                                    (0, generic_api_calls_1.fetchAudienceUnderCitiesOrLabs)(payLoad)
                                        .then(function (res) {
                                        setCcList(res);
                                    })
                                        .catch(function (err) {
                                        setCcList([]);
                                    });
                                    setFieldValue('selectedAuditors', val);
                                    setFieldTouched('selectedAuditors', true);
                                } }),
                            errors['selectedAuditors'] &&
                                touched['selectedAuditors'] ? (React.createElement("div", { className: "error" }, errors['selectedAuditors'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(multi_selector_1.default, { size: "dropdown-lg", requiredClass: 'validate-filed', label: "Auditee('s)", dropDownList: getDropdownList(values.selectedAuditee), value: values['auditees'], onChangeHnadlre: function (val) {
                                    setFieldValue('auditors', []);
                                    var selectedAudities = val.map(function (signleVal) { return signleVal.short_code; });
                                    (0, generic_api_calls_1.fetchAuditorsUnderCC)(selectedAudities)
                                        .then(function (res) {
                                        setFeList(res);
                                    })
                                        .catch(function (err) {
                                        setFeList([]);
                                    });
                                    setFieldValue('auditees', val);
                                    setFieldTouched('auditees', true);
                                } }),
                            errors['auditees'] && touched['auditees'] ? (React.createElement("div", { className: "error" }, errors['auditees'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 5 },
                            React.createElement(multi_selector_1.default, { size: "dropdown-lg", requiredClass: 'validate-filed', label: "Auditor('s)", value: values['auditors'], dropDownList: getDropdownList(values.selectedAuditors), onChangeHnadlre: function (val) {
                                    setFieldValue('auditors', val);
                                    setFieldTouched('auditors', true);
                                } }),
                            errors['auditors'] && touched['auditors'] ? (React.createElement("div", { className: "error" }, errors['auditors'])) : null),
                        React.createElement(material_1.Grid, { item: true, xs: 10 },
                            React.createElement("div", { className: "btns-wrapper form-btn-wrapper" },
                                React.createElement(button_component_1.default, { buttonName: "Cancel", color: "yellow-outline", onClickHandler: cancelButtonHandler }),
                                React.createElement(button_component_1.default, { buttonName: "Publish", color: "yellow", type: "submit" }))))));
            }))));
};
exports.default = React.memo(AssignUsersToDraftAudit);
